/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import '@medical/pages/staff/RecruitmentScheduleShift/CreateRecruitmentScheduleShift/styles.css'

import { useQuery } from '@apollo/react-hooks'
import AutoComplete from '@medical/components/AutoComplete'
import CheckBoxList from '@medical/components/CheckBoxList'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import Spinner from '@medical/components/Spinner'
import {
  objectTargetConstant,
  SHCEDULE_SHIFT_ACTION,
  TYPE_CREATE,
} from '@medical/constant'
import {
  convertClinicalTimeSheet,
  convertDoctors,
  setTimeSplitByDay,
  splitLogicReverse,
  thisMonthHolidays,
  thisRangeHolidays,
} from '@medical/libs'
import { isHolidayInRangeDate } from '@medical/libs/isHolidayInRangeDate'
import ShiftGroupFormRepeatSetting from '@medical/pages/staff/components/ShiftGroupFormRepeatSetting'
import ShiftGroupFormSplits from '@medical/pages/staff/components/ShiftGroupFormSplits'
import {
  CLINIC_TIME_SHEET,
  GET_SALARY_AVAILABLESHIFT,
} from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import { DatePicker, Switch } from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Checkbox, Checkbox as CheckboxPrime } from 'primereact/checkbox'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { TabPanel, TabView } from 'primereact/tabview'
import { pathOr } from 'ramda'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'

import { ShiftFormDoctorPicker } from '../../components/ShiftFormDoctorPicker'
import ShiftFormRecruitmentCategory from '../../components/ShiftFormRecruitmentCategory'
import { ShiftFormRecruitmentCategoryDetail } from '../../components/ShiftFormRecruitmentCategoryDetail'
import ShiftFormStaffComment from '../../components/ShiftFormStaffComment'
import { WorkScheduleComment } from '../../DetailWorkSchedule/WorkScheduleComment'

const RecruitmentScheduleShiftForm = ({
  i18n,
  stateUnpublished = {},
  recruitmentScheduleShift,
  clinicalDepartments,
  activitiesHistory,
  postActivitiesHistory,
  onSubmit,
  date,
  title,
  submitLabel,
  confirmationMessage,
  workSchedule,
  isMoneyPermitted,
  isCreateAvailableShift,
  isUpdateScheduleAvailableShift,
  isDetailAvailableShiftGroup,
  isDeleteScheduleAvailableShift,
  node = {},
  popup,
  isChangeRecruitmentShiftsStatusPermitted,
  staffComments,
  recruitmentCategory,
  isAddCommentPermitted,
  isDeleteCommentPermitted,
  recruitmentScheduleId,
  staffMe,
  holidaysData,
  dataEnumComment,
  rangeDateShow = [null, null],
  scheduledShiftGroupId,
  detailValue,
  firstAvailableShift,
  isHideDoubleRecruitmentConfirm,
  isDeleteAllComment
}) => {
  let initValue
  const [informTime, setInformTime] = useState(
    recruitmentScheduleShift
      ? `${
          moment(recruitmentScheduleShift.node.startDate)
            .format()
            .split('+')[0]
            .split('T')[0]
        }T00:00:00`
      : detailValue
      ? detailValue.startDate
      : ''
  )
  const [selectingClinicId, setSelectingClinicId] = useState('')
  const [
    selectingClinicDepartmentId,
    setSelectingClinicDepartmentId,
  ] = useState('')
  const [shiftTitle, setShiftTitle] = useState('')

  useEffect(() => {
    if (recruitmentScheduleShift) {
      setSelectingClinicId(
        firstAvailableShift.availableShifts &&
          firstAvailableShift.availableShifts.length > 0
          ? firstAvailableShift.availableShifts[0].clinicalDepartment.clinic.id
          : ''
      )
      setSelectingClinicDepartmentId(
        recruitmentScheduleShift &&
          firstAvailableShift.availableShifts &&
          firstAvailableShift.availableShifts.length > 0
          ? firstAvailableShift.availableShifts[0].clinicalDepartment.id
          : ''
      )
    } else if (detailValue) {
      setSelectingClinicId(detailValue.clinicalDepartment.clinic.id)
      setSelectingClinicDepartmentId(detailValue.clinicalDepartment.id)
    }
  }, [recruitmentScheduleShift, detailValue])
  // Test log
  const validationSchema = Yup.object()
    .shape({
      clinicalDepartment: Yup.object()
        .nullable()
        .required(i18n.t('validation.clinicalDepartment.required')),
      doctor: workSchedule
        ? Yup.object()
            .nullable()
            .required(i18n.t('validation.doctor.required'))
        : undefined,
      date: Yup.date(i18n.t('validation.date.required')).required(''),
      startTime: Yup.date('Should be string').required(''),
      splitComments: Yup.array().of(
        Yup.object().shape(
          {
            type: Yup.string().when('text', {
              is: text => text && text.length > 0,
              then: Yup.string(i18n.t('validation.comment.required')).required(
                i18n.t('validation.dropdownCommentType.required')
              ),
            }),
            text: Yup.string().when('type', {
              is: value => value && value.length > 0,
              then: Yup.string(i18n.t('validation.comment.required'))
                .trim()
                .required(i18n.t('validation.comment.required'))
                .max(500, i18n.t('validation.title.max', { max: 500 })),
            }),
          },
          ['type', 'text']
        )
      ),
      splitRecruitmentCategories: Yup.array().of(
        Yup.object().shape(
          {
            category: Yup.string().when('status', {
              is: text => text && text.length > 0,
              then: Yup.string().required('募集項目を入力してください'),
            }),
            status: Yup.string().when('category', {
              is: value => value && value.length > 0,
              then: Yup.string()
                .trim()
                .required('募集項目を入力してください'),
            }),
            description: Yup.string().when('category', {
              is: value => value && value.length > 0 && value === 'OTHER',
              then: Yup.string()
                .trim()
                .required('募集項目を入力してください')
                .max(500, i18n.t('validation.title.max', { max: 500 })),
            }),
          },
          ['category', 'status', 'description']
        )
      ),
      numberOfRooms: Yup.number()
        .required(i18n.t('validation.numberOfRooms.required'))
        .min(1, i18n.t('validation.numberOfRooms.min'))
        .max(10, i18n.t('validation.numberOfRooms.max')),
      startEndDate: Yup.mixed().test(
        'validate start end date',
        i18n.t('validation.shiftGroup.startEndDate'),
        val => {
          if (val && val[0] && val[1]) return true
          return false
        }
      ),
      name: Yup.string().required(i18n.t('validation.shiftGroup.name')),
      repeatSetting: Yup.string().required(i18n.t('validation.shiftGroup.day')),
      otherRecruitmentCategory: Yup.string()
        .nullable()
        .when('recruitmentCategory', {
          is: rc => rc.value === 'OTHER',
          then: Yup.string().required(
            i18n.t('validation.otherRecruitmentCategory.required')
          ),
        }),
      objectTarget: Yup.string().nullable(),
      workPattern: Yup.mixed().when('objectTarget', {
        is: objectTargetConstant.SET_FOR_EACH_WORKPARTTEN,
        then: Yup.mixed().test(
          'validateWorkPartten',
          i18n.t('main.incentiveSetting.listMessageValidation.objectTarget'),
          val => val && val.length > 0
        ),
      }),
      specificDoctors: Yup.mixed().when('objectTarget', {
        is: objectTargetConstant.SET_FOR_EACH_DOCTOR,
        then: Yup.mixed().test(
          'validateSelectDoctor',
          i18n.t('main.incentiveSetting.listMessageValidation.objectTarget'),
          val => val && val.length > 0
        ),
      }),
    })
    .test('checkSelectDay', i18n.t('validation.shiftGroup.day'), value => {
      if (
        (!value.isMonday &&
          !value.isTuesday &&
          !value.isWednesday &&
          !value.isThursday &&
          !value.isFriday &&
          !value.isSaturday &&
          !value.isSunday &&
          !value.isDayoff &&
          value.repeatSetting === TYPE_CREATE.WEEKLY) ||
        (value.listWeekDay.length === 0 &&
          value.listWeekDay1.length === 0 &&
          value.listWeekDay2.length === 0 &&
          value.listWeekDay3.length === 0 &&
          value.listWeekDay4.length === 0 &&
          value.listWeekDay5.length === 0 &&
          value.repeatSetting === TYPE_CREATE.MONTHLY)
      ) {
        return false
      }
      return true
    })

  const holidays = thisMonthHolidays({
    date: informTime,
    holidaysData: pathOr([], ['items'], holidaysData),
  })
  let isHoliday = false
  // const [startTimeCheckHourlyWage, setStartTimeCheckHourlyWage] = useState({
  //   time: `${moment().format('YYYY-MM-DD')}T` + `09:00:00`,
  //   index: 0,
  //   isEndTime: false,
  // })
  // const [currentHourlyWage, setCurrentHourlyWage] = useState([0, 0, 0, 0])
  const [informTimeData, setInformTimeData] = useState('')
  useEffect(() => {
    for (const element of holidays) {
      if (element.date === moment(informTime).format('YYYY-MM-DD'))
        isHoliday = true
    }
  }, [informTime])

  const { loading, error, data, refetch } = useQuery(CLINIC_TIME_SHEET, {
    variables: {
      clinicalDepartmentId: selectingClinicDepartmentId,
      date: informTime,
      isHoliday,
    },
  })
  //Call api get hourlywage by value
  const {
    loading: hourlyWageLoading,
    error: hourlyWageError,
    data: hourlyWageData,
  } = useQuery(GET_SALARY_AVAILABLESHIFT, {
    variables: {
      where: {
        ClinicalDepartment: {
          id: selectingClinicDepartmentId || undefined,
        },
        startDate_lte: informTime ? informTime + 'Z' : undefined,
        endDate_gte: informTime ? informTime + 'Z' : undefined,
        deletedAt: null,
      },
      skip: 0,
      first: 10,
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!stateUnpublished && !stateUnpublished.splits) {
      setInformTime('')
    }
  }, [selectingClinicId])
  useEffect(() => {
    if (stateUnpublished && stateUnpublished.splits) {
      setInformTime(
        `${
          moment(stateUnpublished.endTime)
            .format()
            .split('+')[0]
            .split('T')[0]
        }T00:00:00`
      )
      setSelectingClinicId(stateUnpublished.clinicalDepartment.clinic.id)
    }
  }, [stateUnpublished])

  useEffect(() => {
    if (selectingClinicId && informTime && isCreateAvailableShift) {
      refetch()
    }
  }, [selectingClinicId, informTime])
  useEffect(() => {
    if (!informTime) {
      setInformTimeData('')
    }
    if (data) {
      setInformTimeData(convertClinicalTimeSheet(data, selectingClinicId))
    }
  }, [data])

  const disabledDate = current => {
    const min = moment().subtract(1, 'days')
    const max = moment().add(1, 'years')
    return !(min.isSameOrBefore(current) && max.isAfter(current))
  }

  const availableShiftEditedList =
    recruitmentScheduleShift && recruitmentScheduleShift.node
      ? recruitmentScheduleShift.node.availableShifts.map(item => ({
          value: item.id,
          description: `${moment(item.startTime).format(
            'YYYY年MM月DD日'
          )}　${moment(item.startTime).format('HH:mm')}～${moment(
            item.endTime
          ).format('HH:mm')} ${
            item.workSchedule
              ? '(確定済み)'
              : item.isPublished
              ? '（掲載）'
              : '（未掲載）'
          }`,
          date: moment(item.startTime).format('YYYY-MM-DD'),
          isDisable: !!item.workSchedule,
        }))
      : []
  const typeCreateOptions = [
    {
      label: i18n.t('staff.scheduledShiftGroup.daily'),
      value: TYPE_CREATE.DAILY,
    },
    {
      label: i18n.t('staff.scheduledShiftGroup.weekly'),
      value: TYPE_CREATE.WEEKLY,
    },
    {
      label: i18n.t('staff.scheduledShiftGroup.monthly'),
      value: TYPE_CREATE.MONTHLY,
    },
  ]
  const sampleDataOfCheckbox = [
    { value: 'monday', description: i18n.t('staff.createHourlyWage.monDay') },
    { value: 'tuesday', description: i18n.t('staff.createHourlyWage.tuesDay') },
    {
      value: 'wednesday',
      description: i18n.t('staff.createHourlyWage.wednesDay'),
    },
    {
      value: 'thursday',
      description: i18n.t('staff.createHourlyWage.thursDay'),
    },
    { value: 'friday', description: i18n.t('staff.createHourlyWage.friDay') },
    {
      value: 'saturday',
      description: i18n.t('staff.createHourlyWage.saturDay'),
    },
    { value: 'sunday', description: i18n.t('staff.createHourlyWage.sunDay') },
  ]
  const recruitmentCategories = [
    {
      label: '募集項目なし',
      value: 'NO_RECRUITMENT',
    },
    {
      label: 'MRT',
      value: 'MRT',
    },
    {
      label: '民間医局',
      value: 'PRIVATE_CLINIC',
    },
    {
      label: 'その他 ',
      value: 'OTHER',
    },
  ]

  const recruitmentStatuses = [
    {
      label: '必要なし',
      value: 'NOT_REQUIRED',
    },
    {
      label: '掲載取り下げ',
      value: 'CANCEL_POSTING',
    },
    {
      label: '掲載中',
      value: 'POSTED',
    },
    {
      label: '未',
      value: 'NOT_POSTED_YET',
    },
  ]

  const listObjectTarget = [
    {
      label: i18n.t(
        'main.incentiveSetting.listOptionObjectTarget.setForEachDoctor'
      ),
      value: objectTargetConstant.SET_FOR_EACH_DOCTOR,
    },
    {
      label: i18n.t(
        'main.incentiveSetting.listOptionObjectTarget.setForEachWorkPartten'
      ),
      value: objectTargetConstant.SET_FOR_EACH_WORKPARTTEN,
    },
  ]

  const listWorkPartten = [
    {
      value: 'ALL_WORKPATTTEN',
      description: i18n.t('main.ALL_WORK_PATTERN'),
    },
    {
      value: 'PERMANENT_DOCTOR',
      description: i18n.t('main.PERMANENT_DOCTOR'),
    },
    {
      value: 'REGULAR_SHIFT_DOCTOR',
      description: i18n.t('main.REGULAR_SHIFT_DOCTOR'),
    },
    {
      value: 'IRREGULAR_SHIFT_DOCTOR',
      description: i18n.t('main.IRREGULAR_SHIFT_DOCTOR'),
    },
    {
      value: 'TEMPORARY_DOCTOR',
      description: i18n.t('main.TEMPORARY_DOCTOR'),
    },
  ]

  const findRecruitmentCategory = value =>
    recruitmentCategories.find(it => it.value === value)
  const findRecruitmentStatus = value =>
    recruitmentStatuses.find(it => it.value === value)
  const [activeIndex, setActiveIndex] = useState(0)
  let dataSplits = {}
  if (isDetailAvailableShiftGroup) {
    const dataTime = JSON.parse(node.dataTime)
    if (dataTime) {
      Object.keys(dataTime).forEach(key => {
        dataSplits = {
          ...dataSplits,
          ...splitLogicReverse({
            availableShift: dataTime[key],
            key,
          }),
        }
      })
    }
  }

  if (detailValue) {
    initValue = {
      confirm: false,
      functionName: '',
      confirmationMessage,
      numberOfRooms: node.numberOfRooms > 0 ? node.numberOfRooms : 1,
      comment: detailValue.comment,
      splitComments: [
        {
          text: '',
          type: '',
        },
      ],
      splitRecruitmentCategories: [
        {
          category: '',
          status: '',
          description: '',
        },
      ],
      isHoliday,
      clinicalDepartments,
      clinicalDepartment: detailValue.clinicalDepartment || '',
      startTime: detailValue.startTime || date.toDate(),
      splits: detailValue.splits || [],
      startEndDate: detailValue.startEndDate || [null, null],
      isAllday: detailValue.isAllday || false,
      isMonday: detailValue.isMonday || false,
      isTuesday: detailValue.isTuesday || false,
      isWednesday: detailValue.isWednesday || false,
      isThursday: detailValue.isThursday || false,
      isFriday: detailValue.isFriday || false,
      isSaturday: detailValue.isSaturday || false,
      isSunday: detailValue.isSunday || false,
      isDayoff: detailValue.isDayoff || false,
      isPublished: detailValue.isPublished || false,
      name: detailValue.name || '',
      updateType: detailValue.updateType || '',
      dateInform: detailValue.dateInform || '',
      repeatSetting: detailValue.repeatSetting || '',
      listWeekDay: detailValue.listWeekDay || [],
      listWeekDay1: detailValue.listWeekDay1 || [],
      listWeekDay2: detailValue.listWeekDay2 || [],
      listWeekDay3: detailValue.listWeekDay3 || [],
      listWeekDay4: detailValue.listWeekDay4 || [],
      listWeekDay5: detailValue.listWeekDay5 || [],
      recruitmentCategory:
        detailValue.recruitmentCategory || recruitmentCategories[0],
      recruitmentStatus:
        detailValue.recruitmentStatus || recruitmentStatuses[0],
      otherRecruitmentCategory: detailValue.recruitmentStatus || '',
      isSetIndividually: detailValue.isSetIndividually || false,
      objectTarget: detailValue.isSetIndividually || undefined,
      workParttenSearch: detailValue.workParttenSearch || '',
      textSearchDoctorByName: detailValue.textSearchDoctorByName || '',
      isShowListBox: detailValue.textSearchDoctorByName || false,
      specificDoctors: detailValue.specificDoctors || [],
      workPattern: detailValue.workPattern || [],
      isHolidayRepeat: detailValue.isHolidayRepeat || undefined,
      availableShiftEditedList: detailValue.availableShiftEditedList || [],
      isEarlySpecial: detailValue.isEarlySpecial || false,
      isDoubleRecruitment: detailValue.isDoubleRecruitment || false,
    }
  }
  return (
    <Formik
      enableReinitialize
      initialValues={
        initValue
          ? initValue
          : {
              confirm: false,
              functionName: '',
              confirmationMessage,
              numberOfRooms: node.numberOfRooms > 0 ? node.numberOfRooms : 1,
              comment: node ? node.comment : '',
              splitComments: [
                {
                  text: '',
                  type: '',
                },
              ],
              splitRecruitmentCategories: [
                {
                  category: '',
                  status: '',
                  description: '',
                },
              ],
              isHoliday,
              clinicalDepartments,
              clinicalDepartment:
                isCreateAvailableShift && selectingClinicDepartmentId
                  ? clinicalDepartments.find(
                      ({ id }) => id === selectingClinicDepartmentId
                    )
                  : recruitmentScheduleShift &&
                    recruitmentScheduleShift.node &&
                    firstAvailableShift.availableShifts.length !== 0
                  ? clinicalDepartments.find(
                      ({ id }) =>
                        id ===
                        firstAvailableShift.availableShifts[0]
                          .clinicalDepartment.id
                    )
                  : '',
              startTime:
                recruitmentScheduleShift && recruitmentScheduleShift.node
                  ? recruitmentScheduleShift.node.startTime
                  : date.toDate(),

              splits: isDetailAvailableShiftGroup ? dataSplits : [],
              startEndDate: stateUnpublished.startTime
                ? [stateUnpublished.startTime, stateUnpublished.endTime]
                : rangeDateShow,
              isAllday:
                recruitmentScheduleShift && recruitmentScheduleShift.node
                  ? recruitmentScheduleShift.node.isAllday
                  : false,
              isMonday:
                recruitmentScheduleShift && recruitmentScheduleShift.node
                  ? recruitmentScheduleShift.node.isAvailableOnMonday
                  : false,
              isTuesday:
                recruitmentScheduleShift && recruitmentScheduleShift.node
                  ? recruitmentScheduleShift.node.isAvailableOnTuesday
                  : false,
              isWednesday:
                recruitmentScheduleShift && recruitmentScheduleShift.node
                  ? recruitmentScheduleShift.node.isAvailableOnWednesday
                  : false,
              isThursday:
                recruitmentScheduleShift && recruitmentScheduleShift.node
                  ? recruitmentScheduleShift.node.isAvailableOnThursday
                  : false,
              isFriday:
                recruitmentScheduleShift && recruitmentScheduleShift.node
                  ? recruitmentScheduleShift.node.isAvailableOnFriday
                  : false,
              isSaturday:
                recruitmentScheduleShift && recruitmentScheduleShift.node
                  ? recruitmentScheduleShift.node.isAvailableOnSaturday
                  : false,
              isSunday:
                recruitmentScheduleShift && recruitmentScheduleShift.node
                  ? recruitmentScheduleShift.node.isAvailableOnSunday
                  : false,
              isDayoff:
                recruitmentScheduleShift && recruitmentScheduleShift.node
                  ? recruitmentScheduleShift.node.isAvailableOnHoliday
                  : false,
              isPublished:
                recruitmentScheduleShift && recruitmentScheduleShift.node
                  ? recruitmentScheduleShift.node.isPublished
                  : true,
              name: isCreateAvailableShift
                ? shiftTitle
                : recruitmentScheduleShift.node.name,
              updateType: '',
              dateInform: '',
              repeatSetting:
                recruitmentScheduleShift && recruitmentScheduleShift.node
                  ? recruitmentScheduleShift.node.repeatSetting
                  : '',
              listWeekDay:
                recruitmentScheduleShift &&
                recruitmentScheduleShift.node &&
                recruitmentScheduleShift.node.listWeekDay
                  ? recruitmentScheduleShift.node.listWeekDay
                  : [],
              listWeekDay1:
                recruitmentScheduleShift &&
                recruitmentScheduleShift.node &&
                recruitmentScheduleShift.node.listWeekDay1
                  ? recruitmentScheduleShift.node.listWeekDay1
                  : [],
              listWeekDay2:
                recruitmentScheduleShift &&
                recruitmentScheduleShift.node &&
                recruitmentScheduleShift.node.listWeekDay2
                  ? recruitmentScheduleShift.node.listWeekDay2
                  : [],
              listWeekDay3:
                recruitmentScheduleShift &&
                recruitmentScheduleShift.node &&
                recruitmentScheduleShift.node.listWeekDay3
                  ? recruitmentScheduleShift.node.listWeekDay3
                  : [],
              listWeekDay4:
                recruitmentScheduleShift &&
                recruitmentScheduleShift.node &&
                recruitmentScheduleShift.node.listWeekDay4
                  ? recruitmentScheduleShift.node.listWeekDay4
                  : [],
              listWeekDay5:
                recruitmentScheduleShift &&
                recruitmentScheduleShift.node &&
                recruitmentScheduleShift.node.listWeekDay5
                  ? recruitmentScheduleShift.node.listWeekDay5
                  : [],
              recruitmentCategory: recruitmentScheduleShift
                ? findRecruitmentCategory(
                    recruitmentScheduleShift.node.recruitmentCategory
                  )
                : recruitmentCategories[0],
              recruitmentStatus: recruitmentScheduleShift
                ? findRecruitmentStatus(
                    recruitmentScheduleShift.node.recruitmentStatus
                  )
                : recruitmentStatuses[0],
              otherRecruitmentCategory:
                recruitmentScheduleShift &&
                recruitmentScheduleShift.node.recruitmentCategory
                  ? recruitmentScheduleShift.node.otherRecruitmentCategory
                  : '',
              isSetIndividually: recruitmentScheduleShift
                ? firstAvailableShift.availableShifts[0].isSetIndividually
                : false,
              objectTarget: recruitmentScheduleShift
                ? firstAvailableShift.availableShifts[0].applyWith
                : undefined,
              workParttenSearch: '',
              textSearchDoctorByName: '',
              isShowListBox: false,
              specificDoctors:
                recruitmentScheduleShift &&
                firstAvailableShift.availableShifts[0].applyWith ===
                  objectTargetConstant.SET_FOR_EACH_DOCTOR &&
                firstAvailableShift.availableShifts[0].availableShiftDetails
                  ? convertDoctors(
                      firstAvailableShift.availableShifts[0].availableShiftDetails.map(
                        e => e.doctor
                      )
                    )
                  : [],
              workPattern:
                firstAvailableShift &&
                firstAvailableShift.availableShifts[0].applyWith ===
                  objectTargetConstant.SET_FOR_EACH_WORKPARTTEN &&
                firstAvailableShift.availableShifts[0].workPatternApply
                  ? JSON.parse(
                      firstAvailableShift.availableShifts[0].workPatternApply
                    )
                  : [],
              isHolidayRepeat: recruitmentScheduleShift
                ? recruitmentScheduleShift.node.isHolidayRepeat
                : undefined,
              availableShiftEditedList: [],
              isEarlySpecial:
                recruitmentScheduleShift && recruitmentScheduleShift.node
                  ? recruitmentScheduleShift.node.isEarlySpecial
                  : false,
              isDoubleRecruitment: recruitmentScheduleShift?.node
                ? recruitmentScheduleShift.node.isDoubleRecruitment
                : false,

              count: 1,
              // dateTest: isCreateAvailableShift
              //   ? informTime
              //   : availableShift.startTime || date.toDate(),
            }
      }
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={formikProps => {
        const {
          touched,
          errors,
          setFieldValue,
          setTouched,
          validateForm,
          handleChange,
          handleBlur,
          handleSubmit,
          setSubmitting,
          values,
          dirty,
        } = formikProps
        const holidays1 = thisRangeHolidays({
          holidaysData: pathOr([], ['items'], holidaysData),
          startDate: values.startEndDate[0],
          endDate: values.startEndDate[1],
        })
        const checkIsTime = startTime => {
          const holidays = thisRangeHolidays({
            holidaysData: pathOr([], ['items'], holidaysData),
            startDate: startTime,
            endDate: startTime,
          })
          const date = moment(startTime)
          const dayNo = moment(date).day()

          holidays.length > 0
            ? setFieldValue(
                'splits',
                setTimeSplitByDay('8', 'isAvailableOnHoliday')
              )
            : setFieldValue('splits', setTimeSplitByDay(`${dayNo}`))
        }
        const showSplits = (startTime, endTime) => {
          const holidays = thisRangeHolidays({
            holidaysData: pathOr([], ['items'], holidaysData),
            startDate: startTime,
            endDate: endTime,
          })

          values.splits = isHolidayInRangeDate({
            startDate: startTime,
            endDate: endTime,
          })
          if (holidays.length > 0) {
            setFieldValue('splits', {
              ...values.splits,
              ...setTimeSplitByDay('8', 'isAvailableOnHoliday'),
            })
          }
        }
        const props = { ...formikProps, i18nKey: 'staff.createShift' }
        const disableDateUpdate = current => {
          const min = moment(node.startDate)
          const dateAdd = moment(node.endDate).diff(moment(), 'days')
          const max = moment().add(dateAdd, 'days')
          if (moment().isAfter(node.endDate)) {
            return current && current
          }
          return !(min.isSameOrBefore(current) && current.isSameOrBefore(max))
        }
        return (
          <div>
            <div className='modal-title'>{title}</div>
            {isDetailAvailableShiftGroup && (
              <TabView
                activeIndex={activeIndex}
                onTabChange={e => {
                  setActiveIndex(e.index)
                  if (e.index === 1) {
                    setFieldValue('repeatSetting', node.repeatSetting)
                  }
                }}
              >
                <TabPanel
                  header={i18n.t('staff.scheduledShiftGroup.updateAll')}
                />
                <TabPanel
                  header={i18n.t('staff.scheduledShiftGroup.partialChange')}
                />
              </TabView>
            )}

            <div className='tr'>
              <div className='th'>{i18n.t('staff.createShift.clinic')}</div>
              <div className='td'>
                <AutoComplete
                  {...formikProps}
                  name='clinicalDepartment'
                  field='clinicName'
                  disabled={activeIndex === 1}
                  allSuggestions={clinicalDepartments.filter(
                    department => department.isDisplay
                  )}
                  suggestionName='clinicalDepartments'
                  width='60vw'
                  maxWidth='386px'
                  placeholder={
                    clinicalDepartments.length > 0
                      ? 'クリニックを選択'
                      : '対象データなし'
                  }
                  setCurrentClinicData={val => {
                    if (isCreateAvailableShift || detailValue) {
                      setSelectingClinicId(
                        val && val.clinic ? val.clinic.id : ''
                      )
                      setShiftTitle(val && val.clinicName ? val.clinicName : '')
                    } else {
                      setFieldValue(
                        'clinicId',
                        val && val.clinic ? val.clinic.id : ''
                      )
                      activeIndex !== 1 &&
                        setFieldValue(
                          'name',
                          val && val.clinicName ? val.clinicName : ''
                        )
                    }
                    setSelectingClinicDepartmentId(val ? val.id : '')
                  }}
                />
                <div className='alert'>
                  {touched.clinicalDepartment && errors.clinicalDepartment}
                </div>
              </div>
            </div>
            <div className='tr'>
              <div className='th'>
                {i18n.t('staff.scheduledShiftGroup.shiftGroupName')}
              </div>
              <div className='td'>
                <InputText
                  value={values.name}
                  name='name'
                  onChange={e => {
                    setFieldValue('name', e.target.value)
                  }}
                  disabled={activeIndex === 1}
                />
                <div className='alert'>{touched.name && errors.name}</div>
              </div>
            </div>
            {isCreateAvailableShift ? (
              <div className='tr'>
                <div className='th'>
                  {i18n.t('staff.createShift.timeSheet')}
                </div>
                <div className='td'>
                  <InputTextarea
                    // {...formikProps}
                    disabled
                    name='dateInform'
                    value={
                      informTime && selectingClinicDepartmentId
                        ? informTimeData.toString()
                        : ''
                    }
                    label={i18n.t('staff.createShift.timeSheet')}
                    autoResize
                  />
                  <div className='alert'>
                    {touched.dateInform && errors.dateInform}
                  </div>
                </div>
              </div>
            ) : null}
            <div className='tr'>
              <div className='th'>{i18n.t('staff.createHourlyWage.date')}</div>
              <div className='td'>
                <DatePicker.RangePicker
                  name='startEndDate'
                  value={[
                    values.startEndDate[0]
                      ? moment(
                          new Date(values.startEndDate[0]),
                          'YYYY年MM月DD日'
                        )
                      : null,
                    values.startEndDate[1]
                      ? moment.utc(
                          new Date(values.startEndDate[1]),
                          'YYYY年MM月DD日'
                        )
                      : null,
                  ]}
                  allowClear={false}
                  placeholder={[
                    i18n.t('staff.scheduledShiftGroup.startDate'),
                    i18n.t('staff.scheduledShiftGroup.endDate'),
                  ]}
                  locale={locale}
                  disabledDate={
                    isDetailAvailableShiftGroup && activeIndex === 1
                      ? disableDateUpdate
                      : disabledDate
                  }
                  onChange={value => {
                    setInformTime(
                      `${
                        moment(value[0])
                          .format()
                          .split('+')[0]
                          .split('T')[0]
                      }T00:00:00`
                    )
                    if (
                      scheduledShiftGroupId ||
                      (detailValue && detailValue.scheduledShiftGroupId)
                    ) {
                      setFieldValue('dateInform', informTimeData)
                    }

                    if (values.repeatSetting === TYPE_CREATE.DAILY) {
                      moment(value[0]).isSame(moment(value[1]))
                        ? checkIsTime(moment(value[0]).toISOString())
                        : showSplits(
                            moment(value[0]).toISOString(),
                            moment(value[1]).toISOString()
                          )
                    }

                    setFieldValue('startEndDate', [
                      moment(value[0]).toISOString(),
                      moment(value[1]).toISOString(),
                    ])
                  }}
                  format='YYYY年MM月DD日'
                />
                <div className='alert'>
                  {touched.startEndDate && errors.startEndDate}
                </div>
              </div>
            </div>
            <ShiftGroupFormRepeatSetting
              formikProps={formikProps}
              i18n={i18n}
              typeCreateOptions={typeCreateOptions}
              holidays={holidays1}
              sampleDataOfCheckbox={sampleDataOfCheckbox}
              isCreate
              isRecruitmentSchedule
              disabled={activeIndex === 1}
              activeIndex={activeIndex}
              recruitmentScheduleShift={recruitmentScheduleShift?.node || null}
              dataSplits={dataSplits}
            />
            <ShiftGroupFormSplits
              formikProps={formikProps}
              i18n={i18n}
              isMoneyPermitted={isMoneyPermitted}
              isCreateAvailableShift={isCreateAvailableShift}
              isDetailAvailableShiftGroup={isDetailAvailableShiftGroup}
              popup={popup}
              hourlyWageData={hourlyWageData?.defaultHourlyWageSchedules}
              selectingClinicDepartmentId={selectingClinicDepartmentId}
              informTime={informTime}
              handleDeleteHourlyWage={() => {}}
            />
            <Spinner
              {...props}
              disabled={activeIndex === 1}
              inputWidth='auto'
              name='numberOfRooms'
              label={i18n.t('staff.createShift.numberOfRooms')}
              min={1}
              max={10}
              keyfilter='int'
            />
            <div className='staffpage-comment-container'>
              <div className='staffpage-title'>
                {i18n.t('main.staffComment.titleStaffComment')}
              </div>
              {!isCreateAvailableShift && (
                <WorkScheduleComment
                  comments={staffComments}
                  staffId={staffMe.id}
                  isDeletePermitted={isDeleteCommentPermitted}
                  scheduledAvailableShiftGroupId={recruitmentScheduleId}
                  isAddPermitted={isAddCommentPermitted}
                  isRecruitmentShift
                  dataEnumComment={dataEnumComment}
                  isDeleteAllComment={isDeleteAllComment}
                />
              )}
            </div>
            {isCreateAvailableShift && (
              <ShiftFormStaffComment
                i18n={i18n}
                formikProps={formikProps}
                isShowAdd
              />
            )}

            <div className='tr'>
              <div className='th'>{i18n.t('main.workDetail')}</div>
              <div className='td'>
                <InputTextarea
                  name='comment'
                  autoResize
                  onChange={handleChange}
                  value={values.comment}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div className='tr'>
              <div className='th'>
                {i18n.t('staff.scheduledShiftGroup.tittlePublished')}
              </div>
              <div className='td toggle-container'>
                <Switch
                  name='isPublished'
                  checkedChildren={i18n.t(
                    'staff.scheduledShiftGroup.published'
                  )}
                  unCheckedChildren={i18n.t(
                    'staff.scheduledShiftGroup.notPublished'
                  )}
                  disabled={values.isSetIndividually}
                  checked={values.isPublished}
                  onChange={e => {
                    if (
                      isChangeRecruitmentShiftsStatusPermitted &&
                      isUpdateScheduleAvailableShift
                    ) {
                      setFieldValue('isPublished', e)
                    } else {
                      popup.error(
                        i18n.t(
                          'staff.scheduledShiftGroup.errorMessagePermision'
                        )
                      )
                    }
                  }}
                />
              </div>
            </div>
            <div className='tr'>
              <div className='th'>
                {i18n.t('staff.scheduledShiftGroup.titleClassifyShift')}
              </div>
              <div className='td'>
                {i18n.t('staff.scheduledShiftGroup.classifyScheduleShift')}
              </div>
            </div>
            {isCreateAvailableShift && !initValue && (
              <ShiftFormRecruitmentCategory
                i18n={i18n}
                formikProps={formikProps}
              />
            )}
            {isCreateAvailableShift && initValue && (
              <div className='staffpage-comment-container'>
                <div className='staffpage-title'>募集項目</div>
                <ShiftFormRecruitmentCategoryDetail
                  scheduledShiftGroupId={detailValue.scheduledShiftGroupId}
                  recruitmentCategory={detailValue.recruitmentCategory}
                />
              </div>
            )}
            {!isCreateAvailableShift && (
              <div className='staffpage-comment-container'>
                <div className='staffpage-title'>募集項目</div>
                <ShiftFormRecruitmentCategoryDetail
                  scheduledShiftGroupId={scheduledShiftGroupId}
                  recruitmentCategory={recruitmentCategory}
                />
              </div>
            )}
            <div className='tr'>
              <div className='th'>
                {i18n.t('staff.createShift.setPublicRecruitmentShift')}
              </div>
              <div className='td'>
                <Checkbox
                  onChange={e => {
                    if (isChangeRecruitmentShiftsStatusPermitted) {
                      setFieldValue('isSetIndividually', e.checked)
                      if (e.checked) {
                        setFieldValue(
                          'objectTarget',
                          objectTargetConstant.SET_FOR_EACH_DOCTOR
                        )
                      } else {
                        setFieldValue('objectTarget', undefined)
                      }
                      setFieldValue('isPublished', false)
                    } else {
                      popup.error(
                        i18n.t(
                          'staff.scheduledShiftGroup.errorMessagePermision'
                        )
                      )
                    }
                  }}
                  checked={values.isSetIndividually}
                />
              </div>
            </div>
            {values.isSetIndividually && (
              <ShiftFormDoctorPicker
                {...formikProps}
                i18n={i18n}
                hasPermitted={() => {
                  if (!isChangeRecruitmentShiftsStatusPermitted) {
                    popup.error(
                      i18n.t('staff.scheduledShiftGroup.errorMessagePermision')
                    )
                    return false
                  }
                  return true
                }}
                listObjectTarget={listObjectTarget}
                listWorkPartten={listWorkPartten}
                isDisplay={values.isSetIndividually}
                formikProps={formikProps}
                isCreateAvailableShift={false}
              />
            )}

            {!isCreateAvailableShift && (
              <>
                <div className='tr'>
                  <div className='th'>{i18n.t('main.postUpdateHistory')}</div>
                  <div className='td'>
                    {activitiesHistory &&
                      activitiesHistory.map((it, k) => (
                        <div key={k}>
                          <span>
                            {moment(it.createdAt)
                              .startOf('day')
                              .format('YYYY年MM月DD日')}
                          </span>
                          <span> {moment(it.createdAt).format('HH:mm')} </span>
                          <span>
                            {it.staff.lastname} {it.staff.firstname}
                          </span>
                          <span>{i18n.t(it.activity)}</span>
                        </div>
                      ))}
                  </div>
                </div>
                <div className='tr'>
                  <div className='th'>{i18n.t('main.updateHistory')}</div>
                  <div className='td'>
                    {postActivitiesHistory &&
                      postActivitiesHistory.map((it, k) => (
                        <div key={k}>
                          <span>
                            {moment(it.createdAt)
                              .startOf('day')
                              .format('YYYY年MM月DD日')}
                          </span>
                          <span> {moment(it.createdAt).format('HH:mm')} </span>
                          <span>
                            {it.staff.lastname} {it.staff.firstname}
                          </span>
                          <span>
                            {i18n.t(
                              `staff.activityLogsStaffHistory.${it.activity}`
                            )}
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
              </>
            )}
            {!isCreateAvailableShift && (
              <CheckBoxList
                {...formikProps}
                label={i18n.t(
                  'staff.scheduledShiftGroup.availableShiftEditedList'
                )}
                name='availableShiftEditedList'
                options={availableShiftEditedList}
                locale={locale}
              />
            )}
            <div className='modal-bottom'>
              {isCreateAvailableShift ? (
                <Button
                  onClick={() => {
                    validateForm().then(response => {
                      if (!Object.keys(response).length) {
                        // checkStatus({
                        //   variables: {
                        //     clinicalDepartmentId: isCreateAvailableShift
                        //       ? selectingClinicDepartmentId
                        //       : values.clinicalDepartment.id,
                        //     date: informTime,
                        //     isHoliday,
                        //     startTime: timePick.startTime,
                        //     endTime: timeEnd,
                        //   },
                        // }).then(data => {
                        //   const isValidTimeRange =
                        //     data.data
                        //       .staffCheckAvailableShiftCreateInClinicWorkTime
                        //   if (!isValidTimeRange) {
                        //     setFieldValue(
                        //       'confirmationMessage',
                        //       i18n.t('staff.scheduledShiftGroup.messageCreate')
                        //     )
                        //   } else {
                        //     setFieldValue(
                        //       'confirmationMessage',
                        //       confirmationMessage
                        //     )
                        //   }
                        setFieldValue('confirm', true)
                        // })
                      } else {
                        setTouched(response)
                      }
                    })
                  }}
                  label={submitLabel}
                />
              ) : (
                <div>
                  {isDeleteScheduleAvailableShift && activeIndex === 0 ? (
                    <Button
                      onClick={() => {
                        validateForm().then(response => {
                          if (!Object.keys(response).length) {
                            setFieldValue(
                              'confirmationMessage',
                              i18n.t(
                                'staff.scheduledShiftGroup.messageDeleteScheduleShift'
                              )
                            )
                            setFieldValue('confirm', true)
                            setFieldValue(
                              'updateType',
                              SHCEDULE_SHIFT_ACTION.DELETE_ALL
                            )
                          } else {
                            setTouched(response)
                          }
                        })
                      }}
                      label={i18n.t('staff.scheduledShiftGroup.btnDeleteAll')}
                      icon='pi pi-trash'
                      className='p-button-danger'
                    />
                  ) : null}
                  {isUpdateScheduleAvailableShift ? (
                    <>
                      {activeIndex === 0 ? (
                        <>
                          <Button
                            disabled={!dirty}
                            onClick={() => {
                              validateForm().then(response => {
                                if (!Object.keys(response).length) {
                                  setFieldValue(
                                    'confirmationMessage',
                                    i18n.t(
                                      'staff.scheduledShiftGroup.messageUpdateAllScheduleShift'
                                    )
                                  )
                                  setFieldValue('confirm', true)
                                  setFieldValue(
                                    'updateType',
                                    SHCEDULE_SHIFT_ACTION.UPDATE_ALL
                                  )
                                } else {
                                  setTouched(response)
                                }
                              })
                            }}
                            label={i18n.t(
                              'staff.scheduledShiftGroup.btnUpdateAll'
                            )}
                            icon='pi pi-pencil'
                          />
                          <Button
                            type='submit'
                            label={i18n.t(
                              'staff.scheduledShiftGroup.duplicateSubmit'
                            )}
                            icon='pi pi-pencil'
                            onClick={() => {
                              validateForm().then(response => {
                                if (!Object.keys(response).length) {
                                  setFieldValue('functionName', 'duplicate')
                                  setSubmitting(true)
                                  handleSubmit()
                                } else {
                                  setTouched(response)
                                }
                              })
                            }}
                          />
                        </>
                      ) : (
                        <Button
                          disabled={!dirty}
                          onClick={() => {
                            validateForm().then(response => {
                              if (!Object.keys(response).length) {
                                setFieldValue(
                                  'confirmationMessage',
                                  i18n.t(
                                    'staff.scheduledShiftGroup.messageUpdatePartialScheduleShift'
                                  )
                                )

                                setFieldValue('confirm', true)
                                setFieldValue(
                                  'updateType',
                                  SHCEDULE_SHIFT_ACTION.UPDATE_PARTIAL
                                )
                              } else {
                                setTouched(response)
                              }
                            })
                          }}
                          label={i18n.t(
                            'staff.scheduledShiftGroup.btnUpdateAfterStartDate'
                          )}
                          icon='pi pi-pencil'
                        />
                      )}
                    </>
                  ) : null}
                </div>
              )}
            </div>
            <ConfirmationModal
              {...formikProps}
              confirmationMessage={values.confirmationMessage}
              isCreateAvailableShift={isHideDoubleRecruitmentConfirm}
            />
            {!isCreateAvailableShift ? (
              <div className='mt-15'>
                {activeIndex === 0 && (
                  <>
                    <p className='asterisk-label-head'>
                      {i18n.t('staff.descriptionField.buttonDelete')}
                      <br />
                    </p>
                    <p className='asterisk-label-head'>
                      {i18n.t('staff.descriptionField.buttonUpdateAll')}
                      <br />
                    </p>
                  </>
                )}
                {activeIndex === 1 && (
                  <p className='asterisk-label-head'>
                    {i18n.t('staff.descriptionField.buttonUpdatePartial')}
                    <br />
                  </p>
                )}
              </div>
            ) : null}
          </div>
        )
      }}
    />
  )
}

export default RecruitmentScheduleShiftForm
