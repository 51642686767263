import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import {
  STAFF_CREATE_AS_GROUP,
  STAFF_UPDATE_AS_GROUP,
} from '@medical/constant/permissions'
import {
  checkStaffPermission,
  findNameOrEmail,
  onPageChange,
} from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import moment from 'moment'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'

import { SCHEDULE_AVAILABLESHIFT_CONNECTION } from './AvailableShiftGroupList.graphql'
import AvailableShiftGroupListScene from './AvailableShiftGroupListScene'

const AvailableShiftGroupList = ({ history, location: { search } }) => {
  const {
    page = 1,
    rowsPerPage = 10,
    fullname,
    email,
    name_contains = '',
  } = queryString.parse(search)
  const [orderBy, setOrderBy] = useState('startDate_DESC')
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  const variables = {
    first: first < 0 ? 10 : first,
    skip: skip < 0 ? 0 : skip,
    availableShiftsSkip2: 0,
    availableShiftsFirst2: 1,
    orderBy,
    where: {
      isRecruitmentShift: false,
      deletedAt: null,
      name_contains: name_contains.trim(),
      AvailableShift_some: {
        deletedAt: null,
        WorkSchedule: {
          Doctor: {
            AND: findNameOrEmail({ fullname, email }),
            deletedAt: null,
          },
        },
      },
    },
  }
  const [{ i18n }] = useCustom()
  const isCreateScheduledShiftGroupPermitted = checkStaffPermission({
    functionName: STAFF_CREATE_AS_GROUP,
  })
  const isUpdateScheduledShiftGroupPermitted = checkStaffPermission({
    functionName: STAFF_UPDATE_AS_GROUP,
  })

  const { loading, error, data, refetch } = useQuery(
    SCHEDULE_AVAILABLESHIFT_CONNECTION,
    {
      variables,
      fetchPolicy: 'network-only',
    }
  )
  useEffect(() => {
    refetch()
  })
  const onSortHandle = e => {
    if (e.sortField === 'startDate') {
      setOrderBy(
        orderBy !== 'startDate_ASC' ? 'startDate_ASC' : 'startDate_DESC'
      )
    }
    if (e.sortField === 'createdAt') {
      setOrderBy(
        orderBy !== 'createdAt_ASC' ? 'createdAt_ASC' : 'createdAt_DESC'
      )
    }
    if (e.sortField === 'updatedAt') {
      setOrderBy(
        orderBy !== 'updatedAt_ASC' ? 'updatedAt_ASC' : 'updatedAt_DESC'
      )
    }
  }
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  const {
    scheduledAvailableShiftGroupsConnection: {
      edges,
      aggregate: { count },
    },
  } = data
  const renderAvailableDayInMoth = monthly => {
    const monthlys = JSON.parse(monthly)
    if (Array.isArray(monthlys)) {
      const res = []
      for (let i = 0; i < monthlys.length; i++) {
        const inWeekDetail = renderAvailableDayInWeekly(i + 1, monthlys[i])
        if (inWeekDetail) {
          res.push(inWeekDetail)
        }
      }
      return res.join('、')
    }
  }
  const renderAvailableDayInWeekly = (text, week) => {
    const inWeek = renderAvailableDay(
      week[0],
      week[1],
      week[2],
      week[3],
      week[4],
      week[5],
      week[6],
      false
    )
    if (!inWeek || inWeek === '') {
      return null
    }
    return `第${text} (${inWeek})`
  }
  const renderAvailableDay = (
    isAvailableOnMonday,
    isAvailableOnTuesday,
    isAvailableOnWednesday,
    isAvailableOnThursday,
    isAvailableOnFriday,
    isAvailableOnSaturday,
    isAvailableOnSunday,
    isAvailableOnHoliday
  ) => {
    let availableDay = ''
    if (
      !isAvailableOnMonday ||
      !isAvailableOnTuesday ||
      !isAvailableOnWednesday ||
      !isAvailableOnThursday ||
      !isAvailableOnFriday
    ) {
      if (isAvailableOnMonday) availableDay += '月'
      if (isAvailableOnTuesday) availableDay += '火'
      if (isAvailableOnWednesday) availableDay += '水'
      if (isAvailableOnThursday) availableDay += '木'
      if (isAvailableOnFriday) availableDay += '金'
      if (isAvailableOnSaturday) availableDay += '土'
      if (isAvailableOnSunday) availableDay += '日'
      if (isAvailableOnHoliday) availableDay += '祝'
    } else {
      availableDay = '平日'
      if (isAvailableOnSaturday) availableDay += '土'
      if (isAvailableOnSunday) availableDay += '日'
      if (isAvailableOnHoliday) availableDay += '祝'
    }
    return availableDay
  }
  const fortmatDate = node => {
    const typeTime = node.repeatSetting
    if (typeTime === 'DAILY') {
      return '毎日'
    }
    if (typeTime === 'WEEKLY') {
      return `${'毎週 ' + '('}${renderAvailableDay(
        node.isAvailableOnMonday,
        node.isAvailableOnTuesday,
        node.isAvailableOnWednesday,
        node.isAvailableOnThursday,
        node.isAvailableOnFriday,
        node.isAvailableOnSaturday,
        node.isAvailableOnSunday,
        node.isAvailableOnHoliday
      )})`
    }
    if (typeTime === 'MONTHLY') {
      return renderAvailableDayInMoth(node.dateMatrix)
    }
  }
  return (
    <AvailableShiftGroupListScene
      fortmatDate={fortmatDate}
      i18n={i18n}
      edges={edges}
      count={count}
      page={parseInt(page, 10)}
      rowsPerPage={parseInt(rowsPerPage, 10)}
      fullname={fullname}
      name_contains={name_contains}
      onSortHandle={onSortHandle}
      onPageChange={onPageChange(history, search)}
      isUpdatePermitted={isUpdateScheduledShiftGroupPermitted}
      isCreatePermitted={isCreateScheduledShiftGroupPermitted}
      orderBy={orderBy}
    />
  )
}

export default AvailableShiftGroupList
