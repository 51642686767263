import './doctorFooterStyle.css'

import React from 'react'
import { Link } from 'react-router-dom'
import Router from '@medical/routes/router'
const DoctorFooter = ({ i18n, year, month, isDoctorBlocked }) => {
  return (
    <div className='doctor-layout-footer'>
      <div className='footer-top'>
        <div id='footer-container' className='footer-container'>
          <div className='footer-content-col'>
            <div className='footer-content-header'>
              <label htmlFor='menu-toggle-1'>
                {i18n.t('doctor.footer.findShift')}
                <span className='footer-header-icon'>
                  <i className='pi pi-plus' />
                </span>
              </label>
            </div>
            <input type='checkbox' id='menu-toggle-1' />
            <ul id='footer-header-1'>
              <li>
                <Link
                  to={Router.get(Router.doctorAvailableShifts, {
                    year,
                    month,
                  })}
                >
                  {i18n.t('doctor.footer.avalableShift')}
                </Link>
              </li>
              {isDoctorBlocked ? null : (
                <li>
                  <Link to={Router.get(Router.doctorRecruitments)}>
                    {i18n.t('doctor.footer.recruitment')}
                  </Link>
                </li>
              )}
            </ul>
          </div>
          <div className='footer-content-col'>
            <div className='footer-content-header'>
              <label htmlFor='menu-toggle-2'>
                {i18n.t('doctor.footer.checkWorkSchedule')}
                <span className='footer-header-icon'>
                  <i className='pi pi-plus' />
                </span>
              </label>
            </div>
            <input type='checkbox' id='menu-toggle-2' />
            <ul id='footer-header-2'>
              <li>
                <Link
                  to={Router.get(Router.doctorRequestedWorkSchedulesList, {
                    year,
                    month,
                  })}
                >
                  {i18n.t('doctor.footer.requestedWorkScheduleList')}
                </Link>
              </li>
              <li>
                <Link
                  to={Router.get(Router.doctorAcceptedWorkSchedulesList, {
                    year,
                    month,
                  })}
                >
                  {i18n.t('doctor.footer.acceptedWorkScheduleList')}
                </Link>
              </li>
              <li>
                <Link
                  to={Router.get(Router.doctorWorkReports, {
                    year,
                    month,
                  })}
                >
                  {i18n.t('doctor.footer.workReport')}
                </Link>
              </li>
              <li>
                <Link
                  to={Router.get(Router.doctorTransportReportsWithoutYearMonth)}
                >
                  {i18n.t('doctor.footer.transportationReport')}
                </Link>
              </li>
            </ul>
          </div>
          <div className='footer-content-col'>
            <div className='footer-content-header'>
              <label htmlFor='menu-toggle-3'>
                {i18n.t('doctor.footer.application')}
                <span className='footer-header-icon'>
                  <i className='pi pi-plus' />
                </span>
              </label>
            </div>
            <input type='checkbox' id='menu-toggle-3' />
            <ul id='footer-header-3'>
              <li>
                <Link to={Router.get(Router.doctorWaitingRecruitment)}>
                  {i18n.t('doctor.footer.waitingRecruitment')}
                </Link>
              </li>
              <li>
                <Link to={Router.get(Router.doctorTransportRegister)}>
                  {i18n.t('doctor.footer.transportRegister')}
                </Link>
              </li>
              <li>
                <Link
                  to={Router.get(Router.doctorTransportReports, {
                    year,
                    month,
                  })}
                >
                  {i18n.t('doctor.footer.transportPeriodic')}
                </Link>
              </li>

              <li>
                <Link
                  to={Router.get(
                    Router.doctorAcceptedWorkSchedulesListWithoutYearMonth,
                    {
                      year,
                      month,
                    }
                  )}
                >
                  時間外勤務申請
                </Link>
              </li>
              {/* <li>
                <a 
                  href='https://docs.google.com/forms/d/e/1FAIpQLScbgycZiyKrbL8I5UJ_wa6PK5GBQPTTnxUvozIz-zOCjipnXw/viewform'
                >
                  時間外勤務申請
                </a>
              </li> */}
              <li>
                <Link
                  to={Router.get(
                    Router.doctorAcceptedWorkSchedulesListWithoutYearMonth,
                    {
                      year,
                      month,
                    }
                  )}
                >
                  {i18n.t('doctor.footer.workOutsideApplication')}
                </Link>
              </li>
              <li>
                <Link
                  to={Router.get(Router.doctorWorkOutsideClinic, {
                    year,
                    month,
                  })}
                >
                  {i18n.t('doctor.footer.acceptedWorkSchedule')}
                </Link>
              </li>
              <li>
                <Link
                  to={Router.get(Router.doctorTransportPeriodic, {
                    year,
                    month,
                  })}
                >
                  その他交通費精算
                </Link>
              </li>
            </ul>
          </div>
          <div className='footer-content-col'>
            <div className='footer-content-header'>
              <label htmlFor='menu-toggle-4'>
                {i18n.t('doctor.footer.clinic')}
                <span className='footer-header-icon'>
                  <i className='pi pi-plus' />
                </span>
              </label>
            </div>
            <input type='checkbox' id='menu-toggle-4' />
            <ul id='footer-header-4'>
              <li>
                <Link to={Router.get(Router.doctorClinic)}>
                  {i18n.t('doctor.footer.doctorClinic')}
                </Link>
              </li>
              <li>
                <a
                  href='https://sites.google.com/view/caps365doctors/access'
                  target='blank'
                  rel='noopener noreferrer'
                >
                  {i18n.t('doctor.footer.siteAccess')}
                </a>
              </li>
              <li>
                <Link
                  to={Router.get(Router.doctorNews, {
                    year,
                    month,
                  })}
                >
                  {i18n.t('doctor.footer.news')}
                </Link>
              </li>
              <li>
                <Link
                  to={Router.get(Router.doctorPortal, {
                    year,
                    month,
                  })}
                >
                  {i18n.t('doctor.footer.portal')}
                </Link>
              </li>
            </ul>
          </div>
          <div className='footer-content-col'>
            <div className='footer-content-header'>
              <label htmlFor='menu-toggle-5'>
                {i18n.t('doctor.footer.trouble')}
                <span className='footer-header-icon'>
                  <i className='pi pi-plus' />
                </span>
              </label>
            </div>
            <input type='checkbox' id='menu-toggle-5' />
            <ul id='footer-header-5'>
              <li>
                <Link to={Router.get(Router.doctorQuestions)}>
                  {i18n.t('doctor.footer.question')}
                </Link>
              </li>
              <li>
                <Link to={Router.doctorInquiry}>
                  {i18n.t('doctor.footer.contact')}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className='footer-bottom'>
        <h3 style={{ color: '#F0396D' }}>
          {i18n.t('doctor.footer.companyName')}
        </h3>
        <div style={{ display: 'flex' }}>
          <Link to={Router.get(Router.doctorPrivacy)}>
            {i18n.t('doctor.footer.privacy')}
          </Link>
          <span style={{ margin: '0 2rem' }}></span>
          <Link to={Router.get(Router.doctorTerms)}>
            {i18n.t('doctor.footer.term')}
          </Link>
        </div>
      </div>
    </div>
  )
}
export default DoctorFooter
