import { useMutation } from '@apollo/react-hooks'
import { useCustom } from '@medical/provider/context'
import React from 'react'

import {
  STAFF_CREATE_TRANSPORTATION_TYPE_SETTING,
  TRANSPORTATION_TYPE_SETTINGS_CONNECTION,
} from '../MovingExpenseSetting.graphql'
import MovingExpenseAddSettingScense from './MovingExpenseAddSettingScense'

export const MovingExpensesAddSetting = ({ closeModal }) => {
  const [{ i18n, popup }] = useCustom()
  const [createMovingExpensesSetting] = useMutation(
    STAFF_CREATE_TRANSPORTATION_TYPE_SETTING
  )
  const onSubmit = async ({ type, isApply, maxCost }, { setSubmitting }) => {
    try {
      await createMovingExpensesSetting({
        variables: {
          type,
          isApply,
          maxCost: parseInt(maxCost),
        },
        refetchQueries: [
          {
            query: TRANSPORTATION_TYPE_SETTINGS_CONNECTION,
            variables: {},
          },
        ],
      })
      popup.success(i18n.t('staff.movingExpensesDetail.submissionSuccess'))
      setSubmitting(false)
      closeModal()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }
  return (
    <>
      <MovingExpenseAddSettingScense
        style={{ paddingTOp: 0 }}
        i18n={i18n}
        onSubmit={onSubmit}
      />
    </>
  )
}
