import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { DOCTOR_ME } from '@medical/layout/DoctorLayout/DoctorLayoutRequest.graphql'
import { useCustom } from '@medical/provider/context'
import moment from 'moment'
import React, { useEffect } from 'react'

import { DOCTOR_GET_TRANSPORT_BY_CAR } from './TransportReports.graphql'
import TransportReportsByCarDetailScene from './TransportReportsByCarDetailScene'

const TransportReportsByCarDetail = ({
  match: {
    path,
    params: {
      year = moment()
        .utc()
        .year(),
      month = moment()
        .utc()
        .month() + 1,
    },
  },
}) => {
  const [{ i18n }] = useCustom()
  const { data: dataDoctor } = useQuery(DOCTOR_ME)
  const { data, loading, error, refetch } = useQuery(
    DOCTOR_GET_TRANSPORT_BY_CAR,
    {
      variables: {
        where: {
          id: dataDoctor.doctorMe.id,
        },
        orderBy: 'createdAt_DESC',
        first: 1,
        skip: 0,
      },
    }
  )
  useEffect(() => {
    refetch()
  })
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const distanceClinicData =
    data.doctor.doctorAddressHistories.length > 0
      ? JSON.parse(data.doctor.doctorAddressHistories[0].dataTransportation)
      : []
  const dataResponse = distanceClinicData.map(item => ({
    clinic: item.clinic,
    clinicName: item.clinicName,
    distance: item.distance,
    price: item.price,
    applicableDate: moment(item.applicableDate).format('YYYY年MM月DD日'),
  }))

  const homeAddress =
    (data.homeAddress && data.doctor.homeAddress.address1) ||
    '' + data.doctor.homeAddress.address2 ||
    ''
  return (
    <TransportReportsByCarDetailScene
      i18n={i18n}
      data={data.doctor}
      dataResponse={dataResponse || []}
      homeAddress={homeAddress !== 'null' ? homeAddress : ''}
    />
  )
}
export default TransportReportsByCarDetail
