import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, NotFound, ProgressSpinner } from '@medical/components'
import {
  HOLIDAY_URL,
  objectTargetConstant,
  TYPE_CREATE,
} from '@medical/constant'
import {
  SHOW_MONEY_FIELDS,
  STAFF_ADD_COMMENT,
  STAFF_DELETE_ALL_COMMENT,
  STAFF_DELETE_COMMENT,
  STAFF_DELETE_SCHEDULE_AVAILABLE_SHIFTS,
  STAFF_UPDATE_RECRUITMENT_SHIFTS_STATUS,
  STAFF_UPDATE_SCHEDULE_AVAILABLE_SHIFTS,
} from '@medical/constant/permissions'
import { useAxios } from '@medical/hooks'
import {
  checkStaffPermission,
  clinicalDepartmentNames,
  combineNames,
  sortAndFilterByClinicOrder,
  splitLogic,
  thisRangeHolidays,
  waitFor,
} from '@medical/libs'
import { activityLogHistories } from '@medical/libs/activityLogHistory'
import { arrayNotEqual } from '@medical/libs/arrayNotEqual'
import { CREATE_ACTIVITY } from '@medical/pages/doctor/AvailableShift/AvailableShift.graphql'
import { DOCTORS_DEPARTMENTS } from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import {
  convertBooleanToDay,
  convertDayInMonthToMatrix,
} from '@medical/pages/staff/RecruitmentScheduleShift/CreateRecruitmentScheduleShift/function'
import {
  ACTIVITIES_HISTORY,
  STAFF_DELETE_ALL_SHIFT_SCHEDULE,
  STAFF_UPDATE_SHIFT_SCHEDULE,
} from '@medical/pages/staff/RecruitmentScheduleShift/CreateRecruitmentScheduleShift/RecruitmentScheduleShift.grapql'
import RecruitmentScheduleShiftForm from '@medical/pages/staff/RecruitmentScheduleShift/CreateRecruitmentScheduleShift/RecruitmentScheduleShiftForm'
import {
  FIRST_AVAILABLE_SHIFT_IN_SCHEDULE,
  SCHEDULE_AVAILABLESHIFT_CONNECTION,
} from '@medical/pages/staff/RecruitmentScheduleShift/RecruitmentScheduleShiftList/RecruitmentScheduleShiftList.graphql'
import { useCustom } from '@medical/provider/context'
import moment from 'moment'
import { pathOr } from 'ramda'
import React, { useEffect, useMemo } from 'react'
import Router from '@medical/routes/router'

import { GET_ENUM_COMMENT } from '../../DetailWorkSchedule/WorkScheduleComment.graphql'

const DetailRecruitmentScheduleShift = ({
  history,
  match: {
    params: { year, month, day, recruitmentScheduleid },
  },
  closeModal
}) => {
  const [{ i18n, popup }] = useCustom()
  const postActivity = [
    {
      value: 'CREATE_RECRUITMENT_SHIFTS_GROUP',
      description: '定期募集シフト作成',
    },
    {
      value: 'UPDATE_RECRUITMENT_SHIFTS_GROUP',
      description: '定期募集シフト更新',
    },
  ]

  const activities = [
    {
      value: 'UPDATED_RECRUITMENT_SHIFTS_PUBLISHED',
      description: '掲載',
    },
    {
      value: 'UPDATED_RECRUITMENT_SHIFTS_NOT_PUBLISHED',
      description: '未掲載',
    },
    {
      value: 'UPDATED_RECRUITMENT_SHIFTS_GROUP_PUBLISHED',
      description: '定期募集シフト掲載更新',
    },
    {
      value: 'UPDATED_RECRUITMENT_SHIFTS_GROUP_NOT_PUBLISHED',
      description: '定期募集シフト未掲載更新',
    },
    {
      value: 'UPDATE_RECRUITMENT_SHIFTS_PUBLIC_SPECIFIC_DOCTOR',
      description: '個別に設定',
    },
    {
      value: 'UPDATE_RECRUITMENT_SHIFTS_PUBLIC_WORK_PATTERN',
      description: '勤務形態で設定',
    },
  ]
  const date =
    year && month
      ? moment()
          .startOf('day')
          .year(year)
          .month(month - 1)
          .date(day || 1)
          .hour(9)
      : moment()
          .startOf('day')
          .hour(9)
  const isMoneyPermitted = checkStaffPermission({
    functionName: SHOW_MONEY_FIELDS,
  })
  const isAddCommentPermitted = checkStaffPermission({
    functionName: STAFF_ADD_COMMENT,
  })
  const isDeleteCommentPermitted = checkStaffPermission({
    functionName: STAFF_DELETE_COMMENT,
  })

  const isDeleteAllComment = checkStaffPermission({
    functionName: STAFF_DELETE_ALL_COMMENT,
  })

  const { loading, error, data } = useQuery(DOCTORS_DEPARTMENTS)
  const { loading: acLoading, error: acError, data: acData } = useQuery(
    ACTIVITIES_HISTORY,
    {
      variables: {
        orderBy: 'createdAt_ASC',
        where: {
          scheduledAvailableShiftGroup: recruitmentScheduleid,
        },
      },
      fetchPolicy: 'network-only',
    }
  )
  const variables = {
    where: {
      isRecruitmentShift: true,
      name_contains: '',
      AvailableShift_some: {
        workSchedule: null,
      },
      id: recruitmentScheduleid,
    },
    availableIsEditedIndividually: true,
    availableWorkSchedule: null,
    availableDeletedAt: null,
  }
  const {
    loading: scheduleShiftLoading,
    error: scheduleShiftError,
    data: scheduleShiftData,
    refetch,
  } = useQuery(SCHEDULE_AVAILABLESHIFT_CONNECTION, {
    variables,
  })
  useEffect(() => {
    refetch()
  })
  const { data: firstAvailableShift } = useQuery(
    FIRST_AVAILABLE_SHIFT_IN_SCHEDULE,
    {
      variables: {
        first: 1,
        skip: 0,
        where: {
          ScheduledAvailableShiftGroup: {
            id: recruitmentScheduleid,
          },
        },
      },
    }
  )
  const [staffCreateActivity] = useMutation(CREATE_ACTIVITY)
  const [staffUpdateScheduleShift] = useMutation(STAFF_UPDATE_SHIFT_SCHEDULE)
  const [staffDeleteScheduleShift] = useMutation(
    STAFF_DELETE_ALL_SHIFT_SCHEDULE
  )
  const isChangeRecruitmentShiftsStatusPermitted = checkStaffPermission({
    functionName: STAFF_UPDATE_RECRUITMENT_SHIFTS_STATUS,
  })
  const isUpdateScheduleAvailableShift = checkStaffPermission({
    functionName: STAFF_UPDATE_SCHEDULE_AVAILABLE_SHIFTS,
  })
  const isDeleteScheduleAvailableShift = checkStaffPermission({
    functionName: STAFF_DELETE_SCHEDULE_AVAILABLE_SHIFTS,
  })

  const {
    loading: holidaysLoading,
    data: holidaysData,
    error: holidaysError,
  } = useAxios({
    url: HOLIDAY_URL,
    params: {
      timeMin: moment(date)
        .startOf('month')
        .toISOString(),
      timeMax: moment(date)
        .add(1, 'year')
        .endOf('month')
        .toISOString(),
    },
  })
  const {
    loading: loadingEnumComment,
    error: errorComment,
    data: dataEnumComment,
  } = useQuery(GET_ENUM_COMMENT, {
    variables: {
      name: 'commentTypes',
    },
  })

  const clinicalDepartments = useMemo(() => {
    if (!data) return

    const { clinicalDepartments, departments } = data

    const sortedClinicalDepartments = sortAndFilterByClinicOrder({
      clinicalDepartments,
      // isFilter: true,
    })

    const departmentNames = clinicalDepartmentNames({ departments })

    return combineNames({
      clinicalDepartments: sortedClinicalDepartments,
      departmentNames,
    })
  }, [data])

  const { updateActivityHistories, postActivityHistories } = useMemo(() => {
    if (!acData) {
      return {
        updateActivityHistories: [],
        postActivityHistories: [],
      }
    }
    return activityLogHistories(
      acData.activityHistories,
      activities,
      postActivity
    )
  }, [acData, activities, postActivity])

  const edges = useMemo(() => {
    if (!scheduleShiftData) return
    return scheduleShiftData.scheduledAvailableShiftGroupsConnection.edges[0]
  }, [scheduleShiftData])

  const rangeDateShow = useMemo(() => {
    if (!edges) return
    return [moment.utc(edges.node.startDate), moment.utc(edges.node.endDate)]
  }, [edges])

  if (loading || scheduleShiftLoading || acLoading)
    return <ProgressSpinner />
  if (holidaysLoading || loadingEnumComment) return <ProgressSpinner />
  if (error || holidaysError || scheduleShiftError || acError)
    return (
      <ErrorComponent
        error={error || holidaysError || scheduleShiftError || acError}
      />
    )
  if (errorComment) return <ErrorComponent error={errorComment} />

  if (
    !scheduleShiftData ||
    !scheduleShiftData.scheduledAvailableShiftGroupsConnection ||
    scheduleShiftData.scheduledAvailableShiftGroupsConnection.edges.length === 0
  )
    return <NotFound />
  const { node } = edges

  const hasChangeDoctorPublic = (
    isSetIndividually,
    objectTarget,
    workPattern,
    doctor,
    availableShift
  ) =>
    isSetIndividually !== !!availableShift?.isSetIndividually ||
    availableShift?.applyWith !== objectTarget ||
    (objectTarget === objectTargetConstant.SET_FOR_EACH_WORKPARTTEN &&
      arrayNotEqual(
        JSON.parse(availableShift?.workPatternApply),
        workPattern
      )) ||
    (objectTarget === objectTargetConstant.SET_FOR_EACH_DOCTOR &&
      arrayNotEqual(
        availableShift?.availableShiftDetails.map(e => e.doctor.id),
        doctor.map(e => e.id)
      ))

  const onSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    const {
      functionName,
      clinicalDepartment: { id: clinicalDepartmentId },
      date: submitDate,
      startTime,
      splits,
      name,
      isEarlySpecial,
      isDoubleRecruitment,
      defaultHourlyWageId,
      startEndDate,
      isPublished,
      updateType,
      repeatSetting,
      listWeekDay,
      listWeekDay1,
      listWeekDay2,
      listWeekDay3,
      listWeekDay4,
      numberOfRooms,
      comment,
      isSetIndividually,
      objectTarget,
      workPattern,
      specificDoctors,
      isHolidayRepeat,
      availableShiftEditedList,
    } = values
    const startDateFormat = `${
      moment(startEndDate[0])
        .format()
        .split('T')[0]
        .split('+')[0]
    }T00:00:00Z`
    const endDateFormat = `${
      moment
        .utc(startEndDate[1])
        .format()
        .split('T')[0]
        .split('+')[0]
    }T23:59:59Z`
    const splitComments = []
    values.splitComments.forEach(value => {
      if (value.text !== '' && value.type !== '') {
        splitComments.push({
          text: value.text,
          type: value.type,
          staff: {},
        })
      }
    })

    if (
      node.staffComments.length > 0 &&
      values.updateType === 'UPDATE_PARTIAL'
    ) {
      node.staffComments.forEach(value => {
        if (value.text !== '' && value.type !== '') {
          splitComments.push({
            text: value.text,
            type: value.type,
            staff: {
              connect: {
                id: value.staff.id,
              },
            },
          })
        }
      })
    }

    const splitRecruitmentCategories = []
    if (
      node.recruitmentCategory.length > 0 &&
      values.updateType === 'UPDATE_PARTIAL'
    ) {
      node.recruitmentCategory.forEach(value => {
        if (value.text !== '' && value.type !== '') {
          splitRecruitmentCategories.push({
            category: value.category,
            status: value.status,
            description: value.description,
            staff: {
              connect: {
                id: value.staff.id,
              },
            },
          })
        }
      })
    }

    let dataTime
    Object.keys(splits).forEach(key => {
      dataTime = {
        ...dataTime,
        ...splitLogic({
          submitDate: splits[key][0].startTime,
          splits: splits[key],
          key,
          clinicalDepartmentId,
        }),
      }
    })
    const holidays = thisRangeHolidays({
      holidaysData: pathOr([], ['items'], holidaysData),
      startDate: startEndDate[0],
      endDate: startEndDate[1],
    })
    const isHolidays = []
    holidays.forEach(item => {
      isHolidays.push(item.date)
    })
    // let rangeStartDate = startEndDate[0]
    // let rangeEndDate = startEndDate[1]
    // const startTimeDefault = moment(startTime)
    //   .set('hour', 9)
    //   .set('minute', 0)
    // const endTimeDefault = moment(endTime)
    //   .set('hour', 9)
    //   .set('minute', 0)
    // if (moment(startTime).isBefore(startTimeDefault)) {
    //   rangeStartDate = moment(startEndDate[0]).subtract(1, 'day')
    // }
    // if (moment(endTime).isBefore(endTimeDefault)) {
    //   rangeEndDate = moment(startEndDate[1]).subtract(1, 'day')
    // }
    const listBooleanDayInMonth = convertDayInMonthToMatrix(
      listWeekDay,
      listWeekDay1,
      listWeekDay2,
      listWeekDay3,
      listWeekDay4
    )
    try {
      const action = updateType.split('_')
      await waitFor(2000)
      if (action.includes('UPDATE')) {
        await staffUpdateScheduleShift({
          variables: {
            scheduledAvailableShiftGroupId: recruitmentScheduleid,
            clinicalDepartmentId,
            isHolidays,
            isEarlySpecial,
            isDoubleRecruitment,
            defaultHourlyWageId,
            isPublished,
            updateType,
            repeatSetting,
            comment,
            staffComments: splitComments,
            recruitmentCategory: splitRecruitmentCategories,
            numberOfRooms: parseInt(numberOfRooms),
            dateMatrix: JSON.stringify(listBooleanDayInMonth),
            dataTime,
            data: {
              name,
              startDate: startDateFormat,
              endDate: endDateFormat,
              // startTime: formatSubmitTime(rangeStartDate, startTime).split(
              //   'Z'
              // )[0],
              // endTime: formatSubmitTime(rangeEndDate, endTime).split('Z')[0],
              isAvailableOnMonday:
                repeatSetting === TYPE_CREATE.DAILY ? true : values.isMonday,
              isAvailableOnTuesday:
                repeatSetting === TYPE_CREATE.DAILY ? true : values.isTuesday,
              isAvailableOnWednesday:
                repeatSetting === TYPE_CREATE.DAILY ? true : values.isWednesday,
              isAvailableOnThursday:
                repeatSetting === TYPE_CREATE.DAILY ? true : values.isThursday,
              isAvailableOnFriday:
                repeatSetting === TYPE_CREATE.DAILY ? true : values.isFriday,
              isAvailableOnSaturday:
                repeatSetting === TYPE_CREATE.DAILY ? true : values.isSaturday,
              isAvailableOnSunday:
                repeatSetting === TYPE_CREATE.DAILY ? true : values.isSunday,
              isAvailableOnHoliday:
                repeatSetting === TYPE_CREATE.DAILY ? true : values.isDayoff,
              blockedDays:
                node.blockedDays !== null ? node.blockedDays : undefined,
            },
            isSetIndividually,
            applyWith:
              isSetIndividually && objectTarget ? objectTarget : undefined,
            workPatternApply:
              isSetIndividually &&
              objectTarget === objectTargetConstant.SET_FOR_EACH_WORKPARTTEN
                ? workPattern
                : undefined,
            listDoctorId:
              isSetIndividually &&
              objectTarget === objectTargetConstant.SET_FOR_EACH_DOCTOR
                ? specificDoctors.map(e => e.id)
                : undefined,
            isChangeDoctorPublic: hasChangeDoctorPublic(
              isSetIndividually,
              objectTarget,
              workPattern,
              specificDoctors,
              firstAvailableShift.availableShifts[0]
            ),
            isHolidayRepeat,
            availableShiftEditedList,
          },
        })
      } else if (action.includes('DELETE')) {
        await staffDeleteScheduleShift({
          variables: {
            scheduledAvailableShiftGroupId: recruitmentScheduleid,
          },
        })
        staffCreateActivity({
          variables: {
            activity: 'DELETE_RECRUITMENT_SHIFTS_GROUP',
            details: name,
          },
        })
      }
      resetForm()
      closeModal()
      // setIsUpdateRecruitmentShift(!isUpdateRecruitmentShift)
      functionName == !'duplicate' &&
        popup.success(i18n.t('staff.scheduledShiftGroup.submissionSuccess'))
      if (functionName === 'duplicate') {
        history.push({
          pathname: `${Router.staffRecruitmentScheduleShift +
            Router.staffRecruitmentScheduleShiftCreate}`,
          state: {
            ...values,
            startEndDate: [
              moment(values.startEndDate[0]).format(),
              moment(values.startEndDate[1]).format(),
            ],
            recruitmentCategory: node.recruitmentCategory,
            scheduledShiftGroupId: node.id,
            startDate: node.startDate,
          },
        })
      }
      setSubmitting(false)
    } catch (error) {
      popup.error(error)
      setFieldValue('confirm', false)
      setSubmitting(false)
    }
  }

  const { staffMe } = scheduleShiftData
  if (edges && edges.node) {
    const matrixDate = JSON.parse(edges.node.dateMatrix)
    edges.node.listWeekDay =
      matrixDate && matrixDate[0] ? convertBooleanToDay(matrixDate[0]) : []
    edges.node.listWeekDay1 =
      matrixDate && matrixDate[1] ? convertBooleanToDay(matrixDate[1]) : []
    edges.node.listWeekDay2 =
      matrixDate && matrixDate[2] ? convertBooleanToDay(matrixDate[2]) : []
    edges.node.listWeekDay3 =
      matrixDate && matrixDate[3] ? convertBooleanToDay(matrixDate[3]) : []
    edges.node.listWeekDay4 =
      matrixDate && matrixDate[4] ? convertBooleanToDay(matrixDate[4]) : []
    const {
      isAvailableOnMonday,
      isAvailableOnTuesday,
      isAvailableOnWednesday,
      isAvailableOnThursday,
      isAvailableOnFriday,
    } = edges.node
    if (
      isAvailableOnMonday &&
      isAvailableOnTuesday &&
      isAvailableOnWednesday &&
      isAvailableOnThursday &&
      isAvailableOnFriday
    ) {
      edges.node.isAllday = true
    }
    edges.node.startDate = edges.node.startDate.split('.')[0]

    edges.node.endDate = edges.node.endDate.split('.')[0]
  }
  return (
    <RecruitmentScheduleShiftForm
      i18n={i18n}
      title={i18n.t('staff.scheduledShiftGroup.titleUpdateScheduleShift')}
      submitLabel={i18n.t('staff.scheduledShiftGroup.createSubmit')}
      confirmationMessage={i18n.t(
        'staff.scheduledShiftGroup.createConfirmation'
      )}
      onSubmit={onSubmit}
      recruitmentScheduleShift={edges}
      clinicalDepartments={clinicalDepartments}
      activitiesHistory={updateActivityHistories}
      postActivitiesHistory={postActivityHistories}
      date={date}
      node={node}
      isMoneyPermitted={isMoneyPermitted}
      isDetailAvailableShiftGroup
      isChangeRecruitmentShiftsStatusPermitted={
        isChangeRecruitmentShiftsStatusPermitted
      }
      isUpdateScheduleAvailableShift={isUpdateScheduleAvailableShift}
      isDeleteScheduleAvailableShift={isDeleteScheduleAvailableShift}
      popup={popup}
      isAddCommentPermitted={isAddCommentPermitted}
      isDeleteCommentPermitted={isDeleteCommentPermitted}
      staffMe={staffMe}
      staffComments={node.staffComments}
      recruitmentCategory={node.recruitmentCategory}
      recruitmentScheduleId={recruitmentScheduleid}
      holidaysData={holidaysData}
      dataEnumComment={dataEnumComment}
      rangeDateShow={rangeDateShow}
      scheduledShiftGroupId={recruitmentScheduleid}
      firstAvailableShift={firstAvailableShift}
      isDeleteAllComment={isDeleteAllComment}
    />
  )
}

// const mapStateToProps = state => ({
//   isUpdateRecruitmentShift: state.staffStore.isUpdateRecruitmentShift,
// })
// const mapDispatchToProps = dispatch => ({
//   setIsUpdateRecruitmentShift: data =>
//     dispatch(setIsUpdateRecruitmentShift(data)),
// })
export default DetailRecruitmentScheduleShift
//  connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(DetailRecruitmentScheduleShift)
