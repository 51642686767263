import {
  END_TIME_DEFAULT_AFTERNOON,
  END_TIME_DEFAULT_MORNING,
  FORMAT_DATE_CLINIC_TIME_WORK,
  START_TIME_DEFAULT_AFTERNOON,
  START_TIME_DEFAULT_MORNING,
} from '@medical/constant'
export const getClinicalDepartmentJP = clinic => {
  let val = ''
  switch (clinic) {
    case 'CHILDREN_MEDICINE':
      val = '小児科'
      break
    case 'INTERNAL_MEDICINE':
      val = '内科'
      break
    case 'CHILDREN_VACCINE_MEDICINE':
      val = '小児科ワクチン専任(対象：小児～成人)'
      break
    case 'INTERNAL_VACCINE_MEDICINE':
      val = '内科ワクチン専任(対象：小児～成人)'
      break
    default:
      break
  }
  return val
}
export const adDefaultClinicalDepartmentTime = clinicalDepartments => {
  let listClinicDepartment = []
  for (let i = 0; i < clinicalDepartments.length; i += 1) {
    const {
      startTimeMondayMorning,
      endTimeMondayMorning,
      startTimeMondayAfternoon,
      endTimeMondayAfternoon,
      isAvailableOnMondayMorning,
      isAvailableOnMondayAfternoon,

      startTimeTuesdayMorning,
      endTimeTuesdayMorning,
      startTimeTuesdayAfternoon,
      endTimeTuesdayAfternoon,
      isAvailableOnTuesdayMorning,
      isAvailableOnTuesdayAfternoon,

      startTimeWednesdayMorning,
      endTimeWednesdayMorning,
      startTimeWednesdayAfternoon,
      endTimeWednesdayAfternoon,
      isAvailableOnWednesdayMorning,
      isAvailableOnWednesdayAfternoon,

      startTimeThursdayMorning,
      endTimeThursdayMorning,
      startTimeThursdayAfternoon,
      endTimeThursdayAfternoon,
      isAvailableOnThursdayMorning,
      isAvailableOnThursdayAfternoon,

      startTimeFridayMorning,
      endTimeFridayMorning,
      startTimeFridayAfternoon,
      endTimeFridayAfternoon,
      isAvailableOnFridayMorning,
      isAvailableOnFridayAfternoon,

      startTimeSaturdayMorning,
      endTimeSaturdayMorning,
      startTimeSaturdayAfternoon,
      endTimeSaturdayAfternoon,
      isAvailableOnSaturdayMorning,
      isAvailableOnSaturdayAfternoon,

      startTimeSundayMorning,
      endTimeSundayMorning,
      startTimeSundayAfternoon,
      endTimeSundayAfternoon,
      isAvailableOnSundayMorning,
      isAvailableOnSundayAfternoon,

      startTimeHolidayMorning,
      endTimeHolidayMorning,
      startTimeHolidayAfternoon,
      endTimeHolidayAfternoon,
      isAvailableOnHolidayMorning,
      isAvailableOnHolidayAfternoon,
    } = clinicalDepartments[i]
    clinicalDepartments[i].startTimeMondayMorning = !startTimeMondayMorning
      ? START_TIME_DEFAULT_MORNING
      : startTimeMondayMorning
    clinicalDepartments[i].endTimeMondayMorning = !endTimeMondayMorning
      ? END_TIME_DEFAULT_MORNING
      : endTimeMondayMorning
    clinicalDepartments[i].startTimeMondayAfternoon = !startTimeMondayAfternoon
      ? START_TIME_DEFAULT_AFTERNOON
      : startTimeMondayAfternoon
    clinicalDepartments[i].endTimeMondayAfternoon = !endTimeMondayAfternoon
      ? END_TIME_DEFAULT_AFTERNOON
      : endTimeMondayAfternoon

    clinicalDepartments[i].startTimeTuesdayMorning = !startTimeTuesdayMorning
      ? START_TIME_DEFAULT_MORNING
      : startTimeTuesdayMorning
    clinicalDepartments[i].endTimeTuesdayMorning = !endTimeTuesdayMorning
      ? END_TIME_DEFAULT_MORNING
      : endTimeTuesdayMorning
    clinicalDepartments[
      i
    ].startTimeTuesdayAfternoon = !startTimeTuesdayAfternoon
      ? START_TIME_DEFAULT_AFTERNOON
      : startTimeTuesdayAfternoon
    clinicalDepartments[i].endTimeTuesdayAfternoon = !endTimeTuesdayAfternoon
      ? END_TIME_DEFAULT_AFTERNOON
      : endTimeTuesdayAfternoon

    clinicalDepartments[
      i
    ].startTimeWednesdayMorning = !startTimeWednesdayMorning
      ? START_TIME_DEFAULT_MORNING
      : startTimeWednesdayMorning
    clinicalDepartments[i].endTimeWednesdayMorning = !endTimeWednesdayMorning
      ? END_TIME_DEFAULT_MORNING
      : endTimeWednesdayMorning
    clinicalDepartments[
      i
    ].startTimeWednesdayAfternoon = !startTimeWednesdayAfternoon
      ? START_TIME_DEFAULT_AFTERNOON
      : startTimeWednesdayAfternoon
    clinicalDepartments[
      i
    ].endTimeWednesdayAfternoon = !endTimeWednesdayAfternoon
      ? END_TIME_DEFAULT_AFTERNOON
      : endTimeWednesdayAfternoon

    clinicalDepartments[i].startTimeThursdayMorning = !startTimeThursdayMorning
      ? START_TIME_DEFAULT_MORNING
      : startTimeThursdayMorning
    clinicalDepartments[i].endTimeThursdayMorning = !endTimeThursdayMorning
      ? END_TIME_DEFAULT_MORNING
      : endTimeThursdayMorning
    clinicalDepartments[
      i
    ].startTimeThursdayAfternoon = !startTimeThursdayAfternoon
      ? START_TIME_DEFAULT_AFTERNOON
      : startTimeThursdayAfternoon
    clinicalDepartments[i].endTimeThursdayAfternoon = !endTimeThursdayAfternoon
      ? END_TIME_DEFAULT_AFTERNOON
      : endTimeThursdayAfternoon

    clinicalDepartments[i].startTimeFridayMorning = !startTimeFridayMorning
      ? START_TIME_DEFAULT_MORNING
      : startTimeFridayMorning
    clinicalDepartments[i].endTimeFridayMorning = !endTimeFridayMorning
      ? END_TIME_DEFAULT_MORNING
      : endTimeFridayMorning
    clinicalDepartments[i].startTimeFridayAfternoon = !startTimeFridayAfternoon
      ? START_TIME_DEFAULT_AFTERNOON
      : startTimeFridayAfternoon
    clinicalDepartments[i].endTimeFridayAfternoon = !endTimeFridayAfternoon
      ? END_TIME_DEFAULT_AFTERNOON
      : endTimeFridayAfternoon

    clinicalDepartments[i].startTimeSaturdayMorning = !startTimeSaturdayMorning
      ? START_TIME_DEFAULT_MORNING
      : startTimeSaturdayMorning
    clinicalDepartments[i].endTimeSaturdayMorning = !endTimeSaturdayMorning
      ? END_TIME_DEFAULT_MORNING
      : endTimeSaturdayMorning
    clinicalDepartments[
      i
    ].startTimeSaturdayAfternoon = !startTimeSaturdayAfternoon
      ? START_TIME_DEFAULT_AFTERNOON
      : startTimeSaturdayAfternoon
    clinicalDepartments[i].endTimeSaturdayAfternoon = !endTimeSaturdayAfternoon
      ? END_TIME_DEFAULT_AFTERNOON
      : endTimeSaturdayAfternoon

    clinicalDepartments[i].startTimeSundayMorning = !startTimeSundayMorning
      ? START_TIME_DEFAULT_MORNING
      : startTimeSundayMorning
    clinicalDepartments[i].endTimeSundayMorning = !endTimeSundayMorning
      ? END_TIME_DEFAULT_MORNING
      : endTimeSundayMorning
    clinicalDepartments[i].startTimeSundayAfternoon = !startTimeSundayAfternoon
      ? START_TIME_DEFAULT_AFTERNOON
      : startTimeSundayAfternoon
    clinicalDepartments[i].endTimeSundayAfternoon = !endTimeSundayAfternoon
      ? END_TIME_DEFAULT_AFTERNOON
      : endTimeSundayAfternoon

    clinicalDepartments[i].startTimeHolidayMorning = !startTimeHolidayMorning
      ? START_TIME_DEFAULT_MORNING
      : startTimeHolidayMorning
    clinicalDepartments[i].endTimeHolidayMorning = !endTimeHolidayMorning
      ? END_TIME_DEFAULT_MORNING
      : endTimeHolidayMorning
    clinicalDepartments[
      i
    ].startTimeHolidayAfternoon = !startTimeHolidayAfternoon
      ? START_TIME_DEFAULT_AFTERNOON
      : startTimeHolidayAfternoon
    clinicalDepartments[i].endTimeHolidayAfternoon = !endTimeHolidayAfternoon
      ? END_TIME_DEFAULT_AFTERNOON
      : endTimeHolidayAfternoon

    clinicalDepartments[i].isAvailableOnMondayMorning =
      isAvailableOnMondayMorning === null ? true : isAvailableOnMondayMorning
    clinicalDepartments[i].isAvailableOnMondayAfternoon =
      isAvailableOnMondayAfternoon === null
        ? true
        : isAvailableOnMondayAfternoon

    clinicalDepartments[i].isAvailableOnTuesdayMorning =
      isAvailableOnTuesdayMorning === null ? true : isAvailableOnTuesdayMorning
    clinicalDepartments[i].isAvailableOnTuesdayAfternoon =
      isAvailableOnTuesdayAfternoon === null
        ? true
        : isAvailableOnTuesdayAfternoon

    clinicalDepartments[i].isAvailableOnWednesdayMorning =
      isAvailableOnWednesdayMorning === null
        ? true
        : isAvailableOnWednesdayMorning
    clinicalDepartments[i].isAvailableOnWednesdayAfternoon =
      isAvailableOnWednesdayAfternoon === null
        ? true
        : isAvailableOnWednesdayAfternoon

    clinicalDepartments[i].isAvailableOnThursdayMorning =
      isAvailableOnThursdayMorning === null
        ? true
        : isAvailableOnThursdayMorning
    clinicalDepartments[i].isAvailableOnThursdayAfternoon =
      isAvailableOnThursdayAfternoon === null
        ? true
        : isAvailableOnThursdayAfternoon

    clinicalDepartments[i].isAvailableOnFridayMorning =
      isAvailableOnFridayMorning === null ? true : isAvailableOnFridayMorning
    clinicalDepartments[i].isAvailableOnFridayAfternoon =
      isAvailableOnFridayAfternoon === null
        ? true
        : isAvailableOnFridayAfternoon

    clinicalDepartments[i].isAvailableOnSaturdayMorning =
      isAvailableOnSaturdayMorning === null
        ? true
        : isAvailableOnSaturdayMorning
    clinicalDepartments[i].isAvailableOnSaturdayAfternoon =
      isAvailableOnSaturdayAfternoon === null
        ? true
        : isAvailableOnSaturdayAfternoon

    clinicalDepartments[i].isAvailableOnSundayMorning =
      isAvailableOnSundayMorning === null ? true : isAvailableOnSundayMorning
    clinicalDepartments[i].isAvailableOnSundayAfternoon =
      isAvailableOnSundayAfternoon === null
        ? true
        : isAvailableOnSundayAfternoon

    clinicalDepartments[i].isAvailableOnHolidayMorning =
      isAvailableOnHolidayMorning === null ? true : isAvailableOnHolidayMorning
    clinicalDepartments[i].isAvailableOnHolidayAfternoon =
      isAvailableOnHolidayAfternoon === null
        ? true
        : isAvailableOnHolidayAfternoon

    listClinicDepartment.push(clinicalDepartments[i])
  }
  return listClinicDepartment
}

export const addClinicalDepartmentName = listClinic => {
  const listClinicDepartment = []
  for (let i = 0; i < listClinic.length; i += 1) {
    listClinic[i].clinicName = getClinicalDepartmentJP(listClinic[i].name)
    listClinicDepartment.push(listClinic[i])
  }
  return listClinicDepartment
}

export const convertClinicalTimeSheet = (
  dataClinicTimeSheet,
  selectingClinicId
) => {
  let result = ''
  if (selectingClinicId === '' || !dataClinicTimeSheet) {
    return result
  }
  if (
    dataClinicTimeSheet.clinicTimeSheet &&
    dataClinicTimeSheet.clinicTimeSheet.length === 1 &&
    dataClinicTimeSheet.clinicTimeSheet[0] &&
    dataClinicTimeSheet.clinicTimeSheet[0].isAvailableMorning &&
    dataClinicTimeSheet.clinicTimeSheet[0].isAvailableAfternoon
  ) {
    result = `午前: ${dataClinicTimeSheet.clinicTimeSheet[0].startTimeMorning} - ${dataClinicTimeSheet.clinicTimeSheet[0].endTimeMorning} \n 午後: ${dataClinicTimeSheet.clinicTimeSheet[0].startTimeAfternoon} - ${dataClinicTimeSheet.clinicTimeSheet[0].endTimeAfternoon}
    `
  }
  if (
    dataClinicTimeSheet.clinicTimeSheet &&
    dataClinicTimeSheet.clinicTimeSheet.length === 1 &&
    dataClinicTimeSheet.clinicTimeSheet[0] &&
    dataClinicTimeSheet.clinicTimeSheet[0].isAvailableMorning &&
    !dataClinicTimeSheet.clinicTimeSheet[0].isAvailableAfternoon
  ) {
    result = `午前: ${dataClinicTimeSheet.clinicTimeSheet[0].startTimeMorning} - ${dataClinicTimeSheet.clinicTimeSheet[0].endTimeMorning} \n 午後: 定休`
  }
  if (
    dataClinicTimeSheet.clinicTimeSheet &&
    dataClinicTimeSheet.clinicTimeSheet.length === 1 &&
    dataClinicTimeSheet.clinicTimeSheet[0] &&
    !dataClinicTimeSheet.clinicTimeSheet[0].isAvailableMorning &&
    dataClinicTimeSheet.clinicTimeSheet[0].isAvailableAfternoon
  ) {
    result = `午前: 定休 \n 午後: ${dataClinicTimeSheet.clinicTimeSheet[0].startTimeAfternoon} - ${dataClinicTimeSheet.clinicTimeSheet[0].endTimeAfternoon}`
  }
  if (
    !dataClinicTimeSheet.clinicTimeSheet ||
    !dataClinicTimeSheet.clinicTimeSheet.length === 1 ||
    (dataClinicTimeSheet.clinicTimeSheet[0] &&
      !dataClinicTimeSheet.clinicTimeSheet[0].isAvailableMorning &&
      !dataClinicTimeSheet.clinicTimeSheet[0].isAvailableAfternoon)
  ) {
    result = '定休日'
  }
  return result
}
