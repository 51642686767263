import AutoComplete from '@medical/components/AutoComplete'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import UploadImage from '@medical/components/Forms/UploadImage'
import { DatePicker, Input, InputNumber } from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import { ErrorMessage, Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import React, { useState } from 'react'
import * as Yup from 'yup'

const MovingExpensesRegistrationDetailScence = ({
  i18n,
  fullName,
  onSubmit,
  clinicalDepartments,
  date,
  transportationExpense,
  typeSettingData,
}) => {
  const newTypes = typeSettingData.map(item => ({
    value: item.type && item.type,
    description: item.type,
    maxCost: item.maxCost ? item.maxCost : undefined,
  }))
  const [selectingClinicId, setSelectingClinicId] = useState(
    (transportationExpense &&
      transportationExpense.clinicalDepartment.clinic.id) ||
      ''
  )
  const [
    selectingClinicDepartmentId,
    setSelectingClinicDepartmentId,
  ] = useState(
    (transportationExpense && transportationExpense.clinicalDepartment.id) || ''
  )
  const validationSchema = Yup.object().shape({
    dateSelected: Yup.string()
      .test(
        'checkDate',
        `${i18n.t('staff.movingExpensesDetail.date')}${i18n.t(
          'validation.required'
        )}`,
        value => moment(value).isValid()
      )
      .required(
        `${i18n.t('staff.movingExpensesDetail.date')}${i18n.t(
          'validation.required'
        )}`
      ),
    checkMaxCost: Yup.boolean()
      .test('checkMaxCost', '交通費は上限金額を超えています。', val => !val)
      .when('cost', {
        is: cost => cost === '' || cost === null || cost === undefined,
        then: Yup.boolean().oneOf(
          [true],
          `${i18n.t('transportApplication.cost')}${i18n.t(
            'validation.required'
          )}`
        ),
      }),
    cost: Yup.string()
      .nullable()
      // .test(
      //   'len',
      //   '１日の往復金額は5,000円以内でお願いします',
      //   val => val == '' || parseInt(val) <= 5000
      // )
      .required(
        `${i18n.t('staff.movingExpensesDetail.twoWayFare')}${i18n.t(
          'validation.required'
        )}`
      ),
    clinicalDepartment: Yup.object()
      .nullable()
      .required(`${i18n.t('main.clinicName')}${i18n.t('validation.required')}`),
    type: Yup.string()
      .nullable()
      .required(
        `${i18n.t(
          'staff.movingExpensesOtherDetail.applicationClassification'
        )}${i18n.t('validation.required')}`
      ),
    comment: Yup.string()
      .nullable()
      .max(500, i18n.t('validation.title.max')),
  })
  const disabledDate = current => {
    const minDay = moment(date).startOf('month')
    const maxDay = moment(date).endOf('month')
    return !maxDay.isSameOrAfter(current) || !minDay.isSameOrBefore(current)
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        confirm: false,
        clinicalDepartments,
        clinicId: selectingClinicId || '',
        departmentId: selectingClinicDepartmentId || '',
        clinicalDepartment: selectingClinicDepartmentId
          ? clinicalDepartments.find(
              ({ id }) => id === selectingClinicDepartmentId
            )
          : '',
        cost: transportationExpense?.cost || '',
        dateSelected: transportationExpense?.endTime || date,
        type: transportationExpense?.type || undefined,
        billImage: transportationExpense?.billImage || undefined,
        file: undefined,
        comment: transportationExpense?.comment || undefined,
        checkMaxCost: false,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={formikProps => {
        const confirmationMessage = i18n.t(
          'staff.movingExpensesDetail.createMovingConfirmation'
        )
        const {
          setFieldValue,
          values,
          validateForm,
          setTouched,
          handleChange,
        } = formikProps
        return (
          <div className='container move-expenses-container'>
            <div className='row'>
              <div className='move-expenses-header__title'>
                {i18n.t('staff.movingExpensesDetail.createMovingExpenses')}
              </div>
            </div>
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='move-expenses-item__title'>
                {i18n.t('staff.movingExpensesDetail.doctorName')}
              </div>
              <div>
                <div>{fullName}</div>
                <ErrorMessage
                  name='clinicalclinics'
                  component='div'
                  className='color-red'
                />
              </div>
            </div>
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='move-expenses-item__title'>
                {i18n.t('staff.movingExpensesDetail.today')}
              </div>
              <div>
                <div>{moment().format('LL (dd)')}</div>
                <ErrorMessage
                  name='clinicalclinics'
                  component='div'
                  className='color-red'
                />
              </div>
            </div>
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='move-expenses-item__title required'>
                {i18n.t('staff.movingExpensesDetail.clinicName')}
              </div>
              <div style={{ width: '67%' }}>
                <AutoComplete
                  {...formikProps}
                  name='clinicalDepartment'
                  field='clinicName'
                  allSuggestions={clinicalDepartments}
                  suggestionName='clinicalDepartments'
                  width='91%'
                  maxWidth='386px'
                  paddingBottom='0px'
                  placeholder={
                    clinicalDepartments.length > 0
                      ? 'クリニックを選択'
                      : '対象データなし'
                  }
                  setCurrentClinicData={val => {
                    setSelectingClinicId(val && val.clinic ? val.clinic.id : '')
                    setSelectingClinicDepartmentId(val ? val.id : '')
                  }}
                />
                <ErrorMessage
                  name='clinicalDepartment'
                  component='div'
                  className='color-red'
                />
              </div>
            </div>
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='move-expenses-item__title required'>
                {i18n.t('staff.movingExpensesDetail.date')}
              </div>
              <div>
                <DatePicker
                  name='dateSelected'
                  locale={locale}
                  format='YYYY年MM月DD日'
                  defaultValue={
                    values.dateSelected ? moment(values.dateSelected) : null
                  }
                  style={{ border: '1px solid gray' }}
                  onChange={value => {
                    setFieldValue('dateSelected', moment(value).toString())
                  }}
                  disabledDate={disabledDate}
                />
                <ErrorMessage
                  name='dateSelected'
                  component='div'
                  className='color-red'
                />
              </div>
            </div>
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='move-expenses-item__title required'>
                {i18n.t('staff.movingExpensesDetail.twoWayFare')}
              </div>
              <div style={{ width: '67%' }}>
                <div className='move-expenses-item__input '>
                  <InputNumber
                    placeholder='￥ 300'
                    formatter={value =>
                      `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    type='tel'
                    size='middle'
                    min={1}
                    name='cost'
                    step={5}
                    style={{ width: '35%', minWidth: '151px' }}
                    value={values.cost}
                    onChange={e => {
                      setFieldValue('cost', e)
                      if (values.maxCost < e) {
                        setFieldValue('checkMaxCost', true)
                      } else {
                        setFieldValue('checkMaxCost', false)
                      }
                    }}
                  />
                </div>
                <ErrorMessage
                  name='checkMaxCost'
                  component='div'
                  className='color-red'
                />
              </div>
            </div>
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='move-expenses-item__title '>
                {i18n.t('staff.movingExpensesOtherDetail.receipt')}
              </div>
              <div style={{ width: '67%' }}>
                <div className='move-expenses-item__input'>
                  <UploadImage
                    {...formikProps}
                    imgKey={transportationExpense?.billImage}
                  />
                </div>
              </div>
            </div>
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='move-expenses-item__title required'>
                {i18n.t(
                  'staff.movingExpensesOtherDetail.applicationClassification'
                )}
              </div>
              <div style={{ width: '67%' }}>
                <div className='move-expenses-item__input '>
                  <Dropdown
                    placeholder='申請区分を選択'
                    optionLabel='description'
                    valueLabel='value'
                    options={newTypes}
                    onChange={e => {
                      setFieldValue('type', e.value.value)
                      setFieldValue('maxCost', e.value.maxCost)
                    }}
                    value={newTypes.find(item => item.value === values.type)}
                  />
                </div>
                <ErrorMessage
                  name='type'
                  component='div'
                  className='color-red'
                />
              </div>
            </div>
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='move-expenses-item__title'>
                {i18n.t('staff.movingExpensesOtherDetail.comment')}
              </div>
              <div className='width-67'>
                <Input.TextArea
                  placeholder={i18n.t(
                    'staff.movingExpensesOtherDetail.comment'
                  )}
                  size='large'
                  name='comment'
                  value={values.comment}
                  autoSize={{
                    minRows: 2,
                    maxRows: 3,
                  }}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className='row move-center mt-30'>
              <Button
                label={i18n.t('staff.movingExpensesDetail.btnUpdate')}
                className='reflect-button'
                type='submit'
                onClick={() => {
                  validateForm().then(response => {
                    if (!Object.keys(response).length) {
                      setFieldValue('confirm', true)
                    } else {
                      setTouched(response)
                    }
                  })
                }}
              />
            </div>
            <ConfirmationModal
              {...formikProps}
              confirmationMessage={confirmationMessage}
            />
          </div>
        )
      }}
    />
  )
}

export default MovingExpensesRegistrationDetailScence
