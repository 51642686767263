import { useMutation } from '@apollo/react-hooks'
import LoginComponent from '@medical/components/Forms/LoginComponent'
import { STAFF_EMAIL, STAFF_TOKEN_KEY } from '@medical/constant'
import { waitFor } from '@medical/libs'
import Auth from '@medical/middleware/auth'
import { CREATE_ACTIVITY } from '@medical/pages/doctor/AvailableShift/AvailableShift.graphql'
import { useCustom } from '@medical/provider/context'
import HomeRedirect from '@medical/routes/HomeRedirect'
import Router from '@medical/routes/router'
import React from 'react'
import { Link } from 'react-router-dom'

import { STAFF_LOGIN } from './StaffLogin.graphql'

const LoginContainer = ({ history, location: { state } }) => {
  const [staffLogin] = useMutation(STAFF_LOGIN)
  const [staffCreateActivity] = useMutation(CREATE_ACTIVITY)
  const [{ i18n, popup }, { setToken }] = useCustom()
  const { email: stateEmail } = state || {}

  if (Auth.getToken()) {
    return <HomeRedirect />
  }

  const login = async ({ email, password }, { setSubmitting }) => {
    try {
      const loginResponse = await staffLogin({
        variables: {
          email,
          password,
        },
      })
      const { token } = loginResponse.data.staffLogin
      await waitFor(1000)
      setToken(token)
      localStorage.setItem(STAFF_TOKEN_KEY, token)
      localStorage.setItem(STAFF_EMAIL, email)
      popup.success(i18n.t('staff.login.success'))
      await staffCreateActivity({
        variables: {
          activity: 'LOGGED_STAFF',
        },
      })
      history.push({
        pathname: Router.home,
      })
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }

  return (
    <LoginComponent
      email={stateEmail}
      title={i18n.t('staff.login.title')}
      loginLabel={i18n.t('staff.login.staffLoginLabel')}
      emailLabel={i18n.t('staff.login.emailLabel')}
      passwordLabel={i18n.t('staff.login.passwordLabel')}
      submitLabel={i18n.t('staff.login.submit')}
      login={login}
      bottom={
        <>
          <div className='login-link'>
            <Link to={Router.staffForgotPassword}>
              {i18n.t('staff.login.forgotPassword')}
            </Link>
          </div>
          <div className='login-link'>
            <Link to={Router.doctorLogin}>
              {i18n.t('staff.login.doctorLoginLink')}
            </Link>
          </div>
        </>
      }
    />
  )
}

export default LoginContainer
