import { useApolloClient, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { onPageChange } from '@medical/libs'
import Auth from '@medical/middleware/auth'
import { useCustom } from '@medical/provider/context'
import queryString from 'query-string'
import React, { useContext, useEffect, useState } from 'react'

import {
  ACTIVITY_LOGS_DOCTOR_HISTORY,
  DOCTOR_QUERY,
  DOCTORS_CONNECTION,
} from './ActivityLogsDoctorHistory.graphql'
import ActivityLogsDoctorHistoryScene from './ActivityLogsDoctorHistoryScene'
import {
  removeProgress,
  setProgressBar,
} from '@medical/provider/store/reducers/progress/progress.action'
import { connect } from 'react-redux'
import { SocketContext } from '@medical/provider/socket'
import moment from 'moment'

const ActivityLogsDoctorHistory = ({
  history,
  location: { search },
  progress,
  setProgress,
  removeProgress,
}) => {
  const socket = useContext(SocketContext)
  const [{ i18n, popup }] = useCustom()
  const client = useApolloClient()
  const {
    page = 1,
    rowsPerPage = 20,
    orderBy = 'createdAt_DESC',
    date = '',
  } = queryString.parse(search)
  const startDate = date ? date.split(',')[0] : undefined
  const endDate = date ? date.split(',')[1] : undefined

  const [doctorId, setDoctorId] = useState(null)
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  const variables = {
    first: first < 0 ? 20 : first,
    skip: skip < 0 ? 0 : skip,
    orderBy,
    where: {
      AND: [
        {
          Doctor: {
            id: doctorId || undefined,
            deletedAt: null,
          },
        },
      ],
      activity_in: [
        'LOGGED_DOCTOR',
        'DOCTOR_SEARCHED_FOR_SHIFTS',
        'DOCTOR_DISPLAYED_INFORMATION_FOR_INSURANCE_MEDICAL_TREATMENT_AND_SALARY_PAYMENT',
      ],
      createdAt_gte: startDate ? new Date(startDate) : undefined,
      createdAt_lte: endDate ? new Date(endDate) : undefined,
    },
  }

  const doctorsVar = {
    orderBy: 'unSigned_ASC',
    skip: 0,
    first: 10,
  }

  const { loading, error, data, refetch } = useQuery(
    ACTIVITY_LOGS_DOCTOR_HISTORY,
    {
      variables,
      fetchPolicy: 'network-only',
    }
  )
  const {
    loading: uniqueDoctorLoading,
    error: uniqueDoctorError,
    data: uniqueDoctorData,
  } = useQuery(DOCTOR_QUERY, {
    variables: {
      where: {
        id: doctorId || undefined,
      },
    },
    fetchPolicy: 'network-only',
  })
  const {
    loading: doctorLoading,
    error: doctorError,
    data: doctorData,
  } = useQuery(DOCTORS_CONNECTION, {
    variables: doctorsVar,
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    refetch()
  })

  if (loading || doctorLoading || uniqueDoctorLoading)
    return <ProgressSpinner />
  if (error || doctorError || uniqueDoctorError)
    return <ErrorComponent error={error || doctorError || uniqueDoctorError} />

  const {
    activityLogsConnection: {
      edges,
      aggregate: { count },
    },
  } = data

  const {
    doctorsConnection: { edges: doctors },
  } = doctorData

  const convertDoctors = doctors =>
    doctors.map(doctor => ({
      ...doctor.node,
      fullNameEmail: doctor.node.blocked
        ? `【募集制限中】${doctor.node.fullName} (${doctor.node.email})`
        : `${doctor.node.fullName} (${doctor.node.email})`,
    }))
  const convertSelectedDoctor = doctor =>
    doctor.blocked
      ? `【募集制限中】${doctor.lastname} ${doctor.firstname} (${doctor.email})`
      : `${doctor.lastname} ${doctor.firstname} (${doctor.email})`

  const token = Auth.getToken()
  return (
    <ActivityLogsDoctorHistoryScene
      i18n={i18n}
      socket={socket}
      progress={progress}
      setProgress={progress => setProgress(progress)}
      removeProgress={progress => removeProgress(progress)}
      edges={edges}
      doctors={convertDoctors(doctors)}
      rowsPerPage={parseInt(rowsPerPage, 10)}
      page={page}
      count={count}
      doctorId={doctorId}
      onPageChange={onPageChange(history, search)}
      client={client}
      popup={popup}
      convertDoctors={convertDoctors}
      token={token}
      setDoctorId={setDoctorId}
      date={date?.split(',') || [null, null]}
      selectedDoctor={
        uniqueDoctorData.doctor
          ? convertSelectedDoctor(uniqueDoctorData.doctor)
          : ''
      }
    />
  )
}

const mapStateToProps = state => ({
  progress: state.progressBarStore.progress,
})

const mapDispatchToProps = dispatch => ({
  setProgress: progress => dispatch(setProgressBar(progress)),
  removeProgress: progress => dispatch(removeProgress(progress)),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityLogsDoctorHistory)
