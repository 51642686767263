import ModalLink from '@medical/components/ModalLink'
import Router from '@medical/routes/router'
import { Button } from 'primereact/button'
import React from 'react'

export const ActionItem = ({ item, type = '' }) => {
  if (type === 'availableShifts') {
    if (item.comment)
      return (
        <div className='action-group'>
          <ModalLink
            to={Router.get(Router.doctorSingleAvailableShift, {
              availableShiftId: item.id,
            })}
          >
            <Button icon='pi pi-info' className='p-button-secondary' />
          </ModalLink>
        </div>
      )
    return (
      <div className='action-group'>
        <Button icon='pi pi-info' className='p-button-secondary' disabled />
      </div>
    )
  }
  return (
    <div className='action-group'>
      <ModalLink
        to={Router.get(Router.doctorSingleSchedule, {
          workScheduleId: item.id,
        })}
      >
        <Button icon='pi pi-info' className='p-button-secondary' />
      </ModalLink>
    </div>
  )
}
