import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import {
  clinicalDepartmentNames,
  combineNames,
  sortAndFilterByClinicOrder,
  splitLogic,
} from '@medical/libs'
import { parseJwt } from '@medical/constant/utilities'
import moment from 'moment'
import { useCustom } from '@medical/provider/context'
import React, { useEffect } from 'react'

import {
  CREATE_WORK_OUTSIDE,
  DEPARTMENTS_LIST,
  QUERY_WORK_OUTSIDE,
  DELETE_WORK_OUTSIDE,
} from './WorkOutsideClinic.graphql'
import { WORK_OUTSIDE_CONNECTION } from '../WorkOutsideClinic.graphql'
import WorkOutsideClinicDetailScene from './WorkOutsideClinicDetailScene'

const WorkOutsideClinicDetail = ({
  closeModal,
  match: {
    params: { id },
  },
}) => {
  const [{ i18n, popup }] = useCustom()
  const { doctorId } = parseJwt(localStorage.getItem('medical_doctor_token'))
  const variables = {
    where: {
      Doctor: {
        id: doctorId,
      },
    },
    orderBy: 'createdAt_DESC',
  }
  const mutationOptions = {
    refetchQueries: [
      {
        query: WORK_OUTSIDE_CONNECTION,
        variables,
      },
    ],
    awaitRefetchQueries: true,
  }
  const [doctorUpdateWorkOutside] = useMutation(
    CREATE_WORK_OUTSIDE,
    mutationOptions
  )
  const [doctorDeleteWorkOutside] = useMutation(
    DELETE_WORK_OUTSIDE,
    mutationOptions
  )
  const { loading, error, data } = useQuery(DEPARTMENTS_LIST)
  const {
    loading: detailLoading,
    error: detailError,
    data: detailData,
  } = useQuery(QUERY_WORK_OUTSIDE, {
    variables: {
      where: { id },
    },
  })

  if (loading || detailLoading) return <ProgressSpinner />
  if (error || detailError)
    return <ErrorComponent error={error || detailError} />

  const { clinicalDepartments, departments } = data
  const { workOutside } = detailData
  const sortedClinicalDepartments = sortAndFilterByClinicOrder({
    clinicalDepartments,
    isFilter: true,
  })
  const departmentNames = clinicalDepartmentNames({ departments })

  const clinicalDepartmentsCombineName = combineNames({
    clinicalDepartments: sortedClinicalDepartments,
    departmentNames,
  })

  const clinicalWorkOutside = clinicalDepartmentsCombineName
    .filter(it => it.clinic.isOutside)
    .map(it => ({
      id: it.id,
      name: it.clinicName,
    }))
  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    let popupMsg = ''
    try {
      const {
        endTime,
        splitDateTime1,
        splitDateTime2,
        splitDateTime3,
        isStartTimeBreakTime,
        isSplitDateTime1BreakTime,
        isSplitDateTime2BreakTime,
        isSplitDateTime3BreakTime,
      } = splitLogic({ submitDate: values.date, splits: values.splits })
      if (values.functionName === 'update') {
        await doctorUpdateWorkOutside({
          variables: {
            workOutsideId: workOutside.id,
            clinicalDepartmentId: values.clinicDepartment.id,
            startTime: moment(values.date)
              .hour(moment(values.startTime).hour())
              .minute(moment(values.startTime).minute()),
            endTime,
            splitDateTime1,
            splitDateTime2,
            splitDateTime3,
            isSplitDateTime3BreakTime,
            isSplitDateTime2BreakTime,
            isSplitDateTime1BreakTime,
            isStartTimeBreakTime,
            address: values.address,
            workContent: values.workContent,
          },
        })
        popupMsg = '申請しました'
      } else {
        await doctorDeleteWorkOutside({
          variables: {
            workOutsideId: workOutside.id,
          },
        })
        popupMsg = '削除しました'
      }
      popup.success(popupMsg)
      closeModal()
      resetForm()
      setSubmitting(false)
    } catch (err) {
      popup.error(err)
      setSubmitting(false)
    }
  }
  return (
    <WorkOutsideClinicDetailScene
      i18n={i18n}
      clinicalDepartments={clinicalWorkOutside}
      workOutside={workOutside}
      onSubmit={onSubmit}
    />
  )
}

export default WorkOutsideClinicDetail
