import ConfirmationModal from '@medical/components/ConfirmationModal'
import Editor from '@medical/components/Editor'
import BreadCrumb from '@medical/components/Breadcrumb'
import { Formik } from 'formik'
import { Button } from 'primereact/button'
import CustomDropdown from '@medical/components/DropdownForSignup'
import ModalLink from '@medical/components/ModalLink'
import Router from '@medical/routes/router'
import { InputText } from 'primereact/inputtext'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

const PortalForm = ({
  portal: { largeTitle = '', mediumTitle = '', smallTitle = '', content = '' },
  onSubmit,
  largeCategory,
  mediumCategory,
  updatePortal,
  isCreatePermitted,
  isUpdatePermitted,
  isDeletePermitted,
}) => {
  const largeCategoryOptions = []
  const mediumCategoryOptions = []
  if (largeCategory.length > 0) {
    largeCategory.map(lc => {
      largeCategoryOptions.push({
        value: lc.largeTitle,
        description: lc.largeTitle,
      })
    })
  }
  if (mediumCategory.length > 0) {
    mediumCategory.map(mc => {
      mediumCategoryOptions.push({
        value: mc.mediumTitle,
        description: mc.mediumTitle,
      })
    })
  }
  const { i18n } = useTranslation()
  const validationSchema = Yup.object().shape({
    largeTitle: Yup.string().required('大項目は必要です。'),
    mediumTitle: Yup.string().required('中項目は必要です。'),
    smallTitle: Yup.string()
      .trim()
      .min(2, i18n.t('validation.title.min', { min: 2 }))
      .max(100, i18n.t('validation.title.max', { max: 100 }))
      .required('小項目を入力してください'),
    content: Yup.string()
      .trim()
      .max(100000, i18n.t('validation.content.max', { max: 100000 }))
      .nullable()
      .required('内容を入力してください')
      .test('only-text', '内容を入力してください', value => {
        const div = document.createElement('div')
        div.innerHTML = value
        return div.textContent.trim()
      }),
  })

  return (
    <>
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('staff.menuBar.portalList')}
          <BreadCrumb
            items={
              updatePortal
                ? [
                    {
                      title: i18n.t('staff.menuBar.portalList'),
                      to: Router.staffPortals,
                    },
                    { title: i18n.t('staff.menuBar.portalUpdate') },
                  ]
                : [
                    {
                      title: i18n.t('staff.menuBar.portalList'),
                      to: Router.staffPortals,
                    },
                    { title: i18n.t('staff.menuBar.portalCreate') },
                  ]
            }
          />
        </div>
      </div>
      <div className='container'>
        <div className='box'>
          <Formik
            initialValues={{
              confirm: false,
              function: '',
              largeTitle,
              mediumTitle,
              smallTitle,
              content,
              confirmationMessage: '',
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            render={formikProps => {
              const {
                values,
                touched,
                errors,
                setTouched,
                validateForm,
                handleChange,
                setFieldValue,
              } = formikProps
              return (
                <div>
                  <div className='tr'>
                    <div className='th' style={{ width: '20%' }}>
                      大項目
                    </div>
                    <div className='td'>
                      <CustomDropdown
                        {...formikProps}
                        value={values.largeTitle}
                        name='largeTitle'
                        options={largeCategoryOptions}
                        onChange={handleChange}
                        style={{ width: '100%' }}
                      />
                    </div>
                    <div
                      style={{
                        position: 'relative',
                        paddingRight: '20px',
                        top: '-2px',
                      }}
                    >
                      <ModalLink
                        to={Router.get(Router.staffLargePortalTitleList)}
                      >
                        <Button icon='pi pi-pencil' />
                      </ModalLink>
                    </div>
                  </div>
                  <div className='tr'>
                    <div className='th' style={{ width: '20%' }}>
                      中項目
                    </div>
                    <div className='td'>
                      <CustomDropdown
                        {...formikProps}
                        value={values.mediumTitle}
                        name='mediumTitle'
                        options={mediumCategoryOptions}
                        onChange={handleChange}
                        style={{ width: '100%' }}
                      />
                    </div>
                    <div
                      style={{
                        position: 'relative',
                        paddingRight: '20px',
                        top: '-2px',
                      }}
                    >
                      <ModalLink
                        to={Router.get(Router.staffMediumPortalTitleList)}
                      >
                        <Button icon='pi pi-pencil' />
                      </ModalLink>
                    </div>
                  </div>
                  <div className='tr'>
                    <div className='th' style={{ width: '20%' }}>
                      小項目
                    </div>
                    <div className='td'>
                      <InputText
                        value={values.smallTitle}
                        name='smallTitle'
                        onChange={handleChange}
                        style={{ width: '100%' }}
                      />
                      <div className='alert'>
                        {!!touched.smallTitle && errors.smallTitle}
                      </div>
                    </div>
                  </div>
                  <div className='tr'>
                    <div className='th' style={{ width: '20%' }}>
                      {i18n.t('staff.announces.contentLabel')}
                    </div>
                    <div className='td'>
                      <Editor
                        value={values.content}
                        onTextChange={e =>
                          setFieldValue('content', e.htmlValue)
                        }
                        headerEditor='portal'
                      />
                      <div className='alert'>
                        {!!touched.content && errors.content}
                      </div>
                    </div>
                  </div>

                  <div className='modal-bottom'>
                    {updatePortal && isDeletePermitted && (
                      <Button
                        label='削除する'
                        onClick={() => {
                          setFieldValue('function', 'delete')
                          setFieldValue('confirmationMessage', '削除しますか？')
                          setFieldValue('confirm', true)
                        }}
                        className='cancel-button'
                      />
                    )}
                    {updatePortal && isUpdatePermitted && (
                      <Button
                        label='更新する'
                        onClick={() => {
                          validateForm().then(response => {
                            if (!Object.keys(response).length) {
                              setFieldValue('function', 'update')
                              setFieldValue(
                                'confirmationMessage',
                                '更新しますか？'
                              )
                              setFieldValue('confirm', true)
                            } else {
                              setTouched(response)
                            }
                          })
                        }}
                        className='reflect-button'
                      />
                    )}
                    {!updatePortal && isCreatePermitted && (
                      <Button
                        label='作成する'
                        onClick={() => {
                          validateForm().then(response => {
                            if (!Object.keys(response).length) {
                              setFieldValue(
                                'confirmationMessage',
                                'ポータルを作成しますか？'
                              )
                              setFieldValue('confirm', true)
                            } else {
                              setTouched(response)
                            }
                          })
                        }}
                        className='reflect-button'
                      />
                    )}
                  </div>

                  <ConfirmationModal
                    {...formikProps}
                    confirmationMessage={values.confirmationMessage}
                  />
                </div>
              )
            }}
          />
        </div>
      </div>
    </>
  )
}

export default PortalForm
