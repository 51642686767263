import jaLocale from '@fullcalendar/core/locales/ja'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import { FullCalendar } from 'primereact/fullcalendar'
import { dateValidation, isHolidays } from '@medical/libs'
import Router from '@medical/routes/router'
import { isEdge, isIE } from 'react-device-detect'
import moment from 'moment'
import React, { useState } from 'react'
import './AvailableShiftStyle.css'
import './SearchFormStyle.css'
import { Formik } from 'formik'
import 'fork/react-date-range/dist/styles.css'
import 'fork/react-date-range/dist/theme/default.css'
import { HOURLY_WAGE_LIST } from '@medical/constant'
import { Button } from 'primereact/button'
import { Link } from 'react-router-dom'
import { AvailableShiftHeader } from './AvailableShiftComponents/AvailableShiftHeader'
import AvailableShiftTable from './AvailableShiftComponents/AvailablesShiftTable'
import WorkScheduleTable from './AvailableShiftComponents/WorkScheduleTable'
import MultipleDatePicker from './AvailableShiftComponents/MultipleDatePicker'
import ClinicList from './AvailableShiftComponents/ClinicList'
import SpecifyTime from './AvailableShiftComponents/SpecifyTime'
import HourlyWageList from './AvailableShiftComponents/HourlyWageList'
import BreadCrumb from '@medical/components/Breadcrumb'
import { isMobile } from 'react-device-detect'
import { Tabs } from 'antd'
import WorkContent from './AvailableShiftComponents/WorkContent'
import { useCustom } from '@medical/provider/context'

const AvailableShiftScene = ({
  i18n,
  history,
  date,
  events,
  eventClick,
  calendar,
  selectedDate,
  setSelectedDate,
  holidays,
  lastLoggedIn,
  registeredItem,
  onSubmit,
  activeTab,
  clinics,
  holidaysData,
  listRegionData,
  ...props
}) => {
  const [activeIndex, setActiveIndex] = useState(
    activeTab !== '' ? activeTab.replace('#', '') : 0
  )
  const [{ isDoctorBlocked }] = useCustom()

  const [dateRange, setDateRange] = useState({})
  const [weeks, setWeeks] = useState([])
  const [chosenType, setchosenType] = useState(undefined)
  const prevMonth = moment(date).subtract(1, 'month')
  const nextMonth = moment(date).add(1, 'month')
  const isPreviousDisabled = dateValidation({
    year: prevMonth.year(),
    month: prevMonth.month() + 1,
    day: prevMonth.date(),
  })
  const isNextDisabled =
    nextMonth.isAfter(moment().add(12, 'month')) ||
    dateValidation({
      year: nextMonth.year(),
      month: nextMonth.month() + 1,
      day: nextMonth.date(),
    })
  const isSelectedDateHoliday = isHolidays({ holidays, date: selectedDate })
  const { TabPane } = Tabs
  const handleChangeRange = value => {
    setDateRange(value)
  }
  const handleChosenType = type => {
    setchosenType(type)
  }
  const handleChecked = weeks => {
    setWeeks(weeks)
  }
  const [checkTabDate, setCheckTabDate] = useState(true)
  const callbackTabClicked = (key, e) => {
    if (key === '0') {
      setCheckTabDate(false)
    }
    if (key === '1') {
      setCheckTabDate(true)
    }
  }
  return (
    <div className='available-shift-container container is-max'>
      <div className='content-header'>
        <div className='staff-title'>
          {i18n.t('doctor.menuBar.availableShifts')}
          <BreadCrumb items={[{ title: 'シフトを探す' }]} />
        </div>
      </div>
      <div className='content-available-shifts'>
        <Tabs
          defaultActiveKey={isDoctorBlocked ? '1' : activeIndex}
          type='card'
          size='large'
          onChange={e => {
            setActiveIndex(e)
            if (e === 0) {
              setSelectedDate(null)
            }
            history.push({
              hash: `#${e}`,
            })
          }}
          onTabClick={callbackTabClicked}
        >
          <TabPane
            tab={
              isMobile
                ? i18n.t('doctor.availableShifts.getListByConditionMobile')
                : i18n.t('doctor.availableShifts.getListByCondition')
            }
            key='0'
            disabled={isDoctorBlocked}
          >
            <Formik
              initialValues={{
                dateRange: dateRange,
                weeks: weeks,
                clinics: [],
                startTime: undefined,
                endTime: undefined,
                morningShift: false,
                eveningShift: false,
                nightShift: false,
                isSpecialHourlyWage: false,
                hourlyWage: null,
                hourlyWageList: HOURLY_WAGE_LIST,
                departments: [],
              }}
              onSubmit={onSubmit}
              render={formikProps => {
                const { handleSubmit } = formikProps
                return (
                  <>
                    <h3
                      className='panel-header'
                      style={{ padding: '1rem 1rem 0', margin: '0 0.25rem' }}
                    >
                      {i18n.t('doctor.availableShifts.panelHeader')}
                    </h3>
                    <div className='content-container calendar-container '>
                      <MultipleDatePicker
                        {...formikProps}
                        field='dateRange'
                        locale='ja'
                        ranges={dateRange}
                        handleChangeRange={handleChangeRange}
                        handleChecked={handleChecked}
                        weeks={weeks}
                        months={isMobile ? 1 : 2}
                        minDate={new Date()}
                        maxDate={
                          new Date(
                            moment()
                              .add(12, 'months')
                              .calendar()
                          )
                        }
                        holidaysData={holidaysData}
                      />
                    </div>
                    <div className='content-container clinic-container'>
                      <div className='container-header'>
                        <p>{i18n.t('doctor.availableShifts.clinicHeader')}</p>
                      </div>
                      <div className='content-row'>
                        <ClinicList {...formikProps} clinics={clinics} listRegionData={listRegionData} />
                      </div>
                    </div>
                    <div className='content-container clinic-container'>
                      <div className='container-header'>
                        <p>
                          {i18n.t('doctor.availableShifts.workContentHeader')}
                        </p>
                      </div>
                      <div className='content-row'>
                        <WorkContent {...formikProps} i18n={i18n} />
                      </div>
                    </div>
                    <div className='content-container work-hour-container'>
                      <div className='container-header'>
                        <p>
                          {i18n.t('doctor.availableShifts.workScheduleHeader')}
                        </p>
                      </div>
                      <SpecifyTime
                        {...formikProps}
                        i18n={i18n}
                        chosenType={chosenType}
                        handleChosenType={handleChosenType}
                      />
                    </div>
                    <div className='content-container condition-container'>
                      <div className='container-header'>
                        <p>
                          {i18n.t('doctor.availableShifts.conditionHeader')}
                        </p>
                      </div>
                      <HourlyWageList
                        {...formikProps}
                        i18n={i18n}
                        hourlyWageList={HOURLY_WAGE_LIST}
                        placeholder={i18n.t(
                          'doctor.availableShifts.undefinedHourlyWage'
                        )}
                      />
                    </div>
                    <div
                      className='content-container bottom-container'
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <div className='btn-clear'>
                        <Button
                          style={{
                            width: '130px',
                            marginRight: '10px',
                          }}
                          onClick={() => {
                            history.push({
                              pathname:
                                Router.staffActivityLogsListDoctorHistory,
                            })
                          }}
                          label={i18n.t(
                            'doctor.availableShifts.clearCondition'
                          )}
                        />
                      </div>
                      <div
                        className='btn-search'
                        style={{
                          border: '1px solid var(--primary)',
                          borderRadius: '4px',
                        }}
                      >
                        <Button
                          style={{
                            width: '130px',
                          }}
                          type='submit'
                          onClick={handleSubmit}
                          label={i18n.t('doctor.availableShifts.search')}
                        />
                      </div>
                    </div>
                  </>
                )
              }}
            />
          </TabPane>
          <TabPane tab={i18n.t('doctor.availableShifts.getList')} key='1'>
            <>
              <div className='doctor-container'>
                <div className='doctor-left'>
                  <div className='calendar-container'>
                    <FullCalendar
                      ref={calendar}
                      events={events}
                      locale={jaLocale}
                      options={{
                        locales: [jaLocale],
                        locale: process.env.REACT_APP_LOCALE,
                        plugins: [
                          dayGridPlugin,
                          timeGridPlugin,
                          interactionPlugin,
                        ],
                        defaultView: 'dayGridMonth',
                        timezone: 'Asia/Tokyo',
                        defaultDate: date.format('YYYY-MM-DD'),
                        header: {
                          left: null,
                          center: 'title',
                          right: null,
                        },
                        editable: false,
                        eventClick,
                        eventColor: 'transparent',
                        height: isIE || isEdge ? '1000px' : 'auto',
                        showNonCurrentDates: false,
                        fixedWeekCount: false,
                      }}
                    />
                    <div className='calendar-control'>
                      <Link
                        to={Router.get(
                          Router.doctorAvailableShifts,
                          {
                            year: prevMonth.year(),
                            month: prevMonth.month() + 1,
                          },
                          {
                            activeIndex: activeIndex,
                          }
                        )}
                        onClick={
                          isPreviousDisabled
                            ? e => e.preventDefault()
                            : undefined
                        }
                      >
                        <Button
                          icon='pi pi-chevron-left'
                          style={{ fontSize: '14px' }}
                          disabled={isPreviousDisabled}
                        />
                      </Link>
                      <Link
                        to={Router.get(
                          Router.doctorAvailableShifts,
                          {
                            year: nextMonth.year(),
                            month: nextMonth.month() + 1,
                          },
                          {
                            activeIndex: activeIndex,
                          }
                        )}
                        onClick={
                          isNextDisabled ? e => e.preventDefault() : undefined
                        }
                      >
                        <Button
                          icon='pi pi-chevron-right'
                          style={{ fontSize: '14px' }}
                          disabled={isNextDisabled}
                        />
                      </Link>
                    </div>
                  </div>

                  <div className='tooltip-parent'>
                    <div className='tooltip-icon'>
                      <div className='tool-event event-only-as' />
                    </div>{' '}
                    {i18n.t('doctor.availableShifts.hasAvailableShift')}
                    <div className='tooltip-icon'>
                      <div className='tool-event event-only-not-accepted' />
                    </div>{' '}
                    {i18n.t(
                      'doctor.availableShifts.hasNotAcceptedWorkSchedule'
                    )}
                    <div className='tooltip-icon'>
                      <div className='tool-event event-only-accepted' />
                    </div>{' '}
                    {i18n.t('doctor.availableShifts.hasAcceptedWorkSchedule')}
                  </div>
                </div>
              </div>
            </>
          </TabPane>
        </Tabs>
      </div>

      {checkTabDate === true
        ? selectedDate && (
            <div
              className={
                selectedDate ? 'doctor-content show' : 'doctor-content'
              }
            >
              <AvailableShiftHeader
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                isSelectedDateHoliday={isSelectedDateHoliday}
              />
              <p className='recess-info'>{i18n.t('main.infoRecessTime')}</p>
              <div className='overflow-container'>
                <div className='content-table'>
                  <WorkScheduleTable
                    {...props}
                    i18n={i18n}
                    selectedDate={selectedDate}
                    isSelectedDateHoliday={isSelectedDateHoliday}
                  />
                </div>
                {!isDoctorBlocked ? (
                  <div className='content-table'>
                    <AvailableShiftTable
                      {...props}
                      i18n={i18n}
                      selectedDate={selectedDate}
                      lastLoggedIn={lastLoggedIn}
                      isSelectedDateHoliday={isSelectedDateHoliday}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          )
        : null}
    </div>
  )
}

export default AvailableShiftScene
