import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, NotFound, ProgressSpinner } from '@medical/components'
import { DOCTOR_ME } from '@medical/layout/DoctorLayout/DoctorLayoutRequest.graphql'
import { DEPARTMENTS_LIST } from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import { useCustom } from '@medical/provider/context'
import moment from 'moment'
import React, { useEffect } from 'react'

import {
  DOCTOR_CREATE_TRANSOPRT_EXPENSE,
  DOCTOR_DELETE_TRANSOPRT_EXPENSE,
  DOCTOR_GET_TRANSOPRT_EXPENSE,
} from './TransportRegister.graphql'
import TransportRegisterScene from './TransportRegisterScene'

const TransportRegister = () => {
  const [{ i18n, popup }] = useCustom()
  const remove = 'remove'
  const { loading, error, data } = useQuery(DEPARTMENTS_LIST)
  const { data: dataDoctor } = useQuery(DOCTOR_ME)
// TODO
  const [createTransport] = useMutation(DOCTOR_CREATE_TRANSOPRT_EXPENSE)
  const [deleteTransport] = useMutation(DOCTOR_DELETE_TRANSOPRT_EXPENSE)
  const {
    loading: loadingTransport,
    error: errorTransport,
    data: dataTransport,
    refetch,
  } = useQuery(DOCTOR_GET_TRANSOPRT_EXPENSE, {
    variables: {
      where: {
        status: null,
        deletedAt: null,
        isHidden: false,
        Doctor: {
          id: dataDoctor.doctorMe.id,
          deletedAt: null,
        },
        Clinic_TransportationExpense_clinicToClinic: {
          deletedAt: null,
        },
      },
    },
  })
  useEffect(() => {
    refetch()
  })
  if (loading || loadingTransport) return <ProgressSpinner />
  if (error || errorTransport) return <ErrorComponent error={error} />
  const onSubmit = async (
    { transportationsId, functionName, ...values },
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    const clinics = []
    const transportations = []
    values.splits.map(item => {
      let endTime = item.endTime ? item.endTime : null
      if (item?.isHidden) {
        endTime = moment
          .utc()
          .startOf('month')
          .subtract(1, 'days')
          .endOf('days')
      }
      clinics.push(item.clinic.id)
      transportations.push({
        id: item.id !== '' ? item.id : null,
        url: item.url,
        departurePlace: item.departurePlace,
        arrivalPlace: item.arrivalPlace,
        cost: item.cost,
        startTime: item.startTime,
        endTime,
        isHistory: item.isHistory ? item.isHistory : null,
        isHidden: item.isHidden ? item.isHidden : null,
        acceptedBy: item.acceptedBy ? { connect: { id: 's' } } : null,
        category: 'CLINIC_TRANFFIC_FEE',
        returnPath: item.isCheckBox
          ? {
              set: [
                item.roundTrip.departurePlaceRoundTrip,
                item.roundTrip.arrivalPlaceRoundTrip,
                `${item.roundTrip.costRoundTrip}`,
                item.roundTrip.urlRoundTrip,
              ],
            }
          : {
              set: [],
            },
      })
      return [clinics, transportations]
    })
    let successMessage = i18n.t('transportApplication.submissionSuccess')
    if (functionName === remove) {
      successMessage = i18n.t('main.removeSubmissionSuccess')
    }

    try {
      if (functionName === remove) {
        await deleteTransport({
          variables: { transportationsId, reverse: false },
        })
      } else if (functionName === 'hidden') {
        await createTransport({
          variables: {
            clinics,
            transportations,
          },
        })
      } else {
        if (transportationsId.length > 0) {
          await deleteTransport({
            variables: { transportationsId, reverse: false },
          })
        }
        if (clinics.length > 0 && transportations.length > 0) {
          await createTransport({
            variables: {
              clinics,
              transportations,
            },
          })
        }
      }

      resetForm()
      popup.success(successMessage)
      setSubmitting(false)
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
      setFieldValue('confirm', false)
    }
  }

  const { clinicalDepartments } = data
  const clinicals = []
  clinicalDepartments
    .filter(it => it.clinic.isPublished === true)
    .map(item => {
      clinicals.push(item.clinic)
      return clinicals
    })
  const transportationExpenses = dataTransport.transportationExpenses
    .sort((a, b) => moment(a.startTime) - moment(b.startTime))
    .map(item => ({
      id: item.id,
      clinic: item.clinic,
      url: item.url,
      departurePlace: item.departurePlace,
      arrivalPlace: item.arrivalPlace,
      cost: item.cost || 0,
      endTime: item.endTime ? item.endTime : null,
      isPast: false,
      isHidden: false,
      createdAt: item.createdAt,
      acceptedBy: item.acceptedBy,
      roundTrip:
        item.returnPath.length > 0
          ? {
              urlRoundTrip: item.returnPath[3],
              departurePlaceRoundTrip: item.returnPath[0],
              arrivalPlaceRoundTrip: item.returnPath[1],
              costRoundTrip: item.returnPath[2],
            }
          : null,

      startTime: moment.utc(item.startTime, 'YYYY年MM月DD日'),
      isCheckBox: item.returnPath.length > 0,
    }))
  return (
    <TransportRegisterScene
      i18n={i18n}
      onSubmit={onSubmit}
      clinicals={[...new Set(clinicals)]}
      dataTransport={transportationExpenses || ''}
      remove={remove}
    />
  )
}
export default TransportRegister
