import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import { AVAILABLE_SHIFT_FRAGMENTS } from '@medical/pages/doctor/AvailableShift/AvailableShift.graphql'
import { WORK_SCHEDULE_FRAGMENTS } from '@medical/pages/doctor/Schedule/Schedule.graphql'
import gql from 'graphql-tag'

export const DOCTOR_AVAILABLE_SHIFT = gql`
  query DOCTOR_AVAILABLE_SHIFT($availableShiftId: ID) {
    availableShift(where: { id: $availableShiftId }) {
      ...AVAILABLE_SHIFT_FRAGMENTS
    }
    ...DEPARTMENTS_FRAGMENT
  }
  ${AVAILABLE_SHIFT_FRAGMENTS}
  ${DEPARTMENTS_FRAGMENT}
`

export const ACCEPTED_WORK_SCHEDULES = gql`
  query ACCEPTED_WORK_SCHEDULES(
    $startTime: DateTime
    $endTime: DateTime
    $clinicalDepartmentId: ID
  ) {
    workSchedules(
      where: {
        startTime_gte: $startTime
        startTime_lt: $endTime
        clinicalDepartment: { id: $clinicalDepartmentId }
      }
    ) {
      ...WORK_SCHEDULE_FRAGMENTS
    }
  }
  ${WORK_SCHEDULE_FRAGMENTS}
`
