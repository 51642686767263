import React from 'react'
import { Button } from 'primereact/button'
import BreadCrumb from '@medical/components/Breadcrumb'
import Router from '@medical/routes/router'
import Dropzone from 'react-dropzone'
import { FieldArray, Formik } from 'formik'
import Papa from 'papaparse'
import * as Yup from 'yup'
import moment from 'moment'
import './style.css'
const HourlyWageCsvSence = ({
  onSubmit,
  i18n,
  popup,
  downloadSample,
  isUploadPermitted,
  isUploadCheckPermitted,
  checkCsvData,
  isUploaded,
  downloadClinicalDepartments,
}) => {
  const translateKeys = {
    format: 'のフォーマットは正しくありません',
    validateFormatStartDate: '開始日のフォーマットは正しくありません',
    validateFormatEndDate: '終了日のフォーマットは正しくありません',
    validateType: '区分は新規か既存のどちらか一つを選択してください',
    validateWeekday: '平日の選択が正しくありません',
    atLeastOneItem: '時間設定は1以上設定してください',
    requireClinicDepartment: 'クリニックを選択してください',
    validateRepairTime: '開始時間は終了時間以前に設定してください',
    validateFullDay: ' 曜日は1つ以上選択してください ',
    validateHourlyWage : '時給のフォーマットは正しくありません'
  }
  const validationSchema = Yup.object().shape({
    data: Yup.array()
      .min(1, translateKeys.atLeastOneItem)
      .of(
        Yup.object()
          .shape({
            診療科コード: Yup.string().required(
              translateKeys.requireClinicDepartment
            ),
            設定開始日時: Yup.string()
              .required(
                i18n.t('doctor.waitingRecruitment.validate.startDate.required')
              )
              .test(
                'validateFormatStartDate',
                translateKeys.validateFormatStartDate,
                value => {
                  return moment(value, 'YYYY/M/D', true).isValid()
                }
              ),
            設定終了日時: Yup.string()
              .required(
                i18n.t('doctor.waitingRecruitment.validate.endDate.required')
              )
              .test(
                'validateFormatEndDate',
                translateKeys.validateFormatEndDate,
                value => {
                  return moment(value, 'YYYY/M/D', true).isValid()
                }
              ),
            設定開始時間: Yup.string()
              .required(
                `${i18n.t('main.startTime')} ${i18n.t('validation.required')}`
              )
              .test(
                'validateFormatStartTime',
                `設定開始時間${translateKeys.format} `,
                value => {
                  return moment(value, 'H:mm', true).isValid()
                }
              ),
            設定終了時間: Yup.string()
              .required(
                `${i18n.t('main.endTime')} ${i18n.t('validation.required')}`
              )
              .test(
                'validateFormatEndTime',
                `設定終了時間${translateKeys.format}`,
                value => {
                  return moment(value, 'H:mm', true).isValid()
                }
              ),
            時給: Yup.number()
              .typeError(i18n.t('validation.hourlyWage.required'))
              .required(i18n.t('validation.hourlyWage.required'))
              .test('validateFormatWage', translateKeys.validateHourlyWage, value => {
                if(value <0){
                  return false
                }
                return true
              }),
            '区分（新規)': Yup.boolean().typeError(
              `区分（新規)${translateKeys.format}`
            ),
            '区分（既存)': Yup.boolean().typeError(
              `区分（既存)${translateKeys.format}`
            ),
            月: Yup.boolean().typeError(`月${translateKeys.format}`),
            火: Yup.boolean().typeError(`火${translateKeys.format}`),
            水: Yup.boolean().typeError(`水${translateKeys.format}`),
            木: Yup.boolean().typeError(`木${translateKeys.format}`),
            金: Yup.boolean().typeError(`金${translateKeys.format}`),
            土: Yup.boolean().typeError(`土${translateKeys.format}`),
            日: Yup.boolean().typeError(`日${translateKeys.format}`),
            平日: Yup.boolean().typeError(`平日${translateKeys.format}`),
            祝: Yup.boolean().typeError(`祝${translateKeys.format}`),
            delete: Yup.mixed()
              .nullable()
              .test(
                'isDelete',
                `${i18n.t('validation.isDelete')}`,
                value => value === '1' || !value
              ),
          })
          .test('validateType', translateKeys.validateType, value => {
            if (
              value.月 === undefined ||
              value.月 === '' ||
              value.月 === null ||
              typeof value.日 != 'boolean' ||
              value.火 === '' ||
              value.火 === null ||
              value.火 === undefined ||
              typeof value.火 != 'boolean' ||
              value.水 === '' ||
              value.水 === null ||
              value.水 === undefined ||
              typeof value.水 != 'boolean' ||
              value.木 === '' ||
              value.木 === null ||
              value.木 === undefined ||
              typeof value.木 != 'boolean' ||
              value.金 === '' ||
              value.金 === null ||
              value.金 === undefined ||
              typeof value.金 != 'boolean' ||
              value.土 === '' ||
              value.土 === null ||
              value.土 === undefined ||
              typeof value.土 != 'boolean' ||
              value.日 === '' ||
              value.日 === null ||
              value.日 === undefined ||
              typeof value.日 != 'boolean' ||
              value.平日 === '' ||
              value.平日 === null ||
              value.平日 === undefined ||
              typeof value.平日 != 'boolean' ||
              value.時給 === '' ||
              value.時給 === null ||
              value.時給 === undefined ||
              value['区分（新規)'] === '' ||
              value['区分（新規)'] === null ||
              value['区分（新規)'] === undefined ||
              typeof value['区分（新規)'] !== 'boolean' ||
              value['区分（既存)'] === '' ||
              value['区分（既存)'] === null ||
              value['区分（既存)'] === undefined ||
              typeof value['区分（既存)'] !== 'boolean' ||
              value.時給 === '' ||
              value.時給 === null ||
              value.時給 === undefined ||
              isNaN(value.時給) === true ||
              value.設定終了時間 === '' ||
              value.設定終了時間 === null ||
              value.設定終了時間 === undefined ||
              moment(value.設定終了時間, 'H:mm', true).isValid() === false ||
              value.設定開始時間 === '' ||
              value.設定開始時間 === null ||
              value.設定開始時間 === undefined ||
              moment(value.設定開始時間, 'H:mm', true).isValid() === false ||
              value.設定終了日時 === '' ||
              value.設定終了日時 === null ||
              value.設定終了日時 === undefined ||
              value.設定開始日時 === '' ||
              value.設定開始日時 === null ||
              value.設定開始日時 === undefined ||
              value.診療科コード === '' ||
              value.診療科コード === null ||
              value.診療科コード === undefined ||
              value.祝 === '' ||
              value.祝 === null ||
              value.祝 === undefined ||
              typeof value.祝 != 'boolean'
            ) {
              return true
            } else if (value['区分（新規)'] && value['区分（既存)']) {
              return false
            }
            return true
          })
          .test('validateTime', translateKeys.validateRepairTime, value => {
            if (
              value.月 === undefined ||
              value.月 === '' ||
              value.月 === null ||
              typeof value.日 != 'boolean' ||
              value.火 === '' ||
              value.火 === null ||
              value.火 === undefined ||
              typeof value.火 != 'boolean' ||
              value.水 === '' ||
              value.水 === null ||
              value.水 === undefined ||
              typeof value.水 != 'boolean' ||
              value.木 === '' ||
              value.木 === null ||
              value.木 === undefined ||
              typeof value.木 != 'boolean' ||
              value.金 === '' ||
              value.金 === null ||
              value.金 === undefined ||
              typeof value.金 != 'boolean' ||
              value.土 === '' ||
              value.土 === null ||
              value.土 === undefined ||
              typeof value.土 != 'boolean' ||
              value.日 === '' ||
              value.日 === null ||
              value.日 === undefined ||
              typeof value.日 != 'boolean' ||
              value.平日 === '' ||
              value.平日 === null ||
              value.平日 === undefined ||
              typeof value.平日 != 'boolean' ||
              value.時給 === '' ||
              value.時給 === null ||
              value.時給 === undefined ||
              value['区分（新規)'] === '' ||
              value['区分（新規)'] === null ||
              value['区分（新規)'] === undefined ||
              value['区分（既存)'] === '' ||
              value['区分（既存)'] === null ||
              value['区分（新規)'] === undefined ||
              value.時給 === '' ||
              value.時給 === null ||
              value.時給 === undefined ||
              isNaN(value.時給) === true ||
              value.設定終了時間 === '' ||
              value.設定終了時間 === null ||
              value.設定終了時間 === undefined ||
              moment(value.設定終了時間, 'H:mm', true).isValid() === false ||
              value.設定開始時間 === '' ||
              value.設定開始時間 === null ||
              value.設定開始時間 === undefined ||
              moment(value.設定開始時間, 'H:mm', true).isValid() === false ||
              value.設定終了日時 === '' ||
              value.設定終了日時 === null ||
              value.設定終了日時 === undefined ||
              value.設定開始日時 === '' ||
              value.設定開始日時 === null ||
              value.設定開始日時 === undefined ||
              value.診療科コード === '' ||
              value.診療科コード === null ||
              value.診療科コード === undefined ||
              value.祝 === '' ||
              value.祝 === null ||
              value.祝 === undefined ||
              typeof value.祝 != 'boolean'
            ) {
              return true
            } else if (value && value.設定終了時間 && value.設定開始時間) {
              if (
                parseInt(value.設定開始時間.split(':')[0]) >=
                parseInt(value.設定終了時間.split(':')[0])
              ) {
                return false
              } else {
                return true
              }
            }
          })
          .test(
            'validateDate',
            i18n.t('doctor.waitingRecruitment.validate.endDate.min'),
            value => {
              if (
                value.月 === undefined ||
                value.月 === '' ||
                value.月 === null ||
                typeof value.日 != 'boolean' ||
                value.火 === '' ||
                value.火 === null ||
                value.火 === undefined ||
                typeof value.火 != 'boolean' ||
                value.水 === '' ||
                value.水 === null ||
                value.水 === undefined ||
                typeof value.水 != 'boolean' ||
                value.木 === '' ||
                value.木 === null ||
                value.木 === undefined ||
                typeof value.木 != 'boolean' ||
                value.金 === '' ||
                value.金 === null ||
                value.金 === undefined ||
                typeof value.金 != 'boolean' ||
                value.土 === '' ||
                value.土 === null ||
                value.土 === undefined ||
                typeof value.土 != 'boolean' ||
                value.日 === '' ||
                value.日 === null ||
                value.日 === undefined ||
                typeof value.日 != 'boolean' ||
                value.平日 === '' ||
                value.平日 === null ||
                value.平日 === undefined ||
                typeof value.平日 != 'boolean' ||
                value.時給 === '' ||
                value.時給 === null ||
                value.時給 === undefined ||
                value['区分（新規)'] === '' ||
                value['区分（新規)'] === null ||
                value['区分（新規)'] === undefined ||
                value['区分（既存)'] === '' ||
                value['区分（既存)'] === null ||
                value['区分（新規)'] === undefined ||
                value.時給 === '' ||
                value.時給 === null ||
                value.時給 === undefined ||
                isNaN(value.時給) === true ||
                value.設定終了時間 === '' ||
                value.設定終了時間 === null ||
                value.設定終了時間 === undefined ||
                moment(value.設定終了時間, 'H:mm', true).isValid() === false ||
                value.設定開始時間 === '' ||
                value.設定開始時間 === null ||
                value.設定開始時間 === undefined ||
                moment(value.設定開始時間, 'H:mm', true).isValid() === false ||
                value.設定終了日時 === '' ||
                value.設定終了日時 === null ||
                value.設定終了日時 === undefined ||
                value.設定開始日時 === '' ||
                value.設定開始日時 === null ||
                value.設定開始日時 === undefined ||
                value.診療科コード === '' ||
                value.診療科コード === null ||
                value.診療科コード === undefined ||
                value.祝 === '' ||
                value.祝 === null ||
                value.祝 === undefined ||
                typeof value.祝 != 'boolean'
              ) {
                return true
              } else if (value.設定開始日時 && value.設定終了日時) {
                if (
                  Date.parse(value.設定開始日時) >=
                  Date.parse(value.設定終了日時)
                )
                  return false
              }
              return true
            }
          )
          .test('validateFullDay', translateKeys.validateFullDay, value => {
            if (
              value.月 === undefined ||
              value.月 === '' ||
              value.月 === null ||
              typeof value.日 != 'boolean' ||
              value.火 === '' ||
              value.火 === null ||
              value.火 === undefined ||
              typeof value.火 != 'boolean' ||
              value.水 === '' ||
              value.水 === null ||
              value.水 === undefined ||
              typeof value.水 != 'boolean' ||
              value.木 === '' ||
              value.木 === null ||
              value.木 === undefined ||
              typeof value.木 != 'boolean' ||
              value.金 === '' ||
              value.金 === null ||
              value.金 === undefined ||
              typeof value.金 != 'boolean' ||
              value.土 === '' ||
              value.土 === null ||
              value.土 === undefined ||
              typeof value.土 != 'boolean' ||
              value.日 === '' ||
              value.日 === null ||
              value.日 === undefined ||
              typeof value.日 != 'boolean' ||
              value.平日 === '' ||
              value.平日 === null ||
              value.平日 === undefined ||
              typeof value.平日 != 'boolean' ||
              value.時給 === '' ||
              value.時給 === null ||
              value.時給 === undefined ||
              value['区分（新規)'] === '' ||
              value['区分（新規)'] === null ||
              value['区分（新規)'] === undefined ||
              value['区分（既存)'] === '' ||
              value['区分（既存)'] === null ||
              value['区分（新規)'] === undefined ||
              value.時給 === '' ||
              value.時給 === null ||
              value.時給 === undefined ||
              isNaN(value.時給) === true ||
              value.設定終了時間 === '' ||
              value.設定終了時間 === null ||
              value.設定終了時間 === undefined ||
              moment(value.設定終了時間, 'H:mm', true).isValid() === false ||
              value.設定開始時間 === '' ||
              value.設定開始時間 === null ||
              value.設定開始時間 === undefined ||
              moment(value.設定開始時間, 'H:mm', true).isValid() === false ||
              value.設定終了日時 === '' ||
              value.設定終了日時 === null ||
              value.設定終了日時 === undefined ||
              value.設定開始日時 === '' ||
              value.設定開始日時 === null ||
              value.設定開始日時 === undefined ||
              value.診療科コード === '' ||
              value.診療科コード === null ||
              value.診療科コード === undefined ||
              value.祝 === '' ||
              value.祝 === null ||
              value.祝 === undefined ||
              typeof value.祝 != 'boolean'
            ) {
              return true
            }
            else if (
              !value.平日 &&
              !value.月 &&
              !value.火 &&
              !value.水 &&
              !value.木 &&
              !value.金 &&
              !value.土 &&
              !value.日 &&
              !value.祝
            ) {
              return false
            }
            return true
          })
      ),
  })

  const exceptKeys = ['checked', 'errorMessage']
  return (
    <Formik
      initialValues={{
        data: [],
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={formikProps => {
        const {
          values,
          errors,
          setFieldValue,
          handleSubmit,
          touched,
        } = formikProps
        const { data } = values
        let keys = []
        if (data.length > 0) {
          keys = Object.keys(data[0]).filter(
            key => key !== 'errorMessage' || key !== 'checked'
          )
        }
        const titles = {
          true: i18n.t('staff.menuBar.titleAvailableShiftCSV'),
          false: i18n.t('staff.menuBar.titleWorkSchedule'),
        }
        return (
          <>
            <div className='staff-header'>
              <div className='staff-title'>
                <span>新規作成（csvアップロード）</span>
                <BreadCrumb
                  items={[
                    {
                      to: Router.get(Router.staffHourlyWages),
                      title: '募集シフト時給設定管理',
                    },
                    { title: '募集シフト時給設定CSVアップロード' },
                  ]}
                />
              </div>
              <div className='staff-buttons'>
                <Button
                  icon='pi pi-download'
                  className='p-button-normal'
                  onClick={downloadClinicalDepartments}
                  label={i18n.t('main.clinicalDepartment')}
                />
                <Button
                  icon='pi pi-download'
                  className='p-button-normal'
                  label={i18n.t('main.sampleCsv')}
                  onClick={downloadSample}
                />
              </div>
            </div>
            {isUploadPermitted || isUploadCheckPermitted ? (
              <div className='container'>
                <div className='box'>
                  <div className='box-top'>
                    <div
                      style={{
                        position: 'relative',
                        display: 'flex',
                        flex: 1,
                        alignItems: 'center',
                      }}
                    >
                      <div className='alert is-left'>
                        {typeof errors.data === 'string' &&
                          touched.data &&
                          errors.data}
                      </div>
                    </div>
                    {values.data.length > 0 && (
                      <Button
                        onClick={() => {
                          setFieldValue('data', [])
                        }}
                        label={i18n.t('main.cancel')}
                        className='p-button-secondary'
                        icon='pi pi-times'
                        style={{ marginRight: '20px' }}
                      />
                    )}
                    {isUploadCheckPermitted ? (
                      <Button
                        onClick={() => {
                          if (
                            (errors.data && errors.data.length > 0) ||
                            values.data.find(d => d.errorMessage)
                          ) {
                            popup.error(i18n.t('main.hasCsvFileError'))
                          } else {
                            checkCsvData(values, { setFieldValue })
                          }
                        }}
                        label={i18n.t('staff.csvUpload.check')}
                        type='submit'
                        disabled={data.length === 0 || isUploaded}
                        style={{ marginRight: '20px' }}
                      />
                    ) : null}
                    {isUploadPermitted ? (
                      <Button
                        onClick={() => {
                          if (
                            (errors.data && errors.data.length > 0) ||
                            values.data.find(d => d.errorMessage)
                          ) {
                            popup.error(i18n.t('main.hasCsvFileError'))
                          } else {
                            handleSubmit()
                          }
                        }}
                        label={i18n.t('staff.csvUpload.submit')}
                        type='submit'
                        disabled={data.length === 0 || isUploaded}
                      />
                    ) : null}
                  </div>
                  {values.data.length < 1 && (
                    <Dropzone
                      onDrop={files => {
                        if (files.length > 0) {
                          Papa.parse(files[0], {
                            header: true,
                            complete: async results => {
                              setFieldValue('data', results.data)
                            },
                          })
                        }
                      }}
                      accept='.csv'
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: 'dropzone' })}>
                          <input {...getInputProps()} />
                          <p>{i18n.t('doctor.uploadFile.dropFileHere')}</p>
                        </div>
                      )}
                    </Dropzone>
                  )}

                  {data.length > 0 && (
                    <div className='overflow-container'>
                      <table className='table'>
                        <thead>
                          <tr>
                            <th>{i18n.t('main.action')}</th>

                            {keys.map(
                              (key, index) =>
                                !exceptKeys.includes(key) && (
                                  <th key={`${key}_${index}`}>{key}</th>
                                )
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          <FieldArray
                            name='data'
                            render={({ remove }) => (
                              <React.Fragment>
                                {data.map((item, i) => {
                                  const error =
                                    errors.data && errors.data.length > 0
                                      ? errors.data[i]
                                      : false
                                  const isChecked = data[i].checked
                                  const errorMessage =
                                    item.errorMessage ||
                                    Object.keys(error || {}).reduce(
                                      (acc, curr) => `${acc}\n${error[curr]}`,
                                      ''
                                    )
                                  return (
                                    <React.Fragment>
                                      <tr key={`${i}`}>
                                        {!errorMessage ? (
                                          <td>
                                            {isChecked ? (
                                              <Button
                                                icon='pi pi-check'
                                                className='p-button-success'
                                              />
                                            ) : (
                                              <Button
                                                icon='pi pi-info'
                                                tooltip={i18n.t(
                                                  'staff.csvUpload.notChecked'
                                                )}
                                              />
                                            )}
                                          </td>
                                        ) : (
                                          <td>
                                            <div>
                                              <Button
                                                onClick={() => remove(i)}
                                                icon='pi pi-times'
                                                className='p-button-danger'
                                              />
                                            </div>
                                          </td>
                                        )}
                                        {keys.map(
                                          key =>
                                            !exceptKeys.includes(key) && (
                                              <td>{item[key]}</td>
                                            )
                                        )}
                                      </tr>
                                      {errorMessage && (
                                        <tr key={`errorMessage_${i}`}>
                                          <td colSpan={keys.length + 1}>
                                            <div className='alert is-left'>
                                              {errorMessage}
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                    </React.Fragment>
                                  )
                                })}
                              </React.Fragment>
                            )}
                          />
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            ) : null}
          </>
        )
      }}
    />
  )
}
export default HourlyWageCsvSence
