import ConfirmationModal from '@medical/components/ConfirmationModal'
import { Input, InputNumber, Switch } from 'antd'
import { ErrorMessage, Formik } from 'formik'
import { Button } from 'primereact/button'
import React from 'react'
import * as Yup from 'yup'

const MovingExpenseAddSettingScense = ({ onSubmit, i18n }) => {
  const initialValues = {
    type: '',
    isApply: true,
    maxCost: 0,
    confirm: false,
  }
  const validationSchema = Yup.object().shape({
    type: Yup.string().required('申請区分名を入力してください。'),
    maxCost: Yup.number()
      .nullable()
      .min(1, '上限金額を入力してください。')
      .required('上限金額を入力してください。'),
  })
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        render={formikProps => {
          const {
            values,
            setTouched,
            validateForm,
            setFieldValue,
          } = formikProps
          return (
            <div className='container move-expenses-container'>
              <div
                className='move-expenses-header__title'
                style={{ textAlign: 'center' }}
              >
                <span>
                  {i18n.t(`main.movingExpensesSetting.settingApllyAdditional`)}
                </span>
              </div>
              <div className='tr'>
                <div className='th' style={{ width: '30%' }}>
                  {i18n.t(`main.movingExpensesSetting.settingChooseType`)}
                </div>
                <div className='td'>
                  <Input
                    {...formikProps}
                    value={values.type}
                    name='type'
                    placeholder='申請区分入力'
                    onChange={e => {
                      setFieldValue(`type`, e.target.value)
                    }}
                    style={{ width: '80%' }}
                  />
                  <ErrorMessage
                    name='type'
                    component='div'
                    className='signup-alert'
                  />
                </div>
              </div>
              <div className='tr'>
                <div className='th' style={{ width: '30%' }}>
                  {i18n.t(`main.movingExpensesSetting.settingMaxCost`)}
                </div>
                <div className='td'>
                  <InputNumber
                    value={values.maxCost}
                    name='maxCost'
                    // formatter={value =>
                    //   new Intl.NumberFormat('ja-JP', {
                    //     style: 'currency',
                    //     currency: 'JPY',
                    //   }).format(value)
                    // }
                    va
                    min={0}
                    step={10}
                    style={{ width: '80%' }}
                    onChange={e => {
                      setFieldValue('maxCost', e)
                    }}
                  />
                  <span style={{ marginLeft: 5 }}>円</span>
                  <ErrorMessage
                    name='maxCost'
                    component='div'
                    className='signup-alert'
                  />
                </div>
              </div>
              <div className='tr'>
                <div
                  className='th'
                  style={{ width: '30%', whiteSpace: 'normal' }}
                >
                  {i18n.t(`main.movingExpensesSetting.settingIsApply`)}
                </div>
                <div className='td'>
                  <div
                    className='td toggle-published'
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      width: '80%',
                    }}
                  >
                    <Switch
                      checkedChildren='表示'
                      unCheckedChildren='非表示'
                      name='isApply'
                      defaultChecked
                      onChange={e => {
                        setFieldValue('isApply', e)
                      }}
                    />
                  </div>
                </div>
              </div>
              <div style={{ textAlign: 'center' }}>
                <Button
                  label='登録'
                  className='reflect-button btn'
                  type='submit'
                  onClick={() => {
                    validateForm().then(response => {
                      if (!Object.keys(response).length) {
                        setFieldValue('confirm', true)
                      } else {
                        setTouched(response)
                      }
                    })
                  }}
                />
              </div>
              <ConfirmationModal
                {...formikProps}
                confirmationMessage={i18n.t(
                  'main.movingExpensesSetting.confirmCreate'
                )}
              />
            </div>
          )
        }}
      />
    </>
  )
}

export default MovingExpenseAddSettingScense
