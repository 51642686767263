import axios from 'axios'

const uploadImage = async (i18n, popup, postPreSignedUrl, file) => {
  let key
  if (postPreSignedUrl.createPresignedPostUrl) {
    try {
      const dataResponse = JSON.parse(postPreSignedUrl.createPresignedPostUrl)
      const { url, fields } = dataResponse.fulfillmentValue
      const formData = new FormData()
      Object.keys(fields).forEach(key => {
        formData.append(key, fields[key])
      })
      formData.append('file', file)
      await axios({
        method: 'POST',
        url,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      key = fields.key
    } catch (e) {
      popup.error(i18n.t('doctor.uploadFile.submissionError'))
    }
  }
  return key
}

export default uploadImage
