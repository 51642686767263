import AcceptedWorkScheduleDetail from '@medical/pages/doctor/AcceptedWorkSchedulesList/AcceptedWorkScheduleDetail/AcceptedWorkScheduleDetail'
import AcceptedWorkScheduleOverTime from '@medical/pages/doctor/AcceptedWorkSchedulesList/AcceptedWorkScheduleOverTime/AcceptedWorkScheduleOverTime'
import AcceptedWorkSchedulesList from '@medical/pages/doctor/AcceptedWorkSchedulesList/AcceptedWorkSchedulesList'
import AvailableShift from '@medical/pages/doctor/AvailableShift/AvailableShift'
import BankAccount from '@medical/pages/doctor/BankAccount/BankAccount'
import ChangePassword from '@medical/pages/doctor/ChangePassword/ChangePassword'
import { CreateWaitingRecruitment } from '@medical/pages/doctor/CreateWaitingRecruitment/CreateWaitingRecruitment'
import { ConfirmEmail } from '@medical/pages/doctor/ConfirmEmail/ConfirmEmail'
import DoctorCart from '@medical/pages/doctor/DoctorCart/DoctorCart'
import DoctorEmailConfirm from '@medical/pages/doctor/DoctorEmailConfirmation/DoctorEmailConfirmation'
import DoctorForgotPassword from '@medical/pages/doctor/DoctorForgotPassword/DoctorForgotPassword'
import DoctorHome from '@medical/pages/doctor/DoctorHome/DoctorHome'
import CreateMyNumber from '@medical/pages/doctor/DoctorIdentity/DoctorIdentity'
import DoctorMyPage from '@medical/pages/doctor/DoctorMyPage/DoctorMyPage'
import DoctorPortal from '@medical/pages/doctor/DoctorPortal/DoctorPortal'
import DoctorResetPassword from '@medical/pages/doctor/DoctorResetPassword/DoctorResetPassword'
import DoctorSignup from '@medical/pages/doctor/DoctorSignup/DoctorSignup'
import DoctorSignupSuccess from '@medical/pages/doctor/DoctorSignupSuccess'
import { DoctorWaitingRecruitment } from '@medical/pages/doctor/DoctorWaitingRecruitment/DoctorWaitingRecruitment'
import Inquiry from '@medical/pages/doctor/Inquiry/Inquiry'
import DoctorLogin from '@medical/pages/doctor/Login/DoctorLogin'
import News from '@medical/pages/doctor/News/News'
import Privacy from '@medical/pages/doctor/Privacy/Privacy'
import Questions from '@medical/pages/doctor/Questions/Questions'
import RecruitmentPostApply from '@medical/pages/doctor/RecruitmentPost/RecruitmentPostApply/RecruitmentPostApply'
import RecruitmentPostDetail from '@medical/pages/doctor/RecruitmentPost/RecruitmentPostDetail/RecruitmentPostDetail'
import RecruitmentPosts from '@medical/pages/doctor/RecruitmentPost/RecruitmentPosts'
import RequestedAvailableShiftsList from '@medical/pages/doctor/RequestedAvailableShiftsList/RequestedAvailableShiftsList'
import RequestedWorkSchedulesList from '@medical/pages/doctor/RequestedWorkSchedulesList/RequestedWorkSchedulesList'
import Schedule from '@medical/pages/doctor/Schedule/Schedule'
import SingleAvailableShift from '@medical/pages/doctor/SingleAvailableShift/SingleAvailableShift'
import SingleSchedule from '@medical/pages/doctor/SingleSchedule/SingleSchedule'
import Terms from '@medical/pages/doctor/Terms/Terms'
import TransportPeriodic from '@medical/pages/doctor/Transport/TransportPeriodic/TransportPeriodic'
import TransportRegister from '@medical/pages/doctor/Transport/TransportRegister/TransportRegister'
import TransportReports from '@medical/pages/doctor/Transport/TransportReports/TransportReports'
import UpdateProfile from '@medical/pages/doctor/UpdateProfile/UpdateProfile'
import UploadFile from '@medical/pages/doctor/UploadFile/UploadFile'
import WorkReports from '@medical/pages/doctor/WorkReports/WorkReports'
import WorkOutsideClinic from '@medical/pages/doctor/WorkOutsideClinic/WorkOutsideClinic'
import WorkOutsideClinicCreateScene from '@medical/pages/doctor/WorkOutsideClinic/Create/WorkOutsideClinicCreate'
import WorkOutsideClinicDetail from '@medical/pages/doctor/WorkOutsideClinic/Detail/WorkOutsideClinicDetail'
import Route from '@medical/routes/router'
import DoctorClinic from '@medical/pages/doctor/DoctorClinic/DoctorClinics/DoctorClinic'
import DoctorClinicDetail from '@medical/pages/doctor/DoctorClinic/DoctorClinicDetail/DoctorClinicDetail'
import WarningDoctorRegister from '@medical/components/Forms/WarningDoctorRegister'
import EmailNotificationsSetting from '@medical/pages/doctor/EmailNotificationsSetting/EmailNotificationsSetting'
import NewsDetail from '@medical/pages/doctor/News/NewDetail/NewsDetail'
import FindAvailableShift from '@medical/pages/doctor/FindAvailableShift/FindAvailableShift'
import TransportReportsByCarDetail from '@medical/pages/doctor/Transport/TransportReports/TransportReportsByCarDetail'

const DoctorRouters = {
  doctorTerms: {
    description: 'Doctor Terms page',
    notProtected: true,
    path: Route.doctorTerms,
    component: Terms,
  },
  doctorPrivacy: {
    description: 'Doctor Privacy page',
    notProtected: true,
    path: Route.doctorPrivacy,
    component: Privacy,
  },
  doctorResetPassword: {
    description: 'Doctor Email Confirm Page',
    notProtected: true,
    path: Route.doctorResetPassword,
    component: DoctorResetPassword,
  },
  doctorForgotPassword: {
    description: 'Doctor Email Confirm Page',
    notProtected: true,
    path: Route.doctorForgotPassword,
    component: DoctorForgotPassword,
  },
  doctorSignup: {
    description: 'Doctor Sign Up Page',
    notProtected: true,
    path: Route.doctorSignup,
    component: DoctorSignup,
  },
  doctorSignupSuccess: {
    description: 'Doctor Sign Up Success',
    notProtected: true,
    path: Route.doctorSignupSuccess,
    component: DoctorSignupSuccess,
  },
  doctorWarningRegister: {
    description: 'Doctor warning doctor register',
    notProtected: true,
    path: Route.doctorWarningRegister,
    component: WarningDoctorRegister,
  },
  doctorEmailConfirm: {
    description: 'Doctor Email Confirm Page',
    notProtected: true,
    path: Route.doctorEmailConfirm,
    component: DoctorEmailConfirm,
  },
  doctorLogin: {
    description: 'Doctor Login page',
    notProtected: true,
    path: Route.doctorLogin,
    component: DoctorLogin,
  },
  doctorHome: {
    description: 'Doctor Home page',
    path: Route.doctorHome,
    exact: true,
    component: DoctorHome,
  },
  doctorMyPage: {
    description: 'Doctor Profile Page',
    path: Route.doctorMyPage,
    component: DoctorMyPage,
    modals: [
      {
        description: 'Doctor Change password',
        path: Route.doctorUpdateProfile,
        component: UpdateProfile,
        exact: true,
      },
      {
        description: 'Doctor Change password',
        path: Route.doctorChangePassword,
        component: ChangePassword,
        exact: true,
      },
      {
        description: 'Doctor Bank Account',
        path: Route.doctorBankAccount,
        component: BankAccount,
        exact: true,
      },
      {
        description: 'Doctor Upload password',
        path: [Route.doctorUploadFile],
        component: UploadFile,
        exact: true,
      },
      {
        description: 'Doctor Upload password',
        path: [Route.doctorUploadFileOther],
        component: UploadFile,
        exact: true,
      },
      {
        description: 'Doctor Create Identity',
        path: Route.DoctorCreateIdentity,
        component: CreateMyNumber,
        exact: true,
      },
    ],
  },
  doctorEmailNotificationsSetting: {
    description: 'Doctor Email Notifications Setting Page',
    path: Route.doctorEmailNotificationsSetting,
    component: EmailNotificationsSetting,
  },
  doctorNewsDetail: {
    description: 'Doctor full-time / regular part-time new detail',
    path: Route.doctorNewsDetail,
    component: NewsDetail,
    exact: true,
    modals: [
      {
        component: SingleAvailableShift,
        path: Route.doctorSingleAvailableShift,
        exact: true,
      },
    ],
  },
  doctorNews: {
    description: 'Doctor News Page',
    path: Route.doctorNews,
    component: News,
  },
  doctorAvailableShifts: {
    description: 'Doctor Available Shifts calendar page',
    path: [
      Route.doctorAvailableShifts,
      Route.doctorAvailableShiftsFirstLogin,
      Route.doctorAvailableShiftsWithoutYearMonth,
      Route.doctorAvailableShiftsWithoutMonth,
    ],
    component: AvailableShift,
    modals: [
      {
        component: SingleAvailableShift,
        path: Route.doctorSingleAvailableShift,
        exact: true,
      },
      {
        component: SingleSchedule,
        path: Route.doctorSingleSchedule,
        exact: true,
      },
    ],
  },
  doctorSchedules: {
    description: 'Doctor Work Schedule calendar page',
    path: [
      Route.doctorSchedules,
      Route.doctorSchedulesWithoutYearMonth,
      Route.doctorSchedulesWithoutMonth,
    ],
    component: Schedule,
    modals: [
      {
        component: SingleSchedule,
        path: Route.doctorSingleSchedule,
        exact: true,
      },
    ],
  },
  doctorWorkReports: {
    description: 'Doctor Work Reports calendar page',
    path: [
      Route.doctorWorkReports,
      Route.doctorWorkReportsWithoutYearMonth,
      Route.doctorWorkReportsWithoutMonth,
    ],
    component: WorkReports,
  },
  doctorClinic: {
    description: 'Doctor Clinic page',
    path: Route.doctorClinic,
    component: DoctorClinic,
    modals: [
      {
        component: DoctorClinicDetail,
        path: Route.doctorClinicDetail,
        exact: true,
      },
    ],
  },
  doctorInquiry: {
    description: 'Doctor Inquiry create page',
    path: Route.doctorInquiry,
    component: Inquiry,
  },
  doctorTransportReports: {
    description: 'Doctor Transport Reports page',
    path: [
      Route.doctorTransportReports,
      Route.doctorTransportReportsWithoutYearMonth,
      Route.doctorTransportReportsWithoutMonth,
    ],
    component: TransportReports,
    modals: [
      {
        component: TransportReportsByCarDetail,
        path: Route.doctorTransportByCarReports,
      },
    ],
  },
  doctorTransportRegister: {
    description: 'Doctor Register page',
    path: Route.doctorTransportRegister,
    component: TransportRegister,
  },
  doctorTransportPeriodic: {
    description: 'Doctor Periodic page',
    path: Route.doctorTransportPeriodic,
    component: TransportPeriodic,
  },
  doctorQuestion: {
    description: 'Doctor Question page',
    path: Route.doctorQuestions,
    component: Questions,
  },
  doctorCart: {
    description: 'Doctor Cart for Work Schedule request',
    path: Route.doctorCart,
    component: DoctorCart,
  },

  AcceptedWorkSchedulesList: {
    description: 'Doctor Accepted Work Schedules List page',
    path: [
      Route.doctorAcceptedWorkSchedulesList,
      Route.doctorAcceptedWorkSchedulesListWithoutMonth,
      Route.doctorAcceptedWorkSchedulesListWithoutYearMonth,
    ],
    component: AcceptedWorkSchedulesList,
    modals: [
      {
        component: AcceptedWorkScheduleDetail,
        path: Route.doctorAcceptedWorkScheduleDetail,
        modals: [
          {
            path: Route.doctorAcceptedWorkScheduleCancel,
            component: AcceptedWorkScheduleDetail,
            exact: true,
          },
        ],
      },
      {
        description: 'Doctor Register Over Time',
        component: AcceptedWorkScheduleOverTime,
        path: Route.doctorAcceptedWorkSchedulesOverTime,
        exact: true,
      },
      {
        description: 'Doctor Bank Account',
        path: Route.doctorBankAccount,
        component: BankAccount,
        exact: true,
      },
    ],
  },
  RequestedWorkSchedulesList: {
    description: 'Doctor Requested Work Schedules List page',
    path: [
      Route.doctorRequestedWorkSchedulesList,
      Route.doctorRequestedWorkSchedulesListWithoutMonth,
      Route.doctorRequestedWorkSchedulesListWithoutYearMonth,
    ],
    component: RequestedWorkSchedulesList,
  },
  // RequestedAvailableShiftsList: {
  //   description: 'Doctor Requested Available Shifts List page',
  //   path: Route.doctorRequestedAvailableShiftsList,
  //   component: RequestedAvailableShiftsList,
  //   modals: [
  //     {
  //       component: SingleAvailableShift,
  //       path: Route.doctorSingleAvailableShift,
  //       exact: true,
  //     },
  //   ],
  // },
  workOutsideClinic: {
    description: 'doctor work outside clinic',
    path: Route.doctorWorkOutsideClinic,
    component: WorkOutsideClinic,
    modals: [
      {
        component: WorkOutsideClinicCreateScene,
        path: Route.doctorWorkOutsideClinicCreate,
        exact: true,
      },
      {
        component: WorkOutsideClinicDetail,
        path: Route.doctorWorkOutsideClinicDetail,
        exact: true,
      },
    ],
  },
  DoctorPortal: {
    description: 'Doctor Portal page',
    path: Route.doctorPortal,
    component: DoctorPortal,
  },
  confirmEmail: {
    description: 'Doctor Requested Available Shifts List page',
    notProtected: true,
    path: Route.confirmEmail,
    component: ConfirmEmail,
  },
  DoctorRecruitments: {
    description: 'Doctor full-time / regular part-time recruitment',
    path: Route.doctorRecruitments,
    component: RecruitmentPosts,
  },
  DoctorRecruitmentDetail: {
    description: 'Doctor full-time / regular part-time recruitment detail',
    path: Route.doctorRecruitmentDetail,
    component: RecruitmentPostDetail,
    modals: [
      {
        description:
          'Doctor full-time / regular part-time recruitment detail apply',
        path: Route.doctorRecruitmentDetailApply,
        component: RecruitmentPostApply,
        exact: true,
      },
    ],
  },
  DoctorWaitingRecruitment: {
    description: 'Doctor Waiting Recruitment',
    path: Route.doctorWaitingRecruitment,
    component: DoctorWaitingRecruitment,
    modals: [
      {
        component: CreateWaitingRecruitment,
        path: Route.createWaitingRecruitment,
        exact: true,
      },
      {
        component: CreateWaitingRecruitment,
        path: Route.detailWaitingRecruitment,
        exact: true,
      },
    ],
  },
  doctorFindAvailableShift: {
    description: 'Doctor result of find a shift',
    path: Route.doctorFindAvailableShift,
    component: FindAvailableShift,
    modals: [
      {
        component: SingleAvailableShift,
        path: Route.doctorSingleAvailableShift,
        exact: true,
      },
    ],
  },
}

export default DoctorRouters
