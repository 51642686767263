import NotFound from '@medical/components/NotFound'
import GoogleAnalytics from '@medical/provider/GoogleAnalytics'
import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ModalContainer } from 'react-router-modal'
import doctorRoutes from './doctorRoutes'
import HomeRedirect from './HomeRedirect'
import ProtectedRoute from './ProtectedRoute'
import Router from './router'
import ScrollToTop from './ScrollToTop'
import staffRoutes from './staffRoutes'

const RouterContainer = () => (
  <BrowserRouter>
    <GoogleAnalytics>
      <Switch>
        {/* ------------------- Doctor Route config ------------------- */}
        <Route path={Router.home} exact component={HomeRedirect} />
        {Object.keys(doctorRoutes).map(key => {
          const { path, exact, component, modals } = doctorRoutes[key]
          if (doctorRoutes[key].notProtected) {
            return <Route key={key} path={path} component={component} />
          }
          return (
            <ProtectedRoute
              key={key}
              modals={modals}
              path={path}
              exact={exact}
              component={component}
              redirect={Router.doctorLogin}
            />
          )
        })}
        {/* ------------------- Staff Route config ------------------- */}
        {Object.keys(staffRoutes).map(key => {
          const { path, exact, component, modals, permissionId } = staffRoutes[
            key
          ]
          if (staffRoutes[key].notProtected) {
            return <Route key={key} path={path} component={component} />
          }
          return (
            <ProtectedRoute
              key={key}
              modals={modals}
              path={path}
              exact={exact}
              component={component}
              permissionId={permissionId}
              redirect={Router.staffLogin}
            />
          )
        })}
        <Route path='*' component={NotFound} />
      </Switch>
        <ScrollToTop/>
      <ModalContainer />
    </GoogleAnalytics>
  </BrowserRouter>
)

export default RouterContainer

