/* eslint-disable no-empty-pattern */
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import {
  STAFF_CREATE_CATEGORY,
  STAFF_DELETE_CATEGORY,
  STAFF_UPDATE_CATEGORY,
} from '@medical/constant/permissions'
import { checkStaffPermission } from '@medical/libs'
import { QUESTION_CATEGORY } from '@medical/libs/general.graphql'
import { useCustom } from '@medical/provider/context'
import React, { useEffect } from 'react'

import {
  CREATE_CATEGORY,
  QUESTION_CONNECTION,
  UPDATE_CATEGORY,
} from './Category.graphql'
import CategoryForm from './CategoryForm'

const Categorys = ({}) => {
  const client = useApolloClient()
  const [{ i18n, popup }] = useCustom()
  const {
    error,
    loading: loadingCategorys,
    data: categorys,
    refetch,
  } = useQuery(QUESTION_CATEGORY, {
    variables: {
      orderBy: 'createdAt_ASC',
      where: {
        deletedAt: null,
      },
    },
    fetchPolicy: 'network-only',
  })
  useEffect(() => {
    refetch()
  })
  const isCreatePermitted = checkStaffPermission({
    functionName: STAFF_CREATE_CATEGORY,
  })
  const isDeletePermitted = checkStaffPermission({
    functionName: STAFF_DELETE_CATEGORY,
  })
  const isUpdatePermitted = checkStaffPermission({
    functionName: STAFF_UPDATE_CATEGORY,
  })
  const [updateCategory] = useMutation(UPDATE_CATEGORY)
  const [createCategory] = useMutation(CREATE_CATEGORY)
  if (loadingCategorys) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  const onSubmit = async (
    { id, content, isUpdate, isCreate },
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      if (isCreate) {
        await createCategory({
          variables: {
            content,
          },
        })
        popup.success('成功しました')
        setSubmitting(false)
        resetForm()
      } else if (!isCreate) {
        if (isUpdate) {
          await updateCategory({
            variables: {
              categoryId: id,
              content: content ? content.trim() : '',
            },
          })
          popup.success('成功しました')
          setSubmitting(false)
          resetForm()
        } else {
          const {
            data: {
              questionsConnection: { edges },
            },
          } = await client.query({
            query: QUESTION_CONNECTION,
            variables: {
              where: {
                category: id,
                deletedAt: null,
              },
            },
            fetchPolicy: 'network-only',
          })
          if (edges.length > 0) {
            popup.error(i18n.t('staff.questions.CategoryMessageNotDelete'))
            setSubmitting(false)
            resetForm()
          } else {
            await updateCategory({
              variables: {
                categoryId: id,
                deletedAt: isUpdate ? null : new Date().toISOString(),
                content: content ? content.trim() : '',
              },
            })
            popup.success('成功しました')
            setSubmitting(false)
            resetForm()
          }
        }
      }
    } catch (error) {
      popup.error(error)
      setFieldValue('confirm', false)
      setSubmitting(false)
    }
  }
  const listCategory =
    categorys && categorys.questionCategories
      ? categorys.questionCategories.map(item => ({
          id: item.id,
          content: item.content,
          edit: true,
          createdAt: item.createdAt,
        }))
      : []
  return (
    <CategoryForm
      listCategory={listCategory}
      i18n={i18n}
      announce={{}}
      onSubmit={onSubmit}
      submitLabel={i18n.t('staff.createAnnounce.submit')}
      confirmationMessage={i18n.t('staff.createAnnounce.submitConfirmation')}
      isCreatePermitted={isCreatePermitted}
      isDeletePermitted={isDeletePermitted}
      isUpdatePermitted={isUpdatePermitted}
    />
  )
}

export default Categorys
