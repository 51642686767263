import PasswordInput from '@medical/components/PasswordInput'
import {
  PASSWORD_NON_SPACE,
  PASSWORD_PATTERN,
} from '@medical/libs/validation/password'
import { Formik } from 'formik'
import { Button } from 'primereact/button'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

const ChangePasswordComponent = ({ onSubmit }) => {
  const { i18n } = useTranslation()
  const min = i18n.t('validation.password.min')
  const max = i18n.t('validation.password.max')
  const pattern = i18n.t('validation.password.pattern')
  const patternNoSpace = i18n.t('validation.password.patternNoSpace')
  const required = i18n.t('validation.required')
  const password = Yup.string()
    .max(50, max)
    .min(6, min)
    .matches(PASSWORD_NON_SPACE, patternNoSpace)
    .matches(PASSWORD_PATTERN, pattern)
  const validationSchema = Yup.object({
    oldPassword: password.required(
      `${i18n.t('main.changePassword.oldPassword')} ${required}`
    ),
    newPassword: password.required(
      `${i18n.t('main.changePassword.newPassword')} ${required}`
    ),
    newPasswordConfirm: password
      .oneOf(
        [Yup.ref('newPassword'), null],
        i18n.t('validation.password.confirmNotMatch')
      )
      .required(
        `${i18n.t('main.changePassword.newPasswordConfirm')} ${required}`
      ),
  })
  return (
    <Formik
      initialValues={{
        oldPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={formikProps => {
        const { handleSubmit, isSubmitting } = formikProps
        return (
          <React.Fragment>
            <PasswordInput
              {...formikProps}
              name='oldPassword'
              label={i18n.t('main.changePassword.oldPassword')}
            />
            <PasswordInput
              {...formikProps}
              name='newPassword'
              label={i18n.t('main.changePassword.newPassword')}
            />
            <PasswordInput
              {...formikProps}
              name='newPasswordConfirm'
              label={i18n.t('main.changePassword.newPasswordConfirm')}
            />
            <div className='modal-bottom'>
              <Button
                onClick={handleSubmit}
                disabled={isSubmitting}
                type='submit'
                label={i18n.t('main.changePassword.submit')}
              />
            </div>
          </React.Fragment>
        )
      }}
    />
  )
}

export default ChangePasswordComponent
