import {
  PERMITTED_ACTION_FRAGMENT,
  API_GROUP_TYPE_FRAGMENT,
} from '@medical/libs/general.graphql'
import { STAFF_GROUP_FRAGMENT } from '@medical/pages/staff/StaffGroups/StaffGroupsList/StaffGroupsList.graphql'
import gql from 'graphql-tag'

export const API_FRAGMENT = gql`
  fragment API_FRAGMENT on Api {
    id
    name
    description
    type
    apiGroup
  }
`

export const API_QUERY_FRAGMENT = gql`
  fragment API_QUERY_FRAGMENT on Query {
    apis {
      ...API_FRAGMENT
    }
  }
  ${API_FRAGMENT}
`

export const STAFF_GROUP_QUERY = gql`
  query STAFF_GROUP_QUERY($staffGroupId: ID) {
    staffGroup(where: { id: $staffGroupId }) {
      ...STAFF_GROUP_FRAGMENT
      permissions {
        id
        action
        api {
          ...API_FRAGMENT
        }
      }
    }
    ...PERMITTED_ACTION_FRAGMENT
    ...API_GROUP_TYPE_FRAGMENT
  }
  ${STAFF_GROUP_FRAGMENT}
  ${API_FRAGMENT}
  ${PERMITTED_ACTION_FRAGMENT}
  ${API_GROUP_TYPE_FRAGMENT}
`

export const UPDATE_STAFF_GROUP = gql`
  mutation UPDATE_STAFF_GROUP(
    $staffGroupId: ID!
    $name: String
    $permissions: [PermissionForUpdate!]!
  ) {
    staffUpdateStaffGroup(
      staffGroupId: $staffGroupId
      name: $name
      permissions: $permissions
    )
  }
`
