import BreadCrumb from '@medical/components/Breadcrumb'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import Router from '@medical/routes/router'
import { Formik } from 'formik'
import { Button } from 'primereact/button'
import React from 'react'
import { Link } from 'react-router-dom'

const StaffGroupsListScene = ({
  i18n,
  staffGroups,
  onSubmit,
  isCreatePermitted,
  isDeletePermitted,
  isUpdatePermitted,
}) => (
  <React.Fragment>
    <div className='staff-header'>
      <div className='staff-title'>
        {i18n.t('staff.menuBar.staffGroups')}
        <BreadCrumb items={[{ title: i18n.t('staff.menuBar.staffGroups') }]} />
      </div>
      {isCreatePermitted ? (
        <div className='staff-buttons'>
          <Link to={Router.staffGroupCreate}>
            <Button
              label={i18n.t('staff.staffGroup.create')}
              icon='pi pi-plus'
              className='p-button-normal'
            />
          </Link>
        </div>
      ) : null}
    </div>
    <div className='container is-medium'>
      <div className='box'>
        <Formik
          initialValues={{
            staffGroupId: '',
            confirm: false,
          }}
          onSubmit={onSubmit}
          render={formikProps => (
            <React.Fragment>
              <table className='table'>
                <thead>
                  <tr>
                    <th>{i18n.t('main.groupName')}</th>
                    {isUpdatePermitted || isDeletePermitted ? (
                      <th width='140px'>{i18n.t('main.action')}</th>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {staffGroups.map(({ id, name }) => (
                    <tr key={id}>
                      <td>{name}</td>
                      {isUpdatePermitted || isDeletePermitted ? (
                        <td>
                          <div className='action'>
                            {isUpdatePermitted ? (
                              <Link
                                to={Router.get(Router.staffGroupDetail, {
                                  staffGroupId: id,
                                })}
                              >
                                <Button icon='pi pi-pencil' />
                              </Link>
                            ) : null}
                            {isDeletePermitted ? (
                              <Button
                                onClick={() => {
                                  formikProps.setFieldValue('staffGroupId', id)
                                  formikProps.setFieldValue('confirm', true)
                                }}
                                icon='pi pi-trash'
                              />
                            ) : null}
                          </div>
                        </td>
                      ) : null}
                    </tr>
                  ))}
                </tbody>
              </table>
              <ConfirmationModal
                {...formikProps}
                confirmationMessage={i18n.t(
                  'staff.staffGroup.deleteConfirmation'
                )}
              />
            </React.Fragment>
          )}
        />
      </div>
    </div>
  </React.Fragment>
)

export default StaffGroupsListScene
