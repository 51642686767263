import { useMutation } from '@apollo/react-hooks'
import DocumentForm from '@medical/pages/staff/Documents/components/DocumentForm'
import { useCustom } from '@medical/provider/context'
import Router from '@medical/routes/router'
import React from 'react'

import { CREATE_DOCUMENT } from './DocumentCreate.graphql'

const DocumentCreate = ({ history }) => {
  const [{ i18n, popup }] = useCustom()
  const [createDocument] = useMutation(CREATE_DOCUMENT)

  const onSubmit = async (
    { title, content },
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      await createDocument({
        variables: {
          title,
          content,
        },
      })
      popup.success(i18n.t('staff.createDocument.submissionSuccess'))
      setSubmitting(false)
      resetForm()
      history.push({
        pathname: Router.staffDocuments,
      })
    } catch (error) {
      popup.error(error)
      setFieldValue('confirm', false)
      setSubmitting(false)
    }
  }

  return (
    <DocumentForm
      document={{}}
      onSubmit={onSubmit}
      submitLabel={i18n.t('staff.createDocument.submit')}
      confirmationMessage={i18n.t('staff.createDocument.submitConfirmation')}
    />
  )
}

export default DocumentCreate
