import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import ClinicNameRender from '@medical/components/ClinicNameRender'
import {
  STAFF_UPDATE_WS,
  WORK_SCHEDULE_DOWNLOAD_FIELDS,
} from '@medical/constant/permissions'
import {
  checkStaffPermission,
  clinicalDepartmentNames,
  combineNames,
  dateValidation,
  onPageChange,
  sortAndFilterByClinicOrder,
  workPatternName,
} from '@medical/libs'
import Auth from '@medical/middleware/auth'
import { useCustom } from '@medical/provider/context'
import { SocketContext } from '@medical/provider/socket'
import {
  removeProgress,
  setProgressBar,
} from '@medical/provider/store/reducers/progress/progress.action'
import Router from '@medical/routes/router'
import moment from 'moment'
import queryString from 'query-string'
import React, { useContext } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import {
  WORKSCHEDULES_CONNECTION,
  WORKSCHEDULES_CONNECTION_DOWNLOAD,
  WORKSCHEDULES_CONNECTION_FILTER,
} from './AcceptedWorkSchedulesList.graphql'
import AcceptedWorkSchedulesListScene from './AcceptedWorkSchedulesListScene'
import { fieldWorkScheduleByTimeCsv } from '@medical/services/csvService'
import {
  caculatorSalary,
  convertScheduleToTimeShift,
  formatHHmm,
  getDepartmentNameJP,
  getTimeFromDate,
} from '@medical/constant/utilities'
import downloadCsv from 'download-csv'
import {
  DOWNLOAD_CSV_MONTHLY_AVAILABLE_SHIFT_BY_TIME_SHIFT,
  DOWNLOAD_CSV_MONTHLY_WORKSCHEDULE_BY_TIME_SHIFT,
} from '@medical/constant'

const DEFAULT_WORK_SCHEDULE_PER_PAGE = 15

const AcceptedWorkSchedulesList = ({
  history,
  match: {
    url,
    path,
    params: { year = moment().year(), month = moment().month() },
  },
  location: { search },
  progress,
  setProgress,
  removeProgress,
}) => {
  const [{ i18n }] = useCustom()
  const socket = useContext(SocketContext)
  const startDate = moment()
    .year(year)
    .month(month - 1)
    .startOf('month')
  const endDate = startDate.clone().endOf('month')
  const isUpdatePermitted = checkStaffPermission({
    functionName: STAFF_UPDATE_WS,
  })
  const isWorkScheduleDownloadPermitted = checkStaffPermission({
    functionName: WORK_SCHEDULE_DOWNLOAD_FIELDS,
  })
  const {
    page = 1,
    rowsPerPage = 100,
    clinicalDepartmentIds,
    workPatter,
    fullname,
    day,
    isOneVisit,
  } = queryString.parse(search)
  const clinicalDepartmentIdsSearch =
    clinicalDepartmentIds && clinicalDepartmentIds.length > 0
      ? clinicalDepartmentIds.split('＿')
      : undefined
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  const variables = {
    first: first < 0 ? 10 : first,
    skip: skip < 0 ? 0 : skip,
    where: {
      startTime_gte: day ? moment(day).startOf('day') : startDate,
      endTime_lte: day ? moment(day).endOf('day') : endDate,
      deletedAt: null,
      accepted: true,
      Doctor: {
        workPattern: workPatter || undefined,
        unSigned_contains: fullname
          ? fullname
              .replace(/ /g, '')
              .replace(/　/g, '')
              .toLowerCase()
          : '',
        deletedAt: null,
      },
      ClinicalDepartment: {
        id_in: clinicalDepartmentIdsSearch,
      },
    },
    orderBy: 'startTime_ASC',
  }

  const { loading, error, data } = useQuery(WORKSCHEDULES_CONNECTION, {
    variables,
    fetchPolicy: 'network-only',
  })

  const { refetch: fetchWorScheduleDownload } = useQuery(
    WORKSCHEDULES_CONNECTION_DOWNLOAD,
    {
      fetchPolicy: 'network-only',
      skip: true,
      orderBy: 'startTime_ASC',
    }
  )

  const {
    loading: loadingFilter,
    error: errorFilter,
    data: dataFilter,
  } = useQuery(WORKSCHEDULES_CONNECTION_FILTER, {
    variables,
    fetchPolicy: 'network-only',
    skip: isOneVisit !== '1',
  })

  const dataWorkScheduleFilter = dataFilter
    ? JSON.parse(dataFilter?.workScheduleConnectionFilter.data)
    : dataFilter

  const edgesFilter = dataWorkScheduleFilter?.map(workSchedule => {
    const convertedObj = {
      node: {
        accepted: workSchedule.accepted === 1,
        startTime: workSchedule.startTime,
        id: workSchedule.id,
        endTime: workSchedule.endTime,
        updatedAt: workSchedule.updatedAt,
        acceptedShift: {
          id: workSchedule.acceptedShift_id,
          scheduledAvailableShiftGroup: {
            id: workSchedule.acceptedShift_scheduledAvailableShiftGroup_id,
          },
        },
        clinicalDepartment: {
          clinic: {
            id: workSchedule.clinicalDepartment_clinic_id,
            name: workSchedule.clinicalDepartment_clinic_name,
          },
          extraName: workSchedule.clinicalDepartment_extraName,
          id: workSchedule.clinicalDepartment_id,
          name: workSchedule.clinicalDepartment_name,
        },
        doctor: {
          firstname: workSchedule.doctor_firstname,
          id: workSchedule.doctor_id,
          lastname: workSchedule.doctor_lastname,
          workPattern: workSchedule.doctor_workPattern,
        },
        workScheduleActualTime: {
          endTime: workSchedule.workScheduleActualTime_endTime,
          id: workSchedule.workScheduleActualTime_id,
          startTime: workSchedule.workScheduleActualTime_startTime,
        },
        isshinTime: workSchedule.isshinTime,
      },
    }

    return convertedObj
  })

  const momentDate = moment(`${year} ${month} 01`, 'YYYY MM DD')
  if (
    (dateValidation({ year, month }) &&
      momentDate.isBefore(moment().subtract(1, 'years'), 'month')) ||
    [
      Router.staffAcceptedWorkSchedulesListWithoutMonth,
      Router.staffAcceptedWorkSchedulesListWithoutYearMonth,
    ].includes(path)
  ) {
    return (
      <Redirect
        to={Router.get(Router.staffAcceptedWorkSchedulesList, {
          year: moment().year(),
          month: moment().month() + 1,
        })}
      />
    )
  }

  if (loading || loadingFilter) return <ProgressSpinner />
  if (error || errorFilter) return <ErrorComponent error={error} />

  const {
    workSchedulesConnection: { edges },
    clinicalDepartments,
    departments,
    workPatterns,
  } = data
  const sortedClinicalDepartments = sortAndFilterByClinicOrder({
    clinicalDepartments,
  })
  const departmentNames = clinicalDepartmentNames({ departments })
  const workPatternNames = workPatternName({ workPatterns })

  const convertDataTable = workScheduleLists =>
    workScheduleLists.map(({ node }) => ({
      id: node.id,
      updatedAt: node.updatedAt,
      startTime: node.startTime,
      endTime: node.endTime,
      clinicalDepartmentId: node.clinicalDepartment.id,
      clinicName: ClinicNameRender({
        clinicalDepartment: node.clinicalDepartment,
      }),
      clinicalDepartmentName: departmentNames[node.clinicalDepartment.name],
      workPatternName: workPatternNames[node.doctor.workPattern],
      fullName: `${node.doctor.lastname} ${node.doctor.firstname}`,
      scheduledAvailableShiftGroup: node.acceptedShift
        .scheduledAvailableShiftGroup
        ? node.acceptedShift.scheduledAvailableShiftGroup.id
        : null,
      actualTime: node.workScheduleActualTime
        ? `${moment(node.workScheduleActualTime.startTime).format(
            'HH:mm'
          )}～${moment(node.workScheduleActualTime.endTime).format('HH:mm')}`
        : '',
      acceptedShiftId: node.acceptedShift.id,
      isshinTime: node?.isshinTime,
    }))

  const handleDownloadWorkScheduleByTime = async () => {
    try {
      setProgress({
        progress: DOWNLOAD_CSV_MONTHLY_WORKSCHEDULE_BY_TIME_SHIFT,
        label: '時間帯別確定シフトCSVダウンロード',
        percent: 0,
      })

      let totalRecords
      const workSchedules = []
      for (let i = 0; ; i++) {
        const res = await fetchWorScheduleDownload({
          where: {
            deletedAt: null,
            startTime_gte: startDate,
            endTime_lte: endDate,
            WorkSchedule: {
              accepted: true,
              Doctor: {
                deletedAt: null,
              },
            },
          },
          first: DEFAULT_WORK_SCHEDULE_PER_PAGE,
          skip: i * DEFAULT_WORK_SCHEDULE_PER_PAGE,
        })
        totalRecords = res.data.availableShiftsConnection.aggregate.count

        workSchedules.push(
          ...res.data.availableShiftsConnection.edges.map(item => ({
            ...item.node,
            adjustHourlyWageRange: item.node.workSchedule.adjustHourlyWageRange,
          }))
        )

        setProgress({
          progress: DOWNLOAD_CSV_MONTHLY_WORKSCHEDULE_BY_TIME_SHIFT,
          label: '時間帯別確定シフトCSVダウンロード',
          percent: Math.ceil((workSchedules.length / totalRecords) * 100),
        })

        if (totalRecords === workSchedules.length) break
      }

      // CSV fields
      const fields = fieldWorkScheduleByTimeCsv()
      const filename = [
        `時間帯別確定シフト_`,
        moment.utc(endDate).format('YYYY年MM月'),
        '.CSV',
      ].join('')

      const ClinicNameRender = ({ clinicalDepartment }) =>
        clinicalDepartment.extraName
          ? `${clinicalDepartment.clinic.name} (${clinicalDepartment.extraName})`
          : clinicalDepartment.clinic.name

      const results = []

      workSchedules
        .sort((ws1, ws2) => moment(ws1.endTime).diff(ws2.endTime))
        .forEach(item => {
          const timeShift = convertScheduleToTimeShift({
            startTime: formatHHmm(item.startTime),
            endTime: formatHHmm(item.endTime),
          })
          const startBreakTime = getTimeFromDate(
            item.clinicalDepartment.endTimeMondayMorning
          )
          const endBreakTime = getTimeFromDate(
            item.clinicalDepartment.startTimeMondayAfternoon
          )
          const isShowBreakTime =
            moment(`2000-01-01 ${formatHHmm(item.startTime)}`).isSameOrBefore(
              `2000-01-01 ${startBreakTime}`
            ) &&
            moment(`2000-01-01 ${formatHHmm(item.endTime)}`).isSameOrAfter(
              `2000-01-01 ${endBreakTime}`
            )
          results.push({
            workDate: moment(item.endTime).format('YYYY-MM-DD'),
            doctorName: `${item.workSchedule.doctor.fullName}`,
            clinicName: ClinicNameRender({
              clinicalDepartment: item.clinicalDepartment,
            }),
            clinicalDepartmentName: getDepartmentNameJP(
              item.clinicalDepartment.name
            ),
            startMorningShift: timeShift.morningShift?.split('-')[0] || '',
            endMorningShift: timeShift.morningShift?.split('-')[1] || '',
            startBreakTime: isShowBreakTime ? startBreakTime : '',
            endBreakTime: isShowBreakTime ? endBreakTime : '',
            startAfternoonShift: timeShift.afternoonShift?.split('-')[0] || '',
            endAfternoonShift: timeShift.afternoonShift?.split('-')[1] || '',
            startEveningShift: timeShift.eveningShift?.split('-')[0] || '',
            endEveningShift: timeShift.eveningShift?.split('-')[1] || '',
            splitHourlyWage1: item.splitHourlyWage1,
            splitHourlyWage2: item.splitHourlyWage2,
            splitHourlyWage3: item.splitHourlyWage3,
            hourlyWage: item.hourlyWage,
            dailySalary: caculatorSalary(item),
            isPublished: item.isPublished ? '掲載' : '未掲載',
            isDoubleRecruitment: item.isDoubleRecruitment ? '二診目' : '',
          })
        })

      downloadCsv(results, fields, filename)
      removeProgress(DOWNLOAD_CSV_MONTHLY_WORKSCHEDULE_BY_TIME_SHIFT)
    } catch (err) {
      setProgress({
        progress: DOWNLOAD_CSV_MONTHLY_WORKSCHEDULE_BY_TIME_SHIFT,
        label: '時間帯別確定シフトCSVダウンロード',
        msg: i18n.t('main.msgDownloadCSVStatusError'),
      })
    }
  }

  const token = Auth.getToken()

  const download = `${process.env.REACT_APP_ENDPOINT}/downloadWorkScheduleCsv?token=${token}&year=${year}&month=${month}`
  const downloadUniqueTimeWork = `${process.env.REACT_APP_ENDPOINT}/downloadWorkScheduleCsvUniqueTimeWork?token=${token}&year=${year}&month=${month}`

  return (
    <AcceptedWorkSchedulesListScene
      i18n={i18n}
      socket={socket}
      progress={progress}
      setProgress={progress => {
        setProgress(progress)
      }}
      removeProgress={progress => removeProgress(progress)}
      download={download}
      handleDownloadWorkScheduleByTime={handleDownloadWorkScheduleByTime}
      downloadUniqueTimeWork={downloadUniqueTimeWork}
      workScheduleLists={convertDataTable(
        isOneVisit === '1' ? edgesFilter : edges
      )}
      clinicalDepartments={combineNames({
        clinicalDepartments: sortedClinicalDepartments,
        departmentNames,
      })}
      currentDate={startDate}
      workPatterns={workPatterns}
      clinicalDepartmentIds={clinicalDepartmentIdsSearch}
      isUpdatePermitted={isUpdatePermitted}
      isWorkScheduleDownloadPermitted={isWorkScheduleDownloadPermitted}
      onPageChange={onPageChange(history, search)}
      history={history}
      count={
        isOneVisit === '1'
          ? dataFilter.workScheduleConnectionFilter.count
          : data?.workSchedulesConnection?.aggregate?.count || 0
      }
      url={url}
      rowsPerPage={parseInt(rowsPerPage, 10)}
      page={parseInt(page, 10)}
      fullname={fullname}
      workPatterSearch={workPatter}
      daySearch={day ? moment(day) : ''}
      isOneVisit={isOneVisit}
    />
  )
}

const mapStateToProps = state => ({
  progress: state.progressBarStore.progress,
})

const mapDispatchToProps = dispatch => ({
  setProgress: progress => dispatch(setProgressBar(progress)),
  removeProgress: progress => dispatch(removeProgress(progress)),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AcceptedWorkSchedulesList)
