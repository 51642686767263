import { AutoComplete as PrimeAutoComplete } from 'primereact/autocomplete'
import React from 'react'

const AutoComplete = ({
  name,
  placeholder,
  values,
  setFieldValue,
  field,
  allSuggestions,
  suggestionName,
  width,
  maxWidth,
  searchFields,
  setCurrentClinicData,
  setCurrentProvince,
  setDoctorSelect,
  paddingBottom,
  disabled = false,
  day,
  isActivityHistory,
  setDoctorId,
  value = false,
}) => {
  return (
    <PrimeAutoComplete
      value={
        value ? null :
        day
          ? allSuggestions.find(
              ({ id }) => id === values.splits[day][0].clinicalDepartmentId
            )
            ? allSuggestions.find(
                ({ id }) => id === values.splits[day][0].clinicalDepartmentId
              )
            : values.splits[day][0].clinicalDepartment
          : values[name]
          ? Object.keys(values[name]).length === 0
            ? null
            : values[name]
          : name === 'homeAddress.stateOrRegion'
          ? values.homeAddress.stateOrRegion
          : null
      }
      suggestions={values[suggestionName]}
      completeMethod={e => {
        const results = allSuggestions.filter(suggestion => {
          if (!searchFields)
            return suggestion[field]
              .toLowerCase()
              .includes(e.query.toLowerCase())
          return !!searchFields.reduce(
            (acc, singleField) =>
              acc +
              suggestion[singleField]
                .toLowerCase()
                .includes(e.query.toLowerCase()),
            0
          )
        })
        setFieldValue(suggestionName, results, false)
      }}
      placeholder={placeholder}
      field={field}
      onUnselect={async e => {
        day
          ? setFieldValue(`splits[${day}][0].clinicalDepartment`, e.value)
          : setFieldValue(name, e.value)
        if (setCurrentProvince) {
          await setCurrentProvince(e.value.name_ja)
        }
      }}
      onSelect={async e => {
        day
          ? setFieldValue(`splits[${day}][0].clinicalDepartment`, e.value)
          : setFieldValue(name, e.value)
        if (setCurrentProvince) {
          await setCurrentProvince(e.value.name_ja)
        }
        if (isActivityHistory) {
          setFieldValue('doctor', e.value)
          setDoctorId(e.value.id)
        }
      }}
      onChange={async e => {
        day
          ? setFieldValue(`splits[${day}][0].clinicalDepartment`, e.value)
          : setFieldValue(name, e.value)
        if (setDoctorSelect) {
          await setDoctorSelect(e.value)
        }
        if (setCurrentClinicData) {
          await setCurrentClinicData(e.value)
        }
        // if (setCurrentProvince) {
        //   await setCurrentProvince(e.value)
        // }
      }}
      inputStyle={{
        width: width || 'auto',
        maxWidth: maxWidth || 'inherit',
        paddingBottom: paddingBottom || '',
      }}
      minLength={1}
      dropdown
      disabled={disabled}
    />
  )
}

export default AutoComplete
