import BreadCrumb from '@medical/components/Breadcrumb'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import numeralCurrency from '@medical/libs/numeralCurrency'
import { Checkbox, DatePicker, Input, InputNumber } from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import { ErrorMessage, FieldArray, Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'

import addSplit from './addSplit'
import AutoComplete from './TransportRegisterAutoComplete'
import TransportRegisterModal from './TransportRegisterModal'
import TransportRegisterSceneMobile from './TransportRegisterSceneMobile'

const TransportRegisterScene = ({
  i18n,
  clinicals,
  onSubmit,
  dataTransport = {},
  remove,
}) => {
  const disabledDate = current => {
    const dateNow = moment().get('date')
    let minDay = moment().startOf('month')
    const maxDay = moment().add(3, 'month')
    if (dateNow <= 10) {
      minDay = moment()
        .subtract(1, 'month')
        .startOf('month')
      return !minDay.isSameOrBefore(current) || !maxDay.isSameOrAfter(current)
    }
    return !maxDay.isSameOrAfter(current) || !minDay.isSameOrBefore(current)
  }
  const validationSchema = Yup.object().shape({
    splits: Yup.array().of(
      Yup.object().shape({
        roundTrip: Yup.object()
          .shape({
            urlRoundTrip: Yup.string()
              .url(i18n.t('validation.url'))

              .required(
                `${i18n.t('transportApplication.routeURL')}${i18n.t(
                  'validation.required'
                )}`
              ),
            departurePlaceRoundTrip: Yup.string()
              .trim()
              .required(
                `${i18n.t('transportApplication.departurePlace')}${i18n.t(
                  'validation.required'
                )}`
              ),
            arrivalPlaceRoundTrip: Yup.string()
              .trim()
              .required(
                `${i18n.t('transportApplication.arrivalPlace')}${i18n.t(
                  'validation.required'
                )}`
              ),
            costRoundTrip: Yup.string()
              .nullable()
              .required(
                `${i18n.t('transportApplication.fareOneway')}${i18n.t(
                  'validation.required'
                )}`
              ),
          })
          .nullable(),

        clinic: Yup.object()
          .nullable()
          .required(
            `${i18n.t('main.clinicName')}${i18n.t('validation.required')}`
          ),
        url: Yup.string()
          .url(i18n.t('validation.url'))
          .required(
            `${i18n.t('transportApplication.routeURL')}${i18n.t(
              'validation.required'
            )}`
          ),

        departurePlace: Yup.string()
          .trim()
          .required(
            `${i18n.t('transportApplication.departurePlace')}${i18n.t(
              'validation.required'
            )}`
          ),

        arrivalPlace: Yup.string()
          .trim()
          .required(
            `${i18n.t('transportApplication.arrivalPlace')}${i18n.t(
              'validation.required'
            )}`
          ),

        cost: Yup.string()
          .nullable()
          .required(
            `${i18n.t('transportApplication.fareOneway')}${i18n.t(
              'validation.required'
            )}`
          ),
        totalCost: Yup.string().when('isCost', {
          is: true,
          then: Yup.string().required(
            '１日の往復金額は5,000円以内でお願いします'
          ),
        }),
      })
    ),
  })

  const endTime = (clinic, startTime, values) => {
    const data = values.splits
      .filter(item => item.clinic.id === clinic.id)
      .sort((a, b) => moment(a.startTime) - moment(b.startTime))
    for (let i = 0; i < data.length; i += 1) {
      if (
        moment(data[i].startTime)
          .startOf('days')
          .valueOf() >
        moment(startTime)
          .startOf('days')
          .valueOf()
      ) {
        return moment.utc(data[i].startTime).subtract(1, 'days')
      }
    }
  }
  return (
    <Formik
      enableReinitialize
      initialValues={{
        functionName: '',
        showNote: false,
        confirm: false,
        showModalUrl: false,
        showModalCost: false,
        showModalDeparturePlace: false,
        showModalArrivalPlace: false,
        showMyCarRegulations: false,
        clinicals,
        transportationsId: [],
        splits:
          dataTransport && dataTransport.length
            ? dataTransport
            : [
                {
                  id: '',
                  clinic: '',
                  url: '',
                  departurePlace: '',
                  arrivalPlace: '',
                  cost: '',
                  startTime: moment.utc(new Date(), 'YYYY年MM月DD日'),
                  isCheckBox: false,
                  isPast: false,
                  roundTrip: null,
                  endTime: '',
                  show: false,
                  isCost: false,
                  totalCost: '',
                },
              ],
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={formikProps => {
        const { setFieldValue, setTouched, validateForm, values } = formikProps
        let confirmationMessage = i18n.t(
          'transportApplication.submitConfirmation'
        )
        if (
          values.functionName === remove ||
          values.functionName === 'hidden'
        ) {
          confirmationMessage = (
            <>
              この交通費データを完全に削除しますか?
              データを復元することはできません。
            </>
          )
        }
        if (isMobile) {
          return (
            <>
              <div className='container is-medium'>
                <div className='box'>
                  <div className='transport'>
                    <div className='calendar-container'>
                      <div className='available-shift-list-title'>
                        {i18n.t('transportApplication.feeApplicationTitle')}
                      </div>
                      <div className='mg-bot-15'>
                        <Link
                          to='#'
                          style={{ color: '#40a9ff' }}
                          onClick={() => {
                            validateForm().then(() => {
                              setFieldValue('showNote', true)
                            })
                          }}
                        >
                          {i18n.t('transportApplication.note')}
                        </Link>
                        <TransportRegisterModal
                          {...formikProps}
                          name='showNote'
                        />
                      </div>
                      <div>
                        <FieldArray
                          name='splits'
                          render={arrayHelpers => (
                            <>
                              {values.splits.map((slipt, index) => {
                                const endtime = slipt.endTime
                                  ? slipt.endTime
                                  : endTime(
                                      slipt.clinic,
                                      slipt.startTime,
                                      values
                                    )

                                const currentTime = moment.utc().valueOf()
                                const format = endtime
                                  ? moment(endtime).format('YYYY年MM月DD日まで')
                                  : null
                                let isPast = false
                                const startTimeAddMonth = moment
                                  .utc(slipt.startTime)
                                  .startOf('months')
                                  .add(1, 'months')
                                  .add(10, 'days')
                                const endTimeAddMonth = moment
                                  .utc(endtime)
                                  .startOf('months')
                                  .add(1, 'months')
                                  .add(10, 'days')

                                if (
                                  startTimeAddMonth.isBefore(currentTime) &&
                                  !!endtime
                                ) {
                                  isPast = true
                                }

                                return (
                                  <TransportRegisterSceneMobile
                                    slipt={slipt}
                                    index={index}
                                    formikProps={formikProps}
                                    clinicals={clinicals}
                                    startTimeAddMonth={startTimeAddMonth}
                                    currentTime={currentTime}
                                    isPast={isPast}
                                    // disabledDate={disabledDate}
                                    format={format}
                                    arrayHelpers={arrayHelpers}
                                    remove={remove}
                                    dataTransport={dataTransport}
                                  />
                                )
                              })}
                            </>
                          )}
                        />

                        <div style={{ textAlign: 'center' }}>
                          <Button
                            icon='pi pi-plus'
                            onClick={() => {
                              setFieldValue('splits', [
                                ...values.splits,
                                {
                                  id: '',
                                  clinic: '',
                                  url: '',
                                  departurePlace: '',
                                  arrivalPlace: '',
                                  cost: '',
                                  roundTrip: null,
                                  startTime: moment(
                                    new Date(),
                                    'YYYY年MM月DD日'
                                  ),
                                  show: true,
                                },
                              ])
                            }}
                            label={i18n.t('main.add')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ width: '95%', margin: '10px auto' }}
                    className='modal-bottom-right'
                  >
                    <Button
                      type='button'
                      className='reflect-button btn'
                      onClick={() => {
                        validateForm().then(response => {
                          if (!Object.keys(response).length) {
                            setFieldValue('confirm', true)
                          } else {
                            setTouched(response)
                          }
                        })
                      }}
                      handleNoClick={() => {
                        setFieldValue('confirm', false)
                      }}
                      label={i18n.t('transportApplication.submit')}
                    />
                  </div>
                </div>
              </div>
              <ConfirmationModal
                {...formikProps}
                confirmationMessage={confirmationMessage}
              />
            </>
          )
        }
        return (
          <>
            <div className='container is-medium'>
              <div className='box'>
                <div className='transport'>
                  <div className='staff-header' style={{ marginBottom: '0px' }}>
                    <div className='staff-title'>
                      通勤経路登録
                      <BreadCrumb items={[{ title: '通勤経路登録' }]} />
                    </div>
                  </div>
                  <div className='calendar-container'>
                    <div className='mg-bot-15'>
                      <Link
                        to='#'
                        style={{ color: '#40a9ff' }}
                        onClick={() => {
                          validateForm().then(() => {
                            setFieldValue('showNote', true)
                          })
                        }}
                      >
                        {i18n.t('transportApplication.note')}
                      </Link>
                      <TransportRegisterModal
                        {...formikProps}
                        name='showNote'
                      />
                    </div>
                    <div>
                      <table
                        className='table'
                        style={{ marginBottom: '10px', textAlign: 'center' }}
                      >
                        <thead>
                          <tr>
                            <th width='8%'>{i18n.t('main.clinicName')}</th>
                            <th width='15%'>
                              {i18n.t('transportApplication.routeURL')}
                              <Link
                                to='#'
                                style={{ color: '#40a9ff' }}
                                onClick={() => {
                                  validateForm().then(() => {
                                    setFieldValue('showModalUrl', true)
                                  })
                                }}
                              >
                                ※
                              </Link>
                              <TransportRegisterModal
                                {...formikProps}
                                name='showModalUrl'
                              />
                            </th>

                            <th width='12%'>
                              {i18n.t('transportApplication.departurePlace')}
                              <Link
                                to='#'
                                style={{ color: '#40a9ff' }}
                                onClick={() => {
                                  validateForm().then(() => {
                                    setFieldValue(
                                      'showModalDeparturePlace',
                                      true
                                    )
                                  })
                                }}
                              >
                                ※
                              </Link>
                              <TransportRegisterModal
                                {...formikProps}
                                name='showModalDeparturePlace'
                              />
                            </th>
                            <th width='12%'>
                              {i18n.t('transportApplication.arrivalPlace')}
                              <Link
                                to='#'
                                style={{ color: '#40a9ff' }}
                                onClick={() => {
                                  validateForm().then(() => {
                                    setFieldValue('showModalArrivalPlace', true)
                                  })
                                }}
                              >
                                ※
                              </Link>
                              <TransportRegisterModal
                                {...formikProps}
                                name='showModalArrivalPlace'
                              />
                            </th>
                            <th width='10%'>
                              {i18n.t('transportApplication.fareOneway')}
                              <Link
                                to='#'
                                style={{ color: '#40a9ff' }}
                                onClick={() => {
                                  validateForm().then(() => {
                                    setFieldValue('showModalCost', true)
                                  })
                                }}
                              >
                                ※
                              </Link>
                              <TransportRegisterModal
                                {...formikProps}
                                name='showModalCost'
                              />
                            </th>

                            <th width='5%'>
                              {i18n.t('transportApplication.roundTripRegister')}
                            </th>
                            <th width='5%'>
                              {i18n.t('transportApplication.totalCost')}
                            </th>
                            <th width='15%'>
                              {i18n.t('transportApplication.startDate')}
                            </th>
                            {(dataTransport.length > 0 ||
                              values.splits.length > 0) && <th width='5%' />}
                          </tr>
                        </thead>
                        <FieldArray
                          name='splits'
                          render={arrayHelpers => (
                            <tbody>
                              {values.splits.map((slipt, index) => {
                                const endtime = slipt.endTime
                                  ? slipt.endTime
                                  : endTime(
                                      slipt.clinic,
                                      slipt.startTime,
                                      values
                                    )

                                const currentTime = moment.utc().valueOf()
                                const format = endtime
                                  ? moment(endtime).format('YYYY年MM月DD日まで')
                                  : null
                                let isPast = false
                                const startTimeAddMonth = moment
                                  .utc(slipt.startTime)
                                  .startOf('months')
                                  .add(1, 'months')
                                  .add(10, 'days')
                                if (
                                  startTimeAddMonth.isBefore(currentTime) &&
                                  !!endtime
                                ) {
                                  isPast = true
                                }

                                return (
                                  <>
                                    <tr
                                      key={index}
                                      className={
                                        slipt.isPast || isPast
                                          ? 'background-grey'
                                          : null
                                      }
                                    >
                                      <td
                                        className='tranport'
                                        rowSpan={slipt.isCheckBox ? 2 : null}
                                      >
                                        <AutoComplete
                                          {...formikProps}
                                          register
                                          startTimeAddMonth={startTimeAddMonth}
                                          currentTime={currentTime}
                                          slipt={slipt}
                                          disabled={slipt.isPast || isPast}
                                          index={index}
                                          name={`splits[${index}].clinic`}
                                          field='name'
                                          allSuggestions={clinicals}
                                          suggestionName='clinicals'
                                          width='30vw'
                                          maxWidth='174px'
                                          placeholder={
                                            clinicals.length > 0
                                              ? 'クリニックを選択'
                                              : '対象データなし'
                                          }
                                        />
                                        <ErrorMessage
                                          name={`splits[${index}].clinic`}
                                          component='div'
                                          className='signup-alert'
                                        />
                                        <div className='end-time-transport'>
                                          {slipt.acceptedBy
                                            ? moment
                                                .utc(slipt.createdAt)
                                                .format(
                                                  'YYYY年MM月DD日 事務局入力'
                                                )
                                            : null}
                                        </div>
                                      </td>
                                      <td>
                                        <Input
                                          className='color-url'
                                          placeholder='https://www.navitime.co.jp/'
                                          allowClear
                                          disabled={slipt.isPast || isPast}
                                          name={`splits[${index}].url`}
                                          value={values.splits[index].url}
                                          onChange={e => {
                                            if (
                                              slipt.id &&
                                              startTimeAddMonth < currentTime
                                            ) {
                                              addSplit(
                                                setFieldValue,
                                                values,
                                                slipt,
                                                index
                                              )
                                            } else {
                                              setFieldValue(
                                                `splits[${index}].url`,
                                                e.target.value
                                              )
                                            }
                                          }}
                                        />
                                        <ErrorMessage
                                          name={`splits[${index}].url`}
                                          component='div'
                                          className='signup-alert'
                                        />
                                      </td>
                                      <td>
                                        <Input
                                          placeholder='例：東京駅'
                                          allowClear
                                          disabled={slipt.isPast || isPast}
                                          name={`splits[${index}].departurePlace`}
                                          value={
                                            values.splits[index].departurePlace
                                          }
                                          onChange={e => {
                                            if (
                                              slipt.id &&
                                              startTimeAddMonth < currentTime
                                            ) {
                                              addSplit(
                                                setFieldValue,
                                                values,
                                                slipt,
                                                index
                                              )
                                            } else {
                                              setFieldValue(
                                                `splits[${index}].departurePlace`,
                                                e.target.value
                                              )
                                            }
                                          }}
                                        />
                                        <ErrorMessage
                                          name={`splits[${index}].departurePlace`}
                                          component='div'
                                          className='signup-alert'
                                        />
                                      </td>
                                      <td>
                                        <Input
                                          placeholder='例：新宿駅バス停'
                                          allowClear
                                          disabled={slipt.isPast || isPast}
                                          name={`splits[${index}].arrivalPlace`}
                                          value={
                                            values.splits[index].arrivalPlace
                                          }
                                          onChange={e => {
                                            if (
                                              slipt.id &&
                                              startTimeAddMonth < currentTime
                                            ) {
                                              addSplit(
                                                setFieldValue,
                                                values,
                                                slipt,
                                                index
                                              )
                                            } else {
                                              setFieldValue(
                                                `splits[${index}].arrivalPlace`,
                                                e.target.value
                                              )
                                            }
                                          }}
                                        />
                                        <ErrorMessage
                                          name={`splits[${index}].arrivalPlace`}
                                          component='div'
                                          className='signup-alert'
                                        />
                                      </td>
                                      <td>
                                        <InputNumber
                                          disabled={slipt.isPast || isPast}
                                          placeholder='￥ 300'
                                          formatter={value =>
                                            `￥ ${value}`.replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ','
                                            )
                                          }
                                          type='tel'
                                          allowClear
                                          min={0}
                                          step={5}
                                          value={values.splits[index].cost === null ? 0 : values.splits[index].cost}
                                          name={`splits[${index}].cost`}
                                          onChange={e => {
                                            if (
                                              slipt.id &&
                                              startTimeAddMonth < currentTime
                                            ) {
                                              addSplit(
                                                setFieldValue,
                                                values,
                                                slipt,
                                                index
                                              )
                                            } else {
                                              setFieldValue(
                                                `splits[${index}].cost`,
                                                e
                                              )
                                              if (
                                                (values.splits[index]
                                                  .roundTrip &&
                                                values.splits[index].roundTrip
                                                  .costRoundTrip > 0
                                                  ? parseInt(
                                                      values.splits[index]
                                                        .roundTrip.costRoundTrip
                                                    )
                                                  : e) +
                                                  e >
                                                5000
                                              ) {
                                                setFieldValue(
                                                  `splits[${index}].isCost`,
                                                  true
                                                )
                                              } else {
                                                setFieldValue(
                                                  `splits[${index}].isCost`,
                                                  false
                                                )
                                              }
                                            }
                                          }}
                                        />

                                        <ErrorMessage
                                          name={`splits[${index}].cost`}
                                          component='div'
                                          className='signup-alert'
                                        />
                                      </td>
                                      <td>
                                        <Checkbox
                                          disabled={slipt.isPast || isPast}
                                          checked={slipt.isCheckBox}
                                          onChange={() => {
                                            if (
                                              slipt.id &&
                                              startTimeAddMonth < currentTime
                                            ) {
                                              addSplit(
                                                setFieldValue,
                                                values,
                                                slipt,
                                                index
                                              )
                                            } else {
                                              setFieldValue(
                                                `splits[${index}].isCheckBox`,
                                                !values.splits[index].isCheckBox
                                              )
                                              if (!slipt.isCheckBox) {
                                                setFieldValue(
                                                  `splits[${index}].roundTrip`,
                                                  {
                                                    urlRoundTrip: '',
                                                    departurePlaceRoundTrip: '',
                                                    arrivalPlaceRoundTrip: '',
                                                    costRoundTrip: '',
                                                  }
                                                )
                                              } else {
                                                setFieldValue(
                                                  `splits[${index}].roundTrip`,
                                                  null
                                                )
                                              }
                                            }
                                          }}
                                        />
                                      </td>

                                      <td rowSpan={slipt.isCheckBox ? 2 : null}>
                                        <div>
                                          {numeralCurrency(
                                            values.splits[index].cost +
                                              (values.splits[index].roundTrip &&
                                              values.splits[index].roundTrip
                                                .costRoundTrip > 0
                                                ? parseInt(
                                                    values.splits[index]
                                                      .roundTrip.costRoundTrip
                                                  )
                                                : values.splits[index].cost)
                                          )}
                                        </div>
                                        <ErrorMessage
                                          name={`splits[${index}].totalCost`}
                                          component='div'
                                          className='signup-alert'
                                        />
                                      </td>
                                      <td rowSpan={slipt.isCheckBox ? 2 : null}>
                                        <DatePicker
                                          disabled={slipt.isPast || isPast}
                                          format='YYYY年MM月DD日'
                                          locale={locale}
                                          // disabledDate={disabledDate}
                                          allowClear={false}
                                          value={
                                            values.splits[index].startTime
                                              ? moment.utc(
                                                  new Date(
                                                    values.splits[
                                                      index
                                                    ].startTime
                                                  ),
                                                  'YYYY年MM月DD日'
                                                )
                                              : moment.utc(
                                                  new Date(),
                                                  'YYYY年MM月DD日'
                                                )
                                          }
                                          onChange={value => {
                                            if (
                                              slipt.id &&
                                              startTimeAddMonth < currentTime
                                            ) {
                                              setFieldValue('splits', [
                                                ...values.splits,
                                                {
                                                  clinic: slipt.clinic,
                                                  url: slipt.url,
                                                  departurePlace:
                                                    slipt.departurePlace,
                                                  arrivalPlace:
                                                    slipt.arrivalPlace,
                                                  cost: slipt.cost,
                                                  isCheckBox: slipt.isCheckBox,
                                                  roundTrip: slipt.roundTrip
                                                    ? {
                                                        urlRoundTrip:
                                                          slipt.roundTrip
                                                            .urlRoundTrip,
                                                        departurePlaceRoundTrip:
                                                          slipt.roundTrip
                                                            .departurePlaceRoundTrip,
                                                        arrivalPlaceRoundTrip:
                                                          slipt.roundTrip
                                                            .arrivalPlaceRoundTrip,
                                                        costRoundTrip:
                                                          slipt.roundTrip
                                                            .costRoundTrip,
                                                      }
                                                    : null,
                                                  startTime: moment
                                                    .utc(value)
                                                    .startOf('day')
                                                    .toISOString(),
                                                },
                                              ])
                                              setFieldValue(
                                                `splits[${index}].endTime`,
                                                moment
                                                  .utc(value)
                                                  .startOf('month')
                                                  .subtract(1, 'days')
                                              )
                                              setFieldValue(
                                                `splits[${index}].isHistory`,
                                                moment()
                                              )
                                              setFieldValue(
                                                `splits[${index}].isPast`,
                                                true
                                              )
                                            } else {
                                              setFieldValue(
                                                `splits[${index}].startTime`,
                                                moment
                                                  .utc(value)
                                                  .startOf('day')
                                                  .toISOString()
                                              )
                                            }
                                          }}
                                        />
                                        <div className='end-time-transport'>
                                          {slipt.endTime
                                            ? moment
                                                .utc(slipt.endTime)
                                                .format('YYYY年MM月DD日まで')
                                            : format}
                                        </div>
                                      </td>
                                      {(dataTransport.length > 0 ||
                                        values.splits.length > 0) && (
                                        <td
                                          rowSpan={slipt.isCheckBox ? 2 : null}
                                        >
                                          <Button
                                            icon='pi pi-trash'
                                            onClick={() => {
                                              if (
                                                startTimeAddMonth < currentTime
                                              ) {
                                                setFieldValue(
                                                  `splits[${index}].isHidden`,
                                                  true
                                                )
                                                setFieldValue(
                                                  `splits[${index}].isHistory`,
                                                  moment.utc()
                                                )

                                                setFieldValue(
                                                  'functionName',
                                                  'hidden'
                                                )
                                                setFieldValue('confirm', true)
                                              } else if (slipt.id) {
                                                if (slipt.isPast || isPast) {
                                                  setFieldValue(
                                                    'functionName',
                                                    remove
                                                  )
                                                  values.transportationsId.push(
                                                    slipt.id
                                                  )
                                                  setFieldValue('confirm', true)
                                                } else {
                                                  arrayHelpers.remove(index)

                                                  values.transportationsId.push(
                                                    slipt.id
                                                  )
                                                }
                                              } else {
                                                arrayHelpers.remove(index)
                                              }
                                            }}
                                          />
                                        </td>
                                      )}
                                    </tr>
                                    {slipt.isCheckBox ? (
                                      <tr
                                        className={
                                          slipt.isPast || isPast
                                            ? 'background-grey'
                                            : null
                                        }
                                      >
                                        <td>
                                          <Input
                                            className='color-url'
                                            placeholder='https://www.navitime.co.jp/'
                                            allowClear
                                            disabled={slipt.isPast || isPast}
                                            name={`splits[${index}].roundTrip.urlRoundTrip`}
                                            value={
                                              values.splits[index].roundTrip
                                                .urlRoundTrip
                                            }
                                            onChange={e => {
                                              if (
                                                slipt.id &&
                                                startTimeAddMonth < currentTime
                                              ) {
                                                addSplit(
                                                  setFieldValue,
                                                  values,
                                                  slipt,
                                                  index
                                                )
                                              } else {
                                                setFieldValue(
                                                  `splits[${index}].roundTrip.urlRoundTrip`,
                                                  e.target.value
                                                )
                                              }
                                            }}
                                          />
                                          <ErrorMessage
                                            name={`splits[${index}].roundTrip.urlRoundTrip`}
                                            component='div'
                                            className='signup-alert'
                                          />
                                        </td>
                                        <td>
                                          <Input
                                            placeholder='例：東京駅'
                                            allowClear
                                            disabled={slipt.isPast || isPast}
                                            name={`splits[${index}].roundTrip.departurePlaceRoundTrip`}
                                            value={
                                              values.splits[index].roundTrip
                                                .departurePlaceRoundTrip
                                            }
                                            onChange={e => {
                                              if (
                                                slipt.id &&
                                                startTimeAddMonth < currentTime
                                              ) {
                                                addSplit(
                                                  setFieldValue,
                                                  values,
                                                  slipt,
                                                  index
                                                )
                                              } else {
                                                setFieldValue(
                                                  `splits[${index}].roundTrip.departurePlaceRoundTrip`,
                                                  e.target.value
                                                )
                                              }
                                            }}
                                          />
                                          <ErrorMessage
                                            name={`splits[${index}].roundTrip.departurePlaceRoundTrip`}
                                            component='div'
                                            className='signup-alert'
                                          />
                                        </td>
                                        <td>
                                          <Input
                                            placeholder='例：新宿駅バス停'
                                            allowClear
                                            disabled={slipt.isPast || isPast}
                                            name={`splits[${index}].roundTrip.arrivalPlaceRoundTrip`}
                                            value={
                                              values.splits[index].roundTrip
                                                .arrivalPlaceRoundTrip
                                            }
                                            onChange={e => {
                                              if (
                                                slipt.id &&
                                                startTimeAddMonth < currentTime
                                              ) {
                                                addSplit(
                                                  setFieldValue,
                                                  values,
                                                  slipt,
                                                  index
                                                )
                                              } else {
                                                setFieldValue(
                                                  `splits[${index}].roundTrip.arrivalPlaceRoundTrip`,
                                                  e.target.value
                                                )
                                              }
                                            }}
                                          />
                                          <ErrorMessage
                                            name={`splits[${index}].roundTrip.arrivalPlaceRoundTrip`}
                                            component='div'
                                            className='signup-alert'
                                          />
                                        </td>
                                        <td>
                                          <InputNumber
                                            disabled={slipt.isPast || isPast}
                                            placeholder='￥ 300'
                                            formatter={value =>
                                              `￥ ${value}`.replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ','
                                              )
                                            }
                                            type='tel'
                                            allowClear
                                            min={0}
                                            step={5}
                                            value={
                                              values.splits[index].roundTrip
                                                .costRoundTrip
                                            }
                                            name={`splits[${index}].roundTrip.costRoundTrip`}
                                            onChange={value => {
                                              if (
                                                slipt.id &&
                                                startTimeAddMonth < currentTime
                                              ) {
                                                addSplit(
                                                  setFieldValue,
                                                  values,
                                                  slipt,
                                                  index
                                                )
                                              } else {
                                                setFieldValue(
                                                  `splits[${index}].roundTrip.costRoundTrip`,
                                                  value
                                                )
                                                if (
                                                  values.splits[index].cost +
                                                    value >
                                                  5000
                                                ) {
                                                  setFieldValue(
                                                    `splits[${index}].isCost`,
                                                    true
                                                  )
                                                } else {
                                                  setFieldValue(
                                                    `splits[${index}].isCost`,
                                                    false
                                                  )
                                                }
                                              }
                                            }}
                                          />

                                          <ErrorMessage
                                            name={`splits[${index}].roundTrip.costRoundTrip`}
                                            component='div'
                                            className='signup-alert'
                                          />
                                        </td>
                                        <td />
                                      </tr>
                                    ) : null}
                                  </>
                                )
                              })}
                            </tbody>
                          )}
                        />
                      </table>

                      <div style={{ textAlign: 'center' }}>
                        <Button
                          icon='pi pi-plus'
                          onClick={() => {
                            setFieldValue('splits', [
                              ...values.splits,
                              {
                                id: '',
                                clinic: '',
                                url: '',
                                departurePlace: '',
                                arrivalPlace: '',
                                cost: '',
                                roundTrip: null,
                                startTime: moment(new Date(), 'YYYY年MM月DD日'),
                              },
                            ])
                          }}
                          label={i18n.t('main.add')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{ width: '95%', margin: '10px auto' }}
                  className='modal-bottom-right'
                >
                  <Button
                    type='button'
                    className='reflect-button btn'
                    onClick={() => {
                      validateForm().then(response => {
                        if (!Object.keys(response).length) {
                          setFieldValue('confirm', true)
                        } else {
                          setTouched(response)
                        }
                      })
                    }}
                    handleNoClick={() => {
                      setFieldValue('confirm', false)
                    }}
                    label={i18n.t('transportApplication.submit')}
                  />
                </div>
              </div>
            </div>
            <ConfirmationModal
              {...formikProps}
              confirmationMessage={confirmationMessage}
            />
          </>
        )
      }}
    />
  )
}
export default TransportRegisterScene
