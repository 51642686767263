import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import { CLINIC_FRAGMENT } from '@medical/pages/staff/Clinics/ClinicsList/ClinicsList.graphql'
import gql from 'graphql-tag'

export const STAFF_ME = gql`
  query STAFF_ME {
    staffMe {
      id
      email
    }
    corporations {
      id
      name
    }
  }
`

export const CREATE_CLINIC = gql`
  mutation CREATE_CLINIC(
    $clinicName: String!
    $address: String!
    $email: String!
    $nameKana: String!
    $order: Int!
    $startTimeMonday: DateTime
    $endTimeMonday: DateTime
    $startTimeTuesday: DateTime
    $endTimeTuesday: DateTime
    $startTimeWednesday: DateTime
    $endTimeWednesday: DateTime
    $startTimeThursday: DateTime
    $endTimeThursday: DateTime
    $startTimeFriday: DateTime
    $endTimeFriday: DateTime
    $startTimeSaturday: DateTime
    $endTimeSaturday: DateTime
    $startTimeSunday: DateTime
    $endTimeSunday: DateTime
    $startTimeHoliday: DateTime
    $endTimeHoliday: DateTime
    $isAvailableOnMonday: Boolean!
    $isAvailableOnTuesday: Boolean!
    $isAvailableOnWednesday: Boolean!
    $isAvailableOnThursday: Boolean!
    $isAvailableOnFriday: Boolean!
    $isAvailableOnSaturday: Boolean!
    $isAvailableOnSunday: Boolean!
    $isAvailableOnHoliday: Boolean!
    $isPublished: Boolean!
    $isOutside: Boolean!
    $isSearchDisplay: Boolean!
    $messageIntroduce: String
    $type: ClinicType
    $nearestStations: String
    $corporationId: ID!
    $clinicalDepartments: [ClinicalDepartmentCreateWithoutClinicInput]!
  ) {
    createClinic(
      clinicName: $clinicName
      address: $address
      email: $email
      nameKana: $nameKana
      order: $order
      startTimeMonday: $startTimeMonday
      endTimeMonday: $endTimeMonday
      startTimeTuesday: $startTimeTuesday
      endTimeTuesday: $endTimeTuesday
      startTimeWednesday: $startTimeWednesday
      endTimeWednesday: $endTimeWednesday
      startTimeThursday: $startTimeThursday
      endTimeThursday: $endTimeThursday
      startTimeFriday: $startTimeFriday
      endTimeFriday: $endTimeFriday
      startTimeSaturday: $startTimeSaturday
      endTimeSaturday: $endTimeSaturday
      startTimeSunday: $startTimeSunday
      endTimeSunday: $endTimeSunday
      startTimeHoliday: $startTimeHoliday
      endTimeHoliday: $endTimeHoliday
      isAvailableOnMonday: $isAvailableOnMonday
      isAvailableOnTuesday: $isAvailableOnTuesday
      isAvailableOnWednesday: $isAvailableOnWednesday
      isAvailableOnThursday: $isAvailableOnThursday
      isAvailableOnFriday: $isAvailableOnFriday
      isAvailableOnSaturday: $isAvailableOnSaturday
      isAvailableOnSunday: $isAvailableOnSunday
      isAvailableOnHoliday: $isAvailableOnHoliday
      isPublished: $isPublished
      isOutside: $isOutside
      messageIntroduce: $messageIntroduce
      type: $type
      nearestStations: $nearestStations
      isSearchDisplay: $isSearchDisplay
      corporationId: $corporationId
      clinicalDepartments: $clinicalDepartments
    )
  }
`

export const CLINIC_LIST = gql`
  query Clinics($where: ClinicWhereInput) {
    clinics(where: $where) {
      id
      name
      nameKana
      isPublished
      clinicalDepartments {
        id
        name
        departmentCode
        isDisplay
      }
    }
  }
`

export const UPDATE_CLINIC = gql`
  mutation UPDATE_CLINIC(
    $clinicId: ID!
    $isCreateInternal: Boolean
    $isCreateChildren: Boolean
    $isCreateInternalVaccine: Boolean
    $isCreateChildrenVaccine: Boolean
    $isUpdateInternal: Boolean
    $isUpdateChildren: Boolean
    $isUpdateChildrenVaccine: Boolean
    $isUpdateInternalVaccine: Boolean
    $internalCode: String
    $isDisplayInternal: Boolean
    $childrenCode: String
    $isDisplayChildren: Boolean
    $internalVaccineCode: String
    $isDisplayInternalVaccine: Boolean
    $childrenVaccineCode: String
    $isDisplayChildrenVaccine: Boolean
  ) {
    updateClinic(
      clinicId: $clinicId
      isCreateInternal: $isCreateInternal
      isCreateChildren: $isCreateChildren
      isCreateInternalVaccine: $isCreateInternalVaccine
      isCreateChildrenVaccine: $isCreateChildrenVaccine
      isUpdateInternal: $isUpdateInternal
      isUpdateChildren: $isUpdateChildren
      isUpdateChildrenVaccine: $isUpdateChildrenVaccine
      isUpdateInternalVaccine: $isUpdateInternalVaccine
      internalCode: $internalCode
      isDisplayInternal: $isDisplayInternal
      childrenCode: $childrenCode
      isDisplayChildren: $isDisplayChildren
      internalVaccineCode: $internalVaccineCode
      isDisplayInternalVaccine: $isDisplayInternalVaccine
      childrenVaccineCode: $childrenVaccineCode
      isDisplayChildrenVaccine: $isDisplayChildrenVaccine
    )
  }
`
