import { ANNOUNCE_FRAGMENT } from '@medical/pages/staff/Announces/AnnouncesList/AnnouncesList.graphql'
import gql from 'graphql-tag'

export const UPDATE_RECRUITMENT_POST = gql`
  mutation UPDATE_RECRUITMENT_POST(
    $id: ID!
    $title: String!
    $content: String!
    $isPublished: Boolean!
    $recruitmentDoctorType: WorkPattern!
    $clinicalDepartmentId: ID!
    $image: String
    $imageKeyUpdate: String
  ) {
    staffUpdateRecruitmentPost(
      id: $id
      title: $title
      content: $content
      isPublished: $isPublished
      recruitmentDoctorType: $recruitmentDoctorType
      clinicalDepartmentId: $clinicalDepartmentId
      image: $image
      imageKeyUpdate: $imageKeyUpdate
    )
  }
`

export const DELETE_RECRUITMENT_POST = gql`
  mutation DELETE_RECRUITMENT_POST($id: ID!) {
    staffDeleteRecruitmentPost(id: $id)
  }
`

export const SINGLE_RECRUITMENT_POST = gql`
  query Query($where: RecruitmentPostWhereUniqueInput!) {
    recruitmentPost(where: $where) {
      id
      content
      title
      isPublished
      recruitmentDoctorType
      createdAt
      updatedAt
      imageKey
      clinicalDepartment {
        id
        name
        extraName
        departmentCode
        isDisplay
        clinic {
          id
          name
          order
          isPublished
        }
      }
    }
  }
`
