import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, NotFound, ProgressSpinner } from '@medical/components'
import { INCENTIVE_TYPE } from '@medical/constant'
import {
  checkStaffPermission,
  clinicalDepartmentNames,
  combineNames,
  convertDoctorsConnection,
  sortAndFilterByClinicOrder,
} from '@medical/libs'
import {
  STAFF_UPDATE_INCENTIVE_PERMISSIONS,
  STAFF_DELETE_INCENTIVE_PERMISSIONS,
} from '@medical/constant/permissions'
import {
  DEPARTMENTS_LIST,
  DOCTOR_CONNECTION,
} from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import { getListIdAndWorkPartten } from '@medical/pages/staff/Incentive/IncentiveSetting/component/commonFunction'
import {
  GET_INCENTIVE_BY_ID,
  STAFF_DELETE_INCENTIVE,
  STAFF_UPDATE_INCENTIVE,
  CREATE_ACTIVITY,
} from '@medical/pages/staff/Incentive/IncentiveSetting/component/incentiveSetting.graphql'
import IncentiveSettingForm from '@medical/pages/staff/Incentive/IncentiveSetting/component/IncentiveSettingForm'
import { GET_DOCTORLIST } from '@medical/pages/staff/Incentive/IncentiveSetting/IncentiveSettingList.graphql'
import { useCustom } from '@medical/provider/context'
import moment from 'moment'
import React, { useEffect } from 'react'

const DetailIncentiveSetting = ({
  match: {
    params: { id },
  },
  closeModal,
}) => {
  const [{ i18n, popup }] = useCustom()
  const { loading, error, data } = useQuery(DEPARTMENTS_LIST)
  const [staffUpdateIncentive] = useMutation(STAFF_UPDATE_INCENTIVE)
  const [staffDeleteIncentive] = useMutation(STAFF_DELETE_INCENTIVE)
  const [staffCreateActivity] = useMutation(CREATE_ACTIVITY)
  const isUpdatePermitted = checkStaffPermission({
    functionName: STAFF_UPDATE_INCENTIVE_PERMISSIONS,
  })
  const isDeletePermitted = checkStaffPermission({
    functionName: STAFF_DELETE_INCENTIVE_PERMISSIONS,
  })
  const variables = {
    where: {
      id,
    },
  }
  const {
    loading: loadingIncentive,
    error: errorIncentive,
    data: dataIncentive,
    refetch,
  } = useQuery(GET_INCENTIVE_BY_ID, {
    variables,
  })
  const {
    loading: dataDoctorConnectionLoading,
    error: dataDoctorConnectionError,
    data: dataDoctorConnection,
  } = useQuery(DOCTOR_CONNECTION, {
    variables: {
      where: {
        deletedAt: null,
      },
      first: 10,
      skip: 0,
      orderBy: 'unSigned_ASC',
    },
  })
  const {
    loading: doctorSelectedLoading,
    error: doctorSelectedError,
    data: doctorSelectedData,
    refetch: refetchDoctorSelectedData,
  } = useQuery(GET_DOCTORLIST, {
    variables: {
      where: {
        id,
      },
      incentiveDetailsWhere2: {
        Doctor: {
          deletedAt: null,
        },
      },
    },
  })
  useEffect(() => {
    refetchDoctorSelectedData()
  }, [])
  useEffect(() => {
    refetch()
  }, [dataIncentive])
  if (
    loading ||
    loadingIncentive ||
    dataDoctorConnectionLoading ||
    doctorSelectedLoading
  )
    return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  if (errorIncentive) return <ErrorComponent error={error} />
  if (dataDoctorConnectionError)
    return <ErrorComponent error={dataDoctorConnectionError} />
  if (doctorSelectedError) return <ErrorComponent error={doctorSelectedError} />
  if (
    !dataIncentive &&
    !dataIncentive.incentive &&
    !doctorSelectedData.incentive.incentiveDetails
  )
    return <NotFound />
  const { clinicalDepartments, departments } = data
  const sortedClinicalDepartments = sortAndFilterByClinicOrder({
    clinicalDepartments,
    isFilter: true,
  })

  const departmentNames = clinicalDepartmentNames({ departments })
  const { incentive } = dataIncentive

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    let {
      title,
      workPartten,
      objectTarget,
      doctor,
      rangeDate,
      cost,
      action,
      incentiveType,
    } = values
    try {
      const listDoctorId = []
      if (doctor && Array.isArray(doctor)) {
        for (let i = 0; i < doctor.length; i += 1) {
          listDoctorId.push(doctor[i].id)
        }
      }
      const startTimeFormat = moment(rangeDate[0])
        .startOf('month')
        .format()
        .split('+')[0]
      const endTimeFormat =
        rangeDate.length > 1
          ? moment(rangeDate[1])
              .startOf('month')
              .format()
              .split('+')[0]
          : moment(rangeDate[0])
              .startOf('month')
              .format()
              .split('+')[0]
      if (action === 'delete' && incentive) {
        if (
          moment(incentive.startTime).isBefore(moment().format('YYYY-MM')) &&
          incentiveType !== INCENTIVE_TYPE.NORMAL_INCENTIVE
        ) {
          // const monthUpdate = moment(
          //   moment().format('YYYY-MM-DD HH:mm:ss')
          // ).isBefore(
          //   moment()
          //     .startOf('month')
          //     .add(15, 'day')
          //     .format('YYYY-MM-DD')
          // )
          //   ? moment()
          //       .startOf('month')
          //       .subtract(2, 'month')
          //       .format('YYYY-MM-DD')
          //   : moment()
          //       .startOf('month')
          //       .subtract(1, 'month')
          //       .format('YYYY-MM-DD')

          const monthUpdate = moment()
            .startOf('month')
            .subtract(1, 'month')
            .format('YYYY-MM-DD')
          await staffUpdateIncentive({
            variables: {
              incentiveId: id,
              title: incentive.title,
              applyWith: incentive.applyWith,
              workPatternApply:
                doctorSelectedData &&
                doctorSelectedData.incentive &&
                doctorSelectedData.incentive.incentiveDetails
                  ? getListIdAndWorkPartten(
                      doctorSelectedData.incentive.incentiveDetails
                    ).workPartten
                  : [],
              listDoctorId:
                doctorSelectedData &&
                doctorSelectedData.incentive &&
                doctorSelectedData.incentive.incentiveDetails
                  ? getListIdAndWorkPartten(
                      doctorSelectedData.incentive.incentiveDetails
                    ).listDoctorId
                  : [],
              startTime: incentive.startTime.split('.')[0],
              endTime: monthUpdate,
              amount: incentive.amount,
            },
          })
          staffCreateActivity({
            variables: {
              activity: 'STAFF_UPDATE_INCENTIVE_SETTINGS',
            },
          })
        } else {
          await staffDeleteIncentive({
            variables: {
              incentiveId: id,
            },
          })
          staffCreateActivity({
            variables: {
              activity: 'STAFF_DELETE_INCENTIVE_SETTINGS',
            },
          })
        }
      } else {
        await staffUpdateIncentive({
          variables: {
            incentiveId: id,
            title,
            applyWith: objectTarget.value,
            workPatternApply: workPartten,
            listDoctorId,
            startTime:
              incentive.type === INCENTIVE_TYPE.NORMAL_INCENTIVE
                ? endTimeFormat
                : startTimeFormat,
            endTime: endTimeFormat,
            amount: parseInt(cost),
          },
        })
        staffCreateActivity({
          variables: {
            activity: 'STAFF_UPDATE_INCENTIVE_SETTINGS',
          },
        })
      }
      popup.success(i18n.t('staff.createShift.submissionSuccess'))
      closeModal()
      setSubmitting(false)
      resetForm()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }
  const { edges } = dataDoctorConnection.doctorsConnection
  return (
    <IncentiveSettingForm
      onSubmit={onSubmit}
      clinicalDepartments={combineNames({
        clinicalDepartments: sortedClinicalDepartments,
        departmentNames,
      })}
      node={incentive}
      confirmationMessage={i18n.t('main.incentiveSetting.actionMessage.update')}
      i18n={i18n}
      // doctors={convertDoctors(doctors)}
      doctors={convertDoctorsConnection(edges)}
      doctorsSelected={doctorSelectedData}
      isUpdatePermitted={isUpdatePermitted}
      isDeletePermitted={isDeletePermitted}
    />
  )
}

export default DetailIncentiveSetting
