import gql from 'graphql-tag'
export const UPDATE_INCENTIVE_DETAIL = gql`
mutation STAFF_UPDATE_INCENTIVE_DETAIL(
    $incentiveDetailId: ID!
    $incentiveTotalAmount: Int
  ) {
    staffUpdateIncentiveDetail(
      incentiveDetailId: $incentiveDetailId
      incentiveTotalAmount:$incentiveTotalAmount
    ) 
  }
`
export const GET_INCENTIVE_BY_ID = gql`
  query IncentiveDetailsConnection(
    $where: IncentiveDetailWhereInput
  ) {
    incentiveDetailsConnection(
      where: $where
    ) {
      edges {
        node {
          id
          createdAt
          applyTime
          incentiveTotalAmount
          doctor {
            id
            lastname
            firstname
          }
          incentive {
            id
            type
            title
          }
        }
      }
    }
  }
`

export const CREATE_ACTIVITY = gql`
  mutation CREATE_ACTIVITY_LOG($activity: ActivityLogTypes!, $doctorId: ID) {
    createActivityLogHistory(activity: $activity, doctorId: $doctorId)
  }
`