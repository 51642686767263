import { Dropdown } from 'primereact/dropdown'
import React from 'react'

const CustomDropdown = ({
  label,
  values,
  name,
  options = [],
  setFieldValue,
  optionLabel = 'description',
  valueLabel = 'value',
  disabled,
  classDisabled,
  type,
  required,
  defaultTitle,
}) => (
  <>
    <div className={` ${required ? 'required' : ''}`}>{label}</div>
    <div className=''>
      <Dropdown
        name={name}
        value={options.find(option => {
          const value = type ? values[name][valueLabel] : values[name]
          return option[valueLabel] === value
        })}
        options={options}
        optionLabel={optionLabel}
        onChange={e => {
          const value = type ? e.value : e.value[valueLabel]
          setFieldValue(name, value)
        }}
        disabled={disabled}
        className={classDisabled && disabled ? 'is-disabled' : ''}
        placeholder={defaultTitle}
      />
    </div>
  </>
)

export default CustomDropdown
