import React, { useEffect } from 'react'
import RecruitmentPostApplyScene from './RecruitmentPostApplyScene'
import { useCustom } from '@medical/provider/context'
import { clinicalDepartmentNames } from '@medical/libs'
import { parseJwt } from '@medical/constant/utilities'
import { ErrorComponent, NotFound, ProgressSpinner } from '@medical/components'
import { useMutation, useQuery } from '@apollo/react-hooks'

import {
  DOCTOR_RECRUITMENT_DOCTOR,
  DOCTOR_APPLY_RECRUITMENT_DOCTOR,
  DOCTOR_UPDATE_RECRUITMENT_DOCTOR,
  DOCTOR_DELETE_RECRUITMENT_DOCTOR,
} from './RecruitmentPostApply.grapql'
import { DOCTOR_RECRUITMENT_POST } from '../RecruitmentPostDetail/RecruitmentPostDetail.graphql'
import { DOCTOR_ME } from '@medical/layout/DoctorLayout/DoctorLayoutRequest.graphql'
const RecruitmentPostApply = ({ closeModal, match: { url, params } }) => {
  const [{ i18n, popup, isDoctorBlocked }] = useCustom()
  const postId = url.split('/')[url.split('/').length - 2]
  const { doctorId } = parseJwt(localStorage.getItem('medical_doctor_token'))
  const [applyRecruitment] = useMutation(DOCTOR_APPLY_RECRUITMENT_DOCTOR)
  const [editApplyRecruitment] = useMutation(DOCTOR_UPDATE_RECRUITMENT_DOCTOR)
  const [deleteRecruitment] = useMutation(DOCTOR_DELETE_RECRUITMENT_DOCTOR)
  const { loading, error, data, refetch } = useQuery(DOCTOR_ME)
  const {
    loading: postLoad,
    error: postError,
    data: postData,
    refetch: postDataRefetch,
  } = useQuery(DOCTOR_RECRUITMENT_POST, {
    variables: {
      where: {
        id: postId,
      },
    },
  })
  const {
    loading: recruitmentDoctorLoading,
    error: recruitmentDoctorError,
    data: recruitmentDoctorData,
    refetch: recruitmentDoctorRefetch,
  } = useQuery(DOCTOR_RECRUITMENT_DOCTOR, {
    variables: {
      where: {
        AND: [
          {
            Doctor: {
              id: doctorId,
              deletedAt: null,
            },
            RecruitmentPost: {
              id: postId,
              isPublished: true,
            },
          },
        ],
      },
    },
  })
  useEffect(() => {
    refetch()
    postDataRefetch()
    recruitmentDoctorRefetch()
  })
  if (loading || postLoad || recruitmentDoctorLoading)
    return <ProgressSpinner />
  if (error || postError || recruitmentDoctorError)
    return <ErrorComponent error={error || postError} />
  if (isDoctorBlocked) {
    return <NotFound />
  }
  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      if (values.functionName === 'create') {
        await applyRecruitment({
          variables: {
            remark: values.remark ? values.remark.trim() : null,
            recruitmentPostId: postId,
            desireStartMoment: values.noteOpenTime.trim(),
            startTimeMonday: values.isOnMonday ? values.startTimeMonday : null,
            endTimeMonday: values.isOnMonday ? values.endTimeMonday : null,
            startTimeTuesday: values.isOnTuesday
              ? values.startTimeTuesday
              : null,
            endTimeTuesday: values.isOnTuesday ? values.endTimeTuesday : null,
            startTimeWednesday: values.isOnWednesday
              ? values.startTimeWednesday
              : null,
            endTimeWednesday: values.isOnWednesday
              ? values.endTimeWednesday
              : null,
            startTimeThursday: values.isOnThursday
              ? values.startTimeThursday
              : null,
            endTimeThursday: values.isOnThursday
              ? values.endTimeThursday
              : null,
            startTimeFriday: values.isOnFriday ? values.startTimeFriday : null,
            endTimeFriday: values.isOnFriday ? values.endTimeFriday : null,
            startTimeSaturday: values.isOnSaturday
              ? values.startTimeSaturday
              : null,
            endTimeSaturday: values.isOnSaturday
              ? values.endTimeSaturday
              : null,
            startTimeSunday: values.isOnSunday ? values.startTimeSunday : null,
            endTimeSunday: values.isOnSunday ? values.endTimeSunday : null,
            startTimeHoliday: values.isOnHoliday
              ? values.startTimeHoliday
              : null,
            endTimeHoliday: values.isOnHoliday ? values.endTimeHoliday : null,
          },
        })
      } else if (values.functionName === 'update') {
        await editApplyRecruitment({
          variables: {
            remark: values.remark ? values.remark.trim() : null,
            recruitmentDoctorId: recruitmentDoctorData.recruitmentDoctors[0].id,
            desireStartMoment: values.noteOpenTime.trim(),
            startTimeMonday: values.isOnMonday ? values.startTimeMonday : null,
            endTimeMonday: values.isOnMonday ? values.endTimeMonday : null,
            startTimeTuesday: values.isOnTuesday
              ? values.startTimeTuesday
              : null,
            endTimeTuesday: values.isOnTuesday ? values.endTimeTuesday : null,
            startTimeWednesday: values.isOnWednesday
              ? values.startTimeWednesday
              : null,
            endTimeWednesday: values.isOnWednesday
              ? values.endTimeWednesday
              : null,
            startTimeThursday: values.isOnThursday
              ? values.startTimeThursday
              : null,
            endTimeThursday: values.isOnThursday
              ? values.endTimeThursday
              : null,
            startTimeFriday: values.isOnFriday ? values.startTimeFriday : null,
            endTimeFriday: values.isOnFriday ? values.endTimeFriday : null,
            startTimeSaturday: values.isOnSaturday
              ? values.startTimeSaturday
              : null,
            endTimeSaturday: values.isOnSaturday
              ? values.endTimeSaturday
              : null,
            startTimeSunday: values.isOnSunday ? values.startTimeSunday : null,
            endTimeSunday: values.isOnSunday ? values.endTimeSunday : null,
            startTimeHoliday: values.isOnHoliday
              ? values.startTimeHoliday
              : null,
            endTimeHoliday: values.isOnHoliday ? values.endTimeHoliday : null,
          },
        })
      } else if (values.functionName === 'delete') {
        await deleteRecruitment({
          variables: {
            recruitmentDoctorId: recruitmentDoctorData.recruitmentDoctors[0].id,
          },
        })
      }
      popup.success(i18n.t('staff.updateClinic.submissionSuccess'))
      setSubmitting(false)
      resetForm()
      closeModal()
    } catch (err) {
      popup.error(err)
      setSubmitting(false)
    }
  }
  const { recruitmentPost, departments } = postData
  const departmentNames = clinicalDepartmentNames({
    departments,
  })
  return (
    <RecruitmentPostApplyScene
      i18n={i18n}
      doctor={data.doctorMe}
      recruitmentPost={recruitmentPost}
      departmentNames={departmentNames}
      onSubmit={onSubmit}
      recruitmentDoctorData={
        recruitmentDoctorData && recruitmentDoctorData.recruitmentDoctors
          ? recruitmentDoctorData.recruitmentDoctors[0]
          : {}
      }
    />
  )
}
export default RecruitmentPostApply
