import BreadCrumb from '@medical/components/Breadcrumb'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import CustomDropdown from '@medical/components/DropdownInSearch'
import ModalLink from '@medical/components/ModalLink'
import { DatePicker } from 'antd'
import Router from '@medical/routes/router'
import { Formik } from 'formik'
import { Button, Button as PrimeButton } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Paginator } from 'primereact/paginator'
import queryString from 'query-string'
import { pathOr } from 'ramda'
import React, { useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import Axios from 'axios'
import { waitFor } from '@medical/libs'
import { DOWNLOAD_CSV_DOCTOR_INFO_CONSULTATION_TIME } from '@medical/constant'
import moment from 'moment'

const DoctorConsultationTimeListScene = ({
  i18n,
  page,
  rowsPerPage,
  fullname,
  email,
  edges,
  count,
  onPageChange,
  download,
  socket,
  progressList,
  setProgress,
  removeProgress,
  year,
  history,
}) => {
  const progress = useMemo(() => DOWNLOAD_CSV_DOCTOR_INFO_CONSULTATION_TIME, [
    download,
  ])
  const hasProgress = Object.keys(progressList).includes(progress)
  useEffect(() => {
    if (socket && hasProgress) {
      socket.on(progress, data => {
        if (data?.percent) {
          setProgress({
            progress: progress,
            label: '医師平均診療時間CSVダウンロード中',
            percent: Number(data?.percent),
          })
        }
      })
    }
  }, [socket, hasProgress])
  return (
    <React.Fragment>
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('staff.menuBar.doctorConsultations')}
          <BreadCrumb
            items={[
              {
                title: i18n.t('staff.menuBar.doctorConsultations'),
              },
            ]}
          />
          <span className='upload-doctor-csv-note'>
                  ＊同医師・同年・同月のデータは上書きされます。変更したい場合は再度アップロードしてください。
                </span>
        </div>
        <div className='staff-buttons'>
          {
            <PrimeButton
              icon='pi pi-download'
              className='p-button-normal'
              label='医師平均診療時間CSVダウンロード'
              onClick={async () => {
                setProgress({
                  progress,
                  label: '医師平均診療時間CSVダウンロード中',
                  percent: 0,
                })
                try {
                  const { status, data: urlS3 } = await Axios({
                    method: 'GET',
                    url: download,
                  })
                  await waitFor(3000)
                  if (status === 204) {
                    setProgress({
                      progress,
                      label: '医師平均診療時間CSVダウンロード中',
                      msg: i18n.t('main.msgDownloadCSVStatusError'),
                    })
                    await waitFor(3000)
                  } else {
                    setProgress({
                      progress,
                      percent: 100,
                      url: urlS3,
                    })
                  }
                } catch (err) {
                  setProgress({
                    progress,
                    label: '医師平均診療時間CSVダウンロード中',
                    msg: i18n.t('main.msgDownloadCSVStatusError'),
                  })
                  await waitFor(3000)
                }
                removeProgress(progress)
              }}
            />
          }
          <Link to={Router.get(Router.staffDoctorConsultationTimeCsv)}>
            <Button
              icon='pi pi-cloud-upload'
              className='p-button-normal'
              label='医師平均診療時間CSVアップロード'
            />
          </Link>
        </div>
      </div>
      <div className='container is-max'>
        <div className='box'>
          <Formik
            enableReinitialize
            initialValues={{
              fullname,
              email,
            }}
            render={formikProps => {
              const { values, handleChange, handleBlur } = formikProps
              return (
                <div
                  className='search-parent'
                  style={{ width: '100%', justifyContent: 'space-between' }}
                >
                  <div>
                    <InputText
                      name='fullname'
                      value={values.fullname}
                      placeholder={i18n.t('main.fullname')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{ width: '200px', marginRight: '20px' }}
                    />
                    <InputText
                      name='email'
                      value={values.email}
                      placeholder={i18n.t('main.email')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{ width: '200px', marginRight: '20px' }}
                    />
                    <Link
                      to={{
                        pathname: Router.staffDoctorConsultation,
                        search: queryString.stringify({
                          rowsPerPage,
                          fullname: values.fullname,
                          email: values.email,
                          year,
                        }),
                      }}
                      style={{ marginLeft: '20px' }}
                    >
                      <Button
                        label={i18n.t('main.search')}
                        icon='pi pi-search'
                      />
                    </Link>
                    {fullname || email ? (
                      <Link
                        to={{
                          pathname: Router.staffDoctorConsultation,
                          search: queryString.stringify({
                            rowsPerPage,
                          }),
                        }}
                      >
                        <Button
                          icon='pi pi-times'
                          className='p-button-secondary'
                          style={{ marginLeft: '20px' }}
                        />
                      </Link>
                    ) : null}
                  </div>
                  <div>
                    <span>単位: 分/人</span>
                    <DatePicker
                      picker='year'
                      defaultValue={moment(year)}
                      allowClear={false}
                      onChange={e => {
                        history.push({
                          pathname: Router.staffDoctorConsultation,
                          search: queryString.stringify({
                            rowsPerPage,
                            fullname: values.fullname,
                            email: values.email,
                            year: moment(e).format('YYYY'),
                          }),
                        })
                      }}
                    />
                  </div>
                </div>
              )
            }}
          />
          <div className='overflow-container'>
            <table className='table'>
              <thead>
                <tr>
                  <th width='16%'>{i18n.t('updateProfile.fullname')}</th>
                  <th width='7%'>1月</th>
                  <th width='7%'>2月</th>
                  <th width='7%'>3月</th>
                  <th width='7%'>4月</th>
                  <th width='7%'>5月</th>
                  <th width='7%'>6月</th>
                  <th width='7%'>7月</th>
                  <th width='7%'>8月</th>
                  <th width='7%'>9月</th>
                  <th width='7%'>10月</th>
                  <th width='7%'>11月</th>
                  <th width='7%'>12月</th>
                </tr>
              </thead>
              <tbody>
                {edges.map(({ node }) => (
                  <tr key={node.id}>
                    <td>{node.fullName}</td>
                    <td>
                      {
                        node.doctorConsultationTimes.find(
                          item => item.month === 1
                        )?.score
                      }
                    </td>
                    <td>
                      {
                        node.doctorConsultationTimes.find(
                          item => item.month === 2
                        )?.score
                      }
                    </td>
                    <td>
                      {
                        node.doctorConsultationTimes.find(
                          item => item.month === 3
                        )?.score
                      }
                    </td>
                    <td>
                      {
                        node.doctorConsultationTimes.find(
                          item => item.month === 4
                        )?.score
                      }
                    </td>
                    <td>
                      {
                        node.doctorConsultationTimes.find(
                          item => item.month === 5
                        )?.score
                      }
                    </td>
                    <td>
                      {
                        node.doctorConsultationTimes.find(
                          item => item.month === 6
                        )?.score
                      }
                    </td>
                    <td>
                      {
                        node.doctorConsultationTimes.find(
                          item => item.month === 7
                        )?.score
                      }
                    </td>
                    <td>
                      {
                        node.doctorConsultationTimes.find(
                          item => item.month === 8
                        )?.score
                      }
                    </td>
                    <td>
                      {
                        node.doctorConsultationTimes.find(
                          item => item.month === 9
                        )?.score
                      }
                    </td>
                    <td>
                      {
                        node.doctorConsultationTimes.find(
                          item => item.month === 10
                        )?.score
                      }
                    </td>
                    <td>
                      {
                        node.doctorConsultationTimes.find(
                          item => item.month === 11
                        )?.score
                      }
                    </td>
                    <td>
                      {
                        node.doctorConsultationTimes.find(
                          item => item.month === 12
                        )?.score
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Paginator
            rows={rowsPerPage}
            totalRecords={count}
            first={(page - 1) * rowsPerPage}
            onPageChange={onPageChange}
            leftContent={
              <div className='paginator-total'>
                {i18n.t('main.paginationTotal')}: {count}
              </div>
            }
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default DoctorConsultationTimeListScene
