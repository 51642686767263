import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, NotFound, ProgressSpinner } from '@medical/components'
import StaffForm from '@medical/pages/staff/Staffs/components/StaffForm'
import { STAFF_QUERY } from '@medical/pages/staff/Staffs/StaffDetail/StaffDetail.graphql'
import { useCustom } from '@medical/provider/context'
import React from 'react'

import { UPDATE_STAFF } from './StaffUpdate.graphql'

const StaffUpdate = ({
  match: {
    params: { staffId },
  },
  parentPath,
  location: { search },
  closeModal,
}) => {
  const [{ i18n, popup }] = useCustom()
  const [updateStaff] = useMutation(UPDATE_STAFF)
  const { loading, error, data } = useQuery(STAFF_QUERY, {
    variables: {
      staffId,
    },
  })

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const onSubmit = async (
    {
      firstname,
      lastname,
      firstnameKana,
      lastnameKana,
      position,
      staffGroup: { id: staffGroupId },
    },
    { setSubmitting, resetForm }
  ) => {
    try {
      await updateStaff({
        variables: {
          staffId,
          firstname,
          lastname,
          firstnameKana,
          lastnameKana,
          staffGroupId,
          position,
        },
      })
      popup.success(i18n.t('staff.updateStaff.submissionSuccess'))
      setSubmitting(false)
      resetForm()
      closeModal()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }
  const { staff = {} } = data
  if (!staff) {
    return <NotFound />
  }

  return (
    <StaffForm
      i18n={i18n}
      staff={staff}
      staffGroups={data.staffGroups}
      onSubmit={onSubmit}
      submitLabel={i18n.t('staff.updateStaff.submit')}
      confirmationMessage={i18n.t('staff.updateStaff.submitConfirmation')}
      search={search}
      parentPath={parentPath}
    />
  )
}

export default StaffUpdate
