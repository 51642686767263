import StaffForm from '@medical/pages/staff/Staffs/components/StaffForm'
import Router from '@medical/routes/router'
import { Button } from 'primereact/button'
import React from 'react'
import { Link } from 'react-router-dom'

const StaffDetailScene = ({ i18n, parentPath, search, staff, staffGroups }) => (
  <React.Fragment>
    <StaffForm
      disabled
      staff={staff}
      staffGroups={staffGroups}
      search={search}
      parentPath={parentPath}
    />
    <div className='modal-bottom'>
      <Link
        to={{
          pathname:
            parentPath.pathname +
            Router.get(Router.staffStaffUpdate, {
              staffId: staff.id,
            }),
          search,
        }}
      >
        <Button
          icon='pi pi-pencil'
          label={i18n.t('staff.editStaff.submit')}
          className='p-button-secondary'
        />
      </Link>
    </div>
  </React.Fragment>
)

export default StaffDetailScene
