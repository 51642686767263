import BreadCrumb from '@medical/components/Breadcrumb'
import ModalLink from '@medical/components/ModalLink'
import { useCustom } from '@medical/provider/context'
import Router from '@medical/routes/router'
import { DatePicker } from 'antd'
import { Button } from 'primereact/button'
import { Paginator } from 'primereact/paginator'
import { clinicDepartmentListToString } from '@medical/libs/clinicDepartmentListToString'
import React, { useState } from 'react'
import './DoctorWaitingRecruitment.scss'
import locale from 'antd/es/date-picker/locale/ja_JP'
import { MultiSelect } from 'primereact/multiselect'
import moment from 'moment'

export const DoctorWaitingRecruitmentScene = ({
  waitingRecruitmentShiftList,
  clinicalDepartments,
  clinicalDepartmentIds,
  dateFilter,
  setClinicalDepartmentIds,
  onPageChange,
  setDateFilter,
  pageSize,
  page,
  count,
  clearFilter,
}) => {
  const [{ i18n }] = useCustom()
  const clinicIdList = clinicalDepartments?.map(e => e.id)
  const newArr = waitingRecruitmentShiftList.filter(item => {
    const id = item.recruitmentShifts?.map(c => c.clinic)
    let count = 0
    id.forEach(clinicalDeId => {
      if (clinicIdList.includes(clinicalDeId)) {
        count += 1
      }
    })
    if (count === id.length) {
      return true
    }
    return false
  })
  return (
    <div className='doctor-waiting-recruit'>
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('doctor.waitingRecruitment.title')}
          <BreadCrumb
            items={[{ title: i18n.t('doctor.waitingRecruitment.title') }]}
          />

          <ModalLink to={Router.get(Router.createWaitingRecruitment)}>
            <Button
              className='register-button'
              label={i18n.t(
                'doctor.waitingRecruitment.registerWaitingRecruitment'
              )}
              icon='pi pi-plus'
            />
          </ModalLink>
        </div>
      </div>
      <div className='container'>
        <div className='box'     style={ newArr.length === 0? {marginBottom:'30px'} : null}>
          <div className='filter'>
            <MultiSelect
              options={clinicalDepartments}
              optionLabel='clinicName'
              value={clinicalDepartments.filter(({ id }) =>
                clinicalDepartmentIds.includes(id)
              )}
              onChange={e => {
                setClinicalDepartmentIds(e)
              }}
              placeholder={i18n.t('doctor.waitingRecruitment.clinicSelect')}
              fixedPlaceholder
              filter
            />
            <DatePicker
              className='date-picker mx-2'
              placeholder={i18n.t('doctor.waitingRecruitment.dateSelect')}
              locale={locale}
              value={dateFilter ? moment(dateFilter) : null}
              onChange={e => {
                setDateFilter(e)
              }}
              allowClear={false}
            />
            <Button
              onClick={e => {
                clearFilter()
              }}
              icon='pi pi-times'
            />
          </div>
          <div>
            <table className='table'>
              <thead>
                <tr>
                  <th width='25%'>
                    {i18n.t('doctor.waitingRecruitment.clinicName')}
                  </th>
                  <th width='25%'>
                    {i18n.t('doctor.waitingRecruitment.workingDate')}
                  </th>
                  <th width='18%'>
                    {i18n.t('doctor.waitingRecruitment.workingHour')}
                  </th>
                  <th width='8%'>
                    {i18n.t('doctor.waitingRecruitment.isRecruitment')}
                  </th>
                  <th width='7%' />
                </tr>
              </thead>
              <tbody>
                {newArr.map(ele => (
                  <tr style={{ textAlign: 'center' }} key={ele.id}>
                    <td style={{ textAlign: 'left' }}>
                      {clinicDepartmentListToString(
                        ele.recruitmentShifts,
                        ({ clinic }) =>
                          clinicalDepartments.find(cd => cd.id === clinic),
                        8
                      )}
                    </td>
                    <td>
                      {moment(ele.startDate).utc().format('YYYY年MM月DD日')}〜
                      {moment(ele.endDate).utc().format('YYYY年MM月DD日')}
                    </td>
                    <td>
                      {moment(ele.startTime).format('HH:mm')}〜
                      {moment(ele.endTime).format('HH:mm')}
                    </td>
                    <td>
                      {ele.active
                        ? i18n.t('main.yesLabel')
                        : i18n.t('main.noLabel')}
                    </td>
                    <td>
                      <ModalLink
                        to={Router.get(Router.detailWaitingRecruitment, {
                          id: ele.id,
                        })}
                        state={{ availableWaitingShift: ele }}
                      >
                        <Button icon='pi pi-info' />
                      </ModalLink>
                    </td>
                  </tr>
                ))}
                {newArr.length === 0 && (
                  <tr>
                    <td colSpan={5}>
                      {i18n.t('doctor.waitingRecruitment.noRecord')}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <Paginator
              rows={pageSize}
              totalRecords={count}
              first={(page - 1) * pageSize}
              onPageChange={onPageChange}
              leftContent={
                <div className='paginator-total'>
                  {i18n.t('main.paginationTotal')}: {count}
                </div>
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}
