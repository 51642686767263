import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, NotFound, ProgressSpinner } from '@medical/components'
import Router from '@medical/routes/router'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { ACTIVITY_LOG_DETAIL_BY_STAFF } from './ActivityLogsUpdateByStaffDetail.graphql'

const ActivityLogsUpdateByStaffDetail = ({
  match: {
    params: { activityLogId },
  },
}) => {
  const { i18n } = useTranslation()
  const { loading, error, data } = useQuery(ACTIVITY_LOG_DETAIL_BY_STAFF, {
    variables: {
      activityLogId,
    },
  })
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent />

  const { activityLog } = data

  if (!activityLog) return <NotFound />

  const { createdAt, doctor, staff, activity } = activityLog
  const doctorId = doctor ? doctor.id : ''

  return (
    <div>
      <div className='modal-title'>
        {i18n.t(`staff.activityLogsUpdateByStaff.${activity}`)}
      </div>
      <div className='modal-table'>
        <div style={{ paddingBottom: '5px' }}>
          {i18n.t('staff.activityLogsUpdateByStaff.staff')}:
          <span> {`${staff.lastname} ${staff.firstname}`}</span>
        </div>
        <div style={{ paddingBottom: '5px' }}>
          {i18n.t('staff.activityLogsUpdateByStaff.createdAt')}:
          <span> {moment(createdAt).format('LLL (dd)')}</span>
        </div>
        {activity === 'STAFF_BLOCKED_DOCTOR' ||
        activity === 'STAFF_UNBLOCKED_DOCTOR' ||
        activity === 'STAFF_CANCEL_REQUEST_DELETE_DOCTOR' ? (
          <div>
            <Link
              to={`${Router.staffDoctors}${Router.get(
                Router.staffDoctorDetail,
                {
                  doctorId,
                }
              )}`}
            >
              {i18n.t('staff.activityLogsUpdateByStaff.jumpToDoctor')}
            </Link>
          </div>
        ) : null}
        {activity === 'STAFF_REQUEST_DELETE_DOCTOR' ? (
          <div>
            <Link
              to={`${Router.staffDoctorsDeleteRequestList}${Router.get(
                Router.staffDoctorDetail,
                {
                  doctorId,
                }
              )}`}
            >
              {i18n.t('staff.activityLogsUpdateByStaff.jumpToDoctor')}
            </Link>
          </div>
        ) : null}
      </div>
    </div>
  )
}
export default ActivityLogsUpdateByStaffDetail
