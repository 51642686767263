import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { clinicalDepartmentNames } from '@medical/libs'
import { CLINICS_CONNECTION } from '@medical/pages/staff/Clinics/ClinicsList/ClinicsList.graphql'
import { useCustom } from '@medical/provider/context'
import React, { useEffect } from 'react'
import { isMobile } from 'react-device-detect'

import DoctorClinicMobile from './DoctorClinicMobile'
import DoctorClinicScene from './DoctorClinicScene'

const DoctorClinic = () => {
  const [{ i18n }] = useCustom()
  const { loading, error, data, refetch } = useQuery(CLINICS_CONNECTION, {
    variables: {
      where: {
        isPublished: true,
      },
    },
  })

  useEffect(() => {
    refetch()
  })
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  const {
    clinicsConnection: {
      edges,
      aggregate: { count },
    },
    departments,
  } = data

  const departmentNames = clinicalDepartmentNames({ departments })
  if (isMobile)
    return (
      <DoctorClinicMobile
        i18n={i18n}
        edges={edges}
        count={count}
        departmentNames={departmentNames}
      />
    )
  return (
    <DoctorClinicScene
      i18n={i18n}
      edges={edges}
      count={count}
      departmentNames={departmentNames}
    />
  )
}

export default DoctorClinic
