import ModalRoutes from '@medical/components/ModalRoutes'
import DoctorLayout from '@medical/layout/DoctorLayout/DoctorLayout'
import StaffLayout from '@medical/layout/StaffLayout/StaffLayout'
import middleware from '@medical/middleware'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import Permission from './Permission'
import Router from './router'

const ProtectedRoute = ({
  component: Component,
  redirect,
  permissionId,
  modals = [],
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={routerProps => {
        const props = { ...rest, ...routerProps }
        if (middleware.isDoctor() && redirect === Router.doctorLogin) {
          return (
            <DoctorLayout {...props}>
              <Component {...props} />
              <ModalRoutes modals={modals} />
            </DoctorLayout>
          )
        }
        if (middleware.isStaff() && redirect === Router.staffLogin) {
          return (
            <StaffLayout {...props}>
              <Permission permissionId={permissionId}>
                <Component {...props} />
                <ModalRoutes modals={modals} />
              </Permission>
            </StaffLayout>
          )
        }
        return <Redirect to={redirect} />
      }}
    />
  )
}

export default ProtectedRoute
