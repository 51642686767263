import { DOCTOR_FRAGMENTS } from '@medical/layout/DoctorLayout/DoctorLayoutRequest.graphql'
import gql from 'graphql-tag'

export const ANNOUNCE_FRAGMENT = gql`
  fragment ANNOUNCE_FRAGMENT on Announce {
    id
    createdAt
    updatedAt
    title
    content
    imageKey
  }
`

export const DOCTOR_ANNOUNCES = gql`
  query DOCTOR_ANNOUNCES(
    $where: AnnounceWhereInput
    $first: Int
    $skip: Int
    $orderBy: AnnounceOrderByInput
  ) {
    announcesConnection(
      where: $where
      first: $first
      skip: $skip
      orderBy: $orderBy
    ) {
      edges {
        node {
          ...ANNOUNCE_FRAGMENT
        }
      }
      aggregate(where: $where) {
        count
      }
    }
    doctorSeenAnnounce {
      ...DOCTOR_FRAGMENTS
    }
  }
  ${ANNOUNCE_FRAGMENT}
  ${DOCTOR_FRAGMENTS}
`
