export const START_LOADING = 'START_LOADING'
export const FINISH_LOADING = 'FINISH_LOADING'
export const DOCTOR_PROFILE = 'DOCTOR_PROFILE'

export const startLoading = dispatch => {
  dispatch({
    type: START_LOADING,
  })
}

export const finishLoading = dispatch => {
  dispatch({
    type: FINISH_LOADING,
  })
}

export const setDoctor = payload => dispatch => {
  dispatch({
    type: DOCTOR_PROFILE,
    payload,
  })
}
