/* eslint-disable no-nested-ternary */
import { useApolloClient, useQuery } from '@apollo/react-hooks'
import AutoComplete from '@medical/components/AutoComplete'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import { HOURLY_WAGE_ID, SHIFT_FORM_STEP, TYPE_CREATE } from '@medical/constant'
import {
  convertDoctorsConnection,
  setTimeSplitByDay,
  thisMonthHolidays,
  thisRangeHolidays,
} from '@medical/libs'
import { isHolidayInRangeDate } from '@medical/libs/isHolidayInRangeDate'
import { getEmailTemplate } from '@medical/pages/staff/CreateScheduledShiftGroup/templateEmail'
import { Checkbox, DatePicker, InputNumber, Switch } from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import { ErrorMessage, FieldArray, Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { pathOr } from 'ramda'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'

import { DOCTOR_CONNECTION } from '../CreateAvailableShift/CreateAvailableShift.graphql'
import { STAFF_CHECK_DOCTOR_DEFAULT_HOURLY_WAGE } from '../CreateWorkSchedule/CreateWorkSchedule.graphql'
import ShiftFormStaffComment from './ShiftFormStaffComment'
import ShiftGroupFormRepeatSetting from './ShiftGroupFormRepeatSetting'
import ShiftGroupFormSplits from './ShiftGroupFormSplits'

const ShiftGroupForm = ({
  i18n,
  availableShift = {},
  doctors,
  clinicalDepartments,
  onSubmit,
  date,
  title,
  submitLabel,
  confirmationMessage,
  isMoneyPermitted,
  isCreateScheduledShiftGroup,
  isChangeDateFirstTime,
  isAddCommentPermitted,
  isCreateWorkSchedule,
  holidaysData,
  popup,
  isChangeConfirmedShiftsStatusPermitted,
  detailValue,
}) => {
  let selectedDoctor
  const [informTime, setInformTime] = useState('')
  const [doctorSelecting, setDoctorSelecting] = useState({})

  // const [timePick, setTimePick] = useState({
  //   startTime: `${moment().format('YYYY-MM-DD')}T09:00:00`,
  //   endTime: `${moment().format('YYYY-MM-DD')}T09:01:00`,
  // })
  const client = useApolloClient()
  const validationSchema = Yup.object()
    .shape({
      doctor: Yup.mixed().test(
        'validateSelectDoctor',
        i18n.t('validation.doctor.required'),
        val => {
          if (val && val.id != null) return true
          return false
        }
      ),
      splitComments: Yup.array().of(
        Yup.object().shape(
          {
            type: Yup.string().when('text', {
              is: text => text && text.length > 0,
              then: Yup.string(i18n.t('validation.comment.required')).required(
                i18n.t('validation.dropdownCommentType.required')
              ),
            }),
            text: Yup.string().when('type', {
              is: value => value && value.length > 0,
              then: Yup.string(i18n.t('validation.comment.required'))
                .trim()
                .required(i18n.t('validation.comment.required'))
                .max(500, i18n.t('validation.title.max', { max: 500 })),
            }),
          },
          ['type', 'text']
        )
      ),
      startEndDate: Yup.mixed().test(
        'validate start end date',
        i18n.t('validation.shiftGroup.startEndDate'),
        val => {
          if (val && val[0] && val[1]) return true
          return false
        }
      ),
      // date: Yup.date().required(i18n.t('validation.date.required')),
      // hourlyWage: isMoneyPermitted
      //   ? Yup.number(i18n.t('validation.hourlyWage.number')).required(
      //       i18n.t('validation.hourlyWage.required')
      //     )
      //   : undefined,
      startTime: Yup.date('Should be string').required(''),
      splits: Yup.object().shape({
        7: Yup.array().of(
          Yup.object().shape({
            clinicalDepartmentId: Yup.string().required(
              i18n.t('validation.clinicalDepartment.required')
            ),
          })
        ),
        1: Yup.array().of(
          Yup.object().shape({
            clinicalDepartmentId: Yup.string().required(
              i18n.t('validation.clinicalDepartment.required')
            ),
          })
        ),
        2: Yup.array().of(
          Yup.object().shape({
            clinicalDepartmentId: Yup.string().required(
              i18n.t('validation.clinicalDepartment.required')
            ),
          })
        ),
        3: Yup.array().of(
          Yup.object().shape({
            clinicalDepartmentId: Yup.string().required(
              i18n.t('validation.clinicalDepartment.required')
            ),
          })
        ),
        4: Yup.array().of(
          Yup.object().shape({
            clinicalDepartmentId: Yup.string().required(
              i18n.t('validation.clinicalDepartment.required')
            ),
          })
        ),
        5: Yup.array().of(
          Yup.object().shape({
            clinicalDepartmentId: Yup.string().required(
              i18n.t('validation.clinicalDepartment.required')
            ),
          })
        ),
        6: Yup.array().of(
          Yup.object().shape({
            clinicalDepartmentId: Yup.string().required(
              i18n.t('validation.clinicalDepartment.required')
            ),
          })
        ),
        '7_Hol': Yup.array().of(
          Yup.object().shape({
            clinicalDepartmentId: Yup.string().required(
              i18n.t('validation.clinicalDepartment.required')
            ),
          })
        ),
        '1_Hol': Yup.array().of(
          Yup.object().shape({
            clinicalDepartmentId: Yup.string().required(
              i18n.t('validation.clinicalDepartment.required')
            ),
          })
        ),
        '2_Hol': Yup.array().of(
          Yup.object().shape({
            clinicalDepartmentId: Yup.string().required(
              i18n.t('validation.clinicalDepartment.required')
            ),
          })
        ),
        '3_Hol': Yup.array().of(
          Yup.object().shape({
            clinicalDepartmentId: Yup.string().required(
              i18n.t('validation.clinicalDepartment.required')
            ),
          })
        ),
        '4_Hol': Yup.array().of(
          Yup.object().shape({
            clinicalDepartmentId: Yup.string().required(
              i18n.t('validation.clinicalDepartment.required')
            ),
          })
        ),
        '5_Hol': Yup.array().of(
          Yup.object().shape({
            clinicalDepartmentId: Yup.string().required(
              i18n.t('validation.clinicalDepartment.required')
            ),
          })
        ),
        '6_Hol': Yup.array().of(
          Yup.object().shape({
            clinicalDepartmentId: Yup.string().required(
              i18n.t('validation.clinicalDepartment.required')
            ),
          })
        ),
        8: Yup.array().of(
          Yup.object().shape({
            clinicalDepartmentId: Yup.string().required(
              i18n.t('validation.clinicalDepartment.required')
            ),
          })
        ),
      }),

      name: Yup.string().required(i18n.t('validation.shiftGroup.name')),
      mailContent: Yup.string().when('isSendMail', {
        is: true,
        then: Yup.string().required(
          i18n.t('staff.scheduledShiftGroup.mailContentRequired')
        ),
      }),
      repeatSetting: Yup.string().required(i18n.t('validation.shiftGroup.day')),
      doctorSubsidy: Yup.array()
        .nullable()
        .of(
          Yup.object().shape({
            title: Yup.string()
              .nullable()
              .required(i18n.t('validation.doctorSubsidy')),
            money: Yup.number()
              .nullable()
              .required(i18n.t('validation.doctorSubsidy')),
          })
        ),
      isHolidayRepeat: Yup.boolean().nullable(),
    })
    .test('checkSelectDay', i18n.t('validation.shiftGroup.day'), value => {
      if (
        (!value.isMonday &&
          !value.isTuesday &&
          !value.isWednesday &&
          !value.isThursday &&
          !value.isFriday &&
          !value.isSaturday &&
          !value.isSunday &&
          !value.isDayoff &&
          value.repeatSetting === TYPE_CREATE.WEEKLY) ||
        (value.listWeekDay.length === 0 &&
          value.listWeekDay1.length === 0 &&
          value.listWeekDay2.length === 0 &&
          value.listWeekDay3.length === 0 &&
          value.listWeekDay4.length === 0 &&
          value.repeatSetting === TYPE_CREATE.MONTHLY)
      ) {
        return false
      }
      return true
    })
  const holidays = thisMonthHolidays({
    date: informTime,
    holidaysData: pathOr([], ['items'], holidaysData),
  })
  let isHoliday = false

  const [startTimeCheckHourlyWage, setStartTimeCheckHourlyWage] = useState({
    time: `${moment().format('YYYY-MM-DD')}T09:00:00`,
    index: 0,
    isEndTime: false,
  })
  const [currentHourlyWage, setCurrentHourlyWage] = useState([0, 0, 0, 0])
  useEffect(() => {
    for (const element of holidays) {
      if (element.date === moment(informTime).format('YYYY-MM-DD'))
        isHoliday = true
    }
  }, [informTime])

  useEffect(() => {
    if (date && isChangeDateFirstTime) {
      setInformTime(moment(date).format())
    }
  }, [date, isChangeDateFirstTime])

  const disabledDate = current => {
    const min = moment()
    const max = moment().add(6, 'quarters')
    return !(min.isSameOrBefore(current) && max.isAfter(current))
  }

  const renderAvailableDay = (
    isAvailableOnMonday,
    isAvailableOnTuesday,
    isAvailableOnWednesday,
    isAvailableOnThursday,
    isAvailableOnFriday,
    isAvailableOnSaturday,
    isAvailableOnSunday,
    isAvailableOnHoliday
  ) => {
    let availableDay = ''
    if (
      !isAvailableOnMonday ||
      !isAvailableOnTuesday ||
      !isAvailableOnWednesday ||
      !isAvailableOnThursday ||
      !isAvailableOnFriday
    ) {
      if (isAvailableOnMonday) availableDay += '月'
      if (isAvailableOnTuesday) availableDay += '火'
      if (isAvailableOnWednesday) availableDay += '水'
      if (isAvailableOnThursday) availableDay += '木'
      if (isAvailableOnFriday) availableDay += '金'
      if (isAvailableOnSaturday) availableDay += '土'
      if (isAvailableOnSunday) availableDay += '日'
      if (isAvailableOnHoliday) availableDay += '祝'
    } else {
      availableDay = '平日'
      if (isAvailableOnSaturday) availableDay += '土'
      if (isAvailableOnSunday) availableDay += '日'
      if (isAvailableOnHoliday) availableDay += '祝'
    }
    return availableDay
  }
  const sampleDataOfCheckbox = [
    { value: 'monday', description: i18n.t('staff.createHourlyWage.monDay') },
    { value: 'tuesday', description: i18n.t('staff.createHourlyWage.tuesDay') },
    {
      value: 'wednesday',
      description: i18n.t('staff.createHourlyWage.wednesDay'),
    },
    {
      value: 'thursday',
      description: i18n.t('staff.createHourlyWage.thursDay'),
    },
    { value: 'friday', description: i18n.t('staff.createHourlyWage.friDay') },
    {
      value: 'saturday',
      description: i18n.t('staff.createHourlyWage.saturDay'),
    },
    { value: 'sunday', description: i18n.t('staff.createHourlyWage.sunDay') },
  ]
  const typeCreateOptions = [
    {
      label: i18n.t('staff.scheduledShiftGroup.daily'),
      value: TYPE_CREATE.DAILY,
    },
    {
      label: i18n.t('staff.scheduledShiftGroup.weekly'),
      value: TYPE_CREATE.WEEKLY,
    },
    {
      label: i18n.t('staff.scheduledShiftGroup.monthly'),
      value: TYPE_CREATE.MONTHLY,
    },
  ]

  if (detailValue) {
    const fullNameEmail = `${detailValue.doctors.fullName} (${detailValue.doctors.email})`
    selectedDoctor= {...detailValue.doctors,fullNameEmail}
    for (const key in detailValue.splits) {
      detailValue.splits[key][0].isDisabled = false
    }
  }
  return (
    <Formik
      enableReinitialize
      initialValues={{
        confirm: false,
        name: detailValue ? detailValue.name : '',
        doctor: {},
        isHolidayRepeat: detailValue ? detailValue.isHolidayRepeat : false,
        doctors,
        isHoliday,
        clinicId: '',
        clinicalDepartments,
        clinicalDepartment: '',
        startTime: date.toDate(),
        splits: detailValue ? Object.assign({}, detailValue.splits) : [],
        isAllday: detailValue ? detailValue.isAllday : false,
        isMonday: detailValue ? detailValue.isMonday : false,
        isTuesday: detailValue ? detailValue.isTuesday : false,
        isWednesday: detailValue ? detailValue.isWednesday : false,
        isThursday: detailValue ? detailValue.isThursday : false,
        isFriday: detailValue ? detailValue.isFriday : false,
        isSaturday: detailValue ? detailValue.isSaturday : false,
        isSunday: detailValue ? detailValue.isSunday : false,
        isDayoff: detailValue ? detailValue.isDayoff : false,
        isDayOnWeekday: detailValue ? detailValue.isDayOnWeekday : false,
        startEndDate: detailValue
          ? [
              detailValue.startEndDate[0],
              moment(detailValue.startEndDate[1])
                .subtract(9, 'hours')
                .toISOString(),
            ]
          : [null, null],
        defaultHourlyWageId: '',
        mailContent: '',
        isSendMail: detailValue ? detailValue.isSendMail : false,
        repeatSetting: detailValue ? detailValue.repeatSetting : '',
        listWeekDay: detailValue ? detailValue.listWeekDay : [],
        listWeekDay1: detailValue ? detailValue.listWeekDay1 : [],
        listWeekDay2: detailValue ? detailValue.listWeekDay2 : [],
        listWeekDay3: detailValue ? detailValue.listWeekDay3 : [],
        listWeekDay4: detailValue ? detailValue.listWeekDay4 : [],
        comment: '',
        splitComments: detailValue
          ? detailValue.splitComments
          : [
              {
                text: '',
                type: '',
              },
            ],
        doctorSubsidy: detailValue ? detailValue.doctorSubsidy : [],
        isPublished: detailValue ? detailValue.isPublished : true,
        listDaySelected: detailValue ? detailValue.listDaySelected : [],
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={formikProps => {
        const {
          handleChange,
          touched,
          errors,
          setFieldValue,
          handleBlur,
          setTouched,
          validateForm,
          values,
        } = formikProps
        const holidays1 = thisRangeHolidays({
          holidaysData: pathOr([], ['items'], holidaysData),
          startDate: values.startEndDate[0],
          endDate: values.startEndDate[1],
        })
        const checkIsTime = startTime => {
          const holidays = thisRangeHolidays({
            holidaysData: pathOr([], ['items'], holidaysData),
            startDate: startTime,
            endDate: startTime,
          })
          const date = moment(startTime)
          const dayNo = moment(date).day()

          holidays.length > 0
            ? setFieldValue(
                'splits',
                setTimeSplitByDay('8', 'isAvailableOnHoliday')
              )
            : setFieldValue('splits', setTimeSplitByDay(`${dayNo}`))
        }
        const showSplits = (startTime, endTime) => {
          const holidays = thisRangeHolidays({
            holidaysData: pathOr([], ['items'], holidaysData),
            startDate: startTime,
            endDate: endTime,
          })

          values.splits = isHolidayInRangeDate({
            startDate: startTime,
            endDate: endTime,
          })
          if (holidays.length > 0) {
            setFieldValue('splits', {
              ...values.splits,
              ...setTimeSplitByDay('8', 'isAvailableOnHoliday'),
            })
          }
        }
        return (
          <div>
            <div className='modal-title'>{title}</div>
            {/* <div className='tr'>
              <div className='th'>{i18n.t('staff.createShift.clinic')}</div>
              <div className='td'>
                <AutoComplete
                  {...formikProps}
                  name='clinicalDepartment'
                  field='clinicName'
                  allSuggestions={clinicalDepartments}
                  suggestionName='clinicalDepartments'
                  width='60vw'
                  maxWidth='386px'
                  placeholder={
                    clinicalDepartments.length > 0
                      ? 'クリニックを選択'
                      : '対象データなし'
                  }
                  setCurrentClinicData={val => {
                    if (isCreateScheduledShiftGroup) {
                      setSelectingClinicId(
                        val && val.clinic ? val.clinic.id : ''
                      )
                    } else {
                      setFieldValue(
                        'clinicId',
                        val && val.clinic ? val.clinic.id : ''
                      )
                    }
                    setSelectingClinicDepartmentId(val ? val.id : '')
                    // setCLinicalDepartmentId(val ? val.id : '')
                  }}
                />
                <div className='alert'>
                  {touched.clinicalDepartment && errors.clinicalDepartment}
                </div>
              </div>
            </div> */}
            <div className='tr'>
              <div className='th'>
                {i18n.t('staff.scheduledShiftGroup.shiftGroupName')}
              </div>
              <div className='td'>
                <InputText
                  value={values.name}
                  name='name'
                  onChange={e => {
                    setFieldValue('name', e.target.value)
                  }}
                  onBlur={handleBlur}
                />
                <div className='alert'>{touched.name && errors.name}</div>
              </div>
            </div>
            <div className='tr'>
              <div className='th'>
                {i18n.t('staff.scheduledShiftGroup.doctor')}
              </div>
              <div className='td'>
                <AutoComplete
                  {...formikProps}
                  name='doctor'
                  field='fullNameEmail'
                  searchFields={['fullName', 'email']}
                  allSuggestions={values.doctors}
                  suggestionName='doctors'
                  width='60vw'
                  maxWidth='386px'
                  placeholder={
                    doctors.length > 0 ? '勤務医師を選択' : '対象データなし'
                  }
                  setDoctorSelect={async val => {
                    setFieldValue('doctor', val)
                    setFieldValue('defaultHourlyWageId', val[HOURLY_WAGE_ID])
                    setDoctorSelecting(val)
                    // if (typeof val !== 'string') {
                    //   let tempObj = [
                    //     {
                    //       date: date
                    //         .clone()
                    //         .add(SHIFT_FORM_STEP, 'minute')
                    //         .toDate(),
                    //       hourlyWage: 0,
                    //       isBreakTime: false,
                    //       hourlyWageSat: 0,
                    //       isBreakTimeSat: false,
                    //       hourlyWageHoliday: 0,
                    //       isBreakTimeHoliday: false,
                    //     },
                    //   ]
                    //   // setFieldValue('splits', tempObj)
                    // }
                    if (typeof val === 'string') {
                      try {
                        const { data: dataConnection } = await client.query({
                          query: DOCTOR_CONNECTION,
                          variables: {
                            where: {
                              AND: [
                                {
                                  OR: [
                                    {
                                      unSigned_contains:
                                        typeof val === 'string'
                                          ? val
                                              .replace(/ /g, '')
                                              .replace(/　/g, '')
                                              .toLowerCase()
                                          : '',
                                    },
                                    {
                                      email_contains: val,
                                    },
                                  ],
                                },
                              ],
                              registrationStatus_in: [
                                'CREATED_BY_STAFF',
                                'ACCEPTED',
                              ],
                              // blocked: false,
                            },
                            first: 10,
                            skip: 0,
                            orderBy: 'unSigned_ASC',
                          },
                          fetchPolicy: 'network-only',
                        })

                        setFieldValue(
                          'doctors',
                          convertDoctorsConnection(
                            dataConnection.doctorsConnection.edges
                          )
                        )
                      } catch (err) {}
                    }
                  }}
                />
                <div className='alert'>{touched.doctor && errors.doctor}</div>
              </div>
            </div>
            <div className='tr'>
              <div className='th'>{i18n.t('staff.createShift.restDay')}</div>
              <div className='td'>
                {renderAvailableDay(
                  values.doctor.isAvailableOnMonday,
                  values.doctor.isAvailableOnTuesday,
                  values.doctor.isAvailableOnWednesday,
                  values.doctor.isAvailableOnThursday,
                  values.doctor.isAvailableOnFriday,
                  values.doctor.isAvailableOnSaturday,
                  values.doctor.isAvailableOnSunday,
                  values.doctor.isAvailableOnHoliday
                )}
              </div>
            </div>
            <div className='create-as-group'>
              <div className='tr'>
                <div className='th'>
                  {i18n.t('staff.createHourlyWage.date')}
                </div>
                <div className='td'>
                  <DatePicker.RangePicker
                    name='startEndDate'
                    value={[
                      values.startEndDate[0]
                        ? moment(
                            new Date(values.startEndDate[0]),
                            'YYYY年MM月DD日'
                          )
                        : null,
                      values.startEndDate[1]
                        ? moment(
                            new Date(values.startEndDate[1]),
                            'YYYY年MM月DD日'
                          )
                        : null,
                    ]}
                    allowClear={false}
                    placeholder={[
                      i18n.t('staff.scheduledShiftGroup.startDate'),
                      i18n.t('staff.scheduledShiftGroup.endDate'),
                    ]}
                    locale={locale}
                    disabledDate={disabledDate}
                    onChange={value => {
                      setFieldValue('startEndDate', [
                        moment(value[0]).toISOString(),
                        moment(value[1]).toISOString(),
                      ])
                      setInformTime(
                        moment(value[0])
                          .format()
                          .split('+')[0]
                      )
                      if (values.repeatSetting === TYPE_CREATE.DAILY)
                        moment(value[0]).isSame(moment(value[1]))
                          ? checkIsTime(
                              moment(value[0]).toISOString(),
                              moment(value[1]).toISOString()
                            )
                          : showSplits(
                              moment(value[0]).toISOString(),
                              moment(value[1]).toISOString()
                            )
                    }}
                    format='YYYY年MM月DD日'
                  />
                  <div className='alert'>
                    {touched.startEndDate && errors.startEndDate}
                  </div>
                </div>
              </div>
              <ShiftGroupFormRepeatSetting
                formikProps={formikProps}
                i18n={i18n}
                typeCreateOptions={typeCreateOptions}
                holidays={holidays1}
                sampleDataOfCheckbox={sampleDataOfCheckbox}
                isCreate
              />
            </div>
            <ShiftGroupFormSplits
              formikProps={formikProps}
              i18n={i18n}
              clinicalDepartments={clinicalDepartments}
              isMoneyPermitted={isMoneyPermitted}
              isCreateScheduledShiftGroup={isCreateScheduledShiftGroup}
              currentHourlyWage={currentHourlyWage}
              availableShift={availableShift}
              isCreateWorkSchedule={isCreateWorkSchedule}
              doctorSelecting={doctorSelecting}
              isHoliday={isHoliday}
              informTime={informTime}
              // getStartTimeValue={async (val, index) => {
              //   const formatTimePick = moment(val)
              //     .format()
              //     .split('+')[0]
              //   if (
              //     index === 0 &&
              //     (isCreateScheduledShiftGroup || isCreateWorkSchedule)
              //   ) {
              //   }
              //   // setTimePick({
              //   //   ...timePick,
              //   //   startTime: moment(val)
              //   //     .format()
              //   //     .split('+')[0],
              //   // })
              // }}
              // handleClickAddHourlyWage={handleClickAddHourlyWage}
              getEndTimeValue={async (val, index) => {
                const formatTimePick = moment(val)
                  .format()
                  .split('+')[0]

                if (
                  values.splits &&
                  values.splits.length > 1 &&
                  (isCreateScheduledShiftGroup || isCreateWorkSchedule) &&
                  values.splits[index + 1] &&
                  index < 4
                ) {
                  setStartTimeCheckHourlyWage({
                    time: formatTimePick,
                    index,
                    isEndTime: true,
                  })
                }

                // setTimePick({
                //   ...timePick,
                //   endTime: moment(val)
                //     .format()
                //     .split('+')[0],
                // })
              }}
            />
            {values.doctorSubsidy ? (
              <div>
                <FieldArray
                  name='doctorSubsidy'
                  render={arrayHelpers => (
                    <div>
                      {values.doctorSubsidy.map((item, index) => (
                        <div className='doctor-subsidy-container'>
                          <div className='doctor-subsidy__item'>
                            {i18n.t('staff.workSchedule.doctorSubsidy')}
                          </div>
                          <div className='doctor-subsidy__item'>
                            <div className='title'>
                              {i18n.t('staff.workSchedule.subsidyName')}
                            </div>
                            <div>
                              <InputText
                                value={item.title}
                                onChange={e => {
                                  setFieldValue(
                                    `doctorSubsidy[${index}].title`,
                                    e.target.value
                                  )
                                }}
                              />
                              <ErrorMessage
                                name={`doctorSubsidy[${index}].title`}
                                component='div'
                                className='signup-alert'
                              />
                            </div>
                          </div>
                          <div className='doctor-subsidy__item'>
                            <div className='title'>
                              {i18n.t('staff.workSchedule.subsidyValue')}
                            </div>
                            <div>
                              <InputNumber
                                size='medium'
                                value={item.money}
                                formatter={value =>
                                  `${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ','
                                  )
                                }
                                type='tel'
                                allowClear
                                min={0}
                                step={10}
                                onChange={e => {
                                  setFieldValue(
                                    `doctorSubsidy[${index}].money`,
                                    e
                                  )
                                }}
                              />
                              <ErrorMessage
                                name={`doctorSubsidy[${index}].money`}
                                component='div'
                                className='signup-alert'
                              />
                            </div>
                          </div>
                          <div className='doctor-subsidy__item'>
                            <Button
                              type='button'
                              icon='pi pi-trash'
                              onClick={() => {
                                arrayHelpers.remove(index)
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                />
                <Button
                  type='button'
                  icon='pi pi-plus'
                  label={i18n.t('staff.workSchedule.btnSubsidy')}
                  onClick={() => {
                    setFieldValue('doctorSubsidy', [
                      ...values.doctorSubsidy,
                      {
                        title: '',
                        money: null,
                      },
                    ])
                  }}
                />
              </div>
            ) : null}
            {isAddCommentPermitted && (
              <div style={{ padding: '15px' }}>
                <ShiftFormStaffComment i18n={i18n} formikProps={formikProps} />
              </div>
            )}
            <div className='tr'>
              <div className='th'>{i18n.t('main.workDetail')}</div>
              <div className='td'>
                <InputTextarea
                  value={values.comment}
                  name='comment'
                  autoResize
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div className='tr'>
              <div className='th'>
                {i18n.t('staff.scheduledShiftGroup.tittlePublished')}
              </div>
              <div className='td toggle-container'>
                <Switch
                  name='isPublished'
                  checkedChildren={i18n.t(
                    'staff.scheduledShiftGroup.published'
                  )}
                  unCheckedChildren={i18n.t(
                    'staff.scheduledShiftGroup.notPublished'
                  )}
                  checked={values.isPublished}
                  onChange={e => {
                    if (isChangeConfirmedShiftsStatusPermitted) {
                      setFieldValue('isPublished', e)
                      if (!e.checked) {
                        setFieldValue('isSendMail', false)
                      }
                    } else {
                      popup.error(
                        i18n.t(
                          'staff.scheduledShiftGroup.errorMessagePermision'
                        )
                      )
                    }
                  }}
                />
              </div>
            </div>
            <div className='tr'>
              <div className='th'>
                {i18n.t('staff.scheduledShiftGroup.mailContent')}
              </div>
              <div className='td'>
                <InputTextarea
                  name='mailContent'
                  value={
                    values.isPublished
                      ? values.isSendMail
                        ? values.mailContent
                        : ''
                      : ''
                  }
                  autoResize
                  disabled={!values.isSendMail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  name='mailContent'
                  component='div'
                  className='signup-alert'
                />
              </div>
            </div>
            <div className='tr'>
              <div className='th' />
              <div className='td'>
                <Checkbox
                  name='isSendMail'
                  disabled={!values.isPublished}
                  checked={values.isSendMail}
                  onChange={e => {
                    setFieldValue('isSendMail', e.target.checked)
                    setFieldValue(
                      'mailContent',
                      getEmailTemplate(
                        false,
                        values.doctor,
                        clinicalDepartments,
                        date,
                        values.splits,
                        values.startEndDate,
                        values.isMonday,
                        values.isTuesday,
                        values.isWednesday,
                        values.isThursday,
                        values.isFriday,
                        values.isSaturday,
                        values.isSunday,
                        values.isDayoff
                      )
                    )
                  }}
                />
                <span> {i18n.t('staff.scheduledShiftGroup.isSendMail')}</span>
                <div style={{ marginTop: '10px', fontSize: '12px' }}>
                  <span className='asterisk-label-head '>
                    {i18n.t('staff.scheduledShiftGroup.sendMailNote1')}
                  </span>
                  <br />
                  <span className='asterisk-label-head '>
                    {i18n.t('staff.scheduledShiftGroup.sendMailNote2')}
                  </span>
                </div>
              </div>
            </div>
            <div className='modal-bottom'>
              <Button
                onClick={() => {
                  validateForm().then(response => {
                    if (!Object.keys(response).length) {
                      setFieldValue('confirm', true)
                    } else {
                      setTouched(response)
                    }
                  })
                }}
                label={submitLabel}
              />
            </div>
            <ConfirmationModal
              {...formikProps}
              confirmationMessage={confirmationMessage}
            />
          </div>
        )
      }}
    />
  )
}

export default ShiftGroupForm
