import './style.css'

import BreadCrumb from '@medical/components/Breadcrumb'
import ModalLink from '@medical/components/ModalLink'
import Router from '@medical/routes/router'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Paginator } from 'primereact/paginator'
import queryString from 'query-string'
import React from 'react'
import { Link } from 'react-router-dom'

const AvailableShiftGroupListScene = ({
  i18n,
  page,
  rowsPerPage,
  fullname,
  name_contains,
  onSortHandle,
  edges,
  count,
  onPageChange,
  isUpdatePermitted,
  isCreatePermitted,
  fortmatDate,
  orderBy,
}) => {
  const renderAvailableDay = (
    isAvailableOnMonday,
    isAvailableOnTuesday,
    isAvailableOnWednesday,
    isAvailableOnThursday,
    isAvailableOnFriday,
    isAvailableOnSaturday,
    isAvailableOnSunday,
    isAvailableOnHoliday
  ) => {
    let availableDay = ''
    if (
      !isAvailableOnMonday ||
      !isAvailableOnTuesday ||
      !isAvailableOnWednesday ||
      !isAvailableOnThursday ||
      !isAvailableOnFriday
    ) {
      if (isAvailableOnMonday) availableDay += '月'
      if (isAvailableOnTuesday) availableDay += '火'
      if (isAvailableOnWednesday) availableDay += '水'
      if (isAvailableOnThursday) availableDay += '木'
      if (isAvailableOnFriday) availableDay += '金'
      if (isAvailableOnSaturday) availableDay += '土'
      if (isAvailableOnSunday) availableDay += '日'
      if (isAvailableOnHoliday) availableDay += '祝'
    } else {
      availableDay = '平日'
      if (isAvailableOnSaturday) availableDay += '土'
      if (isAvailableOnSunday) availableDay += '日'
      if (isAvailableOnHoliday) availableDay += '祝'
    }
    return availableDay
  }
  return (
    <React.Fragment>
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('staff.regularFixedShiftList.title')}
          <BreadCrumb
            items={[
              {
                title: i18n.t('staff.regularFixedShiftList.title'),
              },
            ]}
          />
        </div>
        <div className='staff-buttons'>
          {isCreatePermitted ? (
            <ModalLink to={Router.get(Router.staffCreateScheduledShiftGroup)}>
              <Button
                icon='pi pi-plus-circle'
                className='p-button-normal'
                label={i18n.t(
                  'staff.regularFixedShiftList.createRegularFixedShiftList'
                )}
              />
            </ModalLink>
          ) : null}
        </div>
      </div>
      <div className='container is-max'>
        <div className='box'>
          <Formik
            enableReinitialize
            initialValues={{
              fullname,
              name_contains,
            }}
            render={formikProps => {
              const { values, handleChange, handleBlur } = formikProps
              return (
                <div className='available_shift_group_list-header'>
                  <div className='search-parent'>
                    <InputText
                      name='fullname'
                      value={values.fullname}
                      placeholder={i18n.t('main.fullname')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{ width: '200px', marginRight: '20px' }}
                    />
                    <InputText
                      name='name_contains'
                      value={values.name_contains}
                      placeholder={i18n.t(
                        'staff.regularFixedShiftList.shiftTitle'
                      )}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{ width: '200px', marginRight: '20px' }}
                    />
                    <Link
                      to={{
                        pathname: Router.staffAvailableShiftGroupList,
                        search: queryString.stringify({
                          rowsPerPage,
                          fullname: values.fullname,
                          name_contains: values.name_contains,
                        }),
                      }}
                      style={{ marginLeft: '20px' }}
                    >
                      <Button
                        label={i18n.t('main.search')}
                        icon='pi pi-search'
                      />
                    </Link>
                    {fullname || name_contains ? (
                      <Link
                        to={{
                          pathname: Router.staffAvailableShiftGroupList,
                          search: queryString.stringify({
                            rowsPerPage,
                          }),
                        }}
                      >
                        <Button
                          icon='pi pi-times'
                          className='p-button-secondary'
                          style={{ marginLeft: '20px' }}
                        />
                      </Link>
                    ) : null}
                  </div>
                  <div>
                    <p className='asterisk-label-head'>
                      個別編集：定期確定シフトで作成したシフトを個別で編集した場合「有」と表示されます。
                      <br />
                      個別で編集したシフトが含まれる期間は一括で編集ができなくなります。
                    </p>
                  </div>
                </div>
              )
            }}
          />
          <Formik
            initialValues={{
              confirm: false,
              doctorId: '',
              functionName: '',
              createdAt: orderBy === 'createdAt_DESC',
              updatedAt: orderBy === 'updatedAt_DESC',
              startDate: orderBy === 'startDate_DESC',
            }}
            render={formikProps => {
              return (
                <div className='overflow-container'>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th width='13%'>
                          {i18n.t('staff.regularFixedShiftList.name')}
                        </th>
                        <th width='17%'>
                          {i18n.t('staff.regularFixedShiftList.shiftTitle')}
                        </th>
                        <th width='11%'>
                          {i18n.t('staff.regularFixedShiftList.createdAt')}
                          <Button
                            style={{
                              border: 'none',
                              backgroundColor: 'transparent',
                              color: '#686868',
                            }}
                            icon={
                              !formikProps.values.createdAt
                                ? 'pi pi-sort-down'
                                : 'pi pi-sort-up'
                            }
                            onClick={() => {
                              onSortHandle({ sortField: 'createdAt' })
                            }}
                          />
                        </th>
                        <th width='11%'>
                          {i18n.t('staff.regularFixedShiftList.updatedAt')}
                          <Button
                            style={{
                              border: 'none',
                              backgroundColor: 'transparent',
                              color: '#686868',
                            }}
                            icon={
                              !formikProps.values.updatedAt
                                ? 'pi pi-sort-down'
                                : 'pi pi-sort-up'
                            }
                            onClick={() => {
                              onSortHandle({ sortField: 'updatedAt' })
                            }}
                          />
                        </th>
                        <th width='17%'>
                          {i18n.t('staff.regularFixedShiftList.periodDay')}
                          <Button
                            style={{
                              border: 'none',
                              backgroundColor: 'transparent',
                              color: '#686868',
                            }}
                            icon={
                              !formikProps.values.startDate
                                ? 'pi pi-sort-down'
                                : 'pi pi-sort-up'
                            }
                            onClick={() => {
                              onSortHandle({ sortField: 'startDate' })
                            }}
                          />
                        </th>
                        <th width='11%'>
                          {i18n.t('staff.regularFixedShiftList.workingDay')}
                        </th>
                        <th width='10%' className='asterisk-label-tail'>
                          {i18n.t('staff.regularFixedShiftList.isEdited')}
                        </th>
                        {isUpdatePermitted ? (
                          <th width='10%'>{i18n.t('main.action')}</th>
                        ) : null}
                      </tr>
                    </thead>
                    <tbody>
                      {edges.map(({ node }) => (
                        <tr
                          key={node.id}
                          className={
                            moment(node.endDate) - moment(new Date()) < 0
                              ? 'table-block'
                              : ''
                          }
                        >
                          <td>
                            {node.availableShifts[0].workSchedule
                              ? `${node.availableShifts[0].workSchedule.doctor.lastname} ${node.availableShifts[0].workSchedule.doctor.firstname}`
                              : ''}
                          </td>
                          <td>{node.name}</td>
                          <td>{moment(node.createdAt).format('LL (dd)')}</td>
                          <td>{moment(node.updatedAt).format('LL (dd)')}</td>
                          <td>
                            {`${moment(node.startDate).format(
                              'YYYY年MM月DD日'
                            )}～${moment
                              .utc(node.endDate)
                              .format('YYYY年MM月DD日')}`}
                          </td>
                          <td>{fortmatDate(node)}</td>

                          <td>{node.isEdited ? '有' : ''}</td>
                          {isUpdatePermitted ? (
                            <td>
                              <div className='action-doctor-list'>
                                {moment(node.endDate) - moment(new Date()) >
                                0 ? (
                                  <ModalLink
                                    to={Router.get(
                                      Router.staffDetailScheduledShiftGroup,
                                      {
                                        scheduledShiftGroupId: node.id,
                                      }
                                    )}
                                  >
                                    <Button icon='pi pi-pencil' />
                                  </ModalLink>
                                ) : (
                                  <Button icon='pi pi-pencil' disabled />
                                )}
                              </div>
                            </td>
                          ) : null}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )
            }}
          />
          <Paginator
            rows={rowsPerPage}
            totalRecords={count}
            first={(page - 1) * rowsPerPage}
            onPageChange={onPageChange}
            leftContent={
              <div className='paginator-total'>
                {i18n.t('main.paginationTotal')}: {count}
              </div>
            }
          />
        </div>
      </div>
    </React.Fragment>
  )
}
export default AvailableShiftGroupListScene
