import ReactGA from 'react-ga'

export const initGA = () => {
  ReactGA.initialize(process.env.REACT_APP_GOOGLEANALYTICS_KEY)
}

export const logPageView = page => {
  ReactGA.set({
    page,
  })
  ReactGA.pageview(page)
}

export const logEvent = ({
  category,
  action,
  label,
  value,
  nonInteraction = false,
}) => {
  ReactGA.event({
    category,
    action,
    label,
    value,
    nonInteraction,
  })
}
