import { useMutation } from '@apollo/react-hooks'
import { DOWNLOAD_FILE } from '@medical/pages/staff/Doctors/DoctorDetail/DoctorDetail.graphql'
import Router from '@medical/routes/router'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'

// import './style.css'

const NewSliderScene = ({
  edges,

  settings,

  i18n,
}) => {
  const [listData, setListData] = useState([])

  const [downloadFile] = useMutation(DOWNLOAD_FILE)

  const downloadFileSubmit = async key => {
    if (!key || key.length === 0) return null

    try {
      const {
        data: { downloadFileS3: fileURL },
      } = await downloadFile({
        variables: {
          key,
        },
      })

      return fileURL
    } catch (error) {
      return null
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      const promises = []

      for (const item of edges) {
        if (item.node.imageKey) {
          promises.push(downloadFileSubmit(item.node.imageKey))
        }
        if (!item.node.imageKey) {
          promises.push(downloadFileSubmit(process.env.REACT_APP_IMAGE_DEFAULT))
        }
      }

      const results = await Promise.all(promises)

      const temp = edges.map((el, index) => ({
        ...el,

        img: results[index],
      }))

      setListData(temp)
    }

    fetchData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div>
        <Slider {...settings}>
          {listData.length > 0 &&
            listData.map(node => (
              <div
                className='new-item'
                key={node.id}
                style={{ width: 'calc(50vw - 140px)' }}
              >
                <Link
                    to={Router.get(Router.doctorNewsDetail, {
                      newsId: node.node.id,
                    })}
                  >
                    <img
                  alt=''
                  height='100%'
                  width='100%'
                  style={{
                    aspectRatio: '2/1',
                    objectFit: 'contain',
                    margin: 'auto',
                  }}
                  src={node.img}
                />
                  </Link>
                

                <div
                  className='wrap-new-content'
                >
                  <p className='new-date'>
                    {i18n.t('main.recruitmentScheduleShift.updatedAt')} :{' '}
                    {moment(node.node.updatedAt).format('LL (dd)')}
                  </p>

                  <Link
                    to={Router.get(Router.doctorNewsDetail, {
                      newsId: node.node.id,
                    })}
                  >
                    <h3 className='new-title'>{node.node.title}</h3>
                  </Link>

                  <p className='new-content' style={{position: 'relative',height:'40px',width:'100%'}}>
                    <p className='new-content-child' style={{position: 'absolute', top: 0, left: 0 }}>{node.node.content.replace(/<[^>]+>/g, '')}</p>
                    
                  </p>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </>
  )
}

export default NewSliderScene
