import BreadCrumb from '@medical/components/Breadcrumb'
import ModalLink from '@medical/components/ModalLink'
import Router from '@medical/routes/router'
import { Button } from 'primereact/button'
import React from 'react'

const MyPageScene = ({ i18n, staff }) => (
  <React.Fragment>
    <div className='staff-header'>
      <div className='staff-title'>
        {i18n.t('staff.menuBar.myPage')}
        <BreadCrumb items={[{ title: i18n.t('staff.menuBar.myPage') }]} />
      </div>
    </div>
    <div className='container is-medium'>
      <div className='box'>
        <div className='tr'>
          <div className='th'>{i18n.t('staff.myPage.greeting')}</div>
          <div
            className='td'
            style={{ fontSize: '18px' }}
          >{`${staff.lastname} ${staff.firstname}`}</div>
        </div>
        <div className='tr'>
          <div className='th'>{i18n.t('staff.myPage.profile')}</div>
          <div className='td'>
            <ModalLink to={Router.staffUpdateProfile}>
              <Button
                label={i18n.t('staff.myPage.updateProfile')}
                icon='pi pi-pencil'
                className='p-button-secondary'
              />
            </ModalLink>
          </div>
        </div>
        <div className='tr'>
          <div className='th'>{i18n.t('staff.myPage.password')}</div>
          <div className='td'>
            <ModalLink to={Router.staffChangePassword}>
              <Button
                label={i18n.t('staff.myPage.changePassword')}
                icon='pi pi-lock'
                className='p-button-secondary'
              />
            </ModalLink>
          </div>
        </div>
        <div />
      </div>
    </div>
  </React.Fragment>
)

export default MyPageScene
