import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { findNameOrEmail, onPageChange } from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import queryString from 'query-string'
import React from 'react'

import { DOCTORS_CONNECTION } from '../DoctorsList/DoctorsList.graphql'
import PatientSatisfactionResultsScene from './PatientSatisfactionResultsScene'

const PatientSatisfactionResults = ({ history, location: { search } }) => {
  const [{ i18n }] = useCustom()
  const {
    page = 1,
    rowsPerPage = 20,
    fullname,
    email,
    orderBy = 'createdAt_DESC',
  } = queryString.parse(search)
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  const variables = {
    first: first < 0 ? 20 : first,
    skip: skip < 0 ? 0 : skip,
    orderBy,
    where: {
      AND: findNameOrEmail({ fullname, email }),
      registrationStatus_not_in: ['PENDING', 'REJECTED', 'NEW'],
    },
  }
  const { loading, error, data } = useQuery(DOCTORS_CONNECTION, {
    variables,
    fetchPolicy: 'network-only',
  })

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const {
    doctorsConnection: {
      edges,
      aggregate: { count },
    },
  } = data

  return (
    <PatientSatisfactionResultsScene
      i18n={i18n}
      edges={edges}
      fullname={fullname}
      email={email}
      rowsPerPage={parseInt(rowsPerPage, 10)}
      count={count}
      page={parseInt(page, 10)}
      onPageChange={onPageChange(history, search)}
    />
  )
}

export default PatientSatisfactionResults
