import BreadCrumb from '@medical/components/Breadcrumb'
import Router from '@medical/routes/router'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Paginator } from 'primereact/paginator'
import queryString from 'query-string'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const ListMovingExpensesScene = ({
  isUpdateMovingExpenses,
  i18n,
  listMovingExpenses,
  data,
  page,
  rowsPerPage,
  onPageChange,
  count,
  fullname,
  email,
}) => {
  const [mailFilter, setMailFilter] = useState(email || '')
  const [nameFilter, setNameFilter] = useState(fullname || '')
  const listFilter = listMovingExpenses || []
  const getParam = id => ({
    doctorId: id,
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  })
  const getDepartment = keys => {
    const result = []
    keys.forEach(as => {
      data.departments.forEach(department => {
        if (department.value === as) {
          result.push(department.description)
        }
      })
    })
    return result
  }

  return (
    <div
      style={{
        padding: '10px',
        height: '100%',
      }}
    >
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('main.movingExpenses.title')}
          <BreadCrumb
            items={[{ title: i18n.t('main.movingExpenses.title') }]}
          />
        </div>
      </div>
      <div
        className='box'
        style={{
          backgroundColor: '#ffffff',
          marginLeft: '20px',
          marginRight: '20px',
          padding: '20px',
          borderRadius: '10px',
        }}
      >
        <div className='search-parent'>
          <InputText
            value={nameFilter}
            name='fullname'
            placeholder={i18n.t('main.fullname')}
            onChange={e => {
              setNameFilter(e.target.value)
            }}
            style={{ width: '170px', marginRight: '20px' }}
          />
          <InputText
            value={mailFilter}
            name='fullname'
            placeholder={i18n.t('main.movingExpenses.mail')}
            onChange={e => {
              setMailFilter(e.target.value)
            }}
            style={{ width: '170px', marginRight: '20px' }}
          />
          <Link
            to={{
              pathname: Router.staffDoctorsMovingExpenses,
              search: queryString.stringify({
                rowsPerPage,
                fullname: nameFilter,
                email: mailFilter,
              }),
            }}
            style={{ marginLeft: '20px' }}
          >
            <Button
              label={i18n.t('main.movingExpenses.search')}
              icon='pi pi-search'
            />
          </Link>

          {fullname || email ? (
            <Link
              to={{
                pathname: Router.staffDoctorsMovingExpenses,
                search: queryString.stringify({
                  rowsPerPage,
                }),
              }}
            >
              <Button
                icon='pi pi-times'
                className='p-button-secondary'
                style={{ marginLeft: '20px' }}
              />
            </Link>
          ) : null}
        </div>
        <div>
          <div>
            <table className='table-moving-expenses'>
              <thead>
                <tr>
                  <th width='18%'>{i18n.t('main.movingExpenses.fullname')}</th>
                  <th width='18%'>{i18n.t('main.movingExpenses.mail')}</th>
                  <th width='10%'>{i18n.t('main.movingExpenses.tel')}</th>
                  <th width='20%'>
                    {i18n.t('main.movingExpenses.department')}
                  </th>
                  <th width='18%'>{i18n.t('main.movingExpenses.address')}</th>
                  <th width='12%'>
                    {i18n.t('main.movingExpenses.nearestStation')}
                  </th>
                  {isUpdateMovingExpenses ? (
                    <th width='30%'>{i18n.t('main.movingExpenses.detail')}</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {listFilter && listFilter.length > 0
                  ? listFilter.map(as => (
                      <tr>
                        <td>{`${as.node.lastname} ${as.node.firstname}`}</td>
                        <td>
                          <a href={`mailto:${as.node.email}`}>
                            {as.node.email}
                          </a>
                        </td>
                        <td>{as.node.phoneNumber}</td>
                        <td>
                          {getDepartment(as.node.departments).map(de => (
                            <p>{de}</p>
                          ))}
                        </td>
                        <td>
                          {(as.node.homeAddress && as.node.homeAddress.address1
                            ? as.node.homeAddress.address1
                            : '') +
                            (as.node.homeAddress && as.node.homeAddress.address2
                              ? `, ${as.node.homeAddress.address2}`
                              : '')}
                        </td>
                        <td>
                          {as.node.nearestStations
                            ? as.node.nearestStations.join(', ')
                            : ''}
                        </td>
                        {isUpdateMovingExpenses ? (
                          <td>
                            <Link
                              to={Router.get(
                                Router.staffDoctorsMovingExpensesDetail,
                                getParam(as.node.id)
                              )}
                              className={
                                Router.staffDoctorsMovingExpensesDetail
                              }
                            >
                              <Button icon='pi pi-pencil' />
                            </Link>
                          </td>
                        ) : null}
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
            <Paginator
              rows={rowsPerPage}
              totalRecords={count}
              first={(page - 1) * rowsPerPage}
              onPageChange={onPageChange}
              leftContent={
                <div className='paginator-total'>
                  {i18n.t('main.paginationTotal')}: {count}
                </div>
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default ListMovingExpensesScene
