import BreadCrumb from '@medical/components/Breadcrumb'
import CustomDropdown from '@medical/components/DropdownInSearch'
import ModalLink from '@medical/components/ModalLink'
import moment from 'moment'
import Router from '@medical/routes/router'
import { Formik } from 'formik'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Paginator } from 'primereact/paginator'
import queryString from 'query-string'
import React from 'react'
import { Link } from 'react-router-dom'

const recruitmentDoctorStatus = [
  {
    value: 'NOT_ACTION',
    description: '未対応',
  },
  {
    value: 'IN_ACTION',
    description: '対応中',
  },
  {
    value: 'COMPLETED',
    description: '対応終了',
  },
]

const recruitmentDoctorType = [
  {
    value: 'PERMANENT_DOCTOR',
    description: '常勤',
  },
  {
    value: 'REGULAR_SHIFT_DOCTOR',
    description: '定期非常勤',
  },
]

const RecruitmentDoctorListScene = ({
  i18n,
  page,
  rowsPerPage,
  fullname,
  email,
  type,
  edges,
  count,
  onPageChange,
  status,
  isPageRecruitmentPost,
  departments,
}) => {
  const renderRecruitmentDoctorTypeStatus = status => {
    switch (status) {
      case 'NOT_ACTION':
        return <span style={{ color: '#ff518f' }}>未対応</span>
      case 'IN_ACTION':
        return <span style={{ color: '#369efd' }}>対応中</span>
      case 'COMPLETED':
        return <span style={{ color: '#647380' }}>対応終了</span>
    }
  }
  const renderRecruitmentDoctorType = type => {
    return type === 'PERMANENT_DOCTOR' ? '常勤' : '定期非常勤'
  }
  return (
    <React.Fragment>
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('staff.menuBar.recruitmentDoctor')}
          <BreadCrumb
            items={[
              {
                title: i18n.t('staff.menuBar.recruitmentDoctor'),
              },
            ]}
          />
        </div>
        {isPageRecruitmentPost ? (
          <div className='staff-buttons'>
            <Link to={Router.staffRecruitmentPosts}>
              <Button label='募集要項掲載一覧' className='p-button-normal' />
            </Link>
          </div>
        ) : null}
      </div>
      <div className='container is-max'>
        <div className='box'>
          <Formik
            enableReinitialize
            initialValues={{
              fullname,
              email,
              status,
              type,
            }}
            render={formikProps => {
              const { values, handleChange, handleBlur } = formikProps
              return (
                <div className='search-parent'>
                  <InputText
                    name='fullname'
                    value={values.fullname}
                    placeholder={i18n.t('main.fullname')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ width: '200px', marginRight: '20px' }}
                  />
                  <InputText
                    name='email'
                    value={values.email}
                    placeholder='メールアドレス'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ width: '200px', marginRight: '20px' }}
                  />
                  <CustomDropdown
                    {...formikProps}
                    options={recruitmentDoctorStatus}
                    name='status'
                    defaultTitle={i18n.t('staff.myNumberManage.status')}
                  />
                  <span style={{ marginLeft: '20px' }}></span>
                  <CustomDropdown
                    {...formikProps}
                    options={recruitmentDoctorType}
                    name='type'
                    defaultTitle='区分'
                  />
                  <Link
                    to={{
                      pathname: Router.staffRecruitmentDoctorList,
                      search: queryString.stringify({
                        rowsPerPage,
                        fullname: values.fullname,
                        email: values.email,
                        status: values.status,
                        type: values.type,
                      }),
                    }}
                    style={{ marginLeft: '20px' }}
                  >
                    <Button label={i18n.t('main.search')} icon='pi pi-search' />
                  </Link>
                  {fullname || email || status || type ? (
                    <Link
                      to={{
                        pathname: Router.staffRecruitmentDoctorList,
                        search: queryString.stringify({
                          rowsPerPage,
                        }),
                      }}
                    >
                      <Button
                        icon='pi pi-times'
                        className='p-button-secondary'
                        style={{ marginLeft: '20px' }}
                      />
                    </Link>
                  ) : null}
                </div>
              )
            }}
          />
          <Formik
            initialValues={{
              confirm: false,
            }}
            render={formikProps => (
              <div className='overflow-container'>
                <div
                  style={{
                    marginTop: '30px',
                    marginBottom: '10px',
                    color: '#f03a6c',
                  }}
                >
                  ※ステータスを未対応→対応中に変更すると、医師サイト側から応募内容の変更ができなくなります。
                </div>
                <table className='table'>
                  <thead>
                    <tr>
                      <th width='15%'>医師名</th>
                      <th width='15%'>メールアドレス</th>
                      <th width='20%'>募集要項タイトル</th>
                      <th width='20%'>クリニック名</th>
                      <th width='15%'>応募日</th>
                      <th width='15%'>ステータス</th>
                      <th width='15%'>応募区分</th>
                      <th width='10%'>応募内容詳細</th>
                      <th width='10%'>お問い合わせ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {edges.length > 0 ? (
                      <>
                        {edges.map(({ node }, index) => (
                          <tr key={node.id} style={{ textAlign: 'center' }}>
                            <td>{`${node.doctor.lastname} ${node.doctor.firstname}`}</td>
                            <td>{node.doctor.email}</td>
                            <td>{node.recruitmentPostId.title}</td>
                            <td>
                              {departments?.find(
                                ({ id }) =>
                                  id ===
                                  node.recruitmentPostId.clinicalDepartment?.id
                              )?.clinicName || 'なし'}
                            </td>
                            <td>{moment(node.createdAt).format('LL (dd)')}</td>
                            <td>
                              {renderRecruitmentDoctorTypeStatus(node.status)}
                            </td>
                            <td>
                              {renderRecruitmentDoctorType(
                                node.recruitmentPostId.recruitmentDoctorType
                              )}
                            </td>
                            <td>
                              <ModalLink
                                to={Router.get(
                                  Router.staffRecruitmentDoctorDetail,
                                  {
                                    id: node.id,
                                  }
                                )}
                              >
                                <Button icon='pi pi-info' />
                              </ModalLink>
                            </td>
                            <td>
                              <Link
                                to={{
                                  pathname: Router.staffInquiries,
                                  search: queryString.stringify({
                                    doctorId: node.doctor.id,
                                    doctorName: `${node.doctor.lastname} ${node.doctor.firstname}`,
                                    conversationId: node.doctor.conversation
                                      ? node.doctor.conversation.id
                                      : '',
                                  }),
                                }}
                                style={{ color: '#f84e51', fontWeight: 'bold' }}
                                rel='noopener noreferrer'
                                target='_blank'
                              >
                                <Button
                                  icon='pi pi-external-link'
                                  style={{ border: 'none' }}
                                />
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <td colSpan={6}>医師からの応募がありません</td>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          />
          <Paginator
            rows={rowsPerPage}
            totalRecords={count}
            first={(page - 1) * rowsPerPage}
            onPageChange={onPageChange}
            leftContent={
              <div className='paginator-total'>
                {i18n.t('main.paginationTotal')}: {count}
              </div>
            }
          />
        </div>
      </div>
    </React.Fragment>
  )
}
export default RecruitmentDoctorListScene
