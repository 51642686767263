import gql from 'graphql-tag'

export const DOCTOR_CREATE_TRANSOPRT_EXPENSE = gql`
  mutation doctorCreateTransportationExpense(
    $clinics: [String!]
    $transportations: [TransportationExpenseCreateWithoutDoctorInput!]!
  ) {
    doctorCreateTransportationExpense(
      clinics: $clinics
      transportations: $transportations
    )
  }
`
export const DOCTOR_DELETE_TRANSOPRT_EXPENSE = gql`
  mutation staffDeleteTransportationExpense(
    $transportationsId: [ID!]!
    $reverse: Boolean
  ) {
    staffDeleteTransportationExpense(
      transportationsId: $transportationsId
      reverse: $reverse
    )
  }
`
export const DOCTOR_GET_TRANSOPRT_EXPENSE = gql`
  query doctorGetTransportExpense($where: TransportationExpenseWhereInput) {
    transportationExpenses(where: $where) {
      id
      startTime
      endTime
      url
      type
      departurePlace
      arrivalPlace
      cost
      status
      returnPath
      acceptedBy {
        id
      }
      createdAt
      clinic {
        id
        name
      }
      doctor {
        id
      }
    }
  }
`
