import ClinicNameRender from '@medical/components/ClinicNameRender'
import StatusRender from '@medical/components/StatusRender'
import numeralCurrency from '@medical/libs/numeralCurrency'
import moment from 'moment'
import { InputTextarea } from 'primereact/inputtextarea'
import React from 'react'

const SingleScheduleScene = ({
  i18n,
  availableShifts,
  workSchedule,
  departmentNames,
}) => {
  const {
    clinicalDepartment,
    startTime,
    endTime,
    accepted,
    acceptedShift,
  } = workSchedule

  return (
    <React.Fragment>
      <div className='modal-title'>
        {accepted ? '確定' : '応募'}
        {i18n.t('main.shift')}
      </div>
      <table className='single-available-shift modal-table'>
        <tbody>
          <tr>
            <th>{i18n.t('main.status')}</th>
            <td>
              <StatusRender val={accepted} />
            </td>
          </tr>
          <tr>
            <th>{i18n.t('main.clinicName')}</th>
            <td>{ClinicNameRender({ clinicalDepartment })}</td>
          </tr>
          <tr>
            <th>{i18n.t('main.departmentName')}</th>
            <td>{departmentNames[clinicalDepartment.name]}</td>
          </tr>
          <tr>
            <th>{i18n.t('main.scheduleDate')}</th>
            <td>{moment(startTime).format('LL (dd)')}</td>
          </tr>
          <tr>
            <th>{i18n.t('main.scheduleTime')}</th>
            <td>
              {moment(startTime).format('HH:mm')}~
              {moment(endTime).format('HH:mm')}
            </td>
          </tr>
          <tr>
            <th>{i18n.t('main.dailySalary')}</th>
            <td>{numeralCurrency(acceptedShift.dailySalary)}</td>
          </tr>
          {!accepted ? (
            // &&
            // availableShifts &&
            // availableShifts.length > 0 &&
            // availableShifts[0].comment
            <tr>
              <th>{i18n.t('main.workDetail')}</th>
              <td>
                <InputTextarea
                  value={availableShifts[0].comment}
                  disabled
                  autoResize
                  rows={8}
                />
              </td>
            </tr>
          ) : null}

          {accepted &&
          availableShifts &&
          availableShifts.length > 0 &&
          availableShifts[0].comment ? (
            <tr>
              <th>{i18n.t('main.workDetail')}</th>
              <td>
                <InputTextarea
                  value={availableShifts[0].comment}
                  disabled
                  autoResize
                  rows={8}
                />
              </td>
            </tr>
          ) : null}

          {/* <tr>
            <th>{i18n.t('main.relatedSchedules')}</th>
            <td>
              {relatedWorkSchedules.map(schedule => (
                <div key={schedule.id}>
                  {moment(schedule.startTime).format('HH:mm')}-
                  {moment(schedule.endTime).format('HH:mm')}
                </div>
              ))}
            </td>
          </tr> */}
        </tbody>
      </table>
    </React.Fragment>
  )
}

export default SingleScheduleScene
