import gql from 'graphql-tag'

export const SCHEDULE_AVAILABLESHIFT_CONNECTION = gql`
  query REGULAR_FIXED_SHIFT_QUERY(
    $orderBy: ScheduledAvailableShiftGroupOrderByInput
    $where: ScheduledAvailableShiftGroupWhereInput
    $skip: Int
    $first: Int
    $availableShiftsSkip2: Int
    $availableShiftsFirst2: Int
  ) {
    scheduledAvailableShiftGroupsConnection(
      orderBy: $orderBy
      where: $where
      skip: $skip
      first: $first
    ) {
      aggregate(where: $where) {
        count
      }
      edges {
        node {
          id
          createdAt
          updatedAt
          name
          startDate
          endDate
          dataTime
          isAvailableOnMonday
          isAvailableOnTuesday
          isAvailableOnWednesday
          isAvailableOnThursday
          isAvailableOnFriday
          isAvailableOnSaturday
          isAvailableOnSunday
          isAvailableOnHoliday
          isEdited
          dateMatrix
          repeatSetting
          availableShifts(
            skip: $availableShiftsSkip2
            first: $availableShiftsFirst2
          ) {
            id
            deletedAt
            workSchedule {
              id
              doctor {
                id
                lastname
                firstname
              }
            }
          }
        }
      }
    }
  }
`
