import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import { CLINICAL_DEPARTMENTS_QUERY } from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import gql from 'graphql-tag'

export const HOURLY_WAGE_SCHEDULE = gql`
  query Query(
    $skip: Int
    $first: Int
    $orderBy: DefaultHourlyWageScheduleOrderByInput
    $where: DefaultHourlyWageScheduleWhereInput
  ) {
    defaultHourlyWageSchedulesConnection(
      skip: $skip
      first: $first
      orderBy: $orderBy
      where: $where
    ) {
      aggregate(where: $where) {
        count
      }
      edges {
        node {
          id
          startDate
          endDate
          startTime
          endTime
          createdAt
          updatedAt
          hourlyWageDefault
          isAvailableOnMonday
          isAvailableOnTuesday
          isAvailableOnWednesday
          isAvailableOnThursday
          isAvailableOnFriday
          isAvailableOnSaturday
          isAvailableOnSunday
          isAvailableOnHoliday
          classify
          clinicalDepartment {
            id
            departmentCode
            name
            extraName
            clinic {
              id
              name
              nameKana
            }
          }
        }
      }
    }
    ...DEPARTMENTS_FRAGMENT
    ...CLINICAL_DEPARTMENTS_QUERY
  }
  ${CLINICAL_DEPARTMENTS_QUERY}
  ${DEPARTMENTS_FRAGMENT}
`

export const GET_HOURLY_WAGE_SCHEDULE_BY_ID = gql`
  query getDefaultHourlyWageSchedule(
    $where: DefaultHourlyWageScheduleWhereUniqueInput!
  ) {
    defaultHourlyWageSchedule(where: $where) {
      id
      createdAt
      updatedAt
      startDate
      endDate
      startTime
      endTime
      hourlyWageDefault
      isAvailableOnMonday
      isAvailableOnTuesday
      isAvailableOnWednesday
      isAvailableOnThursday
      isAvailableOnFriday
      isAvailableOnSaturday
      isAvailableOnSunday
      isAvailableOnHoliday
      clinicalDepartment {
        id
        name
        clinic {
          id
          name
        }
      }
      classify
    }
  }
`
export const CREATE_DEFAULT_HOURLY_WAGE_SCHEDULE = gql`
  mutation createDefaultHourlyWageSchedule(
    $clinicalDepartmentId: ID!
    $startDate: String!
    $endDate: String!
    $startTime: String!
    $endTime: String!
    $hourlyWageDefault: Int!
    $isAvailableOnMonday: Boolean!
    $isAvailableOnTuesday: Boolean!
    $isAvailableOnWednesday: Boolean!
    $isAvailableOnThursday: Boolean!
    $isAvailableOnFriday: Boolean!
    $isAvailableOnSaturday: Boolean!
    $isAvailableOnSunday: Boolean!
    $isAvailableOnHoliday: Boolean!
    $classify: String
  ) {
    staffCreateDefaultHourlyWageSchedule(
      clinicalDepartmentId: $clinicalDepartmentId
      startDate: $startDate
      endDate: $endDate
      startTime: $startTime
      endTime: $endTime
      hourlyWageDefault: $hourlyWageDefault
      isAvailableOnMonday: $isAvailableOnMonday
      isAvailableOnTuesday: $isAvailableOnTuesday
      isAvailableOnWednesday: $isAvailableOnWednesday
      isAvailableOnThursday: $isAvailableOnThursday
      isAvailableOnFriday: $isAvailableOnFriday
      isAvailableOnSaturday: $isAvailableOnSaturday
      isAvailableOnSunday: $isAvailableOnSunday
      isAvailableOnHoliday: $isAvailableOnHoliday
      classify: $classify
    ) {
      id
      createdAt
      updatedAt
      deletedAt
      startDate
      endDate
      startTime
      endTime
      hourlyWageDefault
      isAvailableOnMonday
      isAvailableOnTuesday
      isAvailableOnWednesday
      isAvailableOnThursday
      isAvailableOnFriday
      isAvailableOnSaturday
      isAvailableOnSunday
      isAvailableOnHoliday
      classify
    }
  }
`
export const UPDATE_DEFAULT_HOURLY_WAGE_SCHEDULE = gql`
  mutation updateDefaultHourlyWageSchedule(
    $defaultHourlyWageScheduleId: ID!
    $clinicalDepartmentId: ID!
    $startDate: String!
    $endDate: String!
    $startTime: String!
    $endTime: String!
    $hourlyWageDefault: Int!
    $isAvailableOnMonday: Boolean!
    $isAvailableOnTuesday: Boolean!
    $isAvailableOnWednesday: Boolean!
    $isAvailableOnThursday: Boolean!
    $isAvailableOnFriday: Boolean!
    $isAvailableOnSaturday: Boolean!
    $isAvailableOnSunday: Boolean!
    $isAvailableOnHoliday: Boolean!
    $classify: String
  ) {
    staffUpdateDefaultHourlyWageSchedule(
      defaultHourlyWageScheduleId: $defaultHourlyWageScheduleId
      clinicalDepartmentId: $clinicalDepartmentId
      startDate: $startDate
      endDate: $endDate
      startTime: $startTime
      endTime: $endTime
      hourlyWageDefault: $hourlyWageDefault
      isAvailableOnMonday: $isAvailableOnMonday
      isAvailableOnTuesday: $isAvailableOnTuesday
      isAvailableOnWednesday: $isAvailableOnWednesday
      isAvailableOnThursday: $isAvailableOnThursday
      isAvailableOnFriday: $isAvailableOnFriday
      isAvailableOnSaturday: $isAvailableOnSaturday
      isAvailableOnSunday: $isAvailableOnSunday
      isAvailableOnHoliday: $isAvailableOnHoliday
      classify: $classify
    ) {
      id
      createdAt
      updatedAt
      deletedAt
      startDate
      endDate
      startTime
      endTime
      hourlyWageDefault
      isAvailableOnMonday
      isAvailableOnTuesday
      isAvailableOnWednesday
      isAvailableOnThursday
      isAvailableOnFriday
      isAvailableOnSaturday
      isAvailableOnSunday
      isAvailableOnHoliday
      clinicalDepartment {
        id
        departmentCode
        name
        extraName
        clinic {
          id
          name
          nameKana
          address
        }
        isDisplay
      }
    }
  }
`
export const DELETE__DEFAULT_HOURLY_WAGE_SCHEDULE = gql`
  mutation DeleteDefaultHourlyWageSchedule($defaultHourlyWageScheduleId: ID!) {
    staffDeleteDefaultHourlyWageSchedule(
      defaultHourlyWageScheduleId: $defaultHourlyWageScheduleId
    )
  }
`

export const DELETE_MULTI_DEFAULT_HOURLY_WAGE_SCHEDULE = gql`
  mutation DeleteMultiDefaultHourlyWageSchedule(
    $defaultHourlyWageScheduleId: [ID!]!
  ) {
    staffDeleteMultiDefaultHourlyWageSchedule(
      defaultHourlyWageScheduleId: $defaultHourlyWageScheduleId
    )
  }
`

export const DUPLICATE_DEFAULT_HOURLY_WAGE_SCHEDULE = gql`
  mutation duplicateDefaultHourlyWageSchedule(
    $clinicalDepartmentId: ID!
    $startDate: String!
    $endDate: String!
    $startTime: String!
    $endTime: String!
    $hourlyWageDefault: Int!
    $isAvailableOnMonday: Boolean!
    $isAvailableOnTuesday: Boolean!
    $isAvailableOnWednesday: Boolean!
    $isAvailableOnThursday: Boolean!
    $isAvailableOnFriday: Boolean!
    $isAvailableOnSaturday: Boolean!
    $isAvailableOnSunday: Boolean!
    $isAvailableOnHoliday: Boolean!
    $classify: String
  ) {
    staffDuplicateDefaultHourlyWageSchedule(
      clinicalDepartmentId: $clinicalDepartmentId
      startDate: $startDate
      endDate: $endDate
      startTime: $startTime
      endTime: $endTime
      hourlyWageDefault: $hourlyWageDefault
      isAvailableOnMonday: $isAvailableOnMonday
      isAvailableOnTuesday: $isAvailableOnTuesday
      isAvailableOnWednesday: $isAvailableOnWednesday
      isAvailableOnThursday: $isAvailableOnThursday
      isAvailableOnFriday: $isAvailableOnFriday
      isAvailableOnSaturday: $isAvailableOnSaturday
      isAvailableOnSunday: $isAvailableOnSunday
      isAvailableOnHoliday: $isAvailableOnHoliday
      classify: $classify
    ) {
      id
      createdAt
      updatedAt
      deletedAt
      startDate
      endDate
      startTime
      endTime
      hourlyWageDefault
      isAvailableOnMonday
      isAvailableOnTuesday
      isAvailableOnWednesday
      isAvailableOnThursday
      isAvailableOnFriday
      isAvailableOnSaturday
      isAvailableOnSunday
      isAvailableOnHoliday
      classify
    }
  }
`
