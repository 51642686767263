import './style.css'

import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { clinicalDepartmentNames } from '@medical/libs'
import { SINGLE_ANNOUNCE } from '@medical/pages/staff/Announces/AnnounceUpdate/AnnounceUpdate.graphql'
import { DOCTORS_DEPARTMENTS } from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import { useCustom } from '@medical/provider/context'
import {
  addItem,
  removeItem,
} from '@medical/provider/store/reducers/cart/cart.action'
import React from 'react'
import { connect } from 'react-redux'

import NewDetalScene from './NewDetalScene'
import { DOCTOR_ME } from '@medical/layout/DoctorLayout/DoctorLayoutRequest.graphql'

const NewsDetail = ({
  addItemToCart,
  removeItemFromCart,
  cartItems,
  match: {
    params: { newsId },
  },
}) => {
  const { loading, error, data } = useQuery(SINGLE_ANNOUNCE, {
    variables: {
      announceId: newsId,
    },
    fetchPolicy: 'network-only',
  })
  const {
    loading: loadingDoctordepartments,
    error: errorDoctordepartments,
    data: dataDoctordepartments,
  } = useQuery(DOCTORS_DEPARTMENTS)
  const {
    loading: doctorMeLoading,
    error: doctorMeError,
    data: doctorMeData,
  } = useQuery(DOCTOR_ME)

  const [{ i18n, popup }] = useCustom()
  if (loading || loadingDoctordepartments || doctorMeLoading)
    return <ProgressSpinner />
  if (error || errorDoctordepartments || doctorMeError)
    return <ErrorComponent error={error || errorDoctordepartments} />

  const { departments } = dataDoctordepartments

  const departmentNames = clinicalDepartmentNames({ departments })

  const { announce = {} } = data
  data.announce.availableShiftList = data?.announce.availableShiftList
    .filter(a =>
      doctorMeData.doctorMe.departments.includes(a.clinicalDepartment.name)
    )
    .map(a => ({
      ...a,
      clinicName: `${departmentNames[a.clinicalDepartment.name]}`,
    }))

  return (
    <NewDetalScene
      i18n={i18n}
      announce={announce}
      departmentNames={departmentNames}
      cartItems={cartItems}
      addItemToCart={item => {
        popup.success('募集シフトをカートに追加しました')
        addItemToCart(item)
      }}
      removeItemFromCart={item => {
        popup.warn('募集シフトをカートから削除しました')
        removeItemFromCart(item)
      }}
    />
  )
}

const mapStateToProps = state => ({
  cartItems: state.cartStore.items,
})

const mapDispatchToProps = dispatch => ({
  addItemToCart: item => dispatch(addItem(item)),
  removeItemFromCart: availableShiftId =>
    dispatch(removeItem(availableShiftId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewsDetail)
