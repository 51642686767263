import queryString from 'query-string'

const onChangePage = (history, search) => e => {
  const searchObject = queryString.parse(search)
  history.push({
    search: queryString.stringify({
      ...searchObject,
      page: e.page + 1,
    }),
  })
}
export const onChangePageAnt = (history, search) => e => {
  const searchObject = queryString.parse(search)
  history.push({
    search: queryString.stringify({
      ...searchObject,
      page: e ,
    }),
  })
}

export default onChangePage
