import gql from 'graphql-tag'

import { WORK_SCHEDULE_COMMENT_FRAGMENTS } from './DetailWorkSchedule.graphql'

export const ADD_COMMENT = gql`
  mutation ADD_COMMENT(
    $workScheduleId: ID
    $text: String!
    $type: commentTypes!
    $availableShiftId: ID
    $scheduledAvailableShiftGroupId: ID
    $waitingRecruitmentShiftId: ID
  ) {
    staffAddComment(
      workScheduleId: $workScheduleId
      availableShiftId: $availableShiftId
      scheduledAvailableShiftGroupId: $scheduledAvailableShiftGroupId
      waitingRecruitmentShiftId: $waitingRecruitmentShiftId
      text: $text
      type: $type
    ) {
      ...WORK_SCHEDULE_COMMENT_FRAGMENTS
    }
  }
  ${WORK_SCHEDULE_COMMENT_FRAGMENTS}
`

export const DELETE_COMMENT = gql`
  mutation DELETE_COMMENT($workScheduleCommentId: ID!) {
    staffDeleteComment(workScheduleCommentId: $workScheduleCommentId)
  }
`
export const GET_ENUM_COMMENT = gql`
  query Query($name: String!) {
    enumValues(name: $name) {
      description
      value
    }
  }
`
