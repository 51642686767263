import { useCustom } from '@medical/provider/context'
import React from 'react'
import { useMemo } from 'react'

export const DATE_ORDER = ['第一希望日', '第二希望日', '第三希望日']

export default function DoctorInterviewSchedule({ interviewTime }) {
  const [{ i18n }] = useCustom()
  const expectedTime = useMemo(() => JSON.parse(interviewTime), [interviewTime])

  return (
    <div style={{ marginTop: '30px' }}>
      {expectedTime &&
        expectedTime.map((item, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              gap: '32px',
              alignItems: 'center',
              marginBottom: '16px',
            }}
          >
            <div className='row' style={{ gap: '16px' }}>
              <div className='report-bold'>{DATE_ORDER[index]}</div>
              <div className='report-bold' style={{ fontWeight: 400 }}>
                {item.date}
              </div>
            </div>
            <div className='row' style={{ gap: '16px' }}>
              <div className='report-bold' style={{ width: '80px' }}>
                {item.type === 'SELECT'
                  ? i18n.t('doctor.signup.specifyTimeZone')
                  : i18n.t('doctor.signup.specifyTime')}
              </div>
              <div className='report-bold' style={{ fontWeight: 400 }}>
                {item.expectTime.join(', ')}
              </div>
            </div>
          </div>
        ))}
    </div>
  )
}
