import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, NotFound, ProgressSpinner } from '@medical/components'
import { onPageChange } from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import queryString from 'query-string'
import React, { useEffect } from 'react'

import { QUESTION_CATEGORY, QUESTION_CONNECTION } from './Questions.graphql'
import QuestionsScene from './QuestionScene'

const Questions = ({ history, location: { search } }) => {
  const [{ i18n }] = useCustom()
  const {
    page = 1,
    rowsPerPage = 20,
    orderBy = 'categoryCreatedAt_ASC',
    category,
    content,
  } = queryString.parse(search)
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  const variables = {
    first: first < 0 ? 10 : first,
    skip: skip < 0 ? 0 : skip,
    orderBy,
    where: {
      AND: [
        { isHide: false },
        {
          QuestionCategory_Question_categoryToQuestionCategory: {
            id: category,
            deletedAt: null,
          },
          OR: [
            {
              title_contains: content,
            },
            {
              content_contains: content,
            },
          ],
        },
      ],
    },
  }
  const { loading, error, data, refetch } = useQuery(QUESTION_CONNECTION, {
    variables,
    where: {
      deletedAt: null,
    },
    fetchPolicy: 'network-only',
  })
  const {
    loading: loadingCategorys,
    error: errorCategory,
    data: categorys,
  } = useQuery(QUESTION_CATEGORY, {
    orderBy: 'createdAt_ASC',
    fetchPolicy: 'network-only',
  })
  useEffect(() => {
    refetch()
  })
  if (loading || loadingCategorys) return <ProgressSpinner />
  if (error || errorCategory) return <ErrorComponent error={error} />

  const {
    questionsConnection: {
      edges,
      aggregate: { count },
    },
  } = data
  const listCategory = categorys.questionCategories.map(item => ({
    value: item.id,
    description: item.content,
    createdAt: item.createdAt,
  }))
  const categorySearch = () => {
    for (const item of listCategory) {
      if (item.value === category) {
        return item
      }
    }
  }
  return (
    <QuestionsScene
      i18n={i18n}
      categorySearch={categorySearch}
      contentSearch={content}
      history={history}
      listCategory={listCategory}
      edges={edges}
      rowsPerPage={rowsPerPage}
      page={page}
      count={count}
      onPageChange={onPageChange(history, search)}
      isCreatePermitted
      isDeletePermitted
      isUpdatePermitted
    />
  )
}

export default Questions
