import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, NotFound, ProgressSpinner } from '@medical/components'
import { UPDATE_MOVING_EXPENSES_REGISTRATION } from '@medical/constant/permissions'
import {
  checkStaffPermission,
  clinicalDepartmentNames,
  combineNames,
  sortAndFilterByClinicOrder,
} from '@medical/libs'
import { DEPARTMENTS_LIST } from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import { useCustom } from '@medical/provider/context'
import Router from '@medical/routes/router'
import Button from 'fork/primereact/button'
import moment from 'moment'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import CreateMovingExpensesScene from '../CreateMovingExpenses/CreateMovingExpensesScene'
import {
  GET_MOVING_EXPENSES,
  TRANPORTATION_TYPE_SETTINGS,
  UPDATE_TRANFFIC_FEE,
} from './UpdateMovingExpenses.graphql'

const UpdateMovingExpenses = ({
  closeModal,
  match: {
    params: {
      transportationId,
      year = moment().year(),
      month = moment().month() + 1,
    },
  },
}) => {
  const date = moment()
    .year(year)
    .month(month - 1)
    .startOf('month')
  const [{ i18n, popup }] = useCustom()
  const {
    loading: loadingDepartment,
    error: errorDepartment,
    data: departmentList,
  } = useQuery(DEPARTMENTS_LIST)
  const [staffUpdateTransportationTranfficFee] = useMutation(
    UPDATE_TRANFFIC_FEE
  )

  const { loading, error, data } = useQuery(GET_MOVING_EXPENSES, {
    variables: {
      where: {
        id: transportationId,
      },
    },
    fetchPolicy: 'network-only',
  })
  const {
    error: errorTypeSetting,
    loading: loadingTypeSetting,
    data: dataTypeSetting,
    refetch: refetchTypeSetting,
  } = useQuery(TRANPORTATION_TYPE_SETTINGS, {
    variables: {
      where: {
        isApply: true,
        isExpiredSetting: false,
      },
    },
  })
  useEffect(() => {
    refetchTypeSetting()
  })
  if (loading || loadingDepartment || loadingTypeSetting)
    return <ProgressSpinner />
  if (error || errorDepartment || errorTypeSetting)
    return (
      <ErrorComponent error={error || errorDepartment || errorTypeSetting} />
    )

  if (
    !data.transportationExpense ||
    !departmentList.departments ||
    !departmentList.clinicalDepartments
  ) {
    return <NotFound />
  }
  const isUpdateMovingExpenses = checkStaffPermission({
    functionName: UPDATE_MOVING_EXPENSES_REGISTRATION,
  })
  if (!isUpdateMovingExpenses) {
    return (
      <div className='static-container'>
        <div className='static-title'>エラー</div>
        <div className='static-content'>{i18n.t('main.noPermissionToSee')}</div>
        <Link to={Router.staffHome}>
          <Button
            label={i18n.t('main.pageNotFoundButton')}
            className='static-button'
          />
        </Link>
      </div>
    )
  }

  let { clinicalDepartments, departments } = departmentList
  clinicalDepartments = clinicalDepartments.filter(
    item => item.clinic.isPublished === true
  )

  const sortedClinicalDepartments = sortAndFilterByClinicOrder({
    clinicalDepartments,
    isFilter: true,
  })
  const { doctor } = data.transportationExpense
  const departmentNames = clinicalDepartmentNames({ departments })

  const onSubmit = async (
    { departmentId, cost, dateSelected, type, file, comment },
    { setSubmitting, setFieldValue }
  ) => {
    try {
      await staffUpdateTransportationTranfficFee({
        variables: {
          cost,
          clinicalDepartmentId: departmentId,
          type,
          endTime: moment(dateSelected).startOf('day'),
          transportationId,
          file,
          comment,
        },
      })
      popup.success(i18n.t('staff.hourlyPayment.submissionSuccess'))
      setSubmitting(false)
      closeModal()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
      setFieldValue('confirm', false)
    }
  }
  return (
    <CreateMovingExpensesScene
      date={date}
      i18n={i18n}
      popup={popup}
      transportationExpense={data.transportationExpense}
      fullName={doctor.fullName}
      onSubmit={onSubmit}
      clinicalDepartments={combineNames({
        clinicalDepartments: sortedClinicalDepartments,
        departmentNames,
      })}
      typeSettingData={dataTypeSetting.transportationTypeSettings}
    />
  )
}
export default UpdateMovingExpenses
