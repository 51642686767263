export const activityLogHistories = (
  activityHistories = [],
  activities = [],
  updateActivities = []
) => ({
  updateActivityHistories: activityHistories
    .filter(it => activities.find(ac => ac.value === it.activity))
    .map(it => ({
      id: it.id,
      staff: it.staff ? `${it.staff.lastname} ${it.staff.firstname}` : '',
      createdAt: it.createdAt,
      activity: activities.find(ac => ac.value === it.activity).description,
    })),
  postActivityHistories: activityHistories
    .filter(it => updateActivities.find(ac => ac.value === it.activity))
    .map(it => ({
      id: it.id,
      staff: it.staff ? `${it.staff.lastname} ${it.staff.firstname}` : '',
      createdAt: it.createdAt,
      activity: updateActivities.find(ac => ac.value === it.activity).value,
    })),
})
