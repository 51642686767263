import moment from 'moment'

export const OPTION_TYPES = {
  YEAR: 'YEAR',
  MONTH: 'MONTH',
}
export const generateOptions = (type, previousValue = 0, laterValue = 0) => {
  const result = []
  let currentValue = null
  switch (type) {
    case OPTION_TYPES.YEAR:
      currentValue = new Date().getFullYear()
      for (
        let i = currentValue - previousValue;
        i <= new Date().getFullYear() + laterValue;
        i += 1
      )
        result.push({ value: i, description: `${i}` })
      break
    case OPTION_TYPES.MONTH:
      for (let i = 1; i <= 12; i += 1)
        result.push({ value: i, description: `${i}` })
      break
    default:
      break
  }
  return result
}
const checkMyNumberArr = [6, 5, 4, 3, 2, 7, 6, 5, 4, 3, 2]

const calcCheckDigit = undecupleDigits => {
  if (!/^\d{11}$/.test(undecupleDigits)) return -1

  let sumPnxQn = 0
  for (let i = 0; i < 11; i += 1) {
    sumPnxQn += undecupleDigits[i] * checkMyNumberArr[i]
  }

  const mods = sumPnxQn % 11

  if (mods <= 1) {
    return 0
  }
  return 11 - mods
}

export const validateIdentityNumber = identityNumber => {
  if (!/^\d{12}$/.test(identityNumber)) return false
  const checkDigit = calcCheckDigit(identityNumber.substring(0, 11))
  const isValid = parseInt(identityNumber[11]) === parseInt(checkDigit)

  return isValid
}

export const checkIsPdf = value => {
  if (
    value &&
    value.substring(value.lastIndexOf('.') + 1).split('?')[0] === 'pdf'
  )
    return true
  return false
}

export const parseJwt = token => {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

export function validateStartTimeEndTime(startTime, endTime) {
  const startTimeHour = moment(startTime).hour()
  const endTimeHour = moment(endTime).hour()
  const endTimeMinute = moment(endTime).minute()
  if (startTimeHour >= 7 && startTimeHour < 13) {
    if (endTimeHour > 13) {
      return true
    }
    if (endTimeHour === 13 && endTimeMinute > 0) {
      return true
    } else {
      return false
    }
  }
  if (startTimeHour >= 13 && startTimeHour < 18) {
    if (endTimeHour > 18) {
      return true
    }
    if (endTimeHour === 18 && endTimeMinute > 0) {
      return true
    } else {
      return false
    }
  }
  if (startTimeHour > 18) {
    return false
  }
}

export const getDepartmentNameJP = departmentName => {
  let name = ''
  switch (departmentName) {
    case 'INTERNAL_MEDICINE':
      name = '内科'
      break
    case 'CHILDREN_MEDICINE':
      name = '小児科'
      break
    case 'CHILDREN_VACCINE_MEDICINE':
      name = '小児科ワクチン専任(対象：小児～成人)'
      break
    case 'INTERNAL_VACCINE_MEDICINE':
      name = '内科ワクチン専任(対象：小児～成人)'
      break
    default:
      break
  }
  return name
}

export const getWorkPatternJP = value => {
  switch (value) {
    case 'PERMANENT_DOCTOR':
      return '常勤'
    case 'REGULAR_SHIFT_DOCTOR':
      return '定期非常勤'
    case 'IRREGULAR_SHIFT_DOCTOR':
      return '不定期非常勤'
    case 'TEMPORARY_DOCTOR':
      return 'スポット'
  }
}

export const getRegistrationStatusJP = registrationStatus => {
  let name = ''
  switch (registrationStatus) {
    case 'NEW':
      name = '承認待ち'
      break
    case 'PENDING':
      name = '一時保留'
      break
    case 'REJECTED':
      name = '非承認'
      break
    case 'ACCEPTED':
      name = '承認済み'
      break
    case 'CREATED_BY_STAFF':
      name = 'スタッフ作成'
      break
    default:
      break
  }
  return name
}

export const getJoinBackgroundJP = joinBackground => {
  let name = ''
  switch (joinBackground) {
    case 'DIRECT':
      name = '直接'
      break
    case 'REFERRAL_COMPANY':
      name = '紹介会社'
      break
    case 'REFERRAL_STAFF':
      name = 'スタッフ紹介'
      break
    case 'OTHER':
      name = 'その他'
      break
    default:
      break
  }
  return name
}

// Helper function to format time as hh:mm in 24-hour format
function formatTime(date) {
  return date.toTimeString().slice(0, 5)
}

export function calculateShiftsByTime(startTime, endTime) {
  // Convert startTime and endTime strings to Date objects
  const startDate = new Date(`2000-01-01T${startTime}`)
  const endDate = new Date(`2000-01-01T${endTime}`)

  // Define shift boundaries
  const morningBoundary = new Date(`2000-01-01T15:00`)
  const afternoonBoundary = new Date(`2000-01-01T18:00`)

  // Initialize shift objects
  const shifts = {}

  // Calculate and assign shifts
  if (startDate < morningBoundary) {
    shifts.morningShift = `${startTime} - ${formatTime(
      endDate < morningBoundary ? endDate : morningBoundary
    )}`
    if (endDate > morningBoundary) {
      shifts.afternoonShift = `${formatTime(morningBoundary)} - ${formatTime(
        endDate < afternoonBoundary ? endDate : afternoonBoundary
      )}`
      if (endDate > afternoonBoundary) {
        shifts.eveningShift = `${formatTime(afternoonBoundary)} - ${endTime}`
      }
    }
  } else if (startDate < afternoonBoundary) {
    shifts.afternoonShift = `${startTime} - ${formatTime(
      endDate < afternoonBoundary ? endDate : afternoonBoundary
    )}`
    if (endDate > afternoonBoundary) {
      shifts.eveningShift = `${formatTime(afternoonBoundary)} - ${endTime}`
    }
  } else {
    shifts.eveningShift = `${startTime} - ${endTime}`
  }

  return shifts
}

export function formatHHmm(time) {
  return moment(time).format('HH:mm')
}

function convertTimeToFloat(time) {
  const timeHHmm = {
    startTime: formatHHmm(time.startTime),
    endTime: formatHHmm(time.endTime),
  }

  const [startHour, startMinute] = timeHHmm.startTime.split(':').map(Number)
  const [endHour, endMinute] = timeHHmm.endTime.split(':').map(Number)

  const startDecimal = startHour + startMinute / 60
  const endDecimal = endHour + endMinute / 60

  return { startTime: startDecimal, endTime: endDecimal }
}

export function convertDecimalToTime(decimal) {
  const hours = Math.floor(decimal)
  const minutes = Math.round((decimal - hours) * 60)
  const formattedHours = hours.toString().padStart(2, '0')
  const formattedMinutes = minutes.toString().padStart(2, '0')
  return `${formattedHours}:${formattedMinutes}`
}

function convertObjectToTime(obj) {
  const startTime = convertDecimalToTime(obj.startTime)
  const endTime = convertDecimalToTime(obj.endTime)
  return { startTime, endTime }
}

// Handle caculate final isshin time
export function getFinalWorkingTime(workingTime, leavingTime) {
  const rootWorkingTime = convertTimeToFloat(workingTime)
  const otherSchedules = leavingTime.map(item => convertTimeToFloat(item))

  const finalWorkingTime = []

  // Adding the first working time to the final array
  finalWorkingTime.push({
    startTime: rootWorkingTime.startTime,
    endTime: rootWorkingTime.endTime,
  })

  // Sorting the leaving time array based on the starting time
  otherSchedules.sort((a, b) => a.startTime - b.startTime)

  // Looping through each leaving time period
  for (let i = 0; i < otherSchedules.length; i++) {
    if (finalWorkingTime.length === 0) return ''
    const prevWorkingTime = finalWorkingTime[finalWorkingTime.length - 1]
    const currentSchedule = otherSchedules[i]

    if (
      prevWorkingTime.startTime >= currentSchedule.endTime ||
      prevWorkingTime.endTime <= currentSchedule.startTime
    ) {
      continue
    }

    // If the current leaving time is within the current working time
    if (
      currentSchedule.startTime >= prevWorkingTime.startTime &&
      currentSchedule.endTime <= prevWorkingTime.endTime
    ) {
      // Split the working time into two parts and add to the final array
      finalWorkingTime.pop()
      if (prevWorkingTime.startTime !== currentSchedule.startTime) {
        finalWorkingTime.push({
          startTime: prevWorkingTime.startTime,
          endTime: currentSchedule.startTime,
        })
      }
      if (prevWorkingTime.endTime !== currentSchedule.endTime) {
        finalWorkingTime.push({
          startTime: currentSchedule.endTime,
          endTime: prevWorkingTime.endTime,
        })
      }
    } else {
      // If the current leaving time is not within the current working time,
      // then find the next working time period and add to the final array
      let nextWorkingTime
      for (let j = i + 1; j < otherSchedules.length; j++) {
        const nextSchedule = otherSchedules[j]
        if (nextSchedule.startTime > prevWorkingTime.endTime) {
          nextWorkingTime = {
            startTime: prevWorkingTime.endTime,
            endTime: nextSchedule.startTime,
          }
          break
        }
      }

      if (!nextWorkingTime) {
        // If there is no next working time period, then the remaining time is added to the final array
        finalWorkingTime.pop()
        if (prevWorkingTime.startTime !== currentSchedule.startTime) {
          finalWorkingTime.push({
            startTime: prevWorkingTime.startTime,
            endTime: currentSchedule.startTime,
          })
        }
        if (prevWorkingTime.endTime !== currentSchedule.endTime) {
          finalWorkingTime.push({
            startTime: currentSchedule.endTime,
            endTime: prevWorkingTime.endTime,
          })
        }
      } else {
        // If there is a next working time period, then split the current working time and add to the final array
        finalWorkingTime.pop()
        if (prevWorkingTime.startTime !== currentSchedule.startTime) {
          finalWorkingTime.push({
            startTime: prevWorkingTime.startTime,
            endTime: currentSchedule.startTime,
          })
        }
        finalWorkingTime.push({
          startTime: currentSchedule.endTime,
          endTime: nextWorkingTime.startTime,
        })
        finalWorkingTime.push({
          startTime: nextWorkingTime.endTime,
          endTime: prevWorkingTime.endTime,
        })
      }
    }
  }

  const workTimeCheckedTime = finalWorkingTime.filter(schedule => {
    return schedule.startTime < schedule.endTime
  })

  // get and return first availableTime
  const availableSplit = workTimeCheckedTime.length
    ? workTimeCheckedTime.map(convertObjectToTime)
    : []

  return availableSplit
}

// Convert schedule has startTime and endTime to shifts as morning, afternoon, evening
export function convertScheduleToTimeShift(schedule) {
  // Initialize shift objects
  const shifts = {
    morningShift: '',
    afternoonShift: '',
    eveningShift: '',
  }
  if (!schedule) return shifts

  const { startTime, endTime } = schedule

  // Convert startTime and endTime strings to Date objects
  const startDate = new Date(`2000-01-01T${startTime}`)
  const endDate = new Date(`2000-01-01T${endTime}`)

  // Define shift boundaries
  const morningBoundary = new Date(`2000-01-01T13:00`)
  const afternoonBoundary = new Date(`2000-01-01T15:00`)
  const eveningnoonBoundary = new Date(`2000-01-01T18:00`)

  // Calculate and assign shifts
  if (startDate < morningBoundary) {
    shifts.morningShift = `${startTime}-${formatTime(
      endDate < morningBoundary ? endDate : morningBoundary
    )}`
    if (endDate > afternoonBoundary) {
      shifts.afternoonShift = `${formatTime(afternoonBoundary)}-${formatTime(
        endDate < eveningnoonBoundary ? endDate : eveningnoonBoundary
      )}`
      if (endDate > eveningnoonBoundary) {
        shifts.eveningShift = `${formatTime(eveningnoonBoundary)}-${endTime}`
      }
    }
  } else if (startDate < eveningnoonBoundary) {
    shifts.afternoonShift = `${startTime}-${formatTime(
      endDate < eveningnoonBoundary ? endDate : eveningnoonBoundary
    )}`
    if (endDate > eveningnoonBoundary) {
      shifts.eveningShift = `${formatTime(eveningnoonBoundary)}-${endTime}`
    }
  } else {
    shifts.eveningShift = `${startTime}-${endTime}`
  }

  return shifts
}

export function getDaysBetween(startDatetime, endDatetime) {
  const startDate = moment(startDatetime).startOf('day')
  const endDate = moment(endDatetime).endOf('day')
  const days = []

  const currentDate = startDate.clone()
  while (currentDate.isSameOrBefore(endDate, 'day')) {
    days.push(currentDate.format('YYYY-MM-DD'))
    currentDate.add(1, 'day')
  }

  return days
}

export function joinAdjacentTimes(timeIntervals) {
  if (!timeIntervals.length) {
    return []
  }

  timeIntervals.sort((a, b) => a.startTime.localeCompare(b.startTime)) // Sort intervals by start time
  const mergedIntervals = [timeIntervals[0]]

  for (let i = 1; i < timeIntervals.length; i++) {
    const prevInterval = mergedIntervals[mergedIntervals.length - 1]
    const prevEndTime = new Date(`1970-01-01T${prevInterval.endTime}`)
    const currentStartTime = new Date(
      `1970-01-01T${timeIntervals[i].startTime}`
    )

    if (currentStartTime <= prevEndTime) {
      prevInterval.endTime = timeIntervals[i].endTime
    } else {
      mergedIntervals.push(timeIntervals[i])
    }
  }

  return mergedIntervals
}

export const caculatorSalary = ({
  adjustHourlyWageRange,
  startTime,
  endTime,
  hourlyWage,
  splitDateTime1,
  splitDateTime2,
  splitDateTime3,
  splitHourlyWage1,
  splitHourlyWage2,
  splitHourlyWage3,
  isStartTimeBreakTime,
  isSplitDateTime1BreakTime,
  isSplitDateTime2BreakTime,
  isSplitDateTime3BreakTime,
}) => {
  let totalDoctorSubsidy = 0

  const splits = []
  const interval = moment.range(moment(startTime), moment(endTime))

  let rangeStartTime = startTime
  const rangeEndTime = endTime
  if (splitDateTime1 && moment(splitDateTime1).within(interval)) {
    splits.push({
      range: moment.range(moment(rangeStartTime), moment(splitDateTime1)),
      hourlyWage:
        splitHourlyWage1 +
          (!isSplitDateTime1BreakTime ? adjustHourlyWageRange : 0) || 0,
    })
    rangeStartTime = splitDateTime1
  }
  if (splitDateTime2 && moment(splitDateTime2).within(interval)) {
    splits.push({
      range: moment.range(moment(rangeStartTime), moment(splitDateTime2)),
      hourlyWage:
        splitHourlyWage2 +
          (!isSplitDateTime2BreakTime ? adjustHourlyWageRange : 0) || 0,
    })
    rangeStartTime = splitDateTime2
  }

  if (splitDateTime3 && moment(splitDateTime3).within(interval)) {
    splits.push({
      range: moment.range(moment(rangeStartTime), moment(splitDateTime3)),
      hourlyWage:
        splitHourlyWage3 +
          (!isSplitDateTime3BreakTime ? adjustHourlyWageRange : 0) || 0,
    })
    rangeStartTime = splitDateTime3
  }

  splits.push({
    range: moment.range(moment(rangeStartTime), moment(rangeEndTime)),
    hourlyWage:
      hourlyWage + (!isStartTimeBreakTime ? adjustHourlyWageRange : 0) || 0,
  })

  const dailySalary =
    splits.reduce(
      (acc, curr) => acc + (curr.range.diff('minutes') / 60) * curr.hourlyWage,
      0
    ) + totalDoctorSubsidy

  return Math.round(dailySalary)
}

export function getTimeFromDate(dateString) {
  const dateTime = new Date(dateString)
  const hours = dateTime
    .getUTCHours()
    .toString()
    .padStart(2, '0')
  const minutes = dateTime
    .getUTCMinutes()
    .toString()
    .padStart(2, '0')

  return `${hours}:${minutes}`
}

function parseTime(timeString) {
  const [hours, minutes] = timeString.split(':').map(Number)
  return hours * 60 + minutes
}
function isTimeInRange(time, startTime, endTime) {
  return time >= startTime && time <= endTime
}

function formatTimeToString(minutes) {
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60
  const formattedHours = hours >= 10 ? hours : `0${hours}`
  const formattedMinutes =
    remainingMinutes >= 10 ? remainingMinutes : `0${remainingMinutes}`
  return `${formattedHours}:${formattedMinutes}`
}
export function getNonCoveredTimeRanges(intervals) {
  const DAY_START = parseTime('09:00')
  const DAY_END = parseTime('21:00')
  intervals.push({ startTime: '13:00', endTime: '15:00' })
  const timeIntervals = mergeTimeIntervals(intervals)
  let availableTimeRanges = [
    {
      startTime: DAY_START,
      endTime: DAY_END,
    },
  ]
  for (const interval of timeIntervals) {
    let startTime
    startTime = parseTime(interval.startTime)
    if (parseTime(interval.startTime) < DAY_START) {
      startTime = parseTime('09:00')
    }
    if (
      parseTime(interval.startTime) < DAY_START &&
      parseTime(interval.endTime) < DAY_START
    )
      continue
    const endTime = parseTime(interval.endTime)
    const newAvailableTimeRanges = []

    for (const availableRange of availableTimeRanges) {
      if (
        isTimeInRange(
          startTime,
          availableRange.startTime,
          availableRange.endTime
        )
      ) {
        if (availableRange.startTime < startTime) {
          newAvailableTimeRanges.push({
            startTime: availableRange.startTime,
            endTime: startTime,
          })
        }
        if (availableRange.endTime > endTime) {
          newAvailableTimeRanges.push({
            startTime: endTime,
            endTime: availableRange.endTime,
          })
        }
      } else {
        newAvailableTimeRanges.push({
          startTime: availableRange.startTime,
          endTime: availableRange.endTime,
        })
      }
    }
    availableTimeRanges = newAvailableTimeRanges
  }
  return availableTimeRanges.map(item => ({
    startTime: formatTimeToString(item.startTime),
    endTime: formatTimeToString(item.endTime),
  }))
}

function mergeTimeIntervals(intervals) {
  intervals.sort((a, b) => parseTime(a.startTime) - parseTime(b.startTime))

  const mergedIntervals = []
  let currentInterval = intervals[0]

  for (let i = 1; i < intervals.length; i++) {
    const nextInterval = intervals[i]
    const currentEndTime = parseTime(currentInterval.endTime)
    const nextStartTime = parseTime(nextInterval.startTime)
    const nextEndTime = parseTime(nextInterval.endTime)

    if (nextStartTime <= currentEndTime) {
      currentInterval.endTime =
        nextEndTime > currentEndTime
          ? nextInterval.endTime
          : currentInterval.endTime
    } else {
      mergedIntervals.push(currentInterval)
      currentInterval = nextInterval
    }
  }

  mergedIntervals.push(currentInterval)

  return mergedIntervals
}
