import BreadCrumb from '@medical/components/Breadcrumb'
import ModalLink from '@medical/components/ModalLink'
import Router from '@medical/routes/router'
import { DatePicker } from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Paginator } from 'primereact/paginator'
import queryString from 'query-string'
import React from 'react'
import { Link } from 'react-router-dom'

const WorkOutsideClinicScene = ({
  resultWorkOutsidesList,
  i18n,
  rowsPerPage,
  page,
  count,
  onPageChange,
  fullname,
  date,
  statusConvertText,
  isProcessPermitted,
}) => (
  <Formik
    enableReinitialize
    initialValues={{
      fullname,
      date,
    }}
    onSubmit={{}}
    render={formikProps => {
      const { values, handleChange, handleBlur, setFieldValue } = formikProps
      return (
        <div
          style={{
            padding: '10px',
            height: '100%',
          }}
        >
          <div className='staff-header'>
            <div className='staff-title'>
              {i18n.t('main.workOutsideClinic.staffTitle')}
              <BreadCrumb
                items={[{ title: i18n.t('main.workOutsideClinic.staffTitle') }]}
              />
            </div>
          </div>
          <div
            className='box'
            style={{
              backgroundColor: '#ffffff',
              marginLeft: '20px',
              marginRight: '20px',
              padding: '20px',
              borderRadius: '10px',
            }}
          >
            <div className='search-parent'>
              <InputText
                name='fullname'
                value={values.fullname}
                placeholder={i18n.t('main.fullname')}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{ width: '200px', marginRight: '20px' }}
              />
              <DatePicker
                placeholder='勤務日を選択'
                value={values.date ? moment(values.date) : null}
                format='YYYY年MM月DD日'
                locale={locale}
                allowClear={false}
                onChange={e => {
                  setFieldValue('date', moment(e).format())
                }}
              />
              <Link
                to={{
                  pathname: Router.staffWorkOutsideClinic,
                  search: queryString.stringify({
                    rowsPerPage,
                    fullname: values.fullname,
                    date: values.date,
                  }),
                }}
                style={{ marginLeft: '20px' }}
              >
                <Button label={i18n.t('main.search')} icon='pi pi-search' />
              </Link>
              {fullname || date ? (
                <Link
                  to={{
                    pathname: Router.staffWorkOutsideClinic,
                    search: queryString.stringify({
                      rowsPerPage,
                    }),
                  }}
                >
                  <Button
                    icon='pi pi-times'
                    className='p-button-secondary'
                    style={{ marginLeft: '20px' }}
                  />
                </Link>
              ) : null}
            </div>
            <div>
              <div>
                <table className='table-workSchedule-cancel'>
                  <thead>
                    <tr className='table-workSchedule-cancel-th'>
                      <th width='10%'>
                        {i18n.t('main.workOutsideClinic.doctorName')}
                      </th>
                      <th width='15%'>
                        {i18n.t('main.workOutsideClinic.workingDay')}
                      </th>
                      <th width='10%'>
                        {i18n.t('main.workOutsideClinic.createdAt')}
                      </th>
                      <th width='10%'>
                        {i18n.t('main.workOutsideClinic.clinicName')}
                      </th>
                      <th width='10%'>
                        {i18n.t('main.workOutsideClinic.time')}
                      </th>
                      <th width='10%'>
                        {i18n.t('main.workOutsideClinic.address')}
                      </th>
                      <th width='10%'>
                        {i18n.t('main.workOutsideClinic.status')}
                      </th>
                      <th width='10%' />
                    </tr>
                  </thead>
                  <tbody>
                    {resultWorkOutsidesList.length > 0 ? (
                      resultWorkOutsidesList.map(record => (
                        <tr key={record.id} style={{ textAlign: 'center' }}>
                          <td>{record.doctorName}</td>
                          <td>
                            {moment(record.startTime).format('YYYY年MM月DD日')}
                          </td>
                          <td>
                            {moment(record.createdAt).format('YYYY年MM月DD日')}
                          </td>
                          <td>{`${record.clinicName}_${record.clinicalDepartmentName}`}</td>
                          <td>
                            {`${moment(record.startTime).format(
                              'HH:mm'
                            )}～${moment(record.endTime).format('HH:mm')}`}
                          </td>
                          <td>{record.address}</td>
                          <td>{statusConvertText(record.status)}</td>
                          <td>
                            <ModalLink
                              to={Router.get(
                                Router.staffWorkOutsideClinicDetail,
                                {
                                  id: record.id,
                                }
                              )}
                            >
                              <Button icon='pi pi-info' />
                            </ModalLink>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={8}>申請がありません</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <Paginator
              rows={parseInt(rowsPerPage)}
              totalRecords={count}
              first={(page - 1) * rowsPerPage}
              onPageChange={onPageChange}
              leftContent={
                <div className='paginator-total'>
                  {i18n.t('main.paginationTotal')}: {count}
                </div>
              }
            />
          </div>
        </div>
      )
    }}
  />
)
export default WorkOutsideClinicScene
