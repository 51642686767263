import gql from 'graphql-tag'

export const CREATE_MOVING_EXPENSES = gql`
  mutation staffCreateTransportationExpense(
    $doctorId: ID!
    $departmentId: ID!
    $cost: Int!
    $month: DateTime!
    $type: String
    $file: String
    $comment: String
  ) {
    staffCreateTransportationExpense(
      doctorId: $doctorId
      departmentId: $departmentId
      cost: $cost
      month: $month
      type: $type
      file: $file
      comment: $comment
    )
  }
`
export const TRANPORTATION_TYPE_SETTINGS = gql`
  query TransportationTypeSettings(
    $where: TransportationTypeSettingWhereInput
  ) {
    transportationTypeSettings(where: $where) {
      id
      maxCost
      type
      isApply
    }
  }
`
