import BreadCrumb from '@medical/components/Breadcrumb'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import Editor from '@medical/components/Editor'
import ModalLink from '@medical/components/ModalLink'
import Router from '@medical/routes/router'
import { Input } from 'antd'
import { Formik } from 'formik'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

const QuestionsForm = ({
  listCategory = {},
  category,
  question = {},
  onSubmit,
  submitDeletLabel,
  isDeletePermitted,
  isCreatePermitted,
  isUpdatePermitted,
  confirmationMessage,
  confirmationDeleteMessage,
  submitLabel,
}) => {
  const { i18n } = useTranslation()
  const validationSchema = Yup.object().shape({
    category: Yup.object()
      .nullable()
      .required(
        `${i18n.t('staff.questions.category')}${i18n.t('validation.required')}`
      ),
    title: Yup.string()
      .trim()
      .required(i18n.t('validation.title.required')),
    content: Yup.string()
      .trim()
      .max(100000, i18n.t('validation.content.max', { max: 100000 }))
      .nullable()
      .required('内容を入力してください')
      .test('only-text', '内容を入力してください', value => {
        const div = document.createElement('div')
        div.innerHTML = value
        return div.textContent.trim()
      }),
  })

  return (
    <React.Fragment>
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('staff.questions.title')}
          <BreadCrumb
            link={Router.staffQuestions}
            items={[
              {
                title: i18n.t('staff.menuBar.question'),
                to: Router.staffQuestions,
              },
            ]}
          />
        </div>
      </div>
      <div className='container' >
        <div className='box'>
          <Formik
            initialValues={{
              confirm: false,
              id: question.id,
              title: question.title,
              content: question.content,
              category,
              confirmationMessage,
            }}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            render={formikProps => {
              const {
                values,
                touched,
                errors,
                setTouched,
                validateForm,
                handleChange,
                setFieldValue,
              } = formikProps
              return (
                <div>
                  <div className='tr'>
                    <div className='th' style={{ width: '12%' }}>
                      {i18n.t('staff.questions.category')}
                    </div>
                    <div className='td'>
                      <Dropdown
                        value={values.category}
                        options={listCategory}
                        name='categoryId'
                        optionLabel='description'
                        valueLabel='value'
                        placeholder='カテゴリー'
                        onChange={e => {
                          setFieldValue('category', e.value)
                        }}
                        style={{ width: '100%' }}
                      />
                      <div className='alert'>
                        {!!touched.category && errors.category}
                      </div>
                    </div>
                    <div style={{ marginLeft: 'auto', marginRight: '-33px' }}>
                      <ModalLink to={Router.get(Router.staffQuestionCategory)}>
                        <Button icon='pi pi-pencil' />
                      </ModalLink>
                    </div>
                  </div>

                  <div className='tr'>
                    <div className='th' style={{ width: '12%' }}>
                      {i18n.t('staff.questions.titleLabel')}
                    </div>
                    <div className='td'>
                      <Input
                        value={values.title}
                        name='title'
                        placeholder='タイトル'
                        onChange={handleChange}
                        style={{ width: '100%' }}
                      />
                      <div className='alert'>
                        {!!touched.title && errors.title}
                      </div>
                    </div>
                  </div>
                  <div className='tr'>
                    <div className='th' style={{ width: '12%' }}>
                      {i18n.t('staff.questions.content')}
                    </div>
                    <div className='td'>
                      <Editor
                        value={values.content}
                        headerEditor='question'
                        onTextChange={e =>
                          setFieldValue('content', e.htmlValue)
                        }
                      />
                      <div className='alert'>
                        {!!touched.content && errors.content}
                      </div>
                    </div>
                  </div>
                  <div className='modal-bottom'>
                    {isDeletePermitted ? (
                      <Button
                        label={submitDeletLabel}
                        onClick={() => {
                          validateForm().then(response => {
                            if (!Object.keys(response).length) {
                              setFieldValue(
                                'confirmationMessage',
                                confirmationDeleteMessage
                              )
                              setFieldValue('isDelete', true)
                              setFieldValue('confirm', true)
                            } else {
                              setTouched(response)
                            }
                          })
                        }}
                        className='cancel-button '
                      />
                    ) : null}
                    {isCreatePermitted || isUpdatePermitted ? (
                      <Button
                        label={submitLabel}
                        onClick={() => {
                          validateForm().then(response => {
                            if (!Object.keys(response).length) {
                              setFieldValue(
                                'confirmationMessage',
                                confirmationMessage
                              )
                              setFieldValue('isDelete', false)
                              setFieldValue('confirm', true)
                            } else {
                              setTouched(response)
                            }
                          })
                        }}
                        className={isCreatePermitted ? 'reflect-button' : null}
                      />
                    ) : null}
                  </div>

                  <ConfirmationModal
                    {...formikProps}
                    confirmationMessage={values.confirmationMessage}
                  />
                </div>
              )
            }}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default QuestionsForm
