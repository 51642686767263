import {
  PERMITTED_ACTION_FRAGMENT,
  API_GROUP_TYPE_FRAGMENT,
} from '@medical/libs/general.graphql'
import { API_QUERY_FRAGMENT } from '@medical/pages/staff/StaffGroups/StaffGroupDetail/StaffGroupDetail.graphql'
import gql from 'graphql-tag'

export const STAFF_GROUP_CREATE_QUERY = gql`
  query STAFF_GROUP_QUERY {
    ...API_QUERY_FRAGMENT
    ...PERMITTED_ACTION_FRAGMENT
    ...API_GROUP_TYPE_FRAGMENT
  }
  ${API_QUERY_FRAGMENT}
  ${PERMITTED_ACTION_FRAGMENT}
  ${API_GROUP_TYPE_FRAGMENT}
`

export const CREATE_STAFF_GROUP = gql`
  mutation CREATE_STAFF_GROUP(
    $name: String!
    $permissions: [PermissionForCreate!]!
  ) {
    staffCreateStaffGroup(name: $name, permissions: $permissions)
  }
`
