import gql from 'graphql-tag'

export const TRANSPORTATION_TYPE_SETTINGS_CONNECTION = gql`
  query TransportationTypeSettingsConnection(
    $where: TransportationTypeSettingWhereInput
    $orderBy: TransportationTypeSettingOrderByInput
    $first: Int
    $skip: Int
  ) {
    transportationTypeSettingsConnection(
      where: $where
      orderBy: $orderBy
      first: $first
      skip: $skip
    ) {
      edges {
        node {
          id
          isApply
          maxCost
          createdAt
          updatedAt
          createdBy {
            id
            firstname
            lastname
          }
          updatedBy {
            id
            firstname
            lastname
          }
          type
        }
      }
      aggregate(where: $where) {
        count
      }
    }
  }
`
export const STAFF_CREATE_TRANSPORTATION_TYPE_SETTING = gql`
  mutation staffCreateTransportTypeSetting(
    $type: String!
    $isApply: Boolean!
    $maxCost: Int
  ) {
    staffCreateTransportTypeSetting(
      type: $type
      isApply: $isApply
      maxCost: $maxCost
    ) {
      id
    }
  }
`

export const TRANSPORTATION_TYPE_SETTINGS_SINGLE = gql`
  query TransportationTypeSetting(
    $where: TransportationTypeSettingWhereUniqueInput!
  ) {
    transportationTypeSetting(where: $where) {
      id
      isApply
      maxCost
      createdAt
      updatedAt
      createdBy {
        id
        firstname
        lastname
      }
      updatedBy {
        id
        firstname
        lastname
      }
      type
    }
  }
`
export const DELETE_TRANSPORTATION_TYPE_SETTINGS = gql`
  mutation StaffUpsertTransportTypeSetting(
    $staffUpsertTransportTypeSettingId: ID!
    $action: String!
    $type: String
    $maxCost: Int
    $isApply: Boolean
  ) {
    staffUpsertTransportTypeSetting(
      id: $staffUpsertTransportTypeSettingId
      action: $action
      type: $type
      maxCost: $maxCost
      isApply: $isApply
    )
  }
`
export const TRANSPORTATION_TYPE_SETTINGS = gql`
  query TransportationTypeSettings(
    $where: TransportationTypeSettingWhereInput
  ) {
    transportationTypeSettings(where: $where) {
      id
      isApply
      isExpiredSetting
      expiredDate
      maxCost
      type
    }
  }
`

export const STAFF_UPDATE_TRANPORTATION_SETTING_DATE = gql`
  mutation StaffUpdateTransportSettingDate(
    $staffUpdateTransportSettingDateId: ID!
    $expiredDate: Int
    $isApply: Boolean
  ) {
    staffUpdateTransportSettingDate(
      id: $staffUpdateTransportSettingDateId
      expiredDate: $expiredDate
      isApply: $isApply
    )
  }
`