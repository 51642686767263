import BreadCrumb from '@medical/components/Breadcrumb'
import { FieldArray, Formik } from 'formik'
import Papa from 'papaparse'
import { Button } from 'primereact/button'
import React from 'react'
import Dropzone from 'react-dropzone'
import * as Yup from 'yup'

const WorkScheduleCsvScene = ({
  popup,
  i18n,
  downloadClinicalDepartments,
  onSubmit,
  downloadSample,
  isAvailableShift,
  isUploaded,
  checkCsvData,
  isUploadPermitted,
  isUploadCheckPermitted,
}) => {
  const translateKeys = {
    numberOfRooms: i18n.t('staff.createShift.numberOfRooms'),
    startTime: i18n.t('staff.createShift.startTime'),
    endTime: i18n.t('staff.createShift.endTime'),
    hourlyWage: i18n.t('main.downloadWorkSchedule.hourlyWage'),
    splitDateTime1: i18n.t('main.downloadWorkSchedule.splitDateTime1'),
    splitDateTime2: i18n.t('main.downloadWorkSchedule.splitDateTime2'),
    splitDateTime3: i18n.t('main.downloadWorkSchedule.splitDateTime3'),
    splitHourlyWage1: i18n.t('main.downloadWorkSchedule.splitHourlyWage1'),
    splitHourlyWage2: i18n.t('main.downloadWorkSchedule.splitHourlyWage2'),
    splitHourlyWage3: i18n.t('main.downloadWorkSchedule.splitHourlyWage3'),
    delete: i18n.t('doctor.availableShifts.removeItem'),
    departmentCode: i18n.t('main.departmentCode'),
    email: i18n.t('updateProfile.email'),
    date: i18n.t('staff.createShift.date'),
    comment: i18n.t('main.workDetail'),
    isStartTimeBreakTime: i18n.t(
      'main.downloadWorkSchedule.isStartTimeBreakTime'
    ),
    isSplitDateTime1BreakTime: i18n.t(
      'main.downloadWorkSchedule.isSplitDateTime1BreakTime'
    ),
    isSplitDateTime2BreakTime: i18n.t(
      'main.downloadWorkSchedule.isSplitDateTime2BreakTime'
    ),
    isSplitDateTime3BreakTime: i18n.t(
      'main.downloadWorkSchedule.isSplitDateTime3BreakTime'
    ),
  }
  const validationSchema = Yup.object().shape({
    data: Yup.array()
      .min(1, i18n.t('validation.atLeastOneItem'))
      .of(
        Yup.object().shape({
          email: isAvailableShift
            ? undefined
            : Yup.string()
                .email(i18n.t('validation.email.email'))
                .required(i18n.t('validation.email.required')),
          date: Yup.string().required(
            `${i18n.t('main.date')} ${i18n.t('validation.required')}`
          ),
          startTime: Yup.string().required(
            `${i18n.t('main.startTime')} ${i18n.t('validation.required')}`
          ),
          endTime: Yup.string().required(
            `${i18n.t('main.endTime')} ${i18n.t('validation.required')}`
          ),
          splitDateTime1: Yup.string(),
          splitDateTime2: Yup.string(),
          splitDateTime3: Yup.string(),
          hourlyWage: Yup.number()
            .typeError(i18n.t('validation.hourlyWage.required'))
            .required(i18n.t('validation.hourlyWage.required')),
          splitHourlyWage1: Yup.number().transform((cv, ov) =>
            ov === '' ? undefined : cv
          ),
          splitHourlyWage2: Yup.number().transform((cv, ov) =>
            ov === '' ? undefined : cv
          ),
          splitHourlyWage3: Yup.number().transform((cv, ov) =>
            ov === '' ? undefined : cv
          ),
          numberOfRooms: isAvailableShift
            ? Yup.number()
                .min(1, `${i18n.t('validation.numberOfRooms.min')}`)
                .max(10, `${i18n.t('validation.numberOfRooms.max')}`)
                .typeError(i18n.t('validation.numberOfRooms.required'))
                .required(i18n.t('validation.numberOfRooms.required'))
            : undefined,
          delete:
            !isAvailableShift &&
            Yup.mixed()
              .nullable()
              .test(
                'isDelete',
                `${i18n.t('validation.isDelete')}`,
                value => value === '1' || !value
              ),
          comment: Yup.string(),
          isStartTimeBreakTime: Yup.boolean()
            .typeError(i18n.t('validation.breakTime.required'))
            .required(i18n.t('validation.breakTime.required')),
          isSplitDateTime1BreakTime: Yup.boolean().transform((cv, ov) =>
            ov === '' ? undefined : cv
          ),
          isSplitDateTime2BreakTime: Yup.boolean().transform((cv, ov) =>
            ov === '' ? undefined : cv
          ),
          isSplitDateTime3BreakTime: Yup.boolean().transform((cv, ov) =>
            ov === '' ? undefined : cv
          ),
        })
      ),
  })

  const exceptKeys = ['checked', 'errorMessage']

  return (
    <Formik
      initialValues={{
        data: [],
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={formikProps => {
        const {
          values,
          errors,
          setFieldValue,
          handleSubmit,
          touched,
        } = formikProps
        const { data } = values
        let keys = []
        if (data.length > 0) {
          
          keys = Object.keys(data[0]).filter(
            key => key !== 'errorMessage' || key !== 'checked'
          )
        }
        const titles = {
          true: i18n.t('staff.menuBar.titleAvailableShiftCSV'),
          false: i18n.t('staff.menuBar.titleWorkSchedule'),
        }
        
        return (
          <React.Fragment>
            <div className='staff-header'>
              <div className='staff-title'>
                {titles[isAvailableShift]}
                <BreadCrumb
                  items={[
                    {
                      title: titles[isAvailableShift],
                    },
                  ]}
                />
              </div>
              <div className='staff-buttons'>
                <Button
                  icon='pi pi-download'
                  className='p-button-normal'
                  onClick={downloadClinicalDepartments}
                  label={i18n.t('main.clinicalDepartment')}
                />
                <Button
                  icon='pi pi-download'
                  className='p-button-normal'
                  onClick={downloadSample}
                  label={i18n.t('main.sampleCsv')}
                />
              </div>
            </div>
            {isUploadPermitted || isUploadCheckPermitted ? (
              <div className='container'>
                <div className='box'>
                  <div className='box-top'>
                    <div
                      style={{
                        position: 'relative',
                        display: 'flex',
                        flex: 1,
                        alignItems: 'center',
                      }}
                    >
                      <div className='alert is-left'>
                        {typeof errors.data === 'string' &&
                          touched.data &&
                          errors.data}
                      </div>
                    </div>
                    {values.data.length > 0 && (
                      <Button
                        onClick={() => setFieldValue('data', [])}
                        label={i18n.t('main.cancel')}
                        className='p-button-secondary'
                        icon='pi pi-times'
                        style={{ marginRight: '20px' }}
                      />
                    )}
                    {isUploadCheckPermitted ? (
                      <Button
                        onClick={() => {
                          if (
                            (errors.data && errors.data.length > 0) ||
                            values.data.find(d => d.errorMessage)
                          ) {
                            popup.error(i18n.t('main.hasCsvFileError'))
                          } else {
                            checkCsvData(values, { setFieldValue })
                          }
                        }}
                        label={i18n.t('staff.csvUpload.check')}
                        type='submit'
                        disabled={data.length === 0 || isUploaded}
                        style={{ marginRight: '20px' }}
                      />
                    ) : null}
                    {isUploadPermitted ? (
                      <Button
                        onClick={() => {
                          if (
                            (errors.data && errors.data.length > 0) ||
                            values.data.find(d => d.errorMessage)
                          ) {
                            popup.error(i18n.t('main.hasCsvFileError'))
                          } else {
                            handleSubmit()
                          }
                        }}
                        label={i18n.t('staff.csvUpload.submit')}
                        type='submit'
                        disabled={data.length === 0 || isUploaded}
                      />
                    ) : null}
                  </div>
                  {values.data.length < 1 && (
                    <Dropzone
                      onDrop={files => {
                        if (files.length > 0) {
                          Papa.parse(files[0], {
                            header: true,
                            complete: async results => {
                              setFieldValue('data', results.data)
                            },
                          })
                        }
                      }}
                      accept='.csv'
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: 'dropzone' })}>
                          <input {...getInputProps()} />
                          <p>{i18n.t('doctor.uploadFile.dropFileHere')}</p>
                        </div>
                      )}
                    </Dropzone>
                  )}
                  {data.length > 0 && (
                    <div className='overflow-container'>
                      <table className='table'>
                        <thead>
                          <tr>
                            <th>{i18n.t('main.action')}</th>
                            {keys.map(
                              (key, index) =>
                                !exceptKeys.includes(key) && (
                                  <th key={`${key}_${index}`}>
                                    {translateKeys[key]}
                                  </th>
                                )
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          <FieldArray
                            name='data'
                            render={({ remove }) => (
                              <React.Fragment>
                                
                                {data.map((item, i) => {
                                  
                                  const error =
                                    errors.data && errors.data.length > 0
                                      ? errors.data[i]
                                      : false
                                    
                                  const isChecked = data[i].checked
                                  const errorMessage =
                                    item.errorMessage ||
                                    Object.keys(error || {}).reduce(
                                      (acc, curr) => {
                                        
                                        
                                        return `${acc}\n${error[curr]}`
                                      } ,
                                      ''
                                    )
                                    
                        
                                  return (
                                    <React.Fragment>
                                      <tr key={`${i}`}>
                                        {!errorMessage ? (
                                          <td>
                                            {isChecked ? (
                                              <Button
                                                icon='pi pi-check'
                                                className='p-button-success'
                                              />
                                            ) : (
                                              <Button
                                                icon='pi pi-info'
                                                tooltip={i18n.t(
                                                  'staff.csvUpload.notChecked'
                                                )}
                                              />
                                            )}
                                          </td>
                                        ) : (
                                          <td>
                                            <div>
                                              <Button
                                                onClick={() => remove(i)}
                                                icon='pi pi-times'
                                                className='p-button-danger'
                                              />
                                            </div>
                                          </td>
                                        )}
                                        {keys.map(
                                          key =>
                                            !exceptKeys.includes(key) && (
                                              <td>{item[key]}</td>
                                            )
                                        )}
                                      </tr>
                                      {errorMessage && (
                                        <tr key={`errorMessage_${i}`}>
                                          <td colSpan={keys.length + 1}>
                                            <div className='alert is-left'>
                                              {errorMessage}
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                    </React.Fragment>
                                  )
                                })}
                              </React.Fragment>
                            )}
                          />
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            ) : null}
          </React.Fragment>
        )
      }}
    />
  )
}

export default WorkScheduleCsvScene
