import gql from 'graphql-tag'

export const ACTIVITY_LOG_FRAGMENT = gql`
  fragment ACTIVITY_LOG_FRAGMENT on ActivityLog {
    id
    createdAt
    doctor {
      id
      lastname
      firstname
    }
    activity
    details
  }
`

export const ACTIVITY_LOGS_CONNECTION = gql`
  query ACTIVITY_LOGS_CONNECTION(
    $where: ActivityLogWhereInput
    $skip: Int
    $first: Int
    $orderBy: ActivityLogOrderByInput
  ) {
    activityLogsConnection(
      where: $where
      skip: $skip
      first: $first
      orderBy: $orderBy
    ) {
      edges {
        node {
          ...ACTIVITY_LOG_FRAGMENT
        }
      }
      aggregate(where: $where) {
        count
      }
    }
  }
  ${ACTIVITY_LOG_FRAGMENT}
`

export const DOCTORS_CONNECTION = gql`
  query DOCTORS_CONNECTION(
    $where: DoctorWhereInput
    $first: Int
    $skip: Int
    $orderBy: DoctorOrderByInput
  ) {
    doctorsConnection(
      where: $where
      first: $first
      skip: $skip
      orderBy: $orderBy
    ) {
      edges {
        node {
          id
          email
          fullName
          fullNameKana
          blocked
        }
      }
      aggregate(where: $where) {
        count
      }
    }
  }
`

export const DOCTOR_QUERY = gql`
  query Query($where: DoctorWhereUniqueInput!) {
    doctor(where: $where) {
      id
      firstname
      lastname
      email
      blocked
    }
  }
`
