import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { PERMANENT_DOCTOR, REGULAR_SHIFT_DOCTOR } from '@medical/constant/index'
import { STAFF_CREATE_DEFAULT_HOULRY_WAGE } from '@medical/constant/permissions'
import { checkStaffPermission, onPageChange } from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import queryString from 'query-string'
import React, { useEffect } from 'react'

import {
  GET_DOCTOR_DEFAULT_HOURLY_WAGE,
  GET_HOURLY_WAGE,
  UPDATE_HOURLY_WAGE,
} from './HourlyPayment.graphql'
import HourlyPaymentScene from './HourlyPaymentScene'

const HourlyPayment = ({ history, location: { search } }) => {
  const [{ i18n, popup }] = useCustom()
  const {
    doctorEmail,
    doctorNameFilter = '',
    page = 1,
    rowsPerPage = 10,
    // workPatternFilter = '',
  } = queryString.parse(search)
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  const [updateHourlyWage] = useMutation(UPDATE_HOURLY_WAGE)
  const {
    loading: doctorHourlyWageLoading,
    error: doctorHourlyWageError,
    data: doctorHourlyWageData,
    refetch: doctorHourlyDataRefetch,
  } = useQuery(GET_DOCTOR_DEFAULT_HOURLY_WAGE, {
    variables: {
      first: first < 0 ? 10 : first,
      skip: skip < 0 ? 0 : skip,
      orderBy: 'startDate_ASC',
      where: {
        deletedAt: null,
        Doctor: {
          unSigned_contains: doctorNameFilter
            ? doctorNameFilter
                .replace(/ /g, '')
                .replace(/　/g, '')
                .toLowerCase()
            : '',
          email_contains: doctorEmail?.trim(),
          deletedAt: null,
        },
        ClinicalDepartment: {
          Clinic: {
            deletedAt: null,
          },
        },
        // {
        //   doctor: {
        //     workPattern_in: [workPatternFilter],
        //   },
        // },
      },
    },
  })
  const { loading, error, data, refetch } = useQuery(GET_HOURLY_WAGE, {
    variables: {
      where: {
        workPattern_in: [REGULAR_SHIFT_DOCTOR, PERMANENT_DOCTOR],
      },
    },
  })

  useEffect(() => {
    doctorHourlyDataRefetch()
    // refetch()
  })

  if (loading || doctorHourlyWageLoading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  if (doctorHourlyWageError)
    return <ErrorComponent error={doctorHourlyWageError} />
  const confirmationMessage = i18n.t('staff.hourlyPayment.submitConfirmation')
  const onSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    const defaultHourlyWages = [
      {
        id: values.idFullTime,
        hourlyWage: values.fullTimeDoctor,
      },
      {
        id: values.idPartTime,
        hourlyWage: values.partTimeDoctor,
      },
    ]
    try {
      await updateHourlyWage({
        variables: {
          defaultHourlyWages,
        },
      })
      popup.success(i18n.t('staff.hourlyPayment.submissionSuccess'))
      setSubmitting(false)
      resetForm()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
      setFieldValue('confirm', false)
    }
  }
  const isCreateDefaultHourlyWage = checkStaffPermission({
    functionName: STAFF_CREATE_DEFAULT_HOULRY_WAGE,
  })
  const {
    defaultHourlyWagesConnection: {
      edges,
      aggregate: { count },
    },
  } = doctorHourlyWageData
  // const { edges } = doctorHourlyWageData.defaultHourlyWagesConnection
  return (
    <HourlyPaymentScene
      i18n={i18n}
      count={count}
      hourlyPayment={data.defaultHourlyWages}
      hourlyWageData={doctorHourlyWageData}
      confirmationMessage={confirmationMessage}
      edges={edges}
      onSubmit={onSubmit}
      buttonLabel={i18n.t('staff.doctorDetail.submit')}
      doctorEmail={doctorEmail}
      doctorNameFilter={doctorNameFilter}
      page={parseInt(page, 10)}
      rowsPerPage={parseInt(rowsPerPage, 10)}
      onPageChange={onPageChange(history, search)}
      isCreateDefaultHourlyWage={isCreateDefaultHourlyWage}
      // workPatternFilter={workPatternFilter}
    />
  )
}

export default HourlyPayment
