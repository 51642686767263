import { STAFF_FRAGMENTS } from '@medical/layout/StaffLayout/StaffLayout.graphql'
import gql from 'graphql-tag'

export const STAFF_CREATE_STAFF = gql`
  mutation STAFF_CREATE_STAFF(
    $lastname: String!
    $lastnameKana: String!
    $firstname: String!
    $firstnameKana: String!
    $email: String!
    $position: String
    $clinicId: ID
    $staffGroupId: ID!
  ) {
    staffCreateStaff(
      lastname: $lastname
      lastnameKana: $lastnameKana
      firstname: $firstname
      firstnameKana: $firstnameKana
      email: $email
      position: $position
      clinicId: $clinicId
      staffGroupId: $staffGroupId
    ) {
      ...STAFF_FRAGMENTS
    }
  }
  ${STAFF_FRAGMENTS}
`
