import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import {
  clinicalDepartmentNames,
  combineNames,
  sortAndFilterByClinicOrder,
  splitLogic,
} from '@medical/libs'
import { parseJwt } from '@medical/constant/utilities'
import moment from 'moment'
import { useCustom } from '@medical/provider/context'
import React, { useEffect } from 'react'

import {
  CREATE_WORK_OUTSIDE,
  DEPARTMENTS_LIST,
} from './WorkOutsideClinic.graphql'
import { WORK_OUTSIDE_CONNECTION } from '../WorkOutsideClinic.graphql'
import WorkOutsideClinicCreateScene from './WorkOutsideClinicCreateScene'

const WorkOutsideClinicCreate = ({ closeModal }) => {
  const [{ i18n, popup }] = useCustom()
  const { doctorId } = parseJwt(localStorage.getItem('medical_doctor_token'))
  const variables = {
    where: {
      Doctor: {
        id: doctorId,
      },
    },
    orderBy: 'createdAt_DESC',
  }
  const mutationOptions = {
    refetchQueries: [
      {
        query: WORK_OUTSIDE_CONNECTION,
        variables,
      },
    ],
    awaitRefetchQueries: true,
  }
  const [doctorCreateWorkOutside] = useMutation(
    CREATE_WORK_OUTSIDE,
    mutationOptions
  )
  const { loading, error, data } = useQuery(DEPARTMENTS_LIST)

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const { clinicalDepartments, departments } = data
  const sortedClinicalDepartments = sortAndFilterByClinicOrder({
    clinicalDepartments,
    isFilter: true,
  })
  const departmentNames = clinicalDepartmentNames({ departments })

  const clinicalDepartmentsCombineName = combineNames({
    clinicalDepartments: sortedClinicalDepartments,
    departmentNames,
  })

  const clinicalWorkOutside = clinicalDepartmentsCombineName
    .filter(it => it.clinic.isOutside)
    .map(it => ({
      id: it.id,
      name: it.clinicName,
    }))
  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const {
        endTime,
        splitDateTime1,
        splitDateTime2,
        splitDateTime3,
        isStartTimeBreakTime,
        isSplitDateTime1BreakTime,
        isSplitDateTime2BreakTime,
        isSplitDateTime3BreakTime,
      } = splitLogic({ submitDate: values.date, splits: values.splits })
      await doctorCreateWorkOutside({
        variables: {
          clinicalDepartmentId: values.clinicDepartment.id,
          startTime: moment(values.date)
            .hour(moment(values.startTime).hour())
            .minute(moment(values.startTime).minute()),
          endTime,
          splitDateTime1,
          splitDateTime2,
          splitDateTime3,
          isSplitDateTime3BreakTime,
          isSplitDateTime2BreakTime,
          isSplitDateTime1BreakTime,
          isStartTimeBreakTime,
          address: values.address,
          workContent: values.workContent,
        },
      })
      popup.success('申請しました')
      closeModal()
      resetForm()
      setSubmitting(false)
    } catch (err) {
      popup.error(err)
      setSubmitting(false)
    }
  }
  return (
    <WorkOutsideClinicCreateScene
      i18n={i18n}
      clinicalDepartments={clinicalWorkOutside}
      onSubmit={onSubmit}
    />
  )
}

export default WorkOutsideClinicCreate
