import { STAFF_GROUPS_QUERY } from '@medical/pages/staff/StaffGroups/StaffGroupsList/StaffGroupsList.graphql'
import gql from 'graphql-tag'

export const STAFF_FRAGMENTS = gql`
  fragment STAFF_FRAGMENTS on Staff {
    id
    firstname
    lastname
    firstnameKana
    lastnameKana
    email
    position
    isGoldWare
    staffGroup {
      id
      name
      permissions {
        id
        action
        deletedAt
        api {
          id
          type
          name
        }
      }
    }
    clinic {
      id
      name
      order
    }
  }
`

export const STAFF_ME = gql`
  query STAFF_ME {
    staffMe {
      ...STAFF_FRAGMENTS
    }
    ...STAFF_GROUPS_QUERY
  }
  ${STAFF_FRAGMENTS}
  ${STAFF_GROUPS_QUERY}
`

export const STAFF_SUBSCRIPTION = gql`
  subscription {
    staffLoggedIn {
      id
      email
    }
  }
`

export const GET_NEW_SCHEDULE_CANCEL = gql`
  query WorkScheduleCancellationLetters(
    $where: WorkScheduleCancellationLetterWhereInput
  ) {
    workScheduleCancellationLetters(where: $where) {
      id
      status
    }
  }
`

export const WORK_OUTSIDES = gql`
  query WORK_OUTSIDES($where: WorkOutsideWhereInput) {
    workOutsides(where: $where) {
      id
      status
    }
  }
`

export const STAFF_MSG_SUBSCRIPTION = gql`
  subscription STAFF_MSG_SUBSCRIPTION {
    staffConversationsSubscription {
      id
      isReadByStaff
    }
  }
`

export const CONVERSATIONS = gql`
  query Conversations($where: ConversationWhereInput) {
    conversations(where: $where) {
      id
      isReadByStaff
    }
  }
`
export const WORK_OUTSIDE_SUBSCRIPTION = gql`
  subscription WORK_OUTSIDE_SUBSCRIPTION {
    workOutsideSubscription {
      id
      status
    }
  }
`

export const WORK_SCHEDULE_CANCEL_SUBSCRIPTION = gql`
  subscription WORK_SCHEDULE_CANCEL_SUBSCRIPTION {
    workScheduleCancelLetterSubscription {
      id
      status
    }
  }
`
export const WAITING_RECRUITMENT_SHIFTS = gql`
  query WaitingRecruitmentShifts($where: WaitingRecruitmentShiftWhereInput) {
    waitingRecruitmentShifts(where: $where) {
      id
      startTime
      startDate
      endTime
      endDate
      confirmationStatus
      active
    }
  }
`

export const WAITING_RECRUITMENT_SHIFTS_SUBSCRIPTION = gql`
  subscription WAITING_RECRUITMENT_SHIFTS_SUBSCRIPTION {
    waitingRecruitmentShiftSubscription {
      id
      startTime
      startDate
      endTime
      endDate
    }
  }
`

export const RECRUITMENT_MANAGEMENT = gql`
  query Query($where: RecruitmentDoctorWhereInput) {
    recruitmentDoctors(where: $where) {
      id
      status
    }
  }
`

export const RECRUITMENT_MANAGEMENT_SUBSCRIPTION = gql`
  subscription RECRUITMENT_MANAGEMENT_SUBSCRIPTION {
    recruitmentManagementSubscription {
      id
      status
    }
  }
`
export const WS_OVERTIME = gql`
  query WorkScheduleOvertimes($where: WorkScheduleOvertimeWhereInput) {
    workScheduleOvertimes(where: $where) {
      id
      status
    }
  }
`
export const WORK_OVERTIME_SUBSCRIPTION = gql`
  subscription WORK_OVERTIME_SUBSCRIPTION {
    workOverTimeSubscription {
      id
      status
    }
  }
`

export const DOCTOR_STATUS = gql`
  query Doctors($where: DoctorWhereInput) {
    doctors(where: $where) {
      id
      registrationStatus
    }
  }
`
export const DOCTOR_STATUS_SUBSCRIPTION = gql`
  subscription DOCTOR_STATUS_SUBSCRIPTION {
    doctorStatusSubscription {
      id
      registrationStatus
    }
  }
`
