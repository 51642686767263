import gql from 'graphql-tag'

export const CREATE_CATEGORY = gql`
  mutation StaffCreateQuestionCategory($content: String!) {
    staffCreateQuestionCategory(content: $content)
  }
`
export const UPDATE_CATEGORY = gql`
  mutation staffUpdateQuestionCategory(
    $categoryId: ID
    $content: String
    $deletedAt: String
  ) {
    staffUpdateQuestionCategory(
      categoryId: $categoryId
      content: $content
      deletedAt: $deletedAt
    )
  }
`

export const QUESTION_CONNECTION = gql`
  query QUESTION_CONNECTION(
    $where: QuestionWhereInput
    $skip: Int
    $first: Int
    $orderBy: QuestionOrderByInput
  ) {
    questionsConnection(
      where: $where
      skip: $skip
      first: $first
      orderBy: $orderBy
    ) {
      edges {
        node {
          id
          category {
            id
          }
        }
      }
      aggregate(where: $where) {
        count
      }
    }
  }
`