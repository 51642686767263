import './styles.css'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, NotFound, ProgressSpinner } from '@medical/components'
import { WAITING_FOR_APPROVAL } from '@medical/constant'
import { DOCTOR_ME } from '@medical/layout/DoctorLayout/DoctorLayoutRequest.graphql'
import { useCustom } from '@medical/provider/context'
import moment from 'moment'
import React, { useEffect } from 'react'

import {
  DOCTOR_CREATE_TRANSPORT_PEROPDIC,
  DOCTOR_DOWNLOAD_IMG,
  DOCTOR_GET_TRANSPORT_PEROPDIC,
  TRANPORTATION_TYPE_SETTINGS,
} from './TransportPeriodic.graphql'
import TransportPeriodicScene from './TransportPeriodicScene'

const TransportPeriodic = () => {
  const { data: dataDoctor } = useQuery(DOCTOR_ME)

  const { loading, error, data, refetch } = useQuery(
    DOCTOR_GET_TRANSPORT_PEROPDIC,
    {
      variables: {
        where: {
          deletedAt: null,
          Doctor: {
            id: dataDoctor.doctorMe.id,
          },
          type_not: null,
          Clinic_TransportationExpense_clinicToClinic: {
            deletedAt: null,
          },
        },
      },
    }
  )
  const {
    error: errorTypeSetting,
    loading: loadingTypeSetting,
    data: dataTypeSetting,
    refetch: refetchTypeSetting,
  } = useQuery(TRANPORTATION_TYPE_SETTINGS, {
    variables: {
      where: {
        isApply: true,
        isExpiredSetting: false,
      },
    },
  })
  const [{ i18n, popup }] = useCustom()
  const [downloadFile] = useMutation(DOCTOR_DOWNLOAD_IMG)
  const [createPeriodic] = useMutation(DOCTOR_CREATE_TRANSPORT_PEROPDIC, {
    refetchQueries: DOCTOR_GET_TRANSPORT_PEROPDIC,
    variables: {
      where: {
        deletedAt: null,
        Doctor: {
          id: dataDoctor.doctorMe.id,
        },
        type_not: null,
      },
    },
    awaitRefetchQueries: true,
  })
  useEffect(() => {
    refetch()
    refetchTypeSetting()
  })
  if (loading || loadingTypeSetting) return <ProgressSpinner />
  if (error || errorTypeSetting) return <ErrorComponent error={error} />
  const onSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    const transportations = []
    const fileImages = []
    const clinics = []
    values.splits.map((item, index) => {
      clinics.push(item.clinic.id)
      if (item.file !== '') {
        fileImages.push(item.file)
        transportations.push({
          url: item.routeUrl,
          startTime: moment.utc(item.startTime).toISOString(),
          endTime: moment.utc(item.endTime).toISOString(),
          type: item.category.value,
          status: WAITING_FOR_APPROVAL,
          departurePlace: item.travelSectionStart,
          arrivalPlace: item.travelSectionEnd,
          cost: item.category.value === 'CAR' ? 0 : item.cost,
          billImage: `${index}`,
          category: 'RECURRING_TRAVEL_FEE',
          comment: item.comment,
        })
        return [clinics, fileImages, transportations]
      }
      transportations.push({
        url: item.routeUrl,
        startTime: moment.utc(item.startTime).toISOString(),
        endTime: moment.utc(item.endTime).toISOString(),
        type: item.category.value,
        status: WAITING_FOR_APPROVAL,
        departurePlace: item.travelSectionStart,
        arrivalPlace: item.travelSectionEnd,
        cost: item.category.value === 'CAR' ? 0 : item.cost,
        category: 'RECURRING_TRAVEL_FEE',
        comment: item.comment,
      })
      return [clinics, transportations]
    })
    try {
      await createPeriodic({
        variables: {
          clinics,
          fileImages,
          transportations,
        },
      })

      resetForm()
      popup.success(i18n.t('staff.hourlyPayment.submissionSuccess'))
      setSubmitting(false)
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
      setFieldValue('confirm', false)
    }
  }
  const downloadFileSubmit = async ({ key }, { setSubmitting, resetForm }) => {
    try {
      const {
        data: { downloadFile: fileURL },
      } = await downloadFile({
        variables: {
          key,
        },
      })
      window.open(fileURL, '_blank')
      setSubmitting(false)
      resetForm()
    } catch (error) {
      popup.error(i18n.t('main.downloadFileError'))
      setSubmitting(false)
    }
  }
  const confirmationMessage = i18n.t(
    'transportApplication.submitConfirmationPeriodic'
  )
  const getClinics = () => {
    const clinics = []
    const clinicId = []
    if (
      data &&
      data.clinicalDepartments &&
      data.clinicalDepartments.length > 0
    ) {
      data.clinicalDepartments.map(as => {
        if (!clinicId.includes(as.clinic.id)) {
          clinics.push(as.clinic)
          clinicId.push(as.clinic.id)
        }
      })
      return clinics
    }
    return []
  }
  const clinic = getClinics()
  return (
    <TransportPeriodicScene
      i18n={i18n}
      clinic={clinic}
      periodicData={data.transportationExpenses}
      typeSettingData={dataTypeSetting.transportationTypeSettings}
      downloadFileSubmit={downloadFileSubmit}
      confirmationMessage={confirmationMessage}
      onSubmit={onSubmit}
      popup={popup}
    />
  )
}
export default TransportPeriodic
