import { Checkbox, TimePicker, Input } from 'antd'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import moment from 'moment'
import React, { useState } from 'react'
import Router from '@medical/routes/router'
import { Link } from 'react-router-dom'
import { ErrorMessage, Formik } from 'formik'
import * as Yup from 'yup'

const ClinicsManageUpdate = ({
  i18n,
  listState,
  setCurrentState,
  onUpdateClinic,
  clinics,
}) => {
  const options = [
    {
      value: true,
      label: '掲載',
    },
    {
      value: false,
      label: '未掲載',
    },
  ]
  const optionClinics = clinics?.map(item => {
    return { value: item.id, label: item.name }
  })
  const findDepartment = (clinicId, departmentName, field) => {
    const clinicalDepartment = clinics.find(item => item.id === clinicId)
      .clinicalDepartments
    return clinicalDepartment.find(item => item.name === departmentName)?.[
      field
    ] === undefined
      ? ''
      : clinicalDepartment.find(item => item.name === departmentName)?.[field]
  }
  const validationSchema = Yup.object().shape({
    clinicId: Yup.string().required(`${i18n.t('validation.required')}`),
    isDisplayInternal: Yup.boolean().when(
      ['isUpdateInternal', 'isCreateInternal'],
      {
        is: (isUpdateInternal, isCreateInternal) =>
          isUpdateInternal && isCreateInternal,
        then: Yup.boolean().required(`${i18n.t('validation.required')}`),
      }
    ),
    internalCode: Yup.string().when('isUpdateInternal', {
      is: true,
      then: Yup.string()
        .matches(/^[0-9]+$/, i18n.t('validation.medicalLicenseNumber.type'))
        .required(`${i18n.t('validation.required')}`),
    }),
    isDisplayChildren: Yup.boolean().when(
      ['isUpdateChildren', 'isCreateChildren'],
      {
        is: (isUpdateChildren, isCreateChildren) =>
          isUpdateChildren && isCreateChildren,
        then: Yup.boolean().required(`${i18n.t('validation.required')}`),
      }
    ),
    childrenCode: Yup.string().when('isUpdateChildren', {
      is: true,
      then: Yup.string()
        .matches(/^[0-9]+$/, i18n.t('validation.medicalLicenseNumber.type'))
        .required(`${i18n.t('validation.required')}`),
    }),
    isDisplayInternalVaccine: Yup.boolean().when(
      ['isUpdateInternalVaccine', 'isCreateInternalVaccine'],
      {
        is: (isUpdateInternalVaccine, isCreateInternalVaccine) =>
          isUpdateInternalVaccine && isCreateInternalVaccine,
        then: Yup.boolean().required(`${i18n.t('validation.required')}`),
      }
    ),
    internalVaccineCode: Yup.string().when('isUpdateInternalVaccine', {
      is: true,
      then: Yup.string()
        .matches(/^[0-9]+$/, i18n.t('validation.medicalLicenseNumber.type'))
        .required(`${i18n.t('validation.required')}`),
    }),
    isDisplayChildrenVaccine: Yup.boolean().when(
      ['isUpdateChildrenVaccine', 'isCreateChildrenVaccine'],
      {
        is: (isUpdateChildrenVaccine, isCreateChildrenVaccine) =>
          isUpdateChildrenVaccine && isCreateChildrenVaccine,
        then: Yup.boolean().required(`${i18n.t('validation.required')}`),
      }
    ),
    childrenVaccineCode: Yup.string().when('isUpdateChildrenVaccine', {
      is: true,
      then: Yup.string()
        .matches(/^[0-9]+$/, i18n.t('validation.medicalLicenseNumber.type'))
        .required(`${i18n.t('validation.required')}`),
    }),
  })
  return (
    <React.Fragment>
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('staff.menuBar.clinics')}
          <div className='breadcrumb'>
            <Link to={Router.home}>{i18n.t('main.home')}</Link>
            <Link to={Router.staffClinics}>
              <i className='pi pi-chevron-right' />
              {i18n.t('staff.menuBar.clinics')}
            </Link>
            <a onClick={() => setCurrentState(listState.state0)}>
              <i className='pi pi-chevron-right' />
              マネジメント
            </a>
            <i className='pi pi-chevron-right' />
            診療科編集
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='box'>
          <div>
            <Formik
              enableReinitialize
              initialValues={{
                clinicId: '',
                internalCode: '',
                isDisplayInternal: '',
                isUpdateInternal: '',
                childrenCode: '',
                isDisplayChildren: '',
                isUpdateChildren: '',
                internalVaccineCode: '',
                isDisplayInternalVaccine: '',
                isUpdateInternalVaccine: '',
                childrenVaccineCode: '',
                isDisplayChildrenVaccine: '',
                isUpdateChildrenVaccine: '',
              }}
              validationSchema={validationSchema}
              onSubmit={onUpdateClinic}
              render={formikProps => {
                const { setFieldValue, handleSubmit, values } = formikProps
                const props = { ...formikProps }
                return (
                  <div>
                    <div className='tr'>
                      <div className='th' style={{ width: '200px' }}>
                        クリニック名
                      </div>
                      <div className='td'>
                        <Dropdown
                          value={values.clinicId}
                          options={optionClinics}
                          onChange={e => {
                            setFieldValue('clinicId', e.value)
                            setFieldValue(
                              'internalCode',
                              findDepartment(
                                e.value,
                                'INTERNAL_MEDICINE',
                                'departmentCode'
                              )
                            )
                            setFieldValue(
                              'isDisplayInternal',
                              findDepartment(
                                e.value,
                                'INTERNAL_MEDICINE',
                                'isDisplay'
                              )
                            )
                            setFieldValue(
                              'isUpdateInternal',
                              findDepartment(e.value, 'INTERNAL_MEDICINE', 'id')
                                ? true
                                : false
                            )
                            setFieldValue(
                              'childrenCode',
                              findDepartment(
                                e.value,
                                'CHILDREN_MEDICINE',
                                'departmentCode'
                              )
                            )
                            setFieldValue(
                              'isDisplayChildren',
                              findDepartment(
                                e.value,
                                'CHILDREN_MEDICINE',
                                'isDisplay'
                              )
                            )
                            setFieldValue(
                              'isUpdateChildren',
                              findDepartment(e.value, 'CHILDREN_MEDICINE', 'id')
                                ? true
                                : false
                            )
                            setFieldValue(
                              'internalVaccineCode',
                              findDepartment(
                                e.value,
                                'INTERNAL_VACCINE_MEDICINE',
                                'departmentCode'
                              )
                            )
                            setFieldValue(
                              'isDisplayInternalVaccine',
                              findDepartment(
                                e.value,
                                'INTERNAL_VACCINE_MEDICINE',
                                'isDisplay'
                              )
                            )
                            setFieldValue(
                              'isUpdateInternalVaccine',
                              findDepartment(
                                e.value,
                                'INTERNAL_VACCINE_MEDICINE',
                                'id'
                              )
                                ? true
                                : false
                            )
                            setFieldValue(
                              'childrenVaccineCode',
                              findDepartment(
                                e.value,
                                'CHILDREN_VACCINE_MEDICINE',
                                'departmentCode'
                              )
                            )
                            setFieldValue(
                              'isDisplayChildrenVaccine',
                              findDepartment(
                                e.value,
                                'CHILDREN_VACCINE_MEDICINE',
                                'isDisplay'
                              )
                            )
                            setFieldValue(
                              'isUpdateChildrenVaccine',
                              findDepartment(
                                e.value,
                                'CHILDREN_VACCINE_MEDICINE',
                                'id'
                              )
                                ? true
                                : false
                            )
                          }}
                        />
                        <ErrorMessage
                          name='clinicId'
                          component='div'
                          className='signup-alert'
                          style={{ width: '200px', marginRight: '20px' }}
                        />
                      </div>
                    </div>
                    <div
                      style={{ fontWeight: 'bold', padding: '15px 0 0 15px' }}
                    >
                      診療科
                      <br />
                      作成・編集
                    </div>
                    <div className='tr'>
                      <div className='th' style={{ width: '200px' }}>
                        <Checkbox
                          style={{ margin: '0 15px 0 30px' }}
                          checked={values.isUpdateInternal}
                          disabled={
                            !!values.internalCode && !values.isCreateInternal
                          }
                          onChange={e => {
                            setFieldValue('isUpdateInternal', e.target.checked)
                          }}
                        />
                        内科
                      </div>
                      <div className='td'>
                        <div className='row'>
                          <Input
                            style={{ width: '200px', marginRight: '20px' }}
                            value={values.internalCode}
                            disabled={
                              !!values.internalCode && !values.isCreateInternal
                            }
                            onChange={e => {
                              setFieldValue('internalCode', e.target.value)
                              setFieldValue('isCreateInternal', true)
                            }}
                          />
                          <Dropdown
                            value={values.isDisplayInternal}
                            options={options}
                            onChange={e => {
                              setFieldValue('isDisplayInternal', e.target.value)
                            }}
                          />
                        </div>
                        <div className='row'>
                          <ErrorMessage
                            name='internalCode'
                            component='div'
                            className='signup-alert'
                            style={{ width: '200px', marginRight: '20px' }}
                          />
                          <ErrorMessage
                            name='isDisplayInternal'
                            component='div'
                            className='signup-alert'
                          />
                        </div>
                      </div>
                    </div>
                    <div className='tr'>
                      <div className='th' style={{ width: '200px' }}>
                        <Checkbox
                          style={{ margin: '0 15px 0 30px' }}
                          checked={values.isUpdateChildren}
                          disabled={
                            !!values.childrenCode && !values.isCreateChildren
                          }
                          onChange={e => {
                            setFieldValue('isUpdateChildren', e.target.checked)
                          }}
                        />
                        小児科
                      </div>
                      <div className='td'>
                        <div className='row'>
                          <Input
                            style={{ width: '200px', marginRight: '20px' }}
                            value={values.childrenCode}
                            disabled={
                              !!values.childrenCode && !values.isCreateChildren
                            }
                            onChange={e => {
                              setFieldValue('childrenCode', e.target.value)
                              setFieldValue('isCreateChildren', true)
                            }}
                          />
                          <Dropdown
                            value={values.isDisplayChildren}
                            options={options}
                            onChange={e => {
                              setFieldValue('isDisplayChildren', e.target.value)
                            }}
                          />
                        </div>
                        <div className='row'>
                          <ErrorMessage
                            name='childrenCode'
                            component='div'
                            className='signup-alert'
                            style={{ width: '200px', marginRight: '20px' }}
                          />
                          <ErrorMessage
                            name='isDisplayChildren'
                            component='div'
                            className='signup-alert'
                          />
                        </div>
                      </div>
                    </div>

                    <div className='tr'>
                      <div className='th' style={{ width: '200px' }}>
                        <Checkbox
                          style={{ margin: '0 15px 0 30px' }}
                          checked={values.isUpdateChildrenVaccine}
                          disabled={
                            !!values.childrenVaccineCode &&
                            !values.isCreateChildrenVaccine
                          }
                          onChange={e => {
                            setFieldValue(
                              'isUpdateChildrenVaccine',
                              e.target.checked
                            )
                          }}
                        />
                        小児科ワクチン
                      </div>
                      <div className='td'>
                        <div className='row'>
                          <Input
                            style={{ width: '200px', marginRight: '20px' }}
                            value={values.childrenVaccineCode}
                            disabled={
                              !!values.childrenVaccineCode &&
                              !values.isCreateChildrenVaccine
                            }
                            onChange={e => {
                              setFieldValue(
                                'childrenVaccineCode',
                                e.target.value
                              )
                              setFieldValue('isCreateChildrenVaccine', true)
                            }}
                          />
                          <Dropdown
                            value={values.isDisplayChildrenVaccine}
                            options={options}
                            onChange={e => {
                              setFieldValue(
                                'isDisplayChildrenVaccine',
                                e.target.value
                              )
                            }}
                          />
                        </div>
                        <div className='row'>
                          <ErrorMessage
                            name='childrenVaccineCode'
                            component='div'
                            className='signup-alert'
                            style={{ width: '200px', marginRight: '20px' }}
                          />
                          <ErrorMessage
                            name='isDisplayChildrenVaccine'
                            component='div'
                            className='signup-alert'
                          />
                        </div>
                      </div>
                    </div>
                    <div className='tr'>
                      <div className='th' style={{ width: '200px' }}>
                        <Checkbox
                          style={{ margin: '0 15px 0 30px' }}
                          checked={values.isUpdateInternalVaccine}
                          disabled={
                            !!values.internalVaccineCode &&
                            !values.isCreateInternalVaccine
                          }
                          onChange={e => {
                            setFieldValue(
                              'isUpdateInternalVaccine',
                              e.target.checked
                            )
                          }}
                        />
                        内科ワクチン
                      </div>
                      <div className='td'>
                        <div className='row'>
                          <Input
                            style={{ width: '200px', marginRight: '20px' }}
                            value={values.internalVaccineCode}
                            disabled={
                              !!values.internalVaccineCode &&
                              !values.isCreateInternalVaccine
                            }
                            onChange={e => {
                              setFieldValue(
                                'internalVaccineCode',
                                e.target.value
                              )
                              setFieldValue('isCreateInternalVaccine', true)
                            }}
                          />
                          <Dropdown
                            value={values.isDisplayInternalVaccine}
                            options={options}
                            onChange={e => {
                              setFieldValue(
                                'isDisplayInternalVaccine',
                                e.target.value
                              )
                            }}
                          />
                        </div>
                        <div className='row'>
                          <ErrorMessage
                            name='internalVaccineCode'
                            component='div'
                            className='signup-alert'
                            style={{ width: '200px', marginRight: '20px' }}
                          />
                          <ErrorMessage
                            name='isDisplayInternalVaccine'
                            component='div'
                            className='signup-alert'
                          />
                        </div>
                      </div>
                    </div>

                    <div className='modal-bottom'>
                      <Button
                        type='submit'
                        className='reflect-button'
                        onClick={handleSubmit}
                        label={i18n.t('staff.editClinic.reflect')}
                      />
                    </div>
                  </div>
                )
              }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ClinicsManageUpdate
