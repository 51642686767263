import './style.css'

import { ProgressSpinner } from '@medical/components'
import CustomDatePicker from '@medical/components/DatePicker'
import CustomDropdown from '@medical/components/DropdownForSignup'
import {
  KATAKANA_REG_EXP,
  MAX_NAME_LENGTH,
  NAME_REG_EXP,
} from '@medical/constant'
import { ErrorMessage, Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import React, { forwardRef, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

const SignupComponentStep1 = forwardRef(
  ({ handleNextStep, stateData, staffCheckDoctorLoading }, ref) => {
    const { i18n } = useTranslation()
    const genders = [
      { value: 'MALE', description: `${i18n.t('doctor.signup.male')}` },
      { value: 'FEMALE', description: `${i18n.t('doctor.signup.female')}` },
    ]
    const nameRegex = Yup.string()
      .max(MAX_NAME_LENGTH, i18n.t('validation.max', { max: MAX_NAME_LENGTH }))
      .matches(NAME_REG_EXP, i18n.t('validation.noSpecialCharacter'))

    const katakanaNameRegex = nameRegex.matches(
      KATAKANA_REG_EXP,
      i18n.t('validation.katakanaOnly')
    )
    const minDate = moment().subtract(100, 'year')
    const maxDate = moment().subtract(20, 'year')
    const yearRange = `${minDate.format('YYYY')}:${maxDate.format('YYYY')}`
    // const recaptcha = useRef()
    return (
      <Formik
        enableReinitialize
        initialValues={{
          email: stateData.email,
          firstname: stateData.firstname,
          lastname: stateData.lastname,
          firstnameKana: stateData.firstnameKana,
          lastnameKana: stateData.lastnameKana,
          birthday: stateData.birthday,
          gender: stateData.gender,
          medicalLicenseNumber: stateData.medicalLicenseNumber,
          isDisableButton: true,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(i18n.t('validation.email.email'))
            .required(i18n.t('validation.email.required')),
          firstname: nameRegex.required(
            `${i18n.t('updateProfile.firstname')}${i18n.t(
              'validation.required'
            )}`
          ),
          lastname: nameRegex.required(
            `${i18n.t('updateProfile.lastname')}${i18n.t(
              'validation.required'
            )}`
          ),
          firstnameKana: katakanaNameRegex.required(
            `${i18n.t('updateProfile.firstnameKana')}${i18n.t(
              'validation.required'
            )}`
          ),
          lastnameKana: katakanaNameRegex.required(
            `${i18n.t('updateProfile.lastnameKana')}${i18n.t(
              'validation.required'
            )}`
          ),
          gender: Yup.string().nullable(),
          medicalLicenseNumber: Yup.string()
            .matches(/^[0-9]+$/, i18n.t('validation.medicalLicenseNumber.type'))
            .min(6, i18n.t('validation.medicalLicenseNumber.length'))
            .max(6, i18n.t('validation.medicalLicenseNumber.length'))
            .nullable()
            .required(
              `${i18n.t('doctor.signup.medicalLicenseNumberLabel')}${i18n.t(
                'validation.required'
              )}`
            ),
        })}
        onSubmit={values => {
          handleNextStep({
            ...values,
            birthday: moment(values.birthday).format(),
            gender: values.gender === '' ? null : values.gender,
          })
        }}
        render={formikProps => {
          const {
            values,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            errors,
          } = formikProps
          return (
            <div>
              <form>
                <div className='signup-inner'>
                  <div className='signup-label required-label'>
                    {i18n.t('doctor.signup.emailLabel')}
                  </div>
                  <div className='signup-input'>
                    <InputText
                      value={values.email}
                      name='email'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoCapitalize='none'
                    />
                    <ErrorMessage
                      name='email'
                      component='div'
                      className='signup-alert'
                    />
                  </div>
                  <div className='row'>
                    <div className='col-6'>
                      <div className='signup-label required-label'>
                        {i18n.t('doctor.signup.lastNameLabel')}
                      </div>
                      <div className='signup-input-half'>
                        <InputText
                          values={values}
                          value={values.lastname}
                          name='lastname'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoCapitalize='none'
                        />
                        <ErrorMessage
                          name='lastname'
                          component='div'
                          className='signup-alert'
                        />
                      </div>
                    </div>
                    <div className='col-6'>
                      <div className='signup-label required-label'>
                        {i18n.t('doctor.signup.firstNameLabel')}
                      </div>
                      <div className='signup-input-half'>
                        <InputText
                          values={values}
                          value={values.firstname}
                          name='firstname'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoCapitalize='none'
                        />
                        <ErrorMessage
                          name='firstname'
                          component='div'
                          className='signup-alert'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-6 inputKana'>
                      <div className='signup-label required-label'>
                        {i18n.t('doctor.signup.lastNameLabelKana')}
                      </div>
                      <div className='signup-input-half'>
                        <InputText
                          values={values}
                          value={values.lastnameKana}
                          name='lastnameKana'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoCapitalize='none'
                        />
                        <ErrorMessage
                          name='lastnameKana'
                          component='div'
                          className='signup-alert'
                        />
                      </div>
                    </div>
                    <div className='col-6'>
                      <div className='signup-label required-label'>
                        {i18n.t('doctor.signup.firstNameLabelKana')}
                      </div>
                      <div className='signup-input-half'>
                        <InputText
                          values={values}
                          value={values.firstnameKana}
                          name='firstnameKana'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoCapitalize='none'
                        />
                        <ErrorMessage
                          name='firstnameKana'
                          component='div'
                          className='signup-alert'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6 col-6'>
                      <div className='signup-label'>
                        {i18n.t('doctor.signup.genderLabel')}
                      </div>
                      <div className='signup-input-half'>
                        <CustomDropdown
                          {...formikProps}
                          name='gender'
                          options={genders}
                          classDisabled
                          required
                        />
                      </div>
                    </div>
                    <div className='col-md-6 col-6'>
                      <div className='signup-label required-label'>
                        {i18n.t('doctor.signup.birthdayLabel')}
                      </div>
                      <div className='signup-input-half'>
                        <CustomDatePicker
                          {...formikProps}
                          name='birthday'
                          value={values.birthday}
                          readOnlyInput
                          yearNavigator
                          yearRange={yearRange}
                          monthNavigator
                          classDisabled
                          minDate={minDate.startOf('year').toDate()}
                          maxDate={maxDate.endOf('year').toDate()}
                          viewDate={
                            formikProps.values.birthday
                              ? moment(formikProps.values.birthday).toDate()
                              : minDate.startOf('year').toDate()
                          }
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className='signup-label required-label'>
                    {i18n.t('doctor.signup.medicalLicenseNumberLabel')}
                  </div>
                  <div className='signup-input'>
                    <InputText
                      values={values}
                      value={values.medicalLicenseNumber}
                      name='medicalLicenseNumber'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoCapitalize='none'
                    />
                    <ErrorMessage
                      name='medicalLicenseNumber'
                      component='div'
                      className='signup-alert'
                    />
                  </div>
                  <div className='tr captcha-container'>
                    <div className='td captcha-item'>
                      <ReCAPTCHA
                        ref={ref}
                        hl='ja'
                        sitekey={process.env.REACT_APP_SITE_CAPTCHA_KEY}
                        // sitekey='6LeGtUwfAAAAAMwyx7g4-sebyEDsUdsLgiPR_7Qb'
                        onChange={value => {
                          if (value) {
                            setFieldValue('tokenResponse', value)
                            setFieldValue('isDisableButton', false)
                          } else {
                            setFieldValue('isDisableButton', true)
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className='submit-button'>
                  {staffCheckDoctorLoading ? (
                    <ProgressSpinner />
                  ) : (
                    <Button
                      type='button'
                      className='signup-button p-button-primary'
                      onClick={handleSubmit}
                      label={i18n.t('doctor.signup.submitLabel')}
                      disabled={values.isDisableButton}
                      style={values.isDisableButton ? { opacity: 0.5 } : null}
                    />
                  )}
                </div>
              </form>
            </div>
          )
        }}
      />
    )
  }
)

export default SignupComponentStep1
