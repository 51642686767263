import './style.css'
import { useMutation } from '@apollo/react-hooks'
import { ReactComponent as Clinic } from '@medical/assets/image/clinic.svg'
import { DOWNLOAD_FILE } from '@medical/pages/staff/Doctors/DoctorDetail/DoctorDetail.graphql'
import Router from '@medical/routes/router'
import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
const FindJobScene = ({ edges, clinicalDepartments }) => {
  const [listData, setListData] = useState([])
  const [downloadFile] = useMutation(DOWNLOAD_FILE)
  const downloadFileSubmit = async key => {
    if (!key || key.length === 0) return null
    try {
      const {
        data: { downloadFileS3: fileURL },
      } = await downloadFile({
        variables: {
          key,
        },
      })
      return fileURL
    } catch (error) {
      return null
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      const promises = []
      for (const item of edges) {
        if (item.node.imageKey) {
          promises.push(downloadFileSubmit(item.node.imageKey))
        }
        if (!item.node.imageKey) {
          promises.push(downloadFileSubmit(process.env.REACT_APP_IMAGE_DEFAULT))
        }
      }
      const results = await Promise.all(promises)
      const temp = edges.map((el, index) => ({
        ...el,
        img: results[index],
      }))
      setListData(temp)
    }
    fetchData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const getClinicDepartmentName = clinicalDepartmentId => {
    if (!clinicalDepartmentId) return ''
    return clinicalDepartments.find(({ id }) => id === clinicalDepartmentId)
      .clinicName
  }
  return (
    <>
      <p className='find-job-title'>採用情報を見る</p>
      {edges.length === 0 && <p>掲載がありません</p>}
      {listData?.map(({ node, img }) => (
        <div key={node.id}>
          <div className='is-pc'>
            <div
              style={{
                display: 'flex',
                marginTop: '27px',
                alignItems: 'center',
              }}
            >
              <div style={{ maxHeight: '90px', maxWidth: '180px' }}>
                <Link
                  to={Router.get(Router.doctorRecruitmentDetail, {
                    recruitmentId: node.id,
                  })}
                  className='recruitment-img-link '
                >
                  <img
                    className='img-find-job-hover'
                    src={img}
                    alt={node.title}
                    style={{
                      border: '1px solid #eeeeee',
                      aspectRatio: '2/1',
                      objectFit: 'contain',
                      margin: 'auto',
                      height: '100%',
                      width: '100%',
                    }}
                  />
                </Link>
              </div>

              <div
                className='find-job-item-content'
                style={{
                  marginLeft: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <div className='find-job-item-content-wrap'>
                  <span className='find-job-item-content-type-sp'>
                    {node.recruitmentDoctorType === 'PERMANENT_DOCTOR'
                      ? '常勤'
                      : '定期非常勤'}
                  </span>

                  <Link
                    to={Router.get(Router.doctorRecruitmentDetail, {
                      recruitmentId: node.id,
                    })}
                  >
                    <p className='find-job-item-content-description-sp'>
                      {node.title}
                    </p>
                  </Link>
                </div>

                <p
                  className={`find-job-item-content-title-sp ${!node.clinicalDepartment &&
                    'find-job-clinic-hidden'}`}
                >
                  <i
                    style={{ marginRight: '5px', marginBottom: '-3px' }}
                    className='bg-red mt-0'
                  >
                    <Clinic />
                  </i>
                  {getClinicDepartmentName(node.clinicalDepartment?.id)}
                </p>
              </div>
            </div>
          </div>
          <div className='is-sp'>
            <div
              style={{
                display: 'flex',
                marginTop: '27px',
                flexDirection: 'column',
              }}
            >
              <div className='is-sp-find-job'>
                <Link
                  to={Router.get(Router.doctorRecruitmentDetail, {
                    recruitmentId: node.id,
                  })}
                >
                  <img
                    src={img}
                    alt={node.title}
                    style={{
                      border: '1px solid #eeeeee',
                      aspectRatio: '2/1',
                      objectFit: 'contain',
                      margin: 'auto',
                      height: '100%',
                      width: '100%',
                    }}
                  />
                </Link>
              </div>
              <div
                className='find-job-item-content'
                style={{
                  marginLeft: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <div className='find-job-item-content-wrap'>
                  <span className='find-job-item-content-type-sp'>
                    {node.recruitmentDoctorType === 'PERMANENT_DOCTOR'
                      ? '常勤'
                      : '定期非常勤'}
                  </span>

                  <Link
                    to={Router.get(Router.doctorRecruitmentDetail, {
                      recruitmentId: node.id,
                    })}
                  >
                    <p className='find-job-item-content-description-sp'>
                      {node.title}
                    </p>
                  </Link>
                </div>
                <p
                  className={`find-job-item-content-title-sp ${!node.clinicalDepartment &&
                    'find-job-clinic-hidden'}`}
                >
                  <i
                    style={{ marginRight: '5px', marginBottom: '-3px' }}
                    className='bg-red mt-0'
                  >
                    <Clinic />
                  </i>
                  {getClinicDepartmentName(node.clinicalDepartment?.id)}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className='wrap-see-more'>
        <Link to={Router.doctorRecruitments}>もっと見る</Link>
      </div>
    </>
  )
}

export default FindJobScene
