import './DetailWaitingRecruitment.scss'

import { useCustom } from '@medical/provider/context'
import { Button } from 'primereact/button'
import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { WorkScheduleComment } from '../DetailWorkSchedule/WorkScheduleComment'
import { ConfirmStatusTemplate } from '../WaitingRecruitment/WaitingRecruitmentTemplate'
import { Dropdown } from 'primereact/dropdown'
import { Formik } from 'formik'
import * as Yup from 'yup'
import ConfirmationModal from '@medical/components/ConfirmationModal'

export const DetailWaitingRecruitmentScene = ({
  detailWaitingRecruitment,
  workScheduleComment,
  isAddPermitted,
  isDeletePermitted,
  staffMeId,
  onSubmit,
  isDeleteAllComment
}) => {
  const [{ i18n }] = useCustom()
  const validationSchema = Yup.object().shape({
    confirmationStatus: Yup.string().required(i18n.t('validation.identity.required'))
  })
  return (
    <Formik 
      initialValues={{
        confirm: false,
        confirmationStatus: ''
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      render={formikProps => {
        const {
          handleChange,
          setFieldValue,
          validateForm,
          values,
          isSubmitting
        } = formikProps
        return (
          <div className='detail-waiting-recruitment'>
            <div className='small-modal-title modal-title'>
              {i18n.t('staff.detailWaitingRecruitment.title')}
            </div>
            <div className='small-modal-content'>
              <div className='content-row'>
                <b>{i18n.t('staff.detailWaitingRecruitment.clinicName')}</b>
                <div className="content-value">{detailWaitingRecruitment.clinicName}</div>
              </div>
              <div className='content-row'>
                <b>{i18n.t('staff.detailWaitingRecruitment.workingDate')}</b>
                <span className="content-value">
                  {moment(detailWaitingRecruitment.startDate).format(
                    'YYYY年MM月DD日'
                  )}{' '}
                  -{moment(detailWaitingRecruitment.endDate).format('YYYY年MM月DD日')}
                </span>
              </div>
              <div className='content-row'>
                <b>{i18n.t('staff.detailWaitingRecruitment.workingHour')}</b>
                <span className="content-value">
                  {moment(detailWaitingRecruitment.startTime).format('HH:mm')} -
                  {moment(detailWaitingRecruitment.endTime).format('HH:mm')}
                </span>
              </div>
              <div className='content-row'>
                <b>{i18n.t('staff.detailWaitingRecruitment.note')}</b>
                <span className="content-value">{detailWaitingRecruitment.description}</span>
              </div>
              <div className='content-row'>
                <b>{i18n.t('staff.waitingRecruitment.status')}</b>
                <div className='content-value'>
                  <span>
                    <Dropdown
                      value={values.confirmationStatus}
                      name="confirmationStatus"
                      optionLabel='description'
                      valueLabel='value'
                      options={ConfirmStatusTemplate}
                      placeholder={i18n.t('staff.waitingRecruitment.confirmationStatus.' + `${detailWaitingRecruitment.confirmationStatus}`)}
                      onChange={handleChange}
                    />
                  </span>
                  <span>
                    <Button
                      className='update-waiting-recruitment-btn'
                      type='submit'
                      label={i18n.t('staff.detailWaitingRecruitment.updateButton')}
                      onClick={() => {
                        validateForm().then(response => {
                          if (!Object.keys(response).length) {
                            setFieldValue('confirm', true)
                          }
                        })
                      }}
                      disabled={isSubmitting}
                    />
                  </span>
                </div>
              </div>
              <div>
                <b>{i18n.t('staff.detailWaitingRecruitment.staffComment')}</b>
                <WorkScheduleComment
                  comments={workScheduleComment}
                  isWaitingRecruitment={true}
                  waitingRecruitmentShiftId={detailWaitingRecruitment.id}
                  isAddPermitted={isAddPermitted}
                  isDeletePermitted={isDeletePermitted}
                  staffId={staffMeId}
                  isShowType={false}
                  isDeleteAllComment={isDeleteAllComment}
                />
              </div>
              <div className='button-row'>
                <Link to='../waiting_recruitment'>
                  <Button
                    className='btn-main-color'
                    label={i18n.t('staff.detailWaitingRecruitment.ok')}
                  />
                </Link>
              </div>
            </div>
            <ConfirmationModal
              {...formikProps}
              confirmationMessage={i18n.t('staff.detailWaitingRecruitment.confirmation')}
            />
          </div>
        );
      }}
    />
  )
}
