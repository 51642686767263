import {
  DEPARTMENTS_FRAGMENT,
  WORK_PATTERNS_FRAGMENT,
} from '@medical/libs/general.graphql'
import { CLINICAL_DEPARTMENTS_QUERY } from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import gql from 'graphql-tag'

export const WORKSCHEDULES_CONNECTION = gql`
  query WorkSchedulesConnection(
    $first: Int
    $skip: Int
    $where: WorkScheduleWhereInput
    $orderBy: WorkScheduleOrderByInput
  ) {
    workSchedulesConnection(
      where: $where
      first: $first
      skip: $skip
      orderBy: $orderBy
    ) {
      aggregate(where: $where) {
        count
      }
      edges {
        node {
          id
          startTime
          endTime
          accepted
          updatedAt
          clinicalDepartment {
            id
            name
            extraName
            clinic {
              id
              name
            }
          }
          doctor {
            id
            lastname
            firstname
            workPattern
          }
          workScheduleActualTime {
            id
            startTime
            endTime
          }
          acceptedShift {
            id
            scheduledAvailableShiftGroup {
              id
            }
          }
        }
      }
    }
    ...DEPARTMENTS_FRAGMENT
    ...CLINICAL_DEPARTMENTS_QUERY
    ...WORK_PATTERNS_FRAGMENT
  }
  ${CLINICAL_DEPARTMENTS_QUERY}
  ${DEPARTMENTS_FRAGMENT}
  ${WORK_PATTERNS_FRAGMENT}
`

export const WORKSCHEDULES_CONNECTION_FILTER = gql`
  query WorkScheduleConnectionFilter(
    $first: Int
    $skip: Int
    $where: WorkScheduleWhereInput
    $orderBy: WorkScheduleOrderByInput
  ) {
    workScheduleConnectionFilter(
      where: $where
      first: $first
      skip: $skip
      orderBy: $orderBy
    ) {
      count
      data
    }
  }
`

export const WORKSCHEDULES_CONNECTION_DOWNLOAD = gql`
  query AvailableShiftsConnection(
    $first: Int
    $skip: Int
    $where: AvailableShiftWhereInput
    $orderBy: AvailableShiftOrderByInput
  ) {
    availableShiftsConnection(where: $where, first: $first, skip: $skip, orderBy: $orderBy) {
      aggregate(where: $where) {
        count
      }
      edges {
        node {
          id
          startTime
          endTime
          hourlyWage
          splitDateTime1
          splitDateTime2
          splitDateTime3
          splitHourlyWage1
          splitHourlyWage2
          splitHourlyWage3
          isStartTimeBreakTime
          isSplitDateTime1BreakTime
          isSplitDateTime2BreakTime
          isSplitDateTime3BreakTime
          isPublished
          dailySalary
          availableShiftToWorkSchedule
          isDoubleRecruitment
          clinicalDepartment {
            id
            endTimeMondayMorning
            startTimeMondayAfternoon
            name
            extraName
            clinic {
              id
              name
            }
          }
          workSchedule {
            id
            adjustHourlyWageRange
            doctor {
              id
              fullName
            }
          }
        }
      }
    }
  }
`
