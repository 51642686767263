import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { checkStaffPermission, splitLogic } from '@medical/libs'
import moment from 'moment'
import { clinicalDepartmentNames } from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import queryString from 'query-string'
import React, { useEffect } from 'react'

import WorkOutsideClinicDetailScene from './WorkOutsideClinicDetailScene'
import {
  QUERY_WORK_OUTSIDE,
  ACCEPTED_OR_REJECTED,
  WORK_OUTSIDE_CONNECTION,
} from '../WorkOutsideClinic.graphql'
import { CREATE_ACTIVITY } from '@medical/pages/doctor/AvailableShift/AvailableShift.graphql'
import { STAFF_PROCESS_WORK_OUTSIDE } from '@medical/constant/permissions'

const WorkOutsideClinicDetail = ({
  match: {
    params: { id },
  },
  closeModal,
  location: { search },
}) => {
  const {
    page = 1,
    rowsPerPage = 20,
    fullname,
    date,
    orderBy = 'createdAt_DESC',
  } = queryString.parse(search)
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  const isProcessPermitted = checkStaffPermission({
    functionName: STAFF_PROCESS_WORK_OUTSIDE,
  })
  let variables = {
    first: first < 0 ? 20 : first,
    skip: skip < 0 ? 0 : skip,
    orderBy,
    where: {
      AND: [
        {
          Doctor: {
            unSigned_contains: fullname
              ? fullname
                  .replace(/ /g, '')
                  .replace(/　/g, '')
                  .toLowerCase()
              : '',
          },
        },
      ],
    },
  }
  if (date)
    variables = {
      first: first < 0 ? 20 : first,
      skip: skip < 0 ? 0 : skip,
      orderBy,
      where: {
        AND: [
          {
            Doctor: {
              deletedAt: null,
              unSigned_contains: fullname
                ? fullname
                    .replace(/ /g, '')
                    .replace(/　/g, '')
                    .toLowerCase()
                : '',
            },
            startTime_gte: moment(date).startOf('days'),
            endTime_lte: moment(date).endOf('days'),
          },
        ],
      },
    }
  const [{ i18n, popup }] = useCustom()
  const mutationOptions = {
    refetchQueries: [
      {
        query: WORK_OUTSIDE_CONNECTION,
        variables,
      },
    ],
    awaitRefetchQueries: true,
  }
  const [staffCreateActivity] = useMutation(CREATE_ACTIVITY)

  const [staffProcessWorkOutside] = useMutation(
    ACCEPTED_OR_REJECTED,
    mutationOptions
  )
  const { loading, error, data, refetch } = useQuery(QUERY_WORK_OUTSIDE, {
    variables: {
      where: {
        id,
      },
    },
  })
  useEffect(() => {
    refetch()
  })
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const {
        endTime,
        hourlyWage,
        splitDateTime1,
        splitDateTime2,
        splitDateTime3,
        splitHourlyWage1,
        splitHourlyWage2,
        splitHourlyWage3,
        isStartTimeBreakTime,
        isSplitDateTime1BreakTime,
        isSplitDateTime2BreakTime,
        isSplitDateTime3BreakTime,
      } = splitLogic({ submitDate: values.date, splits: values.splits })
      await staffProcessWorkOutside({
        variables: {
          workOutsideId: id,
          startTime: moment(values.date)
            .hour(moment(values.startTime).hour())
            .minute(moment(values.startTime).minute()),
          endTime,
          hourlyWage,
          splitDateTime1,
          splitDateTime2,
          splitDateTime3,
          splitHourlyWage1,
          splitHourlyWage2,
          splitHourlyWage3,
          isStartTimeBreakTime,
          isSplitDateTime1BreakTime,
          isSplitDateTime2BreakTime,
          isSplitDateTime3BreakTime,
          action: values.functionName,
        },
      })
      if (values.functionName === 'APPROVAL') {
        staffCreateActivity({
          variables: {
            activity: 'APPROVAL_WORK_OUTSIDE',
            doctorId: workOutside.createdBy.id,
          },
        })
      }
      if (values.functionName === 'REJECTED') {
        staffCreateActivity({
          variables: {
            activity: 'REJECTED_WORK_OUTSIDE',
            doctorId: workOutside.createdBy.id,
          },
        })
      }
      popup.success('成功しました')
      setSubmitting(false)
      resetForm()
      closeModal()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }
  const { departments, workOutside } = data
  const departmentNames = clinicalDepartmentNames({
    departments,
  })
  return (
    <WorkOutsideClinicDetailScene
      workOutside={workOutside}
      i18n={i18n}
      departmentNames={departmentNames}
      onSubmit={onSubmit}
      isProcessPermitted={isProcessPermitted}
    />
  )
}

export default WorkOutsideClinicDetail
