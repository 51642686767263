import React from 'react'
import { Button } from 'primereact/button'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import { Checkbox } from 'antd'
import { Formik } from 'formik'
import moment from 'moment'
import { InputTextarea } from 'primereact/inputtextarea'
const RecruitmentDoctorDetailScene = ({
  i18n,
  onSubmit,
  doctor,
  recruitmentPost,
  recruitmentDoctorData,
  isUpdatePermitted,
  departmentNames,
}) => {
  const renderWorkPattern = value => {
    switch (value) {
      case 'PERMANENT_DOCTOR':
        return '常勤'
      case 'REGULAR_SHIFT_DOCTOR':
        return '定期非常勤'
      case 'IRREGULAR_SHIFT_DOCTOR':
        return '不定期非常勤'
      case 'TEMPORARY_DOCTOR':
        return 'スポット'
    }
  }
  return (
    <Formik
      enableReinitialize
      initialValues={{
        confirm: false,
        confirmationMessage: '更新しますか？',
        notAction: recruitmentDoctorData.status === 'NOT_ACTION',
        inAction: recruitmentDoctorData.status === 'IN_ACTION',
        completed: recruitmentDoctorData.status === 'COMPLETED',
        staffMemo: recruitmentDoctorData.staffMemo || '',
      }}
      onSubmit={onSubmit}
      render={formikProps => {
        const {
          values,
          setFieldValue,
          validateForm,
          setTouched,
          handleChange,
        } = formikProps
        const format = 'HH:mm'
        return (
          <React.Fragment>
            <div className='modal-title' style={{ fontWeight: 'bold' }}>
              {i18n.t('doctor.recruimentApply.title')}
            </div>
            <div className='tr'>
              <div className='th '>
                {i18n.t('doctor.recruimentApply.recruimentTitle')}
              </div>
              <div className='td'>{recruitmentPost.title}</div>
            </div>
            <div className='tr'>
              <div className='th '> {i18n.t('staff.activityLogs.doctor')}</div>
              <div className='td'>{doctor.fullName}</div>
            </div>
            <div className='tr'>
              <div className='th '>
                {i18n.t('doctor.recruimentApply.email')}
              </div>
              <div className='td'>{doctor.email}</div>
            </div>
            <div className='tr'>
              <div className='th '>{i18n.t('doctorClinic.name')}</div>
              <div className='td'>
                {recruitmentPost.clinicalDepartment
                  ? `${recruitmentPost.clinicalDepartment.clinic.name}_${
                      departmentNames[recruitmentPost.clinicalDepartment.name]
                    }`
                  : 'なし'}
              </div>
            </div>
            <div className='tr'>
              <div className='th '>現在のCAPSでの勤務形態</div>
              <div className='td'>{renderWorkPattern(doctor.workPattern)}</div>
            </div>
            <div className='tr'>
              <div className='th '>勤務開始希望</div>
              <div className='td'>
                {renderWorkPattern(recruitmentPost.recruitmentDoctorType)}
              </div>
            </div>

            <div className='tr'>
              <div className='th ' style={{ marginBottom: 'auto' }}>
                {i18n.t('doctor.recruimentApply.workables')}
              </div>
              <div className='td'></div>
              {recruitmentDoctorData.startTimeMonday &&
              recruitmentDoctorData.endTimeMonday ? (
                <>
                  <div className='th'></div>
                  <div className='td'>
                    {`月　${moment(
                      recruitmentDoctorData.startTimeMonday
                    ).format(format)}～${moment(
                      recruitmentDoctorData.endTimeMonday
                    ).format(format)}`}
                  </div>
                </>
              ) : null}
              {recruitmentDoctorData.startTimeTuesday &&
              recruitmentDoctorData.endTimeTuesday ? (
                <>
                  <div className='th'></div>
                  <div className='td'>
                    {`火　${moment(
                      recruitmentDoctorData.startTimeTuesday
                    ).format(format)}～${moment(
                      recruitmentDoctorData.endTimeTuesday
                    ).format(format)}`}
                  </div>
                </>
              ) : null}
              {recruitmentDoctorData.startTimeWednesday &&
              recruitmentDoctorData.endTimeWednesday ? (
                <>
                  <div className='th'></div>
                  <div className='td'>
                    {`水　${moment(
                      recruitmentDoctorData.startTimeWednesday
                    ).format(format)}～${moment(
                      recruitmentDoctorData.endTimeWednesday
                    ).format(format)}`}
                  </div>
                </>
              ) : null}
              {recruitmentDoctorData.startTimeThursday &&
              recruitmentDoctorData.endTimeThursday ? (
                <>
                  <div className='th'></div>
                  <div className='td'>
                    {`木　${moment(
                      recruitmentDoctorData.startTimeThursday
                    ).format(format)}～${moment(
                      recruitmentDoctorData.endTimeThursday
                    ).format(format)}`}
                  </div>
                </>
              ) : null}
              {recruitmentDoctorData.startTimeFriday &&
              recruitmentDoctorData.endTimeFriday ? (
                <>
                  <div className='th'></div>
                  <div className='td'>
                    {`金　${moment(
                      recruitmentDoctorData.startTimeFriday
                    ).format(format)}～${moment(
                      recruitmentDoctorData.endTimeFriday
                    ).format(format)}`}
                  </div>
                </>
              ) : null}
              {recruitmentDoctorData.startTimeSaturday &&
              recruitmentDoctorData.endTimeSaturday ? (
                <>
                  <div className='th'></div>
                  <div className='td'>
                    {`土　${moment(
                      recruitmentDoctorData.startTimeSaturday
                    ).format(format)}～${moment(
                      recruitmentDoctorData.endTimeSaturday
                    ).format(format)}`}
                  </div>
                </>
              ) : null}
              {recruitmentDoctorData.startTimeSunday &&
              recruitmentDoctorData.endTimeSunday ? (
                <>
                  <div className='th'></div>
                  <div className='td'>
                    {`日　${moment(
                      recruitmentDoctorData.startTimeSunday
                    ).format(format)}～${moment(
                      recruitmentDoctorData.endTimeSunday
                    ).format(format)}`}
                  </div>
                </>
              ) : null}
              {recruitmentDoctorData.startTimeHoliday &&
              recruitmentDoctorData.endTimeHoliday ? (
                <>
                  <div className='th'></div>
                  <div className='td'>
                    {`祝　${moment(
                      recruitmentDoctorData.startTimeHoliday
                    ).format(format)}～${moment(
                      recruitmentDoctorData.endTimeHoliday
                    ).format(format)}`}
                  </div>
                </>
              ) : null}
            </div>
            <div className='tr'>
              <div className='th '>
                {i18n.t('doctor.recruimentApply.desiredTime')}
              </div>
              <div className='td '>
                {recruitmentDoctorData.desireStartMoment}
              </div>
            </div>
            <div className='tr'>
              <div className='th'>ステータス</div>
              <div className='td'>
                <div className='row'>
                  <Checkbox
                    style={{ marginRight: '10px', marginBottom: '10px' }}
                    checked={values.notAction}
                    onChange={e => {
                      if (e.target.checked) {
                        setFieldValue('inAction', false)
                        setFieldValue('completed', false)
                        setFieldValue('notAction', true)
                      } else {
                        setFieldValue('notAction', true)
                      }
                    }}
                  />
                  未対応
                </div>
                <div className='row'>
                  <Checkbox
                    style={{ marginRight: '10px', marginBottom: '10px' }}
                    checked={values.inAction}
                    onChange={e => {
                      if (e.target.checked) {
                        setFieldValue('notAction', false)
                        setFieldValue('completed', false)
                        setFieldValue('inAction', true)
                      } else {
                        setFieldValue('inAction', true)
                      }
                    }}
                  />
                  対応中
                </div>
                <div className='row'>
                  <Checkbox
                    style={{ marginRight: '10px', marginBottom: '10px' }}
                    checked={values.completed}
                    onChange={e => {
                      if (e.target.checked) {
                        setFieldValue('inAction', false)
                        setFieldValue('notAction', false)
                        setFieldValue('completed', true)
                      } else {
                        setFieldValue('completed', true)
                      }
                    }}
                  />
                  対応終了
                </div>
              </div>
            </div>
            <div className='tr'>
              <div className='th'>スタッフメモ</div>
              <div className='td'>
                <InputTextarea
                  name='staffMemo'
                  placeholder='メモ'
                  values={values}
                  value={values.staffMemo}
                  onChange={handleChange}
                  autoResize
                  rows={5}
                />
              </div>
            </div>
            <div className='tr'>
              <div className='th '>備考欄</div>
              <div className='td row'>
                <InputTextarea
                  name='remark'
                  value={recruitmentDoctorData.remark}
                  disabled
                  rows={8}
                  autoResize
                />
              </div>
            </div>
            <div className='div modal-bottom'>
              {isUpdatePermitted && (
                <Button
                  className='reflect-button'
                  label='ステータスを更新する'
                  onClick={() => {
                    validateForm().then(response => {
                      if (!Object.keys(response).length) {
                        setFieldValue('confirm', true)
                      } else {
                        setTouched(response)
                      }
                    })
                  }}
                />
              )}
            </div>
            <ConfirmationModal
              {...formikProps}
              confirmationMessage={values.confirmationMessage}
            />
          </React.Fragment>
        )
      }}
    />
  )
}
export default RecruitmentDoctorDetailScene
