import gql from 'graphql-tag'

export const GET_LIST_SCHEDULE_CANCEL = gql`
  query WorkScheduleCancellationLettersConnection(
    $orderBy: WorkScheduleCancellationLetterOrderByInput
    $where: WorkScheduleCancellationLetterWhereInput
    $first: Int
    $skip: Int
  ) {
    workScheduleCancellationLettersConnection(
      orderBy: $orderBy
      where: $where
      first: $first
      skip: $skip
    ) {
      edges {
        cursor
        node {
          id
          createdAt
          updatedAt
          deletedAt
          isTickCountCancel
          status
          acceptedAt
          reason
          reassignDoctorNote
          reworkNote
          comment
          workSchedule {
            id
            doctor {
              id
              firstnameKana
              lastnameKana
              firstname
              lastname
            }
            createdAt
            acceptedBy {
              id
              firstname
              lastname
            }
            acceptedShift {
              id
              createdAt
              createdBy {
                id
                lastname
                firstname
              }
            }
            isCreatedByDoctor
            clinicalDepartment {
              id
              name
              clinic {
                id
                name
              }
            }
            startTime
            endTime
          }
        }
      }
      aggregate(where: $where) {
        count
      }
    }
  }
`
