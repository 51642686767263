import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { CATEGORY_TRANSPORT } from '@medical/constant'
import { dateValidation, findNameOrEmail, onPageChange } from '@medical/libs'
import Auth from '@medical/middleware/auth'
import { useCustom } from '@medical/provider/context'
import { SocketContext } from '@medical/provider/socket'
import {
  removeProgress,
  setProgressBar,
} from '@medical/provider/store/reducers/progress/progress.action'
import Router from '@medical/routes/router'
import { connect } from 'react-redux'
import moment from 'moment'
import queryString from 'query-string'
import React, { useContext, useMemo } from 'react'
import { Redirect } from 'react-router-dom'
import { DOCTORS_MONTHLY_TRANSPORTATION_EXPENSES } from './DoctorsMonthlyTransportationExpenses.graphql'
import DoctorsMonthlyTransporationExpensesScene from './DoctorsMonthlyTransportationExpensesScene'

const DoctorsMonthlyTransporationExpenses = ({
  match: {
    params: { year = moment().year(), month = moment().month() + 1 },
  },
  location: { search },
  history,
  progress,
  setProgress,
  removeProgress,
}) => {
  const [{ i18n }] = useCustom()
  const socket = useContext(SocketContext)
  const {
    fullname,
    email,
    isWaitingForApproval = undefined,
    isDuplicate = undefined,
    isDuplicateRouteFee = undefined,
    page = 1,
    rowsPerPage = 20,
  } = queryString.parse(search)

  const startDateOfMonth = useMemo(
    () =>
      moment()
        .year(year)
        .month(month - 1)
        .startOf('month')
        .toISOString(),
    [year, month]
  )

  const endDateOfMonth = useMemo(
    () =>
      moment()
        .year(year)
        .month(month - 1)
        .endOf('month')
        .toISOString(),
    [year, month]
  )

  const waitingForApproval = useMemo(
    () => ({
      startTime_lte: endDateOfMonth,
      startTime_gte: startDateOfMonth,
      status: 'WAITING_FOR_APPROVAL',
      deletedAt: null,
    }),
    [endDateOfMonth, startDateOfMonth]
  )

  const isDuplicateCondition = useMemo(
    () => ({
      endTime: { gte: startDateOfMonth, lt: endDateOfMonth },
      OR: [
        {
          category: 'RECURRING_TRAVEL_FEE',
          status: 'ACCEPTED',
        },
        {
          category: 'TRANFFIC_FEE',
          ClinicalDepartment: { isNot: null },
        },
      ],
    }),
    [startDateOfMonth, endDateOfMonth]
  )

  const transportationExpenseCondition = []
  const transportationNoneExpenseCondition = []
  if (isWaitingForApproval === '有') {
    transportationExpenseCondition.push(waitingForApproval)
  } else if (isWaitingForApproval === '無') {
    transportationNoneExpenseCondition.push(waitingForApproval)
  }

  if (isDuplicate === '有') {
    transportationExpenseCondition.push(isDuplicateCondition)
  } else if (isDuplicate === '無') {
    transportationNoneExpenseCondition.push(isDuplicateCondition)
  }

  const { loading, data, error } = useQuery(
    DOCTORS_MONTHLY_TRANSPORTATION_EXPENSES,
    {
      variables: {
        where: {
          registrationStatus_not_in: ['NEW', 'PENDING', 'REJECTED'],
          deletedAt: null,
          AND: findNameOrEmail({ fullname, email }),
          OR: [
            {
              WorkSchedule_some: {
                deletedAt: null,
                accepted: true,
                startTime_gte: startDateOfMonth,
                startTime_lte: moment()
                  .startOf('days')
                  .toISOString(),
                AvailableShift_some: {
                  isPublished: true,
                },
              },
            },
            {
              TransportationExpense_some: {
                deletedAt: null,
                status: 'ACCEPTED',
                category_in: ['RECURRING_TRAVEL_FEE', 'TRANFFIC_FEE'],
                endTime_gte: startDateOfMonth,
                endTime_lt: endDateOfMonth,
                OR: [
                  {
                    category: 'RECURRING_TRAVEL_FEE',
                    status: 'ACCEPTED',
                  },
                  {
                    category: 'TRANFFIC_FEE',
                    ClinicalDepartment: {
                      isNot: null,
                    },
                  },
                ],
              },
            },
          ],
          TransportationExpense_some: transportationExpenseCondition.length
            ? {
                AND: transportationExpenseCondition,
              }
            : undefined,
          TransportationExpense_none: transportationNoneExpenseCondition.length
            ? {
                AND: transportationNoneExpenseCondition,
              }
            : undefined,
        },
        date: startDateOfMonth,
        doctorMonthlyTransportationExpensesDate2: startDateOfMonth,
        transportationExpensesWhere2: {
          startTime_lte: endDateOfMonth,
          startTime_gte: startDateOfMonth,
          status: 'WAITING_FOR_APPROVAL',
          deletedAt: null,
        },
        first: !isDuplicateRouteFee ? +rowsPerPage : undefined,
        skip: !isDuplicateRouteFee ? (page - 1) * rowsPerPage : undefined,
      },
      fetchPolicy: 'network-only',
    }
  )

  let dataResponse = useMemo(() => {
    if (!data) return []
    return data.doctorsConnection.edges.map(({ node }) => {
      const {
        doctorMonthlyWorkSchedules,
        doctorMonthlyTransportationExpenses,
      } = node
      let routeTransportationFee = '無'
      let duplicateTransporation = '無'
      const dataTransportations = doctorMonthlyTransportationExpenses.filter(
        transportation =>
          !transportation.deletedAt &&
          transportation.category !== CATEGORY_TRANSPORT.TRANFFIC_FEE
      )
      const dataEndTimeTranffic = doctorMonthlyTransportationExpenses
        .filter(
          transportation =>
            !transportation.deletedAt &&
            transportation.category === CATEGORY_TRANSPORT.TRANFFIC_FEE
        )
        .map(({ endTime }) => endTime)
      if (doctorMonthlyWorkSchedules.length > 0) {
        function checkIfFeeDuplicateExists(arr) {
          return new Set(arr).size !== arr.length
        }
        let startTimeWs = doctorMonthlyWorkSchedules
          .filter(
            ({ notApplyCostTransportation }) => !notApplyCostTransportation
          )
          .map(item => moment(item.startTime).format('YYYY-MM-DD'))
        if (checkIfFeeDuplicateExists(startTimeWs) == true) {
          routeTransportationFee = '有'
        }
      }
      if (dataTransportations.length > 0) {
        let startTimeWs = doctorMonthlyWorkSchedules
          .filter(
            ({ notApplyCostTransportation }) => !notApplyCostTransportation
          )
          .map(item => item.startTime)
        startTimeWs.push(...dataEndTimeTranffic)
        startTimeWs = new Set(startTimeWs)
        startTimeWs.forEach(startTime => {
          dataTransportations.forEach(transportation => {
            const datesCheck = transportation.blockedDays
              ? transportation.blockedDays.split(',')
              : []
            const timeCurrent = moment(startTime).format('YYYY-MM-DD')
            if (
              !datesCheck.includes(timeCurrent.slice(0, 10)) &&
              moment(transportation.startTime).isSameOrBefore(startTime) &&
              moment(transportation.endTime).isSameOrAfter(startTime)
            ) {
              duplicateTransporation = '有'
            }
          })
        })
      }
      return {
        doctorId: node.id,
        fullName: node.fullName,
        email: node.email,
        numberOfMonthlyFixedShifts: doctorMonthlyWorkSchedules.length,
        transportationExpensesWaitingForApproval:
          node.transportationExpenses.length > 0 ? '有' : '無',
        duplicateTransporation,
        routeTransportationFee,
      }
    })
  }, [data])

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  if (
    dateValidation({ year, month }, true) ||
    moment(`${year} ${month} 01`, 'YYYY MM DD').isBefore(
      moment().subtract(12, 'months'),
      'month'
    )
  ) {
    return (
      <Redirect
        to={Router.get(Router.staffDoctorsMonthlyTransportationExpenses, {
          year: moment()
            .utc()
            .year(),
          month:
            moment()
              .utc()
              .month() + 1,
        })}
      />
    )
  }

  if (isDuplicateRouteFee) {
    dataResponse = dataResponse.filter(
      data => data.routeTransportationFee == isDuplicateRouteFee
    )
  }
  const token = Auth.getToken()
  const url = `${process.env.REACT_APP_ENDPOINT}/downloadListMonthlyTransportationExpensesDoctorCsv?token=${token}&year=${year}&month=${month}`
  const urlSpecificDay = date => {
    const startTime = moment(date[0]).toISOString()
    const endTime = moment(date[1]).toISOString()
    return `${process.env.REACT_APP_ENDPOINT}/downloadListDailyTransportationExpensesDoctorCsv?token=${token}&startTime=${startTime}&endTime=${endTime}`
  }
  return (
    <DoctorsMonthlyTransporationExpensesScene
      socket={socket}
      progress={progress}
      setProgress={progress => {
        setProgress(progress)
      }}
      removeProgress={progress => removeProgress(progress)}
      download={url}
      i18n={i18n}
      year={year}
      month={month}
      email={email}
      fullname={fullname}
      page={parseInt(page, 10)}
      urlSpecificDay={urlSpecificDay}
      data={
        !isDuplicateRouteFee
          ? dataResponse
          : dataResponse.slice(
              (page - 1) * rowsPerPage,
              page > 1 ? parseInt(page * rowsPerPage) : 20
            )
      }
      rowsPerPage={parseInt(rowsPerPage, 10)}
      onPageChange={onPageChange(history, search)}
      count={
        !isDuplicateRouteFee
          ? data.doctorsConnection.aggregate.count
          : dataResponse.length
      }
      isDuplicate={isDuplicate}
      isWaitingForApproval={isWaitingForApproval}
      isDuplicateRouteFee={isDuplicateRouteFee}
    />
  )
}

const mapStateToProps = state => ({
  progress: state.progressBarStore.progress,
})

const mapDispatchToProps = dispatch => ({
  setProgress: progress => dispatch(setProgressBar(progress)),
  removeProgress: progress => dispatch(removeProgress(progress)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DoctorsMonthlyTransporationExpenses)
