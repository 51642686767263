import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import gql from 'graphql-tag'

export const STAFF_ACCEPT_WORKSCHEDULE_OVERTIME = gql`
  mutation staffAcceptWorkScheduleOverTime(
    $workOverTimeId: ID!
    $workScheduleId: ID!
    $data: WorkScheduleOvertimeUpdateWithoutWorkScheduleDataInput!
    $startTime: DateTime
    $endTime: DateTime
    $hourlyWage: Int
    $splitDateTime1: DateTime
    $splitDateTime2: DateTime
    $splitDateTime3: DateTime
    $splitHourlyWage1: Int
    $splitHourlyWage2: Int
    $splitHourlyWage3: Int
    $isStartTimeBreakTime: Boolean
    $isSplitDateTime1BreakTime: Boolean
    $isSplitDateTime2BreakTime: Boolean
    $isSplitDateTime3BreakTime: Boolean
  ) {
    staffAcceptWorkScheduleOverTime(
      workOverTimeId: $workOverTimeId
      workScheduleId: $workScheduleId
      data: $data
      startTime: $startTime
      endTime: $endTime
      hourlyWage: $hourlyWage
      splitDateTime1: $splitDateTime1
      splitDateTime2: $splitDateTime2
      splitDateTime3: $splitDateTime3
      splitHourlyWage1: $splitHourlyWage1
      splitHourlyWage2: $splitHourlyWage2
      splitHourlyWage3: $splitHourlyWage3
      isStartTimeBreakTime: $isStartTimeBreakTime
      isSplitDateTime1BreakTime: $isSplitDateTime1BreakTime
      isSplitDateTime2BreakTime: $isSplitDateTime2BreakTime
      isSplitDateTime3BreakTime: $isSplitDateTime3BreakTime
    ) {
      id
    }
  }
`
export const WORKSCHEDULE_OVERTIME_DETAIL = gql`
  query WorkScheduleOvertimes($where: WorkScheduleOvertimeWhereInput) {
    workScheduleOvertimes(where: $where) {
      id
      deletedAt
      lateArrival
      reasonEarlyDeparture
      status
      startTime
      endTime
      splitDateTime1
      splitDateTime2
      splitDateTime3
      isStartTimeBreakTime
      isSplitDateTime1BreakTime
      isSplitDateTime2BreakTime
      isSplitDateTime3BreakTime
      hourlyWage
      splitHourlyWage1
      splitHourlyWage2
      splitHourlyWage3
      earlyDeparture
      reasonLateArrival
      workScheduleHistory
      comment
      workSchedule {
        id
        startTime
        endTime
        workScheduleActualTime {
          id
          startTime
          endTime
        }
        clinicalDepartment {
          id
          name
          extraName
          isDisplay
          clinic {
            id
            order
            name
            nameKana
          }
        }
        acceptedShift {
          id
        }
      }
    }
    ...DEPARTMENTS_FRAGMENT
  }
  ${DEPARTMENTS_FRAGMENT}
`
