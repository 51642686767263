import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import gql from 'graphql-tag'

export const CLINICS_DAILY_REPORT = gql`
  query CLINICS_DAILY_REPORT($date: DateTime!) {
    clinicalDepartments(orderBy: name_ASC) {
      id
      name
      extraName
      isDisplay
      clinic {
        id
        name
        order
        region
        Region {
          id
          name
          position
        }
      }
      clinicalSchedules {
        id
        startDate
        endDate
        endTimeMorning
        startTimeMorning
        isAvailableMorning
        endTimeAfternoon
        startTimeAfternoon
        isAvailableAfternoon
        deletedAt
      }
      availableShifts(date: $date) {
        id
        startTime
        endTime
        hourlyWage
        splitDateTime1
        splitDateTime2
        splitDateTime3
        splitHourlyWage1
        splitHourlyWage2
        splitHourlyWage3
        isStartTimeBreakTime
        isSplitDateTime1BreakTime
        isSplitDateTime2BreakTime
        isSplitDateTime3BreakTime
        isEarlySpecial
        isDoubleRecruitment
        isPublished
        isSpecial
      }
      workSchedules(date: $date) {
        id
        startTime
        endTime
        accepted
        deletedStatus
        deletedAt
        acceptedShift {
          deletedAt
          id
          isPublished
          scheduledAvailableShiftGroup {
            id
          }
          splitDateTime1
          splitDateTime2
          splitDateTime3
          isStartTimeBreakTime
          isSplitDateTime1BreakTime
          isSplitDateTime2BreakTime
          isSplitDateTime3BreakTime
          isDoubleRecruitment
        }
        doctor {
          id
          firstname
          lastname
          firstWorkday
          workPattern
          deletedAt
        }
      }
    }
    ...DEPARTMENTS_FRAGMENT
  }
  ${DEPARTMENTS_FRAGMENT}
`
export const WORK_SCHEDULES_CONNECTION = gql`
  query Query($where: WorkScheduleWhereInput) {
    workSchedulesConnection(where: $where) {
      edges {
        node {
          id
        }
      }
    }
  }
`
export const REGION_QUERY = gql`
  query regions($orderBy: RegionOrderByInput) {
    regions(orderBy: $orderBy) {
      name
      id
      position
    }
  }
`