import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, NotFound, ProgressSpinner } from '@medical/components'
import { clinicalDepartmentNames } from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import React from 'react'

import { DOCTOR_RECRUITMENT_POST } from './RecruitmentPostDetail.graphql'
import RecruitmentPostDetailScene from './RecruitmentPostDetailScene'

const RecruitmentPostDetail = ({
  match: {
    params: { recruitmentId },
  },
}) => {
  const [{ i18n, isDoctorBlocked  }] = useCustom()
  const { loading, error, data } = useQuery(DOCTOR_RECRUITMENT_POST, {
    variables: {
      where: {
        id: recruitmentId,
      },
    },
  })
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  const { recruitmentPost = {}, departments } = data
  if (!recruitmentPost || isDoctorBlocked) {
    return <NotFound />
  }
  const departmentNames = clinicalDepartmentNames({
    departments,
  })
  return (
    <RecruitmentPostDetailScene
      i18n={i18n}
      recruitmentPost={recruitmentPost}
      recruitmentId={recruitmentId}
      departmentNames={departmentNames}
    />
  )
}
export default RecruitmentPostDetail
