import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import ClinicNameRender from '@medical/components/ClinicNameRender'
import {
  clinicalDepartmentNames,
} from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import React, { useEffect } from 'react'
import { parseJwt } from '@medical/constant/utilities'
import { WORK_OUTSIDE_CONNECTION } from './WorkOutsideClinic.graphql'
import WorkOutsideClinicScene from './WorkOutsideClinicScene'

const WorkOutsideClinic = () => {
  const [{ i18n }] = useCustom()
  const { doctorId } = parseJwt(localStorage.getItem('medical_doctor_token'))
  const { loading, error, data, refetch } = useQuery(WORK_OUTSIDE_CONNECTION, {
    variables: {
      where: {
        Doctor: {
          id: doctorId,
        },
      },
      orderBy: 'createdAt_DESC',
    },
  })

  useEffect(() => {
    refetch()
  }, [refetch])

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  const {
    workOutsidesConnection: {
      edges,
      aggregate: { count },
    },
    departments,
  } = data

  const departmentNames = clinicalDepartmentNames({
    departments,
  })
  const resultWorkOutsidesList = []
  // eslint-disable-next-line array-callback-return
  edges.forEach(({ node }) => {
    resultWorkOutsidesList.push({
      id: node.id,
      createdAt: node.createdAt,
      doctorName: `${node.createdBy.lastname} ${node.createdBy.firstname}`,
      startTime: node.startTime,
      endTime: node.endTime,
      clinicName: ClinicNameRender({
        clinicalDepartment: node.clinicalDepartment,
      }),
      clinicalDepartmentName: departmentNames[node.clinicalDepartment.name],
      address: node.address,
      status: node.status,
    })
  })

  const statusConvertText = status => {
    switch (status) {
      case 'WAITING_APPROVAL': {
        return <span style={{ color: '#369EFD' }}>承認待ち</span>
      }
      case 'APPROVAL': {
        return <span style={{ color: '#647380' }}>承認済み</span>
      }
      case 'REJECTED': {
        return <span style={{ color: '#FF518F' }}>却下</span>
      }
      default:
        return null
    }
  }

  return (
    <WorkOutsideClinicScene
      i18n={i18n}
      resultWorkOutsidesList={resultWorkOutsidesList}
      statusConvertText={statusConvertText}
    />
  )
}

export default WorkOutsideClinic
