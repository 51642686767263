import gql from 'graphql-tag'

export const CREATE_REGION = gql`
  mutation staffCreateRegion($name: String!, $position: Int!) {
    staffCreateRegion(name: $name, position: $position)
  }
`
export const UPDATE_REGION = gql`
  mutation staffUpdateRegion(
    $regionId: ID
    $name: String
    $deletedAt: String
    $position: Int!
  ) {
    staffUpdateRegion(
      regionId: $regionId
      name: $name
      deletedAt: $deletedAt
      position: $position
    )
  }
`
