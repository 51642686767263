import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { CATEGORY_QUERY } from './Category.graphql'
import { useCustom } from '@medical/provider/context'
import React, { useEffect } from 'react'
import {
  STAFF_CREATE_PORTAL,
  STAFF_UPDATE_PORTAL,
  STAFF_DELETE_PORTAL,
} from '@medical/constant/permissions'
import { checkStaffPermission } from '@medical/libs'
import { UPSERT_CATEGORY, DELETE_CATEGORY } from './Category.graphql'
import CategoryForm from './CategoryForm'

const Categorys = ({ location: { pathname } }) => {
  const [{ i18n, popup }] = useCustom()
  const isCreatePermitted = checkStaffPermission({
    functionName: STAFF_CREATE_PORTAL,
  })
  const isDeletePermitted = checkStaffPermission({
    functionName: STAFF_DELETE_PORTAL,
  })
  const isUpdatePermitted = checkStaffPermission({
    functionName: STAFF_UPDATE_PORTAL,
  })
  const isLargeTitle = pathname.includes('large')
  const { error, loading, data, refetch } = useQuery(CATEGORY_QUERY, {
    variables: {
      orderBy: 'createdAt_ASC',
      where: {
        titleType: isLargeTitle ? 'LARGE_TITLE' : 'MEDIUM_TITLE',
      },
    },
    fetchPolicy: 'network-only',
  })
  useEffect(() => {
    refetch()
  })
  const [deleteCategory] = useMutation(DELETE_CATEGORY)
  const [upsertCategory] = useMutation(UPSERT_CATEGORY)
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  const onSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    let successMessage = '削除しました'
    if (values.functionName === 'update') {
      successMessage = '更新しました'
    } else if (values.functionName === 'create') {
      successMessage = '作成しました'
    }
    try {
      if (values.functionName === 'create' && isLargeTitle) {
        await upsertCategory({
          variables: {
            portalId: '',
            largeTitle: values.createContent.trim(),
            titleType: 'LARGE_TITLE',
          },
        })
      } else if (values.functionName === 'create' && !isLargeTitle) {
        await upsertCategory({
          variables: {
            portalId: '',
            mediumTitle: values.createContent.trim(),
            titleType: 'MEDIUM_TITLE',
          },
        })
      } else if (values.functionName === 'update' && isLargeTitle) {
        await upsertCategory({
          variables: {
            portalId: values.id,
            largeTitle: values.content.trim(),
            titleType: 'LARGE_TITLE',
          },
        })
      } else if (values.functionName === 'update' && !isLargeTitle) {
        await upsertCategory({
          variables: {
            portalId: values.id,
            mediumTitle: values.content.trim(),
            titleType: 'MEDIUM_TITLE',
          },
        })
      } else if (values.functionName === 'delete') {
        await deleteCategory({
          variables: {
            portalId: values.id,
            isDeleted: true,
            deleteTitle: true,
          },
        })
      }
      popup.success(successMessage)
      setSubmitting(false)
      resetForm()
    } catch (error) {
      popup.error(error)
      setFieldValue('confirm', false)
      setSubmitting(false)
    }
  }
  const listCategory =
    data && data.portals
      ? data.portals.map(item => ({
          id: item.id,
          content: isLargeTitle ? item.largeTitle : item.mediumTitle,
          edit: true,
        }))
      : []
  return (
    <CategoryForm
      listCategory={listCategory}
      isLargeTitle={isLargeTitle}
      i18n={i18n}
      onSubmit={onSubmit}
      submitLabel={i18n.t('staff.createAnnounce.submit')}
      confirmationMessage={i18n.t('staff.createAnnounce.submitConfirmation')}
      isCreatePermitted={isCreatePermitted}
      isUpdatePermitted={isUpdatePermitted}
      isDeletePermitted={isDeletePermitted}
    />
  )
}

export default Categorys
