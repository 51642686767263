import { Formik } from 'formik'
import { Button } from 'primereact/button'
import ClinicNameRender from '@medical/components/ClinicNameRender'
import { splitLogicReverse } from '@medical/libs'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import React from 'react'
import CustomCalendarPicker from '@medical/components/CalendarPicker'
import moment from 'moment'
import ShiftFormWorkOutsideSplits from '@medical/pages/staff/components/ShiftFormWorkOutsideSplits'

import { SHIFT_FORM_STEP } from '@medical/constant'

const WorkOutsideClinicDetailScene = ({
  departmentNames,
  workOutside,
  i18n,
  onSubmit,
  isProcessPermitted,
}) => {
  const disabled = workOutside.status !== 'WAITING_APPROVAL'
  const date = moment(workOutside.startTime)
  .startOf('day')
  .hour(9)
  return (
    <Formik
      enableReinitialize
      initialValues={{
        confirm: false,
        functionName: '',
        confirmationMessage: '',
        clinicName: ClinicNameRender({
          clinicalDepartment: workOutside.clinicalDepartment,
        }),
        clinicalDepartmentName:
          departmentNames[workOutside.clinicalDepartment.name],
        doctorName: `${workOutside.createdBy.lastname} ${workOutside.createdBy.firstname}`,
        startTime: workOutside.startTime,
        date: date.toDate(),
        splits: splitLogicReverse({
          availableShift: workOutside,
        }),
      }}
      onSubmit={onSubmit}
      render={formikProps => {
        const { setFieldValue, handleChange, values } = formikProps
        return (
          <div>
            <div className='modal-title'>
              {i18n.t('doctor.menuBar.workOutsideClinic')}
            </div>
            <div className='tr'>
              <div className='th'>
                {i18n.t('main.workOutsideClinic.clinicName')}
              </div>
              <div className='td'>{`${values.clinicName}_${values.clinicalDepartmentName}`}</div>
            </div>

            <div className='tr'>
              <div className='th'>
                {i18n.t('main.workOutsideClinic.doctorName')}
              </div>
              <div className='td'>{values.doctorName}</div>
            </div>

            <CustomCalendarPicker
              {...formikProps}
              label={i18n.t('main.workOutsideClinic.workingDay')}
              name='date'
              onChange={val => {
                handleChange(val)
              }}
              disabled={disabled}
            />

            <ShiftFormWorkOutsideSplits
              {...formikProps}
              isMoneyPermitted={true}
              i18n={i18n}
              disabled={disabled}
            />

            <div className='tr'>
              <div className='th'>
                {i18n.t('main.workOutsideClinic.address')}
              </div>
              <div className='td'>{workOutside.address}</div>
            </div>

            <div className='tr'>
              <div className='th'>
                {i18n.t('main.workOutsideClinic.workContent')}
              </div>
              <div className='td'>{workOutside.workContent}</div>
            </div>
            {workOutside.status === 'WAITING_APPROVAL' && isProcessPermitted ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div
                  className='col-md-12 center-block '
                  style={{ marginRight: '18px' }}
                >
                  <Button
                    className='cancel-button'
                    onClick={() => {
                      setFieldValue('confirmationMessage', '却下しますか？')
                      setFieldValue('functionName', 'REJECTED')
                      setFieldValue('confirm', true)
                    }}
                    label='却下する'
                  />
                </div>
                <div className='col-md-12 center-block'>
                  <Button
                    className='reflect-button'
                    onClick={() => {
                      setFieldValue('confirmationMessage', '承認しますか？')
                      setFieldValue('functionName', 'APPROVAL')
                      setFieldValue('confirm', true)
                    }}
                    label='承認する'
                  />
                </div>
              </div>
            ) : null}
            <ConfirmationModal
              {...formikProps}
              confirmationMessage={values.confirmationMessage}
            />
          </div>
        )
      }}
    />
  )
}

export default WorkOutsideClinicDetailScene
