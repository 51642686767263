import gql from 'graphql-tag'

export const QUESTION_CATEGORY = gql`
  query QuestionCategories($where: QuestionCategoryWhereInput) {
    questionCategories(where: $where) {
      id
      content
    }
  }
`

export const QUESTION_CONNECTION = gql`
  query QUESTION_CONNECTION(
    $where: QuestionWhereInput
    $skip: Int
    $first: Int
    $orderBy: QuestionOrderByInput
  ) {
    questionsConnection(
      where: $where
      skip: $skip
      first: $first
      orderBy: $orderBy
    ) {
      edges {
        node {
          id
          category {
            id
          }
          title
          content
          createdAt
          updatedAt
          isHide
        }
      }
      aggregate(where: $where) {
        count
      }
    }
  }
`

export const ISHIDE_QUESTION = gql`
  mutation UpdateQuestion(
    $data: QuestionUpdateInput!
    $where: QuestionWhereUniqueInput!
  ) {
    updateQuestion(data: $data, where: $where) {
      id
    }
  }
`

