import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import CreateDoctorSkillScene from './CreateDoctorSkillScene'
import { useCustom } from '@medical/provider/context'
import React from 'react'
import { STAFF_DOCTOR_SKILL_CREATE } from '@medical/constant/permissions'
import { checkStaffPermission } from '@medical/libs'
import { STAFF_ME, CREATE_SKILL } from './CreateDoctorSkill.graphql'

const CreateDoctorSkill = ({ closeModal }) => {
  const [{ popup }] = useCustom()
  const isCreatePermitted = checkStaffPermission({
    functionName: STAFF_DOCTOR_SKILL_CREATE,
  })
  const [staffCreateSkill] = useMutation(CREATE_SKILL)
  const { loading, error, data } = useQuery(STAFF_ME)
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const variables = {
        name: values.skillName,
        createdBy: data.staffMe.id,
        isShow: values.skillDisplay,
      }
      await staffCreateSkill({
        variables,
      })
      popup.success('作成しました。')
      setSubmitting(false)
      closeModal()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }
  return (
    <CreateDoctorSkillScene
      onSubmit={onSubmit}
      isCreatePermitted={isCreatePermitted}
    />
  )
}

export default CreateDoctorSkill
