import './style.css'

import ConfirmationModal from '@medical/components/ConfirmationModal'
import { Checkbox, TimePicker } from 'antd'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import React, { useState } from 'react'
import * as Yup from 'yup'

const TimePerDayForm = ({
  i18n,
  onSubmit,
  clinic = {},
  clinicalDepartments,
}) => {
  const [stateData, setStateData] = useState(clinic)
  const validationSchema = Yup.object().shape({})

  return (
    <Formik
      enableReinitialize
      initialValues={{
        confirm: false,
        noteOpenTime: stateData.noteOpenTime,
        department: '',
        clinicalDepartments,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={formikProps => {
        const { setFieldValue, setTouched, validateForm, values } = formikProps
        const format = 'HH:mm'
        const { RangePicker } = TimePicker
        return (
          <div className='time-day-per-form_container'>
            {values.clinicalDepartments && values.clinicalDepartments.length > 0
              ? values.clinicalDepartments.map((item, index) => (
                  <>
                    <div className='text-bold'>{item.clinicName}</div>
                    <div className='time-rest row'>
                      <div className='label col'>
                        {i18n.t('staff.editClinic.monday')}
                      </div>
                      <div className='time-detail col-4'>
                        <div className='display-flex'>
                          <p>{i18n.t('staff.editClinic.timeLineMorning')}</p>
                          <RangePicker
                            value={[
                              item.startTimeMondayMorning
                                ? moment(
                                    new Date(
                                      item.startTimeMondayMorning.split('Z')[0]
                                    ),
                                    // format
                                    'YYYY-MM-DD'
                                  )
                                : null,
                              item.endTimeMondayMorning
                                ? moment(
                                    new Date(
                                      item.endTimeMondayMorning.split('Z')[0]
                                    ),
                                    // format
                                    'YYYY-MM-DD'
                                  )
                                : null,
                            ]}
                            format='HH:mm'
                            allowClear={false}
                            disabledHours={() => [
                              0,
                              1,
                              2,
                              3,
                              4,
                              5,
                              6,
                              14,
                              15,
                              16,
                              17,
                              18,
                              19,
                              20,
                              21,
                              22,
                              23,
                            ]}
                            placeholder={['開始時刻', '終了時刻']}
                            hideDisabledOptions
                            disabled={!item.isAvailableOnMondayMorning}
                            onChange={(value, timeString) => {
                              setFieldValue(
                                `clinicalDepartments[${index}].startTimeMondayMorning`,
                                moment(value[0])
                                  .add(9, 'hour')
                                  .toISOString()
                                // .split('+')[0]
                              )

                              setFieldValue(
                                `clinicalDepartments[${index}].endTimeMondayMorning`,
                                moment(value[1])
                                  .add(9, 'hour')
                                  .toISOString()
                                // .split('+')[0]
                              )
                            }}
                          />
                        </div>
                        <div className='display-flex'>
                          <p>{i18n.t('staff.editClinic.timeLineAfternoon')}</p>
                          <RangePicker
                            value={[
                              item.startTimeMondayAfternoon
                                ? moment(
                                    new Date(
                                      item.startTimeMondayAfternoon.split(
                                        'Z'
                                      )[0]
                                    ),
                                    format
                                  )
                                : null,
                              item.endTimeMondayAfternoon
                                ? moment(
                                    new Date(
                                      item.endTimeMondayAfternoon.split('Z')[0]
                                    ),
                                    format
                                  )
                                : null,
                            ]}
                            format={format}
                            allowClear={false}
                            disabledHours={() => [
                              0,
                              1,
                              2,
                              3,
                              4,
                              5,
                              6,
                              7,
                              8,
                              9,
                              10,
                              11,
                              12,
                            ]}
                            placeholder={['開始時刻', '終了時刻']}
                            hideDisabledOptions
                            disabled={!item.isAvailableOnMondayAfternoon}
                            onChange={value => {
                              setFieldValue(
                                `clinicalDepartments[${index}].startTimeMondayAfternoon`,
                                moment(value[0])
                                  .add(9, 'hour')
                                  .toISOString()
                                // .split('+')[0]
                              )
                              setFieldValue(
                                `clinicalDepartments[${index}].endTimeMondayAfternoon`,
                                moment(value[1])
                                  .add(9, 'hour')
                                  .toISOString()
                                // .split('+')[0]
                              )
                            }}
                          />
                        </div>
                      </div>
                      <div className='checkbox col-4'>
                        <div className='row pd-5'>
                          <div className='label'>
                            {/* {i18n.t('staff.editClinic.regularHoliday')} */}
                            {i18n.t('staff.editClinic.isOffTime')}
                          </div>
                          <div>
                            <Checkbox
                              defaultChecked={!item.isAvailableOnMondayMorning}
                              onChange={e => {
                                setFieldValue(
                                  `clinicalDepartments[${index}].isAvailableOnMondayMorning`,
                                  !e.target.checked
                                )
                              }}
                            />
                          </div>
                        </div>
                        <div className='row pd-5'>
                          <div className='label'>
                            {/* {i18n.t('staff.editClinic.regularHoliday')} */}
                            {i18n.t('staff.editClinic.isOffTime')}
                          </div>
                          <div>
                            <Checkbox
                              defaultChecked={
                                !item.isAvailableOnMondayAfternoon
                              }
                              onChange={e => {
                                setFieldValue(
                                  `clinicalDepartments[${index}].isAvailableOnMondayAfternoon`,
                                  !e.target.checked
                                )
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='time-rest row'>
                      <div className='label col-4'>
                        {i18n.t('staff.editClinic.tuesday')}
                      </div>
                      <div className='time-detail col-4'>
                        <div className='display-flex'>
                          <p>{i18n.t('staff.editClinic.timeLineMorning')}</p>
                          <RangePicker
                            value={[
                              item.startTimeTuesdayMorning
                                ? moment(
                                    new Date(
                                      item.startTimeTuesdayMorning.split('Z')[0]
                                    ),
                                    format
                                  )
                                : null,
                              item.endTimeTuesdayMorning
                                ? moment(
                                    new Date(
                                      item.endTimeTuesdayMorning.split('Z')[0]
                                    ),
                                    format
                                  )
                                : null,
                            ]}
                            format={format}
                            allowClear={false}
                            disabledHours={() => [
                              0,
                              1,
                              2,
                              3,
                              4,
                              5,
                              6,
                              14,
                              15,
                              16,
                              17,
                              18,
                              19,
                              20,
                              21,
                              22,
                              23,
                            ]}
                            placeholder={['開始時刻', '終了時刻']}
                            hideDisabledOptions
                            disabled={!item.isAvailableOnTuesdayMorning}
                            onChange={value => {
                              setFieldValue(
                                `clinicalDepartments[${index}].startTimeTuesdayMorning`,
                                moment(value[0])
                                  .add(9, 'hour')
                                  .toISOString()
                                // .split('+')[0]
                              )
                              setFieldValue(
                                `clinicalDepartments[${index}].endTimeTuesdayMorning`,
                                moment(value[1])
                                  .add(9, 'hour')
                                  .toISOString()
                                // .split('+')[0]
                              )
                            }}
                          />
                        </div>
                        <div className='display-flex'>
                          <p>{i18n.t('staff.editClinic.timeLineAfternoon')}</p>
                          <RangePicker
                            value={[
                              item.startTimeTuesdayAfternoon
                                ? moment(
                                    new Date(
                                      item.startTimeTuesdayAfternoon.split(
                                        'Z'
                                      )[0]
                                    ),
                                    format
                                  )
                                : null,
                              item.endTimeTuesdayAfternoon
                                ? moment(
                                    new Date(
                                      item.endTimeTuesdayAfternoon.split('Z')[0]
                                    ),
                                    format
                                  )
                                : null,
                            ]}
                            format={format}
                            allowClear={false}
                            disabledHours={() => [
                              0,
                              1,
                              2,
                              3,
                              4,
                              5,
                              6,
                              7,
                              8,
                              9,
                              10,
                              11,
                              12,
                            ]}
                            placeholder={['開始時刻', '終了時刻']}
                            hideDisabledOptions
                            disabled={!item.isAvailableOnTuesdayAfternoon}
                            onChange={value => {
                              setFieldValue(
                                `clinicalDepartments[${index}].startTimeTuesdayAfternoon`,
                                moment(value[0])
                                  .add(9, 'hour')
                                  .toISOString()
                                // .split('+')[0]
                              )
                              setFieldValue(
                                `clinicalDepartments[${index}].endTimeTuesdayAfternoon`,
                                moment(value[1])
                                  .add(9, 'hour')
                                  .toISOString()
                                // .split('+')[0]
                              )
                            }}
                          />
                        </div>
                      </div>
                      <div className='checkbox col-4'>
                        <div className='row pd-5'>
                          <div className='label'>
                            {/* {i18n.t('staff.editClinic.regularHoliday')} */}
                            {i18n.t('staff.editClinic.isOffTime')}
                          </div>
                          <div>
                            <Checkbox
                              defaultChecked={!item.isAvailableOnTuesdayMorning}
                              onChange={e => {
                                setFieldValue(
                                  `clinicalDepartments[${index}].isAvailableOnTuesdayMorning`,
                                  !e.target.checked
                                )
                              }}
                            />
                          </div>
                        </div>
                        <div className='row pd-5'>
                          <div className='label'>
                            {/* {i18n.t('staff.editClinic.regularHoliday')} */}
                            {i18n.t('staff.editClinic.isOffTime')}
                          </div>
                          <div>
                            <Checkbox
                              defaultChecked={
                                !item.isAvailableOnTuesdayAfternoon
                              }
                              onChange={e => {
                                setFieldValue(
                                  `clinicalDepartments[${index}].isAvailableOnTuesdayAfternoon`,
                                  !e.target.checked
                                )
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='time-rest row'>
                      <div className='label col-4'>
                        {i18n.t('staff.editClinic.wednesday')}
                      </div>
                      <div className='time-detail col-4'>
                        <div className='display-flex'>
                          <p>{i18n.t('staff.editClinic.timeLineMorning')}</p>
                          <RangePicker
                            value={[
                              item.startTimeWednesdayMorning
                                ? moment(
                                    new Date(
                                      item.startTimeWednesdayMorning.split(
                                        'Z'
                                      )[0]
                                    ),
                                    format
                                  )
                                : null,
                              item.endTimeWednesdayMorning
                                ? moment(
                                    new Date(
                                      item.endTimeWednesdayMorning.split('Z')[0]
                                    ),
                                    format
                                  )
                                : null,
                            ]}
                            format={format}
                            allowClear={false}
                            disabledHours={() => [
                              0,
                              1,
                              2,
                              3,
                              4,
                              5,
                              6,
                              14,
                              15,
                              16,
                              17,
                              18,
                              19,
                              20,
                              21,
                              22,
                              23,
                            ]}
                            placeholder={['開始時刻', '終了時刻']}
                            hideDisabledOptions
                            disabled={!item.isAvailableOnWednesdayMorning}
                            onChange={value => {
                              setFieldValue(
                                `clinicalDepartments[${index}].startTimeWednesdayMorning`,
                                moment(value[0])
                                  .add(9, 'hour')
                                  .toISOString()
                                // .split('+')[0]
                              )
                              setFieldValue(
                                `clinicalDepartments[${index}].endTimeWednesdayMorning`,
                                moment(value[1])
                                  .add(9, 'hour')
                                  .toISOString()
                                // .split('+')[0]
                              )
                            }}
                          />
                        </div>
                        <div className='display-flex'>
                          <p>{i18n.t('staff.editClinic.timeLineMorning')}</p>
                          <RangePicker
                            value={[
                              item.startTimeWednesdayAfternoon
                                ? moment(
                                    new Date(
                                      item.startTimeWednesdayAfternoon.split(
                                        'Z'
                                      )[0]
                                    ),
                                    format
                                  )
                                : null,
                              item.endTimeWednesdayAfternoon
                                ? moment(
                                    new Date(
                                      item.endTimeWednesdayAfternoon.split(
                                        'Z'
                                      )[0]
                                    ),
                                    format
                                  )
                                : null,
                            ]}
                            format={format}
                            allowClear={false}
                            disabledHours={() => [
                              0,
                              1,
                              2,
                              3,
                              4,
                              5,
                              6,
                              7,
                              8,
                              9,
                              10,
                              11,
                              12,
                            ]}
                            placeholder={['開始時刻', '終了時刻']}
                            hideDisabledOptions
                            disabled={!item.isAvailableOnWednesdayAfternoon}
                            onChange={value => {
                              setFieldValue(
                                `clinicalDepartments[${index}].startTimeWednesdayAfternoon`,
                                moment(value[0])
                                  .add(9, 'hour')
                                  .toISOString()
                                // .split('+')[0]
                              )
                              setFieldValue(
                                `clinicalDepartments[${index}].endTimeWednesdayAfternoon`,
                                moment(value[1])
                                  .add(9, 'hour')
                                  .toISOString()
                                // .split('+')[0]
                              )
                            }}
                          />
                        </div>
                      </div>
                      <div className='checkbox col-4'>
                        <div className='row pd-5'>
                          <div className='label'>
                            {/* {i18n.t('staff.editClinic.regularHoliday')} */}
                            {i18n.t('staff.editClinic.isOffTime')}
                          </div>
                          <div>
                            <Checkbox
                              defaultChecked={
                                !item.isAvailableOnWednesdayMorning
                              }
                              onChange={e => {
                                setFieldValue(
                                  `clinicalDepartments[${index}].isAvailableOnWednesdayMorning`,
                                  !e.target.checked
                                )
                              }}
                            />
                          </div>
                        </div>
                        <div className='row pd-5'>
                          <div className='label'>
                            {/* {i18n.t('staff.editClinic.regularHoliday')} */}
                            {i18n.t('staff.editClinic.isOffTime')}
                          </div>
                          <div>
                            <Checkbox
                              defaultChecked={
                                !item.isAvailableOnWednesdayAfternoon
                              }
                              onChange={e => {
                                setFieldValue(
                                  `clinicalDepartments[${index}].isAvailableOnWednesdayAfternoon`,
                                  !e.target.checked
                                )
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='time-rest row'>
                      <div className='label col-4'>
                        {i18n.t('staff.editClinic.thursday')}
                      </div>
                      <div className='time-detail col-4'>
                        <div className='display-flex'>
                          <p>{i18n.t('staff.editClinic.timeLineMorning')}</p>
                          <RangePicker
                            value={[
                              item.startTimeThursdayMorning
                                ? moment(
                                    new Date(
                                      item.startTimeThursdayMorning.split(
                                        'Z'
                                      )[0]
                                    ),
                                    format
                                  )
                                : null,
                              item.endTimeThursdayMorning
                                ? moment(
                                    new Date(
                                      item.endTimeThursdayMorning.split('Z')[0]
                                    ),
                                    format
                                  )
                                : null,
                            ]}
                            format={format}
                            allowClear={false}
                            disabledHours={() => [
                              0,
                              1,
                              2,
                              3,
                              4,
                              5,
                              6,
                              14,
                              15,
                              16,
                              17,
                              18,
                              19,
                              20,
                              21,
                              22,
                              23,
                            ]}
                            placeholder={['開始時刻', '終了時刻']}
                            hideDisabledOptions
                            disabled={!item.isAvailableOnThursdayMorning}
                            onChange={value => {
                              setFieldValue(
                                `clinicalDepartments[${index}].startTimeThursdayMorning`,
                                moment(value[0])
                                  .add(9, 'hour')
                                  .toISOString()
                                // .split('+')[0]
                              )
                              setFieldValue(
                                `clinicalDepartments[${index}].endTimeThursdayMorning`,
                                moment(value[1])
                                  .add(9, 'hour')
                                  .toISOString()
                                // .split('+')[0]
                              )
                            }}
                          />
                        </div>
                        <div className='display-flex'>
                          <p>{i18n.t('staff.editClinic.timeLineAfternoon')}</p>
                          <RangePicker
                            value={[
                              item.startTimeThursdayAfternoon
                                ? moment(
                                    new Date(
                                      item.startTimeThursdayAfternoon.split(
                                        'Z'
                                      )[0]
                                    ),
                                    format
                                  )
                                : null,
                              item.endTimeThursdayAfternoon
                                ? moment(
                                    new Date(
                                      item.endTimeThursdayAfternoon.split(
                                        'Z'
                                      )[0]
                                    ),
                                    format
                                  )
                                : null,
                            ]}
                            format={format}
                            allowClear={false}
                            disabledHours={() => [
                              0,
                              1,
                              2,
                              3,
                              4,
                              5,
                              6,
                              7,
                              8,
                              9,
                              10,
                              11,
                              12,
                            ]}
                            placeholder={['開始時刻', '終了時刻']}
                            hideDisabledOptions
                            disabled={!item.isAvailableOnThursdayAfternoon}
                            onChange={value => {
                              setFieldValue(
                                `clinicalDepartments[${index}].startTimeThursdayAfternoon`,
                                moment(value[0])
                                  .add(9, 'hour')
                                  .toISOString()
                                // .split('+')[0]
                              )
                              setFieldValue(
                                `clinicalDepartments[${index}].endTimeThursdayAfternoon`,
                                moment(value[1])
                                  .add(9, 'hour')
                                  .toISOString()
                                // .split('+')[0]
                              )
                            }}
                          />
                        </div>
                      </div>
                      <div className='checkbox col-4'>
                        <div className='row pd-5 '>
                          <div className='label'>
                            {/* {i18n.t('staff.editClinic.regularHoliday')} */}
                            {i18n.t('staff.editClinic.isOffTime')}
                          </div>
                          <div>
                            <Checkbox
                              defaultChecked={
                                !item.isAvailableOnThursdayMorning
                              }
                              onChange={e => {
                                setFieldValue(
                                  `clinicalDepartments[${index}].isAvailableOnThursdayMorning`,
                                  !e.target.checked
                                )
                              }}
                            />
                          </div>
                        </div>
                        <div className='row pd-5'>
                          <div className='label'>
                            {/* {i18n.t('staff.editClinic.regularHoliday')} */}
                            {i18n.t('staff.editClinic.isOffTime')}
                          </div>
                          <div>
                            <Checkbox
                              defaultChecked={
                                !item.isAvailableOnThursdayAfternoon
                              }
                              onChange={e => {
                                setFieldValue(
                                  `clinicalDepartments[${index}].isAvailableOnThursdayAfternoon`,
                                  !e.target.checked
                                )
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='time-rest row'>
                      <div className='label col-4'>
                        {i18n.t('staff.editClinic.friday')}
                      </div>
                      <div className='time-detail col-4'>
                        <div className='display-flex'>
                          <p>{i18n.t('staff.editClinic.timeLineMorning')}</p>
                          <RangePicker
                            value={[
                              item.startTimeFridayMorning
                                ? moment(
                                    new Date(
                                      item.startTimeFridayMorning.split('Z')[0]
                                    ),
                                    format
                                  )
                                : null,
                              item.endTimeFridayMorning
                                ? moment(
                                    new Date(
                                      item.endTimeFridayMorning.split('Z')[0]
                                    ),
                                    format
                                  )
                                : null,
                            ]}
                            format={format}
                            allowClear={false}
                            disabledHours={() => [
                              0,
                              1,
                              2,
                              3,
                              4,
                              5,
                              6,
                              14,
                              15,
                              16,
                              17,
                              18,
                              19,
                              20,
                              21,
                              22,
                              23,
                            ]}
                            placeholder={['開始時刻', '終了時刻']}
                            hideDisabledOptions
                            disabled={!item.isAvailableOnFridayMorning}
                            onChange={value => {
                              setFieldValue(
                                `clinicalDepartments[${index}].startTimeFridayMorning`,
                                moment(value[0])
                                  .add(9, 'hour')
                                  .toISOString()
                                // .split('+')[0]
                              )
                              setFieldValue(
                                `clinicalDepartments[${index}].endTimeFridayMorning`,
                                moment(value[1])
                                  .add(9, 'hour')
                                  .toISOString()
                                // .split('+')[0]
                              )
                            }}
                          />
                        </div>
                        <div className='display-flex'>
                          <p>{i18n.t('staff.editClinic.timeLineAfternoon')}</p>
                          <RangePicker
                            value={[
                              item.startTimeFridayAfternoon
                                ? moment(
                                    new Date(
                                      item.startTimeFridayAfternoon.split(
                                        'Z'
                                      )[0]
                                    ),
                                    format
                                  )
                                : null,
                              item.endTimeFridayAfternoon
                                ? moment(
                                    new Date(
                                      item.endTimeFridayAfternoon.split('Z')[0]
                                    ),
                                    format
                                  )
                                : null,
                            ]}
                            format={format}
                            allowClear={false}
                            disabledHours={() => [
                              0,
                              1,
                              2,
                              3,
                              4,
                              5,
                              6,
                              7,
                              8,
                              9,
                              10,
                              11,
                              12,
                            ]}
                            placeholder={['開始時刻', '終了時刻']}
                            hideDisabledOptions
                            disabled={!item.isAvailableOnFridayAfternoon}
                            onChange={value => {
                              setFieldValue(
                                `clinicalDepartments[${index}].startTimeFridayAfternoon`,
                                moment(value[0])
                                  .add(9, 'hour')
                                  .toISOString()
                                // .split('+')[0]
                              )
                              setFieldValue(
                                `clinicalDepartments[${index}].endTimeFridayAfternoon`,
                                moment(value[1])
                                  .add(9, 'hour')
                                  .toISOString()
                                // .split('+')[0]
                              )
                            }}
                          />
                        </div>
                      </div>
                      <div className='checkbox col-4'>
                        <div className='row pd-5'>
                          <div className='label'>
                            {/* {i18n.t('staff.editClinic.regularHoliday')} */}
                            {i18n.t('staff.editClinic.isOffTime')}
                          </div>
                          <div>
                            <Checkbox
                              defaultChecked={!item.isAvailableOnFridayMorning}
                              onChange={e => {
                                setFieldValue(
                                  `clinicalDepartments[${index}].isAvailableOnFridayMorning`,
                                  !e.target.checked
                                )
                              }}
                            />
                          </div>
                        </div>
                        <div className='row pd-5'>
                          <div className='label'>
                            {/* {i18n.t('staff.editClinic.regularHoliday')} */}
                            {i18n.t('staff.editClinic.isOffTime')}
                          </div>
                          <div>
                            <Checkbox
                              defaultChecked={
                                !item.isAvailableOnFridayAfternoon
                              }
                              onChange={e => {
                                setFieldValue(
                                  `clinicalDepartments[${index}].isAvailableOnFridayAfternoon`,
                                  !e.target.checked
                                )
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='time-rest row'>
                      <div className='label col-4'>
                        {i18n.t('staff.editClinic.saturday')}
                      </div>
                      <div className='time-detail col-4'>
                        <div className='display-flex'>
                          <p>{i18n.t('staff.editClinic.timeLineMorning')}</p>
                          <RangePicker
                            value={[
                              item.startTimeSaturdayMorning
                                ? moment(
                                    new Date(
                                      item.startTimeSaturdayMorning.split(
                                        'Z'
                                      )[0]
                                    ),
                                    format
                                  )
                                : null,
                              item.endTimeSaturdayMorning
                                ? moment(
                                    new Date(
                                      item.endTimeSaturdayMorning.split('Z')[0]
                                    ),
                                    format
                                  )
                                : null,
                            ]}
                            format={format}
                            allowClear={false}
                            disabledHours={() => [
                              0,
                              1,
                              2,
                              3,
                              4,
                              5,
                              6,
                              14,
                              15,
                              16,
                              17,
                              18,
                              19,
                              20,
                              21,
                              22,
                              23,
                            ]}
                            placeholder={['開始時刻', '終了時刻']}
                            hideDisabledOptions
                            disabled={!item.isAvailableOnSaturdayMorning}
                            onChange={value => {
                              setFieldValue(
                                `clinicalDepartments[${index}].startTimeSaturdayMorning`,
                                moment(value[0])
                                  .add(9, 'hour')
                                  .toISOString()
                                // .split('+')[0]
                              )
                              setFieldValue(
                                `clinicalDepartments[${index}].endTimeSaturdayMorning`,
                                moment(value[1])
                                  .add(9, 'hour')
                                  .toISOString()
                                // .split('+')[0]
                              )
                            }}
                          />
                        </div>
                        <div className='display-flex'>
                          <p>{i18n.t('staff.editClinic.timeLineAfternoon')}</p>
                          <RangePicker
                            value={[
                              item.startTimeSaturdayAfternoon
                                ? moment(
                                    new Date(
                                      item.startTimeSaturdayAfternoon.split(
                                        'Z'
                                      )[0]
                                    ),
                                    format
                                  )
                                : null,
                              item.endTimeSaturdayAfternoon
                                ? moment(
                                    new Date(
                                      item.endTimeSaturdayAfternoon.split(
                                        'Z'
                                      )[0]
                                    ),
                                    format
                                  )
                                : null,
                            ]}
                            format={format}
                            allowClear={false}
                            disabledHours={() => [
                              0,
                              1,
                              2,
                              3,
                              4,
                              5,
                              6,
                              7,
                              8,
                              9,
                              10,
                              11,
                              12,
                            ]}
                            placeholder={['開始時刻', '終了時刻']}
                            hideDisabledOptions
                            disabled={!item.isAvailableOnSaturdayAfternoon}
                            onChange={value => {
                              setFieldValue(
                                `clinicalDepartments[${index}].startTimeSaturdayAfternoon`,
                                moment(value[0])
                                  .add(9, 'hour')
                                  .toISOString()
                                // .split('+')[0]
                              )
                              setFieldValue(
                                `clinicalDepartments[${index}].endTimeSaturdayAfternoon`,
                                moment(value[1])
                                  .add(9, 'hour')
                                  .toISOString()
                                // .split('+')[0]
                              )
                            }}
                          />
                        </div>
                      </div>
                      <div className='checkbox col-4'>
                        <div className='row pd-5'>
                          <div className='label'>
                            {/* {i18n.t('staff.editClinic.regularHoliday')} */}
                            {i18n.t('staff.editClinic.isOffTime')}
                          </div>
                          <div>
                            <Checkbox
                              defaultChecked={
                                !item.isAvailableOnSaturdayMorning
                              }
                              onChange={e => {
                                setFieldValue(
                                  `clinicalDepartments[${index}].isAvailableOnSaturdayMorning`,
                                  !e.target.checked
                                )
                              }}
                            />
                          </div>
                        </div>
                        <div className='row pd-5'>
                          <div className='label'>
                            {/* {i18n.t('staff.editClinic.regularHoliday')} */}
                            {i18n.t('staff.editClinic.isOffTime')}
                          </div>
                          <div>
                            <Checkbox
                              defaultChecked={
                                !item.isAvailableOnSaturdayAfternoon
                              }
                              onChange={e => {
                                setFieldValue(
                                  `clinicalDepartments[${index}].isAvailableOnSaturdayAfternoon`,
                                  !e.target.checked
                                )
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='time-rest row'>
                      <div className='label col-4'>
                        {i18n.t('staff.editClinic.sunday')}
                      </div>
                      <div className='time-detail col-4'>
                        <div className='display-flex'>
                          <p>{i18n.t('staff.editClinic.timeLineMorning')}</p>
                          <RangePicker
                            value={[
                              item.startTimeSundayMorning
                                ? moment(
                                    new Date(
                                      item.startTimeSundayMorning.split('Z')[0]
                                    ),
                                    format
                                  )
                                : null,
                              item.endTimeSundayMorning
                                ? moment(
                                    new Date(
                                      item.endTimeSundayMorning.split('Z')[0]
                                    ),
                                    format
                                  )
                                : null,
                            ]}
                            format={format}
                            allowClear={false}
                            disabledHours={() => [
                              0,
                              1,
                              2,
                              3,
                              4,
                              5,
                              6,
                              14,
                              15,
                              16,
                              17,
                              18,
                              19,
                              20,
                              21,
                              22,
                              23,
                            ]}
                            placeholder={['開始時刻', '終了時刻']}
                            hideDisabledOptions
                            disabled={!item.isAvailableOnSundayMorning}
                            onChange={value => {
                              setFieldValue(
                                `clinicalDepartments[${index}].startTimeSundayMorning`,
                                moment(value[0])
                                  .add(9, 'hour')
                                  .toISOString()
                                // .split('+')[0]
                              )
                              setFieldValue(
                                `clinicalDepartments[${index}].endTimeSundayMorning`,
                                moment(value[1])
                                  .add(9, 'hour')
                                  .toISOString()
                                // .split('+')[0]
                              )
                            }}
                          />
                        </div>
                        <div className='display-flex'>
                          <p>{i18n.t('staff.editClinic.timeLineAfternoon')}</p>
                          <RangePicker
                            value={[
                              item.startTimeSundayAfternoon
                                ? moment(
                                    new Date(
                                      item.startTimeSundayAfternoon.split(
                                        'Z'
                                      )[0]
                                    ),
                                    format
                                  )
                                : null,
                              item.endTimeSundayAfternoon
                                ? moment(
                                    new Date(
                                      item.endTimeSundayAfternoon.split('Z')[0]
                                    ),
                                    format
                                  )
                                : null,
                            ]}
                            format={format}
                            allowClear={false}
                            disabledHours={() => [
                              0,
                              1,
                              2,
                              3,
                              4,
                              5,
                              6,
                              7,
                              8,
                              9,
                              10,
                              11,
                              12,
                            ]}
                            placeholder={['開始時刻', '終了時刻']}
                            hideDisabledOptions
                            disabled={!item.isAvailableOnSundayAfternoon}
                            onChange={value => {
                              setFieldValue(
                                `clinicalDepartments[${index}].startTimeSundayAfternoon`,
                                moment(value[0])
                                  .add(9, 'hour')
                                  .toISOString()
                                // .split('+')[0]
                              )
                              setFieldValue(
                                `clinicalDepartments[${index}].endTimeSundayAfternoon`,
                                moment(value[1])
                                  .add(9, 'hour')
                                  .toISOString()
                                // .split('+')[0]
                              )
                            }}
                          />
                        </div>
                      </div>
                      <div className='checkbox col-4'>
                        <div className='row pd-5'>
                          <div className='label'>
                            {/* {i18n.t('staff.editClinic.regularHoliday')} */}
                            {i18n.t('staff.editClinic.isOffTime')}
                          </div>
                          <div>
                            <Checkbox
                              defaultChecked={!item.isAvailableOnSundayMorning}
                              onChange={e => {
                                setFieldValue(
                                  `clinicalDepartments[${index}].isAvailableOnSundayMorning`,
                                  !e.target.checked
                                )
                              }}
                            />
                          </div>
                        </div>
                        <div className='row pd-5'>
                          <div className='label'>
                            {/* {i18n.t('staff.editClinic.regularHoliday')} */}
                            {i18n.t('staff.editClinic.isOffTime')}
                          </div>
                          <div>
                            <Checkbox
                              defaultChecked={
                                !item.isAvailableOnSundayAfternoon
                              }
                              onChange={e => {
                                setFieldValue(
                                  `clinicalDepartments[${index}].isAvailableOnSundayAfternoon`,
                                  !e.target.checked
                                )
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='time-rest row'>
                      <div className='label col-4'>
                        {i18n.t('staff.editClinic.holiday')}
                      </div>
                      <div className='time-detail col-4'>
                        <div className='display-flex'>
                          <p>{i18n.t('staff.editClinic.timeLineMorning')}</p>
                          <RangePicker
                            value={[
                              item.startTimeHolidayMorning
                                ? moment(
                                    new Date(
                                      item.startTimeHolidayMorning.split('Z')[0]
                                    ),
                                    format
                                  )
                                : null,
                              item.endTimeHolidayMorning
                                ? moment(
                                    new Date(
                                      item.endTimeHolidayMorning.split('Z')[0]
                                    ),
                                    format
                                  )
                                : null,
                            ]}
                            format={format}
                            allowClear={false}
                            disabledHours={() => [
                              0,
                              1,
                              2,
                              3,
                              4,
                              5,
                              6,
                              14,
                              15,
                              16,
                              17,
                              18,
                              19,
                              20,
                              21,
                              22,
                              23,
                            ]}
                            placeholder={['開始時刻', '終了時刻']}
                            hideDisabledOptions
                            disabled={!item.isAvailableOnHolidayMorning}
                            onChange={value => {
                              setFieldValue(
                                `clinicalDepartments[${index}].startTimeHolidayMorning`,
                                moment(value[0])
                                  .add(9, 'hour')
                                  .toISOString()
                                // .split('+')[0]
                              )
                              setFieldValue(
                                `clinicalDepartments[${index}].endTimeHolidayMorning`,
                                moment(value[1])
                                  .add(9, 'hour')
                                  .toISOString()
                                // .split('+')[0]
                              )
                            }}
                          />
                        </div>
                        <div className='display-flex'>
                          <p>{i18n.t('staff.editClinic.timeLineAfternoon')}</p>
                          <RangePicker
                            value={[
                              item.startTimeHolidayAfternoon
                                ? moment(
                                    new Date(
                                      item.startTimeHolidayAfternoon.split(
                                        'Z'
                                      )[0]
                                    ),
                                    format
                                  )
                                : null,
                              item.endTimeHolidayAfternoon
                                ? moment(
                                    new Date(
                                      item.endTimeHolidayAfternoon.split('Z')[0]
                                    ),
                                    format
                                  )
                                : null,
                            ]}
                            format={format}
                            allowClear={false}
                            disabledHours={() => [
                              0,
                              1,
                              2,
                              3,
                              4,
                              5,
                              6,
                              7,
                              8,
                              9,
                              10,
                              11,
                              12,
                            ]}
                            placeholder={['開始時刻', '終了時刻']}
                            hideDisabledOptions
                            disabled={!item.isAvailableOnHolidayAfternoon}
                            onChange={value => {
                              setFieldValue(
                                `clinicalDepartments[${index}].startTimeHolidayAfternoon`,
                                moment(value[0])
                                  .add(9, 'hour')
                                  .toISOString()
                                // .split('+')[0]
                              )
                              setFieldValue(
                                `clinicalDepartments[${index}].endTimeHolidayAfternoon`,
                                moment(value[1])
                                  .add(9, 'hour')
                                  .toISOString()
                                // .split('+')[0]
                              )
                            }}
                          />
                        </div>
                      </div>
                      <div className='checkbox col-4'>
                        <div className='row pd-5'>
                          <div className='label'>
                            {/* {i18n.t('staff.editClinic.regularHoliday')} */}
                            {i18n.t('staff.editClinic.isOffTime')}
                          </div>
                          <div>
                            <Checkbox
                              defaultChecked={!item.isAvailableOnHolidayMorning}
                              onChange={e => {
                                setFieldValue(
                                  `clinicalDepartments[${index}].isAvailableOnHolidayMorning`,
                                  !e.target.checked
                                )
                              }}
                            />
                          </div>
                        </div>
                        <div className='row pd-5'>
                          <div className='label'>
                            {/* {i18n.t('staff.editClinic.regularHoliday')} */}
                            {i18n.t('staff.editClinic.isOffTime')}
                          </div>
                          <div>
                            <Checkbox
                              defaultChecked={
                                !item.isAvailableOnHolidayAfternoon
                              }
                              onChange={e => {
                                setFieldValue(
                                  `clinicalDepartments[${index}].isAvailableOnHolidayAfternoon`,
                                  !e.target.checked
                                )
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))
              : null}
            <div className='tr'>
              <div className='th' style={{ width: '18%' }}>
                {i18n.t('staff.treatmentResult.noteOpenTime')}
              </div>
              <div className='td'>
                <InputText
                  value={values.noteOpenTime}
                  placeholder='例:  午前の受付は12:00まで、午後の受付は20:00までです'
                  classDisabled
                  onChange={e => {
                    // setStateData({
                    //   ...stateData,
                    //   noteOpenTime: e.target.value,
                    // })

                    setFieldValue('noteOpenTime', e.target.value)
                  }}
                />
              </div>
            </div>

            <div className='modal-bottom'>
              <Button
                className='reflect-button'
                onClick={() => {
                  validateForm().then(response => {
                    if (!Object.keys(response).length) {
                      setFieldValue('confirm', true)
                    } else {
                      setTouched(response)
                    }
                  })
                }}
                label={i18n.t('staff.editClinic.reflect')}
              />
            </div>
            <ConfirmationModal
              {...formikProps}
              confirmationMessage={i18n.t(
                'staff.editClinic.updateConfirmation'
              )}
            />
          </div>
        )
      }}
    />
  )
}

export default TimePerDayForm
