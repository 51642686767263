import { Checkbox } from 'antd'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import React from 'react'
import { useTranslation } from 'react-i18next'

const ConfirmationModal = ({
  values,
  isSubmitting,
  handleSubmit,
  handleNoClick,
  setFieldValue,
  confirmationMessage,
  children,
  visibleKey = 'confirm',
  isSendMail,
  createIndicator = false,
  statusAccepted = false,
  isUpdate = false,
  isDeleteAction,
  isStaffUpdateAddressClinic,
  doctorUpdate,
  customBody,
  isCreateAvailableShift,
}) => {
  const { i18n } = useTranslation()
  const noClick = () => setFieldValue(visibleKey, false)
  const addressExits = values.homeAddress?.address1 ? true : false
  const changeStatusAndTransportOnly = !doctorUpdate
    ? values.doctorStatus &&
      values.doctorStatus.length > 0 &&
      values.doctorStatus[0] === 'ACCEPTED' &&
      !values.isSendMail
    : false
  const contentUpdateProfile = (
    <div>
      <p>
        ※住所情報の更新がありました。
        <br />
        新住所：{values.homeAddress?.address1}
        {values.homeAddress?.address2}
      </p>
      <>
        <p>
          通勤経路登録情報も上書きしますか？ <br />
          通勤経路新規・変更登録メニューから確認ができます。
        </p>
        <p>
          適用開始日：
          {moment()
            .startOf('months')
            .format('LL')}
        </p>

        <>
          <Checkbox
            onChange={event => {
              setFieldValue('isUpdateAddress', event.target.checked)
            }}
          >
            マイカー通勤距離更新
          </Checkbox>
          <br />
          <Checkbox
            onChange={event => {
              setFieldValue('isCreateTransportationCost', event.target.checked)
            }}
          >
            通勤経路登録更新
          </Checkbox>
        </>
      </>
    </div>
  )
  const content = (
    <>
      <div>
        <p>
          ※以下の住所情報を元に通勤経路情報を作成しますか？
          <br />
          通勤経路新規・変更登録メニューから確認できます。
        </p>
        <p>
          住所：{values.homeAddress?.address1}
          {values.homeAddress?.address2}
        </p>
        {!doctorUpdate && (
          <>
            <p>
              適用開始日：
              {moment()
                .startOf('months')
                .format('LL')}
            </p>
            <Checkbox
              inputId='isUpdateAddress'
              onChange={event => {
                setFieldValue('isUpdateAddress', event.target.checked)
              }}
            >
              マイカー通勤距離更新
            </Checkbox>
            <br />
            <Checkbox
              inputId='isCreateTransportationCost'
              onChange={event => {
                setFieldValue(
                  'isCreateTransportationCost',
                  event.target.checked
                )
              }}
            >
              通勤経路登録更新
            </Checkbox>
          </>
        )}
      </div>
    </>
  )
  const footer = (
    <>
      <div>
        <Button
          type='submit'
          icon={`pi pi-${isSubmitting ? 'spinner pi-spin' : 'check'}`}
          label={
            statusAccepted
              ? i18n.t('doctor.uploadStatus.btnChangeStatusAndSendmail1')
              : `${!doctorUpdate ? '医師' : ''}プロフィールの${
                  isUpdate ? '更新' : '作成'
                }`
          }
          disabled={isSubmitting}
          onClick={handleSubmit}
        />
      </div>
      {isSubmitting && values.isCreateTransportationCost && (
        <div
          className='asterisk-label-head'
          style={{ textAlign: 'start', marginTop: '10px' }}
        >
          {i18n.t('doctor.uploadStatus.wait')}
        </div>
      )}
    </>
  )
  return (
    <Dialog
      header={i18n.t('main.confirmation')}
      visible={values[visibleKey]}
      className='p-dialog'
      footer={
        isDeleteAction ? (
          <>
            <Button
              type='submit'
              disabled={isSubmitting}
              onClick={handleSubmit}
              icon={
                !changeStatusAndTransportOnly &&
                `pi pi-${isSubmitting ? 'spinner pi-spin' : 'check'}`
              }
              label='設定を削除する'
            />
            <Button
              type='submit'
              disabled={isSubmitting}
              onClick={handleNoClick || noClick}
              label={i18n.t('main.no')}
              icon={!changeStatusAndTransportOnly && 'pi pi-times'}
            />
          </>
        ) : (
          <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {isSendMail && (
                <Checkbox
                  style={{ marginLeft: '0px' }}
                  onChange={() => {
                    setFieldValue('isSendMail', !values.isSendMail)
                  }}
                >
                  {i18n.t('staff.workSchedule.isSendMail')}
                </Checkbox>
              )}
              {isCreateAvailableShift && (
                <div
                  style={{
                    color: '#fb5c40',
                    marginLeft: '0px',
                    display: 'flex',
                  }}
                >
                  <Checkbox
                  style={{marginRight: '10px'}}
                    onChange={() => {
                      setFieldValue(
                        'isDoubleRecruitment',
                        !values.isDoubleRecruitment
                      )
                    }}
                    checked={values.isDoubleRecruitment}
                  ></Checkbox>

                  {i18n.t('staff.createShift.submitConfirmation2')}
                </div>
              )}
            </div>
            {(createIndicator && addressExits) || statusAccepted || isUpdate ? (
              footer
            ) : (
              <div>
                {isStaffUpdateAddressClinic && (
                  <span
                    style={{ float: 'left' }}
                    className='asterisk-label-head'
                  >
                    クリック住所変更の場合、交通費更新についてシステム管理者へお問合せください。
                  </span>
                )}

                <Button
                  type='submit'
                  label={
                    changeStatusAndTransportOnly
                      ? i18n.t('doctor.uploadStatus.btnChangeStatusOnly')
                      : i18n.t('main.yes')
                  }
                  icon={
                    !changeStatusAndTransportOnly &&
                    `pi pi-${isSubmitting ? 'spinner pi-spin' : 'check'}`
                  }
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                />
                {!changeStatusAndTransportOnly && (
                  <Button
                    label={i18n.t('main.no')}
                    icon='pi pi-times'
                    onClick={handleNoClick || noClick}
                    className='p-button-secondary'
                    disabled={isSubmitting}
                  />
                )}
                {isSubmitting &&
                  values.isCreateTransportationCost &&
                  changeStatusAndTransportOnly && (
                    <div
                      className='asterisk-label-head'
                      style={{ textAlign: 'start', marginTop: '10px' }}
                    >
                      {i18n.t('doctor.uploadStatus.wait')}
                    </div>
                  )}
              </div>
            )}
          </>
        )
      }
      closable={!isSubmitting}
      onHide={handleNoClick || noClick}
    >
      {children}
      {confirmationMessage}
      {customBody}
      {(createIndicator && addressExits) ||
      statusAccepted ||
      changeStatusAndTransportOnly
        ? content
        : ''}
      {isUpdate ? contentUpdateProfile : ''}
    </Dialog>
  )
}

export default ConfirmationModal
