import gql from 'graphql-tag'

export const DOCTOR_SIGNUP = gql`
  mutation DOCTOR_SIGN_UP(
    $lastname: String!
    $lastnameKana: String!
    $firstname: String!
    $firstnameKana: String!
    $gender: Gender
    $birthday: DateTime!
    $medicalLicenseNumber: String
    $departments: [DepartmentName!]
    $email: String!
    $phoneNumber: String!
    $stateOrRegion: String
    $address1: String
    $zipCode: String
    $placeOfWork: String!
    $graduationUniversity: String!
    $graduationUniversityYear: Int!
    $obtainDoctorLicenseYear: Int!
    $introducedBy: String
    $professionalDescription: String
    $reasonKnowWebsites: [ReasonKnowWebsite!]
    $otherReasonKnowWebsite: String
    $careerHistory: [CareerHistoryForCreate!]
    $skillSet: [ID!]
    $interviewTime: String
  ) {
    doctorSignUp(
      lastname: $lastname
      lastnameKana: $lastnameKana
      firstname: $firstname
      firstnameKana: $firstnameKana
      birthday: $birthday
      gender: $gender
      email: $email
      medicalLicenseNumber: $medicalLicenseNumber
      phoneNumber: $phoneNumber
      stateOrRegion: $stateOrRegion
      address1: $address1
      zipCode: $zipCode
      placeOfWork: $placeOfWork
      departments: $departments
      graduationUniversity: $graduationUniversity
      graduationUniversityYear: $graduationUniversityYear
      obtainDoctorLicenseYear: $obtainDoctorLicenseYear
      introducedBy: $introducedBy
      professionalDescription: $professionalDescription
      reasonKnowWebsites: $reasonKnowWebsites
      otherReasonKnowWebsite: $otherReasonKnowWebsite
      careerHistory: $careerHistory
      skillSet: $skillSet
      interviewTime: $interviewTime
    ) {
      id
    }
  }
`

export const CHECK_DOCTOR_REGISTER = gql`
  mutation StaffCheckDoctor(
    $tokenResponse: String!
    $lastnameKana: String!
    $firstnameKana: String!
    $firstname: String!
    $lastname: String!
    $email: String!
    $birthday: DateTime!
    $medicalLicenseNumber: String
  ) {
    staffCheckDoctor(
      tokenResponse: $tokenResponse
      lastnameKana: $lastnameKana
      firstnameKana: $firstnameKana
      firstname: $firstname
      lastname: $lastname
      email: $email
      birthday: $birthday
      medicalLicenseNumber: $medicalLicenseNumber
    )
  }
`
