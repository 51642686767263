/* eslint-disable no-nested-ternary */
import BreadCrumb from '@medical/components/Breadcrumb'
import { dateValidation } from '@medical/libs'
import numeralCurrency from '@medical/libs/numeralCurrency'
import styles from '@medical/pages/staff/MonthlyAvailableShift/Style'
import Router from '@medical/routes/router'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { MultiSelect } from 'primereact/multiselect'
import { Paginator } from 'primereact/paginator'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { waitFor } from '@medical/libs'
import Axios from 'axios'
import {
  DOWNLOAD_CSV_MASS_RECRUITMENT,
  DOWNLOAD_CSV_MONTHLY_AVAILABLE_SHIFT,
  DOWNLOAD_CSV_UNCONFIRM_SHIFT,
} from '@medical/constant'

const AvailableShiftListScene = ({
  i18n,
  availableShiftLists,
  onDropdownChange,
  clinicalDepartmentIds,
  clinicalDepartments,
  currentDate,
  onSortHandle,
  urlDownloadcsvAvailableShifts,
  status,
  type,
  rowsPerPage,
  page,
  count,
  onPageChange,
  urlDownloadUnconfirmed,
  socket,
  progress,
  setProgress,
  removeProgress,
  handleFetchSatisfieldShiftDownload,
  handleFetchUnfulfilledShiftDownload,
  handleDownloadUnfilledTimeWorkSchedule,
  handleDownloadRecruitmentShift,
}) => {
  const recruitmentShiftsTypes = [
    {
      value: 'SINGLE',
      description: '個別作成',
    },
    {
      value: 'PERIODICAL',
      description: '定期作成',
    },
  ]
  const postStatuses = [
    {
      value: 'PUBLISHED',
      description: '掲載',
    },
    {
      value: 'UN_PUBLISHED',
      description: '未掲載',
    },
  ]

  const statusState = postStatuses.find(e => e.value === status)
  const shiftTypeState = recruitmentShiftsTypes.find(e => e.value === type)
  const [statusFilter, setStatusFilter] = useState(statusState || {})
  const [shiftTypeFilter, setShiftTypeFilter] = useState(shiftTypeState || {})
  const date = moment(currentDate)
  const prevMonth = moment(date).subtract(1, 'month')
  const nextMonth = moment(date).add(1, 'month')
  const isPreviousDisabled =
    date.isSameOrBefore(moment().subtract(1, 'years'), 'month') &&
    date.isBefore(
      moment()
        .subtract(6, 'months')
        .endOf('month')
    )

  const isNextDisabled =
    dateValidation({
      year: nextMonth.year(),
      month: nextMonth.month() + 1,
    }) && date.isSameOrAfter(moment().add(6, 'quarters'), 'month')

  const getParams = (statusParam, typeParam) => {
    const clinicalParams =
      typeof clinicalDepartmentIds === 'object'
        ? clinicalDepartmentIds.join('&')
        : clinicalDepartmentIds
    const params = {}
    if (statusParam) params.status = statusParam.value
    if (typeParam) params.type = typeParam.value
    if (clinicalDepartmentIds.length !== 0)
      params.clinicalDepartmentIds = clinicalParams
    return params
  }
  const hasProgress =
    Object.keys(progress).includes(DOWNLOAD_CSV_MONTHLY_AVAILABLE_SHIFT) ||
    Object.keys(progress).includes(DOWNLOAD_CSV_UNCONFIRM_SHIFT) ||
    Object.keys(progress).includes(DOWNLOAD_CSV_MASS_RECRUITMENT)
  useEffect(() => {
    if (socket && hasProgress) {
      socket.on(DOWNLOAD_CSV_MONTHLY_AVAILABLE_SHIFT, data => {
        if (data?.percent) {
          setProgress({
            progress: DOWNLOAD_CSV_MONTHLY_AVAILABLE_SHIFT,
            label: '募集シフトCSVダウンロード中',
            percent: Number(data.percent),
          })
        }
      })
      socket.on(DOWNLOAD_CSV_UNCONFIRM_SHIFT, data => {
        if (data?.percent) {
          setProgress({
            progress: DOWNLOAD_CSV_UNCONFIRM_SHIFT,
            label: '半未充足シフトcsvダウンロード中',
            percent: Number(data.percent),
          })
        }
      })
      socket.on(DOWNLOAD_CSV_MASS_RECRUITMENT, data => {
        if (data?.percent) {
          setProgress({
            progress: DOWNLOAD_CSV_MASS_RECRUITMENT,
            label: '未充足シフトcsvダウンロード中',
            percent: Number(data.percent),
          })
        }
      })
    }
  }, [socket, hasProgress])
  return (
    <React.Fragment>
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('main.availableShiftList')}
          <BreadCrumb
            items={[
              {
                to: Router.get(Router.staffMonthlyAvailableShifts, {
                  year: date.year(),
                  month: date.month() + 1,
                }),
                title: moment(date).format('YYYY年MM月'),
              },
              { title: i18n.t('main.availableShiftList') },
            ]}
          />
        </div>

        <div>
          <div
            className='staff-buttons'
            style={{
              marginTop: '50px',
              display: 'flex',
              alignItems: 'flex-start',
            }}
          >
           
           <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginRight: '20px',
              }}
            >
              <Button
                icon='pi pi-download'
                className='p-button-gray'
                label='募集立てシフトCSVダウンロード'
                onClick={async () => {
                  handleDownloadRecruitmentShift()
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginRight: '20px',
              }}
            >
              <Button
                icon='pi pi-download'
                className='p-button-gray'
                label='未充足時間CSVダウンロード'
                onClick={async () => {
                  handleDownloadUnfilledTimeWorkSchedule()
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginRight: '20px',
              }}
            >
              <Button
                icon='pi pi-download'
                className='p-button-normal-empty'
                label='未充足シフトcsvダウンロード'
                onClick={async () => {
                  handleFetchSatisfieldShiftDownload()
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginRight: '20px',
              }}
            >
              <Button
                icon='pi pi-download'
                className='p-button-normal-empty'
                label='半未充足シフトcsvダウンロード'
                onClick={async () => {
                  handleFetchUnfulfilledShiftDownload()
                }}
              />
            </div>
            <div>
              {' '}
              <Button
                icon='pi pi-download'
                className='p-button-normal-empty'
                label='募集シフトCSVダウンロード'
                onClick={async () => {
                  setProgress({
                    progress: DOWNLOAD_CSV_MONTHLY_AVAILABLE_SHIFT,
                    label: '募集シフトCSVダウンロード中',
                    percent: 0,
                  })
                  try {
                    const { status, data: urlS3 } = await Axios({
                      method: 'GET',
                      url: urlDownloadcsvAvailableShifts,
                    })
                    await waitFor(3000)
                    if (status === 204) {
                      setProgress({
                        progress: DOWNLOAD_CSV_MONTHLY_AVAILABLE_SHIFT,
                        label: '募集シフトCSVダウンロード中',
                        msg: i18n.t('main.msgDownloadCSVStatusError'),
                      })
                      await waitFor(3000)
                    } else {
                      setProgress({
                        progress: DOWNLOAD_CSV_MONTHLY_AVAILABLE_SHIFT,
                        percent: 100,
                        url: urlS3,
                      })
                    }
                  } catch (err) {
                    setProgress({
                      progress: DOWNLOAD_CSV_MONTHLY_AVAILABLE_SHIFT,
                      label: '募集シフトCSVダウンロード中',
                      msg: i18n.t('main.msgDownloadCSVStatusError'),
                    })
                    await waitFor(3000)
                  }
                  removeProgress(DOWNLOAD_CSV_MONTHLY_AVAILABLE_SHIFT)
                }}
              />
            </div>
          </div>
          <div style={{ marginTop: '12px' }}>
            <span style={{ color: '#f03a6c' }}>
            ※募集立て・未充足時間CSVは仮設置です。重いので時間をおいてダウンロードしてください<br/>
            ※現時点で未充足、半未充足となっている募集シフトを出力します
            </span>
          </div>
        </div>
      </div>
      <div className='container is-max'>
        <div className='box'>
          <div style={styles.header}>
            <Link
              to={Router.get(Router.staffAvailableShiftList, {
                year: prevMonth.year(),
                month: prevMonth.month() + 1,
              })}
              onClick={isPreviousDisabled ? e => e.preventDefault() : undefined}
            >
              <Button icon='pi pi-chevron-left' disabled={isPreviousDisabled} />
            </Link>
            <div style={styles.title}>
              {moment(currentDate).format('YYYY年MM月')}
            </div>
            <Link
              to={Router.get(Router.staffAvailableShiftList, {
                year: nextMonth.year(),
                month: nextMonth.month() + 1,
              })}
              onClick={isNextDisabled ? e => e.preventDefault() : undefined}
            >
              <Button icon='pi pi-chevron-right' disabled={isNextDisabled} />
            </Link>
          </div>

          <div className='search-parent'>
            <MultiSelect
              value={clinicalDepartments.filter(({ id }) =>
                clinicalDepartmentIds.includes(id)
              )}
              fixedPlaceholder
              options={clinicalDepartments}
              optionLabel='clinicName'
              onChange={onDropdownChange}
              filter
              placeholder={i18n.t('main.chooseClinic')}
            />
            <Dropdown
              value={statusFilter}
              name='statusValue'
              optionLabel='description'
              valueLabel='value'
              options={postStatuses}
              placeholder={i18n.t('main.postStatus')}
              onChange={e => {
                setStatusFilter(e.value)
              }}
              style={{ width: '50px', marginLeft: '20px' }}
            />

            <Dropdown
              value={shiftTypeFilter}
              name='shiftTypeValue'
              optionLabel='description'
              valueLabel='value'
              options={recruitmentShiftsTypes}
              placeholder={i18n.t('main.recruitmentShiftsType')}
              onChange={e => {
                setShiftTypeFilter(e.value)
              }}
              style={{ width: '50px', marginLeft: '20px' }}
            />

            <Link
              to={{
                pathname: Router.availableShiftLists,
                search: queryString.stringify(
                  getParams(statusFilter, shiftTypeFilter)
                ),
              }}
              style={{ marginLeft: '20px' }}
            >
              <Button label={i18n.t('main.search')} icon='pi pi-search' />
            </Link>
            {Object.keys(statusFilter).length !== 0 ||
            Object.keys(shiftTypeFilter).length !== 0 ? (
              <Link
                to={{
                  pathname: Router.availableShiftLists,
                }}
              >
                <Button
                  icon='pi pi-times'
                  className='p-button-secondary'
                  style={{ marginLeft: '20px' }}
                />
              </Link>
            ) : null}
          </div>
          <Formik
            initialValues={{
              startTime: false,
            }}
            render={formikProps => {
              const { setFieldValue, values } = formikProps
              return (
                <div className='overflow-container'>
                  <table className='table-workSchedule'>
                    <thead>
                      <tr>
                        <th width='12%'>
                          {i18n.t('main.availableShiftCreatedAt')}
                        </th>
                        <th width='12%'>{i18n.t('main.clinicName')}</th>
                        <th width='12%'>{i18n.t('main.departmentName')}</th>
                        <th width='12%'>
                          {i18n.t('main.availableShiftDate')}
                          <Button
                            style={{
                              border: 'none',
                              backgroundColor: 'transparent',
                              color: '#686868',
                            }}
                            icon={
                              !values.startTime
                                ? 'pi pi-sort-down'
                                : 'pi pi-sort-up'
                            }
                            onClick={() => {
                              if (!values.startTime) {
                                setFieldValue('startTime', true)
                              } else {
                                setFieldValue('startTime', false)
                              }
                              onSortHandle({ sortField: 'startTime' })
                            }}
                          />
                        </th>
                        <th width='8%'>
                          {i18n.t('main.availableShiftStartTime')}
                        </th>
                        <th width='8%'>
                          {i18n.t('main.availableShiftEndTime')}
                        </th>
                        <th width='10%'>
                          {i18n.t('main.availableShiftSalary')}
                        </th>
                        <th width='7%'>{i18n.t('main.postStatus')}</th>
                        <th width='10%'>
                          {i18n.t('main.recruitmentShiftsType')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {availableShiftLists.length > 0 ? (
                        availableShiftLists
                          .filter(availableShift =>
                            clinicalDepartmentIds.length === 0
                              ? true
                              : clinicalDepartmentIds.includes(
                                  availableShift.clinicalDepartmentId
                                )
                          )
                          .map(as => (
                            <tr key={as.id}>
                              <>
                                <td>
                                  {moment(as.createdAt).format(
                                    'YYYY/MM/DD HH:mm'
                                  )}
                                </td>
                                <td>{as.clinicName}</td>
                                <td>{as.clinicalDepartmentName}</td>
                                <td>
                                  {moment(as.startTime)
                                    .startOf('day')
                                    .format('YYYY年MM月DD日(ddd)')}
                                </td>
                                <td>{moment(as.startTime).format('HH:mm')}</td>
                                <td>{moment(as.endTime).format('HH:mm')}</td>
                                <td>{numeralCurrency(as.dailySalary)}</td>
                                <td>{as.isPublished ? '掲載' : '未掲載'}</td>
                                <td>
                                  {as.recruitmentShiftsType === 'SINGLE'
                                    ? '個別作成'
                                    : '定期作成'}
                                </td>
                              </>
                            </tr>
                          ))
                      ) : (
                        <tr>
                          <td colSpan={7}>{i18n.t('main.noWorkSchedule')}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )
            }}
          />
          <Paginator
            rows={rowsPerPage}
            totalRecords={count}
            first={(page - 1) * rowsPerPage}
            onPageChange={onPageChange}
            leftContent={
              <div className='paginator-total'>
                {i18n.t('main.paginationTotal')}: {count}
              </div>
            }
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default AvailableShiftListScene
