/* eslint-disable no-nested-ternary */
import { useMutation } from '@apollo/react-hooks'
import { ProgressSpinner } from '@medical/components'
import BreadCrumb from '@medical/components/Breadcrumb'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import { GET_POST_PRE_SIGNED_URL } from '@medical/components/Forms/UploadImage/UploadImage.graphql'
import { PreviewImgDialog } from '@medical/components/PreviewImgDialog'
import {
  ACCEPTED,
  MAX_FILE_SIZE,
  REJECTED,
  WAITING_FOR_APPROVAL,
} from '@medical/constant'
import types from '@medical/constant/typeTransports'
import { checkIsPdf } from '@medical/constant/utilities'
import numeralCurrency from '@medical/libs/numeralCurrency'
import uploadImage from '@medical/libs/uploadImage'
import { DOWNLOAD_IMG } from '@medical/pages/staff/MovingExpenses/MovingExpensesOther/ListMovingExpensesOther.graphql'
import { DatePicker, Input, InputNumber, Modal, Tabs } from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import { ErrorMessage, FieldArray, Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import Dropzone from 'react-dropzone'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'

import TransportPeriodicSceneMobile from './TransportPeriodicSceneMobile'

const TransportPeriodicScene = ({
  i18n,
  onSubmit,
  periodicData,
  typeSettingData,
  downloadFileSubmit,
  confirmationMessage,
  clinic,
  popup,
}) => {
  const newTypes = typeSettingData.map(item => ({
    value: item.type && item.type,
    description: item.type,
    maxCost: item.maxCost ? item.maxCost : undefined,
  }))
  const { TabPane } = Tabs
  const [visible, setvisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [previewImgModal, setPreviewImgModal] = useState(false)
  const [isDownloadFile, setIsDownloadFile] = useState(false)
  const [billImage, setBillImage] = useState()
  const [dataImg, setDataImg] = useState()
  const [downloadImg] = useMutation(DOWNLOAD_IMG)
  const [getPostPreSignedUrl] = useMutation(GET_POST_PRE_SIGNED_URL)

  useEffect(() => {
    if (billImage) {
      const downloadFile = async () => {
        try {
          const {
            data: { downloadFile: fileURL },
          } = await downloadImg({
            variables: {
              key: billImage,
            },
          })
          if (checkIsPdf(billImage)) {
            window.open(fileURL.toString(), '_blank')
          } else {
            setPreviewImgModal(true)
          }
          setDataImg(fileURL.toString())
          setIsDownloadFile(false)
        } catch (error) {
          popup.error(i18n.t('main.downloadFileError'))
        }
      }
      downloadFile()
    }
  }, [billImage, isDownloadFile])
  const showModal = () => {
    setvisible(true)
  }
  const hideModal = () => {
    setvisible(false)
  }
  const tdStatus = status => {
    switch (status) {
      case ACCEPTED:
        return <td>{i18n.t('transportApplication.accepted')}</td>
      case REJECTED:
        return (
          <td className='color-reject'>
            {i18n.t('transportApplication.rejected')}
          </td>
        )
      case WAITING_FOR_APPROVAL:
        return (
          <td className='color-waiting'>
            {i18n.t('transportApplication.waiting')}
          </td>
        )
      default:
        return <td />
    }
  }
  const validationSchema = Yup.object().shape({
    splits: Yup.array().of(
      Yup.object().shape({
        clinic: Yup.object()
          .nullable()
          .required(
            `${i18n.t('transportApplication.clinicName')}${i18n.t(
              'validation.required'
            )}`
          ),
        category: Yup.object()
          .nullable()
          .required(
            `${i18n.t('transportApplication.categoryApplication')}${i18n.t(
              'validation.required'
            )}`
          ),
        routeUrl: Yup.string()
          .url(i18n.t('validation.url'))
          .when('category', {
            is: text =>
              (text &&
                !['駐車代', '高速道路代', '宿泊代', 'その他'].includes(
                  text.value
                )) ||
              text === null,
            then: Yup.string().required(
              `${i18n.t('transportApplication.routeURL')}${i18n.t(
                'validation.required'
              )}`
            ),
          }),
        checkMaxCost: Yup.boolean()
          .test('checkMaxCost', '交通費は上限金額を超えています。', val => !val)
          .when('cost', {
            is: cost => cost === '' || cost === null || cost === undefined,
            then: Yup.boolean().oneOf(
              [true],
              `${i18n.t('transportApplication.cost')}${i18n.t(
                'validation.required'
              )}`
            ),
          }),
        cost: Yup.string()
          .nullable()
          .required(
            `${i18n.t('transportApplication.cost')}${i18n.t(
              'validation.required'
            )}`
          ),
        travelSectionEnd: Yup.string()
          .trim()
          .when('category', {
            is: text =>
              (text &&
                !['駐車代', '宿泊代'].includes(
                  text.value
                )) ||
              text === null,
            then: Yup.string().required(
              `${i18n.t('transportApplication.travelSection')}${i18n.t(
                'validation.required'
              )}`
            ),
          }),

        travelSectionStart: Yup.string()
          .trim()
          .when('category', {
            is: text =>
              (text &&
                !['駐車代', '宿泊代'].includes(
                  text.value
                )) ||
              text === null,
            then: Yup.string().required(
              `${i18n.t('transportApplication.travelSection')}${i18n.t(
                'validation.required'
              )}`
            ),
          }),
        endTime: Yup.string().required(
          `${i18n.t('transportApplication.date')}${i18n.t(
            'validation.required'
          )}`
        ),
        startTime: Yup.string().required(
          `${i18n.t('transportApplication.date')}${i18n.t(
            'validation.required'
          )}`
        ),
        comment: Yup.string()
          .nullable()
          .max(500, i18n.t('validation.comment.max', { max: 500 })),
      })
    ),
  })

  return (
    <>
      <div className='container is-medium'>
        <div className='box'>
          <div className='transport'>
            <div className='calendar-container'>
              <div className='staff-header'>
                <div className='staff-title'>
                  その他交通費精算
                  <BreadCrumb items={[{ title: 'その他交通費精算' }]} />
                </div>
              </div>
              <Tabs className='tab-transport' type='card'>
                <TabPane tab={i18n.t('doctor.menuBar.periodic')} key='1'>
                  <Formik
                    enableReinitialize
                    initialValues={{
                      confirm: false,
                      types,
                      splits: [
                        {
                          clinic: '',
                          category: '',
                          routeUrl: '',
                          startTime: '',
                          endTime: '',
                          travelSectionStart: '',
                          travelSectionEnd: '',
                          cost: '',
                          file: '',
                          comment: '',
                          maxCost: 0,
                          checkMaxCost: false,
                        },
                      ],
                    }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    render={formikProps => {
                      const {
                        setFieldValue,
                        setTouched,
                        validateForm,
                        handleChange,
                        values,
                      } = formikProps
                      if (isMobile) {
                        return (
                          <FieldArray
                            name='splits'
                            render={({ remove }) => (
                              <>
                                {values.splits.map((slipt, index) => (
                                  <TransportPeriodicSceneMobile
                                    index={index}
                                    formikProps={formikProps}
                                    showModal={showModal}
                                    visible={visible}
                                    hideModal={hideModal}
                                    remove={remove}
                                    slipt={slipt}
                                    clinic={clinic}
                                    newTypes={newTypes}
                                  />
                                ))}
                                <div style={{ textAlign: 'center' }}>
                                  <Button
                                    icon='pi pi-plus'
                                    onClick={() => {
                                      setFieldValue('splits', [
                                        ...values.splits,
                                        {
                                          category: '',
                                          routeUrl: '',
                                          startTime: '',
                                          endTime: '',
                                          travelSectionStart: '',
                                          travelSectionEnd: '',
                                          cost: '',
                                          file: '',
                                          comment: '',
                                          checkMaxCost: false,
                                        },
                                      ])
                                    }}
                                    label={i18n.t('main.add')}
                                  />
                                </div>

                                <div className='modal-bottom-right'>
                                  <Button
                                    type='button'
                                    onClick={() => {
                                      validateForm().then(response => {
                                        if (!Object.keys(response).length) {
                                          setFieldValue('confirm', true)
                                        } else {
                                          setTouched(response)
                                        }
                                      })
                                    }}
                                    className='reflect-button btn'
                                    label={i18n.t(
                                      'transportApplication.application'
                                    )}
                                  />
                                </div>
                                <ConfirmationModal
                                  {...formikProps}
                                  confirmationMessage={confirmationMessage}
                                />
                              </>
                            )}
                          />
                        )
                      }

                      return (
                        <>
                          <table
                            className='table'
                            style={{
                              marginBottom: '10px',
                              textAlign: 'center',
                            }}
                          >
                            <thead>
                              <tr className='transport'>
                                <th width='8%'>
                                  {i18n.t('transportApplication.clinicName')}
                                </th>
                                <th width='5%'>
                                  {i18n.t(
                                    'transportApplication.categoryApplication'
                                  )}
                                </th>
                                <th width='15%'>
                                  {i18n.t('transportApplication.routeURL')}
                                  <Link
                                    to='#'
                                    style={{ color: '#40a9ff' }}
                                    onClick={showModal}
                                  >
                                    ※
                                  </Link>
                                  <Modal
                                    title='経路URLについて'
                                    className='modal-antd'
                                    closable={false}
                                    visible={visible}
                                    okButtonProps={{
                                      style: {
                                        display: 'none',
                                      },
                                    }}
                                    onCancel={hideModal}
                                    cancelText={i18n.t('main.close')}
                                  >
                                    <span className='font-weight-600'>
                                      1. 電車/バスの定期券をご申請いただく場合
                                    </span>
                                    <br />
                                    以下のサイトで勤務拠点の片道の最短/最安経路を検索し、検索結果が表示されたURLをご提出ください。
                                    <br />
                                    <Link
                                      to='#'
                                      onClick={() => {
                                        window.open(
                                          'https://transit.yahoo.co.jp/',
                                          '_blank'
                                        )
                                      }}
                                    >
                                      (https://transit.yahoo.co.jp/)
                                    </Link>
                                    <br />
                                    <span className='font-weight-600'>
                                      2.
                                      拠点間移動にかかった交通費をご申請いただく場合
                                    </span>
                                    <br />
                                    以下のサイトで移動した距離の最短/最安経路を検索し、検索結果が表示されたURLをご提出ください。
                                    移動元拠点～移動先拠点まで利用する全ての交通機関を指定し、経路検索を行ってください。
                                    <br />
                                    <Link
                                      to='#'
                                      onClick={() => {
                                        window.open(
                                          'https://transit.yahoo.co.jp/',
                                          '_blank'
                                        )
                                      }}
                                    >
                                      (https://transit.yahoo.co.jp/)
                                    </Link>
                                    <br />
                                    <span className='font-weight-600'>
                                      3.
                                      タクシー/マイカー通勤/その他公共交通機関以外をご利用の場合
                                    </span>
                                    <br />
                                    以下のサイトで移動した距離を経路検索し、その検索結果のURLをご提出ください。
                                    <br />
                                    <Link
                                      to='#'
                                      onClick={() => {
                                        window.open(
                                          'https://www.google.com/maps/dir///@35.888145,139.716763,18z?hl=ja',
                                          '_blank'
                                        )
                                      }}
                                    >
                                      (https://www.google.com/maps/dir///@35.888145,139.716763,18z?hl=ja)
                                    </Link>
                                  </Modal>
                                </th>
                                <th width='10%'>
                                  {i18n.t('transportApplication.date')}
                                </th>
                                <th width='18%'>
                                  {i18n.t('transportApplication.travelSection')}
                                </th>
                                <th width='5%'>
                                  {i18n.t('transportApplication.cost')}
                                </th>
                                <th width='8%'>
                                  {i18n.t('transportApplication.receipt')}
                                </th>
                                <th width='8%'>
                                  {i18n.t('transportApplication.comment')}
                                </th>
                                <th width='5%' />
                              </tr>
                            </thead>
                            <FieldArray
                              name='splits'
                              render={({ remove }) => (
                                <tbody>
                                  {values.splits.map((slipt, index) => (
                                    <tr key={index}>
                                      <td style={{ textAlign: 'left' }}>
                                        <Dropdown
                                          {...formikProps}
                                          value={values.splits[index].clinic}
                                          name={`splits[${index}].clinic`}
                                          placeholder='クリニックを選択'
                                          optionLabel='name'
                                          valueLabel='id'
                                          options={clinic || []}
                                          onChange={e => {
                                            setFieldValue(
                                              `splits[${index}].clinic`,
                                              e.value
                                            )
                                          }}
                                        />
                                        <ErrorMessage
                                          name={`splits[${index}].clinic`}
                                          component='div'
                                          className='signup-alert'
                                        />
                                      </td>
                                      <td className='tranport'>
                                        <Dropdown
                                          {...formikProps}
                                          value={values.splits[index].category}
                                          name={`splits[${index}].category`}
                                          placeholder='申請区分を選択'
                                          optionLabel='description'
                                          valueLabel='value'
                                          options={newTypes}
                                          onChange={e => {
                                            setFieldValue(
                                              `splits[${index}].category`,
                                              e.value
                                            )
                                            setFieldValue(
                                              `splits[${index}].maxCost`,
                                              e.maxCost
                                            )
                                          }}
                                        />
                                        <ErrorMessage
                                          name={`splits[${index}].category`}
                                          component='div'
                                          className='signup-alert'
                                        />
                                      </td>

                                      <td>
                                        <Input
                                          allowClear
                                          className='color-url'
                                          placeholder='https://www.navitime.co.jp/'
                                          name={`splits[${index}].routeUrl`}
                                          value={values.splits[index].routeUrl}
                                          disabled={
                                            !!(
                                              values.splits[index].category
                                                .value === '駐車代'
                                            )
                                          }
                                          onChange={handleChange}
                                        />
                                        <ErrorMessage
                                          name={`splits[${index}].routeUrl`}
                                          component='div'
                                          className='signup-alert'
                                        />
                                      </td>
                                      <td>
                                        <DatePicker
                                          className='input-usage-period'
                                          style={{ width: '100%' }}
                                          placeholder={i18n.t(
                                            'transportApplication.date'
                                          )}
                                          format='YYYY年MM月DD日'
                                          // disabledDate={disabledDate}
                                          locale={locale}
                                          allowClear={false}
                                          value={
                                            values.splits[index].startTime
                                              ? moment(
                                                  new Date(
                                                    values.splits[
                                                      index
                                                    ].startTime
                                                  ),
                                                  'YYYY年MM月DD日'
                                                )
                                              : null
                                          }
                                          onChange={value => {
                                            setFieldValue(
                                              `splits[${index}].startTime`,
                                              moment
                                                .utc(value)
                                                .startOf('day')
                                                .toISOString()
                                            )
                                            setFieldValue(
                                              `splits[${index}].endTime`,
                                              moment
                                                .utc(value)
                                                .endOf('day')
                                                .toISOString()
                                            )
                                          }}
                                        />
                                        <ErrorMessage
                                          name={
                                            `splits[${index}].endTime` &&
                                            `splits[${index}].startTime`
                                          }
                                          component='div'
                                          className='signup-alert'
                                        />
                                      </td>
                                      <td>
                                        <Input.Group compact>
                                          <Input
                                            allowClear
                                            style={{
                                              width: '40%',
                                              textAlign: 'center',
                                            }}
                                            placeholder='東京駅'
                                            name={`splits[${index}].travelSectionStart`}
                                            value={
                                              values.splits[index]
                                                .travelSectionStart
                                            }
                                            disabled={
                                              !!(
                                                values.splits[index].category
                                                  .value === '駐車代' ||
                                                values.splits[index].category
                                                  .value === 'CAR' ||
                                                values.splits[index].category
                                                  .value === '宿泊代' ||
                                                values.splits[index].category
                                                  .value === 'ACCOMMONDATION'
                                              )
                                            }
                                            onChange={handleChange}
                                          />

                                          <Input
                                            className='site-input-split'
                                            style={{
                                              width: 30,
                                              borderLeft: 0,
                                              borderRight: 0,
                                              pointerEvents: 'none',
                                            }}
                                            placeholder='~'
                                            disabled
                                          />
                                          <Input
                                            allowClear
                                            className='site-input-right'
                                            style={{
                                              width: '40%',
                                              textAlign: 'center',
                                            }}
                                            placeholder='葛西駅'
                                            name={`splits[${index}].travelSectionEnd`}
                                            value={
                                              values.splits[index]
                                                .travelSectionEnd
                                            }
                                            onChange={handleChange}
                                            disabled={
                                              !!(
                                                values.splits[index].category
                                                  .value === '駐車代' ||
                                                values.splits[index].category
                                                  .value === '宿泊代'
                                              )
                                            }
                                          />
                                          <ErrorMessage
                                            name={
                                              `splits[${index}].travelSectionStart` &&
                                              `splits[${index}].travelSectionEnd`
                                            }
                                            component='div'
                                            className='signup-alert'
                                          />
                                        </Input.Group>
                                      </td>
                                      <td>
                                        <InputNumber
                                          {...formikProps}
                                          placeholder='￥ 1000'
                                          formatter={value =>
                                            new Intl.NumberFormat('ja-JP', {
                                              style: 'currency',
                                              currency: 'JPY',
                                            }).format(value)
                                          }
                                          allowClear
                                          disabled={
                                            !!(
                                              values.splits[index].category
                                                .value === 'CAR'
                                            )
                                          }
                                          step={10}
                                          min={0}
                                          type='tel'
                                          name={`splits[${index}].cost`}
                                          value={
                                            values.splits[index].category
                                              .value === 'CAR'
                                              ? 0
                                              : values.splits[index].cost
                                          }
                                          onChange={e => {
                                            setFieldValue(
                                              `splits[${index}].cost`,
                                              e
                                            )
                                            if (
                                              values.splits[index].category
                                                .maxCost < e
                                            ) {
                                              setFieldValue(
                                                `splits[${index}].checkMaxCost`,
                                                true
                                              )
                                            } else {
                                              setFieldValue(
                                                `splits[${index}].checkMaxCost`,
                                                false
                                              )
                                            }
                                          }}
                                        />
                                        <ErrorMessage
                                          name={`splits[${index}].checkMaxCost`}
                                          component='div'
                                          className='signup-alert'
                                        />
                                      </td>
                                      <td>
                                        {loading ? (
                                          <ProgressSpinner />
                                        ) : (
                                          <Dropzone
                                            onDrop={async files => {
                                              if (files.length > 0) {
                                                setLoading(true)
                                                setFieldValue(
                                                  `splits[${index}].slipt`,
                                                  true
                                                )
                                                const {
                                                  data: postPreSignedUrl,
                                                } = await getPostPreSignedUrl({
                                                  variables: {
                                                    fileName: files[0].name,
                                                    mimetype: files[0].type,
                                                  },
                                                })
                                                const key = await uploadImage(
                                                  i18n,
                                                  popup,
                                                  postPreSignedUrl,
                                                  files[0]
                                                )
                                                if (key) {
                                                  setFieldValue(
                                                    `splits[${index}].file`,
                                                    key
                                                  )
                                                }
                                                setLoading(false)
                                              }
                                            }}
                                            accept={[
                                              'image/jpeg',
                                              'image/png',
                                              'application/pdf',
                                            ]}
                                            maxSize={MAX_FILE_SIZE}
                                            multiple={false}
                                          >
                                            {({
                                              getRootProps,
                                              getInputProps,
                                            }) => (
                                              <div {...getRootProps({})}>
                                                <Button
                                                  label={i18n.t(
                                                    'doctor.uploadFile.submit'
                                                  )}
                                                  icon='pi pi-cloud-upload'
                                                  className='p-button-secondary sp-button'
                                                />
                                                <input {...getInputProps()} />
                                                <aside>{slipt.file}</aside>
                                              </div>
                                            )}
                                          </Dropzone>
                                        )}
                                      </td>
                                      <td>
                                        <Input.TextArea
                                          placeholder={i18n.t(
                                            'transportApplication.comment'
                                          )}
                                          name={`splits[${index}].comment`}
                                          value={values.splits[index].comment}
                                          autoSize={{
                                            minRows: 1,
                                            maxRows: 2,
                                          }}
                                          onChange={handleChange}
                                        />
                                        <ErrorMessage
                                          name={`splits[${index}].comment`}
                                          component='div'
                                          className='signup-alert'
                                        />
                                      </td>
                                      <td>
                                        <Button
                                          icon='pi pi-trash'
                                          onClick={() => {
                                            if (values.splits.length === 1) {
                                              setFieldValue(
                                                `splits[${index}].routeUrl`,
                                                ''
                                              )
                                              setFieldValue(
                                                `splits[${index}].clinic`,
                                                ''
                                              )
                                              setFieldValue(
                                                `splits[${index}].category`,
                                                ''
                                              )
                                              setFieldValue(
                                                `splits[${index}].startTime`,
                                                ''
                                              )
                                              setFieldValue(
                                                `splits[${index}].endTime`,
                                                ''
                                              )
                                              setFieldValue(
                                                `splits[${index}].travelSectionStart`,
                                                ''
                                              )
                                              setFieldValue(
                                                `splits[${index}].travelSectionEnd`,
                                                ''
                                              )
                                              setFieldValue(
                                                `splits[${index}].cost`,
                                                ''
                                              )
                                              setFieldValue(
                                                `splits[${index}].file`,
                                                ''
                                              )
                                            } else {
                                              remove(index)
                                            }
                                          }}
                                        />
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              )}
                            />
                          </table>
                          <div style={{ textAlign: 'center' }}>
                            <Button
                              icon='pi pi-plus'
                              onClick={() => {
                                setFieldValue('splits', [
                                  ...values.splits,
                                  {
                                    clinic: '',
                                    category: '',
                                    routeUrl: '',
                                    startTime: '',
                                    endTime: '',
                                    travelSectionStart: '',
                                    travelSectionEnd: '',
                                    cost: '',
                                    file: '',
                                    checkMaxCost: false,
                                  },
                                ])
                              }}
                              label={i18n.t('main.add')}
                            />
                          </div>

                          <div className='modal-bottom-right'>
                            <Button
                              type='button'
                              onClick={() => {
                                validateForm().then(response => {
                                  if (!Object.keys(response).length) {
                                    setFieldValue('confirm', true)
                                  } else {
                                    setTouched(response)
                                  }
                                })
                              }}
                              className='reflect-button btn'
                              label={i18n.t('transportApplication.application')}
                            />
                          </div>
                          <ConfirmationModal
                            {...formikProps}
                            confirmationMessage={confirmationMessage}
                          />
                        </>
                      )
                    }}
                  />
                </TabPane>
                <TabPane
                  tab={i18n.t('transportApplication.listLocalApplications')}
                  key='2'
                >
                  <Formik
                    initialValues={{
                      confirm: false,
                      key: '',
                    }}
                    onSubmit={downloadFileSubmit}
                    render={formikProps => {
                      if (isMobile) {
                        return (
                          <>
                            {periodicData.length > 0 ? (
                              periodicData.map(periodic => (
                                <div
                                  className='mobile-report'
                                  style={{ marginBottom: '20px' }}
                                >
                                  <div className='tr' key={periodic.id}>
                                    <div
                                      className='th'
                                      style={{ width: '50%' }}
                                    >
                                      {i18n.t(
                                        'transportApplication.clinicName'
                                      )}
                                    </div>
                                    <div
                                      className='td'
                                      style={{ width: '50%' }}
                                    >
                                      {periodic.clinic
                                        ? periodic.clinic.name
                                        : null}
                                    </div>
                                    <div
                                      className='th'
                                      style={{ width: '50%' }}
                                    >
                                      {i18n.t('transportApplication.status')}
                                    </div>
                                    <div
                                      className='td'
                                      style={{ width: '50%' }}
                                    >
                                      {tdStatus(periodic.status)}
                                    </div>
                                    <div
                                      className='th'
                                      style={{ width: '50%' }}
                                    >
                                      {i18n.t(
                                        'transportApplication.categoryApplication'
                                      )}
                                    </div>
                                    <div
                                      className='td'
                                      style={{ width: '50%' }}
                                    >
                                      {periodic.type}
                                    </div>
                                    <div
                                      className='th'
                                      style={{ width: '50%' }}
                                    >
                                      {i18n.t('transportApplication.routeURL')}
                                    </div>
                                    <div
                                      className='td'
                                      style={{ width: '50%', overflow: 'auto' }}
                                    >
                                      <Link
                                        to='#'
                                        onClick={() => {
                                          window.open(
                                            `${periodic.url}`,
                                            '_blank'
                                          )
                                        }}
                                      >
                                        {periodic.url}
                                      </Link>
                                    </div>
                                    <div
                                      className='th'
                                      style={{ width: '50%' }}
                                    >
                                      {i18n.t('transportApplication.date')}
                                    </div>
                                    <div
                                      className='td'
                                      style={{ width: '50%' }}
                                    >
                                      {(periodic.startTime &&
                                        moment(periodic.startTime).format(
                                          'YYYY/MM/DD'
                                        )) ||
                                        moment(periodic.endTime).format(
                                          'YYYY/MM/DD'
                                        )}
                                    </div>
                                    <div
                                      className='th'
                                      style={{ width: '50%' }}
                                    >
                                      {i18n.t(
                                        'transportApplication.travelSection'
                                      )}
                                    </div>
                                    <div
                                      className='td'
                                      style={{ width: '50%', overflow: 'auto' }}
                                    >
                                      {`${
                                        periodic.departurePlace
                                          ? `${periodic.departurePlace}   ~  `
                                          : ''
                                      }${
                                        periodic.arrivalPlace
                                          ? periodic.arrivalPlace
                                          : ''
                                      }`}
                                    </div>
                                    <div
                                      className='th'
                                      style={{ width: '50%' }}
                                    >
                                      {i18n.t('transportApplication.cost')}
                                    </div>
                                    <div
                                      className='td'
                                      style={{ width: '50%' }}
                                    >
                                      {numeralCurrency(periodic.cost)}
                                    </div>
                                    <div
                                      className='th'
                                      style={{ width: '50%' }}
                                    >
                                      {i18n.t('transportApplication.comment')}
                                    </div>
                                    <div
                                      className='td'
                                      style={{ width: '50%' }}
                                    >
                                      {periodic.comment}
                                    </div>
                                    <div
                                      className='th'
                                      style={{ width: '50%' }}
                                    >
                                      {i18n.t('transportApplication.receipt')}
                                    </div>
                                    <div
                                      className='td'
                                      style={{ width: '50%' }}
                                    >
                                      {periodic.billImage ? (
                                        <>
                                          <Button
                                            type='button'
                                            label={i18n.t('main.downloadFile')}
                                            onClick={() => {
                                              setIsDownloadFile(true)
                                              setBillImage(periodic.billImage)
                                              formikProps.setFieldValue(
                                                'key',
                                                periodic.billImage
                                              )
                                            }}
                                            icon='pi pi-info'
                                            className='p-button-secondary'
                                          />
                                          <PreviewImgDialog
                                            i18n={i18n}
                                            visible={previewImgModal}
                                            dataImage={dataImg}
                                            handleClose={() => {
                                              setPreviewImgModal(false)
                                            }}
                                          />
                                        </>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className='td'>
                                ご指定の月は交通費精算がありません。
                              </div>
                            )}
                            <div style={{ textAlign: 'left' }}>
                              <ConfirmationModal
                                {...formikProps}
                                confirmationMessage={i18n.t(
                                  'main.downloadConfirmation'
                                )}
                              />
                            </div>
                          </>
                        )
                      }
                      return (
                        <>
                          <table
                            className='table'
                            style={{
                              marginBottom: '10px',
                              textAlign: 'center',
                            }}
                          >
                            <thead>
                              <tr>
                                <th width='10%'>
                                  {i18n.t('transportApplication.clinicName')}
                                </th>
                                <th width='8%'>
                                  {i18n.t('transportApplication.status')}
                                </th>

                                <th width='8%'>
                                  {i18n.t(
                                    'transportApplication.categoryApplication'
                                  )}
                                </th>
                                <th width='15%'>
                                  {i18n.t('transportApplication.routeURL')}
                                </th>
                                <th width='18%'>
                                  {i18n.t('transportApplication.date')}
                                </th>
                                <th width='15%'>
                                  {i18n.t('transportApplication.travelSection')}
                                </th>
                                <th width='5%'>
                                  {i18n.t('transportApplication.cost')}
                                </th>
                                <th width='12%'>
                                  {i18n.t('transportApplication.comment')}
                                </th>
                                <th width='12%'>
                                  {i18n.t('transportApplication.receipt')}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {periodicData.length > 0 ? (
                                periodicData.map(periodic => (
                                  <tr key={periodic.id}>
                                    <td>
                                      {periodic.clinic
                                        ? periodic.clinic.name
                                        : null}
                                    </td>
                                    {tdStatus(periodic.status)}
                                    <td>{periodic.type}</td>
                                    <td
                                      style={{
                                        maxWidth: '250px',
                                        overflowWrap: 'break-word',
                                      }}
                                    >
                                      <Link
                                        to='#'
                                        onClick={() => {
                                          window.open(
                                            `${periodic.url}`,
                                            '_blank'
                                          )
                                        }}
                                      >
                                        {periodic.url}
                                      </Link>
                                    </td>
                                    <td>
                                      {(periodic.startTime &&
                                        moment(periodic.startTime).format(
                                          'YYYY/MM/DD'
                                        )) ||
                                        moment(periodic.endTime).format(
                                          'YYYY/MM/DD'
                                        )}
                                    </td>
                                    <td
                                      style={{
                                        maxWidth: '150px',
                                        overflowWrap: 'break-word',
                                      }}
                                    >
                                      {`${
                                        periodic.departurePlace
                                          ? `${periodic.departurePlace}   ~  `
                                          : ''
                                      }${
                                        periodic.arrivalPlace
                                          ? periodic.arrivalPlace
                                          : ''
                                      }`}
                                    </td>
                                    <td>{numeralCurrency(periodic.cost)}</td>
                                    <td>{periodic.comment}</td>
                                    {periodic.billImage ? (
                                      <>
                                        <td>
                                          <Button
                                            type='button'
                                            label={i18n.t('main.downloadFile')}
                                            onClick={() => {
                                              setIsDownloadFile(true)
                                              setBillImage(periodic.billImage)
                                              formikProps.setFieldValue(
                                                'key',
                                                periodic.billImage
                                              )
                                              formikProps.setFieldValue(
                                                'confirm',
                                                true
                                              )
                                            }}
                                            icon='pi pi-info'
                                            className='p-button-secondary'
                                          />
                                        </td>
                                        <PreviewImgDialog
                                          i18n={i18n}
                                          visible={previewImgModal}
                                          dataImage={dataImg}
                                          handleClose={() =>
                                            setPreviewImgModal(false)
                                          }
                                        />
                                      </>
                                    ) : (
                                      <td />
                                    )}
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={7}>
                                    ご指定の月は交通費精算がありません。
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </>
                      )
                    }}
                  />
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default TransportPeriodicScene
