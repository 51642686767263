/* eslint-disable no-undef */
import { ErrorComponent, NotFound, ProgressSpinner } from '@medical/components'
import { HOLIDAY_URL } from '@medical/constant'
import {
  STAFF_CREATE_SCHEDULE_AVAILABLE_SHIFTS,
  STAFF_CREATE_SHIFT,
  STAFF_PAGE_AVAILABLE_SHIFT_PUBLISHED,
} from '@medical/constant/permissions'
import { useAxios } from '@medical/hooks'
import { checkStaffPermission, thisRangeHolidays } from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import Router from '@medical/routes/router'
import moment from 'moment'
import queryString from 'query-string'
import { pathOr } from 'ramda'
import React from 'react'
import { Redirect } from 'react-router-dom'

import RecruitmentUnpublishedScene from './RecruitmentUnpublishedScene.js'

const RecruitmentUnpublished = ({
  match: {
    url,
    params: { year = moment().year(), month = moment().month() },
  },
  location: { search },
  history,
  parentPath,
}) => {
  const { clinicalDepartmentIds = [] } = queryString.parse(search)
  const [{ i18n }] = useCustom()
  const dateSearch = moment()
    .year(year)
    .month(month - 1)
    .startOf('month')
  const date = moment()
    .year(year)
    .month(month - 1)
    .endOf('month')
  const {
    loading: loadingHolidays,
    data: holidaysData,
    error: holidaysError,
  } = useAxios({
    url: HOLIDAY_URL,
    params: {
      timeMin: dateSearch.toISOString(),
      timeMax: date.toISOString(),
    },
    dependencies: month
  })
  const holidays = thisRangeHolidays({
    holidaysData: pathOr([], ['items'], holidaysData),
    startDate: dateSearch,
    endDate: date,
  })
  const isHolidays = []
  holidays.forEach(item => {
    isHolidays.push(moment(item.date).format('YYYY-MM-DD'))
  })

  const maxDate = moment()
    .add(12, 'month')
    .endOf('month')
  if (date.isBefore(moment()) || date.isAfter(maxDate)) {
    return (
      <Redirect
        to={Router.get(Router.staffRecruitmentUnpublished, {
          year: moment().year(),
          month: moment().month() + 1,
        })}
      />
    )
  }
  const isStaffPageAvailableShiftPublished = checkStaffPermission({
    functionName: STAFF_PAGE_AVAILABLE_SHIFT_PUBLISHED,
  })
  if (loadingHolidays) return <ProgressSpinner />
  if (holidaysError) return <ErrorComponent error={error} />
  if (!isStaffPageAvailableShiftPublished) return <NotFound />

  const onDropdownChange = e => {
    history.push({
      search: queryString.stringify({
        clinicalDepartmentIds: e.value.map(({ id }) => id),
      }),
    })
  }

  const isCreateSchedulePermitted = checkStaffPermission({
    functionName: STAFF_CREATE_SCHEDULE_AVAILABLE_SHIFTS,
  })
  const isCreateShiftPermitted = checkStaffPermission({
    functionName: STAFF_CREATE_SHIFT,
  })

  return (
    <RecruitmentUnpublishedScene
      i18n={i18n}
      isCreateShiftPermitted={isCreateShiftPermitted}
      isCreateSchedulePermitted={isCreateSchedulePermitted}
      date={date}
      history={history}
      parentPath={parentPath}
      url={url}
      dateSearch={dateSearch}
      isHolidays={isHolidays}
      clinicalDepartmentIds={clinicalDepartmentIds}
      onDropdownChange={onDropdownChange}
    />
  )
}

export default RecruitmentUnpublished
