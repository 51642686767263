import BreadCrumb from '@medical/components/Breadcrumb'
import ModalLink from '@medical/components/ModalLink'
import { STAFF_STATUS } from '@medical/constant'
import { calcWorkScheduleTotalHourPerDay, convertTime } from '@medical/libs'
import numeralCurrency from '@medical/libs/numeralCurrency'
import Router from '@medical/routes/router'
import moment from 'moment'
import { Button } from 'primereact/button'
import React from 'react'
import { isMobile } from 'react-device-detect'

import AcceptedWorkSchedulesListHeader from './AcceptedWorkSchedulesListHeader'
import AcceptedWorkSchedulesListSp from './AcceptedWorkSchedulesListSp'

const AcceptedWorkSchedulesListScene = ({
  i18n,
  date,
  workSchedules,
  totalHours,
  totalDays,
  totalSalary,
}) => {
  const sortedWorkSchedules = workSchedules.sort(
    (a, b) => moment(a.startTime) > moment(b.startTime)
  )
  const showTypeOverTime = status => {
    switch (status) {
      case STAFF_STATUS.WAITING_FOR_APPROVAL:
        return (
          <>
            <Button
              className='w-100'
              label={i18n.t('staff.acceptedWorkSchedulesList.pending')}
            />
          </>
        )
      case STAFF_STATUS.ACCEPTED:
        return (
          <>
            <Button
              className='p-button-secondary w-100'
              label={i18n.t('staff.acceptedWorkSchedulesList.accepted')}
            />
          </>
        )
      default:
        return (
          <>
            <Button
              className='reflect-button w-100'
              label={i18n.t('staff.acceptedWorkSchedulesList.new')}
            />
          </>
        )
    }
  }
  const today = moment().format('YYYY-MM-DD')
  if (isMobile) {
    return (
      <div className='container'>
        <div className='box'>
          <div className='schedule-calendar'>
            <div className='calendar-container'>
              <div style={{ paddingTop: '50px' }}>
                <div style={{ paddingLeft: '10px' }}>
                  <p className='asterisk-label-head'>
                    シフトキャンセル申請は詳細の中から
                    <br />
                    行なってください。
                  </p>
                </div>
                <div className='mobile-work-total'>
                  <div>
                    {' '}
                    {`${i18n.t('main.availableShiftAccepted')}${i18n.t(
                      'main.time'
                    )}${i18n.t('main.total')}`}
                  </div>
                  <div className='mobile-work-total-item'>
                    {convertTime(totalHours)}
                  </div>
                </div>
                <div className='mobile-work-total'>
                  <div>
                    {' '}
                    {`${i18n.t('main.availableShiftAccepted')}${i18n.t(
                      'main.Days'
                    )}${i18n.t('main.total')}`}
                  </div>
                  <div className='mobile-work-total-item'>{`${totalDays}日`}</div>
                </div>
                <div className='mobile-work-total'>
                  <div>{`${i18n.t('main.availableShiftAccepted')}${i18n.t(
                    'main.dailySalary'
                  )}${i18n.t('main.total')}`}</div>
                  <div className='mobile-work-total-item'>
                    {numeralCurrency(totalSalary)}
                  </div>
                </div>
                <AcceptedWorkSchedulesListHeader date={date} />
                {sortedWorkSchedules.length > 0 ? (
                  sortedWorkSchedules.map(workSchedule => (
                    <AcceptedWorkSchedulesListSp
                      workSchedule={workSchedule}
                      key={sortedWorkSchedules.id}
                      showTypeOverTime={showTypeOverTime}
                      today={today}
                    />
                  ))
                ) : (
                  <div className='mobile-report'>
                    <div className='mobile-report-title'>
                      {i18n.t('main.noAcceptedWorkSchedule')}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='container'>
      <div className='staff-header'>
        <div className='staff-title'>
          確定シフト一覧
          <BreadCrumb items={[{ title: '確定シフト一覧' }]} />
        </div>
      </div>
      <div className='box'>
        <div className='schedule-calendar'>
          <div className='calendar-container'>
            <div style={{ paddingTop: '50px' }}>
              <div style={{ textAlign: 'end' }}>
                <p className='asterisk-label-head'>
                  シフトキャンセル申請は詳細の中から行なってください。
                </p>
              </div>
              <div className='overflow-container'>
                <table className='table' style={{ marginBottom: '10px' }}>
                  <thead>
                    <tr>
                      <th width='25%'>{i18n.t('main.clinicName')}</th>
                      <th width='10%'>{i18n.t('main.departmentName')}</th>
                      <th width='17%'>{i18n.t('main.date')}</th>
                      <th width='7%'>{i18n.t('main.startTime')}</th>
                      <th width='7%'>{i18n.t('main.endTime')}</th>
                      <th width='7%'>{i18n.t('main.shiftHour')}</th>
                      <th width='10%'>{i18n.t('main.dailySalary')}</th>
                      <th width='10%'>
                        {i18n.t('staff.acceptedWorkSchedulesList.overTime')}
                      </th>
                      <th width='5%'>{i18n.t('main.descriptionDetail')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedWorkSchedules.length > 0 ? (
                      sortedWorkSchedules.map(workSchedule => {
                        const dataOverTime = workSchedule.workOvertime.filter(
                          item => !item.deletedAt
                        )
                        const workStartTime = moment(
                          workSchedule.startTime
                        ).format('YYYY-MM-DD')
                        const workEndTime = moment(workSchedule.endTime)
                        return (
                          <>
                            <tr
                              style={{ textAlign: 'center' }}
                              key={workSchedule.id}
                            >
                              <td>{`${workSchedule.clinic}`}</td>
                              <td>{workSchedule.clinicalDepartment}</td>
                              <td>
                                {moment(workSchedule.startTime).format(
                                  'LL (dd)'
                                )}
                              </td>
                              <td>
                                {moment(workSchedule.startTime).format('HH:mm')}
                              </td>
                              <td>
                                {moment(workSchedule.endTime).format('HH:mm')}
                              </td>
                              <td>
                                {calcWorkScheduleTotalHourPerDay(workSchedule)}
                              </td>
                              <td>
                                {numeralCurrency(workSchedule.dailySalary)}
                              </td>
                              <td>
                                {workEndTime > moment() ? (
                                  <Button
                                    style={{ backgroundColor: '#c8c8c8' }}
                                    className='p-button-secondary w-100'
                                    label={i18n.t(
                                      'staff.acceptedWorkSchedulesList.rejected'
                                    )}
                                  />
                                ) : (
                                  <ModalLink
                                    to={Router.get(
                                      Router.doctorAcceptedWorkSchedulesOverTime,
                                      {
                                        workScheduleId: workSchedule.id,
                                      }
                                    )}
                                  >
                                    {showTypeOverTime(
                                      dataOverTime[0]
                                        ? dataOverTime[0].status
                                        : null
                                    )}
                                  </ModalLink>
                                )}
                              </td>
                              <td>
                                <ModalLink
                                  to={Router.get(
                                    Router.doctorAcceptedWorkScheduleDetail,
                                    {
                                      workScheduleId: workSchedule.id,
                                    }
                                  )}
                                >
                                  <Button icon='pi pi-info' />
                                </ModalLink>
                              </td>
                            </tr>
                          </>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={7}>
                          {i18n.t('main.noAcceptedWorkSchedule')}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <table>
                <tbody>
                  <tr>
                    <th style={{ textAlign: 'left' }}>
                      {`${i18n.t('main.availableShiftAccepted')}${i18n.t(
                        'main.time'
                      )}${i18n.t('main.total')}`}
                    </th>
                    <td>{convertTime(totalHours)}</td>
                  </tr>
                  <tr>
                    <th style={{ textAlign: 'left' }}>
                      {`${i18n.t('main.availableShiftAccepted')}${i18n.t(
                        'main.Days'
                      )}${i18n.t('main.total')}`}
                    </th>
                    <td>{`${totalDays}日`}</td>
                  </tr>
                  <tr>
                    <th style={{ textAlign: 'left' }}>
                      {`${i18n.t('main.availableShiftAccepted')}${i18n.t(
                        'main.dailySalary'
                      )}${i18n.t('main.total')}`}
                    </th>
                    <td>{numeralCurrency(totalSalary)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <AcceptedWorkSchedulesListHeader date={date} />
          </div>
        </div>
      </div>
    </div>
  )
}
export default AcceptedWorkSchedulesListScene
