import BreadCrumb from '@medical/components/Breadcrumb'
import ModalLink from '@medical/components/ModalLink'
import Router from '@medical/routes/router'
import { Button } from 'primereact/button'
import React from 'react'

const ClinicListScene = ({
  i18n,
  edges,
  isUpdatePermitted,
  departmentNames,
}) => (
  <React.Fragment>
    <div className='staff-header'>
      <div className='staff-title'>
        {i18n.t('staff.menuBar.clinics')}
        <BreadCrumb items={[{ title: i18n.t('staff.menuBar.clinics') }]} />
      </div>
      <div
        className='staff-buttons'
        style={{
          marginTop: '50px',
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-evenly',
          width: '20%',
        }}
      >
        <ModalLink
            to={Router.get(Router.staffRegionEdit)}
        >
          <Button className='p-button-normal' label='エリア作成・編集' />
        </ModalLink>
      </div>
    </div>
    <div className='container'>
      <div className='box'>
        <table className='table'>
          <thead>
            <tr>
              <th width='30%'>{i18n.t('main.clinicName')}</th>
              <th width='20%'>{i18n.t('updateProfile.departments')}</th>
              <th width='30%'>{i18n.t('main.address')}</th>
              {isUpdatePermitted ? (
                <th width='10%'>{i18n.t('main.action')}</th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {edges.map(({ node }) => (
              <tr key={node.id}>
                <td>{node.name}</td>
                <td>
                  <div className='flex-parent'>
                    {node.clinicalDepartments
                      .filter(department => department.isDisplay)
                      .map(department => (
                        <div className='slashed-item' key={department.id}>
                          {departmentNames[department.name]}
                        </div>
                      ))}
                  </div>
                </td>
                <td>{node.address}</td>
                {isUpdatePermitted ? (
                  <td className='center'>
                    <ModalLink
                      to={Router.get(Router.staffClinicDetail, {
                        clinicId: node.id,
                      })}
                    >
                      <Button icon='pi pi-info' />
                    </ModalLink>
                  </td>
                ) : null}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </React.Fragment>
)

export default ClinicListScene
