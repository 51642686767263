import './style.css'

import {useQuery} from '@apollo/react-hooks'
import {ErrorComponent, ProgressSpinner} from '@medical/components'

import {getDetailAddressFromZipCode} from '@medical/libs/getAddressFromZipcode'
import {ErrorMessage, FieldArray, Formik} from 'formik'
import CustomCheckBox from '@medical/components/CheckBoxSignup'
import CustomDropdown from '@medical/components/DropdownForSignup'
import {generateOptions, OPTION_TYPES} from '@medical/constant/utilities'
import {Button} from 'primereact/button'
import {InputText} from 'primereact/inputtext'
import {Checkbox} from 'primereact/checkbox'
import {InputTextarea} from 'primereact/inputtextarea'
import React, {useState} from 'react'
import {phoneFirstRegExp, phoneSecondRegExp, phoneThirdRegExp, PROVINCES,} from '@medical/constant'

import * as Yup from 'yup'
import AutoComplete from '@medical/components/AutoComplete'
import moment from 'moment'
import _ from 'lodash'
import {Dropdown} from '../../../../fork/primereact/dropdown'
import {SKILL_CONNECTIONS} from './signup.grapql'
import {useCustom} from '@medical/provider/context'
import Calendar from '@medical/components/Calendar'
import {DATE_ORDER} from '@medical/pages/staff/Doctors/DoctorDetail/DoctorInterviewSchedule'
import {isMobile} from 'react-device-detect'
import {Checkbox as CheckboxAntd, Radio, Space, TimePicker} from 'antd'

const SignupComponentStep2 = ({
  handleBackStep,
  handleSubmitSignup,
  stateData,
}) => {
  const [graduationUniversityYear, setGraduationUniversityYear] = useState(
    stateData.graduationUniversityYear
      ? stateData.graduationUniversityYear
      : 2000
  )
  const [obtainDoctorLicenseYear, setObtainDoctorLicenseYear] = useState(
    stateData.obtainDoctorLicenseYear ? stateData.obtainDoctorLicenseYear : 2000
  )
  const [{ i18n, popup }] = useCustom()
  const { loading, error, data } = useQuery(SKILL_CONNECTIONS, {
    where: {
      isShow: true,
    },
  })
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  const departments = [
    {
      value: 'INTERNAL_MEDICINE',
      description: `${i18n.t('doctor.signup.internalMedicine')}`,
    },
    {
      value: 'CHILDREN_MEDICINE',
      description: `${i18n.t('doctor.signup.childrenMedicine')}`,
    },
  ]
  const isIntroduce = [
    {
      value: '1',
      description: `${i18n.t('doctor.signup.yesLabel')}`,
    },
    {
      value: '0',
      description: `${i18n.t('doctor.signup.noLabel')}`,
    },
  ]
  const years = generateOptions(OPTION_TYPES.YEAR, 100, 30)

  const listMonth = generateOptions(OPTION_TYPES.MONTH)
  const reasons = [
    {
      value: 'FLYER',
      description: `${i18n.t('doctor.signup.flyer')}`,
    },
    {
      value: 'HOMEPAGE',
      description: `${i18n.t('doctor.signup.homePage')}`,
    },
    {
      value: 'INTERNET',
      description: `${i18n.t('doctor.signup.internet')}`,
    },
    {
      value: 'OTHER',
      description: `${i18n.t('doctor.signup.other')}`,
    },
  ]

  const findIndex = (arr, value) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] === value) {
        return i
        break
      }
    }
    return 'noIndex'
  }
  return (
    <Formik
      enableReinitialize
      initialValues={{
        zipCodeFirst: stateData.zipCodeFirst,
        zipCodeSecond: stateData.zipCodeSecond,
        phoneNumberFirst: stateData.phoneNumberFirst,
        phoneNumberSecond: stateData.phoneNumberSecond,
        phoneNumberThird: stateData.phoneNumberThird,
        stateOrRegion: stateData.stateOrRegion,
        address1: stateData.address1,
        placeOfWork: stateData.placeOfWork,
        departments: stateData.departments,
        graduationUniversity: stateData.graduationUniversity,
        graduationUniversityYear: stateData.graduationUniversityYear
          ? stateData.graduationUniversityYear
          : 2000,
        obtainDoctorLicenseYear: stateData.obtainDoctorLicenseYear
          ? stateData.obtainDoctorLicenseYear
          : 2000,
        professionalDescription: stateData.professionalDescription,
        isIntroduce: stateData.isIntroduce,
        introducedBy: stateData.introducedBy,
        confirm: stateData.confirm,
        reasonKnowWebsites: stateData.reasonKnowWebsites,
        otherReasonKnowWebsite: stateData.otherReasonKnowWebsite,
        careerHistory: stateData.careerHistory,
        skillSet: stateData.skillSet,
        expectedDateInterview: stateData.expectedDateInterview,
      }}
      validationSchema={Yup.object().shape({
        placeOfWork: Yup.string().required(
          `${i18n.t('updateProfile.placeOfWork')}${i18n.t(
            'validation.required'
          )}`
        ),
        phoneNumberFirst: Yup.string()
          .matches(phoneFirstRegExp, i18n.t('main.phoneNumberNotValid'))
          .required(
            `${i18n.t('updateProfile.phoneNumber')}${i18n.t(
              'validation.required'
            )}`
          ),
        phoneNumberSecond: Yup.string()
          .matches(phoneSecondRegExp, i18n.t('main.phoneNumberNotValid'))
          .required(
            `${i18n.t('updateProfile.phoneNumber')}${i18n.t(
              'validation.required'
            )}`
          ),
        phoneNumberThird: Yup.string()
          .matches(phoneThirdRegExp, i18n.t('main.phoneNumberNotValid'))
          .required(
            `${i18n.t('updateProfile.phoneNumber')}${i18n.t(
              'validation.required'
            )}`
          ),
        zipCode: Yup.string().nullable(),
        stateOrRegion: Yup.string()
          .nullable()
          .test(
            'check exits',
            `${i18n.t('updateProfile.homeAddress.stateOrRegion')}${i18n.t(
              'validation.required'
            )}`,
            val =>
              val
                ? PROVINCES.filter(({ name_ja }) => name_ja === val).length > 0
                : true
          ),
        address1: Yup.string().nullable(),
        graduationUniversity: Yup.string().required(
          ` ${i18n.t('doctor.signup.graduationUniversityLabel')}${i18n.t(
            'validation.required'
          )}`
        ),
        departments: Yup.array()
          .of(Yup.string())
          .min(1, i18n.t('validation.departments.required'))
          .required(
            `${i18n.t('updateProfile.departments')}${i18n.t(
              'validation.required'
            )}`
          ),
        graduationUniversityYear: Yup.string().required(
          `${i18n.t('doctor.signup.graduationUniversityYearLabel')}${i18n.t(
            'validation.required'
          )}`
        ),
        obtainDoctorLicenseYear: Yup.string().required(
          `${i18n.t('doctor.signup.obtainDoctorLicenseYearsLabel')}${i18n.t(
            'validation.required'
          )}`
        ),
        professionalDescription: Yup.string().nullable(),
        // medicalLicenseNumber: Yup.string().nullable(),
        isIntroduce: Yup.string().required(
          `${i18n.t('doctor.signup.isIntroduceLabel')}${i18n.t(
            'validation.required'
          )}`
        ),
        introducedBy: Yup.string().when('isIntroduce', {
          is: '1',
          then: Yup.string().required(
            i18n.t('doctor.signup.introduceNameInput')
          ),
        }),
        reasonKnowWebsites: Yup.string(),
        otherReasonKnowWebsite: Yup.string().when(
          'reasonKnowWebsites',
          reasonKnowWebsites =>
            reasonKnowWebsites.includes('OTHER')
              ? Yup.string().required(i18n.t('doctor.signup.inputMessage'))
              : Yup.string().nullable()
        ),
        careerHistory: Yup.array().of(
          Yup.object()
            .shape({
              description: Yup.string(),
              month: Yup.string(),
              year: Yup.string(),
            })
            .test('required-fields', null, function(value) {
              const { description, month, year } = value
              const errors = {}
              if (description || month || year) {
                if (!description) {
                  errors.description = i18n.t('validation.requireDescription')
                }
                if (!month) {
                  errors.month = i18n.t('validation.requireMonth')
                }
                if (!year) {
                  errors.year = i18n.t('validation.requireYear')
                }
                if (Object.keys(errors).length > 0) {
                  return this.createError({ path: this.path, message: errors })
                }
              }
              return true
            })
        ),
        expectedDateInterview: Yup.array().of(
          Yup.object().shape({
            date: Yup.string().test(
              'is-required-or-nullable',
              '面談日付を選択してください。',
              function(value) {
                const { path } = this
                const indexMatch = path.match(/\d+/)
                const index = indexMatch ? parseInt(indexMatch[0], 10) : -1
                if (index === 0 || index === 1) {
                  return !!value
                }
                return true
              }
            ),
            expectTime: Yup.array().of(Yup.string()),
            selectTime: Yup.number().oneOf([1, 2]),
            startTime: Yup.string().test(
              'is-required-or-nullable',
              '面談時間を選択してください。',
              function(value) {
                const { path, parent } = this
                const indexMatch = path.match(/\d+/)
                const index = indexMatch ? parseInt(indexMatch[0], 10) : -1
                if (
                  parent.expectTime.length === 0 &&
                  (index === 0 || index === 1)
                ) {
                  return !!value
                }
                return true
              }
            ),
            // startTime: Yup.string().when(['expectTime', '$index'], {
            //   is: (expectTime, index) => expectTime.length === 0 && index !== 2,
            //   then: Yup.string().required('面談時間を選択してください。'),
            // }),
            // endTime: Yup.string().when(['expectTime', '$index'], {
            //   is: (expectTime, index) => expectTime.length === 0 && index !== 2,
            //   then: Yup.string().required('面談時間を選択してください。'),
            // }),
          })
        ),
      })}
      onSubmit={(values, { setSubmitting }) => {
        const { expectedDateInterview } = values
        const interviewTime = expectedDateInterview.map(date => {
          if (date.selectTime === 2) {
            return {
              date: date.date,
              expectTime: date.expectTime,
              type: 'SELECT',
            }
          } else if (date.selectTime === 1) {
            return {
              date: date.date,
              expectTime: [
                `${moment(date.startTime).format('HH:mm')}～${moment(
                  date.endTime
                ).format('HH:mm')}`,
              ],
              type: 'DEFAULT',
            }
          }
        })

        const temp = {
          ...values,
          // medicalLicenseNumber:
          //   values.medicalLicenseNumber === ''
          //     ? null
          //     : values.medicalLicenseNumber,
          professionalDescription:
            values.professionalDescription === ''
              ? null
              : values.professionalDescription,
          introducedBy: values.introducedBy === '' ? null : values.introducedBy,
          interviewTime: JSON.stringify(interviewTime),
        }
        delete temp.expectedDateInterview

        handleSubmitSignup(temp, { setSubmitting })
      }}
      render={formikProps => {
        const {
          values,
          handleSubmit,
          handleChange,
          handleBlur,
          isSubmitting,
          setFieldValue,
          touched,
          errors,
        } = formikProps
        const getHomeAddress = async zipCode => {
          const {
            status,
            administrativeArea,
            localityArea,
          } = await getDetailAddressFromZipCode({
            zipCode: zipCode,
          })
          if (status === 'OK') {
            setFieldValue('stateOrRegion', administrativeArea)
            setFieldValue('address1', localityArea)
          }
        }
        return (
          <div>
            <form>
              <div className='signup-inner'>
                <div className='signup-label required-label'>
                  {i18n.t('doctor.signup.phoneNumberLabel')}
                </div>
                <div className='signup-input'>
                  <div className='input-split-wrap'>
                    <div className='input-split'>
                      <InputText
                        className='p-inputtext p-component '
                        type='text'
                        name='phoneNumberFirst'
                        defaultValue={values.phoneNumberFirst}
                        pattern='[0-9]{3}'
                        onChange={e => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, '')
                          handleChange(e)
                        }}
                        maxLength='3'
                      />
                      <ErrorMessage
                        name='phoneNumberFirst'
                        component='div'
                        className='signup-alert'
                      />
                    </div>
                    <span>-</span>
                    <div className='input-split'>
                      <InputText
                        className='p-inputtext p-component'
                        type='text'
                        name='phoneNumberSecond'
                        defaultValue={values.phoneNumberSecond}
                        pattern='[0-9]{4}'
                        onChange={e => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, '')
                          handleChange(e)
                        }}
                        maxLength='4'
                      />
                      <ErrorMessage
                        name='phoneNumberSecond'
                        component='div'
                        className='signup-alert'
                      />
                    </div>
                    <span>-</span>
                    <div className='input-split'>
                      <InputText
                        className='p-inputtext p-component'
                        type='text'
                        name='phoneNumberThird'
                        defaultValue={values.phoneNumberThird}
                        pattern='[0-9]{4}'
                        onChange={e => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, '')
                          handleChange(e)
                        }}
                        maxLength='4'
                      />
                      <ErrorMessage
                        name='phoneNumberThird'
                        component='div'
                        className='signup-alert'
                      />
                    </div>
                  </div>
                  {/* <input
                    className='p-inputtext p-component'
                    type='text'
                    value={values.phoneNumber}
                    values={values}
                    name='phoneNumber'
                    pattern='[0-9-]*'
                    onChange={e => {
                      e.target.value = e.target.value.replace(/[^0-9-]/g, '')
                      handleChange(e)
                    }}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name='phoneNumber'
                    component='div'
                    className='signup-alert'
                  /> */}
                </div>
                <div className='row'>
                  <div className='col-6'>
                    <div className='signup-label'>
                      {i18n.t('doctor.signup.postalCodeLabel')}
                    </div>
                    <div className=' signup-input-half input-split-wrap'>
                      <InputText
                        className='p-inputtext p-component'
                        type='text'
                        name='zipCodeFirst'
                        defaultValue={values.zipCodeFirst}
                        pattern='[0-9]{3}'
                        onChange={e => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, '')
                          handleChange(e)
                          if (
                            values.zipCodeSecond &&
                            e.target.value.length === 3
                          ) {
                            getHomeAddress(
                              `${e.target.value}${values.zipCodeSecond}`
                            )
                          }
                        }}
                        maxLength='3'
                      />
                      <ErrorMessage
                        name='zipCodeFirst'
                        component='div'
                        className='signup-alert'
                      />
                      <span>-</span>
                      <InputText
                        className='p-inputtext p-component'
                        type='text'
                        name='zipCodeSecond'
                        defaultValue={values.zipCodeSecond}
                        pattern='[0-9]{4}'
                        onChange={e => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, '')
                          handleChange(e)
                          if (
                            values.zipCodeFirst &&
                            e.target.value.length === 4
                          ) {
                            getHomeAddress(
                              `${values.zipCodeFirst}${e.target.value}`
                            )
                          }
                        }}
                        maxLength='4'
                      />
                      <ErrorMessage
                        name='homeAddress.zipCodeSecond'
                        component='div'
                        className='signup-alert'
                      />
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='signup-label'>
                      {i18n.t('doctor.signup.districtLabel')}
                    </div>
                    <div className='signup-input-half '>
                      <AutoComplete
                        {...formikProps}
                        name='stateOrRegion'
                        field='name_ja'
                        allSuggestions={PROVINCES}
                        suggestionName='provinces'
                        placeholder='都道府県を選択'
                        setCurrentProvince={val => {
                          const data = PROVINCES.find(
                            ({ name_ja }) => name_ja === val
                          )

                          setFieldValue('stateOrRegion', data?.name_ja)
                        }}
                      />
                      <ErrorMessage
                        name='stateOrRegion'
                        component='div'
                        className='signup-alert'
                      />
                    </div>
                  </div>
                </div>

                <div className='signup-label'>
                  {i18n.t('doctor.signup.address1Label')}
                </div>
                <div className='signup-input'>
                  <InputText
                    values={values}
                    value={values.address1}
                    name='address1'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoCapitalize='none'
                  />
                  <ErrorMessage
                    name='address1'
                    component='div'
                    className='signup-alert'
                  />
                </div>

                <div className='signup-label required-label'>
                  {i18n.t('doctor.signup.placeOfWorkLabel')}
                </div>

                <div className='signup-input'>
                  <InputText
                    values={values}
                    value={values.placeOfWork}
                    name='placeOfWork'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoCapitalize='none'
                  />
                  <ErrorMessage
                    name='placeOfWork'
                    component='div'
                    className='signup-alert'
                  />
                </div>
                <div className='signup-label required-label'>
                  {i18n.t('doctor.signup.medicalSubjectLabel')}
                </div>
                <div className='signup-input'>
                  <CustomCheckBox
                    {...formikProps}
                    name='departments'
                    options={departments}
                    required
                  />
                  <ErrorMessage
                    name='departments'
                    component='div'
                    className='signup-alert'
                  />
                </div>

                <div className='signup-label required-label'>
                  {i18n.t('doctor.signup.graduationUniversityLabel')}
                </div>
                <div className='signup-input'>
                  <InputText
                    values={values}
                    value={values.graduationUniversity}
                    name='graduationUniversity'
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name='graduationUniversity'
                    component='div'
                    className='signup-alert'
                  />
                </div>
                <div className='row'>
                  <div className='col-6'>
                    <div className='signup-label required-label'>
                      {i18n.t('doctor.signup.graduationUniversityYearLabel')}
                    </div>
                    <div className='signup-input-half'>
                      <Dropdown
                        value={graduationUniversityYear}
                        name='graduationUniversityYear'
                        options={years}
                        optionLabel='description'
                        onChange={e => {
                          setFieldValue('graduationUniversityYear', e.value)
                          setGraduationUniversityYear(e.value)
                        }}
                        placeholder='2000'
                      />
                    </div>
                    <div className='alert'>
                      {!!touched.graduationUniversityYear &&
                        errors.graduationUniversityYear}
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='signup-label required-label'>
                      {i18n.t('doctor.signup.obtainDoctorLicenseYearsLabel')}
                    </div>
                    <div className='signup-input-half'>
                      <Dropdown
                        value={obtainDoctorLicenseYear}
                        name='obtainDoctorLicenseYear'
                        options={years}
                        optionLabel='description'
                        onChange={e => {
                          setFieldValue('obtainDoctorLicenseYear', e.value)
                          setObtainDoctorLicenseYear(e.value)
                        }}
                        placeholder='2000'
                      />
                    </div>
                    <div className='alert'>
                      {!!touched.obtainDoctorLicenseYear &&
                        errors.obtainDoctorLicenseYear}
                    </div>
                  </div>
                </div>

                <div className='signup-label'>
                  {i18n.t('doctor.signup.professionalDescriptionLabel')}
                </div>
                <div className='signup-input'>
                  <InputTextarea
                    values={values}
                    value={values.professionalDescription}
                    name='professionalDescription'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoResize
                    rows={5}
                  />
                </div>
                <div className='signup-label required-label'>
                  {i18n.t('doctor.signup.isIntroduceLabel')}
                </div>
                <div className='signup-input'>
                  <CustomCheckBox
                    {...formikProps}
                    name='isIntroduce'
                    options={isIntroduce}
                    required
                  />
                  <ErrorMessage
                    name='isIntroduce'
                    component='div'
                    className='signup-alert'
                  />
                </div>
                <div className='signup-label'>
                  {i18n.t('doctor.signup.introduceNameLabel')}
                </div>
                <div className='signup-input'>
                  <InputText
                    values={values}
                    value={
                      values.isIntroduce[0] === '0' ? '' : values.introducedBy
                    }
                    name='introducedBy'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={
                      values.isIntroduce[0] === '0' || values.isIntroduce === ''
                    }
                  />
                  <ErrorMessage
                    name='introducedBy'
                    component='div'
                    className='signup-alert'
                  />
                </div>
                <div className='signup-label'>
                  {i18n.t('doctor.signup.reasonKnowWebsiteLabel')}
                </div>
                <div className='signup-input signup-reason'>
                  <CustomCheckBox
                    {...formikProps}
                    name='reasonKnowWebsites'
                    options={reasons}
                    required
                  />
                </div>
                <div className='signup-label'>
                  {i18n.t('doctor.signup.reasonKnowWebsiteDescriptionLabel')}
                </div>
                <div className='signup-input'>
                  <InputText
                    values={values}
                    value={
                      values.reasonKnowWebsites.includes('OTHER')
                        ? values.otherReasonKnowWebsite
                        : ''
                    }
                    name='otherReasonKnowWebsite'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!values.reasonKnowWebsites.includes('OTHER')}
                  />
                  <ErrorMessage
                    name='otherReasonKnowWebsite'
                    component='div'
                    className='signup-alert'
                  />
                </div>
                <div className='signup-label'>
                  {i18n.t('doctor.signup.careerHistoryLabel')}
                </div>
                <div className='career-history-area'>
                  <FieldArray
                    name='careerHistory'
                    render={arrayHelpers => (
                      <div>
                        {values.careerHistory.map((career, index) => (
                          <div key={index}>
                            <div className='signup-input career-history'>
                              <div className='row'>
                                <div className='year'>
                                  <CustomDropdown
                                    {...formikProps}
                                    name={`careerHistory[${index}].year`}
                                    parentName='careerHistory'
                                    childName='year'
                                    index={index}
                                    options={years}
                                    classDisabled
                                    required
                                  />
                                </div>
                                <div className='col-1 tail-text'>
                                  {i18n.t('doctor.signup.year')}
                                </div>
                                <div className='month'>
                                  <CustomDropdown
                                    {...formikProps}
                                    parentName='careerHistory'
                                    name={`careerHistory[${index}].month`}
                                    childName='month'
                                    index={index}
                                    options={listMonth}
                                    classDisabled
                                    required
                                  />
                                </div>
                                <div className='col-1 tail-text'>
                                  {i18n.t('doctor.signup.month')}
                                </div>
                                <div className='description'>
                                  <InputTextarea
                                    {...formikProps}
                                    name={`careerHistory[${index}].description`}
                                    value={
                                      values.careerHistory[index].description
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoResize
                                    rows={1}
                                  />
                                </div>
                                <div className='col-1 tail-text'>
                                  {i18n.t('doctor.signup.experience')}
                                </div>
                                <div className='col-1'>
                                  <Button
                                    type='button'
                                    label='x'
                                    onClick={() => {
                                      arrayHelpers.remove(index)
                                    }}
                                  />
                                </div>
                              </div>
                              <div className='error-career-history'>
                                <ErrorMessage
                                  name={`careerHistory[${index}].year`}
                                  component='div'
                                  className='signup-alert'
                                />
                                <ErrorMessage
                                  name={`careerHistory[${index}].month`}
                                  component='div'
                                  className='signup-alert'
                                />
                                <ErrorMessage
                                  name={`careerHistory[${index}].description`}
                                  component='div'
                                  className='signup-alert'
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  />
                  <div className='row add-career-button'>
                    <Button
                      type='button'
                      onClick={() => {
                        setFieldValue('careerHistory', [
                          ...values.careerHistory,
                          {
                            year: '',
                            month: '',
                            description: '',
                          },
                        ])
                      }}
                      label={i18n.t('doctor.signup.addCareerButton')}
                    />
                  </div>
                </div>

                <div className='signup-label required-label'>
                  {i18n.t('doctor.signup.desiredDateForInterview')}
                </div>
                <div className='signup-label'>
                  {i18n.t('doctor.signup.noteForSignup')}
                </div>
                <div style={{ marginBottom: '30px' }}>
                  <FieldArray
                    name='expectedDateInterview'
                    render={() => (
                      <div>
                        {values.expectedDateInterview.map((item, index) => {
                          return (
                            <div key={index}>
                              <div className='signup-input career-history mt-5'>
                                <p
                                  className={
                                    index === 2 ? '' : 'required-label'
                                  }
                                  style={{ fontSize: '16px' }}
                                >
                                  {DATE_ORDER[index]}
                                </p>

                                <div
                                  className='row'
                                  style={{
                                    justifyContent: 'end',
                                  }}
                                >
                                  <div>
                                    <div className='row mt-5'>
                                      <p className='col-5 report-bold'>
                                        {i18n.t('doctor.signup.selectDate')}
                                      </p>
                                      <div className='col-8'>
                                        <Calendar
                                          name={`expectedDateInterview[${index}].date`}
                                          value={item?.date || ''}
                                          onChange={e => {
                                            setFieldValue(
                                              `expectedDateInterview[${index}].date`,
                                              moment(e.value).format(
                                                'YYYY/MM/DD'
                                              )
                                            )
                                          }}
                                          showIcon
                                          minDate={moment().toDate()}
                                        />
                                      </div>
                                    </div>

                                    <div
                                      className='row'
                                      style={{
                                        marginBottom: 8,
                                      }}
                                    >
                                      <p className='col-5 report-bold'>
                                        {i18n.t(
                                          'doctor.signup.selectInterviewTime'
                                        )}
                                      </p>

                                      <Radio.Group
                                        value={
                                          values.expectedDateInterview[index]
                                            .selectTime
                                        }
                                        name={`expectedDateInterview[${index}].selectTime`}
                                        size='middle'
                                      >
                                        <Space direction='horizontal'>
                                          <Radio
                                            value={1}
                                            onClick={() => {
                                              setFieldValue(
                                                `expectedDateInterview[${index}].selectTime`,
                                                1
                                              )
                                            }}
                                            style={{
                                              width: '150%',
                                            }}
                                          >
                                            <span
                                              style={{
                                                width: '15%',
                                                marginRight: '16px',
                                                fontWeight: 'bold',
                                              }}
                                            >
                                              {i18n.t(
                                                'doctor.signup.specifyTime'
                                              )}
                                            </span>
                                          </Radio>
                                          <Radio
                                            value={2}
                                            onClick={() => {
                                              setFieldValue(
                                                `expectedDateInterview[${index}].selectTime`,
                                                2
                                              )
                                            }}
                                            style={{ width: '150%' }}
                                          >
                                            <span
                                              style={{
                                                marginRight: '8%',
                                                fontWeight: 'bold',
                                                whiteSpace: 'normal',
                                              }}
                                            >
                                              {i18n.t(
                                                'doctor.signup.specifyTimeZone'
                                              )}
                                            </span>
                                          </Radio>
                                        </Space>
                                      </Radio.Group>
                                    </div>

                                    {item.selectTime === 1 ? (
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyItems: 'space-bettween',
                                          width: '100%',
                                          flexDirection: isMobile
                                            ? 'column'
                                            : 'row',
                                          gap: isMobile ? 16 : 0,
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <p
                                            className='status-label'
                                            style={{ marginRight: '8px' }}
                                          >
                                            {i18n.t('doctor.signup.startTime')}
                                          </p>
                                          <TimePicker
                                            minuteStep={15}
                                            disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7 ,8, 19, 20, 21, 22, 23]}
                                            disabledMinutes={() => {
                                              const minuteOfStartTime = moment(
                                                values.expectedDateInterview?.[
                                                  index
                                                  ]?.startTime
                                              ).hour()
                                              if (minuteOfStartTime === 18) {
                                                return Array.from({ length: 59 }, (_, i) => i + 1);
                                              }
                                              return [];
                                            }}
                                            style={{ padding: 0 }}
                                            name={`expectedDateInterview[${index}].startTime`}
                                            showNow={false}
                                            hideDisabledOptions
                                            popupClassName='timepicker'
                                            value={
                                              values.expectedDateInterview[
                                                index
                                              ]?.startTime
                                            }
                                            format='HH:mm'
                                            placeholder=''
                                            onSelect={value => {
                                              if (value) {
                                                if (value.format('HH') === '18') {
                                                  const newValue = moment().hour(18).minute(0).second(0);
                                                  setFieldValue(
                                                    `expectedDateInterview[${index}].startTime`,
                                                    newValue
                                                  )
                                                  const initialEndTime = moment(
                                                    newValue
                                                  ).add(30, 'minutes')
                                                  setFieldValue(
                                                    `expectedDateInterview[${index}].endTime`,
                                                    initialEndTime
                                                  )
                                                } else {
                                                  setFieldValue(
                                                    `expectedDateInterview[${index}].startTime`,
                                                    value
                                                  )
                                                  const initialEndTime = moment(
                                                    value
                                                  ).add(30, 'minutes')
                                                  setFieldValue(
                                                    `expectedDateInterview[${index}].endTime`,
                                                    initialEndTime
                                                  )
                                                }
                                              } else {
                                                setFieldValue(
                                                  `expectedDateInterview[${index}].startTime`,
                                                  null
                                                )
                                              }
                                            }}
                                            onChange={value => {
                                              setFieldValue(
                                                `expectedDateInterview[${index}].startTime`,
                                                value
                                              )
                                            }}
                                            defaultValue={moment(
                                              '09:00',
                                              'HH:mm'
                                            )}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginLeft: isMobile ? 0 : 16,
                                          }}
                                        >
                                          <p
                                            className='status-label'
                                            style={{ marginRight: '8px' }}
                                          >
                                            {i18n.t('doctor.signup.endTime')}
                                          </p>
                                          <TimePicker
                                            minuteStep={15}
                                            style={{ padding: 0 }}
                                            name={`expectedDateInterview[${index}].endTime`}
                                            showNow={false}
                                            hideDisabledOptions
                                            popupClassName='timepicker'
                                            value={
                                              values.expectedDateInterview[
                                                index
                                              ]?.endTime
                                            }
                                            disabledHours={() => {
                                              const minuteOfStartTime = moment(
                                                values.expectedDateInterview?.[
                                                  index
                                                  ]?.startTime
                                              ).hour();
                                              if (minuteOfStartTime) {
                                                let disableArr = [];
                                                for (let i = 0; i < minuteOfStartTime; i++) {
                                                  disableArr.push(i);
                                                }
                                                return [...disableArr, 19, 20, 21, 22, 23]
                                              }
                                              return [0, 1, 2, 3, 4, 5, 6, 7 ,8, 19, 20, 21, 22, 23]
                                            }}
                                            disabledMinutes={() => {
                                              const minuteOfStartTime = moment(
                                                values.expectedDateInterview?.[
                                                  index
                                                ]?.startTime
                                              ).hour()

                                              const minuteOfEndTime = moment(
                                                values.expectedDateInterview?.[
                                                  index
                                                ]?.endTime
                                              ).hour()
                                              if (!minuteOfEndTime || minuteOfEndTime === 9) {
                                                return Array.from({ length: 30 }, (_, i) => i);
                                              }
                                              if (minuteOfStartTime === minuteOfEndTime) {
                                                if (minuteOfEndTime === 18 && minuteOfStartTime === 18) {
                                                  return Array.from({ length: 60 }, (_, i) => i).filter(i => i !== 30);
                                                }
                                                return _.range(0, moment(values.expectedDateInterview?.[index]?.startTime).minute() + 30)
                                              } else if (minuteOfStartTime !== minuteOfEndTime) {
                                                if (minuteOfEndTime === 18) {
                                                  return Array.from({ length: 29 }, (_, i) => i + 31);
                                                } else if (minuteOfEndTime !== 18) {
                                                  return [];
                                                }
                                              }
                                            }}
                                            format='HH:mm'
                                            placeholder=''
                                            onSelect={value => {
                                              if (value.format('HH') === '18' && +value.format('mm') > 30) {
                                                const newValue = moment().hour(18).minute(0).second(0);
                                                setFieldValue(
                                                  `expectedDateInterview[${index}].endTime`,
                                                  newValue
                                                )
                                              } else if (value.format('HH') === '09' && value.format('mm') === '00') {
                                                const newValue = moment().hour(9).minute(30).second(0);
                                                setFieldValue(
                                                  `expectedDateInterview[${index}].endTime`,
                                                  newValue
                                                )
                                              } else {
                                                setFieldValue(
                                                  `expectedDateInterview[${index}].endTime`,
                                                  value
                                                )
                                              }
                                            }}
                                            onChange={value =>
                                              setFieldValue(
                                                `expectedDateInterview[${index}].endTime`,
                                                value
                                              )
                                            }
                                            defaultValue={moment(
                                              moment(
                                                values.expectedDateInterview?.[
                                                  index
                                                ]?.startTime
                                              ).format('HH:mm'),
                                              'HH:mm'
                                            )}
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyItems: 'space-bettween',
                                          width: '100%',
                                        }}
                                      >
                                        <CheckboxAntd.Group
                                          value={
                                            values.expectedDateInterview[index]
                                              .expectTime
                                          }
                                          onChange={e => {
                                            setFieldValue(
                                              `expectedDateInterview[${index}].expectTime`,
                                              e
                                            )
                                          }}
                                          options={[
                                            '午前(9:00～13:00)',
                                            '午後(13:00～18:00)',
                                            '夜間(18:00～21:00)',
                                          ]}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className='error-career-history'>
                                  {errors?.expectedDateInterview?.[index]
                                    ?.date ? (
                                    <ErrorMessage
                                      name={`expectedDateInterview[${index}].date`}
                                      component='div'
                                      className='signup-alert'
                                    />
                                  ) : (
                                    <ErrorMessage
                                      name={`expectedDateInterview[${index}].startTime`}
                                      component='div'
                                      className='signup-alert'
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    )}
                  />
                </div>

                {data.skillsConnection.edges.map(({ node }, index) => (
                  <div className='signup-input' key={index}>
                    <div className='signup-label'>{node.name}</div>
                    <div className='row'>
                      <div className='checkbox-container checkbox-field'>
                        <Checkbox
                          checked={values.skillSet.includes(`${node.id}`)}
                          onChange={() => {
                            if (!values.skillSet.includes(`${node.id}`)) {
                              const newValue = values.skillSet
                              newValue.push(node.id)
                              setFieldValue('skillSet', newValue)
                            } else {
                              const newValue = values.skillSet
                              const index = findIndex(newValue, node.id)
                              if (index !== 'noIndex') {
                                newValue.splice(index, 1)
                              }
                              setFieldValue('skillSet', newValue)
                            }
                          }}
                        />
                        <div className='checkbox-label'>可</div>
                      </div>
                      <div className='checkbox-container checkbox-field'>
                        <Checkbox
                          checked={!values.skillSet.includes(`${node.id}`)}
                          onChange={() => {
                            if (values.skillSet.includes(`${node.id}`)) {
                              const newValue = values.skillSet
                              const index = findIndex(newValue, node.id)
                              if (index !== 'noIndex') {
                                newValue.splice(index, 1)
                              }
                              setFieldValue('skillSet', newValue)
                            } else {
                              const newValue = values.skillSet
                              newValue.push(node.id)
                              setFieldValue('skillSet', newValue)
                            }
                          }}
                        />
                        <div className='checkbox-label'>不可</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className='submit-button'>
                <Button
                  type='button'
                  className='back-button p-button-primary'
                  onClick={() => handleBackStep(values)}
                  label={i18n.t('doctor.signup.backLabel')}
                />
                {isSubmitting ? (
                  <ProgressSpinner />
                ) : (
                  <Button
                    type='button'
                    disabled={isSubmitting}
                    className='signup-button p-button-primary'
                    onClick={handleSubmit}
                    label={i18n.t('doctor.signup.submitFinalLabel')}
                  />
                )}
              </div>
            </form>
          </div>
        )
      }}
    />
  )
}

export default SignupComponentStep2
