import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import { CLINICAL_DEPARTMENTS_QUERY } from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import gql from 'graphql-tag'

export const GET_RECRUITMENT_UNPUBLISHED = gql`
  query availableShiftPublished($month: DateTime!, $isHolidays: [String!]) {
    availableShiftPublished(month: $month, isHolidays: $isHolidays) {
      startTime
      endTime
      isPublished
      comment
    }
    ...DEPARTMENTS_FRAGMENT
    ...CLINICAL_DEPARTMENTS_QUERY
  }
  ${CLINICAL_DEPARTMENTS_QUERY}
  ${DEPARTMENTS_FRAGMENT}
`
