import { FilePdfOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { PreviewImgDialog } from '@medical/components/PreviewImgDialog'
import { IMAGE_ERROR, MAX_FILE_SIZE } from '@medical/constant'
import { checkIsPdf } from '@medical/constant/utilities'
import { DOWNLOAD_IMG } from '@medical/pages/staff/MovingExpenses/MovingExpensesOther/ListMovingExpensesOther.graphql'
import { useCustom } from '@medical/provider/context'
import { Image } from 'antd'
import axios from 'axios'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import React, { useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'

import { GET_POST_PRE_SIGNED_URL } from './UploadImage.graphql'

const UploadImage = ({ setFieldValue, imgKey }) => {
  const [{ i18n, popup }] = useCustom()
  const [dataImage, setDataImage] = useState('')
  const [dataType, setDataType] = useState('')
  const [billImage, setBillImage] = useState()
  const [displayDelete, setDisplayDelete] = useState(false)
  const [loading, setLoading] = useState(false)
  const [previewImgModal, setPreviewImgModal] = useState(false)
  const [getPostPreSignedUrl] = useMutation(GET_POST_PRE_SIGNED_URL)
  const [
    downloadImg,
    { loading: loadingDownload, error: errorDownload },
  ] = useMutation(DOWNLOAD_IMG)

  useEffect(() => {
    if (imgKey) {
      const downloadFile = async () => {
        try {
          const {
            data: { downloadFile: fileURL },
          } = await downloadImg({
            variables: {
              key: imgKey,
            },
          })
          setDataImage(fileURL.toString())
          setBillImage(imgKey)
        } catch (error) {
          popup.error(i18n.t('main.downloadFileError'))
        }
      }
      downloadFile()
    }
  }, [imgKey])

  if (loadingDownload) return <ProgressSpinner />
  if (errorDownload) return <ErrorComponent />
  const dialogFuncMap = {
    displayDelete: setDisplayDelete,
  }

  const onHide = name => {
    dialogFuncMap[`${name}`](false)
  }
  return (
    <div style={{ display: 'flex' }}>
      {checkIsPdf(dataImage) || dataType ? (
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '150px',
          }}
        >
          <FilePdfOutlined
            style={{
              fontSize: '40px',
              textAlign: 'center',
            }}
          />
          <br />
          <a target='_blank' href={dataImage} title={billImage} rel='noreferrer'>
            {billImage}
          </a>
        </div>
      ) : (
        <>
          {dataImage && (
            <>
              <Image
                width={200}
                height={200}
                src={dataImage || IMAGE_ERROR}
                style={{ cursor: 'pointer' }}
                preview={false}
                onClick={() => setPreviewImgModal(true)}
              />
              <PreviewImgDialog
                i18n={i18n}
                visible={previewImgModal && dataImage}
                dataImage={dataImage}
                handleClose={() => setPreviewImgModal(false)}
                loadingVisible={false}
              />
            </>
          )}
        </>
      )}

      <div className='btn-rigt-image'>
        {loading ? (
          <ProgressSpinner />
        ) : (
          <>
            <Dropzone
              onDrop={async files => {
                if (files.length > 0) {
                  const type = files[0].type.split('/')
                  setDataType(type[0] === 'application')
                  setBillImage(files[0].name)
                  setDataImage(URL.createObjectURL(files[0]))
                  setLoading(true)
                  const { data: postPreSignedUrl } = await getPostPreSignedUrl({
                    variables: {
                      fileName: files[0].name,
                      mimetype: files[0].type,
                    },
                  })
                  if (postPreSignedUrl.createPresignedPostUrl) {
                    try {
                      const dataResponse = JSON.parse(
                        postPreSignedUrl.createPresignedPostUrl
                      )
                      const { url, fields } = dataResponse.fulfillmentValue
                      const formData = new FormData()
                      Object.keys(fields).forEach(key => {
                        formData.append(key, fields[key])
                      })
                      formData.append('file', files[0])
                      await axios({
                        method: 'POST',
                        url,
                        data: formData,
                        headers: {
                          'Content-Type': 'multipart/form-data',
                        },
                      })
                      setFieldValue('file', fields.key)
                      setLoading(false)
                    } catch (e) {
                      popup.error(i18n.t('doctor.uploadFile.submissionError'))
                      setLoading(false)
                    }
                  }
                }
              }}
              accept={['image/jpeg', 'image/png', 'application/pdf']}
              maxSize={MAX_FILE_SIZE}
              multiple={false}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  {dataImage ? (
                    <Button icon='pi pi-pencil' />
                  ) : (
                    <Button
                      icon='pi pi-cloud-upload'
                      label={i18n.t('doctor.uploadFile.upload')}
                    />
                  )}
                  <input {...getInputProps()} />
                </div>
              )}
            </Dropzone>
            {dataImage && (
              <Button
                style={{ marginTop: '5px' }}
                icon='pi pi-trash'
                onClick={() => setDisplayDelete(true)}
              />
            )}
            <Dialog
              position='center'
              onHide={() => onHide('displayDelete')}
              header={i18n.t('main.confirmation')}
              visible={displayDelete}
              style={{ width: '30vw' }}
              footer={
                <div>
                  <Button
                    label={i18n.t('main.yes')}
                    icon='pi pi-check '
                    onClick={() => {
                      setDataImage(null)
                      setBillImage(null)
                      setDataType(false)
                      setFieldValue('billImage', null)
                      setFieldValue('file', null)
                      onHide('displayDelete')
                    }}
                  />
                  <Button
                    label={i18n.t('main.no')}
                    icon='pi pi-times'
                    onClick={() => onHide('displayDelete')}
                    className='p-button-secondary'
                  />
                </div>
              }
            >
              {i18n.t('staff.availableShift.removeConfirmation')}
            </Dialog>
          </>
        )}
      </div>
    </div>
  )
}

export default UploadImage
