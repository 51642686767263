import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { STAFF_PAGE_RECRUITMENT_POST } from '@medical/constant/permissions'
import {
  checkStaffPermission,
  clinicalDepartmentNames,
  combineNames,
  onPageChange,
  sortAndFilterByClinicOrder,
} from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import queryString from 'query-string'
import React, { useEffect } from 'react'
import { RECRUITMENT_DOCTORS_CONNECTION } from './RecruitmentDoctorList.graphql'
import RecruitmentDoctorListScene from './RecruitmentDoctorListScene'
import { DOCTORS_DEPARTMENTS } from '../CreateAvailableShift/CreateAvailableShift.graphql'

const RecruitmentDoctorList = ({ history, location: { search } }) => {
  const {
    page = 1,
    rowsPerPage = 20,
    orderBy = 'createdAt_DESC',
    fullname,
    email,
    status,
    type,
  } = queryString.parse(search)
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  let variables = {
    first: first < 0 ? 20 : first,
    skip: skip < 0 ? 0 : skip,
    orderBy: orderBy,
    where: {
      status,
      RecruitmentPost: { recruitmentDoctorType: type },
      Doctor: {
        deletedAt: null,
        email_contains: email ? email.trim() : '',
        unSigned_contains: fullname
          ? fullname
              .replace(/ /g, '')
              .replace(/　/g, '')
              .toLowerCase()
          : '',
      },
    },
  }

  const [{ i18n, popup }] = useCustom()

  const isPageRecruitmentPost = checkStaffPermission({
    functionName: STAFF_PAGE_RECRUITMENT_POST,
  })

  const { loading, error, data, refetch } = useQuery(
    RECRUITMENT_DOCTORS_CONNECTION,
    {
      variables,
      fetchPolicy: 'network-only',
    }
  )

  const {
    loading: loadingDepartments,
    error: errorDepartments,
    data: dataDepartments,
  } = useQuery(DOCTORS_DEPARTMENTS)

  useEffect(() => {
    refetch()
  })
  if (loading || loadingDepartments) return <ProgressSpinner />
  if (error || errorDepartments) return <ErrorComponent error={error} />
  const {
    recruitmentDoctorsConnection: {
      edges,
      aggregate: { count },
    },
  } = data

  const { clinicalDepartments, departments } = dataDepartments
  const departmentNames = clinicalDepartmentNames({ departments })
  const sortedClinicalDepartments = sortAndFilterByClinicOrder({
    clinicalDepartments,
    isFilter: true,
  })
  const listDepartment = combineNames({
    clinicalDepartments: sortedClinicalDepartments,
    departmentNames,
  })

  return (
    <RecruitmentDoctorListScene
      i18n={i18n}
      edges={edges}
      count={count}
      page={parseInt(page, 10)}
      rowsPerPage={parseInt(rowsPerPage, 10)}
      status={status}
      fullname={fullname}
      email={email}
      type={type}
      onPageChange={onPageChange(history, search)}
      isPageRecruitmentPost={isPageRecruitmentPost}
      departments={listDepartment}
    />
  )
}

export default RecruitmentDoctorList
