import { STAFF_FRAGMENTS } from '@medical/layout/StaffLayout/StaffLayout.graphql'
import gql from 'graphql-tag'

export const UPDATE_STAFF = gql`
  mutation UPDATE_STAFF(
    $staffId: ID!
    $lastname: String
    $lastnameKana: String
    $firstname: String
    $firstnameKana: String
    $position: String
    $staffGroupId: ID
    $clinicId: ID
  ) {
    staffUpdateStaff(
      staffId: $staffId
      lastname: $lastname
      lastnameKana: $lastnameKana
      firstname: $firstname
      firstnameKana: $firstnameKana
      staffGroupId: $staffGroupId
      position: $position
      clinicId: $clinicId
    ) {
      ...STAFF_FRAGMENTS
    }
  }
  ${STAFF_FRAGMENTS}
`
