import numeral from 'numeral'

numeral.register('locale', 'ja', {
  delimiters: {
    thousands: ',',
    decimal: '.',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal(number) {
    return number === 1 ? 'er' : 'ème'
  },
  currency: {
    symbol: '¥',
  },
})

numeral.locale('ja')

const numeralCurrency = val => numeral(val).format('$0,0')

export default numeralCurrency
