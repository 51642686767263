import { useCustom } from '@medical/provider/context'
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import {useMutation, useQuery} from "@apollo/react-hooks";
import {MARK_AS_READ} from "../../pages/doctor/Inquiry/Inquiry.graphql";
import Router from "../../routes/router";
import {parseJwt} from "../../constant/utilities";
import {CONVERSATION_DOCTOR} from "../../layout/DoctorLayout/DoctorLayoutRequest.graphql";

const DoctorLink = ({ history, children, ...props }) => {
  const [, { setMenuToggle }] = useCustom()
  const { to, className } = props

  const onClick = async (e) => {
    e.preventDefault()
    setMenuToggle(false)
    history.push(to)
  }

  return (
    <Link to={to ? to : ''} className={className} onClick={onClick}>
      {children}
    </Link>
  )
}

export default withRouter(DoctorLink)
