import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import CustomDropdown from '@medical/components/DropdownForSignup'
import TextInputComment from '@medical/components/TextInputForCommentPage'
import { ErrorMessage, FieldArray } from 'formik'
import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'

import { GET_ENUM_COMMENT } from '../DetailWorkSchedule/WorkScheduleComment.graphql'

const ShiftFormRecruitmentCategory = ({
  i18n,
  formikProps,
  isShowAdd,
  state,
}) => {
  const { setFieldValue, handleChange, values } = formikProps
  const [categoryOption, setCategoryOption] = useState([])
  const [statusOption, setStatusOption] = useState([])
  const { loading, error, data } = useQuery(GET_ENUM_COMMENT, {
    variables: {
      name: 'RecruitmentCategoryType',
    },
  })
  const {
    loading: statusLoading,
    error: statusError,
    data: statusData,
  } = useQuery(GET_ENUM_COMMENT, {
    variables: {
      name: 'RecruitmentStatusType',
    },
  })
  useEffect(() => {
    if (data && data.enumValues) {
      setCategoryOption(data.enumValues)
    }
    if (statusData && statusData.enumValues) {
      setStatusOption(statusData.enumValues)
    }
  }, [data, statusData]);

  useEffect(()=>{
    if(!state || !state?.recruitmentCategory) return;
    const listRecruitmentCategory2 =  state?.recruitmentCategory.map(function(
      item
    ) {
      delete item.deletedAt
      delete item.id
      delete item.staff
      return item
    })
    setFieldValue('splitRecruitmentCategories',listRecruitmentCategory2);
  },[state?.recruitmentCategory])

  if (loading || statusLoading) return <ProgressSpinner />
  if (error || statusError)
    return <ErrorComponent error={error || statusError} />

  return (
    <div className={`${!isShowAdd ? 'staffpage-comment-container' : ''}`}>
      {!isShowAdd && (
        <div className='staffpage-title'>
          {i18n.t('staff.createShift.recruitmentCategory')}
        </div>
      )}

      <FieldArray
        name='splitRecruitmentCategories'
        render={({ remove }) => (
          <div className='staff-comment-container'>
            {values.splitRecruitmentCategories.map(
              (recruitmentCategory, index) => (
                <div
                  className='input-recruitment-category-container'
                  key={index}
                >
                  {/* <Dropdown /> */}
                  <div className='move-inline' style={{ alignItems: 'start' }}>
                    <CustomDropdown
                      {...formikProps}
                      name={`splitRecruitmentCategories[${index}].category`}
                      options={categoryOption}
                      placeholder='募集項目選択'
                      recruitmentCategory
                      index={index}
                    />
                    <CustomDropdown
                      {...formikProps}
                      name={`splitRecruitmentCategories[${index}].status`}
                      options={statusOption}
                      placeholder='掲載ステータス選択'
                      recruitmentStatus
                      index={index}
                    />
                    {values.splitRecruitmentCategories[index].category ===
                      'OTHER' && (
                      <TextInputComment
                        {...formikProps}
                        name={`splitRecruitmentCategories[${index}].description`}
                        onChange={handleChange}
                        placeholder='入力'
                        inputWidth='100%'
                        recruitmentCategory
                        index={index}
                      />
                    )}
                    <Button
                      style={{ alignItems: 'start', margin: '0px 10px' }}
                      icon='pi pi-trash'
                      onClick={() => {
                        if (values.splitRecruitmentCategories.length === 1) {
                          setFieldValue(
                            `splitRecruitmentCategories[${index}].category`,
                            ''
                          )
                          setFieldValue(
                            `splitRecruitmentCategories[${index}].status`,
                            ''
                          )
                          setFieldValue(
                            `splitRecruitmentCategories[${index}].description`,
                            ''
                          )
                        } else {
                          remove(index)
                        }
                      }}
                    />
                  </div>
                </div>
              )
            )}
            <Button
              icon='pi pi-plus'
              label={i18n.t('main.add')}
              onClick={() => {
                setFieldValue('splitRecruitmentCategories', [
                  ...values.splitRecruitmentCategories,
                  {
                    category: '',
                    status: '',
                    description: '',
                  },
                ])
              }}
            />
          </div>
        )}
      />
    </div>
  )
}
export default ShiftFormRecruitmentCategory
