import { CART_TOKEN_KEY } from '@medical/constant'

export const SET_CART = 'SET_CART'
export const ADD_ITEM = 'ADD_ITEM'
export const REMOVE_ITEM = 'REMOVE_ITEM'

export const addItem = item => dispatch => {
  const storageItems = localStorage.getItem(CART_TOKEN_KEY)
  const items = JSON.parse(storageItems) || []
  items.push(item)
  localStorage.setItem(CART_TOKEN_KEY, JSON.stringify(items))
  dispatch({
    type: ADD_ITEM,
    payload: item,
  })
}

export const removeItem = availableShiftId => dispatch => {
  const storageItems = localStorage.getItem(CART_TOKEN_KEY)
  const items = JSON.parse(storageItems) || []
  const newItems = items.filter(item => item.id !== availableShiftId)
  localStorage.setItem(CART_TOKEN_KEY, JSON.stringify(newItems))
  dispatch({
    type: REMOVE_ITEM,
    payload: newItems,
  })
}
