export const imageCrop = ({
  ctx,
  imgRef,
  canvasRef,
  completedCrop,
  scale = 1,
}) => {
  const scaleX = imgRef.current.naturalWidth / imgRef.current.width
  const scaleY = imgRef.current.naturalHeight / imgRef.current.height
  const pixelRatio = window.devicePixelRatio
  canvasRef.current.width = Math.floor(
    completedCrop.width * scaleX * pixelRatio
  )
  canvasRef.current.height = Math.floor(
    completedCrop.height * scaleY * pixelRatio
  )

  ctx.scale(pixelRatio, pixelRatio)
  ctx.imageSmoothingQuality = 'high'

  const cropX = completedCrop.x * scaleX
  const cropY = completedCrop.y * scaleY

  const centerX = imgRef.current.naturalWidth / 2
  const centerY = imgRef.current.naturalHeight / 2

  ctx.save()

  ctx.translate(-cropX, -cropY)
  ctx.translate(centerX, centerY)
  ctx.scale(scale, scale)
  ctx.translate(-centerX, -centerY)
  ctx.drawImage(
    imgRef.current,
    0,
    0,
    imgRef.current.naturalWidth,
    imgRef.current.naturalHeight,
    0,
    0,
    imgRef.current.naturalWidth,
    imgRef.current.naturalHeight
  )

  ctx.restore()
  return canvasRef
}
