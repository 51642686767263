import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import { WORK_SCHEDULE_FRAGMENTS } from '@medical/pages/doctor/Schedule/Schedule.graphql'
import gql from 'graphql-tag'

export const DOCTOR_WORK_SCHEDULE = gql`
  query DOCTOR_WORK_SCHEDULE($workScheduleId: ID!) {
    workSchedule(where: { id: $workScheduleId }) {
      ...WORK_SCHEDULE_FRAGMENTS
      workScheduleActualTime {
        id
        startTime
        endTime
      }
      acceptedShift {
        id
        startTime
        endTime
        hourlyWage
        dailySalary
        comment
        splitDateTime1
        splitDateTime2
        splitDateTime3
        splitHourlyWage1
        splitHourlyWage2
        splitHourlyWage3
        isStartTimeBreakTime
        isSplitDateTime1BreakTime
        isSplitDateTime2BreakTime
        isSplitDateTime3BreakTime
        doctorSubsidy {
          id
          title
          money
        }
      }
      doctor {
        id
        totalCancelLetter
        firstname
        lastname
        lastnameKana
        firstnameKana
      }
      relatedWorkSchedules {
        id
        startTime
        endTime
        doctor {
          id
          firstname
          lastname
        }
      }
    }
    ...DEPARTMENTS_FRAGMENT
  }
  ${WORK_SCHEDULE_FRAGMENTS}
  ${DEPARTMENTS_FRAGMENT}
`

export const AVAILABLE_SHIFT_COMMENT = gql`
  query Query($availableShiftsWhere: AvailableShiftWhereInput) {
    availableShifts(where: $availableShiftsWhere) {
      id
      comment
    }
  }
`
export const AVAILABLE_SHIFT_SUBSIDY = gql`
  query Query($availableShiftsWhere: AvailableShiftWhereInput) {
    availableShifts(where: $availableShiftsWhere) {
      id
      doctorSubsidy {
        id
        deletedAt
        title
        money
      }
    }
  }
`
