/* eslint-disable no-nested-ternary */
import { useApolloClient } from '@apollo/react-hooks'
import AutoComplete from '@medical/components/AutoComplete'
import MultipleDimensionCheckbox from '@medical/components/CheckBoxMultipleDimension'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import { DEFAULT_HOURLY_WAGE_ACTION, TYPE_CREATE } from '@medical/constant'
import { convertDoctorsConnection } from '@medical/libs'
import { DOCTOR_CONNECTION } from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import { Checkbox, DatePicker, InputNumber, TimePicker } from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import { ErrorMessage, Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import React, { useState } from 'react'
import * as Yup from 'yup'

const DoctorHourlyWageForm = ({
  onSubmit,
  i18n,
  doctors,
  clinicalDepartments,
  isCreateDefaultHourlyWage,
  node,
  isUpdateDefaultHourlyWage,
  isDeleteDefaultHourlyWage,
}) => {
  const client = useApolloClient()
  const [confirmMessage, setConfirmMessage] = useState('')

  const validationSchema = Yup.object()
    .shape({
      workPattern: Yup.object()
        .nullable()
        .required(i18n.t('validation.workPattern')),
      clinicalDepartment: Yup.object()
        .nullable()
        .required(i18n.t('validation.clinicalDepartment.required')),
      doctor: Yup.object()
        .nullable()
        .required(i18n.t('validation.doctor.required')),
      rangeDate: Yup.mixed().test(
        'validate startDate and endDate',
        i18n.t('validation.shiftGroup.startEndDate'),
        val => {
          if (val && val[0] && val[1]) return true
          return false
        }
      ),
      repeatSetting: Yup.string().required(i18n.t('validation.shiftGroup.day')),
      time: Yup.mixed().test(
        'validate startTime and endTime',
        i18n.t('validation.hourlyWagePage.time'),
        val => {
          if (val && val[0] && val[1]) return true
          return false
        }
      ),
      hourlyWage: Yup.number()
        .nullable()
        .required(i18n.t('validation.hourlyWagePage.day')),
    })
    .test('checkSelectDay', i18n.t('validation.hourlyWagePage.date'), value => {
      if (
        (!value.isMonday &&
          !value.isTuesday &&
          !value.isWednesday &&
          !value.isThursday &&
          !value.isFriday &&
          !value.isSaturday &&
          !value.isSunday &&
          !value.isDayoff &&
          value.repeatSetting === TYPE_CREATE.WEEKLY) ||
        (value.listWeekDay.length === 0 &&
          value.listWeekDay1.length === 0 &&
          value.listWeekDay2.length === 0 &&
          value.listWeekDay3.length === 0 &&
          value.listWeekDay4.length === 0 &&
          value.repeatSetting === TYPE_CREATE.MONTHLY)
      ) {
        return false
      }
      return true
    })

  const listWorkPattern = [
    { value: 'REGULAR_SHIFT_DOCTOR', description: '定期非常勤' },
    { value: 'PERMANENT_DOCTOR', description: '常勤' },
  ]
  const typeCreateOptions = [
    {
      label: i18n.t('staff.scheduledShiftGroup.daily'),
      value: TYPE_CREATE.DAILY,
    },
    {
      label: i18n.t('staff.scheduledShiftGroup.weekly'),
      value: TYPE_CREATE.WEEKLY,
    },
    {
      label: i18n.t('staff.scheduledShiftGroup.monthly'),
      value: TYPE_CREATE.MONTHLY,
    },
  ]
  const sampleDataOfCheckbox = [
    { value: 'monday', description: i18n.t('staff.createHourlyWage.monDay') },
    { value: 'tuesday', description: i18n.t('staff.createHourlyWage.tuesDay') },
    {
      value: 'wednesday',
      description: i18n.t('staff.createHourlyWage.wednesDay'),
    },
    {
      value: 'thursday',
      description: i18n.t('staff.createHourlyWage.thursDay'),
    },
    { value: 'friday', description: i18n.t('staff.createHourlyWage.friDay') },
    {
      value: 'saturday',
      description: i18n.t('staff.createHourlyWage.saturDay'),
    },
    { value: 'sunday', description: i18n.t('staff.createHourlyWage.sunDay') },
  ]
  let doctor = ''
  if (node && node.doctor) {
    doctor = {
      id: node.doctor.id,
      fullNameEmail: `${node.doctor.fullName} (${node.doctor.email}`,
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        confirm: false,
        doctor,
        doctors,
        workPattern: node
          ? listWorkPattern.find(({ value }) => value === node.workPattern)
          : '',
        clinicalDepartments,
        clinicalDepartment:
          node && node.clinicalDepartment
            ? clinicalDepartments.find(
                ({ id }) => id === node.clinicalDepartment.id
              )
            : '',
        rangeDate: node ? [node.startDate, node.endDate] : [],
        repeatSetting: node ? node.repeatSetting : '',
        isAllday: node && node.isAllday ? node.isAllday : false,
        isMonday: node ? node.isAvailableOnMonday : false,
        isTuesday: node ? node.isAvailableOnTuesday : false,
        isWednesday: node ? node.isAvailableOnWednesday : false,
        isThursday: node ? node.isAvailableOnThursday : false,
        isFriday: node ? node.isAvailableOnFriday : false,
        isSaturday: node ? node.isAvailableOnSaturday : false,
        isSunday: node ? node.isAvailableOnSunday : false,
        isDayoff: node ? node.isAvailableOnHoliday : false,
        listWeekDay: node && node.listWeekDay ? node.listWeekDay : [],
        listWeekDay1: node && node.listWeekDay1 ? node.listWeekDay1 : [],
        listWeekDay2: node && node.listWeekDay2 ? node.listWeekDay2 : [],
        listWeekDay3: node && node.listWeekDay3 ? node.listWeekDay3 : [],
        listWeekDay4: node && node.listWeekDay4 ? node.listWeekDay4 : [],
        time: node ? [node.startTime, node.endTime] : [],
        hourlyWage: node ? node.hourlyWage : '',
        action: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={formikProps => {
        const {
          touched,
          setFieldValue,
          values,
          validateForm,
          setTouched,
          errors,
        } = formikProps
        return (
          <div className='container move-expenses-container'>
            <div className='row'>
              <div className='move-expenses-header__title'>
                {isCreateDefaultHourlyWage
                  ? i18n.t(
                      'staff.createDoctorManageHourlyWage.titleScreenCreateDefaultHourlyWage'
                    )
                  : null}
              </div>
            </div>
            <div className='tr'>
              <div className='th'>
                {i18n.t('staff.createDoctorManageHourlyWage.workPattern')}
                {/* {i18n.t('staff.movingExpensesDetail.clinicName')} */}
              </div>
              <div className='td'>
                <Dropdown
                  {...formikProps}
                  name='workPattern'
                  optionLabel='description'
                  valueLabel='value'
                  value={values.workPattern}
                  options={listWorkPattern || []}
                  placeholder={i18n.t(
                    'staff.createDoctorManageHourlyWage.placeHolder.workPattern'
                  )}
                  onChange={e => {
                    setFieldValue('workPattern', e.value)
                    setFieldValue(
                      'doctors',
                      doctors.filter(item => item.workPattern === e.value.value)
                    )
                  }}
                  // style={{ width: '37%', height: '40px' }}
                />
                <ErrorMessage
                  name='workPattern'
                  component='div'
                  className='color-red'
                />
              </div>
            </div>
            <div className='tr'>
              <div className='th'>
                {i18n.t('staff.createDoctorManageHourlyWage.doctorName')}
              </div>
              <div className='td'>
                <div>
                  <AutoComplete
                    {...formikProps}
                    name='doctor'
                    field='fullNameEmail'
                    disabled={node && node.doctor}
                    searchFields={['fullName', 'email']}
                    allSuggestions={values.doctors}
                    suggestionName='doctors'
                    width='30vw'
                    placeholder={
                      doctors && doctors.length > 0
                        ? '医師を選択'
                        : '対象データなし'
                    }
                    setDoctorSelect={async val => {
                      if (typeof val === 'string') {
                        try {
                          const { data: dataConnection } = await client.query({
                            query: DOCTOR_CONNECTION,
                            variables: {
                              where: {
                                AND: [
                                  {
                                    OR: [
                                      {
                                        unSigned_contains:
                                          typeof val === 'string'
                                            ? val
                                                .replace(/ /g, '')
                                                .replace(/　/g, '')
                                                .toLowerCase()
                                            : '',
                                      },
                                      {
                                        email_contains: val,
                                      },
                                    ],
                                  },
                                ],
                                registrationStatus_in: [
                                  'CREATED_BY_STAFF',
                                  'ACCEPTED',
                                ],
                                blocked: false,
                              },
                              first: 10,
                              skip: 0,
                              orderBy: 'unSigned_ASC',
                            },
                            fetchPolicy: 'network-only',
                          })
                          // const addDefaultHourlyWages = listDoctor => {
                          //   if (!listDoctor) return []
                          //   return listDoctor.map(doctor => {
                          //     defaultHourlyWages.forEach(hourlyWageItem => {
                          //       if (
                          //         doctor.node.workPattern ===
                          //         hourlyWageItem.workPattern
                          //       ) {
                          //         doctor.node[HOURLY_WAGE] =
                          //           hourlyWageItem.hourlyWage
                          //       }
                          //     })

                          //     return doctor
                          //   })
                          // }
                          setFieldValue(
                            'doctors',
                            convertDoctorsConnection(
                              dataConnection.doctorsConnection.edges
                            )
                          )
                        } catch (err) {}
                      }
                    }}
                  />
                  <ErrorMessage
                    name='doctor'
                    component='div'
                    className='color-red'
                  />
                </div>
              </div>
            </div>
            <div className='tr'>
              <div className='th'>
                {i18n.t('staff.createDoctorManageHourlyWage.clinicDepartment')}
              </div>
              <div className='td'>
                <AutoComplete
                  {...formikProps}
                  name='clinicalDepartment'
                  field='clinicName'
                  allSuggestions={clinicalDepartments}
                  suggestionName='clinicalDepartments'
                  width='60%'
                  // maxWidth='386px'
                  placeholder={
                    clinicalDepartments.length > 0
                      ? 'クリニックを選択'
                      : '対象データなし'
                  }
                />

                <ErrorMessage
                  name='clinicalDepartment'
                  component='div'
                  className='color-red'
                />
              </div>
            </div>
            <div className='tr'>
              <div className='th'>
                {i18n.t('staff.createDoctorManageHourlyWage.setPeriod')}
              </div>
              <div className='td'>
                <DatePicker.RangePicker
                  // style={{ width: '80%' }}
                  disabledDate={current =>
                    current && current > moment().add(1, 'y')
                  }
                  value={[
                    values.rangeDate[0]
                      ? moment(new Date(values.rangeDate[0]), 'YYYY年MM月DD日')
                      : null,
                    values.rangeDate[1]
                      ? moment(new Date(values.rangeDate[1]), 'YYYY年MM月DD日')
                      : null,
                  ]}
                  format='YYYY年MM月DD日'
                  // picker='month'
                  name='rangeDate'
                  allowClear={false}
                  locale={locale}
                  placeholder={['開始日', '終了日']}
                  onChange={e => {
                    setFieldValue('rangeDate', [
                      moment(e[0]).toISOString(),
                      moment(e[1]).toISOString(),
                    ])
                  }}
                />
                <ErrorMessage
                  name='rangeDate'
                  component='div'
                  className='color-red'
                />
              </div>
            </div>

            <div className='tr'>
              <div className='th'>
                {i18n.t('staff.scheduledShiftGroup.repeatUpdate')}
              </div>
              <div className='td'>
                <Dropdown
                  value={values.repeatSetting}
                  options={typeCreateOptions}
                  name='repeatSetting'
                  onChange={e => {
                    if (e.value === TYPE_CREATE.DAILY) {
                      setFieldValue('isAllday', true)
                      setFieldValue('isMonday', true)
                      setFieldValue('isTuesday', true)
                      setFieldValue('isWednesday', true)
                      setFieldValue('isThursday', true)
                      setFieldValue('isFriday', true)
                      setFieldValue('isSaturday', true)
                      setFieldValue('isSunday', true)
                      setFieldValue('isDayoff', true)
                    }
                    setFieldValue('repeatSetting', e.value)
                  }}
                  placeholder={i18n.t(
                    'staff.scheduledShiftGroup.placeHolderOfTypeCreate'
                  )}
                />
                <ErrorMessage
                  name='repeatSetting'
                  component='div'
                  className='color-red'
                />
              </div>
            </div>

            {values.repeatSetting === TYPE_CREATE.WEEKLY ? (
              // use checkbox of PrimeReact
              <div className='tr'>
                <div className='th'>
                  {i18n.t('staff.createHourlyWage.dayOfWeek')}
                </div>
                <div className='td'>
                  <div className='checkbox-container'>
                    <div className='checkbox-item'>
                      <Checkbox
                        name='isAllday'
                        checked={values.isAllday}
                        onChange={e => {
                          if (e.target.checked) {
                            setFieldValue('isAllday', true)
                            setFieldValue('isMonday', true)
                            setFieldValue('isTuesday', true)
                            setFieldValue('isWednesday', true)
                            setFieldValue('isThursday', true)
                            setFieldValue('isFriday', true)
                          } else {
                            setFieldValue('isAllday', false)
                            setFieldValue('isMonday', false)
                            setFieldValue('isTuesday', false)
                            setFieldValue('isWednesday', false)
                            setFieldValue('isThursday', false)
                            setFieldValue('isFriday', false)
                          }
                        }}
                      />
                      <div className='checkbox-title'>
                        {i18n.t('staff.createHourlyWage.allDay')}
                      </div>
                    </div>
                    <div className='checkbox-item'>
                      <Checkbox
                        name='isMonday'
                        checked={values.isMonday}
                        onChange={e => {
                          if (!e.target.checked) {
                            setFieldValue('isAllday', false)
                            setFieldValue('isMonday', false)
                          } else if (
                            values.isTuesday &&
                            values.isWednesday &&
                            values.isThursday &&
                            values.isFriday
                          ) {
                            setFieldValue('isAllday', true)
                            setFieldValue('isMonday', true)
                          } else {
                            setFieldValue('isMonday', e.target.checked)
                          }
                        }}
                      />
                      <div className='checkbox-title'>
                        {i18n.t('staff.createHourlyWage.monDay')}
                      </div>
                    </div>
                    <div className='checkbox-item'>
                      <Checkbox
                        name='isTuesday'
                        checked={values.isTuesday}
                        onChange={e => {
                          if (!e.target.checked) {
                            setFieldValue('isAllday', false)
                            setFieldValue('isTuesday', false)
                          } else if (
                            values.isMonday &&
                            values.isWednesday &&
                            values.isThursday &&
                            values.isFriday
                          ) {
                            setFieldValue('isAllday', true)
                            setFieldValue('isTuesday', true)
                          } else {
                            setFieldValue('isTuesday', e.target.checked)
                          }
                        }}
                      />
                      <div className='checkbox-title'>
                        {i18n.t('staff.createHourlyWage.tuesDay')}
                      </div>
                    </div>
                    <div className='checkbox-item'>
                      <Checkbox
                        name='isWednesday'
                        checked={values.isWednesday}
                        onChange={e => {
                          if (!e.target.checked) {
                            setFieldValue('isAllday', false)
                            setFieldValue('isWednesday', false)
                          } else if (
                            values.isMonday &&
                            values.isTuesday &&
                            values.isThursday &&
                            values.isFriday
                          ) {
                            setFieldValue('isAllday', true)
                            setFieldValue('isWednesday', true)
                          } else {
                            setFieldValue('isWednesday', e.target.checked)
                          }
                        }}
                      />
                      <div className='checkbox-title'>
                        {i18n.t('staff.createHourlyWage.wednesDay')}
                      </div>
                    </div>
                    <div className='checkbox-item'>
                      <Checkbox
                        name='isThursday'
                        checked={values.isThursday}
                        onChange={e => {
                          if (!e.target.checked) {
                            setFieldValue('isAllday', false)
                            setFieldValue('isThursday', false)
                          } else if (
                            values.isMonday &&
                            values.isTuesday &&
                            values.isWednesday &&
                            values.isFriday
                          ) {
                            setFieldValue('isAllday', true)
                            setFieldValue('isThursday', true)
                          } else {
                            setFieldValue('isThursday', e.target.checked)
                          }
                        }}
                      />
                      <div className='checkbox-title'>
                        {i18n.t('staff.createHourlyWage.thursDay')}
                      </div>
                    </div>
                    <div className='checkbox-item'>
                      <Checkbox
                        name='isFriday'
                        checked={values.isFriday}
                        onChange={e => {
                          if (!e.target.checked) {
                            setFieldValue('isAllday', false)
                            setFieldValue('isFriday', false)
                          } else if (
                            values.isMonday &&
                            values.isTuesday &&
                            values.isWednesday &&
                            values.isThursday
                          ) {
                            setFieldValue('isAllday', true)
                            setFieldValue('isFriday', true)
                          } else {
                            setFieldValue('isFriday', e.target.checked)
                          }
                        }}
                      />
                      <div className='checkbox-title'>
                        {i18n.t('staff.createHourlyWage.friDay')}
                      </div>
                    </div>
                    <div className='checkbox-item'>
                      <Checkbox
                        name='isSaturday'
                        checked={values.isSaturday}
                        onChange={e => {
                          setFieldValue('isSaturday', e.target.checked)
                        }}
                      />
                      <div className='checkbox-title'>
                        {i18n.t('staff.createHourlyWage.saturDay')}
                      </div>
                    </div>
                    <div className='checkbox-item'>
                      <Checkbox
                        name='isSunday'
                        checked={values.isSunday}
                        onChange={e => {
                          setFieldValue('isSunday', e.target.checked)
                        }}
                      />
                      <div className='checkbox-title'>
                        {i18n.t('staff.createHourlyWage.sunDay')}
                      </div>
                    </div>
                    <div className='checkbox-item'>
                      <Checkbox
                        name='isDayoff'
                        checked={values.isDayoff}
                        onChange={e => {
                          setFieldValue('isDayoff', e.target.checked)
                        }}
                      />
                      <div className='checkbox-title'>
                        {i18n.t('staff.createHourlyWage.dayOff')}
                      </div>
                    </div>
                  </div>
                  <div className='alert'>
                    {touched.undefined && errors.undefined}
                  </div>
                </div>
              </div>
            ) : values.repeatSetting === TYPE_CREATE.MONTHLY ? (
              <>
                <MultipleDimensionCheckbox
                  {...formikProps}
                  label={i18n.t('staff.scheduledShiftGroup.titleWeek1')}
                  name='listWeekDay'
                  options={sampleDataOfCheckbox}
                  inLine
                />
                <MultipleDimensionCheckbox
                  {...formikProps}
                  label={i18n.t('staff.scheduledShiftGroup.titleWeek2')}
                  name='listWeekDay1'
                  options={sampleDataOfCheckbox}
                  inLine
                />
                <MultipleDimensionCheckbox
                  {...formikProps}
                  label={i18n.t('staff.scheduledShiftGroup.titleWeek3')}
                  name='listWeekDay2'
                  options={sampleDataOfCheckbox}
                  inLine
                />
                <MultipleDimensionCheckbox
                  {...formikProps}
                  label={i18n.t('staff.scheduledShiftGroup.titleWeek4')}
                  name='listWeekDay3'
                  options={sampleDataOfCheckbox}
                  inLine
                />
                <MultipleDimensionCheckbox
                  {...formikProps}
                  label={i18n.t('staff.scheduledShiftGroup.titleWeek5')}
                  name='listWeekDay4'
                  options={sampleDataOfCheckbox}
                  inLine
                />
                <div className='erorr-monthly-repeat'>
                  <div className='alert'>
                    {touched.undefined && errors.undefined}
                  </div>
                </div>
              </>
            ) : null}
            <div className='tr'>
              <div className='th'>
                {i18n.t('staff.createDoctorManageHourlyWage.setTime')}
              </div>
              <div className='td'>
                {/* <div className='move-expenses-item__input '>
                </div> */}
                {/* <Space> */}
                <TimePicker.RangePicker
                  {...formikProps}
                  // className='hourly-picker'
                  value={[
                    // eslint-disable-next-line no-nested-ternary
                    values.time[0] != null
                      ? isCreateDefaultHourlyWage
                        ? moment(values.time[0]).utc()
                        : moment(values.time[0]).utc()
                      : null,
                    // eslint-disable-next-line no-nested-ternary
                    values.time[1] != null
                      ? isCreateDefaultHourlyWage
                        ? moment(values.time[1]).utc()
                        : moment(values.time[1]).utc()
                      : null,
                  ]}
                  name='time'
                  allowClear={false}
                  disabledHours={() => [0, 1, 2, 3, 4, 5, 6]}
                  hideDisabledOptions
                  placeholder={['開始時間', '終了時間']}
                  format='HH:mm'
                  onChange={(e, timeString) => {
                    if (isCreateDefaultHourlyWage) {
                      setFieldValue('time', [
                        moment(e[0]).add(9, 'hours'),
                        moment(e[1]).add(9, 'hours'),
                      ])
                    }
                    if (!isCreateDefaultHourlyWage) {
                      setFieldValue('time', [
                        moment(e[0])
                          .format()
                          .split('+')[0],
                        moment(e[1])
                          .format()
                          .split('+')[0],
                      ])
                    }
                  }}
                />
                {/* </Space> */}
                <ErrorMessage
                  name='time'
                  component='div'
                  className='color-red'
                />
              </div>
            </div>
            <div className='tr'>
              <div className='th'>
                {i18n.t('staff.createDoctorManageHourlyWage.setHourlyWage')}
              </div>

              <div className='td'>
                <InputNumber
                  placeholder='￥ 300'
                  formatter={value =>
                    `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  type='tel'
                  allowClear
                  size='large'
                  min={1}
                  name='hourlyWage'
                  style={{ width: '40%' }}
                  value={values.hourlyWage}
                  onChange={e => {
                    setFieldValue('hourlyWage', e)
                  }}
                  step={100}
                />
                <ErrorMessage
                  name='hourlyWage'
                  component='div'
                  className='color-red'
                />
              </div>
            </div>
            <div className=' move-center mt-30'>
              {isCreateDefaultHourlyWage ? (
                <Button
                  label={i18n.t(
                    'staff.createDoctorManageHourlyWage.button.create'
                  )}
                  className='reflect-button'
                  type='submit'
                  onClick={() => {
                    validateForm().then(response => {
                      if (!Object.keys(response).length) {
                        setConfirmMessage(
                          i18n.t(
                            'staff.createDoctorManageHourlyWage.confirmationMessage.create'
                          )
                        )
                        setFieldValue('confirm', true)
                        setFieldValue(
                          'action',
                          DEFAULT_HOURLY_WAGE_ACTION.CREATE
                        )
                      } else {
                        setTouched(response)
                      }
                    })
                  }}
                />
              ) : (
                <div className='modal-bottom'>
                  {isDeleteDefaultHourlyWage ? (
                    <Button
                      label={i18n.t(
                        'staff.createDoctorManageHourlyWage.button.delete'
                      )}
                      className='reflect-button'
                      type='submit'
                      icon='pi pi-trash'
                      onClick={() => {
                        validateForm().then(response => {
                          if (!Object.keys(response).length) {
                            setConfirmMessage(
                              i18n.t(
                                'staff.createDoctorManageHourlyWage.confirmationMessage.delete'
                              )
                            )
                            setFieldValue('confirm', true)
                            setFieldValue(
                              'action',
                              DEFAULT_HOURLY_WAGE_ACTION.DELETE
                            )
                          } else {
                            setTouched(response)
                          }
                        })
                      }}
                    />
                  ) : null}
                  {isUpdateDefaultHourlyWage ? (
                    <Button
                      label={i18n.t(
                        'staff.createDoctorManageHourlyWage.button.update'
                      )}
                      className=''
                      type='submit'
                      icon='pi pi-pencil'
                      onClick={() => {
                        validateForm().then(response => {
                          if (!Object.keys(response).length) {
                            setConfirmMessage(
                              i18n.t(
                                'staff.createDoctorManageHourlyWage.confirmationMessage.update'
                              )
                            )
                            setFieldValue('confirm', true)
                            setFieldValue(
                              'action',
                              DEFAULT_HOURLY_WAGE_ACTION.UPDATE
                            )
                          } else {
                            setTouched(response)
                          }
                        })
                      }}
                    />
                  ) : null}
                </div>
              )}
            </div>
            <div className='confirmation-moving'>
              <ConfirmationModal
                {...formikProps}
                confirmationMessage={confirmMessage}
              />
            </div>
          </div>
        )
      }}
    />
  )
}
export default DoctorHourlyWageForm
