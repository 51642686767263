import moment from 'moment'

export const setTimeSplitByDay = (day, type) => {
  const date = moment()
    .startOf('day')
    .hour(9)
  const split = {}
  const dayOfWeek = day === '0' ? '7' : day
  let typeInDaily
  switch (dayOfWeek) {
    case 7:
      typeInDaily = 'isAvailableOnSunday'
      break
    case 1:
      typeInDaily = 'isAvailableOnMonday'
      break
    case 2:
      typeInDaily = 'isAvailableOnTuesday'
      break
    case 3:
      typeInDaily = 'isAvailableOnWednesday'
      break
    case 4:
      typeInDaily = 'isAvailableOnThursday'
      break
    case 5:
      typeInDaily = 'isAvailableOnFriday'
      break
    case 6:
      typeInDaily = 'isAvailableOnSaturday'
      break
    case 8:
      typeInDaily = 'isAvailableOnHoliday'
      break

    default:
      break
  }
  split[`${dayOfWeek}`] = []

  split[`${dayOfWeek}`].push({
    startTime: date.toDate(),
    date: date
      .clone()
      .add(1, 'minute')
      .toDate(),
    hourlyWage: 0,
    isBreakTime: false,
    clinicalDepartment: '',
    type: type || typeInDaily,
  })
  return split
}
