import React from 'react'

const DetailAvailableShiftTemplateMail = (
  <>
    {`※初回ご勤務のご案内※
    <br/>この度は、キャップスクリニックの勤務にご応募下さり、誠にありがとうございます。
    <br/><br/>◆オンライン診療修了証について
    <br/>※既にご提出済の方へもご案内しております※
    <br/>└ワクチン専任業務またはクリニック外業務の場合はご登録必須ではありません
    <br/>現在、オンライン診療を希望されるご家族が増えております。
    <br/>つきましてはご勤務いただく先生方には、オンライン診療の受講をお願いしております。
    <br/>【未受講の方】
    <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;└ご案内させていただきますので「お問い合わせ」よりご連絡ください。
    <br/>【受講済みの方】
    <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;└医師免許証同様に確認させていただきますので、「お問い合わせ」より
    <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;修了証の画像を添付の上ご連絡ください。
    <br/><br/>◆各クリニックへのアクセス方法について
    <br/>各拠点へのアクセス方法こちらのURLをご参照ください。
    <br/>https://sites.google.com/view/caps365doctors/access
    <br/><br/>◆初回勤務日の出勤時について
    <br/>初勤務日は拠点スタッフより、ご挨拶を含め、電子カルテ操作などの簡単なオリエンテーション、クリニックの全体像、その日の診療体制や予約状況のご説明をさせていただきたく、診療開始の30分前（上記の初勤務集合時刻）にお越しください。オリエンテーションの30分もご勤務として、時間外勤務手当をお支払いいたします。
    <br/><br/>◆オリエンテーション動画について
    <br/>CAPSグループの代表兼、医療法人ナイズの理事長の白岡が、グループのミッションやビジョン等をご説明させていただいております。また、診断、処方、患者様とのコミュニケーションなどに関する治療方針、注意すべき保険病名のつけ方なども解説しております。必ずご勤務前のご視聴をお願いしております。
    <br/>https://www.youtube.com/watch?v=whmj03yRhzs`}
  </>
)

export default DetailAvailableShiftTemplateMail
