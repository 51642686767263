import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/react-hooks'
import { ErrorComponent, NotFound, ProgressSpinner } from '@medical/components'
import { objectTargetConstant } from '@medical/constant'
import {
  SHOW_MONEY_FIELDS,
  STAFF_ADD_COMMENT,
  STAFF_CREATE_WS,
  STAFF_DELETE_ALL_COMMENT,
  STAFF_DELETE_AS,
  STAFF_DELETE_COMMENT,
  STAFF_EDIT_AVAILABLE_SHIFT_THE_PAST,
  STAFF_UPDATE_AS,
  STAFF_UPDATE_RECRUITMENT_SHIFTS_STATUS,
} from '@medical/constant/permissions'
import {
  checkStaffPermission,
  clinicalDepartmentNames,
  convertDoctors,
  convertDoctorsConnection,
  splitLogic,
  waitFor,
} from '@medical/libs'
import { activityLogHistories } from '@medical/libs/activityLogHistory'
import { arrayNotEqual } from '@medical/libs/arrayNotEqual'
import { checkIsGoldWare } from '@medical/libs/checkIsGoldWare'
import { CREATE_ACTIVITY } from '@medical/pages/doctor/AvailableShift/AvailableShift.graphql'
import { CLINICS_DAILY_REPORT } from '@medical/pages/staff/DailyAvailableShift/DailyAvailableShift.graphql'
import { useCustom } from '@medical/provider/context'
import moment from 'moment'
import React, { useEffect } from 'react'

import { NO_DOCTOR_WORKED_IN_CLINIC } from '../Clinics/GuidanceMessage/GuidanceMessage.grapql'
import {
  AVAILABLE_SHIFT_DETAIL_GENERATOR,
  CONNECT_AVAILABLE_SHIFT_TO_DOCTOR,
  DELETE_AVAILABLE_SHIFT,
  DOCTORS_CONNECTION,
  UPDATE_AVAILABLE_SHIFT,
} from './DetailAvailableShift.graphql'
import DetailAvailableShiftScene from './DetailAvailableShiftScene'

const update = 'update'
const remove = 'remove'
const connectToDoctor = 'connectToDoctor'

const DetailAvailableShift = ({
  match: {
    params: { year, month, day, availableShiftId },
  },
  closeModal,
}) => {
  const activities = [
    {
      value: 'UPDATED_RECRUITMENT_SHIFTS_PUBLISHED',
      description: '掲載',
    },
    {
      value: 'UPDATED_RECRUITMENT_SHIFTS_NOT_PUBLISHED',
      description: '未掲載',
    },
    {
      value: 'UPDATE_RECRUITMENT_SHIFTS_PUBLIC_SPECIFIC_DOCTOR',
      description: '個別に設定',
    },
    {
      value: 'UPDATE_RECRUITMENT_SHIFTS_PUBLIC_WORK_PATTERN',
      description: '勤務形態で設定',
    },
  ]
  const updateActivities = [
    {
      value: 'CREATE_RECRUITMENT_SHIFTS',
      description: '個別募集シフト作成',
    },
    {
      value: 'UPDATE_RECRUITMENT_SHIFTS',
      description: '個別募集シフト更新',
    },
    {
      value: 'CREATE_RECRUITMENT_SHIFTS_GROUP',
      description: '定期募集シフト作成',
    },
    {
      value: 'UPDATE_RECRUITMENT_SHIFTS_GROUP',
      description: '定期募集シフト更新',
    },
  ]
  const [{ i18n, popup }] = useCustom()
  const client = useApolloClient()
  const [staffCreateActivity] = useMutation(CREATE_ACTIVITY)
  const mutationOptions = {
    refetchQueries: [
      {
        query: CLINICS_DAILY_REPORT,
        variables: {
          date: moment()
            .year(year)
            .month(month - 1)
            .date(day)
            .startOf('day'),
        },
        awaitRefetchQueries: true,
      },
    ],
  }
  const [updateAvailableShift] = useMutation(
    UPDATE_AVAILABLE_SHIFT,
    mutationOptions
  )
  const [deleteAvailableShift] = useMutation(
    DELETE_AVAILABLE_SHIFT,
    mutationOptions
  )
  const [connectAvailableShiftToDoctor] = useMutation(
    CONNECT_AVAILABLE_SHIFT_TO_DOCTOR,
    mutationOptions
  )
  const isMoneyPermitted = checkStaffPermission({
    functionName: SHOW_MONEY_FIELDS,
  })
  const isEditAvailableShiftThePast = checkStaffPermission({
    functionName: STAFF_EDIT_AVAILABLE_SHIFT_THE_PAST,
  })
  const isUpdatePermitted = checkStaffPermission({
    functionName: STAFF_UPDATE_AS,
  })
  const isDeletePermitted = checkStaffPermission({
    functionName: STAFF_DELETE_AS,
  })
  const isCreateWsPermitted = checkStaffPermission({
    functionName: STAFF_CREATE_WS,
  })
  const isAddCommentPermitted = checkStaffPermission({
    functionName: STAFF_ADD_COMMENT,
  })
  const isDeleteCommentPermitted = checkStaffPermission({
    functionName: STAFF_DELETE_COMMENT,
  })
  const isChangeRecruitmentShiftsStatusPermitted = checkStaffPermission({
    functionName: STAFF_UPDATE_RECRUITMENT_SHIFTS_STATUS,
  })
  const isDeleteAllComment = checkStaffPermission({
    functionName: STAFF_DELETE_ALL_COMMENT,
  })
  const { loading, error, data, refetch } = useQuery(
    AVAILABLE_SHIFT_DETAIL_GENERATOR(isMoneyPermitted),
    {
      variables: {
        availableShiftId,
        detailWhere: {
          AvailableShift: {
            id: availableShiftId,
          },
          deletedAt: null,
        },
      },
      fetchPolicy: 'network-only',
    }
  )

  const {
    loading: doctorsLoading,
    error: doctorsError,
    data: doctorsData,
  } = useQuery(DOCTORS_CONNECTION, {
    variables: {
      where: {
        deletedAt: null,
        registrationStatus_in: ['CREATED_BY_STAFF', 'ACCEPTED'],
        blocked: false,
      },
      first: 10,
      skip: 0,
      orderBy: 'unSigned_ASC',
    },
    fetchPolicy: 'network-only',
  })
  const [
    getNoDoctorWorked,
    {
      loading: noDoctorWorkedLoading,
      error: noDoctorWorkedError,
      data: noDoctorWorkedData,
    },
  ] = useLazyQuery(NO_DOCTOR_WORKED_IN_CLINIC)

  const isGoldWare = checkIsGoldWare()
  useEffect(() => {
    refetch()
  })
  if (loading || doctorsLoading) return <ProgressSpinner />
  if (error || doctorsError || noDoctorWorkedError)
    return (
      <ErrorComponent error={error || doctorsError || noDoctorWorkedError} />
    )
  const { availableShift, departments, activityHistories } = data
  if (!availableShift) {
    return <NotFound />
  }
  const {
    doctorsConnection: { edges: doctors },
  } = doctorsData
  const { staffComments, recruitmentCategory } = data.availableShift
  const staffCommentAvailableShiftGroup =
    data.availableShift.scheduledAvailableShiftGroup !== null
      ? data.availableShift.scheduledAvailableShiftGroup.staffComments
      : null
  const recruitmentCategoryScheduleGroup =
    data.availableShift.scheduledAvailableShiftGroup !== null
      ? data.availableShift.scheduledAvailableShiftGroup.recruitmentCategory
      : []
  const notEqual = (arg1, arg2, checkDateTime, checkTime) => {
    if (checkDateTime && arg1 && arg2) {
      const dateTime1 = moment(arg1).format('YYYY-MM-DD HH:mm')
      const dateTime2 = moment(arg2).format('YYYY-MM-DD HH:mm')
      return !moment(dateTime1).isSame(moment(dateTime2))
    } else if (checkTime && arg1 && arg2) {
      const minutes1 = moment.duration(moment(arg1).format('HH:mm')).asMinutes()
      const minutes2 = moment.duration(moment(arg2).format('HH:mm')).asMinutes()
      return !(minutes1 == minutes2)
    }
    return arg1 !== arg2
  }
  const checkScheduleChange = (
    startTime,
    endTime,
    hourlyWage,
    splitDateTime1,
    splitDateTime2,
    splitDateTime3,
    splitHourlyWage1,
    splitHourlyWage2,
    splitHourlyWage3,
    isStartTimeBreakTime,
    isSplitDateTime1BreakTime,
    isSplitDateTime2BreakTime,
    isSplitDateTime3BreakTime,
    comment,
    isPublished,
    isSpecial,
    isSetIndividually,
    objectTarget,
    workPattern,
    doctor
  ) => {
    return (
      (startTime && notEqual(availableShift.startTime, startTime, true)) ||
      (endTime && notEqual(availableShift.endTime, endTime, true)) ||
      !!(hourlyWage && notEqual(availableShift.hourlyWage, hourlyWage)) ||
      !!(
        splitDateTime1 &&
        notEqual(availableShift.splitDateTime1, splitDateTime1, false, true)
      ) ||
      !!(
        splitDateTime2 &&
        notEqual(availableShift.splitDateTime2, splitDateTime2, false, true)
      ) ||
      !!(
        splitDateTime3 &&
        notEqual(availableShift.splitDateTime3, splitDateTime3, false, true)
      ) ||
      !!(
        splitHourlyWage1 &&
        notEqual(availableShift.splitHourlyWage1, splitHourlyWage1)
      ) ||
      !!(
        splitHourlyWage2 &&
        notEqual(availableShift.splitHourlyWage2, splitHourlyWage2)
      ) ||
      !!(
        splitHourlyWage3 &&
        notEqual(availableShift.splitHourlyWage3, splitHourlyWage3)
      ) ||
      (isStartTimeBreakTime &&
        notEqual(availableShift.isStartTimeBreakTime, isStartTimeBreakTime)) ||
      (isSplitDateTime1BreakTime &&
        notEqual(
          availableShift.isSplitDateTime1BreakTime,
          isSplitDateTime1BreakTime
        )) ||
      (isSplitDateTime2BreakTime &&
        notEqual(
          availableShift.isSplitDateTime2BreakTime,
          isSplitDateTime2BreakTime
        )) ||
      (isSplitDateTime3BreakTime &&
        notEqual(
          availableShift.isSplitDateTime3BreakTime,
          isSplitDateTime3BreakTime
        )) ||
      notEqual(availableShift.comment, comment) ||
      notEqual(availableShift.isPublished, isPublished) ||
      notEqual(availableShift.isSpecial, isSpecial) ||
      notEqual(availableShift.isSetIndividually, isSetIndividually) ||
      notEqual(availableShift.applyWith, objectTarget) ||
      (objectTarget === objectTargetConstant.SET_FOR_EACH_WORKPARTTEN &&
        arrayNotEqual(
          JSON.parse(availableShift.workPatternApply),
          workPattern
        )) ||
      (objectTarget === objectTargetConstant.SET_FOR_EACH_DOCTOR &&
        arrayNotEqual(
          availableShift.availableShiftDetails.map(e => e.doctor.id),
          doctor.map(e => e.id)
        ))
    )
  }

  const hasChangeDoctorPublic = (
    isSetIndividually,
    objectTarget,
    workPattern,
    doctor
  ) =>
    notEqual(isSetIndividually, availableShift.isSetIndividually) ||
    notEqual(availableShift.applyWith, objectTarget) ||
    (objectTarget === objectTargetConstant.SET_FOR_EACH_WORKPARTTEN &&
      arrayNotEqual(
        JSON.parse(availableShift.workPatternApply),
        workPattern
      )) ||
    (objectTarget === objectTargetConstant.SET_FOR_EACH_DOCTOR &&
      arrayNotEqual(
        availableShift.availableShiftDetails.map(e => e.doctor.id),
        doctor.map(e => e.id)
      ))

  const submitFunctions = {
    [update]: ({
      startTime,
      endTime,
      hourlyWage,
      splitDateTime1,
      splitDateTime2,
      splitDateTime3,
      splitHourlyWage1,
      splitHourlyWage2,
      splitHourlyWage3,
      isStartTimeBreakTime,
      isSplitDateTime1BreakTime,
      isSplitDateTime2BreakTime,
      isSplitDateTime3BreakTime,
      comment,
      isPublished,
      splitComments,
      isSpecial,
      isEarlySpecial,
      isDoubleRecruitment,
      isSetIndividually,
      objectTarget,
      workPattern,
      specificDoctors,
      timeSettingHideShift,
      isSettingHideShift,
    }) => {
      let isOutGroup
      if (availableShift.recruitmentShiftsType === 'PERIODICAL') {
        isOutGroup = checkScheduleChange(
          startTime,
          endTime,
          hourlyWage,
          splitDateTime1,
          splitDateTime2,
          splitDateTime3,
          splitHourlyWage1,
          splitHourlyWage2,
          splitHourlyWage3,
          isStartTimeBreakTime,
          isSplitDateTime1BreakTime,
          isSplitDateTime2BreakTime,
          isSplitDateTime3BreakTime,
          comment,
          isPublished,
          isSpecial,
          isSetIndividually,
          objectTarget,
          workPattern,
          specificDoctors
        )
      }
      updateAvailableShift({
        variables: {
          availableShiftId,
          startTime,
          endTime,
          hourlyWage,
          splitDateTime1,
          splitDateTime2,
          splitDateTime3,
          splitHourlyWage1,
          splitHourlyWage2,
          splitHourlyWage3,
          isStartTimeBreakTime,
          isSplitDateTime1BreakTime,
          isSplitDateTime2BreakTime,
          isSplitDateTime3BreakTime,
          comment,
          isPublished:
            data.availableShift.isPublished !== isPublished
              ? isPublished
              : undefined,
          permissionAccess: isEditAvailableShiftThePast,
          staffComments: splitComments,
          isSpecial,
          isEarlySpecial,
          isDoubleRecruitment,
          isSetIndividually,
          applyWith:
            isSetIndividually && objectTarget ? objectTarget : undefined,
          workPatternApply:
            isSetIndividually &&
            objectTarget === objectTargetConstant.SET_FOR_EACH_WORKPARTTEN
              ? workPattern
              : undefined,
          listDoctorId:
            isSetIndividually &&
            objectTarget === objectTargetConstant.SET_FOR_EACH_DOCTOR
              ? specificDoctors.map(e => e.id)
              : undefined,
          isOutGroup,
          isChangeDoctorPublic: hasChangeDoctorPublic(
            isSetIndividually,
            objectTarget,
            workPattern,
            specificDoctors
          ),
          timeSettingHideShift: isSettingHideShift
            ? timeSettingHideShift
            : null,
        },
      })
    },
    [remove]: () => deleteAvailableShift({ variables: { availableShiftId } }),
    [connectToDoctor]: ({
      doctor: { id: doctorId },
      acceptedReason,
      type,
      introductionHistory,
      introductionHistoryOther,
      commentsAvailableShiftToDoctor,
    }) =>
      connectAvailableShiftToDoctor({
        variables: {
          availableShiftId,
          doctorId,
          acceptedReason: acceptedReason
            .replace(/<p>/g, '<span style="margin: 0">')
            .replace(/<\/p>/g, '</span><br/><br/>'),
          type,
          introductionHistory:
            introductionHistory !== '' ? introductionHistory : null,
          introductionHistoryOther,
          staffComments: commentsAvailableShiftToDoctor,
        },
      }),
  }

  const onSubmit = async (
    {
      functionName,
      date: submitDate,
      splits,
      acceptedReason,
      type,
      isSpecial,
      ...values
    },
    { setSubmitting, resetForm }
  ) => {
    let successMessage
    switch (functionName) {
      case update:
        successMessage = i18n.t('staff.availableShift.updateSubmissionSuccess')
        break
      case remove:
        successMessage = i18n.t('staff.availableShift.removeSubmissionSuccess')
        break
      case connectToDoctor:
        successMessage = i18n.t(
          'staff.availableShift.connectToDoctorSubmissionSuccess'
        )
        break

      default:
        break
    }
    try {
      const time = moment(values.dateSettingHideShift)
        .set('hour', moment(values.timeSettingHideShift).format('HH'))
        .set('minute', 0)
      const splitComments = []
      values.splitComments.map(value => {
        if (value.text !== '' && value.type !== '') {
          splitComments.push({
            text: value.text,
            type: value.type,
            staff: {},
          })
        }

        return splitComments
      })
      const commentsAvailableShiftToDoctor = []
      staffComments.map(value => {
        if (value.text !== '' && value.type !== '') {
          commentsAvailableShiftToDoctor.push({
            text: value.text,
            type: value.type,
            staff: {},
          })
        }
        return commentsAvailableShiftToDoctor
      })
      const args = splitLogic({ submitDate, splits })
      if (
        functionName === update &&
        !isEditAvailableShiftThePast &&
        moment(new Date()).format('YYYY/MM/DD') >
          moment(availableShift.startTime).format('YYYY/MM/DD')
      ) {
        popup.error('勤務日は過去の日付で設定できません。')
        setSubmitting(false)
      } else {
        await submitFunctions[functionName]({
          ...values,
          ...args,
          startTime: values.startTime,
          acceptedReason,
          type: type || null,
          splitComments,
          commentsAvailableShiftToDoctor,
          isSpecial,
          timeSettingHideShift: moment(time).utc(),
        })
        if (functionName === 'remove') {
          staffCreateActivity({
            variables: {
              activity: 'DELETE_RECRUITMENT_SHIFTS',
              details: `${moment(values.startTime).format(
                'YYYY年MM月DD日'
              )} ${moment(values.startTime).format('HH:mm')}~${moment(
                splits[splits.length - 1].date
              ).format('HH:mm')}`,
            },
          })
        }
        await waitFor(1000)
        popup.success(successMessage)
        setSubmitting(false)
        resetForm()
        closeModal()
      }
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }

  const {
    updateActivityHistories,
    postActivityHistories,
  } = activityLogHistories(activityHistories, activities, updateActivities)

  const isPeriodical = availableShift.recruitmentShiftsType === 'PERIODICAL'

  const departmentNames = clinicalDepartmentNames({
    departments,
  })
  const { staffMe } = data

  return (
    <DetailAvailableShiftScene
      i18n={i18n}
      popup={popup}
      availableShift={availableShift}
      doctors={convertDoctorsConnection(doctors)}
      doctorsList={convertDoctorsConnection(
        doctorsData.doctorsConnection.edges
      )}
      update={update}
      remove={remove}
      connectToDoctor={connectToDoctor}
      onSubmit={onSubmit}
      isMoneyPermitted={isMoneyPermitted}
      isUpdatePermitted={isUpdatePermitted}
      isDeletePermitted={isDeletePermitted}
      isCreateWsPermitted={isCreateWsPermitted}
      departmentNames={departmentNames}
      isGoldWare={isGoldWare}
      activityHistories={updateActivityHistories}
      updateHistories={postActivityHistories}
      isChangeRecruitmentShiftsStatusPermitted={
        isChangeRecruitmentShiftsStatusPermitted
      }
      isPeriodical={isPeriodical}
      staffComments={staffComments}
      staffMe={staffMe}
      isDeleteCommentPermitted={isDeleteCommentPermitted}
      isAddCommentPermitted={isAddCommentPermitted}
      availableShiftId={availableShiftId}
      staffCommentAvailableShiftGroup={staffCommentAvailableShiftGroup}
      convertDoctors={convertDoctors}
      client={client}
      getNoDoctorWorked={getNoDoctorWorked}
      countDoctorWorkedInClinic={noDoctorWorkedData?.countDoctorWorkedInClinic}
      isEditAvailableShiftThePast={
        !isEditAvailableShiftThePast &&
        moment(new Date()).format('YYYY/MM/DD') >
          moment(availableShift.startTime).format('YYYY/MM/DD')
      }
      recruitmentCategory={recruitmentCategory}
      recruitmentCategoryScheduleGroup={recruitmentCategoryScheduleGroup}
      isDeleteAllComment={isDeleteAllComment}
    />
  )
}

export default DetailAvailableShift
