import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import gql from 'graphql-tag'

export const STAFF_ME = gql`
  query STAFF_ME {
    staffMe {
      id
    }
  }
`
export const CREATE_SKILL = gql`
  mutation STAFF_CREATE_SKILL(
    $name: String!
    $createdBy: ID!
    $isShow: Boolean
  ) {
    staffCreateSkill(name: $name, createdBy: $createdBy, isShow: $isShow)
  }
`
