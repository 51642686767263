import { dateValidation } from '@medical/libs'
import Router from '@medical/routes/router'
import moment from 'moment'
import { Button } from 'primereact/button'
import queryString from 'query-string'
import React from 'react'
import { Link } from 'react-router-dom'

const ListOfIncentivesHeader = ({ date, nameS, incentiveS, titleS }) => {
  const prevMonth = moment(date).subtract(1, 'month')
  const nextMonth = moment(date).add(1, 'month')
  const isPrevDisabled = dateValidation({
    year: prevMonth.year(),
    month: prevMonth.month() + 1,
  })
  const isNextDisabled = nextMonth.isSameOrAfter(moment().add(12, 'month'))
  return (
    <div className='calendar-control-incentive'>
      <Link
        to={{
          pathname: Router.staffIncentive,
          search: queryString.stringify({
            year: prevMonth.year(),
            month: prevMonth.month() + 1,
            name: nameS,
            type: incentiveS,
            title: titleS,
          }),
        }}
        onClick={isPrevDisabled ? e => e.preventDefault() : undefined}
      >
        <Button
          icon='pi pi-chevron-left'
          style={{ fontSize: '14px' }}
          disabled={isPrevDisabled}
        />
      </Link>
      <div className='calendar-title'>{date.format('YYYY年M月')}</div>
      <Link
        to={{
          pathname: Router.staffIncentive,
          search: queryString.stringify({
            year: nextMonth.year(),
            month: nextMonth.month() + 1,
            name: nameS,
            type: incentiveS,
            title: titleS,
          }),
        }}
        onClick={isNextDisabled ? e => e.preventDefault() : undefined}
      >
        <Button
          icon='pi pi-chevron-right'
          style={{ fontSize: '14px' }}
          disabled={isNextDisabled}
        />
      </Link>
    </div>
  )
}
export default ListOfIncentivesHeader
