import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import { CLINICAL_DEPARTMENTS_QUERY } from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import gql from 'graphql-tag'

export const DOCTOR_CREATE_WAITING_SHIFT = gql`
  mutation DOCTOR_CREATE_WAITING_SHIFT(
    $clinics: [String!]
    $startDate: DateTime!
    $endDate: DateTime!
    $startTime: DateTime!
    $endTime: DateTime!
    $description: String!
  ) {
    doctorRecruitmentShift(
      clinics: $clinics
      startDate: $startDate
      endDate: $endDate
      startTime: $startTime
      endTime: $endTime
      description: $description
    )
  }
`

export const DOCTOR_UPDATE_WAITING_SHIFT = gql`
  mutation DOCTOR_UPDATE_WAITING_SHIFT(
    $doctorRecruitmentShiftUpdateId: ID!, 
    $startDate: DateTime!, 
    $endDate: DateTime!, 
    $startTime: DateTime!, 
    $endTime: DateTime!, 
    $description: String!, 
    $clinics: [String!]
    ) {
    doctorRecruitmentShiftUpdate(
      id: $doctorRecruitmentShiftUpdateId,
      startDate: $startDate,
      endDate: $endDate, 
      startTime: $startTime, 
      endTime: $endTime, 
      description: $description, 
      clinics: $clinics
    )
  }
`

export const CLINICAL_DEPARTMENTS_SETUP_QUERY = gql`
  query CLINICAL_DEPARTMENTS_QUERY {
    ...DEPARTMENTS_FRAGMENT
    ...CLINICAL_DEPARTMENTS_QUERY
  }
  ${DEPARTMENTS_FRAGMENT}
  ${CLINICAL_DEPARTMENTS_QUERY}
`

export const DOCTOR_DELETE_WAITING_RECRUITMENT_SHIFT = gql`
  mutation DoctorDeleteWaitingRecruitmentShift($id: ID!) {
    doctorRecruitmentDeleteShift(id: $id)
  }
`

export const WAITING_RECRUITMENT_QUERY = gql`
  query WaitingRecruitmentShiftsConnection(
    $where: WaitingRecruitmentShiftWhereInput
    $skip: Int
    $first: Int
  ) {
    waitingRecruitmentShiftsConnection(
      where: $where
      skip: $skip
      first: $first
    ) {
      edges {
        node {
          id
          createdAt
          updatedAt
          deletedAt
          startDate
          endDate
          active
          startTime
          endTime
          description

          recruitmentShifts {
            id
            clinic
          }
        }
      }
      aggregate(where: $where) {
        count
      }
    }
    ...DEPARTMENTS_FRAGMENT
    ...CLINICAL_DEPARTMENTS_QUERY
  }
  ${DEPARTMENTS_FRAGMENT}
  ${CLINICAL_DEPARTMENTS_QUERY}
`
