export const SET_CLICK_DATETIME_IN_AVAILABLE_SHIFT_FIRST_TIME =
  'SET_CLICK_DATETIME_IN_AVAILABLE_SHIFT_FIRST_TIME'
export const UPDATE_CANCELLATION_LETTER = 'UPDATE_CANCELLATION_LETTER'
export const UPDATE_RECRUITMENT_SHIFT = 'UPDATE_RECRUITMENT_SHIFT'
export const setClickDateTimeFirstTime = payload => dispatch => {
  dispatch({
    type: SET_CLICK_DATETIME_IN_AVAILABLE_SHIFT_FIRST_TIME,
    payload,
  })
}

export const setUpdateCancellationLetter = payload => dispatch => {
  dispatch({
    type: UPDATE_CANCELLATION_LETTER,
    payload,
  })
}
export const setIsUpdateRecruitmentShift = payload => dispatch => {
  dispatch({
    type: UPDATE_RECRUITMENT_SHIFT,
    payload,
  })
}

