import Moment from 'moment'
import { extendMoment } from 'moment-range'

const moment = extendMoment(Moment)

export const thisRangeHolidays = ({ holidaysData, startDate, endDate }) => {
  const holiday = []
  const startTime = moment(startDate).startOf('day')
  const endTime = moment(endDate).endOf('day')
  const itemRange = moment.range(startTime, endTime)

  for (let j = 0; j < holidaysData.length; j += 1) {
    const startEvent = moment(holidaysData[j].start.date)
    if (itemRange.contains(startEvent)) {
      holiday.push({
        date: holidaysData[j].start.date,
        summary: holidaysData[j].summary,
      })
    }
  }
  return holiday
}
