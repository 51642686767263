import moment from 'moment'

export const convertRevers = availableShift => {
  const {
    endTime,
    splitDateTime1,
    splitDateTime2,
    splitDateTime3,
    hourlyWage,
    splitHourlyWage1,
    splitHourlyWage2,
    splitHourlyWage3,
    isStartTimeBreakTime,
    isSplitDateTime1BreakTime,
    isSplitDateTime2BreakTime,
    isSplitDateTime3BreakTime,
  } = availableShift
  // const endTimeFormat = endTime.split('.')[0]
  // const splitDateTime1Format = splitDateTime1
  //   ? splitDateTime1.split('.')[0]
  //   : splitDateTime1
  // const splitDateTime2Format = splitDateTime2
  //   ? splitDateTime2.split('.')[0]
  //   : splitDateTime2
  // const splitDateTime3Format = splitDateTime3
  //   ? splitDateTime3.split('.')[0]
  //   : splitDateTime3
  const splits = []
  if (splitDateTime1) {
    splits.push({
      date: moment(splitDateTime1).toDate(),
      hourlyWage: splitHourlyWage1,
      isBreakTime: isSplitDateTime1BreakTime,
    })
  }
  if (splitDateTime2) {
    splits.push({
      date: moment(splitDateTime2).toDate(),
      hourlyWage: splitHourlyWage2,
      isBreakTime: isSplitDateTime2BreakTime,
    })
  }
  if (splitDateTime3) {
    splits.push({
      date: moment(splitDateTime3).toDate(),
      hourlyWage: splitHourlyWage3,
      isBreakTime: isSplitDateTime3BreakTime,
    })
  }
  splits.push({
    date: moment(endTime).toDate(),
    hourlyWage,
    isBreakTime: isStartTimeBreakTime,
  })

  return splits
}

export const convertDayInMonthToMatrix = (arr, arr1, arr2, arr3, arr4) => {
  if (arr && arr1 && arr2 && arr3 && arr4) {
    return [
      [
        arr.includes('monday'),
        arr.includes('tuesday'),
        arr.includes('wednesday'),
        arr.includes('thursday'),
        arr.includes('friday'),
        arr.includes('saturday'),
        arr.includes('sunday'),
      ],
      [
        arr1.includes('monday'),
        arr1.includes('tuesday'),
        arr1.includes('wednesday'),
        arr1.includes('thursday'),
        arr1.includes('friday'),
        arr1.includes('saturday'),
        arr1.includes('sunday'),
      ],
      [
        arr2.includes('monday'),
        arr2.includes('tuesday'),
        arr2.includes('wednesday'),
        arr2.includes('thursday'),
        arr2.includes('friday'),
        arr2.includes('saturday'),
        arr2.includes('sunday'),
      ],
      [
        arr3.includes('monday'),
        arr3.includes('tuesday'),
        arr3.includes('wednesday'),
        arr3.includes('thursday'),
        arr3.includes('friday'),
        arr3.includes('saturday'),
        arr3.includes('sunday'),
      ],
      [
        arr4.includes('monday'),
        arr4.includes('tuesday'),
        arr4.includes('wednesday'),
        arr4.includes('thursday'),
        arr4.includes('friday'),
        arr4.includes('saturday'),
        arr4.includes('sunday'),
      ],
    ]
  }
  return []
}

const weekDay = [
  {
    value: 'monday',
    index: 0,
  },
  {
    value: 'tuesday',
    index: 1,
  },
  {
    value: 'wednesday',
    index: 2,
  },
  {
    value: 'thursday',
    index: 3,
  },
  {
    value: 'friday',
    index: 4,
  },
  {
    value: 'saturday',
    index: 5,
  },
  {
    value: 'sunday',
    index: 6,
  },
]
export const convertBooleanToDay = data => {
  const result = []
  if (Array.isArray(data)) {
    for (let i = 0; i < data.length; i += 1) {
      if (data[i]) {
        result.push(weekDay[i].value)
      }
    }
  }
  return result
}
