import moment from 'moment'

const convertUserCsvRecord = data =>
  data.map(
    ({
      email,
      date: rowDate,
      startTime,
      endTime,
      splitDateTime1,
      splitDateTime2,
      splitDateTime3,
      hourlyWage,
      splitHourlyWage1,
      splitHourlyWage2,
      splitHourlyWage3,
      comment,
      isStartTimeBreakTime,
      isSplitDateTime1BreakTime,
      isSplitDateTime2BreakTime,
      isSplitDateTime3BreakTime,
      numberOfRooms,
      departmentCode,
      delete: isDelete,
    }) => {
      const rowMomentDate = moment(rowDate, 'YYYY-MM-DD')
      const years = rowMomentDate.year()
      const months = rowMomentDate.month()
      const date = rowMomentDate.date()
      const momentStartTime = moment({
        years,
        months,
        date,
        hour: moment(startTime, 'HH:mm').hour(),
        minute: moment(startTime, 'HH:mm').minute(),
      })
      const momentEndTime = moment({
        years,
        months,
        date,
        hour: moment(endTime, 'HH:mm').hour(),
        minute: moment(endTime, 'HH:mm').minute(),
      })
      const momentSplitDateTime1 = moment({
        years,
        months,
        date,
        hour: moment(splitDateTime1, 'HH:mm').hour(),
        minute: moment(splitDateTime1, 'HH:mm').minute(),
      })
      const momentSplitDateTime2 = moment({
        years,
        months,
        date,
        hour: moment(splitDateTime2, 'HH:mm').hour(),
        minute: moment(splitDateTime2, 'HH:mm').minute(),
      })
      const momentSplitDateTime3 = moment({
        years,
        months,
        date,
        hour: moment(splitDateTime3, 'HH:mm').hour(),
        minute: moment(splitDateTime3, 'HH:mm').minute(),
      })
      return {
        numberOfRooms: parseInt(numberOfRooms, 10),
        email,
        departmentCode,
        startTime: startTime ? momentStartTime : undefined,
        endTime: endTime ? momentEndTime : undefined,
        hourlyWage: hourlyWage ? parseInt(hourlyWage, 10) : undefined,
        splitDateTime1: splitDateTime1 ? momentSplitDateTime1 : undefined,
        splitDateTime2: splitDateTime2 ? momentSplitDateTime2 : undefined,
        splitDateTime3: splitDateTime3 ? momentSplitDateTime3 : undefined,
        isStartTimeBreakTime: isStartTimeBreakTime === 'TRUE' ? true : false,
        isSplitDateTime1BreakTime:
          isSplitDateTime1BreakTime === 'TRUE' ? true : false,
        isSplitDateTime2BreakTime:
          isSplitDateTime2BreakTime === 'TRUE' ? true : false,
        isSplitDateTime3BreakTime:
          isSplitDateTime3BreakTime === 'TRUE' ? true : false,
        splitHourlyWage1: splitHourlyWage1
          ? parseInt(splitHourlyWage1, 10)
          : undefined,
        splitHourlyWage2: splitHourlyWage2
          ? parseInt(splitHourlyWage2, 10)
          : undefined,
        splitHourlyWage3: splitHourlyWage3
          ? parseInt(splitHourlyWage3, 10)
          : undefined,
        comment: comment || undefined,
        isDelete: isDelete === '1',
      }
    }
  )
export default convertUserCsvRecord
