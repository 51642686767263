import { DOCTOR_FRAGMENTS } from '@medical/layout/DoctorLayout/DoctorLayoutRequest.graphql'
import { FILE_FRAGMENTS } from '@medical/libs/general.graphql'
import gql from 'graphql-tag'

export const DOCTOR_UPLOAD_FILE = gql`
  mutation DOCTOR_UPLOAD_FILE(
    $file: String!
    $fileCategory: FileCategory!
    $fileId: ID
    $filename: String
    $mimetype: String
  ) {
    doctorUpsertFile(
      file: $file
      fileCategory: $fileCategory
      fileId: $fileId
      filename: $filename
      mimetype: $mimetype
    ) {
      ...DOCTOR_FRAGMENTS
      files {
        ...FILE_FRAGMENTS
      }
    }
  }
  ${DOCTOR_FRAGMENTS}
  ${FILE_FRAGMENTS}
`
