/* eslint-disable array-callback-return */
import '@medical/pages/staff/Incentive/IncentiveSetting/component/styles.css'

import ConfirmationModal from '@medical/components/ConfirmationModal'
import TextInput from '@medical/components/TextInput'
import { InputNumber } from 'antd'
import { ErrorMessage, Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import React from 'react'
import * as Yup from 'yup'

const DetailOfIncentivesScene = ({
  onSubmit,
  i18n,
  data,
  isUpdatePermitted,
}) => {
  if (!data[0]) {
    return 'ご指定の月はデータがありません。'
  }
  const validationSchema = Yup.object().shape({
    cost: Yup.number()
      .nullable()
      .required(
        `${i18n.t('transportApplication.cost')}${i18n.t('validation.required')}`
      ),
  })
  const incentiveType = [
    {
      name: i18n.t('main.incentiveSetting.normalIncentive'),
      value: 'NORMAL_INCENTIVE',
    },
    {
      name: i18n.t('main.incentiveSetting.numberIncentive'),
      value: 'NUMBER_INCENTIVE',
    },
    {
      name: i18n.t('main.incentiveSetting.beyound8Incentive'),
      value: 'BEYOND_EIGHT_INCENTIVE',
    },
    {
      name: i18n.t('main.incentiveSetting.timeIncentive'),
      value: 'TIME_INCENTIVE',
    },
    {
      name: i18n.t('main.incentiveSetting.satisfactionIncentive'),
      value: 'SATISFACTION_INCENTIVE',
    },
  ]
  const getIncentiveType = () => {
    let rs
    const incentiveS = data[0].node.incentive.type
    if (incentiveS) {
      incentiveType.map(as => {
        if (as.value === incentiveS) {
          rs = as
        }
      })
    }
    return rs
  }
  const getIncentiveTitle = () => data[0].node.incentive.title
  const getIncentiveCost = () => data[0].node.incentiveTotalAmount
  const isEdit = () => {
    const applyTime = moment(data[0].node.applyTime)
    if (
      moment()
        .subtract(2, 'month')
        .isSameOrAfter(applyTime)
    ) {
      return false
    }
    if (
      applyTime.year() ===
        moment()
          .subtract(1, 'month')
          .year() &&
      applyTime.month() ===
        moment()
          .subtract(1, 'month')
          .month()
    ) {
      if (
        moment().date() <= 25 &&
        data[0].node.incentive.type === 'NORMAL_INCENTIVE'
      ) {
        return true
      }
      if (moment().date() < 15 || moment().date() > 25) {
        return false
      }
    }
    return true
  }
  return (
    <Formik
      enableReinitialize
      initialValues={{
        confirm: false,
        title: getIncentiveTitle(),
        incentiveType: getIncentiveType(),
        cost: getIncentiveCost(),
        doctorId: data[0].node.doctor.id,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={formikProps => {
        const { setFieldValue, values, validateForm, setTouched } = formikProps
        return (
          <div className=' container'>
            <div className='row incentive-container'>
              <div className='hourly-title col-md-12'>
                インセンティブ設定編集
              </div>
            </div>
            <div className='tr'>
              <div className='th'>{i18n.t('main.incentives.name')}</div>
              <div className='td'>{`${data[0].node.doctor.lastname} ${data[0].node.doctor.firstname}`}</div>
            </div>
            <div className='tr'>
              <div className='th'>
                {i18n.t('main.incentiveSetting.incentiveType')}
              </div>
              <div className='td'>
                <Dropdown
                  name='incentiveType'
                  options={incentiveType}
                  optionLabel='name'
                  value={values.incentiveType}
                  disabled
                />
                <ErrorMessage
                  name='incentiveType'
                  component='div'
                  className='color-red'
                />
              </div>
            </div>
            <TextInput
              {...formikProps}
              name='title'
              value={values.title}
              disabled
              label={i18n.t('main.incentives.searchTitle')}
              inputMinWidth='50%'
            />
            <div className='tr'>
              <div className='th'>{i18n.t('main.incentives.cost')}</div>
              <div className='td'>
                <InputNumber
                  placeholder='￥ 0'
                  formatter={value =>
                    `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  type='tel'
                  allowClear
                  size='middle'
                  name='cost'
                  style={{ width: '50%', borderColor: '#978787' }}
                  value={values.cost}
                  onChange={e => {
                    setFieldValue('cost', e)
                  }}
                  inputWidth='50%'
                  step={100}
                  min={1}
                />
                <ErrorMessage
                  name='cost'
                  component='div'
                  className='color-red'
                />
              </div>
            </div>
            {isEdit() && isUpdatePermitted ? (
              <div className='modal-bottom'>
                <Button
                  label='更新する'
                  className='reflect-button'
                  style={{ borderColor: '#978787' }}
                  onClick={() => {
                    validateForm().then(response => {
                      if (!Object.keys(response).length) {
                        setFieldValue('confirm', true)
                        setFieldValue('incentiveDetailId', data[0].node.id)
                        setFieldValue('incentiveTotalAmount', values.cost)
                      } else {
                        setTouched(response)
                      }
                    })
                  }}
                />
              </div>
            ) : null}
            <ConfirmationModal
              {...formikProps}
              confirmationMessage='インセンティブ設定を更新しますか？'
            />
          </div>
        )
      }}
    />
  )
}

export default DetailOfIncentivesScene
