import { InputTextarea as PrimeInputTextarea } from 'primereact/inputtextarea'
import { pathOr } from 'ramda'
import React from 'react'

const InputTextarea = ({
  values,
  handleChange,
  handleBlur,
  name,
  errors,
  touched,
  disabled,
  width,
  label,
  classDisabled,
  rows,
  autoResize,
  padding,
  fontSize,
  myNumberDetail,
  onlyStaff,
}) => {
  const keys = name.split('.')
  const value = pathOr('', keys, values)
  const isTouched = pathOr(false, keys, touched)
  const error = pathOr('', keys, errors)
  return (
    <div className='tr'>
      {label && (
        <div
          className={`th ${onlyStaff ? 'onlyStaff' : ''}`}
          style={{ width, padding }}
        >
          {label}
        </div>
      )}
      <div className='td' style={{ padding }}>
        <PrimeInputTextarea
          rows={rows || 5}
          style={{ resize: 'none', width: '100%', padding: 0, fontSize }}
          name={name}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={disabled}
          className={classDisabled && disabled ? 'is-disabled' : ''}
          autoResize={!!autoResize}
        />
        {myNumberDetail ? null : (
          <div className='alert'>{isTouched && error}</div>
        )}
      </div>
    </div>
  )
}

export default InputTextarea
