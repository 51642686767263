import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { dateValidation } from '@medical/libs'
import workScheduleToEvents from '@medical/libs/workScheduleToEvents'
import Router from '@medical/routes/router'
import moment from 'moment'
import React, { useRef } from 'react'
import { Redirect } from 'react-router-dom'

import { DOCTOR_SCHEDULES } from './Schedule.graphql'
import ScheduleScene from './ScheduleScene'

const ScheduleContainer = ({
  history,
  match: {
    url,
    path,
    params: { year = moment().year(), month = moment().month() + 1 },
  },
}) => {
  const startDate = moment()
    .year(year)
    .month(month - 1)
    .startOf('month').toISOString()
  const endDate = moment(startDate).clone().add(1, 'month').toISOString()
  const { loading, error, data } = useQuery(DOCTOR_SCHEDULES, {
    variables: {
      startDate,
      endDate,
    },
    fetchPolicy: 'network-only',
  })

  const calendar = useRef({})

  if (
    dateValidation({ year, month }) ||
    [
      Router.doctorSchedulesWithoutYearMonth,
      Router.doctorSchedulesWithoutMonth,
    ].includes(path)
  )
    return (
      <Redirect
        to={Router.get(Router.doctorSchedules, {
          year: moment().year(),
          month: moment().month() + 1,
        })}
      />
    )

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const eventClick = arg => {
    history.push({
      pathname: `${url}${Router.get(Router.doctorSingleSchedule, {
        workScheduleId: arg.event.id,
      })}`,
    })
  }

  const events = workScheduleToEvents(data.doctorMe.workSchedules)
  return (
    <ScheduleScene
      eventClick={eventClick}
      events={events}
      calendar={calendar}
      date={startDate}
    />
  )
}

export default ScheduleContainer
