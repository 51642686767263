/* eslint-disable jsx-a11y/label-has-associated-control */
import i18n from '@medical/libs/I18n/i18n'
import { DatePicker } from 'antd'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { pathOr } from 'ramda'
import React, { useState } from 'react'

const styles = {
  checkBoxList_filter: {
    display: 'flex',
    marginBottom: '1rem',
  },
  checkBoxList_wrapper: {
    position: 'unset',
  },
  checkBoxList_body: {
    overflowY: 'auto',
    maxHeight: '300px',
  },
  mr_3: {
    marginRight: '1rem',
  },
  ml_3: {
    marginLeft: '1rem',
  },
}

const CheckBoxList = ({
  disabled,
  values,
  touched,
  errors,
  setFieldValue,
  label,
  name,
  options,
  descriptionLabel = 'description',
  valueLabel = 'value',
  checkedAll = false,
  locale,
}) => {
  const [isCheckedAll, setIsCheckedAll] = useState(checkedAll)
  const [optionsHandled, setOptionHandled] = useState(options)
  const [isFiltered, setIsFiltered] = useState(false)
  const isTouched = pathOr(false, [name], touched)
  const error = pathOr('', [name], errors)

  const handleCheckItem = (e, item) => {
    if (e.checked) {
      const selectedOptions = values[name].concat([item[valueLabel]])
      setFieldValue(name, selectedOptions)
    } else {
      const selectedOptions = values[name].filter(
        opt => opt !== item[valueLabel]
      )
      setFieldValue(name, selectedOptions)
    }
  }

  const handleCheckAll = e => {
    setIsCheckedAll(e.checked)
    if (e.checked) {
      setFieldValue(
        name,
        optionsHandled.map(opt => opt.value)
      )
    } else {
      setFieldValue(name, [])
    }
  }

  const handleFilter = (_, dateSelectedStr) => {
    const optFilteredList = options.filter(opt => opt.date === dateSelectedStr)
    setOptionHandled(optFilteredList)
    setIsFiltered(true)
  }

  const clearFilter = () => {
    setOptionHandled(options)
    setIsFiltered(false)
  }

  const checkBoxList = optionsHandled.map(item => (
    <div className='checkbox-container' key={item[valueLabel]}>
      <Checkbox
        value={item[valueLabel]}
        checked={values[name].includes(item[valueLabel])}
        name={name}
        disabled={item['isDisable'] || disabled}
        onChange={e => handleCheckItem(e, item)}
      />
      <label
        style={styles.ml_3}
        htmlFor={`${item[valueLabel]}`}
        className='checkbox-label'
      >
        {!item.isCancel
          ? item[descriptionLabel]
          : `${item[descriptionLabel]} (キャンセル)`}
      </label>
    </div>
  ))

  if (options.length === 0) {
    return (
      <div className='tr'>
        <div className='th'>{label}</div>
        <div className='td'>{i18n.t('main.noRecord')}</div>
      </div>
    )
  }

  return (
    <div className='tr'>
      <div className='th'>
        <p style={{ marginBottom: '0' }}>{label}</p>
        <p
          className='asterisk-label-head '
          style={{ marginBottom: '0', fontWeight: '300', fontSize: '12px' }}
        >
          {i18n.t('staff.scheduledShiftGroup.noteEditedIndividually1')}
        </p>
        <p style={{ marginBottom: '0', fontWeight: '300', fontSize: '12px' }}>
          {i18n.t('staff.scheduledShiftGroup.noteEditedIndividually2')}
        </p>
      </div>
      <div className='td' style={styles.checkBoxList_wrapper}>
        {(optionsHandled.length >= 10 || isFiltered) && (
          <div style={styles.checkBoxList_filter}>
            <div className='checkbox-container' style={styles.mr_3}>
              <Checkbox checked={isCheckedAll} onChange={handleCheckAll} />
            </div>
            <DatePicker
              style={styles.mr_3}
              onChange={handleFilter}
              locale={locale}
              allowClear={false}
            />
            {isFiltered && <Button icon='pi pi-times' onClick={clearFilter} />}
          </div>
        )}

        {optionsHandled.length ? (
          <div style={styles.checkBoxList_body}>{checkBoxList}</div>
        ) : (
          i18n.t('main.noRecord')
        )}

        <div className='alert'>{isTouched && error}</div>
      </div>
    </div>
  )
}

export default CheckBoxList
