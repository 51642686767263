import gql from 'graphql-tag'
import { DOCTOR_FRAGMENTS } from '@medical/layout/DoctorLayout/DoctorLayoutRequest.graphql'

export const LIST_DOCTOR_CAR_MOVING_EXPENSE = gql`
  query DOCTORS_CONNECTION(
    $where: DoctorWhereInput
    $first: Int
    $skip: Int
    $orderBy: DoctorOrderByInput
  ) {
    doctorsConnection(
      where: $where
      first: $first
      skip: $skip
      orderBy: $orderBy
    ) {
      edges {
        node {
          id
          email
          firstname
          lastname
          departments
          nearestStations
          placeOfWork
          isBlockedMyCar
          homeAddress {
            id
            address1
            address2
            stateOrRegion
            updatedAt
            applicableDate
          }
        }
      }
      aggregate(where: $where) {
        count
      }
    }
  }
`
export const DOCTOR_GET_DISTANCE_CLINIC = gql`
  query Doctor(
    $where: DoctorWhereUniqueInput!
    $orderBy: DoctorAddressHistoryOrderByInput
    $first: Int
    $skip: Int
  ) {
    doctor(where: $where) {
      id
      fullName
      doctorAddressHistories(orderBy: $orderBy, first: $first, skip: $skip) {
        id
        dataTransportation
      }
    }
  }
`
export const GET_GASOLINE_PRICE = gql`
  query GET_GASOLINE_PRICE(
    $skip: Int
    $first: Int
    $where: GasolinePriceWhereInput
    $orderBy: GasolinePriceOrderByInput
  ) {
    gasolinePrices(
      skip: $skip
      first: $first
      where: $where
      orderBy: $orderBy
    ) {
      id
      price
      maxPrice
      startTime
    }
  }
`
export const STAFF_UPDATE_DATA_TRANSPORTATION = gql`
  mutation STAFF_UPDATE_DATA_TRANSPORTATION(
    $doctorId: ID!
    $dataTransportation: String
  ) {
    staffUpdateDataTransportation(
      doctorId: $doctorId
      dataTransportation: $dataTransportation
    ) {
      id
    }
  }
`
export const STAFF_BLOCK_CAR_DOCTOR = gql`
  mutation STAFF_BLOCK_CAR_DOCTOR(
    $doctorId: ID!
    $email: String!
    $isBlockedMyCar: Boolean
    $isReCalculateMyCar: Boolean
  ) {
    staffBlockDoctorMyCar(
      doctorId: $doctorId
      email: $email
      isBlockedMyCar: $isBlockedMyCar
      isReCalculateMyCar: $isReCalculateMyCar
    ) {
      ...DOCTOR_FRAGMENTS
    }
  }
  ${DOCTOR_FRAGMENTS}
`
