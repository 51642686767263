import gql from 'graphql-tag'

export const CREATE_RECRUITMENT_POST = gql`
  mutation CREATE_RECUITMENT_POST(
    $title: String!
    $content: String!
    $recruitmentDoctorType: WorkPattern!
    $isPublished: Boolean!
    $clinicalDepartmentId: ID!
    $image: String
    $imageKeyCopy: String
  ) {
    staffCreateRecruitmentPost(
      title: $title
      content: $content
      recruitmentDoctorType: $recruitmentDoctorType
      isPublished: $isPublished
      clinicalDepartmentId: $clinicalDepartmentId
      image: $image
      imageKeyCopy: $imageKeyCopy
    )
  }
`
