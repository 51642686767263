/* eslint-disable no-nested-ternary */
import CheckBoxList from '@medical/components/CheckBoxList'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import TextInput from '@medical/components/TextInput'
import { TYPE_CREATE, SHCEDULE_SHIFT_ACTION } from '@medical/constant'
import {
  splitLogicReverse,
  thisRangeHolidays,
  setTimeSplitByDay,
} from '@medical/libs'
import ShiftGroupFormSplits from '@medical/pages/staff/components/ShiftGroupFormSplits'
import { Checkbox, DatePicker, InputNumber, Switch } from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import { ErrorMessage, FieldArray, Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import * as Yup from 'yup'
import { isHolidayInRangeDate } from '@medical/libs/isHolidayInRangeDate'
import { pathOr } from 'ramda'
import ShiftGroupFormRepeatSetting from '../components/ShiftGroupFormRepeatSetting'
import { TabPanel, TabView } from 'primereact/tabview'
import React, { useState } from 'react'

import { WorkScheduleComment } from '../DetailWorkSchedule/WorkScheduleComment'
import { getEmailTemplate } from '@medical/pages/staff/CreateScheduledShiftGroup/templateEmail'
import Router from '@medical/routes/router'
import { Link } from 'react-router-dom'

const DetailScheduleShiftGroupScene = ({
  i18n,
  // availableShift = {},
  onSubmit,
  isUpdatePermitted,
  isDeletePermitted,
  scheduledAvailableShiftGroup,
  clinicalDepartments,
  availableShiftList,
  activitiesHistory,
  postActivitiesHistory,
  staffComments,
  isAddCommentPermitted,
  isDeleteCommentPermitted,
  scheduledShiftGroupId,
  isMoneyPermitted,
  staffMe,
  holidaysData,
  doctor,
  popup,
  isChangeConfirmedShiftsStatusPermitted,
  isDeleteAllComment
}) => {
  let isHappen
  for (let i = 0; i < availableShiftList.length; i += 1) {
    if (availableShiftList[i].deletedAt === null) {
      if (moment(availableShiftList[i].startTime) - moment(new Date()) < 0) {
        isHappen = true
        break
      }
    }
  }
  const disabledDate = current => {
    const min = moment().subtract(1, 'days')
    const max = moment().add(1, 'years')
    return !(min.isSameOrBefore(current) && max.isAfter(current))
  }
  const disableDateUpdate = current => {
    const min = moment(scheduledAvailableShiftGroup.startDate)
    const dateAdd = moment(scheduledAvailableShiftGroup.endDate).diff(
      moment(),
      'days'
    )
    const max = moment().add(dateAdd, 'days')
    if (moment().isAfter(scheduledAvailableShiftGroup.endDate)) {
      return current && current
    }
    return !(min.isSameOrBefore(current) && current.isSameOrBefore(max))
  }
  const renderAvailableDay = (
    isAvailableOnMonday,
    isAvailableOnTuesday,
    isAvailableOnWednesday,
    isAvailableOnThursday,
    isAvailableOnFriday,
    isAvailableOnSaturday,
    isAvailableOnSunday,
    isAvailableOnHoliday
  ) => {
    let availableDay = ''
    if (
      !isAvailableOnMonday ||
      !isAvailableOnTuesday ||
      !isAvailableOnWednesday ||
      !isAvailableOnThursday ||
      !isAvailableOnFriday
    ) {
      if (isAvailableOnMonday) availableDay += '月'
      if (isAvailableOnTuesday) availableDay += '火'
      if (isAvailableOnWednesday) availableDay += '水'
      if (isAvailableOnThursday) availableDay += '木'
      if (isAvailableOnFriday) availableDay += '金'
      if (isAvailableOnSaturday) availableDay += '土'
      if (isAvailableOnSunday) availableDay += '日'
      if (isAvailableOnHoliday) availableDay += '祝'
    } else {
      availableDay = '平日'
      if (isAvailableOnSaturday) availableDay += '土'
      if (isAvailableOnSunday) availableDay += '日'
      if (isAvailableOnHoliday) availableDay += '祝'
    }
    return availableDay
  }

  const validationSchema = Yup.object()
    .shape({
      name: Yup.string().required(`${i18n.t('validation.required')}`),
      splits: Yup.object().shape({
        7: Yup.array().of(
          Yup.object().shape({
            clinicalDepartmentId: Yup.string().required(
              i18n.t('validation.clinicalDepartment.required')
            ),
          })
        ),
        1: Yup.array().of(
          Yup.object().shape({
            clinicalDepartmentId: Yup.string().required(
              i18n.t('validation.clinicalDepartment.required')
            ),
          })
        ),
        2: Yup.array().of(
          Yup.object().shape({
            clinicalDepartmentId: Yup.string().required(
              i18n.t('validation.clinicalDepartment.required')
            ),
          })
        ),
        3: Yup.array().of(
          Yup.object().shape({
            clinicalDepartmentId: Yup.string().required(
              i18n.t('validation.clinicalDepartment.required')
            ),
          })
        ),
        4: Yup.array().of(
          Yup.object().shape({
            clinicalDepartmentId: Yup.string().required(
              i18n.t('validation.clinicalDepartment.required')
            ),
          })
        ),
        5: Yup.array().of(
          Yup.object().shape({
            clinicalDepartmentId: Yup.string().required(
              i18n.t('validation.clinicalDepartment.required')
            ),
          })
        ),
        6: Yup.array().of(
          Yup.object().shape({
            clinicalDepartmentId: Yup.string().required(
              i18n.t('validation.clinicalDepartment.required')
            ),
          })
        ),
        '7_Hol': Yup.array().of(
          Yup.object().shape({
            clinicalDepartmentId: Yup.string().required(
              i18n.t('validation.clinicalDepartment.required')
            ),
          })
        ),
        '1_Hol': Yup.array().of(
          Yup.object().shape({
            clinicalDepartmentId: Yup.string().required(
              i18n.t('validation.clinicalDepartment.required')
            ),
          })
        ),
        '2_Hol': Yup.array().of(
          Yup.object().shape({
            clinicalDepartmentId: Yup.string().required(
              i18n.t('validation.clinicalDepartment.required')
            ),
          })
        ),
        '3_Hol': Yup.array().of(
          Yup.object().shape({
            clinicalDepartmentId: Yup.string().required(
              i18n.t('validation.clinicalDepartment.required')
            ),
          })
        ),
        '4_Hol': Yup.array().of(
          Yup.object().shape({
            clinicalDepartmentId: Yup.string().required(
              i18n.t('validation.clinicalDepartment.required')
            ),
          })
        ),
        '5_Hol': Yup.array().of(
          Yup.object().shape({
            clinicalDepartmentId: Yup.string().required(
              i18n.t('validation.clinicalDepartment.required')
            ),
          })
        ),
        '6_Hol': Yup.array().of(
          Yup.object().shape({
            clinicalDepartmentId: Yup.string().required(
              i18n.t('validation.clinicalDepartment.required')
            ),
          })
        ),
        8: Yup.array().of(
          Yup.object().shape({
            clinicalDepartmentId: Yup.string().required(
              i18n.t('validation.clinicalDepartment.required')
            ),
          })
        ),
      }),
      isMonday: Yup.boolean(),
      isTuesday: Yup.boolean(),
      isWednesday: Yup.boolean(),
      isThursday: Yup.boolean(),
      isFriday: Yup.boolean(),
      isSaturday: Yup.boolean(),
      isSunday: Yup.boolean(),
      isDayoff: Yup.boolean(),
      mailContent: Yup.string().when('isSendMail', {
        is: true,
        then: Yup.string().required(
          i18n.t('staff.scheduledShiftGroup.mailContentRequired')
        ),
      }),
      repeatSetting: Yup.string().required(i18n.t('validation.shiftGroup.day')),
      doctorSubsidy: Yup.array()
        .nullable()
        .of(
          Yup.object().shape({
            title: Yup.string()
              .nullable()
              .required(i18n.t('validation.doctorSubsidy')),
            money: Yup.number()
              .nullable()

              .required(i18n.t('validation.doctorSubsidy')),
          })
        ),
      isHolidayRepeat: Yup.boolean().nullable(),
    })
    .test('checkSelectDay', i18n.t('validation.shiftGroup.day'), value => {
      if (
        (!value.isMonday &&
          !value.isTuesday &&
          !value.isWednesday &&
          !value.isThursday &&
          !value.isFriday &&
          !value.isSaturday &&
          !value.isSunday &&
          !value.isDayoff &&
          value.repeatSetting === TYPE_CREATE.WEEKLY) ||
        (value.listWeekDay.length === 0 &&
          value.listWeekDay1.length === 0 &&
          value.listWeekDay2.length === 0 &&
          value.listWeekDay3.length === 0 &&
          value.listWeekDay4.length === 0 &&
          value.repeatSetting === TYPE_CREATE.MONTHLY)
      ) {
        return false
      }
      return true
    })
  const availableShiftEditedList = availableShiftList
    .filter(
      item =>
        (item.isEditedIndividually ||
          item.workSchedule.deletedStatus === 'WORKSCHEDULE_CANCEL') &&
        moment(item.startTime).isAfter(moment())
    )
    .map(item => ({
      value: item.id,
      description: `${moment(item.startTime).format('LL (dd)')}　${moment(
        item.startTime
      ).format('HH:mm')}～${moment(item.endTime).format('HH:mm')}`,
      date: moment(item.startTime).format('YYYY-MM-DD'),
      isCancel: item.workSchedule.deletedStatus === 'WORKSCHEDULE_CANCEL',
    }))

  const sampleDataOfCheckbox = [
    { value: 'monday', description: i18n.t('staff.createHourlyWage.monDay') },
    { value: 'tuesday', description: i18n.t('staff.createHourlyWage.tuesDay') },
    {
      value: 'wednesday',
      description: i18n.t('staff.createHourlyWage.wednesDay'),
    },
    {
      value: 'thursday',
      description: i18n.t('staff.createHourlyWage.thursDay'),
    },
    { value: 'friday', description: i18n.t('staff.createHourlyWage.friDay') },
    {
      value: 'saturday',
      description: i18n.t('staff.createHourlyWage.saturDay'),
    },
    { value: 'sunday', description: i18n.t('staff.createHourlyWage.sunDay') },
  ]
  const typeCreateOptions = [
    {
      label: i18n.t('staff.scheduledShiftGroup.daily'),
      value: TYPE_CREATE.DAILY,
    },
    {
      label: i18n.t('staff.scheduledShiftGroup.weekly'),
      value: TYPE_CREATE.WEEKLY,
    },
    {
      label: i18n.t('staff.scheduledShiftGroup.monthly'),
      value: TYPE_CREATE.MONTHLY,
    },
  ]
  const [activeIndex, setActiveIndex] = useState(0)
  const dataTime = JSON.parse(scheduledAvailableShiftGroup.dataTime)
  let dataSplits = {}
  Object.keys(dataTime).forEach(key => {
    dataSplits = {
      ...dataSplits,
      ...splitLogicReverse({
        availableShift: dataTime[key],
        key,
        disabledClinic: true,
      }),
    }
  })
  let listDaySelected = []
  Object.keys(dataSplits).forEach(key => {
    if (key.length === 1) {
      listDaySelected.push(`${key}_Hol`)
    }
  })
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          functionName: '',
          confirm: false,
          comment: scheduledAvailableShiftGroup.comment || '',
          updateType: '',
          splitComments: [
            {
              text: '',
              type: '',
            },
          ],
          doctor: `${doctor.lastname} ${doctor.firstname}`,
          doctors: doctor,
          splits: dataSplits,
          name: scheduledAvailableShiftGroup.name,
          isAllday:
            scheduledAvailableShiftGroup.isAvailableOnMonday &&
            scheduledAvailableShiftGroup.isAvailableOnTuesday &&
            scheduledAvailableShiftGroup.isAvailableOnWednesday &&
            scheduledAvailableShiftGroup.isAvailableOnThursday &&
            scheduledAvailableShiftGroup.isAvailableOnFriday,
          isMonday: scheduledAvailableShiftGroup.isAvailableOnMonday,
          isTuesday: scheduledAvailableShiftGroup.isAvailableOnTuesday,
          isWednesday: scheduledAvailableShiftGroup.isAvailableOnWednesday,
          isThursday: scheduledAvailableShiftGroup.isAvailableOnThursday,
          isFriday: scheduledAvailableShiftGroup.isAvailableOnFriday,
          isSaturday: scheduledAvailableShiftGroup.isAvailableOnSaturday,
          isSunday: scheduledAvailableShiftGroup.isAvailableOnSunday,
          isDayoff: scheduledAvailableShiftGroup.isAvailableOnHoliday,
          isDayOnWeekday:
            scheduledAvailableShiftGroup.isAvailableOnWeekdayHoliday,
          isSendMail: false,
          mailContent: '',
          doctorEmail: doctor.email,
          startEndDate: [
            scheduledAvailableShiftGroup.startDate,
            scheduledAvailableShiftGroup.endDate,
          ],
          repeatSetting: scheduledAvailableShiftGroup.repeatSetting || '',
          listWeekDay:
            scheduledAvailableShiftGroup &&
            scheduledAvailableShiftGroup.listWeekDay
              ? scheduledAvailableShiftGroup.listWeekDay
              : [],
          listWeekDay1:
            scheduledAvailableShiftGroup &&
            scheduledAvailableShiftGroup.listWeekDay1
              ? scheduledAvailableShiftGroup.listWeekDay1
              : [],
          listWeekDay2:
            scheduledAvailableShiftGroup &&
            scheduledAvailableShiftGroup.listWeekDay2
              ? scheduledAvailableShiftGroup.listWeekDay2
              : [],
          listWeekDay3:
            scheduledAvailableShiftGroup &&
            scheduledAvailableShiftGroup.listWeekDay3
              ? scheduledAvailableShiftGroup.listWeekDay3
              : [],
          listWeekDay4:
            scheduledAvailableShiftGroup &&
            scheduledAvailableShiftGroup.listWeekDay4
              ? scheduledAvailableShiftGroup.listWeekDay4
              : [],
          doctorSubsidy:
            scheduledAvailableShiftGroup &&
            scheduledAvailableShiftGroup.availableShifts &&
            scheduledAvailableShiftGroup.availableShifts[0] &&
            scheduledAvailableShiftGroup.availableShifts[0].workSchedule &&
            scheduledAvailableShiftGroup.availableShifts[0].workSchedule
              .doctorSubsidy
              ? scheduledAvailableShiftGroup.availableShifts[0].workSchedule
                  .doctorSubsidy
              : null,
          isHolidayRepeat: scheduledAvailableShiftGroup
            ? scheduledAvailableShiftGroup.isHolidayRepeat
            : undefined,
          isPublished: scheduledAvailableShiftGroup
            ? scheduledAvailableShiftGroup.isPublished
            : true,
          availableShiftEditedList: [],
          listDaySelected,
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        render={formikProps => {
          const {
            values,
            handleChange,
            validateForm,
            setFieldValue,
            setTouched,
            handleSubmit,
            dirty,
          } = formikProps
          const isDisabledSplit = moment(values.startEndDate[0]).isSameOrBefore(
            moment()
          )
          const holidays1 = thisRangeHolidays({
            holidaysData: pathOr([], ['items'], holidaysData),
            startDate: values.startEndDate[0],
            endDate: values.startEndDate[1],
          })
          const checkIsTime = startTime => {
            const holidays = thisRangeHolidays({
              holidaysData: pathOr([], ['items'], holidaysData),
              startDate: startTime,
              endDate: startTime,
            })
            const date = moment(startTime)
            const dayNo = moment(date).day()

            holidays.length > 0
              ? setFieldValue(
                  'splits',
                  setTimeSplitByDay('8', 'isAvailableOnHoliday')
                )
              : setFieldValue('splits', setTimeSplitByDay(`${dayNo}`))
          }
          const showSplits = (startTime, endTime) => {
            const holidays = thisRangeHolidays({
              holidaysData: pathOr([], ['items'], holidaysData),
              startDate: startTime,
              endDate: endTime,
            })

            values.splits = isHolidayInRangeDate({
              startDate: startTime,
              endDate: endTime,
            })
            if (holidays.length > 0) {
              setFieldValue('splits', {
                ...values.splits,
                ...setTimeSplitByDay('8', 'isAvailableOnHoliday'),
              })
            }
          }
          let confirmationMessage
          const action = values.updateType.split('_')
          if (action.includes('UPDATE')) {
            confirmationMessage = i18n.t(
              'staff.scheduledShiftGroup.updateConfirmation'
            )
          } else {
            confirmationMessage = i18n.t(
              'staff.availableShift.removeConfirmation'
            )
          }
          return (
            <div>
              <div className='modal-title'>
                {i18n.t('staff.scheduledShiftGroup.detailTitle')}
              </div>
              <TabView
                activeIndex={activeIndex}
                onTabChange={e => {
                  setActiveIndex(e.index)
                  if (e.index === 1) {
                    setFieldValue(
                      'repeatSetting',
                      scheduledAvailableShiftGroup.repeatSetting
                    )
                  }
                }}
              >
                <TabPanel
                  header={i18n.t('staff.scheduledShiftGroup.updateAll')}
                ></TabPanel>
                <TabPanel
                  header={i18n.t('staff.scheduledShiftGroup.partialChange')}
                ></TabPanel>
              </TabView>
              <TextInput
                {...formikProps}
                name='doctor'
                label={i18n.t('staff.scheduledShiftGroup.doctor')}
                disabled
              />
              <div className='tr'>
                <div className='th'>
                  {i18n.t('staff.scheduledShiftGroup.shiftGroupName')}
                </div>
                <div className='td'>
                  <InputText
                    value={values.name}
                    name='name'
                    onChange={e => {
                      setFieldValue('name', e.target.value)
                    }}
                    disabled={activeIndex === 1 ? true : false}
                  />
                </div>
              </div>
              <div className='alert' style={{ marginLeft: '290px' }}>
                <ErrorMessage name='name' />
              </div>

              <div className='tr'>
                <div className='th'>{i18n.t('staff.createShift.restDay')}</div>
                <div className='td'>
                  {renderAvailableDay(
                    doctor.isAvailableOnMonday,
                    doctor.isAvailableOnTuesday,
                    doctor.isAvailableOnWednesday,
                    doctor.isAvailableOnThursday,
                    doctor.isAvailableOnFriday,
                    doctor.isAvailableOnSaturday,
                    doctor.isAvailableOnSunday,
                    doctor.isAvailableOnHoliday
                  )}
                </div>
              </div>
              <div className='create-as-group'>
                <div className='tr'>
                  <div className='th'>
                    {i18n.t('staff.createHourlyWage.date')}
                  </div>
                  <div className='td'>
                    <DatePicker.RangePicker
                      value={[
                        values.startEndDate[0]
                          ? moment(
                              new Date(values.startEndDate[0]),
                              'YYYY年MM月DD日'
                            )
                          : null,
                        values.startEndDate[1]
                          ? moment.utc(
                              new Date(values.startEndDate[1]),
                              'YYYY年MM月DD日'
                            )
                          : null,
                      ]}
                      allowClear={false}
                      placeholder={[
                        i18n.t('staff.scheduledShiftGroup.startDate'),
                        i18n.t('staff.scheduledShiftGroup.endDate'),
                      ]}
                      locale={locale}
                      disabledDate={
                        activeIndex === 1 ? disableDateUpdate : disabledDate
                      }
                      onChange={value => {
                        setFieldValue('startEndDate', [
                          moment
                            .utc(value[0])
                            .startOf('day')
                            .toISOString(),
                          moment
                            .utc(value[1])
                            .endOf('day')
                            .toISOString(),
                        ])
                        if (values.repeatSetting === TYPE_CREATE.DAILY) {
                          moment(value[0]).isSame(moment(value[1]))
                            ? checkIsTime(moment(value[0]).toISOString())
                            : showSplits(
                                moment(value[0]).toISOString(),
                                moment(value[1]).toISOString()
                              )
                        }
                      }}
                      format='YYYY年MM月DD日'
                    />
                  </div>
                </div>
                <ShiftGroupFormRepeatSetting
                  formikProps={formikProps}
                  i18n={i18n}
                  typeCreateOptions={typeCreateOptions}
                  holidays={holidays1}
                  activeIndex={activeIndex}
                  sampleDataOfCheckbox={sampleDataOfCheckbox}
                  scheduleShiftGroup={scheduledAvailableShiftGroup}
                  disabled={activeIndex === 1 ? true : false}
                  dataSplits={dataSplits}
                />
                <div className='alert' style={{ marginLeft: '290px' }}>
                  <ErrorMessage name='undefined' />
                </div>
              </div>
              <ShiftGroupFormSplits
                formikProps={formikProps}
                i18n={i18n}
                isMoneyPermitted={isMoneyPermitted}
                disabled={activeIndex === 0 ? isHappen : isDisabledSplit}
                clinicalDepartments={clinicalDepartments}
                isDetailScheduleShiftGroup
              />
              <div>
                <FieldArray
                  name='doctorSubsidy'
                  render={arrayHelpers => (
                    <div>
                      {values.doctorSubsidy &&
                        values.doctorSubsidy.map((item, index) => (
                          <div className='doctor-subsidy-container'>
                            <div className='doctor-subsidy__item'>
                              {i18n.t('staff.workSchedule.doctorSubsidy')}
                            </div>
                            <div className='doctor-subsidy__item'>
                              <div className='title'>
                                {i18n.t('staff.workSchedule.subsidyName')}
                              </div>
                              <div>
                                <InputText
                                  value={item.title}
                                  onChange={e => {
                                    setFieldValue(
                                      `doctorSubsidy[${index}].title`,
                                      e.target.value
                                    )
                                  }}
                                />
                                <ErrorMessage
                                  name={`doctorSubsidy[${index}].title`}
                                  component='div'
                                  className='signup-alert'
                                />
                              </div>
                            </div>
                            <div className='doctor-subsidy__item'>
                              <div className='title'>
                                {i18n.t('staff.workSchedule.subsidyValue')}
                              </div>
                              <div>
                                <InputNumber
                                  size='medium'
                                  value={item.money}
                                  // style={{ fontSize: '5px' }}
                                  formatter={value =>
                                    `${value}`.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ','
                                    )
                                  }
                                  type='tel'
                                  allowClear
                                  min={0}
                                  step={10}
                                  onChange={e => {
                                    setFieldValue(
                                      `doctorSubsidy[${index}].money`,
                                      e
                                    )
                                  }}
                                />
                                <ErrorMessage
                                  name={`doctorSubsidy[${index}].money`}
                                  component='div'
                                  className='signup-alert'
                                />
                              </div>
                            </div>
                            <div className='doctor-subsidy__item'>
                              <Button
                                type='button'
                                // label='x'
                                icon='pi pi-trash'
                                onClick={async () => {
                                  arrayHelpers.remove(index)
                                }}
                              />
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                />
                <Button
                  type='button'
                  label={i18n.t('staff.workSchedule.btnSubsidy')}
                  icon='pi pi-plus'
                  onClick={() => {
                    setFieldValue('doctorSubsidy', [
                      ...values.doctorSubsidy,
                      {
                        title: '',
                        money: null,
                      },
                    ])
                  }}
                />
              </div>

              <div className='staffpage-comment-container'>
                <div className='staffpage-title'>
                  {i18n.t('main.staffComment.titleStaffComment')}
                </div>
                <WorkScheduleComment
                  comments={staffComments}
                  // workScheduleId={availableShift.workSchedule.id}
                  staffId={staffMe.id}
                  isDeletePermitted={isDeleteCommentPermitted}
                  scheduledShiftGroupId={scheduledShiftGroupId}
                  isAddPermitted={isAddCommentPermitted}
                  isDeleteAllComment={isDeleteAllComment}
                />
              </div>
              {/* {isAddCommentPermitted && (
                <ShiftFormStaffComment
                  i18n={i18n}
                  formikProps={formikProps}
                  isShowAdd
                />
              )} */}
              <div className='tr'>
                <div className='th'>{i18n.t('main.workDetail')}</div>
                <div className='td'>
                  <InputTextarea
                    // {...formikProps}
                    name='comment'
                    value={values.comment}
                    autoResize
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='tr'>
                <div className='th'>
                  {i18n.t('staff.scheduledShiftGroup.tittlePublished')}
                </div>
                <div className='td toggle-container'>
                  <Switch
                    name='isPublished'
                    checkedChildren={i18n.t(
                      'staff.scheduledShiftGroup.published'
                    )}
                    unCheckedChildren={i18n.t(
                      'staff.scheduledShiftGroup.notPublished'
                    )}
                    checked={values.isPublished}
                    onChange={e => {
                      if (isChangeConfirmedShiftsStatusPermitted) {
                        setFieldValue('isPublished', e)
                        if (!e.checked) {
                          setFieldValue('isSendMail', false)
                        }
                      } else {
                        popup.error(
                          i18n.t(
                            'staff.scheduledShiftGroup.errorMessagePermision'
                          )
                        )
                      }
                    }}
                  />
                </div>
              </div>
              <div className='tr'>
                <div className='th'>{i18n.t('main.postUpdateHistory')}</div>
                <div className='td'>
                  {postActivitiesHistory &&
                    postActivitiesHistory.map((it, k) => (
                      <div key={k}>
                        <span>
                          {moment(it.createdAt)
                            .startOf('day')
                            .format('YYYY年MM月DD日')}
                        </span>
                        <span> {moment(it.createdAt).format('HH:mm')} </span>
                        <span>
                          {it.staff.lastname} {it.staff.firstname}
                        </span>
                        <span>{i18n.t(it.activity)}</span>
                      </div>
                    ))}
                </div>
              </div>
              <div className='tr'>
                <div className='th'>{i18n.t('main.updateHistory')}</div>
                <div className='td'>
                  {activitiesHistory &&
                    activitiesHistory.map((it, k) => (
                      <div key={k}>
                        <span>
                          {moment(it.createdAt)
                            .startOf('day')
                            .format('YYYY年MM月DD日')}
                        </span>
                        <span> {moment(it.createdAt).format('HH:mm')} </span>
                        <span>
                          {it.staff.lastname} {it.staff.firstname}
                        </span>
                        <span>
                          {i18n.t(
                            `staff.activityLogsStaffHistory.${it.activity}`
                          )}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
              <CheckBoxList
                {...formikProps}
                label={i18n.t(
                  'staff.scheduledShiftGroup.availableShiftEditedList'
                )}
                name='availableShiftEditedList'
                options={availableShiftEditedList}
                locale={locale}
              />
              <div className='tr'>
                <div className='th'>
                  {i18n.t('staff.scheduledShiftGroup.mailContent')}
                </div>
                <div className='td'>
                  <InputTextarea
                    name='mailContent'
                    value={values.isSendMail ? values.mailContent : ''}
                    autoResize
                    disabled={!values.isSendMail}
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name='mailContent'
                    component='div'
                    className='signup-alert'
                  />
                </div>
              </div>
              <div className='tr'>
                <div className='td'>
                  <Checkbox
                    name='isSendMail'
                    checked={values.isSendMail}
                    disabled={!values.isPublished}
                    onChange={e => {
                      const date = moment('1000-10-10')
                        .startOf('day')
                        .hour(9)
                      setFieldValue('isSendMail', e.target.checked)
                      setFieldValue(
                        'mailContent',
                        getEmailTemplate(
                          true,
                          values.doctors,
                          clinicalDepartments,
                          date,
                          values.splits,
                          values.startEndDate,
                          values.isMonday,
                          values.isTuesday,
                          values.isWednesday,
                          values.isThursday,
                          values.isFriday,
                          values.isSaturday,
                          values.isSunday,
                          values.isDayoff
                        )
                      )
                    }}
                  />
                  <span> {i18n.t('staff.scheduledShiftGroup.isSendMail')}</span>
                  <div style={{ marginTop: '10px', fontSize: '12px' }}>
                    <span className='asterisk-label-head '>
                      {i18n.t('staff.scheduledShiftGroup.sendMailNote1')}
                    </span>
                    <br />
                    <span className='asterisk-label-head '>
                      {i18n.t('staff.scheduledShiftGroup.sendMailNote2')}
                      <br />
                    </span>
                    {activeIndex === 0 && (
                      <>
                        <span className='asterisk-label-head'>
                          {i18n.t('staff.scheduledShiftGroup.buttonDelete')}
                          <br />
                        </span>
                        <span className='asterisk-label-head'>
                          {i18n.t('staff.scheduledShiftGroup.buttonUpdateAll')}
                          <br />
                        </span>
                      </>
                    )}
                    {activeIndex === 1 && (
                      <p className='asterisk-label-head'>
                        {i18n.t(
                          'staff.scheduledShiftGroup.buttonUpdatePartial'
                        )}
                        <br />
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className='modal-bottom'>
                {isDeletePermitted && activeIndex === 0 ? (
                  <Button
                    label={i18n.t('staff.scheduledShiftGroup.deleteSubmit')}
                    onClick={() => {
                      setFieldValue(
                        'updateType',
                        SHCEDULE_SHIFT_ACTION.DELETE_ALL
                      )
                      setFieldValue('confirm', true)
                    }}
                    icon='pi pi-trash'
                    className='p-button-danger'
                    disabled={isHappen}
                  />
                ) : null}
                {isUpdatePermitted ? (
                  <>
                    {activeIndex === 0 ? (
                      <Button
                        disabled={!dirty}
                        onClick={() => {
                          validateForm().then(response => {
                            if (!Object.keys(response).length) {
                              setFieldValue('confirm', true)
                              setFieldValue(
                                'updateType',
                                SHCEDULE_SHIFT_ACTION.UPDATE_ALL
                              )
                            } else {
                              setTouched(response)
                            }
                          })
                        }}
                        label={i18n.t('staff.scheduledShiftGroup.btnUpdateAll')}
                        icon='pi pi-pencil'
                      />
                    ) : (
                      <Button
                        disabled={!dirty}
                        onClick={() => {
                          validateForm().then(response => {
                            if (!Object.keys(response).length) {
                              setFieldValue('confirm', true)
                              setFieldValue(
                                'updateType',
                                SHCEDULE_SHIFT_ACTION.UPDATE_PARTIAL
                              )
                            } else {
                              setTouched(response)
                            }
                          })
                        }}
                        label={i18n.t(
                          'staff.scheduledShiftGroup.btnUpdateAfterStartDate'
                        )}
                        icon='pi pi-pencil'
                      />
                    )}
                  </>
                ) : null}
                <Link
                  to={{
                    pathname: `${Router.staffAvailableShiftGroupList +
                      Router.staffCreateScheduledShiftGroup}`,
                    state: { ...values, doctor: null },
                  }}
                >
                  <Button
                    label={i18n.t('staff.scheduledShiftGroup.duplicateSubmit')}
                    icon='pi pi-pencil'
                  />
                </Link>
                <ConfirmationModal
                  {...formikProps}
                  confirmationMessage={confirmationMessage}
                  handleSubmit={() => {
                    handleSubmit()
                  }}
                  handleNoClick={() => {
                    setFieldValue('updateType', '')
                    setFieldValue('confirm', false)
                  }}
                />
              </div>
            </div>
          )
        }}
      />
    </div>
  )
}

export default DetailScheduleShiftGroupScene
