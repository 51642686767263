import './style.css'

import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import AutoComplete from '@medical/components/AutoComplete'
import CustomCalendarPicker from '@medical/components/CalendarPicker'
import CustomCheckBox from '@medical/components/CheckBox'
import Chips from '@medical/components/Chips'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import CustomDropdown from '@medical/components/Dropdown'
import CustomDropdownCarrer from '@medical/components/DropdownForSignup'
import CustomInputTextarea from '@medical/components/InputTextarea'
import TextInput from '@medical/components/TextInput'
import {
  KATAKANA_REG_EXP,
  MAX_HOURLY_WAGE,
  MAX_NAME_LENGTH,
  NAME_REG_EXP,
  PERMANENT_DOCTOR,
  phoneFirstRegExp,
  phoneSecondRegExp,
  phoneThirdRegExp,
  PROVINCES,
  REGULAR_SHIFT_DOCTOR,
} from '@medical/constant'
import { generateOptions, OPTION_TYPES } from '@medical/constant/utilities'
import { getDetailAddressFromZipCode } from '@medical/libs/getAddressFromZipcode'
import { Modal, Switch } from 'antd'
import { ErrorMessage, FieldArray, Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Spinner } from 'primereact/spinner'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { Select } from 'antd'
import { Dropdown } from '../../../../fork/primereact/dropdown'
import { ChangeEmailForm } from '../ChangeEmailForm'
import { SKILL_CONNECTIONS } from '../SignupComponent/signup.grapql'
import TermsOfCarTransportationExpenses from './TermsOfCarTransportationExpenses'

const digDeeper = (errors, message = []) => {
  if (!errors) return
  Object.keys(errors).forEach(key => {
    if (typeof errors[key] === 'string') {
      message.push(<div className='alert'>{errors[key]}</div>)
    } else {
      digDeeper(errors[key], message)
    }
  })
}

const DoctorProfileForm = ({
  i18n,
  doctor = {},
  doctorAddress = {},
  genders,
  onSubmit,
  departments,
  workPatterns = [],
  joinBackgrounds = [],
  insuranceDoctorTypes = [],
  disabled,
  confirmationMessage,
  buttonLabel,
  createIndicator = false,
  updateProfile = false,
  isStaff,
  onlyStaff,
  isEditAddressDoctorProfile,
  isEditGraduationUniversityDoctorProfile,
  isEditIntroducedByDoctorProfile,
  isEditJoinBackgroundDoctorProfile,
  isEditProfessionalDescriptionDoctorProfile,
  isEditMedicalRecordDDoctorProfile,
  isEditSkillInformationDoctorProfile,
  isEditObtainDoctorLicenseYearDoctorProfile,
  isEditCareerHistoryDoctorProfile,
  isEditAdjustHourlyWageRangeDoctorProfile,
  dataSkill,
  isCreateDoctorDeleteRequestPermission,
  isEditStaffMemoDoctorProfile = false,
  isPageStaffMemoDoctorProfile = false,
  doctorUpdate = false,
  isHiddenDoctorNo,
  isViewDoctorMedicalLicenseNumber,
  isEditDoctorMedicalLicenseNumber,
  isViewMedicalRecordDDoctorProfile,
  isUpdate,
  isCanBlockDoctorMail,
}) => {
  const Option = Select.Option
  const [agreeTermVisible, setAgreeTermVisible] = useState(false)
  const [changeEmailVisible, setChangeEmailVisible] = useState(false)
  const [graduationUniversityYear, setGraduationUniversityYear] = useState(
    doctor.graduationUniversityYear ? doctor.graduationUniversityYear : 2000
  )
  const [obtainDoctorLicenseYear, setObtainDoctorLicenseYear] = useState(
    doctor.obtainDoctorLicenseYear ? doctor.obtainDoctorLicenseYear : 2000
  )
  const [errorInsurance, setErrorInsurance] = useState(false);
  const { loading, error, data } = useQuery(SKILL_CONNECTIONS, {
    variables: {
      where: {
        isShow: true,
      },
    },
  })
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  const years = generateOptions(OPTION_TYPES.YEAR, 100, 0)
  const format = /([`!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~])/g
  const formatJP = /([一-龠]+|[ぁ-ゔ]+|[ァ-ヴー]+|[a-zA-Z0-9]+|[ａ-ｚＡ-Ｚ０-９]+|[々〆〤ヶ]+|[\s]+|[‐-])/g
  const nameRegex = Yup.string()
    .max(MAX_NAME_LENGTH, i18n.t('validation.max', { max: MAX_NAME_LENGTH }))
    .matches(NAME_REG_EXP, i18n.t('validation.noSpecialCharacter'))

  const katakanaNameRegex = nameRegex
    .clone()
    .matches(KATAKANA_REG_EXP, i18n.t('validation.katakanaOnly'))
  const validationSchema = Yup.object().shape({
    graduationUniversity:
      !isStaff &&
      Yup.string()
        .nullable()
        .required(
          ` ${i18n.t('doctor.signup.graduationUniversityLabel')}${i18n.t(
            'validation.required'
          )}`
        ),
    graduationUniversityYear:
      !isStaff &&
      Yup.number()
        .nullable()
        .required(
          `${i18n.t('updateProfile.graduationYears')}${i18n.t(
            'validation.required'
          )}`
        ),
    obtainDoctorLicenseYear:
      !isStaff &&
      Yup.number()
        .nullable()
        .required(
          `${i18n.t('updateProfile.obtainMedicalLicenseYears')}${i18n.t(
            'validation.required'
          )}`
        ),
    professionalDescription:
      isEditProfessionalDescriptionDoctorProfile && Yup.string().nullable(),
    email:
      isStaff &&
      Yup.string()
        .email(i18n.t('validation.email.email'))
        .required(i18n.t('validation.email.required')),
    firstname: nameRegex
      .clone()
      .required(
        `${i18n.t('updateProfile.firstname')}${i18n.t('validation.required')}`
      ),
    lastname: nameRegex
      .clone()
      .required(
        `${i18n.t('updateProfile.lastname')}${i18n.t('validation.required')}`
      ),
    firstnameKana: katakanaNameRegex
      .clone()
      .required(
        `${i18n.t('updateProfile.firstnameKana')}${i18n.t(
          'validation.required'
        )}`
      ),
    lastnameKana: katakanaNameRegex.required(
      `${i18n.t('updateProfile.lastnameKana')}${i18n.t('validation.required')}`
    ),
    gender:
      !isStaff &&
      Yup.string().required(
        `${i18n.t('updateProfile.gender')}${i18n.t('validation.required')}`
      ),
    birthday:
      !isStaff &&
      Yup.date()
        .required(
          `${i18n.t('updateProfile.birthday')}${i18n.t('validation.required')}`
        )
        .nullable(),
    medicalLicenseNumber: isEditDoctorMedicalLicenseNumber
      ? Yup.string()
          .matches(/^[0-9]+$/, i18n.t('main.medicalLicenceNumberNotValid'))
          .nullable()
      : undefined,
    departments: Yup.array()
      .of(Yup.string())
      .min(1, i18n.t('validation.departments.required'))
      .required(
        `${i18n.t('updateProfile.departments')}${i18n.t('validation.required')}`
      ),

    phoneNumberFirst:
      !isStaff &&
      Yup.string()
        .matches(phoneFirstRegExp, i18n.t('main.phoneNumberNotValid'))
        .required(
          `${i18n.t('updateProfile.phoneNumber')}${i18n.t(
            'validation.required'
          )}`
        ),
    phoneNumberSecond:
      !isStaff &&
      Yup.string()
        .matches(phoneSecondRegExp, i18n.t('main.phoneNumberNotValid'))
        .required(
          `${i18n.t('updateProfile.phoneNumber')}${i18n.t(
            'validation.required'
          )}`
        ),
    phoneNumberThird:
      !isStaff &&
      Yup.string()
        .matches(phoneThirdRegExp, i18n.t('main.phoneNumberNotValid'))
        .required(
          `${i18n.t('updateProfile.phoneNumber')}${i18n.t(
            'validation.required'
          )}`
        ),
    homeAddress: Yup.object().shape({
      zipCodeFirst:
        !isStaff &&
        Yup.string()
          .nullable()
          .required(
            `${i18n.t('updateProfile.homeAddress.zipCode')}${i18n.t(
              'validation.required'
            )}`
          ),
      zipCodeSecond:
        !isStaff &&
        Yup.string()
          .nullable()
          .required(
            `${i18n.t('updateProfile.homeAddress.zipCode')}${i18n.t(
              'validation.required'
            )}`
          ),
      stateOrRegion:
        !isStaff &&
        Yup.string()
          .nullable()
          .required(
            `${i18n.t('updateProfile.homeAddress.stateOrRegion')}${i18n.t(
              'validation.required'
            )}`
          )
          .test(
            'check exits',
            `${i18n.t('updateProfile.homeAddress.stateOrRegion')}${i18n.t(
              'validation.required'
            )}`,
            val =>
              val &&
              PROVINCES.filter(({ name_ja }) => name_ja === val).length > 0
          ),
      address1: !isStaff
        ? Yup.string()
            .nullable()
            .required(
              `${i18n.t('updateProfile.homeAddress.address1')}${i18n.t(
                'validation.required'
              )}`
            )
            .test(
              'checkAddress1',
              '使用できない文字が含まれています。',
              val => {
                if (val && val.length > 0) {
                  if (
                    !val.match(formatJP) ||
                    (val.match(formatJP) &&
                      val.match(formatJP).join('') !== val) ||
                    val.match(format)
                  ) {
                    return false
                  } else {
                    return true
                  }
                } else {
                  return true
                }
              }
            )
        : Yup.string()
            .nullable()
            .test(
              'checkAddress1',
              '使用できない文字が含まれています。',
              val => {
                if (val && val.length > 0) {
                  if (
                    !val.match(formatJP) ||
                    (val.match(formatJP) &&
                      val.match(formatJP).join('') !== val) ||
                    val.match(format)
                  ) {
                    return false
                  } else {
                    return true
                  }
                } else {
                  return true
                }
              }
            ),
      address2: Yup.string()
        .nullable()
        .test('checkAddress2', '使用できない文字が含まれています。', val => {
          if (val && val.length > 0) {
            if (
              !val.match(formatJP) ||
              (val.match(formatJP) && val.match(formatJP).join('') !== val) ||
              val.match(format)
            ) {
              return false
            } else {
              return true
            }
          } else {
            return true
          }
        }),
    }),
    nearestStations: Yup.array().of(Yup.string()),
    placeOfWork:
      !isStaff &&
      Yup.string()
        .nullable()
        .required(
          `${i18n.t('updateProfile.placeOfWork')}${i18n.t(
            'validation.required'
          )}`
        ),
    workPattern:
      !isStaff && createIndicator
        ? Yup.string().required(
            `${i18n.t('updateProfile.workPattern')}${i18n.t(
              'validation.required'
            )}`
          )
        : undefined,
    adjustHourlyWageRange:
      !isStaff && createIndicator && isEditAdjustHourlyWageRangeDoctorProfile
        ? Yup.string().required(
            `${i18n.t('updateProfile.adjustHourlyWageRange')}${i18n.t(
              'validation.required'
            )}`
          )
        : undefined,
    joinBackground:
      !isStaff && createIndicator && isEditJoinBackgroundDoctorProfile
        ? Yup.string().required(
            `${i18n.t('updateProfile.joinBackground')} ${i18n.t(
              'validation.required'
            )}`
          )
        : undefined,
    joinBackgroundOther:
      !isStaff &&
      isEditJoinBackgroundDoctorProfile &&
      Yup.string().when('joinBackground', {
        is: joinBackgrounds[0].value,
        then: undefined,
        otherwise: Yup.string().required(
          `${i18n.t('updateProfile.joinBackgroundOther')}`
        ),
      }),
    isIntroduce:
      isEditIntroducedByDoctorProfile &&
      Yup.string().required(
        `${i18n.t('doctor.signup.isIntroduceLabel')}${i18n.t(
          'validation.required'
        )}`
      ),
    introducedBy:
      isEditIntroducedByDoctorProfile &&
      Yup.string().when('isIntroduce', {
        is: '1',
        then: Yup.string().required(i18n.t('doctor.signup.introduceNameInput')),
      }),
    reasonKnowWebsites: Yup.string(),
    otherReasonKnowWebsite: Yup.string().when(
      'reasonKnowWebsites',
      reasonKnowWebsites =>
        reasonKnowWebsites.includes('OTHER')
          ? Yup.string().required(i18n.t('doctor.signup.inputMessage'))
          : Yup.string().nullable()
    ),
    isJoinedSublingualImmunotherapyELearning:
      isEditSkillInformationDoctorProfile && Yup.boolean(),
    isJoinedOnlineDiagnosticTraining:
      isEditSkillInformationDoctorProfile && Yup.boolean(),
    careerHistories: Yup.array().of(
      Yup.object().shape({
        description: Yup.string().required(
          i18n.t('validation.requireDescription')
        ),
        month: Yup.string().required(i18n.t('validation.requireMonth')),
        year: Yup.string().required(i18n.t('validation.requireYear')),
      })
    ),
    medicalRecord:
      isStaff &&
      isEditMedicalRecordDDoctorProfile &&
      Yup.string()
        .matches(/^[0-9a-zA-Z.]+$/, i18n.t('main.medicalRecordNotValid'))
        .nullable(),
    orca:
      isStaff &&
      isEditMedicalRecordDDoctorProfile &&
      Yup.string()
        .matches(/^[0-9]+$/, i18n.t('main.orcaNotValid'))
        .nullable(),
    questionnaire:
      isStaff &&
      isEditMedicalRecordDDoctorProfile &&
      Yup.string()
        .matches(/^[0-9]+$/, i18n.t('main.questionnaireNotValid'))
        .nullable(),
    isAgree:
      !isStaff &&
      !doctor.isBlockedMyCar &&
      Yup.string().when('isBlockedMyCar', isBlockedMyCar =>
        isBlockedMyCar
          ? Yup.bool()
              .oneOf([true], `チェックをつけてください。`)
              .required()
          : undefined
      ),
    province:
      !isStaff &&
      Yup.string()
        .test(
          'check exits',
          '都道府県のみ入力必須',
          val =>
            val && PROVINCES.filter(({ name_ja }) => name_ja === val).length > 0
        )
        .required('都道府県のみ入力必須'),
  })
  const minDate = moment().subtract(100, 'year')
  const maxDate = moment().subtract(20, 'year')
  const yearRange = `${minDate.format('YYYY')}:${maxDate.format('YYYY')}`

  const listMonth = generateOptions(OPTION_TYPES.MONTH)

  const reasons = [
    {
      value: 'FLYER',
      description: `${i18n.t('doctor.signup.flyer')}`,
    },
    {
      value: 'HOMEPAGE',
      description: `${i18n.t('doctor.signup.homePage')}`,
    },
    {
      value: 'INTERNET',
      description: `${i18n.t('doctor.signup.internet')}`,
    },
    {
      value: 'OTHER',
      description: `${i18n.t('doctor.signup.other')}`,
    },
  ]
  const isCompletedOption = [
    {
      value: 'true',
      description: `${i18n.t('doctor.signup.doneLabel')}`,
    },
    {
      value: 'false',
      description: `${i18n.t('doctor.signup.yetLabel')}`,
    },
  ]
  const isJoinedTrainingOption = [
    {
      value: 'true',
      description: `${i18n.t('doctor.signup.doneLabel')}`,
      note: '※「提出書類」に修了証を提出してください',
    },
    {
      value: 'false',
      description: `${i18n.t('doctor.signup.yetLabel')}`,
    },
  ]
  const isIntroduce = [
    {
      value: '1',
      description: `${i18n.t('doctor.signup.yesLabel')}`,
    },
    {
      value: '0',
      description: `${i18n.t('doctor.signup.noLabel')}`,
    },
  ]
  const legalHoliday = [
    {
      value: 'MON',
      description: `${i18n.t('staff.createDoctor.monday')}`,
    },
    {
      value: 'TUE',
      description: `${i18n.t('staff.createDoctor.tuesday')}`,
    },
    {
      value: 'WED',
      description: `${i18n.t('staff.createDoctor.wednesday')}`,
    },
    {
      value: 'THURS',
      description: `${i18n.t('staff.createDoctor.thursday')}`,
    },
    {
      value: 'FRI',
      description: `${i18n.t('staff.createDoctor.friday')}`,
    },
    {
      value: 'SAT',
      description: `${i18n.t('staff.createDoctor.saturday')}`,
    },
    {
      value: 'SUN',
      description: `${i18n.t('staff.createDoctor.sunday')}`,
    },
    {
      value: 'HOLI',
      description: `${i18n.t('staff.createDoctor.holiday')}`,
    },
  ]
  const findIndex = (arr, value) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] === value) {
        return i
        break
      }
    }
    return 'noIndex'
  }
  const convertDataSkill = data => {
    const result = []
    data.map(value => {
      result.push(value.id)
    })
    return result
  }

  let url
  if (process.env.REACT_APP_ENV === 'development') {
    url = 'http://localhost:3000/doctor/inquiry'
  } else if (process.env.REACT_APP_ENV === 'staging') {
    url = 'https://staging.capsdoctor365.jp/doctor/inquiry'
  } else if (process.env.REACT_APP_ENV === 'production') {
    url = 'https://doctor.capsdoctor365.jp/doctor/inquiry'
  }

  const convertJapaneseCalendar = target => {
    const jaCalender = [
      {
        era: '明治',
        start: '1868/1/25',
      },
      {
        era: '大正',
        start: '1912/7/30',
      },
      {
        era: '昭和',
        start: '1926/12/25',
      },
      {
        era: '平成',
        start: '1989/1/8',
      },
      {
        era: '令和',
        start: '2019/5/1',
      },
    ]

    for (let i = jaCalender.length - 1; i >= 0; i -= 1) {
      const t = new Date(jaCalender[i].start)
      if (target >= t) {
        return `${jaCalender[i].era +
          (target.getFullYear() - t.getFullYear() + 1)}年${target.getMonth() +
          1}月${target.getDate()}日`
      }
      if (i <= 0) {
        return '対象範囲外です'
      }
    }
  }

  const formatDate = dt => {
    const date = new Date(dt)
    convertJapaneseCalendar(date)
    const result = document.getElementById('result')
    result.innerHTML = convertJapaneseCalendar(date)
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        province: doctor.province || '',
        provinces: PROVINCES,
        stateOrRegions: PROVINCES,
        confirm: false,
        staffMemo: doctor.staffMemo || undefined,
        isViaReferral: false,
        lastLoggedIn: doctor.lastLoggedIn
          ? moment(doctor.lastLoggedIn).format('YYYY年MM月DD日 HH:mm')
          : i18n.t('updateProfile.emptyLastLoggedIn'),
        firstname: doctor.firstname,
        lastname: doctor.lastname,
        firstnameKana: doctor.firstnameKana,
        lastnameKana: doctor.lastnameKana,
        gender: doctor.gender || undefined,
        birthday: doctor.birthday
          ? moment(doctor.birthday).toDate()
          : '1975-01-01T00:00:00.000Z',
        email: doctor.email,
        medicalLicenseNumber: doctor.medicalLicenseNumber || undefined,
        departments: doctor.departments || [],
        phoneNumberFirst: doctor.phoneNumber
          ? doctor.phoneNumber.replace(/\D/g, '').substring(0, 3)
          : '',
        phoneNumberSecond: doctor.phoneNumber
          ? doctor.phoneNumber.replace(/\D/g, '').substring(3, 7)
          : '',
        phoneNumberThird: doctor.phoneNumber
          ? doctor.phoneNumber.replace(/\D/g, '').substring(7, 11)
          : '',
        homeAddress: doctorAddress
          ? {
              zipCodeFirst: doctorAddress.zipCode
                ? doctorAddress.zipCode.replace(/\D/g, '').substring(0, 3)
                : '',
              zipCodeSecond: doctorAddress.zipCode
                ? doctorAddress.zipCode.replace(/\D/g, '').substring(3, 7)
                : '',
              stateOrRegion: doctorAddress.stateOrRegion || undefined,
              address1: doctorAddress.address1,
              address2: doctorAddress.address2,
            }
          : undefined,
        workPattern: doctor.workPattern || undefined,
        adjustHourlyWageRange: doctor.adjustHourlyWageRange || 0,
        legalHolidays: [
          doctor.isAvailableOnMonday ? 'MON' : '',
          doctor.isAvailableOnTuesday ? 'TUE' : '',
          doctor.isAvailableOnWednesday ? 'WED' : '',
          doctor.isAvailableOnThursday ? 'THURS' : '',
          doctor.isAvailableOnFriday ? 'FRI' : '',
          doctor.isAvailableOnSaturday ? 'SAT' : '',
          doctor.isAvailableOnSunday ? 'SUN' : '',
          doctor.isAvailableOnHoliday ? 'HOLI' : '',
        ],
        joinBackground: doctor.joinBackground || undefined,
        joinBackgroundOther: doctor.joinBackgroundOther || undefined,
        nearestStations: doctor.nearestStations || [],
        placeOfWork: doctor.placeOfWork || undefined,
        graduationUniversity: doctor.graduationUniversity || undefined,
        graduationUniversityYear: doctor.graduationUniversityYear || 2000,
        obtainDoctorLicenseYear: doctor.obtainDoctorLicenseYear || 2000,
        professionalDescription: doctor.professionalDescription || undefined,
        reasonKnowWebsites: doctor.reasonKnowWebsites || [],
        otherReasonKnowWebsite:
          doctor &&
          doctor.reasonKnowWebsites &&
          doctor.reasonKnowWebsites.includes('OTHER')
            ? doctor.otherReasonKnowWebsite
            : '',
        isJoinedSublingualImmunotherapyELearning:
          (doctor.isJoinedSublingualImmunotherapyELearning &&
            doctor.isJoinedSublingualImmunotherapyELearning.toString()) ||
          'false',
        isJoinedOnlineDiagnosticTraining:
          (doctor.isJoinedOnlineDiagnosticTraining &&
            doctor.isJoinedOnlineDiagnosticTraining.toString()) ||
          'false',
        introducedBy: doctor.introducedBy || '',
        isIntroduce: !doctor.introducedBy ? '0' : '1',
        careerHistories: doctor.careerHistories || [
          {
            description: '',
            month: '',
            year: '',
          },
        ],
        medicalRecord: doctor.medicalRecord || undefined,
        orca: doctor.orca || undefined,
        skillSet: dataSkill ? convertDataSkill(dataSkill) : [],
        questionnaire: doctor.questionnaire || undefined,
        functionName: '',
        doctorNo: doctor.doctorNo || '',
        confirmationMessage,
        isCreateTransportationCost: false,
        isUpdateAddress: false,
        isBlockedMyCar: doctor.isBlockedMyCar || false,
        isAgree: undefined,
        isBlockedMail: (doctor && doctor.isBlockedMail) || false,
        insuranceDoctorNumber: doctor.insuranceDoctorNumber || undefined,
        insuranceDoctorSymbol: doctor.insuranceDoctorSymbol || undefined,
        insuranceDoctorType: doctor.insuranceDoctorType || undefined,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={formikProps => {
        const {
          errors,
          setTouched,
          setFieldValue,
          validateForm,
          handleChange,
          handleBlur,
          values,
          touched,
        } = formikProps
        const errorMessage = []
        digDeeper(errors, errorMessage)
        const getHomeAddress = async zipCode => {
          const {
            status,
            administrativeArea,
            localityArea,
          } = await getDetailAddressFromZipCode({
            zipCode: zipCode,
          })
          if (status === 'OK') {
            setFieldValue('homeAddress.stateOrRegion', administrativeArea)
            setFieldValue('homeAddress.address1', localityArea)
          }
        }
        const checkEditAddress =
          values.homeAddress.address1 !== doctorAddress.address1 ||
          values.homeAddress.stateOrRegion !== doctorAddress.stateOrRegion ||
          values.homeAddress.address2 !== doctorAddress.address2
        return (
          <React.Fragment>
            {!isHiddenDoctorNo ? (
              <div className='tr'>
                <div className='th'>
                  <div className='onlyStaff'>
                    {i18n.t('staff.createDoctor.doctorNo')}
                  </div>
                </div>
                <div className='td'>
                  <InputText disabled value={values.doctorNo} name='doctorNo' />
                </div>
              </div>
            ) : null}
            <Modal
              title={i18n.t('doctor.myPage.changeEmail')}
              centered
              visible={changeEmailVisible}
              onOk={() => setChangeEmailVisible(false)}
              onCancel={() => setChangeEmailVisible(false)}
              footer={null}
            >
              <ChangeEmailForm
                doctorId={doctor.id}
                currentEmail={doctor.email}
                i18n={i18n}
              />
            </Modal>
            <div className='flex-item'>
              {!createIndicator && isStaff ? (
                <TextInput
                  {...formikProps}
                  name='lastLoggedIn'
                  disabled
                  classDisabled
                  label={i18n.t('updateProfile.lastLoggedIn')}
                />
              ) : null}
            </div>
            <div className='flex-parent'>
              <div className='flex-item'>
                <TextInput
                  {...formikProps}
                  name='lastname'
                  disabled={disabled}
                  classDisabled
                  label={i18n.t('updateProfile.lastname')}
                  required
                />
              </div>
              <div className='flex-item'>
                <TextInput
                  {...formikProps}
                  name='firstname'
                  disabled={disabled}
                  classDisabled
                  label={i18n.t('updateProfile.firstname')}
                  required
                />
              </div>
              <div className='flex-item'>
                <TextInput
                  {...formikProps}
                  name='lastnameKana'
                  disabled={disabled}
                  classDisabled
                  label={i18n.t('updateProfile.lastnameKana')}
                  required
                />
              </div>
              <div className='flex-item'>
                <TextInput
                  {...formikProps}
                  name='firstnameKana'
                  disabled={disabled}
                  classDisabled
                  label={i18n.t('updateProfile.firstnameKana')}
                  required
                />
              </div>
            </div>
            <CustomDropdown
              {...formikProps}
              name='gender'
              options={genders}
              disabled={disabled}
              classDisabled
              label={i18n.t('updateProfile.gender')}
              required={!isStaff}
            />
            <CustomCalendarPicker
              {...formikProps}
              name='birthday'
              disabled={disabled}
              readOnlyInput
              yearNavigator
              yearRange={yearRange}
              monthNavigator
              classDisabled
              label={i18n.t('updateProfile.birthday')}
              required={!isStaff}
              minDate={minDate.startOf('year').toDate()}
              maxDate={maxDate.endOf('year').toDate()}
              viewDate={
                formikProps.values.birthday
                  ? moment(formikProps.values.birthday).toDate()
                  : minDate.startOf('year').toDate()
              }
              onChange={val => {
                formatDate(moment(val.target.value))
                handleChange(val)
              }}
            />
            <div
              id='result'
              className='td'
              style={{
                marginLeft: '40%',
              }}
            />
            {isViewDoctorMedicalLicenseNumber && (
              <TextInput
                {...formikProps}
                name='medicalLicenseNumber'
                disabled={
                  isEditDoctorMedicalLicenseNumber
                    ? disabled
                    : !isEditDoctorMedicalLicenseNumber
                }
                classDisabled
                label={i18n.t('updateProfile.medicalLicenseNumber')}
              />
            )}

            <CustomCheckBox
              {...formikProps}
              name='departments'
              options={departments}
              disabled={!isStaff}
              label={i18n.t('updateProfile.departments')}
              required
            />
            {!isStaff ? (
              <div className='course-description-doctor'>
                {i18n.t('updateProfile.doctorUpdateClinicalDepartment')}
                <Link
                  to='#'
                  onClick={() => {
                    window.open(`${url}`)
                  }}
                >
                  {i18n.t('updateProfile.doctorLinkInquiry')}
                </Link>
              </div>
            ) : null}
            <div className='tr'>
              <div className={isStaff ? 'th' : `th required`}>
                {i18n.t('updateProfile.insurance')}
              </div>
              <div className='td input-split-wrap' style={{
                padding: '15px 15px 0 15px',
              }}>
                <div className='input-split' style={{maxWidth: '33%'}}>
                  <label htmlFor={'insuranceDoctorSymbol'} style={{
                    display: 'flex',
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                    gap: '6px',
                    padding: '0 12px 0 0'
                  }}>
                    {i18n.t('updateProfile.insuranceDoctorSymbol')}
                    <input
                      id={'insuranceDoctorSymbol'}
                      className='p-inputtext p-component'
                      type='text'
                      name='insuranceDoctorSymbol'
                      defaultValue={values.insuranceDoctorSymbol}
                      onChange={e => handleChange(e)}
                      onBlur={handleBlur}
                      disabled={disabled}
                      maxLength='5'
                    />
                  </label>
                  <ErrorMessage
                    name='insuranceDoctorSymbol'
                    component='div'
                    className='signup-alert'
                  />
                </div>
                <div className='input-split' style={{maxWidth: '15%', width: '100%'}}>
                  <CustomDropdown
                    {...formikProps}
                    name='insuranceDoctorType'
                    options={insuranceDoctorTypes}
                    disabled={disabled}
                    style={{
                      padding: 0
                    }}
                    styleDropdown={{
                      minWidth: '1px'
                    }}
                  />
                </div>
                <div className='input-split' style={{maxWidth: '33%'}}>
                  <label htmlFor={'insuranceDoctorNumber'} style={{
                    display: 'flex',
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                    gap: '6px',
                    padding: '0 0 0 12px'
                  }}>
                    {i18n.t('updateProfile.insuranceDoctorNumber')}
                    <input
                      id={'insuranceDoctorNumber'}
                      className='p-inputtext p-component'
                      type='text'
                      name='insuranceDoctorNumber'
                      defaultValue={values.insuranceDoctorNumber}
                      onChange={e => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, '')
                        handleChange(e)
                      }}
                      onBlur={handleBlur}
                      disabled={disabled}
                      maxLength='10'
                    />
                  </label>
                  <ErrorMessage
                    name='insuranceDoctorNumber'
                    component='div'
                    className='signup-alert'
                  />
                </div>
              </div>
              {
                !isStaff && errorInsurance && <>
                  <div className={'th'}></div>
                  <div className='td' style={{
                    padding: '0 15px',
                    color: '#f84e51',
                    fontSize: '12px',
                    whiteSpace: 'nowrap'
                  }}>保険医登録票番号は必須項目です
                  </div>
                </>
              }
            </div>
            {doctorUpdate ? (
              <div className='textInput-wrapper'>
                <TextInput
                  {...formikProps}
                  name='email'
                  disabled={disabled || !isStaff}
                  classDisabled
                  label={i18n.t('updateProfile.email')}
                  required
                />
                <div className='tr change-email'>
                  <div className='th'/>
                  <div className='td'>
                    <Button
                      label={i18n.t('doctor.myPage.changeEmail')}
                      onClick={() => {
                        setChangeEmailVisible(true)
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <TextInput
                {...formikProps}
                name='email'
                disabled={disabled || !isStaff}
                classDisabled
                label={i18n.t('updateProfile.email')}
                required
              />
            )}
            <div className='tr'>
              <div className={isStaff ? 'th' : `th required`}>
                {i18n.t('updateProfile.phoneNumber')}
              </div>
              <div className='td input-split-wrap'>
                <div className='input-split'>
                  <input
                    className='p-inputtext p-component'
                    type='text'
                    name='phoneNumberFirst'
                    defaultValue={values.phoneNumberFirst}
                    pattern='[0-9]{3}'
                    onChange={e => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '')
                      handleChange(e)
                    }}
                    onBlur={handleBlur}
                    disabled={disabled}
                    maxLength='3'
                  />
                  <ErrorMessage
                    name='phoneNumberFirst'
                    component='div'
                    className='signup-alert'
                  />
                </div>
                <span>-</span>
                <div className='input-split'>
                  <input
                    className='p-inputtext p-component'
                    type='text'
                    name='phoneNumberSecond'
                    defaultValue={values.phoneNumberSecond}
                    pattern='[0-9]{4}'
                    onChange={e => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '')
                      handleChange(e)
                    }}
                    onBlur={handleBlur}
                    disabled={disabled}
                    maxLength='4'
                  />
                  <ErrorMessage
                    name='phoneNumberSecond'
                    component='div'
                    className='signup-alert'
                  />
                </div>
                <span>-</span>
                <div className='input-split'>
                  <input
                    className='p-inputtext p-component'
                    type='text'
                    name='phoneNumberThird'
                    defaultValue={values.phoneNumberThird}
                    pattern='[0-9]{4}'
                    onChange={e => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '')
                      handleChange(e)
                    }}
                    onBlur={handleBlur}
                    disabled={disabled}
                    maxLength='4'
                  />
                  <ErrorMessage
                    name='phoneNumberThird'
                    component='div'
                    className='signup-alert'
                  />
                </div>
              </div>
            </div>
            {updateProfile || createIndicator || isEditAddressDoctorProfile ? (
              <>
                <div className='tr'>
                  <div className={isStaff ? 'th' : `th required`}>
                    {i18n.t('updateProfile.homeAddress.zipCode')}
                  </div>
                  <div className='td input-split-wrap'>
                    <div className='input-split'>
                      <input
                        className={`${
                          disabled ? 'is-disabled' : ''
                        }p-inputtext p-component`}
                        type='text'
                        name='homeAddress.zipCodeFirst'
                        defaultValue={values.homeAddress.zipCodeFirst}
                        pattern='[0-9]{3}'
                        onChange={e => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, '')
                          handleChange(e)
                          if (
                            values.homeAddress.zipCodeSecond &&
                            e.target.value.length === 3
                          ) {
                            getHomeAddress(
                              `${e.target.value}${values.homeAddress.zipCodeSecond}`
                            )
                          }
                        }}
                        disabled={disabled}
                        maxLength='3'
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name='homeAddress.zipCodeFirst'
                        component='div'
                        className='signup-alert'
                      />
                    </div>
                    <span>-</span>
                    <div className='input-split'>
                      <input
                        className={`${
                          disabled ? 'is-disabled' : ''
                        }p-inputtext p-component`}
                        type='text'
                        name='homeAddress.zipCodeSecond'
                        defaultValue={values.homeAddress.zipCodeSecond}
                        pattern='[0-9]{4}'
                        onChange={e => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, '')
                          handleChange(e)
                          if (
                            values.homeAddress.zipCodeFirst &&
                            e.target.value.length === 4
                          ) {
                            getHomeAddress(
                              `${values.homeAddress.zipCodeFirst}${e.target.value}`
                            )
                          }
                        }}
                        onBlur={handleBlur}
                        disabled={disabled}
                        maxLength='4'
                      />
                      <ErrorMessage
                        name='homeAddress.zipCodeSecond'
                        component='div'
                        className='signup-alert'
                      />
                    </div>
                  </div>
                </div>
                <div className='tr'>
                  <div className={`th ${onlyStaff ? 'onlyStaff' : ''}`}>
                    {i18n.t('updateProfile.homeAddress.stateOrRegion')}
                  </div>
                  <div className='td'>
                    <AutoComplete
                      {...formikProps}
                      className={` ${!isStaff ? 'required' : ''} ${
                        onlyStaff ? 'onlyStaff' : ''
                      }`}
                      name='homeAddress.stateOrRegion'
                      field='name_ja'
                      allSuggestions={PROVINCES}
                      suggestionName='stateOrRegions'
                      placeholder='都道府県を選択'
                      setCurrentProvince={val => {
                        const data = PROVINCES.find(
                          ({ name_ja }) => name_ja === val
                        )
                        setFieldValue(
                          'homeAddress.stateOrRegion',
                          data?.name_ja
                        )
                      }}
                    />
                    <ErrorMessage
                      name='homeAddress.stateOrRegion'
                      component='div'
                      className='signup-alert'
                    />
                  </div>
                </div>
                {/* <TextInput
                  {...formikProps}
                  name='homeAddress.stateOrRegion'
                  disabled={disabled}
                  classDisabled
                  label={i18n.t('updateProfile.homeAddress.stateOrRegion')}
                  required={!isStaff}
                /> */}
                <TextInput
                  {...formikProps}
                  name='homeAddress.address1'
                  disabled={disabled}
                  classDisabled
                  label={i18n.t('updateProfile.homeAddress.address1')}
                  required={!isStaff}
                />
                <TextInput
                  {...formikProps}
                  name='homeAddress.address2'
                  disabled={disabled}
                  classDisabled
                  label={i18n.t('updateProfile.homeAddress.address2')}
                />
              </>
            ) : null}
            <div className='tr'>
              <div className={`th ${onlyStaff ? 'onlyStaff' : ''}`}>
                {i18n.t('updateProfile.province')}
              </div>
              <div className='td'>
                <AutoComplete
                  {...formikProps}
                  name='province'
                  field='name_ja'
                  allSuggestions={PROVINCES}
                  suggestionName='provinces'
                  placeholder='都道府県を選択'
                  setCurrentProvince={val => {
                    const data = PROVINCES.find(
                      ({ name_ja }) => name_ja === val
                    )

                    setFieldValue('province', data?.name_ja)
                  }}
                />
                <div className='alert'>
                  {!!touched.province && errors.province}
                </div>
              </div>
            </div>
            <TextInput
              {...formikProps}
              name='placeOfWork'
              disabled={disabled}
              classDisabled
              label={i18n.t('updateProfile.placeOfWork')}
              required={!isStaff}
            />
            <Chips
              {...formikProps}
              name='nearestStations'
              disabled={disabled}
              label={i18n.t('updateProfile.nearestStations')}
            />
            {updateProfile ||
            isEditGraduationUniversityDoctorProfile ||
            createIndicator ? (
              <>
                <TextInput
                  {...formikProps}
                  name='graduationUniversity'
                  disabled={disabled}
                  classDisabled
                  label={i18n.t('updateProfile.graduationUniversity')}
                  required={!isStaff}
                />
                <div className='tr'>
                  <div
                    className={`th ${!isStaff ? 'required' : ''} ${
                      onlyStaff ? 'onlyStaff' : ''
                    }`}
                  >
                    {i18n.t('updateProfile.graduationYears')}
                  </div>
                  <div className='td'>
                    <Dropdown
                      value={graduationUniversityYear}
                      name='graduationUniversityYear'
                      options={years}
                      optionLabel='description'
                      onChange={e => {
                        setFieldValue('graduationUniversityYear', e.value)
                        setGraduationUniversityYear(e.value)
                      }}
                      disabled={disabled}
                      className={disabled ? 'is-disabled' : ''}
                      placeholder='2000'
                    />
                  </div>
                  <div className='alert'>{touched && error}</div>
                </div>
              </>
            ) : null}
            {createIndicator ||
            updateProfile ||
            isEditObtainDoctorLicenseYearDoctorProfile ? (
              <div className='tr'>
                <div
                  className={`th ${!isStaff ? 'required' : ''} ${
                    onlyStaff ? 'onlyStaff' : ''
                  }`}
                >
                  {i18n.t('updateProfile.obtainMedicalLicenseYears')}
                </div>
                <div className='td'>
                  <Dropdown
                    value={obtainDoctorLicenseYear}
                    name='obtainDoctorLicenseYear'
                    options={years}
                    optionLabel='description'
                    onChange={e => {
                      setFieldValue('obtainDoctorLicenseYear', e.value)
                      setObtainDoctorLicenseYear(e.value)
                    }}
                    disabled={disabled}
                    className={disabled ? 'is-disabled' : ''}
                    placeholder='2000'
                  />
                </div>
                <div className='alert'>{touched && error}</div>
              </div>
            ) : null}
            {updateProfile ||
            createIndicator ||
            isEditProfessionalDescriptionDoctorProfile ? (
              <CustomInputTextarea
                {...formikProps}
                name='professionalDescription'
                disabled={disabled}
                classDisabled
                label={i18n.t('updateProfile.professionalDescription')}
                autoResize
              />
            ) : null}

            {!updateProfile && (
              <CustomDropdown
                {...formikProps}
                label={i18n.t('staff.createDoctor.workPattern')}
                name='workPattern'
                options={workPatterns}
                disabled={disabled}
                classDisabled
                onlyStaff
              />
            )}

            {!updateProfile &&
            (createIndicator || isEditAdjustHourlyWageRangeDoctorProfile) ? (
              <div className='tr'>
                <div className='th'>
                  <div className='onlyStaff'>
                    {i18n.t('staff.createDoctor.adjustHourlyWageRange')}
                  </div>
                </div>
                <div className='td'>
                  <Spinner
                    {...formikProps}
                    // inputWidth='auto'
                    onChange={handleChange}
                    name='adjustHourlyWageRange'
                    max={MAX_HOURLY_WAGE}
                    step={500}
                    formatInput
                    className='split-autocomplete'
                    disabled={disabled}
                    value={values.adjustHourlyWageRange}
                  />
                </div>
              </div>
            ) : null}

            {!updateProfile &&
              !disabled &&
              [PERMANENT_DOCTOR, REGULAR_SHIFT_DOCTOR].includes(
                formikProps.values.workPattern
              ) && (
                <div className='signup-input'>
                  <CustomCheckBox
                    {...formikProps}
                    label={i18n.t('staff.createDoctor.legalHoliday')}
                    name='legalHolidays'
                    options={legalHoliday}
                    disabled={disabled}
                    inLine
                  />
                </div>
              )}
            {!updateProfile &&
              disabled &&
              [PERMANENT_DOCTOR, REGULAR_SHIFT_DOCTOR].includes(
                formikProps.values.workPattern
              ) && (
                <div className='tr'>
                  <div className='th'>
                    {i18n.t('staff.createDoctor.legalHoliday')}
                  </div>
                  <div className='td'>
                    {doctor.isAvailableOnMonday
                      ? `${i18n.t('staff.createDoctor.monday')}`
                      : ''}
                    {doctor.isAvailableOnTuesday
                      ? `${i18n.t('staff.createDoctor.tuesday')}`
                      : ''}
                    {doctor.isAvailableOnWednesday
                      ? `${i18n.t('staff.createDoctor.wednesday')}`
                      : ''}
                    {doctor.isAvailableOnThursday
                      ? `${i18n.t('staff.createDoctor.thursday')}`
                      : ''}
                    {doctor.isAvailableOnFriday
                      ? `${i18n.t('staff.createDoctor.friday')}`
                      : ''}
                    {doctor.isAvailableOnSaturday
                      ? `${i18n.t('staff.createDoctor.saturday')}`
                      : ''}
                    {doctor.isAvailableOnSunday
                      ? `${i18n.t('staff.createDoctor.sunday')}`
                      : ''}
                    {doctor.isAvailableOnHoliday
                      ? `${i18n.t('staff.createDoctor.holiday')}`
                      : ''}
                  </div>
                </div>
              )}
            {!updateProfile &&
              (createIndicator || isViewMedicalRecordDDoctorProfile) && (
                <>
                  <TextInput
                    {...formikProps}
                    name='medicalRecord'
                    disabled={
                      isEditMedicalRecordDDoctorProfile
                        ? disabled
                        : !isEditMedicalRecordDDoctorProfile
                    }
                    classDisabled
                    label={i18n.t('staff.createDoctor.medicalRecord')}
                    onlyStaff
                  />

                  <TextInput
                    {...formikProps}
                    name='orca'
                    disabled={
                      isEditMedicalRecordDDoctorProfile
                        ? disabled
                        : !isEditMedicalRecordDDoctorProfile
                    }
                    classDisabled
                    label={i18n.t('staff.createDoctor.orca')}
                    onlyStaff
                  />

                  <TextInput
                    {...formikProps}
                    name='questionnaire'
                    disabled={
                      isEditMedicalRecordDDoctorProfile
                        ? disabled
                        : !isEditMedicalRecordDDoctorProfile
                    }
                    classDisabled
                    label={i18n.t('staff.createDoctor.questionnaire')}
                    onlyStaff
                  />
                </>
              )}
            {!updateProfile &&
              (createIndicator || isEditJoinBackgroundDoctorProfile) && (
                <>
                  <CustomDropdown
                    {...formikProps}
                    label={i18n.t('staff.createDoctor.joinBackground')}
                    name='joinBackground'
                    options={joinBackgrounds}
                    disabled={disabled}
                    classDisabled
                    onlyStaff
                  />
                  <CustomInputTextarea
                    {...formikProps}
                    name='joinBackgroundOther'
                    disabled={disabled}
                    classDisabled
                    required={formikProps.values.joinBackground !== 'DIRECT'}
                    label={i18n.t('staff.createDoctor.joinBackgroundOther')}
                    onlyStaff
                  />
                </>
              )}
            {!updateProfile &&
              (createIndicator || isPageStaffMemoDoctorProfile) && (
                <CustomInputTextarea
                  {...formikProps}
                  name='staffMemo'
                  disabled={!isEditStaffMemoDoctorProfile && !createIndicator}
                  classDisabled
                  label={i18n.t('staff.createDoctor.staffMemo')}
                  onlyStaff
                />
              )}

            {/* add field introduce */}
            {(updateProfile ||
              createIndicator ||
              isEditIntroducedByDoctorProfile) && (
              <>
                <CustomCheckBox
                  {...formikProps}
                  name='isIntroduce'
                  options={isIntroduce}
                  label={i18n.t('doctor.signup.isIntroduceLabel')}
                  disabled={disabled}
                  onceCheck
                />
                <div className='inTroduceBy-container'>
                  <InputText
                    {...formikProps}
                    values={values}
                    value={
                      values.isIntroduce[0] === '0' ? '' : values.introducedBy
                    }
                    name='introducedBy'
                    onChange={handleChange}
                    label={i18n.t('doctor.signup.introduceNameLabel')}
                    onBlur={handleBlur}
                    disabled={
                      values.isIntroduce[0] === '0' ||
                      values.isIntroduce[0] === '' ||
                      disabled
                    }
                  />
                  <ErrorMessage
                    name='introducedBy'
                    component='div'
                    className='signup-alert'
                  />
                </div>
              </>
            )}

            <div className='spacing' />
            {isStaff ? (
              <>
                <div className='signup-input signup-reason' style={{ marginTop: '40px' }}>
                  <CustomCheckBox
                    {...formikProps}
                    name='reasonKnowWebsites'
                    options={reasons}
                    disabled={disabled}
                    label={i18n.t('doctor.signup.reasonKnowWebsiteLabel')}
                    breakLine
                  />
                </div>
                <div className='signup-input'>
                  <div className='tr'>
                    <div className='th' />
                    <div className='td'>
                      <InputText
                        {...formikProps}
                        value={
                          values.reasonKnowWebsites.includes('OTHER')
                            ? values.otherReasonKnowWebsite
                            : ''
                        }
                        name='otherReasonKnowWebsite'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label={i18n.t(
                          'doctor.signup.reasonKnowWebsiteDescriptionLabel'
                        )}
                        disabled={!values.reasonKnowWebsites.includes('OTHER')}
                        breakLine
                      />
                      <ErrorMessage
                        name='otherReasonKnowWebsite'
                        component='div'
                        className='signup-alert'
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : null}
            {/* New field */}
            {(updateProfile ||
              createIndicator ||
              isEditSkillInformationDoctorProfile) && (
              <div className='is-join-field'>
                <CustomCheckBox
                  {...formikProps}
                  name='isJoinedSublingualImmunotherapyELearning'
                  options={isCompletedOption}
                  label={i18n.t('doctor.signup.elearningLabel')}
                  disabled={disabled}
                  onceCheck
                />
                {!isStaff ? (
                  <div className='course-description-doctor'>
                    {i18n.t('doctor.signup.elearningDescription')}
                    <Link
                      to='#'
                      onClick={e => {
                        e.preventDefault()
                        window.open(
                          'http://www.ait-e-learning.jp/index.php',
                          '_blank'
                        )
                      }}
                    >
                      {i18n.t('doctor.signup.elearningLink')}
                    </Link>
                  </div>
                ) : null}
                <CustomCheckBox
                  {...formikProps}
                  name='isJoinedOnlineDiagnosticTraining'
                  options={isJoinedTrainingOption}
                  label={i18n.t('doctor.signup.diagnosticTrainingLabel')}
                  disabled={disabled}
                  onceCheck
                />
                {!isStaff ? (
                  <div className='course-description-doctor'>
                    {i18n.t('doctor.signup.diagnosticTrainingDescription')}
                    <Link
                      to='#'
                      onClick={e => {
                        e.preventDefault()
                        window.open(
                          'https://telemed-training.jp/entry',
                          '_blank'
                        )
                      }}
                    >
                      {i18n.t('doctor.signup.diagnosticTrainingLink')}
                    </Link>
                  </div>
                ) : null}
              </div>
            )}
            {(updateProfile ||
              createIndicator ||
              isEditCareerHistoryDoctorProfile) && (
              <>
                <div className='signup-label label-carrerHistory'>
                  {i18n.t('doctor.signup.careerHistoryLabel')}
                </div>
                <div className='career-history-field '>
                  <FieldArray
                    name='careerHistories'
                    render={arrayHelpers => (
                      <div>
                        {values.careerHistories.map((career, index) => (
                          <div key={index}>
                            <div
                              className={`career-history ${
                                isStaff ? 'career-history-container' : ''
                              }`}
                            >
                              <div
                                className={`row ${
                                  !isStaff ? 'career-history_doctor' : ''
                                }`}
                              >
                                <div className='year-container title-career'>
                                  <CustomDropdownCarrer
                                    {...formikProps}
                                    name={`careerHistories[${index}].year`}
                                    parentName='careerHistories'
                                    childName='year'
                                    index={index}
                                    options={years}
                                    classDisabled
                                    disabled={disabled}
                                    required
                                  />
                                </div>
                                <div className='tail-text'>
                                  {i18n.t('doctor.signup.year')}
                                </div>
                                <div className='row '>
                                  <CustomDropdownCarrer
                                    {...formikProps}
                                    parentName='careerHistories'
                                    name={`careerHistories[${index}].month`}
                                    childName='month'
                                    index={index}
                                    options={listMonth}
                                    classDisabled
                                    disabled={disabled}
                                    required
                                  />
                                </div>
                                <div className=' tail-text'>
                                  {i18n.t('doctor.signup.month')}
                                </div>
                                <div className='row carrer-description'>
                                  <InputTextarea
                                    {...formikProps}
                                    name={`careerHistories[${index}].description`}
                                    value={
                                      values.careerHistories[index].description
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoResize
                                    disabled={disabled}
                                    rows={1}
                                    className='updateForm'
                                  />
                                </div>
                                <div className='col-1 tail-text'>
                                  {i18n.t('doctor.signup.experience')}
                                </div>
                                <div className='col-1 '>
                                  <Button
                                    type='button'
                                    label='x'
                                    disabled={disabled}
                                    onClick={() => {
                                      arrayHelpers.remove(index)
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='errors-container'>
                              <ErrorMessage
                                name={`careerHistories[${index}].year`}
                                component='div'
                                className='signup-alert'
                              />
                              <ErrorMessage
                                name={`careerHistories[${index}].month`}
                                component='div'
                                className='signup-alert'
                              />
                              <ErrorMessage
                                name={`careerHistories[${index}].description`}
                                component='div'
                                className='signup-alert'
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  />
                  <div className='row add-career-button'>
                    <Button
                      type='button'
                      disabled={disabled}
                      onClick={() => {
                        setFieldValue('careerHistories', [
                          ...values.careerHistories,
                          {
                            year: '',
                            month: '',
                            description: '',
                          },
                        ])
                      }}
                      label={i18n.t('doctor.signup.addCareerButton')}
                    />
                  </div>
                </div>
              </>
            )}
            {data.skillsConnection.edges.map(({ node }, index) => (
              <div className='tr'>
                <div className='th'>{node.name}</div>
                <div className='td'>
                  <div className='checkbox-container checkbox-field'>
                    <Checkbox
                      checked={values.skillSet.includes(`${node.id}`)}
                      onChange={() => {
                        if (!values.skillSet.includes(`${node.id}`)) {
                          const newValue = values.skillSet
                          newValue.push(node.id)
                          setFieldValue('skillSet', newValue)
                        } else {
                          const newValue = values.skillSet
                          const index = findIndex(newValue, node.id)
                          if (index !== 'noIndex') {
                            newValue.splice(index, 1)
                          }
                          setFieldValue('skillSet', newValue)
                        }
                      }}
                    />
                    <div className='checkbox-label'>可</div>
                  </div>
                  <div className='checkbox-container checkbox-field'>
                    <Checkbox
                      checked={!values.skillSet.includes(`${node.id}`)}
                      onChange={() => {
                        if (values.skillSet.includes(`${node.id}`)) {
                          const newValue = values.skillSet
                          const index = findIndex(newValue, node.id)
                          if (index !== 'noIndex') {
                            newValue.splice(index, 1)
                          }
                          setFieldValue('skillSet', newValue)
                        } else {
                          const newValue = values.skillSet
                          newValue.push(node.id)
                          setFieldValue('skillSet', newValue)
                        }
                      }}
                    />
                    <div className='checkbox-label'>不可</div>
                  </div>
                </div>
              </div>
            ))}
            {createIndicator && (
              <div className='tr'>
                <div className='th' style={{ whiteSpace: 'normal' }}>
                  {i18n.t('staff.createDoctor.viaReferral')}
                </div>
                <div className='td'>
                  <Checkbox
                    onChange={() => {
                      setFieldValue('isViaReferral', !values.isViaReferral)
                    }}
                    checked={values.isViaReferral}
                    name='isViaReferral'
                  />
                </div>
              </div>
            )}
            {/* マイカー通勤申請  医師サイト側のみ */}
            {updateProfile && (
              <div className='tr'>
                <div className='th'>
                  {i18n.t('transportApplication.myCarCommutingApplycation')}
                </div>
                <div className='td'>
                  <div className='checkbox-item toggle-published'>
                    <Switch
                      checkedChildren='申請済み'
                      unCheckedChildren='未申請'
                      name='isBlockedMyCar'
                      checked={values.isBlockedMyCar}
                      onChange={e => {
                        setFieldValue('isBlockedMyCar', e)
                        setFieldValue('isAgree', !e)
                        setAgreeTermVisible(e)
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            {agreeTermVisible && (
              <TermsOfCarTransportationExpenses {...formikProps} />
            )}
            {!disabled && (
              <div className='modal-bottom'>
                {isCreateDoctorDeleteRequestPermission ? (
                  <Button
                    className='cancel-button'
                    onClick={() => {
                      setFieldValue('confirm', true)
                      setFieldValue('functionName', 'createDoctorDeleteRequest')
                      setFieldValue(
                        'confirmationMessage',
                        'この医師データの削除申請をしますか?この申請ではデータの削除はされません。'
                      )
                    }}
                    type='submit'
                    disabled={doctor.deletedBy}
                    label={doctor.deletedBy ? '削除申請済み' : '削除申請する'}
                  />
                ) : null}
                <Button
                  onClick={() => {
                    if (!isStaff && (!values.insuranceDoctorType || !values.insuranceDoctorNumber || !values.insuranceDoctorSymbol)) {
                      setErrorInsurance(true);
                      return;
                    }
                    validateForm().then(response => {
                      if (!Object.keys(response).length) {
                        setFieldValue('confirm', true)
                        setFieldValue('functionName', 'updateDoctor')
                      } else {
                        setTouched(response)
                      }
                    })
                  }}
                  label={buttonLabel}
                />
                {isStaff && isCanBlockDoctorMail && (
                  <>
                    {doctor && doctor.isBlockedMail ? (
                      <Button
                        onClick={() => {
                          setFieldValue('confirm', true)
                          setFieldValue('functionName', 'blockMail')
                          setFieldValue(
                            'confirmationMessage',
                            'このメールアドレスを有効化しますか?'
                          )
                        }}
                        type='submit'
                        label='メールアドレス有効化'
                      />
                    ) : (
                      <Button
                        onClick={() => {
                          setFieldValue('confirm', true)
                          setFieldValue('functionName', 'blockMail')
                          setFieldValue(
                            'confirmationMessage',
                            'このメールアドレスを無効化しますか?'
                          )
                        }}
                        type='submit'
                        label='メールアドレス無効化'
                      />
                    )}
                  </>
                )}
              </div>
            )}
            <ConfirmationModal
              {...formikProps}
              isUpdate={
                isUpdate &&
                checkEditAddress &&
                values.functionName === 'updateDoctor'
              }
              createIndicator={createIndicator}
              confirmationMessage={values.confirmationMessage}
              doctorUpdate={doctorUpdate}
            />
          </React.Fragment>
        )
      }}
    />
  )
}

export default DoctorProfileForm
