import { workScheduleColor } from '@medical/styles'
import moment from 'moment'

const workScheduleToEvents = workSchedules =>
  workSchedules.map(workSchedule => {
    const {
      id,
      startTime: start,
      endTime: end,
      accepted,
      clinicalDepartment: {
        name: clinicalDepartmentName,
        clinic: { name: clinicName },
      },
    } = workSchedule
    const title = `${moment(start).format('HH:mm')} - ${moment(end).format(
      'HH:mm'
    )} \n${clinicName}${clinicalDepartmentName}`
    // const title = `${clinicName} ${clinicalDepartmentName}`
    const color = workScheduleColor[accepted]
    return {
      id,
      start,
      end,
      color,
      title,
    }
  })

export default workScheduleToEvents
