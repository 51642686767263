import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import gql from 'graphql-tag'

export const DOCTOR_GET_TRANSPORT_REPORTS = gql`
  query Query($date: DateTime!) {
    doctorMe {
      id
      isBlockedMyCar
      doctorMonthlyTransportationExpenses(date: $date) {
        id
        createdAt
        deletedAt
        endTime
        startTime
        cost
        status
        type
        category
        blockedDays
        billImage
        urlImageS3
        clinic {
          id
          name
          nameKana
        }
        clinicalDepartment {
          id
          name
          extraName
          clinic {
            id
            name
            nameKana
          }
        }
      }
      doctorMonthlyWorkSchedules(date: $date) {
        id
        startTime
        notApplyCostTransportation
        isTypeTransportation
        costTransportationByMyCar
        costTransportationByDoctorWorkSchedule {
          id
          endTime
          startTime
          returnPath
          cost
        }
        startTime
        endTime
        clinicalDepartment {
          id
          name
          extraName
          clinic {
            id
            name
            nameKana
          }
        }
      }
    }
    ...DEPARTMENTS_FRAGMENT
  }
  ${DEPARTMENTS_FRAGMENT}
`

export const DOCTOR_GET_TRANSPORT_BY_CAR = gql`
  query DoctorAddressHistories(
    $where: DoctorWhereUniqueInput!
    $orderBy: DoctorAddressHistoryOrderByInput
    $first: Int
    $skip: Int
    $doctorAddressHistoriesCondition: DoctorAddressHistoryWhereInput
  ) {
    doctor(where: $where) {
      id
      fullName
      homeAddress {
        id
        address1
        address2
      }
      doctorAddressHistories(
        orderBy: $orderBy
        first: $first
        skip: $skip
        where: $doctorAddressHistoriesCondition
      ) {
        id
        dataTransportation
      }
    }
  }
`
