import { ProgressSpinner as Spinner } from 'primereact/progressspinner'
import React from 'react'

const ProgressSpinner = ({ fullHeight }) => (
  <div
    style={{
      width: '100%',
      height: fullHeight ? '100vh' : '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Spinner style={{ width: '50px', height: '50px' }} />
  </div>
)

export default ProgressSpinner
