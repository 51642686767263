import gql from 'graphql-tag'

export const ANNOUNCE_FRAGMENT = gql`
  fragment ANNOUNCE_FRAGMENT on Announce {
    id
    createdAt
    title
    content
    isPublished
    isSentEmailToAllDoctors
    imageKey
    isAddAvailableShift
    isAddRecruitmentPost
  }
`

export const ANNOUNCES_CONNECTION = gql`
  query ANNOUNCES_CONNECTION(
    $where: AnnounceWhereInput
    $skip: Int
    $first: Int
    $orderBy: AnnounceOrderByInput
  ) {
    announcesConnection(
      where: $where
      skip: $skip
      first: $first
      orderBy: $orderBy
    ) {
      edges {
        node {
          ...ANNOUNCE_FRAGMENT
        }
      }
      aggregate(where: $where) {
        count
      }
    }
  }
  ${ANNOUNCE_FRAGMENT}
`

export const DELETE_ANNOUNCE = gql`
  mutation DELETE_ANNOUNCE($announceId: ID!) {
    staffDeleteAnnounce(announceId: $announceId) {
      ...ANNOUNCE_FRAGMENT
    }
  }
  ${ANNOUNCE_FRAGMENT}
`
export const AVAILABLESHIFTS = gql`
  query AVAILABLESHIFTS($where: AvailableShiftWhereInput) {
    availableShifts(where: $where) {
      id
      startTime
      endTime
      clinicalDepartment {
        id
        name
        clinic {
          id
          name
        }
      }
    }
  }
`

export const RECRUITMENT_POSTS = gql`
  query RecruitmentPosts($where: RecruitmentPostWhereInput) {
    recruitmentPosts(where: $where) {
      id
      title
      clinicalDepartment {
        id
        name
        clinic {
          id
          name
        }
      }
    }
  }
`
