import ConfirmationModal from '@medical/components/ConfirmationModal'
import { Input } from 'antd'
import { ErrorMessage, Formik } from 'formik'
import { Button } from 'primereact/button'
import React, { useState } from 'react'

const CategoryForm = ({
  onSubmit,
  i18n,
  listCategory = [],
  isLargeTitle,
  isCreatePermitted,
  isUpdatePermitted,
  isDeletePermitted,
}) => {
  const [editCategory, setEditCategory] = useState([])
  return (
    <Formik
      enableReinitialize
      initialValues={{
        listCategory,
        createContent: '',
        functionName: '',
        id: '',
        confirmationMessage: '',
      }}
      onSubmit={onSubmit}
      render={formikProps => {
        const { setFieldValue, values } = formikProps
        return (
          <div>
            <div className='tr'>
              <div className='staff-title' style={{ marginBottom: '20px' }}>
                {isLargeTitle ? '大項目編集' : '中項目編集'}
              </div>
            </div>
            <div>
              {values.listCategory && values.listCategory.length > 0
                ? values.listCategory.map((as, index) => (
                    <div className='tr'>
                      <div className='td' style={{ width: '70%' }}>
                        <Input
                          className='category-form-td'
                          value={values.listCategory[index].content}
                          name={`listCategory[${index}].content`}
                          disabled={values.listCategory[index].edit}
                          onChange={e => {
                            setFieldValue(
                              `listCategory[${index}].content`,
                              e.target.value
                            )
                          }}
                        />
                      </div>
                      <div className='td' style={{ width: '30%' }}>
                        {values.listCategory[index].edit && isUpdatePermitted && (
                          <Button
                            style={{ marginLeft: '10px' }}
                            icon='pi pi-pencil'
                            onClick={() => {
                              setEditCategory(!editCategory)
                              values.listCategory[index].edit = false
                            }}
                          />
                        )}
                        {!values.listCategory[index].edit && isUpdatePermitted && (
                          <Button
                            style={{ marginLeft: '10px' }}
                            icon='pi pi-save'
                            disabled={!values.listCategory[index].content}
                            onClick={() => {
                              if (values.listCategory[index].content) {
                                setFieldValue(
                                  'confirmationMessage',
                                  '更新しますか？'
                                )
                                setFieldValue(
                                  'id',
                                  values.listCategory[index].id
                                )
                                setFieldValue(
                                  'content',
                                  values.listCategory[index].content
                                )
                                setFieldValue('functionName', 'update')
                                setFieldValue('confirm', true)
                              }
                            }}
                          />
                        )}
                        {isDeletePermitted && (
                          <Button
                            style={{ marginLeft: '10px' }}
                            icon='pi pi-trash'
                            onClick={() => {
                              setFieldValue(
                                'confirmationMessage',
                                '削除しますか？'
                              )
                              setFieldValue('id', values.listCategory[index].id)
                              setFieldValue(
                                'content',
                                values.listCategory[index].content
                              )
                              setFieldValue('functionName', 'delete')
                              setFieldValue('confirm', true)
                            }}
                          />
                        )}
                      </div>
                    </div>
                  ))
                : null}

              <div className='tr'>
                <div className='td' style={{ width: '70%' }}>
                  <Input
                    value={values.createContent}
                    className='category-form-td'
                    onChange={e => {
                      setFieldValue('createContent', e.target.value)
                    }}
                  />
                  <ErrorMessage
                    name='createContent'
                    component='div'
                    className='signup-alert'
                  />
                </div>
                <div className='td' style={{ width: '30%' }} />
              </div>
              <div style={{ marginTop: '40px', textAlign: 'center' }}>
                {isCreatePermitted && (
                  <Button
                    label='作成'
                    className='reflect-button'
                    icon='pi pi-plus'
                    type='submit'
                    disabled={!values.createContent}
                    onClick={() => {
                      setFieldValue('confirmationMessage', '作成しますか？')
                      setFieldValue('functionName', 'create')
                      setFieldValue('confirm', true)
                    }}
                  />
                )}
              </div>
            </div>
            <ConfirmationModal
              {...formikProps}
              confirmationMessage={values.confirmationMessage}
            />
          </div>
        )
      }}
    />
  )
}
export default CategoryForm
