import { RadioButton } from 'primereact/radiobutton'
import { pathOr } from 'ramda'
import React from 'react'

const CustomRadioButton = ({
  name,
  disabled,
  values,
  touched,
  errors,
  handleChange,
  options = [],
  optionLabel = 'description',
  valueLabel = 'value',
  apiName,
}) => {
  const splittedName = name.split('.')
  const valuesName = pathOr(false, splittedName, values)
  const isTouched = pathOr(false, splittedName, touched)
  const error = pathOr('', splittedName, errors)
  return (
    <React.Fragment>
      {options.map(option => (
        <div className='tr' key={option[valueLabel]}>
          <RadioButton
            value={option[valueLabel]}
            disabled={disabled}
            name={name}
            onChange={handleChange}
            checked={valuesName === option[valueLabel]}
            inputId={apiName + option[valueLabel]}
          />
          <label
            htmlFor={apiName + option[valueLabel]}
            className='checkbox-label'
          >
            {option[optionLabel]}
          </label>
        </div>
      ))}
      <div className='alert'>{isTouched && error}</div>
    </React.Fragment>
  )
}

export default CustomRadioButton
