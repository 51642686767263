import { useMutation } from '@apollo/react-hooks'
import {
  STAFF_CHECK_SHIFT_CSV,
  STAFF_UPLOAD_SHIFT_CSV,
} from '@medical/constant/permissions'
import {
  checkStaffPermission,
  convertUserIncentiveCsvRecord,
} from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import downloadCsv from 'download-csv'
import React, { useState } from 'react'

import {
  CHECK_UPLOAD_SHIFTS,
  CREATE_ACTIVITY,
  UPLOAD_SHIFTS,
} from './IncentiveCsv.graphql'
import IncentiveCsvScene from './IncentiveCsvScene'

const IncentiveCsv = () => {
  const [{ i18n, popup }] = useCustom()
  const [uploadShifts] = useMutation(UPLOAD_SHIFTS)
  const [checkUploadShifts] = useMutation(CHECK_UPLOAD_SHIFTS)
  const [staffCreateActivity] = useMutation(CREATE_ACTIVITY)
  const [state, setState] = useState({
    isUploaded: false,
  })
  const isUploadPermitted = checkStaffPermission({
    functionName: STAFF_UPLOAD_SHIFT_CSV,
  })
  const isUploadCheckPermitted = checkStaffPermission({
    functionName: STAFF_CHECK_SHIFT_CSV,
  })

  const onSubmit = async ({ data: UserCsvData }, { resetForm }) => {
    await setState({
      isUploaded: true,
    })
    try {
      if (!isUploadPermitted) {
        popup.error(i18n.t('main.noPermission'))
      } else {
        popup.info(i18n.t('staff.uploadCsv.uploading'))
        const doctorIncentiveReportCsvRecords = convertUserIncentiveCsvRecord(
          UserCsvData
        )
        await uploadShifts({
          variables: {
            doctorIncentiveReportCsvRecords,
          },
        })
        staffCreateActivity({
          variables: {
            activity: 'STAFF_QUESTIONNAIRE_DATA_CSV_UPLOAD',
          },
        })
        popup.clear()
        popup.success(i18n.t('staff.uploadCsv.submissionSuccess'))
        resetForm()
      }
    } catch (error) {
      popup.clear()
      popup.error(error)
    }
    await setState({
      isUploaded: false,
    })
  }

  const checkCsvData = async ({ data: UserCsvData }, { setFieldValue }) => {
    try {
      if (!isUploadCheckPermitted) {
        popup.error(i18n.t('main.noPermission'))
      } else {
        popup.info(i18n.t('staff.uploadCheckCsv.uploading'))
        const doctorIncentiveReportCsvRecords = convertUserIncentiveCsvRecord(
          UserCsvData
        )
        const {
          data: { staffCheckUploadIncentiveReportCsv },
        } = await checkUploadShifts({
          variables: {
            doctorIncentiveReportCsvRecords,
          },
        })
        const messageAddedCsvData = UserCsvData.map((item, index) => ({
          ...item,
          errorMessage: staffCheckUploadIncentiveReportCsv[index].errorMessage,
          checked: true,
        }))
        setFieldValue('data', messageAddedCsvData)
        popup.clear()
        popup.success(i18n.t('staff.uploadCheckCsv.submissionSuccess'))
      }
    } catch (error) {
      popup.clear()
      popup.error(error)
    }
  }
  const downloadSample = () => {
    const data = [
      {
        name: '山田太郎',
        email: 'sample_yamada_taro@gmail.com',
        department: '小児科',
        clinic: '西葛西',
        date: '2022/1/1',
        field1: '44',
        field2: '22',
        field3: '0',
        field4: '1',
        field5: '65',
        field6: '73',
        field7: '67',
        field8: '100',
        field9: '30',
        field10: '70',
        field11: '6.5',
        field12: '6.7',
        field13: '5.4',
        field14: '5.4',
      },
      {
        name: '山田花子',
        email: 'sample_yamada_hanako@gmail.com',
        department: '小児科',
        clinic: '武蔵小杉',
        date: '2022/1/1',
        field1: '56',
        field2: '12',
        field3: '1',
        field4: '0',
        field5: '81',
        field6: '73',
        field7: '69',
        field8: '100',
        field9: '30',
        field10: '70',
        field11: '7.0',
        field12: '6.7',
        field13: '5.4',
        field14: '5.4',
      },
    ]
    downloadCsv(
      data,
      {
        name: '氏名',
        email: 'DS登録メールアドレス',
        department: '診療科',
        clinic: '拠点',
        date: '日付',
        field1: '非常に満足したの件数',
        field2: 'まあまあ満足したの件数',
        field3: '普通の件数',
        field4: '満足できなかったの件数',
        field5: '非常に満足の割合',
        field6: '前項目の全体平均',
        field7: '総診察件数',
        field8: '総診察時間',
        field9: '診察のみの診察件数',
        field10: '診察のみの診察時間',
        field11: '一件当たりのすべての診療時間の平均',
        field12: '一件当たりのすべての診療時間のその日の全体平均',
        field13: '一件当たりの診察のみの診療時間の平均',
        field14: '一件当たりの診察のみの診療時間のその日の全体平均',
      },
      'インセンティブサンプルCSVデータ.csv'
    )
  }

  return (
    <IncentiveCsvScene
      popup={popup}
      i18n={i18n}
      onSubmit={onSubmit}
      downloadSample={downloadSample}
      isUploaded={state.isUploaded}
      checkCsvData={checkCsvData}
      isUploadPermitted={isUploadPermitted}
      isUploadCheckPermitted={isUploadCheckPermitted}
    />
  )
}

export default IncentiveCsv
