import React, { useEffect } from 'react'
import queryString from 'query-string'
import { Result } from 'antd'
import { useMutation } from '@apollo/react-hooks'
import { CONFIRM_EMAIL } from './ConfirmEmail.graphql'
import { useCustom } from '@medical/provider/context'
import { Button } from 'primereact/button'

export const ConfirmEmail = ({ history, location: { search } }) => {
  const { token } = queryString.parse(search)
  const [confirmEmail, { loading, error, data }] = useMutation(CONFIRM_EMAIL)
  const [{ i18n }] = useCustom()

  useEffect(() => {
    confirmEmail({
      variables: {
        token,
      },
    })
  }, [token])

  const gotToHome = () => {
    history.push('')
  }

  return (
    <>
      <Result
        status={data ? 'success' : error ? 'warning' : 'info'}
        title={
          data
            ? i18n.t('doctor.myPage.confirmEmailSuccess')
            : loading
            ? i18n.t('doctor.myPage.loading')
            : error && error.graphQLErrors[0].debug.message === 'jwt expired'
            ? i18n.t('doctor.myPage.expiredToken')
            : i18n.t('doctor.myPage.internalServerError')
        }
        extra={
          <Button onClick={gotToHome} label={i18n.t('doctor.menuBar.home')}/>
        }
      />
    </>
  )
}
