/* eslint-disable no-plusplus */
/* eslint-disable no-useless-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, NotFound, ProgressSpinner } from '@medical/components'
import ClinicNameRender from '@medical/components/ClinicNameRender'
import { ACTION_TYPE, CATEGORY_TRANSPORT } from '@medical/constant'
import { UPDATE_MOVING_EXPENSES_REGISTRATION } from '@medical/constant/permissions'
import {
  calcTotalTransportationExpense,
  calcWorkScheduleTotalDays,
  checkStaffPermission,
  clinicalDepartmentNames,
} from '@medical/libs'
import MovingExpensesDetailScene from '@medical/pages/staff/MovingExpenses/ListMovingExpenses/MovingExpensesDetailScene'
import { useCustom } from '@medical/provider/context'
import Router from '@medical/routes/router'
import moment from 'moment'
import { Button } from 'primereact/button'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import {
  GET_MONTHLY_MOVING_EXPENSES_DETAIL,
  HANDLE_DELETE_MOVING_EXPENSES_DETAIL,
  HANDLE_DELETE_MOVING_EXPENSES_DETAIL_WORKSCHEDULE,
} from './MovingExpensesDetail.graphql'
import { UPDATE_TYPE_TRANSPORTATION_WORKSCHEDULE } from './ListMovingExpenses.graphql'

const MovingExpensesDetail = ({
  match: {
    params: { doctorId, year = moment().year(), month = moment().month() + 1 },
  },
}) => {
  const [{ i18n, popup }] = useCustom()

  const date = moment()
    .utc()
    .year(year)
    .month(month - 1)
    .startOf('month')

  const variables = {
    where: {
      id: doctorId,
    },
    date,
    doctorId,
  }

  const { loading, error, data, refetch } = useQuery(
    GET_MONTHLY_MOVING_EXPENSES_DETAIL,
    {
      variables,
    }
  )
  useEffect(() => {
    refetch()
  })
  const mutationOptions = {
    refetchQueries: [
      {
        query: GET_MONTHLY_MOVING_EXPENSES_DETAIL,
        variables,
      },
    ],
    awaitRefetchQueries: true,
  }
  const [deleteMovingExpense] = useMutation(
    HANDLE_DELETE_MOVING_EXPENSES_DETAIL,
    mutationOptions
  )
  const [updateType] = useMutation(
    UPDATE_TYPE_TRANSPORTATION_WORKSCHEDULE,
    mutationOptions
  )
  const [restoreDeletedMovingExpense] = useMutation(
    HANDLE_DELETE_MOVING_EXPENSES_DETAIL,
    mutationOptions
  )
  const [deleteMovingExpenseWorkschedule] = useMutation(
    HANDLE_DELETE_MOVING_EXPENSES_DETAIL_WORKSCHEDULE,
    mutationOptions
  )
  const [restoreDeletedMovingExpenseWorkschedule] = useMutation(
    HANDLE_DELETE_MOVING_EXPENSES_DETAIL_WORKSCHEDULE,
    mutationOptions
  )
  const isUpdateMovingExpenses = checkStaffPermission({
    functionName: UPDATE_MOVING_EXPENSES_REGISTRATION,
  })
  if (!isUpdateMovingExpenses) {
    return (
      <div className='static-container'>
        <div className='static-title'>エラー</div>
        <div className='static-content'>{i18n.t('main.noPermissionToSee')}</div>
        <Link to={Router.staffHome}>
          <Button
            label={i18n.t('main.pageNotFoundButton')}
            className='static-button'
          />
        </Link>
      </div>
    )
  }

  const onSubmit = async (
    { startTime, functionName, transportationsId, workScheduleId, isMyCar },
    { setSubmitting, resetForm }
  ) => {
    try {
      if (functionName === ACTION_TYPE.DELETE) {
        if (workScheduleId) {
          await deleteMovingExpenseWorkschedule({
            variables: {
              workScheduleId,
              reverse: false,
              dateTime: moment(startTime).format('YYYY-MM-DD'),
            },
          })
        } else {
          await deleteMovingExpense({
            variables: {
              transportationsId,
              reverse: false,
              dateTime: moment(startTime).format('YYYY-MM-DD'),
            },
          })
        }
        setSubmitting(false)
        resetForm()
        popup.success(i18n.t('main.movingExpenses.deleteSuccess'))
      } else if (functionName === 'UPDATE_TYPE') {
        await updateType({
          variables: {
            where: {
              id: workScheduleId,
            },
            data: {
              isTypeTransportation: isMyCar,
            },
          },
        })
        setSubmitting(false)
        resetForm()
        popup.success(i18n.t('staff.createAnnounce.submissionSuccess'))
      } else {
        if (workScheduleId) {
          await restoreDeletedMovingExpenseWorkschedule({
            variables: {
              workScheduleId,
              reverse: true,
              dateTime: moment(startTime).format('YYYY-MM-DD'),
            },
          })
        } else {
          await restoreDeletedMovingExpense({
            variables: {
              transportationsId,
              reverse: true,
              dateTime: moment(startTime).format('YYYY-MM-DD'),
            },
          })
        }

        setSubmitting(false)
        resetForm()
        popup.success(i18n.t('main.movingExpenses.restoreDeletedSuccess'))
      }
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
      resetForm()
    }
  }
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  if (!data.doctor || !data.departments) {
    return <NotFound />
  }
  const {
    doctorMonthlyTransportationExpenses,
    isBlockedMyCar,
    doctorMonthlyWorkSchedules: workSchedules = [],
  } = data.doctor
  const lstTransportationWithDate = []
  for (const transportation of doctorMonthlyTransportationExpenses) {
    if (transportation.category === CATEGORY_TRANSPORT.TRANFFIC_FEE) {
      lstTransportationWithDate.push({ ...transportation })
    } else {
      const startTime = moment(transportation.startTime).clone()
      const endTime = moment(transportation.endTime)
      while (startTime.isSameOrBefore(endTime)) {
        const timeCurrent = moment(startTime).format('YYYY-MM-DD')
        const datesCheck = transportation.blockedDays
          ? transportation.blockedDays.split(',')
          : []

        if (!datesCheck.includes(timeCurrent.slice(0, 10))) {
          lstTransportationWithDate.push({
            ...transportation,
            startTime: `${timeCurrent}T00:00:00.000Z`,
            endTime: `${timeCurrent}T00:00:00.000Z`,
          })
        }
        startTime.add(1, 'days')
      }
    }
  }
  const departmentNames = clinicalDepartmentNames({
    departments: data.departments,
  })

  const fullName = `${data.doctor.lastname} ${data.doctor.firstname}`

  const sortedWorkSchedules = workSchedules
    .sort((a, b) => moment(a.startTime) - moment(b.startTime))
    .map(ws => ({
      id: ws.id,
      isShowSelectType: true,
      isTypeTransportation: !!ws.isTypeTransportation,
      endTime: ws.startTime,
      deletedAt: ws.deletedAt,
      clinic: ClinicNameRender({
        clinicalDepartment: ws.clinicalDepartment,
      }),
      clinicalDepartment: departmentNames[ws.clinicalDepartment.name],
      startTime: ws.startTime,
      type:
        ws.costTransportationByDoctorWorkSchedule.length > 0
          ? ws.costTransportationByDoctorWorkSchedule[0].type
          : '',
      costWs: ws.isTypeTransportation
        ? ws.costTransportationByMyCar
        : ws.costTransportationByDoctorWorkSchedule.length > 0
        ? ws.costTransportationByDoctorWorkSchedule[0].cost
        : 0,
      costTransportationByDoctorWorkSchedule: ws.isTypeTransportation
        ? ws.costTransportationByMyCar
        : ws.costTransportationByDoctorWorkSchedule.length > 0
        ? ws.costTransportationByDoctorWorkSchedule[0].returnPath[2]
        : 0,
      notApplyCostTransportation: ws.notApplyCostTransportation,
    }))

  const dataTransportation = lstTransportationWithDate
    .sort((a, b) => moment(a.endTime) - moment(b.endTime))
    .map(item => ({
      id: item.id,
      deletedAt: item.deletedAt,
      type: item.type,
      isStaffCreate: item.category === CATEGORY_TRANSPORT.TRANFFIC_FEE,
      category:
        item.category !== CATEGORY_TRANSPORT.TRANFFIC_FEE
          ? item.category
          : undefined,
      clinicDate:
        item.category !== CATEGORY_TRANSPORT.TRANFFIC_FEE
          ? item.clinic.name
          : null,
      clinic:
        item.clinicalDepartment &&
        ClinicNameRender({
          clinicalDepartment: item.clinicalDepartment,
        }),
      clinicalDepartment:
        item.clinicalDepartment &&
        departmentNames[item.clinicalDepartment.name],
      cost: item.cost,
      createdAt: item.createdAt,
      startTime: item.startTime,
      endTime: item.endTime,
      isTransport: true,
      departmentID: item.clinicalDepartment,
    }))
  const dataReport = [...sortedWorkSchedules, ...dataTransportation]
  const totalDays = calcWorkScheduleTotalDays(sortedWorkSchedules)
  const totalTransportationExpense = calcTotalTransportationExpense(
    dataTransportation,
    sortedWorkSchedules
  )
  const getTransportationExpenseDuplicate = () => {
    const recurrentsDuplicate = []
    if (dataReport && dataReport.length > 0) {
      dataReport.map((as, index) => {
        if (!as.deletedAt) {
          if (!as.notApplyCostTransportation) {
            dataReport.map((val, index2) => {
              if (index !== index2 && as.category !== val.category) {
                if (!val.deletedAt) {
                  if (!val.notApplyCostTransportation) {
                    const startTimeAs = as.startTime ? as.startTime : as.endTime
                    const checkTimeAs = moment(startTimeAs).format('YYYY-MM-DD')
                    const startTimeVal = val.startTime
                      ? val.startTime
                      : val.endTime
                    const checkTimeVal = moment(startTimeVal).format(
                      'YYYY-MM-DD'
                    )
                    if (checkTimeVal === checkTimeAs) {
                      recurrentsDuplicate.push(index)
                    }
                  }
                }
              }
            })
          }
        }
      })
    }
    return recurrentsDuplicate
  }

  const getNumberIncentive = () => {
    let count = 0
    if (
      data &&
      data.doctor &&
      data.doctor.doctorMonthlyTransportationExpenses
    ) {
      data.doctor.doctorMonthlyTransportationExpenses.map(as => {
        if (!as.deletedAt) {
          count++
        }
      })
    }
    return count
  }
  dataReport.sort((a, b) => new Date(a.endTime) - new Date(b.endTime))
  const listDuplicates = getTransportationExpenseDuplicate()
  return (
    <MovingExpensesDetailScene
      i18n={i18n}
      date={date}
      onSubmit={onSubmit}
      listDuplicates={listDuplicates}
      movingExpenses={dataReport}
      doctorId={doctorId}
      fullName={fullName}
      totalDays={totalDays}
      totalCost={totalTransportationExpense}
      numberIncentive={getNumberIncentive()}
      isBlockedMyCar={isBlockedMyCar}
    />
  )
}

export default MovingExpensesDetail
