import './style.css'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, NotFound, ProgressSpinner } from '@medical/components'
import {
  STAFF_DELETE_DOCTOR_IDENTITY_PERMISSION,
  STAFF_PROCESS_DOCTOR_IDENTITY,
  STAFF_UPDATE_DOCTOR_IDENTITY_NUMBER,
} from '@medical/constant/permissions'
import {
  checkStaffPermission,
  findNameOrEmail,
  onPageChange,
} from '@medical/libs'
import Auth from '@medical/middleware/auth'
import { useCustom } from '@medical/provider/context'
import queryString from 'query-string'
import React, { useContext, useEffect } from 'react'

import { CREATE_ACTIVITY, DOCTORS_CONNECTION } from './MyNumberManage.graphql'
import MyNumberManageScene from './MyNumberManageScene'
import { SocketContext } from '@medical/provider/socket'
import {
  removeProgress,
  setProgressBar,
} from '@medical/provider/store/reducers/progress/progress.action'
import { connect } from 'react-redux'

const MyNumberManage = ({
  history,
  location: { search },
  progress,
  setProgress,
  removeProgress,
}) => {
  const {
    page = 1,
    rowsPerPage = 20,
    orderBy = 'createdAt_DESC',
    fullname,
    email,
    confirmationStatus_in = [],
  } = queryString.parse(search)
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  let variables = {
    first: first < 0 ? 20 : first,
    skip: skip < 0 ? 0 : skip,
    orderBy,
    where: {
      AND: findNameOrEmail({ fullname, email }),
    },
  }

  const [{ i18n, popup }] = useCustom()
  const socket = useContext(SocketContext)
  const [staffCreateActivity] = useMutation(CREATE_ACTIVITY)
  const createActivityOpenPage = () => {
    staffCreateActivity({
      variables: {
        activity: 'STAFF_OPEN_IDENTITY_NUMBER_SCREEN',
      },
    })
  }
  useEffect(() => {
    createActivityOpenPage()
  }, [])
  const isProcessPermitted = checkStaffPermission({
    functionName: STAFF_PROCESS_DOCTOR_IDENTITY,
  })

  const isUpdateDoctorIdentity = checkStaffPermission({
    functionName: STAFF_UPDATE_DOCTOR_IDENTITY_NUMBER,
  })
  const isDeletePermitted = checkStaffPermission({
    functionName: STAFF_DELETE_DOCTOR_IDENTITY_PERMISSION,
  })

  if (confirmationStatus_in === 'UNSUBMIT') {
    variables = {
      first: first < 0 ? 10 : first,
      skip: skip < 0 ? 0 : skip,
      orderBy,
      where: {
        DoctorIdentity: {
          confirmationStatus: 'UNSUBMIT',
        },
        AND: findNameOrEmail({ fullname, email }),
      },
    }
  }
  if (confirmationStatus_in === 'UNCONFIRM') {
    variables = {
      first: first < 0 ? 10 : first,
      skip: skip < 0 ? 0 : skip,
      orderBy,
      where: {
        DoctorIdentity: {
          OR: [
            { confirmationStatus: 'UNCONFIRM' },
            { confirmationStatus: 'RESUBMITTED' },
          ],
        },
        AND: findNameOrEmail({ fullname, email }),
      },
    }
  }
  if (confirmationStatus_in === 'RESUBMIT_REQUEST') {
    variables = {
      first: first < 0 ? 10 : first,
      skip: skip < 0 ? 0 : skip,
      orderBy,
      where: {
        DoctorIdentity: {
          confirmationStatus: 'RESUBMIT_REQUEST',
        },
        AND: findNameOrEmail({ fullname, email }),
      },
    }
  }
  if (confirmationStatus_in === 'CONFIRMED') {
    variables = {
      first: first < 0 ? 10 : first,
      skip: skip < 0 ? 0 : skip,
      orderBy,
      where: {
        DoctorIdentity: {
          confirmationStatus: 'CONFIRMED',
        },
        AND: findNameOrEmail({ fullname, email }),
      },
    }
  }
  if (confirmationStatus_in.length === 0)
    variables = {
      first: first < 0 ? 10 : first,
      skip: skip < 0 ? 0 : skip,
      orderBy,
      where: {
        AND: findNameOrEmail({ fullname, email }),
      },
    }
  const { loading, error, data, refetch } = useQuery(DOCTORS_CONNECTION, {
    variables,
    fetchPolicy: 'network-only',
  })
  useEffect(() => {
    refetch()
  })
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  const {
    doctorsConnection: {
      edges,
      aggregate: { count },
    },
  } = data

  const renderMyNumber = number => {
    return number
      .toString()
      .replace(/(\d\d\d\d)(\d\d\d\d)(\d\d\d\d)/, '$1 $2 $3')
  }

  const base64Decode = input => {
    return Buffer.from(input, 'base64').toString('ascii')
  }

  return (
    <MyNumberManageScene
      i18n={i18n}
      socket={socket}
      progress={progress}
      setProgress={progress => {
        setProgress(progress)
      }}
      removeProgress={progress => removeProgress(progress)}
      edges={edges}
      count={count}
      page={parseInt(page, 10)}
      rowsPerPage={parseInt(rowsPerPage, 10)}
      confirmationStatus_in={confirmationStatus_in}
      fullname={fullname}
      email={email}
      onPageChange={onPageChange(history, search)}
      staffCreateActivity={staffCreateActivity}
      isProcessPermitted={isProcessPermitted || isDeletePermitted}
      renderMyNumber={renderMyNumber}
      base64Decode={base64Decode}
      isUpdateDoctorIdentity={isUpdateDoctorIdentity}
    />
  )
}

const mapStateToProps = state => ({
  progress: state.progressBarStore.progress,
})

const mapDispatchToProps = dispatch => ({
  setProgress: progress => dispatch(setProgressBar(progress)),
  removeProgress: progress => dispatch(removeProgress(progress)),
})
export default connect(mapStateToProps, mapDispatchToProps)(MyNumberManage)
