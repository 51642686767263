import { DOCTOR_PROFILE, FINISH_LOADING, START_LOADING } from './doctor.action'

const doctorReducer = (
  state = {
    loading: false,
    doctor: {},
  },
  action
) => {
  const { type, payload } = action

  switch (type) {
    case START_LOADING:
      return {
        ...state,
        loading: true,
      }
    case FINISH_LOADING:
      return {
        ...state,
        loading: false,
      }
    case DOCTOR_PROFILE:
      return {
        ...state,
        doctor: {
          ...state.doctor,
          ...payload,
        },
      }

    default:
      return state
  }
}

export default doctorReducer
