import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import {
  HOURLY_WAGE,
  PERMANENT_DOCTOR,
  REGULAR_SHIFT_DOCTOR,
} from '@medical/constant/index'
import {
  SHOW_MONEY_FIELDS,
  STAFF_ADD_COMMENT,
  STAFF_UPDATE_CONFIRMED_SHIFTS_STATUS,
} from '@medical/constant/permissions'
import {
  checkStaffPermission,
  clinicalDepartmentNames,
  combineNames,
  convertDoctors,
  convertDoctorsConnection,
  sortAndFilterByClinicOrder,
  splitLogic,
} from '@medical/libs'
import { ShiftForm } from '@medical/pages/staff/components'
import {
  DEFAULT_HOURLY_WAGES,
  DOCTOR_CONNECTION,
  DOCTORS_DEPARTMENTS,
} from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import { CLINICS_DAILY_REPORT } from '@medical/pages/staff/DailyAvailableShift/DailyAvailableShift.graphql'
import { CLINICS_MONTHLY_REPORT } from '@medical/pages/staff/MonthlyAvailableShift/MonthlyAvailableShift.graphql'
import { useCustom } from '@medical/provider/context'
import moment from 'moment'
import React from 'react'

import { STAFF_CREATE_WORK_SCHEDULE } from './CreateWorkSchedule.graphql'

const CreateWorkSchedule = ({
  location: { states },
  match: {
    params: { year, month, day },
  },
  closeModal,
}) => {
  const [{ i18n, popup }] = useCustom()
  const date = moment()
    .startOf('day')
    .year(year)
    .month(month - 1)
    .date(day || 1)
    .hour(9)
  const isMoneyPermitted = checkStaffPermission({
    functionName: SHOW_MONEY_FIELDS,
  })
  const isAddCommentPermitted = checkStaffPermission({
    functionName: STAFF_ADD_COMMENT,
  })
  const isChangeConfirmedShiftsStatusPermitted = checkStaffPermission({
    functionName: STAFF_UPDATE_CONFIRMED_SHIFTS_STATUS,
  })
  const {
    loading: dataDoctorConnectionLoading,
    error: dataDoctorConnectionError,
    data: dataDoctorConnection,
  } = useQuery(DOCTOR_CONNECTION, {
    variables: {
      where: {
        registrationStatus_in: ['CREATED_BY_STAFF', 'ACCEPTED'],
        // blocked: false,
      },
      first: 10,
      skip: 0,
      orderBy: 'createdAt_ASC',
    },
  })
  const { loading, error, data } = useQuery(DOCTORS_DEPARTMENTS)
  const {
    loading: hourlyWageLoading,
    error: hourlyWageError,
    data: hourlyWageData,
  } = useQuery(DEFAULT_HOURLY_WAGES, {
    variables: {
      where: {
        workPattern_in: [REGULAR_SHIFT_DOCTOR, PERMANENT_DOCTOR],
      },
    },
  })
  const [createAcceptedWorkSchedule] = useMutation(STAFF_CREATE_WORK_SCHEDULE, {
    refetchQueries: day
      ? [
          {
            query: CLINICS_DAILY_REPORT,
            variables: { date: moment(date).startOf('day') },
          },
        ]
      : [
          {
            query: CLINICS_MONTHLY_REPORT,
            variables: {
              date: moment(date).startOf('day'),
            },
          },
        ],
    awaitRefetchQueries: true,
  })

  if (loading || hourlyWageLoading || dataDoctorConnectionLoading)
    return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  if (hourlyWageError) return <ErrorComponent error={hourlyWageError} />
  if (dataDoctorConnectionError)
    return <ErrorComponent error={dataDoctorConnectionError} />

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    const {
      clinicalDepartment: { id: clinicalDepartmentId },
      date: submitDate,
      startTime,
      splits,
      comment,
      doctorSubsidy,
      doctor: { id: doctorId },
      isPosted,
      isDoubleRecruitment,
    } = values
    const newDoctorSubsidy = []
    doctorSubsidy.map(item => {
      newDoctorSubsidy.push({
        money: item.money,
        title: item.title,
      })
    })
    const splitComments = []
    values.splitComments.map(value => {
      if (value.text !== '' && value.type !== '') {
        splitComments.push({
          text: value.text,
          type: value.type,
          staff: {},
        })
      }

      return splitComments
    })
    const {
      endTime,
      hourlyWage,
      splitDateTime1,
      splitDateTime2,
      splitDateTime3,
      splitHourlyWage1,
      splitHourlyWage2,
      splitHourlyWage3,
      isStartTimeBreakTime,
      isSplitDateTime1BreakTime,
      isSplitDateTime2BreakTime,
      isSplitDateTime3BreakTime,
    } = splitLogic({ submitDate, splits })
    try {
      await createAcceptedWorkSchedule({
        variables: {
          clinicalDepartmentId,
          doctorId,
          startTime: moment(submitDate)
            .hour(moment(startTime).hour())
            .minute(moment(startTime).minute()),
          endTime,
          hourlyWage,
          splitDateTime1,
          splitDateTime2,
          splitDateTime3,
          splitHourlyWage1,
          splitHourlyWage2,
          splitHourlyWage3,
          isStartTimeBreakTime,
          isSplitDateTime1BreakTime,
          isSplitDateTime2BreakTime,
          isSplitDateTime3BreakTime,
          comment,
          staffComments: splitComments,
          doctorSubsidy: newDoctorSubsidy,
          isPublished: isPosted,
          isDoubleRecruitment,
        },
      })
      popup.success(i18n.t('staff.createShift.submissionSuccess'))
      setSubmitting(false)
      resetForm()
      closeModal()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }

  const { doctors, clinicalDepartments, departments } = data
  const sortedClinicalDepartments = sortAndFilterByClinicOrder({
    clinicalDepartments,
    isFilter: true,
  })
  const { defaultHourlyWages } = hourlyWageData
  const departmentNames = clinicalDepartmentNames({ departments })
  const addDefaultHourlyWages = listDoctor => {
    if (!listDoctor) return []
    return listDoctor.map(doctor => {
      defaultHourlyWages.forEach(hourlyWage => {
        if (doctor.node.workPattern === hourlyWage.workPattern) {
          doctor.node[HOURLY_WAGE] = hourlyWage.hourlyWage
        }
      })

      return doctor
    })
  }
  const { edges } = dataDoctorConnection.doctorsConnection
  return (
    <ShiftForm
      availableShift={states}
      i18n={i18n}
      title={i18n.t('main.createWorkScheduleForDoctor')}
      submitLabel={i18n.t('main.createWorkSchedule')}
      confirmationMessage={i18n.t('staff.workSchedule.createConfirmation')}
      onSubmit={onSubmit}
      // doctors={convertDoctors(addDefaultHourlyWages(doctors))}
      doctors={convertDoctorsConnection(addDefaultHourlyWages(edges))}
      clinicalDepartments={combineNames({
        clinicalDepartments: sortedClinicalDepartments,
        departmentNames,
      })}
      date={states?.date || date}
      workSchedule
      isCreateWorkSchedule
      isMoneyPermitted={isMoneyPermitted}
      isAddCommentPermitted={isAddCommentPermitted}
      defaultHourlyWages={defaultHourlyWages}
      popup={popup}
      isChangeConfirmedShiftsStatusPermitted={
        isChangeConfirmedShiftsStatusPermitted
      }
    />
  )
}

export default CreateWorkSchedule
