import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { UPDATE_MOVING_EXPENSES_REGISTRATION } from '@medical/constant/permissions'
import { checkStaffPermission, onPageChange } from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import queryString from 'query-string'
import React, { useEffect } from 'react'

import { GET_LIST_MOVING_EXPENSES_GQL } from './ListMovingExpenses.graphql'
import ListMovingExpensesScene from './ListMovingExpensesScene'

const ListMovingExpenses = ({ history, location: { search } }) => {
  const { page = 1, rowsPerPage = 20, fullname, email } = queryString.parse(
    search
  )
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  const [{ i18n }] = useCustom()
  const { loading, error, data, refetch } = useQuery(
    GET_LIST_MOVING_EXPENSES_GQL,
    {
      variables: {
        first: first < 0 ? 20 : first,
        skip: skip < 0 ? 0 : skip,
        where: {
          AND: [
            {
              OR: [
                {
                  unSigned_contains: fullname
                    ? fullname
                        .replace(/ /g, '')
                        .replace(/　/g, '')
                        .toLowerCase()
                    : '',
                },
              ],
            },
            {
              email_contains: email,
            },
          ],
          TransportationExpense_some: {
            id_not: undefined,
          },
        },
      },
      fetchPolicy: 'network-only',
    }
  )
  useEffect(() => {
    refetch()
  })
  const isUpdateMovingExpenses = checkStaffPermission({
    functionName: UPDATE_MOVING_EXPENSES_REGISTRATION,
  })
  if (loading) return <ProgressSpinner fullHeight />
  if (error) return <ErrorComponent error={error} />
  return (
    <ListMovingExpensesScene
      isUpdateMovingExpenses={isUpdateMovingExpenses}
      data={data}
      fullname={fullname}
      email={email}
      rowsPerPage={parseInt(rowsPerPage, 10)}
      listMovingExpenses={data.doctorsConnection.edges}
      onPageChange={onPageChange(history, search)}
      page={parseInt(page, 10)}
      count={data.doctorsConnection.aggregate.count}
      i18n={i18n}
    />
  )
}

export default ListMovingExpenses
