import AutoComplete from '@medical/components/AutoComplete'
import BreadCrumb from '@medical/components/Breadcrumb'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import CustomDropdown from '@medical/components/DropdownForSignup'
import Editor from '@medical/components/Editor'
import Router from '@medical/routes/router'
import { Formik } from 'formik'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Switch } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import Dropzone from 'react-dropzone'
import { useMutation } from '@apollo/react-hooks'
import { DOWNLOAD_FILE } from '../../Doctors/DoctorDetail/DoctorDetail.graphql'
import { MAX_FILE_SIZE } from '@medical/constant'
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from 'react-image-crop'
import { imageCrop } from '@medical/libs/imageCrop'
import { Modal } from 'antd'
import moment from 'moment'
import { useCustom } from '@medical/provider/context'
import { GET_POST_PRE_SIGNED_URL } from '@medical/components/Forms/UploadImage/UploadImage.graphql'
import uploadImage from '@medical/libs/uploadImage'

const RecruitmentPostForm = ({
  announce: {
    title = '',
    content = '',
    isPublished = true,
    recruitmentDoctorType = '',
    clinicalDepartment = '',
    imageKey = '',
  },
  onSubmit,
  isUpdateOrDelete,
  isCreatePermitted,
  isUpdatePermitted,
  isDeletePermitted,
  clinicalDepartments,
}) => {
  const [crop, setCrop] = useState()
  const [img, setImg] = useState(null)
  const [imgSrc, setImgSrc] = useState('')
  const [completedCrop, setCompletedCrop] = useState()
  const [cropImgVisible, setCropImgVisible] = useState(false)
  const [getPostPreSignedUrl] = useMutation(GET_POST_PRE_SIGNED_URL)
  const imgRef = useRef()
  const canvasRef = useRef()
  const [downloadFile] = useMutation(DOWNLOAD_FILE)
  const saveTitle = title
  const saveContent = content
  const [{ i18n, popup }] = useCustom()
  const workPatterns = [
    { value: 'PERMANENT_DOCTOR', description: '常勤' },
    { value: 'REGULAR_SHIFT_DOCTOR', description: '定期非常勤' },
  ]
  let disableInput = false
  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .trim()
      .min(2, i18n.t('validation.title.min', { min: 2 }))
      .max(100, i18n.t('validation.title.max', { max: 100 }))
      .required(i18n.t('validation.title.required')),
    content: Yup.string()
      .trim()
      .max(100000, i18n.t('validation.content.max', { max: 100000 }))
      .nullable()
      .required('内容を入力してください')
      .test('only-text', '内容を入力してください', value => {
        const div = document.createElement('div')
        div.innerHTML = value
        return div.textContent.trim()
      }),
    recruitmentDoctorType: Yup.string().required('応募区分は必要です。'),
    clinicalDepartment: Yup.object()
      .nullable()
      .required(i18n.t('validation.clinicalDepartment.required')),
  })
  const downloadFileSubmit = async (key, callback) => {
    if (!key || key.length === 0) return
    try {
      const {
        data: { downloadFileS3: fileURL },
      } = await downloadFile({
        variables: {
          key,
        },
      })
      callback(fileURL)
    } catch (error) {
      callback(null)
    }
  }
  useEffect(() => {
    downloadFileSubmit(imageKey, fileURL => setImg(fileURL))
  }, [imageKey])

  const centerAspectCrop = (mediaWidth, mediaHeight) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        2 / 1,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    )
  }

  const onImageLoad = event => {
    const { width, height } = event.currentTarget
    setCrop(centerAspectCrop(width, height))
    setCompletedCrop(
      convertToPixelCrop(centerAspectCrop(width, height), width, height)
    )
  }

  const onSelectFile = event => {
    if (event.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        setImgSrc(reader.result.toString() || undefined)
        setCropImgVisible(true)
      })
      reader.readAsDataURL(event[0])
    }
  }

  return (
    <React.Fragment>
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('staff.menuBar.recruitmentPost')}
          <BreadCrumb
            items={[
              {
                title: i18n.t('staff.menuBar.recruitmentPost'),
                to: Router.staffRecruitmentPosts,
              },
              { title: '募集要項掲載作成' },
            ]}
          />
        </div>
      </div>
      <div className='container'>
        <div className='box'>
          <Formik
            enableReinitialize
            initialValues={{
              file: null,
              imageKey: imageKey,
              img: img,
              confirm: false,
              title,
              content,
              isPublished,
              recruitmentDoctorType,
              clinicalDepartment: clinicalDepartment
                ? clinicalDepartments.find(
                    ({ id }) => id === clinicalDepartment.id
                  )
                : {
                    clinicName: 'なし',
                    id: '',
                  },
              confirmationMessage: '',
              functionName: '',
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            render={formikProps => {
              const {
                values,
                touched,
                errors,
                setTouched,
                validateForm,
                handleChange,
                setFieldValue,
              } = formikProps
              return (
                <div>
                  <div className='tr'>
                    <div className='th' style={{ width: '20%' }}>
                      {i18n.t('staff.announces.titleLabel')}
                    </div>
                    <div className='td'>
                      <InputText
                        value={values.title}
                        name='title'
                        onChange={handleChange}
                        style={{ width: '100%' }}
                      />
                      <div className='alert'>
                        {!!touched.title && errors.title}
                      </div>
                    </div>
                  </div>
                  <div className='tr'>
                    <div className='th' style={{ width: '20%' }}>
                      {i18n.t('staff.announces.contentLabel')}
                    </div>
                    <div className='td'>
                      <Editor
                        value={values.content}
                        onTextChange={e =>
                          setFieldValue('content', e.htmlValue)
                        }
                      />
                      <div className='alert'>
                        {!!touched.content && errors.content}
                      </div>
                    </div>
                  </div>
                  <div className='tr'>
                    <div className='th' style={{ width: '20%' }}>
                      {i18n.t('staff.createShift.clinic')}
                    </div>
                    <div className='td'>
                      <AutoComplete
                        {...formikProps}
                        name='clinicalDepartment'
                        field='clinicName'
                        allSuggestions={clinicalDepartments}
                        suggestionName='clinicalDepartments'
                        width='60vw'
                        maxWidth='386px'
                        placeholder={
                          clinicalDepartments.length > 0
                            ? 'クリニックを選択'
                            : '対象データなし'
                        }
                      />
                      <div className='alert'>
                        {touched.clinicalDepartment &&
                          errors.clinicalDepartment}
                      </div>
                    </div>
                  </div>
                  <div className='tr'>
                    <div className='th' style={{ width: '20%' }}>
                      掲載ステータス
                    </div>
                    <div className='td toggle-published'>
                      <Switch
                        checkedChildren='掲載中'
                        unCheckedChildren='未掲載'
                        name='isPublished'
                        defaultChecked={values.isPublished}
                        onChange={e => {
                          setFieldValue('isPublished', e)
                        }}
                      />
                    </div>
                  </div>
                  <div className='tr'>
                    <div className='th' style={{ width: '20%' }}>
                      応募区分
                    </div>
                    <div className='td'>
                      <CustomDropdown
                        {...formikProps}
                        name='recruitmentDoctorType'
                        options={workPatterns}
                      />
                    </div>
                  </div>
                  <div className='tr'>
                    <div className='th' style={{ width: '20%' }}>
                      募集要項用画像
                    </div>
                    <div className='td'>
                      <Dropzone
                        disabled={disableInput}
                        onDrop={onSelectFile}
                        accept={['image/jpeg', 'image/png']}
                        maxSize={MAX_FILE_SIZE}
                        multiple={false}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            style={{ position: 'relative' }}
                            {...getRootProps({
                              className: values.file
                                ? 'dropzone'
                                : 'dropzone dropzone-small',
                            })}
                          >
                            <input {...getInputProps()} />
                            {canvasRef && imgSrc && (
                              <>
                                <canvas
                                  ref={canvasRef}
                                  id='myCanvas'
                                  style={{
                                    maxHeight: 'calc(30vh - 20px)',
                                    display: cropImgVisible ? 'none' : 'unset',
                                  }}
                                />
                                {(values.file || values.img) && (
                                  <button
                                    type='button'
                                    className='close-img-btn'
                                    style={{
                                      position: 'absolute ',
                                      top: '20px',
                                      right: '20px',
                                      zIndex: 3,
                                    }}
                                    onClick={event => {
                                      event.preventDefault()
                                      event.stopPropagation()
                                      setFieldValue('file', null)
                                      setFieldValue('imageKey', '')
                                      setFieldValue('img', '')
                                      setImgSrc()
                                      canvasRef.current
                                        .getContext('2d')
                                        .clearRect(
                                          0,
                                          0,
                                          canvasRef.current.width,
                                          canvasRef.current.height
                                        )
                                      canvasRef.current.width = 0
                                      canvasRef.current.height = 0
                                    }}
                                  >
                                    X
                                  </button>
                                )}
                              </>
                            )}
                            {(values.file || values.img) && (
                              <button
                                type='button'
                                className='close-img-btn'
                                style={{
                                  position: 'absolute ',
                                  top: '20px',
                                  right: '20px',
                                }}
                                onClick={event => {
                                  event.preventDefault()
                                  event.stopPropagation()
                                  setFieldValue('file', null)
                                  setFieldValue('imageKey', '')
                                  setFieldValue('img', '')
                                }}
                              >
                                X
                              </button>
                            )}
                            {!values.file &&
                              (values.img ? (
                                <img
                                  alt={i18n.t('doctor.uploadFile.dropFileHere')}
                                  src={values.img}
                                  style={{
                                    width: '500px',
                                    minWidth: '500px',
                                  }}
                                />
                              ) : (
                                <i
                                  className='pi pi-file'
                                  style={{ fontSize: '30px' }}
                                />
                              ))}
                          </div>
                        )}
                      </Dropzone>
                      {imgSrc && (
                        <Modal
                          title='画像の表示範囲を選択する'
                          centered
                          visible={cropImgVisible}
                          footer={null}
                          onCancel={() => {
                            setImgSrc()
                            setFieldValue('file', null)
                            setCropImgVisible(false)
                          }}
                        >
                          <>
                            <ReactCrop
                              aspect={2 / 1}
                              crop={crop}
                              onChange={(_, percentCrop) =>
                                setCrop(percentCrop)
                              }
                              onComplete={c => setCompletedCrop(c)}
                            >
                              <img
                                ref={imgRef}
                                alt='プレビュー画像'
                                src={imgSrc}
                                onLoad={onImageLoad}
                              />
                            </ReactCrop>
                            <div
                              style={{
                                margin: '1rem 0',
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                onClick={() => {
                                  if (imgSrc) {
                                    const ctx = canvasRef.current.getContext(
                                      '2d'
                                    )
                                    if (ctx) {
                                      const file = imageCrop({
                                        ctx,
                                        imgRef,
                                        canvasRef,
                                        completedCrop,
                                        scale: 1,
                                      })
                                      file.current.toBlob(async blob => {
                                        const newFile = new File(
                                          [blob],
                                          `upload_img_${moment().valueOf()}`,
                                          {
                                            type: 'image/jpeg',
                                          }
                                        )
                                        const {
                                          data: postPreSignedUrl,
                                        } = await getPostPreSignedUrl({
                                          variables: {
                                            fileName: newFile.name,
                                            mimetype: newFile.type,
                                          },
                                        })
                                        const key = await uploadImage(
                                          i18n,
                                          popup,
                                          postPreSignedUrl,
                                          newFile
                                        )
                                        setFieldValue('file', key)
                                      }, 'image/jpeg')
                                    }
                                  }
                                  setCropImgVisible(false)
                                }}
                                label='この画像を登録する'
                                className='reflect-button'
                                style={{ margin: '0 1rem 0' }}
                              />
                              <Button
                                onClick={() => {
                                  setImgSrc()
                                  setFieldValue('file', null)
                                  setCropImgVisible(false)
                                }}
                                label={i18n.t('doctor.identity.cancelButton')}
                              />
                            </div>
                          </>
                        </Modal>
                      )}
                      <div className='alert'>{touched.file && errors.file}</div>
                    </div>
                  </div>
                  <div className='modal-bottom'>
                    {isUpdateOrDelete && (
                      <>
                        {isDeletePermitted && (
                          <Button
                            label='募集要項を削除'
                            className='cancel-button'
                            onClick={() => {
                              setFieldValue('functionName', 'delete')
                              setFieldValue(
                                'confirmationMessage',
                                '募集要項を削除しますか？'
                              )
                              setFieldValue('confirm', true)
                              setFieldValue('title', saveTitle)
                              setFieldValue('content', saveContent)
                            }}
                          />
                        )}
                        {isUpdatePermitted && (
                          <Button
                            label='募集要項を更新'
                            className='reflect-button'
                            onClick={() => {
                              validateForm().then(response => {
                                if (!Object.keys(response).length) {
                                  setFieldValue(
                                    'confirmationMessage',
                                    '募集要項を更新しますか？'
                                  )
                                  setFieldValue('functionName', 'update')
                                  setFieldValue('confirm', true)
                                } else {
                                  setTouched(response)
                                }
                              })
                            }}
                          />
                        )}
                      </>
                    )}
                    {!isUpdateOrDelete && isCreatePermitted && (
                      <Button
                        label='募集要項を作成'
                        className='reflect-button'
                        onClick={() => {
                          validateForm().then(response => {
                            if (!Object.keys(response).length) {
                              setFieldValue(
                                'confirmationMessage',
                                '募集要項を作成しますか？'
                              )
                              setFieldValue('confirm', true)
                            } else {
                              setTouched(response)
                            }
                          })
                        }}
                      />
                    )}
                  </div>

                  <ConfirmationModal
                    {...formikProps}
                    confirmationMessage={values.confirmationMessage}
                  />
                </div>
              )
            }}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default RecruitmentPostForm
