import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { HOLIDAY_URL, objectTargetConstant } from '@medical/constant/index'
import {
  SHOW_MONEY_FIELDS,
  STAFF_ADD_COMMENT,
  STAFF_UPDATE_RECRUITMENT_SHIFTS_STATUS,
  STAFF_UPDATE_SCHEDULE_AVAILABLE_SHIFTS,
} from '@medical/constant/permissions'
import { useAxios } from '@medical/hooks'
import {
  checkStaffPermission,
  clinicalDepartmentNames,
  combineNames,
  sortAndFilterByClinicOrder,
  splitLogic,
  thisRangeHolidays,
} from '@medical/libs'
import { DOCTORS_DEPARTMENTS } from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import { convertDayInMonthToMatrix } from '@medical/pages/staff/RecruitmentScheduleShift/CreateRecruitmentScheduleShift/function'
import { STAFF_CREATE_SHIFT_SCHEDULE } from '@medical/pages/staff/RecruitmentScheduleShift/CreateRecruitmentScheduleShift/RecruitmentScheduleShift.grapql'
import RecruitmentScheduleShiftForm from '@medical/pages/staff/RecruitmentScheduleShift/CreateRecruitmentScheduleShift/RecruitmentScheduleShiftForm'
import { useCustom } from '@medical/provider/context'
import moment from 'moment'
import { pathOr } from 'ramda'
import React from 'react'
import { TYPE_CREATE } from '@medical/constant'

import { GET_ENUM_COMMENT } from '../../DetailWorkSchedule/WorkScheduleComment.graphql'

const CreateRecruitmentScheduleShift = ({
  match: {
    params: { year, month, day },
  },
  location: { stateUnpublished, state },
  closeModal,
}) => {
  const [{ i18n, popup }] = useCustom()
  const date =
    year && month
      ? moment()
          .startOf('day')
          .year(year)
          .month(month - 1)
          .date(day || 1)
          .hour(9)
      : moment()
          .startOf('day')
          .hour(9)
  const isMoneyPermitted = checkStaffPermission({
    functionName: SHOW_MONEY_FIELDS,
  })
  const isAddCommentPermitted = checkStaffPermission({
    functionName: STAFF_ADD_COMMENT,
  })
  const { loading, error, data } = useQuery(DOCTORS_DEPARTMENTS)
  const isChangeRecruitmentShiftsStatusPermitted = checkStaffPermission({
    functionName: STAFF_UPDATE_RECRUITMENT_SHIFTS_STATUS,
  })
  const isUpdateScheduleAvailableShift = checkStaffPermission({
    functionName: STAFF_UPDATE_SCHEDULE_AVAILABLE_SHIFTS,
  })
  const {
    loading: holidaysLoading,
    data: holidaysData,
    error: holidaysError,
  } = useAxios({
    url: HOLIDAY_URL,
    params: {
      timeMin: moment(date)
        .startOf('month')
        .toISOString(),
      timeMax: moment(date)
        .add(1, 'year')
        .endOf('month')
        .toISOString(),
    },
  })
  const {
    loading: loadingEnumComment,
    error: errorComment,
    data: dataEnumComment,
  } = useQuery(GET_ENUM_COMMENT, {
    variables: {
      name: 'commentTypes',
    },
  })

  const [createScheduleShift] = useMutation(STAFF_CREATE_SHIFT_SCHEDULE)
  if (loadingEnumComment) return <ProgressSpinner />
  if (holidaysLoading || loading) return <ProgressSpinner />
  if (error || holidaysError || errorComment)
    return <ErrorComponent error={error} />
  const onSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    const {
      clinicalDepartment: { id: clinicalDepartmentId },
      date: submitDate,
      startTime,
      splits,
      name,
      isMonday,
      isTuesday,
      isWednesday,
      isThursday,
      isFriday,
      isSaturday,
      isSunday,
      isDayoff,
      startEndDate,
      isPublished,
      repeatSetting,
      listWeekDay,
      listWeekDay1,
      listWeekDay2,
      listWeekDay3,
      listWeekDay4,
      comment,
      numberOfRooms,
      isSetIndividually,
      objectTarget,
      workPattern,
      specificDoctors,
      isHolidayRepeat,
      isEarlySpecial,
      isDoubleRecruitment,
    } = values
    const splitComments = []
    values.splitComments.map(value => {
      if (value.text !== '' && value.type !== '') {
        splitComments.push({
          text: value.text,
          type: value.type,
          staff: {},
        })
      }

      return splitComments
    })
    const splitRecruitmentCategories = []
    values.splitRecruitmentCategories.map(value => {
      if (value.category !== '' && value.status !== '') {
        splitRecruitmentCategories.push({
          category: value.category,
          status: value.status,
          description: value.description || '',
          staff: {},
        })
      }

      return splitRecruitmentCategories
    })
    // const {
    //   endTime,
    //   splitDateTime1,
    //   splitDateTime2,
    //   splitDateTime3,
    //   hourlyWage,
    //   splitHourlyWage1,
    //   splitHourlyWage2,
    //   splitHourlyWage3,
    //   isStartTimeBreakTime,
    //   isSplitDateTime1BreakTime,
    //   isSplitDateTime2BreakTime,
    //   isSplitDateTime3BreakTime,
    //   hourlyWageSat,
    //   splitHourlyWage1Sat,
    //   splitHourlyWage2Sat,
    //   splitHourlyWage3Sat,
    //   isStartTimeBreakTimeSat,
    //   isSplitDateTime1BreakTimeSat,
    //   isSplitDateTime2BreakTimeSat,
    //   isSplitDateTime3BreakTimeSat,
    //   hourlyWageHoliday,
    //   splitHourlyWage1Holiday,
    //   splitHourlyWage2Holiday,
    //   splitHourlyWage3Holiday,
    //   isStartTimeBreakTimeHoliday,
    //   isSplitDateTime1BreakTimeHoliday,
    //   isSplitDateTime2BreakTimeHoliday,
    //   isSplitDateTime3BreakTimeHoliday,
    // } = splitLogic({ submitDate, splits })
    let dataTime
    Object.keys(splits).forEach(key => {
      dataTime = {
        ...dataTime,
        ...splitLogic({
          submitDate,
          splits: splits[key],
          key,
          clinicalDepartmentId,
          date: moment(),
        }),
      }
    })
    const holidays = thisRangeHolidays({
      holidaysData: pathOr([], ['items'], holidaysData),
      startDate: startEndDate[0],
      endDate: startEndDate[1],
    })
    const isHolidays = []
    holidays.forEach(item => {
      isHolidays.push(item.date)
    })
    const listBooleanDayInMonth = convertDayInMonthToMatrix(
      listWeekDay,
      listWeekDay1,
      listWeekDay2,
      listWeekDay3,
      listWeekDay4
    )
    // let rangeStartDate = startEndDate[0]
    // let rangeEndDate = startEndDate[1]
    // const startTimeDefault = moment(startTime)
    //   .set('hour', 9)
    //   .set('minute', 0)
    // const endTimeDefault = moment(endTime)
    //   .set('hour', 9)
    //   .set('minute', 0)
    // if (moment(startTime).isBefore(startTimeDefault)) {
    //   rangeStartDate = moment(startEndDate[0]).subtract(1, 'day')
    // }
    // if (moment(endTime).isBefore(endTimeDefault)) {
    //   rangeEndDate = moment(startEndDate[1]).subtract(1, 'day')
    // }
    const startDateFormat = `${
      moment(startEndDate[0])
        .toISOString()
        .split('T')[0]
        .split('+')[0]
    }T00:00:00`
    const endDateFormat = `${
      moment(startEndDate[1])
        .toISOString()
        .split('T')[0]
        .split('+')[0]
    }T23:59:59`

    try {
      await createScheduleShift({
        variables: {
          clinicalDepartmentId,
          dataTime,
          numberOfRooms: parseInt(numberOfRooms),
          isHolidays,
          isPublished,
          repeatSetting,
          comment,
          staffComments: splitComments,
          recruitmentCategory: splitRecruitmentCategories,
          dateMatrix: JSON.stringify(listBooleanDayInMonth),
          data: {
            name,
            startDate: startDateFormat ? startDateFormat + 'Z' : undefined,
            endDate: endDateFormat ? endDateFormat + 'Z' : undefined,
            isAvailableOnMonday:
              repeatSetting === TYPE_CREATE.DAILY ? true : isMonday,
            isAvailableOnTuesday:
              repeatSetting === TYPE_CREATE.DAILY ? true : isTuesday,
            isAvailableOnWednesday:
              repeatSetting === TYPE_CREATE.DAILY ? true : isWednesday,
            isAvailableOnThursday:
              repeatSetting === TYPE_CREATE.DAILY ? true : isThursday,
            isAvailableOnFriday:
              repeatSetting === TYPE_CREATE.DAILY ? true : isFriday,
            isAvailableOnSaturday:
              repeatSetting === TYPE_CREATE.DAILY ? true : isSaturday,
            isAvailableOnSunday:
              repeatSetting === TYPE_CREATE.DAILY ? true : isSunday,
            isAvailableOnHoliday:
              repeatSetting === TYPE_CREATE.DAILY ? true : isDayoff,
          },
          isSetIndividually,
          applyWith:
            isSetIndividually && objectTarget ? objectTarget : undefined,
          workPatternApply:
            isSetIndividually &&
            objectTarget === objectTargetConstant.SET_FOR_EACH_WORKPARTTEN
              ? workPattern
              : undefined,
          listDoctorId:
            isSetIndividually &&
            objectTarget === objectTargetConstant.SET_FOR_EACH_DOCTOR
              ? specificDoctors.map(e => e.id)
              : undefined,
          isHolidayRepeat,
          isEarlySpecial,
          isDoubleRecruitment,
        },
      })
      popup.success(i18n.t('staff.scheduledShiftGroup.submissionSuccess'))
      setSubmitting(false)
      resetForm()
      closeModal()
    } catch (error) {
      popup.error(error)
      setFieldValue('confirm', false)
      setSubmitting(false)
    }
  }

  const { clinicalDepartments, departments } = data
  const sortedClinicalDepartments = sortAndFilterByClinicOrder({
    clinicalDepartments,
    isFilter: true,
  })
  const departmentNames = clinicalDepartmentNames({ departments })
  return (
    <RecruitmentScheduleShiftForm
      i18n={i18n}
      title={i18n.t('staff.scheduledShiftGroup.titleCreateScheduleShift')}
      submitLabel={i18n.t('staff.scheduledShiftGroup.createSubmit')}
      confirmationMessage={i18n.t('staff.scheduledShiftGroup.messageCreate')}
      onSubmit={onSubmit}
      clinicalDepartments={combineNames({
        clinicalDepartments: sortedClinicalDepartments,
        departmentNames,
      })}
      date={
        stateUnpublished && stateUnpublished.startTime
          ? moment(stateUnpublished.startTime)
          : date
      }
      isMoneyPermitted={isMoneyPermitted}
      isCreateAvailableShift
      isChangeRecruitmentShiftsStatusPermitted={
        isChangeRecruitmentShiftsStatusPermitted
      }
      stateUnpublished={stateUnpublished || {}}
      isUpdateScheduleAvailableShift={isUpdateScheduleAvailableShift}
      popup={popup}
      isAddCommentPermitted={isAddCommentPermitted}
      holidaysData={holidaysData}
      dataEnumComment={dataEnumComment}
      detailValue={state}
      isHideDoubleRecruitmentConfirm
    />
  )
}

export default CreateRecruitmentScheduleShift
