import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import StaffGroupForm from '@medical/pages/staff/StaffGroups/StaffGroupForm/StaffGroupForm'
import { useCustom } from '@medical/provider/context'
import React from 'react'

import {
  STAFF_GROUP_QUERY,
  UPDATE_STAFF_GROUP,
} from './StaffGroupDetail.graphql'

const StaffGroupDetail = ({
  match: {
    params: { staffGroupId },
  },
}) => {
  const [{ i18n, popup }] = useCustom()
  const [updateStaffGroup] = useMutation(UPDATE_STAFF_GROUP, {
    refetchQueries: [
      {
        query: STAFF_GROUP_QUERY,
        variables: {
          staffGroupId,
        },
      },
    ],
    awaitRefetchQueries: true,
  })
  const { loading, error, data } = useQuery(STAFF_GROUP_QUERY, {
    variables: {
      staffGroupId,
    },
  })

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const onSubmit = async (
    { groupName, permissions },
    { setSubmitting, resetForm }
  ) => {
    try {
      await updateStaffGroup({
        variables: {
          staffGroupId,
          name: groupName,
          permissions: permissions.map(({ id: permissionId, action }) => ({
            permissionId,
            action,
          })),
        },
      })
      popup.success(i18n.t('staff.staffGroup.updateSuccess'))
      setSubmitting(false)
      resetForm()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }
  const { staffGroup, permittedActions, apiGroupTypes } = data

  return (
    <StaffGroupForm
      i18n={i18n}
      staffGroup={staffGroup}
      onSubmit={onSubmit}
      permittedActions={permittedActions}
      submitLabel={i18n.t('staff.staffGroup.update')}
      confirmationMessage={i18n.t('staff.staffGroup.updateConfirmation')}
      apiGroupTypes={apiGroupTypes}
    />
  )
}

export default StaffGroupDetail
