import BreadCrumb from '@medical/components/Breadcrumb'
import ModalLink from '@medical/components/ModalLink'
import Router from '@medical/routes/router'
import { Button } from 'primereact/button'
import queryString from 'query-string'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const Header = ({
  items,
  isCreateShiftPermitted,
  isUploadPermitted,
  isCreateSchedulePermitted,
}) => {
  const { i18n } = useTranslation()

  return (
    <div className='staff-header mb-0'>
      <div className='staff-title'>
        {i18n.t('staff.availableShift.title')}
        <BreadCrumb items={items} />
      </div>
      <div className='staff-buttons'>
        {isCreateShiftPermitted ? (
          <ModalLink to={Router.staffCreateAvailableShift} >
            <Button
              icon='pi pi-plus'
              className='p-button-normal-empty'
              label={i18n.t('main.createAvailableShift')}
            />
          </ModalLink>
        ) : null}
        {isCreateSchedulePermitted ? (
          <ModalLink to={Router.staffCreateWorkSchedule}>
            <Button
              icon='pi pi-plus'
              className='p-button-normal'
              label={i18n.t('main.createWorkSchedule')}
            />
          </ModalLink>
        ) : null}
        {isUploadPermitted ? (
          <React.Fragment>
            <Link
              to={{
                pathname: Router.staffWorkScheduleCsv,
                search: queryString.stringify({
                  isAvailableShift: true,
                }),
              }}
            >
              <Button
                icon='pi pi-cloud-upload'
                className='p-button-normal-empty'
                label={i18n.t('staff.menuBar.availableShiftCSV')}
              />
            </Link>
            <Link
              to={{
                pathname: Router.staffWorkScheduleCsv,
              }}
            >
              <Button
                icon='pi pi-cloud-upload'
                className='p-button-normal'
                label={i18n.t('staff.menuBar.workSchedule')}
              />
            </Link>
          </React.Fragment>
        ) : null}
      </div>
    </div>
  )
}

export default Header
