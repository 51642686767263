import { useMutation } from '@apollo/react-hooks'
import UploadFileComponent from '@medical/components/Forms/UploadFile'
import { useCustom } from '@medical/provider/context'
import React from 'react'

import { DOCTOR_UPLOAD_FILE } from './UploadFile.graphql'

const UploadFile = ({
  match: {
    params: { fileCategory, fileId },
  },
  closeModal,
}) => {
  const [{ i18n, popup }] = useCustom()
  const [uploadFileMutation] = useMutation(DOCTOR_UPLOAD_FILE)
  const uploadFile = async ({ file, filename, mimetype }, { setSubmitting, setFieldValue }) => {
    try {
      await uploadFileMutation({
        variables: {
          file,
          fileCategory,
          fileId,
          filename,
          mimetype,
        },
      })

      popup.success(i18n.t('doctor.uploadFile.submissionSuccess'))
      setSubmitting(false)
      closeModal()
    } catch (error) {
      popup.error(error)
      setFieldValue('confirm', false)
      setSubmitting(false)
      closeModal()
    }
  }

  return (
    <UploadFileComponent
      submitLabel={i18n.t('doctor.uploadFile.submit')}
      uploadFile={uploadFile}
      confirmationMessage={i18n.t('doctor.uploadFile.submitConfirmation')}
    />
  )
}

export default React.memo(UploadFile)
