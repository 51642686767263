import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import {
  clinicalDepartmentNames,
  combineNames,
  sortAndFilterByClinicOrder,
} from '@medical/libs'
import AnnounceForm from '@medical/pages/staff/Announces/components/AnnounceForm'
import { DOCTORS_DEPARTMENTS } from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import { useCustom } from '@medical/provider/context'
import React from 'react'

import { SINGLE_ANNOUNCE, UPDATE_ANNOUNCE } from './AnnounceUpdate.graphql'

const AnnounceUpdate = ({
  match: {
    params: { announceId },
  },
}) => {
  const [{ i18n, popup }] = useCustom()
  const [updateAnnounce] = useMutation(UPDATE_ANNOUNCE)
  const { loading, error, data } = useQuery(SINGLE_ANNOUNCE, {
    variables: {
      announceId,
    },
  })

  const {
    loading: clinicalDepartmentLoading,
    error: clinicalDepartmentErr,
    data: clinicalDepartmentData,
  } = useQuery(DOCTORS_DEPARTMENTS)

  if (clinicalDepartmentLoading || loading) return <ProgressSpinner />
  if (clinicalDepartmentErr || error)
    return <ErrorComponent error={clinicalDepartmentErr || error} />

  const { clinicalDepartments, departments } = clinicalDepartmentData
  const departmentNames = clinicalDepartmentNames({ departments })
  const sortedClinicalDepartments = sortAndFilterByClinicOrder({
    clinicalDepartments,
    isFilter: true,
  })

  const onSubmit = async (
    {
      title,
      content,
      isPublished,
      isSentEmailToAllDoctors,
      file,
      isAddAvailableShift,
      availableShiftsIds,
      isAddRecruitmentPost,
      recruitmentPostIds,
      isNotSendDoctorWorkScheduleBlock,
      isNotSendDoctorBlock,
    },
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      await updateAnnounce({
        variables: {
          announceId,
          title,
          content,
          isPublished,
          isSentEmailToAllDoctors,
          image: file,
          isAddAvailableShift,
          availableShifts: availableShiftsIds,
          isAddRecruitmentPost,
          recruitmentPosts: recruitmentPostIds,
          isNotSendDoctorWorkScheduleBlock,
          isNotSendDoctorBlock,
        },
        refetchQueries: [
          {
            query: SINGLE_ANNOUNCE,
            variables: {
              announceId,
            },
          },
        ],
      })

      popup.success(i18n.t('staff.updateAnnounce.submissionSuccess'))
      setSubmitting(false)
      // resetForm()
      setFieldValue('confirm', false)
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
      setFieldValue('confirm', false)
    }
  }

  const { announce } = data

  announce.availableShiftList = announce.availableShiftList.map(a => ({
    ...a,
    clinicName: `${a.clinicalDepartment.clinic.name}_${
      departmentNames[a.clinicalDepartment.name]
    }`,
  }))
  announce.recruitmentPostList = announce.recruitmentPostList.map(p => ({
    ...p,
    clinicName: `${p.clinicalDepartment.clinic.name}_${
      departmentNames[p.clinicalDepartment.name]
    }`,
  }))
  return (
    <AnnounceForm
      announce={announce || {}}
      onSubmit={onSubmit}
      submitLabel={i18n.t('staff.updateAnnounce.submit')}
      confirmationMessage={i18n.t('staff.updateAnnounce.submitConfirmation')}
      isUpdate={true}
      clinicalDepartments={combineNames({
        clinicalDepartments: sortedClinicalDepartments,
        departmentNames,
      })}
      departmentNames={departmentNames}
    />
  )
}

export default AnnounceUpdate
