import gql from 'graphql-tag'

export const DOCUMENT_FRAGMENT = gql`
  fragment DOCUMENT_FRAGMENT on Document {
    id
    createdAt
    title
    content
  }
`

export const DOCUMENTS_CONNECTION = gql`
  query DOCUMENTS_CONNECTION(
    $where: DocumentWhereInput
    $skip: Int
    $first: Int
    $orderBy: DocumentOrderByInput
  ) {
    documentsConnection(
      where: $where
      skip: $skip
      first: $first
      orderBy: $orderBy
    ) {
      edges {
        node {
          ...DOCUMENT_FRAGMENT
        }
      }
      aggregate(where: $where) {
        count
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
`

export const DELETE_DOCUMENT = gql`
  mutation DELETE_DOCUMENT($documentId: ID!) {
    staffDeleteDocument(documentId: $documentId) {
      ...DOCUMENT_FRAGMENT
    }
  }
  ${DOCUMENT_FRAGMENT}
`
