import { DOCTOR_FRAGMENTS } from '@medical/layout/DoctorLayout/DoctorLayoutRequest.graphql'
import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import gql from 'graphql-tag'

export const DOCTORS_CONNECTION = gql`
  query DOCTORS_CONNECTION(
    $where: DoctorWhereInput
    $first: Int
    $skip: Int
    $orderBy: DoctorOrderByInput
  ) {
    doctorsConnection(
      where: $where
      first: $first
      skip: $skip
      orderBy: $orderBy
    ) {
      edges {
        node {
          ...DOCTOR_FRAGMENTS
          deletedBy {
            id
          }
        }
      }
      aggregate(where: $where) {
        count
      }
    }
    staffMe {
      id
    }
    ...DEPARTMENTS_FRAGMENT
  }
  ${DOCTOR_FRAGMENTS}
  ${DEPARTMENTS_FRAGMENT}
`

export const DELETE_DOCTOR = gql`
  mutation DELETE_DOCTOR($doctorId: ID!) {
    staffDeleteDoctor(doctorId: $doctorId)
  }
`

export const STAFF_HARD_DELETE_DOCTOR = gql`
  mutation DELETE_DOCTOR($doctorId: ID!) {
    staffHardDeleteDoctor(doctorId: $doctorId)
  }
`
