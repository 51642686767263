import Moment from 'moment'
import { extendMoment } from 'moment-range'

import numeralCurrency from './numeralCurrency'

const moment = extendMoment(Moment)

const calculateSalary = ({
  availableShift,
  startTime,
  endTime,
  getNumber,
  hourlyWageRange,
  doctorSubsidy,
  values,
  dayOfWeek,
  i18n,
  date,
}) => {
  const formatSubmitTime = submitTime =>
    `${moment(date).format('YYYY-MM-DD')}T${
      moment(submitTime)
        .format()
        .split('T')[1]
        .split('+')[0]
    }`
  startTime = formatSubmitTime(startTime)
  endTime = formatSubmitTime(endTime)

  const splits = []
  const interval = moment.range(moment(startTime), moment(endTime))
  let rangeStartTime = startTime
  const rangeEndTime = endTime
  const {
    hourlyWage,
    splitDateTime1,
    splitDateTime2,
    splitDateTime3,
    splitHourlyWage1,
    splitHourlyWage2,
    splitHourlyWage3,
  } = availableShift
  if (splitDateTime1 && moment(splitDateTime1).within(interval)) {
    splits.push({
      range: moment.range(moment(rangeStartTime), moment(splitDateTime1)),
      hourlyWage:
        splitHourlyWage1 +
          (values && values[1] && !values[1].isBreakTime
            ? hourlyWageRange
            : 0) || 0,
    })
    rangeStartTime = splitDateTime1
  }

  if (splitDateTime2 && moment(splitDateTime2).within(interval)) {
    splits.push({
      range: moment.range(moment(rangeStartTime), moment(splitDateTime2)),
      hourlyWage:
        splitHourlyWage2 +
          (values && values[2] && !values[2].isBreakTime
            ? hourlyWageRange
            : 0) || 0,
    })
    rangeStartTime = splitDateTime2
  }

  if (splitDateTime3 && moment(splitDateTime3).within(interval)) {
    splits.push({
      range: moment.range(moment(rangeStartTime), moment(splitDateTime3)),
      hourlyWage:
        splitHourlyWage3 +
          (values && values[3] && !values[3].isBreakTime
            ? hourlyWageRange
            : 0) || 0,
    })
    rangeStartTime = splitDateTime3
  }

  splits.push({
    range: moment.range(moment(rangeStartTime), moment(rangeEndTime)),
    hourlyWage:
      hourlyWage +
        (values && values[0] && !values[0].isBreakTime ? hourlyWageRange : 0) ||
      0,
  })
  if (getNumber) {
    const result = splits.reduce(
      (acc, curr) => acc + (curr.range.diff('minutes') / 60) * curr.hourlyWage,
      0
    )
    return Math.round(result)
  }
  if (dayOfWeek) {
    const text = i18n.t(`staff.createShift.${dayOfWeek}`)
    const result =
      text +
      ' ' +
      numeralCurrency(
        splits.reduce(
          (acc, curr) =>
            acc + (curr.range.diff('minutes') / 60) * curr.hourlyWage,
          0
        ) + doctorSubsidy
      )
    return result + '  '
  } else {
    return numeralCurrency(
      splits.reduce(
        (acc, curr) =>
          acc + (curr.range.diff('minutes') / 60) * curr.hourlyWage,
        0
      ) + doctorSubsidy
    )
  }
}

export default calculateSalary
