import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import {
  STAFF_PAGE_DOCTOR_DELETE_REQUEST,
  STAFF_CONFIRM_DOCTOR_DELETE_REQUEST_PERMISSION,
} from '@medical/constant/permissions'
import {
  checkStaffPermission,
  clinicalDepartmentNames,
  findNameOrEmail,
  onPageChange,
} from '@medical/libs'
import { CREATE_ACTIVITY } from '@medical/pages/doctor/AvailableShift/AvailableShift.graphql'
import { useCustom } from '@medical/provider/context'
import queryString from 'query-string'
import React, { useEffect } from 'react'

import {
  DOCTORS_CONNECTION,
  STAFF_HARD_DELETE_DOCTOR,
} from './DoctorsDeleteRequestList.graphql'
import { STAFF_CREATE_DOCTOR_DELETE_REQUEST } from '../DoctorDetail/DoctorDetail.graphql'
import DoctorsDeleteRequestListScene from './DoctorsDeleteRequestListScene'

const restore = 'restore'
const confirmDelete = 'confirmDelete'

const DoctorsDeleteRequestList = ({ history, location: { search } }) => {
  const {
    page = 1,
    rowsPerPage = 20,
    orderBy = 'createdAt_DESC',
    fullname,
    email,
  } = queryString.parse(search)
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  const variables = {
    first: first < 0 ? 20 : first,
    skip: skip < 0 ? 0 : skip,
    orderBy,
    where: {
      AND: findNameOrEmail({ fullname, email }),
      deletedBy_not: null,
    },
  }
  const [{ i18n, popup }] = useCustom()
  const isConfirmDeleteRequestPermitted = checkStaffPermission({
    functionName: STAFF_CONFIRM_DOCTOR_DELETE_REQUEST_PERMISSION,
  })
  const isRestorePermitted = checkStaffPermission({
    functionName: STAFF_PAGE_DOCTOR_DELETE_REQUEST,
  })
  const [staffCreateActivity] = useMutation(CREATE_ACTIVITY)

  const mutationOptions = {
    refetchQueries: [
      {
        query: DOCTORS_CONNECTION,
        variables,
      },
    ],
    awaitRefetchQueries: true,
  }
  const [unDeleteDoctor] = useMutation(
    STAFF_CREATE_DOCTOR_DELETE_REQUEST,
    mutationOptions
  )
  const [confirmDeleteDoctor] = useMutation(
    STAFF_HARD_DELETE_DOCTOR,
    mutationOptions
  )
  const { loading, error, data, refetch } = useQuery(DOCTORS_CONNECTION, {
    variables,
    fetchPolicy: 'network-only',
  })
  useEffect(() => {
    refetch()
  })

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  const departmentNames = clinicalDepartmentNames({
    departments: data.departments,
  })
  const staffId = data.staffMe.id

  const onSubmit = async (
    { doctorId, functionName, doctorName },
    { setSubmitting, resetForm }
  ) => {
    try {
      if (functionName === confirmDelete) {
        await confirmDeleteDoctor({
          variables: {
            doctorId,
          },
        })
        await staffCreateActivity({
          variables: {
            activity: 'STAFF_HARD_DELETE_DOCTOR',
            details: doctorName,
          },
        })
        setSubmitting(false)
        resetForm()
        popup.success('削除しました。')
      } else {
        await unDeleteDoctor({
          variables: {
            doctorId,
          },
        })
        await staffCreateActivity({
          variables: {
            activity: 'STAFF_CANCEL_REQUEST_DELETE_DOCTOR',
            doctorId,
          },
        })
        setSubmitting(false)
        resetForm()
        popup.success('削除申請の取り消しをしました。')
      }
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }

  const {
    doctorsConnection: {
      edges,
      aggregate: { count },
    },
  } = data
  return (
    <DoctorsDeleteRequestListScene
      i18n={i18n}
      onSubmit={onSubmit}
      edges={edges}
      count={count}
      variables={variables}
      page={parseInt(page, 10)}
      rowsPerPage={parseInt(rowsPerPage, 10)}
      fullname={fullname}
      email={email}
      onPageChange={onPageChange(history, search)}
      confirmDelete={confirmDelete}
      restore={restore}
      isConfirmDeleteRequestPermitted={isConfirmDeleteRequestPermitted}
      isRestorePermitted={isRestorePermitted}
      departmentNames={departmentNames}
      staffId={staffId}
    />
  )
}

export default DoctorsDeleteRequestList
