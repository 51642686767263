import './style.css'

import React from 'react'

const SignupProgress = ({ step }) => (
  <div>
    <div className='tabs'>
      <div className={`tab-item ${step >= 0 ? 'active' : ''}`}>1</div>
      <div className={`line ${step >= 1 ? 'line-active' : ''}`} />
      <div className={`tab-item ${step >= 1 ? 'active' : ''}`}>2</div>
    </div>
  </div>
)

export default SignupProgress
