import { Button } from 'primereact/button'
import React, { useState } from 'react'

import BreadCrumb from '@medical/components/Breadcrumb'
import styles from './Style'
import { FieldArray, Form, Formik } from 'formik'
import moment from 'moment'
import * as yub from 'yup'
import { useCustom } from '@medical/provider/context'
import { useEffect } from 'react'
import { ProgressSpinner } from '@medical/components'
import TableDataBody from './components/TableDataBody'
import { MultiSelect } from 'primereact/multiselect'

const lastDayOfMonth = i => {
  return moment()
    .add(i + 1, 'month')
    .endOf('month')
    .date()
}

const EarlySpecialHourlyWageSettingShiftScene = ({
  data,
  monthAhead,
  setMonthAhead,
  currentMonth,
  handleUpdatetHourlyWage,
  handleDownload,
  loading,
  isPermittedEdit,
  departmentNames,
  uniqueClinicName,
  clinicalDepartmentNames,
  setClinicIds,
  clinicIds,
  setClinicalDepartmentNames,
  listRegions,
  regionIds,
  setRegionIds,
}) => {
  const [{ popup, i18n }] = useCustom()
  const [isError, setIsError] = useState()

  const validationSchema = yub.object().shape({
    data: yub.array().of(
      yub
        .object()
        .shape({
          weekdayMorning: yub.number().nullable(),
          weekdayAfternoon: yub.number().nullable(),
          weekdayNight: yub.number().nullable(),
          saturdayMorning: yub.number().nullable(),
          saturdayAfternoon: yub.number().nullable(),
          saturdayNight: yub.number().nullable(),
          holidayMorning: yub.number().nullable(),
          holidayAfternoon: yub.number().nullable(),
          holidayNight: yub.number().nullable(),
        })
        .test(
          'checkFields',
          'All fields must have a value if one field has a value',
          function(value) {
            const fields = [
              'weekdayMorning',
              'weekdayAfternoon',
              'weekdayNight',
              'saturdayMorning',
              'saturdayAfternoon',
              'saturdayNight',
              'holidayMorning',
              'holidayAfternoon',
              'holidayNight',
            ]
            const hasValue = fields.some(field => value[field])

            if (hasValue || !!value['isValid'] || !!value['isEarlySpecial']) {
              const fieldNoValue = fields.find(field => !value[field])

              if (fieldNoValue) {
                setIsError(true)
              }
              return !fieldNoValue
            }

            return true
          }
        )
    ),
  })

  function handleNextMonthAhead(setFieldValue, setSubmitting) {
    setSubmitting(true)
    setFieldValue('data', [])
    setMonthAhead(prev => prev + 1)
  }
  function handleBackMonthAhead(setFieldValue, setSubmitting) {
    setSubmitting(true)
    setFieldValue('data', [])
    setMonthAhead(prev => prev - 1)
  }

  useEffect(() => {
    if (isError) {
      popup.error('値を入力してください')
    }
  }, [isError])

  return (
    <Formik
      onSubmit={(values, { setSubmitting, resetForm }) => {
        handleUpdatetHourlyWage(values.data, { setSubmitting, resetForm })
      }}
      validateOnChange={false}
      initialValues={{ data }}
      validationSchema={validationSchema}
      enableReinitialize
      render={({
        values,
        setFieldValue,
        dirty,
        errors,
        isSubmitting,
        setSubmitting,
      }) => {
        return (
          <>
            <div className='staff-title'>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <span>早特時給</span>
                {handleDownload && isPermittedEdit && (
                  <Button
                    icon='pi pi-download'
                    className='p-button-normal-empty'
                    label='早特時給CSVダウンロード'
                    onClick={handleDownload}
                  />
                )}
              </div>

              <BreadCrumb
                items={[
                  {
                    title: '早特時給',
                  },
                ]}
              />
            </div>
            <Form>
              <div>
                <div
                  className='box pt-3'
                  style={{
                    position: 'relative',
                  }}
                >
                  <div
                    style={{
                      // position: 'absolute',
                      right: 0,
                      top: 0,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'start',
                    }}
                  >
                    <div
                      style={{
                        color: '#FF0000',
                        fontSize: '12px',
                      }}
                    >
                      ＊有効になっていないものは実行されません
                      <br />
                      ＊募集シフトの時給が０円の場合は上書きされません
                      <br />
                      ＊特別時給は上書きされません
                      <br />
                      ＊早特表示へチェックした場合、時給が上書きされたシフトは早特表示になります（チェックしない場合、早特表示は無くなります）
                      <br />
                      ＊値を入力した行は全ての時間帯に値を入力してください
                      <br />
                      ＊募集シフトの開始時間で上書きされる時給が決まります。午前（07:00-12:59）、午後（13:00-17:59）、夜間（18:00-23:59）
                    </div>
                  </div>
                  <div
                    style={{
                      margin: '30px 0',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      zIndex: 100,
                    }}
                  >
                    <div style={{ flex: 1, textAlign: 'right' }}>
                      <Button
                        icon='pi pi-chevron-left'
                        disabled={monthAhead <= 1}
                        onClick={e => {
                          e.preventDefault()
                          handleBackMonthAhead(setFieldValue, setSubmitting)
                        }}
                      />
                    </div>
                    <div style={styles.title}>{`${monthAhead}ヶ月先`}</div>
                    <div style={{ flex: 1, textAlign: 'left' }}>
                      <Button
                        icon='pi pi-chevron-right'
                        disabled={monthAhead >= 3}
                        onClick={e => {
                          e.preventDefault()
                          handleNextMonthAhead(setFieldValue, setSubmitting)
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      marginBottom: '10px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div
                      className='filter'
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <MultiSelect
                        className='mx-2 dropdown-clinic'
                        options={listRegions}
                        optionLabel='region'
                        value={listRegions.filter(({ id }) =>
                          regionIds.includes(id)
                        )}
                        onChange={e => {
                          setSubmitting(true)
                          setRegionIds(e)
                        }}
                        placeholder={i18n.t(
                          'staff.waitingRecruitment.areaSelect'
                        )}
                        fixedPlaceholder
                        filter
                      />
                      <MultiSelect
                        className='mx-2 dropdown-clinic'
                        options={uniqueClinicName}
                        optionLabel='clinicName'
                        value={uniqueClinicName.filter(({ id }) =>
                          clinicIds.includes(id)
                        )}
                        onChange={e => {
                          setSubmitting(true)
                          setClinicIds(e)
                        }}
                        placeholder={i18n.t(
                          'staff.waitingRecruitment.clinicSelect'
                        )}
                        fixedPlaceholder
                        filter
                      />
                      <MultiSelect
                        className='mx-2 dropdown-clinic'
                        options={departmentNames}
                        optionLabel='departmentName'
                        value={departmentNames.filter(({ name }) =>
                          clinicalDepartmentNames.includes(name)
                        )}
                        onChange={e => {
                          setSubmitting(true)
                          setClinicalDepartmentNames(e)
                        }}
                        placeholder={i18n.t(
                          'staff.waitingRecruitment.departmentSelect'
                        )}
                        fixedPlaceholder
                        filter
                      />
                      <Button
                        onClick={e => {
                          e.preventDefault()
                          setSubmitting(true)
                          setClinicIds({ value: [] })
                          setClinicalDepartmentNames({ value: [] })
                        }}
                        disabled={
                          clinicIds.length === 0 &&
                          clinicalDepartmentNames.length === 0
                        }
                        icon='pi pi-times'
                        className='mx-2'
                      />
                    </div>
                    {
                      <div style={{ fontWeight: 'bolder' }}>
                        次回実行日時：{currentMonth + 1}/{1}AM2時頃 <br />
                        {currentMonth + monthAhead + 1}/{1}~
                        {currentMonth + monthAhead + 1}/
                        {lastDayOfMonth(monthAhead + 1)}
                        の募集シフトの時給が上書きされます。
                      </div>
                    }
                  </div>
                  <div
                    style={{
                      maxHeight: 'calc(100vh - 275px)',
                      overflow: 'auto',
                    }}
                  >
                    <table
                      className='table'
                      style={{
                        position: 'relative',
                        maxHeight: '150px',
                        textAlign: 'center',
                        width: '100%',
                        textAlign: 'center',
                        overflow: 'auto',
                      }}
                    >
                      <thead
                        style={{
                          position: 'sticky',
                          top: 0,
                          zIndex: 999,
                        }}
                      >
                        <tr>
                          <th
                            className='border-top_none'
                            style={{ width: '4%' }}
                          >
                            有効
                          </th>
                          <th
                            className='border-top_none padding-0'
                            style={{ width: '4%' }}
                          >
                            <div>
                              早特
                              <br />
                              表示
                            </div>
                          </th>
                          <th
                            className='border-top_none'
                            style={{ width: '12%' }}
                          >
                            エリア
                          </th>
                          <th
                            className='border-top_none'
                            style={{ width: '12%' }}
                          >
                            クリニック名
                          </th>
                          <th
                            className='border-top_none'
                            style={{ width: '12%' }}
                          >
                            診療科
                          </th>
                          <th
                            className='border-top_none'
                            style={{ width: '6%' }}
                          >
                            平日午前
                          </th>
                          <th
                            className='border-top_none'
                            style={{ width: '6%' }}
                          >
                            平日午後
                          </th>
                          <th
                            className='border-top_none'
                            style={{ width: '6%' }}
                          >
                            平日夜間
                          </th>
                          <th
                            className='border-top_none'
                            style={{ width: '6%' }}
                          >
                            土曜午前
                          </th>
                          <th
                            className='border-top_none'
                            style={{ width: '6%' }}
                          >
                            土曜午後
                          </th>
                          <th
                            className='border-top_none'
                            style={{ width: '6%' }}
                          >
                            土曜夜間
                          </th>
                          <th
                            className='border-top_none'
                            style={{ width: '6%' }}
                          >
                            日祝午前
                          </th>
                          <th
                            className='border-top_none'
                            style={{ width: '6%' }}
                          >
                            日祝午後
                          </th>
                          <th
                            className='border-top_none'
                            style={{ width: '6%' }}
                          >
                            日祝夜間
                          </th>
                        </tr>
                      </thead>
                      {loading ? (
                        <div
                          style={{
                            position: 'sticky',
                            left: '50%',
                            bototm: 0,
                            color: 'black',
                            transform: 'translate(-50%, 0)',
                            height: '100px',
                          }}
                        >
                          <ProgressSpinner />
                        </div>
                      ) : (
                        <FieldArray
                          name='data'
                          render={() => {
                            return (
                              <TableDataBody
                                setFieldValue={setFieldValue}
                                values={values}
                                errors={errors}
                                isPermittedEdit={isPermittedEdit}
                              />
                            )
                          }}
                        />
                      )}
                    </table>
                  </div>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'end',
                      marginTop: '12px',
                    }}
                  >
                    {isPermittedEdit && (
                      <Button
                        className='reflect-button'
                        disabled={!dirty || isSubmitting || loading}
                        type='submit'
                        label='確定する'
                        onClick={() => {
                          if (isError) {
                            setIsError(false)
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Form>
          </>
        )
      }}
    />
  )
}

export default React.memo(EarlySpecialHourlyWageSettingShiftScene)
