import { STAFF_FRAGMENTS } from '@medical/layout/StaffLayout/StaffLayout.graphql'
import { STAFF_GROUPS_QUERY } from '@medical/pages/staff/StaffGroups/StaffGroupsList/StaffGroupsList.graphql'
import gql from 'graphql-tag'

export const STAFF_QUERY = gql`
  query STAFF_QUERY($staffId: ID) {
    staff(where: { id: $staffId }) {
      ...STAFF_FRAGMENTS
    }
    ...STAFF_GROUPS_QUERY
  }
  ${STAFF_FRAGMENTS}
  ${STAFF_GROUPS_QUERY}
`
export const STAFF_RESEND_CONFIRMATION_EMAIL = gql`
  mutation STAFF_RESEND_CONFIRMATION_EMAIL($email: String!) {
    resendConfirmationEmailToStaff(email: $email) {
      id
    }
  }
`
