import { Chips as PrimeChips } from 'primereact/chips'
import React, { useRef } from 'react'

const Chips = ({ label, name, setFieldValue, values, disabled }) => {
  const ref = useRef(null)

  return (
    <div className='tr'>
      <div className='th'>{label}</div>
      <div className='td'>
        {disabled ? (
          values[name].map((value, kk) => (
            <div key={kk} className='slashed-item'>
              {value}
            </div>
          ))
        ) : (
          <PrimeChips
            ref={ref}
            value={values[name] || []}
            onChange={e => {
              setFieldValue(name, [...new Set(e.value)])
            }}
            onBlur={() => {
              if (ref.current.inputElement.value) {
                setFieldValue(name, [
                  ...new Set([...values[name], ref.current.inputElement.value]),
                ])
                ref.current.inputElement.value = ''
              }
            }}
            disabled={disabled}
          />
        )}
      </div>
    </div>
  )
}

export default Chips
