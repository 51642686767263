import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import React from 'react'

import { DOCTOR_ANNOUNCES } from '../../News/News.graphql'
import NewSliderScene from './NewSliderScene'

const News = ({ i18n }) => {
  const variables = {
    where: {
      isPublished: true,
    },
    first: 3,
    skip: 0,
    orderBy: 'createdAt_DESC',
  }
  const { loading, error, data } = useQuery(DOCTOR_ANNOUNCES, {
    variables,
  })
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const {
    announcesConnection: {
      edges,
      aggregate: { count },
    },
  } = data

  const settings = {
    swipeToSlide: true,
    slidesToShow: 2,
    centerMode: true,
    infinite: true,

    // className: "center",
    dots: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: false,

          variableWidth: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          centerMode: false,
          variableWidth: false,
        },
      },
    ],
  }

  return <NewSliderScene i18n={i18n} edges={edges} settings={settings} />
}

export default News
