import { Checkbox } from 'primereact/checkbox'
// import { pathOr } from 'ramda'
import React from 'react'

const CustomCheckBox = ({
  name,
  disabled,
  values,
  setFieldValue,
  options = [],
  optionLabel = 'description',
  valueLabel = 'value',
}) => (
  <div>
    <div className='row'>
      {options.map(option => (
        <div
          className='checkbox-container checkbox-field'
          key={option[valueLabel]}
        >
          <Checkbox
            value={option[valueLabel]}
            disabled={disabled}
            onChange={e => {
              const selectedOptions =
                name === 'isIntroduce' ||
                name === 'isJoinedSublingualImmunotherapyELearning' ||
                name === 'isJoinedOnlineDiagnosticTraining'
                  ? [values[name]]
                  : [...values[name]]

              if (values[name].includes(e.value)) {
                selectedOptions.splice(selectedOptions.indexOf(e.value), 1)
              } else {
                if (
                  name === 'isIntroduce' ||
                  name === 'isJoinedSublingualImmunotherapyELearning' ||
                  name === 'isJoinedOnlineDiagnosticTraining'
                ) {
                  selectedOptions.length = 0
                }
                selectedOptions.push(e.value)
              }
              setFieldValue(name, selectedOptions)
            }}
            checked={values[name].includes(option[valueLabel])}

          />
          <div className='checkbox-label'>{option[optionLabel]}</div>
        </div>
      ))}
    </div>
  </div>
)

export default CustomCheckBox
