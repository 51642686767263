import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { STAFF_ME } from '@medical/layout/StaffLayout/StaffLayout.graphql'
import StaffForm from '@medical/pages/staff/Staffs/components/StaffForm'
import { useCustom } from '@medical/provider/context'
import React from 'react'

import { STAFF_UPDATE_PROFILE } from './StaffUpdateProfile.graphql'

const StaffUpdateProfile = ({
  closeModal,
  parentPath,
  location: { search },
}) => {
  const [{ i18n, popup }] = useCustom()
  const [updateProfile] = useMutation(STAFF_UPDATE_PROFILE)
  const { loading, error, data } = useQuery(STAFF_ME)

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const onSubmit = async (
    { firstname, lastname, firstnameKana, lastnameKana },
    { setSubmitting, resetForm }
  ) => {
    try {
      await updateProfile({
        variables: {
          firstname,
          lastname,
          firstnameKana,
          lastnameKana,
        },
      })
      popup.success(i18n.t('updateProfile.submissionSuccess'))
      setSubmitting(false)
      resetForm()
      closeModal()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }

  return (
    <StaffForm
      i18n={i18n}
      staff={data.staffMe || {}}
      staffGroups={data.staffGroups}
      onSubmit={onSubmit}
      submitLabel={i18n.t('updateProfile.submit')}
      confirmationMessage={i18n.t('updateProfile.submitConfirmation')}
      search={search}
      parentPath={parentPath}
      updateProfile
    />
  )
}

export default StaffUpdateProfile
