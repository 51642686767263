import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { UPDATE_MOVING_EXPENSES_REGISTRATION } from '@medical/constant/permissions'
import { checkStaffPermission, onPageChange } from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import { SocketContext } from '@medical/provider/socket'
import {
  removeProgress,
  setProgressBar,
} from '@medical/provider/store/reducers/progress/progress.action'
import queryString from 'query-string'
import React, { useContext, useEffect } from 'react'
import { connect } from 'react-redux'

import { GET_LIST_MOVING_EXPENSES_OTHER_GQL } from './ListMovingExpensesOther.graphql'
import ListMovingExpensesOtherScene from './ListMovingExpensesOtherScene'

const ListMovingExpensesOther = ({
  history,
  location: { search },
  progress,
  setProgress,
  removeProgress,
}) => {
  const [{ i18n }] = useCustom()
  const socket = useContext(SocketContext)
  const {
    page = 1,
    rowsPerPage = 20,
    fullname,
    status,
    startAt,
    endAt,
    startTimeGte,
    startTimeLte,
  } = queryString.parse(search)
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)

  const arrStatus = ['WAITING_FOR_APPROVAL', 'REJECTED', 'ACCEPTED']

  const { loading, error, data, refetch } = useQuery(
    GET_LIST_MOVING_EXPENSES_OTHER_GQL,
    {
      variables: {
        skip,
        first,
        orderBy: null,
        where: {
          category: 'RECURRING_TRAVEL_FEE',
          type_not: null,
          deletedAt: null,
          status,
          Doctor: {
            OR: [
              {
                unSigned_contains: fullname
                  ? fullname
                      .replace(/ /g, '')
                      .replace(/　/g, '')
                      .toLowerCase()
                  : '',
              },
            ],
          },
          createdAt_lte: endAt,
          createdAt_gte: startAt,
          startTime_lte: startTimeLte,
          startTime_gte: startTimeGte,
        },
      },
      fetchPolicy: 'network-only',
    }
  )

  useEffect(() => {
    refetch()
  }, [refetch])

  if (loading) return <ProgressSpinner fullHeight />
  if (error) return <ErrorComponent error={error} />
  const isUpdateMovingExpenses = checkStaffPermission({
    functionName: UPDATE_MOVING_EXPENSES_REGISTRATION,
  })
  return (
    <ListMovingExpensesOtherScene
      socket={socket}
      progress={progress}
      setProgress={progress => {
        setProgress(progress)
      }}
      removeProgress={progress => removeProgress(progress)}
      isUpdateMovingExpenses={isUpdateMovingExpenses}
      list={data.transportationExpensesConnection.edges}
      arrStatus={arrStatus}
      i18n={i18n}
      count={data.transportationExpensesConnection.aggregate.count}
      fullname={fullname}
      status={status}
      startAt={startAt}
      endAt={endAt}
      startTimeGte={startTimeGte}
      startTimeLte={startTimeLte}
      page={parseInt(page, 10)}
      rowsPerPage={parseInt(rowsPerPage, 10)}
      onPageChange={onPageChange(history, search)}
      dates={[startAt, endAt]}
      startTime={[startTimeGte, startTimeLte]}
    />
  )
}

const mapStateToProps = state => ({
  progress: state.progressBarStore.progress,
})

const mapDispatchToProps = dispatch => ({
  setProgress: progress => dispatch(setProgressBar(progress)),
  removeProgress: progress => dispatch(removeProgress(progress)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListMovingExpensesOther)
