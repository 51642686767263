import gql from 'graphql-tag'

export const ANNOUNCE_FRAGMENT = gql`
  fragment ANNOUNCE_FRAGMENT on Announce {
    id
    createdAt
    title
    content
    isPublished
    isSentEmailToAllDoctors
    imageKey
    isAddAvailableShift
    isAddRecruitmentPost
    isNotSendDoctorWorkScheduleBlock
    isNotSendDoctorBlock
  }
`
export const UPDATE_ANNOUNCE = gql`
  mutation UPDATE_ANNOUNCE(
    $announceId: ID!
    $title: String
    $content: String
    $isPublished: Boolean!
    $isSentEmailToAllDoctors: Boolean!
    $image: String
    $isAddAvailableShift: Boolean!
    $isAddRecruitmentPost: Boolean!
    $availableShifts: [ID!]
    $recruitmentPosts: [ID!]
    $isNotSendDoctorBlock: Boolean
    $isNotSendDoctorWorkScheduleBlock: Boolean
  ) {
    staffUpdateAnnounce(
      announceId: $announceId
      title: $title
      content: $content
      isPublished: $isPublished
      isSentEmailToAllDoctors: $isSentEmailToAllDoctors
      image: $image
      isAddAvailableShift: $isAddAvailableShift
      isAddRecruitmentPost: $isAddRecruitmentPost
      availableShifts: $availableShifts
      recruitmentPosts: $recruitmentPosts
      isNotSendDoctorWorkScheduleBlock: $isNotSendDoctorWorkScheduleBlock
      isNotSendDoctorBlock: $isNotSendDoctorBlock
    ) {
      ...ANNOUNCE_FRAGMENT
    }
  }
  ${ANNOUNCE_FRAGMENT}
`

export const SINGLE_ANNOUNCE = gql`
  query SINGLE_ANNOUNCE($announceId: ID) {
    announce(where: { id: $announceId }) {
      ...ANNOUNCE_FRAGMENT
      recruitmentPostList {
        id
        title
        status
        recruitmentDoctorType
        imageKey
        content
        clinicalDepartment {
          id
          name
          extraName
          departmentCode
          isDisplay
          clinic {
            id
            name
            order
            isPublished
          }
        }
      }
      availableShiftList {
        id
        startTime
        endTime
        createdAt
        updatedAt
        splitDateTime1
        splitDateTime2
        splitDateTime3
        hourlyWage
        splitHourlyWage1
        splitHourlyWage2
        splitHourlyWage3
        dailySalary
        comment
        updatedHourlyWageAt
        isPublished
        isSpecial
        recruitmentShiftsType
        clinicalDepartment {
          id
          name
          extraName
          isDisplay
          clinic {
            id
            order
            name
            nameKana
          }
        }
      }
    }
  }
  ${ANNOUNCE_FRAGMENT}
`
