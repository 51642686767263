import BreadCrumb from '@medical/components/Breadcrumb'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import { calcWorkScheduleTotalHourPerDay, convertTime } from '@medical/libs'
import numeralCurrency from '@medical/libs/numeralCurrency'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import React from 'react'
import { isMobile } from 'react-device-detect'

import RequestedWorkSchedulesListHeader from './RequestedWorkSchedulesListHeader'
import RequestedWorkSchedulesListSp from './RequestedWorkSchedulesListSp'
import styles from './Style'

const RequestedWorkSchedulesListScene = ({
  i18n,
  date,
  workSchedules,
  totalHours,
  totalDays,
  totalSalary,
  onSubmit,
}) => {
  const sortedWorkSchedules = workSchedules.sort(
    (a, b) => moment(a.startTime) > moment(b.startTime)
  )
  return (
    <Formik
      initialValues={{
        confirm: false,
        workScheduleId: '',
      }}
      onSubmit={onSubmit}
      render={formikProps => {
        const { setFieldValue } = formikProps
        if (isMobile) {
          return (
            <div className='container'>
              <div className='box'>
                <div className='schedule-calendar'>
                  <div className='calendar-container'>
                    <div style={{ paddingTop: '50px' }}>
                      <div className='mobile-work-total'>
                        <div>
                          {`${i18n.t('main.requestedWorkSchedule')}${i18n.t(
                            'main.time'
                          )}${i18n.t('main.total')}`}
                        </div>
                        <div className='mobile-work-total-item'>
                          {convertTime(totalHours)}
                        </div>
                      </div>
                      <div className='mobile-work-total'>
                        <div>
                          {`${i18n.t('main.requestedWorkSchedule')}${i18n.t(
                            'main.Days'
                          )}${i18n.t('main.total')}`}
                        </div>
                        <div className='mobile-work-total-item'>
                          {`${totalDays}日`}
                        </div>
                      </div>
                      <div className='mobile-work-total'>
                        <div>{`${i18n.t('main.requestedWorkSchedule')}${i18n.t(
                          'main.dailySalary'
                        )}${i18n.t('main.total')}`}</div>
                        <div className='mobile-work-total-item'>
                          {numeralCurrency(totalSalary)}
                        </div>
                      </div>
                      <RequestedWorkSchedulesListHeader date={moment(date)} />
                      {sortedWorkSchedules.length > 0 ? (
                        sortedWorkSchedules.map(workSchedule => (
                          <div style={styles.spContainer}>
                            <div style={styles.removeButton}>
                              <Button
                                onClick={() => {
                                  setFieldValue(
                                    'workScheduleId',
                                    workSchedule.id
                                  )
                                  setFieldValue('confirm', true)
                                }}
                                className='p-button-danger'
                                icon='pi pi-times'
                              />
                            </div>
                            <div style={styles.mobileRequestTitle}>
                              {`${i18n.t('main.scheduleDate')}: ${moment(
                                workSchedule.startTime
                              ).format('LL (dd)')}`}
                            </div>
                            <RequestedWorkSchedulesListSp
                              workSchedule={workSchedule}
                              key={workSchedule.id}
                            />
                          </div>
                        ))
                      ) : (
                        <div className='mobile-report'>
                          <div className='mobile-report-title'>
                            {i18n.t('main.noRequestedWorkSchedule')}
                          </div>
                        </div>
                      )}
                      <ConfirmationModal
                        {...formikProps}
                        confirmationMessage={i18n.t(
                          'doctor.cancelRequestedWorkSchedule.submitConfirmation'
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }

        return (
          <div className='container'>
            <div className='staff-header'>
              <div className='staff-title'>
                応募中シフト一覧
                <BreadCrumb items={[{ title: '応募中シフト一覧' }]} />
              </div>
            </div>
            <div className='box'>
              <div className='schedule-calendar'>
                <div className='calendar-container'>
                  <div style={{ paddingTop: '50px' }}>
                    <div className='overflow-container'>
                      <table className='table' style={{ marginBottom: '10px' }}>
                        <thead>
                          <tr>
                            <th width='30%'>{i18n.t('main.clinicName')}</th>
                            <th width='10%'>{i18n.t('main.departmentName')}</th>
                            <th width='20%'>{i18n.t('main.date')}</th>
                            <th width='10%'>{i18n.t('main.startTime')}</th>
                            <th width='10%'>{i18n.t('main.endTime')}</th>
                            <th width='10%'>{i18n.t('main.shiftHour')}</th>
                            <th width='8%'>{i18n.t('main.dailySalary')}</th>
                            <th width='5%' />
                          </tr>
                        </thead>
                        <tbody>
                          {sortedWorkSchedules.length > 0 ? (
                            sortedWorkSchedules.map(workSchedule => (
                              <tr key={workSchedule.id}>
                                <td>{`${workSchedule.clinic}`}</td>
                                <td>{workSchedule.clinicalDepartment}</td>
                                <td>
                                  {moment(workSchedule.startTime).format(
                                    'LL (dd)'
                                  )}
                                </td>
                                <td>
                                  {moment(workSchedule.startTime).format(
                                    'HH:mm'
                                  )}
                                </td>
                                <td>
                                  {moment(workSchedule.endTime).format('HH:mm')}
                                </td>
                                <td>
                                  {calcWorkScheduleTotalHourPerDay(
                                    workSchedule
                                  )}
                                </td>
                                <td>
                                  {numeralCurrency(workSchedule.dailySalary)}
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                  <Button
                                    onClick={() => {
                                      setFieldValue(
                                        'workScheduleId',
                                        workSchedule.id
                                      )
                                      setFieldValue('confirm', true)
                                    }}
                                    label={i18n.t('main.cancel')}
                                    className='p-button-danger'
                                    style={{
                                      fontSize: '11px',
                                      width: '80px',
                                      fontWeight: 'bold',
                                    }}
                                  />
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={8}>
                                {i18n.t('main.noRequestedWorkSchedule')}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <table>
                      <tbody>
                        <tr>
                          <th style={{ textAlign: 'left' }}>
                            {`${i18n.t('main.requestedWorkSchedule')}${i18n.t(
                              'main.time'
                            )}${i18n.t('main.total')}`}
                          </th>
                          <td> {convertTime(totalHours)}</td>
                        </tr>
                        <tr>
                          <th style={{ textAlign: 'left' }}>
                            {`${i18n.t('main.requestedWorkSchedule')}${i18n.t(
                              'main.Days'
                            )}${i18n.t('main.total')}`}
                          </th>
                          <td>{`${totalDays}日`}</td>
                        </tr>
                        <tr>
                          <th style={{ textAlign: 'left' }}>
                            {`${i18n.t('main.requestedWorkSchedule')}${i18n.t(
                              'main.dailySalary'
                            )}${i18n.t('main.total')}`}
                          </th>
                          <td>{numeralCurrency(totalSalary)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <ConfirmationModal
                    {...formikProps}
                    confirmationMessage={i18n.t(
                      'doctor.cancelRequestedWorkSchedule.submitConfirmation'
                    )}
                  />
                  <RequestedWorkSchedulesListHeader date={moment(date)} />
                </div>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}
export default RequestedWorkSchedulesListScene
