import moment from 'moment'

export const checkOverlapTime = (
  startTime1,
  endTime1,
  startTime2,
  endTime2
) => {
  const rangeTime1 = moment.range(startTime1, endTime1)
  const rangeTime2 = moment.range(startTime2, endTime2)
  return rangeTime1.overlaps(rangeTime2)
}
