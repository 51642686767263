/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable react/destructuring-assignment */
import { cloudWatchLogger } from '@medical/libs'
import middleware from '@medical/middleware'
import { DOCTOR_EMAIL, STAFF_EMAIL } from '@medical/constant'
import React from 'react'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error, info) {
    let email
    if (middleware.isDoctor()) {
      email = localStorage.getItem(DOCTOR_EMAIL)
    } else if (middleware.isStaff()) {
      email = localStorage.getItem(STAFF_EMAIL)
    }
    const infoError = info.componentStack.toString().split('\n')[1]
    const detail = error.toString()

    cloudWatchLogger().error({
      level: 'ERROR',
      detail: infoError.trim(),
      urlError: window.location.href,
      email,
      eM: detail,
    })
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1 />
    }
    return this.props.children
  }
}

export default ErrorBoundary
