import gql from 'graphql-tag'

export const ACTIVITY_LOGS_DOCTOR_HISTORY = gql`
  query ActivityLogsConnection(
    $skip: Int
    $first: Int
    $where: ActivityLogWhereInput
    $orderBy: ActivityLogOrderByInput
  ) {
    activityLogsConnection(skip: $skip, first: $first, where: $where, orderBy: $orderBy) {
      aggregate(where: $where) {
        count
      }
      edges {
        node {
          id
          doctor {
            id
            lastname
            firstname
          }
          createdAt
          activity
          staff {
            id
            lastname
            firstname
            createdAt
          }
        }
      }
    }
  }
`

export const DOCTORS_CONNECTION = gql`
  query DOCTORS_CONNECTION(
    $where: DoctorWhereInput
    $first: Int
    $skip: Int
    $orderBy: DoctorOrderByInput
  ) {
    doctorsConnection(
      where: $where
      first: $first
      skip: $skip
      orderBy: $orderBy
    ) {
      edges {
        node {
          id
          email
          fullName
          fullNameKana
          blocked
        }
      }
      aggregate(where: $where) {
        count
      }
    }
  }
`

export const DOCTOR_QUERY = gql`
query Query($where: DoctorWhereUniqueInput!) {
  doctor(where: $where) {
    id
    firstname
    lastname
    email
    blocked
  }
}
`
