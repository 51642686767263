import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import {
  HOLIDAY_URL,
  HOURLY_WAGE,
  HOURLY_WAGE_ID,
  PERMANENT_DOCTOR,
  REGULAR_SHIFT_DOCTOR,
  TYPE_CREATE,
} from '@medical/constant'
import {
  SHOW_MONEY_FIELDS,
  STAFF_ADD_COMMENT,
  STAFF_UPDATE_CONFIRMED_SHIFTS_STATUS,
} from '@medical/constant/permissions'
import { useAxios } from '@medical/hooks'
import {
  checkStaffPermission,
  clinicalDepartmentNames,
  combineNames,
  convertDoctors,
  convertDoctorsConnection,
  findNameOrEmail,
  sortAndFilterByClinicOrder,
  splitLogic,
  thisRangeHolidays,
} from '@medical/libs'
import { SCHEDULE_AVAILABLESHIFT_CONNECTION } from '@medical/pages/staff/AvailableShiftGroupList/AvailableShiftGroupList.graphql'
import { ShiftGroupForm } from '@medical/pages/staff/components'
import {
  DEFAULT_HOURLY_WAGES,
  DOCTOR_CONNECTION,
  DOCTORS_DEPARTMENTS,
} from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import { CLINICS_DAILY_REPORT } from '@medical/pages/staff/DailyAvailableShift/DailyAvailableShift.graphql'
import { CLINICS_MONTHLY_REPORT } from '@medical/pages/staff/MonthlyAvailableShift/MonthlyAvailableShift.graphql'
import { convertDayInMonthToMatrix } from '@medical/pages/staff/RecruitmentScheduleShift/CreateRecruitmentScheduleShift/function'
import { useCustom } from '@medical/provider/context'
import moment from 'moment'
import queryString from 'query-string'
import { pathOr } from 'ramda'
import React from 'react'

import { STAFF_CREATE_SCHEDULED_SHIFT_GROUP } from './CreateScheduledShiftGroup.graphql'
import { LinkMail } from './templateEmail'

const CreateScheduledShiftGroup = ({
  match: {
    params: { year, month, day },
  },
  location: { search, state },
  closeModal,
}) => {
  const [{ i18n, popup }] = useCustom()
  const date =
    year && month
      ? moment()
          .startOf('day')
          .year(year)
          .month(month - 1)
          .date(day || 1)
          .hour(9)
      : moment()
          .startOf('day')
          .hour(9)
  const isMoneyPermitted = checkStaffPermission({
    functionName: SHOW_MONEY_FIELDS,
  })
  const isAddCommentPermitted = checkStaffPermission({
    functionName: STAFF_ADD_COMMENT,
  })
  const isChangeConfirmedShiftsStatusPermitted = checkStaffPermission({
    functionName: STAFF_UPDATE_CONFIRMED_SHIFTS_STATUS,
  })
  const { loading, error, data } = useQuery(DOCTORS_DEPARTMENTS)
  const {
    loading: dataDoctorConnectionLoading,
    error: dataDoctorConnectionError,
    data: dataDoctorConnection,
  } = useQuery(DOCTOR_CONNECTION, {
    variables: {
      where: {
        registrationStatus_in: ['CREATED_BY_STAFF', 'ACCEPTED'],
        // blocked: false,
      },
      first: 10,
      skip: 0,
      orderBy: 'createdAt_ASC',
    },
  })
  // const {
  //   loading: hourlyWageLoading,
  //   error: hourlyWageError,
  //   data: hourlyWageData,
  // } = useQuery(DEFAULT_HOURLY_WAGES, {
  //   variables: {
  //     where: {
  //       workPattern_in: [REGULAR_SHIFT_DOCTOR, PERMANENT_DOCTOR],
  //     },
  //   },
  // })

  const {
    page = 1,
    rowsPerPage = 10,
    orderBy = 'startDate_ASC',
    fullname,
    email,
    name_contains = '',
  } = queryString.parse(search)
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  const reloadVariables = {
    first: first < 0 ? 10 : first,
    skip: skip < 0 ? 0 : skip,
    orderBy,
    where: {
      name_contains: name_contains.trim(),
      AvailableShift_some: {
        WorkSchedule: {
          Doctor: {
            AND: findNameOrEmail({ fullname, email }),
            deletedAt: null,
          },
        },
      },
    },
  }

  const getRefetchQueries = () => {
    if (year && month) {
      return day
        ? [
            {
              query: CLINICS_DAILY_REPORT,
              variables: { date: moment(date).startOf('day') },
            },
          ]
        : [
            {
              query: CLINICS_MONTHLY_REPORT,
              variables: {
                date: moment(date).startOf('day'),
              },
            },
          ]
    }

    if (window.location.href.indexOf('/staff/available_shift_group_list')) {
      return [
        {
          query: SCHEDULE_AVAILABLESHIFT_CONNECTION,
          variables: reloadVariables,
          fetchPolicy: 'network-only',
        },
      ]
    }
    return []
  }
  const [createScheduledShift] = useMutation(
    STAFF_CREATE_SCHEDULED_SHIFT_GROUP,
    {
      refetchQueries: getRefetchQueries(),
      awaitRefetchQueries: true,
    }
  )

  const {
    loading: holidaysLoading,
    data: holidaysData,
    error: holidaysError,
  } = useAxios({
    url: HOLIDAY_URL,
    params: {
      timeMin: moment(date)
        .startOf('month')
        .toISOString(),
      timeMax: moment(date)
        .add(18, 'month')
        .endOf('month')
        .toISOString(),
    },
  })

  if (loading || dataDoctorConnectionLoading) return <ProgressSpinner />
  if (holidaysLoading || loading) return <ProgressSpinner />
  if (error || holidaysError) return <ErrorComponent error={error} />
  if (dataDoctorConnectionError)
    return <ErrorComponent error={dataDoctorConnectionError} />

  const formatSubmitTime = submitTime =>
    `1000-10-10T${
      moment(submitTime)
        .format()
        .split('T')[1]
        .split('+')[0]
    }`
  // const formatSubmitTimeAdd9Hours = submitTime => {
  //   let time = `1000-10-10T${
  //     moment(submitTime)
  //       // .add(9, 'hours')
  //       .format()
  //       .split('T')[1]
  //       .split('+')[0]
  //   }`
  //   return time
  // }

  const onSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    const {
      clinicalDepartment: { id: clinicalDepartmentId },
      date: submitDate,
      isHolidayRepeat,
      splits,
      doctor: { id: doctorId },
      name,
      isMonday,
      isTuesday,
      isWednesday,
      isThursday,
      isFriday,
      isSaturday,
      isSunday,
      isDayoff,
      defaultHourlyWageId,
      mailContent,
      repeatSetting,
      isSendMail,
      startEndDate,
      listWeekDay,
      listWeekDay1,
      listWeekDay2,
      listWeekDay3,
      listWeekDay4,
      isDayOnWeekday,
      comment,
      doctorSubsidy,
    } = values
    const splitComments = []
    values.splitComments.map(value => {
      if (value.text !== '' && value.type !== '') {
        splitComments.push({
          text: value.text,
          type: value.type,
          staff: {},
        })
      }

      return splitComments
    })
    let dataTime
    Object.keys(splits).forEach(key => {
      dataTime = {
        ...dataTime,
        ...splitLogic({ submitDate, splits: splits[key], key, date: moment() }),
      }
    })
    const holidays = thisRangeHolidays({
      holidaysData: pathOr([], ['items'], holidaysData),
      startDate: startEndDate[0],
      endDate: startEndDate[1],
    })
    const isHolidays = []
    holidays.forEach(item => {
      isHolidays.push(item.date)
    })
    const mail = isSendMail
      ? `${mailContent.trim()}
${LinkMail()}`
      : null
    try {
      const listBooleanDayInMonth = convertDayInMonthToMatrix(
        listWeekDay,
        listWeekDay1,
        listWeekDay2,
        listWeekDay3,
        listWeekDay4
      )

      const startDateFormat = `${
        moment(startEndDate[0])
          .format()
          .split('T')[0]
          .split('+')[0]
      }T00:00:00`
      const endDateFormat = `${
        moment(startEndDate[1])
          .format()
          .split('T')[0]
          .split('+')[0]
      }T23:59:59`
      await createScheduledShift({
        variables: {
          doctorId,
          accepted: true,
          defaultHourlyWageId,
          mailContent: mail,
          isHolidays,
          repeatSetting,
          comment,
          staffComments: splitComments,
          dateMatrix: JSON.stringify(listBooleanDayInMonth),
          doctorSubsidy,
          dataTime,
          isPublished: values.isPublished,
          isHolidayRepeat,
          data: {
            name,
            startDate: startDateFormat,
            endDate: endDateFormat,
            isAvailableOnMonday:
              repeatSetting === TYPE_CREATE.DAILY ? true : isMonday,
            isAvailableOnTuesday:
              repeatSetting === TYPE_CREATE.DAILY ? true : isTuesday,
            isAvailableOnWednesday:
              repeatSetting === TYPE_CREATE.DAILY ? true : isWednesday,
            isAvailableOnThursday:
              repeatSetting === TYPE_CREATE.DAILY ? true : isThursday,
            isAvailableOnFriday:
              repeatSetting === TYPE_CREATE.DAILY ? true : isFriday,
            isAvailableOnSaturday:
              repeatSetting === TYPE_CREATE.DAILY ? true : isSaturday,
            isAvailableOnSunday:
              repeatSetting === TYPE_CREATE.DAILY ? true : isSunday,
            isAvailableOnHoliday:
              repeatSetting === TYPE_CREATE.DAILY ? true : isDayoff,
            isAvailableOnWeekdayHoliday: isDayOnWeekday,
          },
        },
      })
      popup.success(i18n.t('staff.scheduledShiftGroup.submissionSuccess'))
      setSubmitting(false)
      resetForm()
      closeModal()
    } catch (error) {
      popup.error(error)
      setFieldValue('confirm', false)
      setSubmitting(false)
    }
  }

  const { doctors, clinicalDepartments, departments } = data

  const sortedClinicalDepartments = sortAndFilterByClinicOrder({
    clinicalDepartments,
    isFilter: true,
  })
  // const { defaultHourlyWages } = hourlyWageData
  const departmentNames = clinicalDepartmentNames({ departments })
  // const addDefaultHourlyWages = listDoctor => {
  //   if (!listDoctor) return []
  //   return listDoctor.map(doctor => {
  //     defaultHourlyWages.forEach(hourlyWage => {
  //       if (doctor.node.workPattern === hourlyWage.workPattern) {
  //         doctor.node[HOURLY_WAGE] = hourlyWage.hourlyWage
  //         doctor.node[HOURLY_WAGE_ID] = hourlyWage.id
  //       }
  //     })
  //     return doctor
  //   })
  // }

  // const filterDoctor = () => {
  //   const doctorList = convertDoctors(addDefaultHourlyWages(doctors))
  //   if (!doctorList || doctorList.length === 0) return []
  //   return doctorList.filter(doctor =>
  //     ['PERMANENT_DOCTOR', 'REGULAR_SHIFT_DOCTOR'].includes(doctor.workPattern)
  //   )
  // }
  const { edges } = dataDoctorConnection.doctorsConnection
  return (
    <ShiftGroupForm
      i18n={i18n}
      title={i18n.t('staff.scheduledShiftGroup.createTitle')}
      submitLabel={i18n.t('staff.scheduledShiftGroup.createSubmit')}
      confirmationMessage={i18n.t(
        'staff.scheduledShiftGroup.createConfirmation'
      )}
      onSubmit={onSubmit}
      // doctors={filterDoctor()}
      // doctors={convertDoctorsConnection(addDefaultHourlyWages(edges))}
      doctors={convertDoctorsConnection(edges)}
      clinicalDepartments={combineNames({
        clinicalDepartments: sortedClinicalDepartments,
        departmentNames,
      })}
      date={date}
      isMoneyPermitted={isMoneyPermitted}
      isCreateWorkSchedule
      isAddCommentPermitted={isAddCommentPermitted}
      isCreateScheduledShiftGroup
      // defaultHourlyWages={defaultHourlyWages}
      holidaysData={holidaysData}
      popup={popup}
      isChangeConfirmedShiftsStatusPermitted={
        isChangeConfirmedShiftsStatusPermitted
      }
      detailValue={state}
    />
  )
}

export default CreateScheduledShiftGroup
