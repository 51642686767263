import gql from 'graphql-tag'

export const DOCTOR_QUERY = gql`
  query DOCTOR_QUERY($doctorId: ID) {
    doctor(where: { id: $doctorId }) {
      id
      email
      fullName
      birthday
      doctorIdentity {
        id
        identityNumber
        frontSideIdentityKey
        backSideIdentityKey
        frontSideDriverLicenseKey
        backSideDriverLicenseKey
        doctorIdentityRelation {
          id
          name
          birthday
          relation
          identityNumber
          updatedAt
          deletedAt
        }
      }
    }
  }
`

export const STAFF_PROCESS_DOCTOR_IDENTITY_REQUEST = gql`
  mutation STAFF_PROCESS_DOCTOR_IDENTITY_REQUEST(
    $doctorIdentityId: ID!
    $isApprove: Boolean!
    $mailContent: String
  ) {
    staffProcessDoctorIdentity(
      doctorIdentityId: $doctorIdentityId
      isApprove: $isApprove
      mailContent: $mailContent
    )
  }
`

export const STAFF_UPDATE_DOCTOR_IDENTITY = gql`
  mutation STAFF_UPDATE_DOCTOR_IDENTITY(
    $doctorId: ID!
    $identityNumber: String!
    $frontSideIdentityFile: String
    $backSideIdentityFile: String
    $frontSideLicenseFile: String
    $backSideLicenseFile: String
    $doctorIdentityRelations: [DoctorIdentityRelationForUpsert!]
  ) {
    staffUpdateDoctorIdentity(
      doctorId: $doctorId
      identityNumber: $identityNumber
      frontSideIdentityFile: $frontSideIdentityFile
      backSideIdentityFile: $backSideIdentityFile
      frontSideLicenseFile: $frontSideLicenseFile
      backSideLicenseFile: $backSideLicenseFile
      doctorIdentityRelations: $doctorIdentityRelations
    ) 
  }
`
