import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import {
  DOCTORS_FIELDS,
  STAFF_CREATE_DOCTOR,
  STAFF_UPDATE_DOCTOR,
} from '@medical/constant/permissions'
import {
  checkStaffPermission,
  clinicalDepartmentNames,
  findNameOrEmail,
  onPageChange,
} from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import Auth from '@medical/middleware/auth'
import queryString from 'query-string'
import React, { useContext, useEffect, useState } from 'react'

import {
  BLOCK_DOCTOR,
  DOCTORS_CONNECTION,
  RESTORE_BLOCKED_DOCTOR,
} from '../DoctorsList/DoctorsList.graphql'
import DoctorListScene from '../DoctorsList/DoctorsListScene'
import { SocketContext } from '@medical/provider/socket'
import {
  removeProgress,
  setProgressBar,
} from '@medical/provider/store/reducers/progress/progress.action'
import { connect } from 'react-redux'

const restore = 'restore'
const block = 'block'

const DoctorsPendingList = ({
  history,
  location: { search },
  progress,
  setProgress,
  removeProgress,
}) => {
  const {
    page = 1,
    rowsPerPage = 20,
    orderBy = 'createdAt_DESC',
    fullname,
    email,
    status,
  } = queryString.parse(search)
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  const variables = {
    first: first < 0 ? 20 : first,
    skip: skip < 0 ? 0 : skip,
    orderBy,
    where: {
      AND: findNameOrEmail({ fullname, email }),
      registrationStatus_in: status
        ? [status]
        : ['PENDING', 'NEW', 'ACCEPTED', 'REJECTED'],
    },
  }
  const socket = useContext(SocketContext)
  const [{ i18n, popup }] = useCustom()
  const isDoctorsFieldsPermitted = checkStaffPermission({
    functionName: DOCTORS_FIELDS,
  })
  const isUpdatePermitted = checkStaffPermission({
    functionName: STAFF_UPDATE_DOCTOR,
  })
  const isCreatePermitted = checkStaffPermission({
    functionName: STAFF_CREATE_DOCTOR,
  })
  const [isDoctorsPendingList] = useState(true)
  const mutationOptions = {
    refetchQueries: [
      {
        query: DOCTORS_CONNECTION,
        variables,
      },
    ],
    awaitRefetchQueries: true,
  }
  const [blockDoctor] = useMutation(BLOCK_DOCTOR, mutationOptions)
  const [restoreBlockedDoctor] = useMutation(
    RESTORE_BLOCKED_DOCTOR,
    mutationOptions
  )
  const { loading, error, data, refetch } = useQuery(DOCTORS_CONNECTION, {
    variables,
    fetchPolicy: 'network-only',
  })
  useEffect(() => {
    refetch()
  }, [data, refetch])
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const departmentNames = clinicalDepartmentNames({
    departments: data.departments,
  })
  const token = Auth.getToken()

  const url = `${process.env.REACT_APP_ENDPOINT}/downloadcsvDoctorPending?token=${token}`

  const onSubmit = async (
    { doctorId, functionName },
    { setSubmitting, resetForm }
  ) => {
    try {
      if (functionName === block) {
        await blockDoctor({
          variables: {
            doctorId,
          },
        })
        setSubmitting(false)
        resetForm()
        popup.success(i18n.t('staff.blockDoctor.submissionSuccess'))
      } else {
        await restoreBlockedDoctor({
          variables: {
            doctorId,
          },
        })
        setSubmitting(false)
        resetForm()
        popup.success(i18n.t('staff.restoreBlockedDoctor.submissionSuccess'))
      }
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }

  const {
    doctorsConnection: {
      edges,
      aggregate: { count },
    },
  } = data
  return (
    <DoctorListScene
      i18n={i18n}
      socket={socket}
      progressList={progress}
      setProgress={progress => setProgress(progress)}
      removeProgress={progress => removeProgress(progress)}
      download={url}
      onSubmit={onSubmit}
      edges={edges}
      count={count}
      variables={variables}
      page={parseInt(page, 10)}
      rowsPerPage={parseInt(rowsPerPage, 10)}
      fullname={fullname}
      email={email}
      status={status}
      onPageChange={onPageChange(history, search)}
      block={block}
      restore={restore}
      isDoctorsFieldsPermitted={isDoctorsFieldsPermitted}
      isUpdatePermitted={isUpdatePermitted}
      isCreatePermitted={isCreatePermitted}
      departmentNames={departmentNames}
      isDoctorsPendingList={isDoctorsPendingList}
    />
  )
}

const mapStateToProps = state => ({
  progress: state.progressBarStore.progress,
})

const mapDispatchToProps = dispatch => ({
  setProgress: progress => dispatch(setProgressBar(progress)),
  removeProgress: progress => dispatch(removeProgress(progress)),
})
export default connect(mapStateToProps, mapDispatchToProps)(DoctorsPendingList)
