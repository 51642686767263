import ClinicNameRender from '@medical/components/ClinicNameRender'
import StatusRender from '@medical/components/StatusRender'
import numeralCurrency from '@medical/libs/numeralCurrency'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ActionItem } from './ActionItem'

export const WorkScheduleItem = ({ workSchedule, departmentNames }) => {
  const { i18n } = useTranslation()

  return (
    <div className='mobile-work-schedule-item'>
      <div style={{ marginBottom: '10px' }}>
        {i18n.t('main.clinicName')} :
        <span className='clinic-name'>
          {ClinicNameRender({
            clinicalDepartment: workSchedule.clinicalDepartment,
          })}
        </span>
      </div>
      <div>
        <div>
          {i18n.t('main.departmentName')} :{' '}
          {departmentNames[workSchedule.clinicalDepartment.name]}
        </div>
        <div>
          {i18n.t('main.startTime')} :{' '}
          {moment(workSchedule.startTime).format('HH:mm')}
        </div>
        <div>
          {i18n.t('main.endTime')} :{' '}
          {moment(workSchedule.endTime).format('HH:mm')}
        </div>
        <div>
          {i18n.t('main.dailySalary')} :{' '}
          {numeralCurrency(workSchedule.acceptedShift.dailySalary)}
        </div>
        <div>
          {i18n.t('main.status')} : <StatusRender val={workSchedule.accepted} />
        </div>
      </div>
      <span className='action'>
        <ActionItem item={workSchedule} />
      </span>
    </div>
  )
}
