import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { CLINIC_QUERY } from '@medical/pages/staff/Clinics/ClinicUpdate/ClinicUpdate.graphql'
import React from 'react'

import DoctorClinicDetailScene from './DoctorClinicDetailScene'

const DoctorClinicDetail = ({
  match: {
    params: { id },
  },
}) => {
  const { loading, error, data } = useQuery(CLINIC_QUERY, {
    variables: {
      clinicId: id,
      deletedAt: null,
    },
  })

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  return (
    <DoctorClinicDetailScene
      clinic={data.clinic || {}}
      departments={data.departments || []}
      disabled
    />
  )
}

export default DoctorClinicDetail
