import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, NotFound, ProgressSpinner } from '@medical/components'
import {
  clinicalDepartmentNames,
  combineNames,
  sortAndFilterByClinicOrder,
} from '@medical/libs'
import { onChangePageAnt } from '@medical/libs/onPageChange'
import { DOCTORS_DEPARTMENTS } from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import { RECRUITMENT_POSTS_CONNECTION } from '@medical/pages/staff/RecruitmentPost/RecruitmentPostsList/RecruitmentPost.graphql'
import { useCustom } from '@medical/provider/context'
import queryString from 'query-string'
import React from 'react'

import RecruitmentPostsScene from './RecruitmentPostsScene'

const RecruitmentPosts = ({ history, location: { search } }) => {
  const [{ i18n, isDoctorBlocked }] = useCustom()
  const {
    page = 1,
    rowsPerPage = 10,
    orderBy = 'createdAt_DESC',
    title,
    workPattern,
    clinicalDepartmentIds,
    content_contains,
  } = queryString.parse(search, {
    // fetchPolicy: 'network-only',
  })
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  const clinicalDepartmentIdsSearch =
    clinicalDepartmentIds && clinicalDepartmentIds.length > 0
      ? clinicalDepartmentIds.split('＿')
      : undefined
  let variables = {
    first: first < 0 ? 10 : first,
    skip: skip < 0 ? 0 : skip,

    orderBy,

    where: {
      isPublished: true,
      ClinicalDepartment: clinicalDepartmentIdsSearch && {
        id_in: clinicalDepartmentIdsSearch,
        Clinic: {
          deletedAt: null,
        },
      },
    },
  }
  if (clinicalDepartmentIdsSearch?.length) {
    variables = {
      where: {
        isPublished: true,
        ClinicalDepartment: clinicalDepartmentIdsSearch && {
          id_in: clinicalDepartmentIdsSearch,
          Clinic: {
            deletedAt: null,
          },
        },
      },
      first: first < 0 ? 10 : first,
      skip: skip < 0 ? 0 : skip,
      orderBy,
    }
  }
  if (workPattern) {
    variables = {
      where: {
        isPublished: true,
        recruitmentDoctorType: workPattern,
        ClinicalDepartment: {
          Clinic: {
            deletedAt: null,
          },
        },
        // clinicalDepartment: {
        //   id_in: clinicalDepartmentIdsSearch,
        // },
      },
      first: first < 0 ? 10 : first,
      skip: skip < 0 ? 0 : skip,
      orderBy,
    }
  }
  if (content_contains) {
    variables = {
      where: {
        OR: [
          {
            title_contains: content_contains,
          },
          {
            content_contains: content_contains,
          },
        ],
        isPublished: true,
        ClinicalDepartment: {
          Clinic: {
            deletedAt: null,
          },
        },
        // clinicalDepartment: {
        //   id_in: clinicalDepartmentIdsSearch,
        // },
      },
      first: first < 0 ? 10 : first,
      skip: skip < 0 ? 0 : skip,
      orderBy,
    }
  }
  if (workPattern && clinicalDepartmentIdsSearch?.length) {
    variables = {
      where: {
        isPublished: true,

        recruitmentDoctorType: workPattern,

        ClinicalDepartment: {
          id_in: clinicalDepartmentIdsSearch,
          Clinic: {
            deletedAt: null,
          },
        },
      },
      first: first < 0 ? 10 : first,
      skip: skip < 0 ? 0 : skip,
      orderBy,
    }
  }

  if (content_contains && clinicalDepartmentIdsSearch?.length) {
    variables = {
      where: {
        isPublished: true,
        OR: [
          {
            title_contains: content_contains,
          },
          {
            content_contains: content_contains,
          },
        ],
        ClinicalDepartment: {
          id_in: clinicalDepartmentIdsSearch,
          Clinic: {
            deletedAt: null,
          },
        },
      },
      first: first < 0 ? 10 : first,
      skip: skip < 0 ? 0 : skip,
      orderBy,
    }
  }

  if (content_contains && workPattern) {
    variables = {
      where: {
        recruitmentDoctorType: workPattern,
        isPublished: true,
        OR: [
          {
            title_contains: content_contains,
          },
          {
            content_contains: content_contains,
          },
        ],
        ClinicalDepartment: {
          id_in: clinicalDepartmentIdsSearch,
          Clinic: {
            deletedAt: null,
          },
        },
      },

      first: first < 0 ? 10 : first,

      skip: skip < 0 ? 0 : skip,

      orderBy,
    }
  }

  if (clinicalDepartmentIdsSearch?.length && workPattern && content_contains)
    variables = {
      where: {
        isPublished: true,

        recruitmentDoctorType: workPattern,

        OR: [
          {
            title_contains: content_contains,
          },

          {
            content_contains: content_contains,
          },
        ],

        ClinicalDepartment: {
          id_in: clinicalDepartmentIdsSearch,
          Clinic: {
            deletedAt: null,
          },
        },
      },

      first: first < 0 ? 10 : first,

      skip: skip < 0 ? 0 : skip,

      orderBy,
    }
  const { loading, error, data } = useQuery(RECRUITMENT_POSTS_CONNECTION, {
    variables,
    fetchPolicy: 'network-only',
  })
  const {
    loading: clinicalDepartmentLoading,

    error: clinicalDepartmentErr,

    data: clinicalDepartmentData,
  } = useQuery(DOCTORS_DEPARTMENTS)
  if (loading || clinicalDepartmentLoading) return <ProgressSpinner />
  if (error || clinicalDepartmentErr)
    return <ErrorComponent error={error || clinicalDepartmentErr} />
  if (isDoctorBlocked) {
    return <NotFound />
  }
  const {
    recruitmentPostsConnection: {
      edges,
      aggregate: { count },
    },
  } = data
  const { clinicalDepartments, departments } = clinicalDepartmentData
  const departmentNames = clinicalDepartmentNames({ departments })
  const sortedClinicalDepartments = sortAndFilterByClinicOrder({
    clinicalDepartments,
    isFilter: true,
  })
  return (
    <RecruitmentPostsScene
      i18n={i18n}
      edges={edges}
      rowsPerPage={rowsPerPage}
      page={page}
      count={count}
      title={title}
      content_contains={content_contains}
      onPageChange={onChangePageAnt(history, search)}
      workPattern={workPattern}
      history={history}
      departmentNames={departmentNames}
      clinicalDepartments={combineNames({
        clinicalDepartments: sortedClinicalDepartments,
        departmentNames,
      })}
      clinicalDepartmentIds={clinicalDepartmentIdsSearch}
    />
  )
}
export default RecruitmentPosts
