import gql from 'graphql-tag'

export const ADDRESS_FRAGMENTS = gql`
  fragment ADDRESS_FRAGMENTS on Address {
    id
    stateOrRegion
    address1
    address2
    zipCode
  }
`

export const DOCTOR_FRAGMENTS = gql`
  fragment DOCTOR_FRAGMENTS on Doctor {
    id
    insuranceDoctorNumber
    insuranceDoctorSymbol
    insuranceDoctorType
    firstname
    lastname
    fullName
    firstnameKana
    lastnameKana
    fullNameKana
    gender
    birthday
    medicalLicenseNumber
    departments
    email
    phoneNumber
    homeAddress {
      ...ADDRESS_FRAGMENTS
    }
    nearestStations
    placeOfWork
    workPattern
    adjustHourlyWageRange
    joinBackground
    doctorNo
    joinBackgroundOther
    graduationUniversity
    graduationUniversityYear
    obtainDoctorLicenseYear
    professionalDescription
    lastAnnounceSeen
    lastLoggedIn
    unseenNoAnnounce
    blocked
    registrationStatus
    isJoinedSublingualImmunotherapyELearning
    isJoinedOnlineDiagnosticTraining
    introducedBy
    reasonKnowWebsites
    otherReasonKnowWebsite
    careerHistories {
      year
      month
      description
    }
    isAvailableOnMonday
    isAvailableOnTuesday
    isAvailableOnWednesday
    isAvailableOnThursday
    isAvailableOnFriday
    isAvailableOnSaturday
    isAvailableOnSunday
    isAvailableOnHoliday
    medicalRecord
    orca
    questionnaire
    updatedAt
    skillSet {
      id
    }
    firstWorkday
    createdAt
    confirmationNumber
    deletedBy {
      id
    }
    countedCancelWorkTendency {
      totalCount
      oneYear
      sixMonth
      threeMonth
      oneMonth
    }
    countedCancelLetterDueToClinicTendency {
      totalCount
      oneYear
      sixMonth
      threeMonth
      oneMonth
    }
    staffMemo
    province
    isBlockedMyCar
    isBlockedMail
    interviewTime
  }
  ${ADDRESS_FRAGMENTS}
`
export const DOCTOR_ME_FRAGMENT = gql`
  fragment DOCTOR_ME_FRAGMENT on Query {
    doctorMe {
      ...DOCTOR_FRAGMENTS
      bankAccount {
        id
        bankName
        bankCode
        branchName
        branchCode
        accountType
        accountNumber
        accountHolderName
      }
      files(where: { deletedAt: null }) {
        id
        description
        encoding
        fileCategory
        filename
        key
        mimetype
      }
      countedDoctorLastWorkShift {
        lastWorkday
        lastWorkdayClinic
        lastWorkdayDepartment
        numberShiftWorkedInMorning
        numberShiftWorkedInAfternoon
        numberShiftWorkedInNight
      }
    }
  }
  ${DOCTOR_FRAGMENTS}
`

export const DOCTOR_ME = gql`
  query DOCTOR_ME {
    ...DOCTOR_ME_FRAGMENT
  }
  ${DOCTOR_ME_FRAGMENT}
  
`

export const DOCTOR_SUBSCRIPTION = gql`
  subscription {
    doctorLoggedIn {
      id
      email
    }
  }
`

export const DOCTOR_BLOCKED_MAIL_SUBSCRIPTION = gql`
  subscription {
    doctorBlockedMail {
      id
    }
  }
`

export const DOCTOR_MSG_SUBSCRIPTION = gql`
  subscription DOCTOR_MSG_SUBSCRIPTION($doctorId: ID!) {
    doctorConversationsSubscription(doctorId: $doctorId) {
      id
      isReadByDoctor
    }
  }
`

export const CONVERSATION_DOCTOR = gql`
  query CONVERSATION_DOCTOR($where: ConversationWhereInput!) {
      conversationDoctor(where: $where) {
        id
        isReadByDoctor
    }
  }
`
