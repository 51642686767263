import gql from 'graphql-tag'

export const DOCTOR_QUERY = gql`
  query DOCTOR_QUERY($doctorId: ID) {
    doctor(where: { id: $doctorId }) {
      id
      email
      fullName
      birthday
      doctorIdentity {
        id
        identityNumber
        frontSideIdentityKey
        backSideIdentityKey
        frontSideDriverLicenseKey
        backSideDriverLicenseKey
        doctorIdentityRelation {
          id
          name
          birthday
          relation
          identityNumber
          updatedAt
          deletedAt
        }
      }
    }
  }
`

export const STAFF_PROCESS_DOCTOR_IDENTITY_REQUEST = gql`
  mutation STAFF_PROCESS_DOCTOR_IDENTITY_REQUEST(
    $doctorIdentityId: ID!
    $isApprove: Boolean!
    $mailContent: String
  ) {
    staffProcessDoctorIdentity(
      doctorIdentityId: $doctorIdentityId
      isApprove: $isApprove
      mailContent: $mailContent
    )
  }
`

export const STAFF_DELETE_DOCTOR_IDENTITY = gql`
  mutation STAFF_DELETE_DOCTOR_IDENTITY($doctorIdentityId: ID!) {
    staffDeleteDoctorIdentity(doctorIdentityId: $doctorIdentityId)
  }
`
export const DELETE_FILE = gql`
  mutation DELETE_FILE($key: String!) {
    deleteFile(key: $key)
  }
`