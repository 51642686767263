import { combineReducers } from 'redux'

import cartReducer from './cart/cart.reducer'
import doctorReducer from './doctor/doctor.reducer'
import progressBarReducer from './progress/progress.reducer'
import staffReducer from './staff/staff.reducer'

const reducers = combineReducers({
  doctorStore: doctorReducer,
  cartStore: cartReducer,
  staffStore: staffReducer,
  progressBarStore: progressBarReducer,
})

export default reducers
