import ModalLink from '@medical/components/ModalLink'
import { calcWorkScheduleTotalHourPerDay } from '@medical/libs'
import numeralCurrency from '@medical/libs/numeralCurrency'
import Router from '@medical/routes/router'
import moment from 'moment'
import { Button } from 'primereact/button'
import React from 'react'
import { useTranslation } from 'react-i18next'

const AcceptedWorkSchedulesListSp = ({
  workSchedule,
  showTypeOverTime,
  today,
  now
}) => {
  const { i18n } = useTranslation()
  const dataOverTime = workSchedule.workOvertime.filter(item => !item.deletedAt)
  const workStartTime = moment(workSchedule.startTime).format('YYYY-MM-DD')
  const workEndTime = moment(workSchedule.endTime)
  return (
    <div className='mobile-report'>
      <div className='mobile-report-title'>
        {`${i18n.t('main.scheduleDate')}: ${moment(
          workSchedule.startTime
        ).format('LL (dd)')}`}
      </div>
      <div className='mobile-report-item'>
        {i18n.t('main.clinicName')} :
        <span className='mobile-cart-item-content'>{workSchedule.clinic}</span>
      </div>
      <div className='mobile-report-item'>
        {i18n.t('main.departmentName')} :
        <span className='mobile-report-item-content'>
          {workSchedule.clinicalDepartment}
        </span>
      </div>
      <div className='mobile-report-item'>
        {i18n.t('main.startTime')} :
        <span className='mobile-report-item-content'>
          {moment(workSchedule.startTime).format('HH:mm')}
        </span>
      </div>
      <div className='mobile-report-item'>
        {i18n.t('main.endTime')} :
        <span className='mobile-report-item-content'>
          {moment(workSchedule.endTime).format('HH:mm')}
        </span>
      </div>
      <div className='mobile-report-item'>
        {i18n.t('main.shiftHour')} :
        <span className='mobile-report-item-content'>
          {calcWorkScheduleTotalHourPerDay(workSchedule)}
        </span>
      </div>
      <div className='mobile-report-item'>
        {i18n.t('main.dailySalary')} :
        <span className='mobile-report-item-content'>
          {numeralCurrency(workSchedule.dailySalary)}
        </span>
      </div>
      <div className='mobile-report-item'>
        {i18n.t('staff.acceptedWorkSchedulesList.overTime')}{' '}
        <span className='mobile-report-item-content'>
          {workEndTime > moment() ? (
            <Button
              style={{ backgroundColor: '#c8c8c8', width: 'auto' }}
              className='p-button-secondary w-100'
              label={i18n.t('staff.acceptedWorkSchedulesList.rejected')}
            />
          ) : (
            <ModalLink
              to={Router.get(Router.doctorAcceptedWorkSchedulesOverTime, {
                workScheduleId: workSchedule.id,
              })}
            >
              {showTypeOverTime(
                dataOverTime[0] ? dataOverTime[0].status : null
              )}
            </ModalLink>
          )}
        </span>
      </div>
      <div className='mobile-report-item'>
        {i18n.t('main.descriptionDetail')} :
        <span className='mobile-report-item-content'>
          <ModalLink
            to={Router.get(Router.doctorAcceptedWorkScheduleDetail, {
              workScheduleId: workSchedule.id,
            })}
          >
            <Button icon='pi pi-info' />
          </ModalLink>
        </span>
      </div>
    </div>
  )
}

export default AcceptedWorkSchedulesListSp
