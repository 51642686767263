import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, NotFound, ProgressSpinner } from '@medical/components'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ACTIVITY_LOG_DETAIL } from './ActivityDetail.graphql'
import { renderDetails } from './renderDetails'

export const ActivityDetail = ({
  match: {
    params: { activityLogId },
  },
}) => {
  const { i18n } = useTranslation()
  const { loading, error, data } = useQuery(ACTIVITY_LOG_DETAIL, {
    variables: {
      activityLogId,
    },
  })
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent />

  const { activityLog } = data

  if (!activityLog) return <NotFound />

  const { createdAt, doctor, activity, details } = activityLog
  return (
    <div>
      <div className='modal-title'>
        {i18n.t(`staff.activityLogs.${activity}`)}
      </div>
      <div className='modal-table'>
        <div style={{ paddingBottom: '5px' }}>
          {i18n.t('staff.activityLogs.doctor')}:
          <span> {`${doctor.lastname} ${doctor.firstname}`}</span>
        </div>
        <div style={{ paddingBottom: '5px' }}>
          {i18n.t('staff.activityLogs.createdAt')}:
          <span> {moment(createdAt).format('LLL (dd)')}</span>
        </div>
        <div>
          {renderDetails({ i18n, activity, details, doctorId: doctor.id })}
        </div>
      </div>
    </div>
  )
}
