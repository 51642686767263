import AutoComplete from '@medical/components/AutoComplete'
import BreadCrumb from '@medical/components/Breadcrumb'
import ModalLink from '@medical/components/ModalLink'
import Router from '@medical/routes/router'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Paginator } from 'primereact/paginator'
import React from 'react'

import { DOCTORS_CONNECTION } from './ActivityList.graphql'

const ActivityListScene = ({
  i18n,
  client,
  popup,
  edges,
  rowsPerPage,
  page,
  count,
  onPageChange,
  doctors,
  doctorId,
  convertDoctors,
  setDoctorId,
  selectedDoctor,
}) => (
  <React.Fragment>
    <div className='staff-header'>
      <div className='staff-title'>
        {i18n.t('staff.activityLogs.title')}
        <BreadCrumb items={[{ title: i18n.t('staff.activityLogs.title') }]} />
      </div>
    </div>
    <div className='container'>
      <div className='box'>
        <Formik
          initialValues={{
            doctor: selectedDoctor,
            newDoctors: doctors,
          }}
          render={formikProps => {
            const { values, setFieldValue } = formikProps
            return (
              <div className='search-parent'>
                <AutoComplete
                  {...formikProps}
                  name='doctor'
                  field='fullNameEmail'
                  searchFields={['fullName', 'email']}
                  allSuggestions={values.newDoctors}
                  suggestionName='doctors'
                  width='60vw'
                  maxWidth='300px'
                  placeholder='医師を選択してください'
                  setDoctorSelect={async val => {
                    if (typeof val === 'string') {
                      try {
                        const { data: dataConnection } = await client.query({
                          query: DOCTORS_CONNECTION,
                          variables: {
                            where: {
                              AND: [
                                {
                                  OR: [
                                    {
                                      unSigned_contains:
                                        typeof val === 'string'
                                          ? val
                                              .replace(/ /g, '')
                                              .replace(/　/g, '')
                                              .toLowerCase()
                                          : '',
                                    },
                                    {
                                      email_contains: val,
                                    },
                                  ],
                                },
                              ],
                              registrationStatus_in: [
                                'CREATED_BY_STAFF',
                                'ACCEPTED',
                              ],
                            },
                            first: 10,
                            skip: 0,
                            orderBy: 'unSigned_ASC',
                          },
                          fetchPolicy: 'network-only',
                        })
                        setFieldValue(
                          'newDoctors',
                          convertDoctors(dataConnection.doctorsConnection.edges)
                        )
                      } catch (err) {
                        popup.error()
                      }
                    }
                  }}
                  isActivityHistory
                  setDoctorId={setDoctorId}
                />
                {doctorId ? (
                  <Button
                    icon='pi pi-times'
                    className='p-button-secondary'
                    style={{ marginLeft: '20px' }}
                    onClick={() => {
                      setDoctorId('')
                    }}
                  />
                ) : null}
              </div>
            )
          }}
        />
        <div>
          <table className='table' style={{ width: '100%' }}>
            <thead>
              <tr>
                <th width='30%'>{i18n.t('staff.activityLogs.doctor')}</th>
                <th width='40%'>{i18n.t('staff.activityLogs.activity')}</th>
                <th width='30%'>{i18n.t('staff.activityLogs.createdAt')}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {edges.map(({ node }) => (
                <tr key={node.id}>
                  <td>{`${node.doctor.lastname} ${node.doctor.firstname}`}</td>
                  <td>{i18n.t(`staff.activityLogs.${node.activity}`)}</td>
                  <td className='center'>
                    {moment(node.createdAt).format('LLL (dd)')}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <ModalLink
                      to={Router.get(Router.staffActivityLogDetail, {
                        activityLogId: node.id,
                      })}
                    >
                      <Button icon='pi pi-info' />
                    </ModalLink>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Paginator
          rows={rowsPerPage}
          totalRecords={count}
          first={(page - 1) * rowsPerPage}
          onPageChange={onPageChange}
          leftContent={
            <div className='paginator-total'>
              {i18n.t('main.paginationTotal')}: {count}
            </div>
          }
        />
      </div>
    </div>
  </React.Fragment>
)
export default ActivityListScene
