import { DialogCsv } from '@medical/components'
import AutoComplete from '@medical/components/AutoComplete'
import BreadCrumb from '@medical/components/Breadcrumb'
import { waitFor } from '@medical/libs'
import Router from '@medical/routes/router'
import { DatePicker } from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import Axios from 'axios'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Paginator } from 'primereact/paginator'
import queryString from 'query-string'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { DOWNLOAD_CSV_DOCTOR_LOG_HISTORY } from '@medical/constant'
import { DOCTORS_CONNECTION } from './ActivityLogsDoctorHistory.graphql'

const ActivityLogsDoctorHistoryScene = ({
  i18n,
  client,
  popup,
  edges,
  rowsPerPage,
  page,
  count,
  onPageChange,
  doctors,
  doctorId,
  convertDoctors,
  setDoctorId,
  selectedDoctor,
  date,
  token,
  socket,
  progress,
  setProgress,
  removeProgress,
}) => {
  const { RangePicker } = DatePicker
  const [displayDialog, setDisplayDialog] = useState(false)
  const [{ loading, msg }, setState] = useState({
    loading: true,
    msg: null,
  })
  const [dateCsv, setDateCsv] = useState(date)
  const disabledDate = current => {
    const min = moment().subtract(1, 'days')
    const max = moment().add(1, 'years')
    return min.isSameOrBefore(current) && max.isAfter(current)
  }
  const hasProgress = Object.keys(progress).includes(
    DOWNLOAD_CSV_DOCTOR_LOG_HISTORY
  )
  useEffect(() => {
    if (socket && hasProgress) {
      socket.on(DOWNLOAD_CSV_DOCTOR_LOG_HISTORY, data => {
        if (data?.percent) {
          setProgress({
            progress: DOWNLOAD_CSV_DOCTOR_LOG_HISTORY,
            label: `${i18n.t('staff.activityLogsDoctorHistory.title')}中`,
            percent: Number(data.percent),
          })
        }
      })
    }
  }, [socket, hasProgress])
  return (
    <React.Fragment>
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('staff.activityLogsDoctorHistory.title')}
          <BreadCrumb
            items={[{ title: i18n.t('staff.activityLogsDoctorHistory.title') }]}
          />
        </div>

        <div
          className='staff-buttons'
          style={{
            marginTop: '50px',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-evenly',
            width: '27%',
          }}
        >
          <Button
            icon='pi pi-download'
            className='p-button-normal-empty'
            label='指定した期間のデータをダウンロード'
            onClick={async () => {
              setProgress({
                progress: DOWNLOAD_CSV_DOCTOR_LOG_HISTORY,
                label: `${i18n.t('staff.activityLogsDoctorHistory.title')}中`,
                percent: 0,
              })
              try {
                const download = `${
                  process.env.REACT_APP_ENDPOINT
                }/downloadActivityLogsDoctorHistoriesCsv?token=${token}&startDate=${
                  dateCsv[0] ? moment(dateCsv[0]).format('YYYY-MM-DD') : ''
                }&endDate=${
                  dateCsv[1] ? moment(dateCsv[1]).format('YYYY-MM-DD') : ''
                }`
                const { status, data: urlS3 } = await Axios({
                  method: 'GET',
                  url: download,
                })
                if (status === 204) {
                  setProgress({
                    progress: DOWNLOAD_CSV_DOCTOR_LOG_HISTORY,
                    label: `${i18n.t(
                      'staff.activityLogsDoctorHistory.title'
                    )}中`,
                    msg: i18n.t('main.msgDownloadCSVStatusError'),
                  })
                  await waitFor(3000)
                } else if (status === 202) {
                  setProgress({
                    progress: DOWNLOAD_CSV_DOCTOR_LOG_HISTORY,
                    label: `${i18n.t(
                      'staff.activityLogsDoctorHistory.title'
                    )}中`,
                    msg: i18n.t('main.msgDownloadCSVStatusError'),
                  })
                  await waitFor(3000)
                } else {
                  setProgress({
                    progress: DOWNLOAD_CSV_DOCTOR_LOG_HISTORY,
                    percent: 100,
                    url: urlS3,
                  })
                }
              } catch (err) {
                setProgress({
                  progress: DOWNLOAD_CSV_DOCTOR_LOG_HISTORY,
                  msg: i18n.t('main.msgDownloadCSVStatusError'),
                })
              }
              removeProgress(DOWNLOAD_CSV_DOCTOR_LOG_HISTORY)
            }}
          />
        </div>
        <DialogCsv
          onHide={() => setDisplayDialog(false)}
          i18n={i18n}
          loading={loading}
          displayDialog={displayDialog}
          msg={msg}
        />
      </div>
      <div className='container'>
        <div className='box'>
          <div className='search-parent'>
            <Formik
              initialValues={{
                doctor: selectedDoctor,
                newDoctors: doctors,
                doctorId,
                date,
                startDate: null,
                endDate: null,
              }}
              render={formikProps => {
                const { values, setFieldValue } = formikProps
                return (
                  <>
                    <div style={{ display: 'flex' }}>
                      <div style={{ marginRight: '10px' }}>
                        <AutoComplete
                          {...formikProps}
                          name='doctor'
                          field='fullNameEmail'
                          searchFields={['fullName', 'email']}
                          allSuggestions={values.newDoctors}
                          suggestionName='doctors'
                          width='60vw'
                          maxWidth='300px'
                          placeholder='医師を選択してください'
                          setDoctorSelect={async val => {
                            if (typeof val === 'string') {
                              try {
                                const {
                                  data: dataConnection,
                                } = await client.query({
                                  query: DOCTORS_CONNECTION,
                                  variables: {
                                    where: {
                                      AND: [
                                        {
                                          OR: [
                                            {
                                              unSigned_contains:
                                                typeof val === 'string'
                                                  ? val
                                                      .replace(/ /g, '')
                                                      .replace(/　/g, '')
                                                      .toLowerCase()
                                                  : '',
                                            },
                                            {
                                              email_contains: val,
                                            },
                                          ],
                                        },
                                      ],
                                      registrationStatus_in: [
                                        'CREATED_BY_STAFF',
                                        'ACCEPTED',
                                      ],
                                    },
                                    first: 10,
                                    skip: 0,
                                    orderBy: 'unSigned_ASC',
                                  },
                                  fetchPolicy: 'network-only',
                                })
                                setFieldValue(
                                  'newDoctors',
                                  convertDoctors(
                                    dataConnection.doctorsConnection.edges
                                  )
                                )
                              } catch (err) {
                                popup.error()
                              }
                            }
                          }}
                          isActivityHistory
                          setDoctorId={setDoctorId}
                        />
                      </div>
                      <div>
                        <RangePicker
                          size='large'
                          name='date'
                          disabledDate={disabledDate}
                          value={[
                            values.date[0]
                              ? moment.utc(
                                  new Date(values.date[0]),
                                  'YYYY-MM-DD'
                                )
                              : null,
                            values.date[1]
                              ? moment.utc(
                                  new Date(values.date[1]),
                                  'YYYY-MM-DD'
                                )
                              : null,
                          ]}
                          style={{
                            borderColor: '#a6a6a6',
                            borderRadius: '3px',
                            marginRight: '20px',
                          }}
                          placeholder={['開始日', '終了日']}
                          format='YYYY年MM月DD日'
                          allowClear={false}
                          locale={locale}
                          onChange={e => {
                            setDateCsv([
                              moment(e[0]).format('YYYY-MM-DD'),
                              moment(e[1]).format('YYYY-MM-DD'),
                            ])
                            setFieldValue('date', [
                              moment(e[0]).format('YYYY-MM-DD'),
                              moment(e[1]).format('YYYY-MM-DD'),
                            ])
                          }}
                        />
                      </div>

                      <Link
                        to={{
                          pathname: Router.staffActivityLogsListDoctorHistory,
                          search: queryString.stringify({
                            rowsPerPage,
                            date: values.date.join(','),
                          }),
                        }}
                        style={{ marginLeft: '20px' }}
                      >
                        <Button
                          label={i18n.t('main.search')}
                          icon='pi pi-search'
                          style={{ height: '40px' }}
                        />
                      </Link>
                      <Link
                        to={{
                          pathname: Router.staffActivityLogsListDoctorHistory,
                          search: queryString.stringify({
                            rowsPerPage,
                          }),
                        }}
                      >
                        <Button
                          icon='pi pi-times'
                          className='p-button-secondary'
                          style={{ marginLeft: '20px' }}
                          onClick={() => {
                            setDoctorId('')
                            setDateCsv([])
                            setFieldValue('date', [])
                          }}
                        />
                      </Link>
                    </div>
                  </>
                )
              }}
            />
          </div>
          <div>
            <table className='table' style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th width='30%'>
                    {i18n.t('staff.activityLogsDoctorHistory.doctor')}
                  </th>
                  <th width='40%'>
                    {i18n.t('staff.activityLogsDoctorHistory.action')}
                  </th>
                  <th width='30%'>
                    {i18n.t('staff.activityLogsDoctorHistory.createdAt')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {edges.map(({ node }) => (
                  <tr key={node.id}>
                    <td>{`${node.doctor.lastname} ${node.doctor.firstname}`}</td>
                    <td>
                      {i18n.t(
                        `staff.activityLogsDoctorHistory.${node.activity}`
                      )}
                    </td>
                    <td className='center'>
                      {moment(node.createdAt).format('LLL (dd)')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Paginator
            rows={rowsPerPage}
            totalRecords={count}
            first={(page - 1) * rowsPerPage}
            onPageChange={onPageChange}
            leftContent={
              <div className='paginator-total'>
                {i18n.t('main.paginationTotal')}: {count}
              </div>
            }
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default ActivityLogsDoctorHistoryScene
