import { useApolloClient, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { useCustom } from '@medical/provider/context'
import Router from '@medical/routes/router'
import downloadCsv from 'download-csv'
import moment from 'moment'
import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'

import { DOCTORS_CONNECTION } from '../DoctorsList/DoctorsList.graphql'
import { YEARLY_DOCTOR_INCENTIVE_REPORT } from './PatientSatisfactionByYear.graphql'
import PatientSatisfactionByYearScene from './PatientSatisfactionByYearScene'

const PatientSatisfactionByYear = ({
  history,
  match: {
    path,
    params: { id, year = moment().year() },
  },
}) => {
  const [{ i18n, popup }] = useCustom()
  const date = moment().year(year)
  const { loading, error, data, refetch } = useQuery(
    YEARLY_DOCTOR_INCENTIVE_REPORT,
    {
      variables: {
        doctorId: id,
        date: new Date(moment(year).format('YYYY-MM-DD')),
      },
    }
  )
  useEffect(() => {
    refetch()
  })
  const client = useApolloClient()

  const {
    data: doctorConnection,
    loading: loadingDoctorConnection,
    error: errorDoctorConnection,
  } = useQuery(DOCTORS_CONNECTION, {
    variables: {
      where: {
        id,
      },
    },
  })

  if ([Router.staffPatientSatisfactionResultsByYearWithoutYear].includes(path))
    return (
      <Redirect
        to={Router.get(Router.staffPatientSatisfactionResultsByYear, {
          id,
          year: moment().year(),
        })}
      />
    )

  if (loading || loadingDoctorConnection) return <ProgressSpinner />
  if (error || errorDoctorConnection) return <ErrorComponent error={error} />

  let doctorName = ''
  if (doctorConnection.doctorsConnection) {
    const doctorNode = doctorConnection.doctorsConnection.edges[0]
    doctorName = doctorNode ? doctorNode.node.fullName : ''
  }

  const numberRound = number => Math.round(number * 100) / 100

  const download = async () => {
    try {
      const {
        data: { yearlyDoctorIncentiveReport },
      } = await client.query({
        query: YEARLY_DOCTOR_INCENTIVE_REPORT,
        variables: {
          doctorId: id,
          date: moment(year).format('YYYY-MM-DD'),
        },
      })
      const dataByYear = yearlyDoctorIncentiveReport.map(
        ({
          date: time,
          doctorClinic,
          doctorDepartment,
          field1,
          field2,
          field3,
          field4,
          field5,
          field6,
          field7,
          field8,
          field9,
          field10,
          field11,
          field12,
          field13,
          field14,
        }) => ({
          date: moment(time).month() + 1,
          doctorClinic,
          doctorDepartment,
          field1: numberRound(field1),
          field2: numberRound(field2),
          field3: numberRound(field3),
          field4: numberRound(field4),
          field5: numberRound(field5),
          field6: numberRound(field6),
          field7: numberRound(field7),
          field8: numberRound(field8),
          field9: numberRound(field9),
          field10: numberRound(field10),
          field11: numberRound(field11),
          field12: numberRound(field12),
          field13: numberRound(field13),
          field14: numberRound(field14),
        })
      )
      await downloadCsv(
        dataByYear,
        {
          date: i18n.t('patientSatisfactionByYearMonth.month'),
          doctorClinic: i18n.t('patientSatisfactionByYearMonth.clinic'),
          doctorDepartment: i18n.t('patientSatisfactionByYearMonth.department'),
          field1: i18n.t('patientSatisfactionByYearMonth.case1'),
          field2: i18n.t('patientSatisfactionByYearMonth.case2'),
          field3: i18n.t('patientSatisfactionByYearMonth.case3'),
          field4: i18n.t('patientSatisfactionByYearMonth.case4'),
          field5: i18n.t('patientSatisfactionByYearMonth.case5'),
          field6: i18n.t('patientSatisfactionByYearMonth.case6'),
          field7: i18n.t('patientSatisfactionByYearMonth.case7'),
          field8: i18n.t('patientSatisfactionByYearMonth.case8'),
          field9: i18n.t('patientSatisfactionByYearMonth.case9'),
          field10: i18n.t('patientSatisfactionByYearMonth.case10'),
          field11: i18n.t('patientSatisfactionByYearMonth.case11'),
          field12: i18n.t('patientSatisfactionByYearMonth.case12'),
          field13: i18n.t('patientSatisfactionByYearMonth.case13'),
          field14: i18n.t('patientSatisfactionByYearMonth.case14'),
        },
        `${i18n.t('staff.menuBar.patientSatisfactionResults')}${moment().format(
          'YYYYMMDDHHmm'
        )}.csv`
      )
    } catch (error) {
      popup.error(i18n.t('main.downloadFileError'))
    }
  }

  return (
    <PatientSatisfactionByYearScene
      i18n={i18n}
      doctorId={id}
      date={date}
      history={history}
      yearlyDoctorIncentiveReports={data.yearlyDoctorIncentiveReport}
      doctorName={doctorName}
      download={download}
    />
  )
}

export default PatientSatisfactionByYear
