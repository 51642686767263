import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import ClinicNameRender from '@medical/components/ClinicNameRender'
import { DOCTOR_ME } from '@medical/layout/DoctorLayout/DoctorLayoutRequest.graphql'
import {
  clinicalDepartmentNames,
  combineNames,
  sortAndFilterByClinicOrder,
  onPageChange,
} from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import {
  addItem,
  removeItem,
} from '@medical/provider/store/reducers/cart/cart.action'
import moment from 'moment'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { DOCTOR_APPLY_AVAILABLE_SHIFTS } from './RequestedAvailableShiftsList.graphql'
import RequestedAvailableShiftsListScene from './RequestedAvailableShiftsListScene'

const RequestedAvailableShiftsList = ({
  addItemToCart,
  removeItemFromCart,
  cartItems,
  history,
  location: { search },
}) => {
  const [{ i18n, popup }] = useCustom()
  const {
    clinicalDepartmentIds = [],
    page = 1,
    rowsPerPage = 10,
  } = queryString.parse(search)
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  const [date, setDate] = useState([null, null])
  const { loading, error, data, refetch } = useQuery(
    DOCTOR_APPLY_AVAILABLE_SHIFTS,
    {
      variables: {
        startDate: date[0],
        endDate: date[1],
        first: first < 0 ? 10 : first,
        skip: skip < 0 ? 0 : skip,
        clinicalDepartmentId:
          clinicalDepartmentIds.length > 0 ? clinicalDepartmentIds : undefined,
      },
    }
  )
  const {
    loading: doctorDataLoading,
    error: doctorDataError,
    data: doctorData,
  } = useQuery(DOCTOR_ME)

  useEffect(() => {
    refetch()
  })

  if (loading || doctorDataLoading) return <ProgressSpinner />
  if (error || doctorDataError) return <ErrorComponent error={error} />
  const { lastLoggedIn, blocked } = doctorData.doctorMe
  const availableShifts = data.doctorGetCanApplyAvailableShifts
  const { clinicalDepartments } = data
  const departmentNames = clinicalDepartmentNames({
    departments: data.departments,
  })
  const onDropdownChange = e => {
    history.push({
      search: queryString.stringify({
        clinicalDepartmentIds: e ? e.value.map(({ id }) => id) : undefined,
      }),
    })
    refetch()
  }
  const sortedClinicalDepartments = sortAndFilterByClinicOrder({
    clinicalDepartments,
    isFilter: true,
  })
  const resultAvailableShifts = []
  availableShifts.map(as => {
    resultAvailableShifts.push({
      id: as.id,
      clinicName: ClinicNameRender({
        clinicalDepartment: as.clinicalDepartment,
      }),
      createdAt: as.createdAt,
      updatedAt: as.updatedAt,
      clinicalDepartmentName: departmentNames[as.clinicalDepartment.name],
      clinicalDepartment: as.clinicalDepartment,
      startTime: as.startTime,
      endTime: as.endTime,
      dailySalary: as.dailySalary,
      comment: as.comment,
      isHourlyWageUpdated:
        moment(as.updatedHourlyWageAt) - moment(lastLoggedIn) ? 1 : 0,
      isSpecial: as.isSpecial,
    })
    return resultAvailableShifts
  })
  return (
    <RequestedAvailableShiftsListScene
      i18n={i18n}
      availableShifts={blocked ? [] : resultAvailableShifts}
      date={date}
      setDate={e => {
        setDate(e)
      }}
      rowsPerPage={rowsPerPage}
      page={page}
      count={availableShifts[0]?.count || 0}
      onPageChange={onPageChange(history, search)}
      lastLoggedIn={lastLoggedIn}
      cartItems={cartItems}
      clinicalDepartments={combineNames({
        clinicalDepartments: sortedClinicalDepartments,
        departmentNames,
      })}
      clinicalDepartmentIds={clinicalDepartmentIds}
      onDropdownChange={onDropdownChange}
      addItemToCart={item => {
        popup.success('募集シフトをカートに追加しました')
        addItemToCart(item)
      }}
      removeItemFromCart={item => {
        popup.warn('募集シフトをカートから削除しました')
        removeItemFromCart(item)
      }}
    />
  )
}

const mapStateToProps = state => ({
  cartItems: state.cartStore.items,
})

const mapDispatchToProps = dispatch => ({
  addItemToCart: item => dispatch(addItem(item)),
  removeItemFromCart: availableShiftId =>
    dispatch(removeItem(availableShiftId)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestedAvailableShiftsList)
