/* eslint-disable no-irregular-whitespace */
import { useMutation, useQuery } from '@apollo/react-hooks'
import { ProgressSpinner } from '@medical/components'
import { checkStaffPermission, onPageChange } from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import moment from 'moment'
import queryString from 'query-string'
import React, { useContext, useEffect } from 'react'
import {
  STAFF_UPDATE_INCENTIVE_DETAIL_PERMISSIONS,
  STAFF_DELETE_INCENTIVE_DETAIL_PERMISSIONS,
} from '@medical/constant/permissions'
import {
  DELETE_INCENTIVE_DETAIL,
  GET_INCENTIVE,
  CREATE_ACTIVITY,
} from './ListOfIncentives.graphql'
import ListOfIncentivesScene from './ListOfIncentivesScene'
import { connect } from 'react-redux'
import {
  removeProgress,
  setProgressBar,
} from '@medical/provider/store/reducers/progress/progress.action'
import { SocketContext } from '@medical/provider/socket'

const ListOfIncentives = ({
  history,
  location: { search },
  progress,
  setProgress,
  removeProgress,
}) => {
  const socket = useContext(SocketContext)
  const [{ i18n, popup }] = useCustom()
  const IncentiveType = [
    'NORMAL_INCENTIVE',
    'NUMBER_INCENTIVE',
    'BEYOND_EIGHT_INCENTIVE',
    'TIME_INCENTIVE',
    'SATISFACTION_INCENTIVE',
  ]
  const IncentiveApplyType = [
    'NEW_DOCTOR',
    'SPECIFY_DOCTORS',
    'ALL_DOCTORS',
    'WORK_PATTERN_APPLIED',
  ]
  let {
    page = 1,
    rowsPerPage = 20,
    orderBy = 'createdAt_DESC',
    name,
    type,
    title,
    year,
    month,
  } = queryString.parse(search)
  if (!year || !month) {
    year = moment().year()
    month = moment().month() + 1
  }
  const isUpdatePermitted = checkStaffPermission({
    functionName: STAFF_UPDATE_INCENTIVE_DETAIL_PERMISSIONS,
  })
  const isDeletePermitted = checkStaffPermission({
    functionName: STAFF_DELETE_INCENTIVE_DETAIL_PERMISSIONS,
  })
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  const applyTime = moment()
    .utc()
    .year(year)
    .month(month - 1)
    .startOf('month')
  const incentiveTypeSearch = () => {
    if (
      moment()
        .subtract(2, 'month')
        .isSameOrAfter(applyTime)
    ) {
      return [
        'NORMAL_INCENTIVE',
        'NUMBER_INCENTIVE',
        'BEYOND_EIGHT_INCENTIVE',
        'TIME_INCENTIVE',
        'SATISFACTION_INCENTIVE',
      ]
    }
    if (
      moment()
        .subtract(1, 'month')
        .year() === applyTime.year() &&
      moment()
        .subtract(1, 'month')
        .month() === applyTime.month()
    ) {
      if (moment().date() >= 15) {
        return [
          'NORMAL_INCENTIVE',
          'NUMBER_INCENTIVE',
          'BEYOND_EIGHT_INCENTIVE',
          'TIME_INCENTIVE',
          'SATISFACTION_INCENTIVE',
        ]
      }
      return ['NORMAL_INCENTIVE']
    }
    return ['NORMAL_INCENTIVE']
  }
  const variables = {
    first: first < 0 ? 10 : first,
    skip: skip < 0 ? 0 : skip,
    orderBy,
    where: {
      incentiveTotalAmount_gte: 1,
      applyTime,
      AND: {
        Doctor: {
          OR: [
            {
              unSigned_contains: name
                ? name
                    .replace(/ /g, '')
                    .replace(/　/g, '')
                    .toLowerCase()
                : '',
            },
          ],
          deletedAt: null,
        },
        Incentive: {
          AND: type
            ? {
                type,
                title,
              }
            : {
                type_in: incentiveTypeSearch(),
                title,
              },
        },
      },
    },
  }
  const { loading, data, refetch } = useQuery(GET_INCENTIVE, {
    variables,
    fetchPolicy: 'network-only',
  })
  useEffect(() => {
    refetch()
  }, [])
  const [deleteIncentive] = useMutation(DELETE_INCENTIVE_DETAIL)
  const [staffCreateActivity] = useMutation(CREATE_ACTIVITY)
  const onSubmit = async (
    { incentiveDetailId, doctorId },
    { setSubmitting, resetForm }
  ) => {
    try {
      await deleteIncentive({
        variables: {
          incentiveDetailId,
        },
      })
      staffCreateActivity({
        variables: {
          activity: 'STAFF_DELETE_INCENTIVE_GRANT',
          doctorId: doctorId,
        },
      })
      refetch()
      popup.success('成功しました')
      setSubmitting(false)
      resetForm()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
      resetForm()
    }
  }
  if (loading) return <ProgressSpinner />
  // if (error) return <ErrorComponent error={error} />
  if (data && data.incentiveDetailsConnection) {
    data.incentiveDetailsConnection.edges.map(as => {
      if (IncentiveType.includes(as.node.incentive.type))
        as.node.incentive.type = i18n.t(
          `main.IncentiveType.${as.node.incentive.type}`
        )
    })
  }

  return (
    <ListOfIncentivesScene
      socket={socket}
      progressList={progress}
      setProgress={progress => setProgress(progress)}
      removeProgress={progress => removeProgress(progress)}
      IncentiveType={IncentiveType}
      IncentiveApplyType={IncentiveApplyType}
      i18n={i18n}
      list={
        data && data.incentiveDetailsConnection
          ? data.incentiveDetailsConnection.edges
          : []
      }
      history={history}
      onPageChange={onPageChange(history, search)}
      count={
        data && data.incentiveDetailsConnection
          ? data.incentiveDetailsConnection.aggregate.count
          : 0
      }
      rowsPerPage={parseInt(rowsPerPage, 10)}
      page={parseInt(page, 10)}
      nameS={name}
      incentiveS={type}
      titleS={title}
      applyTime={applyTime}
      onSubmit={onSubmit}
      isUpdatePermitted={isUpdatePermitted}
      isDeletePermitted={isDeletePermitted}
      year={year}
      month={month}
    />
  )
}
const mapStateToProps = state => ({
  progress: state.progressBarStore.progress,
})

const mapDispatchToProps = dispatch => ({
  setProgress: progress => dispatch(setProgressBar(progress)),
  removeProgress: progress => dispatch(removeProgress(progress)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ListOfIncentives)
