import { ConfirmationModal } from '@medical/components'
import BreadCrumb from '@medical/components/Breadcrumb'
import ModalLink from '@medical/components/ModalLink'
import Router from '@medical/routes/router'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { Paginator } from 'primereact/paginator'
import queryString from 'query-string'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Checkbox } from 'antd'

const ListMovingExpensesManagementScene = ({
  i18n,
  listMovingExpenses,
  page,
  rowsPerPage,
  onPageChange,
  count,
  fullname,
  email,
  onSubmit,
  isRestorePermitted,
  isBlockedMyCar,
}) => {
  const [mailFilter, setMailFilter] = useState(email || '')
  const [nameFilter, setNameFilter] = useState(fullname || '')
  const isBlockedMyCarPattern = [
    { status: '許可', value: true },
    { status: '拒否', value: false },
  ]
  const [isBlockedMyCarFilter, setIsBlockedMyCarFilter] = useState(
    isBlockedMyCar !== undefined
      ? isBlockedMyCarPattern.find(item => item.value === isBlockedMyCar)
      : undefined
  )
  const listFilter = listMovingExpenses || []
  const CustomBody = ({ handleChange }) => (
    <div className='mt-10'>
      <Checkbox onChange={event => handleChange(event)}>
        マイカー通勤距離更新
      </Checkbox>
    </div>
  )

  return (
    <div
      style={{
        padding: '10px',
        height: '100%',
      }}
    >
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('staff.menuBar.doctorTransportByPersonalCarManagement')}
          <BreadCrumb
            items={[
              {
                title: i18n.t(
                  'staff.menuBar.doctorTransportByPersonalCarManagement'
                ),
              },
            ]}
          />
        </div>
        <ModalLink to={Router.get(Router.staffGasolinePrice)}>
          <Button
            icon='pi pi-pencil'
            className='p-button-normal'
            label={i18n.t('main.movingExpenses.gasolineSetting')}
          />
        </ModalLink>
      </div>
      <div
        className='box'
        style={{
          backgroundColor: '#ffffff',
          marginLeft: '20px',
          marginRight: '20px',
          padding: '20px',
          borderRadius: '10px',
        }}
      >
        <div className='search-parent'>
          <InputText
            value={nameFilter}
            name='fullname'
            placeholder={i18n.t('main.fullname')}
            onChange={e => {
              setNameFilter(e.target.value)
            }}
            style={{ width: '170px', marginRight: '20px' }}
          />
          <InputText
            value={mailFilter}
            name='fullname'
            placeholder={i18n.t('main.movingExpenses.mail')}
            onChange={e => {
              setMailFilter(e.target.value)
            }}
            style={{ width: '170px', marginRight: '20px' }}
          />
          <div style={{ width: '170px', marginRight: '20px' }}>
            <Dropdown
              name='isBlockedMyCar'
              options={isBlockedMyCarPattern}
              className='drop-down-select-workpartten-search'
              optionLabel='status'
              valueLabel='value'
              value={isBlockedMyCarFilter}
              placeholder={i18n.t('main.movingExpenses.blockMyCar')}
              onChange={event => {
                setIsBlockedMyCarFilter(event.target.value)
              }}
            />
          </div>
          <Link
            to={{
              pathname: Router.staffManageDoctorTransportByPersonalCar,
              search: queryString.stringify({
                rowsPerPage,
                fullname: nameFilter,
                email: mailFilter,
                isBlockedMyCar: isBlockedMyCarFilter
                  ? isBlockedMyCarFilter.value
                  : undefined,
              }),
            }}
            style={{ marginLeft: '20px' }}
          >
            <Button
              label={i18n.t('main.movingExpenses.search')}
              icon='pi pi-search'
            />
          </Link>

          {fullname || email || isBlockedMyCar !== undefined ? (
            <Link
              to={{
                pathname: Router.staffManageDoctorTransportByPersonalCar,
                search: queryString.stringify({
                  rowsPerPage,
                }),
              }}
            >
              <Button
                icon='pi pi-times'
                className='p-button-secondary'
                style={{ marginLeft: '20px' }}
              />
            </Link>
          ) : null}
        </div>
        <div>
          <div>
            <Formik
              initialValues={{
                confirm: false,
                doctor: '',
                isBlockedMyCar: false,
                email: '',
                isReCalculateMyCar: false,
              }}
              onSubmit={onSubmit}
              render={formikProps => {
                const { values, setFieldValue } = formikProps
                return (
                  <>
                    <table className='table-moving-expenses'>
                      <thead>
                        <tr>
                          <th width='12%'>
                            {i18n.t('main.movingExpenses.fullname')}
                          </th>
                          <th width='15%'>
                            {i18n.t('main.movingExpenses.mail')}
                          </th>
                          <th width='12%'>
                            {i18n.t('main.movingExpenses.addressUpdateAt')}
                          </th>
                          <th width='12%'>
                            {i18n.t('main.movingExpenses.effectiveDate')}
                          </th>
                          <th width='18%'>
                            {i18n.t('main.movingExpenses.address')}
                          </th>
                          <th width='12%'>
                            {i18n.t('main.movingExpenses.placeOfWork')}
                          </th>
                          <th width='12%'>
                            {i18n.t('main.movingExpenses.nearestStation')}
                          </th>
                          <th width='12%'>
                            {i18n.t('main.movingExpenses.detail')}
                          </th>
                          <th width='12%'>
                            {i18n.t('main.movingExpenses.blockMyCar')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {listFilter && listFilter.length > 0
                          ? listFilter.map(({ node }) => (
                              <tr
                                key={node.id}
                                className={
                                  !node.isBlockedMyCar ? 'table-block' : ''
                                }
                              >
                                <td>{`${node.lastname} ${node.firstname}`}</td>
                                <td>
                                  <a href={`mailto:${node.email}`}>
                                    {node.email}
                                  </a>
                                </td>
                                <td>
                                  {node.homeAddress &&
                                  node.homeAddress.updatedAt
                                    ? moment(node.homeAddress.updatedAt).format(
                                        'LL'
                                      )
                                    : ''}
                                </td>
                                <td>
                                  {node.homeAddress &&
                                  node.homeAddress.applicableDate
                                    ? moment(
                                        node.homeAddress.applicableDate
                                      ).format('LL')
                                    : ''}
                                </td>
                                <td>
                                  {(node.homeAddress &&
                                  node.homeAddress.address1
                                    ? node.homeAddress.address1
                                    : '') +
                                    (node.homeAddress &&
                                    node.homeAddress.address2
                                      ? `, ${node.homeAddress.address2}`
                                      : '')}
                                </td>
                                <td>{node.placeOfWork}</td>
                                <td>
                                  {node.nearestStations
                                    ? node.nearestStations.join(', ')
                                    : ''}
                                </td>
                                <td>
                                  <Link
                                    to={{
                                      pathname: Router.get(
                                        Router.staffManageDoctorTransportByCarDetail,
                                        {
                                          id: node.id,
                                        }
                                      ),
                                      state:
                                        (node.homeAddress &&
                                        node.homeAddress.address1
                                          ? node.homeAddress.address1
                                          : '') +
                                        (node.homeAddress &&
                                        node.homeAddress.address2
                                          ? `, ${node.homeAddress.address2}`
                                          : ''),
                                    }}
                                  >
                                    <Button icon='pi pi-pencil' />
                                  </Link>
                                </td>
                                <td>
                                  {!node.isBlockedMyCar ? (
                                    <Button
                                      onClick={async () => {
                                        await setFieldValue('doctorId', node.id)
                                        await setFieldValue('email', node.email)
                                        await setFieldValue(
                                          'isBlockedMyCar',
                                          true
                                        )
                                        await setFieldValue('confirm', true)
                                      }}
                                      icon='pi pi-check-circle'
                                    />
                                  ) : null}
                                  {node.isBlockedMyCar && isRestorePermitted ? (
                                    <Button
                                      onClick={async () => {
                                        await setFieldValue('doctorId', node.id)
                                        await setFieldValue('email', node.email)
                                        await setFieldValue(
                                          'isBlockedMyCar',
                                          false
                                        )
                                        await setFieldValue('confirm', true)
                                      }}
                                      className='p-button-danger'
                                      icon='pi pi-ban'
                                    />
                                  ) : null}
                                </td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </table>
                    <ConfirmationModal
                      {...formikProps}
                      confirmationMessage={
                        !values.isBlockedMyCar
                          ? 'マイカー利用を制限してよろしいですか？'
                          : 'マイカー利用制限を解除してよろしいでしょうか？'
                      }
                      customBody={
                        values.isBlockedMyCar ? (
                          <CustomBody
                            handleChange={event =>
                              setFieldValue(
                                'isReCalculateMyCar',
                                event.target.checked
                              )
                            }
                          />
                        ) : null
                      }
                    />
                  </>
                )
              }}
            />
            <Paginator
              rows={rowsPerPage}
              totalRecords={count}
              first={(page - 1) * rowsPerPage}
              onPageChange={onPageChange}
              leftContent={
                <div className='paginator-total'>
                  {i18n.t('main.paginationTotal')}: {count}
                </div>
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListMovingExpensesManagementScene
