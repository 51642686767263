import { useApolloClient, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { onPageChange } from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import queryString from 'query-string'
import React, { useState } from 'react'

import {
  ACTIVITY_LOGS_CONNECTION,
  DOCTOR_QUERY,
  DOCTORS_CONNECTION,
} from './ActivityList.graphql'
import ActivityListScene from './ActivityListScene'

const ActivityList = ({ history, location: { search } }) => {
  const [{ i18n, popup }] = useCustom()
  const client = useApolloClient()
  const {
    page = 1,
    rowsPerPage = 20,
    orderBy = 'createdAt_DESC',
  } = queryString.parse(search)
  const [doctorId, setDoctorId] = useState('')
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  let variables = {
    first: first < 0 ? 20 : first,
    skip: skip < 0 ? 0 : skip,
    orderBy,
    where: {
      activity_in: [
        'UPDATED_PROFILE',
        'UPDATED_BANK',
        'CREATED_INQUIRY',
        'CREATED_WORK_SCHEDULE',
        'CANCELED_WORK_SCHEDULE',
        'UPDATED_FILE',
      ],
    },
  }

  if (doctorId)
    variables = {
      first: first < 0 ? 20 : first,
      skip: skip < 0 ? 0 : skip,
      orderBy,
      where: {
        AND: [
          {
            Doctor: {
              id: doctorId,
              deletedAt: null,
            },
          },
        ],
        activity_in: [
          'UPDATED_PROFILE',
          'UPDATED_BANK',
          'CREATED_INQUIRY',
          'CREATED_WORK_SCHEDULE',
          'CANCELED_WORK_SCHEDULE',
          'UPDATED_FILE',
        ],
      },
    }
  const doctorsVar = {
    orderBy: 'unSigned_ASC',
    skip: 0,
    first: 10,
  }

  const { loading, error, data } = useQuery(ACTIVITY_LOGS_CONNECTION, {
    variables,
    fetchPolicy: 'network-only',
  })
  const {
    loading: uniqueDoctorLoading,
    error: uniqueDoctorError,
    data: uniqueDoctorData,
  } = useQuery(DOCTOR_QUERY, {
    variables: {
      where: {
        id: doctorId,
      },
    },
    fetchPolicy: 'network-only',
  })
  const {
    loading: doctorLoading,
    error: doctorError,
    data: doctorData,
  } = useQuery(DOCTORS_CONNECTION, {
    variables: doctorsVar,
    fetchPolicy: 'network-only',
  })

  if (loading || doctorLoading || uniqueDoctorLoading)
    return <ProgressSpinner />
  if (error || doctorError || uniqueDoctorError)
    return <ErrorComponent error={error || doctorError || uniqueDoctorError} />

  const {
    activityLogsConnection: {
      edges,
      aggregate: { count },
    },
  } = data

  const {
    doctorsConnection: { edges: doctors },
  } = doctorData
  const convertDoctors = doctors =>
    doctors.map(doctor => ({
      ...doctor.node,
      fullNameEmail: doctor.node.blocked
        ? `【募集制限中】${doctor.node.fullName} (${doctor.node.email})`
        : `${doctor.node.fullName} (${doctor.node.email})`,
    }))
  const convertSelectedDoctor = doctor =>
    doctor.blocked
      ? `【募集制限中】${doctor.lastname} ${doctor.firstname} (${doctor.email})`
      : `${doctor.lastname} ${doctor.firstname} (${doctor.email})`
  return (
    <ActivityListScene
      i18n={i18n}
      edges={edges}
      doctors={convertDoctors(doctors)}
      rowsPerPage={parseInt(rowsPerPage, 10)}
      page={page}
      count={count}
      doctorId={doctorId}
      selectedDoctor={
        uniqueDoctorData.doctor
          ? convertSelectedDoctor(uniqueDoctorData.doctor)
          : ''
      }
      onPageChange={onPageChange(history, search)}
      client={client}
      popup={popup}
      convertDoctors={convertDoctors}
      setDoctorId={setDoctorId}
    />
  )
}

export default ActivityList
