import BreadCrumb from '@medical/components/Breadcrumb'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import ModalLink from '@medical/components/ModalLink'
import Router from '@medical/routes/router'
import { Formik } from 'formik'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Paginator } from 'primereact/paginator'
import queryString from 'query-string'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Auth from '@medical/middleware/auth'
import Axios from 'axios'
import { waitFor } from '@medical/libs'
import { DOWNLOAD_CSV_STAFF_LIST } from '@medical/constant'

const StaffsListScene = ({
  i18n,
  page,
  rowsPerPage,
  fullname,
  onPageChange,
  edges,
  count,
  onSubmit,
  isCreatePermitted,
  isDeletePermitted,
  isUpdatePermitted,
  socket,
  progress,
  setProgress,
  removeProgress,
}) => {
  const hasProgress = Object.keys(progress).includes(DOWNLOAD_CSV_STAFF_LIST)
  useEffect(() => {
    if (socket && hasProgress) {
      socket.on(DOWNLOAD_CSV_STAFF_LIST, data => {
        if (data?.percent) {
          setProgress({
            progress: DOWNLOAD_CSV_STAFF_LIST,
            label: `${i18n.t('staff.menuBar.staffs')}中`,
            percent: Number(data.percent),
          })
        }
      })
    }
  }, [socket, hasProgress])

  return (
    <React.Fragment>
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('staff.menuBar.staffs')}
          <BreadCrumb items={[{ title: i18n.t('staff.menuBar.staffs') }]} />
        </div>
        <div className='staff-buttons'>
          <Button
            icon='pi pi-download'
            className='p-button-normal'
            label='CSVダウンロード'
            onClick={async () => {
              const token = Auth.getToken()
              try {
                setProgress({
                  progress: DOWNLOAD_CSV_STAFF_LIST,
                  label: `${i18n.t('staff.menuBar.staffs')}中`,
                  percent: 0,
                })
                const url = `${process.env.REACT_APP_ENDPOINT}/downloadStaffs?token=${token}`
                const { status, data: urlS3 } = await Axios({
                  method: 'GET',
                  url,
                })
                await waitFor(3000)
                if (status === 204) {
                  setProgress({
                    progress: DOWNLOAD_CSV_STAFF_LIST,
                    label: `${i18n.t('staff.menuBar.staffs')}中`,
                    msg: i18n.t('main.msgDownloadCSVStatusError'),
                  })
                  await waitFor(3000)
                } else {
                  setProgress({
                    progress: DOWNLOAD_CSV_STAFF_LIST,
                    percent: 100,
                    url: urlS3,
                  })
                }
              } catch (err) {
                setProgress({
                  progress: DOWNLOAD_CSV_STAFF_LIST,
                  label: `${i18n.t('staff.menuBar.staffs')}中`,
                  msg: i18n.t('main.msgDownloadCSVStatusError'),
                })
                await waitFor(3000)
              }
              removeProgress(DOWNLOAD_CSV_STAFF_LIST)
            }}
          />
          {isCreatePermitted ? (
            <ModalLink to={Router.get(Router.staffStaffCreate)}>
              <Button
                icon='pi pi-plus-circle'
                className='p-button-normal'
                label={i18n.t('staff.createStaff.submit')}
              />
            </ModalLink>
          ) : null}
        </div>
      </div>
      <div className='container is-max'>
        <div className='box'>
          <Formik
            initialValues={{
              fullname,
            }}
            render={formikProps => {
              const { values, handleBlur, handleChange } = formikProps
              return (
                <div className='search-parent'>
                  <InputText
                    name='fullname'
                    value={values.fullname}
                    placeholder={i18n.t('main.fullname')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ width: '200px', marginRight: '20px' }}
                  />
                  <Link
                    to={{
                      pathname: Router.staffStaffs,
                      search: queryString.stringify({
                        rowsPerPage,
                        fullname: values.fullname,
                      }),
                    }}
                  >
                    <Button label={i18n.t('main.search')} icon='pi pi-search' />
                  </Link>
                  {fullname ? (
                    <Link
                      to={{
                        pathname: Router.staffStaffs,
                        search: queryString.stringify({
                          rowsPerPage,
                        }),
                      }}
                    >
                      <Button
                        icon='pi pi-times'
                        className='p-button-secondary'
                        style={{ marginLeft: '10px' }}
                      />
                    </Link>
                  ) : null}
                </div>
              )
            }}
          />
          <Formik
            initialValues={{
              confirm: false,
              staffId: '',
            }}
            onSubmit={onSubmit}
            render={formikProps => {
              const { setFieldValue } = formikProps
              return (
                <div>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th width='30%'>{i18n.t('updateProfile.fullname')}</th>
                        <th width='30%'>{i18n.t('updateProfile.email')}</th>
                        <th width='15%'>
                          {i18n.t('updateProfile.staffGroup')}
                        </th>
                        <th width='15%'>{i18n.t('updateProfile.position')}</th>
                        {isDeletePermitted || isUpdatePermitted ? (
                          <th width='10%'>{i18n.t('main.action')}</th>
                        ) : null}
                      </tr>
                    </thead>
                    <tbody>
                      {edges.map(({ node }) => (
                        <tr key={node.id}>
                          <td>{`${node.lastname} ${node.firstname}`}</td>
                          <td>{node.email}</td>
                          <td>{node.staffGroup ? node.staffGroup.name : ''}</td>
                          <td>{node.position}</td>
                          {isDeletePermitted || isUpdatePermitted ? (
                            <td>
                              <div className='action'>
                                {isUpdatePermitted ? (
                                  <ModalLink
                                    to={Router.get(Router.staffStaffDetail, {
                                      staffId: node.id,
                                    })}
                                  >
                                    <Button icon='pi pi-info' />
                                  </ModalLink>
                                ) : null}
                                {isDeletePermitted ? (
                                  <Button
                                    onClick={() => {
                                      setFieldValue('staffId', node.id)
                                      setFieldValue('confirm', true)
                                    }}
                                    icon='pi pi-trash'
                                  />
                                ) : null}
                              </div>
                            </td>
                          ) : null}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <ConfirmationModal
                    {...formikProps}
                    confirmationMessage={i18n.t(
                      'staff.deleteStaff.submitConfirmation'
                    )}
                  />
                  <Paginator
                    rows={rowsPerPage}
                    totalRecords={count}
                    first={(page - 1) * rowsPerPage}
                    onPageChange={onPageChange}
                    leftContent={
                      <div className='paginator-total'>
                        {i18n.t('main.paginationTotal')}: {count}
                      </div>
                    }
                  />
                </div>
              )
            }}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default StaffsListScene
