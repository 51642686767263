import moment from 'moment'
import React, { useState } from 'react'
import { Button } from 'primereact/button'
import { Col, Modal, Row, Select } from 'antd'
import './style.css'
import types from '@medical/constant/typeTransports'
import { Link } from 'react-router-dom'
import { PreviewImgDialog } from '@medical/components/PreviewImgDialog'

const TransportReportsSceneMobile = ({
  i18n,
  split,
  setFieldValue,
  isDisable,
  isBlockedMyCar,
  hideModal,
  visible,
  showModal,
  openImgDialog,
  handleOpenImgDialog,
}) => {
  // const [toggleItem, setToggleItem] = useState([])
  const [dataImg, setDataImg] = useState()
  const Option = Select.Option
  const getType = val => {
    let rs

    types.map(as => {
      if (as.value == val) {
        rs = as.description
      }
    })
    return rs
  }
  return (
    <>
      <div
        className='mobile-report'
        style={{ marginBottom: '20px', paddingBottom: '10px' }}
      >
        <Row className='mobile-report-row'>
          <div style={{ fontWeight: 'bold' }}>
            {i18n.t('transportApplication.date')}
            <span style={{ padding: '0 5px' }}> : </span>
          </div>
          <div>
            <span>
              {!split.isTransport || split.category
                ? moment(split.startTime).format('LL (dd)')
                : !split.startTime
                ? moment(split.endTime).format('LL (dd)')
                : null}
            </span>
            {!split.isTransport ? null : !split.startTime ? (
              <p>'(事務局入力)'</p>
            ) : null}
          </div>
        </Row>

        <Row className='mobile-report-row'>
          <div style={{ fontWeight: 'bold' }}>
            {i18n.t('勤務先')}
            <span style={{ padding: '0 5px' }}> : </span>
          </div>
          <div>
            {split.category ? split.clinicDate : split.clinic} -{' '}
            {split.clinicalDepartment}
            {/* {split.costTransportationByDoctorWorkSchedule > 0 &&
            !split.isTypeTransportation ? (
              <>
                <div className='border-top' />
                <span>{split.clinicalDepartment}</span>
              </>
            ) : null} */}
          </div>
        </Row>

        {/* <Row gutter={[24, 2]} className='mobile-report-row'>
          <Col span={24} style={{ fontWeight: 'bold' }}>
            {i18n.t('main.departmentName')}
          </Col>
          <Col span={24}>
            {split.clinicalDepartment}
            {split.costTransportationByDoctorWorkSchedule > 0 &&
            !split.isTypeTransportation ? (
              <>
                <div className='border-top' />
                {split.clinicalDepartment}
              </>
            ) : null}
          </Col>
        </Row> */}

        {/* {toggleItem.includes(index) && ( */}
        <>
          <Row className='mobile-report-row'>
            <div style={{ fontWeight: 'bold' }}>
              {i18n.t('transportApplication.categoryApplication')}
              <span style={{ padding: '0 5px' }}> : </span>
            </div>
            <div>
              {split.isTransport ? (
                split.type
              ) : split.category == 'RECURRING_TRAVEL_FEE' ? (
                getType(split.type)
              ) : split.isShowSelectType ? (
                <Select
                  defaultValue={split.isTypeTransportation === true ? '1' : '0'}
                  style={{ width: 120 }}
                  disabled={
                    !isDisable ||
                    split.deletedAt ||
                    split.notApplyCostTransportation
                  }
                  onChange={async e => {
                    setFieldValue('workScheduleId', split.id)
                    setFieldValue('isMyCar', e === '0' ? false : true)
                    setFieldValue('confirm', true)
                  }}
                >
                  <Option value='0'>公共交通機関</Option>
                  <Option disabled={!isBlockedMyCar} value='1'>
                    マイカー利用
                  </Option>
                </Select>
              ) : null}
            </div>
          </Row>

          <Row className='mobile-report-row'>
            <div style={{ fontWeight: 'bold' }}>
              {i18n.t('transportApplication.fareOneway')}
              <span style={{ padding: '0 5px' }}> : </span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              {!split.isTransport ? <span>{split.costWs} 円</span> : null}
              {split.departmentID ? (
                split.type === null && split.startTime === null ? null : (
                  <span>{split.cost / 2}円</span>
                )
              ) : null}
              {split.costTransportationByDoctorWorkSchedule > 0 &&
                !split.isTypeTransportation && (
                  <div style={{ margin: ' 0 0 0 5px' }}>
                    <span>(復路 : </span>
                    {
                      <span>
                        {split.costTransportationByDoctorWorkSchedule}円
                      </span>
                    }
                    <span>)</span>
                  </div>
                )}
            </div>
          </Row>

          <Row className='mobile-report-row'>
            <div style={{ fontWeight: 'bold' }}>
              {i18n.t('transportApplication.fareRoundTrip')}
              <span style={{ padding: '0 5px' }}> : </span>
            </div>
            <div>
              {!split.isTransport ? (
                <span>
                  {parseInt(
                    split.costTransportationByDoctorWorkSchedule > 0 &&
                      !split.isTypeTransportation
                      ? split.costTransportationByDoctorWorkSchedule
                      : split.costWs
                  ) + split.costWs}{' '}
                  円
                </span>
              ) : null}
              {split.departmentID ? <span>{split.cost}円</span> : null}
            </div>
          </Row>

          <Row className='mobile-report-row'>
            <div style={{ fontWeight: 'bold' }}>
              {i18n.t('transportApplication.feeApplication')}
              <Link style={{ color: '#40a9ff' }} to='#' onClick={showModal}>
                ※
              </Link>
              <Modal
                title='申請料金について'
                className='modal-antd'
                closable={false}
                visible={visible}
                okButtonProps={{
                  style: {
                    display: 'none',
                  },
                }}
                onCancel={hideModal}
                cancelText={i18n.t('main.close')}
              >
                <span>
                  「その他交通費申請」より申請し、承認された場合の金額がこちらに表示されます。拠点間での移動や普段とは違う経路で通勤した場合は、「その他交通費申請」メニューより経路と金額を申請してください。
                </span>
              </Modal>
              <span style={{ padding: '0 5px' }}> : </span>
            </div>
            <div>
              {split.isTransport && !split.departmentID ? (
                <span>{split.cost}円</span>
              ) : (
                <span>なし</span>
              )}
            </div>
          </Row>
          <Row className='mobile-report-row'>
            <div style={{ fontWeight: 'bold' }}>
              {i18n.t('transportApplication.receipt')}
              <span style={{ padding: '0 5px' }}> : </span>
            </div>
            <div>
              {split.urlImageS3 ? (
                <>
                  <Button
                    label={i18n.t('main.downloadFile')}
                    onClick={() => {
                      setDataImg(split.urlImageS3)
                      handleOpenImgDialog(true)
                    }}
                    icon='pi pi-info'
                    className='p-button-secondary'
                  />
                  <PreviewImgDialog
                    i18n={i18n}
                    visible={openImgDialog}
                    dataImage={dataImg}
                    handleClose={() => handleOpenImgDialog(false)}
                  />
                </>
              ) : null}
            </div>
          </Row>
        </>
        {/* )}
        <div style={{ textAlign: 'center' }}>
          <Button
            style={{
              borderRadius: '25px',
              marginBottom: '5px',
            }}
            icon={
              toggleItem.indexOf(index) === -1
                ? 'pi pi-chevron-down'
                : 'pi pi-chevron-up'
            }
            onClick={() =>
              !toggleItem.includes(index)
                ? setToggleItem(prevValue => [...prevValue, index])
                : setToggleItem(prevValue =>
                    prevValue.filter(item => item !== index)
                  )
            }
          />
        </div> */}
      </div>
    </>
  )
}
export default TransportReportsSceneMobile
