import './style.css'

import ConfirmationModal from '@medical/components/ConfirmationModal'
import numeralCurrency from '@medical/libs/numeralCurrency'
import { ActionItem } from '@medical/pages/doctor/AvailableShift/AvailableShiftComponents/ActionItem.js'
import { DatePicker } from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { MultiSelect } from 'primereact/multiselect'
import React from 'react'
import { Paginator } from 'primereact/paginator'

const RequestedAvailableShiftsListScene = ({
  i18n,
  availableShifts,
  onSubmit,
  addItemToCart,
  removeItemFromCart,
  lastLoggedIn,
  onDropdownChange,
  cartItems,
  clinicalDepartmentIds,
  clinicalDepartments,
  setDate,
  date,
  rowsPerPage,
  page,
  count,
  onPageChange,
}) => {
  const disabledDate = current => {
    const today = moment().subtract(1, 'days')
    return !today.isSameOrBefore(current)
  }
  return (
    <div>
      <Formik
        initialValues={{
          confirm: false,
        }}
        onSubmit={onSubmit}
        render={formikProps => (
          <div className='container'>
            <div className='box'>
              <div className='schedule-calendar'>
                <div className='calendar-container'>
                  <div style={{ paddingTop: '50px' }}>
                    <div className='last-login'>
                      {`${i18n.t('main.lastLoggedIn')} : ${moment(lastLoggedIn)
                        .startOf('day')
                        .format('YYYY年MM月DD日(ddd)')}`}
                    </div>
                    <div className='available-shift-list-title'>
                      {i18n.t('main.availableShiftList')}
                    </div>
                    <div className='row available-shift-list-header'>
                      <div className='row search-parent'>
                        <MultiSelect
                          value={clinicalDepartments.filter(({ id }) =>
                            clinicalDepartmentIds.includes(id)
                          )}
                          fixedPlaceholder
                          options={clinicalDepartments}
                          optionLabel='clinicName'
                          onChange={onDropdownChange}
                          filter
                          placeholder={i18n.t('main.chooseClinic')}
                        />
                        <div className='search-time'>
                          <DatePicker.RangePicker
                            value={[
                              date[0]
                                ? moment(new Date(date[0]), 'YYYY年MM月DD日')
                                : null,
                              date[1]
                                ? moment(new Date(date[1]), 'YYYY年MM月DD日')
                                : null,
                            ]}
                            disabledDate={disabledDate}
                            placeholder={['開始日', '終了日']}
                            format='YYYY年MM月DD日'
                            locale={locale}
                            allowClear={false}
                            onChange={value => {
                              setDate([
                                moment(value[0])
                                  .startOf('day')
                                  .toISOString(),
                                moment(value[1])
                                  .endOf('day')
                                  .toISOString(),
                              ])
                            }}
                          />
                        </div>
                        <Button
                          className='clear-filter-button'
                          icon='pi pi-times'
                          onClick={() => {
                            setDate([null, null])
                            onDropdownChange(null)
                          }}
                        />
                      </div>
                      <div className='header'>
                        <div className='available-shift-list row'>
                          <span className='tag-name'>New</span>
                          <span>前回ログインから新たに募集開始したシフト</span>
                        </div>
                        <div className='available-shift-list row'>
                          <span className='tag-name update'>Update</span>
                          <span>前回ログインから新たに情報更新したシフト</span>
                        </div>
                      </div>
                    </div>
                    <div className='overflow-container'>
                      <table className='table' style={{ textAlign: 'center' }}>
                        <thead>
                          <tr>
                            <th width='20%'>{i18n.t('main.clinicName')}</th>
                            <th width='15%'>{i18n.t('main.departmentName')}</th>
                            <th width='22%'>{i18n.t('main.date')}</th>
                            <th width='12%'>{i18n.t('main.startTime')}</th>
                            <th width='12%'>{i18n.t('main.endTime')}</th>
                            <th width='12%'>{i18n.t('main.dailySalary')}</th>
                            <th width='5%'>
                              {i18n.t('main.descriptionDetail')}
                            </th>
                            <th width='5%'>{i18n.t('main.apply')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {availableShifts.length > 0 ? (
                            availableShifts.map(availableShift => {
                              if (
                                new Date(availableShift.createdAt).getTime() -
                                  new Date(lastLoggedIn).getTime() >
                                  0 &&
                                availableShift.createdAt ===
                                  availableShift.updatedAt
                              ) {
                                return (
                                  <tr
                                    className='new-shift'
                                    key={availableShift.id}
                                  >
                                    <td>
                                      <div className='available-shift-list row'>
                                        <p className='tag-name'>New</p>
                                      </div>
                                      <p>{`${availableShift.clinicName}`}</p>
                                    </td>
                                    <td>
                                      {availableShift.clinicalDepartmentName}
                                    </td>
                                    <td>
                                      {moment(availableShift.startTime).format(
                                        'LL (dd)'
                                      )}
                                    </td>
                                    <td>
                                      {moment(availableShift.startTime).format(
                                        'HH:mm'
                                      )}
                                    </td>
                                    <td>
                                      {moment(availableShift.endTime).format(
                                        'HH:mm'
                                      )}
                                    </td>
                                    <td>
                                      {numeralCurrency(
                                        availableShift.dailySalary
                                      )}
                                      {availableShift.isSpecial && (
                                        <p
                                          style={{
                                            backgroundColor: '#ff2575',
                                            color: '#fff',
                                            width: 'fit-content',
                                          }}
                                        >
                                          特別時給
                                        </p>
                                      )}
                                    </td>
                                    <td>
                                      <ActionItem
                                        item={availableShift}
                                        type='availableShifts'
                                      />
                                    </td>
                                    <td>
                                      <div className='table-action'>
                                        {cartItems.some(
                                          element =>
                                            element.id === availableShift.id
                                        ) ? (
                                          <Button
                                            onClick={() =>
                                              removeItemFromCart(
                                                availableShift.id
                                              )
                                            }
                                            icon='pi pi-times'
                                            className='p-button-danger'
                                          />
                                        ) : (
                                          <Button
                                            onClick={() =>
                                              addItemToCart(availableShift)
                                            }
                                            icon='pi pi-plus'
                                            className='p-button-success'
                                          />
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                )
                              }
                              if (
                                new Date(availableShift.updatedAt).getTime() -
                                  new Date(lastLoggedIn).getTime() >
                                0
                              ) {
                                return (
                                  <tr
                                    className='renew-shift'
                                    key={availableShift.id}
                                  >
                                    <td>
                                      <div className='available-shift-list row'>
                                        {availableShift.isHourlyWageUpdated ? (
                                          <p className='tag-name'>¥</p>
                                        ) : null}
                                        <p className='tag-name update'>
                                          Update
                                        </p>
                                      </div>
                                      <p>{`${availableShift.clinicName}`}</p>
                                    </td>
                                    <td>
                                      {availableShift.clinicalDepartmentName}
                                    </td>
                                    <td>
                                      {moment(availableShift.startTime).format(
                                        'LL (dd)'
                                      )}
                                    </td>
                                    <td>
                                      {moment(availableShift.startTime).format(
                                        'HH:mm'
                                      )}
                                    </td>
                                    <td>
                                      {moment(availableShift.endTime).format(
                                        'HH:mm'
                                      )}
                                    </td>
                                    <td>
                                      {numeralCurrency(
                                        availableShift.dailySalary
                                      )}
                                      {availableShift.isSpecial && (
                                        <p
                                          style={{
                                            backgroundColor: '#ff2575',
                                            color: '#fff',
                                            width: 'fit-content',
                                          }}
                                        >
                                          特別時給
                                        </p>
                                      )}
                                    </td>
                                    <td>
                                      <ActionItem
                                        item={availableShift}
                                        type='availableShifts'
                                      />
                                    </td>
                                    <td>
                                      <div className='table-action'>
                                        {cartItems.some(
                                          element =>
                                            element.id === availableShift.id
                                        ) ? (
                                          <Button
                                            onClick={() =>
                                              removeItemFromCart(
                                                availableShift.id
                                              )
                                            }
                                            icon='pi pi-times'
                                            className='p-button-danger'
                                          />
                                        ) : (
                                          <Button
                                            onClick={() =>
                                              addItemToCart(availableShift)
                                            }
                                            icon='pi pi-plus'
                                            className='p-button-success'
                                          />
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                )
                              }
                              return (
                                <tr key={availableShift.id}>
                                  <td>
                                    <p>{`${availableShift.clinicName}`}</p>
                                  </td>
                                  <td>
                                    {availableShift.clinicalDepartmentName}
                                  </td>
                                  <td>
                                    {moment(availableShift.startTime).format(
                                      'LL (dd)'
                                    )}
                                  </td>
                                  <td>
                                    {moment(availableShift.startTime).format(
                                      'HH:mm'
                                    )}
                                  </td>
                                  <td>
                                    {moment(availableShift.endTime).format(
                                      'HH:mm'
                                    )}
                                  </td>
                                  <td style={{ textAlign: '-webkit-center' }}>
                                    {numeralCurrency(
                                      availableShift.dailySalary
                                    )}
                                    {availableShift.isSpecial && (
                                      <p
                                        style={{
                                          backgroundColor: '#ff2575',
                                          color: '#fff',
                                          width: 'fit-content',
                                        }}
                                      >
                                        特別時給
                                      </p>
                                    )}
                                  </td>
                                  <td>
                                    <ActionItem
                                      item={availableShift}
                                      type='availableShifts'
                                    />
                                  </td>
                                  <td>
                                    <div className='table-action'>
                                      {cartItems.some(
                                        element =>
                                          element.id === availableShift.id
                                      ) ? (
                                        <Button
                                          onClick={() =>
                                            removeItemFromCart(
                                              availableShift.id
                                            )
                                          }
                                          icon='pi pi-times'
                                          className='p-button-danger'
                                        />
                                      ) : (
                                        <Button
                                          onClick={() =>
                                            addItemToCart(availableShift)
                                          }
                                          icon='pi pi-plus'
                                          className='p-button-success'
                                        />
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              )
                            })
                          ) : (
                            <tr>
                              <td colSpan={8}>
                                {i18n.t('main.noRequestedAvailableShift')}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <Paginator
                        rows={rowsPerPage}
                        totalRecords={count}
                        first={(page - 1) * rowsPerPage}
                        onPageChange={onPageChange}
                        leftContent={
                          <div className='paginator-total'>
                            {i18n.t('main.paginationTotal')}: {count}
                          </div>
                        }
                      />
                    </div>
                  </div>

                  <ConfirmationModal
                    {...formikProps}
                    confirmationMessage={i18n.t(
                      'doctor.cancelRequestedWorkSchedule.submitConfirmation'
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      />
    </div>
  )
}
export default RequestedAvailableShiftsListScene
