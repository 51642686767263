import './styles.css'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, NotFound, ProgressSpinner } from '@medical/components'
import { ACCEPTED } from '@medical/constant'
import { checkStaffPermission, clinicalDepartmentNames } from '@medical/libs'
import { STAFF_UPDATE_SCHEDULE_CANCEL } from '@medical/constant/permissions'
import {
  GET_DETAIL_WORK_SCHEDULES,
  GET_HISTORY_MODIFY_CANCEL_SHIFT,
  UPDATE_WORK_SCHEDULE_CANCELLATION_LETTER,
  WORK_SCHEDULE_CONNECTION,
} from '@medical/pages/staff/DetailWorkScheduleCancelShift/DetailWorkScheduleCancelShift.graphql'
import DetailWorkScheduleCancelShiftScene from '@medical/pages/staff/DetailWorkScheduleCancelShift/DetailWorkScheduleCancelShiftScene'
import { useCustom } from '@medical/provider/context'
import Router from '@medical/routes/router'
import moment from 'moment'
import React, { useEffect } from 'react'
import { CREATE_ACTIVITY } from '@medical/pages/doctor/AvailableShift/AvailableShift.graphql'

const DetailWorkScheduleCancelShift = ({
  history,
  match: {
    params: { year, month, day, cancelShiftId },
  },
  closeModal,
  // setUpdateCancellationLetter,
}) => {
  const [{ i18n, popup }] = useCustom()
  const [staffCreateActivity] = useMutation(CREATE_ACTIVITY)
  const [updateWorkScheduleCancellation] = useMutation(
    UPDATE_WORK_SCHEDULE_CANCELLATION_LETTER
  )
  const { loading, error, data, refetch } = useQuery(WORK_SCHEDULE_CONNECTION, {
    variables: {
      where: {
        deletedAt: null,
        status_in: ['WAITING_FOR_APPROVAL', 'REJECTED', 'ACCEPTED'],
        id: cancelShiftId,
      },
      orderBy: 'updatedAt_DESC',
    },
  })
  const {
    loading: historyChangeLoading,
    error: historyChangeError,
    data: historyChangeData,
    refetch: historyRefetch,
  } = useQuery(GET_HISTORY_MODIFY_CANCEL_SHIFT, {
    variables: {
      where: {
        id: cancelShiftId,
      },
    },
  })

  const {
    data: detailWorkScheduleData,
    loading: detailWSLoading,
    error: detailWSError,
  } = useQuery(GET_DETAIL_WORK_SCHEDULES, {
    variables: {
      where: {
        id: cancelShiftId,
      },
    },
  })
  useEffect(() => {
    refetch()
    historyRefetch()
  }, [])
  if (loading || historyChangeLoading || detailWSLoading)
    return <ProgressSpinner />
  if (error || historyChangeError || detailWSError)
    return <ErrorComponent error={error || detailWSError} />
  if (
    !data ||
    !data.workScheduleCancellationLettersConnection ||
    !data.workScheduleCancellationLettersConnection.edges ||
    !data.workScheduleCancellationLettersConnection.edges[0] ||
    !data.workScheduleCancellationLettersConnection.edges[0].node
  )
    return <NotFound />
  let workSchedule
  if (
    data &&
    data.workScheduleCancellationLettersConnection &&
    data.workScheduleCancellationLettersConnection.edges &&
    data.workScheduleCancellationLettersConnection.edges[0]
  ) {
    const { edges } = data.workScheduleCancellationLettersConnection
    workSchedule = edges[0].node.workSchedule
  }
  const { departments } = data
  const departmentNames = clinicalDepartmentNames({
    departments,
  })
  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    let {
      action,
      isTickCountCancel,
      isTickCountCancelDueToClinicReason,
      isFirstTimeProcess,
    } = values
    if (Array.isArray(isTickCountCancel) && isTickCountCancel.length > 0) {
      isTickCountCancel = isTickCountCancel[0]
    }
    if (
      Array.isArray(isTickCountCancelDueToClinicReason) &&
      isTickCountCancelDueToClinicReason.length > 0
    ) {
      isTickCountCancelDueToClinicReason = isTickCountCancelDueToClinicReason[0]
    }
    let isUpdateCountCancel = !isFirstTimeProcess
    try {
      await updateWorkScheduleCancellation({
        variables: {
          isTickCountCancel,
          isTickCountCancelDueToClinicReason:
            isTickCountCancelDueToClinicReason === 'yes',
          status: action,
          workScheduleCancellationLetterId: cancelShiftId,
          isUpdateCountCancel,
        },
      })
      if (values.action === ACCEPTED) {
        const ws =
          data.workScheduleCancellationLettersConnection.edges[0].node
            .workSchedule
        staffCreateActivity({
          variables: {
            activity: 'CANCEL_FIXED_SHIFTS',
            doctorId: ws.doctor.id,
            details: `拠点：${ws.clinicalDepartment.clinic.name}_${
              departmentNames[ws.clinicalDepartment.name]
            } シフト日時：${moment(ws.startTime).format(
              'YYYY年MM月DD日'
            )} ${moment(ws.startTime).format('HH:mm')}~${moment(
              ws.endTime
            ).format('HH:mm')}`,
          },
        })
      }
      popup.success(i18n.t('staff.createShift.submissionSuccess'))
      setSubmitting(false)
      resetForm()
      closeModal()
      // setUpdateCancellationLetter(true)
      if (action === ACCEPTED && isFirstTimeProcess) {
        let splits = []
        if (
          detailWorkScheduleData &&
          detailWorkScheduleData.workScheduleCancellationLetters &&
          detailWorkScheduleData.workScheduleCancellationLetters[0] &&
          detailWorkScheduleData.workScheduleCancellationLetters[0]
            .workSchedule &&
          detailWorkScheduleData.workScheduleCancellationLetters[0].workSchedule
            .acceptedShift
        ) {
          const {
            acceptedShift,
          } = detailWorkScheduleData.workScheduleCancellationLetters[0].workSchedule
          if (
            !acceptedShift.splitDateTime1 &&
            !acceptedShift.splitDateTime2 &&
            !acceptedShift.splitDateTime3
          ) {
            splits.push({
              date: moment(acceptedShift.endTime).toDate(),
              hourlyWage: acceptedShift.hourlyWage,
            })
          }
          if (
            acceptedShift.splitDateTime1 &&
            !acceptedShift.splitDateTime2 &&
            !acceptedShift.splitDateTime3
          ) {
            splits.push({
              date: moment(acceptedShift.splitDateTime1).toDate(),
              hourlyWage: acceptedShift.splitHourlyWage1,
            })
            splits.push({
              date: moment(acceptedShift.endTime).toDate(),
              hourlyWage: acceptedShift.hourlyWage,
            })
          }
          if (
            acceptedShift.splitDateTime1 &&
            acceptedShift.splitDateTime2 &&
            !acceptedShift.splitDateTime3
          ) {
            splits.push({
              date: moment(acceptedShift.splitDateTime1).toDate(),
              hourlyWage: acceptedShift.splitHourlyWage1,
            })
            splits.push({
              date: moment(acceptedShift.splitDateTime2).toDate(),
              hourlyWage: acceptedShift.splitHourlyWage2,
            })
            splits.push({
              date: moment(acceptedShift.endTime).toDate(),
              hourlyWage: acceptedShift.hourlyWage,
            })
          }
          if (
            acceptedShift.splitDateTime1 &&
            acceptedShift.splitDateTime2 &&
            acceptedShift.splitDateTime3
          ) {
            splits.push({
              date: moment(acceptedShift.splitDateTime1).toDate(),
              hourlyWage: acceptedShift.splitHourlyWage1,
            })
            splits.push({
              date: moment(acceptedShift.splitDateTime2).toDate(),
              hourlyWage: acceptedShift.splitHourlyWage2,
            })
            splits.push({
              date: moment(acceptedShift.splitDateTime3).toDate(),
              hourlyWage: acceptedShift.splitHourlyWage3,
            })

            splits.push({
              date: moment(acceptedShift.endTime).toDate(),
              hourlyWage: acceptedShift.hourlyWage,
            })
          }
        }
        history.push({
          pathname: `${Router.get(Router.staffCancelShifts)}${Router.get(
            Router.staffCreateAvailableShift
          )}`,
          state: {
            clinicalDepartment:
              workSchedule && workSchedule.clinicalDepartment
                ? workSchedule.clinicalDepartment
                : '',
            startTime: moment(workSchedule.startTime).toDate(),
            splits,
            isPublished: true,
          },
        })
      }
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }

  const isUpdateScheduleCancel = checkStaffPermission({
    functionName: STAFF_UPDATE_SCHEDULE_CANCEL,
  })
  return (
    <DetailWorkScheduleCancelShiftScene
      onSubmit={onSubmit}
      confirmationMessage={i18n.t('staff.createHourlyWage.createHourlyWage')}
      i18n={i18n}
      data={data}
      historyChangeData={historyChangeData}
      isUpdateScheduleCancel={isUpdateScheduleCancel}
    />
  )
}

export default DetailWorkScheduleCancelShift
