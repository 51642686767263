import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import gql from 'graphql-tag'

export const DOCTOR_WORK_SCHEDULES = gql`
  query WorkSchedules(
    $where: WorkScheduleWhereInput
    $orderBy: WorkScheduleOrderByInput
  ) {
    workSchedules(where: $where, orderBy: $orderBy) {
      id
      startTime
      endTime
      accepted
      deletedStatus
      adjustHourlyWageRange
      acceptedAt
      clinicalDepartment {
        id
        name
        extraName
        isDisplay
        clinic {
          id
          order
          name
          nameKana
          messageIntroduce
        }
      }
      workScheduleCancellationLetter {
        id
      }
      workOvertime {
        id
        status
      }
      acceptedShift {
        id
        dailySalary
        hourlyWage
        startTime
        endTime
        splitDateTime1
        splitDateTime2
        splitDateTime3
        splitHourlyWage1
        splitHourlyWage2
        splitHourlyWage3
        doctorSubsidy {
          id
          title
          money
        }
      }
    }
    ...DEPARTMENTS_FRAGMENT
  }
  ${DEPARTMENTS_FRAGMENT}
`
