import { FileTextOutlined } from '@ant-design/icons'
import BreadCrumb from '@medical/components/Breadcrumb'
import { useCustom } from '@medical/provider/context'
import { Popover } from 'antd'
import Mark from 'mark.js'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import React, { useEffect, useState } from 'react'
import uuid from 'uuid'

const DoctorPortalScene = ({
  menuNodes,
  nodeSelected,
  largeTypeList,
  largeTypeSelected,
  selectLargeType,
  searchContent,
  nodesSearched,
}) => {
  const [{ i18n, activeIndexSecond }, { setActiveIndexSecond }] = useCustom()
  const [contentSearch, setContentSearch] = useState('')
  useEffect(() => {
    const markInstance = new Mark(document.querySelector('#search-node'))
    markInstance.unmark({
      done: () => {
        markInstance.mark(contentSearch, {
          className: 'mark',
        })
      },
    })
  }, [contentSearch, nodeSelected])
  const [nodeData, setNodeData] = useState('')
  const [nodeLabel, setNodeLabel] = useState('')
  const menu = menuNodes.map(node => (
    <>
      <Accordion
        activeIndex={activeIndexSecond}
        onTabClose={() => setActiveIndexSecond(null)}
        onTabOpen={() => setActiveIndexSecond(0)}
      >
        <AccordionTab
          header={
            <>
              <FileTextOutlined
                style={{
                  fontSize: '20px',
                  color: 'var(--primary)',
                }}
              />
              <span style={{ color: 'var(--primary)' }}>{node.label}</span>
            </>
          }
        >
          {node.children.map(nodeLv2 => (
            <Accordion
              activeIndex={activeIndexSecond}
              onTabClose={() => setActiveIndexSecond(null)}
              onTabOpen={() => setActiveIndexSecond(0)}
            >
              <AccordionTab
                header={
                  <div style={{ marginLeft: '20px' }}>
                    <span
                      style={{
                        color: 'var(--primary)',
                        fontSize: '16px',
                      }}
                    >
                      {nodeLv2.label}
                      <span style={{ marginLeft: '5px' }}>
                        ({nodeLv2.children.length})
                      </span>
                    </span>
                  </div>
                }
              >
                {nodeLv2.children.map(nodeLv3 => {
                  if (nodeLv3 === null) {
                    return null
                  }
                  return (
                    <div>
                      <button
                        className='node-btn'
                        style={{ width: '100%', textAlign: 'start' }}
                        onClick={() => {
                          setNodeData(nodeLv3.data)
                          setNodeLabel(nodeLv3.label)
                        }}
                      >
                        <p
                          style={{
                            color: 'var(--black)',
                            textDecoration: 'underline',
                            fontSize: '16px',
                          }}
                        >
                          {nodeLv3.label}
                        </p>
                      </button>
                    </div>
                  )
                })}
              </AccordionTab>
            </Accordion>
          ))}
        </AccordionTab>
      </Accordion>
    </>
  ))

  const NodesSearchedContent = () => {
    if (nodesSearched.length == 0)
      return <>{i18n.t('doctor.portal.notFound')}</>
    return (
      <ul className='nodeSearch'>
        {nodesSearched.map(e => (
          <li
            key={uuid.v1()}
            onClick={() => {
              setNodeData(e.data)
              setNodeLabel(e.smallTitle)
            }}
            className='cursor-pointer'
          >
            {e.smallTitle}
          </li>
        ))}
      </ul>
    )
  }

  return (
    <div className='doctor-portal'>
      {/* <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('doctor.portal.portalInformation')}
          <BreadCrumb
            items={[{ title: i18n.t('doctor.portal.portalInformation') }]}
          />
        </div>
      </div> */}
      <div className='container is-max-portal'>
        <div className='staff-title'>
          {i18n.t('doctor.portal.portalInformation')}
          <BreadCrumb
            items={[{ title: i18n.t('doctor.portal.portalInformation') }]}
          />
        </div>
        <div className='box'>
          <div className='header'>
            <div className='header-input-search'>
              <Dropdown
                id='item-order-1'
                value={largeTypeSelected}
                options={largeTypeList.map(e => ({ label: e, value: e }))}
                onChange={e => selectLargeType(e.value)}
                placeholder={i18n.t('doctor.portal.article')}
              />
              <Button
                id='item-order-2'
                icon='pi pi-times'
                className='header-clear'
                onClick={() => selectLargeType(null)}
              />
            </div>
            <div className='header-submit-btn'>
              <span
                id='item-order-3'
                className='p-input-icon-right header-contentInput'
              >
                <i className='pi pi-search' />
                <InputText
                  value={contentSearch}
                  onChange={e => setContentSearch(e.target.value)}
                  placeholder={i18n.t('doctor.portal.search')}
                />
              </span>
              <Popover
                content={NodesSearchedContent}
                title={contentSearch}
                placement='bottomLeft'
                trigger='click'
              >
                <Button
                  id='item-order-4'
                  onClick={() => searchContent(contentSearch)}
                  label={i18n.t('doctor.portal.search')}
                />
              </Popover>
            </div>
          </div>
          <div className='body'>
            <div className='body-menu'>
              <ul className='pl-0'>{menu}</ul>
            </div>
            <div className='body-content'>
              {nodeLabel ? <h1>{nodeLabel}</h1> : <h1>{nodeSelected.label}</h1>}
              {nodeData ? (
                <div
                  id='search-node'
                  dangerouslySetInnerHTML={{ __html: nodeData }}
                />
              ) : (
                <div
                  id='search-node'
                  dangerouslySetInnerHTML={{ __html: nodeSelected.data }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DoctorPortalScene
