import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import gql from 'graphql-tag'

export const WORK_SCHEDULE_CONNECTION = gql`
  query WorkScheduleCancellationLettersConnection(
    $orderBy: WorkScheduleCancellationLetterOrderByInput
    $where: WorkScheduleCancellationLetterWhereInput
  ) {
    workScheduleCancellationLettersConnection(
      orderBy: $orderBy
      where: $where
    ) {
      edges {
        cursor
        node {
          id
          createdAt
          updatedAt
          deletedAt
          isTickCountCancel
          isTickCountCancelDueToClinicReason
          status
          acceptedAt
          reason
          reassignDoctorNote
          reworkNote
          comment
          workSchedule {
            id
            doctor {
              id
              firstnameKana
              lastnameKana
              firstname
              lastname
              totalCancelLetter
              countedCancelLetter
              countedCancelLetterDueToClinicReason
              email
              workScheduleCancellationLetterHistory {
                date
                count
              }
              workScheduleCancellationLetterHistoryDueToClinicReason {
                date
                count
              }
            }
            createdAt
            acceptedBy {
              id
              firstname
              lastname
            }
            acceptedShift {
              id
              createdAt
              createdBy {
                id
                lastname
                firstname
              }
            }
            isCreatedByDoctor
            clinicalDepartment {
              id
              name
              clinic {
                id
                name
              }
            }
            startTime
            endTime
          }
          id
        }
      }
    }
    ...DEPARTMENTS_FRAGMENT
  }
  ${DEPARTMENTS_FRAGMENT}
`

export const UPDATE_WORK_SCHEDULE_CANCELLATION_LETTER = gql`
  mutation UPDATE_WORK_SCHEDULE_CANCELLATION_LETTER(
    $isTickCountCancel: Boolean
    $isTickCountCancelDueToClinicReason: Boolean
    $status: WorkScheduleCancellationLetterStatus!
    $workScheduleCancellationLetterId: ID!
    $isUpdateCountCancel: Boolean!
  ) {
    staffUpdateWorkScheduleCancellationLetter(
      isTickCountCancel: $isTickCountCancel
      isTickCountCancelDueToClinicReason: $isTickCountCancelDueToClinicReason
      status: $status
      workScheduleCancellationLetterId: $workScheduleCancellationLetterId
      isUpdateCountCancel: $isUpdateCountCancel
    )
  }
`

export const APPROVED_TICKET_COUNT_CANCEL = gql`
  query TickCountCancelCount($doctorId: ID!) {
    tickCountCancelCount(doctorId: $doctorId)
  }
`
export const COUNT_TICK_COUNT_CANCEL = gql`
  query TickCountCancelCount($doctorId: ID!) {
    workScheduleCancellationLetterCount(doctorId: $doctorId)
  }
`
export const GET_HISTORY_MODIFY_CANCEL_SHIFT = gql`
  query WorkScheduleCancellationLetter(
    $where: WorkScheduleCancellationLetterWhereUniqueInput!
  ) {
    workScheduleCancellationLetter(where: $where) {
      id
      updatedBy {
        id
        firstname
        lastname
      }
      status
      updatedAt
    }
  }
`
export const GET_DETAIL_WORK_SCHEDULES = gql`
  query WorkScheduleCancellationLetters(
    $where: WorkScheduleCancellationLetterWhereInput
  ) {
    workScheduleCancellationLetters(where: $where) {
      id
      updatedAt
      createdAt
      workSchedule {
        id
        acceptedShift {
          id
          createdAt
          updatedAt
          startTime
          endTime
          hourlyWage
          splitDateTime1
          splitDateTime2
          splitDateTime3
          splitHourlyWage1
          splitHourlyWage2
          splitHourlyWage3
        }
        startTime
        endTime
      }
    }
  }
`
export const STAFF_FRAGMENTS = gql`
  fragment STAFF_FRAGMENTS on Staff {
    id
  }
`

export const STAFF_ME = gql`
  query STAFF_ME {
    staffMe {
      ...STAFF_FRAGMENTS
    }
  }
  ${STAFF_FRAGMENTS}
`
