export const DoctorStatusTemplate = [
  {
    value: 'NEW',
    description: '承認待ち',
  },
  {
    value: 'PENDING',
    description: '一時保留',
  },
  {
    value: 'ACCEPTED',
    description: '承認済み',
  },
  {
    value: 'REJECTED',
    description: '非承認',
  },
  {
    value: 'CREATED_BY_STAFF',
    description: 'スタッフ作成',
  },
]

export const getStatus = value => {
  let result = ''
  switch (value) {
    case 'NEW':
      result = '承認待ち'
      break
    case 'PENDING':
      result = '一時保留'
      break
    case 'ACCEPTED':
      result = '承認済み'
      break
    case 'REJECTED':
      result = '非承認'
      break
    case 'CREATED_BY_STAFF':
      result = 'スタッフ作成'
      break
    default:
      break
  }
  return result
}
