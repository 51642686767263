import { DOCTOR_STATUSES } from '@medical/constant'

export const HeaderMail = () => {
  return `
  この度は、CAPSドクター365にご登録いただきありがとうございます。

  ご登録内容が確認できました。
  再度ログインすると、募集シフトの閲覧・応募が可能となっております。
  また、医師免許証の写し及び保険医登録票の写しをご登録ください。
  引き続き、CAPSドクター365をご利用ください。
  `
}

export const LinkMail = () => {
  let url
  if (process.env.REACT_APP_ENV === 'development')
    url = 'http://localhost:3000/doctor/login'
  else if (process.env.REACT_APP_ENV === 'staging')
    url = 'https://staging.capsdoctor365.jp/doctor/login'
  else url = 'https://doctor.capsdoctor365.jp/doctor/login'
  return `
  <a href=${url}>こちらをクリックしてください。</a>
  `
}
export const FooterMail = data => {
  return `
  お名前 (姓): ${data.lastname || ''}
  お名前 (名): ${data.firstname || ''}
  メールアドレス: ${data.email || ''}
  `
}
export const getEmailTemplate = (status, data) => {
  switch (status) {
    case DOCTOR_STATUSES.NEW:
    case DOCTOR_STATUSES.REJECTED:
      return ''
    case DOCTOR_STATUSES.ACCEPTED:
      return `
      ${HeaderMail()}
      ${FooterMail(data)}
      `
    case DOCTOR_STATUSES.PENDING:
      return 'この度は、CAPSドクター365にご登録申請をいただき、誠にありがとうございます。確認させていただきたいことがごさいますため、CAPSドクターサポートチーム（doctor-support@caps365.jp）より、ご登録されたこのメールアドレスに、ご連絡をさせていただきます。その確認後、ご登録完了となります。何卒、よろしくお願いします。'
    default:
      return ''
  }
}
