import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import gql from 'graphql-tag'

export const CLINICS_MONTHLY_REPORT = gql`
  query CLINICS_MONTHLY_REPORT($date: DateTime!) {
    clinicalDepartments(orderBy: name_ASC) {
      id
      name
      extraName
      isDisplay
      clinic {
        id
        name
        order
        region
        Region {
          id
          name
          position
        }
      }
      monthReports(date: $date) {
        date
        acceptedHours
        availableHours
        notRequestedAvailableShiftCount
      }
    }
    ...DEPARTMENTS_FRAGMENT
  }
  ${DEPARTMENTS_FRAGMENT}
`

export const MONTHLY_WORK_SCHEDULE_GENERATOR = isMoneyPermitted => {
  if (isMoneyPermitted) {
    return gql`
      query CLINICS_MONTHLY_REPORT_CSV($date: DateTime!) {
        clinicalDepartments: workSchedulesCsv(date: $date)
      }
    `
  }
  return gql`
    query CLINICS_MONTHLY_REPORT_CSV($date: DateTime!) {
      clinicalDepartments: workSchedulesCsv(date: $date)
    }
  `
}

export const WORK_SCHEDULES_CONNECTION = gql`
  query Query($where: WorkScheduleWhereInput) {
    workSchedulesConnection(where: $where) {
      edges {
        node {
          id
        }
      }
    }
  }
`

export const REGION_QUERY = gql`
  query regions($orderBy: RegionOrderByInput) {
    regions(orderBy: $orderBy) {
      name
      id
      position
    }
  }
`