import gql from 'graphql-tag'

export const WORK_SCHEDULE_FRAGMENTS = gql`
  fragment WORK_SCHEDULE_FRAGMENTS on WorkSchedule {
    id
    startTime
    endTime
    accepted
    comment
    deletedReason
    acceptedReason
    rejectedReason
    deletedStatus
    adjustHourlyWageRange
    acceptedAt
    clinicalDepartment {
      id
      name
      extraName
      isDisplay
      clinic {
        id
        order
        name
        nameKana
        messageIntroduce
      }
    }
    workScheduleCancellationLetter {
      id
      status
    }
    workOvertime {
      id
      status
      deletedAt
      startTime
      endTime
      earlyDeparture
      lateArrival
      reasonEarlyDeparture
      reasonLateArrival
      workScheduleHistory
      splitDateTime1
      splitDateTime2
      splitDateTime3
      splitHourlyWage1
      splitHourlyWage2
      splitHourlyWage3
      isStartTimeBreakTime
      isSplitDateTime1BreakTime
      isSplitDateTime2BreakTime
      isSplitDateTime3BreakTime
      comment
    }
  }
`

export const DOCTOR_SCHEDULES = gql`
  query DOCTOR_SCHEDULES($startDate: DateTime!, $endDate: DateTime!) {
    doctorMe {
      id
      workSchedules(
        where: { startTime_gte: $startDate, startTime_lte: $endDate }
      ) {
        ...WORK_SCHEDULE_FRAGMENTS
        relatedWorkSchedules {
          ...WORK_SCHEDULE_FRAGMENTS
        }
      }
    }
  }
  ${WORK_SCHEDULE_FRAGMENTS}
`
