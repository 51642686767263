import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { STAFF_ME } from '@medical/layout/StaffLayout/StaffLayout.graphql'
import { useCustom } from '@medical/provider/context'
import React from 'react'

import MyPageScene from './MyPageScene'

const MyPage = () => {
  const [{ i18n }] = useCustom()
  const { loading, error, data } = useQuery(STAFF_ME)

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  return <MyPageScene i18n={i18n} staff={data.staffMe} />
}

export default MyPage
