import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import CustomDropdown from '@medical/components/DropdownForSignup'
import TextInput from '@medical/components/TextInputForCommentPage'
import { useCustom } from '@medical/provider/context'
import { Formik } from 'formik'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'

import { AVAILABLE_SHIFT_DETAIL_GENERATOR } from '../DetailAvailableShift/DetailAvailableShift.graphql'
import { SCHEDULE_AVAILABLESHIFT_CONNECTION } from '../RecruitmentScheduleShift/RecruitmentScheduleShiftList/RecruitmentScheduleShiftList.graphql'
import {
  GET_ENUM_COMMENT,
  STAFF_CREATE_RECRUITMENT_CATEGORY,
  STAFF_UPSERT_RECRUITMENT_CATEGORY,
} from './RecruitmentCategory.graphql'

export const ShiftFormRecruitmentCategoryDetail = ({
  availableShiftId,
  scheduledShiftGroupId,
  recruitmentCategory,
  recruitmentCategoryScheduleGroup,
  isMoneyPermitted = true,
}) => {
  const [{ i18n, popup }] = useCustom()
  let dataComments = recruitmentCategory
  if (recruitmentCategoryScheduleGroup) {
    dataComments = [...dataComments, ...recruitmentCategoryScheduleGroup]
  }
  const [createRecruitment] = useMutation(STAFF_CREATE_RECRUITMENT_CATEGORY)
  const [upsertRecruitment] = useMutation(STAFF_UPSERT_RECRUITMENT_CATEGORY)
  const [categoryOption, setCategoryOption] = useState([])
  const [statusOption, setStatusOption] = useState([])
  const { loading, error, data } = useQuery(GET_ENUM_COMMENT, {
    variables: {
      name: 'RecruitmentCategoryType',
    },
  })
  const {
    loading: statusLoading,
    error: statusError,
    data: statusData,
  } = useQuery(GET_ENUM_COMMENT, {
    variables: {
      name: 'RecruitmentStatusType',
    },
  })
  useEffect(() => {
    if (data && data.enumValues) {
      setCategoryOption(data.enumValues)
    }
    if (statusData && statusData.enumValues) {
      setStatusOption(statusData.enumValues)
    }
  }, [data, statusData])

  if (loading || statusLoading) return <ProgressSpinner />
  if (error || statusError)
    return <ErrorComponent error={error || statusError} />

  const onHandleUpsert = async (values, { resetForm, setSubmitting }) => {
    try {
      const item = values.dataComments.find(
        i => i.id === values.recruitmentCategoryId
      )
      await upsertRecruitment({
        variables: {
          recruitmentCategoryId: item.id,
          category: item.category,
          status: item.status,
          description: item.description,
          action: values.function,
        },
        update: (store, { data: { staffUpsertRecruitmentCategory } }) => {
          if (availableShiftId) {
            const availableShiftData = store.readQuery({
              query: AVAILABLE_SHIFT_DETAIL_GENERATOR(isMoneyPermitted),
              variables: {
                availableShiftId,
                detailWhere: {
                  AvailableShift: {
                    id: availableShiftId,
                  },
                  deletedAt: null,
                },
              },
            })
            store.writeQuery({
              query: AVAILABLE_SHIFT_DETAIL_GENERATOR(isMoneyPermitted),
              data: {
                ...availableShiftData,
                availableShift: {
                  ...availableShiftData.availableShift,
                  recruitmentCategory: availableShiftData.availableShift.recruitmentCategory.filter(
                    comment => comment.id !== availableShiftId
                  ),
                },
              },
              variables: {
                availableShiftId,
                detailWhere: {
                  AvailableShift: {
                    id: availableShiftId,
                  },
                  deletedAt: null,
                },
              },
            })
          }
        },
      })
      resetForm()
      popup.success('成功しました')
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }
  const onAddRecruitment = async (values, { resetForm, setSubmitting }) => {
    try {
      await createRecruitment({
        variables: {
          scheduleAvailableShiftGroupId: values.scheduledShiftGroupId,
          availableShiftId: values.availableShiftId,
          category: values.category,
          status: values.status,
          description: values.description,
        },
        update: (store, { data: { staffCreateRecruitmentCategory } }) => {
          if (availableShiftId) {
            const availableShiftData = store.readQuery({
              query: AVAILABLE_SHIFT_DETAIL_GENERATOR(isMoneyPermitted),
              variables: {
                availableShiftId,
                detailWhere: {
                  AvailableShift: {
                    id: availableShiftId,
                  },
                  deletedAt: null,
                },
              },
            })
            availableShiftData.availableShift.recruitmentCategory.push(
              staffCreateRecruitmentCategory
            )
            store.writeQuery({
              query: AVAILABLE_SHIFT_DETAIL_GENERATOR(isMoneyPermitted),
              data: availableShiftData,
              variables: {
                availableShiftId,
                detailWhere: {
                  AvailableShift: {
                    id: availableShiftId,
                  },
                  deletedAt: null,
                },
              },
            })
          } else if (scheduledShiftGroupId) {
            const variables = {
              where: {
                isRecruitmentShift: true,
                name_contains: '',
                AvailableShift_some: {
                  workSchedule: null,
                },
                id: scheduledShiftGroupId,
              },
            }
            const scheduledAvailableShiftGroupData = store.readQuery({
              query: SCHEDULE_AVAILABLESHIFT_CONNECTION,
              variables,
            })
            scheduledAvailableShiftGroupData.scheduledAvailableShiftGroupsConnection.edges[0].node.recruitmentCategory.push(
              staffCreateRecruitmentCategory
            )
            store.writeQuery({
              query: SCHEDULE_AVAILABLESHIFT_CONNECTION,
              data: scheduledAvailableShiftGroupData,
              variables,
            })
          }
        },
      })
      resetForm()
      popup.success('成功しました')
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }
  const validationSchema = Yup.object().shape({
    category: Yup.string().required('募集項目を入力してください'),
    status: Yup.string().required('募集項目を入力してください'),
    description: Yup.string().when('category', {
      is: 'OTHER',
      then: Yup.string().required('募集項目を入力してください'),
    }),
  })
  return (
    <div className='staff-comment-container'>
      {dataComments.length === 0 ? (
        <div style={{ padding: ' 20px 0' }}>募集項目の登録はありません。</div>
      ) : (
        <Formik
          enableReinitialize
          initialValues={{
            dataComments,
            function: '',
            confirm: false,
            recruitmentCategoryId: '',
          }}
          onSubmit={onHandleUpsert}
        >
          {formikProps => {
            const { values, setFieldValue } = formikProps
            return (
              <React.Fragment>
                <table className='table-comment-container'>
                  <thead
                    style={{
                      textAlign: 'left',
                    }}
                  >
                    <th
                      style={{
                        width: '15%',
                      }}
                    />
                    <th
                      style={{
                        width: '10%',
                      }}
                    />
                    <th
                      style={{
                        width: '2%',
                      }}
                    />
                    <th
                      style={{
                        width: '65%',
                      }}
                    />
                    <th
                      style={{
                        width: '2%',
                      }}
                    />
                    <th
                      style={{
                        width: '5%',
                      }}
                    />
                  </thead>
                  <tbody>
                    {values.dataComments.map((staffComment, index) => (
                      <>
                        <tr
                          key={staffComment.id}
                          className='list-staff-comment-container'
                        >
                          <td className='staff-comment-name'>
                            {`${staffComment?.staff?.lastname} ${staffComment?.staff?.firstname}`}
                          </td>
                          <td className='staff-comment-type'>
                            <Dropdown
                              value={categoryOption.find(
                                option =>
                                  option.value ===
                                  values.dataComments[index].category
                              )}
                              name={`dataComments[${index}].category`}
                              onChange={e => {
                                setFieldValue(
                                  `dataComments[${index}].category`,
                                  e.target.value.value
                                )
                              }}
                              options={categoryOption}
                              optionLabel='description'
                              disabled={!values.dataComments[index].isEditting}
                            />
                          </td>
                          <td>
                            <Dropdown
                              value={statusOption.find(
                                option =>
                                  option.value ===
                                  values.dataComments[index].status
                              )}
                              name={`dataComments[${index}].status`}
                              onChange={e => {
                                setFieldValue(
                                  `dataComments[${index}].status`,
                                  e.target.value.value
                                )
                              }}
                              options={statusOption}
                              optionLabel='description'
                              disabled={!values.dataComments[index].isEditting}
                            />
                          </td>
                          {staffComment.category === 'OTHER' ? (
                            <td>
                              <InputText
                                // onChange={handleChange}
                                value={staffComment.description}
                                name={`dataComments[${index}].description`}
                                onChange={e => {
                                  setFieldValue(
                                    `dataComments[${index}].description`,
                                    e.target.value
                                  )
                                }}
                                disabled={
                                  !values.dataComments[index].isEditting
                                }
                              />
                            </td>
                          ) : (
                            <td />
                          )}

                          <td />
                          <td>
                            {values.dataComments[index].isEditting ? (
                              <Button
                                icon='pi pi-save'
                                disabled={
                                  values.dataComments[index].category !==
                                  'OTHER'
                                    ? false
                                    : values.dataComments[index].description
                                        .length === 0
                                }
                                onClick={() => {
                                  setFieldValue(
                                    `dataComments[${index}].isEditting`,
                                    false
                                  )
                                  setFieldValue(
                                    'confirmationMessage',
                                    '募集項目を編集しますか。'
                                  )
                                  setFieldValue(
                                    'recruitmentCategoryId',
                                    values.dataComments[index].id
                                  )
                                  setFieldValue('function', 'update')
                                  setFieldValue('confirm', true)
                                }}
                              />
                            ) : (
                              <Button
                                icon='pi pi-pencil'
                                onClick={() => {
                                  setFieldValue(
                                    `dataComments[${index}].isEditting`,
                                    true
                                  )
                                }}
                              />
                            )}
                          </td>
                          <td>
                            <Button
                              icon='pi pi-trash'
                              onClick={() => {
                                setFieldValue(
                                  'confirmationMessage',
                                  '募集項目を削除しますか。'
                                )
                                setFieldValue(
                                  'recruitmentCategoryId',
                                  values.dataComments[index].id
                                )
                                setFieldValue('function', 'delete')
                                setFieldValue('confirm', true)
                              }}
                            />
                          </td>
                        </tr>
                        <div style={{ marginBottom: '20px' }} />
                      </>
                    ))}
                  </tbody>
                </table>
                <ConfirmationModal
                  {...formikProps}
                  confirmationMessage={values.confirmationMessage}
                />
              </React.Fragment>
            )
          }}
        </Formik>
      )}

      <Formik
        enableReinitialize
        initialValues={{
          confirm: false,
          description: '',
          status: '',
          category: '',
          availableShiftId: availableShiftId || null,
          scheduledShiftGroupId: scheduledShiftGroupId || null,
        }}
        validationSchema={validationSchema}
        onSubmit={onAddRecruitment}
      >
        {formikProps => {
          const {
            setFieldValue,
            handleChange,
            validateForm,
            setTouched,
            handleSubmit,
            values,
          } = formikProps
          return (
            <div>
              {/* <Dropdown /> */}
              <div className='move-inline' style={{ alignItems: 'start' }}>
                <CustomDropdown
                  {...formikProps}
                  name='category'
                  options={categoryOption}
                  placeholder='募集項目選択'
                  // recruitmentCategory
                />
                <CustomDropdown
                  {...formikProps}
                  name='status'
                  options={statusOption}
                  placeholder='掲載ステータス選択'
                  // recruitmentStatus
                />
                {values.category === 'OTHER' && (
                  <TextInput
                    {...formikProps}
                    name='description'
                    onChange={handleChange}
                    placeholder='入力'
                    inputWidth='100%'
                    // recruitmentCategory
                  />
                )}
              </div>

              <div>
                <Button
                  icon='pi pi-plus'
                  className='reflect-button'
                  label='募集項目を追加'
                  onClick={() => {
                    validateForm().then(response => {
                      if (!Object.keys(response).length) {
                        setFieldValue('confirm', true)
                        handleSubmit()
                      } else {
                        setTouched(response)
                      }
                    })
                  }}
                />
              </div>
            </div>
          )
        }}
      </Formik>
    </div>
  )
}
