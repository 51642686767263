import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import { DOCTOR_FRAGMENTS } from '@medical/layout/DoctorLayout/DoctorLayoutRequest.graphql'
import gql from 'graphql-tag'

export const GET_DOCTOR_WORK = gql`
  query DOCTORS_CONNECTION(
    $where: DoctorWhereInput
    $first: Int
    $skip: Int
    $orderBy: DoctorOrderByInput
  ) {
    doctorsConnection(
      where: $where
      first: $first
      skip: $skip
      orderBy: $orderBy
    ) {
      edges {
        node {
          id
          email
          phoneNumber
          lastname
          firstname
          homeAddress {
            id
            address1
            address2
          }
          departments
          nearestStations
        }
      }
      aggregate(where: $where) {
        count
      }
    }
    ...DEPARTMENTS_FRAGMENT
  }
  ${DEPARTMENTS_FRAGMENT}
`

export const GET_DOCTOR_WORK_SEARCH = gql`
  query doctorSearch(
    $email: String
    $fullName: String
    $phoneNumber: String
    $searchDepartments: [String!]
    $specifyTime: String
    $specifyTimeRange: String
    $dayWorks: [String!]
    $finalWorkDay: [String!]
    $nextWorkDay: [String!]
    $isHoliday: [String!]
    $first: Int
    $skip: Int
  ) {
    doctorSearch(
      email: $email
      fullName: $fullName
      phoneNumber: $phoneNumber
      searchDepartments: $searchDepartments
      specifyTime: $specifyTime
      specifyTimeRange: $specifyTimeRange
      dayWorks: $dayWorks
      finalWorkDay: $finalWorkDay
      nextWorkDay: $nextWorkDay
      isHoliday: $isHoliday
      first: $first
      skip: $skip
    ) {
      data {
        id
        email
        phoneNumber
        lastname
        firstname
        departments
        address1
        address2
        nearestStations
      }
      count
    }
    ...DEPARTMENTS_FRAGMENT
  }
  ${DEPARTMENTS_FRAGMENT}
`

export const DOCTORS_CONNECTION = gql`
  query DOCTORS_CONNECTION(
    $where: DoctorWhereInput
    $first: Int
    $skip: Int
    $orderBy: DoctorOrderByInput
  ) {
    doctorsConnection(
      where: $where
      first: $first
      skip: $skip
      orderBy: $orderBy
    ) {
      edges {
        node {
          id
          firstname
          lastname
          fullName
          firstnameKana
          lastnameKana
          fullNameKana
          gender
          birthday
          medicalLicenseNumber
          departments
          email
          phoneNumber
          homeAddress {
            id
            stateOrRegion
            address1
            address2
            zipCode
          }
          nearestStations
          placeOfWork
          workPattern
          joinBackground
          doctorNo
          joinBackgroundOther
          lastLoggedIn
          blocked
          registrationStatus
          isJoinedSublingualImmunotherapyELearning
          isJoinedOnlineDiagnosticTraining
          medicalRecord
          orca
          questionnaire
          updatedAt
          createdAt
          confirmationNumber
          province
          staffMemo
          countedDoctorLastWorkShift {
            lastWorkday
            lastWorkdayClinic
            lastWorkdayDepartment
            numberShiftWorkedInMorning
            numberShiftWorkedInAfternoon
            numberShiftWorkedInNight
          }
          bankAccount {
            id
            createdAt
            updatedAt
            bankName
            bankCode
            branchName
            branchCode
            accountType
            accountNumber
            accountHolderName
          }
        }
      }
      aggregate(where: $where) {
        count
      }
    }
    ...DEPARTMENTS_FRAGMENT
  }
  ${DEPARTMENTS_FRAGMENT}
`
