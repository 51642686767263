import gql from 'graphql-tag'

export const UPLOAD_SHIFTS = gql`
  mutation UPLOAD_SHIFTS(
    $UserCsvRecords: [UserCsvRecord!]!
    $isAvailableShift: Boolean!
  ) {
    staffUploadShift(
      UserCsvRecords: $UserCsvRecords
      isAvailableShift: $isAvailableShift
    )
  }
`

export const CHECK_UPLOAD_SHIFTS = gql`
  mutation CHECK_UPLOAD_SHIFTS(
    $UserCsvRecords: [UserCsvRecord!]!
    $isAvailableShift: Boolean!
  ) {
    staffCheckUploadShifts(
      UserCsvRecords: $UserCsvRecords
      isAvailableShift: $isAvailableShift
    ) {
      email
      endTime
      errorMessage
      hourlyWage
      numberOfRooms
      splitDateTime1
      splitDateTime2
      splitDateTime3
      splitHourlyWage1
      splitHourlyWage2
      splitHourlyWage3
      startTime
    }
  }
`
