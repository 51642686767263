import gql from 'graphql-tag'

export const STAFF_CREATE_SCHEDULED_SHIFT_GROUP = gql`
  mutation CreateScheduledAvailableShiftGroupMutation(
    $doctorId: ID!
    $defaultHourlyWageId: ID
    $accepted: Boolean
    $data: ScheduledAvailableShiftGroupCreateInput!
    $dataTime: DayOfWeekInput!
    $isHolidays: [String!]
    $repeatSetting: RepeatSettingTypes
    $dateMatrix: String
    $mailContent: String
    $comment: String
    $staffComments: [WorkScheduleCommentCreateInput!]
    $doctorSubsidy: [DoctorSubsidyForCreateInput!]
    $isPublished: Boolean
    $isHolidayRepeat: Boolean
  ) {
    staffCreateScheduledAvailableShiftGroup(
      doctorId: $doctorId
      defaultHourlyWageId: $defaultHourlyWageId
      dataTime: $dataTime
      accepted: $accepted
      isHolidays: $isHolidays
      mailContent: $mailContent
      data: $data
      repeatSetting: $repeatSetting
      isHolidayRepeat: $isHolidayRepeat
      dateMatrix: $dateMatrix
      comment: $comment
      staffComments: $staffComments
      doctorSubsidy: $doctorSubsidy
      isPublished: $isPublished
    ) {
      id
      name
      startDate
      endDate
      availableShifts {
        updatedHourlyWageAt
        startTime
        endTime
        hourlyWage
      }
      isAvailableOnMonday
      isAvailableOnTuesday
      isAvailableOnWednesday
      isAvailableOnThursday
      isAvailableOnFriday
      isAvailableOnSunday
      isAvailableOnSaturday
      isAvailableOnHoliday
      defaultHourlyWage {
        hourlyWage
        workPattern
      }
    }
  }
`

export const STAFF_CHECK_AVAILABLE_SHIFT_CREATE_IN_CLINIC_WORK_TIME = gql`
  mutation STAFF_CHECK_AVAILABLE_SHIFT_CREATE_IN_CLINIC_WORK_TIME(
    $clinicId: ID!
    $date: DateTime!
    $startTime: DateTime!
    $endTime: DateTime!
    $isHoliday: Boolean!
  ) {
    staffCheckAvailableShiftCreateInClinicWorkTime(
      clinicId: $clinicId
      date: $date
      startTime: $startTime
      endTime: $endTime
      isHoliday: $isHoliday
    )
  }
`
