import gql from 'graphql-tag'

export const STAFF_GROUP_FRAGMENT = gql`
  fragment STAFF_GROUP_FRAGMENT on StaffGroup {
    id
    name
  }
`

export const STAFF_GROUPS_QUERY = gql`
  fragment STAFF_GROUPS_QUERY on Query {
    staffGroups {
      ...STAFF_GROUP_FRAGMENT
    }
  }
  ${STAFF_GROUP_FRAGMENT}
`

export const STAFF_GROUPS = gql`
  query STAFF_GROUPS {
    ...STAFF_GROUPS_QUERY
  }
  ${STAFF_GROUPS_QUERY}
`

export const DELETE_STAFF_GROUP = gql`
  mutation DELETE_STAFF_GROUP($staffGroupId: ID!) {
    staffDeleteStaffGroup(staffGroupId: $staffGroupId)
  }
`
