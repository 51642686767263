import gql from 'graphql-tag'

export const RECRUITMENT_POSTS_CONNECTION = gql`
  query Query(
    $where: RecruitmentPostWhereInput
    $orderBy: RecruitmentPostOrderByInput
    $first: Int
    $skip: Int
  ) {
    recruitmentPostsConnection(
      where: $where
      orderBy: $orderBy
      first: $first
      skip: $skip
    ) {
      edges {
        node {
          id
          isPublished
          title
          updatedAt
          createdAt
          status
          recruitmentDoctorType
          imageKey
          content
          clinicalDepartment {
            id
            name
            extraName
            departmentCode
            isDisplay
            clinic {
              id
              name
              order
              isPublished
            }
          }
        }
      }
      aggregate(where: $where) {
        count
      }
    }
  }
`
