import gql from 'graphql-tag'

export const TOTAL_AMOUNT_DOCTOR_SALARY_LIST = gql`
  query TOTAL_AMOUNT_DOCTOR_SALARY_LIST(
    $where: DoctorWhereInput
    $date: DateTime!
    $first: Int
    $skip: Int
    $orderBy: DoctorOrderByInput
  ) {
    doctorsConnection(
      where: $where
      first: $first
      skip: $skip
      orderBy: $orderBy
    ) {
      aggregate(where: $where) {
        count
      }
      edges {
        node {
          id
          fullName
          email
          doctorMonthlyTransportationExpenses(date: $date) {
            id
            createdAt
            deletedAt
            endTime
            startTime
            cost
            category
            type
            blockedDays
          }
          doctorMonthlyWorkSchedules(date: $date) {
            id
            startTime
            endTime
            notApplyCostTransportation
            acceptedShift {
              id
              dailySalary
              startTime
              endTime
            }
            costTransportationByDoctorWorkSchedule {
              id
              cost
              startTime
              endTime
              returnPath
            }
            clinicalDepartment {
              name
            }
            doctorSubsidy {
              id
              title
              money
            }
            adjustHourlyWageRange
          }
          doctorMonthlyIncentive(date: $date)
          departments
        }
      }
    }
  }
`

export const WORK_SCHEDULE_TOTAL_AMOUNT_DOCTOR = gql`
  query WORK_SCHEDULE_TOTAL_AMOUNT_DOCTOR($where: WorkScheduleWhereInput, $skip: Int, $first: Int) {
    workSchedules(where: $where, skip: $skip, first: $first) {
      doctor {
        id
      }
      id
    }
  }
`

export const LIST_MONTHLY_DOCTOR_INCENTIVE_IDS_QUERY = gql`
  query LIST_MONTHLY_DOCTOR_INCENTIVE_IDS_QUERY($date: DateTime) {
    listMonthlyDoctorIncentiveId(date: $date)
  }
`

export const LIST_MONTHLY_DOCTOR_TRANSPORTATION_IDS_QUERY = gql`
  query LIST_MONTHLY_DOCTOR_TRANSPORTATION_IDS_QUERY($date: DateTime) {
    listMonthlyDoctorTransportationId(date: $date)
  }
`

export const TOTAL_AMOUNT_DOCTOR_SALARY_IDS_LIST = gql`
  query TOTAL_AMOUNT_DOCTOR_SALARY_IDS_LIST(
    $where: DoctorSalaryListInput
    $date: DateTime!
    $first: Int
    $skip: Int
    $orderBy: DoctorOrderByInput
  ) {
    getAmountDoctorSalaryList(
      where: $where
      first: $first
      skip: $skip
      orderBy: $orderBy
    ) {
      aggregate(where: $where) {
        count
      }
      edges {
        node {
          id
          fullName
          firstname
          email
          doctorMonthlyTransportationExpenses(date: $date) {
            id
            createdAt
            deletedAt
            endTime
            startTime
            cost
            category
            type
            blockedDays
          }
          doctorMonthlyWorkSchedules(date: $date) {
            id
            startTime
            endTime
            notApplyCostTransportation
            acceptedShift {
              id
              dailySalary
              startTime
              endTime
            }
            costTransportationByDoctorWorkSchedule {
              id
              cost
              startTime
              endTime
              returnPath
            }
            clinicalDepartment {
              name
            }
            doctorSubsidy {
              id
              title
              money
            }
            adjustHourlyWageRange
          }
          doctorMonthlyIncentive(date: $date)
          departments
        }
      }
    }
  }
`
export const CREATE_ACTIVITY = gql`
  mutation CREATE_ACTIVITY_LOG($activity: ActivityLogTypes!, $doctorId: ID) {
    createActivityLogHistory(activity: $activity, doctorId: $doctorId)
  }
`