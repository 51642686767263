import gql from 'graphql-tag'

export const GET_INCENTIVE = gql`
  query IncentiveDetailsConnection(
    $orderBy: IncentiveDetailOrderByInput
    $skip: Int
    $first: Int
    $where: IncentiveDetailWhereInput
  ) {
    incentiveDetailsConnection(
      orderBy: $orderBy
      skip: $skip
      first: $first
      where: $where
    ) {
      aggregate(where: $where) {
        count
      }
      edges {
        node {
          id
          createdAt
          applyTime
          incentiveTotalAmount
          doctor {
            id
            email
            lastname
            firstname
          }
          incentive {
            id
            type
            title
          }
        }
      }
    }
  }
`
export const DELETE_INCENTIVE_DETAIL = gql`
  mutation STAFF_DELETE_INCENTIVE_DETAIL($incentiveDetailId: ID!) {
    staffDeleteIncentiveDetail(incentiveDetailId: $incentiveDetailId)
  }
`

export const GET_INCENTIVE_ALL = gql`
  query IncentiveDetailsConnection(
    $orderBy: IncentiveDetailOrderByInput
    $where: IncentiveDetailWhereInput
  ) {
    incentiveDetailsConnection(orderBy: $orderBy, where: $where) {
      edges {
        node {
          id
          createdAt
          applyTime
          incentiveTotalAmount
          doctor {
            id
            email
            lastname
            firstname
          }
          incentive {
            id
            type
            title
          }
        }
      }
    }
  }
`
export const CREATE_ACTIVITY = gql`
  mutation CREATE_ACTIVITY_LOG($activity: ActivityLogTypes!, $doctorId: ID) {
    createActivityLogHistory(activity: $activity, doctorId: $doctorId)
  }
`