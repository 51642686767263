import moment from 'moment'

const convertMinuteAndHour = time => {
  const rHours = Math.floor(time).toString()
  const minutes = (time - rHours) * 60
  const rMinutes = Math.round(minutes).toString()
  return {
    rHours: rHours < 10 ? `0${rHours}` : rHours,
    rMinutes: rMinutes < 10 ? `0${rMinutes}` : rMinutes,
  }
}

export const calcWorkScheduleTotalHours = workSchedules => {
  const totalHours = workSchedules.reduce(
    (acc, curr) =>
      acc + moment(curr.endTime).diff(moment(curr.startTime), 'minutes') / 60,
    0
  )
  return totalHours
}

export const calcWorkScheduleTotalSalary = workSchedules => {
  const totalSalary = workSchedules.reduce(
    (acc, curr) => acc + curr.dailySalary,
    0
  )

  return totalSalary
}

export const calcWorkScheduleTotalDays = workSchedules => {
  const totalDays = workSchedules.reduce((acc, workSchedule) => {
    const key = moment(workSchedule.startTime).startOf('day')
    if (!acc[key]) {
      acc[key] = true
    }
    return acc
  }, {})
  return Object.keys(totalDays).length
}

export const calcWorkScheduleTotalHourPerDay = workSchedule => {
  const calcTotal = parseFloat(
    (
      moment(workSchedule.endTime).diff(workSchedule.startTime, 'minutes') / 60
    ).toFixed(2)
  )
  const { rHours, rMinutes } = convertMinuteAndHour(calcTotal)
  const result = `${rHours}:${rMinutes}`
  return result
}

export const convertTime = time => {
  const { rHours, rMinutes } = convertMinuteAndHour(time)
  return `${rHours}時間${rMinutes}分`
}

export const calcTotalTransportationExpense = (
  transportationExpenses,
  sortedWorkSchedules
) => {
  const totalCostTransportationExpense = transportationExpenses
    .filter(item => !item.deletedAt)
    .reduce((acc, expense) => acc + expense.cost, 0)

  const totalCostTransportationByWorkSchedules = sortedWorkSchedules
    .filter(
      item =>
        !item.notApplyCostTransportation ||
        item.notApplyCostTransportation === null
    )
    .reduce(
      (acc, ws) =>
        acc +
        ws.costWs +
        (ws.costTransportationByDoctorWorkSchedule
          ? parseInt(ws.costTransportationByDoctorWorkSchedule)
          : ws.costWs),
      0
    )
  return totalCostTransportationExpense + totalCostTransportationByWorkSchedules
}
