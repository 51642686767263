import { Checkbox } from 'primereact/checkbox'
import { pathOr } from 'ramda'
import React from 'react'

const CustomCheckBox = ({
  label,
  name,
  disabled,
  values,
  touched,
  errors,
  setFieldValue,
  options = [],
  optionLabel = 'description',
  valueLabel = 'value',
  required,
  getCurrentValue,
  onceCheck,
  breakLine,
  inLine,
  reponsiveUI,
  customTH,
  customTD,
  hasPermitted = () => true,
}) => {
  const isTouched = pathOr(false, [name], touched)
  const error = pathOr('', [name], errors)
  return (
    <div className={`tr ${reponsiveUI ? reponsiveUI : ''}`}>
      <div
        className={`th ${breakLine ? 'reson-label_check' : ''} ${
          required ? 'required' : ''
        } ${customTH ? customTH : ''}`}
      >
        {label}
      </div>
      <div className={`td ${inLine ? 'inline-row' : ''} ${customTD ? customTD : ''}`}>
        {options.map((option, i) => (
          <div
            className={`checkbox-container ${
              inLine ? 'inline-row_container' : ''
            }`}
            key={option[valueLabel]}
          >
            <Checkbox
              value={option[valueLabel]}
              disabled={disabled}
              onChange={e => {
                if (hasPermitted()) {
                  const selectedOptions =
                    name === 'doctorStatus' || onceCheck
                      ? [values[name]]
                      : [...values[name]]
                  if (values[name].includes(e.value)) {
                    selectedOptions.splice(selectedOptions.indexOf(e.value), 1)
                  } else {
                    if (name === 'doctorStatus') {
                      selectedOptions.length = 0
                      getCurrentValue(e.value)
                    }
                    if (onceCheck) {
                      selectedOptions.length = 0
                    }
                    selectedOptions.push(e.value)
                  }
                  setFieldValue(name, selectedOptions)
                }
              }}
              checked={values[name].includes(option[valueLabel])}
              inputId={`${option[valueLabel]}`}
              name={name}
            />
            <label htmlFor={`${option[valueLabel]}`} className='checkbox-label'>
              {option[optionLabel]}
            </label>

            {values[name].includes(option[valueLabel]) && option.note && (
              <p
                className='alert'
                style={{
                  margin: '0 0 0 12px',
                }}
              >
                {option.note}
              </p>
            )}
          </div>
        ))}
        <div className='alert'>{isTouched && error}</div>
      </div>
    </div>
  )
}

export default CustomCheckBox
