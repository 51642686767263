import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import React from 'react'
import { useTranslation } from 'react-i18next'

const ConfirmationModal = ({
  values,
  isSubmitting,
  handleSubmit,
  handleNoClick,
  setFieldValue,
  confirmationMessage,
  children,
  visibleKey = 'confirm',
}) => {
  const { i18n } = useTranslation()
  const noClick = () => setFieldValue(visibleKey, false)
  return (
    <Dialog
      header={i18n.t('staff.detailDoctorIdentity.title')}
      visible={values[visibleKey]}
      className='p-dialog'
      footer={
        <div>
          <Button
            label={i18n.t('staff.detailDoctorIdentity.cancelButton')}
            icon='pi pi-times'
            onClick={handleNoClick || noClick}
            className='cancel-button'
            disabled={isSubmitting}
          />
          <Button
            className='reflect-button'
            type='submit'
            label={i18n.t('staff.detailDoctorIdentity.sendButton')}
            icon={`pi pi-${isSubmitting ? 'spinner pi-spin' : 'check'}`}
            disabled={isSubmitting}
            onClick={handleSubmit}
          />
        </div>
      }
      closable={!isSubmitting}
      onHide={handleNoClick || noClick}
    >
      {children}
      {confirmationMessage}
    </Dialog>
  )
}

export default ConfirmationModal
