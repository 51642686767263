import { dateValidation } from '@medical/libs'
import Router from '@medical/routes/router'
import moment from 'moment'
import { Button } from 'primereact/button'
import React from 'react'
import { Link } from 'react-router-dom'

const DoctorsMonthlyTransportationExpensesHeader = ({ date }) => {
  const prevMonth = moment(date).subtract(1, 'month')
  const nextMonth = moment(date).add(1, 'month')
  const isPrevDisabled =
    dateValidation(
      {
        year: prevMonth.year(),
        month: prevMonth.month() + 1,
      },
      true
    ) ||
    moment(
      `${prevMonth.year()} ${prevMonth.month() + 1} 01`,
      'YYYY MM DD'
    ).isBefore(moment().subtract(12, 'months'), 'month')
  const isNextDisabled = nextMonth.isSameOrAfter(moment().endOf('month'))
  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', margin: '1rem 0' }}
    >
      <Link
        to={Router.get(Router.staffDoctorsMonthlyTransportationExpenses, {
          year: prevMonth.year(),
          month: prevMonth.month() + 1,
        })}
        onClick={isPrevDisabled ? e => e.preventDefault() : undefined}
      >
        <Button
          icon='pi pi-chevron-left'
          style={{ fontSize: '14px' }}
          disabled={isPrevDisabled}
        />
      </Link>
      <div className='calendar-title' style={{ margin: '0 3rem' }}>
        {date.format('YYYY年M月')}
      </div>
      <Link
        to={Router.get(Router.staffDoctorsMonthlyTransportationExpenses, {
          year: nextMonth.year(),
          month: nextMonth.month() + 1,
        })}
        onClick={isNextDisabled ? e => e.preventDefault() : undefined}
      >
        <Button
          icon='pi pi-chevron-right'
          style={{ fontSize: '14px' }}
          disabled={isNextDisabled}
        />
      </Link>
    </div>
  )
}
export default DoctorsMonthlyTransportationExpensesHeader
