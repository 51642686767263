import moment from 'moment'

const addSplit = (setFieldValue, values, slipt, index) => {
  setFieldValue('splits', [
    ...values.splits,
    {
      clinic: slipt.clinic,
      url: slipt.url,
      departurePlace: slipt.departurePlace,
      arrivalPlace: slipt.arrivalPlace,
      cost: slipt.cost,
      isCheckBox: slipt.isCheckBox,
      roundTrip: slipt.roundTrip
        ? {
            urlRoundTrip: slipt.roundTrip.urlRoundTrip,
            departurePlaceRoundTrip: slipt.roundTrip.departurePlaceRoundTrip,
            arrivalPlaceRoundTrip: slipt.roundTrip.arrivalPlaceRoundTrip,
            costRoundTrip: slipt.roundTrip.costRoundTrip,
          }
        : null,
      startTime: moment
        .utc()
        .startOf('month')
        .toISOString(),
    },
  ])

  setFieldValue(
    `splits[${index}].endTime`,
    moment
      .utc()
      .startOf('month')
      .subtract(1, 'days')
      .endOf('days')
  )

  setFieldValue(`splits[${index}].isHistory`, moment())
  setFieldValue(`splits[${index}].isPast`, true)
}
export default addSplit
