import { Chips as PrimeChips } from 'primereact/chips'
import React, { useRef } from 'react'

const Chips = ({
  label,
  name,
  setFieldValue,
  values,
  disabled,
  componentClassName,
  referenceVariable,
  hasPermitted = () => true,
}) => {
  const ref = useRef(null)
  return (
    <div className='tr'>
      {label && <div className='th'>{label}</div>}
      <div className='td'>
        {disabled ? (
          values[name].map((value, kk) => (
            <div key={kk} className='slashed-item'>
              {value}
            </div>
          ))
        ) : (
          <PrimeChips
            ref={ref}
            className={componentClassName}
            value={
              values[referenceVariable].map(item => item.fullNameEmail) || []
            }
            onChange={e => {
              if (hasPermitted()) {
                setFieldValue(name, [...new Set(e.value)])
              }
            }}
            onRemove={e => {
              if (hasPermitted()) {
                let tempList = values[referenceVariable]
                tempList = tempList.filter(
                  item => item.fullNameEmail !== e.value[0]
                )
                setFieldValue(referenceVariable, tempList)
              }
            }}
            onBlur={() => {
              if (ref.current.inputElement.value) {
                setFieldValue(name, [
                  ...new Set([...values[name], ref.current.inputElement.value]),
                ])
                ref.current.inputElement.value = ''
              }
            }}
            disabled={disabled}
          />
        )}
      </div>
    </div>
  )
}

export default Chips
