import { ADDRESS_FRAGMENTS } from '@medical/layout/DoctorLayout/DoctorLayoutRequest.graphql'
import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import {
  AVAILABLE_SHIFT_FRAGMENTS,
  AVAILABLE_SHIFT_FRAGMENTS_NO_MONEY,
} from '@medical/pages/doctor/AvailableShift/AvailableShift.graphql'
import { WORK_SCHEDULE_FRAGMENTS } from '@medical/pages/doctor/Schedule/Schedule.graphql'
import { WORK_SCHEDULE_COMMENT_FRAGMENTS } from '@medical/pages/staff/DetailWorkSchedule/DetailWorkSchedule.graphql'
import gql from 'graphql-tag'

export const AVAILABLE_SHIFT_DETAIL_GENERATOR = isMoneyPermitted => {
  if (isMoneyPermitted) {
    return gql`
      query AVAILABLE_SHIFT_DETAIL(
        $availableShiftId: ID
        $detailWhere: AvailableShiftDetailWhereInput
      ) {
        staffMe {
          id
        }
        availableShift(where: { id: $availableShiftId }) {
          ...AVAILABLE_SHIFT_FRAGMENTS
          staffComments {
            ...WORK_SCHEDULE_COMMENT_FRAGMENTS
          }
          isEarlySpecial
          isDoubleRecruitment
          recruitmentCategory(where: { deletedAt: null }) {
            id
            status
            category
            description
            deletedAt
            staff {
              id
              lastname
              firstname
            }
          }
          scheduledAvailableShiftGroup {
            id
            staffComments {
              ...WORK_SCHEDULE_COMMENT_FRAGMENTS
            }
            recruitmentCategory(where: { deletedAt: null }) {
              id
              status
              category
              description
              deletedAt
              staff {
                id
                lastname
                firstname
              }
            }
          }
          isSetIndividually
          applyWith
          workPatternApply
          timeSettingHideShift
          availableShiftDetails(where: $detailWhere) {
            doctor {
              id
              blocked
              fullName
              email
            }
            deletedAt
          }
        }
        activityHistories(where: { availableShift: $availableShiftId }) {
          id
          createdAt
          staff {
            firstname
            lastname
          }
          activity
          availableShift
        }
        ...DEPARTMENTS_FRAGMENT
      }
      ${AVAILABLE_SHIFT_FRAGMENTS}
      ${DEPARTMENTS_FRAGMENT}
      ${WORK_SCHEDULE_COMMENT_FRAGMENTS}
    `
  }
  if (!isMoneyPermitted) {
    return gql`
      query AVAILABLE_SHIFT_DETAIL($availableShiftId: ID) {
        staffMe {
          id
        }
        availableShift(where: { id: $availableShiftId }) {
          ...AVAILABLE_SHIFT_FRAGMENTS
          staffComments {
            ...WORK_SCHEDULE_COMMENT_FRAGMENTS
          }
          recruitmentCategory {
            id
          }
          scheduledAvailableShiftGroup {
            id
            staffComments {
              ...WORK_SCHEDULE_COMMENT_FRAGMENTS
            }
          }
        }
        activityHistories(where: { availableShift: $availableShiftId }) {
          id
          createdAt
          staff {
            firstname
            lastname
          }
          activity
          availableShift
        }
        ...DEPARTMENTS_FRAGMENT
      }
      ${AVAILABLE_SHIFT_FRAGMENTS}
      ${DEPARTMENTS_FRAGMENT}
      ${WORK_SCHEDULE_COMMENT_FRAGMENTS}
    `
  }
  return gql`
    query AVAILABLE_SHIFT_DETAIL($availableShiftId: ID) {
      availableShift(where: { id: $availableShiftId }) {
        ...AVAILABLE_SHIFT_FRAGMENTS_NO_MONEY
      }
      ...DOCTORS_QUERY
      ...DEPARTMENTS_FRAGMENT
    }
    ${AVAILABLE_SHIFT_FRAGMENTS_NO_MONEY}
    ${DEPARTMENTS_FRAGMENT}
  `
}

export const DELETE_AVAILABLE_SHIFT = gql`
  mutation DELETE_AVAILABLE_SHIFT($availableShiftId: ID!) {
    staffDeleteAvailableShift(availableShiftId: $availableShiftId)
  }
`

export const UPDATE_AVAILABLE_SHIFT = gql`
  mutation UPDATE_AVAILABLE_SHIFT(
    $availableShiftId: ID!
    $startTime: DateTime
    $endTime: DateTime
    $hourlyWage: Int
    $splitDateTime1: DateTime
    $splitDateTime2: DateTime
    $splitDateTime3: DateTime
    $splitHourlyWage1: Int
    $splitHourlyWage2: Int
    $splitHourlyWage3: Int
    $comment: String
    $isPublished: Boolean
    $permissionAccess: Boolean
    $isStartTimeBreakTime: Boolean
    $isSplitDateTime1BreakTime: Boolean
    $isSplitDateTime2BreakTime: Boolean
    $isSplitDateTime3BreakTime: Boolean
    $staffComments: [WorkScheduleCommentCreateInput!]
    $isSpecial: Boolean
    $isEarlySpecial: Boolean
    $isDoubleRecruitment: Boolean
    $isOutGroup: Boolean
    $isSetIndividually: Boolean!
    $applyWith: IncentiveApplyType
    $workPatternApply: [String!]
    $listDoctorId: [ID!]
    $timeSettingHideShift: DateTime
  ) {
    staffUpdateAvailableShift(
      availableShiftId: $availableShiftId
      startTime: $startTime
      endTime: $endTime
      hourlyWage: $hourlyWage
      splitDateTime1: $splitDateTime1
      splitDateTime2: $splitDateTime2
      splitDateTime3: $splitDateTime3
      splitHourlyWage1: $splitHourlyWage1
      splitHourlyWage2: $splitHourlyWage2
      splitHourlyWage3: $splitHourlyWage3
      comment: $comment
      isPublished: $isPublished
      permissionAccess: $permissionAccess
      isStartTimeBreakTime: $isStartTimeBreakTime
      isSplitDateTime1BreakTime: $isSplitDateTime1BreakTime
      isSplitDateTime2BreakTime: $isSplitDateTime2BreakTime
      isSplitDateTime3BreakTime: $isSplitDateTime3BreakTime
      staffComments: $staffComments
      isSpecial: $isSpecial
      isEarlySpecial: $isEarlySpecial
      isDoubleRecruitment: $isDoubleRecruitment
      isOutGroup: $isOutGroup
      isSetIndividually: $isSetIndividually
      applyWith: $applyWith
      workPatternApply: $workPatternApply
      listDoctorId: $listDoctorId
      timeSettingHideShift: $timeSettingHideShift
    ) {
      ...AVAILABLE_SHIFT_FRAGMENTS
    }
  }
  ${AVAILABLE_SHIFT_FRAGMENTS}
`

export const CONNECT_AVAILABLE_SHIFT_TO_DOCTOR = gql`
  mutation CONNECT_AVAILABLE_SHIFT_TO_DOCTOR(
    $availableShiftId: ID!
    $doctorId: ID!
    $acceptedReason: String
    $type: workTypes
    $introductionHistory: IntroductionHistoryType
    $introductionHistoryOther: String
    $staffComments: [WorkScheduleCommentCreateInput!]
  ) {
    staffCreateWorkSchedule(
      availableShiftId: $availableShiftId
      doctorId: $doctorId
      acceptedReason: $acceptedReason
      type: $type
      introductionHistory: $introductionHistory
      introductionHistoryOther: $introductionHistoryOther
      staffComments: $staffComments
    ) {
      ...WORK_SCHEDULE_FRAGMENTS
    }
  }
  ${WORK_SCHEDULE_FRAGMENTS}
`

export const ACTIVITIES_HISTORY = gql`
  query ACTIVITIES_HISTORY($where: ActivityHistoryWhereInput) {
    activityHistories(where: $where) {
      id
      createdAt
      activity
      staff {
        id
        firstname
        lastname
      }
    }
  }
`

export const DOCTORS_CONNECTION = gql`
  query DOCTORS_CONNECTION(
    $where: DoctorWhereInput
    $first: Int
    $skip: Int
    $orderBy: DoctorOrderByInput
  ) {
    doctorsConnection(
      where: $where
      first: $first
      skip: $skip
      orderBy: $orderBy
    ) {
      edges {
        node {
          id
          fullName
          fullNameKana
          email
          blocked
          workPattern
          isAvailableOnMonday
          isAvailableOnTuesday
          isAvailableOnWednesday
          isAvailableOnThursday
          isAvailableOnFriday
          isAvailableOnSaturday
          isAvailableOnSunday
          isAvailableOnHoliday
          registrationStatus
          firstWorkday
          nearestStations
          homeAddress {
            ...ADDRESS_FRAGMENTS
          }
        }
      }
      aggregate(where: $where) {
        count
      }
    }
  }
  ${ADDRESS_FRAGMENTS}
`
