/* eslint-disable no-nested-ternary */
import ConfirmationModal from '@medical/components/ConfirmationModal'
import ModalLink from '@medical/components/ModalLink'
import { PreviewImgDialog } from '@medical/components/PreviewImgDialog'
import types from '@medical/constant/typeTransports'
import numeralCurrency from '@medical/libs/numeralCurrency'
import Router from '@medical/routes/router'
import { Modal, Select } from 'antd'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'

import TransportReportsHeader from './TransportReportsHeader'
import TransportReportsSceneMobile from './TransportReportsSceneMobile'

const TransportReportsScene = ({
  i18n,
  date,
  data,
  year,
  month,
  totalDays,
  totalCost,
  listDuplicates,
  numberIncentive,
  onSubmit,
  isBlockedMyCar,
  hideModal,
  visible,
  showModal,
  openImgDialog,
  handleOpenImgDialog,
}) => {
  const [dataImg, setDataImg] = useState()
  const { Option } = Select
  const getType = val => {
    let rs

    types.map(as => {
      if (as.value == val) {
        rs = as.description
      }
    })
    return rs
  }
  return (
    <div className='container  container-transport-report mw-1590'>
      <div className='top-right-btn'>
        <ModalLink
          to={Router.get(Router.doctorTransportByCarReports, {
            year,
            month,
          })}
        >
          <Button
            className='btn-carTransportPeriodicModal'
            type='button'
            label={i18n.t('transportApplication.carTransportPeriodicModal')}
          />
        </ModalLink>
      </div>
      <div className='box '>
        <Formik
          enableReinitialize
          initialValues={{
            confirm: false,
            isMyCar: '',
            workScheduleId: '',
          }}
          onSubmit={onSubmit}
          render={formikProps => {
            const { setFieldValue } = formikProps
            const confirmationMessage = i18n.t('main.movingExpenses.updateType')
            const isDisable = true
            return (
              <>
                <div className='schedule-calendar'>
                  <div className='calendar-container'>
                    <div style={{ paddingTop: '50px' }}>
                      <div className='overflow-container'>
                        {isMobile ? (
                          data.map((split, index) => (
                            <TransportReportsSceneMobile
                              {...formikProps}
                              i18n={i18n}
                              split={split}
                              isDisable={isDisable}
                              isBlockedMyCar={isBlockedMyCar}
                              hideModal={hideModal}
                              visible={visible}
                              showModal={showModal}
                              openImgDialog={openImgDialog}
                              handleOpenImgDialog={handleOpenImgDialog}
                            />
                          ))
                        ) : (
                          <table
                            className='table'
                            style={{ marginBottom: '10px' }}
                          >
                            <thead>
                              <tr>
                                <th width='14%'>
                                  {i18n.t('transportApplication.date')}
                                </th>
                                <th width='14%'>
                                  {i18n.t(
                                    'transportApplication.dateApplication'
                                  )}
                                </th>
                                <th width='11%'>{i18n.t('main.clinicName')}</th>
                                <th width='11%'>
                                  {i18n.t('main.departmentName')}
                                </th>

                                <th width='10%'>
                                  {i18n.t(
                                    'transportApplication.categoryApplication'
                                  )}
                                </th>
                                <th width='10%'>
                                  {i18n.t('transportApplication.fareOneway')}
                                </th>
                                <th width='10%'>
                                  {i18n.t('transportApplication.fareRoundTrip')}
                                </th>
                                <th width='10%'>
                                  {i18n.t(
                                    'transportApplication.feeApplication'
                                  )}
                                  <Link
                                    style={{ color: '#40a9ff' }}
                                    to='#'
                                    onClick={showModal}
                                  >
                                    ※
                                  </Link>
                                  <Modal
                                    title='申請料金について'
                                    className='modal-antd'
                                    closable={false}
                                    visible={visible}
                                    okButtonProps={{
                                      style: {
                                        display: 'none',
                                      },
                                    }}
                                    onCancel={hideModal}
                                    cancelText={i18n.t('main.close')}
                                  >
                                    <span>
                                      「その他交通費申請」より申請し、承認された場合の金額がこちらに表示されます。拠点間での移動や普段とは違う経路で通勤した場合は、「その他交通費申請」メニューより経路と金額を申請してください。
                                    </span>
                                  </Modal>
                                </th>
                                <th width='12%'>
                                  {i18n.t('transportApplication.receipt')}
                                </th>
                              </tr>
                            </thead>
                            <tbody style={{ textAlign: 'center' }}>
                              {data.length > 0 ? (
                                data.map((item, index) => (
                                  <tr
                                    key={index}
                                    className={
                                      item.deletedAt ||
                                      item.notApplyCostTransportation
                                        ? 'background-grey'
                                        : listDuplicates.includes(index)
                                        ? 'background-pink'
                                        : null
                                    }
                                  >
                                    <td>
                                      <span>
                                        {!item.isTransport || item.category
                                          ? moment(item.startTime).format(
                                              'LL (dd)'
                                            )
                                          : !item.startTime
                                          ? moment(item.endTime).format(
                                              'LL (dd)'
                                            )
                                          : null}
                                      </span>
                                      <p>
                                        {!item.isTransport
                                          ? null
                                          : !item.startTime
                                          ? '(事務局入力)'
                                          : null}
                                      </p>
                                    </td>
                                    <td>
                                      <span>
                                        {item.isTransport && !item.departmentID
                                          ? moment(item.createdAt).format(
                                              'LL (dd)'
                                            )
                                          : !item.startTime
                                          ? moment(item.createdAt)
                                              .utc()
                                              .format('LL (dd)')
                                          : null}
                                      </span>
                                      <p>
                                        {!item.isTransport
                                          ? null
                                          : !item.startTime
                                          ? '(事務局入力)'
                                          : null}
                                      </p>
                                    </td>
                                    <td>
                                      {item.category
                                        ? item.clinicDate
                                        : item.clinic}
                                      {item.costTransportationByDoctorWorkSchedule >
                                        0 && !item.isTypeTransportation ? (
                                        <>
                                          <div className='border-top' />
                                          {item.clinic}
                                        </>
                                      ) : null}
                                    </td>
                                    <td>
                                      {item.clinicalDepartment}
                                      {item.costTransportationByDoctorWorkSchedule >
                                        0 && !item.isTypeTransportation ? (
                                        <>
                                          <div className='border-top' />
                                          {item.clinicalDepartment}
                                        </>
                                      ) : null}
                                    </td>
                                    <td>
                                      {item.isTransport ? (
                                        item.type
                                      ) : item.category ===
                                        'RECURRING_TRAVEL_FEE' ? (
                                        getType(item.type)
                                      ) : item.isShowSelectType ? (
                                        <Select
                                          value={
                                            item.isTypeTransportation === true
                                              ? '1'
                                              : '0'
                                          }
                                          style={{ width: 120 }}
                                          disabled={
                                            !isDisable ||
                                            item.deletedAt ||
                                            item.notApplyCostTransportation
                                          }
                                          onChange={async e => {
                                            setFieldValue(
                                              'workScheduleId',
                                              item.id
                                            )
                                            setFieldValue('isMyCar', e !== '0')
                                            setFieldValue('confirm', true)
                                          }}
                                        >
                                          <Option value='0'>
                                            公共交通機関
                                          </Option>
                                          <Option
                                            disabled={!isBlockedMyCar}
                                            value='1'
                                          >
                                            マイカー利用
                                          </Option>
                                        </Select>
                                      ) : null}
                                    </td>
                                    <td>
                                      {!item.isTransport
                                        ? numeralCurrency(item.costWs)
                                        : null}

                                      {item.departmentID
                                        ? item.type === null &&
                                          item.startTime === null
                                          ? null
                                          : numeralCurrency(item.cost / 2)
                                        : null}
                                      {item.costTransportationByDoctorWorkSchedule >
                                        0 &&
                                        !item.isTypeTransportation && (
                                          <>
                                            <div className='border-top' />
                                            {numeralCurrency(
                                              item.costTransportationByDoctorWorkSchedule
                                            )}
                                          </>
                                        )}
                                    </td>
                                    <td>
                                      {!item.isTransport
                                        ? numeralCurrency(
                                            parseInt(
                                              item.costTransportationByDoctorWorkSchedule >
                                                0 && !item.isTypeTransportation
                                                ? item.costTransportationByDoctorWorkSchedule
                                                : item.costWs
                                            ) + item.costWs
                                          )
                                        : null}
                                      {item.departmentID
                                        ? numeralCurrency(item.cost)
                                        : null}
                                    </td>

                                    <td>
                                      {item.isTransport && !item.departmentID
                                        ? numeralCurrency(item.cost)
                                        : null}
                                    </td>
                                    {item.urlImageS3 ? (
                                      <>
                                        <td>
                                          <Button
                                            type='button'
                                            label={i18n.t('main.downloadFile')}
                                            onClick={() => {
                                              setDataImg(item.urlImageS3)
                                              handleOpenImgDialog(true)
                                            }}
                                            icon='pi pi-info'
                                            className='p-button-secondary'
                                          />
                                        </td>
                                        <PreviewImgDialog
                                          i18n={i18n}
                                          visible={openImgDialog}
                                          dataImage={dataImg}
                                          handleClose={() =>
                                            handleOpenImgDialog(false)
                                          }
                                        />
                                      </>
                                    ) : (
                                      <td />
                                    )}
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td
                                    style={{ textAlign: 'center' }}
                                    colSpan={8}
                                  >
                                    ご指定の月は確定シフトと交通費精算がありません。
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        )}
                      </div>
                      <table>
                        <tbody>
                          <tr>
                            <th style={{ textAlign: 'left' }}>
                              {`${i18n.t('transportApplication.days')}`}
                            </th>
                            <td>{`${totalDays}日`}</td>
                          </tr>
                          <tr>
                            <th style={{ textAlign: 'left' }}>
                              {`${i18n.t(
                                'transportApplication.countRegister'
                              )}`}
                            </th>
                            <td>{`${numberIncentive}件`}</td>
                          </tr>
                          <tr>
                            <th style={{ textAlign: 'left' }}>
                              {`${i18n.t('transportApplication.total')}`}
                            </th>
                            <td>{numeralCurrency(totalCost)}</td>
                          </tr>
                        </tbody>
                      </table>
                      <ConfirmationModal
                        {...formikProps}
                        confirmationMessage={confirmationMessage}
                      />
                    </div>
                    <TransportReportsHeader date={date} />
                  </div>
                </div>
              </>
            )
          }}
        />
      </div>
    </div>
  )
}
export default TransportReportsScene
