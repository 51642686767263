import { useApolloClient, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import Auth from '@medical/middleware/auth'
import { useCustom } from '@medical/provider/context'
import {
  finishLoading,
  setDoctor,
  startLoading,
} from '@medical/provider/store/actions'
import Router from '@medical/routes/router'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { parseJwt } from '../../constant/utilities'
import {
  CONVERSATION_DOCTOR,
  DOCTOR_BLOCKED_MAIL_SUBSCRIPTION,
  DOCTOR_ME,
  DOCTOR_MSG_SUBSCRIPTION,
  DOCTOR_SUBSCRIPTION,
} from './DoctorLayoutRequest.graphql'
import DoctorLayoutScene from './DoctorLayoutScene'
import { DOCTOR_EMAIL } from '@medical/constant'

const DoctorLayout = ({ history, children, cartItems, path }) => {
  const client = useApolloClient()
  const { doctorId } = parseJwt(localStorage.getItem('medical_doctor_token'))
  const [
    { i18n, activeIndex, menuToggle, activeIndexSecond, isDoctorBlocked, newMsg },
    { setActiveIndex, setMenuToggle, setActiveIndexSecond, setIsDoctorBlocked, setNewMsg },
  ] = useCustom()
  const { loading, error, data, refetch, subscribeToMore } = useQuery(
    DOCTOR_ME,
    {
      variables: {
        filesWhere: {
          deletedAt: null,
        }
      }
    }
  )
  const {
    loading: conversationLoading,
    error: conversationError,
    data: conversationData,
    subscribeToMore: subscribeToMoreConversation,
  } = useQuery(CONVERSATION_DOCTOR, {
    variables: {
      where: {
        Doctor: {
          id: doctorId,
        },
      },
    },
    fetchPolicy: "no-cache"
  })
  const [{ year, month }] = useState({
    year: moment().year(),
    month: moment().month() + 1,
  })
  useEffect(() => {
    subscribeToMore({
      document: DOCTOR_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        const email = localStorage.getItem(DOCTOR_EMAIL)
        if (subscriptionData.data.doctorLoggedIn.email === email) {
          Auth.signout({ history, client, path: Router.doctorLogin })
        }
        return prev
      },
    })
    // sign out when staff block doctor mail
    subscribeToMore({
      document: DOCTOR_BLOCKED_MAIL_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (subscriptionData.data.doctorBlockedMail) {
          Auth.signout({ history, client, path: Router.doctorLogin })
        }
        return prev
      },
    })
    subscribeToMoreConversation({
      document: DOCTOR_MSG_SUBSCRIPTION,
      variables: { doctorId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        return {
          conversations: [
            subscriptionData.data.doctorConversationsSubscription,
          ],
        }
      },
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (error) {
      if (error.graphQLErrors.length > 0 && error.graphQLErrors[0].message)
        Auth.signout({ history, client, path: Router.doctorLogin })
    }
  }, [client, error, history])

  useEffect(() => {
    void refetch()
  }, []);

  useEffect(() => {
    if (conversationData && conversationData.conversationDoctor) {
      setNewMsg(conversationData.conversationDoctor.isReadByDoctor === false)
    }
  }, [conversationData]);
  // useEffect(() => {
  //   if (cartItems.length > 0) {
  //     window.onbeforeunload = function() {
  //       return 'Are you sure you want to navigate away?'
  //     }
  //   } else {
  //     window.onbeforeunload = null
  //   }
  // }, [cartItems])

  if (loading || conversationLoading) return <ProgressSpinner fullHeight />
  if (error || conversationError)
    return <ErrorComponent error={error || conversationError} />

  const onSignout = () =>
    Auth.signout({ history, client, pathname: Router.doctorLogin })

  const { registrationStatus } = data.doctorMe
  const doctorPermissions = ['REJECTED', 'PENDING', 'NEW'].includes(
    registrationStatus
  )
  setIsDoctorBlocked(data.doctorMe && data.doctorMe.blocked)

  return (
    <DoctorLayoutScene
      client={client}
      menuToggle={menuToggle}
      setMenuToggle={setMenuToggle}
      year={year}
      month={month}
      onSignout={onSignout}
      path={path}
      i18n={i18n}
      doctor={data.doctorMe || {}}
      doctorPermissions={doctorPermissions}
      cartItems={cartItems}
      activeIndex={activeIndex}
      setActiveIndex={setActiveIndex}
      activeIndexSecond={activeIndexSecond}
      setActiveIndexSecond={setActiveIndexSecond}
      newMsg={newMsg}
      isDoctorBlocked={isDoctorBlocked}
    >
      {children}
    </DoctorLayoutScene>
  )
}

const mapStateToProps = state => ({
  loading: state.doctorStore.loading,
  doctor: state.doctorStore.doctor,
  cartItems: state.cartStore.items,
})

const mapDispatchToProps = dispatch => ({
  startLoading: () => startLoading(dispatch),
  finishLoading: () => finishLoading(dispatch),
  setDoctor: payload => dispatch(setDoctor(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DoctorLayout)
