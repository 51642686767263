import { STAFF_FRAGMENTS } from '@medical/layout/StaffLayout/StaffLayout.graphql'
import gql from 'graphql-tag'

export const STAFF_UPDATE_PROFILE = gql`
  mutation STAFF_UPDATE_PROFILE(
    $firstname: String
    $lastname: String
    $firstnameKana: String
    $lastnameKana: String
  ) {
    staffUpdateProfile(
      firstname: $firstname
      lastname: $lastname
      firstnameKana: $firstnameKana
      lastnameKana: $lastnameKana
    ) {
      ...STAFF_FRAGMENTS
    }
  }
  ${STAFF_FRAGMENTS}
`
