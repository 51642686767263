import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import DoctorIdentityForm from '@medical/components/Forms/DoctorIdentityForm'
import { useCustom } from '@medical/provider/context'
import React from 'react'

import {
  CREATE_UPDATE_DOCTOR_IDENTITY,
  GET_PROFILE_MYNUMBER,
} from './DoctorIdentity.graphql'

const DoctorCreateIdentity = ({ closeModal }) => {
  const [{ i18n, popup }] = useCustom()
  const { loading, error, data } = useQuery(GET_PROFILE_MYNUMBER)
  const [CreateUpdateDoctorIdentity] = useMutation(
    CREATE_UPDATE_DOCTOR_IDENTITY
  )

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const formatDoctorIdentityRelation = doctorIdentityRelation => {
    doctorIdentityRelation.forEach(v => {
      delete v.updatedAt
      delete v.__typename
      if (!v.deletedAt || v.deletedAt.length === 0) delete v.deletedAt
    })
    return doctorIdentityRelation
  }
  const onSubmit = async (values, { setSubmitting, setFieldValue }) => {
    try {
      const {
        identityNumber,
        doctorIdentityRelation,
        deletedDoctorIdentityRelation,
        frontSideIdentityKey,
        backSideIdentityKey,
        frontSideLicenseKey,
        backSideLicenseKey,
      } = values
      await CreateUpdateDoctorIdentity({
        variables: {
          isCreateAction: !data.doctorMe.doctorIdentity,
          identityNumber,
          frontSideIdentityKey,
          backSideIdentityKey,
          frontSideLicenseKey,
          backSideLicenseKey,
          doctorIdentityRelations: formatDoctorIdentityRelation([
            ...doctorIdentityRelation,
            ...deletedDoctorIdentityRelation,
          ]),
        },
      })
      popup.success(i18n.t('updateProfile.submissionSuccess'))
      setSubmitting(false)
      closeModal()
    } catch (error) {
      popup.error(error)
      setFieldValue('confirm', false)
      setSubmitting(false)
    }
  }

  return (
    <React.Fragment>
      <div className='modal-title p-autocomplete-query'>
        {data.doctorMe.doctorIdentity && data.doctorMe.doctorIdentity.id
          ? i18n.t('doctor.identity.titleUpdate')
          : i18n.t('doctor.identity.titleCreate')}
      </div>
      <DoctorIdentityForm
        closeModal={closeModal}
        doctor={data.doctorMe}
        identity={data.doctorMe.doctorIdentity}
        onSubmit={onSubmit}
        confirmationMessage={
          data.doctorMe.doctorIdentity && data.doctorMe.doctorIdentity.id
            ? i18n.t('doctor.identity.updateConfirmMessage')
            : i18n.t('doctor.identity.createConfirmMessage')
        }
      />
    </React.Fragment>
  )
}

export default DoctorCreateIdentity
