import { ProgressSpinner } from '@medical/components'
import { Dialog } from 'primereact/dialog'

import React from 'react'

const DialogCsv = ({ i18n, displayDialog, loading, onHide, msg }) => {
  return (
    <Dialog
      header={i18n.t('main.confirmation')}
      visible={displayDialog}
      onHide={() => onHide()}
      breakpoints={{ '960px': '75vw' }}
      style={{ width: '50vw' }}
    >
      <p style={{ textAlign: 'center' }}>{msg}</p>
      {loading && <ProgressSpinner />}
    </Dialog>
  )
}

export default DialogCsv
