import { ConfirmationModal } from '@medical/components'
import { DatePicker } from 'antd'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import React from 'react'
import * as Yup from 'yup'
import locale from 'antd/es/date-picker/locale/ja_JP'

export const GasolinePriceScene = ({
  i18n,
  data,
  onSubmit,
  isSetMaxPriceGasolinePermitted,
}) => {
  const styles = {
    checkBoxList_body: {
      overflowY: 'auto',
      maxHeight: '300px',
    },
  }

  const disabledDate = current => {
    const dateNow = moment().get('date')
    let minDay = moment().startOf('month')
    const maxDay = moment().add(3, 'month')
    if (dateNow <= 10) {
      minDay = moment()
        .subtract(1, 'month')
        .startOf('month')
      return !minDay.isSameOrBefore(current) || !maxDay.isSameOrAfter(current)
    }
    return !maxDay.isSameOrAfter(current) || !minDay.isSameOrBefore(current)
  }
  const validationSchema = Yup.object().shape({
    price: Yup.number()
      .typeError(i18n.t('validation.hourlyWage.number'))
      .test(
        'price greater than 0',
        '金額は０以上で入力してください',
        val => val > 0
      )
      .required(i18n.t('validation.hourlyWage.required')),
    startTime: Yup.string().required(
      `適用開始日${i18n.t('validation.required')}`
    ),
    maxPrice: Yup.number()
      .typeError(i18n.t('validation.hourlyWage.number'))
      .test(
        'price greater than 0',
        '金額は０以上で入力してください',
        val => val >= 0
      ),
  })
  return (
    <Formik
      initialValues={{
        maxPrice: data[0]?.maxPrice || 0,
        price: data[0]?.price || 0,
        startTime: data[0]?.startTime
          ? moment(data[0].startTime).format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD'),
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      render={formikProps => {
        const {
          errors,
          values,
          touched,
          validateForm,
          setFieldValue,
          setTouched,
          dirty,
        } = formikProps
        return (
          <div className='gasoline-price-form'>
            <div className='modal-title'>
              {i18n.t('main.movingExpenses.gasolineSetting')}
            </div>
            <div className='tr'>
              <div className='th'>
                {i18n.t('main.movingExpenses.gasolineSettingPrice')}
              </div>
              <div className='td'>
                <InputText
                  name='price'
                  value={values.price}
                  style={{ width: '30%' }}
                  onChange={event => {
                    setFieldValue('price', event.target.value)
                  }}
                />
                <span> 円 </span>
                <div className='alert'>{!!touched.price && errors.price}</div>
              </div>
            </div>
            {isSetMaxPriceGasolinePermitted && (
              <div className='tr'>
                <div className='th'>
                  {i18n.t('main.movingExpenses.gasolineSettingMaxPrice')}
                </div>
                <div className='td'>
                  <InputText
                    name='price'
                    value={values.maxPrice}
                    style={{ width: '30%' }}
                    onChange={event => {
                      setFieldValue('maxPrice', event.target.value)
                    }}
                  />
                  <span> 円 </span>
                  <div className='alert'>
                    {!!touched.maxPrice && errors.maxPrice}
                  </div>
                </div>
              </div>
            )}

            <div className='tr'>
              <div className='th'>
                {i18n.t('main.movingExpenses.effectiveDate')}
              </div>
              <div className='td'>
                <DatePicker
                  name='startTime'
                  className='date-picker'
                  format='YYYY年MM月DD日'
                  value={
                    values.startTime
                      ? moment.utc(new Date(values.startTime), 'YYYY年MM月DD日')
                      : moment.utc(new Date(), 'YYYY年MM月DD日')
                  }
                  locale={locale}
                  disabledDate={disabledDate}
                  allowClear={false}
                  onChange={event => {
                    setFieldValue(
                      `startTime`,
                      moment
                        .utc(event)
                        .startOf('day')
                        .toISOString()
                    )
                  }}
                />
                <div className='alert'>
                  {!!touched.startTime && errors.startTime}
                </div>
              </div>
            </div>
            <div className='modal-bottom'>
              <Button
                disabled={!dirty}
                className='btn-main-color'
                label={i18n.t('doctor.waitingRecruitment.update')}
                onClick={() => {
                  validateForm().then(response => {
                    if (!Object.keys(response).length) {
                      setFieldValue('confirm', true)
                    } else {
                      setTouched(response)
                    }
                  })
                }}
              />
            </div>
            {data && data.slice(1).length > 0 && (
              <div className='tr'>
                <div className='th'>
                  {i18n.t('main.movingExpenses.gasolineHistory')}
                </div>
              </div>
            )}
            <div
              className='gasoline-price-history'
              style={
                data && data.slice(1).length > 10
                  ? styles.checkBoxList_body
                  : undefined
              }
            >
              {data && data.slice(1).length > 0 ? (
                <>
                  {data.slice(1).map(item => (
                    <>
                      <div
                        className='tr'
                        style={{ width: '90%', margin: 'auto' }}
                      >
                        <div className='td' style={{ width: '20%' }}>
                          {moment(item.createdAt).format('LL (dd)')}
                        </div>
                        <div className='td' style={{ width: '25%' }}>
                          {`${item.modifiedBy.firstnameKana} ${item.modifiedBy.lastnameKana}`}
                        </div>
                        <div className='td' style={{ width: '7%' }}>
                          {`${item.price}円`}
                        </div>
                        <div className='td' style={{ width: '7%' }}>
                          {`${item.maxPrice || 0}円`}
                        </div>
                        <div className='td' style={{ width: '25%' }}>
                          {i18n.t('main.movingExpenses.effectiveDate')}:{' '}
                          {moment(item.startTime).format('LL (dd)')}
                        </div>
                      </div>
                    </>
                  ))}
                </>
              ) : null}
            </div>
            <ConfirmationModal
              {...formikProps}
              confirmationMessage={i18n.t(
                'staff.availableShift.updateConfirmation'
              )}
            />
          </div>
        )
      }}
    />
  )
}
