import gql from 'graphql-tag'

export const GET_HOURLY_WAGE = gql`
  query Query($where: DefaultHourlyWageWhereInput) {
    defaultHourlyWages(where: $where) {
      id
      workPattern
      hourlyWage
    }
  }
`
export const UPDATE_HOURLY_WAGE = gql`
  mutation StaffUpdateDefaultHourlyWage(
    $defaultHourlyWages: [DefaultHourlyWageForUpdate!]!
  ) {
    staffUpdateDefaultHourlyWage(defaultHourlyWages: $defaultHourlyWages)
  }
`
export const GET_DOCTOR_DEFAULT_HOURLY_WAGE = gql`
  query DefaultHourlyWagesConnection($where: DefaultHourlyWageWhereInput) {
    defaultHourlyWagesConnection(where: $where) {
      edges {
        node {
          id
          startDate
          endDate
          startTime
          endTime
          hourlyWage
          repeatSetting
          dateMatrix
          isAvailableOnMonday
          isAvailableOnTuesday
          isAvailableOnWednesday
          isAvailableOnThursday
          isAvailableOnFriday
          isAvailableOnSaturday
          isAvailableOnSunday
          isAvailableOnHoliday
          doctor {
            lastname
            firstname
            id
            email
            workPattern
            fullName
          }
          workPattern
          clinicalDepartment {
            id
            name
          }
        }
        cursor
      }
      aggregate(where: $where) {
        count
      }
    }
  }
`
export const STAFF_CREATE_DEFAULT_HOURLY_WAGE = gql`
  mutation StaffCreateDoctorDefaultHourlyWage(
    $workPattern: WorkPattern
    $startDate: String!
    $endDate: String!
    $repeatSetting: RepeatSettingTypes
    $isAvailableOnMonday: Boolean
    $isAvailableOnTuesday: Boolean
    $isAvailableOnWednesday: Boolean
    $isAvailableOnThursday: Boolean
    $isAvailableOnFriday: Boolean
    $isAvailableOnSaturday: Boolean
    $isAvailableOnSunday: Boolean
    $isAvailableOnHoliday: Boolean
    $dateMatrix: String
    $endTime: String!
    $startTime: String!
    $hourlyWage: Int
    $doctorId: ID!
    $clinicalDepartmentId: ID!
  ) {
    staffCreateDoctorDefaultHourlyWage(
      workPattern: $workPattern
      startDate: $startDate
      endDate: $endDate
      repeatSetting: $repeatSetting
      isAvailableOnMonday: $isAvailableOnMonday
      isAvailableOnTuesday: $isAvailableOnTuesday
      isAvailableOnWednesday: $isAvailableOnWednesday
      isAvailableOnThursday: $isAvailableOnThursday
      isAvailableOnFriday: $isAvailableOnFriday
      isAvailableOnSaturday: $isAvailableOnSaturday
      isAvailableOnSunday: $isAvailableOnSunday
      isAvailableOnHoliday: $isAvailableOnHoliday
      dateMatrix: $dateMatrix
      endTime: $endTime
      startTime: $startTime
      hourlyWage: $hourlyWage
      doctorId: $doctorId
      clinicalDepartmentId: $clinicalDepartmentId
    )
  }
`

export const STAFF_UPDATE_DEFAULT_HOURLY_WAGE = gql`
  mutation StaffUpdateDefaultHourlyWage(
    $startDate: String!
    $endDate: String!
    $startTime: String!
    $endTime: String!
    $doctorId: ID!
    $clinicalDepartmentId: ID!
    $doctorDefaultHourlyWageId: ID!
    $workPattern: WorkPattern
    $repeatSetting: RepeatSettingTypes
    $isAvailableOnMonday: Boolean
    $isAvailableOnTuesday: Boolean
    $isAvailableOnWednesday: Boolean
    $isAvailableOnThursday: Boolean
    $isAvailableOnFriday: Boolean
    $isAvailableOnSaturday: Boolean
    $isAvailableOnSunday: Boolean
    $isAvailableOnHoliday: Boolean
    $dateMatrix: String
    $hourlyWage: Int
    $isHolidays: [String!]
  ) {
    staffUpdateDefaultHourlyWage(
      startDate: $startDate
      endDate: $endDate
      startTime: $startTime
      endTime: $endTime
      doctorId: $doctorId
      clinicalDepartmentId: $clinicalDepartmentId
      doctorDefaultHourlyWageId: $doctorDefaultHourlyWageId
      workPattern: $workPattern
      repeatSetting: $repeatSetting
      isAvailableOnMonday: $isAvailableOnMonday
      isAvailableOnTuesday: $isAvailableOnTuesday
      isAvailableOnWednesday: $isAvailableOnWednesday
      isAvailableOnThursday: $isAvailableOnThursday
      isAvailableOnFriday: $isAvailableOnFriday
      isAvailableOnSaturday: $isAvailableOnSaturday
      isAvailableOnSunday: $isAvailableOnSunday
      isAvailableOnHoliday: $isAvailableOnHoliday
      dateMatrix: $dateMatrix
      hourlyWage: $hourlyWage
      isHolidays: $isHolidays
    )
  }
`
export const STAFF_DELETE_DEFAULT_HOURLY_WAGE = gql`
  mutation StaffDeleteDefaultHourlyWage($doctorDefaultHourlyWageId: ID!) {
    staffDeleteDefaultHourlyWage(
      doctorDefaultHourlyWageId: $doctorDefaultHourlyWageId
    )
  }
`
