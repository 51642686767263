import { CLINICAL_DEPARTMENTS_QUERY } from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import gql from 'graphql-tag'

export const DOCTOR_GET_TRANSPORT_PEROPDIC = gql`
  query DOCTOR_GET_TRANSPORT_PEROPDIC($where: TransportationExpenseWhereInput) {
    transportationExpenses(where: $where) {
      id
      createdAt
      startTime
      endTime
      url
      type
      departurePlace
      arrivalPlace
      cost
      status
      billImage
      comment
      doctor {
        id
        lastname
        firstname
      }
      clinic {
        id
        name
      }
    }
    ...CLINICAL_DEPARTMENTS_QUERY
  }
  ${CLINICAL_DEPARTMENTS_QUERY}
`
export const DOCTOR_CREATE_TRANSPORT_PEROPDIC = gql`
  mutation doctorCreateTransportationExpense(
    $clinics: [String!]
    $fileImages: [String!]
    $transportations: [TransportationExpenseCreateWithoutDoctorInput!]!
  ) {
    doctorCreateTransportationExpense(
      clinics: $clinics
      fileImages: $fileImages
      transportations: $transportations
    )
  }
`
export const DOCTOR_DOWNLOAD_IMG = gql`
  mutation downloadFile($key: String!) {
    downloadFile(key: $key)
  }
`
export const TRANPORTATION_TYPE_SETTINGS = gql`
  query TransportationTypeSettings(
    $where: TransportationTypeSettingWhereInput
  ) {
    transportationTypeSettings(where: $where) {
      id
      maxCost
      type
      isApply
    }
  }
`
