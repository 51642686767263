import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import gql from 'graphql-tag'

export const CREATE_WORK_OUTSIDE = gql`
  mutation CREATE_OR_UPDATE_WORK_OUTSIDE(
    $workOutsideId: ID
    $clinicalDepartmentId: ID!
    $startTime: DateTime!
    $endTime: DateTime!
    $splitDateTime1: DateTime
    $splitDateTime2: DateTime
    $splitDateTime3: DateTime
    $isStartTimeBreakTime: Boolean
    $isSplitDateTime1BreakTime: Boolean
    $isSplitDateTime2BreakTime: Boolean
    $isSplitDateTime3BreakTime: Boolean
    $address: String!
    $workContent: String!
  ) {
    doctorUpsertWorkOutside(
      workOutsideId: $workOutsideId
      clinicalDepartmentId: $clinicalDepartmentId
      startTime: $startTime
      endTime: $endTime
      splitDateTime1: $splitDateTime1
      splitDateTime2: $splitDateTime2
      splitDateTime3: $splitDateTime3
      isStartTimeBreakTime: $isStartTimeBreakTime
      isSplitDateTime1BreakTime: $isSplitDateTime1BreakTime
      isSplitDateTime2BreakTime: $isSplitDateTime2BreakTime
      isSplitDateTime3BreakTime: $isSplitDateTime3BreakTime
      address: $address
      workContent: $workContent
    )
  }
`

export const CLINICAL_DEPARTMENTS_QUERY = gql`
  fragment CLINICAL_DEPARTMENTS_QUERY on Query {
    clinicalDepartments {
      id
      name
      extraName
      departmentCode
      isDisplay

      clinic {
        id
        name
        order
        isPublished
        isOutside
      }
    }
  }
`
export const DEPARTMENTS_LIST = gql`
  query DEPARTMENTS_LIST {
    ...CLINICAL_DEPARTMENTS_QUERY
    ...DEPARTMENTS_FRAGMENT
  }
  ${CLINICAL_DEPARTMENTS_QUERY}
  ${DEPARTMENTS_FRAGMENT}
`
