import Provider from '@medical/provider'
import { SocketContext } from '@medical/provider/socket'
import RouterContainer from '@medical/routes/RouterContainer'
import React, { useEffect, useState } from 'react'
import { io } from 'socket.io-client'

const App = () => {
  const [socket, setSocket] = useState()
  useEffect(() => {
    const socketIO = io(process.env.REACT_APP_SOCKET, {
      reconnection: true,
      reconnectionDelay: 5000,
      transports: ["websocket"],
    })
    setSocket(socketIO)
    return () => socketIO.disconnect()
  }, [])
  return (
    <Provider>
      <SocketContext.Provider value={socket}>
        <RouterContainer />
      </SocketContext.Provider>
    </Provider>
  )
}

export default App
