import gql from 'graphql-tag'

export const CREATE_PORTAL = gql`
  mutation staffUpsertPortal(
    $portalId: ID
    $largeTitle: String
    $mediumTitle: String
    $smallTitle: String
    $titleType: titlePortalType!
    $content: String
  ) {
    staffUpsertPortal(
      portalId: $portalId
      largeTitle: $largeTitle
      mediumTitle: $mediumTitle
      smallTitle: $smallTitle
      titleType: $titleType
      content: $content
    ) {
      id
      largeTitle
      mediumTitle
      smallTitle
      titleType
      content
    }
  }
`

export const CATEGORY_QUERY = gql`
  query Portals($orderBy: PortalOrderByInput, $where: PortalWhereInput) {
    portals(orderBy: $orderBy, where: $where) {
      id
      largeTitle
      mediumTitle
      titleType
    }
  }
`
