import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import ClinicNameRender from '@medical/components/ClinicNameRender'
import {
  clinicalDepartmentNames,
  combineNames,
  dateValidation,
  onPageChange,
  sortAndFilterByClinicOrder,
} from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import Router from '@medical/routes/router'
import moment from 'moment'
import queryString from 'query-string'
import React, { useContext, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import Auth from '@medical/middleware/auth'

import {
  AVAILABLE_SHIFTS_CONNECTION,
  MASS_RECRUITMENT_UNCONFIRM,
  WORKSCHEDULES_CONNECTION_DOWNLOAD_CSV,
} from './AvailableShiftList.graphql'
import AvailableShiftListScene from './AvailableShiftListScene'
import {
  removeProgress,
  setProgressBar,
} from '@medical/provider/store/reducers/progress/progress.action'
import { connect } from 'react-redux'
import { SocketContext } from '@medical/provider/socket'
import {
  convertScheduleToTimeShift,
  formatHHmm,
  getDepartmentNameJP,
  getNonCoveredTimeRanges,
  getTimeFromDate,
} from '@medical/constant/utilities'
import {
  fieldAvailableShiftsUnconfirmed,
  fieldRecruitmentShiftCsv,
  fieldUnFilledWorkScheduleByTimeCsv,
} from '@medical/services/csvService'
import {
  DOWNLOAD_CSV_MASS_RECRUITMENT,
  DOWNLOAD_CSV_MONTHLY_RECRUITMENT_SHIFT,
  DOWNLOAD_CSV_UNCONFIRM_SHIFT,
  DOWNLOAD_CSV_UNFILLED_TIME_WORK_SCHEDULE,
} from '@medical/constant'
import downloadCsv from 'download-csv'
import { WORKSCHEDULES_CONNECTION_DOWNLOAD } from '../AcceptedWorkSchedulesList/AcceptedWorkSchedulesList.graphql'

const DEFAULT_NUMBER_OF_PAGE = 15

const AvailableShiftList = ({
  history,
  match: {
    path,
    params: { year = moment().year(), month = moment().month() },
  },
  location: { search },
  progress,
  setProgress,
  removeProgress,
}) => {
  const socket = useContext(SocketContext)
  const {
    clinicalDepartmentIds = [],
    status,
    type,
    page = 1,
    rowsPerPage = 10,
  } = queryString.parse(search)
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)

  const [{ i18n }] = useCustom()
  const [orderBy, setOrderBy] = useState('startTime_DESC')
  const [skipFetch, setSkipFetch] = useState(true)

  const startDate = moment()
    .year(year)
    .month(month - 1)
    .startOf('month')
  const endDate = startDate.clone().add(1, 'month')
  const yearCSV = moment(endDate).year()
  const monthCSV = moment(endDate).month()
  const variables = {
    first: first < 0 ? 10 : first,
    skip: skip < 0 ? 0 : skip,
    orderBy: 'startTime_ASC',
    where: {
      WorkSchedule: null,
      startTime_gte: startDate,
      startTime_lt: endDate,
      isPublished: status ? status === 'PUBLISHED' : status,
      recruitmentShiftsType_in: type,
      ClinicalDepartment:
        clinicalDepartmentIds.length > 0
          ? {
              id_in: clinicalDepartmentIds,
            }
          : undefined,
    },
  }

  const { loading, error, data, refetch } = useQuery(
    AVAILABLE_SHIFTS_CONNECTION,
    {
      variables,
      fetchPolicy: 'network-only',
    }
  )

  const { refetch: fetchMoreDataDownload } = useQuery(
    MASS_RECRUITMENT_UNCONFIRM,
    {
      skip: skipFetch,
      fetchPolicy: 'no-cache',
    }
  )

  const { refetch: fetchUnfilledTimeWorkSchedule } = useQuery(
    WORKSCHEDULES_CONNECTION_DOWNLOAD_CSV,
    {
      fetchPolicy: 'network-only',
      skip: true,
      orderBy: 'startTime_ASC',
    }
  )

  const { refetch: fetchRecruitmentShiftDownload } = useQuery(
    WORKSCHEDULES_CONNECTION_DOWNLOAD,
    {
      fetchPolicy: 'network-only',
      skip: true,
      orderBy: 'startTime_ASC',
    }
  )

  useEffect(() => {
    refetch()
  }, [])

  const handleDownload = async (data, fileNameCsv) => {
    const resultUnconfirmedShifts = []
    data.forEach(item => {
      const departmentName = getDepartmentNameJP(item.name)
      const { monthlyAvailableShiftChouboshuu } = item
      if (monthlyAvailableShiftChouboshuu) {
        monthlyAvailableShiftChouboshuu.forEach(value => {
          resultUnconfirmedShifts.push({
            sort: value.startTime,
            createdAt: moment(value.createdAt).format('YYYY-MM-DD'),
            startTime: moment(value.startTime).format('HH:mm'),
            endTime: moment(value.endTime).format('HH:mm'),
            workDate: moment(value.startTime).format('YYYY-MM-DD'),
            order:
              value.clinicalDepartment.clinic.order < 10
                ? `="0${value.clinicalDepartment.clinic.order}"`
                : `="${value.clinicalDepartment.clinic.order}"`,
            clinicName: value.clinicalDepartment.clinic.name,
            clinicalDepartmentName: departmentName,
            hourlyWage: value.hourlyWage,
            splitHourlyWage1: value.splitHourlyWage1,
            splitHourlyWage2: value.splitHourlyWage2,
            splitHourlyWage3: value.splitHourlyWage3,
            splitDateTime1: value.splitDateTime1
              ? moment(value.splitDateTime1).format('HH:mm')
              : undefined,
            splitDateTime2: value.splitDateTime2
              ? moment(value.splitDateTime2).format('HH:mm')
              : undefined,
            splitDateTime3: value.splitDateTime3
              ? moment(value.splitDateTime3).format('HH:mm')
              : undefined,
            dailySalary: value.dailySalary,
            isPublished: !value.isPublished ? '未掲載中' : '',
            recruitmentShiftsType:
              value.recruitmentShiftsType == 'SINGLE' ? '個別作成' : '定期作成',
            isSpecial: value.isSpecial ? '特別時給設定あり' : '',
            comment: value.comment
              ? value.comment.replace(/\r?\n/g, '').replace(',', '')
              : undefined,
            isDoubleRecruitment: value.isDoubleRecruitment ? '二診目' : '',
          })
        })
      }
    })
    const filename = [
      `${fileNameCsv || '未充足シフト'}_`,
      moment.utc(endDate).format('YYYY年MM月'),
      '.CSV',
    ].join('')
    const fields = fieldAvailableShiftsUnconfirmed()

    const field = {}
    fields.forEach(f => (field[f.value] = f.label))

    downloadCsv(
      resultUnconfirmedShifts
        .sort((a, b) => moment(a.sort) - moment(b.sort))
        .map(item => {
          const {
            clinicName,
            clinicalDepartmentName,
            comment,
            createdAt,
            dailySalary,
            endTime,
            hourlyWage,
            isPublished,
            isSpecial,
            order,
            recruitmentShiftsType,
            splitDateTime1,
            splitDateTime2,
            splitDateTime3,
            splitHourlyWage1,
            splitHourlyWage2,
            splitHourlyWage3,
            startTime,
            workDate,
            isDoubleRecruitment,
          } = item
          return {
            createdAt,
            order,
            clinicName,
            clinicalDepartmentName,
            workDate,
            startTime,
            splitDateTime1,
            splitDateTime2,
            splitDateTime3,
            endTime,
            splitHourlyWage1: hourlyWage,
            splitHourlyWage2: splitHourlyWage1,
            splitHourlyWage3: splitHourlyWage2,
            hourlyWage: splitHourlyWage3,
            dailySalary,
            isPublished,
            recruitmentShiftsType,
            isSpecial,
            comment,
            isDoubleRecruitment,
          }
        }),
      field,
      filename
    )
  }

  const compare = (a, b) =>
    moment(a.node.startTime) > moment(b.node.startTime) ? 1 : -1

  useEffect(() => {
    if (!loading && data && data.availableShiftsConnection) {
      if (orderBy === 'startTime_ASC' || orderBy === 'startTime_DESC') {
        data.availableShiftsConnection.edges.sort((a, b) =>
          orderBy === 'startTime_ASC' ? compare(a, b) : compare(b, a)
        )
      }
    }
  }, [loading, data, orderBy])
  const momentDate = moment(`${year} ${month} 01`, 'YYYY MM DD')
  if (
    (dateValidation({ year, month }) &&
      momentDate.isBefore(moment().subtract(1, 'years'), 'month')) ||
    [
      Router.staffAvailableShiftListWithoutMonth,
      Router.staffAvailableShiftListWithoutYearMonth,
    ].includes(path)
  )
    return (
      <Redirect
        to={Router.get(Router.staffAvailableShiftList, {
          year: moment().year(),
          month: moment().month() + 1,
        })}
      />
    )

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  const onDropdownChange = e => {
    history.push({
      search: queryString.stringify({
        clinicalDepartmentIds: e.value.map(({ id }) => id),
      }),
    })
  }

  const onSortHandle = e => {
    if (e.sortField === 'startTime') {
      setOrderBy(
        orderBy !== 'startTime_ASC' ? 'startTime_ASC' : 'startTime_DESC'
      )
    }
  }

  const {
    availableShiftsConnection: {
      edges,
      aggregate: { count },
    },
    clinicalDepartments,
    departments,
  } = data
  const sortedClinicalDepartments = sortAndFilterByClinicOrder({
    clinicalDepartments,
  })
  const departmentNames = clinicalDepartmentNames({ departments })

  const resultAvailableShiftLists = []

  edges.map(({ node }) => {
    resultAvailableShiftLists.push({
      id: node.id,
      createdAt: node.createdAt,
      startTime: node.startTime,
      endTime: node.endTime,
      clinicalDepartmentId: node.clinicalDepartment.id,
      clinicName: ClinicNameRender({
        clinicalDepartment: node.clinicalDepartment,
      }),
      clinicalDepartmentName: departmentNames[node.clinicalDepartment.name],
      hourlyWage: node.hourlyWage,
      splitHourlyWage1: node.splitHourlyWage1,
      splitHourlyWage2: node.splitHourlyWage2,
      splitHourlyWage3: node.splitHourlyWage3,
      splitDateTime1: node.splitDateTime1,
      splitDateTime2: node.splitDateTime2,
      splitDateTime3: node.splitDateTime3,
      dailySalary: node.dailySalary,
      isPublished: node.isPublished,
      recruitmentShiftsType: node.recruitmentShiftsType,
      isSpecial: node.isSpecial,
      recruitmentCategory: node.scheduledAvailableShiftGroup
        ? node.recruitmentCategory.concat(
            node.scheduledAvailableShiftGroup.recruitmentCategory
          )
        : node.recruitmentCategory,
      comment: node.comment,
    })
    return resultAvailableShiftLists
  })
  const token = Auth.getToken()

  const urlDownloadcsvAvailableShifts = `${process.env.REACT_APP_ENDPOINT}/downloadcsvAvailableShifts?token=${token}&year=${year}&month=${month}`

  const urlDownloadUnconfirmed = `${
    process.env.REACT_APP_ENDPOINT
  }/downloadcsvSatisfiedShift?token=${token}&date=${moment
    .utc(endDate)
    .format('YYYY-MM-DD')}&isSatisfiedShift=${true}`

  const urlDownloadMassRecruitment = `${
    process.env.REACT_APP_ENDPOINT
  }/downloadcsvSatisfiedShift?token=${token}&date=${moment
    .utc(endDate)
    .format('YYYY-MM-DD')}&isSatisfiedShift=${false}`

  const handleFetchSatisfieldShiftDownload = async () => {
    setProgress({
      progress: DOWNLOAD_CSV_MASS_RECRUITMENT,
      label: '未充足シフトcsvダウンロード中',
      percent: 0,
    })
    const NUMBER_RECORD_EACH_FETCH = Math.ceil(
      clinicalDepartments?.length / DEFAULT_NUMBER_OF_PAGE
    )

    try {
      setSkipFetch(false)
      const dataDownload = []
      const totalPage = Math.ceil(
        clinicalDepartments?.length / NUMBER_RECORD_EACH_FETCH
      )
      for (let i = 0; i < totalPage; i++) {
        const res = await fetchMoreDataDownload({
          date: moment.utc(endDate).format('YYYY-MM-DD'),
          isUnConfirmedShift: false,
          first: NUMBER_RECORD_EACH_FETCH,
          skip: i * NUMBER_RECORD_EACH_FETCH,
        })
        setProgress({
          progress: DOWNLOAD_CSV_MASS_RECRUITMENT,
          label: '未充足シフトcsvダウンロード中',
          percent: ((i + 1) / totalPage) * 100,
        })
        dataDownload.push(...res.data.clinicalDepartments)
      }
      handleDownload(dataDownload, '未充足シフト')
      removeProgress(DOWNLOAD_CSV_MASS_RECRUITMENT)
      setSkipFetch(true)
    } catch (err) {
      setProgress({
        progress: DOWNLOAD_CSV_MASS_RECRUITMENT,
        label: '未充足シフトcsvダウンロード中',
        msg: i18n.t('main.msgDownloadCSVStatusError'),
      })
    }
  }

  const handleFetchUnfulfilledShiftDownload = async () => {
    setProgress({
      progress: DOWNLOAD_CSV_UNCONFIRM_SHIFT,
      label: '半未充足シフトcsvダウンロード中',
      percent: 0,
    })
    const NUMBER_RECORD_EACH_FETCH = Math.ceil(
      clinicalDepartments?.length / DEFAULT_NUMBER_OF_PAGE
    )

    try {
      setSkipFetch(false)
      const dataDownload = []
      const totalPage = Math.ceil(
        clinicalDepartments?.length / NUMBER_RECORD_EACH_FETCH
      )
      for (let i = 0; i < totalPage; i++) {
        const res = await fetchMoreDataDownload({
          date: moment.utc(endDate).format('YYYY-MM-DD'),
          isUnConfirmedShift: true,
          first: NUMBER_RECORD_EACH_FETCH,
          skip: i * NUMBER_RECORD_EACH_FETCH,
        })
        setProgress({
          progress: DOWNLOAD_CSV_UNCONFIRM_SHIFT,
          label: '半未充足シフトcsvダウンロード中',
          percent: ((i + 1) / totalPage) * 100,
        })
        dataDownload.push(...res.data.clinicalDepartments)
      }
      handleDownload(dataDownload, '半未充足シフト')
      removeProgress(DOWNLOAD_CSV_UNCONFIRM_SHIFT)
      setSkipFetch(true)
    } catch (err) {
      setProgress({
        progress: DOWNLOAD_CSV_UNCONFIRM_SHIFT,
        label: '半未充足シフトcsvダウンロード中',
        msg: i18n.t('main.msgDownloadCSVStatusError'),
      })
    }
  }

  const handleDownloadUnfilledTimeWorkSchedule = async () => {
    try {
      setProgress({
        progress: DOWNLOAD_CSV_UNFILLED_TIME_WORK_SCHEDULE,
        label: '未充足時間CSVダウンロード',
        percent: 0,
      })

      const NUMBER_RECORD_EACH_FETCH = Math.ceil(
        clinicalDepartments?.length / DEFAULT_NUMBER_OF_PAGE
      )

      const workSchedules = []
      const totalPage = Math.ceil(
        clinicalDepartments?.length / NUMBER_RECORD_EACH_FETCH
      )
      for (let i = 0; i < totalPage; i++) {
        const res = await fetchUnfilledTimeWorkSchedule({
          date: moment.utc(endDate).format('YYYY-MM-DD'),
          monthlyWorkSchedules: {
            date: moment.utc(endDate).format('YYYY-MM-DD'),
          },
          first: NUMBER_RECORD_EACH_FETCH,
          skip: i * NUMBER_RECORD_EACH_FETCH,
        })
        setProgress({
          progress: DOWNLOAD_CSV_UNFILLED_TIME_WORK_SCHEDULE,
          label: '未充足時間CSVダウンロード中',
          percent: ((i + 1) / totalPage) * 100,
        })
        workSchedules.push(...res.data.clinicalDepartments)
      }

      const filename = [
        `未充足時間_`,
        moment.utc(endDate).format('YYYY年MM月'),
        '.CSV',
      ].join('')
      const handleDownload = async (data, filename) => {
        const result = []
        const dayInMonth = moment(startDate).daysInMonth()
        data.forEach(item => {
          const { monthlyWorkSchedules } = item
          const groupedObjects = {
            clinicName: item.clinic.name,
            order: item.clinic.order,
            clinicalDepartmentName: item.name,
          }
          for (let i = 1; i <= dayInMonth; i++) {
            if (!groupedObjects[i]) {
              groupedObjects[i] = []
            }
          }
          if (monthlyWorkSchedules) {
            monthlyWorkSchedules.forEach(workSchedule => {
              if (workSchedule.accepted) {
                const timeDays = moment(workSchedule.startTime).date()
                groupedObjects[timeDays].push({
                  startTime: moment(workSchedule.startTime).format('HH:mm'),
                  endTime: moment(workSchedule.endTime).format('HH:mm'),
                })
              }
            })
          }
          result.push(groupedObjects)
        })
        const clinicalDepartmentArray = []
        result.forEach(item => {
          for (let i = 0; i < 31; i++) {
            if (item[`${i + 1}`]) {
              clinicalDepartmentArray.push({
                clinicName: item.clinicName,
                clinicalDepartmentName: getDepartmentNameJP(
                  item.clinicalDepartmentName
                ),
                order: item.order,
                workDate: moment(`${yearCSV}/${monthCSV}/${i + 1}`).format(
                  'YYYY-MM-DD'
                ),
                workSchedules: getNonCoveredTimeRanges(item[`${i + 1}`]),
              })
            }
          }
        })

        const freeTimesArray = []
        let maxColumnUnfillTime = 0

        const sortData = clinicalDepartmentArray.sort(function(a, b) {
          const orderMap = {
            内科: 1,
            小児科: 2,
            '小児科ワクチン専任(対象：小児～成人)': 3,
            '内科ワクチン専任(対象：小児～成人)': 4,
          }
          const orderA = orderMap[a.clinicalDepartmentName]
          const orderB = orderMap[b.clinicalDepartmentName]

          const dateA = new Date(a.workDate)
          const dateB = new Date(b.workDate)
          if (dateA < dateB) return -1
          if (dateA > dateB) return 1
          return (
            a.order - b.order || a.clinicName - b.clinicName || orderA - orderB
          )
        })
        sortData.forEach(item => {
          const freeTimes = {
            clinicName: item.clinicName,
            clinicalDepartmentName: item.clinicalDepartmentName,
            workDate: item.workDate,
          }
          if (item.workSchedules.length > maxColumnUnfillTime) {
            maxColumnUnfillTime = item.workSchedules.length
          }
          for (let i = 0; i < item.workSchedules.length; i++) {
            freeTimes[`unfillStartTime${i + 1}`] =
              item.workSchedules[i].startTime
            freeTimes[`unfillEndTime${i + 1}`] = item.workSchedules[i].endTime
          }
          freeTimesArray.push(freeTimes)
        })
        downloadCsv(
          freeTimesArray,
          fieldUnFilledWorkScheduleByTimeCsv(maxColumnUnfillTime),
          filename
        )
      }

      handleDownload(workSchedules, filename)
      removeProgress(DOWNLOAD_CSV_UNFILLED_TIME_WORK_SCHEDULE)
    } catch (err) {
      setProgress({
        progress: DOWNLOAD_CSV_UNFILLED_TIME_WORK_SCHEDULE,
        label: '未充足時間CSVダウンロード中',
        msg: i18n.t('main.msgDownloadCSVStatusError'),
      })
    }
  }

  const handleDownloadRecruitmentShift = async () => {
    try {
      setProgress({
        progress: DOWNLOAD_CSV_MONTHLY_RECRUITMENT_SHIFT,
        label: '募集立てシフトCSVダウンロード',
        percent: 0,
      })

      let totalRecords
      const availableShiftLists = []
      for (let i = 0; ; i++) {
        const res = await fetchRecruitmentShiftDownload({
          where: {
            deletedAt: null,
            startTime_gte: startDate,
            endTime_lte: endDate,
            recruitmentShiftsType_not: null,
          },
          first: 15,
          skip: i * 15,
        })
        totalRecords = res.data.availableShiftsConnection.aggregate.count

        availableShiftLists.push(
          ...res.data.availableShiftsConnection.edges.map(item => ({
            ...item.node,
          }))
        )

        setProgress({
          progress: DOWNLOAD_CSV_MONTHLY_RECRUITMENT_SHIFT,
          label: '募集立てシフトCSVダウンロード',
          percent: Math.ceil((availableShiftLists.length / totalRecords) * 100),
        })

        if (totalRecords === availableShiftLists.length) break
      }

      // CSV fields
      const fields = fieldRecruitmentShiftCsv()
      const filename = [
        `募集立てシフトCSV_`,
        moment.utc(endDate).format('YYYY年MM月'),
        '.CSV',
      ].join('')

      const ClinicNameRender = ({ clinicalDepartment }) =>
        clinicalDepartment.extraName
          ? `${clinicalDepartment.clinic.name} (${clinicalDepartment.extraName})`
          : clinicalDepartment.clinic.name

      const results = []

      availableShiftLists
        .sort((ws1, ws2) => moment(ws1.endTime).diff(ws2.endTime))
        .forEach(item => {
          let fieldWorkScheduleByTimeCsv
          if (item?.workSchedule === null) {
            fieldWorkScheduleByTimeCsv = '募集中'
          } else {
            fieldWorkScheduleByTimeCsv = item.availableShiftToWorkSchedule
              ? '確定'
              : '未承認'
          }

          const timeShift = convertScheduleToTimeShift({
            startTime: formatHHmm(item.startTime),
            endTime: formatHHmm(item.endTime),
          })
          const startBreakTime = getTimeFromDate(
            item.clinicalDepartment.endTimeMondayMorning
          )
          const endBreakTime = getTimeFromDate(
            item.clinicalDepartment.startTimeMondayAfternoon
          )
          const isShowBreakTime =
            moment(`2000-01-01 ${formatHHmm(item.startTime)}`).isSameOrBefore(
              `2000-01-01 ${startBreakTime}`
            ) &&
            moment(`2000-01-01 ${formatHHmm(item.endTime)}`).isSameOrAfter(
              `2000-01-01 ${endBreakTime}`
            )
          results.push({
            workDate: moment(item.endTime).format('YYYY-MM-DD'),
            clinicName: ClinicNameRender({
              clinicalDepartment: item.clinicalDepartment,
            }),
            clinicalDepartmentName: getDepartmentNameJP(
              item.clinicalDepartment.name
            ),
            startMorningShift: timeShift.morningShift?.split('-')[0] || '',
            endMorningShift: timeShift.morningShift?.split('-')[1] || '',
            startBreakTime: isShowBreakTime ? startBreakTime : '',
            endBreakTime: isShowBreakTime ? endBreakTime : '',
            startAfternoonShift: timeShift.afternoonShift?.split('-')[0] || '',
            endAfternoonShift: timeShift.afternoonShift?.split('-')[1] || '',
            startEveningShift: timeShift.eveningShift?.split('-')[0] || '',
            endEveningShift: timeShift.eveningShift?.split('-')[1] || '',
            splitHourlyWage1: item.splitHourlyWage1,
            splitHourlyWage2: item.splitHourlyWage2,
            splitHourlyWage3: item.splitHourlyWage3,
            hourlyWage: item.hourlyWage,
            dailySalary: item.dailySalary,
            isPublished: item.isPublished ? '掲載' : '未掲載',
            statusShift: fieldWorkScheduleByTimeCsv,
            isDoubleRecruitment: item.isDoubleRecruitment ? '二診目' : '',
          })
        })

      downloadCsv(results, fields, filename)
      removeProgress(DOWNLOAD_CSV_MONTHLY_RECRUITMENT_SHIFT)
    } catch (err) {
      setProgress({
        progress: DOWNLOAD_CSV_MONTHLY_RECRUITMENT_SHIFT,
        label: '募集立てシフトCSVダウンロード',
        msg: i18n.t('main.msgDownloadCSVStatusError'),
      })
    }
  }

  return (
    <AvailableShiftListScene
      i18n={i18n}
      socket={socket}
      progress={progress}
      setProgress={progress => {
        setProgress(progress)
      }}
      removeProgress={progress => removeProgress(progress)}
      urlDownloadcsvAvailableShifts={urlDownloadcsvAvailableShifts}
      urlDownloadUnconfirmed={urlDownloadUnconfirmed}
      availableShiftLists={resultAvailableShiftLists}
      clinicalDepartments={combineNames({
        clinicalDepartments: sortedClinicalDepartments,
        departmentNames,
      })}
      currentDate={startDate}
      clinicalDepartmentIds={clinicalDepartmentIds}
      onDropdownChange={onDropdownChange}
      onSortHandle={onSortHandle}
      status={status}
      type={type}
      rowsPerPage={rowsPerPage}
      page={page}
      count={count}
      onPageChange={onPageChange(history, search)}
      urlDownloadMassRecruitment={urlDownloadMassRecruitment}
      handleFetchSatisfieldShiftDownload={handleFetchSatisfieldShiftDownload}
      handleFetchUnfulfilledShiftDownload={handleFetchUnfulfilledShiftDownload}
      handleDownloadUnfilledTimeWorkSchedule={
        handleDownloadUnfilledTimeWorkSchedule
      }
      handleDownloadRecruitmentShift={handleDownloadRecruitmentShift}
    />
  )
}

const mapStateToProps = state => ({
  progress: state.progressBarStore.progress,
})

const mapDispatchToProps = dispatch => ({
  setProgress: progress => dispatch(setProgressBar(progress)),
  removeProgress: progress => dispatch(removeProgress(progress)),
})
export default connect(mapStateToProps, mapDispatchToProps)(AvailableShiftList)
