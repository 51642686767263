import { ProgressSpinner } from '@medical/components'
import { Checkbox } from 'antd'
import { ErrorMessage, Formik } from 'formik'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import React from 'react'
import * as Yup from 'yup'

const CreateDoctorSkillScene = ({ onSubmit, isCreatePermitted }) => {
  const validationSchema = Yup.object().shape({
    skillName: Yup.string()
      .required('スキル名は必要です。')
      .test('len', '100文字以下で設定してください', val => val.length <= 100),
  })

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          skillName: '',
          skillDisplay: false,
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        render={formikProps => {
          const {
            values,
            isSubmitting,
            setFieldValue,
            handleSubmit,
          } = formikProps
          return (
            <div className='doctor-skill'>
              <div className='modal-title'>医師スキル作成</div>
              <div className='tr'>
                <div className='th'>スキル名</div>
                <div className='td'>
                  <InputText
                    {...formikProps}
                    name='skillName'
                    onChange={e => {
                      setFieldValue('skillName', e.target.value)
                    }}
                  />
                </div>
              </div>
              <div className='tr'>
                <div className='th' />
                <div className='td'>
                  <div className='alert'>
                    <ErrorMessage name='skillName' />
                  </div>
                </div>
              </div>
              <div className='tr checkbox'>
                <div className='th'>医師スキルをプロフィール上に表示</div>
                <div className='td'>
                  <Checkbox
                    {...formikProps}
                    name='skillDisplay'
                    onChange={e => {
                      setFieldValue('skillDisplay', e.target.checked)
                    }}
                    // label={i18n.t('main.clinicName')}
                  />
                </div>
              </div>
              {isCreatePermitted ? (
                <div className='modal-bottom'>
                  {isSubmitting ? (
                    <ProgressSpinner />
                  ) : (
                    <Button
                      type='submit'
                      disabled={isSubmitting || values.skillName.length === 0}
                      className='reflect-button'
                      onClick={handleSubmit}
                      label='登録'
                    />
                  )}
                </div>
              ) : null}
            </div>
          )
        }}
      />
    </div>
  )
}

export default CreateDoctorSkillScene
