import BreadCrumb from '@medical/components/Breadcrumb'
import ModalLink from '@medical/components/ModalLink'
import Router from '@medical/routes/router'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import React from 'react'
import { isMobile } from 'react-device-detect'

const WorkOutsideClinicScene = ({
  resultWorkOutsidesList,
  i18n,
  statusConvertText,
}) => (
  <Formik
    initialValues={{
      fullname: '',
      statusValue: '',
    }}
    onSubmit={{}}
    render={formikProps => (
      <div
        style={{
          margin: '0px auto',
          width: '70%'
        }}
      >
        <div className='staff-header'>
          <div className='staff-title'>
            {i18n.t('main.workOutsideClinic.title')}
            <BreadCrumb
              items={[
                {
                  title: i18n.t('main.workOutsideClinic.title'),
                },
              ]}
            />
          </div>
          <div
            className='staff-buttons'
          >
            <ModalLink to={Router.get(Router.doctorWorkOutsideClinicCreate)}>
              <Button
                icon='pi pi-plus-circle'
                className='p-button-normal'
                label={i18n.t('main.workOutsideClinic.create')}
              />
            </ModalLink>
          </div>
        </div>
        <div
          id='doctor-work-outside'
          className='box'
        >
          <div>
            <table className='table-workSchedule-cancel'>
              <thead>
                <tr className='table-workSchedule-cancel-th'>
                  <th width='10%'>
                    {i18n.t('main.workOutsideClinic.workingDay')}
                  </th>
                  <th width='12%'>
                    {i18n.t('main.workOutsideClinic.createdAt')}
                  </th>
                  <th width='10%'>
                    {i18n.t('main.workOutsideClinic.clinicName')}
                  </th>
                  {!isMobile && (
                    <>
                      <th width='10%'>
                        {i18n.t('main.workOutsideClinic.time')}
                      </th>
                      <th width='10%'>
                        {i18n.t('main.workOutsideClinic.address')}
                      </th>
                      <th width='10%'>
                        {i18n.t('main.workScheduleCancel.status')}
                      </th>
                    </>
                  )}
                  <th width='10%' />
                </tr>
              </thead>
              <tbody>
                {resultWorkOutsidesList.length > 0 ? (
                  resultWorkOutsidesList.map(record => (
                    <tr key={record.id} style={{ textAlign: 'center' }}>
                      <td>
                        {moment(record.startTime).format('YYYY年MM月DD日')}
                      </td>
                      <td>
                        {moment(record.createdAt).format('YYYY年MM月DD日')}
                      </td>
                      <td>{`${record.clinicName}_${record.clinicalDepartmentName}`}</td>
                      {!isMobile && (
                        <>
                          <td>
                            {`${moment(record.startTime).format(
                              'HH:mm'
                            )}～${moment(record.endTime).format('HH:mm')}`}
                          </td>
                          <td>{record.address}</td>
                          <td>{statusConvertText(record.status)}</td>
                        </>
                      )}
                      <td>
                        <ModalLink
                          to={Router.get(Router.doctorWorkOutsideClinicDetail, {
                            id: record.id,
                          })}
                        >
                          <Button icon='pi pi-info' />
                        </ModalLink>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8}>申請がありません</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )}
  />
)
export default WorkOutsideClinicScene
