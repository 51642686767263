import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, NotFound, ProgressSpinner } from '@medical/components'
import { clinicalDepartmentNames, splitLogic } from '@medical/libs'
import { DOCTOR_WORK_SCHEDULE } from '@medical/pages/doctor/SingleSchedule/SingleSchedule.graphql'
import { useCustom } from '@medical/provider/context'
import moment from 'moment'
import React from 'react'

import { DOCTOR_CREATE_WORKSCHEDULE_OVERTIME } from './AcceptedWorkScheduleOverTime.graphql'
import AcceptedWorkScheduleOverTimeScene from './AcceptedWorkScheduleOverTimeScene'

const AcceptedWorkScheduleOverTime = ({
  closeModal,
  match: {
    params: { workScheduleId, year, month, day },
  },
}) => {
  const [{ i18n, popup }] = useCustom()
  const { loading, error, data } = useQuery(DOCTOR_WORK_SCHEDULE, {
    variables: { workScheduleId },
  })
  const [createOverTime] = useMutation(DOCTOR_CREATE_WORKSCHEDULE_OVERTIME)
  const date = moment()
    .startOf('day')
    .year(year)
    .month(month - 1)
    .date(day || 1)
    .hour(9)
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  const { workSchedule = {}, departments } = data
  if (!workSchedule) {
    return <NotFound />
  }
  const { workScheduleActualTime } = workSchedule
  let dataOvertime = null
  if (workSchedule.workOvertime.length > 0) {
    const workOvertime = workSchedule.workOvertime.filter(
      item => !item.deletedAt
    )
    if (workOvertime.length > 0) {
      dataOvertime = {
        splitDateTime1: workOvertime[0].splitDateTime1,
        splitDateTime2: workOvertime[0].splitDateTime2,
        splitDateTime3: workOvertime[0].splitDateTime3,
        isStartTimeBreakTime: workOvertime[0].isSplitDateTime2BreakTime,
        isSplitDateTime1BreakTime: workOvertime[0].isSplitDateTime1BreakTime,
        isSplitDateTime2BreakTime: workOvertime[0].isSplitDateTime2BreakTime,
        isSplitDateTime3BreakTime: workOvertime[0].isSplitDateTime3BreakTime,
        earlyDeparture: !!workOvertime[0].earlyDeparture,
        endTime: workOvertime[0].endTime,
        startTime: workOvertime[0].startTime,
        lateArrival: !!workOvertime[0].lateArrival,
        reasonEarlyDeparture: !!workOvertime[0].reasonEarlyDeparture && workOvertime[0].reasonEarlyDeparture.length > 0 ? workOvertime[0].reasonEarlyDeparture.split(', ') : null,
        reasonLateArrival: !!workOvertime[0].reasonLateArrival && workOvertime[0].reasonLateArrival.length > 0 ? workOvertime[0].reasonLateArrival.split(', ') : null,
        workScheduleHistory: JSON.parse(workOvertime[0].workScheduleHistory),
        hasComment: !!workOvertime[0].comment,
        comment: workOvertime[0].comment,
      }
    }
  }
  const departmentNames = clinicalDepartmentNames({
    departments,
  })
  const onSubmit = async (values, { setSubmitting, setFieldValue }) => {
    const { splits, startTime } = values
    const {
      endTime,
      splitDateTime1,
      splitDateTime2,
      splitDateTime3,
      isStartTimeBreakTime,
      isSplitDateTime1BreakTime,
      isSplitDateTime2BreakTime,
      isSplitDateTime3BreakTime,
      hourlyWage,
      splitHourlyWage1,
      splitHourlyWage2,
      splitHourlyWage3,
    } = splitLogic({ submitDate: startTime, splits })
    try {
      await createOverTime({
        variables: {
          workScheduleId,
          data: {
            startTime,
            earlyDeparture: values.isEarlyArrival,
            lateArrival: values.isOverTime,
            reasonEarlyDeparture: values.reasonEarlyArrival && values.reasonEarlyArrival.length > 0 ? values.reasonEarlyArrival.join(', ') : null,
            reasonLateArrival: values.reasonOverTime && values.reasonOverTime.length > 0 ? values.reasonOverTime.join(', ') : null,
            comment: values.comment,
            endTime,
            hourlyWage,
            splitDateTime1,
            splitDateTime2,
            splitDateTime3,
            isStartTimeBreakTime,
            isSplitDateTime1BreakTime,
            isSplitDateTime2BreakTime,
            isSplitDateTime3BreakTime,
            splitHourlyWage1,
            splitHourlyWage2,
            splitHourlyWage3,
          },
        },
      })
      closeModal()
      popup.success(i18n.t('staff.hourlyPayment.submissionSuccess'))
      setSubmitting(false)
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
      setFieldValue('confirm', false)
    }
  }
  return (
    <AcceptedWorkScheduleOverTimeScene
      workSchedule={workSchedule}
      i18n={i18n}
      departmentNames={departmentNames}
      closeModal={closeModal}
      onSubmit={onSubmit}
      dataOvertime={dataOvertime}
      workScheduleActualTime={workScheduleActualTime}
      date={date}
    />
  )
}
export default AcceptedWorkScheduleOverTime
