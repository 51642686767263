import { ReactComponent as Calendar } from '@medical/assets/image/calendar.svg'
import { ReactComponent as Doctor } from '@medical/assets/image/doctor_menu.svg'
import { ReactComponent as Hospital } from '@medical/assets/image/hospital.svg'
import { ReactComponent as Incentive } from '@medical/assets/image/incentive.svg'
import logo from '@medical/assets/image/logo.png'
import { ReactComponent as Question } from '@medical/assets/image/question.svg'
import { ReactComponent as Portal } from '@medical/assets/image/stack-fill.svg'
import { ReactComponent as Moving } from '@medical/assets/image/subway-line.svg'
import { ReactComponent as Yen } from '@medical/assets/image/yen.svg'
import getMenuPath from '@medical/libs/getMenuPath'
import { useStaffPermission } from '@medical/routes/Permission'
import Router from '@medical/routes/router'
import StaffRouters from '@medical/routes/staffRoutes'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { Button } from 'primereact/button'
import React, { useContext, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { ProgressBar } from '@medical/components/ProgressBar'
import { SocketContext } from '@medical/provider/socket'
import { STAFF_TOKEN_KEY } from '@medical/constant'
import { useCustom } from '@medical/provider/context'
import {
  STAFF_PAGE_ACTIVITY_LOG_LIST,
  STAFF_PAGE_DOCTOR_DELETE_REQUEST,
  STAFF_PAGE_DOCTORS,
  STAFF_PAGE_INCENTIVE_REPORT,
  STAFF_PAGE_INCENTIVE_SETTING,
  STAFF_PAGE_MY_NUMBER,
  STAFF_PAGE_OVERTIME,
  STAFF_PAGE_SALARY_CONFIRM,
  STAFF_PAGE_SCHEDULE_CANCEL,
  STAFF_PAGE_TRANSPORTATION_EXPEMSES,
  STAFF_VIEW_HOURLY_WAGE_PAGE,
  STAFF_VIEW_HOURLY_WAGE_PAGE_RECRUITMENT,
  STAFF_VIEW_PAGE_SCHEDULE_AVAILABLE_SHIFTS,
  STAFF_VIEW_PAGE_DOCTOR_CONSULTATION_TIME,
  STAFF_VIEW_PAGE_DOCTOR_OUTPUT_INSURANCE
} from '@medical/constant/permissions'
import { checkStaffPermission } from '@medical/libs'

const MyLink = ({ permissionId, children, ...props }) => {
  const permissions = useStaffPermission()
  if (permissions.includes(permissionId)) {
    return <Link {...props}>{children}</Link>
  }
  return null
}
const getDate = () => ({
  year: new Date().getFullYear(),
  month: new Date().getMonth() + 1,
  day: new Date().getDate(),
})

const isNewScheduleCancel = data => {
  return !!data.find(item => item.status === 'WAITING_FOR_APPROVAL')
}

const StaffLayoutScene = ({
  onSignout,
  path,
  children,
  year,
  month,
  staff,
  newMsg,
  dataWorkOutside,
  dataNewScheduleCancel,
  newWaitingRecruitmentShift,
  recruitmentManager,
  wotData,
  doctorsData,
  progress,
  removeProgress,
  setProgress,
}) => {
  const socket = useContext(SocketContext)

  const [
    {
      i18n,
      activeIndex,
      menuToggle,
      activeIndexSecond,
      activeIndexThird,
      activeIndexFour,
      activeIndexSix,
      activeIndexFive,
      activeIndexSeven,
    },
    {
      setActiveIndex,
      setMenuToggle,
      setActiveIndexSecond,
      setActiveIndexThird,
      setActiveIndexFour,
      setActiveIndexSix,
      setActiveIndexFive,
      setActiveSeven,
    },
  ] = useCustom()

  const isPageScheduleCancel = checkStaffPermission({
    functionName: STAFF_PAGE_SCHEDULE_CANCEL,
  })
  const staffPageIncentiveSetting = checkStaffPermission({
    functionName: STAFF_PAGE_INCENTIVE_SETTING,
  })
  const staffPageOverTime = checkStaffPermission({
    functionName: STAFF_PAGE_OVERTIME,
  })
  const isViewPageScheduleAvailableShift = checkStaffPermission({
    functionName: STAFF_VIEW_PAGE_SCHEDULE_AVAILABLE_SHIFTS,
  })
  const isViewPageDefaultHourlyWage = checkStaffPermission({
    functionName: STAFF_VIEW_HOURLY_WAGE_PAGE,
  })
  const isViewPageDefaultHourlyWageRecruitment = checkStaffPermission({
    functionName: STAFF_VIEW_HOURLY_WAGE_PAGE_RECRUITMENT,
  })
  const isViewPageDoctors = checkStaffPermission({
    functionName: STAFF_PAGE_DOCTORS,
  })
  const isViewPageMyNumber = checkStaffPermission({
    functionName: STAFF_PAGE_MY_NUMBER,
  })
  const isViewPageIncentiveReport = checkStaffPermission({
    functionName: STAFF_PAGE_INCENTIVE_REPORT,
  })
  const isViewPageDeleteDoctorRequest = checkStaffPermission({
    functionName: STAFF_PAGE_DOCTOR_DELETE_REQUEST,
  })
  const isViewPageSalaryConfirm = checkStaffPermission({
    functionName: STAFF_PAGE_SALARY_CONFIRM,
  })
  const isPageMovingExpenses = checkStaffPermission({
    functionName: STAFF_PAGE_TRANSPORTATION_EXPEMSES,
  })
  const isStaffPageActivityLogList = checkStaffPermission({
    functionName: STAFF_PAGE_ACTIVITY_LOG_LIST,
  })
  const isStaffPageDoctorConsultationTime = checkStaffPermission({
    functionName: STAFF_VIEW_PAGE_DOCTOR_CONSULTATION_TIME,
  })
  const isViewStaffDoctorOutputInsurancePermitted = checkStaffPermission({
    functionName: STAFF_VIEW_PAGE_DOCTOR_OUTPUT_INSURANCE,
  })
  useEffect(() => {
    const token = localStorage.getItem(STAFF_TOKEN_KEY)
    if (token) {
      const userInfo = JSON.parse(atob(token.split('.')[1]))
      if (userInfo?.staffId && socket) {
        socket.emit('joinRoom', { userId: userInfo.staffId })
      }
    }
  }, [socket])

  const isAtLeastOneChild = useMemo(
    () =>
      isViewPageSalaryConfirm ||
      isViewPageDeleteDoctorRequest ||
      isViewPageIncentiveReport ||
      isViewPageMyNumber ||
      isViewPageDoctors,
    [
      isViewPageSalaryConfirm,
      isViewPageDeleteDoctorRequest,
      isViewPageIncentiveReport,
      isViewPageMyNumber,
      isViewPageDoctors,
    ]
  )

  return (
    <div className={`doctor-layout ${!menuToggle}`}>
      <div className='staff-layout-header'>
        <div className='staff-layout-logo'>
          <img src={logo} alt='' style={{ width: '60%' }} />
        </div>
        <Button
          icon='pi pi-bars'
          style={{ fontSize: '20px' }}
          onClick={() => setMenuToggle(!menuToggle)}
        />
        <div className='doctor-layout-spacer' />
      </div>
      <div className='staff-layout-content'>
        <div className='staff-layout-menu'>
          <div className='staff-layout-menu-inner'>
            <div className='doctor-layout-menu-profile'>
              <Accordion
                activeIndex={activeIndex}
                onTabClose={() => setActiveIndex(null)}
                onTabOpen={() => setActiveIndex(0)}
              >
                <AccordionTab
                  header={
                    <span
                      style={{ color: 'white' }}
                    >{`${staff.lastname} ${staff.firstname}`}</span>
                  }
                >
                  <Link
                    to={Router.staffMyPage}
                    className={`staff-layout-menu-link ${getMenuPath(
                      Router.staffMyPage,
                      path
                    )}`}
                  >
                    <i className='pi pi-user' />
                    {i18n.t('staff.menuBar.myPage')}
                  </Link>
                  <div className='staff-layout-menu-link' onClick={onSignout}>
                    <i className='pi pi-sign-out' />
                    {i18n.t('staff.menuBar.signOut')}
                  </div>
                </AccordionTab>
              </Accordion>
              <div className='staff-layout-menu-divider' />
              <Accordion
                activeIndex={activeIndexSecond}
                onTabClose={() => setActiveIndexSecond(null)}
                onTabOpen={() => setActiveIndexSecond(0)}
                className='doctornav-group'
              >
                <AccordionTab
                  header={
                    <React.Fragment>
                      <i className='menu-icon bg-red ml-5 mt-5'>
                        <Calendar />
                      </i>
                      <span style={{ marginLeft: '10px' }}>
                        {i18n.t('staff.menuBar.availableShift')}
                      </span>
                    </React.Fragment>
                  }
                >
                  <Link
                    to={Router.get(Router.staffMonthlyAvailableShifts, {
                      year,
                      month,
                    })}
                    className={`staff-layout-menu-link ${getMenuPath(
                      [
                        Router.staffMonthlyAvailableShifts,
                        Router.staffDailyAvailableShifts,
                      ],
                      path
                    )}`}
                  >
                    <span style={{ paddingLeft: '30px' }}>
                      {i18n.t('staff.availableShift.title')}
                    </span>
                  </Link>
                  {isPageScheduleCancel ? (
                    <Link
                      to={Router.get(Router.staffCancelShifts, getDate())}
                      className={`staff-layout-menu-link ${getMenuPath(
                        [Router.staffCancelShifts],
                        path
                      )}`}
                    >
                      <span style={{ paddingLeft: '30px' }}>
                        {i18n.t('main.workScheduleCancel.title')}
                      </span>
                      {dataNewScheduleCancel &&
                      dataNewScheduleCancel.workScheduleCancellationLetters
                        .length > 0 &&
                      isNewScheduleCancel(
                        dataNewScheduleCancel.workScheduleCancellationLetters
                      ) ? (
                        <div className='new-schedule-cancel'>New</div>
                      ) : null}
                    </Link>
                  ) : null}
                  {staffPageOverTime && (
                    <MyLink
                      permissionId={
                        StaffRouters.staffWorkScheduleOverTimes.permissionId
                      }
                      to={Router.staffOverTime}
                      className={`staff-layout-menu-link ${getMenuPath(
                        Router.staffOverTime,
                        path
                      )}`}
                    >
                      <span style={{ paddingLeft: '30px', fontSize: '15px' }}>
                        {i18n.t('staff.menuBar.overTime')}
                        {wotData.includes('WAITING_FOR_APPROVAL') && (
                          <div
                            className='new-schedule-cancel'
                            style={{ marginLeft: '55%' }}
                          >
                            New
                          </div>
                        )}
                      </span>
                    </MyLink>
                  )}
                  <MyLink
                    to={Router.staffWorkOutsideClinic}
                    permissionId={
                      StaffRouters.staffWorkOutsideClinic.permissionId
                    }
                    className={`staff-layout-menu-link ${getMenuPath(
                      Router.staffWorkOutsideClinic,
                      path
                    )}`}
                  >
                    <span style={{ paddingLeft: '30px' }}>
                      {i18n.t('staff.menuBar.workOutsideClinic')}
                    </span>
                    {dataWorkOutside && dataWorkOutside.length > 0 ? (
                      <span className='new-work-outside'>
                        {dataWorkOutside.length}
                      </span>
                    ) : null}
                  </MyLink>
                  <MyLink
                    to={Router.staffWaitingRecruitment}
                    permissionId={StaffRouters.waitingRecruitment.permissionId}
                    className={`staff-layout-menu-link ${getMenuPath(
                      Router.staffWaitingRecruitment,
                      path
                    )}`}
                  >
                    <span style={{ paddingLeft: '30px' }}>
                      {i18n.t('staff.menuBar.waitingRecruitment')}
                    </span>
                    {newWaitingRecruitmentShift ? (
                      <div className='new-waiting-recruitment-shift'>New</div>
                    ) : null}
                  </MyLink>
                  <MyLink
                    to={Router.staffAvailableShiftGroupList}
                    permissionId={
                      StaffRouters.staffAvailableShiftGroupList.permissionId
                    }
                    className={`staff-layout-menu-link ${getMenuPath(
                      Router.staffAvailableShiftGroupList,
                      path
                    )}`}
                  >
                    <span style={{ paddingLeft: '30px' }}>
                      {i18n.t('staff.menuBar.regularFixedShiftList')}
                    </span>
                  </MyLink>

                  {/* Add submenu recruitmentScheduleShift */}
                  {isViewPageScheduleAvailableShift ? (
                    <Link
                      to={Router.get(Router.staffRecruitmentScheduleShift)}
                      className={`staff-layout-menu-link ${getMenuPath(
                        [Router.staffRecruitmentScheduleShift],
                        path
                      )}`}
                    >
                      <span style={{ paddingLeft: '30px' }}>
                        {i18n.t('staff.menuBar.recruitmentScheduleShift')}
                      </span>
                    </Link>
                  ) : null}
                  <Link to={Router.get(Router.staffExpectationDoubleRecruitmentRecord, getDate())} className={`staff-layout-menu-link ${getMenuPath(
                    Router.staffExpectationDoubleRecruitmentRecord,
                    path
                  )}`}>
                     <span style={{paddingLeft: '30px'}}>
                        二診目希望登録
                      </span>
                  </Link>
                </AccordionTab>
              </Accordion>
              {/* add HourlyWage */}
              {(isViewPageDefaultHourlyWage ||
                isViewPageDefaultHourlyWageRecruitment) && (
                <Accordion
                  activeIndex={activeIndexThird}
                  onTabClose={() => setActiveIndexThird(null)}
                  onTabOpen={() => setActiveIndexThird(0)}
                  className='doctornav-group'
                >
                  <AccordionTab
                    header={
                      <React.Fragment>
                        <i className='menu-icon'>
                          <Yen />
                        </i>
                        <span style={{ marginLeft: '10px' }}>
                          {i18n.t('staff.menuBar.hourlyWageManagement')}
                        </span>
                      </React.Fragment>
                    }
                  >
                    <MyLink
                      to={Router.staffHourlyWages}
                      permissionId={StaffRouters.staffHourlyWage.permissionId}
                      className={`staff-layout-menu-link ${getMenuPath(
                        Router.staffHourlyWages,
                        path
                      )}`}
                    >
                      <span style={{ paddingLeft: '30px' }}>
                        {i18n.t('staff.menuBar.hourlyWage')}
                      </span>
                    </MyLink>
                    <MyLink
                      to={Router.get(Router.staffHourlyPayment)}
                      permissionId={
                        StaffRouters.staffHourlyPayment.permissionId
                      }
                      className={`staff-layout-menu-link ${getMenuPath(
                        [Router.staffHourlyPayment],
                        path
                      )}`}
                    >
                      <span style={{ paddingLeft: '30px' }}>
                        {i18n.t('staff.menuBar.hourlyPay')}
                      </span>
                    </MyLink>
                    <MyLink
                      to={Router.get(Router.staffHourlyWageChangeReservation)}
                      permissionId={
                        StaffRouters.staffHourlyWageChangeReservation
                          .permissionId
                      }
                      className={`staff-layout-menu-link ${getMenuPath(
                        [Router.staffHourlyWageChangeReservation],
                        path
                      )}`}
                    >
                      <span style={{ paddingLeft: '30px' }}>
                        {i18n.t('staff.menuBar.hourlyWageChangeReservation')}
                      </span>
                    </MyLink>
                  </AccordionTab>
                </Accordion>
              )}
              {/* Add menubar to view doctor pending */}
              {isAtLeastOneChild && (
                <Accordion
                  activeIndex={activeIndexFour}
                  onTabClose={() => setActiveIndexFour(null)}
                  onTabOpen={() => setActiveIndexFour(0)}
                  className='doctornav-group'
                >
                  <AccordionTab
                    header={
                      <React.Fragment>
                        <i className='menu-icon bg-red ml-5 mt-5'>
                          <Doctor />
                        </i>
                        <span style={{ marginLeft: '10px' }}>
                          {i18n.t('staff.menuBar.doctorsGroup')}
                        </span>
                      </React.Fragment>
                    }
                  >
                    <MyLink
                      permissionId={StaffRouters.staffDoctors.permissionId}
                      to={Router.staffDoctors}
                      className={`staff-layout-menu-link ${getMenuPath(
                        Router.staffDoctors,
                        path
                      )}`}
                    >
                      <span style={{ paddingLeft: '30px' }}>
                        {i18n.t('staff.menuBar.doctors')}
                      </span>
                    </MyLink>
                    <MyLink
                      permissionId={
                        StaffRouters.staffDoctorsPending.permissionId
                      }
                      to={Router.staffDoctorsPending}
                      className={`staff-layout-menu-link ${getMenuPath(
                        Router.staffDoctorsPending,
                        path
                      )}`}
                    >
                      <span style={{ paddingLeft: '30px' }}>
                        {i18n.t('staff.menuBar.doctorsPending')}
                        {doctorsData.length > 0 && (
                          <div
                            className='new-schedule-cancel'
                            style={{ marginLeft: '55%' }}
                          >
                            New
                          </div>
                        )}
                      </span>
                    </MyLink>
                    <MyLink
                      permissionId={
                        StaffRouters.staffMyNumberManage.permissionId
                      }
                      to={Router.staffMyNumberManage}
                      className={`staff-layout-menu-link ${getMenuPath(
                        Router.staffMyNumberManage,
                        path
                      )}`}
                    >
                      <span style={{ paddingLeft: '30px' }}>
                        {i18n.t('staff.menuBar.myNumberManage')}
                      </span>
                    </MyLink>
                    <MyLink
                      permissionId={StaffRouters.staffDoctors.permissionId}
                      to={Router.staffDoctorWorkRecords}
                      className={`staff-layout-menu-link ${getMenuPath(
                        Router.staffDoctorWorkRecords,
                        path
                      )}`}
                    >
                      <span style={{ paddingLeft: '30px' }}>
                        {i18n.t('staff.menuBar.doctorWorkRecords')}
                      </span>
                    </MyLink>
                    <MyLink
                      permissionId={
                        StaffRouters.staffPatientSatisfactionResults
                          .permissionId
                      }
                      to={Router.staffPatientSatisfactionResults}
                      className={`staff-layout-menu-link ${getMenuPath(
                        Router.staffPatientSatisfactionResults,
                        path
                      )}`}
                    >
                      <span style={{ fontSize: '14.5px', paddingLeft: '30px' }}>
                        {i18n.t('staff.menuBar.patientSatisfactionResults')}
                      </span>
                    </MyLink>
                    <MyLink
                      permissionId={
                        StaffRouters.staffNewDoctorsList.permissionId
                      }
                      to={Router.staffNewDoctorsList}
                      className={`staff-layout-menu-link ${getMenuPath(
                        Router.staffNewDoctorsList,
                        // Router.staffNewDoctorsListWithoutYearMonth,
                        // Router.staffNewDoctorsListWithoutMonth,
                        path
                      )}`}
                    >
                      <span style={{ paddingLeft: '30px' }}>
                        {i18n.t('staff.menuBar.newDoctorsList')}
                      </span>
                    </MyLink>
                    <MyLink
                      permissionId={
                        StaffRouters.monthlyDoctorSalaryConfirmation
                          .permissionId
                      }
                      to={Router.get(
                        Router.staffMonthlyDoctorSalaryConfirmation,
                        {
                          year,
                          month,
                        }
                      )}
                      className={`staff-layout-menu-link ${getMenuPath(
                        Router.staffMonthlyDoctorSalaryConfirmation,
                        // Router.staffMonthlyDoctorSalaryConfirmationWithoutYearMonth,
                        // Router.staffMonthlyDoctorSalaryConfirmationWithoutMonth,
                        path
                      )}`}
                    >
                      <span style={{ paddingLeft: '30px' }}>
                        {i18n.t(
                          'staff.menuBar.monthlyDoctorSalaryConfirmation'
                        )}
                      </span>
                    </MyLink>
                    <MyLink
                      permissionId={
                        StaffRouters.staffDoctorsDeleteRequestList.permissionId
                      }
                      to={Router.staffDoctorsDeleteRequestList}
                      className={`staff-layout-menu-link ${getMenuPath(
                        Router.staffDoctorsDeleteRequestList,
                        path
                      )}`}
                    >
                      <span style={{ paddingLeft: '30px' }}>
                        {i18n.t('staff.menuBar.doctorsDeleteRequest')}
                      </span>
                    </MyLink>
                    {isStaffPageDoctorConsultationTime && (
                      <MyLink
                        permissionId={StaffRouters.staffDoctors.permissionId}
                        to={Router.staffDoctorConsultation}
                        className={`staff-layout-menu-link ${getMenuPath(
                          Router.staffDoctorConsultation,
                          path
                        )}`}
                      >
                        <span style={{ paddingLeft: '30px' }}>
                          {i18n.t('staff.menuBar.doctorConsultations')}
                        </span>
                      </MyLink>
                    )}
                    {
                      isViewStaffDoctorOutputInsurancePermitted && (
                        <MyLink
                          permissionId={StaffRouters.staffDoctorOutputInsurance.permissionId}
                          to={Router.staffDoctorOutputInsurance}
                          className={`staff-layout-menu-link ${getMenuPath(
                            Router.staffDoctorOutputInsurance,
                            path
                          )}`}
                        >
                      <span style={{ paddingLeft: '30px' }}>
                         {i18n.t('staff.menuBar.doctorOutputInsurance')}
                      </span>
                        </MyLink>
                      )
                    }
                  </AccordionTab>
                </Accordion>
              )}
              <MyLink
                to={Router.staffStaffs}
                permissionId={StaffRouters.staffStaffs.permissionId}
                className={`staff-layout-menu-link ${getMenuPath(
                  Router.staffStaffs,
                  path
                )}`}
              >
                <i className='pi pi-users' />
                {i18n.t('staff.menuBar.staffs')}
              </MyLink>
              <MyLink
                to={Router.staffClinics}
                permissionId={StaffRouters.staffClinics.permissionId}
                className={`staff-layout-menu-link ${getMenuPath(
                  Router.staffClinics,
                  path
                )}`}
              >
                <i className='menu-icon'>
                  <Hospital />
                </i>
                {i18n.t('staff.menuBar.clinics')}
              </MyLink>
              {isStaffPageActivityLogList && (
                <Accordion
                  activeIndex={activeIndexFive}
                  onTabClose={() => setActiveIndexFive(null)}
                  onTabOpen={() => setActiveIndexFive(0)}
                  className='history-nav-group'
                >
                  <AccordionTab
                    header={
                      <div className='icon-history'>
                        <i className='pi pi-clock history-icon' />
                        {i18n.t('staff.menuBar.loginAndUpdateHistoryGroup')}
                      </div>
                    }
                  >
                    <MyLink
                      to={Router.staffActivityLogsListDoctorHistory}
                      permissionId={
                        StaffRouters.staffActivityLogsList.permissionId
                      }
                      className={`staff-layout-menu-link ${getMenuPath(
                        Router.staffActivityLogsListDoctorHistory,
                        path
                      )}`}
                    >
                      <span style={{ paddingLeft: '30px' }}>
                        {i18n.t('staff.menuBar.activityLogsDoctorHistories')}
                      </span>
                    </MyLink>
                    <MyLink
                      to={Router.staffActivityLogsListStaffHistory}
                      permissionId={
                        StaffRouters.staffActivityLogsList.permissionId
                      }
                      className={`staff-layout-menu-link ${getMenuPath(
                        Router.staffActivityLogsListStaffHistory,
                        path
                      )}`}
                    >
                      <span style={{ paddingLeft: '30px' }}>
                        {i18n.t('staff.menuBar.activityLogsStaffHistories')}
                      </span>
                    </MyLink>
                    <MyLink
                      to={Router.staffActivityLogsList}
                      permissionId={
                        StaffRouters.staffActivityLogsList.permissionId
                      }
                      className={`staff-layout-menu-link ${getMenuPath(
                        Router.staffActivityLogsList,
                        path
                      )}`}
                    >
                      <span style={{ paddingLeft: '30px' }}>
                        {i18n.t('staff.menuBar.activityLogs')}
                      </span>
                    </MyLink>
                    <MyLink
                      to={Router.staffActivityLogsListUpdateByStaff}
                      permissionId={
                        StaffRouters.staffActivityLogsList.permissionId
                      }
                      className={`staff-layout-menu-link ${getMenuPath(
                        Router.staffActivityLogsListUpdateByStaff,
                        path
                      )}`}
                    >
                      <span style={{ paddingLeft: '30px' }}>
                        {i18n.t('staff.menuBar.activityLogsUpdateByStaff')}
                      </span>
                    </MyLink>
                  </AccordionTab>
                </Accordion>
              )}

              {isPageMovingExpenses && (
                <Accordion
                  activeIndex={activeIndexSix}
                  onTabClose={() => setActiveIndexSix(null)}
                  onTabOpen={() => setActiveIndexSix(0)}
                  className='doctornav-group' // img, style
                >
                  <AccordionTab
                    header={
                      <React.Fragment>
                        <i className='menu-icon bg-red ml-5 mt-5'>
                          <Moving />
                        </i>
                        <span style={{ marginLeft: '10px' }}>
                          {i18n.t('staff.menuBar.movingExpenses')}
                        </span>
                      </React.Fragment>
                    }
                  >
                    <Link
                      to={Router.get(Router.staffDoctorsMovingExpenses)}
                      className={`staff-layout-menu-link ${getMenuPath(
                        Router.staffDoctorsMovingExpenses,
                        path
                      )}`}
                    >
                      <span style={{ paddingLeft: '30px' }}>
                        {i18n.t('staff.menuBar.movingExpensesList')}
                      </span>
                    </Link>
                    <Link
                      to={Router.get(
                        Router.staffDoctorsMonthlyTransportationExpenses,
                        {
                          year,
                          month,
                        }
                      )}
                      className={`staff-layout-menu-link ${getMenuPath(
                        Router.staffDoctorsMonthlyTransportationExpenses,
                        path
                      )}`}
                    >
                      <span style={{ paddingLeft: '30px' }}>
                        {i18n.t('staff.menuBar.monthlyTransportationExpenses')}
                      </span>
                    </Link>
                    <Link
                      to={Router.get(
                        Router.staffDoctorsMovingExpensesRegistration
                      )}
                      className={`staff-layout-menu-link ${getMenuPath(
                        Router.staffDoctorsMovingExpensesRegistration,
                        path
                      )}`}
                    >
                      <span style={{ paddingLeft: '30px' }}>
                        {i18n.t('staff.menuBar.movingExpensesRegistration')}
                      </span>
                    </Link>
                    <Link
                      to={Router.get(Router.staffDoctorsMovingExpensesOther)}
                      className={`staff-layout-menu-link ${getMenuPath(
                        Router.staffDoctorsMovingExpensesOther,
                        path
                      )}`}
                    >
                      <span style={{ paddingLeft: '30px' }}>
                        {i18n.t('staff.menuBar.movingExpensesOther')}
                      </span>
                    </Link>
                    <Link
                      to={Router.get(
                        Router.staffManageDoctorTransportByPersonalCar
                      )}
                      className={`staff-layout-menu-link ${getMenuPath(
                        Router.staffManageDoctorTransportByPersonalCar,
                        path
                      )}`}
                    >
                      <span style={{ paddingLeft: '30px' }}>
                        {i18n.t(
                          'staff.menuBar.doctorTransportByPersonalCarManagement'
                        )}
                      </span>
                    </Link>
                    <Link
                      to={Router.get(Router.staffDoctorsMovingExpensesSetting)}
                      className={`staff-layout-menu-link ${getMenuPath(
                        Router.staffDoctorsMovingExpensesSetting,
                        path
                      )}`}
                    >
                      <span style={{ paddingLeft: '30px' }}>
                        {i18n.t('staff.menuBar.settingTransportationExpense')}
                      </span>
                    </Link>
                  </AccordionTab>
                </Accordion>
              )}
              {staffPageIncentiveSetting ? (
                <Accordion
                  activeIndex={activeIndexSeven}
                  onTabClose={() => setActiveSeven(null)}
                  onTabOpen={() => setActiveSeven(0)}
                  className='doctornav-group'
                >
                  <AccordionTab
                    header={
                      <React.Fragment>
                        <i className='menu-icon bg-red ml-5 mt-5'>
                          <Incentive />
                        </i>
                        <span style={{ marginLeft: '10px' }}>
                          {i18n.t('staff.menuBar.incentive')}
                        </span>
                      </React.Fragment>
                    }
                  >
                    <MyLink
                      permissionId={
                        StaffRouters.staffIncentiveSetting.permissionId
                      }
                      to={Router.staffIncentiveSetting}
                      className={`staff-layout-menu-link ${getMenuPath(
                        Router.staffIncentiveSetting,
                        path
                      )}`}
                    >
                      <span style={{ fontSize: '14.5px', paddingLeft: '30px' }}>
                        {i18n.t('main.incentiveSetting.title')}
                      </span>
                    </MyLink>

                    <MyLink
                      permissionId={StaffRouters.staffIncentive.permissionId}
                      to={Router.staffIncentive}
                      className={`staff-layout-menu-link ${getMenuPath(
                        Router.staffIncentive,
                        path
                      )}`}
                    >
                      <span style={{ paddingLeft: '30px' }}>
                        {i18n.t('main.incentives.title')}
                      </span>
                    </MyLink>

                    <MyLink
                      permissionId={
                        StaffRouters.staffIncentiveCsvImport.permissionId
                      }
                      to={Router.staffIncentiveCsvImport}
                      className={`staff-layout-menu-link ${getMenuPath(
                        Router.staffIncentiveCsvImport,
                        path
                      )}`}
                    >
                      <span style={{ paddingLeft: '30px' }}>
                        {i18n.t('main.incentiveCsv.menu')}
                      </span>
                    </MyLink>
                  </AccordionTab>
                </Accordion>
              ) : null}
              <div className='staff-layout-menu-divider' />
              <MyLink
                to={Router.staffPortals}
                permissionId={StaffRouters.staffPortals.permissionId}
                className={`staff-layout-menu-link ${getMenuPath(
                  Router.staffPortals,
                  path
                )}`}
              >
                <Portal />
                <span style={{ marginLeft: '10px' }}>
                  {i18n.t('staff.menuBar.portalList')}
                </span>
              </MyLink>
              <MyLink
                to={Router.staffInquiries}
                permissionId={StaffRouters.staffInquiries.permissionId}
                className={
                  newMsg
                    ? `new-message staff-layout-menu-link ${getMenuPath(
                        Router.staffInquiries,
                        path
                      )}`
                    : `staff-layout-menu-link ${getMenuPath(
                        Router.staffInquiries,
                        path
                      )}`
                }
              >
                <i className='pi pi-briefcase' />
                {i18n.t('staff.menuBar.inquiries')}
              </MyLink>
              <MyLink
                to={Router.staffRecruitmentDoctorList}
                permissionId={
                  StaffRouters.staffRecruitmentDoctorList.permissionId
                }
                className={`staff-layout-menu-link ${getMenuPath(
                  Router.staffRecruitmentDoctorList,
                  path
                )}`}
              >
                <i className='pi pi-info-circle' />
                <p>
                  {i18n.t('staff.menuBar.recruitmentDoctor')}
                  {recruitmentManager ? (
                    <span className='new-recruitment-doctor'>New</span>
                  ) : null}
                </p>
              </MyLink>
              <MyLink
                to={Router.staffAnnounces}
                permissionId={StaffRouters.staffAnnounces.permissionId}
                className={`staff-layout-menu-link ${getMenuPath(
                  Router.staffAnnounces,
                  path
                )}`}
              >
                <i className='pi pi-info-circle' />
                {i18n.t('staff.menuBar.announces')}
              </MyLink>
              <MyLink
                to={Router.staffQuestions}
                permissionId={StaffRouters.staffQuestions.permissionId}
                className={`staff-layout-menu-link ${getMenuPath(
                  Router.staffQuestions,
                  path
                )}`}
              >
                <i
                  // style={{ marginLeft: '-8px' }}
                  className='pi question-circle'
                />
                <Question style={{ marginLeft: '-8px', marginRight: '10px' }} />
                {`  ${i18n.t('staff.menuBar.question')}`}
              </MyLink>
              <MyLink
                to={Router.staffGroups}
                permissionId={StaffRouters.staffGroups.permissionId}
                className={`staff-layout-menu-link ${getMenuPath(
                  [Router.staffGroups, Router.staffGroupCreate],
                  path
                )}`}
              >
                <i className='pi pi-sitemap' />
                {i18n.t('staff.menuBar.staffGroups')}
              </MyLink>
              <MyLink
                to={Router.staffDocuments}
                permissionId={StaffRouters.staffDocuments.permissionId}
                className={`staff-layout-menu-link ${getMenuPath(
                  Router.staffDocuments,
                  path
                )}`}
              >
                <i className='pi pi-pencil' />
                {i18n.t('staff.menuBar.documents')}
              </MyLink>
            </div>
          </div>
        </div>
        <div
          className='staff-layout-body'
          style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR }}
        >
          <ProgressBar
            i18n={i18n}
            progress={progress}
            removeProgress={progress => removeProgress(progress)}
            setProgress={progress => setProgress(progress)}
            user={staff}
          />
          {children}
        </div>
      </div>
    </div>
  )
}

export default StaffLayoutScene
