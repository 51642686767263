import moment from 'moment'
import React, { useState } from 'react'
import './TableResult.css'
import { Button } from 'primereact/button'
import numeralCurrency from '@medical/libs/numeralCurrency'
import { Pagination } from 'antd'
import { ActionItem } from '../AvailableShift/AvailableShiftComponents/ActionItem'
import { onChangePageAnt } from '@medical/libs/onPageChange'
import DialogViewShift from '../DoctorHome/components/DialogViewShift'

const TableResult = ({
  history,
  search,
  i18n,
  tableData,
  PaginationProps,
  addItemToCart,
  removeItemFromCart,
  cartItems,
  page,
}) => {
  const [selectedShift, setSelectedShift] = useState()

  const dataList = []
  tableData.map(item => {
    dataList.push({
      isSpecial: item.isSpecial,
      isEarlySpecial: item.isEarlySpecial,
      id: item.id,
      startTime: moment(item.startTime)
        .utc()
        .format(),
      endTime: moment(item.endTime)
        .utc()
        .format(),
      clinicalDepartment: item.clinicalDepartment,
      dailySalary: item.dailySalary,
      clinicName: item.clinicalDepartment.clinic.name,
      departments: item.clinicalDepartment.name,
      comment: item.comment,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    })
    return true
  })

  const handleCloseViewShift = () => {
    setSelectedShift(undefined)
  }

  const handleClickAdd = value => {
    if (value.comment) {
      setSelectedShift(value)
    } else {
      addItemToCart(value)
    }
  }

  return (
    <>
      <div className='table-result'>
        <div className='table-container'>
          <table className='table'>
            <thead>
              <tr>
                <th>{i18n.t('main.clinicName')}</th>
                <th>{i18n.t('updateProfile.departments')}</th>
                <th>{i18n.t('main.date')}</th>
                <th>{i18n.t('main.startTime')}</th>
                <th>{i18n.t('main.endTime')}</th>
                <th>{i18n.t('main.dailySalary')}</th>
                <th width='5%'> {i18n.t('main.descriptionDetail')}</th>
                <th width='5%'>{i18n.t('main.apply')}</th>
              </tr>
            </thead>
            <tbody>
              {dataList.length > 0 ? (
                dataList.map(value => (
                  <tr style={{ textAlign: 'center' }}>
                    <td>
                      {/* <div className='available-shift-list row'>
                        {value.updatedAt === value.createdAt ? (
                          <p className='tag-name'>New</p>
                        ) : (
                          <p className='tag-name update'>Update</p>
                        )}
                        
                      </div> */}
                      <div className='available-shift-list row'>
                        {value.updatedAt === value.createdAt ? (
                          <p className='tag-name'>New</p>
                        ) : (
                          <p className='tag-name update'>Update</p>
                        )}
                        <p> {value.clinicalDepartment.clinic.name}</p>
                      </div>
                    </td>

                    <td>{i18n.t(`main.${value.clinicalDepartment.name}`)}</td>

                    <td>{moment(value.startTime).format('LL (dd)')}</td>

                    <td>{moment(value.startTime).format('HH:mm')}</td>

                    <td>{moment(value.endTime).format('HH:mm')}</td>

                    <td>
                      <div style={{
                        display: 'flex',
                        gap: '8px',
                        justifyContent: 'center',
                      }}>
                        {value.isSpecial && (
                          <p
                            style={{
                              margin: 0,
                              backgroundColor: '#ff2575',
                              color: '#FFFFFF',
                              padding: '2px 12px',
                              borderRadius: '15px',
                              fontWeight: 'bold',
                            }}
                          >
                            特別時給
                          </p>
                        )}
                        {value.isEarlySpecial && (
                          <p
                            style={{
                              margin: 0,
                              backgroundColor: '#FF4013',
                              color: '#FFFFFF',
                              padding: '2px 12px',
                              borderRadius: '15px',
                              fontWeight: 'bold',
                            }}
                          >
                            早特時給
                          </p>
                        )}
                      </div>
                      {numeralCurrency(value.dailySalary)}
                    </td>

                    <td>
                      <ActionItem item={value} type='availableShifts' />
                    </td>

                    <td>
                      <div className='table-action'>
                        {cartItems.some(element => element.id === value.id) ? (
                          <Button
                            onClick={() => removeItemFromCart(value.id)}
                            icon='pi pi-times'
                            className='p-button-danger'
                          />
                        ) : (
                          <Button
                            onClick={() => {
                              handleClickAdd(value)
                            }}
                            icon='pi pi-plus'
                            className='p-button-success'
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td style={{ textAlign: 'center' }} colSpan={8}>
                    {i18n.t('main.noRequestedAvailableShift')}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <Pagination
          total={PaginationProps.count}
          size='small'
          pageSize={PaginationProps.pageSize}
          current={parseInt(page)}
          onChange={onChangePageAnt(history, search)}
          style={{ textAlign: 'center', margin: '30px 0px' }}
          showSizeChanger={false}
        />
      </div>

      {!!selectedShift && (
        <DialogViewShift
          open={!!selectedShift}
          onClose={handleCloseViewShift}
          item={selectedShift}
          addItemToCart={addItemToCart}
          isAddToCart
        />
      )}
    </>
  )
}

export default TableResult
