import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import {
  STAFF_CHECK_HOURLYWAGE_CSV,
  STAFF_CREATE_HOURLY_WAGE_SCHEDULES,
  STAFF_DELETE_HOURLY_WAGE_SCHEDULES,
  STAFF_HOURLYWAGE_CSV,
  STAFF_UPDATE_HOURLY_WAGE_SCHEDULES,
} from '@medical/constant/permissions'
import {
  onPageChange,
  checkStaffPermission,
  clinicalDepartmentNames,
  combineNames,
  sortAndFilterByClinicOrder,
} from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import moment from 'moment'
import queryString from 'query-string'
import React, { useEffect } from 'react'
import { useState } from 'react'

import { HOURLY_WAGE_SCHEDULE } from './ClinicsHourlyWage.graphql'
import ClinicsHourlyWageScene from './ClinicsHourlyWageScene'

const ClinicsHourlyWageList = ({ history, location: { search } }) => {
  const [orderBy, setOrderBy] = useState('startDate_DESC')
  const [rowsSelect, setRowSelected] = useState([])

  const [{ i18n }] = useCustom()
  const isCreatePermitted = checkStaffPermission({
    functionName: STAFF_CREATE_HOURLY_WAGE_SCHEDULES,
  })
  const isUpdatePermitted = checkStaffPermission({
    functionName: STAFF_UPDATE_HOURLY_WAGE_SCHEDULES,
  })
  const isDeletePermitted = checkStaffPermission({
    functionName: STAFF_DELETE_HOURLY_WAGE_SCHEDULES,
  })
  const isUploadPermitted = checkStaffPermission({
    functionName: STAFF_HOURLYWAGE_CSV,
  })
  const isUploadCheckPermitted = checkStaffPermission({
    functionName: STAFF_CHECK_HOURLYWAGE_CSV,
  })
  const {
    clinicalDepartmentIds = '',
    page = 1,
    rowsPerPage = 50,
    date = '',
  } = queryString.parse(search)
  const clinicalDepartmentIds2 =
    clinicalDepartmentIds !== '' ? clinicalDepartmentIds.split(',') : []
  const startDate = date?.split(',')[0]
  const endDate = date?.split(',')[1]
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  const queryClinicalDepartmentIds = clinicalDepartmentIds2.map(cl => ({
    ClinicalDepartment: {
      id: cl,
    },
  }))
  const clinicalDepartmentInput = {
    ClinicalDepartment: {
      Clinic: {
        deletedAt: null,
      },
    },
  }
  const dateWhereInputs = {
    startDate_lte: moment(endDate).toISOString(),
    endDate_gte: moment(startDate).toISOString(),
    ...clinicalDepartmentInput,
  }
  const variables = {
    first: first < 0 ? 50 : first,
    skip: skip < 0 ? 0 : skip,
    orderBy,
    where:
      date !== '' && queryClinicalDepartmentIds.length > 0
        ? {
            AND: [dateWhereInputs, { OR: queryClinicalDepartmentIds }],
          }
        : date === '' && queryClinicalDepartmentIds.length === 0
        ? undefined
        : date === ''
        ? {
            OR: queryClinicalDepartmentIds,
          }
        : dateWhereInputs,
  }
  const { loading, error, data, refetch } = useQuery(HOURLY_WAGE_SCHEDULE, {
    variables,
    fetchPolicy: 'network-only',
  })
  useEffect(() => {
    refetch()
  })
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  const onDropdownChange = (e, setFieldValue) => {
    setFieldValue(
      'clinicalDepartmentIds',
      e.value.map(({ id }) => id)
    )
  }
  const {
    defaultHourlyWageSchedulesConnection: {
      edges: defaultHourlyWageSchedules,
      aggregate: { count },
    },
    departments,
    clinicalDepartments,
  } = data

  const sortedClinicalDepartments = sortAndFilterByClinicOrder({
    clinicalDepartments,
    // isFilter: true,
  })
  // const sortedHourlyWage = defaultHourlyWageSchedules.sort(
  //   (a, b) => moment(a.startDate) - moment(b.startDate)
  // )
  // const sortedHourlyWage1 = sortedHourlyWage.sort((a, b) => {
  //   if (a.startDate === b.startDate)
  //     return moment(a.startTime) - moment(b.startTime)
  //   return 0
  // })
  // const sortedHourlyWage2 = sortedHourlyWage1.sort((a, b) => {
  //   if (a.startTime === b.startTime)
  //     return moment(a.endTime) - moment(b.endTime)
  //   return 0
  // })

  // const resultHourlyWageSchedulesLists = []
  const departmentNames = clinicalDepartmentNames({ departments })
  // for (let i = 0; i < clinicalDepartments.length; i += 1) {
  //   let count = 0
  //   for (let j = 0; j < sortedHourlyWage2.length; j += 1) {
  //     if (
  //       clinicalDepartments[i].id === sortedHourlyWage2[j].clinicalDepartment.id
  //     ) {
  //       count += 1
  //     }
  //   }
  //   if (count === 0) {
  //     resultHourlyWageSchedulesLists.push({
  //       id: new Date().getUTCMilliseconds() + clinicalDepartments[i].id,
  //       clinicName: ClinicNameRender({
  //         clinicalDepartment: clinicalDepartments[i],
  //       }),
  //       clinicId: clinicalDepartments[i].clinic.id,
  //       clinicalDepartmentId: clinicalDepartments[i].id,
  //       departmentCode: clinicalDepartments[i].departmentCode,
  //       clinicalDepartmentName: departmentNames[clinicalDepartments[i].name],
  //     })
  //   }
  // }

  // sortedHourlyWage2.map(item => {
  //   resultHourlyWageSchedulesLists.push({
  //     id: item.id,
  //     clinicName: ClinicNameRender({
  //       clinicalDepartment: item.clinicalDepartment,
  //     }),
  //     clinicId: item.clinicalDepartment.clinic.id,
  //     clinicalDepartmentId: item.clinicalDepartment.id,
  //     departmentCode: item.clinicalDepartment.departmentCode,
  //     clinicalDepartmentName: departmentNames[item.clinicalDepartment.name],
  //     hourlyWageDefault: item.hourlyWageDefault,
  //     startDate: item.startDate,
  //     startTime: item.startTime,
  //     createdAt: item.createdAt,
  //     updatedAt: item.updatedAt,
  //     endDate: item.endDate,
  //     endTime: item.endTime,
  //     classify: item.classify,
  //     isAvailableOnMonday: item.isAvailableOnMonday
  //       ? i18n.t('staff.editClinic.monday')
  //       : '',
  //     isAvailableOnTuesday: item.isAvailableOnTuesday
  //       ? i18n.t('staff.editClinic.tuesday')
  //       : '',
  //     isAvailableOnWednesday: item.isAvailableOnWednesday
  //       ? i18n.t('staff.editClinic.wednesday')
  //       : '',
  //     isAvailableOnThursday: item.isAvailableOnThursday
  //       ? i18n.t('staff.editClinic.thursday')
  //       : '',
  //     isAvailableOnFriday: item.isAvailableOnFriday
  //       ? i18n.t('staff.editClinic.friday')
  //       : '',
  //     isAvailableOnSaturday: item.isAvailableOnSaturday
  //       ? i18n.t('staff.editClinic.saturday')
  //       : '',
  //     isAvailableOnSunday: item.isAvailableOnSunday
  //       ? i18n.t('staff.editClinic.sunday')
  //       : '',
  //     isAvailableOnHoliday: item.isAvailableOnHoliday
  //       ? i18n.t('staff.editClinic.holi')
  //       : '',
  //   })
  //   return resultHourlyWageSchedulesLists
  // })
  // const sortedHourlyWageSchedulesLists = resultHourlyWageSchedulesLists.sort(
  //   (a, b) => a.departmentCode - b.departmentCode
  // )
  const onSortHandle = e => {
    if (e.sortField === 'startDate') {
      setOrderBy(
        orderBy !== 'startDate_ASC' ? 'startDate_ASC' : 'startDate_DESC'
      )
    }
    if (e.sortField === 'createdAt') {
      setOrderBy(
        orderBy !== 'createdAt_ASC' ? 'createdAt_ASC' : 'createdAt_DESC'
      )
    }
    if (e.sortField === 'updatedAt') {
      setOrderBy(
        orderBy !== 'updatedAt_ASC' ? 'updatedAt_ASC' : 'updatedAt_DESC'
      )
    }
  }

  const hourlyWageSchedules = defaultHourlyWageSchedules.map(({ node }) => ({
    ...node,
    isAvailableOnMonday: node.isAvailableOnMonday
      ? i18n.t('staff.editClinic.monday')
      : '',
    isAvailableOnTuesday: node.isAvailableOnTuesday
      ? i18n.t('staff.editClinic.tuesday')
      : '',
    isAvailableOnWednesday: node.isAvailableOnWednesday
      ? i18n.t('staff.editClinic.wednesday')
      : '',
    isAvailableOnThursday: node.isAvailableOnThursday
      ? i18n.t('staff.editClinic.thursday')
      : '',
    isAvailableOnFriday: node.isAvailableOnFriday
      ? i18n.t('staff.editClinic.friday')
      : '',
    isAvailableOnSaturday: node.isAvailableOnSaturday
      ? i18n.t('staff.editClinic.saturday')
      : '',
    isAvailableOnSunday: node.isAvailableOnSunday
      ? i18n.t('staff.editClinic.sunday')
      : '',
    isAvailableOnHoliday: node.isAvailableOnHoliday
      ? i18n.t('staff.editClinic.holi')
      : '',
  }))
  return (
    <ClinicsHourlyWageScene
      i18n={i18n}
      onSortHandle={onSortHandle}
      orderBy={{
        createdAt: orderBy === 'createdAt_DESC',
        updatedAt: orderBy === 'updatedAt_DESC',
        startDate: orderBy === 'startDate_DESC',
      }}
      rowsPerPage={parseInt(rowsPerPage, 10)}
      count={count}
      page={parseInt(page, 10)}
      onPageChange={onPageChange(history, search)}
      defaultHourlyWageSchedules={hourlyWageSchedules}
      refetchHourlyWageSchedules={refetch}
      isCreatePermitted={isCreatePermitted}
      isUpdatePermitted={isUpdatePermitted}
      isDeletePermitted={isDeletePermitted}
      clinicalDepartments={combineNames({
        clinicalDepartments: sortedClinicalDepartments,
        departmentNames,
      })}
      clinicalDepartmentIds={clinicalDepartmentIds2}
      onDropdownChange={onDropdownChange}
      isUploadPermitted={isUploadPermitted}
      isUploadCheckPermitted={isUploadCheckPermitted}
      date={date?.split(',') || [null, null]}
      rowsSelect={rowsSelect}
      setRowSelected={setRowSelected}
    />
  )
}

export default ClinicsHourlyWageList
