/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
import BreadCrumb from '@medical/components/Breadcrumb'
import Router from '@medical/routes/router'
import { Formik } from 'formik'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { Paginator } from 'primereact/paginator'
import queryString from 'query-string'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './style.css'

const QuestionsScene = ({
  i18n,
  history,
  edges = [],
  contentSearch,
  categorySearch = {},
  rowsPerPage,
  page,
  count,
  onPageChange,
  onSubmit,
  listCategory,
}) => {
  const category = categorySearch || {}
  const [content, setContent] = useState(contentSearch)
  const search = categorys => {
    history.push({
      pathname: Router.doctorQuestions,
      search: queryString.stringify({
        category: categorys && categorys.value ? categorys.value : undefined,
        content,
      }),
    })
  }
  return (
    <React.Fragment>
      <div style={{ width: '70%', margin: 'auto' }}>
        <div className='doctor-header'>
          <div className='doctor-title'>
            {i18n.t('doctor.questions.title')}
            <BreadCrumb items={[{ title: i18n.t('doctor.questions.title') }]} />
          </div>
        </div>
        <div>
          <div className='box'>
            <Formik
              initialValues={{ confirm: false, announceId: '' }}
              onSubmit={onSubmit}
              render={formikProps => (
                <div>
                  <div className='search-parent'>
                    <div className='dropdown-category'>
                    <Dropdown
                      value={category}
                      name='category'
                      options={listCategory}
                      optionLabel='description'
                      valueLabel='value'
                      placeholder={i18n.t('staff.questions.category')}
                      onChange={e => {
                        search(e.value)
                      }}
                    />
                    {category || content ? (
                      <Link
                        to={{
                          pathname: Router.doctorQuestions,
                        }}
                      >
                        <Button
                          icon='pi pi-times'
                          className='p-button-secondary'
                        />
                      </Link>
                    ) : null}
                    </div>
                   <div className='input-search'>
                     <InputText

                      value={content}
                      placeholder='検索'
                      onChange={e => {
                        setContent(e.target.value)
                      }}
                      // style={{
                      //   width: '67%',
                      //   marginLeft: 'auto',
                      // }}

                      
                    />
                    <Link
                    className='link-question'
                      to={{
                        pathname: Router.doctorQuestions,
                        search: queryString.stringify({
                          category:
                            category && category.value
                              ? category.value
                              : undefined,
                          content,
                        }),
                      }}
                    >
                      <Button
                        label={i18n.t('main.search')}
                        icon='pi pi-search'
                      />
                    </Link>
                   </div>
                  </div>
                  <p />
                  <div>
                    <div style={{ marginLeft: '20px', overflowX: 'auto' }}>
                      {edges || edges.length > 0
                        ? edges.map(({ node }) => (
                            <div>
                              <div className='question-category'>
                                {node.category.content}
                              </div>
                              <div className='question-title'>
                                <>
                                  <span
                                    style={{
                                      fontWeight: 'bold',
                                      fontSize: '16px',
                                    }}
                                  >{`Q. `}</span>
                                  <span>{node.title}</span>
                                </>
                              </div>
                              <div className='question-content'>
                                <>
                                  <div
                                    style={{
                                      fontWeight: 'bold',
                                      fontSize: '16px',
                                    }}
                                  >{`A. `}</div>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: node.content,
                                    }}
                                  />
                                </>
                              </div>
                              <p />
                              <p />
                            </div>
                          ))
                        : null}
                      <div style={{ width: '100%', textAlign: 'center' }}>
                        {edges.length === 0 && (category || content)
                          ? '検索結果に該当する質問内容は存在しません。'
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />
            <Paginator
              rows={rowsPerPage}
              totalRecords={count}
              first={(page - 1) * rowsPerPage}
              onPageChange={onPageChange}
              leftContent={
                <div className='paginator-total'>
                  {i18n.t('main.paginationTotal')}: {count}
                </div>
              }
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default QuestionsScene
