import BreadCrumb from '@medical/components/Breadcrumb'
import ModalLink from '@medical/components/ModalLink'
import { TYPE_CREATE } from '@medical/constant'
import Router from '@medical/routes/router'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Paginator } from 'primereact/paginator'
import queryString from 'query-string'
import React from 'react'
import { Link } from 'react-router-dom'
const removeSpace = fullname => {
  if (!fullname) {
    return undefined
  }
  const halfspace = ` `
  // eslint-disable-next-line no-irregular-whitespace
  const fullspace = `　`
  if (!fullname.includes(halfspace) && !fullname.includes(fullspace)) {
    return fullname
    // eslint-disable-next-line no-else-return
  } else {
    const newString = fullname.replace(halfspace, '').replace(fullspace, '')
    return newString
  }
}

const RecruitmentScheduleShiftListScene = ({
  i18n,
  page,
  rowsPerPage,
  fullname,
  name_contains,
  edges,
  count,
  onPageChange,
  isUpdateRecruitment = true,
  isCreateScheduleAvailableShift,
  fortmatDate,
  onSortHandle,
  orderBy,
}) => {
  // const convertRepeatSettingToJP = type => {
  //   let result = ''
  //   switch (type) {
  //     case TYPE_CREATE.DAILY:
  //       result = i18n.t('staff.scheduledShiftGroup.daily')
  //       break
  //     case TYPE_CREATE.WEEKLY:
  //       result = i18n.t('staff.scheduledShiftGroup.weekly')
  //       break
  //     case TYPE_CREATE.MONTHLY:
  //       result = i18n.t('staff.scheduledShiftGroup.monthly')
  //       break
  //     default:
  //       result = ''
  //   }
  //   return result
  // }
  // const renderAvailableDay = (
  //   isAvailableOnMonday,
  //   isAvailableOnTuesday,
  //   isAvailableOnWednesday,
  //   isAvailableOnThursday,
  //   isAvailableOnFriday,
  //   isAvailableOnSaturday,
  //   isAvailableOnSunday,
  //   isAvailableOnHoliday
  // ) => {
  //   let availableDay = ''
  //   if (
  //     !isAvailableOnMonday ||
  //     !isAvailableOnTuesday ||
  //     !isAvailableOnWednesday ||
  //     !isAvailableOnThursday ||
  //     !isAvailableOnFriday
  //   ) {
  //     if (isAvailableOnMonday) availableDay += '月'
  //     if (isAvailableOnTuesday) availableDay += '火'
  //     if (isAvailableOnWednesday) availableDay += '水'
  //     if (isAvailableOnThursday) availableDay += '木'
  //     if (isAvailableOnFriday) availableDay += '金'
  //     if (isAvailableOnSaturday) availableDay += '土'
  //     if (isAvailableOnSunday) availableDay += '日'
  //     if (isAvailableOnHoliday) availableDay += '祝'
  //   } else {
  //     availableDay = '平日'
  //     if (isAvailableOnSaturday) availableDay += '土'
  //     if (isAvailableOnSunday) availableDay += '日'
  //     if (isAvailableOnHoliday) availableDay += '祝'
  //   }
  //   return availableDay
  // }

  return (
    <React.Fragment>
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('main.recruitmentScheduleShift.title')}
          <BreadCrumb
            items={[
              {
                title: i18n.t('main.recruitmentScheduleShift.title'),
              },
            ]}
          />
        </div>
        <div className='staff-buttons'>
          {isCreateScheduleAvailableShift ? (
            <ModalLink
              to={Router.get(Router.staffRecruitmentScheduleShiftCreate)}
            >
              <Button
                icon='pi pi-plus-circle'
                className='p-button-normal'
                label={i18n.t(
                  'main.recruitmentScheduleShift.isCreateRecruitment'
                )}
              />
            </ModalLink>
          ) : null}
        </div>
      </div>
      <div className='container is-max'>
        <div className='box'>
          <Formik
            enableReinitialize
            initialValues={{
              fullname,
              name_contains,
            }}
            render={formikProps => {
              const { values, handleChange, handleBlur } = formikProps
              return (
                <div className='available_shift_group_list-header'>
                  <div className='search-parent'>
                    <InputText
                      name='fullname'
                      value={values.fullname}
                      placeholder={i18n.t('main.fullname')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{ width: '200px', marginRight: '20px' }}
                    />
                    <InputText
                      name='name_contains'
                      value={values.name_contains}
                      placeholder={i18n.t(
                        'main.recruitmentScheduleShift.shiftTitle'
                      )}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{ width: '200px', marginRight: '20px' }}
                    />
                    <Link
                      to={{
                        pathname: Router.staffRecruitmentScheduleShift,
                        search: queryString.stringify({
                          rowsPerPage,
                          fullname: removeSpace(values.fullname),
                          name_contains: values.name_contains,
                        }),
                      }}
                      style={{ marginLeft: '20px' }}
                    >
                      <Button
                        label={i18n.t('main.search')}
                        icon='pi pi-search'
                      />
                    </Link>
                    {fullname || name_contains ? (
                      <Link
                        to={{
                          pathname: Router.staffRecruitmentScheduleShift,
                          search: queryString.stringify({
                            rowsPerPage,
                          }),
                        }}
                      >
                        <Button
                          icon='pi pi-times'
                          className='p-button-secondary'
                          style={{ marginLeft: '20px' }}
                        />
                      </Link>
                    ) : null}
                  </div>
                  <div>
                    <p className='asterisk-label-head'>
                      個別編集：定期募集シフトで作成したシフトを個別で編集した場合は「有」と表示されます。
                      <br />
                    </p>
                  </div>
                </div>
              )
            }}
          />
          <Formik
            initialValues={{
              confirm: false,
              doctorId: '',
              functionName: '',
              createdAt: orderBy === 'createdAt_DESC',
              updatedAt: orderBy === 'updatedAt_DESC',
              startDate: orderBy === 'startDate_DESC',
            }}
            render={formikProps => {
              return (
                <div className='overflow-container'>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th width='13%'>
                          {i18n.t('main.recruitmentScheduleShift.shiftTitle')}
                        </th>
                        <th width='13%'>
                          {i18n.t('main.recruitmentScheduleShift.createdAt')}
                          <Button
                            style={{
                              border: 'none',
                              backgroundColor: 'transparent',
                              color: '#686868',
                            }}
                            icon={
                              !formikProps.values.createdAt
                                ? 'pi pi-sort-down'
                                : 'pi pi-sort-up'
                            }
                            onClick={() => {
                              onSortHandle({ sortField: 'createdAt' })
                            }}
                          />
                        </th>
                        <th width='13%'>
                          {i18n.t('main.recruitmentScheduleShift.updatedAt')}
                          <Button
                            style={{
                              border: 'none',
                              backgroundColor: 'transparent',
                              color: '#686868',
                            }}
                            icon={
                              !formikProps.values.updatedAt
                                ? 'pi pi-sort-down'
                                : 'pi pi-sort-up'
                            }
                            onClick={() => {
                              onSortHandle({ sortField: 'updatedAt' })
                            }}
                          />
                        </th>
                        <th width='13%'>
                          {i18n.t('main.recruitmentScheduleShift.name')}
                        </th>
                        <th width='20%'>
                          {i18n.t('main.recruitmentScheduleShift.periodDay')}
                          <Button
                            style={{
                              border: 'none',
                              backgroundColor: 'transparent',
                              color: '#686868',
                            }}
                            icon={
                              !formikProps.values.startDate
                                ? 'pi pi-sort-down'
                                : 'pi pi-sort-up'
                            }
                            onClick={() => {
                              onSortHandle({ sortField: 'startDate' })
                            }}
                          />
                        </th>

                        <th width='11%'>
                          {i18n.t('main.recruitmentScheduleShift.workingDay')}
                        </th>
                        <th width='11%' className='asterisk-label-tail'>
                          {i18n.t('個別編集')}
                        </th>
                        {isUpdateRecruitment ? <th width='10%'></th> : null}
                      </tr>
                    </thead>
                    <tbody>
                      {edges.map(({ node }) => (
                        <tr key={node.id} style={{ textAlign: 'center' }}>
                          <td>{node.name}</td>
                          <td>{moment(node.generatedAt).format('LL (dd)')}</td>
                          <td>{moment(node.updatedAt).format('LL (dd)')}</td>
                          <td>
                            {node.updatedBy
                              ? `${node.updatedBy.lastname} ${node.updatedBy.firstname}`
                              : ''}
                          </td>
                          <td>
                            {`${moment(node.startDate).format(
                              'YYYY年MM月DD日'
                            )}～${moment
                              .utc(node.endDate)
                              .format('YYYY年MM月DD日')}`}
                          </td>
                          <td>{fortmatDate(node)}</td>

                          <td>{node.availableShifts.length ? '有' : ''}</td>
                          {isUpdateRecruitment ? (
                            <td>
                              <div className='action-doctor-list'>
                                <ModalLink
                                  to={Router.get(
                                    Router.staffRecruitmentScheduleShiftDetail,
                                    {
                                      recruitmentScheduleid: node.id,
                                    }
                                  )}
                                >
                                  <Button icon='pi pi-pencil' />
                                </ModalLink>
                              </div>
                            </td>
                          ) : null}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )
            }}
          />
          <Paginator
            rows={rowsPerPage}
            totalRecords={count}
            first={(page - 1) * rowsPerPage}
            onPageChange={onPageChange}
            leftContent={
              <div className='paginator-total'>
                {i18n.t('main.paginationTotal')}: {count}
              </div>
            }
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default RecruitmentScheduleShiftListScene
