import gql from 'graphql-tag'

export const YEARLY_DOCTOR_INCENTIVE_REPORT = gql`
  query YearlyDoctorIncentiveReport($doctorId: ID!, $date: DateTime!) {
    yearlyDoctorIncentiveReport(doctorId: $doctorId, date: $date) {
      date
      doctorClinic
      doctorDepartment
      field1
      field2
      field3
      field4
      field5
      field6
      field7
      field8
      field9
      field10
      field11
      field12
      field13
      field14
    }
  }
`
