import gql from 'graphql-tag'

export const STAFF_CREATE_SHIFT_SCHEDULE = gql`
  mutation staffCreateScheduledAvailableShiftGroupRecruitment(
    $dataTime: DayOfWeekInput!
    $isHolidays: [String!]
    $data: ScheduledAvailableShiftGroupCreateInput!
    $isPublished: Boolean!
    $repeatSetting: RepeatSettingTypes
    $dateMatrix: String
    $comment: String
    $staffComments: [WorkScheduleCommentCreateInput!]
    $numberOfRooms: Int!
    $recruitmentCategory: [AvailableShiftRecruitmentCreateInput!]
    $isSetIndividually: Boolean!
    $applyWith: IncentiveApplyType
    $workPatternApply: [String!]
    $listDoctorId: [ID!]
    $isHolidayRepeat: Boolean
    $isEarlySpecial: Boolean
    $isDoubleRecruitment: Boolean
  ) {
    staffCreateScheduledAvailableShiftGroupRecruitment(
      isHolidays: $isHolidays
      data: $data
      dataTime: $dataTime
      isPublished: $isPublished
      repeatSetting: $repeatSetting
      dateMatrix: $dateMatrix
      comment: $comment
      staffComments: $staffComments
      recruitmentCategory: $recruitmentCategory
      isSetIndividually: $isSetIndividually
      applyWith: $applyWith
      workPatternApply: $workPatternApply
      listDoctorId: $listDoctorId
      isHolidayRepeat: $isHolidayRepeat
      numberOfRooms: $numberOfRooms
      isEarlySpecial: $isEarlySpecial
      isDoubleRecruitment: $isDoubleRecruitment
    ) {
      id
    }
  }
`
export const STAFF_UPDATE_SHIFT_SCHEDULE = gql`
  mutation staffUpdateScheduledAvailableShiftGroupRecruitment(
    $scheduledAvailableShiftGroupId: ID!
    $dataTime: DayOfWeekInput!
    $isHolidays: [String!]
    $isEarlySpecial: Boolean!
    $isDoubleRecruitment: Boolean!
    $data: ScheduledAvailableShiftGroupUpdateInput!
    $isPublished: Boolean!
    $updateType: String!
    $repeatSetting: RepeatSettingTypes
    $dateMatrix: String
    $staffComments: [WorkScheduleCommentCreateInput!]
    $recruitmentCategory: [AvailableShiftRecruitmentCreateInput!]
    $comment: String
    $numberOfRooms: Int!
    $isSetIndividually: Boolean!
    $applyWith: IncentiveApplyType
    $workPatternApply: [String!]
    $listDoctorId: [ID!]
    $isChangeDoctorPublic: Boolean!
    $isHolidayRepeat: Boolean
    $availableShiftEditedList: [String!]
  ) {
    staffUpdateScheduledAvailableShiftGroupRecruitment(
      scheduledAvailableShiftGroupId: $scheduledAvailableShiftGroupId
      dataTime: $dataTime
      isHolidays: $isHolidays
      isEarlySpecial: $isEarlySpecial
      isDoubleRecruitment: $isDoubleRecruitment
      data: $data
      isPublished: $isPublished
      updateType: $updateType
      repeatSetting: $repeatSetting
      dateMatrix: $dateMatrix
      staffComments: $staffComments
      recruitmentCategory: $recruitmentCategory
      comment: $comment
      numberOfRooms: $numberOfRooms
      isSetIndividually: $isSetIndividually
      applyWith: $applyWith
      workPatternApply: $workPatternApply
      listDoctorId: $listDoctorId
      isChangeDoctorPublic: $isChangeDoctorPublic
      isHolidayRepeat: $isHolidayRepeat
      availableShiftEditedList: $availableShiftEditedList
    )
  }
`

export const STAFF_DELETE_ALL_SHIFT_SCHEDULE = gql`
  mutation STAFF_DELETE_SCHEDULED_AVAILABLE_SHIFT_GROUP_RECRUITMENT(
    $scheduledAvailableShiftGroupId: ID!
  ) {
    staffDeleteScheduledAvailableShiftGroupRecruitment(
      scheduledAvailableShiftGroupId: $scheduledAvailableShiftGroupId
    )
  }
`

export const ACTIVITIES_HISTORY = gql`
  query ACTIVITIES_HISTORY($where: ActivityHistoryWhereInput) {
    activityHistories(where: $where) {
      id
      createdAt
      activity
      staff {
        id
        firstname
        lastname
      }
    }
  }
`
