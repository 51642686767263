import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import {
  clinicalDepartmentNames,
  combineNames,
  sortAndFilterByClinicOrder,
} from '@medical/libs'
import { DOCTORS_DEPARTMENTS } from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import { RECRUITMENT_POSTS_CONNECTION } from '@medical/pages/staff/RecruitmentPost/RecruitmentPostsList/RecruitmentPost.graphql'
import React from 'react'

import FindJobScene from './FindJobScene'

const FindJobLayout = () => {
  const variables = {
    first: 4,
    skip: 0,
    orderBy: 'createdAt_DESC',
    where: {
      OR: [
        {
          ClinicalDepartment: {
            isDisplay: true,
            Clinic: {
              deletedAt: null,
            }
          }
        },
        {
          ClinicalDepartment: null
        }
      ],
      isPublished: true,
    },
  }

  const { loading, error, data } = useQuery(RECRUITMENT_POSTS_CONNECTION, {
    variables,
    fetchPolicy: 'network-only',
  })

  const {
    loading: clinicalDepartmentLoading,
    error: clinicalDepartmentErr,
    data: clinicalDepartmentData,
  } = useQuery(DOCTORS_DEPARTMENTS)
  if (loading || clinicalDepartmentLoading) return <ProgressSpinner />
  if (error || clinicalDepartmentErr)
    return <ErrorComponent error={error || clinicalDepartmentErr} />

  const {
    recruitmentPostsConnection: { edges },
  } = data
  const { clinicalDepartments, departments } = clinicalDepartmentData
  const departmentNames = clinicalDepartmentNames({ departments })
  const sortedClinicalDepartments = sortAndFilterByClinicOrder({
    clinicalDepartments,
    isFilter: true,
  })
  return (
    <FindJobScene
      edges={edges}
      clinicalDepartments={combineNames({
        clinicalDepartments: sortedClinicalDepartments,
        departmentNames,
      })}
    />
  )
}

export default FindJobLayout
