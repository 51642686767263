import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { HOLIDAY_URL } from '@medical/constant'
import { useAxios } from '@medical/hooks'
import {
  availableShiftsToEvents,
  checkDoctorBlocked,
  clinicalDepartmentNames,
  dateValidation,
  thisMonthHolidays,
  waitFor,
} from '@medical/libs'
import { thisHolidays } from '@medical/libs/thisHolidays'
import { useCustom } from '@medical/provider/context'
import {
  addItem,
  removeItem,
} from '@medical/provider/store/reducers/cart/cart.action'
import Router from '@medical/routes/router'
import moment from 'moment'
import { pathOr } from 'ramda'
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import {
  CLINICS_CONNECTION,
  CREATE_ACTIVITY,
  DOCTOR_AVAILABLE_SHIFTS_GENERATOR,
} from './AvailableShift.graphql'
// import { CLINICS_CONNECTION } from '@medical/pages/staff/Clinics/ClinicsList/ClinicsList.graphql'
import AvailableShiftScene from './AvailableShiftScene'
import queryString from 'query-string'
import { REGION_QUERY } from '@medical/pages/staff/DailyAvailableShift/DailyAvailableShift.graphql'

const initialState = {
  loading: false,
  selectedDate: null,
  availableShifts: [],
  workSchedules: [],
}
const AvailableShiftContainer = ({
  history,
  addItemToCart,
  removeItemFromCart,
  cartItems,
  match: {
    params: { year = moment().year(), month = moment().month() + 1 },
    path,
    url,
  },
}) => {
  const [{ i18n, popup }] = useCustom()
  const [toggleAvailableShift, setToggleAvailableShift] = useState(true)
  const [toggleWorkSchedule, setToggleWorkSchedule] = useState(true)
  const [doctorCreateActivity] = useMutation(CREATE_ACTIVITY)
  const date = moment()
    .year(year)
    .month(month - 1)
    .startOf('month')
  const isoDate = date.clone().toISOString()
  const [state, setState] = useState(initialState)
  const client = useApolloClient()
  const isDoctorBlocked = checkDoctorBlocked({
    client,
  })

  const calendar = useRef({})

  const { loading: queryLoading, error: queryError, data } = useQuery(
    DOCTOR_AVAILABLE_SHIFTS_GENERATOR(isDoctorBlocked),
    {
      variables: {
        date,
      },
      fetchPolicy: 'network-only',
    }
  )
  const {
    loading: loadingClinics,
    data: dataClinics,
    error: errorClinics,
    refetch,
  } = useQuery(CLINICS_CONNECTION, {
    variables: {
      where: {
        isSearchDisplay: true,
      },
    },
  })

  const { loading:listRegionLoading, error:listRegionError, data:listRegionData } = useQuery(REGION_QUERY, {
    variables: {
      deletedAt: null,
    },
  })

  const { loading, data: holidaysData, error: holidaysError } = useAxios({
    url: HOLIDAY_URL,
    params: {
      timeMin: moment(date).startOf('month').toISOString(),
      timeMax: moment(date)
        .add(1, 'year')
        .endOf('month').toISOString(),
    },
  })
  useEffect(() => {
    setState(initialState)
  }, [isoDate])

  useEffect(() => {
    refetch()
    doctorCreateActivity({
      variables: {
        activity: 'DOCTOR_SEARCHED_FOR_SHIFTS',
      },
    })
  }, [])

  if (
    dateValidation({ year, month }) ||
    [
      Router.doctorAvailableShiftsWithoutMonth,
      Router.doctorAvailableShiftsWithoutYearMonth,
    ].includes(path)
  )
    return (
      <Redirect
        to={Router.get(Router.doctorAvailableShifts, {
          year: moment().year(),
          month: moment().month() + 1,
        })}
      />
    )

  if (queryLoading || loading || loadingClinics) return <ProgressSpinner />

  if (queryError || holidaysError || errorClinics)
    return (
      <ErrorComponent error={queryError || holidaysError || errorClinics} />
    )
  if (!data.doctorMe) return <ErrorComponent />

  const {
    clinicsConnection: { edges },
  } = dataClinics
  const holidays = thisMonthHolidays({
    date,
    holidaysData: pathOr([], ['items'], holidaysData),
  })
  const hodidaysMounth = thisHolidays({
    data,
    date,
    holiday: holidays,
  })
  const { registrationStatus, lastLoggedIn } = data.doctorMe

  const events = ['REJECTED', 'PENDING', 'NEW'].includes(registrationStatus)
    ? null
    : availableShiftsToEvents(
        data.doctorMe.monthlyAvailableShifts,
        state.selectedDate,
        holidays,
        hodidaysMounth,
        lastLoggedIn
      )

  const eventClick = async arg => {
    const originTime = arg.event.start.toString().split(' GMT')[0]
    const japanTimeGMT = `${originTime} GMT+0900`

    await setState({
      ...state,
      loading: true,
      selectedDate: japanTimeGMT,
    })
    await waitFor(10)
    await setState(prevState => ({
      ...prevState,
      availableShifts: arg.event.extendedProps.data,
      workSchedules: arg.event.extendedProps.workSchedules,
      loading: false,
    }))
  }
  const departmentNames = clinicalDepartmentNames({
    departments: data.departments,
  })

  const onSubmit = async (
    {
      dateRange,
      clinics,
      startTime,
      endTime,
      morningShift,
      eveningShift,
      nightShift,
      isSpecialHourlyWage,
      hourlyWage,
      weeks,
      departments,
    },
    { setSubmitting }
  ) => {
    try {
      dateRange = Object.values(dateRange).map(item => {
        return moment(item.startDate).format('YYYYMMDD')
      })
      const dateValue = dateRange.join(',')
      const weekValue = weeks.join(',')
      const clinicsValue = clinics.join('&clinics=')
      let page = 1
      startTime = startTime ? moment(startTime).format('HH:mm') : null
      endTime = endTime ? moment(endTime).format('HH:mm') : null
      hourlyWage = hourlyWage?.value
      const query = {
        startTime,
        endTime,
        morningShift,
        eveningShift,
        nightShift,
        isSpecialHourlyWage,
        hourlyWage,
        page,
        departments,
      }

      history.push({
        pathname: Router.doctorFindAvailableShift,
        search:
          `dateRange=${dateValue}&weeks=${weekValue}&clinics=${clinicsValue}&` +
          queryString.stringify({
            ...query,
          }),
      })
      await setSubmitting(false)
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }

  return (
    <AvailableShiftScene
      i18n={i18n}
      history={history}
      loading={state.loading}
      eventClick={eventClick}
      events={events}
      calendar={calendar}
      date={date}
      availableShifts={state.availableShifts}
      workSchedules={state.workSchedules}
      addItemToCart={item => {
        popup.success('募集シフトをカートに追加しました')
        addItemToCart(item)
      }}
      removeItemFromCart={item => {
        popup.warn('募集シフトをカートから削除しました')
        removeItemFromCart(item)
      }}
      cartItems={cartItems}
      selectedDate={state.selectedDate}
      setSelectedDate={selectedDate => setState({ ...state, selectedDate })}
      toggleAvailableShift={toggleAvailableShift}
      setToggleAvailableShift={setToggleAvailableShift}
      toggleWorkSchedule={toggleWorkSchedule}
      setToggleWorkSchedule={setToggleWorkSchedule}
      isDoctorBlocked={isDoctorBlocked}
      holidays={holidays}
      hodidaysMounth={hodidaysMounth}
      departmentNames={departmentNames}
      lastLoggedIn={lastLoggedIn}
      onSubmit={onSubmit}
      activeTab={history.location.hash}
      clinics={edges}
      holidaysData={holidaysData}
      listRegionData={listRegionData}
    />
  )
}

const mapStateToProps = state => ({
  cartItems: state.cartStore.items,
})

const mapDispatchToProps = dispatch => ({
  addItemToCart: item => dispatch(addItem(item)),
  removeItemFromCart: availableShiftId =>
    dispatch(removeItem(availableShiftId)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AvailableShiftContainer)
