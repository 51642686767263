/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import BreadCrumb from '@medical/components/Breadcrumb'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import ModalLink from '@medical/components/ModalLink'
import Router from '@medical/routes/router'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { Paginator } from 'primereact/paginator'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Auth from '@medical/middleware/auth'
import Axios from 'axios'
import { waitFor } from '@medical/libs'
import ListOfIncentivesHeader from './ListOfIncentivesHeader'
import { DOWNLOAD_CSV_INCENTIVE_LIST } from '@medical/constant'

const ListOfIncentivesScene = ({
  i18n,
  list,
  page,
  onPageChange,
  count,
  rowsPerPage,
  nameS,
  incentiveS,
  titleS,
  applyTime,
  onSubmit,
  isUpdatePermitted,
  isDeletePermitted,
  year,
  month,
  socket,
  progressList,
  setProgress,
  removeProgress,
}) => {
  const hasProgress = Object.keys(progressList).includes(DOWNLOAD_CSV_INCENTIVE_LIST)
  useEffect(() => {
    if (socket && hasProgress) {
      socket.on(DOWNLOAD_CSV_INCENTIVE_LIST, data => {
        if (data?.percent) {
          setProgress({
            progress: DOWNLOAD_CSV_INCENTIVE_LIST,
            label: 'インセンティブ一覧のcsvダウンロード中',
            percent: Number(data?.percent),
          })
        }
      })
    }
  }, [socket, hasProgress])
  const incentiveType = [
    {
      name: i18n.t('main.incentiveSetting.normalIncentive'),
      value: 'NORMAL_INCENTIVE',
    },
    {
      name: i18n.t('main.incentiveSetting.numberIncentive'),
      value: 'NUMBER_INCENTIVE',
    },
    {
      name: i18n.t('main.incentiveSetting.beyound8Incentive'),
      value: 'BEYOND_EIGHT_INCENTIVE',
    },
    {
      name: i18n.t('main.incentiveSetting.timeIncentive'),
      value: 'TIME_INCENTIVE',
    },
    {
      name: i18n.t('main.incentiveSetting.satisfactionIncentive'),
      value: 'SATISFACTION_INCENTIVE',
    },
  ]
  const getIncentiveType = () => {
    let rs
    if (incentiveS) {
      incentiveType.map(as => {
        if (as.value === incentiveS) {
          rs = as
        }
      })
    }
    return rs
  }
  const [name, setName] = useState(nameS)
  const [incentive, setInsentive] = useState(getIncentiveType())
  const [title, setTitle] = useState(titleS)
  const removeSpace = fullname => {
    if (!fullname) {
      return undefined
    }
    const halfspace = ` `
    // eslint-disable-next-line no-irregular-whitespace
    const fullspace = `　`
    if (!fullname.includes(halfspace) && !fullname.includes(fullspace)) {
      return fullname
      // eslint-disable-next-line no-else-return
    } else {
      const newString = fullname.replace(halfspace, '').replace(fullspace, '')
      return newString
    }
  }
  const isIncentiveOld = as => {
    if (
      moment()
        .subtract(2, 'month')
        .isSameOrAfter(applyTime)
    ) {
      return false
    }
    if (
      moment()
        .subtract(1, 'month')
        .year() === applyTime.year() &&
      moment()
        .subtract(1, 'month')
        .month() === applyTime.month()
    ) {
      if (moment().date() > 25) {
        return false
      }
    }
    return true
  }
  return (
    <div>
      <div
        style={{
          padding: '10px',
          height: '100%',
        }}
      >
        <div className='staff-header'>
          <div className='staff-title'>
            {i18n.t('main.incentives.title')}
            <BreadCrumb items={[{ title: i18n.t('main.incentives.title') }]} />
          </div>
        </div>
        <div
          className='box'
          style={{
            backgroundColor: '#ffffff',
            marginLeft: '20px',
            marginRight: '20px',
            padding: '20px',
            borderRadius: '10px',
          }}
        >
          <div className='search-parent'>
            <InputText
              value={name}
              name='fullname'
              placeholder={i18n.t('main.incentives.name')}
              onChange={e => {
                setName(e.target.value)
              }}
              style={{ width: '170px', marginRight: '20px' }}
            />
            <Dropdown
              value={incentive}
              name='incentive'
              options={incentiveType}
              optionLabel='name'
              placeholder={i18n.t('main.incentives.incentive')}
              onChange={e => {
                setInsentive(e.target.value)
              }}
              style={{ width: '280px', marginRight: '20px' }}
            />
            <InputText
              value={title}
              placeholder={i18n.t('main.incentives.searchTitle')}
              onChange={e => {
                setTitle(e.target.value)
              }}
              style={{ width: '170px', marginRight: '20px' }}
            />
            <Link
              to={{
                pathname: Router.staffIncentive,
                search: queryString.stringify({
                  rowsPerPage,
                  name: removeSpace(name),
                  type:
                    incentive && incentive.value ? incentive.value : undefined,
                  title: removeSpace(title),
                  year: moment(applyTime).year(),
                  month: moment(applyTime).month() + 1,
                }),
              }}
              style={{ marginLeft: '20px' }}
            >
              <Button
                label={i18n.t('main.incentives.search')}
                icon='pi pi-search'
              />
            </Link>

            {name || incentive || title ? (
              <Link
                to={{
                  pathname: Router.staffIncentive,
                  search: queryString.stringify({
                    rowsPerPage,
                  }),
                }}
                style={{ marginLeft: '20px' }}
              >
                <Button
                  icon='pi pi-times'
                  className='p-button-secondary'
                  style={{ marginLeft: '20px' }}
                />
              </Link>
            ) : null}
            <div style={{ marginLeft: 'auto', marginRight: '0px' }}>
              <Button
                icon='pi pi-download'
                className='p-button-normal'
                label='CSVダウンロード'
                style={{ marginLeft: '100px' }}
                onClick={async () => {
                  setProgress({
                    progress: DOWNLOAD_CSV_INCENTIVE_LIST,
                    label: 'インセンティブ一覧のcsvダウンロード中',
                    percent: 0,
                  })
                  const token = Auth.getToken()
                  try {
                    const url = `${process.env.REACT_APP_ENDPOINT}/downloadIncentiveCsv?token=${token}&year=${year}&month=${month}`
                    const { status, data: urlS3 } = await Axios({
                      method: 'GET',
                      url,
                    })
                    await waitFor(3000)
                    if (status === 204) {
                      setProgress({
                        progress: DOWNLOAD_CSV_INCENTIVE_LIST,
                        label: 'インセンティブ一覧のcsvダウンロード中',
                        msg: i18n.t('main.msgDownloadCSVStatusError'),
                      })
                      await waitFor(3000)
                    } else {
                      setProgress({
                        progress: DOWNLOAD_CSV_INCENTIVE_LIST,
                        percent: 100,
                        url: urlS3,
                      })
                    }
                  } catch (err) {
                    setProgress({
                      progress: DOWNLOAD_CSV_INCENTIVE_LIST,
                      label: 'インセンティブ一覧のcsvダウンロード中',
                      msg: i18n.t('main.msgDownloadCSVStatusError'),
                    })
                    await waitFor(3000)
                  }
                  removeProgress(DOWNLOAD_CSV_INCENTIVE_LIST)
                }}
              />
            </div>
          </div>

          <ListOfIncentivesHeader
            date={applyTime}
            nameS={nameS}
            incentiveS={incentiveS}
            titleS={titleS}
          />
          <div>
            <Formik
              initialValues={{
                confirm: false,
                staffId: '',
              }}
              onSubmit={onSubmit}
              render={formikProps => {
                const { setFieldValue } = formikProps
                return (
                  <div>
                    <table className='table-moving-expenses'>
                      <thead>
                        <tr>
                          <th width='22%'>{i18n.t('main.incentives.name')}</th>
                          <th width='20%'>
                            {i18n.t('main.incentives.incentive')}
                          </th>
                          <th width='25%'>
                            {i18n.t('main.incentives.searchTitle')}
                          </th>
                          <th width='10%'>{i18n.t('main.incentives.cost')}</th>
                          {isIncentiveOld() &&
                          (isUpdatePermitted || isDeletePermitted) ? (
                            <th width='8%' />
                          ) : null}
                        </tr>
                      </thead>
                      <tbody>
                        {list && list.length > 0
                          ? list.map(as => (
                              <tr>
                                <td>{`${as.node.doctor.lastname} ${as.node.doctor.firstname}`}</td>
                                <td>{as.node.incentive.type}</td>
                                <td>{as.node.incentive.title}</td>
                                <td>
                                  {`￥${as.node.incentiveTotalAmount}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ','
                                  )}
                                </td>
                                {isIncentiveOld(as) &&
                                (isUpdatePermitted || isDeletePermitted) ? (
                                  <td>
                                    <div className='action'>
                                      {isUpdatePermitted ? (
                                        <ModalLink
                                          to={Router.get(
                                            Router.staffIncentiveDetail,
                                            {
                                              id: as.node.id,
                                            }
                                          )}
                                          className={
                                            Router.staffIncentiveDetail
                                          }
                                        >
                                          <Button icon='pi pi-pencil' />
                                        </ModalLink>
                                      ) : null}
                                      {isDeletePermitted ? (
                                        <Button
                                          onClick={() => {
                                            setFieldValue(
                                              'incentiveDetailId',
                                              as.node.id
                                            )
                                            setFieldValue(
                                              'doctorId',
                                              as.node.doctor.id
                                            )
                                            setFieldValue('confirm', true)
                                          }}
                                          icon='pi pi-trash'
                                        />
                                      ) : null}
                                    </div>
                                  </td>
                                ) : null}
                              </tr>
                            ))
                          : 'ご指定の月はデータがありません。'}
                      </tbody>
                    </table>
                    <ConfirmationModal
                      {...formikProps}
                      confirmationMessage='データの復元はできません。本当に削除しますか?'
                    />
                    <Paginator
                      rows={rowsPerPage}
                      totalRecords={count}
                      first={(page - 1) * rowsPerPage}
                      onPageChange={onPageChange}
                      leftContent={
                        <div className='paginator-total'>
                          {i18n.t('main.paginationTotal')}: {count}
                        </div>
                      }
                    />
                  </div>
                )
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default ListOfIncentivesScene
