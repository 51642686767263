import gql from 'graphql-tag'

export const DOCTOR_CHANGE_RESET_PASSWORD = gql`
  mutation DOCTOR_CHANGE_RESET_PASSWORD(
    $resetPasswordNumber: String!
    $newPassword: String!
  ) {
    doctorChangeResetPassword(
      resetPasswordNumber: $resetPasswordNumber
      newPassword: $newPassword
    )
  }
`

export const DOCTOR_CHECK_RESET_PASSWORD_NUMBER = gql`
  query DOCTOR_CHECK_RESET_PASSWORD_NUMBER($resetPasswordNumber: String!) {
    doctorCheckPasswordNumber(resetPasswordNumber: $resetPasswordNumber)
  }
`
