import BreadCrumb from '@medical/components/Breadcrumb'
import Router from '@medical/routes/router'
import {
  Checkbox,
  DatePicker,
  Input,
  Radio,
  Select,
  Space,
  TimePicker,
} from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Paginator } from 'primereact/paginator'
import queryString from 'query-string'
import React from 'react'
import { Link } from 'react-router-dom'

const DoctorWorkRecordScene = ({
  i18n,
  fullname,
  email,
  rowsPerPage,
  onPageChange,
  count,
  page,
  dataDepartment,
  phoneNumber,
  searchDepartments,
  specifyTime,
  timeRange,
  dayWorks,
  finalWorkDay,
  nextWorkDay,
  data,
  handleDownload
}) => {
  const format = 'HH:mm'
  const { RangePicker } = DatePicker
  const optionDepartments = []
  const { Option } = Select
  const disabledDate = current => {
    const min = moment().subtract(1, 'days')
    const max = moment().add(6, 'months')

    return !(min.isSameOrBefore(current) && max.isAfter(current))
  }
  const disabledDateLastWorkDay = current => {
    const min = moment().subtract(1, 'days')
    const max = moment().subtract(6, 'months')

    return !(max.isSameOrBefore(current) && min.isSameOrAfter(current))
  }
  dataDepartment.forEach(element => {
    optionDepartments.push(
      <Option key={element.value}>{element.description}</Option>
    )
  })

  const doctorWorks = data.map(it => ({
    id: it.id,
    email: it.email,
    phoneNumber: it.phoneNumber,
    fullname: `${it.lastname} ${it.firstname}`,
    address1: it.address1,
    address2: it.address2,
    departments: it.departments
      ? dataDepartment.filter(department =>
          it.departments.includes(department.value)
        )
      : undefined,
    nearestStations: it.nearestStations,
  }))
  const plainOptions = [
    { label: '午前(9:00-13:00)', value: 'morning' },
    { label: '午後(13:00-18:00)', value: 'afternoon' },
    { label: '夜間(18:00-21:00)', value: 'evening' },
  ]
  const days = [
    {
      value: '0',
      label: i18n.t('staff.createHourlyWage.monDay'),
    },
    {
      value: '1',
      label: i18n.t('staff.createHourlyWage.tuesDay'),
    },
    {
      value: '2',
      label: i18n.t('staff.createHourlyWage.wednesDay'),
    },
    {
      value: '3',
      label: i18n.t('staff.createHourlyWage.thursDay'),
    },
    {
      value: '4',
      label: i18n.t('staff.createHourlyWage.friDay'),
    },
    {
      value: '5',
      label: i18n.t('staff.createHourlyWage.saturDay'),
    },
    {
      value: '6',
      label: i18n.t('staff.createHourlyWage.sunDay'),
    },
    {
      value: 'isHoliday',
      label: i18n.t('staff.createHourlyWage.dayOff'),
    },
  ]
  return (
    <React.Fragment>
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('staff.menuBar.doctorWorkRecords')}
          <BreadCrumb
            items={[{ title: i18n.t('staff.menuBar.doctorWorkRecords') }]}
          />
        </div>
        <div className='staff-buttons' style={{ marginTop: '50px' }}>
        <Button
          icon='pi pi-download'
          className='p-button-gray'
          label='医師出勤傾向CSVダウンロード'
          onClick={handleDownload}
        />
        </div>
      </div>
      <div className='container is-medium box' style={{ marginBottom: '15px' }}>
        <Formik
          initialValues={{
            fullname,
            email,
            searchDepartments,
            phoneNumber,
            specifyTime: specifyTime ? JSON.parse(specifyTime) : undefined,
            timeRange,
            dayWorks,
            finalWorkDay,
            nextWorkDay,
            isSpecifyTimeRange: !!timeRange,
            isSpecifyTime: specifyTime ? !!JSON.parse(specifyTime) : false,
          }}
          render={formikProps => {
            const {
              values,
              handleBlur,
              handleChange,
              resetForm,
              setFieldValue,
            } = formikProps
            let radioGroup
            if (values.isSpecifyTimeRange) {
              radioGroup = 2
            }
            if (values.isSpecifyTime) {
              radioGroup = 1
            }
            return (
              <div className='container'>
                <div className='row'>
                  <div className='col-8' style={{ width: '80%' }}>
                    <div style={{ padding: '15px', fontWeight: 'bold' }}>
                      本日より起算して前後6ヶ月間の確定シフト情報から検索します。
                    </div>
                    <div className='tr col-5'>
                      <div className='th'>
                        {i18n.t(
                          'staff.createDoctorManageHourlyWage.doctorName'
                        )}
                      </div>
                      <div className='td'>
                        <Input
                          name='fullname'
                          value={values.fullname}
                          placeholder={i18n.t('main.fullname')}
                          onChange={handleChange}
                          size='middle'
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>

                    <div className='tr col-5'>
                      <div className='th'>
                        {i18n.t(
                          'staff.createDoctorManageHourlyWage.doctorEmail'
                        )}
                      </div>
                      <div className='td'>
                        <Input
                          name='email'
                          value={values.email}
                          placeholder={i18n.t('main.email')}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          size='middle'
                        />
                      </div>
                    </div>
                    <div className='tr col-5'>
                      <div className='th'>{i18n.t('main.phoneNumber')}</div>
                      <div className='td'>
                        <Input
                          name='phoneNumber'
                          value={values.phoneNumber}
                          placeholder={i18n.t('main.phoneNumber')}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          size='middle'
                        />
                      </div>
                    </div>
                    <div className='tr col-5'>
                      <div className='th'>{i18n.t('main.departments')}</div>
                      <div className='td'>
                        <Select
                          mode='multiple'
                          allowClear
                          value={values.searchDepartments}
                          name='searchDepartments'
                          showArrow
                          style={{ width: '100%' }}
                          placeholder={i18n.t('main.departments')}
                          onChange={value => {
                            setFieldValue('searchDepartments', value)
                          }}
                          size='middle'
                        >
                          {optionDepartments}
                        </Select>
                      </div>
                    </div>
                    <div className='tr' style={{ width: '120%' }}>
                      <div className='th' style={{ width: '12%' }}>
                        {i18n.t('main.scheduleTime')}
                      </div>
                      <div className='td'>
                        <div
                          style={{ marginBottom: '-28px', textAlign: 'center' }}
                        >
                          <span
                            style={{
                              marginRight: '20px',
                              fontWeight: 'bold',
                            }}
                          >
                            {i18n.t('main.startTime')}
                          </span>

                          <TimePicker
                            format={format}
                            showNow={false}
                            hideDisabledOptions
                            placeholder='指定なし'
                            popupClassName='timepicker'
                            disabled={!values.isSpecifyTime}
                            disabledHours={() => [0, 1, 2, 3, 4, 5, 6]}
                            value={
                              values.specifyTime && values.specifyTime.startTime
                                ? moment(values.specifyTime.startTime, 'HH:mm')
                                : null
                            }
                            onSelect={value => {
                              value
                                ? setFieldValue(
                                    'specifyTime.startTime',
                                    moment(value).format('HH:mm')
                                  )
                                : setFieldValue('specifyTime.startTime', '')
                            }}
                            onChange={value => {
                              value
                                ? setFieldValue(
                                    'specifyTime.startTime',
                                    moment(value).format('HH:mm')
                                  )
                                : setFieldValue('specifyTime.startTime', '')
                            }}
                          />
                          <span
                            style={{
                              margin: '0px 20px',
                              fontWeight: 'bold',
                            }}
                          >
                            {i18n.t('main.endTime')}
                          </span>
                          <TimePicker
                            hideDisabledOptions
                            popupClassName='timepicker'
                            showNow={false}
                            disabledHours={() => [0, 1, 2, 3, 4, 5, 6]}
                            placeholder='指定なし'
                            disabled={!values.isSpecifyTime}
                            value={
                              values.specifyTime && values.specifyTime.endTime
                                ? moment(values.specifyTime.endTime, 'HH:mm')
                                : null
                            }
                            onSelect={value => {
                              value
                                ? setFieldValue(
                                    'specifyTime.endTime',
                                    moment(value).format('HH:mm')
                                  )
                                : setFieldValue('specifyTime.endTime', '')
                            }}
                            onChange={value => {
                              value
                                ? setFieldValue(
                                    'specifyTime.endTime',
                                    moment(value).format('HH:mm')
                                  )
                                : setFieldValue('specifyTime.endTime', '')
                            }}
                            format={format}
                          />
                        </div>
                        <Radio.Group
                          value={radioGroup}
                          name='radioGroup'
                          size='middle'
                        >
                          <Space direction='vertical'>
                            <Radio
                              value={1}
                              onClick={() => {
                                setFieldValue('isSpecifyTime', true)
                                setFieldValue('isSpecifyTimeRange', false)
                                setFieldValue('timeRange', undefined)
                              }}
                              style={{ width: '118%', marginBottom: '15px' }}
                            >
                              <span
                                style={{
                                  width: '15%',
                                  marginRight: '10%',
                                  fontWeight: 'bold',
                                }}
                              >
                                {i18n.t('main.specifyTime')}
                              </span>
                            </Radio>
                            <Radio
                              value={2}
                              onClick={() => {
                                setFieldValue('isSpecifyTime', false)
                                setFieldValue('isSpecifyTimeRange', true)
                                setFieldValue('specifyTime', undefined)
                              }}
                              style={{ width: '120%' }}
                            >
                              <span
                                style={{
                                  marginRight: '8%',
                                  fontWeight: 'bold',
                                  whiteSpace: 'normal',
                                }}
                              >
                                {i18n.t('main.specifyTimeRange')}
                              </span>
                            </Radio>
                          </Space>
                        </Radio.Group>
                        <div
                          style={{ marginTop: '-20px', textAlign: 'center' }}
                        >
                          <Checkbox.Group
                            style={{ fontWeight: '600' }}
                            name='specifyTimeRange'
                            disabled={!values.isSpecifyTimeRange}
                            options={plainOptions}
                            value={values.timeRange}
                            onChange={e => {
                              setFieldValue('timeRange', e)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='tr '>
                      <div className='th workTendency-th'>勤務曜日</div>
                      <div className='td'>
                        <Checkbox.Group
                          options={days}
                          value={values.dayWorks}
                          name='dayWorks'
                          onChange={e => {
                            setFieldValue('dayWorks', e)
                          }}
                        />
                      </div>
                    </div>
                    <div className='tr '>
                      <div className='th workTendency-th'>最終勤務日</div>
                      <div className='td'>
                        <RangePicker
                          size='middle'
                          placeholder={['日付を選択', '日付を選択']}
                          format='YYYY年MM月DD日'
                          locale={locale}
                          disabledDate={disabledDateLastWorkDay}
                          value={[
                            values.finalWorkDay
                              ? moment(
                                  new Date(values.finalWorkDay[0]),
                                  'YYYY年MM月DD日'
                                )
                              : null,
                            values.finalWorkDay
                              ? moment(
                                  new Date(values.finalWorkDay[1]),
                                  'YYYY年MM月DD日'
                                )
                              : null,
                          ]}
                          onChange={value => {
                            value
                              ? setFieldValue('finalWorkDay', [
                                  moment(value[0]).format('YYYY-MM-DD'),
                                  moment(value[1]).format('YYYY-MM-DD'),
                                ])
                              : setFieldValue('finalWorkDay', '')
                          }}
                        />
                      </div>
                    </div>
                    <div className='tr '>
                      <div className='th workTendency-th note '>
                        次回勤務予定日
                      </div>
                      <div className='td'>
                        <RangePicker
                          size='middle'
                          placeholder={['日付を選択', '日付を選択']}
                          disabledDate={disabledDate}
                          format='YYYY年MM月DD日'
                          locale={locale}
                          value={[
                            values.nextWorkDay
                              ? moment(
                                  new Date(values.nextWorkDay[0]),
                                  'YYYY年MM月DD日'
                                )
                              : null,
                            values.nextWorkDay
                              ? moment(
                                  new Date(values.nextWorkDay[1]),
                                  'YYYY年MM月DD日'
                                )
                              : null,
                          ]}
                          onChange={value => {
                            value
                              ? setFieldValue('nextWorkDay', [
                                  moment(value[0]).format('YYYY-MM-DD'),
                                  moment(value[1]).format('YYYY-MM-DD'),
                                ])
                              : setFieldValue('nextWorkDay', '')
                          }}
                        />
                      </div>
                      <div
                        className='asterisk-label-head th'
                        style={{ fontWeight: 'normal' }}
                      >
                        未来分の勤務詳細は本メニューからは閲覧できません。次回勤務予定日の詳細はシフト表→「確定シフト一覧」よりご確認ください。
                      </div>
                    </div>
                  </div>
                </div>
                <div className='modal-bottom-right'>
                  <Link
                    to={{
                      pathname: Router.staffDoctorWorkRecords,
                      // search: queryString.stringify({
                      //   rowsPerPage,
                      // }),
                    }}
                    onClick={resetForm}
                  >
                    <Button
                      className='btn outline-red'
                      label={i18n.t('main.clear')}
                    />
                  </Link>
                  <Link
                    to={{
                      pathname: Router.staffDoctorWorkRecords,
                      search: queryString.stringify({
                        fullname: values.fullname,
                        email: values.email,
                        phoneNumber: values.phoneNumber,
                        searchDepartments: values.searchDepartments,
                        specifyTime: values.specifyTime
                          ? JSON.stringify(values.specifyTime)
                          : undefined,
                        timeRange: values.timeRange
                          ? values.timeRange
                          : undefined,
                        dayWorks: values.dayWorks,
                        finalWorkDay: values.finalWorkDay,
                        nextWorkDay: values.nextWorkDay,
                      }),
                    }}
                  >
                    <Button
                      style={{ padding: '0px 20px' }}
                      className='reflect-button btn'
                      label={i18n.t('main.search')}
                    />
                  </Link>
                </div>
              </div>
            )
          }}
        />
      </div>
      <div className='container is-medium box'>
        <Formik
          render={formikProps => (
            <div>
              <table className='table'>
                <thead>
                  <tr>
                    <th width='10%'>{i18n.t('doctorWorkRecord.fullname')}</th>
                    <th width='20%'>{i18n.t('doctorWorkRecord.email')}</th>
                    <th width='15%'>
                      {i18n.t('doctorWorkRecord.phoneNumber')}
                    </th>
                    <th width='20%'>
                      {i18n.t('doctorWorkRecord.departmentName')}
                    </th>
                    <th width='20%'>{i18n.t('doctorWorkRecord.address')}</th>
                    <th width='15%'>
                      {i18n.t('doctorWorkRecord.nearestStations')}
                    </th>
                    <th width='10%'>{i18n.t(i18n.t('main.action'))}</th>
                  </tr>
                </thead>
                <tbody>
                  {doctorWorks &&
                    doctorWorks.map(item => (
                      <tr key={item.id} style={{ textAlign: 'center' }}>
                        <td>{item.fullname}</td>
                        <td>
                          <a href={`mailto:${item.email}`}>{item.email}</a>
                        </td>
                        <td>{item.phoneNumber}</td>
                        <td>
                          {item.departments
                            ? item.departments.map((it, k) => (
                                <p key={k}>{it.description}</p>
                              ))
                            : null}
                        </td>
                        <td>
                          <p>{item?.address1}</p>
                          <p>{item?.address2}</p>
                        </td>
                        <td>{item.nearestStations}</td>
                        <td>
                          <Link
                            to={Router.get(Router.staffDoctorWorkReport, {
                              id: item.id,
                              year: moment().year(),
                              month: moment().month() + 1,
                            })}
                          >
                            <Button icon='pi pi-info' />
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <Paginator
                rows={rowsPerPage}
                totalRecords={count}
                first={(page - 1) * rowsPerPage}
                onPageChange={onPageChange}
                leftContent={
                  <div className='paginator-total'>
                    {i18n.t('main.paginationTotal')}: {count}
                  </div>
                }
              />
            </div>
          )}
        />
      </div>
    </React.Fragment>
  )
}

export default DoctorWorkRecordScene
