import { useMutation } from '@apollo/react-hooks'
import { ConfirmationModal } from '@medical/components'
import UploadIdentity from '@medical/components/Forms/DoctorIdentityForm/UploadIdentity'
import { validateIdentityNumber } from '@medical/constant/utilities'
import { ja } from '@medical/libs'
import { Input } from 'antd'
import { ErrorMessage, FieldArray, Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { InputText } from 'primereact/inputtext'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { DOWNLOAD_FILE } from '../Doctors/DoctorDetail/DoctorDetail.graphql'

const base64Decode = input => {
  return Buffer.from(input, 'base64').toString('ascii')
}
const ManagerDoctorIdentityScence = ({
  doctor,
  doctorIdentity,
  onSubmit,
  isProcessPermitted,
  disabled,
}) => {
  const { i18n } = useTranslation()
  const minDate = moment().subtract(100, 'year')
  const maxDate = moment()
  const yearRange = `${minDate.format('YYYY')}:${maxDate.format('YYYY')}`
  const [frontSideIdentityKey, setFrontSideIdentityKey] = useState(null)
  const [backSideIdentityKey, setBackSideIdentityKey] = useState(null)
  const [frontSideLicenseKey, setFrontSideDriverLicenseKey] = useState(null)
  const [backSideLicenseKey, setBackSideDriverLicenseKey] = useState(null)
  const [numberVisible, setNumberVisible] = React.useState(false)
  const [relationNumberVisible, setRelationNumberVisible] = React.useState(
    false
  )
  const [downloadFile] = useMutation(DOWNLOAD_FILE)
  const downloadFileSubmit = async (key, callback) => {
    if (!key || key.length === 0) return
    try {
      const {
        data: { downloadFileS3: fileURL },
      } = await downloadFile({
        variables: {
          key,
        },
      })
      callback(fileURL)
    } catch (error) {
      callback(null)
    }
  }
  useEffect(() => {
    if (doctorIdentity) {
      downloadFileSubmit(doctorIdentity.frontSideIdentityKey, fileURL => {
        setFrontSideIdentityKey(fileURL)
      }
      )
      downloadFileSubmit(doctorIdentity.backSideIdentityKey, fileURL =>
        setBackSideIdentityKey(fileURL)
      )
      downloadFileSubmit(doctorIdentity.frontSideDriverLicenseKey, fileURL =>
        setFrontSideDriverLicenseKey(fileURL)
      )
      downloadFileSubmit(doctorIdentity.backSideDriverLicenseKey, fileURL =>
        setBackSideDriverLicenseKey(fileURL)
      )
    }
  }, [doctorIdentity])
  const validationSchema = Yup.object({
    identityNumber: Yup.string()
      .required(i18n.t('validation.identity.required'))
      .test(
        'checkDigitNumber',
        i18n.t('validation.identity.checkDigit'),
        value => validateIdentityNumber(value)
      ),
    frontSideIdentityImg: Yup.mixed().required(
      i18n.t('validation.identity.myNumberRequired')
    ),
    backSideIdentityImg: Yup.mixed().required(
      i18n.t('validation.identity.myNumberRequired')
    ),
    doctorIdentityRelation: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(i18n.t('validation.identity.nameRequired')),
        relation: Yup.string().required(
          i18n.t('validation.identity.relationRequired')
        ),
        birthday: Yup.string().required(
          i18n.t('validation.identity.birthdayRequired')
        ),
        identityNumber: Yup.string()
          .required(i18n.t('validation.identity.identityNumberRequired'))
          .test(
            'checkDigitNumberRelation',
            i18n.t('validation.identity.checkDigit'),
            value => validateIdentityNumber(value)
          ),
      })
    ),
  })
  const formatDoctorIdentityRelation = array => {
    if (!array || array.length === 0) return []
    const result = array.map(element => {
      const elementReplace = {}
      elementReplace.id = element.id
      elementReplace.name = element.name
      elementReplace.relation = element.relation
      elementReplace.identityNumber = base64Decode(element.identityNumber)
      elementReplace.birthday = moment(element.birthday).toDate()
      return elementReplace
    })
    return result
  }
  return (
    <Formik
      enableReinitialize
      initialValues={{
        functionName: '',
        confirm: false,
        rejectedReason: '',
        fullName: doctor.fullName,
        email: doctor.email,
        birthday: moment(doctor.birthday).format('YYYY年MM月DD日'),
        identityNumber:
          doctorIdentity && doctorIdentity.identityNumber
            ? base64Decode(doctorIdentity.identityNumber)
            : '',
        frontSideIdentityImg: doctorIdentity?.frontSideIdentityKey,
        backSideIdentityImg: doctorIdentity?.backSideIdentityKey,
        frontSideLicenseImg: doctorIdentity?.frontSideDriverLicenseKey,
        backSideLicenseImg: doctorIdentity?.backSideDriverLicenseKey,
        deletedDoctorIdentityRelation: [],
        doctorIdentityRelation:
          doctorIdentity && doctorIdentity.doctorIdentityRelation
            ? formatDoctorIdentityRelation(
                doctorIdentity.doctorIdentityRelation
              )
            : [],
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      render={formikProps => {
        const {
          isSubmitting,
          validateForm,
          setTouched,
          setFieldValue,
          handleChange,
          handleBlur,
          values,
        } = formikProps
        return (
          <React.Fragment>
            <div className='tr'>
              <div className='th'>{i18n.t('doctor.identity.fullName')}</div>
              <div className='td'>{values.fullName}</div>
            </div>
            <div className='tr'>
              <div className='th'>{i18n.t('doctor.identity.mailAddress')}</div>
              <div className='td'>{values.email}</div>
            </div>
            <div className='tr'>
              <div className='th'>{i18n.t('doctor.identity.birthday')}</div>
              <div className='td'>{values.birthday}</div>
            </div>
            <div className='tr'>
              <div className='th'>{i18n.t('doctor.identity.myNumber')}</div>
              <div className='td'>
                <Input.Password
                  visibilityToggle={{
                    visible: numberVisible,
                    onVisibleChange: setNumberVisible,
                  }}
                  style={{ width: 200 }}
                  className='p-inputtext p-component'
                  type='text'
                  name='identityNumber'
                  placeholder={i18n.t('doctor.identity.myNumberPlaceholder')}
                  value={values.identityNumber}
                  pattern='[0-9-]*'
                  onChange={e => {
                    e.target.value = e.target.value.replace(/[^0-9-]/g, '')
                    handleChange(e)
                  }}
                  onBlur={handleBlur}
                  disabled={disabled}
                />
                <ErrorMessage
                  name='identityNumber'
                  component='div'
                  className='signup-alert'
                />
              </div>
            </div>
            {/* Start Drop Image */}
            <div className='tr'>
              <div className='th'>
                {i18n.t('staff.detailDoctorIdentity.myNumberImage')}
              </div>
            </div>
            <div className='tr'>
              <div className='td' style={{ width: '50%' }}>
                {i18n.t('doctor.identity.front')}
              </div>
              <div className='td' style={{ width: '50%' }}>
                {i18n.t('doctor.identity.back')}
              </div>
            </div>
            <div className='tr'>
              <div className='td' style={{ width: '50%' }}>
                <UploadIdentity
                  {...formikProps}
                  i18n={i18n}
                  imgKey={frontSideIdentityKey}
                  fieldValue='frontSideIdentityImg'
                  downloadFileSubmit={downloadFileSubmit}
                  onCompleteUpload={fields => {
                    setFieldValue('frontSideIdentityImg', fields.key)
                  }}
                />
                <ErrorMessage
                  name='frontSideIdentityImg'
                  component='div'
                  className='signup-alert'
                />
              </div>
              <div className='td' style={{ width: '50%' }}>
                <UploadIdentity
                  {...formikProps}
                  i18n={i18n}
                  imgKey={backSideIdentityKey}
                  fieldValue='backSideIdentityImg'
                  downloadFileSubmit={downloadFileSubmit}
                  onCompleteUpload={fields => {
                    setFieldValue('backSideIdentityImg', fields.key)
                  }}
                />
                <ErrorMessage
                  name='backSideIdentityImg'
                  component='div'
                  className='signup-alert'
                />
              </div>
            </div>
            {/* End Drop Image */}
            {/* Start Drop Image */}
            <div className='tr'>
              <div className='th'>
                {i18n.t('staff.detailDoctorIdentity.identityCard')}
              </div>
            </div>
            <div className='tr'>
              <div className='td' style={{ width: '50%' }}>
                {i18n.t('doctor.identity.front')}
              </div>
              <div className='td' style={{ width: '50%' }}>
                {i18n.t('doctor.identity.back')}
              </div>
            </div>
            <div className='tr'>
              <div className='td' style={{ width: '50%' }}>
                <UploadIdentity
                  {...formikProps}
                  i18n={i18n}
                  imgKey={frontSideLicenseKey}
                  fieldValue='frontSideLicenseImg'
                  downloadFileSubmit={downloadFileSubmit}
                  onCompleteUpload={fields => {
                    setFieldValue('frontSideLicenseImg', fields.key)
                  }}
                />
              </div>
              <div className='td' style={{ width: '50%' }}>
                <UploadIdentity
                  {...formikProps}
                  i18n={i18n}
                  imgKey={backSideLicenseKey}
                  fieldValue='backSideLicenseImg'
                  downloadFileSubmit={downloadFileSubmit}
                  onCompleteUpload={fields => {
                    setFieldValue('backSideLicenseImg', fields.key)
                  }}
                />
              </div>
            </div>
            {/* End Drop Image */}
            <div className='tr'>
              <div className='th'>
                {i18n.t('doctor.identity.familyIdentity')}
              </div>
            </div>

            <div className='container'>
              <FieldArray
                name='doctorIdentityRelation'
                render={arrayHelpers => (
                  <div>
                    <table className='table-comment-container'>
                      <thead
                        style={{
                          textAlign: 'left',
                        }}
                      >
                        <th
                          style={{
                            width: '15%',
                          }}
                        >
                          <span>{i18n.t('doctor.identity.personName')}</span>
                        </th>
                        <th
                          style={{
                            width: '2%',
                          }}
                        />
                        <th
                          style={{
                            width: '15%',
                          }}
                        >
                          <span>{i18n.t('doctor.identity.relation')}</span>
                        </th>
                        <th
                          style={{
                            width: '2%',
                          }}
                        />
                        <th>
                          <span>{i18n.t('doctor.identity.birthday')}</span>
                        </th>
                        <th
                          style={{
                            width: '2%',
                          }}
                        />
                        <th
                          style={{
                            width: '25%',
                          }}
                        >
                          <span>{i18n.t('doctor.identity.myNumber')}</span>
                        </th>
                        <th
                          style={{
                            width: '2%',
                          }}
                        />

                        <th
                          style={{
                            width: '5%',
                          }}
                        />
                      </thead>
                      <tbody>
                        {values.doctorIdentityRelation.map(
                          (doctorIdentityRelation, index) => (
                            <>
                              <tr key={doctorIdentityRelation.id}>
                                <td className>
                                  <InputText
                                    value={
                                      values.doctorIdentityRelation[index].name
                                    }
                                    name={`doctorIdentityRelation[${index}].name`}
                                    disabled={disabled}
                                    classDisabled
                                    onChange={e => {
                                      setFieldValue(
                                        `doctorIdentityRelation[${index}].name`,
                                        e.target.value
                                      )
                                    }}
                                  />
                                  <ErrorMessage
                                    name={`doctorIdentityRelation[${index}].name`}
                                    component='div'
                                    className='signup-alert'
                                  />
                                </td>
                                <td />
                                <td className>
                                  <InputText
                                    value={
                                      values.doctorIdentityRelation[index]
                                        .relation
                                    }
                                    name={`doctorIdentityRelation[${index}].relation`}
                                    disabled={disabled}
                                    classDisabled
                                    onChange={e => {
                                      setFieldValue(
                                        `doctorIdentityRelation[${index}].relation`,
                                        e.target.value
                                      )
                                    }}
                                  />
                                  <ErrorMessage
                                    name={`doctorIdentityRelation[${index}].relation`}
                                    component='div'
                                    className='signup-alert'
                                  />
                                </td>
                                <td />
                                <td className='calendar-identity'>
                                  <Calendar
                                    value={
                                      values.doctorIdentityRelation[index]
                                        .birthday ||
                                      minDate.startOf('year').toDate()
                                    }
                                    dateFormat={ja.dateFormat}
                                    locale={ja}
                                    onChange={e =>
                                      setFieldValue(
                                        `doctorIdentityRelation[${index}].birthday`,
                                        e.value
                                      )
                                    }
                                    disabled={disabled}
                                    showIcon={!disabled}
                                    yearNavigator
                                    yearRange={yearRange}
                                    monthNavigator
                                    required
                                    readOnlyInput
                                    minDate={minDate.startOf('year').toDate()}
                                    maxDate={new Date()}
                                  />
                                  <ErrorMessage
                                    name={`doctorIdentityRelation[${index}].birthday`}
                                    component='div'
                                    className='signup-alert'
                                  />
                                </td>
                                <td />
                                <td>
                                  <Input.Password
                                    visibilityToggle={{
                                      visible: relationNumberVisible,
                                      onVisibleChange: setRelationNumberVisible,
                                    }}
                                    value={
                                      values.doctorIdentityRelation[index]
                                        .identityNumber
                                    }
                                    name={`doctorIdentityRelation[${index}].identityNumber`}
                                    disabled={disabled}
                                    classDisabled
                                    pattern='[0-9-]*'
                                    onChange={e => {
                                      e.target.value = e.target.value.replace(
                                        /[^0-9-]/g,
                                        ''
                                      )
                                      setFieldValue(
                                        `doctorIdentityRelation[${index}].identityNumber`,
                                        e.target.value
                                      )
                                    }}
                                  />
                                  <ErrorMessage
                                    name={`doctorIdentityRelation[${index}].identityNumber`}
                                    component='div'
                                    className='signup-alert'
                                  />
                                </td>
                                <td />
                                <td>
                                  <Button
                                    icon='pi pi-trash'
                                    onClick={() => {
                                      if (
                                        !doctor &&
                                        (!doctorIdentityRelation.deletedAt ||
                                          doctorIdentityRelation.deletedAt
                                            .length === 0)
                                      ) {
                                        const cloneDoctorIdentityRelation = {
                                          ...doctorIdentityRelation,
                                        }
                                        cloneDoctorIdentityRelation.deletedAt = moment
                                          .utc(new Date())
                                          .format()
                                        setFieldValue(
                                          `deletedDoctorIdentityRelation`,
                                          [
                                            ...values.deletedDoctorIdentityRelation,
                                            cloneDoctorIdentityRelation,
                                          ]
                                        )
                                      }
                                      arrayHelpers.remove(index)
                                    }}
                                  />
                                </td>
                              </tr>
                              <div style={{ marginBottom: '20px' }} />
                            </>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              />
            </div>
            <div className='row add-career-button'>
              <Button
                type='button'
                disabled={disabled}
                onClick={() => {
                  setFieldValue('doctorIdentityRelation', [
                    ...values.doctorIdentityRelation,
                    {
                      name: '',
                      relation: '',
                      birthday: moment('1970/01/01').toDate(),
                      identityNumber: '',
                      deletedAt: '',
                    },
                  ])
                }}
                label={i18n.t('doctor.signup.addCareerButton')}
              />
            </div>
            <div className='modal-bottom'>
              {isProcessPermitted ? (
                <>
                  <Button
                    className='reflect-button'
                    onClick={() => {
                      validateForm().then(response => {
                        if (!Object.keys(response).length) {
                          setFieldValue('confirm', true)
                        } else {
                          setTouched(response)
                        }
                      })
                    }}
                    disabled={isSubmitting}
                    type='submit'
                    label={i18n.t('doctor.myPage.updateButton')}
                  />
                </>
              ) : null}
            </div>
            <ConfirmationModal
              {...formikProps}
              confirmationMessage={i18n.t(
                'doctor.identity.updateConfirmMessage'
              )}
            />
          </React.Fragment>
        )
      }}
    />
  )
}

export default ManagerDoctorIdentityScence
