import { FileTextOutlined } from '@ant-design/icons'
import BreadCrumb from '@medical/components/Breadcrumb'
import { useCustom } from '@medical/provider/context'
import { Collapse, Popover } from 'antd'
import Mark from 'mark.js'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import React, { useEffect, useState } from 'react'
import uuid from 'uuid'

const { Panel } = Collapse
const DoctorPortalMobile = ({
  menuNodes,
  nodeSelected,
  selectNode,
  largeTypeList,
  largeTypeSelected,
  selectLargeType,
  searchContent,
  nodesSearched,
}) => {
  const [{ i18n }] = useCustom()
  const [node1, setNode1] = useState()
  const [node2, setNode2] = useState()
  const [node3, setNode3] = useState()
  const [contentSearch, setContentSearch] = useState('')
  useEffect(() => {
    const markInstance = new Mark(document.querySelector('#search-node'))
    markInstance.unmark({
      done: () => {
        markInstance.mark(contentSearch, {
          className: 'mark',
        })
      },
    })
  }, [contentSearch, nodeSelected])
  const [listLabelVisible, setListLabelVisible] = useState(false)
  const handleVisibleChange = newVisible => {
    setListLabelVisible(newVisible)
  }

  const menu = menuNodes.map(node => {
    const onChange = key => {
      setNode1(key)
      setNode2(key)
      setNode3(key)
    }
    return (
      <>
        <Collapse bordered={false} onChange={onChange} activeKey={node1}>
          <Panel
            header={
              <>
                <FileTextOutlined
                  style={{
                    color: 'var(--primary)',
                    fontSize: '18px',
                    marginRight: '10px',
                  }}
                />
                <span
                  style={{
                    color: 'var(--primary)',
                    fontSize: '18px',
                    fontWeight: 'bold',
                  }}
                >
                  {node.label}
                </span>
              </>
            }
            key={node.label}
          >
            <Collapse bordered={false} onChange={onChange} activeKey={node2}>
              {node.children.map(nodeLv2 => (
                <Panel
                  header={
                    <span
                      style={{
                        paddingLeft: '16px',
                        color: 'var(--primary)',
                        fontSize: '16px',
                      }}
                    >
                      {nodeLv2.label}({nodeLv2.children.length})
                    </span>
                  }
                  key={nodeLv2.label}
                >
                  <Collapse
                    bordered={false}
                    onChange={onChange}
                    activeKey={node3}
                  >
                    {nodeLv2.children.map(nodeLv3 => (
                      <Panel
                        header={
                          <span
                            style={{
                              paddingLeft: '32px',
                              color: 'var(--black)',
                              fontSize: '16px',
                              textDecoration: 'underline',
                            }}
                          >
                            {nodeLv3.label}
                          </span>
                        }
                        key={nodeLv3.label}
                      >
                        <div style={{ overflow: 'scroll' }}>
                          <div
                            dangerouslySetInnerHTML={{ __html: nodeLv3.data }}
                          />
                        </div>
                      </Panel>
                    ))}
                  </Collapse>
                </Panel>
              ))}
            </Collapse>
          </Panel>
        </Collapse>
      </>
    )
  })

  const NodesSearchedContent = () => {
    if (nodesSearched.length == 0)
      return <>{i18n.t('doctor.portal.notFound')}</>
    return (
      <ul className='nodeSearch'>
        {nodesSearched.map(e => (
          <li
            key={uuid.v1()}
            onClick={() => {
              setNode1(e.largeTitle)
              setNode2(e.mediumTitle)
              setNode3(e.smallTitle)
            }}
            className='cursor-pointer'
          >
            {e.smallTitle}
          </li>
        ))}
      </ul>
    )
  }

  return (
    <div className='doctor-portal'>
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('doctor.portal.portalInformation')}
          <BreadCrumb
            items={[{ title: i18n.t('doctor.portal.portalInformation') }]}
          />
        </div>
      </div>
      <div className='container is-max-portal'>
        <div className='box'>
          <div className='header'>
            <div className='header-submit-btn'>
              <span
                id='item-order-3'
                className='p-input-icon-right header-contentInput'
              >
                <i className='pi pi-search' />
                <InputText
                  value={contentSearch}
                  onChange={e => setContentSearch(e.target.value)}
                  placeholder='フリーワード検索'
                />
              </span>
              <Popover
                content={NodesSearchedContent}
                title={contentSearch}
                placement='bottomLeft'
                trigger='click'
                visible={listLabelVisible}
                onVisibleChange={handleVisibleChange}
              >
                <Button
                  id='item-order-4'
                  onClick={() => searchContent(contentSearch)}
                  label={i18n.t('doctor.portal.search')}
                />
              </Popover>
            </div>
            <div className='header-input-search'>
              <Dropdown
                id='item-order-1'
                value={largeTypeSelected}
                options={largeTypeList.map(e => ({ label: e, value: e }))}
                onChange={e => selectLargeType(e.value)}
                placeholder='大項目を選択'
              />
              <Button
                id='item-order-2'
                icon='pi pi-times'
                className='header-clear'
                onClick={() => selectLargeType(null)}
              />
            </div>
          </div>
          <div className='body-mobile'>
            <div className='body-mobile-menu'>{menu}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DoctorPortalMobile
