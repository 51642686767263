import { DOCUMENT_FRAGMENT } from '@medical/pages/staff/Documents/DocumentsList/DocumentsList.graphql'
import gql from 'graphql-tag'

export const CREATE_DOCUMENT = gql`
  mutation CREATE_DOCUMENT($title: String!, $content: String!) {
    staffCreateDocument(title: $title, content: $content) {
      ...DOCUMENT_FRAGMENT
    }
  }
  ${DOCUMENT_FRAGMENT}
`
