import moment from 'moment'

const splitLogicReverse = ({ availableShift, key, disabledClinic }) => {
  const {
    endTime,
    splitDateTime1,
    splitDateTime2,
    splitDateTime3,
    hourlyWage,
    splitHourlyWage1,
    splitHourlyWage2,
    splitHourlyWage3,
    isStartTimeBreakTime,
    isSplitDateTime1BreakTime,
    isSplitDateTime2BreakTime,
    isSplitDateTime3BreakTime,
    startTime,
    clinicalDepartmentId,
  } = availableShift
  const splits = []
  if (splitDateTime1) {
    splits.push({
      date: moment(splitDateTime1).toDate(),
      startTime: startTime ? moment(startTime).toDate() : undefined,
      clinicalDepartmentId: clinicalDepartmentId || undefined,
      hourlyWage: splitHourlyWage1 || 0,
      isBreakTime: isSplitDateTime1BreakTime,
      isDisabled: disabledClinic,
    })
  }
  if (splitDateTime2) {
    splits.push({
      date: moment(splitDateTime2).toDate(),
      startTime: startTime ? moment(startTime).toDate() : undefined,
      clinicalDepartmentId: clinicalDepartmentId || undefined,
      hourlyWage: splitHourlyWage2 || 0,
      isBreakTime: isSplitDateTime2BreakTime,
      isDisabled: disabledClinic,
    })
  }
  if (splitDateTime3) {
    splits.push({
      date: moment(splitDateTime3).toDate(),
      startTime: startTime ? moment(startTime).toDate() : undefined,
      clinicalDepartmentId: clinicalDepartmentId || undefined,
      hourlyWage: splitHourlyWage3 || 0,
      isBreakTime: isSplitDateTime3BreakTime,
      isDisabled: disabledClinic,
    })
  }
  splits.push({
    date: moment(endTime).toDate(),
    startTime: startTime ? moment(startTime).toDate() : undefined,
    hourlyWage: hourlyWage || 0,
    isBreakTime: isStartTimeBreakTime,
    clinicalDepartmentId: clinicalDepartmentId || undefined,
    isDisabled: disabledClinic,
  })
  if (key) {
    let day
    switch (key) {
      case 'mon':
        day = 1
        break
      case 'mon_hol':
        day = '1_Hol'
        break
      case 'tue':
        day = 2
        break
      case 'tue_hol':
        day = '2_Hol'
        break
      case 'wed':
        day = 3
        break
      case 'wed_hol':
        day = '3_Hol'
        break
      case 'thu':
        day = 4
        break
      case 'thu_hol':
        day = '4_Hol'
        break
      case 'fri':
        day = 5
        break
      case 'fri_hol':
        day = '5_Hol'
        break
      case 'sat':
        day = 6
        break
      case 'sat_hol':
        day = '6_Hol'
        break
      case 'sun':
        day = 7
        break
      case 'sun_hol':
        day = '7_Hol'
        break
      case 'hol':
        day = 8
        break
      default:
        break
    }
    const split = {}
    split[day] = [...splits]
    return split
  }
  return splits
}

export default splitLogicReverse
