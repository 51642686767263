import moment from 'moment'
import { isMobile } from 'react-device-detect'

const availableShiftsToEvents = (
  monthlyAvailableShifts,
  selectedDate,
  holidays,
  hodidaysMounth,
  lastLoggedIn
) => {
  const events = []
  monthlyAvailableShifts.forEach(({ date, availableShifts }) => {
    if (availableShifts && availableShifts.length > 0) {
      for (let i = 0; i < availableShifts.length; i += 1) {
        if (moment(availableShifts[i].createdAt) - moment(lastLoggedIn) > 0) {
          if (availableShifts[i].createdAt === availableShifts[i].updatedAt) {
            events.push({
              date: moment(date).format('YYYY-MM-DD'),
              title: 'New',
              className: 'tag-name-new',
              url: '/',
            })
            break
          } else {
            events.push({
              date: moment(date).format('YYYY-MM-DD'),
              title: 'Update',
              className: 'tag-name-update',
              url: '/',
            })
            break
          }
        }
      }
    }
  })
  monthlyAvailableShifts.forEach(({ date, availableShifts }) => {
    if (availableShifts && availableShifts.length > 0) {
      for (let i = 0; i < availableShifts.length; i += 1) {
        if (availableShifts[i].isSpecial) {
          events.push({
            date: moment(date).format('YYYY-MM-DD'),
            title: '特別時給',
            className: `${
              isMobile
                ? 'tag-name-special-salary tag-name-special-salary-mobile'
                : 'tag-name-special-salary '
            }`,
            url: '/',
          })
          break
        }
      }
    }
  })
  monthlyAvailableShifts.forEach(({ date, availableShifts, workSchedules }) => {
    if (!availableShifts) {
      const classNames = ['event']
      if (workSchedules.some(({ accepted }) => accepted)) {
        classNames.push('event-only-accepted')
      } else if (workSchedules.some(({ accepted }) => !accepted)) {
        classNames.push('event-only-not-accepted')
      }

      if (selectedDate && date === selectedDate) {
        classNames.push('event-selected')
      }
      events.push({
        date: moment(date).format('YYYY-MM-DD'),
        title: 'Available',
        data: availableShifts,
        workSchedules: workSchedules,
        classNames,
      })
    } else if (availableShifts.length > 0 || workSchedules.length > 0) {
      const classNames = ['event']
      if (availableShifts.length > 0) {
        classNames.push('event-only-as')
        classNames.push('has-recruitment-shift-available')
      }
      if (workSchedules.some(({ accepted }) => accepted)) {
        classNames.push('event-only-accepted')
      } else if (workSchedules.some(({ accepted }) => !accepted)) {
        classNames.push('event-only-not-accepted')
      }

      if (selectedDate && date === selectedDate) {
        classNames.push('event-selected')
      }
      events.push({
        date: moment(date).format('YYYY-MM-DD'),
        title: 'Available',
        data: availableShifts,
        workSchedules: workSchedules,
        classNames,
      })
    }
  })

  if (holidays.length > 0) {
    holidays.forEach(data => {
      if (hodidaysMounth && hodidaysMounth.includes(data.date)) {
        events.push({
          date: moment(data.date).format('YYYY-MM-DD'),
          title: '祝日・法定休日',
          className: 'holiday',
          url: '/',
        })
      } else {
        events.push({
          date: moment(data.date).format('YYYY-MM-DD'),
          title: '祝日',
          className: 'holiday event',
          url: '/',
        })
      }
    })
  }
  const valueArr = holidays.map(function(item) {
    return item.date
  })
  if (hodidaysMounth.length > 0) {
    hodidaysMounth.forEach(data => {
      if (!valueArr.includes(data)) {
        events.push({
          date: moment(data).format('YYYY-MM-DD'),
          title: '法定休日',
          className: 'holiday event',
          url: '/',
        })
      }
    })
  }
  return events
}

export default availableShiftsToEvents
