import BreadCrumb from '@medical/components/Breadcrumb'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import Router from '@medical/routes/router'
import { AccordionTab } from 'fork/primereact/components/accordion/Accordion'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Paginator } from 'primereact/paginator'
import React from 'react'
import { Link } from 'react-router-dom'

const DocumentsListScene = ({
  i18n,
  edges,
  rowsPerPage,
  page,
  count,
  onPageChange,
  onSubmit,
}) => (
  <React.Fragment>
    <div className='staff-header'>
      <div className='staff-title'>
        {i18n.t('staff.menuBar.documents')}
        <BreadCrumb items={[{ title: i18n.t('staff.menuBar.documents') }]} />
      </div>
      <div className='staff-buttons'>
        <Link to={Router.staffDocumentCreate}>
          <Button
            label={i18n.t('staff.createDocument.submit')}
            icon='pi pi-plus'
            className='p-button-normal'
          />
        </Link>
      </div>
    </div>
    <div className='container'>
      <div className='box'>
        <div className='document-exp'>
          ※事務局スタッフ間で共有したいファイルのリンクを保存することが出来ます。
        </div>
        <Formik
          initialValues={{ confirm: false, documentId: '' }}
          onSubmit={onSubmit}
          render={formikProps => (
            <div>
              <table className='table' style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th width='20%'>{i18n.t('staff.documents.titleLabel')}</th>
                    <th width='60%'>
                      {i18n.t('staff.documents.contentLabel')}
                    </th>
                    <th width='10%'>{i18n.t('staff.documents.createdAt')}</th>
                    <th width='10%'>{i18n.t('main.action')}</th>
                  </tr>
                </thead>
                <tbody>
                  {edges.map(({ node }) => (
                    <tr key={node.id}>
                      <td>{node.title}</td>
                      <td>
                        <div
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{ __html: node.content }}
                        />
                      </td>
                      <td className='center'>
                        {moment(node.createdAt).format('LL (dd)')}
                      </td>
                      <td>
                        <div className='action'>
                          <Link
                            to={Router.get(Router.staffDocumentUpdate, {
                              documentId: node.id,
                            })}
                          >
                            <Button icon='pi pi-pencil' />
                          </Link>

                          <Button
                            onClick={() => {
                              formikProps.setFieldValue('documentId', node.id)
                              formikProps.setFieldValue('confirm', true)
                            }}
                            icon='pi pi-trash'
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <ConfirmationModal
                {...formikProps}
                confirmationMessage={i18n.t(
                  'staff.deleteDocument.submitConfirmation'
                )}
              />
            </div>
          )}
        />
        <Paginator
          rows={rowsPerPage}
          totalRecords={count}
          first={(page - 1) * rowsPerPage}
          onPageChange={onPageChange}
          leftContent={
            <div className='paginator-total'>
              {i18n.t('main.paginationTotal')}: {count}
            </div>
          }
        />
      </div>
    </div>
  </React.Fragment>
)

export default DocumentsListScene
