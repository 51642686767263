import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import BankAccountForm from '@medical/components/Forms/BankAccountForm/BankAccountForm'
import { useCustom } from '@medical/provider/context'
import React from 'react'

import {
  DOCTOR_BANK_ACCOUNT,
  DOCTOR_UPSERT_BANK_ACCOUNT,
} from './BankAccount.graphql'

const BankAccount = ({ closeModal }) => {
  const [{ i18n, popup }] = useCustom()
  const { loading, error, data } = useQuery(DOCTOR_BANK_ACCOUNT)
  const [upsertBankAccount] = useMutation(DOCTOR_UPSERT_BANK_ACCOUNT)

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const onSubmit = async (
    {
      bankName,
      bankCode,
      branchName,
      branchCode,
      accountType,
      accountNumber,
      accountHolderName,
    },
    { setSubmitting, setFieldValue }
  ) => {
    try {
      await upsertBankAccount({
        variables: {
          bankName,
          bankCode,
          branchName,
          branchCode,
          accountType,
          accountNumber,
          accountHolderName,
        },
      })
      popup.success(i18n.t('doctor.bankAccount.submissionSuccess'))
      setSubmitting(false)
      closeModal()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
      setFieldValue('confirm', false)
    }
  }

  return (
    <BankAccountForm
      i18n={i18n}
      onSubmit={onSubmit}
      bankAccount={data.doctorMe.bankAccount || {}}
      confirmationMessage={i18n.t('doctor.bankAccount.submitConfirmation')}
    />
  )
}

export default BankAccount
