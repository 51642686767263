import { Checkbox } from 'primereact/checkbox'
import { pathOr } from 'ramda'
import React from 'react'

const CustomCheckBox = ({
  label,
  name,
  disabled,
  values,
  touched,
  errors,
  setFieldValue,
  options = [],
  optionLabel = 'description',
  valueLabel = 'value',
  required,
  getCurrentValue,
  onceCheck,
  breakLine,
  inLine,
}) => {
  const isTouched = pathOr(false, [name], touched)
  const error = pathOr('', [name], errors)
  return (
    <div className='tr inline-row'>
      <div
        className={`th ${breakLine ? 'reson-label_check' : ''} ${
          inLine ? 'labelhourly_wage-checkbox' : ''
        } ${required ? 'required' : ''}`}
      >
        {label}
      </div>
      <div className={`td ${inLine ? 'inline-row pd-0' : ''}`}>
        {options.map((option, i) => (
          <div
            className={`checkbox-container pd-5 ${inLine ? 'margin-non' : ''}`}
            key={option[valueLabel]}
          >
            <Checkbox
              value={option[valueLabel]}
              disabled={disabled}
              onChange={e => {
                const selectedOptions =
                  name === 'doctorStatus' || onceCheck
                    ? [values[name]]
                    : [...values[name]]
                if (values[name].includes(e.value)) {
                  selectedOptions.splice(selectedOptions.indexOf(e.value), 1)
                } else {
                  if (getCurrentValue) {
                    getCurrentValue(e.value)
                  }
                  if (onceCheck) {
                    selectedOptions.length = 0
                  }
                  selectedOptions.push(e.value)
                }
                setFieldValue(name, selectedOptions)
              }}
              checked={values[name].includes(option[valueLabel])}
              inputId={`${i}`}
              name={name}
            />
            <label htmlFor={`${i}`} className='checkbox-label'>
              {option[optionLabel]}
            </label>
          </div>
        ))}
        <div className='alert'>{isTouched && error}</div>
      </div>
    </div>
  )
}

export default CustomCheckBox
