import { DOCTOR_TOKEN_KEY, STAFF_TOKEN_KEY } from '@medical/constant'

const isDoctor = () => localStorage.getItem(DOCTOR_TOKEN_KEY)

const isStaff = () => localStorage.getItem(STAFF_TOKEN_KEY)

export default {
  isDoctor,
  isStaff,
}
