import gql from 'graphql-tag'

export const STAFFS_CONNECTION = gql`
  query STAFFS_CONNECTION(
    $where: StaffWhereInput
    $first: Int
    $skip: Int
    $orderBy: StaffOrderByInput
  ) {
    staffsConnection(
      where: $where
      first: $first
      skip: $skip
      orderBy: $orderBy
    ) {
      edges {
        node {
          id
          firstname
          lastname
          firstnameKana
          lastnameKana
          email
          position
          staffGroup {
            id
            name
          }
        }
      }
      aggregate(where: $where) {
        count
      }
    }
  }
`

export const DELETE_STAFF = gql`
  mutation DELETE_STAFF($staffId: ID!) {
    staffDeleteStaff(staffId: $staffId)
  }
`
