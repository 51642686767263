import gql from 'graphql-tag'

export const UPLOAD_SHIFTS = gql`
  mutation STAFF_UPLOAD_DOCTOR_REPORT_INCENTIVE_CSV(
    $doctorIncentiveReportCsvRecords: [DoctorIncentiveReportCsvRecord!]!
  ) {
    staffUploadIncentiveReportCsv(
      doctorIncentiveReportCsvRecords: $doctorIncentiveReportCsvRecords
    )
  }
`

export const CHECK_UPLOAD_SHIFTS = gql`
  mutation STAFF_CHECK_UPLOAD_DOCTOR_REPORT_INCENTIVE_CSV(
    $doctorIncentiveReportCsvRecords: [DoctorIncentiveReportCsvRecord!]!
  ) {
    staffCheckUploadIncentiveReportCsv(
      doctorIncentiveReportCsvRecords: $doctorIncentiveReportCsvRecords
    ) {
      errorMessage
    }
  }
`

export const CREATE_ACTIVITY = gql`
  mutation CREATE_ACTIVITY_LOG($activity: ActivityLogTypes!, $doctorId: ID) {
    createActivityLogHistory(activity: $activity, doctorId: $doctorId)
  }
`
