import CustomCheckbox from '@medical/components/CheckBox'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import { ACCEPTED, REJECTED, WAITING_FOR_APPROVAL } from '@medical/constant'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import React, { useState } from 'react'
import * as Yup from 'yup'

const DetailWorkScheduleCancelShiftScene = ({
  onSubmit,
  i18n,
  data = {},
  historyChangeData,
  isUpdateScheduleCancel,
}) => {
  const [confirmationMessage, setConfirmationMessage] = useState()
  const validationSchema = Yup.object().shape({
    isTickCountCancel: Yup.mixed().test(
      'validateCheckbox',
      i18n.t('staff.detailWorkScheduleShift.requiredCheckbox'),
      val => {
        if (Array.isArray(val) && val.length > 0 && val[0] !== null) {
          return true
        }
        return false
      }
    ),
    isTickCountCancelDueToClinicReason: Yup.mixed().test(
      'validateCheckbox',
      i18n.t('staff.detailWorkScheduleShift.requiredCheckbox'),
      val => {
        if (Array.isArray(val) && val.length > 0 && val[0] !== null) {
          return true
        }
        return false
      }
    ),
  })
  const {
    reason,
    reassignDoctorNote,
    reworkNote,
    workSchedule,
    status,
    isTickCountCancel,
    isTickCountCancelDueToClinicReason,
  } = data.workScheduleCancellationLettersConnection.edges[0].node
  const { doctor, startTime, endTime, clinicalDepartment } = workSchedule
  const options = [
    {
      value: true,
      description: i18n.t('staff.detailWorkScheduleShift.count'),
    },
    {
      value: false,
      description: i18n.t('staff.detailWorkScheduleShift.notCount'),
    },
  ]
  const getDepartment = valueName => {
    if (!data.departments || data.departments.length == 0) {
      return ''
    }
    let rs
    data.departments.map(as => {
      if (as.value == valueName) {
        rs = as.description
        return
      }
    })
    return rs
  }
  const getTextStatusJP = inputStatus => {
    let text
    switch (inputStatus) {
      case ACCEPTED:
        text = i18n.t('main.workScheduleCancel.ACCEPTED')
        break
      case REJECTED:
        text = i18n.t('main.workScheduleCancel.REJECTED')
        break
      default:
        text = ''
    }
    return text
  }
  const convertCalendarJP = date => {
    return moment(date).format('LL (dd)')
  }
  return (
    <Formik
      enableReinitialize
      initialValues={{
        confirm: false,
        isTickCountCancel: [isTickCountCancel],
        isTickCountCancelDueToClinicReason:
          isTickCountCancelDueToClinicReason === null ||
          isTickCountCancelDueToClinicReason === undefined
            ? []
            : [isTickCountCancelDueToClinicReason ? 'yes' : 'no'],
        action: '',
        isFirstTimeProcess: false,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={formikProps => {
        const { values, setFieldValue, validateForm, setTouched } = formikProps
        return (
          <div className='detail-work-schedule-cancel-container'>
            <div className='detail-work-titles'>
              {i18n.t('staff.detailWorkScheduleShift.title')}
            </div>
            <div className='tr'>
              <div className='th custom-th'>
                {i18n.t('staff.detailWorkScheduleShift.clinicName')}
              </div>
              <div className='td custom-td'>
                {clinicalDepartment.clinic.name}
              </div>
            </div>
            <div className='tr'>
              <div className='th custom-th'>
                {i18n.t('staff.detailWorkScheduleShift.department')}
              </div>
              <div className='td custom-td'>
                {getDepartment(clinicalDepartment.name)}
              </div>
            </div>
            <div className='tr'>
              <div className='th custom-th'>
                {i18n.t('staff.detailWorkScheduleShift.doctorName')}
              </div>
              <div className='td custom-td'>
                {`${doctor.lastname} ${doctor.firstname}`}
              </div>
            </div>
            <div className='tr'>
              <div className='th custom-th'>
                {i18n.t('staff.detailWorkScheduleShift.email')}
              </div>
              <div className='td custom-td'>{doctor.email}</div>
            </div>
            <div className='tr'>
              <div className='th custom-th'>
                {i18n.t('staff.detailWorkScheduleShift.dateOfWorkSchedule')}
              </div>
              <div className='td custom-td'>
                {convertCalendarJP(moment(startTime).format('YYYY/MM/DD'))}
              </div>
            </div>
            <div className='tr'>
              <div className='th custom-th'>
                {i18n.t('staff.detailWorkScheduleShift.time')}
              </div>
              <div className='td custom-td'>{`${moment(startTime).format(
                'HH:mm'
              )} - ${moment(endTime).format('HH:mm')}`}</div>
            </div>
            <div className='tr'>
              <div className='th custom-th'>
                {i18n.t('staff.detailWorkScheduleShift.reason')}
              </div>
              <div className='td custom-td'>{reason}</div>
            </div>
            <div className='tr'>
              <div className='th custom-th'>
                {i18n.t('staff.detailWorkScheduleShift.alternativeDoctor')}
              </div>
              <div className='td custom-td'>{reassignDoctorNote}</div>
            </div>
            <div className='tr'>
              <div className='th custom-th'>
                {i18n.t('staff.detailWorkScheduleShift.alternativeSchedule')}
              </div>
              <div className='td custom-td'>{reworkNote}</div>
            </div>
            <div className=''>
              <CustomCheckbox
                {...formikProps}
                label={i18n.t('staff.detailWorkScheduleShift.typeCancel')}
                name='isTickCountCancel'
                required
                options={options}
                onceCheck
                customTH='custom-th'
                customTD='custom-td'
              />
            </div>
            <CustomCheckbox
              {...formikProps}
              label={i18n.t(
                'staff.detailWorkScheduleShift.isTickCountCancelDueToClinicReason'
              )}
              name='isTickCountCancelDueToClinicReason'
              options={[
                {
                  value: 'yes',
                  description: i18n.t(
                    'staff.detailWorkScheduleShift.countDueToClinicReason'
                  ),
                },
                {
                  value: 'no',
                  description: i18n.t(
                    'staff.detailWorkScheduleShift.notCountDueToClinicReason'
                  ),
                },
              ]}
              onceCheck
              reponsiveUI='checkbox-canncel-letter'
              customTH='custom-th'
              customTD='custom-td'
            />
            {status !== WAITING_FOR_APPROVAL ? (
              <div className='tr'>
                <div className='th custom-th'>
                  {i18n.t('staff.detailWorkScheduleShift.historyChange')}
                </div>
                <div className='history-update-container custom-td'>
                  <div className='history-update__item'>
                    {historyChangeData &&
                    historyChangeData.workScheduleCancellationLetter &&
                    historyChangeData.workScheduleCancellationLetter.updatedBy
                      ? historyChangeData.workScheduleCancellationLetter
                          .updatedBy.lastname +
                        ' ' +
                        historyChangeData.workScheduleCancellationLetter
                          .updatedBy.firstname
                      : null}
                  </div>
                  <div
                    className={`history-update__item ${
                      status === ACCEPTED ? 'color-accepct' : 'color-reject'
                    }`}
                  >
                    {historyChangeData &&
                    historyChangeData.workScheduleCancellationLetter &&
                    historyChangeData.workScheduleCancellationLetter.status
                      ? getTextStatusJP(
                          historyChangeData.workScheduleCancellationLetter
                            .status
                        )
                      : null}
                  </div>
                  <div className='history-update__item'>
                    {historyChangeData &&
                    historyChangeData.workScheduleCancellationLetter &&
                    historyChangeData.workScheduleCancellationLetter.updatedAt
                      ? convertCalendarJP(
                          moment(
                            historyChangeData.workScheduleCancellationLetter
                              .updatedAt
                          ).format('YYYY/MM/DD')
                        )
                      : null}
                  </div>
                  <div className='history-update__item'>
                    {historyChangeData &&
                    historyChangeData.workScheduleCancellationLetter &&
                    historyChangeData.workScheduleCancellationLetter.updatedAt
                      ? moment(
                          historyChangeData.workScheduleCancellationLetter
                            .updatedAt
                        ).format('HH:mm')
                      : null}
                  </div>
                </div>
              </div>
            ) : null}
            <div className='tr'>
              <div className='custom-th'>
                {i18n.t(
                  'staff.detailWorkScheduleShift.totalApplicationsCancel'
                )}
              </div>
              <div className='td custom-td'>
                {doctor && doctor.totalCancelLetter
                  ? doctor.totalCancelLetter
                  : 0}
              </div>
            </div>
            <div className='tr'>
              <div className='custom-th'>
                {i18n.t(
                  'staff.detailWorkScheduleShift.approveCountApplication'
                )}
              </div>
              <div className='td custom-td'>
                {doctor && doctor.countedCancelLetter
                  ? doctor.countedCancelLetter
                  : 0}
              </div>
            </div>
            <div className='tr'>
              <div className='custom-th'>
                {i18n.t(
                  'staff.detailWorkScheduleShift.titleCountCancelLetterDueToClinicReason'
                )}
              </div>
              <div className='td custom-td'>
                {doctor && doctor.countedCancelLetterDueToClinicReason
                  ? doctor.countedCancelLetterDueToClinicReason
                  : 0}
              </div>
            </div>
            <div className='tr '>
              <div className='custom-th'>
                {i18n.t('staff.detailWorkScheduleShift.historyCancel')}
              </div>
              <div className='td custom-td'>
                {doctor && doctor.workScheduleCancellationLetterHistory
                  ? doctor.workScheduleCancellationLetterHistory.map(item => (
                      <div className='history-staff-update-container'>
                        <div className='history-staff-update__item'>
                          {moment(item.date).format('LL (dd)')}
                        </div>
                        <div className='history-staff-update__item pl-20'>
                          {item.count}
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </div>
            <div className='tr'>
              <div className='custom-th'>
                {i18n.t(
                  'staff.detailWorkScheduleShift.historyDueToClinicReason'
                )}
              </div>
              <div className='td custom-td'>
                {doctor &&
                doctor.workScheduleCancellationLetterHistoryDueToClinicReason
                  ? doctor.workScheduleCancellationLetterHistoryDueToClinicReason.map(
                      item => (
                        <div className='history-staff-update-container'>
                          <div className='history-staff-update__item'>
                            {moment(item.date).format('LL (dd)')}
                          </div>
                          <div className='history-staff-update__item pl-20'>
                            {item.count}
                          </div>
                        </div>
                      )
                    )
                  : null}
              </div>
            </div>
            {isUpdateScheduleCancel ? (
              <>
                {status === WAITING_FOR_APPROVAL ? (
                  <div className='button-container'>
                    <Button
                      label={i18n.t('staff.detailWorkScheduleShift.btnApprove')}
                      className='btn outline-red'
                      onClick={() => {
                        validateForm().then(response => {
                          if (!Object.keys(response).length) {
                            setFieldValue('confirm', true)
                            setFieldValue('action', REJECTED)
                            setFieldValue('isFirstTimeProcess', true)
                          } else {
                            setTouched(response)
                          }
                        })

                        setConfirmationMessage(
                          i18n.t('staff.detailWorkScheduleShift.messageAccepct')
                        )
                      }}
                    />
                    <Button
                      label={i18n.t('staff.detailWorkScheduleShift.btnReject')}
                      className='reflect-button btn'
                      onClick={() => {
                        validateForm().then(response => {
                          if (!Object.keys(response).length) {
                            setFieldValue('confirm', true)
                            setFieldValue('action', ACCEPTED)
                            setFieldValue('isFirstTimeProcess', true)
                          } else {
                            setTouched(response)
                          }
                        })

                        setConfirmationMessage(
                          i18n.t('staff.detailWorkScheduleShift.messageReject')
                        )
                      }}
                    />
                  </div>
                ) : (
                  <div className='button-container'>
                    <Button
                      label={i18n.t('staff.detailWorkScheduleShift.btnUpdate')}
                      className='reflect-button btn'
                      onClick={() => {
                        validateForm().then(response => {
                          if (!Object.keys(response).length) {
                            setFieldValue('confirm', true)
                            setFieldValue('action', status)
                          } else {
                            setTouched(response)
                          }
                        })
                        setConfirmationMessage(
                          i18n.t('staff.detailWorkScheduleShift.messageUpdate')
                        )
                      }}
                    />
                  </div>
                )}
              </>
            ) : null}

            <ConfirmationModal
              {...formikProps}
              confirmationMessage={confirmationMessage}
            />
          </div>
        )
      }}
    />
  )
}

export default DetailWorkScheduleCancelShiftScene
