import moment from 'moment'

const startTime = '7:00'
const endTime = '23:59'
const step = 1

const getMoment = val => {
  const t = val.split(':')
  const tt = moment()
  tt.hours(t[0])
  tt.minutes(t[1])
  tt.seconds(0)
  return tt
}

const result = []

const fromTime = getMoment(startTime)
const toTime = getMoment(endTime)

while (fromTime.isSameOrBefore(toTime)) {
  result.push(fromTime.format('HH:mm'))
  fromTime.add(step, 'minute')
}

export default result
