import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, NotFound, ProgressSpinner } from '@medical/components'
import { STAFF_STATUS } from '@medical/constant'
import { UPDATE_MOVING_EXPENSES_REGISTRATION } from '@medical/constant/permissions'
import { checkStaffPermission } from '@medical/libs'
import MovingExpensesOtherDetailScence from '@medical/pages/staff/MovingExpenses/MovingExpensesOther/MovingExpensesOtherDetailScence'
import { useCustom } from '@medical/provider/context'
import Router from '@medical/routes/router'
import { Button } from 'primereact/button'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import {
  GET_LIST_MOVING_EXPENSES_OTHER,
  STAFF_UPDATE_TRANSPORTATION,
  TRANPORTATION_TYPE_SETTINGS,
} from './ListMovingExpensesOther.graphql'

const MovingExpensesOtherDetail = ({
  closeModal,
  match: {
    params: { doctorId },
  },
}) => {
  const [{ i18n, popup }] = useCustom()
  const accepted = 'accepted'
  const rejected = 'rejected'
  const {
    loading,
    error,
    data: dataTransportationExpenses,
    refetch,
  } = useQuery(GET_LIST_MOVING_EXPENSES_OTHER, {
    variables: {
      where: {
        id: doctorId,
      },
    },
  })
  const [update] = useMutation(STAFF_UPDATE_TRANSPORTATION)

  const {
    error: errorTypeSetting,
    loading: loadingTypeSetting,
    data: dataTypeSetting,
  } = useQuery(TRANPORTATION_TYPE_SETTINGS, {
    variables: {
      where: {
        isApply: true,
      },
    },
  })

  useEffect(() => {
    refetch()
  }, [refetch])
  const isUpdateMovingExpenses = checkStaffPermission({
    functionName: UPDATE_MOVING_EXPENSES_REGISTRATION,
  })
  if (!isUpdateMovingExpenses) {
    return (
      <div className='static-container'>
        <div className='static-title'>エラー</div>
        <div className='static-content'>{i18n.t('main.noPermissionToSee')}</div>
        <Link to={Router.staffHome}>
          <Button
            label={i18n.t('main.pageNotFoundButton')}
            className='static-button'
          />
        </Link>
      </div>
    )
  }
  if (loading || loadingTypeSetting) return <ProgressSpinner fullHeight />
  if (error || errorTypeSetting) return <ErrorComponent error={error} />
  if (!dataTransportationExpenses.transportationExpenses[0]) {
    return <NotFound />
  }

  const onSubmit = async (
    { functionName, ...values },
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    let status
    switch (functionName) {
      case accepted:
        status = STAFF_STATUS.ACCEPTED
        break
      case rejected:
        status = STAFF_STATUS.REJECTED
        break
      default:
        break
    }
    const data = {
      endTime: values.endTime,
      startTime: values.startTime,
      departurePlace:
        values.type === '駐車代' || values.type === 'マイカー'
          ? ''
          : values.departurePlace,
      arrivalPlace:
        values.type === '駐車代' || values.type === 'マイカー'
          ? ''
          : values.arrivalPlace,
      billImage: values.billImage,
      url:
        values.type === '駐車代' || values.type === 'マイカー'
          ? ''
          : values.url,
      type: values.type,
      status,
      cost: values.type === 'マイカー' ? 0 : values.cost || 0,
      category: 'RECURRING_TRAVEL_FEE',
      comment: values.comment,
    }
    try {
      await update({
        variables: {
          clinicId: values.clinic.id,
          transportationId:
            dataTransportationExpenses.transportationExpenses[0].id,
          doctorId:
            dataTransportationExpenses.transportationExpenses[0].doctor.id,
          data,
          file: values.file,
        },
      })
      popup.success(i18n.t('staff.availableShift.updateSubmissionSuccess'))
      setSubmitting(false)
      closeModal()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
      setFieldValue('confirm', false)
    }
  }
  const getClinics = () => {
    const clinics = []
    const clinicId = []
    if (
      dataTransportationExpenses &&
      dataTransportationExpenses.clinicalDepartments &&
      dataTransportationExpenses.clinicalDepartments.length > 0
    ) {
      dataTransportationExpenses.clinicalDepartments.forEach(as => {
        if (!clinicId.includes(as.clinic.id)) {
          clinics.push(as.clinic)
          clinicId.push(as.clinic.id)
        }
      })
      return clinics
    }
    return []
  }
  const clinic = getClinics()
  return (
    <MovingExpensesOtherDetailScence
      transportationExpenses={
        dataTransportationExpenses.transportationExpenses[0]
      }
      i18n={i18n}
      popup={popup}
      closeModal={closeModal}
      accepted={accepted}
      rejected={rejected}
      onSubmit={onSubmit}
      clinic={clinic}
      typeSettingData={dataTypeSetting.transportationTypeSettings}
    />
  )
}

export default MovingExpensesOtherDetail
