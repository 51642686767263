/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from '@apollo/react-hooks'
import {
  ConfirmationModal,
  ErrorComponent,
  NotFound,
  ProgressSpinner,
} from '@medical/components'
import BankAccountForm from '@medical/components/Forms/BankAccountForm/BankAccountForm'
import DoctorProfileForm from '@medical/components/Forms/DoctorProfileForm'
import DoctorStatusForm from '@medical/components/Forms/DoctorStatusForm'
import { LinkMail } from '@medical/components/Forms/DoctorStatusForm/templateEmail'
import DoctorResendConfirmationEmailForm from '@medical/components/Forms/EmailConfirmationForm'
import UploadFileComponent from '@medical/components/Forms/UploadFile'
import { PERMANENT_DOCTOR, REGULAR_SHIFT_DOCTOR } from '@medical/constant'
import {
  EDIT_DOCTOR_LICENSE_NUMBER,
  STAFF_BLOCK_DOCTOR_MAIL,
  STAFF_CREATE_DOCTOR_DELETE_REQUEST_PERMISSION,
  STAFF_EDIT_ADDRESS_DOCTOR_PROFILE,
  STAFF_EDIT_ADJUST_HOURLYWAGE_RANGE_DOCTOR_PROFILE,
  STAFF_EDIT_CAREER_HISTORY_DOCTOR_PROFILE,
  STAFF_EDIT_GRADUATIONUNIVERSITY_DOCTOR_PROFILE,
  STAFF_EDIT_INTRODUCED_BY_DOCTOR_PROFILE,
  STAFF_EDIT_JOIN_BACKGROUND_DOCTOR_PROFILE,
  STAFF_EDIT_MEDICAL_RECORD_DOCTOR_PROFILE,
  STAFF_EDIT_OBTAIN_DOCTOR_LICENSE_YEAR_DOCTOR_PROFILE,
  STAFF_EDIT_PROFESSIONAL_DESCRIPTION_DOCTOR_PROFILE,
  STAFF_EDIT_SKILL_INFORMATION_DOCTOR_PROFILE,
  STAFF_EDIT_STAFFMEMO_DOCTOR_PROFILE,
  STAFF_PAGE_STAFFMEMO_DOCTOR_PROFILE,
  STAFF_UPSERT_DOCTOR_FILE as STAFF_UPSERT_DOCTOR_FILE_2,
  VIEW_DOCTOR_LICENSE_NUMBER,
  VIEW_MEDICAL_RECORD_DOCTOR_PROFILE,
} from '@medical/constant/permissions'
import { checkStaffPermission } from '@medical/libs'
import { CREATE_ACTIVITY } from '@medical/pages/doctor/AvailableShift/AvailableShift.graphql'
import { useCustom } from '@medical/provider/context'
import { Image } from 'antd'
import { Formik } from 'formik'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { TabPanel, TabView } from 'primereact/tabview'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { checkIsPdf } from '@medical/constant/utilities'
import { PreviewImgDialog } from '@medical/components/PreviewImgDialog'
import { DoctorStatusTemplate } from '../DoctorsPendingList/DoctorStatusTemplate'
import {
  DELETE_DOCTOR_FILE,
  DOCTOR_QUERY,
  DOCTOR_RESEND_CONFIRMATION_EMAIL,
  DOWNLOAD_FILE_WITH_CHECK_TYPE,
  QUERY_STAFF,
  STAFF_APPROVE_DOCTOR,
  STAFF_BLOCK_OR_UNBLOCK_DOCTOR,
  STAFF_CREATE_DOCTOR_DELETE_REQUEST,
  STAFF_UPDATE_DOCTOR,
  STAFF_UPSERT_DOCTOR_FILE,
} from './DoctorDetail.graphql'
import WorkTendencyForm from './WorkTendencyForm'
import DoctorInterviewSchedule from './DoctorInterviewSchedule'
import './style.css'

const DoctorDetail = ({
  history,
  location: { pathname, search },
  match: {
    params: { doctorId },
  },
}) => {
  const isFromDeleteRequestList = pathname.includes('doctor_delete_request')
  const [newOther, setNewOther] = useState(false)
  const [img, setImg] = useState({ fileURL: '', imgKey: '' })
  const [{ i18n, popup }] = useCustom()
  const {
    activeIndex = 0,
    editable = true,
    uploadFileCategory,
    fileId,
    ...restSearch
  } = queryString.parse(search)
  const [deleteFile] = useMutation(DELETE_DOCTOR_FILE)
  const [downloadFile] = useMutation(DOWNLOAD_FILE_WITH_CHECK_TYPE)
  const [uploadFile] = useMutation(STAFF_UPSERT_DOCTOR_FILE)
  const [updateDoctor] = useMutation(STAFF_UPDATE_DOCTOR)
  const [blockOrUnblockDoctorMail] = useMutation(STAFF_BLOCK_OR_UNBLOCK_DOCTOR)
  const [processPendingDoctor] = useMutation(STAFF_APPROVE_DOCTOR)
  const [resendConfirmationEmail] = useMutation(
    DOCTOR_RESEND_CONFIRMATION_EMAIL
  )
  const [createDoctorDeleteRequest] = useMutation(
    STAFF_CREATE_DOCTOR_DELETE_REQUEST
  )
  const [staffCreateActivity] = useMutation(CREATE_ACTIVITY)
  const { loading, error, data, refetch } = useQuery(DOCTOR_QUERY, {
    variables: {
      doctorId,
    },
  })
  const {
    loading: staffMeLoading,
    error: staffMeError,
    data: staffMeData,
  } = useQuery(QUERY_STAFF)

  useEffect(() => {
    refetch()
  })
  if (loading || staffMeLoading) return <ProgressSpinner />
  if (error || staffMeError) return <ErrorComponent error={error} />
  const isEditAddressDoctorProfile = checkStaffPermission({
    functionName: STAFF_EDIT_ADDRESS_DOCTOR_PROFILE,
  })
  const isEditGraduationUniversityDoctorProfile = checkStaffPermission({
    functionName: STAFF_EDIT_GRADUATIONUNIVERSITY_DOCTOR_PROFILE,
  })
  const isEditAdjustHourlyWageRangeDoctorProfile = checkStaffPermission({
    functionName: STAFF_EDIT_ADJUST_HOURLYWAGE_RANGE_DOCTOR_PROFILE,
  })
  const isEditIntroducedByDoctorProfile = checkStaffPermission({
    functionName: STAFF_EDIT_INTRODUCED_BY_DOCTOR_PROFILE,
  })
  const isEditStaffMemoDoctorProfile = checkStaffPermission({
    functionName: STAFF_EDIT_STAFFMEMO_DOCTOR_PROFILE,
  })
  const isPageStaffMemoDoctorProfile = checkStaffPermission({
    functionName: STAFF_PAGE_STAFFMEMO_DOCTOR_PROFILE,
  })
  const isEditJoinBackgroundDoctorProfile = checkStaffPermission({
    functionName: STAFF_EDIT_JOIN_BACKGROUND_DOCTOR_PROFILE,
  })
  const isEditProfessionalDescriptionDoctorProfile = checkStaffPermission({
    functionName: STAFF_EDIT_PROFESSIONAL_DESCRIPTION_DOCTOR_PROFILE,
  })
  const isEditMedicalRecordDDoctorProfile = checkStaffPermission({
    functionName: STAFF_EDIT_MEDICAL_RECORD_DOCTOR_PROFILE,
  })
  const isEditSkillInformationDoctorProfile = checkStaffPermission({
    functionName: STAFF_EDIT_SKILL_INFORMATION_DOCTOR_PROFILE,
  })
  const isEditObtainDoctorLicenseYearDoctorProfile = checkStaffPermission({
    functionName: STAFF_EDIT_OBTAIN_DOCTOR_LICENSE_YEAR_DOCTOR_PROFILE,
  })
  const isEditCareerHistoryDoctorProfile = checkStaffPermission({
    functionName: STAFF_EDIT_CAREER_HISTORY_DOCTOR_PROFILE,
  })

  const isCreateDoctorDeleteRequestPermission = checkStaffPermission({
    functionName: STAFF_CREATE_DOCTOR_DELETE_REQUEST_PERMISSION,
  })

  const isViewDoctorMedicalLicenseNumber = checkStaffPermission({
    functionName: VIEW_DOCTOR_LICENSE_NUMBER,
  })

  const isEditDoctorMedicalLicenseNumber = checkStaffPermission({
    functionName: EDIT_DOCTOR_LICENSE_NUMBER,
  })

  const isViewMedicalRecordDDoctorProfile = checkStaffPermission({
    functionName: VIEW_MEDICAL_RECORD_DOCTOR_PROFILE,
  })
  const isUpsertDoctorFile = checkStaffPermission({
    functionName: STAFF_UPSERT_DOCTOR_FILE_2,
  })
  const isCanBlockDoctorMail = checkStaffPermission({
    functionName: STAFF_BLOCK_DOCTOR_MAIL,
  })

  const onTabChange = (e, isEditable, fileCategory, doctorFile) => {
    const idFileOther =
      doctorFile && fileCategory === 'OTHER' ? doctorFile.id : undefined
    history.push({
      pathname,
      search: queryString.stringify({
        ...restSearch,
        activeIndex: e.index,
        editable: isEditable,
        uploadFileCategory: fileCategory,
        fileId: idFileOther,
      }),
    })
  }
  const createNewOther = () => {
    setNewOther(true)
  }
  const downloadFileSubmit = async ({ key }, { setSubmitting, resetForm }) => {
    try {
      const {
        data: { downloadFileWithCheckFileType },
      } = await downloadFile({
        variables: {
          key,
        },
      })
      window.location.href = downloadFileWithCheckFileType
      setSubmitting(false)
      resetForm()
    } catch (error) {
      popup.error(i18n.t('main.downloadFileError'))
      setSubmitting(false)
    }
  }

  const previewImage = async imgKey => {
    try {
      const {
        data: { downloadFileWithCheckFileType },
      } = await downloadFile({
        variables: {
          key: imgKey,
        },
      })
      if (!downloadFileWithCheckFileType) {
        popup.error('画像の拡張子がpng・ jpg・jpegでアップロードしてください')
        return
      }
      if (checkIsPdf(downloadFileWithCheckFileType.toString())) {
        window.open(`${downloadFileWithCheckFileType.toString()}`, '_blank')
      } else {
        setImg(img => ({ fileURL: downloadFileWithCheckFileType, imgKey }))
      }
    } catch (error) {
      popup.error(i18n.t('main.downloadFileError'))
    }
  }

  const uploadFileSubmit = async (
    { file, filename, mimetype },
    { setSubmitting, resetForm }
  ) => {
    try {
      await uploadFile({
        variables: {
          doctorId,
          fileCategory: uploadFileCategory,
          file,
          fileId,
          filename,
          mimetype,
        },
      })
      popup.success(i18n.t('doctor.uploadFile.submissionSuccess'))
      setSubmitting(false)
      resetForm()
      onTabChange({ index: 2 })
    } catch (error) {
      popup.error(i18n.t('doctor.uploadFile.submissionError'))
      setSubmitting(false)
    }
  }

  const updateDoctorSubmit = async (
    values,
    { setSubmitting, setFieldValue, resetForm }
  ) => {
    let {
      lastname,
      lastnameKana,
      firstname,
      firstnameKana,
      birthday,
      gender,
      medicalLicenseNumber,
      homeAddress: {
        stateOrRegion,
        address1,
        address2,
        zipCodeFirst,
        zipCodeSecond,
      },
      nearestStations,
      placeOfWork,
      evaluationComment,
      workPattern,
      adjustHourlyWageRange,
      joinBackground,
      joinBackgroundOther,
      departments,
      phoneNumberFirst,
      phoneNumberSecond,
      phoneNumberThird,
      email,
      graduationUniversity,
      graduationUniversityYear,
      obtainDoctorLicenseYear,
      professionalDescription,
      reasonKnowWebsites,
      otherReasonKnowWebsite,
      isJoinedOnlineDiagnosticTraining,
      isJoinedSublingualImmunotherapyELearning,
      isIntroduce,
      introducedBy,
      careerHistories,
      medicalRecord,
      orca,
      questionnaire,
      legalHolidays,
      isAvailableOnMonday = false,
      isAvailableOnTuesday = false,
      isAvailableOnWednesday = false,
      isAvailableOnThursday = false,
      isAvailableOnFriday = false,
      isAvailableOnSaturday = false,
      isAvailableOnSunday = false,
      isAvailableOnHoliday = false,
      skillSet,
      functionName,
      staffMemo,
      province,
      isCreateTransportationCost,
      isUpdateAddress,
      isBlockedMail,
      insuranceDoctorNumber,
      insuranceDoctorSymbol,
      insuranceDoctorType,
    } = values
    try {
      let successMessage = '成功しました'
      if (functionName === 'createDoctorDeleteRequest')
        successMessage = '削除申請しました'

      if (functionName === 'blockMail') {
        await blockOrUnblockDoctorMail({
          variables: {
            doctorId,
            isBlockedMail: !isBlockedMail,
          },
        })
      }
      if ([PERMANENT_DOCTOR, REGULAR_SHIFT_DOCTOR].includes(workPattern)) {
        isAvailableOnMonday = !!legalHolidays.includes('MON')
        isAvailableOnTuesday = !!legalHolidays.includes('TUE')
        isAvailableOnWednesday = !!legalHolidays.includes('WED')
        isAvailableOnThursday = !!legalHolidays.includes('THURS')
        isAvailableOnFriday = !!legalHolidays.includes('FRI')
        isAvailableOnSaturday = !!legalHolidays.includes('SAT')
        isAvailableOnSunday = !!legalHolidays.includes('SUN')
        isAvailableOnHoliday = !!legalHolidays.includes('HOLI')
      }
      if (Array.isArray(isJoinedOnlineDiagnosticTraining)) {
        // eslint-disable-next-line prefer-destructuring
        isJoinedOnlineDiagnosticTraining = isJoinedOnlineDiagnosticTraining[0]
      }
      if (Array.isArray(isJoinedSublingualImmunotherapyELearning)) {
        // eslint-disable-next-line prefer-destructuring
        isJoinedSublingualImmunotherapyELearning =
          isJoinedSublingualImmunotherapyELearning[0]
      }
      isJoinedOnlineDiagnosticTraining =
        isJoinedOnlineDiagnosticTraining === 'true'
      isJoinedSublingualImmunotherapyELearning =
        isJoinedSublingualImmunotherapyELearning === 'true'

      if (Array.isArray(isIntroduce)) {
        // eslint-disable-next-line prefer-destructuring
        isIntroduce = isIntroduce[0]
      }
      if (isIntroduce === '0') {
        introducedBy = ''
      }
      if (!reasonKnowWebsites.includes('OTHER')) {
        otherReasonKnowWebsite = ''
      }
      // careerHistory
      for (let i = 0; i < careerHistories.length; i += 1) {
        delete careerHistories[i].__typename
        delete careerHistories[i].id
      }
      if (functionName === 'createDoctorDeleteRequest') {
        await createDoctorDeleteRequest({
          variables: {
            doctorId,
            staffId: staffMeData.staffMe.id,
          },
        })
        await staffCreateActivity({
          variables: {
            activity: 'STAFF_REQUEST_DELETE_DOCTOR',
            doctorId,
          },
        })
      }
      if (functionName === 'updateDoctor') {
        await updateDoctor({
          variables: {
            doctorId,
            lastname,
            lastnameKana,
            firstname,
            firstnameKana,
            birthday,
            gender,
            medicalLicenseNumber,
            stateOrRegion,
            address1,
            address2,
            zipCode: `${zipCodeFirst}${zipCodeSecond}`,
            nearestStations,
            placeOfWork,
            evaluationComment,
            workPattern,
            adjustHourlyWageRange,
            joinBackground,
            joinBackgroundOther,
            departments,
            phoneNumber: `${phoneNumberFirst}${phoneNumberSecond}${phoneNumberThird}`,
            email,
            graduationUniversity,
            graduationUniversityYear,
            obtainDoctorLicenseYear,
            professionalDescription,
            reasonKnowWebsites,
            otherReasonKnowWebsite,
            isJoinedOnlineDiagnosticTraining,
            isJoinedSublingualImmunotherapyELearning,
            introducedBy,
            careerHistories,
            medicalRecord,
            orca,
            questionnaire,
            isAvailableOnMonday,
            isAvailableOnTuesday,
            isAvailableOnWednesday,
            isAvailableOnThursday,
            isAvailableOnFriday,
            isAvailableOnSaturday,
            isAvailableOnSunday,
            isAvailableOnHoliday,
            skillSet,
            staffMemo,
            province,
            isCreateTransportationCost,
            isUpdateAddress,
            insuranceDoctorNumber,
            insuranceDoctorSymbol,
            insuranceDoctorType,
          },
        })
      }
      popup.success(successMessage)
      setSubmitting(false)
      resetForm()
      onTabChange({ index: 0 })
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
      setFieldValue('confirm', false)
    }
  }

  const updateBankAccountSubmit = async (
    values,
    { setSubmitting, setFieldValue, resetForm }
  ) => {
    const { doctor } = data
    const doctorAddress = doctor?.homeAddress || {}
    const {
      bankName,
      bankCode,
      branchName,
      branchCode,
      accountType,
      accountNumber,
      accountHolderName,
    } = values
    try {
      await updateDoctor({
        variables: {
          doctorId,
          email: data.doctor.email,
          bankName,
          bankCode,
          branchName,
          branchCode,
          accountType,
          accountNumber,
          accountHolderName,
          ...doctorAddress,
        },
      })
      popup.success(i18n.t('staff.doctorDetail.submissionSuccess'))
      setSubmitting(false)
      resetForm()
      onTabChange({ index: 0 })
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
      setFieldValue('confirm', false)
    }
  }

  const handleDoctorPending = async (
    e,
    { setSubmitting, setFieldValue, resetForm }
  ) => {
    let {
      confirm,
      doctorStatus,
      mailContent,
      isCreateTransportationCost,
      isUpdateAddress,
    } = e
    const registrationStatus = doctorStatus.toString()
    mailContent = `${mailContent}
    ${LinkMail()}
    `
    if (confirm === 'OnlyAccept') mailContent = null
    try {
      await processPendingDoctor({
        variables: {
          doctorId,
          registrationStatus,
          mailContent,
          isCreateTransportationCost,
          isCreateAddressHistory: isUpdateAddress,
        },
      })
      popup.success(i18n.t('staff.doctorDetail.submissionSuccess'))
      setSubmitting(false)
      resetForm()
      onTabChange({ index: 0 })
    } catch (err) {
      popup.error(err)
      setSubmitting(false)
      setFieldValue('confirm', false)
    }
  }

  const onSubmit = async ({ email }, { resetForm, setSubmitting }) => {
    try {
      await resendConfirmationEmail({
        variables: {
          email,
        },
      })
      popup.success(i18n.t('resendConfirmationEmail.submissionSuccess'))
      resetForm()
      setSubmitting(false)
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }

  const deleteFileSubmit = async ({ fileId }, { resetForm, setSubmitting }) => {
    try {
      await deleteFile({
        variables: {
          fileId,
        },
      })
      popup.success(i18n.t('staff.doctorDetail.deleteSuccess'))
      resetForm()
      setSubmitting(false)
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }

  let {
    doctor,
    genders,
    departments,
    workPatterns,
    joinBackgrounds,
    fileCategories,
    insuranceDoctorTypes,
  } = data
  const {
    homeAddress: doctorAddress,
    bankAccount: doctorBankAccount,
    files: doctorFiles,
  } = doctor || {}

  if (!doctor) {
    return <NotFound />
  }
  const currentPage = pathname.split('/')[2]
  if (currentPage === 'doctors_pending') {
    fileCategories = fileCategories.filter(
      fileCategory => fileCategory.value === 'MEDICAL_DOCTOR_REGISTRATION'
    )
  }

  const DoctorStatus = DoctorStatusTemplate
  const otherFileAll = doctorFiles.filter(item => item.fileCategory === 'OTHER')
  return (
    <div>
      <TabView
        activeIndex={parseInt(activeIndex, 10)}
        onTabChange={onTabChange}
      >
        <TabPanel header={i18n.t('staff.myPage.profile')}>
          <div className='modal-wrapper'>
            <DoctorProfileForm
              isStaff
              isUpdate
              isCreateDoctorDeleteRequestPermission={
                isCreateDoctorDeleteRequestPermission
              }
              i18n={i18n}
              genders={genders}
              insuranceDoctorTypes={insuranceDoctorTypes}
              departments={departments}
              doctor={doctor || {}}
              doctorAddress={doctorAddress || {}}
              onSubmit={updateDoctorSubmit}
              disabled={isFromDeleteRequestList || !editable}
              setDisabled={() => onTabChange({ index: 0 })}
              isEditAddressDoctorProfile={isEditAddressDoctorProfile}
              isEditGraduationUniversityDoctorProfile={
                isEditGraduationUniversityDoctorProfile
              }
              isEditAdjustHourlyWageRangeDoctorProfile={
                isEditAdjustHourlyWageRangeDoctorProfile
              }
              isEditIntroducedByDoctorProfile={isEditIntroducedByDoctorProfile}
              isEditJoinBackgroundDoctorProfile={
                isEditJoinBackgroundDoctorProfile
              }
              isEditProfessionalDescriptionDoctorProfile={
                isEditProfessionalDescriptionDoctorProfile
              }
              isEditMedicalRecordDDoctorProfile={
                isEditMedicalRecordDDoctorProfile
              }
              isEditSkillInformationDoctorProfile={
                isEditSkillInformationDoctorProfile
              }
              isEditObtainDoctorLicenseYearDoctorProfile={
                isEditObtainDoctorLicenseYearDoctorProfile
              }
              isEditCareerHistoryDoctorProfile={
                isEditCareerHistoryDoctorProfile
              }
              isEditStaffMemoDoctorProfile={isEditStaffMemoDoctorProfile}
              isPageStaffMemoDoctorProfile={isPageStaffMemoDoctorProfile}
              confirmationMessage={i18n.t(
                'staff.doctorDetail.editProfileConfirmation'
              )}
              workPatterns={workPatterns}
              joinBackgrounds={joinBackgrounds}
              buttonLabel={i18n.t('staff.doctorDetail.submit')}
              dataSkill={doctor.skillSet}
              isViewDoctorMedicalLicenseNumber={
                isViewDoctorMedicalLicenseNumber
              }
              isEditDoctorMedicalLicenseNumber={
                isEditDoctorMedicalLicenseNumber
              }
              isViewMedicalRecordDDoctorProfile={
                isViewMedicalRecordDDoctorProfile
              }
              isCanBlockDoctorMail={isCanBlockDoctorMail}
            />
            {!editable && (
              <div className='modal-bottom'>
                <Button
                  onClick={() => onTabChange({ index: 0 }, false)}
                  label={i18n.t('staff.doctorDetail.editProfile')}
                />
              </div>
            )}
          </div>
        </TabPanel>
        {currentPage === 'doctors' || isFromDeleteRequestList
          ? [
              <TabPanel header={i18n.t('doctor.myPage.bankAccount')}>
                <div className='modal-wrapper'>
                  <BankAccountForm
                    i18n={i18n}
                    bankAccount={doctorBankAccount || {}}
                    onSubmit={updateBankAccountSubmit}
                    confirmationMessage={i18n.t(
                      'staff.doctorDetail.editBankAccountConfirmation'
                    )}
                    disabled={isFromDeleteRequestList || !editable}
                  />
                </div>
              </TabPanel>,
              <TabPanel header={i18n.t('doctor.myPage.files')}>
                <div className='modal-wrapper'>
                  <Formik
                    initialValues={{
                      confirm: false,
                      key: '',
                    }}
                    onSubmit={downloadFileSubmit}
                    render={formikProps => (
                      <React.Fragment>
                        <Dialog
                          style={{ width: 'auto' }}
                          visible={img.fileURL}
                          className='p-dialog'
                          footer={
                            <>
                              <Button
                                type='submit'
                                label='ダウンロード'
                                onClick={() => {
                                  formikProps.setFieldValue('key', img.imgKey)
                                  formikProps.setFieldValue('confirm', true)
                                }}
                                icon='pi pi-cloud-download'
                                className='p-button-secondary'
                                style={{ textAlign: 'center' }}
                              />
                            </>
                          }
                          closeOnEscape
                          onHide={() => setImg({ fileURL: '', imgKey: '' })}
                        >
                          <Image width={300} height={300} src={img.fileURL} />
                        </Dialog>
                        {fileCategories.map(({ value, description }) => {
                          const doctorFile = doctorFiles.find(
                            ({ fileCategory }) => value === fileCategory
                          )
                          if (value === 'OTHER') {
                            return null
                          }
                          return (
                            <div className='tr' key={value}>
                              {value === 'DOCTOR_LICENSE_NEW' ? (
                                <div
                                  className='th-image'
                                  style={{ whiteSpace: 'normal' }}
                                >
                                  <p>医師免許証</p>
                                  <p>(氏名等情報に変更があった場合)</p>
                                </div>
                              ) : (
                                <div className='th-image'>{description}</div>
                              )}
                              <div className='td-image'>
                                {doctorFile ? (
                                  <Button
                                    type='submit'
                                    label={i18n.t('main.downloadFile')}
                                    onClick={() => {
                                      previewImage(doctorFile.key)
                                    }}
                                    icon='pi pi-cloud-download'
                                    className='p-button-secondary'
                                  />
                                ) : (
                                  <Button
                                    icon='pi pi-cloud-download'
                                    label={i18n.t('main.noFile')}
                                    disabled
                                  />
                                )}
                                <Button
                                  icon='pi pi-cloud-upload'
                                  label={i18n.t('doctor.uploadFile.upload')}
                                  onClick={() =>
                                    onTabChange(
                                      { index: 3 },
                                      undefined,
                                      value,
                                      doctorFile
                                    )
                                  }
                                  style={{ marginLeft: '20px' }}
                                  disabled={
                                    isFromDeleteRequestList ||
                                    !editable ||
                                    !isUpsertDoctorFile
                                  }
                                />
                                {doctorFile && (
                                  <Formik
                                    initialValues={{
                                      fileId: undefined,
                                      confirm: false,
                                    }}
                                    onSubmit={deleteFileSubmit}
                                    render={formikProps => {
                                      const { setFieldValue } = formikProps
                                      return (
                                        <>
                                          <Button
                                            type='submit'
                                            onClick={() => {
                                              setFieldValue(
                                                'fileId',
                                                doctorFile.id
                                              )
                                              setFieldValue('confirm', true)
                                            }}
                                            icon='pi pi-trash'
                                            className='p-button-secondary'
                                            style={{
                                              textAlign: 'center',
                                              marginLeft: '20px',
                                            }}
                                          />
                                          <ConfirmationModal
                                            {...formikProps}
                                            confirmationMessage={i18n.t(
                                              'staff.doctorDetail.confirmDeleteFile'
                                            )}
                                          />
                                        </>
                                      )
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          )
                        })}
                        {otherFileAll && otherFileAll.length === 0 ? (
                          <div className='tr' key='NEW_OTHER'>
                            <div className='th-image'>その他</div>
                            <div className='td-image'>
                              <Button
                                icon='pi pi-cloud-download'
                                label={i18n.t('main.noFile')}
                                disabled
                              />
                              <Button
                                icon='pi pi-cloud-upload'
                                label={i18n.t('doctor.uploadFile.upload')}
                                onClick={() =>
                                  onTabChange({ index: 3 }, undefined, 'OTHER')
                                }
                                style={{ marginLeft: '20px' }}
                                disabled={!isUpsertDoctorFile}
                              />
                            </div>
                          </div>
                        ) : null}
                        {otherFileAll && otherFileAll.length > 0
                          ? otherFileAll.map((otherFile, index) => (
                              <div
                                className='tr'
                                key={otherFileAll.fileCategory}
                              >
                                <div className='th-image'>
                                  {index === 0 ? 'その他' : null}
                                </div>
                                <div className='td-image'>
                                  <Button
                                    type='submit'
                                    label={i18n.t('main.downloadFile')}
                                    onClick={() => {
                                      previewImage(otherFile.key)
                                    }}
                                    icon='pi pi-cloud-download'
                                    className='p-button-secondary'
                                  />
                                  <Button
                                    icon='pi pi-cloud-upload'
                                    label={i18n.t('doctor.uploadFile.upload')}
                                    onClick={() =>
                                      onTabChange(
                                        { index: 3 },
                                        undefined,
                                        'OTHER',
                                        otherFile
                                      )
                                    }
                                    disabled={!isUpsertDoctorFile}
                                    style={{ marginLeft: '20px' }}
                                  />
                                  <Formik
                                    initialValues={{
                                      fileId: undefined,
                                      confirm: false,
                                    }}
                                    onSubmit={deleteFileSubmit}
                                    render={formikProps => {
                                      const { setFieldValue } = formikProps
                                      return (
                                        <>
                                          <Button
                                            type='submit'
                                            onClick={() => {
                                              setFieldValue(
                                                'fileId',
                                                otherFile.id
                                              )
                                              setFieldValue('confirm', true)
                                            }}
                                            icon='pi pi-trash'
                                            className='p-button-secondary'
                                            style={{
                                              textAlign: 'center',
                                              marginLeft: '20px',
                                            }}
                                          />
                                          <ConfirmationModal
                                            {...formikProps}
                                            confirmationMessage={i18n.t(
                                              'staff.doctorDetail.confirmDeleteFile'
                                            )}
                                          />
                                        </>
                                      )
                                    }}
                                  />
                                </div>
                              </div>
                            ))
                          : null}
                        {newOther ? (
                          <div className='tr' key='NEW_OTHER'>
                            <div className='th-image' />
                            <div className='td-image'>
                              <Button
                                icon='pi pi-cloud-download'
                                label={i18n.t('main.noFile')}
                                disabled
                              />
                              <Button
                                icon='pi pi-cloud-upload'
                                label={i18n.t('doctor.uploadFile.upload')}
                                onClick={() =>
                                  onTabChange({ index: 3 }, undefined, 'OTHER')
                                }
                                disabled={!isUpsertDoctorFile}
                                style={{ marginLeft: '20px' }}
                              />
                            </div>
                          </div>
                        ) : null}
                        {otherFileAll && otherFileAll.length > 0 ? (
                          <Button
                            style={{
                              color: '#FF0E6B',
                              marginLeft: '45%',
                              borderColor: '#FF0E6B',
                            }}
                            onClick={createNewOther}
                            label='＋追加'
                          />
                        ) : null}
                        <ConfirmationModal
                          {...formikProps}
                          confirmationMessage={i18n.t(
                            'main.downloadConfirmation'
                          )}
                        />
                      </React.Fragment>
                    )}
                  />
                </div>
              </TabPanel>,
              <TabPanel header={i18n.t('doctor.uploadFile.upload')} disabled>
                <div className='modal-wrapper'>
                  <React.Fragment>
                    {uploadFileCategory ? (
                      <React.Fragment>
                        <div style={{ padding: '10px 0', fontSize: '18px' }}>
                          {
                            fileCategories.find(
                              ({ value }) => value === uploadFileCategory
                            ).description
                          }
                        </div>
                        <UploadFileComponent
                          submitLabel={i18n.t('doctor.uploadFile.upload')}
                          uploadFile={uploadFileSubmit}
                          hideTitle
                          confirmationMessage={i18n.t(
                            'doctor.uploadFile.submitConfirmation'
                          )}
                        />
                      </React.Fragment>
                    ) : (
                      <div>
                        {i18n.t('staff.doctorDetail.chooseFileCategory')}
                      </div>
                    )}
                  </React.Fragment>
                </div>
              </TabPanel>,
              <TabPanel
                header={i18n.t('resendConfirmationEmail.title')}
                disabled={isFromDeleteRequestList || !editable}
              >
                <div className='modal-wrapper'>
                  <div>{i18n.t('resendConfirmationEmail.description1')}</div>
                  <div>{i18n.t('resendConfirmationEmail.description2')}</div>
                  <DoctorResendConfirmationEmailForm
                    i18n={i18n}
                    doctor={doctor || {}}
                    onSubmit={onSubmit}
                    disabled={isFromDeleteRequestList || !editable}
                  />
                </div>
              </TabPanel>,
              <TabPanel
                header={i18n.t('staff.workTendency.title')}
                disabled={isFromDeleteRequestList || !editable}
              >
                <div className='modal-wrapper'>
                  <WorkTendencyForm
                    i18n={i18n}
                    doctor={doctor || {}}
                    onSubmit={onSubmit}
                  />
                </div>
              </TabPanel>,
            ]
          : [
              <TabPanel header={i18n.t('doctor.myPage.files')}>
                <div className='modal-wrapper'>
                  <Formik
                    initialValues={{
                      confirm: false,
                      key: '',
                    }}
                    onSubmit={downloadFileSubmit}
                    render={formikProps => (
                      <React.Fragment>
                        <PreviewImgDialog
                          i18n={i18n}
                          visible={img.fileURL}
                          dataImage={img.fileURL}
                          footerLabel='ダウンロード'
                          handleClose={() =>
                            setImg({ fileURL: '', imgKey: '' })
                          }
                          handleDownloadImg={() => {
                            formikProps.setFieldValue('key', img.imgKey)
                            formikProps.setFieldValue('confirm', true)
                          }}
                        />
                        {fileCategories.map(({ value, description }) => {
                          const doctorFile = doctorFiles.find(
                            ({ fileCategory }) => value === fileCategory
                          )
                          if (value === 'OTHER') {
                            return null
                          }
                          return (
                            <div className='tr' key={value}>
                              {value === 'DOCTOR_LICENSE_NEW' ? (
                                <div className='th-image'>
                                  <p>医師免許証</p>
                                  <p>(氏名等情報に変更があった場合)</p>
                                </div>
                              ) : (
                                <div className='th-image'>{description}</div>
                              )}
                              <div className='td-image'>
                                {doctorFile ? (
                                  <Button
                                    type='submit'
                                    label={i18n.t('main.downloadFile')}
                                    onClick={() => {
                                      previewImage(doctorFile.key)
                                    }}
                                    icon='pi pi-cloud-download'
                                    className='p-button-secondary'
                                  />
                                ) : (
                                  <Button
                                    icon='pi pi-cloud-download'
                                    label={i18n.t('main.noFile')}
                                    disabled
                                  />
                                )}
                                <Button
                                  icon='pi pi-cloud-upload'
                                  label={i18n.t('doctor.uploadFile.upload')}
                                  onClick={() =>
                                    onTabChange({ index: 2 }, undefined, value)
                                  }
                                  disabled={!isUpsertDoctorFile}
                                  style={{ marginLeft: '20px' }}
                                />
                              </div>
                            </div>
                          )
                        })}
                        {otherFileAll && otherFileAll.length > 0
                          ? otherFileAll.map((otherFile, index) => (
                              <div
                                className='tr'
                                key={otherFileAll.fileCategory}
                              >
                                <div className='th-image'>
                                  {index === 0 ? 'その他' : null}
                                </div>
                                <div className='td-image'>
                                  <Button
                                    type='submit'
                                    label={i18n.t('main.downloadFile')}
                                    onClick={() => {
                                      formikProps.setFieldValue(
                                        'key',
                                        otherFile.key
                                      )
                                      formikProps.setFieldValue('confirm', true)
                                    }}
                                    icon='pi pi-cloud-download'
                                    className='p-button-secondary'
                                  />
                                  <Button
                                    icon='pi pi-cloud-upload'
                                    label={i18n.t('doctor.uploadFile.upload')}
                                    onClick={() =>
                                      onTabChange(
                                        { index: 3 },
                                        undefined,
                                        'OTHER',
                                        otherFile
                                      )
                                    }
                                    disabled={!isUpsertDoctorFile}
                                    style={{ marginLeft: '20px' }}
                                  />
                                </div>
                              </div>
                            ))
                          : null}
                        {newOther ? (
                          <div className='tr' key='NEW_OTHER'>
                            <div className='th-image' />
                            <div className='td-image'>
                              <Button
                                icon='pi pi-cloud-download'
                                label={i18n.t('main.noFile')}
                                disabled
                              />
                              <Button
                                icon='pi pi-cloud-upload'
                                label={i18n.t('doctor.uploadFile.upload')}
                                onClick={() =>
                                  onTabChange({ index: 3 }, undefined, 'OTHER')
                                }
                                disabled={!isUpsertDoctorFile}
                                style={{ marginLeft: '20px' }}
                              />
                            </div>
                          </div>
                        ) : null}
                        {otherFileAll && otherFileAll.length > 0 ? (
                          <Button
                            style={{
                              color: '#FF0E6B',
                              marginLeft: '45%',
                              borderColor: '#FF0E6B',
                            }}
                            onClick={createNewOther}
                            label='＋追加'
                          />
                        ) : null}
                        <ConfirmationModal
                          {...formikProps}
                          confirmationMessage={i18n.t(
                            'main.downloadConfirmation'
                          )}
                        />
                      </React.Fragment>
                    )}
                  />
                </div>
              </TabPanel>,
              <TabPanel header={i18n.t('doctor.uploadFile.upload')} disabled>
                <div className='modal-wrapper'>
                  <React.Fragment>
                    {uploadFileCategory ? (
                      <React.Fragment>
                        <div style={{ padding: '10px 0', fontSize: '18px' }}>
                          {
                            fileCategories.find(
                              ({ value }) => value === uploadFileCategory
                            ).description
                          }
                        </div>
                        <UploadFileComponent
                          submitLabel={i18n.t('doctor.uploadFile.upload')}
                          uploadFile={uploadFileSubmit}
                          hideTitle
                          confirmationMessage={i18n.t(
                            'doctor.uploadFile.submitConfirmation'
                          )}
                        />
                      </React.Fragment>
                    ) : (
                      <div>
                        {i18n.t('staff.doctorDetail.chooseFileCategory')}
                      </div>
                    )}
                  </React.Fragment>
                </div>
              </TabPanel>,
              <TabPanel header={i18n.t('doctor.myPage.statusHeader')}>
                <div className='modal-wrapper'>
                  <DoctorStatusForm
                    isStaff
                    i18n={i18n}
                    genders={genders}
                    doctorStatus={DoctorStatus}
                    doctor={doctor || {}}
                    doctorAddress={doctorAddress || {}}
                    onSubmit={handleDoctorPending}
                    disabled={false}
                    setDisabled={() => onTabChange({ index: 0 })}
                    confirmationMessage={i18n.t(
                      'staff.doctorDetail.confirmAcceptDoctor'
                    )}
                    workPatterns={workPatterns}
                    buttonLabel={i18n.t('staff.doctorDetail.submit')}
                  />
                </div>
              </TabPanel>,
              <TabPanel header={'連絡可能日'}>
                <div className='modal-wrapper'>
                  <DoctorInterviewSchedule
                    interviewTime={data.doctor?.interviewTime}
                  />
                </div>
              </TabPanel>,
            ]}
      </TabView>
    </div>
  )
}

export default DoctorDetail
