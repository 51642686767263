import BreadCrumb from '@medical/components/Breadcrumb'
import { FieldArray, Formik } from 'formik'
import Papa from 'papaparse'
import { Button } from 'primereact/button'
import React from 'react'
import Dropzone from 'react-dropzone'
import * as Yup from 'yup'

const IncentiveCsvScene = ({
  popup,
  i18n,
  onSubmit,
  downloadSample,
  isUploaded,
  checkCsvData,
  isUploadPermitted,
  isUploadCheckPermitted,
}) => {
  const translateKeys = {
    氏名: i18n.t('main.incentiveCsv.name'),
    DS登録メールアドレス: i18n.t('main.incentiveCsv.email'),
    診療科: i18n.t('main.incentiveCsv.department'),
    拠点: i18n.t('main.incentiveCsv.clinic'),
    日付: i18n.t('main.incentiveCsv.date'),
    非常に満足したの件数: i18n.t('main.incentiveCsv.field1'),
    まあまあ満足したの件数: i18n.t('main.incentiveCsv.field2'),
    普通の件数: i18n.t('main.incentiveCsv.field3'),
    満足できなかったの件数: i18n.t('main.incentiveCsv.field4'),
    非常に満足の割合: i18n.t('main.incentiveCsv.field5'),
    前項目の全体平均: i18n.t('main.incentiveCsv.field6'),
    総診察件数: i18n.t('main.incentiveCsv.field7'),
    総診察時間: i18n.t('main.incentiveCsv.field8'),
    診察のみの診察件数: i18n.t('main.incentiveCsv.field9'),
    診察のみの診察時間: i18n.t('main.incentiveCsv.field10'),
    一件当たりのすべての診療時間の平均: i18n.t('main.incentiveCsv.field11'),
    一件当たりのすべての診療時間のその日の全体平均: i18n.t(
      'main.incentiveCsv.field12'
    ),
    一件当たりの診察のみの診療時間の平均: i18n.t('main.incentiveCsv.field13'),
    一件当たりの診察のみの診療時間のその日の全体平均: i18n.t(
      'main.incentiveCsv.field14'
    ),
  }
  const validationSchema = Yup.object().shape({
    data: Yup.array()
      .min(1, 'レコードは1以上設定してください')
      .of(
        Yup.object().shape({
          DS登録メールアドレス: Yup.string()
            .email(i18n.t('validation.email.email'))
            .required(i18n.t('validation.email.required')),
          氏名: Yup.string().required(i18n.t('validation.name.required')),
          拠点: Yup.string().required(i18n.t('validation.clinic.required')),
          診療科: Yup.string().required(
            i18n.t('validation.department.required')
          ),
          日付: Yup.string().required(
            `${i18n.t('main.date')} ${i18n.t('validation.required')}`
          ),
          非常に満足したの件数: Yup.number()
            .min(0, `${i18n.t('main.incentiveCsv.field1Min')}`)
            .typeError(i18n.t('main.incentiveCsv.field1Required'))
            .required(i18n.t('main.incentiveCsv.field1Required')),
          まあまあ満足したの件数: Yup.number()
            .min(0, `${i18n.t('main.incentiveCsv.field2Min')}`)
            .typeError(i18n.t('main.incentiveCsv.field2Required'))
            .required(i18n.t('main.incentiveCsv.field2Required')),
          普通の件数: Yup.number()
            .min(0, `${i18n.t('main.incentiveCsv.field3Min')}`)
            .typeError(i18n.t('main.incentiveCsv.field3Required'))
            .required(i18n.t('main.incentiveCsv.field3Required')),
          満足できなかったの件数: Yup.number()
            .min(0, `${i18n.t('main.incentiveCsv.field4Min')}`)
            .typeError(i18n.t('main.incentiveCsv.field4Required'))
            .required(i18n.t('main.incentiveCsv.field4Required')),
          非常に満足の割合: Yup.number()
            .min(0, `${i18n.t('main.incentiveCsv.field5Min')}`)
            .max(100, `${i18n.t('main.incentiveCsv.field5Max')}`)
            .typeError(i18n.t('main.incentiveCsv.field5Required'))
            .required(i18n.t('main.incentiveCsv.field5Required')),
          前項目の全体平均: Yup.number()
            .min(0, `${i18n.t('main.incentiveCsv.field6Min')}`)
            .max(100, `${i18n.t('main.incentiveCsv.field6Max')}`)
            .typeError(i18n.t('main.incentiveCsv.field6Required'))
            .required(i18n.t('main.incentiveCsv.field6Required')),
          総診察件数: Yup.number()
            .min(0, `${i18n.t('main.incentiveCsv.field7Min')}`)
            .typeError(i18n.t('main.incentiveCsv.field7Required'))
            .required(i18n.t('main.incentiveCsv.field7Required')),
          総診察時間: Yup.number()
            .min(0, `${i18n.t('main.incentiveCsv.field8Min')}`)
            .typeError(i18n.t('main.incentiveCsv.field8Required'))
            .required(i18n.t('main.incentiveCsv.field8Required')),
          診察のみの診察件数: Yup.number()
            .min(0, `${i18n.t('main.incentiveCsv.field9Min')}`)
            .typeError(i18n.t('main.incentiveCsv.field9Required'))
            .required(i18n.t('main.incentiveCsv.field9Required')),
          診察のみの診察時間: Yup.number()
            .min(0, `${i18n.t('main.incentiveCsv.field10Min')}`)
            .typeError(i18n.t('main.incentiveCsv.field10Required'))
            .required(i18n.t('main.incentiveCsv.field10Required')),
          一件当たりのすべての診療時間の平均: Yup.number()
            .min(0, `${i18n.t('main.incentiveCsv.field11Min')}`)
            .typeError(i18n.t('main.incentiveCsv.field11Required'))
            .required(i18n.t('main.incentiveCsv.field11Required')),
          一件当たりのすべての診療時間のその日の全体平均: Yup.number()
            .min(0, `${i18n.t('main.incentiveCsv.field12Min')}`)
            .typeError(i18n.t('main.incentiveCsv.field12Required'))
            .required(i18n.t('main.incentiveCsv.field12Required')),
          一件当たりの診察のみの診療時間の平均: Yup.number()
            .min(0, `${i18n.t('main.incentiveCsv.field13Min')}`)
            .typeError(i18n.t('main.incentiveCsv.field13Required'))
            .required(i18n.t('main.incentiveCsv.field13Required')),
          一件当たりの診察のみの診療時間のその日の全体平均: Yup.number()
            .min(0, `${i18n.t('main.incentiveCsv.field14Min')}`)
            .typeError(i18n.t('main.incentiveCsv.field14Required'))
            .required(i18n.t('main.incentiveCsv.field14Required')),
        })
      ),
  })

  const exceptKeys = ['checked', 'errorMessage']

  return (
    <Formik
      initialValues={{
        data: [],
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={formikProps => {
        const {
          values,
          errors,
          setFieldValue,
          handleSubmit,
          touched,
        } = formikProps
        const { data } = values
        let keys = []
        if (data.length > 0) {
          keys = Object.keys(data[0]).filter(
            key => key !== 'errorMessage' || key !== 'checked'
          )
        }
        return (
          <React.Fragment>
            <div className='staff-header'>
              <div className='staff-title'>
                {i18n.t('main.incentiveCsv.title')}
                <BreadCrumb
                  items={[
                    {
                      title: i18n.t('main.incentiveCsv.title'),
                    },
                  ]}
                />
              </div>
              <div className='staff-buttons'>
                <Button
                  icon='pi pi-download'
                  className='p-button-normal'
                  onClick={downloadSample}
                  label={i18n.t('main.sampleCsv')}
                />
              </div>
            </div>
            {isUploadPermitted || isUploadCheckPermitted ? (
              <div className='container'>
                <div
                  style={{ justifyContent: 'end', fontSize: '12px' }}
                  className='row'
                >
                  <div>
                    <p className='asterisk-label-head'>
                      同じ医師のデータは1日につき1件までです。（勤務拠点が異なる場合は、同じ医師のデータでもアップロードが可能です。）
                    </p>
                    {/* <p className='asterisk-label-head'>
                      同じ医師の情報は1度に1件しかアップロードできません。
                    </p> */}
                    <p className='asterisk-label-head'>
                      同じ勤務日・勤務拠点の医師の情報を再度アップロードした場合、データが上書きされますのでご注意ください。
                    </p>
                    <p className='asterisk-label-head'>
                      毎月14日23:59までに、先月分のデータをアップロードしていただくようにお願いします。
                    </p>
                  </div>
                </div>
                <div className='box'>
                  <div className='box-top'>
                    <div
                      style={{
                        position: 'relative',
                        display: 'flex',
                        flex: 1,
                        alignItems: 'center',
                      }}
                    >
                      <div className='alert is-left'>
                        {typeof errors.data === 'string' &&
                          touched.data &&
                          errors.data}
                      </div>
                    </div>
                    {values.data.length > 0 && (
                      <Button
                        onClick={() => setFieldValue('data', [])}
                        label={i18n.t('main.cancel')}
                        className='p-button-secondary'
                        icon='pi pi-times'
                        style={{ marginRight: '20px' }}
                      />
                    )}
                    {isUploadCheckPermitted ? (
                      <Button
                        onClick={() => {
                          if (
                            (errors.data && errors.data.length > 0) ||
                            values.data.find(d => d.errorMessage)
                          ) {
                            popup.error(i18n.t('main.hasCsvFileError'))
                          } else {
                            checkCsvData(values, { setFieldValue })
                          }
                        }}
                        label={i18n.t('staff.csvUpload.check')}
                        type='submit'
                        disabled={data.length === 0 || isUploaded}
                        style={{ marginRight: '20px' }}
                      />
                    ) : null}
                    {isUploadPermitted ? (
                      <Button
                        onClick={() => {
                          if (
                            (errors.data && errors.data.length > 0) ||
                            values.data.find(d => d.errorMessage)
                          ) {
                            popup.error(i18n.t('main.hasCsvFileError'))
                          } else {
                            handleSubmit()
                          }
                        }}
                        label={i18n.t('staff.csvUpload.submit')}
                        type='submit'
                        disabled={data.length === 0 || isUploaded}
                      />
                    ) : null}
                  </div>
                  {values.data.length < 1 && (
                    <Dropzone
                      onDrop={files => {
                        if (files.length > 0) {
                          Papa.parse(files[0], {
                            header: true,
                            complete: async results => {
                              setFieldValue('data', results.data)
                            },
                          })
                        }
                      }}
                      accept='.csv'
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: 'dropzone' })}>
                          <input {...getInputProps()} />
                          <p>{i18n.t('doctor.uploadFile.dropFileHere')}</p>
                        </div>
                      )}
                    </Dropzone>
                  )}
                  {data.length > 0 && (
                    <div className='overflow-container'>
                      <table className='table'>
                        <thead>
                          <tr>
                            <th>{i18n.t('main.action')}</th>
                            {keys.map(
                              (key, index) =>
                                !exceptKeys.includes(key) && (
                                  <th key={`${key}_${index}`}>
                                    {translateKeys[key]}
                                  </th>
                                )
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          <FieldArray
                            name='data'
                            render={({ remove }) => (
                              <React.Fragment>
                                {data.map((item, i) => {
                                  const error =
                                    errors.data && errors.data.length > 0
                                      ? errors.data[i]
                                      : false
                                  const isChecked = data[i].checked
                                  const errorMessage =
                                    item.errorMessage ||
                                    Object.keys(error || {}).reduce(
                                      (acc, curr) => `${acc}\n${error[curr]}`,
                                      ''
                                    )
                                  return (
                                    <React.Fragment>
                                      <tr key={`${i}`}>
                                        {!errorMessage ? (
                                          <td>
                                            {isChecked ? (
                                              <Button
                                                icon='pi pi-check'
                                                className='p-button-success'
                                              />
                                            ) : (
                                              <Button
                                                icon='pi pi-info'
                                                tooltip={i18n.t(
                                                  'staff.csvUpload.notChecked'
                                                )}
                                              />
                                            )}
                                          </td>
                                        ) : (
                                          <td>
                                            <div>
                                              <Button
                                                onClick={() => remove(i)}
                                                icon='pi pi-times'
                                                className='p-button-danger'
                                              />
                                            </div>
                                          </td>
                                        )}
                                        {keys.map(
                                          key =>
                                            !exceptKeys.includes(key) && (
                                              <td>{item[key]}</td>
                                            )
                                        )}
                                      </tr>
                                      {errorMessage && (
                                        <tr key={`errorMessage_${i}`}>
                                          <td colSpan={keys.length + 1}>
                                            <div className='alert is-left'>
                                              {errorMessage}
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                    </React.Fragment>
                                  )
                                })}
                              </React.Fragment>
                            )}
                          />
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            ) : null}
          </React.Fragment>
        )
      }}
    />
  )
}

export default IncentiveCsvScene
