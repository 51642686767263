import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import {
  STAFF_CREATE_PORTAL,
  STAFF_UPDATE_PORTAL,
  STAFF_DELETE_PORTAL,
} from '@medical/constant/permissions'
import { checkStaffPermission, onPageChange } from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'

import {
  PORTAL_CONNECTIONS,
  HIDE_PORTAL,
  CATEGORY_QUERY,
} from './PortalList.graphql'
import PortalListScene from './PortalListScene'

const PortalList = ({ history, location: { search } }) => {
  const [{ i18n, popup }] = useCustom()
  const [largeTitle, setLargeTitle] = useState({ value: '', description: '' })
  const [mediumTitle, setMediumTitle] = useState({ value: '', description: '' })
  const {
    page = 1,
    rowsPerPage = 10,
    orderBy = 'largeTitle_ASC',
  } = queryString.parse(search)
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  let variables = {
    where: {
      titleType: 'SMALL_TITLE',
    },
    first: first < 0 ? 10 : first,
    skip: skip < 0 ? 0 : skip,
    orderBy,
  }
  if (largeTitle.value && mediumTitle.value) {
    variables = {
      where: {
        titleType: 'SMALL_TITLE',
        largeTitle: largeTitle.value,
        mediumTitle: mediumTitle.value,
      },
      first: first < 0 ? 10 : first,
      skip: skip < 0 ? 0 : skip,
      orderBy,
    }
  } else if (largeTitle.value)
    variables = {
      where: {
        titleType: 'SMALL_TITLE',
        largeTitle: largeTitle.value,
      },
      first: first < 0 ? 10 : first,
      skip: skip < 0 ? 0 : skip,
      orderBy,
    }
  else if (mediumTitle.value)
    variables = {
      where: {
        titleType: 'SMALL_TITLE',
        mediumTitle: mediumTitle.value,
      },
      first: first < 0 ? 10 : first,
      skip: skip < 0 ? 0 : skip,
      orderBy,
    }
  const mutationOptions = {
    refetchQueries: [
      {
        query: PORTAL_CONNECTIONS,
        variables: variables,
      },
    ],
    awaitRefetchQueries: true,
  }
  const isCreatePermitted = checkStaffPermission({
    functionName: STAFF_CREATE_PORTAL,
  })
  const isDeletePermitted = checkStaffPermission({
    functionName: STAFF_DELETE_PORTAL,
  })
  const isUpdatePermitted = checkStaffPermission({
    functionName: STAFF_UPDATE_PORTAL,
  })
  const { loading, error, data, refetch } = useQuery(PORTAL_CONNECTIONS, {
    variables,
    fetchPolicy: 'network-only',
  })
  const {
    loading: largeCategoryLoading,
    error: largeCategoryError,
    data: largeCategoryData,
    refetch: largeCategoryRefetch,
  } = useQuery(CATEGORY_QUERY, {
    variables: {
      where: {
        titleType: 'LARGE_TITLE',
      },
      orderBy: 'largeTitle_ASC',
    },
  })
  const {
    loading: mediumCategoryLoading,
    error: mediumCategoryError,
    data: mediumCategoryData,
    refetch: mediumCategoryRefetch,
  } = useQuery(CATEGORY_QUERY, {
    variables: {
      where: {
        titleType: 'MEDIUM_TITLE',
      },
      orderBy: 'mediumTitle_ASC',
    },
  })
  const [hidePortal] = useMutation(HIDE_PORTAL, mutationOptions)
  useEffect(() => {
    refetch()
    largeCategoryRefetch()
    mediumCategoryRefetch()
  })
  if (loading || largeCategoryLoading || mediumCategoryLoading)
    return <ProgressSpinner />
  if (error || largeCategoryError || mediumCategoryError)
    return (
      <ErrorComponent
        error={error || largeCategoryError || mediumCategoryError}
      />
    )

  const {
    portalsConnection: {
      edges,
      aggregate: { count },
    },
  } = data

  const onSubmit = async (
    { portalId, isHide },
    { setSubmitting, resetForm }
  ) => {
    try {
      await hidePortal({
        variables: {
          portalId,
          isHide: isHide,
          isDelete: null,
        },
      })
      refetch()
      popup.success('成功しました')
      setSubmitting(false)
      resetForm()
    } catch (error) {
      popup.error('失敗しました')
      setSubmitting(false)
    }
  }
  const sortedList = edges.sort((a, b) => {
    if (a.node.largeTitle === b.node.largeTitle) {
      return a.node.mediumTitle < b.node.mediumTitle ? -1 : 1
    }
    return a.node.largeTitle < b.node.largeTitle ? -1 : 1
  })
  return (
    <PortalListScene
      i18n={i18n}
      edges={sortedList}
      rowsPerPage={rowsPerPage}
      page={page}
      count={count}
      onPageChange={onPageChange(history, search)}
      onSubmit={onSubmit}
      isCreatePermitted={isCreatePermitted}
      isDeletePermitted={isDeletePermitted}
      isUpdatePermitted={isUpdatePermitted}
      largeCategory={largeCategoryData && largeCategoryData.portals}
      mediumCategory={mediumCategoryData && mediumCategoryData.portals}
      largeTitle={largeTitle}
      mediumTitle={mediumTitle}
      removeSearch={() => {
        setLargeTitle({ value: '', description: '' })
        setMediumTitle({ value: '', description: '' })
      }}
      setLargeTitle={e => {
        setLargeTitle({
          value: e.target.value.value,
          description: e.target.value.description,
        })
      }}
      setMediumTitle={e => {
        setMediumTitle({
          value: e.target.value.value,
          description: e.target.value.description,
        })
      }}
    />
  )
}

export default PortalList
