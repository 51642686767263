import gql from 'graphql-tag'

export const DOCTOR_INCENTIVE_REPORT = gql`
  query DoctorIncentiveReports(
    $where: DoctorIncentiveReportWhereInput
    $orderBy: DoctorIncentiveReportOrderByInput
  ) {
    doctorIncentiveReports(where: $where, orderBy: $orderBy) {
      id
      date
      doctorClinic
      doctorDepartment
      field1
      field2
      field3
      field4
      field5
      field6
      field7
      field8
      field9
      field10
      field11
      field12
      field13
      field14
    }
  }
`
