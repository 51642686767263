import './style.css'

import moment from 'moment'
import React from 'react'

const Highlighted = ({ text = '', searchText = '' }) => {
  const splits = text.split(/\r?\n/g)
  if (!searchText.trim()) {
    // const splits = text.split(/\r?\n/g)
    return (
      <div className='text'>
        {splits.map((val, i) => (
          <span key={i}>
            {val}
            {i < splits.length && <br />}
          </span>
        ))}
      </div>
    )
  }
  const escapeRegExp = (str = '') =>
    str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')

  const regex = new RegExp(`(${escapeRegExp(searchText)})`, 'i')
  return (
    <div className='text'>
      {splits.map((val, i) => (
        <span key={i}>
          {val.split(regex).map((part, j) =>
            regex.test(part) ? (
              <span className='hightlight' key={i}>
                {part}
              </span>
            ) : (
              <span key={j}>{part}</span>
            )
          )}
          {i < splits.length && <br />}
        </span>
      ))}
    </div>
  )
}
const isLink = link => {
  const expression = /(https?:\/\/[^\s]+)/g
  const regex = new RegExp(expression)
  if (link.match(regex)) {
    return true
  }
  return false
}

const TextInLink = ({ text = '' }) => {
  const splits = text.split(/\r?\n/g)
  return (
    <>
      {splits.map((val, i) => (
        <>{val === '' ? <br /> : <span key={i}>{val}</span>}</>
      ))}
    </>
  )
}

const LinkMessage = ({ text = '' }) => {
  const expression = /(https?:\/\/[^\s]+)/g
  const regex = new RegExp(expression)
  const lineArray = text.split('\n')

  return (
    <div className='text'>
      {lineArray.map(line => {
        if (!isLink(line)) {
          return (
            <>
              {line ? <TextInLink text={line} /> : null}
              <br />
            </>
          )
        } else {
          const textArray = line.split(regex)
          return (
            <>
              {textArray.map((msg, i) => (
                <>
                  {isLink(msg) ? (
                    <a target='_blank' href={msg}>
                      {msg}
                    </a>
                  ) : (
                    <>{msg ? <TextInLink text={msg} /> : null}</>
                  )}
                </>
              ))}
              <br />
            </>
          )
        }
      })}
    </div>
  )
}
const ChatboxText = ({ value, time, right, searchText }) => (
  <>
    {right ? (
      <div className='chatbox-text row ' style={{ justifyContent: 'end' }}>
        <div className='time right-time-inquiry'>
          {moment(time).format('a HH:mm')}
        </div>
        {isLink(value) ? (
          <div className='text'>
            <LinkMessage text={value} />
          </div>
        ) : (
          <Highlighted searchText={searchText} text={value} />
        )}
      </div>
    ) : (
      <div className='chatbox-text row'>
        {isLink(value) ? (
          <div className='text'>
            <LinkMessage text={value} />
          </div>
        ) : (
          <Highlighted searchText={searchText} text={value} />
        )}
        <div className='time left-time-inquiry'>
          {moment(time).format('a HH:mm')}
        </div>
      </div>
    )}
  </>
)

export default ChatboxText
