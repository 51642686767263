export const sortAndFilterByClinicOrder = ({
  clinicalDepartments,
  isFilter,
  isDisplayCancelShift,
}) => {
  if (clinicalDepartments.length) {
    let filteredData = []
    if (isDisplayCancelShift) {
      clinicalDepartments.forEach(item => {
        filteredData.push({
          ...item,
          ...(item.workSchedules && {
            workSchedules: item.workSchedules.filter(
              ws => !ws.deletedAt || (ws.deletedAt && !ws.doctor?.deletedAt)
            ),
          }),
        })
      })
    } else {
      filteredData = clinicalDepartments
    }
    if (isFilter) {
      return filteredData
        .filter(department => department.isDisplay)
        .sort((a, b) => {
          const positionA = a.clinic.Region?.position ?? null
          const positionB = b.clinic.Region?.position ?? null

          return positionA !== positionB
            ? positionA === null
              ? 1
              : positionB === null
              ? -1
              : positionA - positionB
            : a.clinic.order - b.clinic.order
        })
    }
    return clinicalDepartments.sort((a, b) => {
      const clinicA = a.clinic.order
      const clinicB = b.clinic.order
      return clinicA - clinicB
    })
  }
  return clinicalDepartments
}
