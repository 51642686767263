import React from 'react'
import '../AvailableShiftStyle.css'
import 'fork/react-date-range/dist/styles.css'
import 'fork/react-date-range/dist/theme/default.css'
import { Checkbox } from 'antd'
import AutoComplete from '@medical/components/AutoComplete'

const MultipleDatePicker = ({
  i18n,
  values,
  setFieldValue,
  hourlyWageList,
  placeholder,
}) => {
  return (
    <>
      <div className='content-row'>
        <div className='content-item'>
          <Checkbox
            inputId='isSpecialHourlyWage'
            name='isSpecialHourlyWage'
            value={values.isSpecialHourlyWage}
            onChange={() =>
              setFieldValue('isSpecialHourlyWage', !values.isSpecialHourlyWage)
            }
            checked={values.isSpecialHourlyWage}
          />
          <label htmlFor='isSpecialHourlyWage'>
            {i18n.t('doctor.availableShifts.specialHourlyWage')}
          </label>
        </div>
      </div>
      <div className='content-row'>
        <label
          className='hourly-label'
          htmlFor='amount'
          style={{ fontWeight: 'bold', paddingLeft: '0px' }}
        >
          {i18n.t('doctor.availableShifts.amount')}
        </label>
        <AutoComplete
          name='hourlyWage'
          field='label'
          values={values}
          allSuggestions={hourlyWageList}
          suggestionName='hourlyWageList'
          setFieldValue={setFieldValue}
          placeholder={placeholder}
        />
      </div>
    </>
  )
}

export default MultipleDatePicker
