import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import ClinicNameRender from '@medical/components/ClinicNameRender'
import {
  calcWorkScheduleTotalDays,
  calcWorkScheduleTotalHours,
  calcWorkScheduleTotalSalary,
  clinicalDepartmentNames,
  dateValidation,
} from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import Router from '@medical/routes/router'
import moment from 'moment'
import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'

import {
  DOCTOR_CANCEL_REQUESTED_WORK_SCHEDULE,
  DOCTOR_WORK_SCHEDULES,
} from './RequestedWorkSchedulesList.graphql'
import RequestedWorkSchedulesListScene from './RequestedWorkSchedulesListScene'
import { parseJwt } from '@medical/constant/utilities'

const RequestedWorkSchedulesList = ({
  match: {
    path,
    params: { year = moment().year(), month = moment().month() + 1 },
  },
}) => {
  const [{ i18n, popup }] = useCustom()
  const date = moment
    .utc()
    .year(year)
    .month(month - 1)
    .startOf('month')
    .toISOString()
  const endDate = moment
    .utc()
    .year(year)
    .month(month - 1)
    .endOf('month')
    .toISOString()
  const doctorId = parseJwt(localStorage.getItem('medical_doctor_token'))
    .doctorId
  const { loading, error, data, refetch } = useQuery(DOCTOR_WORK_SCHEDULES, {
    variables: {
      where: {
        Doctor: {
          id: doctorId,
        },
        startTime_gte: date,
        startTime_lt: endDate,
        OR: [{ accepted: false }, { accepted: null }],
        deletedStatus: null,
        deletedAt: null,
        AvailableShift_some: { isPublished: true },
      },
      orderBy: 'startTime_ASC',
    },
  })
  useEffect(() => {
    refetch()
  })
  const [cancelRequestedWorkSchedule] = useMutation(
    DOCTOR_CANCEL_REQUESTED_WORK_SCHEDULE,
    {
      refetchQueries: [
        {
          query: DOCTOR_WORK_SCHEDULES,
          variables: {
            where: {
              Doctor: {
                id: doctorId,
              },
              startTime_gte: date,
              startTime_lt: endDate,
              OR: [{ accepted: false }, { accepted: null }],
              deletedStatus: null,
              deletedAt: null,
              AvailableShift_some: { isPublished: true },
            },
            orderBy: 'startTime_ASC',
          },
        },
      ],
      awaitRefetchQueries: true,
    }
  )

  if (
    dateValidation({ year, month }) ||
    [
      Router.doctorRequestedWorkSchedulesListWithoutYearMonth,
      Router.doctorRequestedWorkSchedulesListWithoutMonth,
    ].includes(path)
  )
    return (
      <Redirect
        to={Router.get(Router.doctorRequestedWorkSchedulesList, {
          year: moment().year(),
          month: moment().month() + 1,
        })}
      />
    )

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const { workSchedules } = data || {}
  const departmentNames = clinicalDepartmentNames({
    departments: data.departments,
  })
  const resultWorkSchedules = []
  workSchedules.map(ws => {
    resultWorkSchedules.push({
      id: ws.id,
      clinic: ClinicNameRender({
        clinicalDepartment: ws.clinicalDepartment,
      }),
      clinicKana: ws.clinicalDepartment.clinic.nameKana,
      clinicalDepartment: departmentNames[ws.clinicalDepartment.name],
      startTime: ws.startTime,
      endTime: ws.endTime,
      dailySalary: ws.acceptedShift.dailySalary,
      accepted: ws.accepted,
    })

    return true
  })

  const totalHours = calcWorkScheduleTotalHours(resultWorkSchedules)

  const totalSalary = calcWorkScheduleTotalSalary(resultWorkSchedules)

  const totalDays = calcWorkScheduleTotalDays(resultWorkSchedules)

  const onSubmit = async ({ workScheduleId }, { setSubmitting, resetForm }) => {
    try {
      await cancelRequestedWorkSchedule({
        variables: {
          workScheduleId,
        },
      })
      popup.success(
        i18n.t('doctor.cancelRequestedWorkSchedule.submissionSuccess')
      )
      setSubmitting(false)
      resetForm()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }

  return (
    <RequestedWorkSchedulesListScene
      i18n={i18n}
      date={date}
      workSchedules={resultWorkSchedules}
      totalHours={totalHours}
      totalDays={totalDays}
      totalSalary={totalSalary}
      onSubmit={onSubmit}
    />
  )
}

export default RequestedWorkSchedulesList
