import { useMutation } from '@apollo/react-hooks'
import ChangePasswordComponent from '@medical/components/Forms/ChangePasswordComponent'
import { useCustom } from '@medical/provider/context'
import React from 'react'

import { STAFF_CHANGE_PASSWORD } from './ChangePassword.graphql'

const ChangePasswordContainer = ({ closeModal }) => {
  const [staffChangePassword] = useMutation(STAFF_CHANGE_PASSWORD)
  const [{ i18n, popup }] = useCustom()

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await staffChangePassword({
        variables: values,
      })
      setSubmitting(false)
      resetForm()
      popup.success(i18n.t('main.changePassword.submissionSuccess'))
      closeModal()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }

  return <ChangePasswordComponent onSubmit={onSubmit} />
}

export default ChangePasswordContainer
