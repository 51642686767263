import logo from '@medical/assets/image/logo.png'
import { ProgressSpinner } from '@medical/components'
import PasswordInput from '@medical/components/PasswordInput'
import { ErrorMessage, Formik } from 'formik'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

const LoginComponent = ({
  loginLabel,
  email,
  emailLabel,
  passwordLabel,
  submitLabel,
  login,
  bottom,
}) => {
  const { i18n } = useTranslation()
  return (
    <Formik
      initialValues={{
        email: email || '',
        password: '',
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(i18n.t('validation.email.email'))
          .required(i18n.t('validation.email.required')),
        password: Yup.string()
          .min(6, i18n.t('validation.password.min'))
          .required(i18n.t('validation.password.required')),
      })}
      onSubmit={login}
      render={({
        values,
        handleChange,
        isSubmitting,
        handleSubmit,
        handleBlur,
        isValid,
      }) => (
        <div className='login-bg'>
          <div className='login-box'>
            <div className='login-title'>
              <img src={logo} alt='' style={{ width: '60%' }} />
            </div>
            <div className='login-title-label'>{loginLabel}</div>
            <form onSubmit={e => e.preventDefault()}>
              <div className='login-inner'>
                <div className='login-label'>{emailLabel}</div>
                <div className='login-input'>
                  <InputText
                    value={values.email}
                    name='email'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoCapitalize='none'
                  />
                  <ErrorMessage
                    name='email'
                    component='div'
                    className='login-alert'
                  />
                </div>
                <div className='login-label'>{passwordLabel}</div>
                <div className='login-input'>
                  <PasswordInput
                    feedback={false}
                    name='password'
                    values={values}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    fullWidth
                  />
                  <ErrorMessage
                    name='password'
                    component='div'
                    className='login-alert'
                  />
                </div>
                {isSubmitting ? (
                  <ProgressSpinner />
                ) : (
                  <Button
                    type='submit'
                    className='login-button p-button-primary'
                    disabled={isSubmitting || !isValid}
                    onClick={handleSubmit}
                    label={submitLabel}
                  />
                )}
              </div>
            </form>
            <div className='login-bottom'>{bottom}</div>
          </div>
        </div>
      )}
    />
  )
}

export default LoginComponent
