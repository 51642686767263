import 'react-image-crop/dist/ReactCrop.css'

import ConfirmationModal from '@medical/components/ConfirmationModal'
import { ACCEPT_IMAGE_EXTENSIONS, MAX_FILE_SIZE } from '@medical/constant'
import { imageCrop } from '@medical/libs/imageCrop'
import { Modal } from 'antd'
import { ErrorMessage, Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import React, { useRef, useState } from 'react'
import Dropzone from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from 'react-image-crop'
import * as Yup from 'yup'
import { useMutation } from '@apollo/react-hooks'
import { GET_POST_PRE_SIGNED_URL } from '../UploadImage/UploadImage.graphql'
import uploadImage from '@medical/libs/uploadImage'

const UploadImageForm = ({
  uploadFile,
  confirmationMessage,
  hideTitle,
  popup,
}) => {
  const [imgSrc, setImgSrc] = useState('')
  const [crop, setCrop] = useState()
  const imgRef = useRef()
  const canvasRef = useRef()
  const [completedCrop, setCompletedCrop] = useState()
  const [cropImgVisible, setCropImgVisible] = useState(false)
  const { i18n } = useTranslation()
  const [getPostPreSignedUrl] = useMutation(GET_POST_PRE_SIGNED_URL)

  const centerAspectCrop = (mediaWidth, mediaHeight) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        2 / 1,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    )
  }

  const onImageLoad = event => {
    const { width, height } = event.currentTarget
    setCrop(centerAspectCrop(width, height))
    setCompletedCrop(
      convertToPixelCrop(centerAspectCrop(width, height), width, height)
    )
  }

  const onSelectFile = event => {
    if (event.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        setImgSrc(reader.result.toString() || '')
        setCropImgVisible(true)
      })
      reader.readAsDataURL(event[0])
    }
  }
  return (
    <Formik
      initialValues={{
        file: '',
        confirm: false,
      }}
      onSubmit={uploadFile}
      validationSchema={Yup.object().shape({
        file: Yup.mixed().required(),
      })}
      render={formikProps => {
        const { setFieldValue, isValid } = formikProps
        return (
          <React.Fragment>
            {!hideTitle && (
              <div className='modal-title'>{i18n.t('main.uploadFile')}</div>
            )}
            <Dropzone
              onDrop={onSelectFile}
              accept={ACCEPT_IMAGE_EXTENSIONS}
              maxSize={MAX_FILE_SIZE}
              multiple={false}
            >
              {({ getRootProps, getInputProps, acceptedFiles }) => (
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  {acceptedFiles &&
                  acceptedFiles.length > 0 &&
                  canvasRef &&
                  imgSrc ? (
                    <canvas
                      ref={canvasRef}
                      id='myCanvas'
                      style={{ maxHeight: 'calc(30vh - 10px)' }}
                    />
                  ) : (
                    <p>{i18n.t('doctor.uploadFile.dropFileHere')}</p>
                  )}
                </div>
              )}
            </Dropzone>
            {imgSrc && (
              <Modal
                title='画像の表示範囲を選択する'
                centered
                visible={cropImgVisible}
                footer={null}
                onCancel={() => {
                  setCropImgVisible(false)
                }}
              >
                <>
                  <ReactCrop
                    aspect={2 / 1}
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={c => setCompletedCrop(c)}
                  >
                    <img
                      ref={imgRef}
                      alt='プレビュー画像'
                      src={imgSrc}
                      onLoad={onImageLoad}
                    />
                  </ReactCrop>
                  <div
                    style={{
                      margin: '1rem 0',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      onClick={() => {
                        if (imgSrc) {
                          const ctx = canvasRef.current.getContext('2d')
                          if (ctx) {
                            const file = imageCrop({
                              ctx,
                              imgRef,
                              canvasRef,
                              completedCrop,
                              scale: 1,
                            })
                            file.current.toBlob(async blob => {
                              const newFile = new File(
                                [blob],
                                `upload_img_${moment().valueOf()}`,
                                {
                                  type: 'image/jpeg',
                                }
                              )
                              const {
                                data: postPreSignedUrl,
                              } = await getPostPreSignedUrl({
                                variables: {
                                  fileName: newFile.name,
                                  mimetype: newFile.type,
                                },
                              })
                              const key = await uploadImage(
                                i18n,
                                popup,
                                postPreSignedUrl,
                                newFile
                              )

                              setFieldValue('file', key)
                            }, 'image/jpeg')
                          }
                        }
                        setCropImgVisible(false)
                      }}
                      label={i18n.t('staff.updateClinic.submit')}
                      className='reflect-button'
                      style={{ margin: '0 1rem 0' }}
                    />
                    <Button
                      onClick={() => {
                        setCropImgVisible(false)
                      }}
                      label={i18n.t('doctor.identity.cancelButton')}
                    />
                  </div>
                </>
              </Modal>
            )}
            <div className='modal-bottom'>
              <Button
                type='submit'
                onClick={() => {
                  setFieldValue('confirm', true)
                }}
                disabled={!isValid}
                label='この画像を登録する'
              />
            </div>
            <ConfirmationModal
              {...formikProps}
              confirmationMessage={confirmationMessage}
            />
          </React.Fragment>
        )
      }}
    />
  )
}

export default UploadImageForm
