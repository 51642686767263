import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { onPageChange } from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import queryString from 'query-string'
import React from 'react'
import { Router } from 'react-router-dom'

import { ACTIVITY_LOGS_UPDATE_BY_STAFF } from './ActivityLogsUpdateByStaff.graphql'
import ActivityLogsUpdateByStaffScene from './ActivityLogsUpdateByStaffScene'

const ActivityLogsUpdateByStaff = ({ history, location: { search } }) => {
  const [{ i18n }] = useCustom()
  const {
    page = 1,
    rowsPerPage = 20,
    orderBy = 'createdAt_DESC',
    staffId,
  } = queryString.parse(search)
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  const variables = {
    first: first < 0 ? 20 : first,
    skip: skip < 0 ? 0 : skip,
    orderBy,
    where: {
      Staff: {
        id: staffId,
      },
      activity_in: [
        'STAFF_BLOCKED_DOCTOR',
        'STAFF_UNBLOCKED_DOCTOR',
        'STAFF_PROCESS_IDENTITY_NUMBER',
        'STAFF_DELETE_IDENTITY_NUMBER',
        'STAFF_QUESTIONNAIRE_DATA_CSV_UPLOAD',
        'STAFF_DELETE_INCENTIVE_SETTINGS',
        'STAFF_UPDATE_INCENTIVE_SETTINGS',
        'STAFF_RENEWAL_OF_INCENTIVE_GRANT',
        'STAFF_DELETE_INCENTIVE_GRANT',
        'STAFF_REQUEST_DELETE_DOCTOR',
        'STAFF_CANCEL_REQUEST_DELETE_DOCTOR',
        'STAFF_HARD_DELETE_DOCTOR',
        'STAFF_APPROVAL_OF_OVERTIME_APPLICATION',
        'STAFF_REJECTION_OF_OVERTIME_APPLICATION',
        'STAFF_WITHDRAWA_OF_APPROVAL_FOR_OVERTIME_APPLICATION',
        'STAFF_CREATE_PORTAL',
        'STAFF_DELETE_PORTAL',
        'STAFF_UPDATE_PORTAL',
        'STAFF_CREATE_QUESTION',
        'STAFF_UPDATE_QUESTION',
        'STAFF_DELETE_QUESTION',
        'DELETE_RECRUITMENT_SHIFTS',
        'DELETE_RECRUITMENT_SHIFTS_GROUP',
        'CANCEL_FIXED_SHIFTS',
        'DELETE_FIXED_SHIFTS_GROUP',
        'APPROVAL_WORK_OUTSIDE',
        'REJECTED_WORK_OUTSIDE',
        'DOWNLOAD_MONTHLY_SALARY_CONFIRMATION_CSV',
        'STAFF_UPLOAD_CSV_DOCTOR_PROFILE_UPDATE',
        'STAFF_EDIT_HOURLY_WAGE_CHANGE_RESERVATIONS',
      ],
    },
  }

  const { loading, error, data } = useQuery(ACTIVITY_LOGS_UPDATE_BY_STAFF, {
    variables,
    fetchPolicy: 'network-only',
  })

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  const {
    activityLogsConnection: {
      edges,
      aggregate: { count },
    },
    staffs,
  } = data

  const onSearch = ({ staff }) => {
    history.push({
      pathname: Router.staffActivityLogsListDoctorHistory,
      search: queryString.stringify({
        rowsPerPage,
        staffId: staff ? staff.id : undefined,
      }),
    })
  }

  const newStaffs = staffs.map(staff => ({
    ...staff,
    fullNameEmail: `${staff.lastname} ${staff.firstname} (${staff.email})`,
    fullName: `${staff.lastname} ${staff.firstname}`,
    fullNameKana: `${staff.lastnameKana} ${staff.firstnameKana}`,
  }))

  return (
    <ActivityLogsUpdateByStaffScene
      i18n={i18n}
      edges={edges}
      staffs={newStaffs}
      rowsPerPage={parseInt(rowsPerPage, 10)}
      page={page}
      count={count}
      staffId={staffId}
      onPageChange={onPageChange(history, search)}
      onSearch={onSearch}
    />
  )
}

export default ActivityLogsUpdateByStaff
