import './style.css'

import BreadCrumb from '@medical/components/Breadcrumb'
import CustomDropdown from '@medical/components/DropdownInSearch'
import ModalLink from '@medical/components/ModalLink'
import MyNumberShow from '@medical/components/MyNumberShow'
import Router from '@medical/routes/router'
import { Formik } from 'formik'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Paginator } from 'primereact/paginator'
import queryString from 'query-string'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Auth from '@medical/middleware/auth'
import Axios from 'axios'
import { waitFor } from '@medical/libs'
import { DOWNLOAD_CSV_DOCTOR_NUMBER_MANAGE } from '@medical/constant'

const myNumberStatus = [
  {
    value: 'UNSUBMIT',
    description: '未提出',
  },
  {
    value: 'UNCONFIRM',
    description: '未確認',
  },
  {
    value: 'RESUBMIT_REQUEST',
    description: '再提出依頼中',
  },
  {
    value: 'CONFIRMED',
    description: '確認済み',
  },
]

const MyNumberManageScene = ({
  i18n,
  page,
  rowsPerPage,
  fullname,
  email,
  edges,
  count,
  onPageChange,
  isProcessPermitted,
  confirmationStatus_in,
  staffCreateActivity,
  renderMyNumber,
  base64Decode,
  isUpdateDoctorIdentity,
  socket,
  progress,
  setProgress,
  removeProgress,
}) => {
  const renderMyNumberStatus = status => {
    switch (status) {
      case 'UNSUBMIT':
        return <span className='status-unsubmit'>未提出</span>
      case 'UNCONFIRM':
        return <span className='status-unconfirm'>未確認</span>
      case 'RESUBMIT_REQUEST':
        return <span className='status-resubmit'>再提出依頼中</span>
      case 'CONFIRMED':
        return <span className='status-confirmed'>確認済み</span>
      case 'RESUBMITTED':
        return <span className='status-unconfirm'>未確認</span>
      default:
        return null
    }
  }
  const hasProgress = Object.keys(progress).includes(DOWNLOAD_CSV_DOCTOR_NUMBER_MANAGE)
  useEffect(() => {
    if (socket && hasProgress) {
      socket.on(DOWNLOAD_CSV_DOCTOR_NUMBER_MANAGE, data => {
        if (data?.percent) {
          setProgress({
            progress: DOWNLOAD_CSV_DOCTOR_NUMBER_MANAGE,
            label: `${i18n.t('staff.myNumberManage.title')}中`,
            percent: Number(data?.percent),
          })
        }
      })
    }
  }, [socket, hasProgress])
  return (
    <React.Fragment>
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('staff.myNumberManage.title')}
          <BreadCrumb
            items={[
              {
                title: i18n.t('staff.myNumberManage.title'),
              },
            ]}
          />
        </div>
        <div className='staff-buttons'>
          <Button
            icon='pi pi-download'
            className='p-button-normal'
            label='CSVダウンロード'
            onClick={async () => {
              setProgress({
                progress: DOWNLOAD_CSV_DOCTOR_NUMBER_MANAGE,
                label: `${i18n.t('staff.myNumberManage.title')}中`,
                percent: 0,
              })
              const token = Auth.getToken()
              try {
                const url = `${process.env.REACT_APP_ENDPOINT}/downloadMyNumberIdentity?token=${token}`
                const { status, data: urlS3 } = await Axios({
                  method: 'GET',
                  url,
                })
                await waitFor(3000)
                staffCreateActivity({
                  variables: {
                    activity: 'STAFF_EXPORT_CSV_IDENTITY_NUMBER',
                  },
                })
                if (status === 204) {
                  setProgress({
                    progress: DOWNLOAD_CSV_DOCTOR_NUMBER_MANAGE,
                    label: `${i18n.t('staff.myNumberManage.title')}中`,
                    msg: i18n.t('main.msgDownloadCSVStatusError'),
                  })
                  await waitFor(3000)
                } else {
                  setProgress({
                    progress: DOWNLOAD_CSV_DOCTOR_NUMBER_MANAGE,
                    percent: 100,
                    url: urlS3,
                  })
                }
              } catch (err) {
                setProgress({
                  progress: DOWNLOAD_CSV_DOCTOR_NUMBER_MANAGE,
                  label: `${i18n.t('staff.myNumberManage.title')}中`,
                  msg: i18n.t('main.msgDownloadCSVStatusError'),
                })
                await waitFor(3000)
              }
              removeProgress(DOWNLOAD_CSV_DOCTOR_NUMBER_MANAGE)
            }}
          />
          <p className='csv-note'>※マイナンバー情報が含まれます</p>
        </div>
      </div>
      <div className='container is-max'>
        <div className='box'>
          <Formik
            enableReinitialize
            initialValues={{
              fullname,
              email,
              confirmationStatus_in,
            }}
            render={formikProps => {
              const { values, handleChange, handleBlur } = formikProps
              return (
                <div className='search-parent'>
                  <InputText
                    name='fullname'
                    value={values.fullname}
                    placeholder={i18n.t('main.fullname')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ width: '200px', marginRight: '20px' }}
                  />
                  <InputText
                    name='email'
                    value={values.email}
                    placeholder='メールアドレス'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ width: '200px', marginRight: '20px' }}
                  />
                  <CustomDropdown
                    {...formikProps}
                    options={myNumberStatus}
                    name='confirmationStatus_in'
                    defaultTitle={i18n.t('staff.myNumberManage.status')}
                  />
                  <Link
                    to={{
                      pathname: Router.staffMyNumberManage,
                      search: queryString.stringify({
                        rowsPerPage,
                        fullname: values.fullname,
                        email: values.email,
                        confirmationStatus_in: values.confirmationStatus_in,
                      }),
                    }}
                    style={{ marginLeft: '20px' }}
                  >
                    <Button label={i18n.t('main.search')} icon='pi pi-search' />
                  </Link>
                  {fullname || email || confirmationStatus_in ? (
                    <Link
                      to={{
                        pathname: Router.staffMyNumberManage,
                        search: queryString.stringify({
                          rowsPerPage,
                        }),
                      }}
                    >
                      <Button
                        icon='pi pi-times'
                        className='p-button-secondary'
                        style={{ marginLeft: '20px' }}
                      />
                    </Link>
                  ) : null}
                </div>
              )
            }}
          />
          <Formik
            initialValues={{
              confirm: false,
            }}
            render={formikProps => (
              <div className='overflow-container'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th width='20%'>{i18n.t('staff.myNumberManage.name')}</th>
                      <th width='23%'>
                        {i18n.t('staff.myNumberManage.email')}
                      </th>
                      <th width='23%'>
                        {i18n.t('staff.myNumberManage.myNumber')}
                      </th>
                      {isProcessPermitted ? (
                        <th width='12%'>
                          {i18n.t('staff.myNumberManage.detail')}
                        </th>
                      ) : null}
                      {isProcessPermitted && isUpdateDoctorIdentity ? (
                        <th width='12%'>編集</th>
                      ) : null}
                      <th width='22%'>
                        {i18n.t('staff.myNumberManage.status')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {edges.map(({ node }, index) => (
                      <tr
                        key={node.id}
                        className={index % 2 === 0 ? 'odd-line' : ''}
                        style={{ textAlign: 'center' }}
                      >
                        <td>{node.fullName}</td>
                        <td>{node.email}</td>
                        <td>
                          <MyNumberShow
                            className={index % 2 === 0 ? 'odd-line' : ''}
                            value={
                              node.doctorIdentity
                                ? renderMyNumber(
                                    base64Decode(
                                      node.doctorIdentity.identityNumber
                                    )
                                  )
                                : ''
                            }
                            disabled
                            classDisabled
                          />
                        </td>
                        {isProcessPermitted ? (
                          node.doctorIdentity ? (
                            <td>
                              <div className='action-doctor-list'>
                                <ModalLink
                                  to={Router.get(
                                    Router.staffDetailDoctorIdentity,
                                    {
                                      doctorId: node.id,
                                    }
                                  )}
                                >
                                  <Button icon='pi pi-info' />
                                </ModalLink>
                              </div>
                            </td>
                          ) : (
                            <td>
                              <Button icon='pi pi-info' disabled />
                            </td>
                          )
                        ) : null}
                        {isProcessPermitted && isUpdateDoctorIdentity ? (
                          node.id ? (
                            <td>
                              <div className='action-doctor-list'>
                                <ModalLink
                                  to={Router.get(
                                    Router.staffManangerDoctoridentity,
                                    {
                                      doctorId: node.id,
                                    }
                                  )}
                                >
                                  <Button icon='pi pi-pencil' />
                                </ModalLink>
                              </div>
                            </td>
                          ) : (
                            <td>
                              <Button icon='pi pi-pencil' disabled />
                            </td>
                          )
                        ) : null}
                        <td>
                          {node.doctorIdentity
                            ? renderMyNumberStatus(
                                node.doctorIdentity.confirmationStatus
                              )
                            : renderMyNumberStatus('UNSUBMIT')}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          />
          <Paginator
            rows={rowsPerPage}
            totalRecords={count}
            first={(page - 1) * rowsPerPage}
            onPageChange={onPageChange}
            leftContent={
              <div className='paginator-total'>
                {i18n.t('main.paginationTotal')}: {count}
              </div>
            }
          />
        </div>
      </div>
    </React.Fragment>
  )
}
export default MyNumberManageScene
