import { objectTargetConstant } from '@medical/constant'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { ListBox } from 'primereact/listbox'
import CustomCheckBox from '@medical/components/CheckBox'
import Chips from '@medical/components/CustomChips/index'
import React from 'react'
import { useApolloClient } from '@apollo/react-hooks'
import { DOCTOR_CONNECTION } from '../CreateAvailableShift/CreateAvailableShift.graphql'
import { convertDoctorsConnection } from '@medical/libs'
import { ErrorMessage } from 'formik'

export const ShiftFormDoctorPicker = ({
  i18n,
  listObjectTarget,
  listWorkPartten,
  formikProps,
  state,
  hasPermitted = () => true,
}) => {
  const { values, setFieldValue } = formikProps
  const inputDoctorNameFilter = React.createRef()
  const client = useApolloClient()
  const searchDoctor = async (doctorName, workPattern) => {
    const { data: dataConnection } = await client.query({
      query: DOCTOR_CONNECTION,
      variables: {
        where: {
          OR: [
            {
              unSigned_contains: doctorName
                ? doctorName
                    .replace(/ /g, '')
                    .replace(/　/g, '')
                    .toLowerCase()
                : '',
            },
            {
              email_contains: doctorName,
            },
          ],
          workPattern:
            workPattern.value !== 'ALL_WORKPATTTEN'
              ? workPattern.value
              : undefined,
          registrationStatus_in: ['CREATED_BY_STAFF', 'ACCEPTED'],
        },
        first: 10,
        skip: 0,
        orderBy: 'unSigned_ASC',
      },
      fetchPolicy: 'network-only',
    })
    return convertDoctorsConnection(
      dataConnection.doctorsConnection.edges.filter(doctor => !doctor.blocked)
    )
  }
  return (
    <>
      <div className='tr'>
        <div className='th'>
          {i18n.t('staff.createShift.recruitmentShiftDisplayDoctor')}
        </div>
        <div className='td'>
          <Dropdown
            name='objectTarget'
            placeholder={i18n.t(
              'main.incentiveSetting.listPlaceHolder.objectTarget'
            )}
            options={listObjectTarget}
            value={values.objectTarget}
            onChange={e => {
              if (hasPermitted()) {
                setFieldValue('objectTarget', e.target.value)
              }
            }}
          />
        </div>
      </div>
      {values.objectTarget === objectTargetConstant.SET_FOR_EACH_DOCTOR && (
        <>
          <div className='tr'>
            <div className='td group-select-type'>
              <Dropdown
                name='workParttenSearch'
                options={listWorkPartten}
                className='drop-down-select-workpartten-search'
                optionLabel='description'
                value={values.workParttenSearch}
                placeholder={i18n.t(
                  'main.incentiveSetting.listPlaceHolder.workPattern'
                )}
                onChange={e => {
                  setFieldValue('workParttenSearch', e.target.value)
                }}
              />
              <InputText
                style={{ width: '48%' }}
                name='textSearchDoctorByName'
                placeholder={i18n.t(
                  'main.incentiveSetting.listPlaceHolder.nameSearch'
                )}
                className='input-text-search'
                ref={inputDoctorNameFilter}
                onChange={e => {
                  setFieldValue('textSearchDoctorByName', e.target.value)
                }}
              />
              <Button
                label={i18n.t('main.incentiveSetting.search')}
                icon='pi pi-search'
                onClick={async e => {
                  const doctorsSearched = await searchDoctor(
                    values.textSearchDoctorByName,
                    values.workParttenSearch
                  )
                  setFieldValue('doctorsList', doctorsSearched)
                }}
              />
            </div>
          </div>
          <div className='tr'>
            {(values.isShowListBox || values.specificDoctors) && (
              <div className='td'>
                <ListBox
                  optionValue='id'
                  dataKey='id'
                  name='listDoctors'
                  optionLabel='fullNameEmail'
                  className='list-doctor'
                  value={values.specificDoctors}
                  listStyle={{ maxHeight: '250px' }}
                  options={values.doctorsList}
                  multiple
                  onChange={e => {
                    if (hasPermitted()) {
                      setFieldValue('specificDoctors', e.value)
                    }
                  }}
                />

                <Chips
                  {...formikProps}
                  name='selectedDoctor'
                  componentClassName='chip-doctor-selected'
                  referenceVariable='specificDoctors'
                  hasPermitted={hasPermitted}
                />
                <ErrorMessage
                  name='specificDoctors'
                  component='div'
                  className='color-red'
                />
              </div>
            )}
          </div>
        </>
      )}

      {values.objectTarget ===
        objectTargetConstant.SET_FOR_EACH_WORKPARTTEN && (
        <CustomCheckBox
          {...formikProps}
          name='workPattern'
          options={listWorkPartten.filter(
            item => item.value !== 'ALL_WORKPATTTEN'
          )}
          hasPermitted={hasPermitted}
        />
      )}
    </>
  )
}
