import gql from 'graphql-tag'

export const CLINIC_FRAGMENT = gql`
    fragment CLINIC_FRAGMENT on Clinic {
        id
        name
        nameKana
        address
        phoneNumber
        email
        isPublished
    }
`

export const CLINICAL_DEPARTMENTS = gql`
  mutation ClinicalDepartments($id: ID) {
    clinicalDepartments(where: { Clinic: { id: $id} }) {
      id,
      name,
      departmentCode
    }
  }
`

export const CLINICS_CONNECTION = gql`
  query CLINICS_CONNECTION($where: ClinicWhereInput, $skip: Int, $first: Int) {
    clinicsConnection(where: $where, skip: $skip, first: $first) {
      edges {
        node {
          ...CLINIC_FRAGMENT
        }
      }
    }
  }
  ${CLINIC_FRAGMENT}
`
export const CREATE_EXPECTATION_DOUBLE_RECRUITMENT_RECORD_PATTERN = gql `
  mutation CreateExpectationDoubleRecruitmentRecordPattern(
    $clinic: ID!,
    $clinicalDepartment: ID!,
    $targetMonth: Int,
    $targetYear: Int,
    $mondayMorningShiftDoctors: Int,
    $mondayAfternoonShiftDoctors: Int,
    $mondayNightShiftDoctors: Int,
    $tuesdayMorningShiftDoctors: Int,
    $tuesdayAfternoonShiftDoctors: Int,
    $tuesdayNightShiftDoctors: Int,
    $wednesdayMorningShiftDoctors: Int,
    $wednesdayAfternoonShiftDoctors: Int,
    $wednesdayNightShiftDoctors: Int,
    $thursdayMorningShiftDoctors: Int,
    $thursdayAfternoonShiftDoctors: Int,
    $thursdayNightShiftDoctors: Int,
    $fridayMorningShiftDoctors: Int,
    $fridayAfternoonShiftDoctors: Int,
    $fridayNightShiftDoctors: Int,
    $saturdayMorningShiftDoctors: Int,
    $saturdayAfternoonShiftDoctors: Int,
    $saturdayNightShiftDoctors: Int,
    $sundayMorningShiftDoctors: Int,
    $sundayAfternoonShiftDoctors: Int,
    $sundayNightShiftDoctors: Int,
    $holidayMorningShiftDoctors: Int,
    $holidayAfternoonShiftDoctors: Int,
    $holidayNightShiftDoctors: Int,
  ) {
    createExpectationDoubleRecruitmentRecordPattern(
      clinic: $clinic
      clinicalDepartment: $clinicalDepartment
      targetMonth: $targetMonth
      targetYear: $targetYear
      mondayMorningShiftDoctors: $mondayMorningShiftDoctors
      mondayAfternoonShiftDoctors: $mondayAfternoonShiftDoctors
      mondayNightShiftDoctors: $mondayNightShiftDoctors
      tuesdayMorningShiftDoctors: $tuesdayMorningShiftDoctors
      tuesdayAfternoonShiftDoctors: $tuesdayAfternoonShiftDoctors
      tuesdayNightShiftDoctors: $tuesdayNightShiftDoctors
      wednesdayMorningShiftDoctors: $wednesdayMorningShiftDoctors
      wednesdayAfternoonShiftDoctors: $wednesdayAfternoonShiftDoctors
      wednesdayNightShiftDoctors: $wednesdayNightShiftDoctors
      thursdayMorningShiftDoctors: $thursdayMorningShiftDoctors
      thursdayAfternoonShiftDoctors: $thursdayAfternoonShiftDoctors
      thursdayNightShiftDoctors: $thursdayNightShiftDoctors
      fridayMorningShiftDoctors: $fridayMorningShiftDoctors
      fridayAfternoonShiftDoctors: $fridayAfternoonShiftDoctors
      fridayNightShiftDoctors: $fridayNightShiftDoctors
      saturdayMorningShiftDoctors: $saturdayMorningShiftDoctors
      saturdayAfternoonShiftDoctors: $saturdayAfternoonShiftDoctors
      saturdayNightShiftDoctors: $saturdayNightShiftDoctors
      sundayMorningShiftDoctors: $sundayMorningShiftDoctors
      sundayAfternoonShiftDoctors: $sundayAfternoonShiftDoctors
      sundayNightShiftDoctors: $sundayNightShiftDoctors
      holidayMorningShiftDoctors: $holidayMorningShiftDoctors
      holidayAfternoonShiftDoctors: $holidayAfternoonShiftDoctors
      holidayNightShiftDoctors: $holidayNightShiftDoctors
    )
  }
`
export const GET_EXPECTATION_DOUBLE_RECRUITMENT_RECORD_PATTERN = gql `
  mutation GetExpectationDoubleRecruitmentRecordPattern(
    $clinic: ID!,
    $clinicalDepartment: ID!,
    $targetMonth: Int,
    $targetYear: Int,
  ) {
    getExpectationDoubleRecruitmentRecordPattern(
      clinic: $clinic
      clinicalDepartment: $clinicalDepartment
      targetMonth: $targetMonth
      targetYear: $targetYear
    ) {
      updatedAt
      createdAt
      id
      targetMonth
      targetYear
      mondayMorningShiftDoctors
      mondayAfternoonShiftDoctors
      mondayNightShiftDoctors
      tuesdayMorningShiftDoctors
      tuesdayAfternoonShiftDoctors
      tuesdayNightShiftDoctors
      wednesdayMorningShiftDoctors
      wednesdayAfternoonShiftDoctors
      wednesdayNightShiftDoctors
      thursdayMorningShiftDoctors
      thursdayAfternoonShiftDoctors
      thursdayNightShiftDoctors
      fridayMorningShiftDoctors
      fridayAfternoonShiftDoctors
      fridayNightShiftDoctors
      saturdayMorningShiftDoctors
      saturdayAfternoonShiftDoctors
      saturdayNightShiftDoctors
      sundayMorningShiftDoctors
      sundayAfternoonShiftDoctors
      sundayNightShiftDoctors
      holidayMorningShiftDoctors
      holidayAfternoonShiftDoctors
      holidayNightShiftDoctors
    }
  }
`