import './style.css'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import {
  addClinicalDepartmentName,
  adDefaultClinicalDepartmentTime,
} from '@medical/libs'
import {
  CLINIC_QUERY,
  GET_CLINIC_SCHEDULE,
  GET_LIST_CLINICAL_DEPARTMENT,
  REGION_QUERY,
  STAFF_UPDATE_CLINIC_SCHEDULE_WORK_TIME,
  UPDATE_CLINIC_IMAGE,
} from '@medical/pages/staff/Clinics/ClinicUpdate/ClinicUpdate.graphql'
import ClinicForm from '@medical/pages/staff/Clinics/components/ClinicForm'
import { UPDATE_CLINIC_DEPARTMENT_DAILY_WORK_TIME } from '@medical/pages/staff/Clinics/TimePerday/TimePerDay.graphql'
import TimePerDayForm from '@medical/pages/staff/Clinics/TimePerday/TimePerDayForm'
import { UPDATE_CLINIC_TREATMENT_RESULT } from '@medical/pages/staff/Clinics/TreatmentResult/TreatmentResult.graphql'
import { DOWNLOAD_FILE } from '@medical/pages/staff/Doctors/DoctorDetail/DoctorDetail.graphql'
import { useCustom } from '@medical/provider/context'
import Router from '@medical/routes/router'
import { Button } from 'primereact/button'
import { TabPanel, TabView } from 'primereact/tabview'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { GuidanceMessage } from '../GuidanceMessage/GuidanceMessage'
import { UPDATE_CLINIC_BASE_MESSAGE } from '../GuidanceMessage/GuidanceMessage.grapql'
import PeriodicOpenTabPanel from '../PeriodicOpenTab/PeriodicOpenTab'
import TreatmentResult from '../TreatmentResult/TreatmentResult'
import UploadImageForm from '@medical/components/Forms/UploadImageForm'

const ClinicDetail = ({
  match: {
    params: { clinicId },
  },
  parentPath,
  closeModal,
}) => {
  const [{ i18n, popup }] = useCustom()
  const [loadingImg, setLoadingImg] = useState(true)
  const [isUploadClinicImage, setIsUploadClinicImage] = useState(false)
  const [downloadFile] = useMutation(DOWNLOAD_FILE)
  const [img, setImg] = useState(null)
  const [updateClinicImage] = useMutation(UPDATE_CLINIC_IMAGE)
  const [updateCLinicDepartmentDailyWorkTime] = useMutation(
    UPDATE_CLINIC_DEPARTMENT_DAILY_WORK_TIME
  )
  const [updateDayOffClinical] = useMutation(
    STAFF_UPDATE_CLINIC_SCHEDULE_WORK_TIME
  )
  const [updateTreatmentResult] = useMutation(UPDATE_CLINIC_TREATMENT_RESULT)
  const [updateBaseInfoMessage] = useMutation(UPDATE_CLINIC_BASE_MESSAGE)
  const downloadFileSubmit = async (key, callback) => {
    if (!key || key.length === 0) return
    try {
      const {
        data: { downloadFileS3: fileURL },
      } = await downloadFile({
        variables: {
          key,
        },
      })
      callback(fileURL)
    } catch (error) {
      callback(null)
    }
  }
  const { loading, error, data, refetch } = useQuery(CLINIC_QUERY, {
    variables: {
      clinicId,
      deletedAt: null,
    },
  })
  const {
    loading: loadingSchedule,
    error: errorSchedule,
    data: dataSchedule,
    refetch: refetchClinicSchedule,
  } = useQuery(GET_CLINIC_SCHEDULE, {
    variables: {
      where: {
        id: clinicId,
      },
      clinicalSchedulesWhere: {
        deletedAt: null,
      },
    },
  })
  const {
    loading: listClinicalLoading,
    error: listClinicalError,
    data: listClinicalData,
    refetch: listClinicDataRefetch,
  } = useQuery(GET_LIST_CLINICAL_DEPARTMENT, {
    variables: {
      where: {
        Clinic: {
          id: clinicId,
        },
        deletedAt: null,
      },
    },
  })
  const { loading:listRegionLoading, error:listRegionError, data:listRegionData } = useQuery(REGION_QUERY, {
    variables: {
      deletedAt: null,
    },
  })

  useEffect(() => {
    refetch()
  })
  useEffect(() => {
    listClinicDataRefetch()
  }, [])
  useEffect(() => {
    if (data && data.clinic && data.clinic.imageKey) {
      downloadFileSubmit(data.clinic.imageKey, fileURL => setImg(fileURL))
    } else {
      downloadFileSubmit(process.env.REACT_APP_IMAGE_DEFAULT, fileURL =>
        setImg(fileURL)
      )
    }
  }, [data && data.clinic && data.clinic.imageKey])
  if (loading || loadingSchedule || listClinicalLoading)
    return <ProgressSpinner />
  if (error || errorSchedule) {
    return <ErrorComponent error={error} />
  }
  if (listClinicalError) return <ErrorComponent error={listClinicalError} />
  let clinicalSchedules = []
  if (
    dataSchedule &&
    dataSchedule.clinic &&
    dataSchedule.clinic.clinicalDepartments &&
    dataSchedule.clinic.clinicalDepartments[0]
  ) {
    clinicalSchedules =
      dataSchedule.clinic.clinicalDepartments[0].clinicalSchedules
    if (clinicalSchedules && clinicalSchedules.length > 0) {
      for (let i = 0; i < clinicalSchedules.length; i += 1) {
        const startDate_UTC = clinicalSchedules[i].startDate
        const endtDate_UTC = clinicalSchedules[i].endDate
        const startTimeMorning_UTC = clinicalSchedules[i].startTimeMorning
          ? clinicalSchedules[i].startTimeMorning.split('Z')[0]
          : null
        const endTimeMorning_UTC = clinicalSchedules[i].endTimeMorning
          ? clinicalSchedules[i].endTimeMorning.split('Z')[0]
          : null
        const startTimeAfternoon_UTC = clinicalSchedules[i].startTimeAfternoon
          ? clinicalSchedules[i].startTimeAfternoon.split('Z')[0]
          : null
        const endTimeAfternoon_UTC = clinicalSchedules[i].endTimeAfternoon
          ? clinicalSchedules[i].endTimeAfternoon.split('Z')[0]
          : null
        const isAvailableMorning = clinicalSchedules[i].isAvailableMorning
        const isAvailableAfternoon = clinicalSchedules[i].isAvailableAfternoon
        clinicalSchedules[i].rangeDay = [startDate_UTC, endtDate_UTC]
        clinicalSchedules[i].rangeTimeMorning = [
          startTimeMorning_UTC,
          endTimeMorning_UTC,
        ]
        clinicalSchedules[i].rangeTimeAfternoon = [
          startTimeAfternoon_UTC,
          endTimeAfternoon_UTC,
        ]
        clinicalSchedules[i].isAvailableMorning = isAvailableMorning
        clinicalSchedules[i].isAvailableAfternoon = isAvailableAfternoon
      }
    }
  }
  const { clinicalDepartments } = listClinicalData
  const handleSubmitPeriodicOpen = async (
    values,
    { setSubmitting, setFieldValue, resetForm }
  ) => {
    const { periodicOpenDay } = values
    try {
      const clinicalSchedules = []
      if (periodicOpenDay.length > 0) {
        for (let i = 0; i < periodicOpenDay.length; i++) {
          if (
            periodicOpenDay[i].rangeDay.includes(null) ||
            periodicOpenDay[i].rangeTimeMorning.includes(null) ||
            periodicOpenDay[i].rangeTimeAfternoon.includes(null)
          ) {
            popup.error('全て入力してください。')
            setSubmitting(false)
            setFieldValue('confirm', false)
            return
          }
          const schedule = {
            startDate:
              `${periodicOpenDay[i].rangeDay[0].split('T')[0]}T` + `00:00:00Z`,
            endDate:
              `${periodicOpenDay[i].rangeDay[1].split('T')[0]}T` + `00:00:00Z`,
            startTimeMorning:
              periodicOpenDay[i].rangeTimeMorning[0].split('+')[0] + 'Z',
            endTimeMorning:
              periodicOpenDay[i].rangeTimeMorning[1].split('+')[0] + 'Z',
            startTimeAfternoon:
              periodicOpenDay[i].rangeTimeAfternoon[0].split('+')[0] + 'Z',
            endTimeAfternoon:
              periodicOpenDay[i].rangeTimeAfternoon[1].split('+')[0] + 'Z',
            isAvailableMorning: periodicOpenDay[i].isAvailableMorning,
            isAvailableAfternoon: periodicOpenDay[i].isAvailableAfternoon,
          }
          clinicalSchedules.push(schedule)
        }
      }
      await updateDayOffClinical({
        variables: {
          clinicId,
          clinicalSchedules,
        },
      })
      popup.success(i18n.t('staff.doctorDetail.submissionSuccess'))
      setSubmitting(false)
      closeModal()
      // refetch()
      // refetchClinicSchedule()
      resetForm()
    } catch (err) {
      popup.error(err)
      setSubmitting(false)
      setFieldValue('confirm', false)
    }
  }
  const handleSubmitOpenTime = async (
    { clinicalDepartments: clinicalDepartmentsValue, noteOpenTime },
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    const clinicalDepartmentValueSubmit = []
    clinicalDepartmentsValue.map(item => {
      clinicalDepartmentValueSubmit.push({ ...item })
    })
    for (let i = 0; i < clinicalDepartmentValueSubmit.length; i += 1) {
      delete clinicalDepartmentValueSubmit[i].clinicName
      delete clinicalDepartmentValueSubmit[i].name
      delete clinicalDepartmentValueSubmit[i].clinic
      delete clinicalDepartmentValueSubmit[i].__typename
    }

    try {
      await updateCLinicDepartmentDailyWorkTime({
        variables: {
          clinicalDepartments: clinicalDepartmentValueSubmit,
          noteOpenTime,
        },
      })
      popup.success(i18n.t('staff.updateClinic.submissionSuccess'))
      setSubmitting(false)
      resetForm()
      closeModal()
    } catch (e) {
      popup.error(e)
      setFieldValue('confirm', false)
      setSubmitting(false)
    }
  }
  const handleSubmitTreatmentResult = async (
    {
      numberPatient,
      maleAgeGroup,
      femaleAgeGroup,
      kindOfDisease,
      vaccine,
      referHospital,
    },
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      await updateTreatmentResult({
        variables: {
          clinicId,
          numberPatient: numberPatient ? parseInt(numberPatient, 10) : null,
          maleAgeFrom: maleAgeGroup[0]
            ? parseInt(maleAgeGroup[0].value, 10)
            : null,
          maleAgeTo: maleAgeGroup[1]
            ? parseInt(maleAgeGroup[1].value, 10)
            : null,
          femaleAgeFrom: femaleAgeGroup[0]
            ? parseInt(femaleAgeGroup[0].value, 10)
            : null,
          femaleAgeTo: femaleAgeGroup[1]
            ? parseInt(femaleAgeGroup[1].value, 10)
            : null,
          kindOfDisease,
          vaccine,
          referHospital,
        },
      })
      popup.success(i18n.t('staff.updateClinic.submissionSuccess'))
      setSubmitting(false)
      resetForm()
      closeModal()
    } catch (error) {
      popup.error(error)
      setFieldValue('confirm', false)
      setSubmitting(false)
    }
  }

  const handleBaseInfoMesSubmit = async (
    { messageIntroduce },
    { setSubmitting, setFieldValue, resetForm }
  ) => {
    try {
      await updateBaseInfoMessage({
        variables: {
          clinicId,
          messageIntroduce,
        },
      })
      popup.success(i18n.t('staff.updateClinic.submissionSuccess'))
      setSubmitting(false)
      resetForm()
      closeModal()
    } catch (error) {
      popup.error(error)
      setFieldValue('confirm', false)
      setSubmitting(false)
    }
  }

  const uploadFileSubmit = async ({ file }, { setSubmitting, resetForm }) => {
    try {
      await updateClinicImage({
        variables: {
          clinicId,
          imageKey: file,
        },
      })
      popup.success(i18n.t('doctor.uploadFile.submissionSuccess'))
      setSubmitting(false)
      resetForm()
      setLoadingImg(true)
      setIsUploadClinicImage(false)
    } catch (error) {
      popup.error(i18n.t('doctor.uploadFile.submissionError'))
      setSubmitting(false)
    }
  }

  return (
    <div>
      <TabView>
        <TabPanel header={i18n.t('staff.editClinic.clinicDetail')}>
          <ClinicForm
            clinic={data.clinic || {}}
            submitLabel={i18n.t('staff.updateClinic.submit')}
            confirmationMessage={i18n.t(
              'staff.updateClinic.submitConfirmation'
            )}
            departments={data.departments || []}
            parentPath={parentPath}
            update
            disabled
            region={listRegionData?.regions}
          />
          <div className='modal-bottom'>
            <Link
              to={
                parentPath.pathname +
                Router.get(Router.staffClinicUpdate, {
                  clinicId,
                })
              }
            >
              <Button
                label={i18n.t('staff.editClinic.submit')}
                icon='pi pi-pencil'
                className='p-button-secondary'
              />
            </Link>
          </div>
        </TabPanel>
        <TabPanel header={i18n.t('staff.editClinic.openTime')}>
          <TimePerDayForm
            i18n={i18n}
            clinic={data.clinic || {}}
            onSubmit={handleSubmitOpenTime}
            clinicalDepartments={adDefaultClinicalDepartmentTime(
              addClinicalDepartmentName(clinicalDepartments)
            )}
          />
        </TabPanel>
        <TabPanel header={i18n.t('staff.editClinic.specialOpenTime')}>
          <PeriodicOpenTabPanel
            refetchClinicSchedule={refetchClinicSchedule}
            i18n={i18n}
            onSubmit={handleSubmitPeriodicOpen}
            clinicalSchedules={clinicalSchedules}
            confirmationMessage={i18n.t(
              'staff.availableShift.updateConfirmation'
            )}
          />
        </TabPanel>
        <TabPanel header={i18n.t('staff.editClinic.treatmentResult')}>
          <TreatmentResult
            refetchClinicSchedule={refetchClinicSchedule}
            submitLabel={i18n.t('staff.treatmentResult.buttonLabel')}
            i18n={i18n}
            clinic={data.clinic || {}}
            onSubmit={handleSubmitTreatmentResult}
            clinicalSchedules={clinicalSchedules}
            confirmationMessage={i18n.t(
              'staff.availableShift.updateConfirmation'
            )}
          />
        </TabPanel>
        <TabPanel header={i18n.t('staff.editClinic.guidanceMessage')}>
          <GuidanceMessage
            i18n={i18n}
            onSubmit={handleBaseInfoMesSubmit}
            clinic={data.clinic || {}}
          />
        </TabPanel>
        <TabPanel header='クリニック画像'>
          <div className='modal-wrapper'>
            {!isUploadClinicImage && (
              <>
                <div className='tr'>
                  <div className='th'>クリニック画像</div>
                  <div className='td'>
                    {loadingImg ? <ProgressSpinner /> : <></>}
                    {!data.clinic.imageKey ? (
                      'クリニック画像はありません。'
                    ) : (
                      <img
                        alt='a'
                        className='chat-image-content'
                        src={img}
                        style={{
                          display: loadingImg ? 'none' : 'unset',
                          width: '500px',
                        }}
                        onLoad={() => setLoadingImg(false)}
                      />
                    )}
                  </div>
                </div>
                <div className='modal-bottom'>
                  <Button
                    className='reflect-button'
                    label='画像アップロード'
                    onClick={() => {
                      setIsUploadClinicImage(true)
                    }}
                  />
                </div>
              </>
            )}
            {isUploadClinicImage && (
              <>
                <div style={{ padding: '10px 0', fontSize: '18px' }}>
                  <p>クリニック画像</p>
                </div>
                <UploadImageForm
                  submitLabel={i18n.t('doctor.uploadFile.upload')}
                  uploadFile={uploadFileSubmit}
                  hideTitle
                  confirmationMessage={i18n.t(
                    'doctor.uploadFile.submitConfirmation'
                  )}
                  popup={popup}
                />
              </>
            )}
          </div>
        </TabPanel>
      </TabView>
    </div>
  )
}

export default ClinicDetail
