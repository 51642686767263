export default {
  table: {
    width: '100%',
    marginBottom: '20px',
  },
  bottom: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
  },
  button: {
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  spCartContainer: {
    border: '1px solid #b2b6b8',
    padding: '10px',
    marginBottom: '5px',
  },
  removeButton: {
    textAlign: 'right',
    marginBottom: '5px',
  },

  mobileRequestItem: {
    fontSize: '13px',
    padding: '2px 0',
  },

  mobileRequestItemContent: {
    paddingLeft: '10px',
    fontWeight: 'bold',
  },
}
