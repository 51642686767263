import { useMutation } from '@apollo/react-hooks'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import { ACCEPT_FILE_EXTENSIONS, MAX_FILE_SIZE } from '@medical/constant'
import { Formik } from 'formik'
import { Button } from 'primereact/button'
import React, { useState } from 'react'
import Dropzone from 'react-dropzone'
import FileIcon, { defaultStyles } from 'react-file-icon'
import * as Yup from 'yup'
import { GET_POST_PRE_SIGNED_URL } from '../UploadImage/UploadImage.graphql'
import { ProgressSpinner } from '@medical/components'
import { useCustom } from '@medical/provider/context'
import uploadImage from '@medical/libs/uploadImage'

const UploadFileComponent = ({
  submitLabel,
  uploadFile,
  confirmationMessage,
  hideTitle,
}) => {
  const [{ i18n, popup }] = useCustom()
  const [loading, setLoading] = useState(false)
  const [filename, setFilename] = useState()
  const [getPostPreSignedUrl] = useMutation(GET_POST_PRE_SIGNED_URL)
  return (
    <Formik
      initialValues={{
        fileName: '',
        file: '',
        mimetype: '',
        confirm: false,
      }}
      onSubmit={uploadFile}
      validationSchema={Yup.object().shape({
        file: Yup.mixed().required(),
      })}
      render={formikProps => {
        const { setFieldValue, isValid } = formikProps
        return (
          <React.Fragment>
            {!hideTitle && (
              <div className='modal-title'>{i18n.t('main.uploadFile')}</div>
            )}
            {loading ? (
              <ProgressSpinner />
            ) : (
              <>
                <Dropzone
                  onDrop={async files => {
                    if (files.length > 0) {
                      setLoading(true)
                      const {
                        data: postPreSignedUrl,
                      } = await getPostPreSignedUrl({
                        variables: {
                          fileName: files[0].name,
                          mimetype: files[0].type,
                        },
                      })
                      const key = await uploadImage(
                        i18n,
                        popup,
                        postPreSignedUrl,
                        files[0]
                      )
                      if (key) {
                        setFieldValue('file', key)
                        setFieldValue('filename', files[0].name)
                        setFieldValue('mimetype', files[0].type)
                        setFilename(files[0].name)
                      }
                      setLoading(false)
                    }
                  }}
                  maxSize={MAX_FILE_SIZE}
                  multiple={false}
                  accept={['image/jpeg', 'image/png', 'application/pdf']}
                >
                  {({ getRootProps, getInputProps }) => {
                    return (
                      <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <p>{i18n.t('doctor.uploadFile.dropFileHere')}</p>
                        {filename && (
                          <>
                            <FileIcon
                              extension={filename.split('.').pop()}
                              {...defaultStyles[filename.split('.').pop()]}
                            />
                            {filename}
                          </>
                        )}
                      </div>
                    )
                  }}
                </Dropzone>
                <div className='modal-bottom'>
                  <Button
                    type='submit'
                    onClick={() => {
                      setFieldValue('confirm', true)
                    }}
                    disabled={!isValid}
                    label={submitLabel}
                  />
                </div>
              </>
            )}
            <ConfirmationModal
              {...formikProps}
              confirmationMessage={confirmationMessage}
            />
          </React.Fragment>
        )
      }}
    />
  )
}

export default UploadFileComponent
