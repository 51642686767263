import React from 'react'
import TableRowData from './TableRowData'

export const departmentNames = {
  INTERNAL_MEDICINE: '内科',
  CHILDREN_MEDICINE: '小児科',
  CHILDREN_VACCINE_MEDICINE: '小児科ワクチン専任(対象：小児～成人)',
  INTERNAL_VACCINE_MEDICINE: '内科ワクチン専任(対象：小児～成人)',
}

const TableDataBody = ({ setFieldValue, values, errors, isPermittedEdit }) => {
  const sortedData = values.data.sort(function(a, b) {
    const orderMap = {
      INTERNAL_MEDICINE: 1,
      CHILDREN_MEDICINE: 2,
      CHILDREN_VACCINE_MEDICINE: 3,
      INTERNAL_VACCINE_MEDICINE: 4,
    }
    const positionA = a.clinic.Region?.position ?? Infinity;
    const positionB = b.clinic.Region?.position ?? Infinity;
    
    if (positionA !== positionB) {
      return positionA - positionB;
    }
    const orderA = orderMap[a.clinicalDepartment.name] 
    const orderB = orderMap[b.clinicalDepartment.name]
    return a.clinic.order - b.clinic.order || orderA - orderB
  })
  const clinicColorMap = {}
  let clinicIndex = 0
  return (
    <tbody>
      {sortedData?.length > 0
        ? sortedData?.map((item, index) => {
          if (!clinicColorMap[item.clinic.id]) {
            clinicColorMap[item.clinic.id] =
              clinicIndex % 2 === 0 ? '#EAD9FF' : '#ffebeb'
            clinicIndex++
          }
          const backgroundColorClinic = clinicColorMap[item.clinic.id]
            const backgroundColor = index % 2 === 0 ? '#F7F7F7' : '#FEFEFE'
            return (
              <TableRowData
                key={index}
                index={index}
                item={item}
                backgroundColor={backgroundColor}
                setFieldValue={setFieldValue}
                isError={errors?.data?.[index]}
                isPermittedEdit={isPermittedEdit}
                backgroundColorClinic={backgroundColorClinic}
              />
            )
          })
        : null}
    </tbody>
  )
}

export default React.memo(TableDataBody)
