import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import gql from 'graphql-tag'

export const DOCTOR_FRAGMENTS = gql`
  fragment DOCTOR_FRAGMENTS on Doctor {
    doctors(where: $where) {
      id
      email
      lastname
      firstname
      departments
      nearestStations
      phoneNumber
      homeAddress {
        id
        address1
        address2
        stateOrRegion
      }
    }
  }
`
export const GET_LIST_MOVING_EXPENSES_GQL = gql`
  query DOCTORS_CONNECTION(
    $where: DoctorWhereInput
    $first: Int
    $skip: Int
    $orderBy: DoctorOrderByInput
  ) {
    doctorsConnection(
      where: $where
      first: $first
      skip: $skip
      orderBy: $orderBy
    ) {
      edges {
        node {
          id
          email
          lastname
          firstname
          departments
          nearestStations
          phoneNumber
          homeAddress {
            id
            address1
            address2
            stateOrRegion
          }
        }
      }
      aggregate(where: $where) {
        count
      }
    }
    ...DEPARTMENTS_FRAGMENT
  }
  ${DEPARTMENTS_FRAGMENT}
`
export const GET_LIST_MOVING_EXPENSES = gql`
  query Query($where: DoctorWhereInput) {
    doctors(where: $where) {
      id
      email
      lastname
      firstname
      departments
      nearestStations
      phoneNumber
      homeAddress {
        id
        address1
        address2
        stateOrRegion
      }
    }
    ...DEPARTMENTS_FRAGMENT
  }
  ${DEPARTMENTS_FRAGMENT}
`
export const UPDATE_TYPE_TRANSPORTATION_WORKSCHEDULE = gql`
  mutation Mutation(
    $data: WorkScheduleUpdateInput!
    $where: WorkScheduleWhereUniqueInput!
  ) {
    updateWorkSchedule(data: $data, where: $where) {
      id
    }
  }
`
