
import gql from 'graphql-tag'

export const UPLOAD_SHIFTS = gql`
  mutation UPLOAD_SHIFTS(
    $HourlyWageCsvRecords: [DefinitionDefaultHourlyWageInput!]!
  ) {
    staffUploadDefaultHourlyWage(
      HourlyWageCsvRecords: $HourlyWageCsvRecords
    )
  }
`

export const CHECK_UPLOAD_SHIFTS = gql`
  mutation CHECK_UPLOAD_SHIFTS(
    $HourlyWageCsvRecords: [DefinitionDefaultHourlyWageInput!]!
  ) {
    staffCheckDefaultHourlyWageCsv(
      HourlyWageCsvRecords: $HourlyWageCsvRecords
    ){
      departmentCode
      startTime
      endTime
      startDate
      endDate
      isWeekday
      isAvailableOnMonday
      isAvailableOnTuesday
      isAvailableOnWednesday
      isAvailableOnThursday
      isAvailableOnFriday
      isAvailableOnSaturday
      isAvailableOnSunday
      isAvailableOnHoliday
      hourlyWageDefault
      isClassiNew
      isClassiExist
      errorMessage
      isDelete
    }
  }
`
