import Router from '@medical/routes/router'
import moment from 'moment'
import queryString from 'query-string'
import React from 'react'
import { Link } from 'react-router-dom'

const getVariant = percentage => {
  if (percentage < 100) {
    return 'danger'
  }
  return 'success'
}

const StatusBar = ({
  date,
  clinicalDepartmentId,
  acceptedHours,
  availableHours,
  notRequestedAvailableShiftCount,
}) => {
  const percentage = availableHours
    ? Math.floor((acceptedHours / availableHours) * 100)
    : 0
  // const percentage = Math.floor(Math.random() * 100)
  const variant = getVariant(percentage)
  const requiredHours = availableHours - acceptedHours
  const momentDate = moment(date)
  if (availableHours === 0 || requiredHours === 0)
    return (
      <Link
        to={{
          pathname: Router.get(Router.staffDailyAvailableShifts, {
            year: momentDate.year(),
            month: momentDate.month() + 1,
            day: momentDate.date(),
          }),
          search: queryString.stringify({
            clinicalDepartmentIds: [clinicalDepartmentId],
          }),
        }}
        style={{ color: '#000' }}
      >
        <div className='progress-container'>
          <i className='pi pi-ban' style={{ color: '#eee' }} />
        </div>
      </Link>
    )
  // if (requiredHours === 0)
  //   return (
  //     <div className="progress-container">
  //       <i className="pi pi-check" style={{ color: 'green' }} />
  //     </div>
  //   )

  return (
    <Link
      to={{
        pathname: Router.get(Router.staffDailyAvailableShifts, {
          year: momentDate.year(),
          month: momentDate.month() + 1,
          day: momentDate.date(),
        }),
        search: queryString.stringify({
          clinicalDepartmentIds: [clinicalDepartmentId],
        }),
      }}
      style={{ color: '#000' }}
    >
      <div
        className={
          notRequestedAvailableShiftCount < 1
            ? 'progress-container'
            : 'progress-container is-got'
        }
      >
        <div className='progress-wrapper'>
          <div className={`progress-bar ${variant}`}>
            <div
              className={`progress-content ${variant}`}
              style={{ width: percentage < 100 ? `${percentage}%` : `100%` }}
            />
          </div>
          <span style={{ fontSize: '12px' }}>
            {parseFloat(requiredHours.toFixed(2))} (
            {parseFloat(notRequestedAvailableShiftCount.toFixed(2))})
          </span>
        </div>
      </div>
    </Link>
  )
}

export default StatusBar
