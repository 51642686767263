import ConfirmationModal from '@medical/components/ConfirmationModal'
import TextInput from '@medical/components/TextInput'
import { Formik } from 'formik'
import { Button } from 'primereact/button'
import React from 'react'

const digDeeper = (errors, message = []) => {
  Object.keys(errors).forEach(key => {
    if (typeof errors[key] === 'string') {
      message.push(<div className='alert'>{errors[key]}</div>)
    } else {
      digDeeper(errors[key], message)
    }
  })
}

const DoctorResendConfirmationEmailForm = ({ i18n, doctor, onSubmit }) => (
  <Formik
    enableReinitialize
    initialValues={{
      confirm: false,
      email: doctor.email,
    }}
    onSubmit={onSubmit}
    render={formikProps => {
      const {
        errors,
        setTouched,
        setFieldValue,
        validateForm,
        values,
      } = formikProps
      const errorMessage = []
      digDeeper(errors, errorMessage)
      return (
        <React.Fragment>
          <TextInput
            {...formikProps}
            label={i18n.t('doctor.uploadStatus.mailAddress')}
            disabled
            name='email'
          />
          <div className='modal-bottom'>
            <Button
              type='submit'
              label={i18n.t('resendConfirmationEmail.submit')}
              onClick={async () => {
                await setFieldValue('isSendEmail', false)
                validateForm().then(response => {
                  if (!Object.keys(response).length) {
                    setFieldValue('confirm', true)
                  } else {
                    setTouched(response)
                  }
                })
              }}
            />
          </div>
          <ConfirmationModal
            {...formikProps}
            confirmationMessage={`${i18n.t(
              'resendConfirmationEmail.submitConfirmation'
            )}: ${values.email}`}
          />
        </React.Fragment>
      )
    }}
  />
)

export default DoctorResendConfirmationEmailForm
