import BreadCrumb from '@medical/components/Breadcrumb'
import ModalLink from '@medical/components/ModalLink'
import Router from '@medical/routes/router'
import { Button } from 'primereact/button'
import React from 'react'

const DoctorClinicScene = ({ i18n, edges, departmentNames }) => (
  <div
    // className='doctor-layout-body'
    style={{ width: '70%', margin: '0px auto' }}
  >
    <div className='doctor-header'>
      <div className='staff-title'>
        {i18n.t('doctor.menuBar.clinicList')}
        <BreadCrumb items={[{ title: i18n.t('doctor.menuBar.clinicList') }]} />
      </div>
    </div>
    <div className='container is-medium box'>
      <table className='table'>
        <thead>
          <tr>
            <th width='15%'>{i18n.t('doctorClinic.name')}</th>
            <th width='20%'>{i18n.t('doctorClinic.department')}</th>
            <th width='25%'>{i18n.t('doctorClinic.address')}</th>
            <th width='10%'>{i18n.t('doctorClinic.treatmentResult')}</th>
          </tr>
        </thead>
        <tbody>
          {edges.map(({ node }) => (
            <tr key={node.id} style={{ textAlign: 'center' }}>
              <td>{node.name}</td>
              <td>
                <div className='flex-parent'>
                  {node.clinicalDepartments
                    .filter(department => department.isDisplay)
                    .map(department => (
                      <div className='slashed-item' key={department.id}>
                        {departmentNames[department.name]}
                      </div>
                    ))}
                </div>
              </td>
              <td>{node.address}</td>
              <td>
                <ModalLink
                  to={Router.get(Router.doctorClinicDetail, {
                    id: node.id,
                  })}
                >
                  <Button icon='pi pi-info' />
                </ModalLink>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
)
export default DoctorClinicScene
