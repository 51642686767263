import BreadCrumb from '@medical/components/Breadcrumb'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import ModalLink from '@medical/components/ModalLink'
import { waitFor } from '@medical/libs'
import numeralCurrency from '@medical/libs/numeralCurrency'
import Auth from '@medical/middleware/auth'
import Router from '@medical/routes/router'
import { DatePicker } from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import Axios from 'axios'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { Paginator } from 'primereact/paginator'
import queryString from 'query-string'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  removeProgress,
  setProgressBar,
} from '@medical/provider/store/reducers/progress/progress.action'
import { connect } from 'react-redux'
import { useContext } from 'react'
import { SocketContext } from '@medical/provider/socket'
import { useEffect } from 'react'
import { DOWNLOAD_CSV_MOVING_EXPENSES_REGISTRATION } from '@medical/constant'

const ListRegistrationScene = ({
  isUpdateMovingExpenses,
  i18n,
  ListRegistration,
  Clinic,
  onSubmit,
  rowsPerPage,
  fullname,
  startTimeGte,
  endTimeLte,
  clinicname,
  page,
  onPageChange,
  count,
  arrIndexSpan,
  setProgress,
  removeProgress,
}) => {
  const socket = useContext(SocketContext)

  const getClinicFilter = name => {
    let res
    if (Clinic.clinics && Clinic.clinics.length > 0) {
      Clinic.clinics.map(as => {
        if (as.name === name) {
          res = as
        }
        return res
      })
    }
  }

  const [clinicFilter, setClinicFilter] = useState(
    getClinicFilter(clinicname) || ''
  )
  const [nameFilter, setNameFilter] = useState(fullname || '')
  const [date, setDate] = useState([startTimeGte, endTimeLte])
  const getTypeRows = isHistory =>
    isHistory ? 'background-color-rows-duplicate' : ''

  const isSearch = () => {
    if (fullname || startTimeGte || endTimeLte || clinicname) {
      return true
    }
    return false
  }

  const endTime = (clinic, startTime, values, doctor) => {
    const data = values.filter(
      item => item.node.clinic.id === clinic && item.node.doctor.id === doctor
    )
    for (let i = 0; i < data.length; i += 1) {
      if (
        moment(data[i].node.startTime)
          .startOf('days')
          .valueOf() >
        moment(startTime)
          .startOf('days')
          .valueOf()
      ) {
        return moment.utc(data[i].node.startTime).subtract(1, 'days')
      }
    }
  }

  useEffect(() => {
    if (socket) {
      socket.on(DOWNLOAD_CSV_MOVING_EXPENSES_REGISTRATION, data => {
        if (data?.percent) {
          setProgress({
            progress: DOWNLOAD_CSV_MOVING_EXPENSES_REGISTRATION,
            label: `${i18n.t('staff.movingExpensesDetail.title')}中`,
            percent: Number(data.percent),
          })
        }
      })
    }
  }, [socket])

  return (
    <div
      style={{
        padding: '10px',
        height: '100%',
      }}
    >
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('staff.movingExpensesDetail.title')}
          <BreadCrumb
            items={[{ title: i18n.t('main.movingExpenses.title') }]}
          />
        </div>
        <div className='staff-buttons'>
          <Button
            icon='pi pi-download'
            className='p-button-normal'
            label='CSVダウンロード'
            onClick={async () => {
              setProgress({
                progress: DOWNLOAD_CSV_MOVING_EXPENSES_REGISTRATION,
                label: `${i18n.t('staff.movingExpensesDetail.title')}中`,
                percent: 0,
              })
              const token = Auth.getToken()
              try {
                const url = `${process.env.REACT_APP_ENDPOINT}/downloadRouteRegistrationListCsv?token=${token}`
                const { status, data: urlS3 } = await Axios({
                  method: 'GET',
                  url,
                })
                await waitFor(3000)
                if (status === 204) {
                  setProgress({
                    progress: DOWNLOAD_CSV_MOVING_EXPENSES_REGISTRATION,
                    label: `${i18n.t('staff.movingExpensesDetail.title')}中`,
                    msg: i18n.t('main.msgDownloadCSVStatusError'),
                  })
                  await waitFor(3000)
                } else {
                  setProgress({
                    progress: DOWNLOAD_CSV_MOVING_EXPENSES_REGISTRATION,
                    percent: 100,
                    url: urlS3,
                  })
                }
              } catch (err) {
                setProgress({
                  progress: DOWNLOAD_CSV_MOVING_EXPENSES_REGISTRATION,
                  label: `${i18n.t('staff.movingExpensesDetail.title')}中`,
                  msg: i18n.t('main.msgDownloadCSVStatusError'),
                })
                await waitFor(3000)
              }
              removeProgress(DOWNLOAD_CSV_MOVING_EXPENSES_REGISTRATION)
            }}
          />
          <ModalLink
            to={Router.get(Router.staffDoctorsMovingExpensesRegistrationCreate)}
          >
            <Button
              icon='pi pi-plus-circle'
              className='p-button-normal'
              label={i18n.t('staff.movingExpensesDetail.titleCreate')}
            />
          </ModalLink>
        </div>
      </div>
      <div
        className='box'
        style={{
          backgroundColor: '#ffffff',
          marginLeft: '20px',
          marginRight: '20px',
          padding: '20px',
          borderRadius: '10px',
        }}
      >
        <div className='search-parent'>
          <InputText
            value={nameFilter}
            name='fullname'
            placeholder={i18n.t('main.fullname')}
            onChange={e => {
              setNameFilter(e.target.value)
            }}
            style={{ width: '170px', marginRight: '20px' }}
          />
          <Dropdown
            value={clinicFilter}
            name='clinicValue'
            optionLabel='name'
            valueLabel='id'
            options={Clinic ? Clinic.clinics : []}
            placeholder={i18n.t('main.movingExpensesRegistration.clinic')}
            onChange={e => {
              setClinicFilter(e.value)
            }}
            style={{ width: '50px', marginRight: '20px' }}
          />

          <div className='search-time'>
            <DatePicker.RangePicker
              value={[
                date[0] ? moment(new Date(date[0]), 'YYYY年MM月DD日') : null,
                date[1] ? moment(new Date(date[1]), 'YYYY年MM月DD日') : null,
              ]}
              // disabledDate={disabledDate}
              placeholder={['開始日', '終了日']}
              format='YYYY年MM月DD日'
              locale={locale}
              allowClear={false}
              onChange={value => {
                setDate([
                  moment(value[0])
                    .startOf('day')
                    .toISOString(),
                  moment(value[1])
                    .endOf('day')
                    .toISOString(),
                ])
              }}
            />
          </div>
          <Link
            to={{
              pathname: Router.staffDoctorsMovingExpensesRegistration,
              search: queryString.stringify({
                rowsPerPage,
                fullname: nameFilter,
                clinicname: clinicFilter.name,
                startTimeGte: date[0],
                endTimeLte: date[1],
              }),
            }}
            style={{ marginLeft: '20px' }}
          >
            <Button
              label={i18n.t('main.movingExpenses.search')}
              icon='pi pi-search'
            />
          </Link>
          {isSearch() ? (
            <Link
              to={{
                pathname: Router.staffDoctorsMovingExpensesRegistration,
                search: queryString.stringify({
                  rowsPerPage,
                }),
              }}
            >
              <Button
                icon='pi pi-times'
                className='p-button-secondary'
                style={{ marginLeft: '20px' }}
              />
            </Link>
          ) : null}
        </div>
        <div>
          <div>
            <table className='table-moving-expenses'>
              <thead>
                <tr>
                  <th width='18%'>
                    {i18n.t('main.movingExpensesRegistration.fullname')}
                  </th>
                  <th width='15%'>
                    {i18n.t('main.movingExpensesRegistration.clinic')}
                  </th>
                  <th width='20%'>
                    {i18n.t('main.movingExpensesRegistration.date')}
                  </th>
                  <th width='20%'>
                    {i18n.t('staff.movingExpensesDetail.startTime')}
                  </th>
                  <th width='10%'>
                    {i18n.t('main.movingExpensesRegistration.cost')}
                  </th>
                  <th width='10%'>
                    {i18n.t('main.movingExpensesRegistration.cost')}
                    {i18n.t('staff.movingExpensesDetail.past')}
                  </th>
                  <th width='10%'>
                    {i18n.t('staff.movingExpensesDetail.typeData')}
                  </th>
                  {isUpdateMovingExpenses ? (
                    <th width='15%'>
                      {i18n.t('main.movingExpensesRegistration.detail')}
                    </th>
                  ) : null}
                </tr>
              </thead>
              <Formik
                initialValues={{
                  confirm: false,
                  transportationsId: [],
                }}
                onSubmit={onSubmit}
                render={formikProps => {
                  const { setFieldValue } = formikProps
                  return (
                    <tbody>
                      {ListRegistration && ListRegistration.length > 0
                        ? ListRegistration.map((as, index) => {
                            const endtime = as.node.endTime
                              ? as.node.endTime
                              : endTime(
                                  as.node.clinic.id,
                                  as.node.startTime,
                                  ListRegistration,
                                  as.node.doctor.id
                                )
                            let isPast = false
                            const startTimeAddMonth = moment
                              .utc(as.node.startTime)
                              .startOf('months')
                              .add(1, 'months')
                              .add(10, 'days')
                              .valueOf()
                            const endTimeAddMonth = moment
                              .utc(endtime)
                              .startOf('months')
                              .add(1, 'months')
                              .add(9, 'days')
                              .valueOf()
                            const currentTime = moment.utc().valueOf()
                            if (
                              endTimeAddMonth < currentTime &&
                              startTimeAddMonth < currentTime
                            ) {
                              isPast = true
                            }
                            return (
                              <tr>
                                <td>{`${as.node.doctor.lastname} ${as.node.doctor.firstname}`}</td>
                                <td
                                  className={getTypeRows(
                                    as.node.isHistory || isPast
                                  )}
                                >
                                  {as.node.clinic.name}
                                </td>
                                <td
                                  className={getTypeRows(
                                    as.node.isHistory || isPast
                                  )}
                                >
                                  {moment(as.node.createdAt).format('LL (dd)')}
                                </td>
                                <td
                                  className={getTypeRows(
                                    as.node.isHistory || isPast
                                  )}
                                >
                                  {moment(as.node.startTime).format('LL (dd)')}
                                </td>
                                <td
                                  className={getTypeRows(
                                    as.node.isHistory || isPast
                                  )}
                                >
                                  {`￥${
                                    as.node.cost ? as.node.cost : 0
                                  }`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </td>

                                <td
                                  className={getTypeRows(
                                    as.node.isHistory || isPast
                                  )}
                                >
                                  {numeralCurrency(
                                    as.node.returnPath && as.node.returnPath[2]
                                      ? parseInt(as.node.returnPath[2])
                                      : as.node.cost
                                  )}
                                </td>
                                <td
                                  className={getTypeRows(
                                    as.node.isHistory || isPast
                                  )}
                                >
                                  {as.node.dataDivision === true
                                    ? '自動'
                                    : '手動'}
                                </td>
                                {isUpdateMovingExpenses ? (
                                  <td
                                    className={getTypeRows(
                                      as.node.isHistory || isPast
                                    )}
                                  >
                                    {as.node.isHistory || isPast ? (
                                      <Button
                                        icon='pi pi-trash'
                                        onClick={() => {
                                          setFieldValue('confirm', true)
                                          setFieldValue('transportationsId', [
                                            as.node.id,
                                          ])
                                        }}
                                      />
                                    ) : (
                                      <ModalLink
                                        to={Router.get(
                                          Router.staffDoctorsMovingExpensesRegistrationDetail,
                                          { transportationId: as.node.id }
                                        )}
                                        className={
                                          Router.staffDoctorsMovingExpensesRegistrationDetail
                                        }
                                      >
                                        <Button icon='pi pi-pencil' />
                                      </ModalLink>
                                    )}
                                  </td>
                                ) : null}
                              </tr>
                            )
                          })
                        : null}
                      <ConfirmationModal
                        {...formikProps}
                        confirmationMessage={i18n.t(
                          'main.movingExpensesRegistration.confirmationMessageDelete'
                        )}
                      />
                    </tbody>
                  )
                }}
              />
            </table>
            <Paginator
              rows={rowsPerPage}
              totalRecords={count}
              first={(page - 1) * rowsPerPage}
              onPageChange={onPageChange}
              leftContent={
                <div className='paginator-total'>
                  {i18n.t('main.paginationTotal')}: {count}
                </div>
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  progress: state.progressBarStore.progress,
})

const mapDispatchToProps = dispatch => ({
  setProgress: progress => dispatch(setProgressBar(progress)),
  removeProgress: progress => dispatch(removeProgress(progress)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListRegistrationScene)
