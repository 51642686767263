import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { STAFF_UPDATE_CLINIC } from '@medical/constant/permissions'
import { checkStaffPermission, clinicalDepartmentNames } from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import React, { useEffect } from 'react'

import { CLINICS_CONNECTION } from './ClinicsList.graphql'
import ClinicListScene from './ClinicsListScene'

const ClinicsList = () => {
  const [{ i18n }] = useCustom()
  const isUpdatePermitted = checkStaffPermission({
    functionName: STAFF_UPDATE_CLINIC,
  })
  const { loading, error, data, refetch } = useQuery(CLINICS_CONNECTION)
  // useEffect(() => {
  //   refetch()
  // }, [])
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const {
    clinicsConnection: {
      edges,
      aggregate: { count },
    },
    departments,
  } = data

  const departmentNames = clinicalDepartmentNames({ departments })

  return (
    <ClinicListScene
      i18n={i18n}
      edges={edges}
      count={count}
      isUpdatePermitted={isUpdatePermitted}
      departmentNames={departmentNames}
    />
  )
}

export default ClinicsList
