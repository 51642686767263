import BreadCrumb from '@medical/components/Breadcrumb'
import { FieldArray, Formik } from 'formik'
import moment from 'moment'
import Papa from 'papaparse'
import { Button } from 'primereact/button'
import React from 'react'
import Dropzone from 'react-dropzone'
import * as Yup from 'yup'

const DoctorCsvUpdateScene = ({
  popup,
  i18n,
  onSubmit,
  downloadSample,
  isUploaded,
  checkCsvData,
  isUploadPermitted,
}) => {
  const translateKeys = {
    姓: 'lastname',
    メールアドレス: 'email',
    名: 'firstname',
    セイ: 'lastnameKana',
    メイ: 'firstnameKana',
    電話番号: 'phoneNumber',
    誕生日: 'birthDay',
    性別: 'gender',
    診療科目: 'departments',
    医籍登録番号: 'medicalLicenseNumber',
    現在の主な勤務先名: 'placeOfWork',
    勤務形態: 'workPattern',
    電子カルテID: 'medicalRecord',
    ORCAID: 'ORCAID',
    患者アンケートID: 'questionnaire',
    入職経緯備考: 'joinBackgroundOther',
    入職経緯: 'joinBackground',
  }

  const validationSchema = Yup.object().shape({
    data: Yup.array()
      .min(1, i18n.t('validation.atLeastOneItemDoctor'))
      .of(
        Yup.object().shape({
          // Email
          医師No: Yup.string().required('医師Noは必要です。'),
          メールアドレス: Yup.string()
            .email(i18n.t('validation.email.email'))
            .required(i18n.t('validation.email.required')),
          銀行コード: Yup.string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(
              /^[0-9]+$/,
              '銀行コードは半角数字4文字以内で入力してください'
            )
            .max(4, '銀行コードは半角数字4文字以内で入力してください'),
          支店コード: Yup.string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(
              /^[0-9]+$/,
              '支店コードは半角数字3文字以内で入力してください'
            )
            .max(3, '支店コードは半角数字3文字以内で入力してください'),
          口座番号: Yup.string()
            .transform((v, o) => (o === '' ? null : v))
            .nullable()
            .matches(
              /^[0-9]+$/,
              '口座番号は半角数字8文字以内で入力してください'
            )
            .max(8, '口座番号は半角数字8文字以内で入力してください'),
        })
      ),
  })
  const exceptKeys = ['checked', 'errorMessage']
  return (
    <Formik
      initialValues={{
        data: [],
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={formikProps => {
        const {
          values,
          errors,
          setFieldValue,
          handleSubmit,
          touched,
        } = formikProps
        const { data } = values
        let keys = []
        if (data.length > 0) {
          keys = Object.keys(data[0]).filter(
            key => key !== 'errorMessage' || key !== 'checked'
          )
        }

        const titles = '医師情報CSVアップロード（更新）'
        return (
          <React.Fragment>
            <div className='staff-header'>
              <div className='staff-title'>
                {titles}
                <BreadCrumb
                  items={[
                    {
                      title: titles,
                    },
                  ]}
                />
              </div>
              <div className='staff-buttons'>
                {/* <Button
                  icon='pi pi-download'
                  className='p-button-normal'
                  onClick={downloadClinicalDepartments}
                  label={i18n.t('main.clinicalDepartment')}
                /> */}
                <Button
                  icon='pi pi-download'
                  className='p-button-normal'
                  onClick={downloadSample}
                  label={i18n.t('main.sampleCsv')}
                />
              </div>
            </div>
            {isUploadPermitted ? (
              <div className='container'>
                <div className='box'>
                  <div className='alert is-left'>
                    ※医師情報csvアップロード（更新）について
                    <br />
                    ・登録情報を更新したい医師のメールアドレスと医師No.を入力してください（2つとも入力必須です）
                    <br />
                    ・登録内容を更新しない情報は必ず空欄のままアップロード作業を行なってください（更新したい項目のみ記載し、ファイルをアップロードしてください）
                    <br />
                    ・誤った情報のままアップロード処理を行うと、医師情報が上書きされてしまいますのでご注意ください
                    <br />
                    ・スタッフサイトから医師情報を更新した場合、医師本人への通知はされません
                  </div>
                  <div className='box-top'>
                    <div
                      style={{
                        position: 'relative',
                        display: 'flex',
                        flex: 1,
                        alignItems: 'center',
                      }}
                    >
                      <div className='alert is-left'>
                        {typeof errors.data === 'string' &&
                          touched.data &&
                          errors.data}
                      </div>
                    </div>
                    {values.data.length > 0 && (
                      <Button
                        onClick={() => setFieldValue('data', [])}
                        label={i18n.t('main.cancel')}
                        className='p-button-secondary'
                        icon='pi pi-times'
                        style={{ marginRight: '20px' }}
                      />
                    )}
                    {isUploadPermitted ? (
                      <Button
                        onClick={() => {
                          if (
                            (errors.data && errors.data.length > 0) ||
                            values.data.find(d => d.errorMessage)
                          ) {
                            popup.error(i18n.t('main.hasCsvFileError'))
                          } else {
                            checkCsvData(values, { setFieldValue })
                          }
                        }}
                        // label='Check'
                        label={i18n.t('staff.csvUpload.check')}
                        type='submit'
                        disabled={data.length === 0 || isUploaded}
                        style={{ marginRight: '20px' }}
                      />
                    ) : null}
                    {isUploadPermitted ? (
                      <Button
                        onClick={() => {
                          if (
                            (errors.data && errors.data.length > 0) ||
                            values.data.find(d => d.errorMessage)
                          ) {
                            popup.error(i18n.t('main.hasCsvFileError'))
                          } else {
                            handleSubmit()
                          }
                        }}
                        label={i18n.t('staff.csvUpload.submit')}
                        type='submit'
                        disabled={data.length === 0 || isUploaded}
                      />
                    ) : null}
                  </div>
                  {values.data.length < 1 && (
                    <Dropzone
                      onDrop={files => {
                        if (files.length > 0) {
                          Papa.parse(files[0], {
                            header: true,
                            complete: async results => {
                              setFieldValue('data', results.data)
                            },
                          })
                        }
                      }}
                      accept='.csv'
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: 'dropzone' })}>
                          <input {...getInputProps()} />
                          <p>{i18n.t('doctor.uploadFile.dropFileHere')}</p>
                        </div>
                      )}
                    </Dropzone>
                  )}
                  {data.length > 0 && (
                    <div className='overflow-container'>
                      <table className='table'>
                        <thead>
                          <tr>
                            <th>{i18n.t('main.action')}</th>
                            {keys.map(
                              (key, index) =>
                                !exceptKeys.includes(key) && (
                                  <th key={`${key}_${index}`}>
                                    {/* {translateKeys[key]} */}
                                    {key}
                                  </th>
                                )
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          <FieldArray
                            name='data'
                            render={({ remove }) => (
                              <React.Fragment>
                                {data.map((item, i) => {
                                  const error =
                                    errors.data && errors.data.length > 0
                                      ? errors.data[i]
                                      : false
                                  const isChecked = data[i].checked
                                  const errorMessage =
                                    item.errorMessage ||
                                    Object.keys(error || {}).reduce(
                                      (acc, curr) => `${acc}\n${error[curr]}`,
                                      ''
                                    )
                                  return (
                                    <React.Fragment>
                                      <tr key={`${i}`}>
                                        {!errorMessage ? (
                                          <td>
                                            {isChecked ? (
                                              <Button
                                                icon='pi pi-check'
                                                className='p-button-success'
                                              />
                                            ) : (
                                              <Button
                                                icon='pi pi-info'
                                                tooltip={i18n.t(
                                                  'staff.csvUpload.notChecked'
                                                )}
                                              />
                                            )}
                                          </td>
                                        ) : (
                                          <td>
                                            <div>
                                              <Button
                                                onClick={() => remove(i)}
                                                icon='pi pi-times'
                                                className='p-button-danger'
                                              />
                                            </div>
                                          </td>
                                        )}
                                        {keys.map(
                                          key =>
                                            !exceptKeys.includes(key) && (
                                              <td>{item[key]}</td>
                                            )
                                        )}
                                      </tr>
                                      {errorMessage && (
                                        <tr key={`errorMessage_${i}`}>
                                          <td colSpan={keys.length + 1}>
                                            <div className='alert is-left'>
                                              {errorMessage}
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                    </React.Fragment>
                                  )
                                })}
                              </React.Fragment>
                            )}
                          />
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            ) : null}
          </React.Fragment>
        )
      }}
    />
  )
}

export default DoctorCsvUpdateScene
