/* eslint-disable array-callback-return */
import BreadCrumb from '@medical/components/Breadcrumb'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import ModalLink from '@medical/components/ModalLink'
import Router from '@medical/routes/router'
import { Checkbox, DatePicker } from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { Paginator } from 'primereact/paginator'
import queryString from 'query-string'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const IncentiveSettingettingListScene = ({
  i18n,
  list,
  page,
  onPageChange,
  count,
  rowsPerPage,
  incentiveS,
  titleS,
  applyS,
  onSubmit,
  before,
  after,
  isCheckbox,
  history,
  date,
  isCreatePermitted,
  isUpdatePermitted,
}) => {
  const removeSpace = fullname => {
    if (!fullname) {
      return undefined
    }
    const halfspace = ` `
    // eslint-disable-next-line no-irregular-whitespace
    const fullspace = `　`
    if (!fullname.includes(halfspace) && !fullname.includes(fullspace)) {
      return fullname
      // eslint-disable-next-line no-else-return
    } else {
      const newString = fullname.replace(halfspace, '').replace(fullspace, '')
      return newString
    }
  }
  const applyTypes = [
    {
      name: i18n.t('main.IncentiveApplyType.NEW_DOCTOR'),
      value: 'NEW_DOCTOR',
    },
    {
      name: i18n.t('main.IncentiveApplyType.SPECIFY_DOCTORS'),
      value: 'SPECIFY_DOCTORS',
    },
    {
      name: i18n.t('main.IncentiveApplyType.ALL_DOCTORS'),
      value: 'ALL_DOCTORS',
    },
    {
      name: i18n.t('main.IncentiveApplyType.WORK_PATTERN_APPLIED'),
      value: 'WORK_PATTERN_APPLIED',
    },
  ]
  const incentiveType = [
    {
      name: i18n.t('main.incentiveSetting.normalIncentive'),
      value: 'NORMAL_INCENTIVE',
    },
    {
      name: i18n.t('main.incentiveSetting.numberIncentive'),
      value: 'NUMBER_INCENTIVE',
    },
    {
      name: i18n.t('main.incentiveSetting.beyound8Incentive'),
      value: 'BEYOND_EIGHT_INCENTIVE',
    },
    {
      name: i18n.t('main.incentiveSetting.timeIncentive'),
      value: 'TIME_INCENTIVE',
    },
    {
      name: i18n.t('main.incentiveSetting.satisfactionIncentive'),
      value: 'SATISFACTION_INCENTIVE',
    },
  ]

  const getIncentiveType = () => {
    let rs
    if (incentiveS) {
      incentiveType.map(as => {
        if (as.value === incentiveS) {
          rs = as
        }
      })
    }
    return rs
  }
  const getApplyType = () => {
    let rs
    if (applyS) {
      applyTypes.map(as => {
        if (as.value === applyS) {
          rs = as
        }
      })
    }
    return rs
  }
  const [incentive, setInsentive] = useState(getIncentiveType())
  const [applyType, setApplyType] = useState(getApplyType())
  const [title, setTitle] = useState(titleS)

  const [dateFilter, setDateFilter] = useState(date)
  const getDate = as =>
    `${moment(as.node.startTime).format('YYYY年MM月')} ~  ${moment(
      as.node.endTime
    ).format('YYYY年MM月')}`
  const clickCheckbox = e => {
    history.push({
      search: queryString.stringify({
        rowsPerPage,
        type: incentive && incentive.value ? incentive.value : undefined,
        title: removeSpace(title),
        apply: applyType && applyType.value ? applyType.value : undefined,
        date: dateFilter,
        old: e.target.checked,
      }),
    })
  }
  const checkOldIncentive = as => {
    const endTimeDefault = 15
    const endTime = moment(as.node.endTime)
    const boforeTwoMonth = moment()
      .subtract(1, 'month')
      .startOf('month')
    if (
      endTime.year() ===
        moment()
          .subtract(1, 'month')
          .year() &&
      endTime.month() ===
        moment()
          .subtract(1, 'month')
          .month()
    ) {
      if (moment().date() > endTimeDefault) {
        return '#e6e6e6'
      }
    }
    if (boforeTwoMonth.isAfter(endTime)) {
      return '#e6e6e6'
    }
    return undefined
  }
  return (
    <div>
      <div
        style={{
          padding: '10px',
          height: '100%',
        }}
      >
        <div className='staff-header'>
          <div className='staff-title'>
            {i18n.t('main.incentiveSetting.title')}
            <BreadCrumb
              items={[{ title: i18n.t('main.incentiveSetting.title') }]}
            />
          </div>
        </div>
        <div
          className='box'
          style={{
            backgroundColor: '#ffffff',
            marginLeft: '20px',
            marginRight: '20px',
            padding: '20px',
            borderRadius: '10px',
          }}
        >
          <div className='search-parent'>
            <Dropdown
              value={incentive}
              name='incentive'
              options={incentiveType}
              optionLabel='name'
              placeholder={i18n.t('main.incentiveSetting.type')}
              onChange={e => {
                setInsentive(e.target.value)
              }}
              style={{ width: '280px', marginRight: '20px' }}
            />
            <InputText
              value={title}
              placeholder={i18n.t('main.incentiveSetting.searchTitle')}
              onChange={e => {
                setTitle(e.target.value)
              }}
              style={{ width: '170px', marginRight: '20px' }}
            />
            <Dropdown
              value={applyType}
              name='incentive'
              options={applyTypes}
              optionLabel='name'
              placeholder={i18n.t('main.incentiveSetting.audience')}
              onChange={e => {
                setApplyType(e.target.value)
              }}
              style={{ width: '120px', marginRight: '20px' }}
            />
            <DatePicker
              locale={locale}
              format='YYYY年MM月'
              value={dateFilter ? moment(dateFilter) : null}
              picker='month'
              style={{ border: '1px solid gray' }}
              onChange={value => {
                setDateFilter(
                  moment(value)
                    .endOf('month')
                    .toISOString()
                )
              }}
            />
            <Link
              to={{
                pathname: Router.staffIncentiveSetting,
                search: queryString.stringify({
                  rowsPerPage,
                  type:
                    incentive && incentive.value ? incentive.value : undefined,
                  title: removeSpace(title),
                  apply:
                    applyType && applyType.value ? applyType.value : undefined,
                  date: dateFilter,
                }),
              }}
              style={{ marginLeft: '20px' }}
            >
              <Button
                label={i18n.t('main.incentiveSetting.search')}
                icon='pi pi-search'
              />
            </Link>
            {incentive || title || applyS || after || before || date ? (
              <Link
                to={{
                  pathname: Router.staffIncentiveSetting,
                  search: queryString.stringify({
                    rowsPerPage,
                  }),
                }}
                style={{ marginLeft: '20px' }}
              >
                <Button
                  icon='pi pi-times'
                  className='p-button-secondary'
                  style={{ marginLeft: '20px' }}
                />
              </Link>
            ) : null}
            {isCreatePermitted ? (
              <div style={{ marginLeft: 'auto', marginRight: '0px' }}>
                <ModalLink
                  to={Router.get(Router.staffCreateIncentiveSettingCreate)}
                >
                  <Button
                    icon='pi pi-plus-circle'
                    className='p-button-normal'
                    label='インセンティブ作成'
                  />
                </ModalLink>
              </div>
            ) : null}
          </div>
          <Formik
            initialValues={{
              confirm: false,
              staffId: '',
            }}
            onSubmit={onSubmit}
            render={formikProps => {
              const { setFieldValue } = formikProps
              return (
                <div>
                  <div>
                    <table className='table-moving-expenses'>
                      <thead>
                        <tr>
                          <th width='18%'>
                            {i18n.t('main.incentiveSetting.incentiveType')}
                          </th>
                          <th width='28%'>
                            {i18n.t('main.incentiveSetting.searchTitle')}
                          </th>
                          <th width='24%'>
                            {i18n.t('main.incentiveSetting.audience')}
                          </th>
                          <th width='22%'>
                            {i18n.t('main.incentiveSetting.period')}
                          </th>
                          {isUpdatePermitted ? <th width='20%' /> : null}
                        </tr>
                      </thead>
                      <tbody>
                        {list && list.length > 0
                          ? list.map(as => (
                              <tr
                                style={{
                                  backgroundColor: checkOldIncentive(as),
                                }}
                              >
                                <td>{as.node.type}</td>
                                <td>{as.node.title}</td>
                                <td>{as.node.applyWith}</td>
                                <td>{getDate(as)}</td>
                                {isUpdatePermitted ? (
                                  <td>
                                    {checkOldIncentive(as) === undefined ? (
                                      <div className='action'>
                                        <ModalLink
                                          to={Router.get(
                                            Router.staffCreateIncentiveSettingDetail,
                                            {
                                              id: as.node.id,
                                            }
                                          )}
                                          className={
                                            Router.staffCreateIncentiveSettingDetail
                                          }
                                        >
                                          <Button icon='pi pi-pencil' />
                                        </ModalLink>
                                      </div>
                                    ) : null}
                                  </td>
                                ) : null}
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </table>
                    <ConfirmationModal
                      {...formikProps}
                      confirmationMessage='データの復元はできません。本当に削除しますか?'
                    />
                    <Paginator
                      rows={rowsPerPage}
                      totalRecords={count}
                      first={(page - 1) * rowsPerPage}
                      onPageChange={onPageChange}
                      leftContent={
                        <div className='paginator-total'>
                          {i18n.t('main.paginationTotal')}: {count}
                        </div>
                      }
                    />
                    <div>
                      <div>
                        <Checkbox
                          checked={isCheckbox === 'true'}
                          onClick={e => {
                            clickCheckbox(e)
                          }}
                          style={{
                            marginLeft: '95.5%',
                            marginTop: '10px',
                            marginBottom: '10px',
                            transform: 'scale(1.75)',
                          }}
                        />
                      </div>
                      <div
                        style={{
                          textAlign: 'right',
                        }}
                      >
                        チェックをつけると、過去の設定も全て表示されます
                      </div>
                    </div>
                  </div>
                </div>
              )
            }}
          />
        </div>
      </div>
    </div>
  )
}
export default IncentiveSettingettingListScene
