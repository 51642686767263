import './DoctorClinicMobile.css'

import BreadCrumb from '@medical/components/Breadcrumb'
import ModalLink from '@medical/components/ModalLink'
import Router from '@medical/routes/router'
import { Col, Row } from 'antd'
import { Button } from 'primereact/button'
import React from 'react'

const DoctorClinicMobile = ({ i18n, edges, departmentNames }) => {
  return (
    <div className='clinic-mobile-wrapper'>
      <div className='doctor-header'>
        <div className='staff-title'>
          {i18n.t('doctor.menuBar.clinicList')}
          <BreadCrumb
            items={[{ title: i18n.t('doctor.menuBar.clinicList') }]}
          />
        </div>
      </div>
      {edges.map(({ node }) => (
        <>
          <div className='clinic-mobile-inner'>
            <Row gutter={[0, 8]} className='clinic-mobile-row'>
              <Col span={24} className='clinic-mobile-col'>
                <p style={{ fontWeight: 'bold' }}>
                  {i18n.t('doctorClinic.name')}
                </p>
                :<p>{node.name}</p>
              </Col>
              <Col span={24} className='clinic-mobile-col'>
                <p>{i18n.t('doctorClinic.department')}</p>:
                <div className='flex-parent' style={{ margin: '0px 5px' }}>
                  {node.clinicalDepartments
                    .filter(department => department.isDisplay)
                    .map(department => (
                      <div className='slashed-item' key={department.id}>
                        {departmentNames[department.name]}
                      </div>
                    ))}
                </div>
              </Col>
              <Col span={24} className='clinic-mobile-col'>
                <p>{i18n.t('doctorClinic.address')}</p>:<p>{node.address}</p>
              </Col>
              <Col span={24} className='clinic-mobile-col'>
                <p>{i18n.t('doctorClinic.treatmentResult')}</p>:
                <ModalLink
                  to={Router.get(Router.doctorClinicDetail, {
                    id: node.id,
                  })}
                >
                  <Button style={{ margin: '0px 5px' }} icon='pi pi-info' />
                </ModalLink>
              </Col>
            </Row>
          </div>
        </>
      ))}
    </div>
  )
}
export default DoctorClinicMobile
