import { useMutation } from '@apollo/react-hooks'
import { ACCEPT_IMAGE_EXTENSIONS, IMAGE_ERROR, MAX_FILE_SIZE } from '@medical/constant'
import { useCustom } from '@medical/provider/context'
import React, { useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import { GET_POST_PRE_SIGNED_URL } from '../UploadImage/UploadImage.graphql'
import { ProgressSpinner } from '@medical/components'
import uploadImage from '@medical/libs/uploadImage'

const UploadIdentity = ({
  i18n,
  values,
  fieldValue,
  setFieldValue,
  downloadFileSubmit,
  imgKey,
}) => {
  const [{ popup }] = useCustom()
  const [loading, setLoading] = useState(false)
  const [loadingImg, setLoadingImg] = useState(true)
  const [fileURL, setFileURL] = useState()
  const [getPostPreSignedUrl] = useMutation(GET_POST_PRE_SIGNED_URL)
  useEffect(() => {
    if (values[fieldValue]) {
      downloadFileSubmit(values[fieldValue], url => {
        setLoadingImg(true)
        setFileURL(url)
      })
    }
  }, [values[fieldValue]])
  return (
    <>
      {loading ? (
        <ProgressSpinner />
      ) : (
        <>
          <Dropzone
            onDrop={async files => {
              if (files.length > 0) {
                setLoading(true)
                const { data: postPreSignedUrl } = await getPostPreSignedUrl({
                  variables: {
                    fileName: files[0].name,
                    mimetype: files[0].type
                  },
                })
                const key = await uploadImage(i18n, popup, postPreSignedUrl, files[0])
                if (key) setFieldValue(fieldValue, key)
                setLoading(false)
              }
            }}
            accept={ACCEPT_IMAGE_EXTENSIONS}
            maxSize={MAX_FILE_SIZE}
            multiple={false}
          >
            {({ getRootProps, getInputProps }) => {
              return (
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  {loadingImg && <ProgressSpinner/>}
                  <img
                      alt={i18n.t('doctor.uploadFile.dropFileHere')}
                      src={fileURL || imgKey || IMAGE_ERROR}
                      style={{display: loadingImg ? 'none' : 'unset'}}
                      onLoad={() => setLoadingImg(false)}
                    />
                </div>
              )
            }}
          </Dropzone>
        </>
      )}
    </>
  )
}

export default UploadIdentity
