import { InputTextarea } from 'primereact/inputtextarea'
import React, { useRef, useEffect } from 'react'
import { isMobile } from 'react-device-detect'

const SingleAvailableShiftScene = ({ i18n, availableShift }) => {
  const textareaRef = useRef(null)

  useEffect(() => {
    const textarea = textareaRef.current?.element
    if (textarea) {
      if (isMobile) {
        textarea.style.height = 'auto'
        textarea.style.height = '40vh'
      } else {
        textarea.style.height = 'auto'
        textarea.style.height = `${textarea.scrollHeight}px`
      }
    }
  }, [availableShift?.comment])

  if (isMobile)
    return (
      <React.Fragment>
        <div className='modal-title'>{i18n.t('main.availableShift')}</div>
        <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
          {i18n.t('main.workDetail')}
        </div>
        <div>
          <InputTextarea
            ref={textareaRef}
            value={availableShift.comment}
            disabled
            autoResize
            rows={8}
          />
        </div>
      </React.Fragment>
    )
  return (
    <React.Fragment>
      <div className='modal-title'>{i18n.t('main.availableShift')}</div>
      <table className='single-available-shift modal-table'>
        <tbody>
          <tr>
            <th style={{ width: '20%' }}>{i18n.t('main.workDetail')}</th>
            <td>
              <InputTextarea
                ref={textareaRef}
                value={availableShift.comment}
                disabled
                autoResize
                style={{
                  minHeight: '128px',
                  maxHeight: '70vh',
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  )
}

export default SingleAvailableShiftScene
