import Moment from 'moment'
import { extendMoment } from 'moment-range'

const moment = extendMoment(Moment)

export const thisHolidays = ({ data, date, holiday }) => {
  const holidays = []
  const holidaysData = []
  const monday = []
  const tuesday = []
  const wednesday = []
  const thursday = []
  const friday = []
  const saturday = []
  const sunday = []

  const startTime = moment(date)
    .startOf('month')
    .startOf('day')
  const endTime = moment(date)
    .endOf('month')
    .endOf('day')
  const itemRange = moment.range(startTime, endTime)
  const arr = Array.from(itemRange.by('days'))
  arr.forEach(element => {
    switch (element.days()) {
      case 1:
        return monday.push({ date: element.format('YYYY-MM-DD') })
      case 2:
        return tuesday.push({ date: element.format('YYYY-MM-DD') })
      case 3:
        return wednesday.push({ date: element.format('YYYY-MM-DD') })
      case 4:
        return thursday.push({ date: element.format('YYYY-MM-DD') })
      case 5:
        return friday.push({ date: element.format('YYYY-MM-DD') })
      case 6:
        return saturday.push({ date: element.format('YYYY-MM-DD') })
      default:
        return sunday.push({ date: element.format('YYYY-MM-DD') })
    }
  })

  if (data.doctorMe.isAvailableOnMonday) {
    holidays.push(...monday)
  }
  if (data.doctorMe.isAvailableOnTuesday) {
    holidays.push(...tuesday)
  }
  if (data.doctorMe.isAvailableOnWednesday) {
    holidays.push(...wednesday)
  }

  if (data.doctorMe.isAvailableOnThursday) {
    holidays.push(...thursday)
  }
  if (data.doctorMe.isAvailableOnFriday) {
    holidays.push(...friday)
  }
  if (data.doctorMe.isAvailableOnSaturday) {
    holidays.push(...saturday)
  }
  if (data.doctorMe.isAvailableOnSunday) {
    holidays.push(...sunday)
  }
  if (data.doctorMe.isAvailableOnHoliday) {
    holidays.push(...holiday)
  }
  for (let j = 0; j < holidays.length; j += 1) {
    const startEvent = moment(holidays[j].date)
    if (itemRange.contains(startEvent)) {
      holidaysData.push(holidays[j].date)
    }
  }
  return [...new Set(holidaysData)]
}
