import gql from 'graphql-tag'

export const UPDATE_TRANFFIC_FEE = gql`
  mutation StaffUpdateTransportationTranfficFee(
    $clinicalDepartmentId: ID!
    $transportationId: ID!
    $cost: Int
    $endTime: DateTime
    $type: String
    $file: String
    $comment: String
  ) {
    staffUpdateTransportationTranfficFee(
      clinicalDepartmentId: $clinicalDepartmentId
      transportationId: $transportationId
      cost: $cost
      endTime: $endTime
      type: $type
      file: $file
      comment: $comment
    )
  }
`
export const GET_MOVING_EXPENSES = gql`
  query TransportationExpense($where: TransportationExpenseWhereUniqueInput!) {
    transportationExpense(where: $where) {
      id
      cost
      type
      startTime
      endTime
      billImage
      urlImageS3
      comment
      doctor {
        id
        fullName
      }
      clinicalDepartment {
        id
        name
        extraName
        isDisplay
        clinic {
          id
          order
          name
          nameKana
        }
      }
    }
  }
`
export const TRANPORTATION_TYPE_SETTINGS = gql`
  query TransportationTypeSettings(
    $where: TransportationTypeSettingWhereInput
  ) {
    transportationTypeSettings(where: $where) {
      id
      maxCost
      type
      isApply
    }
  }
`
