import Calendar from '@medical/components/Calendar'
import { pathOr } from 'ramda'
import React from 'react'

const CustomCalendarPicker = ({
  label,
  errors,
  touched,
  name,
  values,
  required,
  placeholder,
  ...props
}) => {
  const isTouched = pathOr(false, [name], touched)
  const error = pathOr('', [name], errors)
  return (
    <div className='tr'>
      {label && (
        <div className={`th ${required ? 'required' : ''}`}>{label}</div>
      )}
      <div className='td'>
        <Calendar {...props} name={name} value={values[name]} placeholder={placeholder} />
        <div className='alert'>{isTouched && error}</div>
      </div>
    </div>
  )
}

export default CustomCalendarPicker
