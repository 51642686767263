export default (val, path) => {
  if (!path) return ''
  if (Array.isArray(val)) {
    let has = false
    val.forEach(valItem => {
      if (Array.isArray(path)) {
        path.forEach(pathItem => {
          if (pathItem.indexOf(valItem) > -1) {
            has = true
          }
        })
      } else if (path.indexOf(valItem) > -1) has = true
    })
    return has ? 'is-selected' : ''
  }
  return path.indexOf(val) > -1 ? 'is-selected' : ''
}
