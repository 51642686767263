import './styles.css'

import { ErrorMessage } from 'formik'
import { InputText } from 'primereact/inputtext'
import { pathOr } from 'ramda'
import React from 'react'

const TextInput = ({
  values,
  handleChange,
  handleBlur,
  name,
  errors,
  touched,
  disabled,
  inputWidth,
  classDisabled,
  placeholder,
  staffComment,
  recruitmentCategory,
  index,
}) => {
  const keys = name.split('.')
  let value = pathOr('', keys, values)
  if (staffComment) {
    value = values.splitComments[index].text
  }
  if (recruitmentCategory) {
    value = values.splitRecruitmentCategories[index].text
  }
  const isTouched = pathOr(false, keys, touched)
  const error = pathOr('', keys, errors)
  return (
    <div
      className='input-comment-page-container'
      style={
        staffComment
          ? { marginRight: 'auto', width: '69%' }
          : { marginRight: 'auto' }
      }
    >
      <div className='' style={{ width: '100%' }}>
        <InputText
          name={name}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          style={inputWidth ? { width: inputWidth } : { width: '100%' }}
          disabled={disabled}
          className={classDisabled && disabled ? 'is-disabled' : ''}
          autoCapitalize='none'
          placeholder={placeholder}
        />
        {staffComment && (
          <ErrorMessage
            name={`splitComments[${index}].text`}
            component='div'
            className='alert'
          />
        )}
        {recruitmentCategory && (
          <ErrorMessage
            name={`splitRecruitmentCategories[${index}].description`}
            component='div'
            className='alert'
          />
        )}
        {!staffComment && !recruitmentCategory && (
          <div className='alert'>{isTouched && error}</div>
        )}
      </div>
    </div>
  )
}

export default TextInput
