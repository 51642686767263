import '@medical/styles/css'

// slide doctor_toppage
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
// ie9 & ie11 support
import 'react-app-polyfill/ie9'
import '@medical/libs/I18n/i18n'
import 'moment/locale/ja'
import 'antd/dist/antd.css'

import * as Sentry from '@sentry/browser'
import moment from 'moment-timezone'
/* ------------------- Application SASS & CSS ------------------- */
import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'
import * as serviceWorker from './serviceWorker'

import ErrorBoundary from '@medical/components/ErrorBoundary'

moment.locale(process.env.REACT_APP_LOCALE)
moment.tz.setDefault('Asia/Tokyo')
// React App error handling logging
// if (!process.env.REACT_APP_LOCAL_DEVELOPMENT) {
//   Sentry.init({
//     dsn: process.env.REACT_APP_SENTRY_KEY,
//   })
// }

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
