import BreadCrumb from "../../../components/Breadcrumb";
import {Button} from "primereact/button";
import {Formik} from "formik";
import AutoComplete from "../../../components/AutoComplete";
import {ProgressSpinner} from "../../../components";
import React, {Suspense} from "react";
import styles from "../ExpectationDoubleRecruitmentRecord/Style";
import Router from '@medical/routes/router'

const DataTable = React.lazy(() => import('./components/ExpectationDoubleRecruitmentRecordPatternDataTable'))

const initialData = [
  {
    monday: null,
    tuesday: null,
    wednesday: null,
    thursday: null,
    friday: null,
    saturday: null,
    sunday: null,
    holiday: null,
  },
  {
    monday: null,
    tuesday: null,
    wednesday: null,
    thursday: null,
    friday: null,
    saturday: null,
    sunday: null,
    holiday: null,
  },
  {
    monday: null,
    tuesday: null,
    wednesday: null,
    thursday: null,
    friday: null,
    saturday: null,
    sunday: null,
    holiday: null,
  }
]

const ExpectationDoubleRecruitmentRecordPatternScene = (
  {
    mutationGetExpectationDoubleRecruitmentRecordPattern,
    onSubmit,
    isUploaded,
    convertClinic,
    clinics,
    clinicalDepartments,
    convertClinicalDepartment,
    year,
    month,
  }
) => {
  return (
    <>
      <div className={'staff-header'}>
        <div className={'staff-title'}
             style={{
               margin: '0',
               marginTop: '20px'
             }}>
          二診目希望パターン登録
          <BreadCrumb
            items={[
              {
                title: '二診目希望登録',
                to: Router.get(Router.staffExpectationDoubleRecruitmentRecord,
                  {
                    year: year,
                    month: month,
                  }
                )
              },
              {
                title: '二診目希望パターン登録',
              },
            ]}
          />
        </div>
      </div>
      <div className='container is-max'>
        {
          isUploaded && <div style={styles.loading}>
            <ProgressSpinner/>
          </div>
        }
        <Formik
          onSubmit={onSubmit}
          initialValues={{
            clinics: convertClinic(clinics),
            clinic_id: '',
            clinicalDepartments: [],
            clinicalDepartmentsOldData: [],
            clinical_department_id: '',
            isSelectClinic: false,
            isSelectClinicalDepartment: false,
            data: initialData,
            valueClinicalDepartments: true
          }}
          render={formikProps => {
            const {
              values,
              errors,
              setFieldValue,
              handleSubmit,
              touched,
              resetForm,
            } = formikProps

            return (
              <div className={'box pt-3'}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '2px',
                }}>
                  <p style={{
                    fontSize: '12px',
                  }}>表示項目説明</p>
                  <p style={{
                    fontSize: '12px',
                    color: 'rgb(255, 0, 0)',
                  }}>＊祝日の場合祝日の値が優先されます。
                  </p>
                </div>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    margin: '20px 0 10px 0',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                    }}>
                    <AutoComplete
                      {...formikProps}
                      disabled={isUploaded}
                      name={'clinic'}
                      field='name'
                      searchFields={['name']}
                      allSuggestions={values.clinics}
                      suggestionName='clinics'
                      width='60vw'
                      maxWidth='200px'
                      placeholder='クリニックを選択'
                      setCurrentClinicData={
                        async val => {
                          setFieldValue('clinics', convertClinic(clinics))
                          setFieldValue('data', initialData)
                          setFieldValue('clinical_department_id', '')
                          setFieldValue('clinicalDepartments', [])
                          setFieldValue('clinicalDepartmentsOldData', [])
                          setFieldValue('valueClinicalDepartments', true)
                          setFieldValue('isSelectClinicalDepartment', false)
                          if (val.id) {
                            setFieldValue('clinic_id', val.id)
                            setFieldValue('isSelectClinic', true)
                            const {data} = await clinicalDepartments({
                              variables: {
                                id: val.id
                              }
                            })
                            setFieldValue('clinicalDepartments', convertClinicalDepartment(data.clinicalDepartments))
                            setFieldValue('clinicalDepartmentsOldData', convertClinicalDepartment(data.clinicalDepartments))
                          } else {
                            setFieldValue('isSelectClinic', false)
                          }
                        }
                      }
                    />
                    <AutoComplete
                      {...formikProps}
                      value={values.valueClinicalDepartments}
                      disabled={isUploaded || !values.isSelectClinic}
                      name={'clinicDepartment'}
                      field='label'
                      searchFields={['label']}
                      allSuggestions={values.clinicalDepartments}
                      suggestionName='clinicalDepartments'
                      width='60vw'
                      maxWidth='300px'
                      placeholder='診獠科選択'
                      setCurrentClinicData={
                        async val => {
                          setFieldValue('clinicalDepartments', convertClinicalDepartment(values.clinicalDepartmentsOldData))
                          setFieldValue('valueClinicalDepartments', false)
                          if (val.id) {
                            setFieldValue('clinical_department_id', val.id)
                            setFieldValue('isSelectClinicalDepartment', true)
                            const {data} = await mutationGetExpectationDoubleRecruitmentRecordPattern({
                              variables: {
                                clinic: values.clinic_id,
                                clinicalDepartment: val.id,
                                targetMonth: +month,
                                targetYear: +year,
                              }
                            })
                            if (data?.getExpectationDoubleRecruitmentRecordPattern) {
                              const expectationDoubleRecruitmentRecordPattern = data?.getExpectationDoubleRecruitmentRecordPattern
                              const newArr = [
                                {
                                  monday: expectationDoubleRecruitmentRecordPattern.mondayMorningShiftDoctors,
                                  tuesday: expectationDoubleRecruitmentRecordPattern.tuesdayMorningShiftDoctors,
                                  wednesday: expectationDoubleRecruitmentRecordPattern.wednesdayMorningShiftDoctors,
                                  thursday: expectationDoubleRecruitmentRecordPattern.thursdayMorningShiftDoctors,
                                  friday: expectationDoubleRecruitmentRecordPattern.fridayMorningShiftDoctors,
                                  saturday: expectationDoubleRecruitmentRecordPattern.saturdayMorningShiftDoctors,
                                  sunday: expectationDoubleRecruitmentRecordPattern.sundayMorningShiftDoctors,
                                  holiday: expectationDoubleRecruitmentRecordPattern.holidayMorningShiftDoctors,
                                },
                                {
                                  monday: expectationDoubleRecruitmentRecordPattern.mondayAfternoonShiftDoctors,
                                  tuesday: expectationDoubleRecruitmentRecordPattern.tuesdayAfternoonShiftDoctors,
                                  wednesday: expectationDoubleRecruitmentRecordPattern.wednesdayAfternoonShiftDoctors,
                                  thursday: expectationDoubleRecruitmentRecordPattern.thursdayAfternoonShiftDoctors,
                                  friday: expectationDoubleRecruitmentRecordPattern.fridayAfternoonShiftDoctors,
                                  saturday: expectationDoubleRecruitmentRecordPattern.saturdayAfternoonShiftDoctors,
                                  sunday: expectationDoubleRecruitmentRecordPattern.sundayAfternoonShiftDoctors,
                                  holiday: expectationDoubleRecruitmentRecordPattern.holidayAfternoonShiftDoctors,
                                },
                                {
                                  monday: expectationDoubleRecruitmentRecordPattern.mondayNightShiftDoctors,
                                  tuesday: expectationDoubleRecruitmentRecordPattern.tuesdayNightShiftDoctors,
                                  wednesday: expectationDoubleRecruitmentRecordPattern.wednesdayNightShiftDoctors,
                                  thursday: expectationDoubleRecruitmentRecordPattern.thursdayNightShiftDoctors,
                                  friday: expectationDoubleRecruitmentRecordPattern.fridayNightShiftDoctors,
                                  saturday: expectationDoubleRecruitmentRecordPattern.saturdayNightShiftDoctors,
                                  sunday: expectationDoubleRecruitmentRecordPattern.sundayNightShiftDoctors,
                                  holiday: expectationDoubleRecruitmentRecordPattern.holidayNightShiftDoctors,
                                }
                              ]
                              setFieldValue('data', newArr)
                            } else {
                              setFieldValue('data', initialData)
                            }
                          } else {
                            setFieldValue('isSelectClinicalDepartment', false)
                          }
                        }
                      }
                    />
                  </div>
                </div>
                <Suspense fallback={<ProgressSpinner/>}>
                  <DataTable
                    values={values}
                    setFieldValue={setFieldValue}/>
                </Suspense>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    marginTop: '20px'
                  }}>
                  <Button
                    onClick={() => {
                      handleSubmit();
                    }}
                    className='reflect-button'
                    disabled={isUploaded || !values.isSelectClinic || !values.isSelectClinicalDepartment}
                    type='submit'
                    label={'確定する'}/>
                </div>
              </div>
            )
          }}
        />
      </div>
    </>
  );
};

export default ExpectationDoubleRecruitmentRecordPatternScene;