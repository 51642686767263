import ConfirmationModal from '@medical/components/ConfirmationModal'
import CustomDropdown from '@medical/components/Dropdown'
import TextInput from '@medical/components/TextInput'
import {
  KATAKANA_REG_EXP,
  MAX_NAME_LENGTH,
  NAME_REG_EXP,
} from '@medical/constant'
import Router from '@medical/routes/router'
import { Formik } from 'formik'
import { Button } from 'primereact/button'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'

const StaffForm = ({
  staffGroups = [],
  staff = {},
  confirmationMessage,
  onSubmit,
  submitLabel,
  disabled = false,
  updateProfile,
  createIndicator,
  parentPath,
  search,
  cancelButton,
}) => {
  const { i18n } = useTranslation()

  const nameRegex = Yup.string()
    .max(MAX_NAME_LENGTH, i18n.t('validation.max', { max: MAX_NAME_LENGTH }))
    .matches(NAME_REG_EXP, i18n.t('validation.noSpecialCharacter'))

  const katakanaNameRegex = nameRegex
    .clone()
    .matches(KATAKANA_REG_EXP, i18n.t('validation.katakanaOnly'))

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(i18n.t('validation.email.email'))
      .required(i18n.t('validation.email.required')),
    firstname: nameRegex
      .clone()
      .required(
        `${i18n.t('updateProfile.firstname')} ${i18n.t('validation.required')}`
      ),
    lastname: nameRegex
      .clone()
      .required(
        `${i18n.t('updateProfile.lastname')} ${i18n.t('validation.required')}`
      ),
    firstnameKana: katakanaNameRegex
      .clone()
      .required(
        `${i18n.t('updateProfile.firstnameKana')} ${i18n.t(
          'validation.required'
        )}`
      ),
    lastnameKana: katakanaNameRegex
      .clone()
      .required(
        `${i18n.t('updateProfile.lastnameKana')} ${i18n.t(
          'validation.required'
        )}`
      ),
    position: Yup.string().nullable(),
    staffGroup: Yup.mixed().test({
      test: value => !!Object.keys(value).length,
      message: i18n.t(
        `${i18n.t('updateProfile.staffGroup')} ${i18n.t('validation.required')}`
      ),
    }),
  })

  return (
    <Formik
      initialValues={{
        confirm: false,
        email: staff.email,
        firstname: staff.firstname,
        lastname: staff.lastname,
        firstnameKana: staff.firstnameKana,
        lastnameKana: staff.lastnameKana,
        staffGroup: staff.staffGroup || {},
        position: staff.position,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={formikProps => {
        const { validateForm, setFieldValue, setTouched } = formikProps
        return (
          <div className='modal-wrapper'>
            <div className='flex-parent'>
              <div className='flex-item'>
                <TextInput
                  {...formikProps}
                  name='lastname'
                  label={i18n.t('updateProfile.lastname')}
                  disabled={disabled}
                  classDisabled
                />
              </div>
              <div className='flex-item'>
                <TextInput
                  {...formikProps}
                  name='firstname'
                  label={i18n.t('updateProfile.firstname')}
                  disabled={disabled}
                  classDisabled
                />
              </div>
              <div className='flex-item'>
                <TextInput
                  {...formikProps}
                  name='lastnameKana'
                  label={i18n.t('updateProfile.lastnameKana')}
                  disabled={disabled}
                  classDisabled
                />
              </div>
              <div className='flex-item'>
                <TextInput
                  {...formikProps}
                  name='firstnameKana'
                  label={i18n.t('updateProfile.firstnameKana')}
                  disabled={disabled}
                  classDisabled
                />
              </div>
            </div>
            <TextInput
              {...formikProps}
              name='email'
              label={i18n.t('updateProfile.email')}
              disabled={disabled || !createIndicator}
              classDisabled
            />
            <div className='flex-item'>
              <CustomDropdown
                {...formikProps}
                name='staffGroup'
                options={staffGroups}
                optionLabel='name'
                valueLabel='id'
                label={i18n.t('updateProfile.staffGroup')}
                disabled={updateProfile || disabled}
                classDisabled
                type
              />
            </div>
            {!updateProfile && (
              <div className='flex-item'>
                <TextInput
                  {...formikProps}
                  name='position'
                  label={i18n.t('updateProfile.position')}
                  disabled={disabled}
                  classDisabled
                />
              </div>
            )}

            {!disabled && (
              <div className='modal-bottom'>
                {!cancelButton && (
                  <Link
                    to={{
                      pathname:
                        parentPath.pathname +
                        Router.get(Router.staffStaffDetail, {
                          staffId: staff.id,
                        }),
                      search,
                    }}
                  >
                    <Button
                      label={i18n.t('main.cancel')}
                      className='p-button-secondary'
                    />
                  </Link>
                )}
                <Button
                  onClick={() => {
                    validateForm().then(response => {
                      if (!Object.keys(response).length) {
                        setFieldValue('confirm', true)
                      } else {
                        setTouched(response)
                      }
                    })
                  }}
                  label={submitLabel}
                  className='p-button-success'
                />
              </div>
            )}
            <ConfirmationModal
              {...formikProps}
              confirmationMessage={confirmationMessage}
            />
          </div>
        )
      }}
    />
  )
}

export default StaffForm
