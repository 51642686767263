import ConfirmationModal from '@medical/components/ConfirmationModal'
import Editor from '@medical/components/Editor'
import { Formik } from 'formik'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

const DocumentForm = ({
  document: { title = '', content = '' },
  onSubmit,
  confirmationMessage,
  submitLabel,
}) => {
  const { i18n } = useTranslation()

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .trim()
      .min(2, i18n.t('validation.title.min', { min: 2 }))
      .max(100, i18n.t('validation.title.max', { max: 100 }))
      .required(i18n.t('validation.title.required')),
    content: Yup.string()
      .trim()
      .max(5000, i18n.t('validation.content.max', { max: 5000 }))
      .nullable()
      .required(i18n.t('validation.content.required'))
      .test('only-text', i18n.t('validation.content.required'), value => {
        const div = document.createElement('div')
        div.innerHTML = value
        return div.textContent.trim()
      }),
  })

  return (
    <div className='container'>
      <div className='box'>
        <Formik
          initialValues={{
            confirm: false,
            title,
            content,
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          render={formikProps => {
            const {
              values,
              touched,
              errors,
              setTouched,
              validateForm,
              handleChange,
              setFieldValue,
            } = formikProps
            return (
              <div>
                <div className='tr'>
                  <div className='th' style={{ width: '20%' }}>
                    {i18n.t('staff.announces.titleLabel')}
                  </div>
                  <div className='td'>
                    <InputText
                      value={values.title}
                      name='title'
                      onChange={handleChange}
                      style={{ width: '100%' }}
                    />
                    <div className='alert'>
                      {!!touched.title && errors.title}
                    </div>
                  </div>
                </div>
                <div className='tr'>
                  <div className='th' style={{ width: '20%' }}>
                    {i18n.t('staff.announces.contentLabel')}
                  </div>
                  <div className='td'>
                    <Editor
                      value={values.content}
                      onTextChange={e => setFieldValue('content', e.htmlValue)}
                    />
                    <div className='alert'>
                      {!!touched.content && errors.content}
                    </div>
                  </div>
                </div>

                <div className='modal-bottom'>
                  <Button
                    label={submitLabel}
                    onClick={() => {
                      validateForm().then(response => {
                        if (!Object.keys(response).length) {
                          setFieldValue('confirm', true)
                        } else {
                          setTouched(response)
                        }
                      })
                    }}
                    className='p-button-success'
                  />
                </div>

                <ConfirmationModal
                  {...formikProps}
                  confirmationMessage={confirmationMessage}
                />
              </div>
            )
          }}
        />
      </div>
    </div>
  )
}

export default DocumentForm
