import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import React, { useState } from 'react'

const MyNumberShow = ({
  value,
  name,
  disabled,
  classDisabled,
  className,
  inDetailScene,
}) => {
  const [show, setShow] = useState(true)
  return (
    <div>
      <div className='p-inputgroup'>
        <InputText
          name={name}
          value={value}
          type={show ? 'password' : undefined}
          disabled={disabled}
          className={
            classDisabled && disabled ? `${className} is-disabled` : className
          }
          autoCapitalize='none'
          style={
            inDetailScene
              ? { textAlign: 'left', width: '150px', padding: '0px' }
              : { textAlign: 'center' }
          }
        />
        <Button
          tabIndex='-1'
          icon={show ? `pi pi-eye` : `pi pi-eye-slash`}
          onClick={() => setShow(!show)}
        />
      </div>
    </div>
  )
}

export default MyNumberShow
