import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { onPageChange } from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import queryString from 'query-string'
import React from 'react'
import { HOLIDAY_URL } from '@medical/constant'
import { connect } from 'react-redux'
import { useAxios } from '@medical/hooks'
import { pathOr } from 'ramda'
import { thisRangeHolidays } from '@medical/libs'
import {
  GET_DOCTOR_WORK_SEARCH,
  DOCTORS_CONNECTION,
} from './DoctorWorkRecords.graph'
import DoctorWorkRecordScene from './DoctorWorkRecordsScene'
import {
  removeProgress,
  setProgressBar,
} from '@medical/provider/store/reducers/progress/progress.action'
import { DOWNLOAD_CSV_DOCTOR_LAST_WORKDAY } from '@medical/constant'
import { fieldDoctorListCsv } from '@medical/services/csvService'
import downloadCsv from 'download-csv'
import _ from 'lodash'
import {
  getDepartmentNameJP,
  getWorkPatternJP,
  getRegistrationStatusJP,
  getJoinBackgroundJP,
} from '@medical/constant/utilities'

import moment from 'moment'

const DockerWorkRecords = ({
  history,
  location: { search },
  progress,
  setProgress,
  removeProgress,
}) => {
  const [{ i18n }] = useCustom()
  const {
    page = 1,
    rowsPerPage = 20,
    fullname,
    email,
    phoneNumber,
    searchDepartments,
    finalWorkDay,
    nextWorkDay,
    dayWorks,
    specifyTime,
    timeRange,
  } = queryString.parse(search)

  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  let specifyTimeRange
  if (timeRange) {
    if (timeRange.includes('morning')) {
      specifyTimeRange = '09:00-13:00'
    }
    if (timeRange.includes('afternoon')) {
      specifyTimeRange = '13:00-18:00'
      if (timeRange.includes('morning')) {
        specifyTimeRange = '09:00-18:00'
      }
    }
    if (timeRange.includes('evening')) {
      specifyTimeRange = '18:00-21:00'
      if (timeRange.includes('afternoon')) {
        specifyTimeRange = '13:00-21:00'
      }
      if (timeRange.includes('morning')) {
        specifyTimeRange = '09:00-21:00'
      }
    }
  }

  const subtractMonth = moment()
    .subtract(6, 'months')
    .startOf('days')
  const addMonth = moment()
    .add(6, 'months')
    .startOf('days')

  const {
    loading: loadingHolidays,
    data: holidaysData,
    error: holidaysError,
  } = useAxios({
    url: HOLIDAY_URL,
    params: {
      timeMin: subtractMonth.toISOString(),
      timeMax: addMonth.toISOString(),
    },
  })

  const holidays = thisRangeHolidays({
    holidaysData: pathOr([], ['items'], holidaysData),
    startDate: subtractMonth,
    endDate: addMonth,
  })
  const isHolidays = []
  holidays.forEach(item => {
    isHolidays.push(moment(item.date).format('YYYY-MM-DD'))
  })
  let isHoliday = []
  if (dayWorks && dayWorks.includes('isHoliday')) {
    isHoliday = isHolidays
  }
  const variables = {
    first: first < 0 ? 20 : first,
    skip: skip < 0 ? 0 : skip,
    fullName: fullname
      ? fullname
          .replace(/ /g, '')
          .replace(/　/g, '')
          .toLowerCase()
      : '',
    email,
    phoneNumber,
    searchDepartments,
    finalWorkDay: finalWorkDay || '',
    nextWorkDay: nextWorkDay || '',
    dayWorks,
    specifyTime,
    specifyTimeRange,
    isHoliday,
  }

  const { refetch } = useQuery(DOCTORS_CONNECTION, {
    fetchPolicy: 'network-only',
    skip: true,
    orderBy: 'createdAt_DESC',
  })

  const { loading, error, data } = useQuery(GET_DOCTOR_WORK_SEARCH, {
    variables,
    fetchPolicy: 'network-only',
  })

  if (loading || loadingHolidays) return <ProgressSpinner />
  if (error || holidaysError) return <ErrorComponent error={error} />
  const { departments } = data

  const handleDownload = async () => {
    try {
      setProgress({
        progress: DOWNLOAD_CSV_DOCTOR_LAST_WORKDAY,
        label: '医師出勤傾向CSVダウンロード',
        percent: 0,
      })
      let totalRecords
      const doctors = []
      for (let i = 0; ; i++) {
        const res = await refetch({
          where: {
            deletedAt: null,
            registrationStatus_in: ['CREATED_BY_STAFF', 'ACCEPTED'],
          },
          first: 50,
          skip: i * 50,
        })
        totalRecords = res.data.doctorsConnection.aggregate.count

        doctors.push(
          ...res.data.doctorsConnection.edges.map(item => ({
            ...item.node,
          }))
        )

        setProgress({
          progress: DOWNLOAD_CSV_DOCTOR_LAST_WORKDAY,
          label: '時間帯別確定シフトCSVダウンロード',
          percent: Math.ceil((doctors.length / totalRecords) * 100),
        })

        if (totalRecords === doctors.length) break
      }
      // CSV fields
      const fields = fieldDoctorListCsv()
      const filename = '医師出勤傾向.CSV'
      const results = []
      doctors.forEach(item => {
        const lstDepartments = []
        const lstNearestStations = []
        item.departments.forEach(element => {
          const departmentName = getDepartmentNameJP(element)
          lstDepartments.push(departmentName)
        })
        item.nearestStations.forEach(element => {
          lstNearestStations.push(element)
        })
        const departmentsName = _.join(lstDepartments, '、')
        const nearestStationsName = _.join(lstNearestStations, '、')
        results.push({
          fullName: `${item.lastname}${item.firstname}`,
          fullNameKana: `${item.lastnameKana}${item.firstnameKana}`,
          lastname: item.lastname,
          firstname: item.firstname,
          lastnameKana: item.lastnameKana,
          firstnameKana: item.firstnameKana,
          email: item.email,
          gender: item.gender ? (item.gender === 'MALE' ? '男性' : '女性') : '',
          birthday: item.birthday
            ? moment(item.birthday).format('YYYY年MM月DD日')
            : '',
          phoneNumber: item.phoneNumber || '',
          medicalLicenseNumber: item.medicalLicenseNumber || '',
          departments: departmentsName,
          zipCode: item.homeAddress?.zipCode || '',
          stateOrRegion: item.homeAddress?.stateOrRegion || '',
          address1: item.homeAddress?.address1 || '',
          address2: item.homeAddress?.address2 || '',
          province: item.province || '',
          placeOfWork: item.placeOfWork || '',
          nearestStations: nearestStationsName,
          workPattern: getWorkPatternJP(item.workPattern) || '',
          joinBackground: getJoinBackgroundJP(item.joinBackground),
          joinBackgroundOther: item.joinBackgroundOther
            ? '"' + item.joinBackgroundOther.replace(/"/g, '""') + '"'
            : '',
          staffMemo: item.staffMemo
            ? '"' + item.staffMemo.replace(/"/g, '""') + '"'
            : '',
          bankName: item.bankAccount?.bankName
            ? '"' + item.bankAccount?.bankName.replace(/"/g, '""') + '"'
            : '',
          bankCode: item.bankAccount?.bankCode || '',
          branchName: item.bankAccount?.branchName
            ? '"' + item.bankAccount.branchName.replace(/"/g, '""') + '"'
            : '',
          branchCode: item.bankAccount?.branchCode || '',
          accountType: item.bankAccount?.accountType || '',
          accountNumber: item.bankAccount?.accountNumber || '',
          accountHolderName: item.bankAccount?.accountHolderName
            ? '"' + item.bankAccount.accountHolderName.replace(/"/g, '""') + '"'
            : '',
          blocked: item.blocked === true ? '応募制限中' : '',
          medicalRecord: item.medicalRecord || '',
          orca: item.orca || '',
          questionnaire: item.questionnaire || '',
          registrationStatus: getRegistrationStatusJP(item.registrationStatus),
          doctorNo: item.doctorNo,
          createdAt: item.createdAt
            ? moment(item.createdAt).format('YYYY年MM月DD日')
            : '',
          updatedAt: item.updatedAt
            ? moment(item.updatedAt).format('YYYY年MM月DD日')
            : '',
          lastLoggedIn: item.lastLoggedIn
            ? moment(item.lastLoggedIn).format('YYYY年MM月DD日')
            : '',
          isJoinedSublingualImmunotherapyELearning:
            item.isJoinedSublingualImmunotherapyELearning === true
              ? '済'
              : '未',
          isJoinedOnlineDiagnosticTraining:
            item.isJoinedOnlineDiagnosticTraining === true ? '済' : '未',
          lastWorkDay: item.countedDoctorLastWorkShift?.lastWorkday
            ? moment(item.countedDoctorLastWorkShift.lastWorkday).format(
                'YYYY年MM月DD日'
              )
            : '勤務実績無',
          lastWorkdayClinic: item.countedDoctorLastWorkShift?.lastWorkdayClinic,
          lastWorkDayDepartment: getDepartmentNameJP(
            item.countedDoctorLastWorkShift?.lastWorkdayDepartment
          ),
          numberShiftWorkedInMorning:
            item.countedDoctorLastWorkShift?.numberShiftWorkedInMorning,
          numberShiftWorkedInAfternoon:
            item.countedDoctorLastWorkShift?.numberShiftWorkedInAfternoon,
          numberShiftWorkedInNight:
            item.countedDoctorLastWorkShift?.numberShiftWorkedInNight,
        })
      })
      downloadCsv(results, fields, filename)
      removeProgress(DOWNLOAD_CSV_DOCTOR_LAST_WORKDAY)
    } catch (err) {
      setProgress({
        progress: DOWNLOAD_CSV_DOCTOR_LAST_WORKDAY,
        label: '医師出勤傾向CSVダウンロード',
        msg: i18n.t('main.msgDownloadCSVStatusError'),
      })
    }
  }

  return (
    <DoctorWorkRecordScene
      i18n={i18n}
      //  edges={edges}
      data={data.doctorSearch[0].data || []}
      fullname={fullname}
      email={email}
      rowsPerPage={parseInt(rowsPerPage, 10)}
      count={data.doctorSearch[0].count}
      page={parseInt(page, 10)}
      onPageChange={onPageChange(history, search)}
      dataDepartment={departments}
      phoneNumber={phoneNumber}
      searchDepartments={searchDepartments}
      timeRange={timeRange}
      dayWorks={dayWorks}
      finalWorkDay={finalWorkDay}
      nextWorkDay={nextWorkDay}
      specifyTime={specifyTime}
      handleDownload={handleDownload}
    />
  )
}

const mapStateToProps = state => ({
  progress: state.progressBarStore.progress,
})

const mapDispatchToProps = dispatch => ({
  setProgress: progress => dispatch(setProgressBar(progress)),
  removeProgress: progress => dispatch(removeProgress(progress)),
})
export default connect(mapStateToProps, mapDispatchToProps)(DockerWorkRecords)
