import './style.css'

import Calendar from '@medical/components/Calendar'
import { pathOr } from 'ramda'
import React from 'react'

const CustomCalendarPicker = ({ errors, touched, name, values, ...props }) => {
  const isTouched = pathOr(false, [name], touched)
  const error = pathOr('', [name], errors)
  return (
    <div>
      <div className='input-birthday'>
        <Calendar {...props} name={name} value={values[name]} />
        <div className='alert'>{isTouched && error}</div>
      </div>
    </div>
  )
}

export default CustomCalendarPicker
