import { calcWorkScheduleTotalHourPerDay } from '@medical/libs'
import numeralCurrency from '@medical/libs/numeralCurrency'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'

const WorkReportsSp = ({ workSchedule }) => {
  const { i18n } = useTranslation()
  return (
    <div className='mobile-report'>
      <div className='mobile-report-title'>
        {`${i18n.t('main.scheduleDate')}: ${moment(
          workSchedule.startTime
        ).format('LL (dd)')}`}
      </div>
      <div className='mobile-report-item'>
        {i18n.t('main.clinicName')} :
        <span className='mobile-cart-item-content'>{workSchedule.clinic}</span>
      </div>
      <div className='mobile-report-item'>
        {i18n.t('main.departmentName')} :
        <span className='mobile-report-item-content'>
          {workSchedule.clinicalDepartment}
        </span>
      </div>
      <div className='mobile-report-item'>
        {i18n.t('main.startTime')} :
        <span className='mobile-report-item-content'>
          {moment(workSchedule.startTime).format('HH:mm')}
        </span>
      </div>
      <div className='mobile-report-item'>
        {i18n.t('main.endTime')} :
        <span className='mobile-report-item-content'>
          {moment(workSchedule.endTime).format('HH:mm')}
        </span>
      </div>
      <div className='mobile-report-item'>
        {i18n.t('main.shiftHour')} :
        <span className='mobile-report-item-content'>
          {calcWorkScheduleTotalHourPerDay(workSchedule)}
        </span>
      </div>
      <div className='mobile-report-item'>
        {i18n.t('main.dailySalary')} :
        <span className='mobile-report-item-content'>
          {numeralCurrency(workSchedule.dailySalary)}
        </span>
      </div>
    </div>
  )
}

export default WorkReportsSp
