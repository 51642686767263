import './style.css'

import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import i18n from '@medical/libs/I18n/i18n'
import numeralCurrency from '@medical/libs/numeralCurrency'
import { useCustom } from '@medical/provider/context'
import Router from '@medical/routes/router'
import { Col, Row } from 'antd'
import moment from 'moment'
import { Button } from 'primereact/button'
import React from 'react'

import { GET_LIST_AVAILABLE } from '../../AvailableShift/AvailableShift.graphql'
import DialogViewShift from './DialogViewShift'
import { useState } from 'react'

const TableDataAvailable = ({
  addItemToCart,
  removeItemFromCart,
  cartItems,
}) => {
  const [isAddToCart, setIsAddToCart] = useState(false)
  const [selectedShift, setSelectedShift] = useState()

  const [{ isDoctorBlocked }] = useCustom()
  const { loading, error, data } = useQuery(GET_LIST_AVAILABLE, {
    variables: {
      isHome: true,
      first: '5',
      skip: '0',
    },
  })
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const { doctorFindAvailableShift } = data
  const dataList = []
  if (doctorFindAvailableShift.data) {
    doctorFindAvailableShift.data.forEach(item => {
      if (moment(item.startTime).isAfter(moment())) {
        dataList.push({
          id: item.id,
          startTime: moment(item.startTime)
            .utc()
            .format(),
          endTime: moment(item.endTime)
            .utc()
            .format(),
          clinicalDepartment: item.clinicalDepartment,
          dailySalary: item.dailySalary,
          createdAt: item.createdAt,
          updatedAt: item.updatedAt,
          comment: item.comment,
        })
      }
    })
  }

  const handleCloseViewShift = () => {
    setSelectedShift(undefined)
  }

  const handleClickAdd = value => {
    if (value.comment) {
      setIsAddToCart(true)
      setSelectedShift(value)
    } else {
      addItemToCart(value)
    }
  }

  const handleClickView = value => {
    setIsAddToCart(false)
    setSelectedShift(value)
  }

  return (
    <>
      <div className='display-table' style={{ marginBottom: '40px' }}>
        <p className='check-shift-title'>シフトを探す</p>
        <div style={{ backgroundColor: '#FFFFFF' }}>
          <Row
            gutter={[16, 24]}
            className='table-row'
            style={{ marginRight: '0px', marginLeft: '0px' }}
          >
            <Col className='table-col' span={4}>
              日付
            </Col>
            <Col className='table-col' span={4}>
              クリニック
            </Col>
            <Col className='table-col' span={3}>
              診療科
            </Col>
            <Col className='table-col' span={6}>
              <span>開始時間</span>
              <span>終了時間</span>
            </Col>
            <Col className='table-col' span={2}>
              日給
            </Col>
            <Col className='table-col' span={2}>
              詳細
            </Col>
            <Col className='table-col' span={2}>
              応募
            </Col>
          </Row>
          {dataList.length > 0 && !isDoctorBlocked ? (
            dataList.map((value, index) => (
              <>
                <Row
                  gutter={[16, 24]}
                  className='table-row-data'
                  style={{
                    marginLeft: '0',
                    marginRight: '0',
                    marginBottom: '10px',
                  }}
                >
                  <Col className='table-col' span={4}>
                    {moment(value.startTime).format('YYYY/MM/DD')}
                  </Col>
                  <Col
                    className='table-col'
                    span={4}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <div className='available-shift-list row'>
                      {value.updatedAt === value.createdAt ? (
                        <p className='tag-name' style={{ margin: '5px 0px' }}>
                          New
                        </p>
                      ) : (
                        <p
                          className='tag-name update'
                          style={{ margin: '5px 0px' }}
                        >
                          Update
                        </p>
                      )}
                    </div>
                    <p style={{ margin: '0' }}>
                      {value.clinicalDepartment.clinic.name}
                    </p>
                  </Col>
                  <Col className='table-col' span={3}>
                    {i18n.t(`main.${value.clinicalDepartment.name}`)}
                  </Col>
                  <Col className='table-col' span={6}>
                    <span>{moment(value.startTime).format('HH:mm')}</span>
                    <span>~</span>
                    <span>{moment(value.endTime).format('HH:mm')}</span>
                  </Col>
                  <Col className='table-col' span={2}>
                    {numeralCurrency(value.dailySalary)}
                  </Col>
                  <Col className='table-col' span={2}>
                    <Button
                      onClick={() => {
                        handleClickView(value)
                      }}
                      disabled={!value.comment}
                      icon='pi pi-info'
                      className='p-button-secondary'
                    />
                  </Col>
                  <Col className='table-col' span={2}>
                    <div>
                      {cartItems.some(element => element.id === value.id) ? (
                        <Button
                          onClick={() => removeItemFromCart(value.id)}
                          icon='pi pi-times'
                          className='p-button-danger'
                        />
                      ) : (
                        <Button
                          onClick={() => {
                            handleClickAdd(value)
                          }}
                          icon='pi pi-plus'
                          className='p-button-success'
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              </>
            ))
          ) : (
            <Row gutter={[16, 24]}>
              <Col style={{ textAlign: 'center', fontWeight: '200' }} span={24}>
                {i18n.t('main.noRequestedAvailableShift')}
              </Col>
            </Row>
          )}
        </div>
        {isDoctorBlocked ? null : (
          <div className='wrap-see-more'>
            <a href={Router.doctorFindAvailableShift}>もっと見る</a>
          </div>
        )}
      </div>
      <div className='display-table-res'>
        <p className='check-shift-title'>シフトを探す</p>
        {dataList.length > 0 && !isDoctorBlocked ? (
          dataList.map((value, index) => (
            <>
              <Row
                gutter={[16, 24]}
                style={{
                  marginBottom: '15px',
                  border: '2px solid var(--borderColor)',
                  alignItems: 'normal',
                  marginLeft: '0',
                  marginRight: '0',
                  fontSize: '13px',
                }}
              >
                <Col
                  className='table-col'
                  span={6}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <span>{moment(value.startTime).format('YYYY/MM/DD')}</span>
                  <span>
                    {moment(value.startTime).format('HH:mm')}~
                    {moment(value.endTime).format('HH:mm')}
                  </span>
                </Col>

                <Col
                  className='table-col'
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  span={6}
                >
                  <div
                    className='available-shift-list row'
                    style={{ justifyContent: 'space-evenly' }}
                  >
                    {value.updatedAt === value.createdAt ? (
                      <p className='tag-name' style={{ margin: '0' }}>
                        New
                      </p>
                    ) : (
                      <p className='tag-name update' style={{ margin: '0' }}>
                        Update
                      </p>
                    )}
                  </div>
                  <p style={{ margin: '0' }}>
                    {value.clinicalDepartment.clinic.name}
                  </p>
                </Col>
                <Col
                  className='table-col'
                  span={4}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <span>{i18n.t(`main.${value.clinicalDepartment.name}`)}</span>
                  <span>{numeralCurrency(value.dailySalary)}</span>
                </Col>
                <Col className='table-col' span={4}>
                  <Button
                    onClick={() => {
                      handleClickView(value)
                    }}
                    disabled={!value.comment}
                    icon='pi pi-info'
                    className='p-button-secondary'
                  />
                </Col>
                <Col className='table-col' span={4}>
                  <div className='table-action'>
                    {cartItems.some(element => element.id === value.id) ? (
                      <Button
                        onClick={() => removeItemFromCart(value.id)}
                        icon='pi pi-times'
                        className='p-button-danger'
                      />
                    ) : (
                      <Button
                        onClick={() => {
                          handleClickAdd(value)
                        }}
                        icon='pi pi-plus'
                        className='p-button-success'
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </>
          ))
        ) : (
          <Row gutter={[16, 24]}>
            <Col style={{ textAlign: 'center', fontWeight: '200' }} span={24}>
              {i18n.t('main.noRequestedAvailableShift')}
            </Col>
          </Row>
        )}
        {isDoctorBlocked ? null : (
          <div style={{ marginBottom: '30px' }} className='wrap-see-more'>
            <a href={Router.doctorFindAvailableShift}>もっと見る</a>
          </div>
        )}
      </div>

      {!!selectedShift && (
        <DialogViewShift
          open={!!selectedShift}
          onClose={handleCloseViewShift}
          item={selectedShift}
          addItemToCart={addItemToCart}
          isAddToCart={isAddToCart}
        />
      )}
    </>
  )
}

export default TableDataAvailable
