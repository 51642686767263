import BreadCrumb from '@medical/components/Breadcrumb'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import { Switch } from 'antd'
import { Formik } from 'formik'
import { Button } from 'primereact/button'
import React from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'

const styles = {
  description: {
    color: '#db7093',
    marginLeft: '1rem',
  },
}

const EmailNotificationsSettingScene = ({
  settingOptions: { isReceivedEmailNotification },
  onSubmit,
  i18n,
}) => {
  const validationSchema = Yup.object().shape({
    isSentEmailToAllDoctors: Yup.boolean(),
  })

  return (
    <div className='email-notifications-setting'>
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('doctor.emailNotificationsSetting.title')}
          <BreadCrumb
            items={[
              { title: i18n.t('doctor.emailNotificationsSetting.title') },
            ]}
          />
        </div>
      </div>
      <div className='container is-max'>
        <div className='box'>
          <Formik
            initialValues={{
              isReceivedEmailNotification,
              confirm: false,
            }}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={onSubmit}
            render={formikProps => {
              const {
                values,
                setTouched,
                validateForm,
                setFieldValue,
              } = formikProps
              return (
                <div>
                  <div className='tr'>
                    <div className='th'>
                      {i18n.t(
                        'doctor.emailNotificationsSetting.isReceivedEmailNotification'
                      )}
                    </div>
                    <div className='td'>
                      <div className='checkbox-item toggle-published'>
                        <Switch
                          checkedChildren={i18n.t('main.isSent')}
                          unCheckedChildren={i18n.t('main.isNotSent')}
                          name='isReceivedEmailNotification'
                          checked={values.isReceivedEmailNotification}
                          onChange={val =>
                            setFieldValue('isReceivedEmailNotification', val)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className='tr' style={styles.description}>
                    {i18n.t('doctor.emailNotificationsSetting.note')}
                  </div>
                  <div className='modal-bottom'>
                    <Link to='../'>
                      <Button
                        label={i18n.t(
                          'doctor.emailNotificationsSetting.button.back'
                        )}
                      />
                    </Link>
                    <Button
                      label={i18n.t(
                        'doctor.emailNotificationsSetting.button.update'
                      )}
                      className='p-button p-component outline-red p-button-text-only'
                      onClick={() => {
                        validateForm().then(response => {
                          if (!Object.keys(response).length) {
                            setFieldValue('confirm', true)
                          } else {
                            setTouched(response)
                          }
                        })
                      }}
                    />
                  </div>

                  <ConfirmationModal
                    {...formikProps}
                    confirmationMessage={i18n.t(
                      'doctor.emailNotificationsSetting.update.confirmation'
                    )}
                  />
                </div>
              )
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default EmailNotificationsSettingScene
