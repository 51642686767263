import React from 'react'
import { Button } from 'primereact/button'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import { InputTextarea } from 'primereact/inputtextarea'
import { Checkbox, TimePicker, Input } from 'antd'
import { Formik, ErrorMessage } from 'formik'
import moment from 'moment'
import * as Yup from 'yup'
const RecruitmentPostApplyScene = ({
  i18n,
  onSubmit,
  doctor,
  recruitmentPost,
  recruitmentDoctorData,
  departmentNames,
}) => {
  const renderWorkPattern = value => {
    switch (value) {
      case 'PERMANENT_DOCTOR':
        return '常勤'
      case 'REGULAR_SHIFT_DOCTOR':
        return '定期非常勤'
      case 'IRREGULAR_SHIFT_DOCTOR':
        return '不定期非常勤'
      case 'TEMPORARY_DOCTOR':
        return 'スポット'
    }
  }
  const validationSchema = Yup.object()
    .shape({
      noteOpenTime: Yup.string()
        .trim()
        .required('勤務開始希望時期を入力してください'),
    })
    .test('atLeastCheckOne', '選択してください。', value => {
      if (
        value.isOnMonday ||
        value.isOnTuesday ||
        value.isOnWednesday ||
        value.isOnThursday ||
        value.isOnFriday ||
        value.isOnSaturday ||
        value.isOnSunday ||
        value.isOnHoliday
      ) {
        return true
      }
      return false
    })
  const disabled =
    recruitmentDoctorData && recruitmentDoctorData.status !== 'NOT_ACTION'
  return (
    <Formik
      enableReinitialize
      initialValues={{
        remark:
          recruitmentDoctorData && recruitmentDoctorData.remark
            ? recruitmentDoctorData.remark
            : '',
        confirm: false,
        isOnMonday: recruitmentDoctorData
          ? recruitmentDoctorData.startTimeMonday &&
            recruitmentDoctorData.endTimeMonday
          : false,
        isOnTuesday: recruitmentDoctorData
          ? recruitmentDoctorData.startTimeTuesday &&
            recruitmentDoctorData.endTimeTuesday
          : false,
        isOnWednesday: recruitmentDoctorData
          ? recruitmentDoctorData.startTimeWednesday &&
            recruitmentDoctorData.endTimeWednesday
          : false,
        isOnThursday: recruitmentDoctorData
          ? recruitmentDoctorData.startTimeThursday &&
            recruitmentDoctorData.endTimeThursday
          : false,
        isOnFriday: recruitmentDoctorData
          ? recruitmentDoctorData.startTimeFriday &&
            recruitmentDoctorData.endTimeFriday
          : false,
        isOnSaturday: recruitmentDoctorData
          ? recruitmentDoctorData.startTimeSaturday &&
            recruitmentDoctorData.endTimeSaturday
          : false,
        isOnSunday: recruitmentDoctorData
          ? recruitmentDoctorData.startTimeSunday &&
            recruitmentDoctorData.endTimeSunday
          : false,
        isOnHoliday: recruitmentDoctorData
          ? recruitmentDoctorData.startTimeHoliday &&
            recruitmentDoctorData.endTimeHoliday
          : false,
        startTimeMonday:
          recruitmentDoctorData && recruitmentDoctorData.startTimeMonday
            ? recruitmentDoctorData.startTimeMonday
            : moment('09:00:00', 'HH:mm'),
        endTimeMonday:
          recruitmentDoctorData && recruitmentDoctorData.endTimeMonday
            ? recruitmentDoctorData.endTimeMonday
            : moment('21:00:00', 'HH:mm'),
        startTimeTuesday:
          recruitmentDoctorData && recruitmentDoctorData.startTimeTuesday
            ? recruitmentDoctorData.startTimeTuesday
            : moment('09:00:00', 'HH:mm'),
        endTimeTuesday:
          recruitmentDoctorData && recruitmentDoctorData.endTimeTuesday
            ? recruitmentDoctorData.endTimeTuesday
            : moment('21:00:00', 'HH:mm'),
        startTimeWednesday:
          recruitmentDoctorData && recruitmentDoctorData.startTimeWednesday
            ? recruitmentDoctorData.startTimeWednesday
            : moment('09:00:00', 'HH:mm'),
        endTimeWednesday:
          recruitmentDoctorData && recruitmentDoctorData.endTimeWednesday
            ? recruitmentDoctorData.endTimeWednesday
            : moment('21:00:00', 'HH:mm'),
        startTimeThursday:
          recruitmentDoctorData && recruitmentDoctorData.startTimeThursday
            ? recruitmentDoctorData.startTimeThursday
            : moment('09:00:00', 'HH:mm'),
        endTimeThursday:
          recruitmentDoctorData && recruitmentDoctorData.endTimeThursday
            ? recruitmentDoctorData.endTimeThursday
            : moment('21:00:00', 'HH:mm'),
        startTimeFriday:
          recruitmentDoctorData && recruitmentDoctorData.startTimeFriday
            ? recruitmentDoctorData.startTimeFriday
            : moment('09:00:00', 'HH:mm'),
        endTimeFriday:
          recruitmentDoctorData && recruitmentDoctorData.endTimeFriday
            ? recruitmentDoctorData.endTimeFriday
            : moment('21:00:00', 'HH:mm'),
        startTimeSaturday:
          recruitmentDoctorData && recruitmentDoctorData.startTimeSaturday
            ? recruitmentDoctorData.startTimeSaturday
            : moment('09:00:00', 'HH:mm'),
        endTimeSaturday:
          recruitmentDoctorData && recruitmentDoctorData.endTimeSaturday
            ? recruitmentDoctorData.endTimeSaturday
            : moment('21:00:00', 'HH:mm'),
        startTimeSunday:
          recruitmentDoctorData && recruitmentDoctorData.startTimeSunday
            ? recruitmentDoctorData.startTimeSunday
            : moment('09:00:00', 'HH:mm'),
        endTimeSunday:
          recruitmentDoctorData && recruitmentDoctorData.endTimeSunday
            ? recruitmentDoctorData.endTimeSunday
            : moment('21:00:00', 'HH:mm'),
        startTimeHoliday:
          recruitmentDoctorData && recruitmentDoctorData.startTimeHoliday
            ? recruitmentDoctorData.startTimeHoliday
            : moment('09:00:00', 'HH:mm'),
        endTimeHoliday:
          recruitmentDoctorData && recruitmentDoctorData.endTimeHoliday
            ? recruitmentDoctorData.endTimeHoliday
            : moment('21:00:00', 'HH:mm'),
        noteOpenTime: recruitmentDoctorData
          ? recruitmentDoctorData.desireStartMoment
          : '',
        confirmationMessage: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={formikProps => {
        const { values, setFieldValue, validateForm, setTouched } = formikProps
        const format = 'HH:mm'
        const { RangePicker } = TimePicker
        return (
          <React.Fragment>
            <div className='modal-title' style={{ fontWeight: 'bold' }}>
              {`${renderWorkPattern(
                recruitmentPost.recruitmentDoctorType
              )}医師募集`}
            </div>
            <div className='tr'>
              <div className='th '>
                {i18n.t('doctor.recruimentApply.recruimentTitle')}
              </div>
              <div className='td'>{recruitmentPost.title}</div>
            </div>
            <div className='tr'>
              <div className='th '>{i18n.t('staff.activityLogs.doctor')}</div>
              <div className='td'>{doctor.fullName}</div>
            </div>
            <div className='tr'>
              <div className='th '>
                {i18n.t('doctor.recruimentApply.email')}
              </div>
              <div className='td'>{doctor.email}</div>
            </div>
            <div className='tr'>
              <div className='th '>{i18n.t('doctorClinic.name')}</div>
              <div className='td'>{`${
                recruitmentPost.clinicalDepartment.clinic.name
              }_${
                departmentNames[recruitmentPost.clinicalDepartment.name]
              }`}</div>
            </div>
            <div className='tr'>
              <div className='th '>現在のCAPSでの勤務形態</div>
              <div className='td'>{renderWorkPattern(doctor.workPattern)}</div>
            </div>
            <div className='tr'>
              <div className='th '>{i18n.t('doctor.recruimentApply.type')}</div>
              <div className='td'>
                {renderWorkPattern(recruitmentPost.recruitmentDoctorType)}
              </div>
            </div>

            <div className='tr'>
              <div className='th ' style={{ marginBottom: 'auto' }}>
                {i18n.t('doctor.recruimentApply.workables')}
              </div>
              <div className='td'>
                <div className='time-rest row'>
                  <div className='checkbox col-4'>
                    <div className='row'>
                      <div>
                        <Checkbox
                          checked={values.isOnMonday}
                          onChange={e => {
                            setFieldValue('isOnMonday', e.target.checked)
                          }}
                          disabled={disabled}
                        />
                      </div>
                      <div style={{ margin: '0px 8px' }}>月</div>
                    </div>
                  </div>
                  <div>
                    <RangePicker
                      format={format}
                      value={[
                        moment(values.startTimeMonday),
                        moment(values.endTimeMonday),
                      ]}
                      disabledHours={() => [0, 1, 2, 3, 4, 5, 6]}
                      hideDisabledOptions
                      allowClear={false}
                      placeholder={['開始時刻', '終了時刻']}
                      onChange={value => {
                        setFieldValue('startTimeMonday', value[0].format())
                        setFieldValue('endTimeMonday', value[1].format())
                      }}
                      disabled={disabled}
                    />
                  </div>
                </div>
                <div className='time-rest row'>
                  <div className='checkbox col-4'>
                    <div className='row'>
                      <div>
                        <Checkbox
                          checked={values.isOnTuesday}
                          onChange={e => {
                            setFieldValue('isOnTuesday', e.target.checked)
                          }}
                          disabled={disabled}
                        />
                      </div>
                      <div style={{ margin: '0px 8px' }}>火</div>
                    </div>
                  </div>
                  <div>
                    <RangePicker
                      format={format}
                      value={[
                        moment(values.startTimeTuesday),
                        moment(values.endTimeTuesday),
                      ]}
                      disabledHours={() => [0, 1, 2, 3, 4, 5, 6]}
                      hideDisabledOptions
                      allowClear={false}
                      placeholder={['開始時刻', '終了時刻']}
                      onChange={value => {
                        setFieldValue('startTimeTuesday', value[0].format())
                        setFieldValue('endTimeTuesday', value[1].format())
                      }}
                      disabled={disabled}
                    />
                  </div>
                </div>
                <div className='time-rest row'>
                  <div className='checkbox col-4'>
                    <div className='row'>
                      <div>
                        <Checkbox
                          checked={values.isOnWednesday}
                          onChange={e => {
                            setFieldValue('isOnWednesday', e.target.checked)
                          }}
                          disabled={disabled}
                        />
                      </div>
                      <div style={{ margin: '0px 8px' }}>水</div>
                    </div>
                  </div>
                  <div>
                    <RangePicker
                      format={format}
                      disabledHours={() => [0, 1, 2, 3, 4, 5, 6]}
                      hideDisabledOptions
                      allowClear={false}
                      placeholder={['開始時刻', '終了時刻']}
                      onChange={value => {
                        setFieldValue('startTimeWednesday', value[0].format())
                        setFieldValue('endTimeWednesday', value[1].format())
                      }}
                      value={[
                        moment(values.startTimeWednesday),
                        moment(values.endTimeWednesday),
                      ]}
                      disabled={disabled}
                    />
                  </div>
                </div>
                <div className='time-rest row'>
                  <div className='checkbox col-4'>
                    <div className='row'>
                      <div>
                        <Checkbox
                          checked={values.isOnThursday}
                          onChange={e => {
                            setFieldValue('isOnThursday', e.target.checked)
                          }}
                          disabled={disabled}
                        />
                      </div>
                      <div style={{ margin: '0px 8px' }}>木</div>
                    </div>
                  </div>
                  <div>
                    <RangePicker
                      format={format}
                      disabledHours={() => [0, 1, 2, 3, 4, 5, 6]}
                      hideDisabledOptions
                      allowClear={false}
                      placeholder={['開始時刻', '終了時刻']}
                      onChange={value => {
                        setFieldValue('startTimeThursday', value[0].format())
                        setFieldValue('endTimeThursday', value[1].format())
                      }}
                      value={[
                        moment(values.startTimeThursday),
                        moment(values.endTimeThursday),
                      ]}
                      disabled={disabled}
                    />
                  </div>
                </div>
                <div className='time-rest row'>
                  <div className='checkbox col-4'>
                    <div className='row'>
                      <div>
                        <Checkbox
                          checked={values.isOnFriday}
                          onChange={e => {
                            setFieldValue('isOnFriday', e.target.checked)
                          }}
                          disabled={disabled}
                        />
                      </div>
                      <div style={{ margin: '0px 8px' }}>金</div>
                    </div>
                  </div>
                  <div>
                    <RangePicker
                      format={format}
                      disabledHours={() => [0, 1, 2, 3, 4, 5, 6]}
                      hideDisabledOptions
                      allowClear={false}
                      placeholder={['開始時刻', '終了時刻']}
                      onChange={value => {
                        setFieldValue('startTimeFriday', value[0].format())
                        setFieldValue('endTimeFriday', value[1].format())
                      }}
                      value={[
                        moment(values.startTimeFriday),
                        moment(values.endTimeFriday),
                      ]}
                      disabled={disabled}
                    />
                  </div>
                </div>
                <div className='time-rest row'>
                  <div className='checkbox col-4'>
                    <div className='row'>
                      <div>
                        <Checkbox
                          checked={values.isOnSaturday}
                          onChange={e => {
                            setFieldValue('isOnSaturday', e.target.checked)
                          }}
                          disabled={disabled}
                        />
                      </div>
                      <div style={{ margin: '0px 8px' }}>土</div>
                    </div>
                  </div>
                  <div>
                    <RangePicker
                      format={format}
                      disabledHours={() => [0, 1, 2, 3, 4, 5, 6]}
                      hideDisabledOptions
                      allowClear={false}
                      placeholder={['開始時刻', '終了時刻']}
                      onChange={value => {
                        setFieldValue('startTimeSaturday', value[0].format())
                        setFieldValue('endTimeSaturday', value[1].format())
                      }}
                      value={[
                        moment(values.startTimeSaturday),
                        moment(values.endTimeSaturday),
                      ]}
                      disabled={disabled}
                    />
                  </div>
                </div>
                <div className='time-rest row'>
                  <div className='checkbox col-4'>
                    <div className='row'>
                      <div>
                        <Checkbox
                          checked={values.isOnSunday}
                          onChange={e => {
                            setFieldValue('isOnSunday', e.target.checked)
                          }}
                          disabled={disabled}
                        />
                      </div>
                      <div style={{ margin: '0px 8px' }}>日</div>
                    </div>
                  </div>
                  <div>
                    <RangePicker
                      format={format}
                      disabledHours={() => [0, 1, 2, 3, 4, 5, 6]}
                      hideDisabledOptions
                      allowClear={false}
                      placeholder={['開始時刻', '終了時刻']}
                      onChange={value => {
                        setFieldValue('startTimeSunday', value[0].format())
                        setFieldValue('endTimeSunday', value[1].format())
                      }}
                      value={[
                        moment(values.startTimeSunday),
                        moment(values.endTimeSunday),
                      ]}
                      disabled={disabled}
                    />
                  </div>
                </div>
                <div className='time-rest row'>
                  <div className='checkbox col-4'>
                    <div className='row'>
                      <div>
                        <Checkbox
                          checked={values.isOnHoliday}
                          onChange={e => {
                            setFieldValue('isOnHoliday', e.target.checked)
                          }}
                          disabled={disabled}
                        />
                      </div>
                      <div style={{ margin: '0px 8px' }}>祝</div>
                    </div>
                  </div>
                  <div>
                    <RangePicker
                      format={format}
                      disabledHours={() => [0, 1, 2, 3, 4, 5, 6]}
                      hideDisabledOptions
                      allowClear={false}
                      placeholder={['開始時刻', '終了時刻']}
                      onChange={value => {
                        setFieldValue('startTimeHoliday', value[0].format())
                        setFieldValue('endTimeHoliday', value[1].format())
                      }}
                      value={[
                        moment(values.startTimeHoliday),
                        moment(values.endTimeHoliday),
                      ]}
                      disabled={disabled}
                    />
                  </div>
                </div>
                <div className='time-rest row'>
                  <ErrorMessage
                    name='undefined'
                    className='alert'
                    component='div'
                  />
                </div>
              </div>
            </div>
            <div className='tr'>
              <div className='th '>
                {i18n.t('doctor.recruimentApply.desiredTime')}
              </div>
              <div className='td row'>
                <Input
                  placeholder='今すぐにでも/o月から勤務開始希望等'
                  value={values.noteOpenTime}
                  onChange={e => {
                    setFieldValue('noteOpenTime', e.target.value)
                  }}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className='tr'>
              <div className='th'></div>
              <div className='td'>
                <ErrorMessage
                  name='noteOpenTime'
                  className='alert'
                  component='div'
                />
              </div>
            </div>
            <div className='tr'>
              <div className='th '>備考欄</div>
              <div className='td row'>
                <InputTextarea
                  name='remark'
                  value={values.remark}
                  placeholder='例：他拠点での勤務も可能です/勤務開始時期については相談させてください...等'
                  disabled={disabled}
                  rows={8}
                  autoResize
                  onChange={e => {
                    setFieldValue('remark', e.target.value)
                  }}
                />
              </div>
            </div>
            <div className='tr'>
              <div className='th'></div>
              <div className='td'>
                <ErrorMessage name='remark' className='alert' component='div' />
              </div>
            </div>
            <div className='div modal-bottom'>
              {recruitmentDoctorData &&
                recruitmentDoctorData.status === 'NOT_ACTION' && (
                  <>
                    <Button
                      className='cancel-button'
                      label='キャンセル'
                      onClick={() => {
                        setFieldValue(
                          'confirmationMessage',
                          'キャンセルしますか？'
                        )
                        setFieldValue('functionName', 'delete')
                        setFieldValue('confirm', true)
                      }}
                    />
                    <Button
                      className='reflect-button'
                      label='編集する'
                      onClick={() => {
                        validateForm().then(response => {
                          if (!Object.keys(response).length) {
                            setFieldValue(
                              'confirmationMessage',
                              '編集しますか？'
                            )
                            setFieldValue('functionName', 'update')
                            setFieldValue('confirm', true)
                          } else {
                            setTouched(response)
                          }
                        })
                      }}
                    />
                  </>
                )}
              {!recruitmentDoctorData && (
                <Button
                  className='reflect-button'
                  label='応募する'
                  onClick={() => {
                    validateForm().then(response => {
                      if (!Object.keys(response).length) {
                        setFieldValue(
                          'confirmationMessage',
                          '応募しますか? 応募が完了した後、内容を事務局側で確認させていただき改めてご連絡致します。事務局確認後は応募内容の変更はできかねます。'
                        )
                        setFieldValue('functionName', 'create')
                        setFieldValue('confirm', true)
                      } else {
                        setTouched(response)
                      }
                    })
                  }}
                />
              )}
            </div>
            <ConfirmationModal
              {...formikProps}
              confirmationMessage={values.confirmationMessage}
            />
          </React.Fragment>
        )
      }}
    />
  )
}
export default RecruitmentPostApplyScene
