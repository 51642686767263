import gql from 'graphql-tag'
import { CLINICAL_DEPARTMENTS_QUERY } from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'

export const STAFF_WORKTENDENCY = gql`
  query Query($doctorId: ID!) {
    doctorGraph(doctorId: $doctorId) {
      time
      dayWork
      clinic
    }
    ...CLINICAL_DEPARTMENTS_QUERY
  }
  ${CLINICAL_DEPARTMENTS_QUERY}
`
