import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, NotFound, ProgressSpinner } from '@medical/components'
import { UPDATE_MOVING_EXPENSES_REGISTRATION } from '@medical/constant/permissions'
import { checkStaffPermission, onPageChange } from '@medical/libs'
import { HANDLE_DELETE_MOVING_EXPENSES_DETAIL } from '@medical/pages/staff/MovingExpenses/ListMovingExpenses/MovingExpensesDetail.graphql'
import { useCustom } from '@medical/provider/context'
import moment from 'moment'
import queryString from 'query-string'
import React, { useEffect } from 'react'

import {
  GET_CLINIC,
  GET_LIST_MOVING_EXPENSES_REGISTRATION_GQL,
} from './ListRegistration.graphql'
import ListRegistrationScene from './ListRegistrationScene'

const ListMovingExpensesRegistration = ({ history, location: { search } }) => {
  const [{ i18n, popup }] = useCustom()
  const [deleteTransport] = useMutation(HANDLE_DELETE_MOVING_EXPENSES_DETAIL)

  const { loading: clinicLoading, error: clinicError, data: clinic } = useQuery(
    GET_CLINIC
  )
  const {
    page = 1,
    rowsPerPage = 20,
    fullname,
    startTimeGte,
    endTimeLte,
    clinicname,
  } = queryString.parse(search)
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  const { loading, error, data, refetch } = useQuery(
    GET_LIST_MOVING_EXPENSES_REGISTRATION_GQL,
    {
      variables: {
        skip,
        first,
        orderBy: 'updatedAt_DESC',
        where: {
          category: 'CLINIC_TRANFFIC_FEE',
          status: null,
          deletedAt: null,
          isHidden: false,
          Doctor: {
            OR: [
              {
                unSigned_contains: fullname
                  ? fullname
                      .replace(/ /g, '')
                      .replace(/　/g, '')
                      .toLowerCase()
                  : '',
              },
            ],
            deletedAt: null,
          },
          Clinic_TransportationExpense_clinicToClinic: {
            name_contains: clinicname,
          },
          createdAt_lte: endTimeLte,
          createdAt_gte: startTimeGte,
        },
      },
      fetchPolicy: 'network-only',
    }
  )
  useEffect(() => {
    refetch()
  })
  const isUpdateMovingExpenses = checkStaffPermission({
    functionName: UPDATE_MOVING_EXPENSES_REGISTRATION,
  })

  if (loading || clinicLoading) return <ProgressSpinner fullHeight />
  if (error || clinicError) return <ErrorComponent error={error} />
  if (!clinic || !data) {
    return <NotFound />
  }

  const onSubmit = async (
    { transportationsId },
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      await deleteTransport({
        variables: { transportationsId, reverse: false },
      })
      popup.success(i18n.t('main.movingExpenses.deleteSuccess'))
      setSubmitting(false)
      resetForm()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
      setFieldValue('confirm', false)
    }
  }
  const getIndexDuplicatesDoctor = dataDoctor => {
    const arrIndex = []
    let check = 0
    arrIndex[check] = 1
    data.transportationExpensesConnection.edges
      .sort((a, b) => moment(a.node.createdAt) - moment(b.node.createdAt))
      .sort((a, b) => moment(a.node.startTime) - moment(b.node.startTime))
      .sort((a, b) => {
        if (a.node.doctor.lastname < b.node.doctor.lastname) {
          return -1
        }
        if (a.node.doctor.lastname > b.node.doctor.lastname) {
          return 1
        }
        if (a.node.doctor.firstname < b.node.doctor.lastname) {
          return -1
        }
        if (a.node.doctor.firstname > b.node.doctor.lastname) {
          return 1
        }
        return 0
      })
    for (let i = 0; i < dataDoctor.length - 1; i += 1) {
      if (
        dataDoctor[i].node.doctor.lastname +
          dataDoctor[i].node.doctor.firstname ===
        dataDoctor[i + 1].node.doctor.lastname +
          dataDoctor[i + 1].node.doctor.firstname
      ) {
        arrIndex[i + 1] = 0
        arrIndex[check] += 1
      } else {
        arrIndex[i + 1] = 1
        check = i + 1
      }
    }
    return arrIndex
  }
  // const arrIndexSpan = getIndexDuplicatesDoctor(
  //   data.transportationExpensesConnection.edges
  // )
  return (
    <ListRegistrationScene
      ListRegistration={data.transportationExpensesConnection.edges}
      // arrIndexSpan={arrIndexSpan}
      fullname={fullname}
      startTimeGte={startTimeGte}
      endTimeLte={endTimeLte}
      clinicname={clinicname}
      rowsPerPage={parseInt(rowsPerPage, 10)}
      page={parseInt(page, 10)}
      isUpdateMovingExpenses={isUpdateMovingExpenses}
      Clinic={clinic}
      i18n={i18n}
      onSubmit={onSubmit}
      history={history}
      onPageChange={onPageChange(history, search)}
      count={data.transportationExpensesConnection.aggregate.count}
    />
  )
}
export default ListMovingExpensesRegistration
