import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import {
  STAFF_CREATE_GROUP,
  STAFF_DELETE_GROUP,
  STAFF_UPDATE_GROUP,
} from '@medical/constant/permissions'
import { checkStaffPermission } from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import React from 'react'

import { DELETE_STAFF_GROUP, STAFF_GROUPS } from './StaffGroupsList.graphql'
import StaffGroupsListScene from './StaffGroupsListScene'

const StaffGroupsList = () => {
  const [{ i18n, popup }] = useCustom()
  const { loading, error, data } = useQuery(STAFF_GROUPS)
  const isCreatePermitted = checkStaffPermission({
    functionName: STAFF_CREATE_GROUP,
  })
  const isDeletePermitted = checkStaffPermission({
    functionName: STAFF_DELETE_GROUP,
  })
  const isUpdatePermitted = checkStaffPermission({
    functionName: STAFF_UPDATE_GROUP,
  })
  const [deleteStaffGroup] = useMutation(DELETE_STAFF_GROUP, {
    refetchQueries: [
      {
        query: STAFF_GROUPS,
      },
    ],
    awaitRefetchQueries: true,
  })

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const onSubmit = async ({ staffGroupId }, { setSubmitting, resetForm }) => {
    try {
      await deleteStaffGroup({
        variables: {
          staffGroupId,
        },
      })
      popup.success(i18n.t('staff.staffGroup.deleteSuccess'))
      setSubmitting(false)
      resetForm()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }

  return (
    <StaffGroupsListScene
      i18n={i18n}
      staffGroups={data.staffGroups}
      onSubmit={onSubmit}
      isCreatePermitted={isCreatePermitted}
      isDeletePermitted={isDeletePermitted}
      isUpdatePermitted={isUpdatePermitted}
    />
  )
}

export default StaffGroupsList
