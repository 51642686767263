import React from 'react'
import '../AvailableShiftStyle.css'
import { Checkbox } from 'antd'

const WorkContent = ({ i18n, setFieldValue, values }) => {
  let selectedDepartments = [...values.departments]
  const getSelectedDepartments = (checked, checkedValue, fieldValue) => {
    if (checked) {
      Array.isArray(checkedValue)
        ? selectedDepartments.push(...checkedValue)
        : selectedDepartments.push(checkedValue)
    } else {
      Array.isArray(checkedValue)
        ? (selectedDepartments = selectedDepartments.filter(
            (index, value) => checkedValue.indexOf(index) === -1
          ))
        : selectedDepartments.splice(
            selectedDepartments.indexOf(checkedValue),
            1
          )
    }
    setFieldValue(fieldValue, selectedDepartments)
  }
  return (
    <>
      <div className='content-item'>
        <Checkbox
          inputId='pediatric'
          name='pediatric'
          value='CHILDREN_MEDICINE'
          onChange={event => {
            getSelectedDepartments(
              event.target.checked,
              'CHILDREN_MEDICINE',
              'departments'
            )
          }}
          checked={values.departments.indexOf('CHILDREN_MEDICINE') !== -1}
        />
        <label htmlFor='pediatric'>
          {i18n.t('doctor.availableShifts.pediatric')}
        </label>
      </div>
      <div className='content-item'>
        <Checkbox
          inputId='internalMedicine'
          name='internalMedicine'
          value='INTERNAL_MEDICINE'
          onChange={event => {
            getSelectedDepartments(
              event.target.checked,
              'INTERNAL_MEDICINE',
              'departments'
            )
          }}
          checked={values.departments.indexOf('INTERNAL_MEDICINE') !== -1}
        />
        <label htmlFor='internalMedicine'>
          {i18n.t('doctor.availableShifts.internalMedicine')}
        </label>
      </div>
      <div className='content-item'>
        <Checkbox
          inputId='dedicatedToVaccination'
          name='dedicatedToVaccination'
          value={[['CHILDREN_VACCINE_MEDICINE', 'INTERNAL_VACCINE_MEDICINE']]}
          onChange={event => {
            getSelectedDepartments(
              event.target.checked,
              ['CHILDREN_VACCINE_MEDICINE', 'INTERNAL_VACCINE_MEDICINE'],
              'departments'
            )
          }}
          checked={
            values.departments.indexOf('CHILDREN_VACCINE_MEDICINE') !== -1 ||
            values.departments.indexOf('INTERNAL_VACCINE_MEDICINE') !== -1
          }
        />
        <label htmlFor='dedicatedToVaccination'>
          {i18n.t('doctor.availableShifts.dedicatedToVaccination')}
        </label>
      </div>
    </>
  )
}

export default WorkContent
