import '../SignupComponent/style.css'

import { useCustom } from '@medical/provider/context'
import { Button } from 'primereact/button'
import React from 'react'

const WarningDoctorRegister = ({ history }) => {
  const [{ i18n }] = useCustom()
  return (
    <div className='signup-bg'>
      <div className='signup-box'>
        <div>
          <div className='signup-title'>新規登録</div>
          <div className='notice-title'>ご登録申請ありがとうございます。</div>
          <div className='signup-successTitle'>
            アカウントの作成に関しては、
          </div>
          <div className='signup-successTitle'>
            改めてドクターサポート事務局よりご連絡致します。
          </div>
          <div className='warning-content pb-20'>
            今後ともよろしくお願いします
          </div>
          {/* <div className='signup-successTitle'>
            今後ともよろしくお願いします
          </div> */}
        </div>
        <div className='submit-button'>
          <Button
            type='submit'
            className='signup-button signup-button-warning p-button-primary'
            onClick={() => {
              history.push('./login')
            }}
            label={i18n.t('doctor.signupSuccess.buttonDirecttoLogin')}
          />
        </div>
      </div>
    </div>
  )
}

export default WarningDoctorRegister
