import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, NotFound, ProgressSpinner } from '@medical/components'
import {
  STAFF_DELETE_QUESTION,
  STAFF_UPDATE_QUESTION,
} from '@medical/constant/permissions'
import { checkStaffPermission } from '@medical/libs'
import { QUESTION_CATEGORY } from '@medical/libs/general.graphql'
import { useCustom } from '@medical/provider/context'
import Router from '@medical/routes/router'
import React from 'react'
import { CREATE_ACTIVITY } from '@medical/pages/doctor/AvailableShift/AvailableShift.graphql'
import QuestionsForm from '../components/QuestionsForm'
import {
  DELETE_QUESTION,
  GET_QUESTION,
  UPDATE_QUESTION,
} from './QuestionsUpdate.graphql'

const QuestionUpdate = ({
  match: {
    params: { questionId },
  },
  history,
}) => {
  const [{ i18n, popup }] = useCustom()
  const [updateQuestion] = useMutation(UPDATE_QUESTION)
  const [deleteQuestion] = useMutation(DELETE_QUESTION)
  const [staffCreateActivity] = useMutation(CREATE_ACTIVITY)
  const { loading, error, data } = useQuery(GET_QUESTION, {
    variables: {
      where: {
        id: questionId,
      },
    },
  })
  const { loading: loadingCategorys, data: categorys } = useQuery(
    QUESTION_CATEGORY,
    {
      variables: {
        orderBy: 'createdAt_ASC',
        where: {
          deletedAt: null,
        },
      },
      fetchPolicy: 'network-only',
    }
  )
  const isUpdatePermitted = checkStaffPermission({
    functionName: STAFF_UPDATE_QUESTION,
  })
  const isDeletePermitted = checkStaffPermission({
    functionName: STAFF_DELETE_QUESTION,
  })
  if (loading || loadingCategorys) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  const listCategory = categorys.questionCategories
    ? categorys.questionCategories.map(item => ({
        value: item.id,
        description: item.content,
        createdAt: item.createdAt,
      }))
    : []
  const {
    questionsConnection: { edges },
  } = data
  const question = edges[0] ? edges[0].node : {}
  if (!question.id) {
    return <NotFound />
  }
  const onSubmit = async (
    { id, title, content, category, isDelete },
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      if (isDelete) {
        await deleteQuestion({
          variables: {
            where: { id: questionId },
          },
        })
        staffCreateActivity({
          variables: {
            activity: 'STAFF_DELETE_QUESTION',
          },
        })
      } else {
        await updateQuestion({
          variables: {
            where: {
              id,
            },
            data: {
              title: title ? title.trim() : '',
              content: content ? content.trim() : '',
              category: { connect: { id: category.value } },
            },
          },
        })
        staffCreateActivity({
          variables: {
            activity: 'STAFF_UPDATE_QUESTION',
          },
        })
      }
      popup.success('成功しました')
      setSubmitting(false)
      resetForm()
      history.push({
        pathname: Router.staffQuestions,
      })
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
      setFieldValue('confirm', false)
    }
  }
  const categorySearch = () => {
    const categoryId = question.category ? question.category.id : null
    for (const item of listCategory) {
      if (item.value === categoryId) {
        return item
      }
    }
  }
  return (
    <QuestionsForm
      isDeletePermitted={isDeletePermitted}
      category={categorySearch()}
      question={question}
      listCategory={listCategory}
      onSubmit={onSubmit}
      isUpdatePermitted={isUpdatePermitted}
      submitLabel={i18n.t('staff.questions.updateLabel')}
      submitDeletLabel={i18n.t('staff.questions.deleteLabel')}
      confirmationMessage={i18n.t(
        'staff.questions.submitUpdateQuestionConfirmation'
      )}
      confirmationDeleteMessage={i18n.t(
        'staff.questions.submitDeleteQuestionConfirmation'
      )}
    />
  )
}

export default QuestionUpdate
