import BreadCrumb from '@medical/components/Breadcrumb'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import { Dropdown } from 'primereact/dropdown'
import Router from '@medical/routes/router'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Paginator } from 'primereact/paginator'
import React from 'react'
import { Link } from 'react-router-dom'

const PortalListScene = ({
  i18n,
  edges,
  rowsPerPage,
  page,
  count,
  onPageChange,
  onSubmit,
  isCreatePermitted,
  isDeletePermitted,
  isUpdatePermitted,
  largeCategory,
  mediumCategory,
  largeTitle,
  mediumTitle,
  setLargeTitle,
  setMediumTitle,
  removeSearch,
}) => {
  const largeCategoryOptions = []
  const mediumCategoryOptions = []
  if (largeCategory.length > 0) {
    largeCategory.map(lc => {
      largeCategoryOptions.push({
        value: lc.largeTitle,
        description: lc.largeTitle,
      })
    })
  }
  if (mediumCategory.length > 0) {
    mediumCategory.map(mc => {
      mediumCategoryOptions.push({
        value: mc.mediumTitle,
        description: mc.mediumTitle,
      })
    })
  }
  const getIndexElementDuplicatesLargeTitle = data => {
    const arrIndex = []
    let check = 0
    arrIndex[check] = 1
    for (let i = 0; i < data.length - 1; i += 1) {
      if (data[i].node.largeTitle === data[i + 1].node.largeTitle) {
        arrIndex[i + 1] = 0
        arrIndex[check] += 1
      } else {
        arrIndex[i + 1] = 1
        check = i + 1
      }
    }
    return arrIndex
  }
  const getIndexElementDuplicatesMediumTitle = data => {
    const arrIndex = []
    let check = 0
    arrIndex[check] = 1
    for (let i = 0; i < data.length - 1; i += 1) {
      if (data[i].node.mediumTitle === data[i + 1].node.mediumTitle) {
        arrIndex[i + 1] = 0
        arrIndex[check] += 1
      } else {
        arrIndex[i + 1] = 1
        check = i + 1
      }
    }
    return arrIndex
  }
  const arrIndexLargeTitle = getIndexElementDuplicatesLargeTitle(edges)
  const arrIndexMediumTitle = getIndexElementDuplicatesMediumTitle(edges)
  return (
    <React.Fragment>
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('staff.menuBar.portalList')}
          <BreadCrumb items={[{ title: i18n.t('staff.menuBar.portalList') }]} />
        </div>
        {isCreatePermitted ? (
          <div className='staff-buttons'>
            <Link to={Router.staffPortalCreate}>
              <Button
                label='項目作成'
                icon='pi pi-plus'
                className='p-button-normal'
              />
            </Link>
          </div>
        ) : null}
      </div>
      <div className='container'>
        <div className='box'>
          <div className='search-parent'>
            <Dropdown
              value={largeTitle}
              options={largeCategoryOptions}
              option
              optionLabel='value'
              onChange={setLargeTitle}
              placeholder='大項目'
            />
            <div style={{ width: '10px' }} />
            <Dropdown
              value={mediumTitle}
              options={mediumCategoryOptions}
              optionLabel='value'
              onChange={setMediumTitle}
              placeholder='中項目'
            />
            {largeTitle.value || mediumTitle.value ? (
              <Button
                icon='pi pi-times'
                onClick={() => {
                  removeSearch()
                }}
                style={{
                  marginLeft: '20px',
                  marginRight: '1px',
                  height: '35px',
                }}
              />
            ) : null}
          </div>
          <Formik
            initialValues={{
              confirm: false,
              portalId: '',
              isHide: '',
              confirmationMessage: '',
            }}
            onSubmit={onSubmit}
            render={formikProps => (
              <div>
                <table className='table' style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th width='15%'>大項目</th>
                      <th width='15%'>中項目</th>
                      <th width='25%'>小項目</th>
                      <th width='15%'>{i18n.t('staff.announces.createdAt')}</th>
                      <th width='15%'>最後更新日</th>
                      <th width='12%'>最終更新者</th>
                      {isUpdatePermitted ? (
                        <th width='10%'>{i18n.t('main.action')}</th>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    {edges.map(({ node }, index) => (
                      <tr key={node.id}>
                        {arrIndexLargeTitle[index] !== 0 ? (
                          <td rowSpan={arrIndexLargeTitle[index]}>
                            {node.largeTitle}
                          </td>
                        ) : null}
                        {arrIndexMediumTitle[index] !== 0 ? (
                          <td rowSpan={arrIndexMediumTitle[index]}>
                            {node.mediumTitle}
                          </td>
                        ) : null}
                        <td
                          style={
                            node.isHide ? { backgroundColor: '#e6e6e6' } : null
                          }
                        >
                          {node.smallTitle}
                        </td>
                        <td
                          style={
                            node.isHide ? { backgroundColor: '#e6e6e6' } : null
                          }
                          className='center'
                        >
                          {moment(node.createdAt).format('LL (dd)')}
                        </td>
                        <td
                          style={
                            node.isHide ? { backgroundColor: '#e6e6e6' } : null
                          }
                          className='center'
                        >
                          {moment(node.updatedAt).format('LL (dd)')}
                        </td>
                        <td
                          style={
                            node.isHide ? { backgroundColor: '#e6e6e6' } : null
                          }
                        >
                          {node.updatedBy
                            ? `${node.updatedBy.lastname} ${node.updatedBy.firstname}`
                            : `${node.createdBy.lastname} ${node.createdBy.firstname}`}
                        </td>
                        {isUpdatePermitted ? (
                          <td
                            style={
                              node.isHide
                                ? { backgroundColor: '#e6e6e6' }
                                : null
                            }
                          >
                            <div className='action'>
                              {isUpdatePermitted || isDeletePermitted ? (
                                <Link
                                  to={Router.get(Router.staffPortalUpdate, {
                                    portalId: node.id,
                                  })}
                                >
                                  <Button icon='pi pi-pencil' />
                                </Link>
                              ) : null}
                              {isUpdatePermitted ? (
                                <Button
                                  onClick={() => {
                                    if (node.isHide)
                                      formikProps.setFieldValue(
                                        'confirmationMessage',
                                        '医師サイトへは表示させますか？'
                                      )
                                    else
                                      formikProps.setFieldValue(
                                        'confirmationMessage',
                                        '医師サイトへは非表示させますか？'
                                      )
                                    formikProps.setFieldValue(
                                      'portalId',
                                      node.id
                                    )
                                    formikProps.setFieldValue(
                                      'isHide',
                                      !node.isHide
                                    )
                                    formikProps.setFieldValue('confirm', true)
                                  }}
                                  icon={
                                    node.isHide
                                      ? 'pi pi-eye'
                                      : 'pi pi-eye-slash'
                                  }
                                />
                              ) : null}
                            </div>
                          </td>
                        ) : null}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <ConfirmationModal
                  {...formikProps}
                  confirmationMessage={formikProps.values.confirmationMessage}
                />
              </div>
            )}
          />
          <Paginator
            rows={rowsPerPage}
            totalRecords={count}
            first={(page - 1) * rowsPerPage}
            onPageChange={onPageChange}
            leftContent={
              <div className='paginator-total'>
                {i18n.t('main.paginationTotal')}: {count}
              </div>
            }
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default PortalListScene
