import { CLINICAL_DEPARTMENTS_QUERY } from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import gql from 'graphql-tag'

export const GET_LIST_MOVING_EXPENSES_OTHER = gql`
  query Query($where: TransportationExpenseWhereInput) {
    transportationExpenses(where: $where) {
      id
      startTime
      endTime
      url
      type
      departurePlace
      arrivalPlace
      cost
      status
      createdAt
      billImage
      comment
      clinic {
        id
        name
      }
      doctor {
        id
        lastname
        firstname
      }
    }
    ...CLINICAL_DEPARTMENTS_QUERY
  }
  ${CLINICAL_DEPARTMENTS_QUERY}
`
export const GET_CLINIC = gql`
  query WorkSchedules {
    clinics {
      id
      name
      nameKana
    }
  }
`
export const DOWNLOAD_IMG = gql`
  mutation downloadFile($key: String!) {
    downloadFile(key: $key)
  }
`
export const UPLOAD_IMG = gql`
  mutation staffUploadTransportationExpense(
    $fileImage: String
    $transportationId: ID
  ) {
    staffUploadTransportationExpense(
      fileImage: $fileImage
      transportationId: $transportationId
    )
  }
`
export const STAFF_UPDATE_TRANSPORTATION = gql`
  mutation staffUpdateTransportationExpense(
    $transportationId: ID!
    $doctorId: ID!
    $clinicId: ID
    $data: TransportationExpenseUpdateWithoutDoctorDataInput!
    $file: String
  ) {
    staffUpdateTransportationExpense(
      transportationId: $transportationId
      doctorId: $doctorId
      clinicId: $clinicId
      data: $data
      file: $file
    ) {
      id
      startTime
      endTime
      url
      type
      departurePlace
      arrivalPlace
      cost
      status
      billImage
    }
  }
`

export const GET_LIST_MOVING_EXPENSES_OTHER_GQL = gql`
  query TransportationExpensesConnection(
    $skip: Int
    $first: Int
    $orderBy: TransportationExpenseOrderByInput
    $where: TransportationExpenseWhereInput
  ) {
    transportationExpensesConnection(
      skip: $skip
      first: $first
      orderBy: $orderBy
      where: $where
    ) {
      aggregate(where: $where) {
        count
      }
      edges {
        node {
          id
          startTime
          endTime
          url
          type
          departurePlace
          arrivalPlace
          cost
          status
          createdAt
          clinic {
            id
            name
          }
          doctor {
            id
            lastname
            firstname
          }
        }
      }
    }
  }
`
export const TRANPORTATION_TYPE_SETTINGS = gql`
  query TransportationTypeSettings(
    $where: TransportationTypeSettingWhereInput
  ) {
    transportationTypeSettings(where: $where) {
      id
      maxCost
      type
      isApply
    }
  }
`
