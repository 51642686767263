import { ja } from '@medical/libs'
import moment from 'moment'
import { Calendar as PrimeCalendar } from '../../../fork/primereact/calendar'
import React from 'react'

const Calendar = ({
  value,
  name,
  handleChange,
  classDisabled,
  disabled,
  ...props
}) => (
  <PrimeCalendar
    readOnlyInput
    value={value ? moment(value).toDate() : undefined}
    onChange={handleChange}
    name={name}
    className={classDisabled && disabled ? 'is-disabled' : ''}
    showIcon={!disabled}
    disabled={disabled}
    dateFormat={ja.dateFormat}
    locale={ja}
    {...props}
  />
)

export default Calendar
