/* eslint-disable react/display-name */
import { useApolloClient, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import BreadCrumb from '@medical/components/Breadcrumb'
import {
  clinicalDepartmentNames,
  combineNames,
  dateValidation,
  sortAndFilterByClinicOrder,
} from '@medical/libs'
import styles from '@medical/pages/staff/MonthlyAvailableShift/Style'
import { useCustom } from '@medical/provider/context'
import Router from '@medical/routes/router'
import { Pagination, Table } from 'antd'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { MultiSelect } from 'primereact/multiselect'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { GET_SALARY_BY_TIME } from '../CreateAvailableShift/CreateAvailableShift.graphql'
import { GET_RECRUITMENT_UNPUBLISHED } from './RecruitmentUnpublished.graphql'

const RecruitmentUnpublishedScene = ({
  i18n,
  date,
  isCreateShiftPermitted,
  isCreateSchedulePermitted,
  history,
  dateSearch,
  isHolidays,
  onDropdownChange,
  clinicalDepartmentIds,
  url,
}) => {
  const { loading, error, data, refetch } = useQuery(
    GET_RECRUITMENT_UNPUBLISHED,
    {
      variables: {
        month: dateSearch,
        isHolidays,
      },
      fetchPolicy: 'network-only',
    }
  )
  useEffect(() => {
    refetch()
  })

  const [{ popup }] = useCustom()
  const client = useApolloClient()
  const [current, setCurrent] = useState(1)
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  const { clinicalDepartments, departments } = data
  const sortedClinicalDepartments = sortAndFilterByClinicOrder({
    clinicalDepartments,
    isFilter: true,
  })

  const departmentNames = clinicalDepartmentNames({ departments })
  const clinicalDepartments1 = combineNames({
    clinicalDepartments: sortedClinicalDepartments,
    departmentNames,
  })
  const list = data.availableShiftPublished
    .sort((a, b) => moment(a.startTime) - moment(b.startTime))
    .map(as => ({
      clinicName: as.comment.split(';')[0],
      departmentName: departmentNames[as.comment.split(';')[1]],
      timeOpen: as.comment.split(';')[2],
      timeClose: as.comment.split(';')[3],
      businessHour: as.startTime,
      startTime: as.startTime,
      endTime: as.endTime,
      time: `${moment(as.startTime).format('LL(dd')}${
        isHolidays.includes(moment(as.startTime).format('YYYY-MM-DD'))
          ? '・祝日)'
          : ')'
      } ${moment(as.startTime).format('HH:mm')}～ ${moment(as.endTime).format(
        'HH:mm'
      )}`,
      isPublished: as.isPublished,
      comment: as.comment,
      value: as,
    }))

  const prevMonth = moment(date).subtract(1, 'month')
  const nextMonth = moment(date).add(1, 'month')
  const isPreviousDisabled =
    date.startOf('month').isBefore(moment().endOf('month')) ||
    dateValidation({
      year: prevMonth.year(),
      month: prevMonth.month() + 1,
    })
  const isNextDisabled =
    nextMonth.isAfter(
      moment()
        .add(12, 'month')
        .endOf('month')
    ) ||
    dateValidation({
      year: nextMonth.year(),
      month: nextMonth.month() + 1,
      day: nextMonth.date(),
    })

  const clickCreateShift = async (createShift, as) => {
    try {
      const departmentName = `${as.comment.split(';')[0]};${
        as.comment.split(';')[1]
      }`
      let clinicalDepartment
      clinicalDepartments1.map(item => {
        if (`${item.clinic.name};${item.name}` === departmentName) {
          clinicalDepartment = item
        }
        return null
      })
      history.push({
        pathname: createShift
          ? `${url}${Router.get(Router.staffCreateAvailableShift)}`
          : `${url}${Router.get(Router.staffRecruitmentScheduleShiftCreate)}`,
        stateUnpublished: {
          clinicalDepartment,
          startTime: as.startTime,
          endTime: as.endTime,
          splits: [
            {
              date: as.endTime,
              isBreakTime: false,
            },
          ],
          unPublished: true,
        },
      })
    } catch (error) {
      popup.error(i18n.t('main.ERROR'))
    }
  }
  const columns = [
    {
      title: <>{i18n.t('main.RecruitmentUnpublished.clinicName')}</>,
      dataIndex: 'clinicName',
      align: 'center',
      width: '10%',
    },
    {
      title: <>{i18n.t('main.RecruitmentUnpublished.department')}</>,
      width: '12%',
      align: 'center',
      dataIndex: 'departmentName',
    },
    {
      title: <>{i18n.t('main.RecruitmentUnpublished.businessHour')}</>,
      key: 'timeOpen',
      align: 'center',
      width: '5%',
      render: record => (
        <>
          {`${moment.utc(record.timeOpen).format('HH:mm')} ～ ${moment
            .utc(record.timeClose)
            .format('HH:mm')}`}
        </>
      ),
    },
    {
      title: <>{i18n.t('main.RecruitmentUnpublished.timeNotPost')}</>,
      dataIndex: 'time',
      align: 'center',
      width: '18%',
    },
    {
      title: <>{i18n.t('main.RecruitmentUnpublished.status')}</>,
      dataIndex: 'isPublished',
      align: 'center',
      width: '14%',
      render: isPublished => (
        <>{!isPublished ? '募集シフト有（未掲載中）' : null}</>
      ),
    },
    {
      title: (
        <>{i18n.t('main.RecruitmentUnpublished.individualRecruitmentCreate')}</>
      ),
      align: 'center',
      render: record => (
        <>
          {isCreateShiftPermitted ? (
            <Button
              icon='pi pi-plus'
              className='p-button-normal-empty'
              label={i18n.t('main.createAvailableShift')}
              style={{ padding: '0px' }}
              onClick={() => clickCreateShift(true, record)}
            />
          ) : null}
        </>
      ),
    },
    {
      title: (
        <>{i18n.t('main.RecruitmentUnpublished.regularRecruitmentCreate')}</>
      ),
      align: 'center',
      render: record => (
        <>
          {isCreateSchedulePermitted ? (
            <Button
              icon='pi pi-plus'
              className='p-button-normal-empty'
              label={i18n.t(
                'main.RecruitmentUnpublished.regularRecruitmentCreate'
              )}
              onClick={() => clickCreateShift(false, record)}
              style={{
                marginLeft: '20px',
                padding: '0px',
              }}
            />
          ) : null}
        </>
      ),
    },
  ]
  return (
    <React.Fragment>
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('main.RecruitmentUnpublished.title')}
          <BreadCrumb
            items={[
              {
                to: Router.get(Router.staffMonthlyAvailableShifts, {
                  year: date.year(),
                  month: date.month() + 1,
                }),
                title: moment(date).format('YYYY年MM月'),
              },
              { title: i18n.t('main.RecruitmentUnpublished.title') },
            ]}
          />
        </div>
      </div>
      <div className='container is-max'>
        <div className='box'>
          <div style={styles.header}>
            <Link
              to={Router.get(Router.staffRecruitmentUnpublished, {
                year: prevMonth.year(),
                month: prevMonth.month() + 1,
              })}
              onClick={isPreviousDisabled ? e => e.preventDefault() : undefined}
            >
              <Button icon='pi pi-chevron-left' disabled={isPreviousDisabled} />
            </Link>
            <div style={styles.title}>{moment(date).format('YYYY年M月')}</div>
            <Link
              to={Router.get(Router.staffRecruitmentUnpublished, {
                year: nextMonth.year(),
                month: nextMonth.month() + 1,
              })}
              onClick={isNextDisabled ? e => e.preventDefault() : undefined}
            >
              <Button icon='pi pi-chevron-right' disabled={isNextDisabled} />
            </Link>
          </div>
          <div className='search-parent'>
            <MultiSelect
              value={clinicalDepartments1.filter(({ id }) =>
                clinicalDepartmentIds.includes(id)
              )}
              fixedPlaceholder
              options={clinicalDepartments1}
              optionLabel='clinicName'
              onChange={onDropdownChange}
              filter
              placeholder={i18n.t('main.chooseClinic')}
            />
          </div>

          <Formik
            render={() => {
              const clinicalDepartmentsFilter = clinicalDepartments1.filter(
                ({ id }) => clinicalDepartmentIds.includes(id)
              )
              const clinicalDepartmentsName = []
              clinicalDepartmentsFilter.map(as => {
                clinicalDepartmentsName.push(`${as.clinic.name};${as.name}`)
                return null
              })
              const dataShow = list.filter(item =>
                clinicalDepartmentIds.length === 0
                  ? true
                  : clinicalDepartmentsName.includes(
                      `${item.comment.split(';')[0]};${
                        item.comment.split(';')[1]
                      }`
                    )
              )
              const pageSize = 100
              const getData = (current1, pagesize) =>
                dataShow.slice((current1 - 1) * pagesize, current1 * pagesize)
              const locale = {
                emptyText: `${i18n.t('main.RecruitmentUnpublished.noData')}`,
              }
              return (
                <div className='recruitment-unpublished-table'>
                  <Table
                    locale={locale}
                    columns={columns}
                    dataSource={getData(current, pageSize)}
                    bordered
                    pagination={false}
                    size='large'
                  />
                  {dataShow.length > 0 ? (
                    <Pagination
                      onChange={setCurrent}
                      total={dataShow.length}
                      showTotal={total => (
                        <>
                          {i18n.t('main.paginationTotal')}: {total}
                        </>
                      )}
                      current={current}
                      pageSize={pageSize}
                      style={{
                        marginTop: '10px',
                        textAlign: 'center',
                      }}
                      showSizeChanger={false}
                      showLessItems
                    />
                  ) : null}
                </div>
              )
            }}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default RecruitmentUnpublishedScene
