import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import DoctorProfileForm from '@medical/components/Forms/DoctorProfileForm'
import { PERMANENT_DOCTOR, REGULAR_SHIFT_DOCTOR } from '@medical/constant'
import { useCustom } from '@medical/provider/context'
import queryString from 'query-string'
import React, { useState } from 'react'

import { DOCTORS_CONNECTION } from '../DoctorsList/DoctorsList.graphql'
import {
  DOCTOR_CREATE_QUERY,
  STAFF_CREATE_DOCTOR,
} from './DoctorCreate.graphql'

const DoctorCreate = ({ closeModal, location: { search } }) => {
  const [{ i18n, popup }] = useCustom()
  const [disabled, setDisabled] = useState(false)
  const {
    page = 1,
    rowsPerPage = 20,
    orderBy = 'createdAt_DESC',
    fullname,
    email: queryEmail,
  } = queryString.parse(search)
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  const variables = {
    first: first < 0 ? 20 : first,
    skip: skip < 0 ? 0 : skip,
    orderBy,
    where: {
      AND: [
        {
          OR: [
            {
              unSigned_contains: fullname
                ? fullname
                    .replace(/ /g, '')
                    .replace(/　/g, '')
                    .toLowerCase()
                : '',
            },
          ],
        },
        {
          email_contains: queryEmail,
        },
      ],
    },
  }
  const [createDoctor] = useMutation(STAFF_CREATE_DOCTOR, {
    refetchQueries: [
      {
        query: DOCTORS_CONNECTION,
        variables,
      },
    ],
    awaitRefetchQueries: true,
  })
  const { loading, error, data } = useQuery(DOCTOR_CREATE_QUERY)

  const createDoctorSubmit = async (
    {
      lastname,
      lastnameKana,
      firstname,
      firstnameKana,
      birthday,
      gender,
      email,
      medicalLicenseNumber,
      phoneNumberFirst,
      phoneNumberSecond,
      phoneNumberThird,
      homeAddress: {
        stateOrRegion,
        address1,
        address2,
        zipCodeFirst,
        zipCodeSecond,
      },
      nearestStations,
      placeOfWork,
      workPattern,
      departments,
      joinBackground,
      joinBackgroundOther,
      evaluationComment,
      graduationUniversity,
      graduationUniversityYear,
      obtainDoctorLicenseYear,
      professionalDescription,
      reasonKnowWebsites,
      otherReasonKnowWebsite,
      isJoinedOnlineDiagnosticTraining,
      isJoinedSublingualImmunotherapyELearning,
      introducedBy,
      careerHistories,
      legalHolidays,
      isAvailableOnMonday,
      isAvailableOnTuesday,
      isAvailableOnWednesday,
      isAvailableOnThursday,
      isAvailableOnFriday,
      isAvailableOnSaturday,
      isAvailableOnSunday,
      isAvailableOnHoliday,
      adjustHourlyWageRange,
      medicalRecord,
      orca,
      skillSet,
      isViaReferral,
      questionnaire,
      staffMemo,
      province,
      isUpdateAddress,
      isCreateTransportationCost,
    },
    { setSubmitting, setFieldValue }
  ) => {
    if (Array.isArray(isJoinedOnlineDiagnosticTraining)) {
      isJoinedOnlineDiagnosticTraining = isJoinedOnlineDiagnosticTraining[0]
    }
    if (Array.isArray(isJoinedSublingualImmunotherapyELearning)) {
      isJoinedSublingualImmunotherapyELearning =
        isJoinedSublingualImmunotherapyELearning[0]
    }
    if ([PERMANENT_DOCTOR, REGULAR_SHIFT_DOCTOR].includes(workPattern)) {
      isAvailableOnMonday = !!legalHolidays.includes('MON')
      isAvailableOnTuesday = !!legalHolidays.includes('TUE')
      isAvailableOnWednesday = !!legalHolidays.includes('WED')
      isAvailableOnThursday = !!legalHolidays.includes('THURS')
      isAvailableOnFriday = !!legalHolidays.includes('FRI')
      isAvailableOnSaturday = !!legalHolidays.includes('SAT')
      isAvailableOnSunday = !!legalHolidays.includes('SUN')
      isAvailableOnHoliday = !!legalHolidays.includes('HOLI')
    }
    isJoinedOnlineDiagnosticTraining =
      isJoinedOnlineDiagnosticTraining === 'true'
    isJoinedSublingualImmunotherapyELearning =
      isJoinedSublingualImmunotherapyELearning === 'true'
    try {
      await createDoctor({
        variables: {
          lastname,
          lastnameKana,
          firstname,
          firstnameKana,
          birthday,
          gender,
          email,
          medicalLicenseNumber,
          phoneNumber: `${phoneNumberFirst}${phoneNumberSecond}${phoneNumberThird}`,
          stateOrRegion,
          address1,
          address2,
          zipCode: `${zipCodeFirst}${zipCodeSecond}`,
          nearestStations,
          placeOfWork,
          workPattern,
          departments,
          joinBackground,
          joinBackgroundOther,
          evaluationComment,
          graduationUniversity,
          graduationUniversityYear,
          obtainDoctorLicenseYear,
          professionalDescription,
          reasonKnowWebsites,
          otherReasonKnowWebsite,
          isJoinedOnlineDiagnosticTraining,
          isJoinedSublingualImmunotherapyELearning,
          introducedBy,
          careerHistories,
          adjustHourlyWageRange,
          medicalRecord,
          orca,
          isAvailableOnMonday,
          isAvailableOnTuesday,
          isAvailableOnWednesday,
          isAvailableOnThursday,
          isAvailableOnFriday,
          isAvailableOnSaturday,
          isAvailableOnSunday,
          isAvailableOnHoliday,
          skillSet,
          isViaReferral,
          questionnaire,
          staffMemo,
          province,
          isCreateAddressHistory: isUpdateAddress,
          isCreateTransportationCost,
        },
      })
      popup.success(i18n.t('staff.doctorDetail.submissionSuccess'))
      setSubmitting(false)
      setDisabled(true)
      closeModal()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
      setFieldValue('confirm', false)
    }
  }

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  return (
    <DoctorProfileForm
      i18n={i18n}
      genders={data.genders}
      departments={data.departments}
      workPatterns={data.workPatterns}
      joinBackgrounds={data.joinBackgrounds}
      onSubmit={createDoctorSubmit}
      disabled={disabled}
      setDisabled={setDisabled}
      confirmationMessage={i18n.t('staff.createDoctor.submitConfirmation')}
      buttonLabel={i18n.t('staff.createDoctor.submit')}
      createIndicator
      isStaff
      isEditMedicalRecordDDoctorProfile
      isViewDoctorMedicalLicenseNumber
      isEditDoctorMedicalLicenseNumber
      isViewMedicalRecordDDoctorProfile
    />
  )
}

export default DoctorCreate
