// eslint-disable-next-line import/no-extraneous-dependencies
import { FileTextOutlined, UserOutlined } from '@ant-design/icons'
import { ReactComponent as Clinic } from '@medical/assets/image/clinic.svg'
import logo from '@medical/assets/image/logo.png'
import { ReactComponent as Question } from '@medical/assets/image/question.svg'
import { ReactComponent as Portal } from '@medical/assets/image/stack-fill.svg'
import { ReactComponent as Tranport } from '@medical/assets/image/subway-line.svg'
import { DoctorLink } from '@medical/components'
import { getMenuPath } from '@medical/libs'
import Router from '@medical/routes/router'
import moment from 'moment'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { Button } from 'primereact/button'
import { Message } from 'primereact/message'
import React from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import { Link } from 'react-router-dom'

import DoctorFooter from './DoctorFooter'
import DoctorHeaderScene from './DoctorHeaderScene'
const DoctorLayoutScene = ({
  i18n,
  onSignout,
  path,
  menuToggle,
  setMenuToggle,
  children,
  year,
  month,
  doctor,
  cartItems,
  activeIndex,
  setActiveIndex,
  activeIndexSecond,
  setActiveIndexSecond,
  doctorPermissions,
  newMsg,
  isDoctorBlocked,
}) => {
  const newDate = moment(doctor.lastLoggedIn).format('YYYY/MM/DD HH:mm')
  if (isMobile && !isTablet)
    return (
      <>
        <div className={`doctor-layout ${!menuToggle}`}>
          <div className='doctor-layout-header'>
            <Button
              icon='pi pi-bars'
              style={{ fontSize: '20px', marginLeft: '20px' }}
              onClick={() => setMenuToggle(!menuToggle)}
              className='is-sp'
            />
            <div className='doctor-layout-spacer is-sp' />
            <div className='doctor-layout-logo'>
              <Link
                to={Router.doctorHome}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <img src={logo} alt='' style={{ width: '60%' }} />
              </Link>
            </div>
            <Button
              icon='pi pi-bars'
              style={{ fontSize: '20px' }}
              onClick={() => setMenuToggle(!menuToggle)}
              className='is-pc'
            />
            <div className='doctor-layout-spacer' />
            <DoctorLink to={Router.doctorCart}>
              <Button
                icon='pi pi-inbox'
                style={{ fontSize: '20px', marginRight: '10px' }}
              >
                <span className='my-badge'>{cartItems.length}</span>
              </Button>
            </DoctorLink>
          </div>
          {menuToggle && (
            <div
              className='doctor-layout-menu-bg'
              onClick={() => setMenuToggle(!menuToggle)}
            />
          )}
          <div className='doctor-layout-menu'>
            <div className='doctor-layout-menu-profile'>
              <Accordion
                activeIndex={activeIndex}
                onTabOpen={() => setActiveIndex(0)}
                onTabClose={() => setActiveIndex(null)}
              >
                <AccordionTab
                  header={
                    <div>
                      <div className='doctor-user-display'>
                        <div className='user-box'>
                          <UserOutlined
                            style={{
                              fontSize: '32px',
                              color: 'white',
                              padding: '5px 0px',
                              marginRight: '10px',
                            }}
                          />
                        </div>

                        <div>
                          <span
                            style={{ color: 'black', fontWeight: 'bold' }}
                          >{`${doctor.firstname} ${doctor.lastname}`}</span>
                          <p
                            style={{
                              fontSize: '13px',
                              fontWeight: 'normal',
                            }}
                          >
                            最終ログイン日時 {newDate}
                          </p>
                        </div>
                      </div>
                    </div>
                  }
                >
                  <DoctorLink
                    to={{
                      pathname: `${Router.doctorMyPage}${Router.doctorUpdateProfile}`,
                    }}
                    className='doctor-layout-menu-link'
                  >
                    <p className='user-display-dropdown'>
                      保険診療・給与支払必須必要情報
                    </p>
                  </DoctorLink>

                  <DoctorLink
                    className='doctor-layout-menu-link'
                    to={{
                      pathname: `${Router.doctorMyPage}${Router.doctorChangePassword}`,
                    }}
                  >
                    <p className='user-display-dropdown'>パスワード変更</p>
                  </DoctorLink>

                  <DoctorLink
                    className='doctor-layout-menu-link'
                    to={{
                      pathname: `${Router.doctorMyPage}${Router.doctorBankAccount}`,
                    }}
                  >
                    <p className='user-display-dropdown'>銀行口座情報</p>
                  </DoctorLink>

                  <DoctorLink
                    className='doctor-layout-menu-link'
                    to={{
                      pathname: `${Router.doctorMyPage}`,
                    }}
                  >
                    <p className='user-display-dropdown'>
                      医師免許証/身分証アップロード
                    </p>
                  </DoctorLink>
                  <DoctorLink
                    className='doctor-layout-menu-link'
                    to={{
                      pathname: `${Router.doctorMyPage}${Router.DoctorCreateIdentity}`,
                    }}
                  >
                    <p className='user-display-dropdown'>マイナンバー情報</p>
                  </DoctorLink>

                  <DoctorLink
                    to={Router.doctorWorkReportsWithoutYearMonth}
                    className='doctor-layout-menu-link'
                  >
                    <p className='user-display-dropdown'>勤務実績</p>
                  </DoctorLink>
                  <DoctorLink
                    to={{
                      pathname: `${Router.doctorEmailNotificationsSetting}`,
                    }}
                    className='doctor-layout-menu-link'
                  >
                    <p className='user-display-dropdown'>メール通知設定</p>
                  </DoctorLink>
                  <div className='doctor-layout-menu-link' onClick={onSignout}>
                    <p className='user-display-dropdown'>
                      {i18n.t('doctor.menuBar.signout')}
                    </p>
                  </div>
                </AccordionTab>
              </Accordion>
              <div className='doctor-layout-menu-divider' />
              {/* <DoctorLink
      to={Router.get(Router.doctorSchedules, {
        year,
        month,
      })}
      className={`doctor-layout-menu-link ${getMenuPath(
        Router.doctorSchedules,
        path
      )}`}
    >
      <i
        className="pi pi-calendar"
        style={{ fontSize: '20px', marginRight: '5px' }}
      />
      {i18n.t('doctor.menuBar.workSchedule')}
    </DoctorLink> */}
              <DoctorLink
                to={Router.get(Router.doctorAvailableShifts, {
                  year,
                  month,
                })}
                className={`doctor-layout-menu-link ${getMenuPath(
                  Router.doctorAvailableShifts,
                  path
                )}`}
              >
                <i
                  className='pi pi-calendar'
                  style={{ fontSize: '20px', marginRight: '5px' }}
                />
                {i18n.t('doctor.menuBar.availableShifts')}
              </DoctorLink>

              {isDoctorBlocked == true ? null : (
                <DoctorLink
                  to={Router.get(Router.doctorRecruitments)}
                  className={`doctor-layout-menu-link ${getMenuPath(
                    Router.doctorRecruitments,
                    path
                  )}`}
                >
                  <i
                    className='pi pi-calendar'
                    style={{ fontSize: '20px', marginRight: '5px' }}
                  />
                  採用情報を見る
                </DoctorLink>
              )}

              <DoctorLink
                to={Router.get(
                  Router.doctorRequestedWorkSchedulesListWithoutYearMonth
                )}
                className={`doctor-layout-menu-link ${getMenuPath(
                  Router.doctorRequestedWorkSchedulesListWithoutYearMonth,
                  path
                )}`}
              >
                <i
                  className='pi pi-list'
                  style={{ fontSize: '20px', marginRight: '5px' }}
                />
                {i18n.t('doctor.menuBar.requestedWorkSchedulesList')}
              </DoctorLink>

              {doctorPermissions ? null : (
                <DoctorLink
                  to={Router.get(
                    Router.doctorAcceptedWorkSchedulesListWithoutYearMonth
                  )}
                  className={`doctor-layout-menu-link ${getMenuPath(
                    Router.doctorAcceptedWorkSchedulesListWithoutYearMonth,
                    path
                  )}`}
                >
                  <i
                    className='pi pi-list'
                    style={{ fontSize: '20px', marginRight: '5px' }}
                  />
                  確定シフト一覧
                </DoctorLink>
              )}

              <DoctorLink
                to={Router.get(Router.doctorWorkReportsWithoutYearMonth)}
                className={`doctor-layout-menu-link ${getMenuPath(
                  Router.doctorWorkReportsWithoutYearMonth,
                  path
                )}`}
              >
                <i
                  className='pi pi-list'
                  style={{ fontSize: '20px', marginRight: '5px' }}
                />
                {i18n.t('doctor.menuBar.workReports')}
              </DoctorLink>

              {/* <DoctorLink
                to={Router.doctorWorkOutsideClinic}
                className={`doctor-layout-menu-link ${getMenuPath(
                  Router.doctorWorkOutsideClinic,
                  path
                )}`}
              >
                <i
                  className='pi pi-list'
                  style={{ fontSize: '20px', marginRight: '5px' }}
                />
                {i18n.t('doctor.menuBar.workOutsideClinic')}
              </DoctorLink> */}

              {/* Full-time / regular part-time recruitment */}
              {/* <DoctorLink
                to={Router.get(Router.doctorRecruitments)}
                className={`doctor-layout-menu-link ${getMenuPath(
                  Router.doctorRecruitments,
                  path
                )}`}
              >
                <i
                  className='pi pi-list'
                  style={{ fontSize: '20px', marginRight: '5px' }}
                />
                {i18n.t('doctor.menuBar.recruitment')}
              </DoctorLink> */}
              {/* <DoctorLink
                to={Router.get(Router.doctorClinic)}
                className={`doctor-layout-menu-link ${getMenuPath(
                  Router.doctorClinic,
                  path
                )}`}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <i
                    style={{ marginLeft: '2px', marginBottom: '-3px' }}
                    className='bg-red mt-0'
                  >
                    <Clinic />
                  </i>
                  <span style={{ marginLeft: '5px' }}>
                    {i18n.t('doctor.menuBar.clinicList')}
                  </span>
                </div>
              </DoctorLink> */}

              <Accordion
                activeIndex={activeIndexSecond}
                onTabClose={() => setActiveIndexSecond(null)}
                onTabOpen={() => setActiveIndexSecond(0)}
                className='doctornav-group'
              >
                <AccordionTab
                  header={
                    <React.Fragment>
                      <i className='menu-icon bg-red mt-5'>
                        <Tranport />
                      </i>
                      <span style={{ marginLeft: '5px' }}>
                        {i18n.t('doctor.menuBar.transportApplication')}
                      </span>
                    </React.Fragment>
                  }
                >
                  <DoctorLink
                    to={Router.get(Router.doctorTransportReports, {
                      year,
                      month,
                    })}
                    className={`doctor-layout-menu-link ${getMenuPath(
                      Router.doctorTransportReports,
                      path
                    )}`}
                  >
                    <span style={{ paddingLeft: '20px' }}>交通費申請</span>
                  </DoctorLink>
                  <DoctorLink
                    to={Router.get(Router.doctorTransportRegister, {
                      year,
                      month,
                    })}
                    className={`doctor-layout-menu-link ${getMenuPath(
                      Router.doctorTransportRegister,
                      path
                    )}`}
                  >
                    <span style={{ paddingLeft: '20px' }}>
                      {i18n.t('doctor.menuBar.transportFeeRegistration')}
                    </span>
                  </DoctorLink>
                  <DoctorLink
                    to={Router.get(Router.doctorTransportPeriodic, {
                      year,
                      month,
                    })}
                    className={`doctor-layout-menu-link ${getMenuPath(
                      Router.doctorTransportPeriodic,
                      path
                    )}`}
                  >
                    <span style={{ paddingLeft: '20px' }}>
                      {i18n.t('doctor.menuBar.periodic')}
                    </span>
                  </DoctorLink>
                </AccordionTab>
              </Accordion>

              <Accordion
                activeIndex={activeIndexSecond}
                onTabClose={() => setActiveIndexSecond(null)}
                onTabOpen={() => setActiveIndexSecond(0)}
                className='doctornav-group'
              >
                <AccordionTab
                  header={
                    <>
                      <FileTextOutlined
                        style={{
                          fontSize: '20px',
                          color: 'var(--primary)',
                        }}
                      />{' '}
                      <span style={{ marginLeft: '5px' }}>各種申請・登録</span>
                    </>
                  }
                >
                  <DoctorLink
                    to={Router.get(Router.doctorWaitingRecruitment, {
                      year,
                      month,
                    })}
                    className={`doctor-layout-menu-link ${getMenuPath(
                      Router.doctorWaitingRecruitment,
                      path
                    )}`}
                  >
                    <span style={{ paddingLeft: '20px' }}>募集待ち登録</span>
                  </DoctorLink>
                  <DoctorLink
                    className={`doctor-layout-menu-link ${getMenuPath(
                      Router.doctorAcceptedWorkSchedulesListWithoutYearMonth,
                      path
                    )}`}
                    to={Router.get(
                      Router.doctorAcceptedWorkSchedulesListWithoutYearMonth,
                      {
                        year,
                        month,
                      }
                    )}
                  >
                    <span style={{ paddingLeft: '20px' }}>時間外勤務申請</span>
                  </DoctorLink>
                  {/* <a
                    className='doctor-layout-menu-link'
                    href='https://docs.google.com/forms/d/e/1FAIpQLScbgycZiyKrbL8I5UJ_wa6PK5GBQPTTnxUvozIz-zOCjipnXw/viewform'
                  >
                    <p style={{ paddingLeft: '20px', fontWeight: '400', marginBottom: '0' }}>
                      時間外勤務申請
                    </p>
                  </a> */}

                  <DoctorLink
                    to={Router.get(
                      Router.doctorAcceptedWorkSchedulesListWithoutYearMonth
                    )}
                    className={`doctor-layout-menu-link ${getMenuPath(
                      Router.doctorAcceptedWorkSchedulesListWithoutYearMonth,
                      path
                    )}`}
                  >
                    <span style={{ paddingLeft: '20px' }}>
                      シフトキャンセル申請
                    </span>
                  </DoctorLink>

                  <DoctorLink
                    to={Router.get(Router.doctorWorkOutsideClinic)}
                    className={`doctor-layout-menu-link ${getMenuPath(
                      Router.doctorWorkOutsideClinic,
                      path
                    )}`}
                  >
                    <span style={{ paddingLeft: '20px' }}>
                      拠点用シフト申請
                    </span>
                  </DoctorLink>
                </AccordionTab>
              </Accordion>

              {/* <DoctorLink to={Router.doctorHome} className="doctor-layout-menu-link">
      <i
        className="pi pi-home"
        style={{ fontSize: '20px', marginRight: '5px' }}
      />
      {i18n.t('doctor.menuBar.home')}
    </DoctorLink> */}
              <div className='doctor-layout-menu-divider' />

              <DoctorLink to={Router.doctorClinic}>
                <div
                  className={`doctor-layout-menu-link ${getMenuPath(
                    Router.doctorClinic,
                    path
                  )}`}
                >
                  <i
                    style={{ marginLeft: '2px', marginBottom: '-3px' }}
                    className='bg-red mt-0'
                  >
                    <Clinic />
                  </i>
                  {i18n.t('doctor.menuBar.clinicList')}
                </div>
              </DoctorLink>

              <DoctorLink to={Router.doctorNews}>
                <div
                  className={`doctor-layout-menu-link ${getMenuPath(
                    Router.doctorNews,
                    path
                  )}`}
                >
                  <i
                    className='pi pi-home'
                    style={{ fontSize: '20px', marginRight: '5px' }}
                  />
                  {i18n.t('doctor.menuBar.news')}
                  {doctor.unseenNoAnnounce > 0 && (
                    <div className='badge'>{doctor.unseenNoAnnounce}</div>
                  )}
                </div>
              </DoctorLink>

              <DoctorLink
                to={Router.doctorInquiry}
                className={`doctor-layout-menu-link ${getMenuPath(
                  Router.doctorInquiry,
                  path
                )}`}
              >
                <div>
                  <i
                    className='pi pi-briefcase'
                    style={{ fontSize: '20px', marginRight: '5px' }}
                  />
                  {i18n.t('doctor.menuBar.inquiry')}
                  {newMsg ? <span className='tag-name'>New</span> : null}
                </div>
              </DoctorLink>

              <DoctorLink to={Router.doctorPortal}>
                <div
                  className={`doctor-layout-menu-link ${getMenuPath(
                    Router.doctorPortal,
                    path
                  )}`}
                >
                  <Portal />
                  &nbsp;{i18n.t('doctor.menuBar.portal')}
                </div>
              </DoctorLink>

              <DoctorLink
                to={Router.doctorQuestions}
                className={`doctor-layout-menu-link ${getMenuPath(
                  Router.doctorQuestions,
                  path
                )}`}
              >
                <div>
                  <i style={{ fontSize: '20px', marginRight: '5px' }} />
                  <Question
                    style={{ marginLeft: '-2px', marginRight: '5px' }}
                  />
                  {i18n.t('doctor.menuBar.question')}
                </div>
              </DoctorLink>

              {/* <a
        href='https://docs.google.com/forms/d/e/1FAIpQLSdByXsGnkv93erBGFY1VHV5FdwjR2p6lOUeFRXCEWs2GweLTw/viewform'
        target='blank'
        rel='noopener noreferrer'
        className='doctor-layout-menu-link'
        style={{
          marginLeft: 'px',
        }}
      >
        <i
          className='pi pi-external-link'
          style={{
            fontSize: '20px',
            marginRight: '5px',
          }}
        />
        {i18n.t('doctor.menuBar.cancelShiftLink')}
      </a> */}
              <a
                href='https://3a10b494-3a9b-4d5e-8b25-c1a27473161b.usrfiles.com/ugd/3a10b4_3904dbe747cf4fda9459824a3b3be579.pdf'
                target='blank'
                rel='noopener noreferrer'
                className='doctor-layout-menu-link'
              >
                <i
                  className='pi pi-external-link'
                  style={{ fontSize: '20px', marginRight: '5px' }}
                />
                {i18n.t('doctor.menuBar.useSite')}
              </a>
              <a
                href='https://docs.google.com/forms/d/e/1FAIpQLScbgycZiyKrbL8I5UJ_wa6PK5GBQPTTnxUvozIz-zOCjipnXw/viewform'
                target='blank'
                rel='noopener noreferrer'
                className='doctor-layout-menu-link'
              >
                <i
                  className='pi pi-external-link'
                  style={{ fontSize: '20px', marginRight: '5px' }}
                />
                {i18n.t('doctor.menuBar.overtimeApplication')}
              </a>
              <a
                href='https://sites.google.com/view/caps365doctors/access'
                target='blank'
                rel='noopener noreferrer'
                className='doctor-layout-menu-link'
              >
                <i
                  className='pi pi-external-link'
                  style={{ fontSize: '20px', marginRight: '5px' }}
                />
                {i18n.t('doctor.menuBar.accessInformation')}
              </a>
            </div>
          </div>
          <div className='doctor-layout-body'>
            <div>
              <div className='doctor-layout-cart'>
                {!!cartItems.length && (
                  <Message
                    severity='warn'
                    text='カートに選択中の募集シフトがあります。'
                    style={{ margin: '3px 1px 5px 0px', fontSize: '0.3' }}
                  />
                )}
              </div>
              {children}
            </div>
            <DoctorFooter
              i18n={i18n}
              year={year}
              month={month}
              isDoctorBlocked={isDoctorBlocked}
            />
          </div>
        </div>
      </>
    )
  return (
    <>
      <DoctorHeaderScene
        doctor={doctor}
        cartItems={cartItems}
        onSignout={onSignout}
        path={path}
        year={year}
        month={month}
      />
      <div style={{ padding: '15px' }}>{children}</div>
      <DoctorFooter
        i18n={i18n}
        year={year}
        month={month}
        isDoctorBlocked={isDoctorBlocked}
      />
    </>
  )
}

export default DoctorLayoutScene
