import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import {
  clinicalDepartmentNames,
  combineNames,
  convertDoctors,
  convertDoctorsConnection,
  sortAndFilterByClinicOrder,
} from '@medical/libs'
import {
  DEPARTMENTS_LIST,
  DOCTOR_CONNECTION,
  DOCTORS_DEPARTMENTS,
} from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import DoctorHourlyWageForm from '@medical/pages/staff/HourlyPayment/DoctorHourlyWageForm'
import { STAFF_CREATE_DEFAULT_HOURLY_WAGE } from '@medical/pages/staff/HourlyPayment/HourlyPayment.graphql'
import { convertDayInMonthToMatrix } from '@medical/pages/staff/RecruitmentScheduleShift/CreateRecruitmentScheduleShift/function'
import { useCustom } from '@medical/provider/context'
import moment from 'moment'
import React from 'react'

const CreateDoctorHourlyWage = ({ closeModal }) => {
  const [{ i18n, popup }] = useCustom()
  const [staffCreateDefaultHourlyWage] = useMutation(
    STAFF_CREATE_DEFAULT_HOURLY_WAGE
  )
  const { loading, error, data } = useQuery(DOCTORS_DEPARTMENTS)
  const {
    loading: loadingDepartment,
    error: errorDepartment,
    data: dataDepartment,
  } = useQuery(DEPARTMENTS_LIST)
  const {
    loading: doctorLoading,
    error: doctorError,
    data: doctorData,
  } = useQuery(DOCTOR_CONNECTION, {
    variables: {
      where: {
        deletedAt: null,
        registrationStatus_in: ['CREATED_BY_STAFF', 'ACCEPTED'],
        blocked: false,
      },
      first: 10,
      skip: 0,
      orderBy: 'unSigned_ASC',
    },
  })
  if (loading || loadingDepartment || doctorLoading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  if (errorDepartment) return <ErrorComponent error={errorDepartment} />
  if (doctorError) return <ErrorComponent error={errorDepartment} />
  const onSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      const {
        doctor,
        workPattern,
        clinicalDepartment,
        rangeDate,
        repeatSetting,
        isAllday,
        isMonday,
        isTuesday,
        isWednesday,
        isThursday,
        isFriday,
        isSaturday,
        isSunday,
        isDayoff,
        listWeekDay,
        listWeekDay1,
        listWeekDay2,
        listWeekDay3,
        listWeekDay4,
        time,
        hourlyWage,
      } = values

      const startDate = `${moment(rangeDate[0]).format(
        'YYYY-MM-DD'
      )}T00:00:00.000Z`
      const endDate = `${moment(rangeDate[1]).format(
        'YYYY-MM-DD'
      )}T00:00:00.000Z`
      const startTime = `1000-10-04T${moment(time[0])
        .subtract(9, 'hours')
        .format('HH:mm:ss')}Z`
      const endTime = `1000-10-04T${moment(time[1])
        .subtract(9, 'hours')
        .format('HH:mm:ss')}Z`
      const listBooleanDayInMonth = convertDayInMonthToMatrix(
        listWeekDay,
        listWeekDay1,
        listWeekDay2,
        listWeekDay3,
        listWeekDay4
      )
      await staffCreateDefaultHourlyWage({
        variables: {
          workPattern: workPattern.value,
          startDate,
          endDate,
          startTime,
          endTime,
          hourlyWage: parseInt(hourlyWage),
          dateMatrix: JSON.stringify(listBooleanDayInMonth),
          isAvailableOnMonday: isMonday,
          isAvailableOnTuesday: isTuesday,
          isAvailableOnWednesday: isWednesday,
          isAvailableOnThursday: isThursday,
          isAvailableOnFriday: isFriday,
          isAvailableOnSaturday: isSaturday,
          isAvailableOnSunday: isSunday,
          isAvailableOnHoliday: isDayoff,
          repeatSetting,
          doctorId: doctor.id,
          clinicalDepartmentId: clinicalDepartment.id,
        },
      })
      popup.success(i18n.t('staff.scheduledShiftGroup.submissionSuccess'))
      setSubmitting(false)
      resetForm()
      closeModal()
    } catch (error) {
      popup.error(error)
      setFieldValue('confirm', false)
      setSubmitting(false)
    }
  }

  const { doctors } = data
  const { clinicalDepartments, departments } = data
  const sortedClinicalDepartments = sortAndFilterByClinicOrder({
    clinicalDepartments,
    isFilter: true,
  })
  const departmentNames = clinicalDepartmentNames({ departments })
  return (
    <DoctorHourlyWageForm
      i18n={i18n}
      onSubmit={onSubmit}
      doctors={convertDoctorsConnection(doctorData.doctorsConnection.edges)}
      clinicalDepartments={combineNames({
        clinicalDepartments: sortedClinicalDepartments,
        departmentNames,
      })}
      isCreateDefaultHourlyWage
    />
    // <h2>Hiii</h2>
  )
}
export default CreateDoctorHourlyWage
