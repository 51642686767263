import SignupSuccess from '@medical/components/Forms/SignupComponent/SignupSuccess'
import { useCustom } from '@medical/provider/context'
import { Button } from 'primereact/button'
import React from 'react'

const DoctorSignupSuccess = ({ history }) => {
  const [{ i18n }] = useCustom()
  const handleBackToLoginPage = () => {
    history.push('./login')
  }
  return (
    <div className='signup-bg'>
      <div className='signup-box'>
        <SignupSuccess />
        <div className='submit-button'>
          <Button
            type='submit'
            className='signup-button p-button-primary'
            onClick={handleBackToLoginPage}
            label={i18n.t('doctor.signupSuccess.loginBackLabel')}
          />
        </div>
      </div>
    </div>
  )
}

export default DoctorSignupSuccess
