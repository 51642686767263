import BreadCrumb from '@medical/components/Breadcrumb'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import ModalLink from '@medical/components/ModalLink'
import numeralCurrency from '@medical/libs/numeralCurrency'
import Router from '@medical/routes/router'
import { DatePicker } from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { Paginator } from 'primereact/paginator'
import queryString from 'query-string'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
const removeSpace = data => {
  if (!data) {
    return undefined
  }
}
const HourlyPaymentScene = ({
  i18n,
  count,
  rowsPerPage,
  page,
  onPageChange,
  onSubmit,
  doctorEmail,
  edges,
  doctorNameFilter,
  isCreateDefaultHourlyWage,
  // workPatternFilter,
}) => {
  // const ragularShiftDoctor = hourlyPayment.filter(
  //   val => val.workPattern === REGULAR_SHIFT_DOCTOR
  // )
  // const irregularShiftDoctor = hourlyPayment.filter(
  //   val => val.workPattern === PERMANENT_DOCTOR
  // )
  // const [ragularDoctor, setRagularDoctor] = useState(ragularShiftDoctor[0])
  // const [irregularDoctor, setIrregularDoctor] = useState(
  //   irregularShiftDoctor[0]
  // )

  // New
  const isSearch = () => {
    // if (fullname || startTimeGte || endTimeLte || clinicname) {
    //   return true
    // }
    // return false
    return true
  }
  const getWorkPatternJP = data => {
    let value = ''
    switch (data) {
      case 'REGULAR_SHIFT_DOCTOR':
        value = i18n.t('main.REGULAR_SHIFT_DOCTOR')
        break
      case 'PERMANENT_DOCTOR':
        value = i18n.t('main.PERMANENT_DOCTOR')
        break
      case 'IRREGULAR_SHIFT_DOCTOR':
        value = i18n.t('main.IRREGULAR_SHIFT_DOCTOR')
        break
      case 'TEMPORARY_DOCTOR':
        value = i18n.t('main.TEMPORARY_DOCTOR')
        break
      default:
        break
    }
    return value
  }
  const getWorkPatternObject = data => {
    let object = {}
    switch (data) {
      case 'REGULAR_SHIFT_DOCTOR':
        object = {
          name: i18n.t('main.REGULAR_SHIFT_DOCTOR'),
          value: 'REGULAR_SHIFT_DOCTOR',
        }
        break
      case 'PERMANENT_DOCTOR':
        object = {
          name: i18n.t('main.PERMANENT_DOCTOR'),
          value: 'PERMANENT_DOCTOR',
        }
        break
      default:
        break
    }
    return object
  }
  const workPatternOptions = [
    {
      name: i18n.t('main.REGULAR_SHIFT_DOCTOR'),
      value: 'REGULAR_SHIFT_DOCTOR',
    },
    { name: i18n.t('main.PERMANENT_DOCTOR'), value: 'PERMANENT_DOCTOR' },
  ]
  return (
    <div
      style={{
        padding: '10px',
        height: '100%',
      }}
    >
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t(
            'staff.createDoctorManageHourlyWage.titleListDefaultHourlyWageList'
          )}
          <BreadCrumb
            items={[
              {
                title: i18n.t(
                  'staff.createDoctorManageHourlyWage.titleListDefaultHourlyWageList'
                ),
              },
            ]}
          />
        </div>
        <div className='staff-buttons'>
          {isCreateDefaultHourlyWage ? (
            <ModalLink to={Router.get(Router.staffCreateDoctorHourlyWage)}>
              <Button
                icon='pi pi-plus-circle'
                className='p-button-normal'
                label={i18n.t(
                  'staff.createDoctorManageHourlyWage.button.createInPageList'
                )}
              />
            </ModalLink>
          ) : null}
        </div>
      </div>
      <div
        className='box'
        style={{
          backgroundColor: '#ffffff',
          marginLeft: '20px',
          marginRight: '20px',
          padding: '20px',
          borderRadius: '10px',
        }}
      >
        <div className='search-parent'>
          <Formik
            enableReinitialize
            initialValues={{
              doctorEmail,
              doctorNameFilter,
              // workPatternFilter: getWorkPatternObject(workPatternFilter),
            }}
            render={formikProps => {
              const {
                values,
                handleChange,
                handleBlur,
                setFieldValue,
              } = formikProps
              return (
                <div className='row-container'>
                  <div className='search-container'>
                    <InputText
                      value={values.doctorNameFilter}
                      name='doctorNameFilter'
                      placeholder={i18n.t('main.fullname')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{ width: '170px', marginRight: '20px' }}
                    />
                    <InputText
                      value={values.doctorEmail}
                      name='doctorEmail'
                      placeholder={i18n.t(
                        'staff.createDoctorManageHourlyWage.placeHolder.email'
                      )}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{ width: '170px', marginRight: '20px' }}
                    />
                    {/* <Dropdown
                      name='workPatternFilter'
                      value={values.workPattern}
                      onChange={handleChange}
                      placeholder={i18n.t(
                        'staff.createDoctorManageHourlyWage.placeHolder.workPattern'
                      )}
                      options={workPatternOptions}
                      optionLabel='name'
                      optionValue='value'
                      onBlur={handleBlur}
                      style={{ width: '170px', marginRight: '20px' }}
                    /> */}
                    <Link
                      to={{
                        pathname: Router.staffHourlyPayment,
                        search: queryString.stringify({
                          rowsPerPage,
                          doctorEmail: values.doctorEmail,
                          doctorNameFilter: values.doctorNameFilter,
                          workPatternFilter: values.workPatternFilter
                            ? values.workPatternFilter.value
                            : '',
                        }),
                      }}
                      style={{ marginLeft: '20px' }}
                    >
                      <Button
                        label={i18n.t('main.movingExpenses.search')}
                        icon='pi pi-search'
                      />
                    </Link>

                    {doctorEmail || doctorNameFilter ? (
                      <Link
                        to={{
                          pathname: Router.staffHourlyPayment,
                          search: queryString.stringify({
                            rowsPerPage,
                          }),
                        }}
                        style={{ marginLeft: '20px' }}
                      >
                        <Button
                          icon='pi pi-times'
                          className='p-button-secondary'
                          style={{ marginLeft: '20px' }}
                        />
                      </Link>
                    ) : null}
                  </div>
                </div>
              )
            }}
          />
        </div>
        <div>
          <div>
            <table className='table'>
              <thead>
                <tr>
                  <th width='15%'>
                    {i18n.t('staff.createDoctorManageHourlyWage.doctorName')}
                  </th>
                  <th width='15%'>
                    {i18n.t('staff.createDoctorManageHourlyWage.doctorEmail')}
                  </th>
                  <th width='20%'>
                    {i18n.t('staff.createDoctorManageHourlyWage.workPattern')}
                  </th>
                  <th width='20%'>
                    {i18n.t('staff.createDoctorManageHourlyWage.date')}
                  </th>

                  <th width='10%'>
                    {i18n.t('staff.createDoctorManageHourlyWage.time')}
                  </th>
                  <th width='10%'>
                    {i18n.t('staff.createDoctorManageHourlyWage.setHourlyWage')}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <Formik
                initialValues={{
                  confirm: false,
                  transportationsId: [],
                }}
                onSubmit={onSubmit}
                render={formikProps => {
                  const { setFieldValue } = formikProps
                  return (
                    <tbody>
                      {edges && edges.length > 0
                        ? edges.map((as, index) => {
                            const { node } = as
                            const startDate = moment(node.startDate).format(
                              'YYYY/MM/DD'
                            )
                            const endDate = moment(node.endDate).format(
                              'YYYY/MM/DD'
                            )
                            const startTime = moment
                              .utc(node.startTime)
                              .format('HH:mm')
                            const endTime = moment
                              .utc(node.endTime)
                              .format('HH:mm')
                            // if (
                            //   endTimeAddMonth < currentTime &&
                            //   startTimeAddMonth < currentTime
                            // ) {
                            //   isPast = true
                            // }
                            return (
                              <tr>
                                <td>
                                  {node.doctor
                                    ? `${node.doctor.lastname}${node.doctor.firstname}`
                                    : null}
                                </td>
                                <td>
                                  {node.doctor ? node.doctor.email : null}
                                </td>
                                {/* <td>
                                  {node.clinicalDepartment
                                    ? getClinicNameJP(
                                        node.clinicalDepartment.name
                                      )
                                    : null}
                                </td> */}
                                <td>{getWorkPatternJP(node.workPattern)}</td>
                                <td>{`${startDate} - ${endDate}`}</td>
                                <td>{`${startTime} - ${endTime}`}</td>
                                <td>{`¥${node.hourlyWage}`}</td>
                                <td>
                                  <div className='action-doctor-list'>
                                    <ModalLink
                                      to={Router.get(
                                        Router.staffDetailDoctorHourlyWage,
                                        {
                                          id: node.id,
                                        }
                                      )}
                                    >
                                      <Button icon='pi pi-pencil' />
                                    </ModalLink>
                                  </div>
                                </td>
                              </tr>
                            )
                          })
                        : null}
                      <ConfirmationModal
                        {...formikProps}
                        confirmationMessage={i18n.t(
                          'main.movingExpensesRegistration.confirmationMessageDelete'
                        )}
                      />
                    </tbody>
                  )
                }}
              />
            </table>
            <Paginator
              rows={rowsPerPage}
              totalRecords={count}
              first={(page - 1) * rowsPerPage}
              onPageChange={onPageChange}
              leftContent={
                <div className='paginator-total'>
                  {i18n.t('main.paginationTotal')}: {count}
                </div>
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default HourlyPaymentScene
