import BreadCrumb from '@medical/components/Breadcrumb'
import CustomDropdown from '@medical/components/DropdownInSearch'
import { dateValidation } from '@medical/libs'
import styles from '@medical/pages/staff/MonthlyAvailableShift/Style'
import Router from '@medical/routes/router'
import { Formik } from 'formik'
import moment from 'moment'
import { Button, Button as PrimeButton } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Paginator } from 'primereact/paginator'
import queryString from 'query-string'
import React from 'react'
import { Link } from 'react-router-dom'

import { DoctorStatusTemplate, getStatus } from './NewDoctorsStatusTemplate'

const removeSpace = fullname => {
  if (!fullname) {
    return undefined
  }
  const halfspace = ` `
  // eslint-disable-next-line no-irregular-whitespace
  const fullspace = `　`
  if (!fullname.includes(halfspace) && !fullname.includes(fullspace)) {
    return fullname
    // eslint-disable-next-line no-else-return
  } else {
    const newString = fullname.replace(halfspace, '').replace(fullspace, '')
    return newString
  }
}

const NewDoctorsListScene = ({
  i18n,
  page,
  rowsPerPage,
  fullname,
  email,
  status,
  edges,
  count,
  onPageChange,
  departmentNames,
  currentDate,
  onSortHandle,
}) => {
  const date = moment(currentDate)
  const prevMonth = moment(date).subtract(1, 'month')
  const nextMonth = moment(date).add(1, 'month')
  const isPreviousDisabled =
    date.isBefore(
      moment()
        .subtract(6, 'months')
        .endOf('month')
    ) ||
    dateValidation({
      year: prevMonth.year(),
      month: prevMonth.month() + 1,
    })
  const isNextDisabled =
    nextMonth.isAfter(
      moment()
        .add(6, 'month')
        .endOf('month')
    ) ||
    dateValidation({
      year: nextMonth.year(),
      month: nextMonth.month() + 1,
      day: nextMonth.date(),
    })

  return (
    <React.Fragment>
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('staff.menuBar.newDoctorsList')}
          <BreadCrumb
            items={[
              {
                title: i18n.t('staff.menuBar.newDoctorsList'),
              },
            ]}
          />
        </div>
      </div>
      <div className='container is-max'>
        <div className='box'>
          <div style={styles.header}>
            <Link
              to={Router.get(Router.staffNewDoctorsList, {
                year: prevMonth.year(),
                month: prevMonth.month() + 1,
              })}
              onClick={isPreviousDisabled ? e => e.preventDefault() : undefined}
            >
              <Button icon='pi pi-chevron-left' disabled={isPreviousDisabled} />
            </Link>
            <div style={styles.title}>
              {moment(currentDate).format('YYYY年M月')}
            </div>
            <Link
              to={Router.get(Router.staffNewDoctorsList, {
                year: nextMonth.year(),
                month: nextMonth.month() + 1,
              })}
              onClick={isNextDisabled ? e => e.preventDefault() : undefined}
            >
              <Button icon='pi pi-chevron-right' disabled={isNextDisabled} />
            </Link>
          </div>
          <Formik
            enableReinitialize
            initialValues={{
              fullname,
              email,
              status,
            }}
            render={formikProps => {
              const { values, handleChange, handleBlur } = formikProps
              return (
                <div className='search-parent'>
                  <InputText
                    name='fullname'
                    value={values.fullname}
                    placeholder={i18n.t('main.fullname')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ width: '200px', marginRight: '20px' }}
                  />
                  <InputText
                    name='email'
                    value={values.email}
                    placeholder={i18n.t('main.email')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ width: '200px', marginRight: '20px' }}
                  />

                  <CustomDropdown
                    {...formikProps}
                    options={DoctorStatusTemplate}
                    name='status'
                    value={values.status}
                    defaultTitle={i18n.t('doctor.myPage.status')}
                  />

                  <Link
                    to={{
                      pathname: Router.staffNewDoctors,
                      search: queryString.stringify({
                        rowsPerPage,
                        fullname: removeSpace(values.fullname),
                        email: values.email,
                        status: values.status,
                      }),
                    }}
                    style={{ marginLeft: '20px' }}
                  >
                    <Button label={i18n.t('main.search')} icon='pi pi-search' />
                  </Link>
                  {fullname || email || status ? (
                    <Link
                      to={{
                        pathname: Router.staffNewDoctorsWithoutMonth,
                        search: queryString.stringify({
                          rowsPerPage,
                        }),
                      }}
                    >
                      <Button
                        icon='pi pi-times'
                        className='p-button-secondary'
                        style={{ marginLeft: '20px' }}
                      />
                    </Link>
                  ) : null}
                </div>
              )
            }}
          />
          <Formik
            initialValues={{
              createdAt: false,
            }}
            render={formikProps => {
              const { setFieldValue, values } = formikProps

              return (
                <div className='overflow-container'>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th width='15%'>{i18n.t('updateProfile.fullname')}</th>
                        <th width='15%'>{i18n.t('updateProfile.email')}</th>
                        <th width='15%'>
                          {i18n.t('updateProfile.doctorRegistrationDate')}
                          <Button
                            style={{
                              border: 'none',
                              backgroundColor: 'transparent',
                              color: '#686868',
                            }}
                            icon={
                              !values.createdAt
                                ? 'pi pi-sort-down'
                                : 'pi pi-sort-up'
                            }
                            onClick={() => {
                              if (!values.createdAt) {
                                setFieldValue('createdAt', true)
                              } else {
                                setFieldValue('createdAt', false)
                              }
                              onSortHandle({ sortField: 'createdAt' })
                            }}
                          />
                        </th>
                        <th width='15%'>
                          {i18n.t('updateProfile.doctorFirstWorkDay')}
                        </th>
                        <th width='15%'>
                          {i18n.t('updateProfile.departments')}
                        </th>
                        <th width='5%'>{i18n.t('パスワード設定')}</th>
                        <th width='10%'>{i18n.t('doctor.myPage.status')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {edges.map(({ node }) => (
                        <tr
                          key={node.id}
                          className={node.blocked ? 'table-block' : ''}
                        >
                          <td>{`${node.lastname} ${node.firstname}`}</td>
                          <td>
                            <a href={`mailto:${node.email}`}>{node.email}</a>
                          </td>
                          <td>
                            {moment(node.createdAt)
                              .startOf('day')
                              .format('YYYY年MM月DD日(ddd)')}
                          </td>
                          <td>
                            {node.firstWorkday
                              ? moment(node.firstWorkday)
                                  .startOf('day')
                                  .format('YYYY年MM月DD日(ddd)')
                              : '初回勤務日未定'}
                          </td>
                          <td>
                            <div className='flex-parent'>
                              {node.departments.sort().map(department => (
                                <div className='slashed-item' key={department}>
                                  {departmentNames[department]}
                                </div>
                              ))}
                            </div>
                          </td>
                          <td>
                            {node.confirmationNumber !== null
                              ? '未設定'
                              : '設定済み'}
                          </td>
                          <td>{getStatus(node.registrationStatus)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )
            }}
          />
          <Paginator
            rows={rowsPerPage}
            totalRecords={count}
            first={(page - 1) * rowsPerPage}
            onPageChange={onPageChange}
            leftContent={
              <div className='paginator-total'>
                {i18n.t('main.paginationTotal')}: {count}
              </div>
            }
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default NewDoctorsListScene
