import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import gql from 'graphql-tag'

export const DOCTOR_RECRUITMENT_DOCTOR = gql`
  query RecruitmentDoctors($where: RecruitmentDoctorWhereInput) {
    recruitmentDoctors(where: $where) {
      id
      status
      desireStartMoment
      endTimeFriday
      endTimeHoliday
      endTimeMonday
      endTimeSaturday
      endTimeSunday
      endTimeThursday
      endTimeWednesday
      endTimeTuesday
      startTimeFriday
      startTimeHoliday
      startTimeMonday
      startTimeSaturday
      startTimeSunday
      startTimeThursday
      startTimeTuesday
      startTimeWednesday
      staffMemo
      remark
      doctor {
        id
        lastname
        firstname
        fullName
        email
        workPattern
      }
      recruitmentPostId {
        title
        id
        recruitmentDoctorType
        clinicalDepartment {
          id
          name
          extraName
          departmentCode
          isDisplay
          clinic {
            id
            name
            order
            isPublished
          }
        }
      }
    }
    ...DEPARTMENTS_FRAGMENT
  }
  ${DEPARTMENTS_FRAGMENT}
`
export const STAFF_UPDATE_RECRUITMENT_DOCTOR = gql`
  mutation STAFF_UPDATE_RECRUITMENT_DOCTOR(
    $recruitmentDoctorId: ID!
    $status: RecruitmentStatus!
    $staffMemo: String!
  ) {
    staffUpdateRecruitmentDoctor(
      recruitmentDoctorId: $recruitmentDoctorId
      status: $status
      staffMemo: $staffMemo
    )
  }
`
