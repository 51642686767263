import { DOCTOR_FRAGMENTS } from '@medical/layout/DoctorLayout/DoctorLayoutRequest.graphql'
import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import gql from 'graphql-tag'

export const RECRUITMENT_DOCTORS_CONNECTION = gql`
  query Query(
    $where: RecruitmentDoctorWhereInput
    $orderBy: RecruitmentDoctorOrderByInput
    $first: Int
    $skip: Int
  ) {
    recruitmentDoctorsConnection(
      where: $where
      orderBy: $orderBy
      first: $first
      skip: $skip
    ) {
      edges {
        node {
          id
          doctor {
            id
            firstname
            lastname
            email
            conversation {
              id
            }
          }
          status
          createdAt
          recruitmentPostId {
            id
            recruitmentDoctorType
            title
            clinicalDepartment {
              id
              name
              extraName
            }
          }
        }
      }
      aggregate(where: $where) {
        count
      }
    }
  }
`
export const CREATE_ACTIVITY = gql`
  mutation CREATE_ACTIVITY_LOG($activity: ActivityLogTypes!, $doctorId: ID) {
    createActivityLogHistory(activity: $activity, doctorId: $doctorId)
  }
`
