import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import { CLINICAL_DEPARTMENTS_QUERY } from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import gql from 'graphql-tag'

export const WAITING_RECRUITMENT_QUERY = gql`
  query WaitingRecruitmentShiftsConnection(
    $where: WaitingRecruitmentShiftWhereInput
    $skip: Int
    $first: Int
    $orderBy: WaitingRecruitmentShiftOrderByInput
  ) {
    waitingRecruitmentShiftsConnection(
      where: $where
      skip: $skip
      first: $first
      orderBy: $orderBy
    ) {
      edges {
        node {
          id
          createdAt
          updatedAt
          deletedAt
          startDate
          endDate
          active
          startTime
          endTime
          description
          recruitmentShifts {
            id
            clinic
          }
        }
      }
      aggregate(where: $where) {
        count
      }
    }
    ...DEPARTMENTS_FRAGMENT
    ...CLINICAL_DEPARTMENTS_QUERY
  }
  ${DEPARTMENTS_FRAGMENT}
  ${CLINICAL_DEPARTMENTS_QUERY}
`
