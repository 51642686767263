import moment from 'moment'

const convertHourlyWageCsvRecord = data =>
  data.map(
    ({
      診療科コード: departmentCode,
      設定開始時間: startTime,
      設定終了時間: endTime,
      設定開始日時: startDate,
      設定終了日時: endDate,
      平日: isWeekday,
      月: isAvailableOnMonday,
      火: isAvailableOnTuesday,
      水: isAvailableOnWednesday,
      木: isAvailableOnThursday,
      金: isAvailableOnFriday,
      土: isAvailableOnSaturday,
      日: isAvailableOnSunday,
      祝: isAvailableOnHoliday,
      時給: hourlyWageDefault,
      '区分（新規)': isClassiNew,
      '区分（既存)': isClassiExist,
      errorMessage,
      delete: isDelete,
    }) => {
      const rowMomentStartDate = moment
        .utc(startDate, 'YYYY-MM-DD')
        .startOf('days')
        .toISOString()
      const rowMomentEndDate =
        moment(endDate).format('YYYY-MM-DD') + 'T' + '23:59:59.000Z'
      const startTimeFormat =
        Number(startTime.split(':')[0]) < 10 ? `0${startTime}` : startTime
      return {
        departmentCode,
        startTime: `1000-10-10T${startTime}:00.000Z`,
        endTime: `1000-10-10T${endTime}:00.000Z`,
        startDate: rowMomentStartDate,
        endDate: rowMomentEndDate,
        isWeekday: isWeekday === '1',
        isAvailableOnMonday:
          isWeekday === '1' ? true : isAvailableOnMonday === '1',
        isAvailableOnTuesday:
          isWeekday === '1' ? true : isAvailableOnTuesday === '1',
        isAvailableOnWednesday:
          isWeekday === '1' ? true : isAvailableOnWednesday === '1',
        isAvailableOnThursday:
          isWeekday === '1' ? true : isAvailableOnThursday === '1',
        isAvailableOnFriday:
          isWeekday === '1' ? true : isAvailableOnFriday === '1',
        isAvailableOnSaturday: isAvailableOnSaturday == 1 ? true : false,
        isAvailableOnSunday: isAvailableOnSunday == 1 ? true : false,
        isAvailableOnHoliday: isAvailableOnHoliday == 1 ? true : false,
        hourlyWageDefault: hourlyWageDefault
          ? parseInt(hourlyWageDefault, 10)
          : undefined,
        isClassiNew: isClassiNew == 1 ? true : false,
        isClassiExist: isClassiExist == 1 ? true : false,
        errorMessage,
        isDelete: isDelete === '1',
      }
    }
  )
export default convertHourlyWageCsvRecord
