import logo from '@medical/assets/image/logo.png'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import { ErrorMessage, Formik } from 'formik'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

const ForgotPasswordForm = ({ onSubmit }) => {
  const { i18n } = useTranslation()

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(i18n.t('validation.email.email'))
      .nullable()
      .required(i18n.t('validation.email.required')),
  })

  return (
    <Formik
      initialValues={{
        confirm: false,
        email: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={formikProps => {
        const {
          values,
          validateForm,
          setFieldValue,
          setTouched,
          handleChange,
          handleBlur,
        } = formikProps
        return (
          <div className='login-bg'>
            <div className='login-box'>
              <div className='login-title'>
                <img src={logo} alt='' style={{ width: '60%' }} />
              </div>
              <div className='login-inner'>
                <div className='login-desc'>
                  {i18n.t('forgotPassword.description')}
                </div>
                <div className='login-input'>
                  <InputText
                    value={values.email}
                    name='email'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={i18n.t('main.email')}
                    autoCapitalize='none'
                  />
                  <ErrorMessage
                    name='email'
                    component='div'
                    className='login-alert'
                  />
                </div>
                <Button
                  className='login-button p-button-primary'
                  label={i18n.t('forgotPassword.submit')}
                  onClick={() => {
                    validateForm().then(response => {
                      if (!Object.keys(response).length) {
                        setFieldValue('confirm', true)
                      } else {
                        setTouched(response)
                      }
                    })
                  }}
                />
              </div>
              <ConfirmationModal
                {...formikProps}
                confirmationMessage={`${i18n.t(
                  'forgotPassword.submitConfirmation'
                )}: ${values.email}`}
              />
            </div>
          </div>
        )
      }}
    />
  )
}

export default ForgotPasswordForm
