import gql from 'graphql-tag'
import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import { CLINICAL_DEPARTMENTS_QUERY } from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
export const UPDATE_EARLY_HOUR_WAGE = gql`
  mutation UPDATE_EARLY_HOUR_WAGE(
    $hourlyWageChangeReservations: [HourlyWageChangeReservationForUpsert]
  ) {
    staffUpdateHourlyWageChangeReservation(
      hourlyWageChangeReservations: $hourlyWageChangeReservations
    ) 
  }
`

export const EARLY_HOUR_WAGE = gql`
  query EARLY_HOUR_WAGE($where: HourlyWageChangeReservationWhereInput) {
    hourlyWageChangeReservations(where: $where) {
      isEarlySpecial
      isValid
      reservation
      createdBy {
        lastname
        firstname
      }
      saturdayAfternoon
      saturdayMorning
      saturdayNight
      updatedAt
      weekdayAfternoon
      weekdayMorning
      weekdayNight
      id
      holidayNight
      holidayMorning
      holidayAfternoon
      clinicalDepartment {
        id
        name
      }
      clinic {
        id
        order
        name
        Region {
          id
          name
          position
        }
      }
    }
    ...DEPARTMENTS_FRAGMENT
    ...CLINICAL_DEPARTMENTS_QUERY
  }
  ${CLINICAL_DEPARTMENTS_QUERY}
  ${DEPARTMENTS_FRAGMENT}
`
export const REGION_QUERY = gql`
  query regions($orderBy: RegionOrderByInput) {
    regions(orderBy: $orderBy) {
      name
      id
      position
    }
  }
`