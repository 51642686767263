import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { STAFF_CREATE_RECRUITMENT_POST } from '@medical/constant/permissions'
import {
  checkStaffPermission,
  clinicalDepartmentNames,
  combineNames,
  sortAndFilterByClinicOrder,
} from '@medical/libs'
import { DOCTORS_DEPARTMENTS } from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import RecruitmentPostForm from '@medical/pages/staff/RecruitmentPost/components/RecruitmentPostForm'
import { useCustom } from '@medical/provider/context'
import Router from '@medical/routes/router'
import React from 'react'

import { CREATE_RECRUITMENT_POST } from './RecruitmentPostCreate.graphql'

const RecruitmentPostCreate = ({ history, location: { state } }) => {
  const [{ i18n, popup }] = useCustom()
  const isCreatePermitted = checkStaffPermission({
    functionName: STAFF_CREATE_RECRUITMENT_POST,
  })
  const [createRecruitmentPost] = useMutation(CREATE_RECRUITMENT_POST)
  const onSubmit = async (
    {
      file,
      title,
      content,
      isPublished,
      recruitmentDoctorType,
      clinicalDepartment,
      imageKey,
    },
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      const variables = {
        title,
        content,
        isPublished,
        recruitmentDoctorType,
        clinicalDepartmentId: clinicalDepartment.id,
        image: file,
        imageKeyCopy: !file ? imageKey : null,
      }
      await createRecruitmentPost({
        variables,
      })
      popup.success(i18n.t('staff.createAnnounce.submissionSuccess'))
      setSubmitting(false)
      resetForm()
      history.push({
        pathname: Router.staffRecruitmentPosts,
      })
    } catch (error) {
      popup.error(error)
      setFieldValue('confirm', false)
      setSubmitting(false)
    }
  }
  const { loading, error, data } = useQuery(DOCTORS_DEPARTMENTS)

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  const { clinicalDepartments, departments } = data
  const departmentNames = clinicalDepartmentNames({ departments })
  const sortedClinicalDepartments = sortAndFilterByClinicOrder({
    clinicalDepartments,
    isFilter: true,
  })
  return (
    <RecruitmentPostForm
      announce={state?.recruitment || {}}
      onSubmit={onSubmit}
      clinicalDepartments={[
        {
          clinicName: 'なし',
          id: '',
        },
        ...combineNames({
          clinicalDepartments: sortedClinicalDepartments,
          departmentNames,
        }),
      ]}
      isCreatePermitted={isCreatePermitted}
    />
  )
}

export default RecruitmentPostCreate
