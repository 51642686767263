import { MAX_HOURLY_WAGE, SHIFT_FORM_STEP } from '@medical/constant'
import { calculateSalary, getAvailableTime, splitLogic } from '@medical/libs'
import { FieldArray } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Spinner } from 'primereact/spinner'
import { pathOr } from 'ramda'
import React from 'react'

import { Checkbox } from 'antd'
import AutoComplete from './ShiftFormSplitsAutoComplete'
import styles from './Style'

const ShiftFormWorkOutsideSplits = ({
  i18n,
  values,
  touched,
  errors,
  setFieldValue,
  handleChange,
  disabled,
  isMoneyPermitted,
  isDoctor,
  isAcceptedWorkScheduleOverTime,
}) => (
  <React.Fragment>
    <div style={styles.row}>
      <div className='shiftForm-container'>
        <FieldArray
          name='splits'
          render={({ remove, push }) => (
            <React.Fragment>
              <table style={styles.table}>
                <thead>
                  <tr>
                    <th style={styles.th} width='32%'>
                      {i18n.t('staff.createShift.startTime')}
                    </th>
                    <th style={styles.th} width='32%'>
                      {i18n.t('staff.createShift.endTime')}
                    </th>
                    {isMoneyPermitted && (
                      <>
                        {!isDoctor && (
                          <th style={styles.th} width='36%'>
                            <span style={styles.input}>
                              {i18n.t('staff.createShift.hourlyWage')}
                            </span>
                          </th>
                        )}
                      </>
                    )}
                    <th style={styles.th}>休憩時間</th>
                  </tr>
                </thead>
                <tbody>
                  {values.splits.map((split, index) => {
                    const prevDate = index
                      ? moment(values.splits[index - 1].date)
                      : moment(values.startTime)
                    const minDate = prevDate
                      .clone()
                      .add(SHIFT_FORM_STEP, 'minute')
                      .toDate()
                    return (
                      <tr key={index}>
                        <td>
                          {index ? (
                            <>
                              <AutoComplete
                                value={prevDate.toDate()}
                                disabled
                              />
                            </>
                          ) : (
                            <>
                              <AutoComplete
                                value={values.startTime}
                                onChange={e => {
                                  setFieldValue('startTime', e.value)
                                  const startTime = moment(e.value)
                                  values.splits.forEach((s, i) => {
                                    if (
                                      startTime.isSameOrAfter(moment(s.date))
                                    ) {
                                      const date = getAvailableTime({
                                        currentTime: e.value,
                                      })
                                      setFieldValue(`splits.${i}.date`, date)
                                    }
                                  })
                                }}
                                disabled={disabled}
                              />
                            </>
                          )}
                        </td>
                        <td>
                          <>
                            <AutoComplete
                              value={split.date}
                              onChange={e => {
                                setFieldValue(`splits[${index}].date`, e.value)
                                const startTime = moment(e.value)
                                for (
                                  let i = index + 1;
                                  i < values.splits.length;
                                  i += 1
                                ) {
                                  if (
                                    startTime.isSameOrAfter(
                                      moment(values.splits[i].date)
                                    )
                                  ) {
                                    const date = getAvailableTime({
                                      currentTime: e.value,
                                    })
                                    setFieldValue(`splits[${i}].date`, date)
                                  }
                                }
                              }}
                              minDate={minDate}
                              disabled={disabled}
                            />
                          </>
                        </td>
                        {isMoneyPermitted && (
                          <td style={{ display: 'flex', flexDirection: 'row' }}>
                            {!isDoctor && (
                              <Spinner
                                onChange={handleChange}
                                value={values.splits[index].hourlyWage}
                                name={`splits[${index}].hourlyWage`}
                                onBlur={() => {
                                  // eslint-disable-next-line no-restricted-globals
                                  if (isNaN(values.splits[index].hourlyWage)) {
                                    setFieldValue(
                                      `splits.${index}.hourlyWage`,
                                      0
                                    )
                                  }
                                }}
                                style={styles.input}
                                min={0}
                                max={MAX_HOURLY_WAGE}
                                step={500}
                                formatInput
                                className='split-autocomplete'
                                disabled={
                                  disabled || values.splits[index].isBreakTime
                                }
                              />
                            )}
                            <td style={{ paddingLeft: '10px' }}>
                              <Checkbox
                                checked={values.splits[index].isBreakTime}
                                name={`splits[${index}].isBreakTime`}
                                onChange={e => {
                                  setFieldValue(
                                    `splits.${index}.isBreakTime`,
                                    e.target.checked
                                  )
                                  if (e.target.checked)
                                    setFieldValue(
                                      `splits.${index}.hourlyWage`,
                                      0
                                    )
                                }}
                                disabled={disabled}
                              />
                            </td>
                            {!!index && (
                              <Button
                                onClick={() => remove(index)}
                                icon='pi pi-times'
                                className='p-button-danger'
                                style={{
                                  display: 'flex',
                                  marginLeft: '10px',
                                }}
                                disabled={disabled}
                              />
                            )}
                            <div className='alert'>
                              {pathOr(
                                null,
                                ['splits', index, 'hourlyWage'],
                                touched
                              ) &&
                                pathOr(
                                  null,
                                  ['splits', index, 'hourlyWage'],
                                  errors
                                )}
                            </div>
                          </td>
                        )}

                        {/* {isDoctor && (
                          <span>
                            {!!index && (
                              <Button
                                onClick={() => remove(index)}
                                icon='pi pi-times'
                                className='p-button-danger'
                                style={{
                                  display: 'flex',
                                  marginLeft: '10px',
                                }}
                                disabled={disabled}
                              />
                            )}
                          </span>
                        )} */}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              {isAcceptedWorkScheduleOverTime && (
                <div
                  style={{ width: '90%', textAlign: 'center' }}
                  className='asterisk-label-head'
                >
                  半角数字で入力してください
                </div>
              )}

              <div style={{ textAlign: 'right' }}>
                {!disabled && values.splits.length < 4 && (
                  <Button
                    // style={{marginRight: '30px'}}
                    onClick={() => {
                      const newDate = getAvailableTime({
                        currentTime:
                          values.splits[values.splits.length - 1].date,
                      })
                      push({
                        date: newDate,
                        hourlyWage: 0,
                      })
                    }}
                    icon='pi pi-plus'
                    label={i18n.t('main.add')}
                  />
                )}
              </div>
            </React.Fragment>
          )}
        />
      </div>
    </div>
    {/* {isMoneyPermitted && (
      <div className='tr'>
        <div className='th'>{i18n.t('main.dailySalary')}</div>
        <div className='td'>
          {calculateSalary({
            availableShift: splitLogic({
              submitDate: values.date,
              splits: values.splits,
            }),
            startTime: moment(values.date)
              .hour(moment(values.startTime).hour())
              .minute(moment(values.startTime).minute()),
            endTime: moment(values.date)
              .hour(moment(values.splits[values.splits.length - 1].date).hour())
              .minute(
                moment(values.splits[values.splits.length - 1].date).minute()
              ),
          })}
        </div>
      </div>
    )} */}
  </React.Fragment>
)

export default ShiftFormWorkOutsideSplits
