import moment from 'moment'

const convertUserIncentiveCsvRecord = data =>
  data.map(
    ({
      氏名: doctorName,
      DS登録メールアドレス: email,
      診療科: department,
      拠点: clinic,
      日付: date,
      非常に満足したの件数: field1,
      まあまあ満足したの件数: field2,
      普通の件数: field3,
      満足できなかったの件数: field4,
      非常に満足の割合: field5,
      前項目の全体平均: field6,
      総診察件数: field7,
      総診察時間: field8,
      診察のみの診察件数: field9,
      診察のみの診察時間: field10,
      一件当たりのすべての診療時間の平均: field11,
      一件当たりのすべての診療時間のその日の全体平均: field12,
      一件当たりの診察のみの診療時間の平均: field13,
      一件当たりの診察のみの診療時間のその日の全体平均: field14,
    }) => ({
      doctorName: doctorName.replace(/ /g, '').replace(/　/g, ''),
      doctorEmail: email.trim(),
      doctorDepartment: department.trim(),
      doctorClinic: clinic.trim(),
      date: moment(date, 'YYYY-MM-DD').endOf('day'),
      field1: field1.trim(),
      field2: field2.trim(),
      field3: field3.trim(),
      field4: field4.trim(),
      field5: field5.trim(),
      field6: field6.trim(),
      field7: field7.trim(),
      field8: field8.trim(),
      field9: field9.trim(),
      field10: field10.trim(),
      field11: field11.trim(),
      field12: field12.trim(),
      field13: field13.trim(),
      field14: field14.trim(),
    })
  )
export default convertUserIncentiveCsvRecord
