export default {
  related: {
    padding: '15px',
  },
  empty: {
    paddingBottom: '20px',
    paddingTop: 0,
    color: '#666',
    textAlign: 'center',
  },
}
