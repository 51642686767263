import { useApolloClient } from '@apollo/react-hooks'
import { STAFF_ME } from '@medical/layout/StaffLayout/StaffLayout.graphql'
import { useCustom } from '@medical/provider/context'
import { Button } from 'primereact/button'
import React from 'react'
import { Link } from 'react-router-dom'

import Router from './router'

export const useStaffPermission = () => {
  const client = useApolloClient()
  const data = client.readQuery({
    query: STAFF_ME,
  })
  const { staffMe } = data
  const permissions = staffMe.staffGroup.permissions.reduce((acc, curr) => {
    if (curr.api.type === 'PAGE' && curr.action === 'ALL') {
      acc.push(curr.api.name)
    }
    return acc
  }, [])
  return permissions
}

const Permission = ({ children, permissionId }) => {
  const [{ i18n }] = useCustom()
  const permissions = useStaffPermission()
  if (permissions.includes(permissionId) || permissionId === undefined) {
    return children
  }
  return (
    <div className='static-container'>
      <div className='static-title'>エラー</div>
      <div className='static-content'>{i18n.t('main.noPermissionToSee')}</div>
      <Link to={Router.staffHome}>
        <Button
          label={i18n.t('main.pageNotFoundButton')}
          className='static-button'
        />
      </Link>
    </div>
  )
}

export default Permission
