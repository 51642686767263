import gql from 'graphql-tag'

export const DOCTOR_ME_CHAT = gql`
  query DOCTOR_ME {
    doctorMe {
      id
      email
      conversation {
        id
        doctor {
          id
        }
        status
      }
    }
  }
`

export const CREATE_MESSAGE = gql`
  mutation CREATE_MESSAGE(
    $message: String!
    $imageKey: String
    $conversationId: String
    $doctorId: String
  ) {
    createConversationContent(
      message: $message
      imageKey: $imageKey
      conversationId: $conversationId
      doctorId: $doctorId
    )
  }
`

export const GET_CONVERSATION_CONTENTS = gql`
  query Node(
    $where: ConversationContentWhereInput
    $first: Int
    $skip: Int
    $orderBy: ConversationContentOrderByInput
  ) {
    conversationContentsConnection(
      where: $where
      first: $first
      skip: $skip
      orderBy: $orderBy
    ) {
      edges {
        node {
          id
          deletedAt
          createdAt
          updatedAt
          message
          isCreatedByStaff
          imageKey
          index
          isRead
          conversation {
            id
            doctor {
              id
              lastname
              firstname
            }
          }
        }
      }
    }
  }
`

export const SEARCH_IN_CONVERSATION = gql`
  query ConversationContents(
    $where: ConversationContentWhereInput
    $orderBy: ConversationContentOrderByInput
  ) {
    conversationContents(where: $where, orderBy: $orderBy) {
      id
      message
      index
      createdAt
    }
  }
`

export const GET_MESSAGES_BY_INDEX = gql`
  query ConversationContentsConnection(
    $where: ConversationContentWhereInput
    $orderBy: ConversationContentOrderByInput
  ) {
    conversationContentsConnection(where: $where, orderBy: $orderBy) {
      edges {
        node {
          id
          message
          imageKey
          index
          isCreatedByStaff
        }
      }
    }
  }
`
export const MARK_AS_READ = gql`
  mutation MARK_AS_READ($conversationId: String!) {
    markAsReadConversationContent(conversationId: $conversationId)
  }
`

export const CONVERSATION_CONTENT_CONNECTION_SUBSCRIPTION = gql`
  subscription CONVERSATION_CONTENT_CONNECTION_SUBSCRIPTION(
    $conversationId: ID!
  ) {
    conversationContentsSubscription(conversationId: $conversationId) {
      id
      deletedAt
      createdAt
      updatedAt
      message
      isCreatedByStaff
      imageKey
      index
      isRead
      conversation {
        id
        doctor {
          id
          lastname
          firstname
        }
      }
    }
  }
`

export const DOCTOR_MSG_SUBSCRIPTION = gql`
  subscription DOCTOR_MSG_SUBSCRIPTION($doctorId: ID!) {
    doctorConversationsSubscription(doctorId: $doctorId) {
      id
      isReadByDoctor
    }
  }
`

export const GET_POST_PRE_SIGNED_URL_CHAT = gql`
  mutation GET_POST_PRE_SIGNED_URL_CHAT($fileName: String!, $mimetype: String!) {
    createPresignedPostUrl(fileName: $fileName, mimetype: $mimetype)
  }
`
