import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import {
  STAFF_DELETE_RECRUITMENT_POST,
  STAFF_UPDATE_RECRUITMENT_POST,
} from '@medical/constant/permissions'
import {
  checkStaffPermission,
  clinicalDepartmentNames,
  combineNames,
  sortAndFilterByClinicOrder,
  waitFor,
} from '@medical/libs'
import { DOCTORS_DEPARTMENTS } from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import RecruitmentPostForm from '@medical/pages/staff/RecruitmentPost/components/RecruitmentPostForm'
import { useCustom } from '@medical/provider/context'
import Router from '@medical/routes/router'
import React, { useEffect } from 'react'

import {
  DELETE_RECRUITMENT_POST,
  SINGLE_RECRUITMENT_POST,
  UPDATE_RECRUITMENT_POST,
} from './RecruitmentPostUpdate.graphql'

const RecruitmentPostUpdate = ({
  history,
  match: {
    params: { recruitment_postId },
  },
}) => {
  const [{ i18n, popup }] = useCustom()
  const isUpdatePermitted = checkStaffPermission({
    functionName: STAFF_UPDATE_RECRUITMENT_POST,
  })
  const isDeletePermitted = checkStaffPermission({
    functionName: STAFF_DELETE_RECRUITMENT_POST,
  })
  const [updateRecruitmentPost] = useMutation(UPDATE_RECRUITMENT_POST)
  const [deleteRecruitmentPost] = useMutation(DELETE_RECRUITMENT_POST)
  const { loading, error, data, refetch } = useQuery(SINGLE_RECRUITMENT_POST, {
    variables: {
      where: {
        id: recruitment_postId,
      },
    },
  })
  const {
    loading: clinicalDepartmentLoading,
    error: clinicalDepartmentErr,
    data: clinicalDepartmentData,
  } = useQuery(DOCTORS_DEPARTMENTS)
  useEffect(() => {
    refetch()
  })

  if (loading || clinicalDepartmentLoading) return <ProgressSpinner />
  if (error || clinicalDepartmentErr)
    return <ErrorComponent error={error || clinicalDepartmentErr} />
  const onSubmit = async (
    {
      title,
      content,
      isPublished,
      recruitmentDoctorType,
      clinicalDepartment,
      functionName,
      file,
      img,
    },
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      if (functionName === 'update') {
        const variables = {
          id: recruitment_postId,
          title,
          content,
          isPublished,
          recruitmentDoctorType,
          clinicalDepartmentId: clinicalDepartment.id,
          image: file,
          imageKeyUpdate: !file ? (img ? null : '') : null,
        }
        await updateRecruitmentPost({
          variables,
        })
      } else if (functionName === 'delete') {
        await deleteRecruitmentPost({
          variables: {
            id: recruitment_postId,
          },
        })
      }
      await waitFor(100)
      history.push({
        pathname: Router.staffRecruitmentPosts,
      })
      popup.success(i18n.t('staff.updateAnnounce.submissionSuccess'))
      setSubmitting(false)
      resetForm()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
      setFieldValue('confirm', false)
    }
  }

  const { recruitmentPost } = data
  const { clinicalDepartments, departments } = clinicalDepartmentData
  const departmentNames = clinicalDepartmentNames({ departments })
  const sortedClinicalDepartments = sortAndFilterByClinicOrder({
    clinicalDepartments,
    isFilter: true,
  })
  return (
    <RecruitmentPostForm
      announce={recruitmentPost || {}}
      onSubmit={onSubmit}
      clinicalDepartments={[
        {
          clinicName: 'なし',
          id: '',
        },
        ...combineNames({
          clinicalDepartments: sortedClinicalDepartments,
          departmentNames,
        }),
      ]}
      isUpdateOrDelete
      isUpdatePermitted={isUpdatePermitted}
      isDeletePermitted={isDeletePermitted}
    />
  )
}

export default RecruitmentPostUpdate
