import gql from 'graphql-tag'

export const DOCTOR_EMAIL_NOTIFICATION_SETTINGS_FRAGMENT = gql`
  fragment DOCTOR_EMAIL_NOTIFICATION_SETTINGS_FRAGMENT on Doctor {
    isReceivedEmailNotification
  }
`

export const DOCTOR_EMAIL_NOTIFICATION_SETTINGS_QUERY = gql`
  query doctorEmailNotificationSettingsQuery {
    doctorEmailNotificationSettings: doctorMe {
      id
      ...DOCTOR_EMAIL_NOTIFICATION_SETTINGS_FRAGMENT
    }
  }
  ${DOCTOR_EMAIL_NOTIFICATION_SETTINGS_FRAGMENT}
`

export const DOCTOR_UPDATE_EMAIL_NOTIFICATION_SETTINGS = gql`
  mutation doctorUpdateEmailNotificationSettings(
    $isReceivedEmailNotification: Boolean!
  ) {
    doctorUpdateEmailNotificationSetting(
      isReceivedEmailNotification: $isReceivedEmailNotification
    )
  }
`
