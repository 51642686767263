import React, {useMemo, useState} from 'react';
import {useCustom} from "../../../provider/context";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {
  CLINICAL_DEPARTMENTS,
  CLINICS_CONNECTION,
  CREATE_EXPECTATION_DOUBLE_RECRUITMENT_RECORD_PATTERN, GET_EXPECTATION_DOUBLE_RECRUITMENT_RECORD_PATTERN
} from "./ExpectationDoubleRecruitmentRecordPattern.graphql";
import moment from "moment/moment";
import {ErrorComponent, ProgressSpinner} from "../../../components";
import ExpectationDoubleRecruitmentRecordPatternScene from "./ExpectationDoubleRecruitmentRecordPatternScene";
import Router from "../../../routes/router";

const ExpectationDoubleRecruitmentRecordPattern = (
  {
    history,
    match: {
      path,
      params: {year = moment().year(), month = moment().month()},
    },
  }
) => {
  const [{popup}] = useCustom()
  const [state, setState] = useState({
    isUploaded: false,
  })

  const {
    loading: clinicsLoading,
    error: clinicsError,
    data: clinics,
    refetch: clinicRefetch
  } = useQuery(CLINICS_CONNECTION, {
    fetchPolicy: 'network-only',
  })

  const [clinicalDepartments] = useMutation(CLINICAL_DEPARTMENTS)
  const [createExpectationDoubleRecruitmentRecordPattern] = useMutation(CREATE_EXPECTATION_DOUBLE_RECRUITMENT_RECORD_PATTERN)
  const [mutationGetExpectationDoubleRecruitmentRecordPattern] = useMutation(GET_EXPECTATION_DOUBLE_RECRUITMENT_RECORD_PATTERN)

  const currentDate = useMemo(
    () =>
      moment()
        .year(year)
        .month(month - 1)
        .startOf('month')
        .toISOString(),
    [year, month]
  )

  //
  // useEffect(() => {
  //   clinicRefetch()
  //
  // }, [])

  if (clinicsLoading) return <ProgressSpinner/>
  if (clinicsError) return <ErrorComponent
    error={clinicsError}/>

  const {
    clinicsConnection: {
      edges,
    },
  } = clinics

  const convertClinic = (clinics) => (clinics.map(clinic => {
    return {
      ...clinic.node,
    }
  }))
  const clinicalDepartmentNameRender = (name) => {
    let clinicDepartmentName = ''
    switch (name) {
      case 'INTERNAL_MEDICINE': {
        clinicDepartmentName = '内科'
        break
      }
      case 'CHILDREN_MEDICINE': {
        clinicDepartmentName = '小児科'
        break
      }
      case 'CHILDREN_VACCINE_MEDICINE': {
        clinicDepartmentName = '小児科ワクチン'
        break
      }
      case 'INTERNAL_VACCINE_MEDICINE': {
        clinicDepartmentName = '内科ワクチン'
        break
      }
      default: {
        break
      }
    }
    return clinicDepartmentName
  }

  const convertClinicalDepartment = (clinicalDepartments) => (clinicalDepartments.map(clinicalDepartment => {
    return {
      label: clinicalDepartmentNameRender(clinicalDepartment.name),
      ...clinicalDepartment
    }
  }))

  const onSubmit = async ({clinic_id, clinical_department_id, data}, {resetForm}) => {
    setState({
      isUploaded: true,
    })
    try {
      const convertData = {
        clinic: clinic_id,
        clinicalDepartment: clinical_department_id,
        targetMonth: +month,
        targetYear: +year,
        mondayMorningShiftDoctors: null,
        mondayAfternoonShiftDoctors: null,
        mondayNightShiftDoctors: null,
        tuesdayMorningShiftDoctors: null,
        tuesdayAfternoonShiftDoctors: null,
        tuesdayNightShiftDoctors: null,
        wednesdayMorningShiftDoctors: null,
        wednesdayAfternoonShiftDoctors: null,
        wednesdayNightShiftDoctors: null,
        thursdayMorningShiftDoctors: null,
        thursdayAfternoonShiftDoctors: null,
        thursdayNightShiftDoctors: null,
        fridayMorningShiftDoctors: null,
        fridayAfternoonShiftDoctors: null,
        fridayNightShiftDoctors: null,
        saturdayMorningShiftDoctors: null,
        saturdayAfternoonShiftDoctors: null,
        saturdayNightShiftDoctors: null,
        sundayMorningShiftDoctors: null,
        sundayAfternoonShiftDoctors: null,
        sundayNightShiftDoctors: null,
        holidayMorningShiftDoctors: null,
        holidayAfternoonShiftDoctors: null,
        holidayNightShiftDoctors: null,
      }
      data.map((item, index) => {
        if (index === 0) {
          convertData.mondayMorningShiftDoctors = item?.monday ?? null
          convertData.tuesdayMorningShiftDoctors = item?.tuesday ?? null
          convertData.wednesdayMorningShiftDoctors = item?.wednesday ?? null
          convertData.thursdayMorningShiftDoctors = item?.thursday ?? null
          convertData.fridayMorningShiftDoctors = item?.friday ?? null
          convertData.saturdayMorningShiftDoctors = item?.saturday ?? null
          convertData.sundayMorningShiftDoctors = item?.sunday ?? null
          convertData.holidayMorningShiftDoctors = item?.holiday ?? null
        } else if (index === 1) {
          convertData.mondayAfternoonShiftDoctors = item?.monday ?? null
          convertData.tuesdayAfternoonShiftDoctors = item?.tuesday ?? null
          convertData.wednesdayAfternoonShiftDoctors = item?.wednesday ?? null
          convertData.thursdayAfternoonShiftDoctors = item?.thursday ?? null
          convertData.fridayAfternoonShiftDoctors = item?.friday ?? null
          convertData.saturdayAfternoonShiftDoctors = item?.saturday ?? null
          convertData.sundayAfternoonShiftDoctors = item?.sunday ?? null
          convertData.holidayAfternoonShiftDoctors = item?.holiday ?? null
        } else {
          convertData.mondayNightShiftDoctors = item?.monday ?? null
          convertData.tuesdayNightShiftDoctors = item?.tuesday ?? null
          convertData.wednesdayNightShiftDoctors = item?.wednesday ?? null
          convertData.thursdayNightShiftDoctors = item?.thursday ?? null
          convertData.fridayNightShiftDoctors = item?.friday ?? null
          convertData.saturdayNightShiftDoctors = item?.saturday ?? null
          convertData.sundayNightShiftDoctors = item?.sunday ?? null
          convertData.holidayNightShiftDoctors = item?.holiday ?? null
        }
      })

      const rs = await createExpectationDoubleRecruitmentRecordPattern(
        {
          variables: convertData
        }
      )
      if (rs) {
        setState({
          isUploaded: false,
        })
        popup.success('成功　保存しました。')
        await history.push({
          pathname: Router.get(Router.staffExpectationDoubleRecruitmentRecord,
            {
              year: moment(currentDate).year(),
              month: moment(currentDate).format('MM'),
            }
          )
        })
      } else {
        popup.error('エラー　エラーが発生しました。再度ご確認ください。')
      }
    } catch (error) {
      console.error(error)
      popup.clear()
      popup.error(error)
    }

  }

  return (
    <ExpectationDoubleRecruitmentRecordPatternScene
      mutationGetExpectationDoubleRecruitmentRecordPattern={mutationGetExpectationDoubleRecruitmentRecordPattern}
      onSubmit={onSubmit}
      isUploaded={state.isUploaded}
      convertClinic={convertClinic}
      convertClinicalDepartment={convertClinicalDepartment}
      clinics={edges}
      year={year}
      month={month}
      clinicalDepartments={clinicalDepartments}/>

  );
};

export default ExpectationDoubleRecruitmentRecordPattern;