import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import PortalForm from '@medical/pages/staff/Portal/components/PortalForm'
import { useCustom } from '@medical/provider/context'
import React, { useEffect } from 'react'
import Router from '@medical/routes/router'
import { QUERY_PORTAL, DELETE_PORTAL } from './PortalUpdate.graphql'
import {
  CATEGORY_QUERY,
  CREATE_PORTAL,
} from '../PortalCreate/PortalCreate.graphql'
import {
  STAFF_CREATE_PORTAL,
  STAFF_UPDATE_PORTAL,
  STAFF_DELETE_PORTAL,
} from '@medical/constant/permissions'
import { checkStaffPermission } from '@medical/libs'
import { CREATE_ACTIVITY } from '@medical/pages/doctor/AvailableShift/AvailableShift.graphql'

const PortalUpdate = ({
  history,
  match: {
    params: { portalId },
  },
}) => {
  const [{ i18n, popup }] = useCustom()
  const isCreatePermitted = checkStaffPermission({
    functionName: STAFF_CREATE_PORTAL,
  })
  const isDeletePermitted = checkStaffPermission({
    functionName: STAFF_DELETE_PORTAL,
  })
  const isUpdatePermitted = checkStaffPermission({
    functionName: STAFF_UPDATE_PORTAL,
  })
  const [staffCreateActivity] = useMutation(CREATE_ACTIVITY)
  const [updatePortal] = useMutation(CREATE_PORTAL)
  const [deletePortal] = useMutation(DELETE_PORTAL)
  const {
    loading: largeCategoryLoading,
    error: largeCategoryError,
    data: largeCategoryData,
    refetch: largeCategoryRefetch,
  } = useQuery(CATEGORY_QUERY, {
    variables: {
      where: {
        titleType: 'LARGE_TITLE',
      },
      orderBy: 'largeTitle_ASC',
    },
  })
  const {
    loading: mediumCategoryLoading,
    error: mediumCategoryError,
    data: mediumCategoryData,
    refetch: mediumCategoryRefetch,
  } = useQuery(CATEGORY_QUERY, {
    variables: {
      where: {
        titleType: 'MEDIUM_TITLE',
      },
      orderBy: 'mediumTitle_ASC',
    },
  })
  const { loading, error, data } = useQuery(QUERY_PORTAL, {
    variables: {
      where: {
        id: portalId,
        titleType: 'SMALL_TITLE',
      },
    },
  })
  useEffect(() => {
    largeCategoryRefetch()
    mediumCategoryRefetch()
  })
  if (loading || largeCategoryLoading || mediumCategoryLoading)
    return <ProgressSpinner />
  if (error || largeCategoryError || mediumCategoryError)
    return (
      <ErrorComponent
        error={error || largeCategoryError || mediumCategoryError}
      />
    )

  const onSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    let successMessage = '更新しました。'
    if (values.function === 'delete') successMessage = '削除しました。'
    try {
      if (values.function === 'delete') {
        await deletePortal({
          variables: {
            portalId,
            isDeleted: true,
          },
        })
        staffCreateActivity({
          variables: {
            activity: 'STAFF_DELETE_PORTAL',
          },
        })
      } else {
        await updatePortal({
          variables: {
            portalId,
            largeTitle: values.largeTitle,
            mediumTitle: values.mediumTitle,
            smallTitle: values.smallTitle,
            content: values.content,
            titleType: 'SMALL_TITLE',
          },
        })
        staffCreateActivity({
          variables: {
            activity: 'STAFF_UPDATE_PORTAL',
          },
        })
      }
      popup.success(successMessage)
      setSubmitting(false)
      resetForm()
      history.push(Router.staffPortals)
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
      setFieldValue('confirm', false)
    }
  }
  const { portals } = data
  return (
    <PortalForm
      portal={portals[0] || {}}
      largeCategory={largeCategoryData && largeCategoryData.portals}
      mediumCategory={mediumCategoryData && mediumCategoryData.portals}
      onSubmit={onSubmit}
      updatePortal
      isCreatePermitted={isCreatePermitted}
      isUpdatePermitted={isUpdatePermitted}
      isDeletePermitted={isDeletePermitted}
    />
  )
}

export default PortalUpdate
