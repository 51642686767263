/* eslint-disable no-nested-ternary */
import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import ClinicNameRender from '@medical/components/ClinicNameRender'
import { CATEGORY_TRANSPORT } from '@medical/constant/index'
import {
  calcTotalTransportationExpense,
  calcWorkScheduleTotalDays,
  clinicalDepartmentNames,
  dateValidation,
} from '@medical/libs'
import { UPDATE_TYPE_TRANSPORTATION_WORKSCHEDULE } from '@medical/pages/staff/MovingExpenses/ListMovingExpenses/ListMovingExpenses.graphql'
import { useCustom } from '@medical/provider/context'
import Router from '@medical/routes/router'
import moment from 'moment'
import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'

import { DOCTOR_GET_TRANSPORT_REPORTS } from './TransportReports.graphql'
import TransportReportsScene from './TransportReportsScene'

const TransportReports = ({
  match: {
    path,
    params: {
      year = moment()
        .utc()
        .year(),
      month = moment()
        .utc()
        .month() + 1,
    },
  },
}) => {
  const [{ i18n, popup }] = useCustom()
  const [visible, setvisible] = useState(false)
  const [openImgDialog, setOpenImgDialog] = useState(false)
  const handleOpenImgDialog = value => {
    setOpenImgDialog(value)
  }
  const showModal = () => {
    setvisible(true)
  }
  const hideModal = () => {
    setvisible(false)
  }
  const date = moment()
    .utc()
    .year(year)
    .month(month - 1)
    .startOf('month').toISOString()
  const variables = {
    date,
  }
  const { loading, error, data, refetch } = useQuery(
    DOCTOR_GET_TRANSPORT_REPORTS,
    {
      variables,
    }
  )
  const mutationOptions = {
    refetchQueries: [
      {
        query: DOCTOR_GET_TRANSPORT_REPORTS,
        variables,
      },
    ],
    awaitRefetchQueries: true,
  }
  const [updateType] = useMutation(
    UPDATE_TYPE_TRANSPORTATION_WORKSCHEDULE,
    mutationOptions
  )
  if (
    dateValidation({ year, month }, true) ||
    [
      Router.doctorTransportReportsWithoutYearMonth,
      Router.doctorTransportReportsWithoutMonth,
    ].includes(path)
  )
    return (
      <Redirect
        to={Router.get(Router.doctorTransportReports, {
          year: moment()
            .utc()
            .year(),
          month:
            moment()
              .utc()
              .month() + 1,
        })}
      />
    )
  const onSubmit = async (
    { workScheduleId, isMyCar },
    { setSubmitting, resetForm }
  ) => {
    try {
      await updateType({
        variables: {
          where: {
            id: workScheduleId,
          },
          data: {
            isTypeTransportation: isMyCar,
          },
        },
      })
      setSubmitting(false)
      resetForm()
      popup.success(i18n.t('staff.createAnnounce.submissionSuccess'))
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
      resetForm()
    }
  }

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  const {
    doctorMonthlyTransportationExpenses,
    isBlockedMyCar,
    doctorMonthlyWorkSchedules: workSchedules = [],
  } = data.doctorMe
  const departmentNames = clinicalDepartmentNames({
    departments: data.departments,
  })
  const lstTransportationWithDate = []
  for (const transportation of doctorMonthlyTransportationExpenses) {
    if (transportation.category === CATEGORY_TRANSPORT.TRANFFIC_FEE) {
      lstTransportationWithDate.push({ ...transportation })
    } else {
      const startTime = moment.utc(transportation.startTime).clone()
      const endTime = moment.utc(transportation.endTime)
      while (startTime.isSameOrBefore(endTime)) {
        const timeCurrent = moment.utc(startTime).format('YYYY-MM-DD')
        const datesCheck = transportation.blockedDays
          ? transportation.blockedDays.split(',')
          : []

        if (!datesCheck.includes(timeCurrent.slice(0, 10))) {
          lstTransportationWithDate.push({
            ...transportation,
            startTime: `${timeCurrent}T00:00:00.000Z`,
            endTime: `${timeCurrent}T00:00:00.000Z`,
          })
        }
        startTime.add(1, 'days')
      }
    }
  }
  const sortedWorkSchedules = workSchedules
    .sort((a, b) => moment(a.startTime) - moment(b.startTime))
    .filter(item => !item.notApplyCostTransportation)
    .map(ws => ({
      deletedAt: ws.deletedAt,
      id: ws.id,
      endTime: ws.startTime,
      isShowSelectType: true,
      isTypeTransportation: ws.isTypeTransportation || false,
      clinic: ClinicNameRender({
        clinicalDepartment: ws.clinicalDepartment,
      }),
      clinicalDepartment: departmentNames[ws.clinicalDepartment.name],
      startTime: ws.startTime,
      costWs: ws.isTypeTransportation
        ? ws.costTransportationByMyCar
        : ws.costTransportationByDoctorWorkSchedule.length > 0
        ? ws.costTransportationByDoctorWorkSchedule[0].cost
        : 0,
      costTransportationByDoctorWorkSchedule: ws.isTypeTransportation
        ? ws.costTransportationByMyCar
        : ws.costTransportationByDoctorWorkSchedule.length > 0
        ? ws.costTransportationByDoctorWorkSchedule[0].returnPath[2]
        : null,
      notApplyCostTransportation: ws.notApplyCostTransportation,
    }))
  const dataTransportation = lstTransportationWithDate
    .sort((a, b) => moment(a.endTime) - moment(b.endTime))
    .filter(({ deletedAt }) => !deletedAt)
    .map(item => ({
      id: item.id,
      category:
        item.category !== CATEGORY_TRANSPORT.TRANFFIC_FEE
          ? item.category
          : undefined,
      deletedAt: item.deletedAt,
      type: item.type,
      clinicDate:
        item.category !== CATEGORY_TRANSPORT.TRANFFIC_FEE
          ? item.clinic.name
          : null,
      clinic:
        item.clinicalDepartment &&
        ClinicNameRender({
          clinicalDepartment: item.clinicalDepartment,
        }),
      clinicalDepartment:
        item.clinicalDepartment &&
        departmentNames[item.clinicalDepartment.name],
      cost: item.cost,
      createdAt: item.createdAt,
      startTime: item.startTime,
      endTime: item.endTime,
      isTransport: true,
      departmentID: item.clinicalDepartment,
      urlImageS3: item.urlImageS3,
    }))
  const dataReport = [...sortedWorkSchedules, ...dataTransportation]
  const totalTransportationExpense = calcTotalTransportationExpense(
    dataTransportation,
    sortedWorkSchedules
  )
  const totalDays = calcWorkScheduleTotalDays(sortedWorkSchedules)
  const countTransport = dataTransportation.filter(
    item => !item.deletedAt && item.type
  ).length
  const getTransportationExpenseDuplicate = () => {
    const recurrentsDuplicate = []
    if (dataReport && dataReport.length > 0) {
      dataReport.forEach((as, index) => {
        if (!as.deletedAt) {
          if (!as.notApplyCostTransportation) {
            dataReport.forEach((val, index2) => {
              if (index !== index2 && as.category !== val.category) {
                if (!val.deletedAt) {
                  if (!val.notApplyCostTransportation) {
                    const startTimeAs = as.startTime ? as.startTime : as.endTime
                    const checkTimeAs = moment(startTimeAs).format('YYYY-MM-DD')
                    const startTimeVal = val.startTime
                      ? val.startTime
                      : val.endTime
                    const checkTimeVal = moment(startTimeVal).format(
                      'YYYY-MM-DD'
                    )
                    if (checkTimeVal === checkTimeAs) {
                      recurrentsDuplicate.push(index)
                    }
                  }
                }
              }
            })
          }
        }
      })
    }
    return recurrentsDuplicate
  }

  dataReport.sort((a, b) => new Date(a.endTime) - new Date(b.endTime))
  const getNumberIncentive = () => {
    let count = 0
    dataReport.forEach(as => {
      if (!as.deletedAt && as.category === 'RECURRING_TRAVEL_FEE') {
        count += 1
      }
    })
    return count
  }
  const listDuplicates = getTransportationExpenseDuplicate()
  return (
    <TransportReportsScene
      i18n={i18n}
      data={dataReport}
      date={date}
      year={year}
      month={month}
      totalCost={totalTransportationExpense}
      totalDays={totalDays}
      countTransport={countTransport}
      listDuplicates={listDuplicates}
      numberIncentive={getNumberIncentive()}
      onSubmit={onSubmit}
      isBlockedMyCar={isBlockedMyCar}
      showModal={showModal}
      visible={visible}
      hideModal={hideModal}
      openImgDialog={openImgDialog}
      handleOpenImgDialog={handleOpenImgDialog}
    />
  )
}
export default TransportReports
