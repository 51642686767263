import BreadCrumb from '@medical/components/Breadcrumb'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import CustomRadioButton from '@medical/components/RadioButton'
import TextInput from '@medical/components/TextInput'
import Router from '@medical/routes/router'
import { FieldArray, Formik } from 'formik'
import { Button } from 'primereact/button'
import { Accordion, AccordionTab } from 'primereact/accordion'
import React from 'react'
import * as Yup from 'yup'
import './style.css'
const StaffGroupForm = ({
  i18n,
  onSubmit,
  staffGroup,
  permittedActions,
  submitLabel,
  confirmationMessage,
  apiGroupTypes,
}) => {
  const validationSchema = Yup.object().shape({
    groupName: Yup.string()
      .nullable()
      .required(`${i18n.t('main.groupName')} ${i18n.t('validation.required')}`),
  })

  const { permissions } = staffGroup
  const sortByType = permissions.sort((a, b) => {
    a = a.api.description
    b = b.api.description
    if (a < b) {
      return -1
    }
    if (a > b) {
      return 1
    }
    return 0
  })

  return (
    <Formik
      initialValues={{
        confirm: false,
        groupName: staffGroup.name,
        permissions: sortByType,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={formikProps => {
        const { values, setFieldValue, validateForm, setTouched } = formikProps
        return (
          <React.Fragment>
            <div className='staff-header'>
              <div className='staff-title'>
                {i18n.t('staff.menuBar.staffGroups')}
                <BreadCrumb
                  items={[
                    {
                      to: Router.staffGroups,
                      title: i18n.t('staff.menuBar.staffGroups'),
                    },
                    { title: i18n.t('staff.staffGroup.create') },
                  ]}
                />
              </div>
            </div>
            <div className='container staff-group'>
              <div className='box'>
                <FieldArray
                  name='permissions'
                  render={() => (
                    <div>
                      <div>
                        <TextInput
                          {...formikProps}
                          name='groupName'
                          label={i18n.t('main.groupName')}
                        />
                      </div>
                      <div className='tr'>
                        <div className='td'>
                          <Accordion
                            multiple
                            activeIndex={null}
                            className='accordion-custom-staff-group'
                          >
                            {apiGroupTypes.length > 0 &&
                              apiGroupTypes.map((apiType, index) => {
                                const listIndex = []
                                const listPermissions =
                                  apiType.value !== 'OTHER'
                                    ? values.permissions.filter((p, i) => {
                                        if (p.api.apiGroup === apiType.value) {
                                          listIndex.push(i)
                                          return p
                                        }
                                      })
                                    : values.permissions.filter((p, i) => {
                                        if (p.api.apiGroup === null) {
                                          listIndex.push(i)
                                          return p
                                        }
                                      })
                                return (
                                  <AccordionTab
                                    key={index}
                                    header={
                                      <span style={{ color: 'white' }}>
                                        {apiType.description}
                                      </span>
                                    }
                                  >
                                    <div
                                      style={{
                                        flexGrow: 1,
                                        overflowY: 'auto',
                                      }}
                                    >
                                      <table className='table'>
                                        <tbody>
                                          {listPermissions.map((item, i) => (
                                            <tr key={item.id}>
                                              <td width='40%'>
                                                {item.api.description}
                                              </td>
                                              <td width='60%'>
                                                <div className='action'>
                                                  <CustomRadioButton
                                                    {...formikProps}
                                                    apiName={item.api.name}
                                                    name={`permissions.${listIndex[i]}.action`}
                                                    options={permittedActions.filter(
                                                      p =>
                                                        p.value !==
                                                        'ONLY_MY_CLINIC'
                                                    )}
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  </AccordionTab>
                                )
                              })}
                          </Accordion>
                        </div>
                      </div>
                      {/* <div style={{ padding: '10px 20px 0' }}>
                        <table className='table'>
                          <thead>
                            <tr>
                              <th width='40%'>{i18n.t('main.apiName')}</th>
                              <th width='60%'>{i18n.t('main.permission')}</th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                      <div
                        style={{
                          flexGrow: 1,
                          overflowY: 'auto',
                          padding: '0 20px',
                        }}
                      >
                        <table className='table'>
                          <tbody>
                            {values.permissions.map((item, index) => (
                              <tr key={item.id}>
                                <td width='40%'>{item.api.description}</td>
                                <td width='60%'>
                                  <div className='action'>
                                    <CustomRadioButton
                                      {...formikProps}
                                      apiName={item.api.name}
                                      name={`permissions.${index}.action`}
                                      options={permittedActions.filter(
                                        p => p.value !== 'ONLY_MY_CLINIC'
                                      )}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div> */}

                      <div className='modal-bottom'>
                        <Button
                          onClick={() => {
                            validateForm().then(response => {
                              if (!Object.keys(response).length) {
                                setFieldValue('confirm', true)
                              } else {
                                setTouched(response)
                              }
                            })
                          }}
                          label={submitLabel}
                        />
                      </div>
                      <ConfirmationModal
                        {...formikProps}
                        confirmationMessage={confirmationMessage}
                      />
                    </div>
                  )}
                />
              </div>
            </div>
          </React.Fragment>
        )
      }}
    />
  )
}

export default StaffGroupForm
