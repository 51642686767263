import { useApolloClient } from '@apollo/react-hooks'
import AutoComplete from '@medical/components/AutoComplete'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import { convertDoctorsConnection } from '@medical/libs'
import { DOCTOR_CONNECTION } from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import { Checkbox, DatePicker, Input, InputNumber } from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import { ErrorMessage, Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { pathOr } from 'ramda'
import React, { useState } from 'react'
// import { AutoComplete } from 'primereact/autocomplete'
import * as Yup from 'yup'

const MovingExpensesRegistrationDetailScence = ({
  clinics,
  i18n,
  data,
  onSubmit,
  isCreate,
  doctors,
}) => {
  const isPast = !!(data && data.returnPath.length > 0)
  const client = useApolloClient()
  const dataClinic = clinics.clinics.filter(it => it.isPublished === true)
  const disabledDate = current => {
    const dateNow = moment().format('DD')
    let minDay = moment().startOf('month')
    const maxDay = moment().add(3, 'month')
    if (dateNow <= 10) {
      minDay = moment()
        .subtract(1, 'month')
        .startOf('month')
      return !minDay.isSameOrBefore(current) || !maxDay.isSameOrAfter(current)
    }
    return !maxDay.isSameOrAfter(current) || !minDay.isSameOrBefore(current)
  }

  const getFullName = (lastname, firstname) => {
    const lastName = lastname || ''
    const firstName = firstname ? ` ${firstname}` : ''
    return lastName + firstName
  }
  const validationSchema = Yup.object().shape({
    cost: Yup.string()
      .nullable()
      .required(
        `${i18n.t('staff.movingExpensesDetail.oneWayFare')}${i18n.t(
          'validation.required'
        )}`
      )
      .when('isCost', {
        is: true,
        then: Yup.string().max(0, '１日の往復金額は5,000円以内でお願いします'),
      }),
    costRoundTrip: Yup.string()
      .nullable()
      .when('isPast', {
        is: true,
        then: Yup.string().required(
          `${i18n.t('staff.movingExpensesDetail.oneWayFare')}${i18n.t(
            'staff.movingExpensesDetail.past'
          )}${i18n.t('validation.required')}`
        ),
      })
      .when('isCost', {
        is: true,
        then: Yup.string().max(0, '１日の往復金額は5,000円以内でお願いします'),
      }),
    clinic: Yup.object()
      .nullable()
      .required(`${i18n.t('main.clinicName')}${i18n.t('validation.required')}`),
    doctor: isCreate
      ? Yup.object()
          .nullable()
          .required(i18n.t('validation.doctor.required'))
      : undefined,
    url: Yup.string()
      .url(i18n.t('validation.url'))
      .required(
        `${i18n.t('staff.movingExpensesDetail.routeURL')}${i18n.t(
          'validation.required'
        )}`
      ),
    urlRoundTrip: Yup.string().when('isPast', {
      is: true,
      then: Yup.string()
        .url(i18n.t('validation.url'))
        .required(
          `${i18n.t('staff.movingExpensesDetail.routeURL')}${i18n.t(
            'staff.movingExpensesDetail.past'
          )}${i18n.t('validation.required')}`
        ),
    }),
    departurePlace: Yup.string()
      .trim()
      .required(
        `${i18n.t('staff.movingExpensesDetail.pointOfDeparture')}${i18n.t(
          'validation.required'
        )}`
      ),
    departurePlaceRoundTrip: Yup.string().when('isPast', {
      is: true,
      then: Yup.string()
        .trim()
        .required(
          `${i18n.t('staff.movingExpensesDetail.pointOfDeparture')}${i18n.t(
            'staff.movingExpensesDetail.past'
          )}${i18n.t('validation.required')}`
        ),
    }),
    arrivalPlace: Yup.string()
      .trim()
      .required(
        `${i18n.t('staff.movingExpensesDetail.destination')}${i18n.t(
          'validation.required'
        )}`
      ),
    startTime: Yup.string().required(
      `${i18n.t('staff.movingExpensesDetail.date')}${i18n.t(
        'validation.required'
      )}`
    ),
    arrivalPlaceRoundTrip: Yup.string().when('isPast', {
      is: true,
      then: Yup.string()
        .trim()
        .required(
          `${i18n.t('staff.movingExpensesDetail.destination')}${i18n.t(
            'staff.movingExpensesDetail.past'
          )}${i18n.t('validation.required')}`
        ),
    }),
  })
  return (
    <Formik
      enableReinitialize
      initialValues={{
        confirm: false,
        doctors,
        address: '',
        nearestStation: '',
        clinic: !isCreate ? data.clinic || '' : '',
        dataClinic,
        url: data && data.url,
        departurePlace: data && data.departurePlace,
        arrivalPlace: data && data.arrivalPlace,
        cost: data && data.cost ? data.cost : data ? 0 : '',
        doctorId: isCreate ? '' : data.doctor.id,
        urlRoundTrip:
          data && data.returnPath.length > 0 ? data.returnPath[3] : '',
        departurePlaceRoundTrip:
          data && data.returnPath.length > 0 ? data.returnPath[0] : '',
        arrivalPlaceRoundTrip:
          data && data.returnPath.length > 0 ? data.returnPath[1] : '',
        costRoundTrip:
          data && data.returnPath.length > 0 ? data.returnPath[2] : '',
        startTime: data
          ? moment.utc(data.startTime, 'YYYY年MM月DD日')
          : moment(new Date(), 'YYYY年MM月DD日'),
        isPast,
        isCost: false,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={formikProps => {
        let confirmationMessage = (
          <>
            更新しますか？ <br />
          </>
        )
        if (!isCreate) {
          const startTimeAddMonth = moment
            .utc(data.startTime)
            .startOf('months')
            .add(1, 'months')
            .add(10, 'days')
            .valueOf()
          const currentTime = moment.utc().valueOf()
          if (startTimeAddMonth < currentTime) {
            confirmationMessage = (
              <>
                更新しますか？ <br />
                <span className='asterisk-label-head '>
                  締め日を過ぎた交通費データは変更ができません。変更内容は、本日時点で適用可能な日付から反映されます。
                </span>
              </>
            )
          }
        } else {
          confirmationMessage = (
            <>
              登録しますか ? <br />
            </>
          )
        }

        const { setFieldValue, values, validateForm, setTouched } = formikProps

        return (
          <div className='container move-expenses-container'>
            <div className='row'>
              <div className='move-expenses-header__title'>
                {isCreate
                  ? i18n.t('staff.movingExpensesDetail.titleCreate')
                  : i18n.t('staff.movingExpensesDetail.titleDetail')}
              </div>
            </div>
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='move-expenses-item__title'>
                {i18n.t('staff.movingExpensesDetail.clinicName')}
              </div>
              <div className='width-67'>
                <Dropdown
                  {...formikProps}
                  value={values.clinic}
                  name='clinic'
                  optionLabel='name'
                  valueLabel='id'
                  options={dataClinic || []}
                  placeholder={
                    values.clinic ? values.clinic.name : 'クリニックを選択'
                  }
                  onChange={e => {
                    setFieldValue('clinic', e.value)
                  }}
                  style={{ width: '100%', height: '40px' }}
                />
                <ErrorMessage
                  name='clinic'
                  component='div'
                  className='color-red'
                />
              </div>
            </div>
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='move-expenses-item__title'>
                {i18n.t('staff.movingExpensesDetail.doctorName')}
              </div>
              <div className='width-67'>
                {!isCreate ? (
                  getFullName(data.doctor.lastname, data.doctor.firstname)
                ) : (
                  <div>
                    <AutoComplete
                      {...formikProps}
                      name='doctor'
                      field='fullNameEmail'
                      searchFields={['fullName', 'email']}
                      // allSuggestions={doctors}
                      allSuggestions={values.doctors}
                      suggestionName='doctors'
                      width='30vw'
                      placeholder={
                        doctors.length > 0 ? '医師を選択' : '対象データなし'
                      }
                      setDoctorSelect={async val => {
                        const stateOrRegion = pathOr(
                          '',
                          ['homeAddress', 'stateOrRegion'],
                          val
                        )
                        const address1 = pathOr(
                          '',
                          ['homeAddress', 'address1'],
                          val
                        )
                        const address2 = pathOr(
                          '',
                          ['homeAddress', 'address2'],
                          val
                        )
                        setFieldValue(
                          'address',
                          `${stateOrRegion}${address1}${address2}`
                        )
                        setFieldValue('doctorId', val.id)
                        setFieldValue('nearestStation', val.nearestStations)
                        if (typeof val === 'string') {
                          try {
                            const { data: dataConnection } = await client.query(
                              {
                                query: DOCTOR_CONNECTION,
                                variables: {
                                  where: {
                                    AND: [
                                      {
                                        OR: [
                                          {
                                            unSigned_contains:
                                              typeof val === 'string'
                                                ? val
                                                    .replace(/ /g, '')
                                                    .replace(/　/g, '')
                                                    .toLowerCase()
                                                : '',
                                          },
                                          {
                                            email_contains: val,
                                          },
                                        ],
                                      },
                                    ],
                                    registrationStatus_in: [
                                      'CREATED_BY_STAFF',
                                      'ACCEPTED',
                                    ],
                                  },
                                  first: 10,
                                  skip: 0,
                                  orderBy: 'unSigned_ASC',
                                },
                                fetchPolicy: 'network-only',
                              }
                            )

                            setFieldValue(
                              'doctors',
                              convertDoctorsConnection(
                                dataConnection.doctorsConnection.edges.filter(
                                  doctor => !doctor.blocked
                                )
                              )
                            )
                          } catch (err) {}
                        }
                      }}
                    />
                    <ErrorMessage
                      name='doctor'
                      component='div'
                      className='color-red'
                    />
                  </div>
                )}
              </div>
            </div>
            {isCreate && (
              <>
                <div className='row mt-15 move-inline move-expenses-item-container'>
                  <div className='move-expenses-item__title'>
                    {i18n.t('staff.movingExpensesDetail.address')}
                  </div>
                  <div className='width-67' style={{ fontSize: '16px' }}>
                    {values.address}
                  </div>
                </div>
                <div className='row mt-15 move-inline move-expenses-item-container'>
                  <div className='move-expenses-item__title'>
                    {i18n.t('staff.movingExpensesDetail.nearestStation')}
                  </div>
                  <div className='width-67' style={{ fontSize: '16px' }}>
                    {values.nearestStation}
                  </div>
                </div>
              </>
            )}
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='move-expenses-item__title'>
                {i18n.t('staff.movingExpensesDetail.routeURL')}
              </div>
              <div className='width-67 color-url'>
                <Input
                  required
                  size='large'
                  placeholder='https://www.navitime.co.jp/'
                  onChange={e => {
                    setFieldValue('url', e.target.value)
                  }}
                  value={values.url}
                  name='url'
                />

                <ErrorMessage
                  name='url'
                  component='div'
                  className='color-red'
                />
              </div>
            </div>
            {values.isPast && (
              <div className='row mt-15 move-inline move-expenses-item-container'>
                <div className='move-expenses-item__title'>
                  {`${i18n.t('staff.movingExpensesDetail.routeURL')}${i18n.t(
                    'staff.movingExpensesDetail.past'
                  )}`}
                </div>
                <div className='width-67 color-url'>
                  <Input
                    {...formikProps}
                    required
                    size='large'
                    placeholder='https://www.navitime.co.jp/'
                    onChange={e => {
                      setFieldValue('urlRoundTrip', e.target.value)
                    }}
                    value={values.urlRoundTrip}
                    name='urlRoundTrip'
                  />

                  <ErrorMessage
                    name='urlRoundTrip'
                    component='div'
                    className='color-red'
                  />
                </div>
              </div>
            )}
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='move-expenses-item__title'>
                {i18n.t('staff.movingExpensesDetail.pointOfDeparture')}
              </div>
              <div className='width-67'>
                <Input
                  {...formikProps}
                  size='large'
                  name='departurePlace'
                  placeholder='例：東京駅'
                  value={values.departurePlace}
                  onChange={e => {
                    setFieldValue('departurePlace', e.target.value)
                  }}
                />
                <ErrorMessage
                  name='departurePlace'
                  component='div'
                  className='color-red'
                />
              </div>
            </div>
            {values.isPast && (
              <div className='row mt-15 move-inline move-expenses-item-container'>
                <div className='move-expenses-item__title'>
                  {`${i18n.t(
                    'staff.movingExpensesDetail.pointOfDeparture'
                  )}${i18n.t('staff.movingExpensesDetail.past')}`}
                </div>
                <div className='width-67'>
                  <Input
                    {...formikProps}
                    placeholder='例：東京駅'
                    size='large'
                    name='departurePlaceRoundTrip'
                    value={values.departurePlaceRoundTrip}
                    onChange={e => {
                      setFieldValue('departurePlaceRoundTrip', e.target.value)
                    }}
                  />
                  <ErrorMessage
                    name='departurePlaceRoundTrip'
                    component='div'
                    className='color-red'
                  />
                </div>
              </div>
            )}
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='move-expenses-item__title'>
                {i18n.t('staff.movingExpensesDetail.destination')}
              </div>
              <div className='width-67'>
                <Input
                  placeholder='例：新宿駅バス停'
                  size='large'
                  name='arrivalPlace'
                  value={values.arrivalPlace}
                  onChange={e => {
                    setFieldValue('arrivalPlace', e.target.value)
                  }}
                />
                <ErrorMessage
                  name='arrivalPlace'
                  component='div'
                  className='color-red'
                />
              </div>
            </div>
            {values.isPast && (
              <div className='row mt-15 move-inline move-expenses-item-container'>
                <div className='move-expenses-item__title'>
                  {`${i18n.t('staff.movingExpensesDetail.destination')}${i18n.t(
                    'staff.movingExpensesDetail.past'
                  )}`}
                </div>
                <div className='width-67'>
                  <Input
                    size='large'
                    name='arrivalPlaceRoundTrip'
                    placeholder='例：新宿駅バス停'
                    value={values.arrivalPlaceRoundTrip}
                    onChange={e => {
                      setFieldValue('arrivalPlaceRoundTrip', e.target.value)
                    }}
                  />
                  <ErrorMessage
                    name='arrivalPlaceRoundTrip'
                    component='div'
                    className='color-red'
                  />
                </div>
              </div>
            )}
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='move-expenses-item__title '>
                {i18n.t('staff.movingExpensesDetail.oneWayFare')}
              </div>
              <div className='width-67'>
                <div className='move-expenses-item__input '>
                  <InputNumber
                    placeholder='￥ 300'
                    formatter={value =>
                      `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    type='tel'
                    allowClear
                    min={0}
                    name='cost'
                    size='large'
                    style={{ width: '100%' }}
                    value={values.cost}
                    onChange={e => {
                      setFieldValue('cost', e)
                      if (
                        (values.costRoundTrip !== ''
                          ? parseInt(values.costRoundTrip)
                          : values.costRoundTrip) +
                          e >
                        5000
                      ) {
                        setFieldValue('isCost', true)
                      } else {
                        setFieldValue('isCost', false)
                      }
                    }}
                    step={5}
                  />
                </div>
                <ErrorMessage
                  name='cost'
                  component='div'
                  className='color-red'
                />
              </div>
            </div>
            {values.isPast && (
              <div className='row mt-15 move-inline move-expenses-item-container'>
                <div className='move-expenses-item__title '>
                  {`${i18n.t('staff.movingExpensesDetail.oneWayFare')}${i18n.t(
                    'staff.movingExpensesDetail.past'
                  )}`}
                </div>

                <div className='width-67'>
                  <div className='move-expenses-item__input '>
                    <InputNumber
                      placeholder='￥ 300'
                      formatter={value =>
                        `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      type='tel'
                      allowClear
                      size='large'
                      min={0}
                      name='costRoundTrip'
                      style={{ width: '100%' }}
                      value={values.costRoundTrip}
                      onChange={e => {
                        setFieldValue('costRoundTrip', e)
                        if (values.cost + e > 5000) {
                          setFieldValue('isCost', true)
                        } else {
                          setFieldValue('isCost', false)
                        }
                      }}
                      step={5}
                    />
                  </div>
                  <ErrorMessage
                    name='costRoundTrip'
                    component='div'
                    className='color-red'
                  />
                </div>
              </div>
            )}

            {isCreate && (
              <>
                <div className='row mt-15 move-inline move-expenses-item-container'>
                  <div className='move-expenses-item__title'>
                    {i18n.t('transportApplication.roundTripRegister')}
                  </div>
                  <div className='width-67'>
                    <Checkbox
                      checked={values.isPast}
                      onChange={() => {
                        setFieldValue('isPast', !values.isPast)
                      }}
                    />
                  </div>
                </div>
              </>
            )}

            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='move-expenses-item__title'>
                {i18n.t('staff.movingExpensesDetail.date')}
              </div>
              <div className='width-67 move-expenses-item__input '>
                <DatePicker
                  style={{ width: '100%' }}
                  format='YYYY年MM月DD日'
                  allowClear={false}
                  size='large'
                  locale={locale}
                  disabledDate={disabledDate}
                  value={moment.utc(
                    new Date(values.startTime),
                    'YYYY年MM月DD日'
                  )}
                  onChange={value => {
                    setFieldValue(
                      'startTime',
                      moment
                        .utc(value)
                        .startOf('day')
                        .toISOString()
                    )
                  }}
                />
                <ErrorMessage
                  name='startTime'
                  component='div'
                  className='color-red'
                />
              </div>
            </div>
            <div className='row move-center mt-30'>
              <Button
                label={
                  isCreate
                    ? i18n.t('transportApplication.submitStaff')
                    : i18n.t('staff.movingExpensesDetail.btnUpdate')
                }
                className='reflect-button'
                type='submit'
                onClick={() => {
                  validateForm().then(response => {
                    if (!Object.keys(response).length) {
                      setFieldValue('confirm', true)
                    } else {
                      setTouched(response)
                    }
                  })
                }}
              />
            </div>
            <div className='confirmation-moving'>
              <ConfirmationModal
                {...formikProps}
                confirmationMessage={confirmationMessage}
              />
            </div>
          </div>
        )
      }}
    />
  )
}

export default MovingExpensesRegistrationDetailScence
