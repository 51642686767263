import { DOCTOR_FRAGMENTS } from '@medical/layout/DoctorLayout/DoctorLayoutRequest.graphql'
import {
  DEPARTMENTS_FRAGMENT,
  GENDERS_FRAGMENT,
  JOIN_BACKGROUNDS_FRAGMENT,
  WORK_PATTERNS_FRAGMENT,
} from '@medical/libs/general.graphql'
import gql from 'graphql-tag'

export const DOCTOR_CREATE_QUERY = gql`
  query {
    ...GENDERS_FRAGMENT
    ...DEPARTMENTS_FRAGMENT
    ...WORK_PATTERNS_FRAGMENT
    ...JOIN_BACKGROUNDS_FRAGMENT
  }
  ${GENDERS_FRAGMENT}
  ${DEPARTMENTS_FRAGMENT}
  ${WORK_PATTERNS_FRAGMENT}
  ${JOIN_BACKGROUNDS_FRAGMENT}
`

export const STAFF_CREATE_DOCTOR = gql`
  mutation STAFF_CREATE_DOCTOR(
    $lastname: String!
    $lastnameKana: String!
    $firstname: String!
    $firstnameKana: String!
    $birthday: DateTime
    $gender: Gender
    $email: String!
    $medicalLicenseNumber: String
    $phoneNumber: String
    $stateOrRegion: String
    $address1: String
    $address2: String
    $zipCode: String
    $nearestStations: [String!]
    $placeOfWork: String
    $evaluationComment: String
    $workPattern: WorkPattern
    $joinBackground: JoinBackground
    $joinBackgroundOther: String
    $departments: [DepartmentName!]!
    $graduationUniversity: String
    $graduationUniversityYear: Int
    $obtainDoctorLicenseYear: Int
    $professionalDescription: String
    $reasonKnowWebsites: [ReasonKnowWebsite!]
    $otherReasonKnowWebsite: String
    $isJoinedSublingualImmunotherapyELearning: Boolean
    $isJoinedOnlineDiagnosticTraining: Boolean
    $introducedBy: String
    $careerHistories: [CareerHistoryForCreate!]
    $isAvailableOnMonday: Boolean
    $isAvailableOnTuesday: Boolean
    $isAvailableOnWednesday: Boolean
    $isAvailableOnThursday: Boolean
    $isAvailableOnFriday: Boolean
    $isAvailableOnSaturday: Boolean
    $isAvailableOnSunday: Boolean
    $isAvailableOnHoliday: Boolean
    $adjustHourlyWageRange: Int
    $medicalRecord: String
    $orca: String
    $skillSet: [ID!]
    $isViaReferral: Boolean
    $questionnaire: String
    $staffMemo: String
    $province: String
    $isCreateAddressHistory: Boolean
    $isCreateTransportationCost: Boolean
  ) {
    staffCreateDoctor(
      lastname: $lastname
      lastnameKana: $lastnameKana
      firstname: $firstname
      firstnameKana: $firstnameKana
      birthday: $birthday
      gender: $gender
      email: $email
      medicalLicenseNumber: $medicalLicenseNumber
      phoneNumber: $phoneNumber
      stateOrRegion: $stateOrRegion
      address1: $address1
      address2: $address2
      zipCode: $zipCode
      nearestStations: $nearestStations
      placeOfWork: $placeOfWork
      evaluationComment: $evaluationComment
      workPattern: $workPattern
      joinBackground: $joinBackground
      joinBackgroundOther: $joinBackgroundOther
      departments: $departments
      graduationUniversity: $graduationUniversity
      graduationUniversityYear: $graduationUniversityYear
      obtainDoctorLicenseYear: $obtainDoctorLicenseYear
      professionalDescription: $professionalDescription
      reasonKnowWebsites: $reasonKnowWebsites
      otherReasonKnowWebsite: $otherReasonKnowWebsite
      isJoinedOnlineDiagnosticTraining: $isJoinedOnlineDiagnosticTraining
      isJoinedSublingualImmunotherapyELearning: $isJoinedSublingualImmunotherapyELearning
      introducedBy: $introducedBy
      careerHistories: $careerHistories
      isAvailableOnMonday: $isAvailableOnMonday
      isAvailableOnTuesday: $isAvailableOnTuesday
      isAvailableOnWednesday: $isAvailableOnWednesday
      isAvailableOnThursday: $isAvailableOnThursday
      isAvailableOnFriday: $isAvailableOnFriday
      isAvailableOnSaturday: $isAvailableOnSaturday
      isAvailableOnSunday: $isAvailableOnSunday
      isAvailableOnHoliday: $isAvailableOnHoliday
      adjustHourlyWageRange: $adjustHourlyWageRange
      medicalRecord: $medicalRecord
      orca: $orca
      skillSet: $skillSet
      isViaReferral: $isViaReferral
      questionnaire: $questionnaire
      staffMemo: $staffMemo
      province: $province
      isCreateAddressHistory: $isCreateAddressHistory
      isCreateTransportationCost: $isCreateTransportationCost
    ) {
      ...DOCTOR_FRAGMENTS
    }
  }
  ${DOCTOR_FRAGMENTS}
`
