import './style.css'

import ConfirmationModal from '@medical/components/ConfirmationModal'
import CustomInputTextarea from '@medical/components/InputTextarea'
import TextInput from '@medical/components/TextInput'
import { TimePicker } from 'antd'
import { ErrorMessage, Formik } from 'formik'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { Spinner } from 'primereact/spinner'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

const TreatmentResult = ({
  submitLabel,
  confirmationMessage,
  onSubmit,
  clinic = {},
}) => {
  const { i18n } = useTranslation()
  const generateAge = () => {
    const rs = []
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i <= 100; i += 10) {
      rs.push({
        label: `${i}`,
        value: `${i}`,
      })
    }
    return rs
  }
  const validateSchema = Yup.object().shape({
    maleAgeGroup: Yup.mixed().test(
      'maleAgeGroup',
      '患者年齢層を正しく入力してください',
      val => {
        if (!val[0].value || !val[1].value) return false
        if ((!val[0].value && val[1].value) || (val[0].value && !val[1].value))
          return false
        if (+val[0].value >= +val[1].value) return false
        return true
      }
    ),
    femaleAgeGroup: Yup.mixed().test(
      'femaleAgeGroup',
      '患者年齢層を正しく入力してください',
      val => {
        if (!val[0].value && !val[1].value) return false
        if ((!val[0].value && val[1].value) || (val[0].value && !val[1].value))
          return false
        if (+val[0].value >= +val[1].value) return false
        return true
      }
    ),
  })

  return (
    <div>
      <Formik
        initialValues={{
          confirm: false,
          numberPatient: clinic.numberPatient,
          maleAgeGroup: [
            {
              label:
                clinic.maleAgeFrom || clinic.maleAgeFrom === 0
                  ? `${clinic.maleAgeFrom}`
                  : null,
              value:
                clinic.maleAgeFrom || clinic.maleAgeFrom === 0
                  ? `${clinic.maleAgeFrom}`
                  : null,
            },
            {
              label: clinic.maleAgeTo ? `${clinic.maleAgeTo}` : null,
              value: clinic.maleAgeTo ? `${clinic.maleAgeTo}` : null,
            },
          ],
          femaleAgeGroup: [
            {
              label:
                clinic.femaleAgeFrom || clinic.femaleAgeFrom === 0
                  ? `${clinic.femaleAgeFrom}`
                  : null,
              value:
                clinic.femaleAgeFrom || clinic.femaleAgeFrom === 0
                  ? `${clinic.femaleAgeFrom}`
                  : null,
            },
            {
              label: clinic.femaleAgeTo ? `${clinic.femaleAgeTo}` : null,
              value: clinic.femaleAgeTo ? `${clinic.femaleAgeTo}` : null,
            },
          ],
          kindOfDisease: clinic.kindOfDisease,
          vaccine: clinic.vaccine,
          referHospital: clinic.referHospital,
        }}
        onSubmit={onSubmit}
        validationSchema={validateSchema}
        render={formikProps => {
          const {
            setFieldValue,
            setTouched,
            validateForm,
            handleChange,
            values,
            errors,
          } = formikProps
          return (
            <div className='treatment-result-form'>
              <div className='tr'>
                <div className='th' style={{ width: '20%' }}>
                  {i18n.t('staff.treatmentResult.numberOfPatients')}
                </div>
                <div className='td'>
                  <Spinner
                    style={{ width: '100%' }}
                    value={values.numberPatient}
                    name='numberPatient'
                    min={0}
                    max={100000000}
                    step={1}
                    formatInput
                    className='split-autocomplete'
                    onChange={e => {
                      handleChange(e)
                    }}
                  />
                </div>
              </div>
              <div className='tr'>
                <div
                  className='th '
                  style={{ width: '20%', fontWeight: 'bold' }}
                >
                  {i18n.t('staff.treatmentResult.ageGroup')}
                </div>
                <div className='td'>
                  <div className='row' style={{ alignItems: 'center' }}>
                    <label className='checkbox-label'>
                      {i18n.t('staff.treatmentResult.male')}
                    </label>
                    <div className='dropdown-custom'>
                      <Dropdown
                        {...formikProps}
                        style={{ minWidth: '100px' }}
                        name='maleAgeGroup'
                        value={values.maleAgeGroup[0]}
                        optionLabel='value'
                        options={generateAge()}
                        onChange={e => {
                          setFieldValue('maleAgeGroup', [
                            e.value,
                            values.maleAgeGroup[1],
                          ])
                        }}
                      />
                      <span> 代 </span>
                      <span> ～ </span>
                      <Dropdown
                        {...formikProps}
                        style={{ minWidth: '100px' }}
                        value={values.maleAgeGroup[1]}
                        optionLabel='value'
                        name='maleAgeGroup'
                        options={generateAge()}
                        onChange={e => {
                          setFieldValue('maleAgeGroup', [
                            values.maleAgeGroup[0],
                            e.value,
                          ])
                        }}
                      />
                      <span style={{ marginRight: '15px' }}> 代 </span>
                    </div>
                  </div>
                  <ErrorMessage
                    style={{ marginTop: '10px' }}
                    name='maleAgeGroup'
                    component='div'
                    className='color-red'
                  />
                  <div
                    className='row'
                    style={{ alignItems: 'center', marginTop: '15px' }}
                  >
                    <label className='checkbox-label'>
                      {i18n.t('staff.treatmentResult.female')}
                    </label>
                    <div className='dropdown-custom'>
                      <Dropdown
                        {...formikProps}
                        style={{ minWidth: '100px' }}
                        name={`femaleAgeGroup[${0}]`}
                        value={values.femaleAgeGroup[0]}
                        optionLabel='value'
                        options={generateAge()}
                        onChange={e => {
                          setFieldValue('femaleAgeGroup', [
                            e.value,
                            values.femaleAgeGroup[1],
                          ])
                        }}
                      />
                      <span> 代 </span>
                      <span> ～ </span>
                      <Dropdown
                        {...formikProps}
                        style={{ minWidth: '100px' }}
                        name={`femaleAgeGroup[${1}]`}
                        value={values.femaleAgeGroup[1]}
                        optionLabel='value'
                        options={generateAge()}
                        onChange={e => {
                          setFieldValue('femaleAgeGroup', [
                            values.femaleAgeGroup[0],
                            e.value,
                          ])
                        }}
                      />
                      <span style={{ marginRight: '15px' }}> 代 </span>
                    </div>
                  </div>
                  <ErrorMessage
                    style={{ marginTop: '10px' }}
                    name='femaleAgeGroup'
                    component='div'
                    className='color-red'
                  />
                </div>
              </div>

              <CustomInputTextarea
                {...formikProps}
                width='20%'
                classDisabled
                autoResize
                label={i18n.t('staff.treatmentResult.type')}
                name='kindOfDisease'
              />
              <CustomInputTextarea
                {...formikProps}
                width='20%'
                autoResize
                classDisabled
                name='vaccine'
                label={i18n.t('staff.treatmentResult.vaccine')}
              />
              <CustomInputTextarea
                {...formikProps}
                width='20%'
                name='referHospital'
                classDisabled
                label={i18n.t('staff.treatmentResult.referClinic')}
                autoResize
              />

              <div className='modal-bottom'>
                <Button
                  label={submitLabel}
                  className='reflect-button'
                  onClick={() => {
                    validateForm().then(response => {
                      if (!Object.keys(response).length) {
                        setFieldValue('confirm', true)
                      } else {
                        setTouched(response)
                      }
                    })
                  }}
                />
              </div>
              <ConfirmationModal
                {...formikProps}
                confirmationMessage={confirmationMessage}
              />
            </div>
          )
        }}
      />
    </div>
  )
}

export default TreatmentResult
