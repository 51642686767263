import gql from 'graphql-tag'

export const STAFF_CREATE_RECRUITMENT_CATEGORY = gql`
  mutation STAFF_CREATE_RECRUITMENT_CATEGORY(
    $scheduleAvailableShiftGroupId: ID
    $availableShiftId: ID
    $category: RecruitmentCategoryType!
    $status: RecruitmentStatusType!
    $description: String
  ) {
    staffCreateRecruitmentCategory(
      scheduleAvailableShiftGroupId: $scheduleAvailableShiftGroupId
      availableShiftId: $availableShiftId
      category: $category
      status: $status
      description: $description
    ) {
      id
      status
      category
      description
      deletedAt
      staff {
        id
        firstname
        lastname
      }
    }
  }
`

export const STAFF_UPSERT_RECRUITMENT_CATEGORY = gql`
  mutation STAFF_UPSERT_RECRUITMENT_CATEGORY(
    $recruitmentCategoryId: ID!
    $category: RecruitmentCategoryType!
    $status: RecruitmentStatusType!
    $action: String!
    $description: String
  ) {
    staffUpsertRecruitmentCategory(
      recruitmentCategoryId: $recruitmentCategoryId
      category: $category
      status: $status
      action: $action
      description: $description
    ) {
      id
      status
      category
      description
      deletedAt
      staff {
        id
        firstname
        lastname
      }
    }
  }
`
export const GET_ENUM_COMMENT = gql`
  query Query($name: String!) {
    enumValues(name: $name) {
      description
      value
    }
  }
`
