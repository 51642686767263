import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { onPageChange } from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import queryString from 'query-string'
import React from 'react'

import { DELETE_DOCUMENT, DOCUMENTS_CONNECTION } from './DocumentsList.graphql'
import DocumentsListScene from './DocumentsListScene'

const DocumentsList = ({ history, location: { search } }) => {
  const [{ i18n, popup }] = useCustom()
  const {
    page = 1,
    rowsPerPage = 10,
    orderBy = 'createdAt_DESC',
  } = queryString.parse(search)
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  const variables = {
    first: first < 0 ? 10 : first,
    skip: skip < 0 ? 0 : skip,
    orderBy,
  }

  const { loading, error, data } = useQuery(DOCUMENTS_CONNECTION, {
    variables,
    fetchPolicy: 'network-only',
  })
  const [deleteDocument] = useMutation(DELETE_DOCUMENT)

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const {
    documentsConnection: {
      edges,
      aggregate: { count },
    },
  } = data

  const onSubmit = async ({ documentId }, { setSubmitting, resetForm }) => {
    try {
      await deleteDocument({
        variables: {
          documentId,
        },
        update: store => {
          const documentsData = store.readQuery({
            query: DOCUMENTS_CONNECTION,
            variables,
          })
          documentsData.documentsConnection.edges = documentsData.documentsConnection.edges.filter(
            edge => edge.node.id !== documentId
          )
          documentsData.documentsConnection.aggregate.count -= 1
          store.writeQuery({
            query: DOCUMENTS_CONNECTION,
            data: documentsData,
            variables,
          })
        },
      })
      popup.success(i18n.t('staff.deleteDocument.submissionSuccess'))
      setSubmitting(false)
      resetForm()
    } catch (error) {
      popup.error(i18n.t('staff.deleteDocument.submissionError'))
      setSubmitting(false)
    }
  }

  return (
    <DocumentsListScene
      i18n={i18n}
      edges={edges}
      rowsPerPage={rowsPerPage}
      page={page}
      count={count}
      onPageChange={onPageChange(history, search)}
      onSubmit={onSubmit}
    />
  )
}

export default DocumentsList
