/* eslint-disable camelcase */
import gql from 'graphql-tag'

export const GET_ALL_TYPE = gql`
  query GET_ALL_TYPE {
    availableShifts {
      type
    }
  }
`

export const GET_TYPE_BY_ID = gql`
query Query($where: AvailableShiftWhereUniqueInput!) {
  availableShift(where: $where) {
    type
  }
}
`
export const UPDATE_TYPE_BY_ID = gql`
mutation Mutation($where: AvailableShiftWhereUniqueInput!, $data: AvailableShiftUpdateInput!) {
  updateAvailableShift(where: $where, data: $data) {
    id
    type
  }
}
`
