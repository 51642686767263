/* eslint-disable no-nested-ternary */
import ConfirmationModal from '@medical/components/ConfirmationModal'
import { Input } from 'antd'
import { ErrorMessage, Formik } from 'formik'
import { Button } from 'primereact/button'
import React, { useState } from 'react'
import * as Yup from 'yup'

const RegionForm = ({
  onSubmit,
  i18n,
  listRegion = [],
  isCreatePermitted,
  isDeletePermitted,
  isUpdatePermitted,
}) => {
  const [editRegion, setEditRegion] = useState(false)
  const validationSchema = Yup.object().shape({
    name: Yup.string().when(['isUpdate', 'isDelete'], {
      is: (isUpdate, isDelete) => !isUpdate && !isDelete,
      then: Yup.string().required(`エリア${i18n.t('validation.required')}`),
    }),
    position: Yup.string().when(['isUpdate', 'isDelete'], {
      is: (isUpdate, isDelete) => !isUpdate && !isDelete,
      then: Yup.string()
        .matches(/^[0-9]+$/, i18n.t('validation.medicalLicenseNumber.type'))
        .required(`並び順${i18n.t('validation.required')}`),
    }),
  })
  return (
    <React.Fragment>
      <div className='staff-header'>
        <div className='staff-title' style={{ marginTop: '50px' }}>
          {i18n.t('staff.regions.regionTitle')}
        </div>
      </div>
      <Formik
        enableReinitialize
        initialValues={{
          listRegion,
          name: '',
          position: '',
          nameUpdate: '',
          positionUpdate: '',
          confirmationMessage: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        render={formikProps => {
          const {
            handleSubmit,
            setFieldValue,
            setTouched,
            values,
            validateForm,
          } = formikProps
          return (
            <div>
              <div>
                {values.listRegion && values.listRegion.length > 0
                  ? values.listRegion.map((as, index) => (
                      <div className='tr'>
                        <div className='td' style={{ width: '60%' }}>
                          <Input
                            className='category-form-td input-category'
                            value={values.listRegion[index].name}
                            name={`listRegion[${index}].name`}
                            disabled={values.listRegion[index].edit}
                            onChange={e => {
                              setFieldValue(
                                `listRegion[${index}].name`,
                                e.target.value
                              )
                            }}
                          />
                        </div>
                        <div className='td' style={{ width: '20%' }}>
                          <Input
                            className='category-form-td input-category'
                            type='number'
                            min={0}
                            value={values.listRegion[index].position}
                            name={`listRegion[${index}].position`}
                            disabled={values.listRegion[index].edit}
                            onChange={e => {
                              if (e.target.value >= 0) {
                                setFieldValue(
                                  `listRegion[${index}].position`,
                                  e.target.value
                                )
                              }
                            }}
                          />
                        </div>
                        <div className='td' style={{ width: '10%' }}>
                          {isUpdatePermitted &&
                          values.listRegion[index].edit ? (
                            <Button
                              style={{ marginLeft: '10px' }}
                              icon='pi pi-pencil'
                              onClick={() => {
                                setEditRegion(!editRegion)
                                values.listRegion[index].edit = false
                              }}
                            />
                          ) : isUpdatePermitted ? (
                            <Button
                              style={{ marginLeft: '10px' }}
                              icon='pi pi-save'
                              disabled={
                                !values.listRegion[index].name ||
                                !values.listRegion[index].position
                              }
                              onClick={() => {
                                setFieldValue(
                                  'confirmationMessage',
                                  i18n.t(
                                    'staff.regions.submitUpdateRegionfirmation'
                                  )
                                )
                                setFieldValue('id', values.listRegion[index].id)
                                setFieldValue('isUpdate', true)
                                setFieldValue(
                                  'nameUpdate',
                                  values.listRegion[index].name
                                )
                                setFieldValue(
                                  'positionUpdate',
                                  values.listRegion[index].position
                                )
                                setFieldValue('confirm', true)
                              }}
                            />
                          ) : null}

                          {isDeletePermitted ? (
                            <Button
                              style={{ marginLeft: '10px' }}
                              icon='pi pi-trash'
                              disabled={!values.listRegion[index].edit}
                              onClick={() => {
                                setFieldValue(
                                  'confirmationMessage',
                                  i18n.t(
                                    'staff.regions.submitDeleteRegionConfirmation'
                                  )
                                )
                                setFieldValue('isDelete', true)
                                setFieldValue('id', values.listRegion[index].id)
                                setFieldValue(
                                  'nameUpdate',
                                  values.listRegion[index].name
                                )
                                setFieldValue(
                                  'positionUpdate',
                                  values.listRegion[index].position
                                )
                                setFieldValue('confirm', true)
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                    ))
                  : null}

                {isCreatePermitted ? (
                  <div>
                    <div className='tr'>
                      <div className='th' style={{ width: '150px' }}>
                        エリア
                      </div>
                      <div className='td'>
                        <Input
                          value={values.name}
                          style={{ maxWidth: '300px' }}
                          className='category-form-td input-category'
                          placeholder='エリア'
                          onChange={e => {
                            setFieldValue('name', e.target.value)
                          }}
                        />
                      </div>
                    </div>
                    <ErrorMessage
                      name='name'
                      component='div'
                      className='signup-alert'
                    />
                    <div className='tr'>
                      <div className='th' style={{ width: '150px' }}>
                        並び順
                      </div>
                      <div className='td'>
                        <Input
                          value={values.position}
                          type='number'
                          min={0}
                          style={{ maxWidth: '300px' }}
                          className='category-form-td input-category'
                          placeholder='並び順'
                          onChange={e => {
                            if (e.target.value >= 0) {
                              setFieldValue('position', e.target.value)
                            }
                          }}
                        />
                      </div>
                    </div>
                    <ErrorMessage
                      name='position'
                      component='div'
                      className='signup-alert'
                    />
                  </div>
                ) : null}
                {isCreatePermitted ? (
                  <div style={{ marginTop: '40px', textAlign: 'center' }}>
                    <Button
                      label={i18n.t('staff.regions.createRegion')}
                      icon='pi pi-plus'
                      type='button'
                      onClick={handleSubmit}
                    />
                  </div>
                ) : null}
              </div>
              <ConfirmationModal
                {...formikProps}
                confirmationMessage={values.confirmationMessage}
              />
            </div>
          )
        }}
      />
    </React.Fragment>
  )
}
export default RegionForm
