import { SHIFT_FORM_STEP } from '@medical/constant'
import moment from 'moment'

export const getAvailableTime = ({ currentTime }) => {
  const startTime = moment(currentTime)
  const maxAvailableTime = moment(currentTime).endOf('day')
  return moment(currentTime).isSameOrAfter(maxAvailableTime, 'minute')
    ? maxAvailableTime.clone().toDate()
    : startTime
        .add(SHIFT_FORM_STEP, 'minute')
        .clone()
        .toDate()
}
