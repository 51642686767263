import { Editor as PrimeEditor } from 'primereact/editor'
import React from 'react'

const header = (
  <React.Fragment>
    <span className='ql-formats'>
      <select className='ql-header' defaultValue='0'>
        <option value='1'>Heading</option>
        <option value='2'>Subheading</option>
        <option value='0'>Normal</option>
      </select>
      <select className='ql-font'>
        <option />
        <option value='serif' />
        <option value='monospace' />
      </select>
    </span>
    <span className='ql-formats'>
      <button className='ql-bold' aria-label='Bold' type='button' />
      <button className='ql-italic' aria-label='Italic' type='button' />
      <button className='ql-underline' aria-label='Underline' type='button' />
    </span>
    <span className='ql-formats'>
      <select className='ql-color' />
      <select className='ql-background' />
    </span>
    <span className='ql-formats'>
      <button
        className='ql-list'
        value='ordered'
        aria-label='Ordered List'
        type='button'
      />
      <button
        className='ql-list'
        value='bullet'
        aria-label='Unordered List'
        type='button'
      />
      <select className='ql-align'>
        <option defaultValue />
        <option value='center' />
        <option value='right' />
        <option value='justify' />
      </select>
    </span>
    <span className='ql-formats'>
      <button className='ql-link' aria-label='Insert Link' type='button' />
      {/* <button className="ql-image" aria-label="Insert Image" type="button" />
          <button
            className="ql-code-block"
            aria-label="Insert Code Block"
            type="button"
          /> */}
    </span>
    {/* <span className="ql-formats">
          <button className="ql-clean" aria-label="Remove Styles" type="button" />
        </span> */}
  </React.Fragment>
)
const headerQuestion = (
  <React.Fragment>
    <span className='ql-formats'>
      <select className='ql-header' defaultValue='0'>
        <option value='1'>Heading</option>
        <option value='2'>Subheading</option>
        <option value='0'>Normal</option>
      </select>
      <select className='ql-font'>
        <option />
        <option value='serif' />
        <option value='monospace' />
      </select>
    </span>
    <span className='ql-formats'>
      <button className='ql-bold' aria-label='Bold' type='button' />
      <button className='ql-italic' aria-label='Italic' type='button' />
      <button className='ql-underline' aria-label='Underline' type='button' />
    </span>
    <span className='ql-formats'>
      <select className='ql-color' />
      <select className='ql-background' />
    </span>
    <span className='ql-formats'>
      <button
        className='ql-list'
        value='ordered'
        aria-label='Ordered List'
        type='button'
      />
      <button
        className='ql-list'
        value='bullet'
        aria-label='Unordered List'
        type='button'
      />
      <select className='ql-align'>
        <option defaultValue />
        <option value='center' />
        <option value='right' />
        <option value='justify' />
      </select>
    </span>
    <span className='ql-formats'>
      <button className='ql-link' aria-label='Insert Link' type='button' />
      {/* <button className='ql-image' aria-label='Insert Image' type='button' /> */}
      
      {/* <button
          className="ql-code-block"
          aria-label="Insert Code Block"
          type="button"
      /> */}
    </span>
    {/* <span className="ql-formats">
          <button className="ql-clean" aria-label="Remove Styles" type="button" />
        </span> */}
  </React.Fragment>
)

const getHeader = headerEditor => {
  if (headerEditor === 'question' || headerEditor === 'portal') {
    return headerQuestion
  }
  return header
}
const Editor = ({
  style,
  value,
  onTextChange,
  headerEditor,
  readOnly = false,
  className,
}) => (
  <PrimeEditor
    className={className}
    style={style}
    value={value}
    onTextChange={onTextChange}
    headerTemplate={getHeader(headerEditor)}
    readOnly={readOnly}
  />
)

export default Editor
