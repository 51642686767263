import gql from 'graphql-tag'

export const STAFF_GET_GASOLINE_PRICE = gql`
  query GasolinePrices(
    $orderBy: GasolinePriceOrderByInput
    $where: GasolinePriceWhereInput
  ) {
    gasolinePrices(orderBy: $orderBy, where: $where) {
      id
      startTime
      price
      maxPrice
      createdAt
      modifiedBy {
        id
        firstnameKana
        lastnameKana
      }
    }
  }
`

export const STAFF_CREATE_GASOLINE_PRICE = gql`
  mutation Mutation($price: Int, $startTime: DateTime, $maxPrice: Int) {
    staffCreateGasolinePrice(
      price: $price
      startTime: $startTime
      maxPrice: $maxPrice
    ) {
      id
    }
  }
`
