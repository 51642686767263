import BreadCrumb from '@medical/components/Breadcrumb'
import ModalLink from '@medical/components/ModalLink'
import Router from '@medical/routes/router'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { Paginator } from 'primereact/paginator'
import queryString from 'query-string'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const ScheduleCancelScene = ({
  data,
  i18n,
  isUpdateScheduleCancel,
  page,
  fullname,
  statusValue,
  rowsPerPage,
  onPageChange,
  count,
}) => {
  const listFilter = data.workScheduleCancellationLettersConnection.edges || []

  const GET_OPTIONS = {
    WAITING_FOR_APPROVAL: '',
    REJECTED: '',
    ACCEPTED: '',
  }
  const getOptions = () => {
    const result = []
    Object.keys(GET_OPTIONS).forEach(key => {
      result.push({
        value: key,
        description: i18n.t(`main.workScheduleCancel.${key}`),
      })
    })
    return result
  }

  const getTypeTd = statusValue => {
    if (statusValue === 'WAITING_FOR_APPROVAL') {
      return 'table-workSchedule-cancel-td-blue'
    }
    if (statusValue === 'REJECTED') {
      return 'table-workSchedule-cancel-td-red'
    }
    return ''
  }

  const formatDate = date => {
    date = moment(date).format()
    if (!date || date.length < 10) {
      return ''
    }
    return (
      parseInt(date.substring(0, 4)) +
      '年' +
      parseInt(date.substring(5, 7)) +
      '月' +
      parseInt(date.substring(8, 10)) +
      '日'
    )
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        fullname,
        statusValue,
      }}
      onSubmit={{}}
      render={formikProps => {
        const { values, handleChange, handleBlur } = formikProps
        return (
          <div
            style={{
              padding: '10px',
              height: '100%',
            }}
          >
            <div className='staff-header'>
              <div className='staff-title'>
                {i18n.t('main.workScheduleCancel.title')}
                <BreadCrumb
                  items={[{ title: i18n.t('main.workScheduleCancel.title') }]}
                />
              </div>
            </div>
            <div
              className='box'
              style={{
                backgroundColor: '#ffffff',
                marginLeft: '20px',
                marginRight: '20px',
                padding: '20px',
                borderRadius: '10px',
              }}
            >
              <div className='search-parent'>
                <InputText
                  value={values.fullname}
                  name='fullname'
                  placeholder={i18n.t('main.fullname')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={{ width: '170px', marginRight: '20px' }}
                />
                <Dropdown
                  value={values.statusValue}
                  name='statusValue'
                  optionLabel='description'
                  valueLabel='value'
                  options={getOptions()}
                  placeholder={i18n.t('main.workScheduleCancel.status')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={{ width: '50px', marginRight: '20px' }}
                />
                <Link
                  to={{
                    pathname: Router.staffCancelShifts,
                    search: queryString.stringify({
                      rowsPerPage,
                      fullname: values.fullname,
                      statusValue: values.statusValue
                        ? values.statusValue.value
                        : null,
                    }),
                  }}
                >
                  <Button label={i18n.t('main.search')} icon='pi pi-search' />
                </Link>
                {fullname || statusValue ? (
                  <Link
                    to={{
                      pathname: Router.staffCancelShifts,
                    }}
                  >
                    <Button
                      icon='pi pi-times'
                      className='p-button-secondary'
                      style={{ marginLeft: '10px' }}
                    />
                  </Link>
                ) : null}
              </div>
              <div>
                <div>
                  <table className='table-workSchedule-cancel'>
                    <thead>
                      <tr className='table-workSchedule-cancel-th'>
                        <th width='10%'>
                          {i18n.t('main.workScheduleCancel.colDate')}
                        </th>
                        <th width='15%'>
                          {i18n.t('main.workScheduleCancel.name')}
                        </th>
                        <th width='10%'>
                          {i18n.t('main.workScheduleCancel.dateTarget')}
                        </th>
                        <th width='10%'>
                          {i18n.t('main.workScheduleCancel.status')}
                        </th>
                        <th width='10%'>
                          {i18n.t('main.workScheduleCancel.createAt')}
                        </th>
                        <th width='10%'>
                          {i18n.t('main.workScheduleCancel.staffCreate')}
                        </th>
                        <th width='10%'>
                          {i18n.t('main.workScheduleCancel.confirmAt')}
                        </th>
                        <th width='15%'>
                          {i18n.t('main.workScheduleCancel.confirmStaff')}
                        </th>
                        {isUpdateScheduleCancel ? (
                          <th width='10%'>
                            {i18n.t('main.workScheduleCancel.detail')}
                          </th>
                        ) : null}
                      </tr>
                    </thead>
                    <tbody>
                      {listFilter.length > 0 ? (
                        listFilter.map((as, index) =>
                          index % 2 === 0 ? (
                            <tr className='table-workSchedule-cancel-td-even'>
                              <td>{formatDate(as.node.updatedAt)}</td>
                              <td>
                                {`${as.node.workSchedule.doctor.lastname} ${as.node.workSchedule.doctor.firstname}`}
                              </td>
                              <td>
                                {formatDate(as.node.workSchedule.startTime)}
                              </td>
                              <td className={getTypeTd(as.node.status)}>
                                {i18n.t(
                                  `main.workScheduleCancel.${as.node.status}`
                                )}
                              </td>
                              <td>
                                {as.node.workSchedule.isCreatedByDoctor
                                  ? formatDate(
                                      as.node.workSchedule.acceptedShift
                                        .createdAt
                                    )
                                  : null}
                              </td>
                              <td>
                                {as.node.workSchedule.isCreatedByDoctor
                                  ? `${as.node.workSchedule.acceptedShift.createdBy.lastname} ${as.node.workSchedule.acceptedShift.createdBy.firstname}`
                                  : null}
                              </td>
                              <td>
                                {formatDate(as.node.workSchedule.createdAt)}
                              </td>
                              <td>
                                {`${as.node.workSchedule.acceptedBy.lastname} ${as.node.workSchedule.acceptedBy.firstname}`}
                              </td>
                              {isUpdateScheduleCancel ? (
                                <td>
                                  <ModalLink
                                    to={Router.get(
                                      Router.staffCancelShiftsDetail,
                                      { cancelShiftId: as.node.id }
                                    )}
                                  >
                                    <Button icon='pi pi-pencil' />
                                  </ModalLink>
                                </td>
                              ) : null}
                            </tr>
                          ) : (
                            <tr className='table-workSchedule-cancel-td-odd'>
                              <td>{formatDate(as.node.updatedAt)}</td>
                              <td>
                                {`${as.node.workSchedule.doctor.lastname} ${as.node.workSchedule.doctor.firstname}`}
                              </td>
                              <td>
                                {formatDate(as.node.workSchedule.startTime)}
                              </td>
                              <td className={getTypeTd(as.node.status)}>
                                {i18n.t(
                                  `main.workScheduleCancel.${as.node.status}`
                                )}
                              </td>
                              <td>
                                {as.node.workSchedule.isCreatedByDoctor
                                  ? formatDate(
                                      as.node.workSchedule.acceptedShift
                                        .createdAt
                                    )
                                  : null}
                              </td>
                              <td>
                                {as.node.workSchedule.isCreatedByDoctor
                                  ? `${as.node.workSchedule.acceptedShift.createdBy.lastname} ${as.node.workSchedule.acceptedShift.createdBy.firstname}`
                                  : null}
                              </td>
                              <td>
                                {formatDate(as.node.workSchedule.createdAt)}
                              </td>
                              <td>
                                {`${as.node.workSchedule.acceptedBy.lastname} ${as.node.workSchedule.acceptedBy.firstname}`}
                              </td>
                              {isUpdateScheduleCancel ? (
                                <td>
                                  <ModalLink
                                    to={Router.get(
                                      Router.staffCancelShiftsDetail,
                                      { cancelShiftId: as.node.id }
                                    )}
                                  >
                                    <Button icon='pi pi-pencil' />
                                  </ModalLink>
                                </td>
                              ) : null}
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td colSpan={9}>
                            {i18n.t('main.workScheduleCancel.message')}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <Paginator
                    rows={rowsPerPage}
                    totalRecords={count}
                    first={(page - 1) * rowsPerPage}
                    onPageChange={onPageChange}
                    leftContent={
                      <div className='paginator-total'>
                        {i18n.t('main.paginationTotal')}: {count}
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}
export default ScheduleCancelScene
