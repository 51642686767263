import { DOCTOR_EMAIL, STAFF_EMAIL } from '@medical/constant'
import { cloudWatchLogger } from '@medical/libs'
import { NETWORK_ERROR } from '@medical/libs/logEvents'
import middleware from '@medical/middleware'
import moment from 'moment'
import { Button } from 'primereact/button'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

export const ErrorComponent = ({
  history = {},
  error: { networkError, graphQLErrors } = {},
}) => {
  const { i18n } = useTranslation()
  if (networkError)
    return (
      <div className='static-container'>
        <div className='static-title'>501</div>
        <div className='static-content'>{i18n.t('main.networkError')}</div>
        <Button
          onClick={() => history.go(0)}
          className='static-button'
          label={i18n.t('main.refresh')}
          icon='pi pi-refresh'
        />
      </div>
    )
  if (graphQLErrors)
    return (
      <React.Fragment>
        {graphQLErrors.map(({ message }, i) => (
          <div className='static-container' key={i}>
            <div className='static-title'>{i18n.t('main.ERROR')}</div>
            <div className='static-content'>{message}</div>
            <Button
              label={i18n.t('main.pageNotFoundButton')}
              className='static-button'
              onClick={history.goBack}
            />
          </div>
        ))}
      </React.Fragment>
    )

  return <div>Error....</div>
}

export default withRouter(ErrorComponent)
