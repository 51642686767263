import './styles.css'

import ConfirmationModal from '@medical/components/ConfirmationModal'
import { Checkbox, DatePicker, Space, TimePicker } from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import { FieldArray, Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import React from 'react'

const digDeeper = (errors, message = []) => {
  if (!errors) return
  Object.keys(errors).forEach(key => {
    if (typeof errors[key] === 'string') {
      message.push(<div className='alert'>{errors[key]}</div>)
    } else {
      digDeeper(errors[key], message)
    }
  })
}

const { RangePicker } = DatePicker
const PeriodicOpenTab = ({
  i18n,
  onSubmit,
  confirmationMessage,
  clinicalSchedules,
  refetchClinicSchedule,
}) => {
  const formatTime = 'HH:mm'
  const formatDay = 'YYYY年MM月DD日'

  return (
    <Formik
      enableReinitialize
      initialValues={{
        periodicOpenDay: clinicalSchedules || [
          {
            rangeDay: [null, null],
            rangeTimeMorning: [null, null],
            rangeTimeAfternoon: [null, null],
            isAvailableMorning: true,
            isAvailableAfternoon: true,
          },
        ],
      }}
      onSubmit={onSubmit}
      render={formikProps => {
        const {
          errors,
          setFieldValue,
          values,
          handleChange,
          validateForm,
          setTouched,
        } = formikProps
        const errorMessage = []
        digDeeper(errors, errorMessage)

        return (
          <div className='periodic-container '>
            <div className='container'>
              <FieldArray
                name='periodicOpenDay'
                render={arrayHelpers => (
                  <React.Fragment>
                    {values.periodicOpenDay.map((item, index) => {
                      return (
                        <div className='row mt-10 periodic-main'>
                          <div className='col-md-4'>
                            <Space direction='vertical' size={12}>
                              <RangePicker
                                name={`periodicOpenDay[${index}].rangeDay`}
                                {...formikProps}
                                value={[
                                  item && item.rangeDay[0]
                                    ? moment(
                                        new Date(item.rangeDay[0]),
                                        formatDay
                                      )
                                    : undefined,
                                  item && item.rangeDay[1]
                                    ? moment(
                                        new Date(item.rangeDay[1]),
                                        formatDay
                                      )
                                    : undefined,
                                ]}
                                format={formatDay}
                                allowClear={false}
                                placeholder={['開始日', '終了日']}
                                // disabled={!item.isAvailable}
                                locale={locale}
                                name={`periodicOpenDay.${index}.rangeDay`}
                                onChange={(val, dateString) => {
                                  setFieldValue(
                                    `periodicOpenDay[${index}].rangeDay`,
                                    [val[0].format(), val[1].format()]
                                  )
                                }}
                              />
                            </Space>
                          </div>
                          <div className=' prl-5'>
                            {/* morning */}
                            <TimePicker.RangePicker
                              {...formikProps}
                              value={[
                                item && item.rangeTimeMorning[0]
                                  ? moment(
                                      new Date(item.rangeTimeMorning[0]),
                                      'HH:mm'
                                    )
                                  : undefined,
                                item && item.rangeTimeMorning[1]
                                  ? moment(
                                      new Date(item.rangeTimeMorning[1]),
                                      'HH:mm'
                                    )
                                  : undefined,
                              ]}
                              format={formatTime}
                              disabledHours={() => [
                                0,
                                1,
                                2,
                                3,
                                4,
                                5,
                                6,
                                14,
                                15,
                                16,
                                17,
                                18,
                                19,
                                20,
                                21,
                                22,
                                23,
                              ]}
                              placeholder={['開始時刻', '終了時刻']}
                              hideDisabledOptions
                              disabled={!item.isAvailableMorning}
                              name={`periodicOpenDay.${index}.rangeTimeMorning`}
                              allowClear={false}
                              onChange={val => {
                                setFieldValue(
                                  `periodicOpenDay[${index}].rangeTimeMorning`,
                                  [val[0].format(), val[1].format()]
                                )
                              }}
                              onSelect={val => {
                                setFieldValue(
                                  `periodicOpenDay[${index}].rangeTimeMorning`,
                                  [val[0].format(), val[1].format()]
                                )
                              }}
                            />

                            {/* afternoon */}
                            <TimePicker.RangePicker
                              {...formikProps}
                              value={[
                                item && item.rangeTimeAfternoon[0]
                                  ? moment(
                                      new Date(item.rangeTimeAfternoon[0]),
                                      'HH:mm'
                                    )
                                  : undefined,
                                item && item.rangeTimeAfternoon[1]
                                  ? moment(
                                      new Date(item.rangeTimeAfternoon[1]),
                                      'HH:mm'
                                    )
                                  : undefined,
                              ]}
                              format={formatTime}
                              disabledHours={() => [
                                0,
                                1,
                                2,
                                3,
                                4,
                                5,
                                6,
                                7,
                                8,
                                9,
                                10,
                                11,
                                12,
                              ]}
                              placeholder={['開始時刻', '終了時刻']}
                              hideDisabledOptions
                              disabled={!item.isAvailableAfternoon}
                              name={`periodicOpenDay.${index}.rangeTimeAfternoon`}
                              allowClear={false}
                              onChange={val => {
                                setFieldValue(
                                  `periodicOpenDay[${index}].rangeTimeAfternoon`,
                                  [val[0].format(), val[1].format()]
                                )
                              }}
                            />
                          </div>
                          <div className='checkbox-container'>
                            <div className='col-md-4 prl-5 checkbox_container checkbox'>
                              <div className='prl-5 label'>
                                {i18n.t('staff.editClinic.labelCheckboxClose')}
                              </div>

                              <Checkbox
                                {...formikProps}
                                name={`periodicOpenDay.${index}.isAvailableMorning`}
                                defaultChecked={!item.isAvailableMorning}
                                onChange={e => {
                                  setFieldValue(
                                    `periodicOpenDay.${index}.isAvailableMorning`,
                                    !e.target.checked
                                  )
                                }}
                              />
                            </div>
                            <div className='col-md-4 prl-5 checkbox_container checkbox'>
                              <div className='prl-5 label'>
                                {i18n.t('staff.editClinic.labelCheckboxClose')}
                              </div>

                              <Checkbox
                                {...formikProps}
                                name={`periodicOpenDay.${index}.isAvailableAfternoon`}
                                defaultChecked={!item.isAvailableAfternoon}
                                onChange={e => {
                                  setFieldValue(
                                    `periodicOpenDay.${index}.isAvailableAfternoon`,
                                    !e.target.checked
                                  )
                                }}
                              />
                            </div>
                          </div>
                          <div className='col-md-2'>
                            <Button
                              icon='pi pi-times'
                              onClick={() => {
                                arrayHelpers.remove(index)
                              }}
                            />
                          </div>
                        </div>
                      )
                    })}
                  </React.Fragment>
                )}
              />
              <div className='row pd-50 button-submit'>
                <div className='col-md-12'>
                  <Button
                    type='submit'
                    icon='pi pi-plus'
                    label={i18n.t('staff.editClinic.buttonAdd')}
                    className='button_add'
                    onClick={() => {
                      const tempList = values.periodicOpenDay
                      tempList.push({
                        rangeDay: [null, null],
                        rangeDayMorning: [null, null],
                        rangeTimeMorning: [
                          `${moment().format('YYYY-MM-DD')}T09:00:00`,
                          `${moment().format('YYYY-MM-DD')}T13:00:00`,
                        ],
                        rangeTimeAfternoon: [
                          `${moment().format('YYYY-MM-DD')}T15:00:00`,
                          `${moment().format('YYYY-MM-DD')}T21:00:00`,
                        ],
                        isAvailableMorning: true,
                        isAvailableAfternoon: true,
                      })
                      setFieldValue('periodicOpenDay', tempList)
                    }}
                  />
                </div>
                <div className='col-md-12 center-block'>
                  <Button
                    className='reflect-button'
                    onClick={() => {
                      validateForm().then(response => {
                        if (!Object.keys(response).length) {
                          setFieldValue('confirm', true)
                        } else {
                          setTouched(response)
                        }
                      })
                    }}
                    label={i18n.t('staff.editClinic.reflect')}
                  />
                </div>
              </div>
              <ConfirmationModal
                {...formikProps}
                confirmationMessage={
                  // values.isSendEmail
                  //   ? confirmationMessage
                  //   : i18n.t(
                  //       'staff.doctorDetail.confirmAcceptDoctocandSendMali'
                  //     )
                  confirmationMessage
                }
              />
            </div>
          </div>
        )
      }}
    />
  )
}

export default PeriodicOpenTab
