import React from 'react'
import { Checkbox } from 'primereact/checkbox'
import { ErrorMessage } from 'formik'

const TermsOfCarTransportationExpenses = ({ values, setFieldValue }) => (
  <div className='course-description-doctor'>
    <p style={{ textAlign: 'center' }}>
      <b>マイカー規定について</b>
    </p>
    <span className='asterisk-label-head' style={{ textAlign: 'justify' }}>
      定常的にマイカーを利用しない場合、チェックは不要です。定常的にマイカーを利用する場合は下記の内容を必ずご確認ください。
      基本的には、公共交通機関による通勤をお願いしておりますが、マイカー通勤をご希望の際には、マイカー通勤申請規定(
      <a href='https://drive.google.com/file/d/1xKk9EFIqdjDpAosAfdZ9qVWVanazma8E/view?usp=sharing'>
        https://drive.google.com/file/d/1xKk9EFIqdjDpAosAfdZ9qVWVanazma8E/view?usp=sharing
      </a>
      )をお読みいただき、マイカー通勤の許可の基準を満たしていること確認して下さい。その後、道路交通法その他自動車交通関係法令並びにマイカー通勤に関する規程を遵守し、常に安全運転に努めることにご同意頂ける場合には、規定への同意欄ににチェックを入れて下さい。こちらにご同意いただけない場合には、マイカー通勤の許可はできませんので、ご注意下さい。
      マイカー通勤の場合には、Googleマップ(
      <a href='https://www.google.com/maps/'>https://www.google.com/maps/</a>
      )のルート検索機能を利用し、出発地と到着地を入力して経路検索をして下さい。その経路に間違いない場合には、検索結果の分数の横に、3つの丸が2つのバーでつながっているアイコンをクリックし、ポップアップ画面の「リンクをコピー」して、そのコピーしたURLをドクターサイト内の経路URLの欄に貼り付けて下さい。ドクターサイト内の出発地、到着地に関してヘア、高速道路を使用しない場合には、出発地、到着地は、「なし」、片道料金は「0」と入力して下さい。高速道路など有料道路を使用する際は、有料道路に乗るインターチェンジ名（例　芝浦IC）と降りるインターチェンジ名（例　柏IC）を入力してください。また、片道料金については、こちらのサイト(
      <a href='https://search.shutoko.jp/index.html'>
        https://search.shutoko.jp/index.html
      </a>
      )で検索し、ETC料金を入力して下さい。
    </span>
    <br />
    <p className='asterisk-label-head' style={{ textAlign: 'justify' }}>
      通勤経路はガソリン代の計算や労災の届出時等に使用します。※法人の判断で経路を見直すことがあります。
      こちらで申請いただく定常的な通勤経路ではない、イレギュラーな交通費の精算は、「交通費精算」にて、ご精算をお願いいたします。
    </p>
    <div style={{ display: 'flex', marginTop: '20px' }}>
      <Checkbox
        onChange={() => {
          setFieldValue('isAgree', !values.isAgree)
        }}
        checked={values.isAgree}
        name='isAgree'
      />
      <div className='checkbox-label'>
        マイカー通勤についての規定を確認しました。
      </div>
    </div>
    <div style={{ justifyContent: 'center' }}>
      <ErrorMessage name='isAgree' component='div' className='signup-alert' />
    </div>
  </div>
)

export default TermsOfCarTransportationExpenses
