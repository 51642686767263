import { cloudWatchLogger } from '@medical/libs'
import { initGA, logPageView } from '@medical/libs/analytics'
import { ACCESSING } from '@medical/libs/logEvents'
import Auth from '@medical/middleware/auth'
import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
// const logger = cloudWatchLogger()

const onResize = () => {
  // We execute the same script as before
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}
const token = Auth.getToken()

const GoogleAnalytics = ({ children, location }) => {
  useEffect(() => {
    initGA()
    onResize()
    window.addEventListener('resize', onResize)
  }, [])

  useEffect(() => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const page = window.location.pathname + window.location.search
    logPageView(page)
    // document.title = `CAPS ${location.pathname}`
    if (location.pathname.indexOf('staff') > -1)
      document.title = `CAPS DOCTOR 365 | Staff`
    // logger.info({
    //   level: 'INFO',
    //   detail: ACCESSING,
    //   location: page,
    //   token,
    // })
  }, [location])

  return children
}

export default withRouter(GoogleAnalytics)
