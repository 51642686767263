import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import ClinicForm from '@medical/pages/staff/Clinics/components/ClinicForm'
import { useCustom } from '@medical/provider/context'
import React, { useEffect } from 'react'

import {
  CLINIC_QUERY,
  REGION_QUERY,
  UPDATE_CLINIC,
} from './ClinicUpdate.graphql'

const ClinicUpdate = ({
  match: {
    params: { clinicId },
  },
  parentPath,
  closeModal,
}) => {
  const [{ i18n, popup }] = useCustom()
  const [updateClinic] = useMutation(UPDATE_CLINIC)
  const { loading, error, data } = useQuery(CLINIC_QUERY, {
    variables: {
      clinicId,
    },
  })

  const {
    loading: listRegionLoading,
    error: listRegionError,
    data: listRegionData,
    refetch,
  } = useQuery(REGION_QUERY, {
    variables: {
      deletedAt: null,
    },
  })

  useEffect(() => {
    refetch()
  }, [])

  if (loading || listRegionLoading) return <ProgressSpinner />
  if (error || listRegionError)
    return <ErrorComponent error={error || listRegionError} />

  const onSubmit = async (
    {
      clinicName,
      clinicNameKana,
      emailAddress,
      address,
      phoneNumber,
      inquiryPhoneNumber,
      departmentName,
      departmentDisplays,
      region,
    },
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      await updateClinic({
        variables: {
          clinicId,
          name: clinicName,
          nameKana: clinicNameKana,
          email: emailAddress,
          phoneNumber,
          inquiryPhoneNumber,
          address,
          departmentNames: departmentName,
          departmentDisplays,
          isUpdateAddress: address !== data.clinic.address,
          region,
        },
      })
      popup.success(i18n.t('staff.updateClinic.submissionSuccess'))
      setSubmitting(false)
      resetForm()
      closeModal()
    } catch (error) {
      popup.error(error)
      setFieldValue('confirm', false)
      setSubmitting(false)
    }
  }

  return (
    <ClinicForm
      clinic={data.clinic}
      onSubmit={onSubmit}
      submitLabel={i18n.t('staff.updateClinic.submit')}
      confirmationMessage={i18n.t('staff.updateClinic.submitConfirmation')}
      departments={data.departments}
      parentPath={parentPath}
      update
      region={listRegionData?.regions}
      isClinicUpdate={true}
    />
  )
}

export default ClinicUpdate
