/* eslint-disable no-empty-pattern */
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import {
  STAFF_CREATE_REGION,
  STAFF_DELETE_REGION,
  STAFF_UPDATE_REGION,
} from '@medical/constant/permissions'
import { checkStaffPermission } from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import React, { createContext, useEffect } from 'react'

import { UPDATE_REGION, CREATE_REGION } from './Region.graphql'
import RegionForm from './RegionScene'
import { REGION_QUERY } from '../Clinics/ClinicUpdate/ClinicUpdate.graphql'

const Regions = ({}) => {
  const client = useApolloClient()
  const [{ i18n, popup }] = useCustom()

  const {
    loading: listRegionLoading,
    error: listRegionError,
    data: listRegionData,
    refetch,
  } = useQuery(REGION_QUERY, {
    variables: {
      deletedAt: null,
      orderBy: 'position_ASC',
    },
    fetchPolicy: 'network-only',
  })
  useEffect(() => {
    refetch()
  })
  const isCreatePermitted = checkStaffPermission({
    functionName: STAFF_CREATE_REGION,
  })
  const isDeletePermitted = checkStaffPermission({
    functionName: STAFF_DELETE_REGION,
  })
  const isUpdatePermitted = checkStaffPermission({
    functionName: STAFF_UPDATE_REGION,
  })
  const [updateRegion] = useMutation(UPDATE_REGION)
  const [createRegion] = useMutation(CREATE_REGION)
  if (listRegionLoading) return <ProgressSpinner />
  if (listRegionError) return <ErrorComponent error={listRegionError} />
  const onSubmit = async (
    { id, name, isUpdate, isDelete, nameUpdate, position, positionUpdate },
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      if (!isUpdate && !isDelete) {
        await createRegion({
          variables: {
            name,
            position: Number(position),
          },
        })
        popup.success('成功しました')
        setSubmitting(false)
        resetForm()
      } else {
        await updateRegion({
          variables: {
            regionId: id,
            name: nameUpdate ? nameUpdate.trim() : '',
            deletedAt: isUpdate ? null : new Date().toISOString(),
            position: Number(positionUpdate),
          },
        })
        popup.success('成功しました')
        setSubmitting(false)
        resetForm()
      }
    } catch (error) {
      popup.error(error)
      setFieldValue('confirm', false)
      setSubmitting(false)
    }
  }
  const listRegion = listRegionData?.regions
    ? listRegionData.regions.map(item => ({
        id: item.id,
        name: item.name,
        position: item.position,
        edit: true,
      }))
    : []
  return (
    <RegionForm
      onSubmit={onSubmit}
      i18n={i18n}
      listRegion={listRegion}
      isCreatePermitted={isCreatePermitted}
      isDeletePermitted={isDeletePermitted}
      isUpdatePermitted={isUpdatePermitted}
    />
  )
}

export default Regions
