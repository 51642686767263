import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { useCustom } from '@medical/provider/context'
import React from 'react'
import { useEffect } from 'react'
import {
  STAFF_UPDATE_TRANPORTATION_SETTING_DATE,
  TRANSPORTATION_TYPE_SETTINGS,
  TRANSPORTATION_TYPE_SETTINGS_CONNECTION,
} from '../MovingExpenseSetting.graphql'
import { MovingExpenseSettingScence } from './MovingExpenseSettingScence'

export const MovingExpenseSetting = () => {
  const [{ i18n, popup }] = useCustom()
  const { loading, error, data, refetch } = useQuery(
    TRANSPORTATION_TYPE_SETTINGS_CONNECTION,
    {
      variables: {
        where: {
          OR: [
            {
              isExpiredSetting: null,
            },
            {
              isExpiredSetting: false,
            },
          ],
        },
      },
    }
  )

  const {
    loading: loadingExpiredDate,
    error: errorExpiredDate,
    data: dataExpiredDate,
  } = useQuery(TRANSPORTATION_TYPE_SETTINGS, {
    variables: {
      where: {
        isExpiredSetting: true,
      },
    },
  })
  const [StaffUpdateTransportSettingDate] = useMutation(
    STAFF_UPDATE_TRANPORTATION_SETTING_DATE
  )
  const onSubmit = async (
    { expiredDate, isApply },
    { setSubmitting, setFieldValue }
  ) => {
    try {
      await StaffUpdateTransportSettingDate({
        variables: {
          staffUpdateTransportSettingDateId: dataExpiredDate
            ? dataExpiredDate.transportationTypeSettings[0].id
            : '',
          expiredDate,
          isApply,
        },
      })
      popup.success(i18n.t('staff.movingExpensesDetail.submissionSuccess'))
      setSubmitting(false)
      setFieldValue('confirm', false)
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
      setFieldValue('confirm', false)
    }
  }
  useEffect(() => {
    refetch()
  })
  if (loading || loadingExpiredDate) return <ProgressSpinner />
  if (error || errorExpiredDate) return <ErrorComponent error={error} />
  return (
    <>
      <MovingExpenseSettingScence
        i18n={i18n}
        onSubmit={onSubmit}
        dataExpiredDate={dataExpiredDate}
        data={data.transportationTypeSettingsConnection.edges}
      />
    </>
  )
}
