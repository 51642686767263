import gql from 'graphql-tag'

export const CLINIC_FRAGMENT = gql`
  fragment CLINIC_FRAGMENT on Clinic {
    id
    name
    nameKana
    address
    phoneNumber
    email
    isPublished
  }
`

export const CLINICS_CONNECTION = gql`
  query CLINICS_CONNECTION($where: ClinicWhereInput, $skip: Int, $first: Int) {
    clinicsConnection(where: $where, skip: $skip, first: $first) {
      edges {
        node {
          ...CLINIC_FRAGMENT
        }
      }
    }
  }
  ${CLINIC_FRAGMENT}
`

export const CLINICAL_DEPARTMENTS = gql`
  mutation ClinicalDepartments($id: ID) {
    clinicalDepartments(where: { Clinic: { id: $id} }) {
      id,
      name,
      departmentCode
    }
  }
`

export const UPSERT_EXPECTATION_DOUBLE_RECRUITMENT_RECORD = gql`
  mutation UpsertExpectationDoubleRecruitmentRecord(
    $clinic: ID!,
    $clinicalDepartment: ID!,
    $data: [CustomExpectationDoubleRecruitmentRecordInput]
  ) {
    upsertExpectationDoubleRecruitmentRecord(
      clinic: $clinic,
      clinicalDepartment: $clinicalDepartment,
      data: $data
    )
  }
`

export const GET_EXPECTATION_DOUBLE_RECRUITMENT_RECORD_PATTERN = gql`
  mutation GetExpectationDoubleRecruitmentRecordPattern(
    $clinic: ID!,
    $clinicalDepartment: ID!,
    $targetMonth: Int,
    $targetYear: Int,
  ) {
    getExpectationDoubleRecruitmentRecordPattern(
      clinic: $clinic
      clinicalDepartment: $clinicalDepartment
      targetMonth: $targetMonth
      targetYear: $targetYear
    ) {
      updatedAt
      createdAt
      id
      targetMonth
      targetYear
      mondayMorningShiftDoctors
      mondayAfternoonShiftDoctors
      mondayNightShiftDoctors
      tuesdayMorningShiftDoctors
      tuesdayAfternoonShiftDoctors
      tuesdayNightShiftDoctors
      wednesdayMorningShiftDoctors
      wednesdayAfternoonShiftDoctors
      wednesdayNightShiftDoctors
      thursdayMorningShiftDoctors
      thursdayAfternoonShiftDoctors
      thursdayNightShiftDoctors
      fridayMorningShiftDoctors
      fridayAfternoonShiftDoctors
      fridayNightShiftDoctors
      saturdayMorningShiftDoctors
      saturdayAfternoonShiftDoctors
      saturdayNightShiftDoctors
      sundayMorningShiftDoctors
      sundayAfternoonShiftDoctors
      sundayNightShiftDoctors
      holidayMorningShiftDoctors
      holidayAfternoonShiftDoctors
      holidayNightShiftDoctors
    }
  }
`

export const GET_EXPECTATION_DOUBLE_RECRUITMENT_RECORD = gql`
  mutation GetExpectationDoubleRecruitmentRecord(
    $clinic: ID!,
    $clinicalDepartment: ID!,
    $year: Int,
    $month: Int,
  ) {
    getExpectationDoubleRecruitmentRecord(
      clinic: $clinic,
      clinicalDepartment: $clinicalDepartment,
      year: $year,
      month: $month
    ) {
      updatedAt
      createdAt
      id
      shiftDate
      morningShiftDoctors
      afternoonShiftDoctors
      nightShiftDoctors
    }
  }
`
