import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import ClinicNameRender from '@medical/components/ClinicNameRender'
import {
  calculateSalaryNumber,
  calcWorkScheduleTotalDays,
  calcWorkScheduleTotalHours,
  calcWorkScheduleTotalSalary,
  clinicalDepartmentNames,
  dateValidation,
  calculaterSubsidy,
} from '@medical/libs'
import Router from '@medical/routes/router'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'

import { DOCTOR_WORK_SCHEDULES } from './AcceptedWorkSchedulesList.graphql'
import AcceptedWorkSchedulesListScene from './AcceptedWorkSchedulesListScene'
import { parseJwt } from '@medical/constant/utilities'

const AcceptedWorkSchedulesList = ({
  match: {
    path,
    params: { year = moment().year(), month = moment().month() + 1 },
  },
}) => {
  const { i18n } = useTranslation()
  const date = moment()
    .year(year)
    .month(month - 1)
    .startOf('month')
    .toISOString()
  const endDate = moment()
    .year(year)
    .month(month - 1)
    .endOf('month')
    .toISOString()
  const doctorId = parseJwt(localStorage.getItem('medical_doctor_token'))
    .doctorId

  const { loading, error, data, refetch } = useQuery(DOCTOR_WORK_SCHEDULES, {
    variables: {
      where: {
        Doctor: {
          id: doctorId,
        },
        startTime_gte: date,
        startTime_lt: endDate,
        accepted: true,
        deletedAt: null,
        AvailableShift_some: { isPublished: true },
      },
      orderBy: 'startTime_ASC',
    },
  })
  useEffect(() => {
    refetch()
  })
  if (
    dateValidation({ year, month }) ||
    [
      Router.doctorAcceptedWorkSchedulesListWithoutYearMonth,
      Router.doctorAcceptedWorkSchedulesListWithoutMonth,
    ].includes(path)
  )
    return (
      <Redirect
        to={Router.get(Router.doctorAcceptedWorkSchedulesList, {
          year: moment().year(),
          month: moment().month() + 1,
        })}
      />
    )

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  const { workSchedules } = data || {}
  const departmentNames = clinicalDepartmentNames({
    departments: data.departments,
  })
  const resultWorkSchedules = []
  workSchedules.map(ws => {
    resultWorkSchedules.push({
      id: ws.id,
      clinic: ClinicNameRender({
        clinicalDepartment: ws.clinicalDepartment,
      }),
      clinicKana: ws.clinicalDepartment.clinic.nameKana,
      clinicalDepartment: departmentNames[ws.clinicalDepartment.name],
      startTime: ws.startTime,
      endTime: ws.endTime,
      dailySalary: calculateSalaryNumber({
        availableShift: ws.acceptedShift,
        startTime: ws.acceptedShift.startTime,
        endTime: ws.acceptedShift.endTime,
        hourlyWageRange: ws.adjustHourlyWageRange,
        doctorSubsidy: calculaterSubsidy(ws.acceptedShift.doctorSubsidy),
      }),
      accepted: ws.accepted,
      isCancel: ws.workScheduleCancellationLetter !== null,
      workOvertime: ws.workOvertime,
    })
    return true
  })
  const totalHours = calcWorkScheduleTotalHours(resultWorkSchedules)

  const totalSalary = calcWorkScheduleTotalSalary(resultWorkSchedules)

  const totalDays = calcWorkScheduleTotalDays(resultWorkSchedules)

  return (
    <AcceptedWorkSchedulesListScene
      i18n={i18n}
      date={moment(date)}
      workSchedules={resultWorkSchedules}
      totalHours={totalHours}
      totalDays={totalDays}
      totalSalary={totalSalary}
    />
  )
}

export default AcceptedWorkSchedulesList
