import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { pathOr } from 'ramda'
import React, { useState } from 'react'

const PasswordInput = ({
  values,
  handleChange,
  handleBlur,
  name,
  errors,
  touched,
  disabled,
  width,
  label,
  inputWidth,
  classDisabled,
  fullWidth,
  onKeyDown,
}) => {
  const [show, setShow] = useState(true)
  const keys = name.split('.')
  const value = pathOr('', keys, values)
  const isTouched = pathOr(false, keys, touched)
  const error = pathOr('', keys, errors)
  if (fullWidth) {
    return (
      <div className='p-inputgroup'>
        <InputText
          name={name}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          type={show ? 'password' : undefined}
          style={inputWidth ? { width: inputWidth } : { width: '100%' }}
          disabled={disabled}
          className={classDisabled && disabled ? 'is-disabled' : ''}
          onKeyDown={onKeyDown}
          autoCapitalize='none'
        />
        <button
          tabIndex='-1'
          type='button'
          onClick={() => setShow(!show)}
          className='p-button p-component p-button-icon-only'
        >
          <i
            className={`p-c p-button-icon-left pi pi-eye${
              show ? `` : `-slash`
            }`}
          />
        </button>
      </div>
    )
  }

  return (
    <div className='tr'>
      <div className='th' style={{ width }}>
        {label}
      </div>
      <div className='td'>
        <div className='p-inputgroup'>
          <InputText
            name={name}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            type={show ? 'password' : undefined}
            style={inputWidth ? { width: inputWidth } : { width: '100%' }}
            disabled={disabled}
            className={classDisabled && disabled ? 'is-disabled' : ''}
            onKeyDown={onKeyDown}
            autoCapitalize='none'
          />
          <Button
            tabIndex='-1'
            icon={show ? `pi pi-eye` : `pi pi-eye-slash`}
            onClick={() => setShow(!show)}
          />
        </div>
        <div className='alert'>{isTouched && error}</div>
      </div>
    </div>
  )
}

export default PasswordInput
