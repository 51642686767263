import gql from 'graphql-tag'

export const DOCTOR_CREATE_WORKSCHEDULE_OVERTIME = gql`
  mutation doctorCreateWorkScheduleOverTime(
    $workScheduleId: ID!
    $data: WorkScheduleOvertimeCreateWithoutWorkScheduleInput!
  ) {
    doctorCreateWorkScheduleOverTime(
      workScheduleId: $workScheduleId
      data: $data
    ) {
      id
    }
  }
`
