import logo from '@medical/assets/image/logo.png'
import { Card } from 'primereact/card'
import { Panel } from 'primereact/panel'
import React from 'react'

const Terms = () => {
  const header = (
    <div className='layout-logo-privacy'>
      <img src={logo} alt='' style={{ width: '100%' }} />
    </div>
  )
  const footer = (
    <div className='privacy-footer'>
      <div
        style={{ textAlign: 'center', paddingBottom: '5px', fontSize: '10px' }}
      >
        © 2018 MNYS 医療法人社団ナイズ　All rights reserved.
      </div>
    </div>
  )
  return (
    <Card
      title='利用規約'
      className='ui-card-shadow'
      header={header}
      footer={footer}
    >
      <Panel header=''>
        <p className='privacy-p'>
          このサービス利用規約（以下「本規約」）は、医療法人社団ナイズ（以下「当社」といいます。）が運営する医師のシフト管理サイト「CAPS
          DOCTOR
          365」（以下「本サービス」）の利用に関する基本的事項を定めたものであり、本サービスを利用する会員（以下「会員」）と当社との間に適用されるものとし、本サービスを通じて提供される一切のサービスをいいます。
        </p>
      </Panel>
      <Panel header='第1条（規約への同意）'>
        <p className='privacy-p'>
          ユーザーは、本サービスの利用に際し、本規約及び当社が別途定める各種規約に同意する必要があるものとします。
          ユーザーは、本規約に同意の上で本サービスを利用するものとします。ユーザーが本サービスの利用を開始した時点で、当該ユーザーは本規約に同意したものとみなされます。
          ユーザーが未成年者等である場合、本サービスの利用に当たっては、予め、親権者などの法定代理人の同意を得るものとします。未成年者等であるユーザーが本サービスの利用を開始した時点で、当該ユーザーは法定代理人の同意を得ているものとみなされます。
        </p>
      </Panel>
      <Panel header='第2条（本規約の適用範囲）'>
        <p className='privacy-p'>
          本規定は会員が本サービスを利用する際に適用されるものとします。本規定に定めがない事項に関しては、個別契約が優先されるものとします。
        </p>
      </Panel>
      <Panel header='第3条（禁止事項）'>
        <p className='privacy-p'>
          本サービスを利用する会員は、次の行為をすることはできません。
        </p>
        <ol className='terms-parenthesis'>
          <li>虚偽の情報を登録し、又は提供すること</li>
          <li>弊社ら、他の会員又は第三者の著作権等知的財産権を侵害する行為</li>
          <li>
            弊社ら、他の会員又は第三者の財産権、プライバシーに関する権利、その他の権利又は利益を侵害する行為
          </li>
          <li>
            本サービスで得た情報を、本サービスの利用目的の範囲を超えて第三者に譲渡し、又は営利を目的とした情報提供活動に用いること
          </li>
          <li>
            コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報を送信する行為
          </li>
          <li>本サービスの運営の妨げとなる一切の行為</li>
          <li>本サービスを利用する他の会員又は第三者を誹謗中傷する行為</li>
          <li>公序良俗に反する行為</li>
          <li>法令に反する一切の行為</li>
          <li>その他、弊社らが不適切と判断する一切の行為</li>
        </ol>
      </Panel>
      <Panel header='第4条（会員の責任）'>
        <p className='privacy-p'>
          会員は自らの意思によって本サービスを利用するものとします。
          <br />
          会員は、会員が自ら登録した情報については、その内容について一切の責任を負うものとします。
          <br />
          前項の登録情報は、本サービスを利用するために必要な範囲内で、会員自らがいつでも変更、追加、削除できるものとし、常に会員が責任をもって利用目的に沿い、正確、最新に保つものとします。
        </p>
      </Panel>
      <Panel header='第5条（利用停止等）'>
        <p className='privacy-p'>
          弊社らは、会員が第2条その他本規約の規定に違反した場合又は本サービスの利用がふさわしくないと弊社らが判断した場合には、会員に通知することなく、当該会員について本サービスの利用資格を取り消し、または本サービスの利用を一時的に停止することができます。
        </p>
      </Panel>
      <Panel header='第6条（登録情報等の取り扱い）'>
        <p className='privacy-p'>
          本サービスにおける個人情報の取り扱いについては、「個人情報保護方針」および「個人情報の取り扱いについて」（以下総称して「個人情報保護方針」といいます）をご確認ください。会員は、本サービスを利用する場合には、当該個人情報保護方針を承認したものとみなします。
          弊社らは、会員が自ら登録した情報又は求人情報を提供する医療機関若しくは企業等による評価に関する情報を、医療機関等に提供することができます。
        </p>
      </Panel>
      <Panel header='第7条（サービス内容の変更）'>
        <p className='privacy-p'>
          弊社らは、本サービスの運営を良好に保つため、事前の通知なく、本サービスの内容を変更することがあり、会員はそれに対して異議を申し立てないものとします。
        </p>
      </Panel>
      <Panel header='第8条（サービスの停止・終了等）'>
        <p className='privacy-p'>
          弊社らは、以下のいずれかに該当する事由が発生した場合、会員への事前の通知及び承諾を要することなく、本サービスを停止または終了することができます。
        </p>
        <ol className='terms-parenthesis'>
          <li>
            本サービス運営のためのシステム（以下「システム」といいます。）の保守、更新等を定期的又は緊急に行う場合
          </li>
          <li>
            通常講ずるべきウィルス対策では防止できないウィルス被害、火災、停電、天災地変などの不可抗力により、本サービスの提供が困難な場合
          </li>
          <li>突発的なシステムの故障等が発生した場合</li>
          <li>
            その他、不測の事態により、弊社らが本サービスの提供が困難と判断した場合
          </li>
        </ol>
      </Panel>
      <Panel header='第9条（免責）'>
        <p className='privacy-p'>
          弊社らは、企業情報等の第三者の情報、求人情報、広告その他第三者により提供される情報、会員等が本サービスに登録し掲載する情報等に関し、内容の正確性、有用性等について何らの保証もしません。
          <br />
          弊社らは、会員による本サービスの利用によって、就職・転職が成功すること又は就職・転職後の雇用条件を保証するものではありません。
          <br />
          会員の本サービスの利用により生じる一切の損害に関して、弊社らは責任を負わないものとします。
          <br />
          弊社らは、弊社らによる本サービスの提供の中断、停止、変更若しくは終了、会員の情報の削除又は消失又は会員の利用資格の取消し若しくは利用の停止により会員が被った損害につき、一切責任を負わないものとします。
          <br />
          本ウェブサイトから他のウェブサイトへのリンク又は他のウェブサイトから本ウェブサイトへのリンクが提供されている場合でも、弊社らは、本ウェブサイト以外のウェブサイト及びそこから得られる情報に関して如何なる理由に基づいても一切の責任を負わないものとします。
          <br />
          弊社らの責任を免責する本規約の条項が消費者契約法等の法令に反することによって無効となる場合など、何らかの理由によって弊社らが本サービスに関して会員に対して損害賠償責任を負うべき場合でも、弊社らの賠償責任は、故意または重過失による場合を除き、会員に生じた直接かつ通常の損害の範囲に限るものとします。
        </p>
      </Panel>
      <Panel header='第10条（本規定の変更）'>
        <p className='privacy-p'>
          弊社らはいつでも特にお知らせすることなく本規定を変更する権利を持つものとします。本規定の変更は、変更後の本規定全文がこのサイト上に掲載された時点でただちに発効するものとします。会員が本規定に同意することにより以上のような修正について、会員が現実の署名をしたものと見なすものとします。常に会員の責任で、本サービスを利用する前に本規定の最新版を読み、必ず最新版の本規定の条件を了解するようにしてください。本規定の変更後に会員が本サービスを引き続き利用した場合、変更を了承したものとみなされます。
        </p>
      </Panel>
      <div style={{ padding: '20px' }}>
        <p>2020年1月8日制定</p>
      </div>
    </Card>
  )
}

export default Terms
