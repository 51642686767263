import splitTimes from '@medical/constant/splitTimes'
import moment from 'moment'
import { AutoComplete } from '../../../../fork/primereact/autocomplete'
import React, { useEffect, useState } from 'react'

const SplitsAutoComplete = ({
  value: valueProp,
  onChange,
  disabled,
  minDate,
}) => {
  const minIndex = minDate
    ? splitTimes.indexOf(moment(minDate).format('HH:mm'))
    : 0
  const allSuggestions = splitTimes.slice(minIndex, splitTimes.length)
  const [suggestions, setSuggestions] = useState(allSuggestions)
  const [value, setValue] = useState(moment(valueProp).format('HH:mm'))
  useEffect(() => {
    setValue(moment(valueProp).format('HH:mm'))
  }, [valueProp])
  return (
    <AutoComplete
      value={value}
      suggestions={suggestions}
      completeMethod={e => {
        const results = allSuggestions.filter(item =>
          item.startsWith(e.query.toLowerCase())
        )
        setSuggestions(results)
      }}
      onChange={e => {
        let value = e.value
        const reg = /^\d+$/
        const regex = new RegExp(reg)
        if (value.match(regex) && value.length > 3) {
          const hour = value.slice(0, 2)
          const minute = value.slice(2, 4)

          value = `${hour}:${minute}`
        }
        setValue(value)
        const has = allSuggestions.some(item => item === value)
        if (has) {
          const date = moment(valueProp).toDate()
          const times = value.split(':')
          date.setHours(times[0])
          date.setMinutes(times[1])
          onChange({ value: date })
        }
      }}
      onBlur={() => {
        const results = allSuggestions.some(item => item === value)
        if (!results) {
          const date = moment(valueProp).toDate()
          const times = allSuggestions[0].split(':')
          date.setHours(times[0])
          date.setMinutes(times[1])
          onChange({ value: date })
        }
      }}
      disabled={disabled}
      className='split-autocomplete'
      dropdown
      // readonly
    />
  )
}

export default SplitsAutoComplete
