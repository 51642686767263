import { CUSTOM_MODAL, SMALL_MODAL } from '@medical/constant'
import { Button } from 'primereact/button'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { ModalRoute } from 'react-router-modal'

export const CustomModal = ({ children, closeModal }) => (
  <div
    className={
      CUSTOM_MODAL.includes(children.type.name)
        ? 'modal-container-custom'
        : SMALL_MODAL.includes(children.type.name)
        ? 'small-modal-container'
        : 'modal-container'
    }
  >
    <div className='modal-top'>
      <Button
        onClick={closeModal}
        style={{ marginLeft: '20px' }}
        icon='pi pi-times'
        className='p-button-secondary'
      />
    </div>
    <div
      className={
        CUSTOM_MODAL.includes(children.type.name)
          ? 'modal-content-custom'
          : 'modal-content'
      }
    >
      {children}
    </div>
  </div>
)

const ModalRoutes = ({
  modals = [],
  match: { path, url },
  location: { search },
}) => (
  <React.Fragment>
    {modals.map(modal => {
      const { component: Component } = modal
      return (
        <ModalRoute
          onBackdropClick={() => {}}
          key={modal.path}
          path={`${path}${modal.path}`}
          component={props => (
            <CustomModal {...props}>
              <Component {...props} />
            </CustomModal>
          )}
          exact={modal.exact}
          parentPath={{ pathname: url, search }}
        />
      )
    })}
  </React.Fragment>
)

export default withRouter(ModalRoutes)
