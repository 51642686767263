/* eslint-disable no-nested-ternary */
import './style.css'

import BreadCrumb from '@medical/components/Breadcrumb'
import ModalLink from '@medical/components/ModalLink'
import numeralCurrency from '@medical/libs/numeralCurrency'
import Router from '@medical/routes/router'
import moment from 'moment'
import { Button } from 'primereact/button'
import { MultiSelect } from 'primereact/multiselect'
import React, { useState } from 'react'
import { Paginator } from 'primereact/paginator'
import { Checkbox, DatePicker, Modal } from 'antd'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import queryString from 'query-string'
import locale from 'antd/es/date-picker/locale/ja_JP'
import { useMutation } from '@apollo/react-hooks'
import { DELETE_MULTI_DEFAULT_HOURLY_WAGE_SCHEDULE } from './ClinicsHourlyWage.graphql'
import { useCustom } from '@medical/provider/context'
import { ExclamationCircleFilled } from '@ant-design/icons'
import _ from 'lodash'

const ClinicsHourlyWageScene = ({
  i18n,
  rowsPerPage,
  count,
  page,
  onPageChange,
  isCreatePermitted,
  isUpdatePermitted,
  isDeletePermitted,
  isUploadPermitted,
  defaultHourlyWageSchedules,
  clinicalDepartmentIds,
  clinicalDepartments,
  onDropdownChange,
  onSortHandle,
  isUploadCheckPermitted,
  orderBy,
  date,
  refetchHourlyWageSchedules,
  rowsSelect,
  setRowSelected,
}) => {
  const [{ popup }] = useCustom()
  const [isShowDialogDelete, setIsShowDialogDelete] = useState(false)

  const [deleteMultiDefaultHourlyWageSchedule] = useMutation(
    DELETE_MULTI_DEFAULT_HOURLY_WAGE_SCHEDULE
  )

  const { RangePicker } = DatePicker
  const renderAvailableDay = (
    isAvailableOnMonday,
    isAvailableOnTuesday,
    isAvailableOnWednesday,
    isAvailableOnThursday,
    isAvailableOnFriday,
    isAvailableOnSaturday,
    isAvailableOnSunday,
    isAvailableOnHoliday
  ) => {
    let availableDay = ''
    if (
      !isAvailableOnMonday ||
      !isAvailableOnTuesday ||
      !isAvailableOnWednesday ||
      !isAvailableOnThursday ||
      !isAvailableOnFriday
    ) {
      if (isAvailableOnMonday) availableDay += isAvailableOnMonday
      if (isAvailableOnTuesday) availableDay += isAvailableOnTuesday
      if (isAvailableOnWednesday) availableDay += isAvailableOnWednesday
      if (isAvailableOnThursday) availableDay += isAvailableOnThursday
      if (isAvailableOnFriday) availableDay += isAvailableOnFriday
      if (isAvailableOnSaturday) availableDay += isAvailableOnSaturday
      if (isAvailableOnSunday) availableDay += isAvailableOnSunday
      if (isAvailableOnHoliday) availableDay += isAvailableOnHoliday
    } else {
      availableDay = i18n.t('staff.editClinic.normalDay')
      if (isAvailableOnSaturday) availableDay += isAvailableOnSaturday
      if (isAvailableOnSunday) availableDay += isAvailableOnSunday
      if (isAvailableOnHoliday) availableDay += isAvailableOnHoliday
    }
    return availableDay
  }

  const hanleDeleteMultiHourlyWageSchedule = async () => {
    await deleteMultiDefaultHourlyWageSchedule({
      variables: {
        defaultHourlyWageScheduleId: rowsSelect,
      },
    })

    setRowSelected([])
    setIsShowDialogDelete(false)
    refetchHourlyWageSchedules('')
    popup.success(i18n.t('staff.createShift.submissionSuccess'))
  }

  const renderDate = (startDate, endDate) =>
    `${moment
      .utc(new Date(startDate))
      .format('YYYY年MM月DD日')} ～ ${moment
      .utc(new Date(endDate))
      .format('YYYY年MM月DD日')}`

  const renderTime = (startTime, endTime) =>
    `${moment.utc(new Date(startTime)).format('HH:mm')} ～ ${moment
      .utc(new Date(endTime))
      .format('HH:mm')}`

  const getIndexElmentDuplicatesClinicName = data => {
    const arrIndex = []
    let check = 0
    arrIndex[check] = 1
    for (let i = 0; i < data.length - 1; i += 1) {
      if (data[i].clinicId === data[i + 1].clinicId) {
        arrIndex[i + 1] = 0
        arrIndex[check] += 1
      } else {
        arrIndex[i + 1] = 1
        check = i + 1
      }
    }
    return arrIndex
  }
  const getIndexElmentDuplicatesDepartments = data => {
    const arrIndex = []
    let check = 0
    arrIndex[check] = 1
    for (let i = 0; i < data.length - 1; i += 1) {
      if (data[i].clinicalDepartmentId === data[i + 1].clinicalDepartmentId) {
        arrIndex[i + 1] = 0
        arrIndex[check] += 1
      } else {
        arrIndex[i + 1] = 1
        check = i + 1
      }
    }
    return arrIndex
  }
  const arrIndexClinicName = getIndexElmentDuplicatesClinicName(
    defaultHourlyWageSchedules.filter(rs =>
      clinicalDepartmentIds.length === 0
        ? true
        : clinicalDepartmentIds.includes(rs.clinicalDepartmentId)
    )
  )
  const arrIndexDepartments = getIndexElmentDuplicatesDepartments(
    defaultHourlyWageSchedules.filter(rs =>
      clinicalDepartmentIds.length === 0
        ? true
        : clinicalDepartmentIds.includes(rs.clinicalDepartmentId)
    )
  )

  const renderClassify = data => {
    if (data === 'NEW') return i18n.t('staff.editClinic.newClinic')
    if (data === 'EXIST') return i18n.t('staff.editClinic.existClinic')
    return ''
  }

  const handleClickSelectAll = e => {
    if (e.target.checked) {
      setRowSelected(
        _.uniq([...rowsSelect, ...defaultHourlyWageSchedules.map(rs => rs.id)])
      )
    } else {
      setRowSelected(
        [...rowsSelect].filter(
          row =>
            !defaultHourlyWageSchedules.find(schedule => schedule.id === row)
        )
      )
    }
  }

  const handleClickSelectRow = (e, id) => {
    if (e.target.checked) {
      setRowSelected([...rowsSelect, id])
    } else {
      setRowSelected([...rowsSelect.filter(row => row !== id)])
    }
  }

  return (
    <React.Fragment>
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('staff.menuBar.hourlyWage')}
          <BreadCrumb items={[{ title: i18n.t('staff.menuBar.hourlyWage') }]} />
        </div>
        <div className='staff-hourlywage-buttons'>
          {isCreatePermitted ? (
            <div className='button-container hourly-wage-container'>
              <ModalLink to={Router.get(Router.staffHourlyWageCreate)}>
                <Button
                  style={{
                    padding: '5px',
                    marginRight: '20px',
                    backgroundColor: 'white',
                  }}
                  label={i18n.t('staff.createHourlyWage.buttonAddInPage')}
                  className='p-button-normal'
                  icon='pi pi-plus-circle'
                />
              </ModalLink>
            </div>
          ) : null}
          {isUploadPermitted ? (
            <div style={{ marginRight: '20px' }}>
              <Link to={Router.staffHourlyWageCsv}>
                <Button
                  icon='pi pi-cloud-upload'
                  className='p-button-normal'
                  label='新規作成（csvアップロード）'
                />
              </Link>
            </div>
          ) : null}
        </div>
      </div>
      <div className='container'>
        <div className='box clinic-hourly-wage'>
          <div className='search-parent' style={{ width: '100%' }}>
            <Formik
              initialValues={{
                clinicalDepartmentIds: clinicalDepartmentIds || [],
                date: date,
              }}
            >
              {({ values, setFieldValue }) => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <MultiSelect
                      value={clinicalDepartments.filter(({ id }) =>
                        values.clinicalDepartmentIds.includes(id)
                      )}
                      style={{ marginRight: '20px' }}
                      fixedPlaceholder
                      options={clinicalDepartments}
                      optionLabel='clinicName'
                      onChange={e => onDropdownChange(e, setFieldValue)}
                      filter
                      placeholder={i18n.t('main.chooseClinic')}
                    />
                    <RangePicker
                      name='date'
                      className='hourly-picker'
                      value={[
                        values.date[0]
                          ? moment.utc(new Date(values.date[0]), 'YYYY-MM-DD')
                          : null,
                        values.date[1]
                          ? moment.utc(new Date(values.date[1]), 'YYYY-MM-DD')
                          : null,
                      ]}
                      style={{
                        borderColor: '#a6a6a6',
                        borderRadius: '3px',
                        marginRight: '20px',
                      }}
                      placeholder={['開始日', '終了日']}
                      allowClear={false}
                      locale={locale}
                      onChange={e => {
                        setFieldValue('date', [
                          moment(e[0]).format('YYYY-MM-DD'),
                          moment(e[1]).format('YYYY-MM-DD'),
                        ])
                      }}
                    />
                    <Link
                      to={{
                        pathname: Router.staffHourlyWages,
                        search: queryString.stringify({
                          rowsPerPage,
                          date: values.date.join(','),
                          clinicalDepartmentIds: values.clinicalDepartmentIds.join(
                            ','
                          ),
                        }),
                      }}
                      style={{ marginLeft: '20px' }}
                    >
                      <Button
                        label={i18n.t('main.search')}
                        icon='pi pi-search'
                      />
                    </Link>
                    {(date[0] && date[1]) ||
                    clinicalDepartmentIds.length > 0 ? (
                      <Link
                        to={{
                          pathname: Router.staffHourlyWages,
                          search: queryString.stringify({
                            rowsPerPage,
                          }),
                        }}
                      >
                        <Button
                          icon='pi pi-times'
                          className='p-button-secondary'
                          style={{ marginLeft: '20px' }}
                        />
                      </Link>
                    ) : null}
                  </div>
                  <Button
                    disabled={!rowsSelect.length}
                    onClick={() => setIsShowDialogDelete(true)}
                    style={{
                      backgroundColor: !rowsSelect.length
                        ? '#DADEE0'
                        : '#f03a6c',
                      border: 'none',
                      color: '#FFFFFF',
                    }}
                    type='primary'
                    label='確定する'
                  />
                </div>
              )}
            </Formik>
          </div>
          <table className='table' style={{ textAlign: 'center' }}>
            <thead>
              <tr>
                <th width='5%'>
                  <Checkbox
                    checked={
                      rowsSelect.filter(
                        row =>
                          !!defaultHourlyWageSchedules.some(
                            schedule => schedule.id === row
                          )
                      ).length === defaultHourlyWageSchedules.length
                    }
                    onChange={handleClickSelectAll}
                  />
                </th>
                <th width='13%'>{i18n.t('main.clinicName')}</th>
                <th width='11%'>
                  作成日
                  <Button
                    style={{
                      border: 'none',
                      backgroundColor: 'transparent',
                      color: '#686868',
                    }}
                    icon={
                      !orderBy.createdAt ? 'pi pi-sort-down' : 'pi pi-sort-up'
                    }
                    onClick={() => {
                      onSortHandle({ sortField: 'createdAt' })
                    }}
                  />
                </th>
                <th width='11%'>
                  更新日
                  <Button
                    style={{
                      border: 'none',
                      backgroundColor: 'transparent',
                      color: '#686868',
                    }}
                    icon={
                      !orderBy.updatedAt ? 'pi pi-sort-down' : 'pi pi-sort-up'
                    }
                    onClick={() => {
                      onSortHandle({ sortField: 'updatedAt' })
                    }}
                  />
                </th>
                <th width='19%'>
                  設定期間
                  <Button
                    style={{
                      border: 'none',
                      backgroundColor: 'transparent',
                      color: '#686868',
                    }}
                    icon={
                      !orderBy.startDate ? 'pi pi-sort-down' : 'pi pi-sort-up'
                    }
                    onClick={() => {
                      onSortHandle({ sortField: 'startDate' })
                    }}
                  />
                </th>
                <th width='10%'>設定曜日</th>
                <th width='10%'>設定時給額</th>
                <th width='10%'>{i18n.t('main.section')}</th>
                {isUpdatePermitted && isDeletePermitted ? (
                  <th width='8%'>{i18n.t('main.detail')}</th>
                ) : null}
                {isCreatePermitted ? <th width='8%'>複製</th> : null}
              </tr>
            </thead>
            <tbody>
              {defaultHourlyWageSchedules.length > 0
                ? defaultHourlyWageSchedules
                    // .filter(rs =>
                    //   clinicalDepartmentIds.length === 0
                    //     ? true
                    //     : clinicalDepartmentIds.includes(
                    //         rs.clinicalDepartmentId
                    //       )
                    // )
                    .map(
                      (rs, index) => (
                        // index % 2 === 1 ? (
                        <tr key={rs.id}>
                          <>
                            <td>
                              <Checkbox
                                checked={rowsSelect.some(row => row === rs.id)}
                                onChange={e => handleClickSelectRow(e, rs.id)}
                              />
                            </td>
                            <td>
                              {
                                clinicalDepartments.find(
                                  cd => cd.id === rs.clinicalDepartment.id
                                )?.clinicName
                              }
                            </td>
                            <td>{moment(rs.createdAt).format('LL (dd)')}</td>
                            <td>{moment(rs.updatedAt).format('LL (dd)')}</td>
                            {rs.startDate ? (
                              <td>
                                {`${renderDate(rs.startDate, rs.endDate)}`}
                              </td>
                            ) : (
                              <td />
                            )}
                            <td>
                              {' '}
                              {`${renderAvailableDay(
                                rs.isAvailableOnMonday,
                                rs.isAvailableOnTuesday,
                                rs.isAvailableOnWednesday,
                                rs.isAvailableOnThursday,
                                rs.isAvailableOnFriday,
                                rs.isAvailableOnSaturday,
                                rs.isAvailableOnSunday,
                                rs.isAvailableOnHoliday
                              )}`}
                            </td>
                            <td>
                              {rs.hourlyWageDefault
                                ? numeralCurrency(rs.hourlyWageDefault)
                                : null}
                            </td>
                            <td>{renderClassify(rs.classify)}</td>
                            {isUpdatePermitted && isDeletePermitted ? (
                              rs.startDate ? (
                                <td className='center'>
                                  <ModalLink
                                    to={Router.get(
                                      Router.staffHourlyWageDetail,
                                      {
                                        recruitmentShiftId: rs.id,
                                      }
                                    )}
                                  >
                                    <Button icon='pi pi-info' />
                                  </ModalLink>
                                </td>
                              ) : (
                                <td className='center' />
                              )
                            ) : null}
                            {isCreatePermitted ? (
                              rs.startDate ? (
                                <td className='center'>
                                  <ModalLink
                                    to={Router.get(
                                      Router.staffHourlyWageDuplicate,
                                      {
                                        recruitmentShiftId: rs.id,
                                      }
                                    )}
                                  >
                                    <Button icon='pi pi-pencil' />
                                  </ModalLink>
                                </td>
                              ) : (
                                <td className='center' />
                              )
                            ) : null}
                          </>
                        </tr>
                      )
                      // ) : (
                      //   <tr key={rs.id}>
                      //     <>
                      //       {arrIndexClinicName[index] !== 0 ? (
                      //         <td rowSpan={arrIndexClinicName[index]}>
                      //           {rs.clinicName}
                      //         </td>
                      //       ) : null}
                      //       {arrIndexDepartments[index] !== 0 ? (
                      //         <td rowSpan={arrIndexDepartments[index]}>
                      //           {rs.clinicalDepartmentName}
                      //         </td>
                      //       ) : null}
                      //       {rs.startDate ? (
                      //         <td className='even-line'>
                      //           {`${renderDate(rs.startDate, rs.endDate)}`}
                      //           <br />
                      //           {`${renderAvailableDay(
                      //             rs.isAvailableOnMonday,
                      //             rs.isAvailableOnTuesday,
                      //             rs.isAvailableOnWednesday,
                      //             rs.isAvailableOnThursday,
                      //             rs.isAvailableOnFriday,
                      //             rs.isAvailableOnSaturday,
                      //             rs.isAvailableOnSunday,
                      //             rs.isAvailableOnHoliday
                      //           )}・${renderTime(rs.startTime, rs.endTime)}`}
                      //         </td>
                      //       ) : (
                      //         <td className='even-line' />
                      //       )}
                      //       <td className='even-line'>
                      //         {rs.hourlyWageDefault
                      //           ? numeralCurrency(rs.hourlyWageDefault)
                      //           : null}
                      //       </td>
                      //       <td className='even-line'>
                      //         {renderClassify(rs.classify)}
                      //       </td>
                      //       {isUpdatePermitted && isDeletePermitted ? (
                      //         rs.startDate ? (
                      //           <td className='center even-line'>
                      //             <ModalLink
                      //               to={Router.get(
                      //                 Router.staffHourlyWageDetail,
                      //                 {
                      //                   recruitmentShiftId: rs.id,
                      //                 }
                      //               )}
                      //             >
                      //               <Button icon='pi pi-info' />
                      //             </ModalLink>
                      //           </td>
                      //         ) : (
                      //           <td className='center even-line' />
                      //         )
                      //       ) : null}

                      //       {isCreatePermitted && isDeletePermitted ? (
                      //         rs.startDate ? (
                      //           <td className='center even-line'>
                      //             <ModalLink
                      //               to={Router.get(
                      //                 Router.staffHourlyWageDuplicate,
                      //                 {
                      //                   recruitmentShiftId: rs.id,
                      //                 }
                      //               )}
                      //             >
                      //               <Button icon='pi pi-pencil' />
                      //             </ModalLink>
                      //           </td>
                      //         ) : (
                      //           <td className='center even-line' />
                      //         )
                      //       ) : null}
                      //     </>
                      //   </tr>
                      // )
                    )
                : null}
            </tbody>
          </table>
          <Paginator
            rows={rowsPerPage}
            totalRecords={count}
            first={(page - 1) * rowsPerPage}
            onPageChange={onPageChange}
            leftContent={
              <div className='paginator-total'>
                {i18n.t('main.paginationTotal')}: {count}
              </div>
            }
          />
        </div>
      </div>

      {isShowDialogDelete && (
        <Modal
          visible
          closable={false}
          footer={
            <div style={{ display: 'flex', gap: '8px', justifyContent: 'end' }}>
              <Button
                onClick={() => setIsShowDialogDelete(false)}
                label='いいえ'
              />
              <Button
                onClick={hanleDeleteMultiHourlyWageSchedule}
                label='はい'
                type='primary'
                style={{
                  backgroundColor: '#f03a6c',
                  border: 'none',
                  color: '#FFFFFF',
                }}
              />
            </div>
          }
          wrapStyle={{
            top: '0px',
            right: 184,
            overflow: 'initial',
            left: 'auto',
          }}
        >
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <ExclamationCircleFilled style={{ color: '#faad14' }} />
            {`本当に${rowsSelect.length}件分を削除しますか？`}
          </div>
        </Modal>
      )}
    </React.Fragment>
  )
}

export default ClinicsHourlyWageScene
