import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import { CLINIC_FRAGMENT } from '@medical/pages/staff/Clinics/ClinicsList/ClinicsList.graphql'
import gql from 'graphql-tag'

export const CLINIC_QUERY = gql`
  query CLINIC($clinicId: ID) {
    clinic(where: { id: $clinicId }) {
      ...CLINIC_FRAGMENT
      imageKey
    }
    ...DEPARTMENTS_FRAGMENT
  }
  ${CLINIC_FRAGMENT}
  ${DEPARTMENTS_FRAGMENT}
`

export const UPDATE_CLINIC_IMAGE = gql`
  mutation UPDATE_CLINIC_IMAGE($clinicId: ID!, $imageKey: String!) {
    staffUpdateClinicImage(clinicId: $clinicId, imageKey: $imageKey) {
      id
      imageKey
    }
  }
`

export const UPDATE_CLINIC = gql`
  mutation UPDATE_CLINIC(
    $clinicId: ID!
    $name: String
    $nameKana: String
    $email: String
    $phoneNumber: String
    $inquiryPhoneNumber: String
    $address: String
    $departmentNames: [DepartmentName!]!
    $departmentDisplays: [DepartmentName!]
    $isUpdateAddress: Boolean
    $region: String
  ) {
    staffUpdateClinic(
      clinicId: $clinicId
      name: $name
      nameKana: $nameKana
      email: $email
      phoneNumber: $phoneNumber
      inquiryPhoneNumber: $inquiryPhoneNumber
      address: $address
      departmentNames: $departmentNames
      departmentDisplays: $departmentDisplays
      isUpdateAddress: $isUpdateAddress
      region: $region
    ) {
      ...CLINIC_FRAGMENT
    }
  }
  ${CLINIC_FRAGMENT}
`
export const STAFF_UPDATE_CLINIC_SCHEDULE_WORK_TIME = gql`
  mutation STAFF_UPDATE_CLINIC_SCHEDULE_WORK_TIME(
    $clinicId: ID!
    $clinicalSchedules: [ClinicalScheduleForUpdate!]!
  ) {
    staffUpdateClinicScheduleWorkTime(
      clinicId: $clinicId
      clinicalSchedules: $clinicalSchedules
    ) {
      id
    }
  }
`
export const GET_CLINIC_SCHEDULE = gql`
  query Query(
    $clinicalSchedulesWhere: ClinicalScheduleWhereInput
    $where: ClinicWhereUniqueInput!
  ) {
    clinic(where: $where) {
      id
      clinicalDepartments {
        clinicalSchedules(where: $clinicalSchedulesWhere) {
          id
          startDate
          endDate
          startTimeMorning
          endTimeMorning
          startTimeAfternoon
          endTimeAfternoon
          isAvailableMorning
          isAvailableAfternoon
          deletedAt
        }
      }
    }
  }
`

export const GET_LIST_CLINICAL_DEPARTMENT = gql`
  query ClinicalDepartments($where: ClinicalDepartmentWhereInput) {
    clinicalDepartments(where: $where) {
      name
      departmentCode
      clinic {
        id
        name
        nameKana
        region
      }
      startTimeMondayMorning
      endTimeMondayMorning
      startTimeMondayAfternoon
      endTimeMondayAfternoon
      startTimeTuesdayMorning
      endTimeTuesdayMorning
      startTimeTuesdayAfternoon
      endTimeTuesdayAfternoon
      startTimeWednesdayMorning
      endTimeWednesdayMorning
      startTimeWednesdayAfternoon
      endTimeWednesdayAfternoon
      startTimeThursdayMorning
      endTimeThursdayMorning
      startTimeThursdayAfternoon
      endTimeThursdayAfternoon
      startTimeFridayMorning
      endTimeFridayMorning
      startTimeFridayAfternoon
      endTimeFridayAfternoon
      startTimeSaturdayMorning
      endTimeSaturdayMorning
      startTimeSaturdayAfternoon
      endTimeSaturdayAfternoon
      startTimeSundayMorning
      endTimeSundayMorning
      startTimeSundayAfternoon
      endTimeSundayAfternoon
      startTimeHolidayMorning
      endTimeHolidayMorning
      startTimeHolidayAfternoon
      endTimeHolidayAfternoon

      isAvailableOnMondayMorning
      isAvailableOnMondayAfternoon
      isAvailableOnTuesdayMorning
      isAvailableOnTuesdayAfternoon
      isAvailableOnWednesdayMorning
      isAvailableOnWednesdayAfternoon
      isAvailableOnThursdayMorning
      isAvailableOnThursdayAfternoon
      isAvailableOnFridayMorning
      isAvailableOnFridayAfternoon
      isAvailableOnSaturdayMorning
      isAvailableOnSaturdayAfternoon
      isAvailableOnSundayMorning
      isAvailableOnSundayAfternoon
      isAvailableOnHolidayMorning
      isAvailableOnHolidayAfternoon
    }
  }
`
export const REGION_QUERY = gql`
  query regions($orderBy: RegionOrderByInput) {
    regions(orderBy: $orderBy) {
      name
      id
      position
    }
  }
`
