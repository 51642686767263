import gql from 'graphql-tag'

export const CLINICS_DAILY_OPEN_TIME = gql`
  query CLINIC_TIME_SHEET(
    $clinicalDepartmentId: ID
    $date: DateTime!
    $isHoliday: Boolean!
  ) {
    clinicTimeSheet(
      clinicalDepartmentId: $clinicalDepartmentId
      date: $date
      isHoliday: $isHoliday
    ) {
      clinicalDepartmentId
      startTimeMorning
      endTimeMorning
      isAvailableMorning
      startTimeAfternoon
      endTimeAfternoon
      isAvailableAfternoon
    }
  }
`
