import ConfirmationModal from '@medical/components/ConfirmationModal'
import TextInput from '@medical/components/TextInput'
import { KATAKANA_REG_EXP } from '@medical/constant'
import { Formik } from 'formik'
import { Button } from 'primereact/button'
import React from 'react'
import * as Yup from 'yup'

const BankAccountForm = ({
  i18n,
  bankAccount,
  i18nKey = 'doctor.bankAccount',
  onSubmit,
  confirmationMessage,
  disabled,
}) => {
  const validationSchema = Yup.object({
    bankName: Yup.string()
      .required(
        i18n.t('doctor.bankAccount.bankName') + i18n.t('validation.required')
      )
      .max(20, i18n.t('validation.title.max', { max: 20 })),
    bankCode: Yup.string()
      .nullable()
      .required(
        i18n.t('doctor.bankAccount.bankCode') + i18n.t('validation.required')
      )
      .max(4, i18n.t('validation.title.max', { max: 4 })),
    branchName: Yup.string()
      .required(
        i18n.t('doctor.bankAccount.branchName') + i18n.t('validation.required')
      )
      .max(20, i18n.t('validation.title.max', { max: 20 })),
    branchCode: Yup.string()
      .nullable()
      .required(
        i18n.t('doctor.bankAccount.branchCode') + i18n.t('validation.required')
      )
      .max(3, i18n.t('validation.title.max', { max: 3 })),
    accountType: Yup.string()
      .required(
        i18n.t('doctor.bankAccount.accountType') + i18n.t('validation.required')
      )
      .max(10, i18n.t('validation.title.max', { max: 10 })),
    accountNumber: Yup.string()
      .required(
        i18n.t('doctor.bankAccount.accountNumber') +
          i18n.t('validation.required')
      )
      .max(8, i18n.t('validation.title.max', { max: 8 })),
    accountHolderName: Yup.string()
      .matches(KATAKANA_REG_EXP, i18n.t('validation.bankKatakanaOnly'))
      .required(
        i18n.t('doctor.bankAccount.accountHolderName') +
          i18n.t('validation.required')
      )
      .max(48, i18n.t('validation.title.max', { max: 48 })),
  })

  return (
    <Formik
      initialValues={{
        confirm: false,
        bankName: bankAccount.bankName,
        bankCode: bankAccount.bankCode,
        branchName: bankAccount.branchName,
        branchCode: bankAccount.branchCode,
        accountType: bankAccount.accountType,
        accountNumber: bankAccount.accountNumber,
        accountHolderName: bankAccount.accountHolderName,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={formikProps => {
        const { setFieldValue, setTouched, validateForm } = formikProps
        const props = { ...formikProps, i18nKey }
        return (
          <React.Fragment>
            <TextInput
              {...props}
              name='bankName'
              disabled={disabled}
              label={i18n.t('doctor.bankAccount.bankName')}
              required
            />
            <TextInput
              {...props}
              name='bankCode'
              disabled={disabled}
              label={i18n.t('doctor.bankAccount.bankCode')}
              required
            />
            <TextInput
              {...props}
              name='branchName'
              disabled={disabled}
              label={i18n.t('doctor.bankAccount.branchName')}
              required
            />
            <TextInput
              {...props}
              name='branchCode'
              disabled={disabled}
              label={i18n.t('doctor.bankAccount.branchCode')}
              required
            />
            <TextInput
              {...props}
              name='accountType'
              disabled={disabled}
              label={i18n.t('doctor.bankAccount.accountType')}
              required
            />
            <TextInput
              {...props}
              name='accountNumber'
              disabled={disabled}
              label={i18n.t('doctor.bankAccount.accountNumber')}
              required
            />
            <TextInput
              {...props}
              name='accountHolderName'
              disabled={disabled}
              label={i18n.t('doctor.bankAccount.accountHolderName')}
              required
            />
            {!disabled && (
              <div className='modal-bottom'>
                <Button
                  onClick={() => {
                    validateForm().then(response => {
                      if (!Object.keys(response).length) {
                        setFieldValue('confirm', true)
                      } else {
                        setTouched(response)
                      }
                    })
                  }}
                  label={i18n.t('doctor.bankAccount.submit')}
                />
              </div>
            )}
            <ConfirmationModal
              {...formikProps}
              confirmationMessage={confirmationMessage}
            />
          </React.Fragment>
        )
      }}
    />
  )
}

export default BankAccountForm
