export default {
    header: {
        marginBottom: '20px',
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 100,
    }

    ,
    headers: {
        position: 'absolute',
        marginBottom: '80px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '15px',
        background: 'linear-gradient(transparent 90%, #ffebeb 50%)',
    }

    ,
    dates: {
        position: 'absolute',
        top: '0',
        right: '0',
        transform: 'translateX(-30px)',
        zIndex: 1,
    }

    ,
    title: {
        lineHeight: '33px',
        fontSize: '28px',
        padding: '0 50px',
    }

    ,
    cell: {
        fontSize: '12px',
        height: '30px',
        display: 'flex',
        whiteSpace: 'nowrap',
        flexDirection: 'column',
        justifyContent: 'center',
    }

    ,
}