import { useApolloClient } from '@apollo/react-hooks'
import { STAFF_ME } from '@medical/layout/StaffLayout/StaffLayout.graphql'

export const checkStaffPermission = ({ functionName }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const client = useApolloClient()
  const { staffMe } = client.readQuery({
    query: STAFF_ME,
  })
  const {
    staffGroup: { permissions },
  } = staffMe
  const permission = permissions.find(
    ({ api: { name } }) => name === functionName
  )
  return (
    permission && permission.deletedAt === null && permission.action === 'ALL'
  )
}
