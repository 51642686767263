import BreadCrumb from '@medical/components/Breadcrumb'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import Router from '@medical/routes/router'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Paginator } from 'primereact/paginator'
import React from 'react'
import { Link } from 'react-router-dom'

const AnnouncesListScene = ({
  i18n,
  edges,
  rowsPerPage,
  page,
  count,
  onPageChange,
  onSubmit,
  isCreatePermitted,
  isDeletePermitted,
  isUpdatePermitted,
}) => (
  <React.Fragment>
    <div className='staff-header'>
      <div className='staff-title'>
        {i18n.t('staff.announces.title')}
        <BreadCrumb items={[{ title: i18n.t('staff.menuBar.announces') }]} />
      </div>
      {isCreatePermitted ? (
        <div className='staff-buttons'>
          <Link to={Router.staffAnnounceCreate}>
            <Button
              label={i18n.t('staff.createAnnounce.submit')}
              icon='pi pi-plus'
              className='p-button-normal'
            />
          </Link>
        </div>
      ) : null}
    </div>
    <div className='container'>
      <div className='box'>
        <Formik
          initialValues={{ confirm: false, announceId: '' }}
          onSubmit={onSubmit}
          render={formikProps => (
            <div>
              <table className='table' style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th width='70%'>{i18n.t('staff.announces.titleLabel')}</th>
                    <th width='20%'>{i18n.t('staff.announces.createdAt')}</th>
                    {isUpdatePermitted || isDeletePermitted ? (
                      <th width='10%'>{i18n.t('main.action')}</th>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {edges.map(({ node }) => (
                    <tr key={node.id}>
                      <td>{node.title}</td>
                      <td className='center'>
                        {moment(node.createdAt).format('LL (dd)')}
                      </td>
                      {isUpdatePermitted || isDeletePermitted ? (
                        <td>
                          <div className='action'>
                            {isUpdatePermitted ? (
                              <Link
                                to={Router.get(Router.staffAnnounceUpdate, {
                                  announceId: node.id,
                                })}
                              >
                                <Button icon='pi pi-pencil' />
                              </Link>
                            ) : null}
                            {isDeletePermitted ? (
                              <Button
                                onClick={() => {
                                  formikProps.setFieldValue(
                                    'announceId',
                                    node.id
                                  )
                                  formikProps.setFieldValue('confirm', true)
                                }}
                                icon='pi pi-trash'
                              />
                            ) : null}
                          </div>
                        </td>
                      ) : null}
                    </tr>
                  ))}
                </tbody>
              </table>
              <ConfirmationModal
                {...formikProps}
                confirmationMessage={i18n.t(
                  'staff.deleteAnnounce.submitConfirmation'
                )}
              />
            </div>
          )}
        />
        <Paginator
          rows={rowsPerPage}
          totalRecords={count}
          first={(page - 1) * rowsPerPage}
          onPageChange={onPageChange}
          leftContent={
            <div className='paginator-total'>
              {i18n.t('main.paginationTotal')}: {count}
            </div>
          }
        />
      </div>
    </div>
  </React.Fragment>
)

export default AnnouncesListScene
