import { splitLogic } from '@medical/libs'
import moment from 'moment'

const renderAvailableDay = (
  isAvailableOnMonday,
  isAvailableOnTuesday,
  isAvailableOnWednesday,
  isAvailableOnThursday,
  isAvailableOnFriday,
  isAvailableOnSaturday,
  isAvailableOnSunday,
  isAvailableOnHoliday
) => {
  let availableDay = ''
  if (
    !isAvailableOnMonday ||
    !isAvailableOnTuesday ||
    !isAvailableOnWednesday ||
    !isAvailableOnThursday ||
    !isAvailableOnFriday
  ) {
    if (isAvailableOnMonday) availableDay += '月'
    if (isAvailableOnTuesday) availableDay += '火'
    if (isAvailableOnWednesday) availableDay += '水'
    if (isAvailableOnThursday) availableDay += '木'
    if (isAvailableOnFriday) availableDay += '金'
    if (isAvailableOnSaturday) availableDay += '土'
    if (isAvailableOnSunday) availableDay += '日'
    if (isAvailableOnHoliday) availableDay += '祝'
  } else {
    availableDay = '平日'
    if (isAvailableOnSaturday) availableDay += '土'
    if (isAvailableOnSunday) availableDay += '日'
    if (isAvailableOnHoliday) availableDay += '祝'
  }
  return availableDay
}

const headerMail = fullName => `${fullName}先生

お世話になっております。
CAPSクリニック ドクターサポート事務局です。
定期確定シフトの内容を変更しましたのでお知らせします。`

export const LinkMail = () => {
  let url
  if (process.env.REACT_APP_ENV === 'development')
    url = 'http://localhost:3000/doctor/login'
  else if (process.env.REACT_APP_ENV === 'staging')
    url = 'https://staging.capsdoctor365.jp/doctor/login'
  else url = 'https://doctor.capsdoctor365.jp/doctor/login'
  return `ご勤務予定については、
CAPSドクター365 (<a href=${url}>${url}</a>) よりご確認ください。`
}

const contentMail = (
  clinicName,
  departmentName,
  submitDate,
  startTime,
  splits,
  startEndDate,
  isAvailableOnMonday,
  isAvailableOnTuesday,
  isAvailableOnWednesday,
  isAvailableOnThursday,
  isAvailableOnFriday,
  isAvailableOnSaturday,
  isAvailableOnSunday,
  isAvailableOnHoliday
) => {
  const {
    endTime,
    splitDateTime1,
    splitDateTime2,
    splitDateTime3,
  } = splitLogic({ submitDate, splits })
  const clinicalDepartmentName = `${clinicName}_${departmentName}`
  const startDate = startEndDate[0]
    ? moment.utc(startEndDate[0]).format('YYYY年MM月DD日')
    : ''
  const endDate = startEndDate[1]
    ? moment.utc(startEndDate[1]).format('YYYY年MM月DD日')
    : ''
  let startEndDateString = ''
  if (startEndDate[0] && startEndDate[1])
    startEndDateString = `${startDate}〜${endDate}`
  if (splitDateTime1 && splitDateTime2 && splitDateTime3)
    return `クリニック名：　「${clinicalDepartmentName}」
期間：　${startEndDateString}
時間：　${moment(startTime).format('HH:mm')}〜${moment(splitDateTime1).format(
      'HH:mm'
    )}, ${moment(splitDateTime1).format('HH:mm')}〜${moment(
      splitDateTime2
    ).format('HH:mm')}, ${moment(splitDateTime2).format('HH:mm')}〜${moment(
      splitDateTime3
    ).format('HH:mm')}, ${moment(splitDateTime3).format('HH:mm')}〜${moment(
      endTime
    ).format('HH:mm')}
曜日： ${renderAvailableDay(
      isAvailableOnMonday,
      isAvailableOnTuesday,
      isAvailableOnWednesday,
      isAvailableOnThursday,
      isAvailableOnFriday,
      isAvailableOnSaturday,
      isAvailableOnSunday,
      isAvailableOnHoliday
    )}`
  if (splitDateTime2 && splitDateTime1)
    return `クリニック名：　「${clinicalDepartmentName}」
期間：　${startEndDateString}
時間：　${moment(startTime).format('HH:mm')}〜${moment(splitDateTime1).format(
      'HH:mm'
    )}, ${moment(splitDateTime1).format('HH:mm')}〜${moment(
      splitDateTime2
    ).format('HH:mm')}, ${moment(splitDateTime2).format('HH:mm')}〜${moment(
      endTime
    ).format('HH:mm')}
曜日： ${renderAvailableDay(
      isAvailableOnMonday,
      isAvailableOnTuesday,
      isAvailableOnWednesday,
      isAvailableOnThursday,
      isAvailableOnFriday,
      isAvailableOnSaturday,
      isAvailableOnSunday,
      isAvailableOnHoliday
    )}`
  if (splitDateTime1)
    return `クリニック名：　「${clinicalDepartmentName}」
期間：　${startEndDateString}
時間：　${moment(startTime).format('HH:mm')}〜${moment(splitDateTime1).format(
      'HH:mm'
    )}, ${moment(splitDateTime1).format('HH:mm')}〜${moment(endTime).format(
      'HH:mm'
    )}
曜日： ${renderAvailableDay(
      isAvailableOnMonday,
      isAvailableOnTuesday,
      isAvailableOnWednesday,
      isAvailableOnThursday,
      isAvailableOnFriday,
      isAvailableOnSaturday,
      isAvailableOnSunday,
      isAvailableOnHoliday
    )}`

  return `クリニック名：　「${clinicalDepartmentName}」
期間：　${startEndDateString}
時間：　${moment(startTime).format('HH:mm')}〜${moment(endTime).format('HH:mm')}
曜日： ${renderAvailableDay(
    isAvailableOnMonday,
    isAvailableOnTuesday,
    isAvailableOnWednesday,
    isAvailableOnThursday,
    isAvailableOnFriday,
    isAvailableOnSaturday,
    isAvailableOnSunday,
    isAvailableOnHoliday
  )}`
}

export const getEmailTemplate = (
  fullName,
  clinicalDepartment,
  submitDate,
  startTime,
  splits,
  startEndDate,
  isAvailableOnMonday,
  isAvailableOnTuesday,
  isAvailableOnWednesday,
  isAvailableOnThursday,
  isAvailableOnFriday,
  isAvailableOnSaturday,
  isAvailableOnSunday,
  isAvailableOnHoliday
) => `${headerMail(fullName)}

${contentMail(
  clinicalDepartment,
  submitDate,
  startTime,
  splits,
  startEndDate,
  isAvailableOnMonday,
  isAvailableOnTuesday,
  isAvailableOnWednesday,
  isAvailableOnThursday,
  isAvailableOnFriday,
  isAvailableOnSaturday,
  isAvailableOnSunday,
  isAvailableOnHoliday
)}`
