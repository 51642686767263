import gql from 'graphql-tag'

export const CHECK_UPLOAD_DOCTORCSV = gql`
  mutation StaffCheckUploadDoctorCsvUpdateMutation(
    $doctorCsvRecordUpdate: [DoctorCsvRecordUpdate!]!
  ) {
    staffCheckUploadDoctorCsvUpdate(
      DoctorCsvRecordUpdate: $doctorCsvRecordUpdate
    ) {
      doctorNo
      email
      lastname
      firstname
      lastnameKana
      firstnameKana
      phoneNumber
      birthDay
      gender
      medicalLicenseNumber
      province
      placeOfWork
      workPattern
      medicalRecord
      orca
      questionnaire
      joinBackground
      joinBackgroundOther
      departments
      errorMessage
    }
  }
`
export const UPLOAD_DOCTOR_CSV = gql`
  mutation StaffUploadDoctorCsvUpdate(
    $doctorCsvRecordUpdate: [DoctorCsvRecordUpdate!]!
  ) {
    staffUploadDoctorCsvUpdate(DoctorCsvRecordUpdate: $doctorCsvRecordUpdate)
  }
`
