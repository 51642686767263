import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import React, { useEffect } from 'react'
import { onPageChange } from '@medical/libs'
import { WAITING_RECRUITMENT_QUERY } from './DoctorWaitingRecruitment.graphql'
import { DoctorWaitingRecruitmentScene } from './DoctorWaitingRecruitmentScene'
import queryString from 'query-string'
import moment from 'moment'
import {
  clinicalDepartmentNames,
  combineNames,
  sortAndFilterByClinicOrder,
} from '@medical/libs'

export const parseJwt = token => {
  let base64Url = token.split('.')[1]
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  let jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

export const DoctorWaitingRecruitment = ({ history, location: { search } }) => {
  const pageSize = 10
  let { clinicalDepartmentIds = [], dateFilter, page = 1 } = queryString.parse(
    search
  )
  const doctorId = parseJwt(localStorage.getItem('medical_doctor_token'))
    .doctorId

  const getWhereRefetch = () => {
    const where = {
      Doctor: {
        id: doctorId,
        deletedAt: null,
      },
      RecruitmentShift_some: {
        clinic_in: clinicalDepartmentIds,
      },
      startDate_lte: dateFilter
        ? new Date(
            moment(dateFilter)
              .endOf('day')
              .format('YYYY-MM-DD')
          )
        : new Date(
            moment(new Date())
              .endOf('day')
              .format('YYYY-MM-DD')
          ),
      endDate_gte: dateFilter
        ? new Date(
            moment(dateFilter)
              .startOf('day')
              .format('YYYY-MM-DD')
          )
        : new Date(
            moment(new Date())
              .startOf('day')
              .format('YYYY-MM-DD')
          ),
    }
    if (!dateFilter) {
      delete where.startDate_lte
      where.endDate_gte = new Date(
        moment(new Date())
          .endOf('day')
          .format('YYYY-MM-DD')
      )
    }
    if (clinicalDepartmentIds.length === 0) delete where.RecruitmentShift_some
    return where
  }

  const { loading, error, data, refetch } = useQuery(
    WAITING_RECRUITMENT_QUERY,
    {
      variables: {
        where: getWhereRefetch(),
        skip: (page - 1) * pageSize,
        first: pageSize,
        orderBy: 'updatedAt_DESC',
      },
    }
  )

  const getWaitingRecruitmentList = () => {
    const query = {
      clinicalDepartmentIds,
      dateFilter,
      page,
    }

    if (!dateFilter) delete query.dateFilter
    history.push({
      search: queryString.stringify({
        ...query,
      }),
    })

    refetch({
      where: getWhereRefetch(),
      skip: (page - 1) * pageSize,
      first: pageSize,
    })
  }
  const setClinicalDepartmentIds = ids => {
    clinicalDepartmentIds = ids.value.map(({ id }) => id)
    getWaitingRecruitmentList()
  }

  const setDateFilter = date => {
    dateFilter = date ? moment(date).format('YYYY-MM-DD') : null
    getWaitingRecruitmentList()
  }

  const setPage = value => {
    page = value
    getWaitingRecruitmentList()
  }

  const clearFilter = () => {
    clinicalDepartmentIds = []
    dateFilter = null
    getWaitingRecruitmentList()
  }
  useEffect(() => {
    refetch()
  })
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  return (
    <DoctorWaitingRecruitmentScene
      waitingRecruitmentShiftList={data.waitingRecruitmentShiftsConnection.edges.map(
        e => ({ ...e.node })
      )}
      clinicalDepartments={combineNames({
        clinicalDepartments: sortAndFilterByClinicOrder({
          clinicalDepartments: data.clinicalDepartments,
          isFilter: true,
        }),
        departmentNames: clinicalDepartmentNames({
          departments: data.departments,
        }),
      })}
      clinicalDepartmentIds={clinicalDepartmentIds}
      dateFilter={dateFilter}
      setClinicalDepartmentIds={setClinicalDepartmentIds}
      setDateFilter={setDateFilter}
      pageSize={pageSize}
      page={page}
      count={data.waitingRecruitmentShiftsConnection.aggregate.count}
      onPageChange={onPageChange(history, search)}
      clearFilter={clearFilter}
    />
  )
}
