import moment from 'moment'

export const splitLogic = ({
  submitDate,
  splits,
  key,
  clinicalDepartmentId,
  date,
}) => {
  const formatSubmitTime = submitTime =>
    `${date ? date.format('YYYY-MM-DD') : '1970-10-10'}T${
      moment(submitTime)
        .second(0)
        .format()
        .split('T')[1]
        .split('+')[0]
    }`
  const args = {}
  if (splits.length > 1) {
    for (let i = 0; i < splits.length - 1; i += 1) {
      args[`splitDateTime${i + 1}`] = moment(submitDate)
        .hour(moment(splits[i].date).hour())
        .minute(moment(splits[i].date).minute())
      args[`splitHourlyWage${i + 1}`] = parseInt(splits[i].hourlyWage)
      args[`isSplitDateTime${i + 1}BreakTime`] = splits[i].isBreakTime
    }
  }
  if (splits[splits.length - 1]) {
    args.endTime = moment(submitDate)
      .hour(moment(splits[splits.length - 1].date).hour())
      .minute(moment(splits[splits.length - 1].date).minute())
      .second(0)
    args.hourlyWage = parseInt(splits[splits.length - 1].hourlyWage)
    args.isStartTimeBreakTime = splits[splits.length - 1].isBreakTime
    args.startTime = splits[0].startTime
  }

  if (key) {
    let day = ''
    switch (key) {
      case '1':
        day = 'mon'
        break
      case '1_Hol':
        day = 'mon_hol'
        break
      case '2':
        day = 'tue'
        break
      case '2_Hol':
        day = 'tue_hol'
        break
      case '3':
        day = 'wed'
        break
      case '3_Hol':
        day = 'wed_hol'
        break
      case '4':
        day = 'thu'
        break
      case '4_Hol':
        day = 'thu_hol'
        break
      case '5':
        day = 'fri'
        break
      case '5_Hol':
        day = 'fri_hol'
        break
      case '6':
        day = 'sat'
        break
      case '6_Hol':
        day = 'sat_hol'
        break
      case '7':
        day = 'sun'
        break
      case '7_Hol':
        day = 'sun_hol'
        break
      case '8':
        day = 'hol'
        break
      default:
        break
    }

    const split = {}
    args.endTime = formatSubmitTime(args.endTime)
    args.startTime = formatSubmitTime(args.startTime)

    args.clinicalDepartmentId =
      clinicalDepartmentId || splits[0].clinicalDepartmentId
    args.splitDateTime1 =
      (args.splitDateTime1 && formatSubmitTime(args.splitDateTime1)) || null
    args.splitDateTime2 =
      (args.splitDateTime2 && formatSubmitTime(args.splitDateTime2)) || null
    args.splitDateTime3 =
      (args.splitDateTime3 && formatSubmitTime(args.splitDateTime3)) || null
    split[day] = args
    return split
  }
  return args
}

export default splitLogic
