import MultipleDimensionCheckbox from '@medical/components/CheckBoxMultipleDimension'
import { TYPE_CREATE } from '@medical/constant'
import { setTimeSplitByDay } from '@medical/libs'
import { isHolidayInRangeDate } from '@medical/libs/isHolidayInRangeDate'
import { Checkbox } from 'antd'
import moment from 'moment'
import { Checkbox as CheckboxPrime } from 'primereact/checkbox'
import { Dropdown } from 'primereact/dropdown'
import React from 'react'

const ShiftGroupFormRepeatSetting = ({
  i18n,
  typeCreateOptions,
  sampleDataOfCheckbox,
  formikProps,
  holidays,
  isCreate,
  disabled,
  activeIndex,
  recruitmentScheduleShift,
  scheduleShiftGroup,
  isRecruitmentSchedule,
  dataSplits,
}) => {
  const { touched, errors, setFieldValue, values } = formikProps

  const removeTimeToDay = (day, isWeekday) => {
    if (isWeekday) {
      Object.keys(values.splits)
        .filter(key => !['7', '6', '8', '7_Hol', '6_Hol'].includes(key))
        .forEach(key => delete values.splits[key])
    } else {
      delete values.splits[day]
    }
  }
  const showSplits = (startTime, endTime) => {
    values.splits = isHolidayInRangeDate({
      startDate: startTime,
      endDate: endTime,
      checkWeekDay: false,
    })
    if (holidays.length > 0) {
      setFieldValue('splits', {
        ...values.splits,
        ...setTimeSplitByDay('8', 'isAvailableOnHoliday'),
      })
    }
  }
  const checkIsTime = startTime => {
    const date = moment(startTime)
    const dayNo = moment(date).day()

    holidays.length > 0
      ? setFieldValue('splits', setTimeSplitByDay('8', 'isAvailableOnHoliday'))
      : setFieldValue('splits', setTimeSplitByDay(`${dayNo}`))
  }
  return (
    <>
      <div className='tr'>
        <div className='th'>
          {i18n.t('staff.scheduledShiftGroup.repeatUpdate')}
        </div>
        <div className='td'>
          <Dropdown
            disabled={disabled}
            value={
              activeIndex === 1
                ? recruitmentScheduleShift?.repeatSetting ||
                  scheduleShiftGroup?.repeatSetting
                : values.repeatSetting
            }
            options={typeCreateOptions}
            onChange={e => {
              if (e.value === TYPE_CREATE.DAILY) {
                setFieldValue('isHolidayRepeat', undefined)
                moment(values.startEndDate[0]).isSame(
                  moment(values.startEndDate[1])
                )
                  ? checkIsTime(values.startEndDate[0])
                  : showSplits(values.startEndDate[0], values.startEndDate[1])
              }
              if (e.value === TYPE_CREATE.WEEKLY) {
                setFieldValue('isAllday', false)
                setFieldValue('isMonday', false)
                setFieldValue('isTuesday', false)
                setFieldValue('isWednesday', false)
                setFieldValue('isThursday', false)
                setFieldValue('isFriday', false)
                setFieldValue('isSaturday', false)
                setFieldValue('isSunday', false)
                setFieldValue('isDayoff', false)
                setFieldValue('splits', {})
               
                if ( scheduleShiftGroup === TYPE_CREATE.DAILY) {
                   setFieldValue('splits', dataSplits)
                }
                setFieldValue('isHolidayRepeat', undefined)
              }
              if (e.value === TYPE_CREATE.MONTHLY ) {
                setFieldValue('splits', dataSplits)
                setFieldValue(
                  'splits',
                  setTimeSplitByDay('8', 'isAvailableOnHoliday')
                )
              }
              setFieldValue('repeatSetting', e.value)
            }}
            placeholder={i18n.t(
              'staff.scheduledShiftGroup.placeHolderOfTypeCreate'
            )}
          />
          <div className='alert'>
            {touched.repeatSetting && errors.repeatSetting}
          </div>
        </div>
      </div>
      {/* // eslint-disable-next-line no-nested-ternary */}
      {values.repeatSetting === TYPE_CREATE.WEEKLY ? (
        // use checkbox of PrimeReact
        <div className='tr'>
          <div className='th'>{i18n.t('staff.createHourlyWage.dayOfWeek')}</div>
          <div className='td' style={{ width: '100%' }}>
            <div className='checkbox-container'>
              <div className='checkbox-item'>
                <Checkbox
                  disabled={disabled}
                  name='isAllday'
                  checked={
                    activeIndex
                      ? recruitmentScheduleShift?.isAllday ||
                        scheduleShiftGroup?.isAllday
                      : values.isAllday
                  }
                  onChange={e => {
                    if (e.target.checked) {
                      setFieldValue('isAllday', true)
                      setFieldValue('isMonday', true)
                      setFieldValue('isTuesday', true)
                      setFieldValue('isWednesday', true)
                      setFieldValue('isThursday', true)
                      setFieldValue('isFriday', true)

                      if (!isRecruitmentSchedule) {
                        {
                          values.listDaySelected.push(
                            '1_Hol',
                            '2_Hol',
                            '3_Hol',
                            '4_Hol',
                            '5_Hol'
                          )
                        }
                      }
                      if (values.isDayOnWeekday) {
                        let dataHoli = {}
                        values.listDaySelected.forEach(item => {
                          dataHoli = {
                            ...dataHoli,
                            ...setTimeSplitByDay(item, 'isAvailableOnHoliday'),
                          }
                        })
                        setFieldValue('splits', {
                          ...values.splits,
                          ...isHolidayInRangeDate({
                            startDate: values.startEndDate[0],
                            endDate: values.startEndDate[1],
                            checkWeekDay: true,
                          }),
                          ...dataHoli,
                        })
                      } else {
                        setFieldValue('splits', {
                          ...values.splits,
                          ...isHolidayInRangeDate({
                            startDate: values.startEndDate[0],
                            endDate: values.startEndDate[1],
                            checkWeekDay: true,
                          }),
                        })
                      }
                    } else {
                      setFieldValue('isAllday', false)
                      setFieldValue('isMonday', false)
                      setFieldValue('isTuesday', false)
                      setFieldValue('isWednesday', false)
                      setFieldValue('isThursday', false)
                      setFieldValue('isFriday', false)
                      removeTimeToDay(null, true)
                    }
                  }}
                />
                <div className='checkbox-title'>
                  {i18n.t('staff.createHourlyWage.allDay')}
                </div>
              </div>
              <div className='checkbox-item'>
                <Checkbox
                  disabled={disabled}
                  name='isMonday'
                  checked={
                    activeIndex
                      ? recruitmentScheduleShift?.isAvailableOnMonday ||
                        scheduleShiftGroup?.isAvailableOnMonday
                      : values.isMonday
                  }
                  onChange={e => {
                    if (!e.target.checked) {
                      setFieldValue('isAllday', false)
                      setFieldValue('isMonday', false)
                      removeTimeToDay('1')
                      if (values.isDayOnWeekday) {
                        removeTimeToDay('1_Hol')
                      }
                      if (!isRecruitmentSchedule) {
                        setFieldValue(
                          'listDaySelected',
                          values.listDaySelected.filter(
                            item => item !== '1_Hol'
                          )
                        )
                      }
                    } else if (
                      values.isTuesday &&
                      values.isWednesday &&
                      values.isThursday &&
                      values.isFriday
                    ) {
                      setFieldValue('isAllday', true)
                      setFieldValue('isMonday', true)
                      if (!isRecruitmentSchedule) {
                        values.listDaySelected.push('1_Hol')
                      }
                      values.isDayOnWeekday
                        ? setFieldValue('splits', {
                            ...values.splits,
                            ...setTimeSplitByDay(
                              '1_Hol',
                              'isAvailableOnHoliday'
                            ),
                            ...setTimeSplitByDay('1', 'isAvailableOnMonday'),
                          })
                        : setFieldValue('splits', {
                            ...values.splits,
                            ...setTimeSplitByDay('1', 'isAvailableOnMonday'),
                          })
                    } else {
                      setFieldValue('isMonday', e.target.checked)
                      values.isDayOnWeekday
                        ? setFieldValue('splits', {
                            ...values.splits,
                            ...setTimeSplitByDay(
                              '1_Hol',
                              'isAvailableOnHoliday'
                            ),
                            ...setTimeSplitByDay('1', 'isAvailableOnMonday'),
                          })
                        : setFieldValue('splits', {
                            ...values.splits,
                            ...setTimeSplitByDay('1', 'isAvailableOnMonday'),
                          })
                      if (!isRecruitmentSchedule) {
                        values.listDaySelected.push('1_Hol')
                      }
                    }
                  }}
                />
                <div className='checkbox-title'>
                  {i18n.t('staff.createHourlyWage.monDay')}
                </div>
              </div>
              <div className='checkbox-item'>
                <Checkbox
                  disabled={disabled}
                  name='isTuesday'
                  checked={
                    activeIndex
                      ? recruitmentScheduleShift?.isAvailableOnTuesday ||
                        scheduleShiftGroup?.isAvailableOnTuesday
                      : values.isTuesday
                  }
                  onChange={e => {
                    if (!e.target.checked) {
                      setFieldValue('isAllday', false)
                      setFieldValue('isTuesday', false)
                      if (!isRecruitmentSchedule) {
                        setFieldValue(
                          'listDaySelected',
                          values.listDaySelected.filter(
                            item => item !== '2_Hol'
                          )
                        )
                      }
                      removeTimeToDay('2')
                      if (values.isDayOnWeekday) {
                        removeTimeToDay('2_Hol')
                      }
                    } else if (
                      values.isMonday &&
                      values.isWednesday &&
                      values.isThursday &&
                      values.isFriday
                    ) {
                      setFieldValue('isAllday', true)
                      setFieldValue('isTuesday', true)
                      if (!isRecruitmentSchedule) {
                        values.listDaySelected.push('2_Hol')
                      }
                      values.isDayOnWeekday
                        ? setFieldValue('splits', {
                            ...values.splits,
                            ...setTimeSplitByDay(
                              '2_Hol',
                              'isAvailableOnHoliday'
                            ),
                            ...setTimeSplitByDay('2', 'isAvailableOnTuesday'),
                          })
                        : setFieldValue('splits', {
                            ...values.splits,
                            ...setTimeSplitByDay('2', 'isAvailableOnTuesday'),
                          })
                    } else {
                      setFieldValue('isTuesday', e.target.checked)
                      if (!isRecruitmentSchedule) {
                        values.listDaySelected.push('2_Hol')
                      }
                      values.isDayOnWeekday
                        ? setFieldValue('splits', {
                            ...values.splits,
                            ...setTimeSplitByDay(
                              '2_Hol',
                              'isAvailableOnHoliday'
                            ),
                            ...setTimeSplitByDay('2', 'isAvailableOnTuesday'),
                          })
                        : setFieldValue('splits', {
                            ...values.splits,
                            ...setTimeSplitByDay('2', 'isAvailableOnTuesday'),
                          })
                    }
                  }}
                />
                <div className='checkbox-title'>
                  {i18n.t('staff.createHourlyWage.tuesDay')}
                </div>
              </div>
              <div className='checkbox-item'>
                <Checkbox
                  disabled={disabled}
                  name='isWednesday'
                  checked={
                    activeIndex
                      ? recruitmentScheduleShift?.isAvailableOnWednesday ||
                        scheduleShiftGroup?.isAvailableOnWednesday
                      : values.isWednesday
                  }
                  onChange={e => {
                    if (!e.target.checked) {
                      setFieldValue('isAllday', false)
                      setFieldValue('isWednesday', false)
                      removeTimeToDay('3')
                      if (values.isDayOnWeekday) {
                        removeTimeToDay('3_Hol')
                      }
                      if (!isRecruitmentSchedule) {
                        setFieldValue(
                          'listDaySelected',
                          values.listDaySelected.filter(
                            item => item !== '3_Hol'
                          )
                        )
                      }
                    } else if (
                      values.isMonday &&
                      values.isTuesday &&
                      values.isThursday &&
                      values.isFriday
                    ) {
                      setFieldValue('isAllday', true)
                      setFieldValue('isWednesday', true)
                      if (!isRecruitmentSchedule) {
                        values.listDaySelected.push('3_Hol')
                      }
                      values.isDayOnWeekday
                        ? setFieldValue('splits', {
                            ...values.splits,
                            ...setTimeSplitByDay(
                              '3_Hol',
                              'isAvailableOnHoliday'
                            ),
                            ...setTimeSplitByDay('3', 'isAvailableOnWednesday'),
                          })
                        : setFieldValue('splits', {
                            ...values.splits,
                            ...setTimeSplitByDay('3', 'isAvailableOnWednesday'),
                          })
                    } else {
                      if (!isRecruitmentSchedule) {
                        values.listDaySelected.push('3_Hol')
                      }
                      values.isDayOnWeekday
                        ? setFieldValue('splits', {
                            ...values.splits,
                            ...setTimeSplitByDay(
                              '3_Hol',
                              'isAvailableOnHoliday'
                            ),
                            ...setTimeSplitByDay('3', 'isAvailableOnWednesday'),
                          })
                        : setFieldValue('splits', {
                            ...values.splits,
                            ...setTimeSplitByDay('3', 'isAvailableOnWednesday'),
                          })
                      setFieldValue('isWednesday', e.target.checked)
                    }
                  }}
                />
                <div className='checkbox-title'>
                  {i18n.t('staff.createHourlyWage.wednesDay')}
                </div>
              </div>
              <div className='checkbox-item'>
                <Checkbox
                  disabled={disabled}
                  name='isThursday'
                  checked={
                    activeIndex
                      ? recruitmentScheduleShift?.isAvailableOnThursday ||
                        scheduleShiftGroup?.isAvailableOnThursday
                      : values.isThursday
                  }
                  onChange={e => {
                    if (!e.target.checked) {
                      setFieldValue('isAllday', false)
                      setFieldValue('isThursday', false)
                      if (!isRecruitmentSchedule) {
                        setFieldValue(
                          'listDaySelected',
                          values.listDaySelected.filter(
                            item => item !== '4_Hol'
                          )
                        )
                      }
                      if (values.isDayOnWeekday) {
                        removeTimeToDay('4_Hol')
                      }
                      removeTimeToDay('4')
                    } else if (
                      values.isMonday &&
                      values.isTuesday &&
                      values.isWednesday &&
                      values.isFriday
                    ) {
                      setFieldValue('isAllday', true)
                      setFieldValue('isThursday', true)
                      if (!isRecruitmentSchedule) {
                        values.listDaySelected.push('4_Hol')
                      }
                      values.isDayOnWeekday
                        ? setFieldValue('splits', {
                            ...values.splits,
                            ...setTimeSplitByDay(
                              '4_Hol',
                              'isAvailableOnHoliday'
                            ),
                            ...setTimeSplitByDay('4', 'isAvailableOnThursday'),
                          })
                        : setFieldValue('splits', {
                            ...values.splits,
                            ...setTimeSplitByDay('4', 'isAvailableOnThursday'),
                          })
                    } else {
                      setFieldValue('isThursday', e.target.checked)
                      if (!isRecruitmentSchedule) {
                        values.listDaySelected.push('4_Hol')
                      }
                      values.isDayOnWeekday
                        ? setFieldValue('splits', {
                            ...values.splits,
                            ...setTimeSplitByDay(
                              '4_Hol',
                              'isAvailableOnHoliday'
                            ),
                            ...setTimeSplitByDay('4', 'isAvailableOnThursday'),
                          })
                        : setFieldValue('splits', {
                            ...values.splits,
                            ...setTimeSplitByDay('4', 'isAvailableOnThursday'),
                          })
                    }
                  }}
                />
                <div className='checkbox-title'>
                  {i18n.t('staff.createHourlyWage.thursDay')}
                </div>
              </div>
              <div className='checkbox-item'>
                <Checkbox
                  disabled={disabled}
                  name='isFriday'
                  checked={
                    activeIndex
                      ? recruitmentScheduleShift?.isAvailableOnFriday ||
                        scheduleShiftGroup?.isAvailableOnFriday
                      : values.isFriday
                  }
                  onChange={e => {
                    if (!e.target.checked) {
                      setFieldValue('isAllday', false)
                      setFieldValue('isFriday', false)
                      if (values.isDayOnWeekday) {
                        removeTimeToDay('5_Hol')
                      }
                      if (!isRecruitmentSchedule) {
                        setFieldValue(
                          'listDaySelected',
                          values.listDaySelected.filter(
                            item => item !== '5_Hol'
                          )
                        )
                      }
                      removeTimeToDay('5')
                    } else if (
                      values.isMonday &&
                      values.isTuesday &&
                      values.isWednesday &&
                      values.isThursday
                    ) {
                      setFieldValue('isAllday', true)
                      setFieldValue('isFriday', true)
                      values.isDayOnWeekday
                        ? setFieldValue('splits', {
                            ...values.splits,
                            ...setTimeSplitByDay(
                              '5_Hol',
                              'isAvailableOnHoliday'
                            ),
                            ...setTimeSplitByDay('5', 'isAvailableOnFriday'),
                          })
                        : setFieldValue('splits', {
                            ...values.splits,
                            ...setTimeSplitByDay('5', 'isAvailableOnFriday'),
                          })
                      if (!isRecruitmentSchedule) {
                        values.listDaySelected.push('5_Hol')
                      }
                    } else {
                      setFieldValue('isFriday', e.target.checked)
                      if (!isRecruitmentSchedule) {
                        values.listDaySelected.push('5_Hol')
                      }
                      values.isDayOnWeekday
                        ? setFieldValue('splits', {
                            ...values.splits,
                            ...setTimeSplitByDay(
                              '5_Hol',
                              'isAvailableOnHoliday'
                            ),
                            ...setTimeSplitByDay('5', 'isAvailableOnFriday'),
                          })
                        : setFieldValue('splits', {
                            ...values.splits,
                            ...setTimeSplitByDay('5', 'isAvailableOnFriday'),
                          })
                    }
                  }}
                />
                <div className='checkbox-title'>
                  {i18n.t('staff.createHourlyWage.friDay')}
                </div>
              </div>
              <div className='checkbox-item'>
                <Checkbox
                  disabled={disabled}
                  name='isSaturday'
                  checked={
                    activeIndex
                      ? recruitmentScheduleShift?.isAvailableOnSaturday ||
                        scheduleShiftGroup?.isAvailableOnSaturday
                      : values.isSaturday
                  }
                  onChange={e => {
                    if (!e.target.checked) {
                      removeTimeToDay('6')
                      if (values.isDayOnWeekday) {
                        removeTimeToDay('6_Hol')
                      }
                      if (!isRecruitmentSchedule) {
                        setFieldValue(
                          'listDaySelected',
                          values.listDaySelected.filter(
                            item => item !== '6_Hol'
                          )
                        )
                      }
                    } else {
                      if (!isRecruitmentSchedule) {
                        values.listDaySelected.push('6_Hol')
                      }
                      values.isDayOnWeekday
                        ? setFieldValue('splits', {
                            ...values.splits,
                            ...setTimeSplitByDay(
                              '6_Hol',
                              'isAvailableOnHoliday'
                            ),
                            ...setTimeSplitByDay('6', 'isAvailableOnSaturday'),
                          })
                        : setFieldValue('splits', {
                            ...values.splits,
                            ...setTimeSplitByDay('6', 'isAvailableOnSaturday'),
                          })
                    }
                    setFieldValue('isSaturday', e.target.checked)
                  }}
                />
                <div className='checkbox-title'>
                  {i18n.t('staff.createHourlyWage.saturDay')}
                </div>
              </div>
              <div className='checkbox-item'>
                <Checkbox
                  disabled={disabled}
                  name='isSunday'
                  checked={
                    activeIndex
                      ? recruitmentScheduleShift?.isAvailableOnSunday ||
                        scheduleShiftGroup?.isAvailableOnSunday
                      : values.isSunday
                  }
                  onChange={e => {
                    if (!e.target.checked) {
                      removeTimeToDay('7')
                      if (values.isDayOnWeekday) {
                        removeTimeToDay('7_Hol')
                      }
                      if (!isRecruitmentSchedule) {
                        setFieldValue(
                          'listDaySelected',
                          values.listDaySelected.filter(
                            item => item !== '7_Hol'
                          )
                        )
                      }
                    } else {
                      if (!isRecruitmentSchedule) {
                        values.listDaySelected.push('7_Hol')
                      }
                      values.isDayOnWeekday
                        ? setFieldValue('splits', {
                            ...values.splits,
                            ...setTimeSplitByDay(
                              '7_Hol',
                              'isAvailableOnHoliday'
                            ),
                            ...setTimeSplitByDay('7', 'isAvailableOnSunday'),
                          })
                        : setFieldValue('splits', {
                            ...values.splits,
                            ...setTimeSplitByDay('7', 'isAvailableOnSunday'),
                          })
                    }
                    setFieldValue('isSunday', e.target.checked)
                  }}
                />
                <div className='checkbox-title'>
                  {i18n.t('staff.createHourlyWage.sunDay')}
                </div>
              </div>
              <div className='checkbox-item'>
                <Checkbox
                  name='isDayoff'
                  disabled={values.isDayOnWeekday || disabled}
                  checked={
                    activeIndex
                      ? recruitmentScheduleShift?.isAvailableOnHoliday ||
                        scheduleShiftGroup?.isAvailableOnHoliday
                      : values.isDayoff
                  }
                  onChange={e => {
                    setFieldValue('isDayoff', e.target.checked)
                    if (!e.target.checked) {
                      removeTimeToDay('8')
                    } else {
                      setFieldValue('splits', {
                        ...values.splits,
                        ...setTimeSplitByDay('8', 'isAvailableOnHoliday'),
                      })
                    }
                  }}
                />
                <div className='checkbox-title'>
                  {i18n.t('staff.createHourlyWage.dayOff')}
                </div>
              </div>
              {!isRecruitmentSchedule && (
                <div className='checkbox-item'>
                  <Checkbox
                    name='isDayOnWeekday'
                    disabled={values.isDayoff || disabled}
                    checked={values.isDayOnWeekday}
                    onChange={e => {
                      setFieldValue('isDayOnWeekday', e.target.checked)
                      if (!e.target.checked) {
                        values.listDaySelected.forEach(item =>
                          removeTimeToDay(item)
                        )
                      } else {
                        let dataHoli = {}
                        values.listDaySelected.forEach(item => {
                          dataHoli = {
                            ...dataHoli,
                            ...setTimeSplitByDay(item, 'isAvailableOnHoliday'),
                          }
                        })
                        setFieldValue('splits', {
                          ...values.splits,
                          ...dataHoli,
                        })
                      }
                    }}
                  />
                  <div className='checkbox-title'>
                    {i18n.t('staff.createHourlyWage.dayOnWeekday')}
                  </div>
                </div>
              )}
            </div>
            <div className='alert'>{touched.undefined && errors.undefined}</div>
          </div>
        </div>
      ) : values.repeatSetting === TYPE_CREATE.MONTHLY ? (
        <>
          <MultipleDimensionCheckbox
            disabled={disabled}
            {...formikProps}
            label={i18n.t('staff.scheduledShiftGroup.titleWeek1')}
            name='listWeekDay'
            options={sampleDataOfCheckbox}
            inLine
            listWeekDay
          />
          <MultipleDimensionCheckbox
            {...formikProps}
            disabled={disabled}
            label={i18n.t('staff.scheduledShiftGroup.titleWeek2')}
            name='listWeekDay1'
            options={sampleDataOfCheckbox}
            inLine
            listWeekDay1
          />
          <MultipleDimensionCheckbox
            {...formikProps}
            disabled={disabled}
            label={i18n.t('staff.scheduledShiftGroup.titleWeek3')}
            name='listWeekDay2'
            options={sampleDataOfCheckbox}
            inLine
            listWeekDay2
          />
          <MultipleDimensionCheckbox
            {...formikProps}
            disabled={disabled}
            label={i18n.t('staff.scheduledShiftGroup.titleWeek4')}
            name='listWeekDay3'
            options={sampleDataOfCheckbox}
            inLine
            listWeekDay3
          />
          <MultipleDimensionCheckbox
            {...formikProps}
            disabled={disabled}
            label={i18n.t('staff.scheduledShiftGroup.titleWeek5')}
            name='listWeekDay4'
            options={sampleDataOfCheckbox}
            inLine
            listWeekDay4
          />
          <div className='erorr-monthly-repeat'>
            <div className='alert'>{touched.undefined && errors.undefined}</div>
          </div>
          <div className='tr'>
            <div className='title-week-day'>
              <label
                htmlFor='isHolidayRepeat'
                style={{ fontWeight: 600 }}
                className='p-checkbox-label'
              >
                {i18n.t('staff.scheduledShiftGroup.isHolidayRepeat')}
              </label>
            </div>
            <div className='td'>
              <CheckboxPrime
                name='isHolidayRepeat'
                inputId='isHolidayRepeat'
                checked={
                  activeIndex
                    ? recruitmentScheduleShift?.isHolidayRepeat ||
                      scheduleShiftGroup?.isHolidayRepeat
                    : values.isHolidayRepeat
                }
                disabled={disabled}
                onChange={e => {
                  setFieldValue('isHolidayRepeat', e.checked)
                  if (e.target.checked) {
                    delete values.splits['8']
                  } else {
                    setFieldValue('splits', {
                      ...values.splits,
                      ...setTimeSplitByDay('8', 'isAvailableOnHoliday'),
                    })
                  }
                }}
              />
            </div>
          </div>
        </>
      ) : null}
    </>
  )
}

export default ShiftGroupFormRepeatSetting
