import gql from "graphql-tag"

export const PORTAL_QUERY = gql`
  query PortalsConnection(
    $where: PortalWhereInput
    $orderBy: PortalOrderByInput
    $skip: Int
    $first: Int
  ) {
    portalsConnection(
      where: $where
      orderBy: $orderBy
      skip: $skip
      first: $first
    ) {
      edges {
        node {
          id
          isHide
          largeTitle
          mediumTitle
          smallTitle
          titleType
          content
          updatedBy {
            id
            firstname
            lastname
          }
          createdAt
          createdBy {
            id
            firstname
            lastname
          }
          updatedAt
        }
      }
      aggregate {
        count
      }
    }
  }
`
