const ClinicOpenTimeRender = ({ clinicalDepartment }) => {
  let result = ''
  if (
    clinicalDepartment.isAvailableMorning &&
    clinicalDepartment.isAvailableAfternoon
  ) {
    result = `${clinicalDepartment.startTimeMorning} ~ ${clinicalDepartment.endTimeMorning} / ${clinicalDepartment.startTimeAfternoon} ~ ${clinicalDepartment.endTimeAfternoon}`
  }
  if (
    clinicalDepartment.isAvailableMorning &&
    !clinicalDepartment.isAvailableAfternoon
  ) {
    result = `${clinicalDepartment.startTimeMorning} ~ ${clinicalDepartment.endTimeMorning} / 定休`
  }
  if (
    !clinicalDepartment.isAvailableMorning &&
    clinicalDepartment.isAvailableAfternoon
  ) {
    result = ` 定休 / ${clinicalDepartment.startTimeAfternoon} ~ ${clinicalDepartment.endTimeAfternoon}`
  }
  if (
    !clinicalDepartment.isAvailableMorning &&
    !clinicalDepartment.isAvailableAfternoon
  ) {
    result = `定休日`
  }
  return result
}
export default ClinicOpenTimeRender
