export const SET_PROGRESS = 'SET_PROGRESS'
export const REMOVE_PROGRESS = 'REMOVE_PROGRESS'

export const setProgressBar = payload => dispatch => {
  dispatch({
    type: SET_PROGRESS,
    payload,
  })
}

export const removeProgress = payload => dispatch => {
  dispatch({
    type: REMOVE_PROGRESS,
    payload
  })
}
