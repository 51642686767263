/* eslint-disable jsx-a11y/label-has-associated-control */
// import ModalLink from '@medical/components/ModalLink'
import ClinicNameRender from '@medical/components/ClinicNameRender'
import numeralCurrency from '@medical/libs/numeralCurrency'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { useQuery } from '@apollo/react-hooks'
import { AVAILABLE_SHIFTS } from '../AvailableShift.graphql'
// import Router from '@medical/routes/router'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
// import { InputText } from 'primereact/inputtext'
import { pathOr } from 'ramda'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'

import { ActionItem } from './ActionItem'
import { AvailableShiftItem } from './AvailableShiftItem'
import DialogViewShift from '../../DoctorHome/components/DialogViewShift'

const sortFields = {
  clinicName: ['clinicalDepartment', 'clinic', 'nameKana'],
  departmentName: ['clinicalDepartment', 'name'],
  startTime: ['startTime'],
  endTime: ['endTime'],
  dailySalary: ['dailySalary'],
}

const orders = {
  ASC: 'ASC',
  DESC: 'DESC',
}

const AvailableShiftTable = ({
  loading,
  i18n,
  addItemToCart,
  availableShifts,
  removeItemFromCart,
  cartItems,
  selectedDate,
  toggleAvailableShift,
  setToggleAvailableShift,
  isSelectedDateHoliday,
  departmentNames,
  lastLoggedIn,
}) => {
  const [selectedShift, setSelectedShift] = useState()

  const availableShiftIds = availableShifts.map(({ id }) => id)
  const { loading: queryLoading, error: queryError, data } = useQuery(
    AVAILABLE_SHIFTS,
    {
      variables: {
        where: {
          id_in: availableShiftIds,
        },
      },
      fetchPolicy: 'network-only',
    }
  )
  const [sort, setSort] = useState('startTime_DESC')

  if (queryLoading) return <ProgressSpinner />
  if (queryError) return <ErrorComponent error={queryError} />
  const [field, order] = sort.split('_')
  const sorts = [
    {
      value: `startTime_${orders.DESC}`,
      label: `${i18n.t('main.startTime')}_${i18n.t('main.DESC.early')}`,
    },
    {
      value: `startTime_${orders.ASC}`,
      label: `${i18n.t('main.startTime')}_${i18n.t('main.ASC.late')}`,
    },
    {
      value: `endTime_${orders.DESC}`,
      label: `${i18n.t('main.endTime')}_${i18n.t('main.DESC.early')}`,
    },
    {
      value: `endTime_${orders.ASC}`,
      label: `${i18n.t('main.endTime')}_${i18n.t('main.ASC.late')}`,
    },
    {
      value: `dailySalary_${orders.ASC}`,
      label: `${i18n.t('main.dailySalary')}_${i18n.t('main.ASC.low')}`,
    },
    {
      value: `dailySalary_${orders.DESC}`,
      label: `${i18n.t('main.dailySalary')}_${i18n.t('main.DESC.high')}`,
    },
    {
      value: `clinicName_${orders.DESC}`,
      label: `${i18n.t('main.clinicName')}_${i18n.t('main.DESC.descending')}`,
    },
    {
      value: `clinicName_${orders.ASC}`,
      label: `${i18n.t('main.clinicName')}_${i18n.t('main.ASC.ascending')}`,
    },
    {
      value: `departmentName_${orders.DESC}`,
      label: `${i18n.t('main.departmentName')}_${i18n.t(
        'main.DESC.descending'
      )}`,
    },
    {
      value: `departmentName_${orders.ASC}`,
      label: `${i18n.t('main.departmentName')}_${i18n.t('main.ASC.ascending')}`,
    },
  ]
  const filteredAvailableShift = data.availableShifts.sort((a, b) => {
    const aValue = pathOr('', sortFields[field], a)
    const bValue = pathOr('', sortFields[field], b)
    let sortValue
    if (typeof aValue === 'string') {
      sortValue = aValue.localeCompare(bValue, 'ja')
    } else {
      sortValue = aValue - bValue
    }
    if (order === orders.DESC) {
      return sortValue
    }
    return -sortValue
  })

  const handleCloseViewShift = () => {
    setSelectedShift(undefined)
  }

  const handleClickAdd = value => {
    if (value.comment) {
      setSelectedShift(value)
    } else {
      addItemToCart(value)
    }
  }

  const action = item => (
    <div className='table-action'>
      {cartItems.some(element => element.id === item.id) ? (
        <Button
          onClick={() => removeItemFromCart(item.id)}
          icon='pi pi-times'
          className='p-button-danger'
        />
      ) : (
        <Button
          onClick={() => {
            handleClickAdd(item)
          }}
          icon='pi pi-plus'
          className='p-button-success'
        />
      )}
    </div>
  )

  if (isMobile) {
    if (!data.availableShifts.length) {
      return null
    }
    return (
      <div>
        <div
          className={`mobile-accordion mobile-header ${
            toggleAvailableShift ? 'active' : ''
          }`}
          onClick={() => setToggleAvailableShift(!toggleAvailableShift)}
        >
          {isSelectedDateHoliday ? (
            <div>
              {selectedDate
                ? `${moment(selectedDate).format('M月D日 (dd)')}(祝)`
                : null}
              <h2>{i18n.t('doctor.availableShifts.availableShifts')}</h2>
            </div>
          ) : (
            <div>
              {selectedDate ? moment(selectedDate).format('M月D日 (dd)') : null}
              <h2>{i18n.t('doctor.availableShifts.availableShifts')}</h2>
            </div>
          )}
          <div className='accordian-button'>
            <i
              className={`pi pi-chevron-${
                toggleAvailableShift ? 'up' : 'down'
              }`}
            />
          </div>
        </div>
        <div
          style={
            toggleAvailableShift
              ? { display: 'block', padding: '5px', backgroundColor: '#fcf8e3' }
              : { display: 'none' }
          }
        >
          <Dropdown
            style={{ width: '100%' }}
            value={`${field}_${order}`}
            options={sorts}
            onChange={e => {
              setSort(e.value)
            }}
          />
        </div>
        <div
          className='mobile-accordian-body'
          style={
            toggleAvailableShift ? { display: 'block' } : { display: 'none' }
          }
        >
          {filteredAvailableShift.map(availableShift => (
            <AvailableShiftItem
              availableShift={availableShift}
              action={action}
              key={availableShift.id}
              departmentNames={departmentNames}
            />
          ))}
        </div>

        {!!selectedShift && (
          <DialogViewShift
            open={!!selectedShift}
            onClose={handleCloseViewShift}
            item={selectedShift}
            addItemToCart={addItemToCart}
            isAddToCart
          />
        )}
      </div>
    )
  }

  const header = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <h2>{i18n.t('doctor.availableShifts.availableShifts')}</h2>
    </div>
  )

  const convertToArray = arr => {
    const result = []
    arr.forEach(item => {
      result.push({
        clinic:
          moment(item.updatedAt) - moment(lastLoggedIn) > 0 ? (
            <div>
              <div className='available-shift-list row'>
                {item.createdAt === item.updatedAt ? (
                  <p className='tag-name'>New</p>
                ) : (
                  <p className='tag-name update'>Update</p>
                )}
              </div>
              {ClinicNameRender({
                clinicalDepartment: item.clinicalDepartment,
              })}
            </div>
          ) : (
            <div>
              <div className='available-shift-list row' />
              {ClinicNameRender({
                clinicalDepartment: item.clinicalDepartment,
              })}
            </div>
          ),
        clinicKana: item.clinicalDepartment.clinic.nameKana,
        name: departmentNames[item.clinicalDepartment.name],
        startTime: moment(item.startTime).format('HH:mm'),
        endTime: moment(item.endTime).format('HH:mm'),
        dailySalary: item.isSpecial ? (
          <>
            {numeralCurrency(item.dailySalary)}
            <p
              style={{
                backgroundColor: '#ff2575',
                color: '#fff',
                width: 'fit-content',
              }}
            >
              特別時給
            </p>
          </>
        ) : (
          <>{numeralCurrency(item.dailySalary)}</>
        ),
        action: action(item),
        commentDetailsDisplay: item.comment ? (
          <div className='grad-wrap'>
            <div className='grad-item'>{item.comment}</div>
            <label className='grad-btn' htmlFor='trigger1'>
              ...詳細を確認してください
            </label>
          </div>
        ) : (
          undefined
        ),
        commentDetails: <ActionItem item={item} type='availableShifts' />,
        dailySalaryNumber: item.dailySalary,
      })
    })
    return result
  }

  return (
    <React.Fragment>
      {loading ? (
        <ProgressSpinner />
      ) : (
        <DataTable
          value={convertToArray(filteredAvailableShift, cartItems)}
          header={header}
          scrollable
          scrollHeight='35vh'
          emptyMessage={i18n.t('main.noAvailableShift')}
        >
          <Column
            field='clinic'
            header={i18n.t('main.clinicName')}
            style={{ width: '20%' }}
            sortable
            sortField='clinicKana'
          />
          <Column
            field='name'
            header={i18n.t('main.departmentName-short')}
            style={{ width: '13%' }}
            sortable
          />
          <Column
            field='startTime'
            header={i18n.t('main.startTime')}
            style={{ width: '15%' }}
            sortable
          />
          <Column
            field='endTime'
            header={i18n.t('main.endTime')}
            style={{ width: '15%' }}
            sortable
          />
          <Column
            field='dailySalary'
            header={i18n.t('main.dailySalary')}
            style={{ width: '18%' }}
            sortable
            sortField='dailySalaryNumber'
          />
          <Column
            field='commentDetailsDisplay'
            header={i18n.t('main.description')}
            style={{ width: '20%' }}
          />
          <Column
            field='commentDetails'
            header={i18n.t('main.descriptionDetail')}
            style={{ width: '10%' }}
          />
          <Column
            field='action'
            header={i18n.t('main.action')}
            style={{ width: '15%' }}
          />
        </DataTable>
      )}
      {!!selectedShift && <DialogViewShift
        open={!!selectedShift}
        onClose={handleCloseViewShift}
        item={selectedShift}
        addItemToCart={addItemToCart}
        isAddToCart
      />}
    </React.Fragment>
  )
}

export default AvailableShiftTable
