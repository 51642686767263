import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import gql from 'graphql-tag'

export const GET_MONTHLY_MOVING_EXPENSES_DETAIL = gql`
  query Query(
    $where: DoctorWhereUniqueInput!
    $date: DateTime!
    $doctorId: ID
  ) {
    doctor(where: $where) {
      id
      lastname
      lastnameKana
      firstname
      firstnameKana
      isBlockedMyCar
      doctorMonthlyTransportationExpenses(date: $date, doctorId: $doctorId) {
        id
        createdAt
        deletedAt
        endTime
        startTime
        cost
        type
        category
        blockedDays
        clinic {
          id
          name
          nameKana
        }
        clinicalDepartment {
          id
          name
          extraName
          clinic {
            id
            name
            nameKana
          }
        }
      }
      doctorMonthlyWorkSchedules(date: $date) {
        id
        isTypeTransportation
        costTransportationByMyCar
        costTransportationByDoctorWorkSchedule {
          id
          endTime
          startTime
          returnPath
          cost
          type
          category
        }
        notApplyCostTransportation
        acceptedShift {
          id
          dailySalary
        }
        deletedAt
        startTime
        endTime
        clinicalDepartment {
          id
          name
          extraName
          clinic {
            id
            name
            nameKana
          }
        }
      }
    }
    ...DEPARTMENTS_FRAGMENT
  }
  ${DEPARTMENTS_FRAGMENT}
`

export const HANDLE_DELETE_MOVING_EXPENSES_DETAIL = gql`
  mutation staffDeleteTransportationExpense(
    $transportationsId: [ID!]!
    $reverse: Boolean
    $dateTime: String
  ) {
    staffDeleteTransportationExpense(
      transportationsId: $transportationsId
      reverse: $reverse
      dateTime: $dateTime
    )
  }
`
export const HANDLE_DELETE_MOVING_EXPENSES_DETAIL_WORKSCHEDULE = gql`
  mutation staffHideCostDoctorWorkSchedule(
    $workScheduleId: ID!
    $reverse: Boolean
  ) {
    staffHideCostDoctorWorkSchedule(
      workScheduleId: $workScheduleId
      reverse: $reverse
    )
  }
`
