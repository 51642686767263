export const fieldAvailableShiftsUnconfirmed = () => {
  const fieldObject = [
    { label: '作成日', value: 'createdAt' },
    { label: 'クリニックNo', value: 'order' },
    { label: 'クリニック名', value: 'clinicName' },
    { label: '診療科', value: 'clinicalDepartmentName' },
    { label: '勤務日', value: 'workDate' },
    {
      label: '勤務開始時間',
      value: 'startTime',
    },
    {
      label: '終了時間1',
      value: 'splitDateTime1',
    },
    {
      label: '終了時間2',
      value: 'splitDateTime2',
    },
    {
      label: '終了時間3',
      value: 'splitDateTime3',
    },
    {
      label: '勤務終了時間',
      value: 'endTime',
    },
    {
      label: '時給1',
      value: 'hourlyWage1',
    },
    {
      label: '時給1',
      value: 'splitHourlyWage1',
    },
    {
      label: '時給2',
      value: 'splitHourlyWage2',
    },
    {
      label: '時給3',
      value: 'splitHourlyWage3',
    },
    {
      label: '時給4',
      value: 'hourlyWage',
    },
    {
      label: '合計日給',
      value: 'dailySalary',
    },
    {
      label: '掲載ステータス',
      value: 'isPublished',
    },
    {
      label: '作成区分',
      value: 'recruitmentShiftsType',
    },
    {
      label: '特別時給設定',
      value: 'isSpecial',
    },
    {
      label: '業務内容（備考）',
      value: 'comment',
    },
    {
      label: '二診目表示',
      value: 'isDoubleRecruitment',
    },
  ]
  return fieldObject
}

export const fieldMonthlyDoctorSalary = () => {
  const fieldObject = {
    fullName: '医師名',
    email: 'メールアドレス',
    departments: '診療科',
    totalWorkScheduleSalary: '勤務実績合計金額',
    totalTransportationExpenses: '交通費合計金額',
    totalMonthyIncentive: 'インセンティブ合計金額',
    totalMonthyDoctorSalary: '3つの合計金額',
  }
  return fieldObject
}

export const fieldEarlyHourWage = () => {
  const fieldObject = {
    isEarlySpecial: '早特表示',
    isValid: '有効',
    clinic: 'クリニック名',
    clinicalDepartment: '診療科',
    updatedAt: '更新日時',
    createdBy: '作成者',
    saturdayAfternoon: '土曜午後',
    saturdayMorning: '土曜午前',
    saturdayNight: '土曜夜間',
    weekdayAfternoon: '平日午後',
    weekdayMorning: '平日午前',
    weekdayNight: '平日夜間',
    holidayMorning: '日祝午前',
    holidayAfternoon: '日祝午後',
    holidayNight: '日祝夜間',
    reservation: '予約',
  }
  return fieldObject
}
export const fieldWorkScheduleByTimeCsv = () => ({
  workDate: '勤務日',
  doctorName: '名前',
  clinicName: 'クリニック名',
  clinicalDepartmentName: '診療科',
  startMorningShift: '午前勤務開始',
  endMorningShift: '午前勤務終了',
  startBreakTime: '休憩開始',
  endBreakTime: '休憩終了',
  spaceCel1: '',
  spaceCel2: '',
  startAfternoonShift: '午後勤務開始',
  endAfternoonShift: '午後勤務終了',
  startEveningShift: '夜間勤務開始',
  endEveningShift: '夜間勤務終了',
  splitHourlyWage1: '時給１',
  splitHourlyWage2: '時給２',
  splitHourlyWage3: '時給３',
  hourlyWage: '時給４',
  dailySalary: '合計日給',
  isPublished: '掲載ステータス',
  isDoubleRecruitment: '二診目表示',
})

export const fieldUnFilledWorkScheduleByTimeCsv = maxUnfillTime => {
  const fieldObject = {
    workDate: '勤務日',
    clinicName: 'クリニック名',
    clinicalDepartmentName: '診療科',
  }

  for (let i = 0; i < maxUnfillTime; i += 1) {
    fieldObject[`unfillStartTime${i + 1}`] = `未充足時間${i + 1}開始`
    fieldObject[`unfillEndTime${i + 1}`] = `未充足時間${i + 1}終了`
  }
  return fieldObject
}

export const fieldRecruitmentShiftCsv = () => ({
  workDate: '勤務日',
  clinicName: 'クリニック名',
  clinicalDepartmentName: '診療科',
  startMorningShift: '午前勤務開始',
  endMorningShift: '午前勤務終了',
  startBreakTime: '休憩開始',
  endBreakTime: '休憩終了',
  spaceCel1: '',
  spaceCel2: '',
  startAfternoonShift: '午後勤務開始',
  endAfternoonShift: '午後勤務終了',
  startEveningShift: '夜間勤務開始',
  endEveningShift: '夜間勤務終了',
  splitHourlyWage1: '時給１',
  splitHourlyWage2: '時給２',
  splitHourlyWage3: '時給３',
  hourlyWage: '時給４',
  dailySalary: '合計日給',
  isPublished: '掲載ステータス',
  statusShift: '募集ステータス',
  isDoubleRecruitment: '二診目表示',
})

export const fieldDoctorListCsv = () => ({
  fullName: '姓名',
  fullNameKana: 'セイメイ',
  lastname: '姓',
  firstname: '名',
  lastnameKana: 'セイ',
  firstnameKana: 'メイ',
  email: 'メールアドレス',
  gender: '性別',
  birthday: '生年月日',
  phoneNumber: '電話番号',
  medicalLicenseNumber: '医籍登録番号',
  departments: '診療科目',
  zipCode: '郵便番号',
  stateOrRegion: '都道府県',
  address1: '住所1',
  address2: '住所2',
  province: '現在の主な勤務先(都道府県)',
  placeOfWork: '現在の主な勤務先名',
  nearestStations: 'ご自宅最寄駅',
  workPattern: '勤務形態',
  joinBackground: '入職経緯',
  joinBackgroundOther: '入職経緯（備考）',
  staffMemo: 'スタッフメモ',
  bankName: '銀行名',
  bankCode: '銀行コード',
  branchName: '支店名',
  branchCode: '支店コード',
  accountType: '口座種別',
  accountNumber: '口座番号',
  accountHolderName: '口座名義人',
  blocked: '応募制限',
  medicalRecord: '電子カルテID',
  orca: 'ORCAID',
  questionnaire: '患者アンケートID',
  registrationStatus: '医師ステータス',
  doctorNo: '医師No.',
  createdAt: '医師データ作成日',
  updatedAt: '医師データ最終更新日',
  lastLoggedIn: '医師最終ログイン日時',
  isJoinedSublingualImmunotherapyELearning: '舌下免疫療法E-ラーニング受講',
  isJoinedOnlineDiagnosticTraining: 'オンライン診療研修受講',
  lastWorkDay: '最終出勤日',
  lastWorkdayClinic: '最終勤務拠点',
  lastWorkDayDepartment: '最終勤務拠点の勤務した診療科',
  numberShiftWorkedInMorning: '６ヶ月勤務回数【午前】',
  numberShiftWorkedInAfternoon: '６ヶ月勤務回数【午後】',
  numberShiftWorkedInNight: '６ヶ月勤務回数【夜間】',
})
