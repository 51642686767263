export const getListIdAndWorkPartten = data => {
  if (data) {
    const tempListIdDoctor = new Map()
    const tempListWorkPartten = new Map()
    for (let i = 0; i < data.length; i++) {
      tempListIdDoctor.set(data[i].doctor.id, {
        id: data[i].doctor.id,
        email: data[i].doctor.email,
        fullName: data[i].doctor.fullName,
        fullNameEmail: `${data[i].doctor.fullName} (${data[i].doctor.email})`,
      })
      tempListWorkPartten.set(data[i].doctor.workPattern, data[i].doctor.email)
    }
    const listNameWorkPartten = []
    const listIdOfDoctor = []
    const listDoctors = []
    for (const [key, value] of tempListWorkPartten) {
      if (key) {
        listNameWorkPartten.push(key)
      }
    }
    for (const [key, value] of tempListIdDoctor) {
      listIdOfDoctor.push(key)
      listDoctors.push(value)
    }
    return {
      listDoctorId: listIdOfDoctor,
      workPartten: listNameWorkPartten,
      listDoctors,
    }
  }
  return {
    listDoctorId: [],
    workPartten: [],
    listDoctors: [],
  }
}
