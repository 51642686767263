import './style.css'

import { useMutation } from '@apollo/react-hooks'
import BreadCrumb from '@medical/components/Breadcrumb'
import { DOWNLOAD_FILE } from '@medical/pages/staff/Doctors/DoctorDetail/DoctorDetail.graphql'
import Router from '@medical/routes/router'
import { Pagination } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const NewScene = ({
  i18n,
  edges = [],
  count,
  rowsPerPage,
  page,
  onPageChange,
}) => {
  const [listData, setListData] = useState([])
  const [downloadFile] = useMutation(DOWNLOAD_FILE)
  const downloadFileSubmit = async key => {
    if (!key || key.length === 0) return null
    try {
      const {
        data: { downloadFileS3: fileURL },
      } = await downloadFile({
        variables: {
          key,
        },
      })
      return fileURL
    } catch (error) {
      return null
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      const promises = []
      for (const item of edges) {
        if (item.node.imageKey) {
          promises.push(downloadFileSubmit(item.node.imageKey))
        }
        if (!item.node.imageKey) {
          promises.push(downloadFileSubmit(process.env.REACT_APP_IMAGE_DEFAULT))
        }
      }
      const results = await Promise.all(promises)
      const temp = edges.map((el, index) => ({
        ...el,
        img: results[index],
      }))
      setListData(temp)
    }

    fetchData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edges])
  return (
    <React.Fragment>
      <div className='container container-news-scene'>
        <div
          className='box'
          style={{
            border: 'none',
            boxShadow: 'none',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <div className='staff-header'>
            <div className='staff-title'>
              {i18n.t('doctor.menuBar.news')}
              <BreadCrumb
                items={[{ title: i18n.t('doctor.news.listTitle') }]}
              />
            </div>
          </div>
          <div className='news-content-wrap'>
            {listData.map(item => {
              return (
                <div className='news-item' style={{ marginBottom: '20px' }}>
                  <div className='news-item-image'>
                    <Link
                      to={Router.get(Router.doctorNewsDetail, {
                        newsId: item.node.id,
                      })}
                      style={{ maxHeight: '100%' }}
                    >
                      <img src={item.img} className='news-img' />
                    </Link>
                  </div>
                  <div className='news-item-content'>
                    <Link
                      to={Router.get(Router.doctorNewsDetail, {
                        newsId: item.node.id,
                      })}
                    >
                      <h2 className='news-title-item'>{item.node.title}</h2>
                    </Link>

                    <p className='news-content'>
                      {item.node.content.replace(/<[^>]+>/g, '')}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>

          <Pagination
            total={count}
            size='small'
            pageSize={rowsPerPage}
            current={parseInt(page)}
            onChange={onPageChange}
            style={{ margin: '50px auto' }}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default NewScene
