import gql from 'graphql-tag'

export const DOCTOR_CHANGE_PASSWORD = gql`
  mutation DOCTOR_CHANGE_PASSWORD(
    $oldPassword: String!
    $newPassword: String!
  ) {
    doctorChangePassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`
