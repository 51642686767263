import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import { CLINICAL_DEPARTMENTS_QUERY } from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import gql from 'graphql-tag'

import { WORK_SCHEDULE_COMMENT_FRAGMENTS } from '../DetailWorkSchedule/DetailWorkSchedule.graphql'

export const STAFF_GET_AS_GROUP_BY_ID = gql`
  query Query(
    $where: ScheduledAvailableShiftGroupWhereUniqueInput!
    $availableShiftsWhere2: AvailableShiftWhereInput
  ) {
    staffMe {
      id
    }
    scheduledAvailableShiftGroup(where: $where) {
      id
      name
      startDate
      endDate
      dataTime
      comment
      isPublished
      defaultHourlyWage {
        id
      }
      isAvailableOnMonday
      isAvailableOnTuesday
      isAvailableOnWednesday
      isAvailableOnThursday
      isAvailableOnFriday
      isAvailableOnSaturday
      isAvailableOnSunday
      isAvailableOnHoliday
      isAvailableOnWeekdayHoliday
      repeatSetting
      dateMatrix
      staffComments {
        ...WORK_SCHEDULE_COMMENT_FRAGMENTS
      }
      isHolidayRepeat
      availableShifts(where: $availableShiftsWhere2) {
        id
        deletedAt
        startTime
        comment
        clinicalDepartment {
          id
          name
          extraName
          isDisplay
          clinic {
            id
            order
            name
            nameKana
          }
        }
        workSchedule {
          id
          doctor {
            id
            email
            firstname
            lastname
            fullName
            isAvailableOnMonday
            isAvailableOnTuesday
            isAvailableOnWednesday
            isAvailableOnThursday
            isAvailableOnFriday
            isAvailableOnSaturday
            isAvailableOnSunday
            isAvailableOnHoliday
          }
          doctorSubsidy {
            id
            title
            money
          }
        }
        hourlyWage
        startTime
        endTime
        splitDateTime1
        splitDateTime2
        splitDateTime3
        splitHourlyWage1
        splitHourlyWage2
        splitHourlyWage3
        isStartTimeBreakTime
        isSplitDateTime1BreakTime
        isSplitDateTime2BreakTime
        isSplitDateTime3BreakTime
        isEditedIndividually
        isPublished
        workSchedule {
          deletedAt
          deletedStatus
        }
      }
    }
    ...DEPARTMENTS_FRAGMENT
    ...CLINICAL_DEPARTMENTS_QUERY
  }
  ${CLINICAL_DEPARTMENTS_QUERY}

  ${WORK_SCHEDULE_COMMENT_FRAGMENTS}
  ${DEPARTMENTS_FRAGMENT}
`

export const STAFF_UPDATE_AS_GROUP_BY_ID = gql`
  mutation UpdateScheduledAvailableShiftGroup(
    $scheduledAvailableShiftGroupId: ID!
    $doctorId: ID!
    $isHolidays: [String!]
    $accepted: Boolean
    $mailContent: String
    $data: ScheduledAvailableShiftGroupUpdateInput!
    $repeatSetting: RepeatSettingTypes
    $dateMatrix: String
    $staffComments: [WorkScheduleCommentCreateInput!]
    $comment: String
    $doctorSubsidy: [DoctorSubsidyForCreateInput!]
    $isHolidayRepeat: Boolean
    $availableShiftEditedList: [String!]
    $updateType: String!
    $dataTime: DayOfWeekInput!
    $isPublished: Boolean
  ) {
    staffUpdateScheduledAvailableShiftGroup(
      scheduledAvailableShiftGroupId: $scheduledAvailableShiftGroupId
      doctorId: $doctorId
      accepted: $accepted
      isHolidays: $isHolidays
      dataTime: $dataTime
      mailContent: $mailContent
      data: $data
      repeatSetting: $repeatSetting
      dateMatrix: $dateMatrix
      staffComments: $staffComments
      comment: $comment
      doctorSubsidy: $doctorSubsidy
      isHolidayRepeat: $isHolidayRepeat
      availableShiftEditedList: $availableShiftEditedList
      updateType: $updateType
      isPublished: $isPublished
    )
  }
`
export const DELETE_AS_GROUP_BY_ID = gql`
  mutation STAFF_DELETE_AS_GROUP(
    $scheduledAvailableShiftGroupId: ID!
    $mailContent: String
    $doctorEmail: String
  ) {
    staffDeleteScheduledAvailableShiftGroup(
      scheduledAvailableShiftGroupId: $scheduledAvailableShiftGroupId
      mailContent: $mailContent
      doctorEmail: $doctorEmail
    )
  }
`

export const ACTIVITIES_HISTORY = gql`
  query ACTIVITIES_HISTORY($where: ActivityHistoryWhereInput) {
    activityHistories(where: $where) {
      id
      createdAt
      activity
      staff {
        id
        firstname
        lastname
      }
    }
  }
`
