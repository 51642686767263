import { ANNOUNCE_FRAGMENT } from '@medical/pages/staff/Announces/AnnouncesList/AnnouncesList.graphql'
import gql from 'graphql-tag'

export const CREATE_ANNOUNCE = gql`
  mutation CREATE_ANNOUNCE(
    $title: String!
    $content: String!
    $isPublished: Boolean!
    $isSentEmailToAllDoctors: Boolean!
    $image: String
    $isAddAvailableShift: Boolean!
    $isAddRecruitmentPost: Boolean!
    $availableShifts: [ID!]
    $recruitmentPosts: [ID!]
    $isNotSendDoctorBlock: Boolean
    $isNotSendDoctorWorkScheduleBlock: Boolean
  ) {
    staffCreateAnnounce(
      title: $title
      content: $content
      isPublished: $isPublished
      isSentEmailToAllDoctors: $isSentEmailToAllDoctors
      image: $image
      isAddAvailableShift: $isAddAvailableShift
      isAddRecruitmentPost: $isAddRecruitmentPost
      availableShifts: $availableShifts
      recruitmentPosts: $recruitmentPosts
      isNotSendDoctorWorkScheduleBlock: $isNotSendDoctorWorkScheduleBlock
      isNotSendDoctorBlock: $isNotSendDoctorBlock
    ) {
      ...ANNOUNCE_FRAGMENT
    }
  }
  ${ANNOUNCE_FRAGMENT}
`
