import Router from '@medical/routes/router'
import moment from 'moment'
import React from 'react'
import { Redirect } from 'react-router-dom'

const StaffHome = () => {
  const now = moment()
  return (
    <Redirect
      to={Router.get(Router.staffMonthlyAvailableShifts, {
        year: now.year(),
        month: now.month() + 1,
      })}
    />
  )
}

export default StaffHome
