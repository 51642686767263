import gql from 'graphql-tag'

export const STAFF_CHANGE_RESET_PASSWORD = gql`
  mutation STAFF_CHANGE_RESET_PASSWORD(
    $resetPasswordNumber: String!
    $newPassword: String!
  ) {
    staffChangeResetPassword(
      resetPasswordNumber: $resetPasswordNumber
      newPassword: $newPassword
    )
  }
`

export const STAFF_CHECK_RESET_PASSWORD_NUMBER = gql`
  query STAFF_CHECK_RESET_PASSWORD_NUMBER($resetPasswordNumber: String!) {
    staffCheckPasswordNumber(resetPasswordNumber: $resetPasswordNumber)
  }
`
