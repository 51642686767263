import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import {
  STAFF_RESTORE_BLOCKED_DOCTOR,
  UPDATE_MOVING_EXPENSES_REGISTRATION,
} from '@medical/constant/permissions'
import {
  checkStaffPermission,
  findNameOrEmail,
  onPageChange,
} from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import queryString from 'query-string'
import React, { useEffect } from 'react'

import {
  LIST_DOCTOR_CAR_MOVING_EXPENSE,
  STAFF_BLOCK_CAR_DOCTOR,
} from './ListMovingExpensesManagement.graphql'
import ListMovingExpensesManagementScene from './ListMovingExpensesManagementScene'

const ListMovingExpensesManagement = ({ history, location: { search } }) => {
  const {
    page = 1,
    rowsPerPage = 20,
    fullname,
    email,
    isBlockedMyCar,
  } = queryString.parse(search, { parseBooleans: true })
  let isBlockedMyCarCondition =
    isBlockedMyCar === false
      ? { OR: [{ isBlockedMyCar: false }, { isBlockedMyCar: null }] }
      : {}
  if (isBlockedMyCar) {
    isBlockedMyCarCondition = {
      OR: [{ isBlockedMyCar: true }],
    }
  }

  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  const [{ i18n, popup }] = useCustom()
  const [updateDoctor] = useMutation(STAFF_BLOCK_CAR_DOCTOR)
  const { loading, error, data, refetch } = useQuery(
    LIST_DOCTOR_CAR_MOVING_EXPENSE,
    {
      variables: {
        first: first < 0 ? 20 : first,
        skip: skip < 0 ? 0 : skip,
        orderBy: 'createdAt_DESC',
        where: {
          registrationStatus_not_in: ['PENDING', 'REJECTED', 'NEW'],
          AND: findNameOrEmail({ fullname, email }),
          ...isBlockedMyCarCondition,
        },
      },
      fetchPolicy: 'network-only',
    }
  )
  useEffect(() => {
    refetch()
  })

  const onSubmit = async (
    { doctorId, isBlockedMyCar, email, isReCalculateMyCar },
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      await updateDoctor({
        variables: {
          doctorId,
          email,
          isBlockedMyCar,
          isReCalculateMyCar
        },
      })
      popup.success('成功しました')
      setSubmitting(false)
      resetForm()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
      setFieldValue('confirm', false)
    }
  }

  const isUpdateMovingExpenses = checkStaffPermission({
    functionName: UPDATE_MOVING_EXPENSES_REGISTRATION,
  })

  const isRestorePermitted = checkStaffPermission({
    functionName: STAFF_RESTORE_BLOCKED_DOCTOR,
  })
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  return (
    <ListMovingExpensesManagementScene
      isUpdateMovingExpenses={isUpdateMovingExpenses}
      isRestorePermitted={isRestorePermitted}
      data={data}
      fullname={fullname}
      email={email}
      isBlockedMyCar={isBlockedMyCar}
      rowsPerPage={parseInt(rowsPerPage, 10)}
      listMovingExpenses={data.doctorsConnection.edges}
      onPageChange={onPageChange(history, search)}
      page={parseInt(page, 10)}
      count={data.doctorsConnection.aggregate.count}
      i18n={i18n}
      onSubmit={onSubmit}
    />
  )
}

export default ListMovingExpensesManagement
