/* eslint-disable react/destructuring-assignment */
import './DoctorHeader.css'
import { MenuOutlined, UserOutlined } from '@ant-design/icons'
import logo from '@medical/assets/image/logo.png'
import moment from 'moment'
import { Button } from 'primereact/button'
import React, { useRef, useState } from 'react'
import Router from '@medical/routes/router'
import ModalLink from '@medical/components/ModalLink'
import { getMenuPath } from '@medical/libs'
import {
  Link,
  useParams,
  useResolvedPath,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'
import { DoctorLink } from '@medical/components'
import i18n from '@medical/libs/I18n/i18n'
import { useCustom } from '@medical/provider/context'

const DoctorHeaderScene = ({
  doctor,
  cartItems,
  onSignout,
  path,
  year,
  month,
}) => {
  const {
    firstname,
    firstnameKana,
    lastname,
    lastnameKana,
    fullName,
    lastLoggedIn,
    bankAccount,
    birthday,
    departments,
    files,
    gender,
    graduationUniversity,
    graduationUniversityYear,
    obtainDoctorLicenseYear,
    phoneNumber,
    placeOfWork,
    homeAddress,
    insuranceDoctorNumber,
    insuranceDoctorSymbol,
    insuranceDoctorType,
  } = doctor

  const [{ isDoctorBlocked }] = useCustom()

  const fileFiltered = files.filter(function(item) {
    if (
      item.fileCategory === 'DOCTOR_LICENSE' ||
      item.fileCategory === 'INSURANCE_DOCTOR_REGISTRATION'
    )
      return item
  })
  fileFiltered.sort((a, b) => {
    if (a && b) return a.fileCategory - b.fileCategory ? -1 : 1
  })
  if (fileFiltered.length === 1) {
    if (fileFiltered[0].fileCategory === 'INSURANCE_DOCTOR_REGISTRATION') {
      fileFiltered.unshift(null)
    }
  }

  const insuranceDoctor = !!(insuranceDoctorNumber && insuranceDoctorType && insuranceDoctorSymbol)
  const registeredItem = [
    gender,
    birthday,
    departments,
    phoneNumber,
    homeAddress.zipCode,
    homeAddress.stateOrRegion,
    homeAddress.address1,
    placeOfWork,
    graduationUniversity,
    graduationUniversityYear,
    obtainDoctorLicenseYear,
    bankAccount,
    insuranceDoctor,
    fileFiltered[0],
    fileFiltered[1],
  ]

  const nameArray = [firstname, firstnameKana, lastname, lastnameKana]
  let missName = false
  let isAlert = false
  for (let i = 0; i < nameArray.length; i += 1) {
    if (!nameArray[i]) {
      missName = true
      break
    }
  }
  if (missName) {
    registeredItem.unshift(null)
  } else registeredItem.unshift('name')
  for (let i = 0; i < registeredItem.length; i += 1) {
    if (i === 3) {
      if (registeredItem[i].length === 0) {
        isAlert = true
        break
      }
    } else if (i === 13) {
      if (registeredItem[i] === false) {
        isAlert = true
        break
      }
    } else if (
      registeredItem[i] === null ||
      registeredItem[i] === undefined ||
      registeredItem[i] === ''
    ) {
      isAlert = true
      break
    }
  }

  const renderUnregisterItem = (value, index) => {
    switch (index) {
      case 0:
        if (!value) {
          return '・氏名'
        }
        break
      case 1:
        if (!value) {
          return '・性別'
        }
        break
      case 2:
        if (!value) {
          return '・生年月日'
        }
        break
      case 3:
        if (value.length === 0) {
          return '・診療科目'
        }
        break
      case 4:
        if (!value) {
          return '・電話番号'
        }
        break
      case 5:
        if (!value) {
          return '・郵便番号'
        }
        break
      case 6:
        if (!value) {
          return '・都道府県'
        }
        break
      case 7:
        if (!value) {
          return '・住所1'
        }
        break
      case 8:
        if (!value) {
          return '・現在の主な勤務先'
        }
        break
      case 9:
        if (!value) {
          return '・卒業大学'
        }
        break
      case 10:
        if (!value) {
          return '・卒業年次'
        }
        break
      case 11:
        if (!value) {
          return '・医師免許取得年次'
        }
        break
      case 12:
        if (!value) {
          return '・銀行口座情報'
        }
        break
      case 13:
        if (!value) {
          return '・保険医登録票番号'
        }
        break
      case 14:
        if (!value) {
          return '・医師免許証アップロード'
        }
        break
      case 15:
        if (!value) {
          return '・保険医登録票アップロード'
        }
        break
      default:
        return null
    }
  }

  const [sidebar, setSidebar] = useState(false)
  const showSidebar = () => {
    setSidebar(!sidebar)
  }
  const newDate = moment(lastLoggedIn).format('YYYY/MM/DD HH:mm')

  const scroll = () => {
    window.scrollTo(500, 600)
  }

  return (
    <div>
      <div className='doctor-header-top'>
        <div className='header-left'>
          <div style={{ width: '120px', marginRight: '10px' }}>
            <DoctorLink to={Router.doctorHome}>
              <img style={{ width: '100%' }} src={logo} alt='' />
            </DoctorLink>
          </div>

          <div style={{ display: 'flex' }}>
            <DoctorLink
              className={`header-link  ${getMenuPath(
                Router.doctorAvailableShiftsWithoutYearMonth,
                path
              )}`}
              to={Router.get(Router.doctorAvailableShiftsWithoutYearMonth)}
            >
              {i18n.t('doctor.menuBar.availableShifts')}
            </DoctorLink>
            {isDoctorBlocked ? null : (
              <DoctorLink
                className={`header-link  ${getMenuPath(
                  Router.doctorRecruitments,
                  path
                )}`}
                to={Router.get(Router.doctorRecruitments)}
              >
                {i18n.t('doctor.footer.recruitment')}
              </DoctorLink>
            )}
            <DoctorLink
              className={`header-link  ${getMenuPath(
                Router.doctorWaitingRecruitment,
                path
              )}`}
              to={Router.get(Router.doctorWaitingRecruitment)}
            >
              募集待ち登録をする
            </DoctorLink>
          </div>
        </div>
        <div className='header-right'>
          <div style={{ marginBottom: '3px', display: 'flex' }}>
            <DoctorLink
              className={`header-link  ${getMenuPath(
                Router.doctorClinic,
                path
              )}`}
              to={Router.doctorClinic}
            >
              クリニック一覧
            </DoctorLink>
            <DoctorLink
              className={`header-link  ${getMenuPath(
                Router.doctorQuestions,
                path
              )}`}
              to={Router.doctorQuestions}
            >
              FAQ(よくある質問)
            </DoctorLink>

            <div className='dropdown-menu'>
              <DoctorLink className='header-link'>各種申請</DoctorLink>
              <div className='dropdown-item'>
                <Link
                  to={Router.doctorWaitingRecruitment}
                  className='header-link'
                  style={{ padding: '10px 0px', fontWeight: '300' }}
                >
                  {i18n.t('doctor.footer.waitingRecruitment')}
                </Link>
                {/* issue 623 本番環境のみ※交通費メニュー非表示 */}
                <Link
                  to={Router.doctorTransportRegister}
                  className='header-link'
                  style={{ padding: '10px 0px', fontWeight: '300' }}
                >
                  通勤経路登録
                </Link>
                <Link
                  to={Router.doctorTransportPeriodic}
                  className='header-link'
                  style={{ padding: '10px 0px', fontWeight: '300' }}
                >
                  その他交通費精算
                </Link>
                <Link
                  to={Router.doctorTransportReportsWithoutYearMonth}
                  className='header-link'
                  style={{ padding: '10px 0px', fontWeight: '300' }}
                >
                  交通費申請
                </Link>

                <Link
                  className='header-link'
                  style={{ padding: '10px 0px', fontWeight: '300' }}
                  to={Router.get(
                    Router.doctorAcceptedWorkSchedulesListWithoutYearMonth,
                    {
                      year,
                      month,
                    }
                  )}
                >
                  時間外勤務申請
                </Link>
                {/* <a
                  className='header-link'
                  href='https://docs.google.com/forms/d/e/1FAIpQLScbgycZiyKrbL8I5UJ_wa6PK5GBQPTTnxUvozIz-zOCjipnXw/viewform'
                  style={{ padding: '10px 0px', fontWeight: '300' }}
                >
                  時間外勤務申請
                </a> */}
                <Link
                  to={Router.doctorWorkOutsideClinic}
                  className='header-link'
                  style={{ padding: '10px 0px', fontWeight: '300' }}
                >
                  拠点用シフト申請
                </Link>
                <Link
                  to={Router.doctorAcceptedWorkSchedulesListWithoutYearMonth}
                  className='header-link'
                  style={{ padding: '10px 0px', fontWeight: '300' }}
                >
                  シフトキャンセル申請
                </Link>
              </div>
            </div>
          </div>
          <DoctorLink to={Router.doctorCart}>
            <Button
              icon='pi pi-inbox'
              style={{ fontSize: '20px', marginRight: '10px' }}
              onClick={showSidebar}
            >
              <span className='my-badge'>{cartItems.length}</span>
            </Button>
          </DoctorLink>

          <div className='header-log-info'>
            <p
              style={{
                textAlign: 'right',
                fontSize: '18px',
                fontWeight: '600',
              }}
            >
              {fullName}
            </p>
            <p style={{ fontSize: '12px' }}>
              <span>最終ログイン日時</span> {newDate}
            </p>
          </div>
          <div className='user-background'>
            <div id='ok'>
              <UserOutlined
                style={{
                  fontSize: '28px',
                  color: 'white',
                  padding: '5px 0px',
                }}
              />
            </div>
            <div className='profile-dropdown'>
              {/* <div className='dropdown-top'>
                <span color='black'>マイページ確認</span>
              </div> */}
              <Link
                className='header-link'
                to={{
                  pathname: `${Router.doctorMyPage}${Router.doctorUpdateProfile}`,
                }}
              >
                保険診療・給与支払必須必要情報
              </Link>
              <Link
                className='header-link'
                to={{
                  pathname: `${Router.doctorMyPage}${Router.doctorChangePassword}`,
                }}
              >
                パスワード変更
              </Link>
              <Link
                className='header-link'
                to={{
                  pathname: `${Router.doctorMyPage}${Router.doctorBankAccount}`,
                }}
              >
                銀行口座情報
              </Link>

              <Link
                className='header-link'
                to={{ pathname: `${Router.doctorMyPage}` }}
                onClick={scroll}
              >
                医師免許証/身分証アップロード
              </Link>

              <Link
                className='header-link'
                to={{
                  pathname: `${Router.doctorMyPage}${Router.DoctorCreateIdentity}`,
                }}
              >
                マイナンバー情報
              </Link>

              <Link
                className='header-link'
                to={Router.doctorWorkReportsWithoutYearMonth}
              >
                勤務実績
              </Link>

              <Link
                className='header-link'
                to={{
                  pathname: `${Router.doctorEmailNotificationsSetting}`,
                }}
              >
                メール通知設定
              </Link>
              <div onClick={onSignout} className='header-link'>
                <p
                  style={{
                    fontWeight: '400',
                    padding: '5px 25px',
                    cursor: 'pointer',
                  }}
                >
                  サインアウト
                </p>
              </div>

              {/* <Link className='header-link'>サインアウト</Link> */}

              {/* {dropdown.map((value, index) => (
                <a className='header-link'>{value}</a>
              ))} */}
            </div>
          </div>
        </div>
      </div>

      {/* header-top-hidden */}
      {/* <div className='header-top-hidden'>
        <Button icon='pi pi-bars' onClick={() => setShowmenu(!showmenu)}></Button>
        {showmenu && (
          <div className='menu-sidebar'>hello</div>
        )}
        <div className='doctor-layout-logo'>
          <img src={logo} alt='' style={{ width: '60%' }} />
        </div>
        <Button
          icon='pi pi-inbox'
          style={{ fontSize: '20px', marginRight: '10px' }}
          onClick={showSidebar}
        >
          <span className='my-badge'>{cartItems.valueOf.length}</span>
        </Button>
      </div> */}
      {/* header-top-hidden */}

      {isAlert && (
        <div className='header-bottom'>
          <div className='header-bottom-inner'>
            <span>
              <a
                className='header-link'
                style={{ color: 'white', marginLeft: '5px' }}
                href={Router.doctorMyPage}
              >
                ※保険診療・給与支払に必要な情報を登録してください
              </a>
            </span>
            【{' '}
            {registeredItem.map((value, index) => (
              <span key={index}>{renderUnregisterItem(value, index)}</span>
            ))}{' '}
            】
          </div>
        </div>
      )}
    </div>
  )
}
export default DoctorHeaderScene
