import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { useCustom } from '@medical/provider/context'
import moment from 'moment'
import React, { useEffect } from 'react'

import {
  DOCTOR_GET_DISTANCE_CLINIC,
  GET_GASOLINE_PRICE,
  STAFF_UPDATE_DATA_TRANSPORTATION,
} from './ListMovingExpensesManagement.graphql'
import MovingExpensesByCarDetailScene from './MovingExpensesByCarDetailScene'

const MovingExpensesByCarDetail = ({
  match: {
    params: { id },
  },
  location: { state },
}) => {
  const [{ i18n, popup }] = useCustom()
  const { loading, error, data, refetch } = useQuery(
    DOCTOR_GET_DISTANCE_CLINIC,
    {
      variables: {
        where: {
          id,
        },
        orderBy: 'createdAt_DESC',
        first: 1,
        skip: 0,
      },
    }
  )
  const {
    loading: loadingGasoline,
    error: errorGasoline,
    data: dataGasoline,
    refetch: refetchGasolin,
  } = useQuery(GET_GASOLINE_PRICE, {
    variables: {
      orderBy: 'createdAt_DESC',
      first: 1,
      skip: 0,
      where: {
        deletedAt: null,
        startTime_lte: moment()
          .utc()
          .startOf('day'),
      },
    },
  })
  const [updateDataTransportaion] = useMutation(
    STAFF_UPDATE_DATA_TRANSPORTATION
  )
  useEffect(() => {
    refetch()
    refetchGasolin()
  })
  if (loading || loadingGasoline) return <ProgressSpinner />
  if (error || errorGasoline)
    return <ErrorComponent error={error || errorGasoline} />
  const onSubmit = async (
    values,
    { setSubmitting, setFieldValue, resetForm }
  ) => {
    const { splits } = values
    const dataSubmit = splits.map(item => {
      const { ...newData } = item
      return newData
    })
    try {
      await updateDataTransportaion({
        variables: {
          doctorId: id,
          dataTransportation: JSON.stringify(dataSubmit),
        },
      })
      popup.success('成功しました')
      setSubmitting(false)
      resetForm()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
      setFieldValue('confirm', false)
    }
  }
  const distanceClinicData = data.doctor.doctorAddressHistories[0]
    ? JSON.parse(data.doctor.doctorAddressHistories[0].dataTransportation)
    : []
  // 燃費は1Lあたり10km(固定)で計算する
  const gasolinePrice =
    dataGasoline.gasolinePrices && dataGasoline.gasolinePrices.length > 0
      ? Math.round(dataGasoline.gasolinePrices[0].price / 10)
      : 0
  const maxPrice =
    dataGasoline.gasolinePrices && dataGasoline.gasolinePrices.length > 0
      ? dataGasoline.gasolinePrices[0].maxPrice
      : 0
  const dataResponse = distanceClinicData.map(item => ({
    clinic: item.clinic,
    clinicName: item.clinicName,
    distance: item.distance,
    price: item.price,
    applicableDate: item.applicableDate,
    highwayFare: item.highwayFare,
    maxPrice,
    gasolinePrice,
  }))
  return (
    <MovingExpensesByCarDetailScene
      i18n={i18n}
      data={data.doctor}
      address={state || ''}
      dataResponse={dataResponse || []}
      onSubmit={onSubmit}
      popup={popup}
    />
  )
}

export default MovingExpensesByCarDetail
