import Moment from 'moment'
import { extendMoment } from 'moment-range'

const moment = extendMoment(Moment)

const calculateSalaryNumber = ({
  availableShift,
  startTime,
  endTime,
  getNumber,
  hourlyWageRange,
  doctorSubsidy,
  values,
}) => {
  const splits = []
  const interval = moment.range(moment(startTime), moment(endTime))
  let rangeStartTime = startTime
  const rangeEndTime = endTime
  const {
    hourlyWage,
    splitDateTime1,
    splitDateTime2,
    splitDateTime3,
    splitHourlyWage1,
    splitHourlyWage2,
    splitHourlyWage3,
    isStartTimeBreakTime,
    isSplitDateTime1BreakTime,
    isSplitDateTime2BreakTime,
    isSplitDateTime3BreakTime,
  } = availableShift
  if (splitDateTime1 && moment(splitDateTime1).within(interval)) {
    splits.push({
      range: moment.range(moment(rangeStartTime), moment(splitDateTime1)),
      hourlyWage:
        splitHourlyWage1 +
          (!isSplitDateTime1BreakTime
            ? hourlyWageRange
              ? hourlyWageRange
              : 0
            : 0) || 0,
    })
    rangeStartTime = splitDateTime1
  }

  if (splitDateTime2 && moment(splitDateTime2).within(interval)) {
    splits.push({
      range: moment.range(moment(rangeStartTime), moment(splitDateTime2)),
      hourlyWage:
        splitHourlyWage2 +
          (!isSplitDateTime2BreakTime
            ? hourlyWageRange
              ? hourlyWageRange
              : 0
            : 0) || 0,
    })
    rangeStartTime = splitDateTime2
  }

  if (splitDateTime3 && moment(splitDateTime3).within(interval)) {
    splits.push({
      range: moment.range(moment(rangeStartTime), moment(splitDateTime3)),
      hourlyWage:
        splitHourlyWage3 +
          (!isSplitDateTime3BreakTime
            ? hourlyWageRange
              ? hourlyWageRange
              : 0
            : 0) || 0,
    })
    rangeStartTime = splitDateTime3
  }

  splits.push({
    range: moment.range(moment(rangeStartTime), moment(rangeEndTime)),
    hourlyWage:
      hourlyWage +
        (!isStartTimeBreakTime ? (hourlyWageRange ? hourlyWageRange : 0) : 0) ||
      0,
  })
  if (getNumber) {
    const result = splits.reduce(
      (acc, curr) => acc + (curr.range.diff('minutes') / 60) * curr.hourlyWage,
      0
    )
    return Math.round(result)
  }
  return (
    splits.reduce(
      (acc, curr) => acc + (curr.range.diff('minutes') / 60) * curr.hourlyWage,
      0
    ) + (doctorSubsidy ? doctorSubsidy : 0)
  )
}

export default calculateSalaryNumber
