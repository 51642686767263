import moment from 'moment'

const convertDoctorCsvRecordUpdate = data =>
  data.map(
    ({
      姓: lastname,
      医師No: doctorNo,
      メールアドレス: email,
      名: firstname,
      セイ: lastnameKana,
      メイ: firstnameKana,
      電話番号: phoneNumber,
      誕生日: birthDay,
      性別: gender,
      診療科目: departments,
      医籍登録番号: medicalLicenseNumber,
      現在の主な勤務先都道府県: province,
      現在の主な勤務先名: placeOfWork,
      勤務形態: workPattern,
      電子カルテID: medicalRecord,
      ORCAID: orca,
      患者アンケートID: questionnaire,
      入職経緯: joinBackground,
      入職経緯備考: joinBackgroundOther,
      郵便番号: zipCode,
      都道府県: stateOrRegion,
      住所1: address1,
      住所2: address2,
      銀行名: bankName,
      銀行コード: bankCode,
      支店名: branchName,
      支店コード: branchCode,
      口座種別: accountType,
      口座番号: accountNumber,
      口座名義人: accountHolderName,
    }) => ({
      lastname,
      doctorNo,
      email,
      firstname,
      lastnameKana,
      firstnameKana,
      phoneNumber,
      birthDay: moment.utc(birthDay).toISOString(),
      gender,
      departments,
      medicalLicenseNumber,
      province,
      placeOfWork,
      workPattern,
      medicalRecord,
      orca,
      questionnaire,
      joinBackground,
      joinBackgroundOther,
      zipCode,
      stateOrRegion,
      address1,
      address2,
      bankName,
      bankCode,
      branchName,
      branchCode,
      accountType,
      accountNumber,
      accountHolderName,
    })
  )
export default convertDoctorCsvRecordUpdate
