import { DOCTOR_FRAGMENTS } from '@medical/layout/DoctorLayout/DoctorLayoutRequest.graphql'
import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import gql from 'graphql-tag'

export const DOCTOR_WORK_REPORTS = gql`
  query myQuery(
    $where: DoctorWhereUniqueInput!
    $date: DateTime!
    $doctorId: ID
  ) {
    doctor(where: $where) {
      adjustHourlyWageRange
      doctorMonthlyTransportationExpenses(date: $date, doctorId: $doctorId) {
        id
        createdAt
        deletedAt
        endTime
        startTime
        cost
        category
        type
        blockedDays
        clinicalDepartment {
          id
        }
      }
      doctorMonthlyWorkSchedules(date: $date) {
        id
        startTime
        endTime
        notApplyCostTransportation
        isTypeTransportation
        costTransportationByMyCar
        costTransportationByDoctorWorkSchedule {
          id
          endTime
          startTime
          returnPath
          cost
        }
        deletedAt
        acceptedShift {
          id
          dailySalary
          startTime
          endTime
        }
        clinicalDepartment {
          id
          name
          extraName
          clinic {
            id
            name
            nameKana
          }
        }
      }
      ...DOCTOR_FRAGMENTS
    }
    ...DEPARTMENTS_FRAGMENT
  }
  ${DOCTOR_FRAGMENTS}
  ${DEPARTMENTS_FRAGMENT}
`

export const DOCTOR_MONTHLY_INCENTIVE_AMOUNT = gql`
  query DoctorMonthlyIncentiveAmount($doctorId: ID, $date: DateTime!) {
    doctorMonthlyIncentive(doctorId: $doctorId, date: $date)
  }
`
