import { useMutation } from '@apollo/react-hooks'
import ForgotPasswordForm from '@medical/components/Forms/ForgotPasswordForm'
import { useCustom } from '@medical/provider/context'
import Router from '@medical/routes/router'
import React from 'react'

import { DOCTOR_FORGOT_PASSWORD } from './DoctorForgotPassword.graphql'

const DoctorForgotPassword = ({ history }) => {
  const [{ i18n, popup }] = useCustom()
  const [forgotPassword] = useMutation(DOCTOR_FORGOT_PASSWORD)

  const onSubmit = async ({ email }, { resetForm, setSubmitting }) => {
    try {
      await forgotPassword({
        variables: {
          email,
        },
      })
      popup.success(i18n.t('forgotPassword.submissionSuccess'))
      resetForm()
      setSubmitting(false)
      history.push({
        pathname: Router.doctorLogin,
      })
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }

  return <ForgotPasswordForm onSubmit={onSubmit} />
}

export default DoctorForgotPassword
