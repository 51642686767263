import React, {useEffect} from 'react';
import BreadCrumb from "../../../components/Breadcrumb";
import {FieldArray, Formik} from 'formik'
import AutoComplete from '@medical/components/AutoComplete'
import {Button} from "primereact/button";
import Papa from "papaparse";
import Dropzone from "react-dropzone";
import * as Yup from 'yup'
import './style.css'

const StaffDoctorOutputInsuranceScene = (
  {
    i18n,
    popup,
    edges,
    convertClinic,
    downloadSample,
    isUploadPermitted,
    isUploadCheckPermitted,
    checkCsvData,
    isUploaded,
    onSubmit,
    isViewStaffDoctorOutputInsurancePermitted
  }) => {
  const translateKeys = {
    clinic_id: 'clinic_id',
    doctor_id: 'doctor_id',
  }
  const validationSchema = Yup.object().shape({
    data: Yup.array()
      .min(1, i18n.t('validation.atLeastOneItem'))
      .of(
        Yup.object().shape({
          clinic_id: Yup.string()
            .matches(/^[a-zA-Z0-9]+$/, '正しい値を入力してください')
            .typeError('正しい値を入力してください')
            .required('clinic_idを入力してください。'),
          doctor_id: Yup.string()
            .matches(/^[a-zA-Z0-9]+$/, '正しい値を入力してください')
            .typeError('正しい値を入力してください')
            .required('doctor_idを入力してください'),
        })
      )
  })

  const exceptKeys = ['checked', 'errorMessage']

  return (<>
    <div className={'staff-header'}>
      <div className={'staff-title'}>
        {i18n.t('staff.menuBar.doctorOutputInsurance')}
        <BreadCrumb
          items={[
            {
              title: i18n.t('staff.menuBar.doctorOutputInsurance'),
            },
          ]}
        />
      </div>
      <div className='staff-buttons'>
        <Button
          icon='pi pi-download'
          className='p-button-normal'
          onClick={downloadSample}
          label={i18n.t('main.sampleCsv')}
        />
      </div>
    </div>
    <div className='container is-max'>
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          data: [],
          clinics: convertClinic(edges),
          clinic_id: '',
          disabled: false,
          clinic_name: '',
          validate: false,
        }}
        validationSchema={validationSchema}
        render={formikProps => {
          const {
            values,
            errors,
            setFieldValue,
            handleSubmit,
            touched,
          } = formikProps
          const {data} = values
          let keys = []
          if (data.length > 0) {
            keys = Object.keys(data[0]).filter(
              key => key !== 'errorMessage' || key !== 'checked'
            )
          }
          return (
            <>
              {
                isUploadPermitted || isUploadCheckPermitted ? (
                  <div className={'box'}>
                    <div className={'box-top'} style={{alignItems: 'center'}}>
                      <AutoComplete
                        disabled={values.disabled}
                        {...formikProps}
                        name={'clinic'}
                        field='name'
                        searchFields={['name']}
                        allSuggestions={values.clinics}
                        suggestionName='clinics'
                        width='60vw'
                        maxWidth='300px'
                        placeholder='クリニックを選択'
                        setCurrentClinicData={
                          async val => {
                            setFieldValue('clinic_id', val.id)
                            setFieldValue('clinic_name', val.name)
                            setFieldValue('clinics', convertClinic(edges))
                          }
                        }
                      />
                      <div className={'doctor__output-action'}>
                        {data.length > 0 && (
                          <Button
                            type={'button'}
                            onClick={() => {
                              setFieldValue('data', [])
                              setFieldValue('disabled', false)
                            }}
                            label={i18n.t('main.cancel')}
                            className='p-button-secondary'
                            icon='pi pi-times'
                          />
                        )}
                        {
                          isUploadCheckPermitted ? (
                            <Button
                              disabled={data.length === 0 || isUploaded || !values.clinic_id}
                              type={'button'}
                              label={i18n.t('staff.csvUpload.check')}
                              onClick={() => {
                                if (
                                  (errors.data && errors.data.length > 0) ||
                                  values.data.find(d => d.errorMessage)
                                ) {
                                  popup.error(i18n.t('main.hasCsvFileError'))
                                } else {
                                  checkCsvData({clinic_id: values.clinic_id}, {data: values.data}, {setFieldValue})
                                }
                              }}
                            />
                          ) : null
                        }
                        {
                          isUploadPermitted ? (
                            <Button
                              onClick={() => {
                                if (
                                  (errors.data && errors.data.length > 0) ||
                                  values.data.find(d => d.errorMessage)
                                ) {
                                  popup.error(i18n.t('main.hasCsvFileError'))
                                } else {
                                  handleSubmit()
                                }
                              }}
                              disabled={data.length === 0 || isUploaded || !values.clinic_id || !values.validate}
                              type={'submit'}
                              label={'PDFダウンロード'}
                            />
                          ) : null
                        }
                      </div>
                    </div>
                    {
                      data.length < 1 ? <Dropzone
                          disabled={!values.clinic_id || isUploaded}
                          onDrop={files => {
                            if (files.length > 0) {
                              Papa.parse(files[0], {
                                header: true,
                                complete: async results => {
                                  const {meta, data, errors} = results
                                  const renderData = data.map(obj => {
                                    let cleanedObj = {};
                                    for (let key in obj) {
                                      let trimmedKey = key.trim();
                                      let trimmedValue = obj[key].trim();
                                      if (trimmedKey && trimmedValue && (trimmedKey === 'clinic_id' || trimmedKey === 'doctor_id')) {
                                        cleanedObj[trimmedKey] = trimmedValue;
                                      }
                                    }
                                    return cleanedObj;
                                  }).filter(obj => {
                                    return Object.keys(obj).length > 0 && Object.entries(obj).some(([key, value]) => {
                                      return key.trim() && value.trim();
                                    });
                                  });
                                  setFieldValue('data', renderData)
                                  setFieldValue('disabled', true)
                                },
                              })
                            }
                          }}
                          onError={error => console.log(error)}
                          accept='.csv'
                        >
                          {({getRootProps, getInputProps}) => (
                            <div {...getRootProps({className: 'dropzone'})}>
                              <input {...getInputProps()} />
                              <p>{i18n.t('doctor.uploadFile.dropFileHere')}</p>
                            </div>
                          )}
                        </Dropzone> :
                        <div className='overflow-container'>
                          <table className='table'>
                            <thead>
                            <tr>
                              <th>{i18n.t('main.action')}</th>
                              {keys.map(
                                (key, index) =>
                                  !exceptKeys.includes(key) && (
                                    <th key={`${key}_${index}`}>
                                      {translateKeys[key]}
                                    </th>
                                  )
                              )}
                            </tr>
                            </thead>
                            <tbody>
                            <FieldArray
                              name={'data'}
                              render={({remove}) => (
                                <>
                                  {data.map((item, i) => {
                                    const error =
                                      errors.data && errors.data.length > 0
                                        ? errors.data[i]
                                        : false
                                    const isChecked = data[i].checked
                                    const errorMessage =
                                      item.errorMessage ||
                                      Object.keys(error || {}).reduce(
                                        (acc, curr) => {
                                          return `${acc}\n${error[curr]}`
                                        },
                                        ''
                                      )
                                    return (
                                      <React.Fragment key={i}>
                                        <tr>
                                          {!errorMessage ? (
                                            <td>
                                              {isChecked ? (
                                                <Button
                                                  icon='pi pi-check'
                                                  className='p-button-success'
                                                  tooltip={'OK'}
                                                />
                                              ) : (
                                                <Button
                                                  icon='pi pi-info'
                                                  tooltip={i18n.t(
                                                    'staff.csvUpload.notChecked'
                                                  )}
                                                />
                                              )}
                                            </td>
                                          ) : (
                                            <td>
                                              <div>
                                                <Button
                                                  onClick={() => remove(i)}
                                                  icon='pi pi-times'
                                                  className='p-button-danger'
                                                />
                                              </div>
                                            </td>
                                          )}
                                          {keys.map(
                                            key =>
                                              !exceptKeys.includes(key) && (
                                                <td key={key}>{item[key]}</td>
                                              )
                                          )}
                                        </tr>
                                        {errorMessage && (
                                          <tr key={`errorMessage_${i}`}>
                                            <td colSpan={keys.length + 1}>
                                              <div className='alert is-left'>
                                                {errorMessage}
                                              </div>
                                            </td>
                                          </tr>
                                        )}
                                      </React.Fragment>
                                    )
                                  })}
                                </>
                              )}
                            />
                            </tbody>
                          </table>
                        </div>
                    }
                  </div>
                ) : null
              }
            </>
          )
        }}
      />
    </div>
  </>);
};

export default StaffDoctorOutputInsuranceScene;