import React from 'react'
import * as Yup from 'yup'
import { DatePicker, Select } from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import './CreateWaitingRecruitment.scss'
import { InputTextarea } from 'primereact/inputtextarea'
import { Button } from 'primereact/button'
import SplitsAutoComplete from '@medical/pages/staff/components/ShiftFormSplitsAutoComplete'
import { MultiSelect } from 'primereact/multiselect'
import moment from 'moment'
import { ConfirmationModal } from '@medical/components'
import { Form, Formik } from 'formik'

export const CreateWaitingRecruitmentScene = ({
  isCreateForm = true,
  onSubmit,
  i18n,
  clinicalDepartments,
  deleteRecruitmentShift,
  availableWaitingShift,
}) => {
  const validationSchema = Yup.object().shape({
    clinics: Yup.array().min(
      1,
      i18n.t('doctor.waitingRecruitment.validate.clinics.required')
    ),
    startDate: Yup.date(
      i18n.t('doctor.waitingRecruitment.validate.startDate.required')
    ).required(i18n.t('doctor.waitingRecruitment.validate.startDate.required')),
    endDate: Yup.date()
      .required(i18n.t('doctor.waitingRecruitment.validate.endDate.required'))
      .min(
        Yup.ref('startDate'),
        i18n.t('doctor.waitingRecruitment.validate.endDate.min')
      ),
    startTime: Yup.string().required(
      i18n.t('doctor.waitingRecruitment.validate.startTime.required')
    ),
    endTime: Yup.string().required(
      i18n.t('doctor.waitingRecruitment.validate.endTime.required')
    ),
    description: Yup.string().nullable(),
  })

  function disabledDate(current) {
    return (
      current &&
      current <
        moment()
          .subtract(1, 'days')
          .endOf('day')
    )
  }

  const clinicalDepartmentLabel = clinic =>
    clinicalDepartments.find(e => e.id === clinic).clinicName

  return (
    <div className='create-waiting-recruit'>
      <div className='small-modal-title modal-title'>
        {i18n.t('doctor.waitingRecruitment.title')}
      </div>
      <div className='modal-content small-modal-content'>
        <Formik
          initialValues={{
            clinics: availableWaitingShift
              ? availableWaitingShift.recruitmentShifts.map(e => e.clinic)
              : [],
            startDate: availableWaitingShift
              ? moment(availableWaitingShift.startDate).utc()
              : '',
            endDate: availableWaitingShift
              ? moment(availableWaitingShift.endDate).utc()
              : '',
            startTime: availableWaitingShift
              ? moment(availableWaitingShift.startTime)
              : moment(new Date()).set({
                  hour: 9,
                  minute: 0,
                  second: 0,
                  millisecond: 0,
                }),
            endTime: availableWaitingShift
              ? moment(availableWaitingShift.endTime)
              : moment(new Date()).set({
                  hour: 9,
                  minute: 1,
                  second: 0,
                  millisecond: 0,
                }),
            description: availableWaitingShift
              ? availableWaitingShift.description
              : '',
            submitCfDialog: false,
            deleteCfDialog: false,
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          render={formikProps => {
            const {
              setFieldValue,
              handleSubmit,
              touched,
              errors,
              values,
            } = formikProps
            return (
              <Form>
                <div className='tr align-top'>
                  <div className='th'>
                    {i18n.t('doctor.waitingRecruitment.clinicName')}
                  </div>
                  <div className='td'>
                    <MultiSelect
                      name='clinics'
                      options={clinicalDepartments.map(e => ({
                        value: e.id,
                        label: e.clinicName,
                      }))}
                      value={values.clinics}
                      onChange={e => {
                        setFieldValue('clinics', e.value)
                      }}
                      filter
                      placeholder={i18n.t(
                        'doctor.waitingRecruitment.clinicSelect'
                      )}
                      fixedPlaceholder
                    />
                    <Select
                      name='clinics'
                      mode='multiple'
                      onChange={e => {
                        setFieldValue('clinics', e)
                      }}
                      options={values.clinics.map(e => ({
                        label: clinicalDepartmentLabel(e),
                        value: e,
                      }))}
                      value={values.clinics}
                      style={{ width: '100%' }}
                      open={false}
                    />
                    {errors.clinics && touched.clinics ? (
                      <div className='error'>{errors.clinics}</div>
                    ) : null}
                  </div>
                </div>
                <div className='tr'>
                  <div className='th'>
                    {i18n.t('doctor.waitingRecruitment.workingDate')}
                  </div>
                  <div className='td date-picker'>
                    <div style={{ minWidth: 150 }}>
                      <DatePicker
                        name='startDate'
                        className='date-picker'
                        onChange={e => setFieldValue('startDate', e)}
                        disabledDate={disabledDate}
                        value={values.startDate}
                        placeholder={i18n.t(
                          'doctor.waitingRecruitment.startDateSelect'
                        )}
                        locale={locale}
                        allowClear={false}
                      />
                      {errors.startDate && touched.startDate ? (
                        <div className='error'>{errors.startDate}</div>
                      ) : null}
                    </div>
                    <span className='mx-2'>〜</span>
                    <div style={{ minWidth: 150 }}>
                      <DatePicker
                        name='endDate'
                        value={values.endDate}
                        onChange={e => setFieldValue('endDate', e)}
                        className='date-picker'
                        disabledDate={disabledDate}
                        placeholder={i18n.t(
                          'doctor.waitingRecruitment.endDateSelect'
                        )}
                        locale={locale}
                        allowClear={false}
                      />
                      {errors.endDate && touched.endDate ? (
                        <div className='error'>{errors.endDate}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className='tr'>
                  <div className='th workingHour-start'>
                    <p>
                      <b>
                        {i18n.t('doctor.waitingRecruitment.workingHourStart')}
                      </b>
                    </p>
                    <SplitsAutoComplete
                      name='startTime'
                      value={values.startTime}
                      onChange={e => setFieldValue('startTime', e.value)}
                      minDate={moment
                        .utc(new Date())
                        .set({ hour: 7, minute: 0, second: 0, millisecond: 0 })}
                      disabled={false}
                    />
                    {errors.startTime && touched.startTime ? (
                      <div className='error'>{errors.startTime}</div>
                    ) : null}
                  </div>
                  <div className='td workingHour-end'>
                    <p>
                      <b>
                        {i18n.t('doctor.waitingRecruitment.workingHourEnd')}
                      </b>
                    </p>
                    <SplitsAutoComplete
                      name='endTime'
                      value={values.endTime}
                      onChange={e => setFieldValue('endTime', e.value)}
                      minDate={values.startTime}
                      disabled={false}
                    />
                    {errors.endTime && touched.endTime ? (
                      <div className='error'>{errors.endTime}</div>
                    ) : null}
                  </div>
                </div>
                <div className='tr tr-wrap'>
                  ※こちらで登録した日時に募集シフトが作成された場合、通知を受け取ることができます。募集シフト作成の通知を受け取りましたら、応募をしてください。応募後、事務局が承認次第勤務（シフト）確定となります。
                  こちらの募集待ち登録が完了しても、勤務（シフト）確定ではないのでご注意ください。
                </div>
                <div className='tr tr-wrap tr-note'>
                  <p>
                    <b>{i18n.t('doctor.waitingRecruitment.note')}</b>
                  </p>
                  <InputTextarea
                    name='description'
                    value={values.description}
                    onChange={e => setFieldValue('description', e.target.value)}
                    placeholder={i18n.t(
                      'doctor.waitingRecruitment.notePlaceholder'
                    )}
                  />
                </div>
                <div className='tr button-row'>
                  {!isCreateForm && (
                    <Button
                      className='btn-main-outline-color'
                      label={i18n.t('doctor.waitingRecruitment.delete')}
                      type='button'
                      onClick={e => setFieldValue('deleteCfDialog', true)}
                    />
                  )}
                  <Button
                    className='btn-main-color'
                    label={i18n.t('doctor.waitingRecruitment.update')}
                    type='submit'
                  />
                </div>
                <ConfirmationModal
                  handleSubmit={e => {
                    e.preventDefault()
                    setFieldValue('deleteCfDialog', false)
                    deleteRecruitmentShift()
                  }}
                  handleNoClick={() => setFieldValue('deleteCfDialog', false)}
                  isSubmitting={false}
                  values={values}
                  visibleKey={'deleteCfDialog'}
                  confirmationMessage={i18n.t(
                    'doctor.waitingRecruitment.deleteCfMessage'
                  )}
                />
              </Form>
            )
          }}
        />
      </div>
    </div>
  )
}
