import CustomCalendarPicker from '@medical/components/CalendarPicker'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import { splitLogicReverse } from '@medical/libs'
import InputTextarea from '@medical/components/InputTextarea'
import TextInput from '@medical/components/TextInput'
import { SHIFT_FORM_STEP } from '@medical/constant'
import ShiftFormWorkOutsideSplits from '@medical/pages/staff/components/ShiftFormWorkOutsideSplits'
import { Formik, ErrorMessage } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import React from 'react'
import * as Yup from 'yup'

const WorkOutsideClinicDetailScene = ({
  i18n,
  onSubmit,
  clinicalDepartments,
  workOutside,
}) => {
  const minDate = moment()
  const maxDate = moment().add(3, 'months')
  const date = moment(workOutside.startTime)
    .startOf('day')
    .hour(9)
  const clinicSelected = clinicalDepartments.filter(
    it => it.id === workOutside.clinicalDepartment.id
  )
  return (
    <Formik
      enableReinitialize
      validationSchema={Yup.object().shape({
        clinicDepartment: Yup.object()
          .nullable()
          .required(i18n.t('validation.clinicalDepartment.required')),
        address: Yup.string().required('場所を入力してください'),
        workContent: Yup.string().required('業務内容を入力してください'),
      })}
      initialValues={{
        confirm: false,
        clinicDepartment: clinicSelected[0],
        address: workOutside.address,
        workContent: workOutside.workContent,
        startTime: workOutside.startTime,
        date: date.toDate(),
        splits: splitLogicReverse({
          availableShift: workOutside,
        }),
      }}
      onSubmit={onSubmit}
      render={formikProps => {
        const {
          setFieldValue,
          handleSubmit,
          handleChange,
          values,
        } = formikProps
        const props = { ...formikProps, i18nKey: 'staff.createShift' }
        return (
          <div>
            <div
              className='modal-title'
              style={{ textAlign: 'left!important' }}
            >
              {i18n.t('main.workOutsideClinic.title')}
            </div>
            <div className='tr'>
              <div className='th'>
                {i18n.t('main.workOutsideClinic.clinicName')}
              </div>
              <div className='td'>
                <Dropdown
                  {...formikProps}
                  name='clinicDepartment'
                  optionLabel='name'
                  valueLabel='id'
                  value={values.clinicDepartment}
                  placeholder='クリニックを選択'
                  options={clinicalDepartments}
                  onChange={e => {
                    setFieldValue('clinicDepartment', e.target.value)
                  }}
                  style={{ width: '210px' }}
                />
                <ErrorMessage
                  className='alert'
                  component='div'
                  name='clinicDepartment'
                />
              </div>
            </div>
            <CustomCalendarPicker
              {...formikProps}
              label={i18n.t('main.workOutsideClinic.workingDay')}
              placeholder={i18n.t('main.workOutsideClinic.workingDay')}
              name='date'
              onChange={val => {
                handleChange(val)
              }}
              minDate={minDate.toDate()}
              maxDate={maxDate.toDate()}
            />

            <ShiftFormWorkOutsideSplits
              {...formikProps}
              i18n={i18n}
              isDoctor
              isMoneyPermitted
            />
            <TextInput
              {...formikProps}
              name='address'
              label={i18n.t('main.workOutsideClinic.address')}
            />
            <InputTextarea
              {...formikProps}
              name='workContent'
              label={i18n.t('main.workOutsideClinic.workContent')}
            />
            {workOutside.status === 'WAITING_APPROVAL' ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div
                  className='col-md-12 center-block '
                  style={{ marginRight: '18px' }}
                >
                  <Button
                    className='cancel-button'
                    onClick={() => {
                      setFieldValue('confirmationMessage', '却下しますか？')
                      setFieldValue('functionName', 'delete')
                      setFieldValue('confirm', true)
                    }}
                    label='キャンセル'
                  />
                </div>
                <div className='col-md-12 center-block'>
                  <Button
                    className='reflect-button'
                    onClick={() => {
                      setFieldValue('confirmationMessage', '承認しますか？')
                      setFieldValue('functionName', 'update')
                      setFieldValue('confirm', true)
                    }}
                    label='申請する'
                  />
                </div>
              </div>
            ) : null}
            <ConfirmationModal
              {...formikProps}
              confirmationMessage={values.confirmationMessage}
            />
          </div>
        )
      }}
    />
  )
}

export default WorkOutsideClinicDetailScene
