import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, NotFound, ProgressSpinner } from '@medical/components'
import { DEFAULT_HOURLY_WAGE_ACTION } from '@medical/constant'
import {
  STAFF_DELETE_DEFAULT_HOULRY_WAGE,
  STAFF_UPDATE_DEFAULT_HOULRY_WAGE,
} from '@medical/constant/permissions'
import {
  checkStaffPermission,
  clinicalDepartmentNames,
  combineNames,
  convertDoctorsConnection,
  sortAndFilterByClinicOrder,
} from '@medical/libs'
import {
  DOCTOR_CONNECTION,
  DOCTORS_DEPARTMENTS,
} from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import DoctorHourlyWageForm from '@medical/pages/staff/HourlyPayment/DoctorHourlyWageForm'
import {
  GET_DOCTOR_DEFAULT_HOURLY_WAGE,
  STAFF_DELETE_DEFAULT_HOURLY_WAGE,
  STAFF_UPDATE_DEFAULT_HOURLY_WAGE,
} from '@medical/pages/staff/HourlyPayment/HourlyPayment.graphql'
import {
  convertBooleanToDay,
  convertDayInMonthToMatrix,
} from '@medical/pages/staff/RecruitmentScheduleShift/CreateRecruitmentScheduleShift/function'
import { useCustom } from '@medical/provider/context'
import moment from 'moment'
import React from 'react'

const DetailDoctorHourlyWage = ({
  match: {
    params: { id },
  },
  closeModal,
}) => {
  const [{ i18n, popup }] = useCustom()
  const {
    loading: loadingData,
    error: errorData,
    data: hourlyWageData,
  } = useQuery(GET_DOCTOR_DEFAULT_HOURLY_WAGE, {
    variables: {
      where: {
        id,
      },
    },
  })

  const { loading, error, data } = useQuery(DOCTORS_DEPARTMENTS)
  const [staffUpdateDefaultHourlyWage] = useMutation(
    STAFF_UPDATE_DEFAULT_HOURLY_WAGE
  )
  const [staffDeleteDefaultHourlyWage] = useMutation(
    STAFF_DELETE_DEFAULT_HOURLY_WAGE
  )

  const {
    loading: doctorLoading,
    error: doctorError,
    data: doctorData,
  } = useQuery(DOCTOR_CONNECTION, {
    variables: {
      where: {
        deletedAt: null,
        registrationStatus_in: ['CREATED_BY_STAFF', 'ACCEPTED'],
        blocked: false,
      },
      first: 10,
      skip: 0,
      orderBy: 'unSigned_ASC',
    },
  })
  if (loading || loadingData || doctorLoading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  if (errorData) return <ErrorComponent error={errorData} />
  if (doctorError) return <ErrorComponent error={doctorError} />
  if (
    !hourlyWageData ||
    !hourlyWageData.defaultHourlyWagesConnection ||
    !hourlyWageData.defaultHourlyWagesConnection.edges ||
    !hourlyWageData.defaultHourlyWagesConnection.edges[0] ||
    !hourlyWageData.defaultHourlyWagesConnection.edges[0].node
  )
    return <NotFound />
  const onSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      const {
        doctor,
        workPattern,
        clinicalDepartment,
        rangeDate,
        repeatSetting,
        isAllday,
        isMonday,
        isTuesday,
        isWednesday,
        isThursday,
        isFriday,
        isSaturday,
        isSunday,
        isDayoff,
        listWeekDay,
        listWeekDay1,
        listWeekDay2,
        listWeekDay3,
        listWeekDay4,
        time,
        hourlyWage,
        action,
      } = values
      const startDate = `${moment(rangeDate[0]).format(
        'YYYY-MM-DD'
      )}T00:00:00.000Z`
      const endDate = `${moment(rangeDate[1]).format(
        'YYYY-MM-DD'
      )}T00:00:00.000Z`
      const startTime = `1000-10-04T${moment.utc(time[0]).format('HH:mm:ss')}Z`
      const endTime = `1000-10-04T${moment.utc(time[1]).format('HH:mm:ss')}Z`
      const listBooleanDayInMonth = convertDayInMonthToMatrix(
        listWeekDay,
        listWeekDay1,
        listWeekDay2,
        listWeekDay3,
        listWeekDay4
      )
      if (action === DEFAULT_HOURLY_WAGE_ACTION.UPDATE) {
        await staffUpdateDefaultHourlyWage({
          variables: {
            doctorDefaultHourlyWageId: id,
            workPattern: workPattern.value,
            startDate,
            endDate,
            startTime,
            endTime,
            hourlyWage: parseInt(hourlyWage),
            dateMatrix: JSON.stringify(listBooleanDayInMonth),
            isAvailableOnMonday: isMonday,
            isAvailableOnTuesday: isTuesday,
            isAvailableOnWednesday: isWednesday,
            isAvailableOnThursday: isThursday,
            isAvailableOnFriday: isFriday,
            isAvailableOnSaturday: isSaturday,
            isAvailableOnSunday: isSunday,
            isAvailableOnHoliday: isDayoff,
            repeatSetting,
            doctorId: doctor.id,
            clinicalDepartmentId: clinicalDepartment.id,
          },
        })
      }
      if (action === DEFAULT_HOURLY_WAGE_ACTION.DELETE) {
        await staffDeleteDefaultHourlyWage({
          variables: {
            doctorDefaultHourlyWageId: id,
          },
        })
      }
      popup.success(i18n.t('staff.scheduledShiftGroup.submissionSuccess'))
      setSubmitting(false)
      resetForm()
      closeModal()
    } catch (error) {
      popup.error(error)
      setFieldValue('confirm', false)
      setSubmitting(false)
    }
  }
  const isUpdateDefaultHourlyWage = checkStaffPermission({
    functionName: STAFF_UPDATE_DEFAULT_HOULRY_WAGE,
  })
  const isDeleteDefaultHourlyWage = checkStaffPermission({
    functionName: STAFF_DELETE_DEFAULT_HOULRY_WAGE,
  })
  const { clinicalDepartments, departments } = data
  const sortedClinicalDepartments = sortAndFilterByClinicOrder({
    clinicalDepartments,
    isFilter: true,
  })
  const departmentNames = clinicalDepartmentNames({ departments })
  const { node } = hourlyWageData.defaultHourlyWagesConnection.edges[0]
  if (node) {
    if (node.repeatSetting === 'MONTHLY') {
      const matrixDate = JSON.parse(node.dateMatrix)
      node.listWeekDay =
        matrixDate && matrixDate[0] ? convertBooleanToDay(matrixDate[0]) : []
      node.listWeekDay1 =
        matrixDate && matrixDate[1] ? convertBooleanToDay(matrixDate[1]) : []
      node.listWeekDay2 =
        matrixDate && matrixDate[2] ? convertBooleanToDay(matrixDate[2]) : []
      node.listWeekDay3 =
        matrixDate && matrixDate[3] ? convertBooleanToDay(matrixDate[3]) : []
      node.listWeekDay4 =
        matrixDate && matrixDate[4] ? convertBooleanToDay(matrixDate[4]) : []
    }

    const {
      isAvailableOnMonday,
      isAvailableOnTuesday,
      isAvailableOnWednesday,
      isAvailableOnThursday,
      isAvailableOnFriday,
    } = node
    if (
      isAvailableOnMonday &&
      isAvailableOnTuesday &&
      isAvailableOnWednesday &&
      isAvailableOnThursday &&
      isAvailableOnFriday &&
      node.repeatSetting === 'WEEKLY'
    ) {
      node.isAllday = true
    }
  }
  return (
    <DoctorHourlyWageForm
      i18n={i18n}
      doctors={convertDoctorsConnection(doctorData.doctorsConnection.edges)}
      onSubmit={onSubmit}
      clinicalDepartments={combineNames({
        clinicalDepartments: sortedClinicalDepartments,
        departmentNames,
      })}
      node={node}
      isUpdateDefaultHourlyWage={isUpdateDefaultHourlyWage}
      isDeleteDefaultHourlyWage={isDeleteDefaultHourlyWage}
    />
  )
}
export default DetailDoctorHourlyWage
