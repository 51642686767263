import BreadCrumb from '@medical/components/Breadcrumb'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import ExportDoctorCSV from '@medical/components/ExportDoctorCSV'
import ModalLink from '@medical/components/ModalLink'
import Router from '@medical/routes/router'
import { Formik } from 'formik'
import { Button, Button as PrimeButton } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Paginator } from 'primereact/paginator'
import queryString from 'query-string'
import React from 'react'
import { Link } from 'react-router-dom'

const DoctorsDeleteRequestList = ({
  i18n,
  page,
  rowsPerPage,
  fullname,
  email,
  edges,
  count,
  onPageChange,
  onSubmit,
  restore,
  confirmDelete,
  isConfirmDeleteRequestPermitted,
  isRestorePermitted,
  departmentNames,
  staffId,
}) => (
  <React.Fragment>
    <div className='staff-header'>
      <div className='staff-title'>
        医師データ削除申請
        <BreadCrumb
          items={[
            {
              title: '医師データ削除申請',
            },
          ]}
        />
      </div>
    </div>
    <div className='container is-max'>
      <div className='box'>
        <Formik
          enableReinitialize
          initialValues={{
            fullname,
            email,
          }}
          render={formikProps => {
            const { values, handleChange, handleBlur } = formikProps
            return (
              <div className='search-parent'>
                <InputText
                  name='fullname'
                  value={values.fullname}
                  placeholder={i18n.t('main.fullname')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={{ width: '200px', marginRight: '20px' }}
                />
                <InputText
                  name='email'
                  value={values.email}
                  placeholder={i18n.t('main.email')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={{ width: '200px', marginRight: '20px' }}
                />
                <Link
                  to={{
                    pathname: Router.staffDoctorsDeleteRequestList,
                    search: queryString.stringify({
                      rowsPerPage,
                      fullname: values.fullname,
                      email: values.email,
                    }),
                  }}
                  style={{ marginLeft: '20px' }}
                >
                  <Button label={i18n.t('main.search')} icon='pi pi-search' />
                </Link>
                {fullname || email ? (
                  <Link
                    to={{
                      pathname: Router.staffDoctorsDeleteRequestList,
                      search: queryString.stringify({
                        rowsPerPage,
                      }),
                    }}
                  >
                    <Button
                      icon='pi pi-times'
                      className='p-button-secondary'
                      style={{ marginLeft: '20px' }}
                    />
                  </Link>
                ) : null}
              </div>
            )
          }}
        />
        <Formik
          initialValues={{
            confirm: false,
            doctorId: '',
            functionName: '',
            doctorName: '',
            doctorEmail: '',
          }}
          onSubmit={onSubmit}
          render={formikProps => {
            const { values, setFieldValue } = formikProps
            let confirmationMessage
            switch (values.functionName) {
              case confirmDelete:
                confirmationMessage = `この医師データを完全に削除しますか？（医師名：　${values.doctorName} メールアドレス：　${values.doctorEmail}）削除した医師データは復元することができません。個人情報は特定できない形で上書きされます。`
                break
              case restore:
                confirmationMessage = 'この医師データ削除申請を取り消しますか？'
                break
              default:
                break
            }

            return (
              <div className='overflow-container'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th width='10%'>{i18n.t('updateProfile.fullname')}</th>
                      <th width='15%'>{i18n.t('updateProfile.email')}</th>
                      <th width='10%'>{i18n.t('updateProfile.phoneNumber')}</th>
                      <th width='15%'>{i18n.t('updateProfile.departments')}</th>
                      <th width='5%'>詳細</th>
                      <th width='20%'>{i18n.t('main.action')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {edges.map(({ node }) => (
                      <tr
                        key={node.id}
                        className={node.blocked ? 'table-block' : ''}
                      >
                        <td>{`${node.lastname} ${node.firstname}`}</td>
                        <td>
                          <a href={`mailto:${node.email}`}>{node.email}</a>
                        </td>
                        <td>{node.phoneNumber}</td>
                        <td>
                          <div className='flex-parent'>
                            {node.departments.sort().map(department => (
                              <div className='slashed-item' key={department}>
                                {departmentNames[department]}
                              </div>
                            ))}
                          </div>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <ModalLink
                            to={Router.get(Router.staffDoctorDetail, {
                              doctorId: node.id,
                            })}
                          >
                            <Button icon='pi pi-info' />
                          </ModalLink>
                        </td>
                        {isConfirmDeleteRequestPermitted ||
                        isRestorePermitted ? (
                          <td>
                            <div className='action-doctor-list'>
                              {isRestorePermitted ? (
                                <Button
                                  onClick={() => {
                                    setFieldValue('doctorId', node.id)
                                    setFieldValue(
                                      'doctorName',
                                      `${node.lastname} ${node.firstname}`
                                    )
                                    setFieldValue(
                                      'doctorEmail',
                                      `${node.email}`
                                    )
                                    setFieldValue('functionName', restore)
                                    setFieldValue('confirm', true)
                                  }}
                                  className='reflect-button'
                                  label='削除申請取り消し'
                                />
                              ) : null}
                              {isConfirmDeleteRequestPermitted ? (
                                <Button
                                  onClick={() => {
                                    setFieldValue('doctorId', node.id)
                                    setFieldValue(
                                      'doctorName',
                                      `${node.lastname} ${node.firstname}`
                                    )
                                    setFieldValue(
                                      'doctorEmail',
                                      `${node.email}`
                                    )
                                    setFieldValue('functionName', confirmDelete)
                                    setFieldValue('confirm', true)
                                  }}
                                  // className='hard-delete-button'
                                  label='医師データ削除'
                                  disabled={
                                    node.deletedBy
                                      ? staffId === node.deletedBy.id
                                      : false
                                  }
                                />
                              ) : null}
                            </div>
                          </td>
                        ) : null}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <ConfirmationModal
                  {...formikProps}
                  confirmationMessage={confirmationMessage}
                />
              </div>
            )
          }}
        />
        <Paginator
          rows={rowsPerPage}
          totalRecords={count}
          first={(page - 1) * rowsPerPage}
          onPageChange={onPageChange}
          leftContent={
            <div className='paginator-total'>
              {i18n.t('main.paginationTotal')}: {count}
            </div>
          }
        />
      </div>
    </div>
  </React.Fragment>
)

export default DoctorsDeleteRequestList
