import { DOCTOR_FRAGMENTS } from '@medical/layout/DoctorLayout/DoctorLayoutRequest.graphql'
import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import gql from 'graphql-tag'

export const SKILL_CONNECTIONS = gql`
  query SkillsConnection($skip: Int, $first: Int, $where: SkillWhereInput) {
    skillsConnection(skip: $skip, first: $first, where: $where) {
      aggregate(where: $where) {
        count
      }
      edges {
        node {
          id
          name
          createdAt
          updatedAt
          isShow
          createdBy {
            id
            lastname
            firstname
          }
          updatedBy {
            id
            lastname
            firstname
          }
        }
      }
    }
  }
`
export const CREATE_ACTIVITY = gql`
  mutation CREATE_ACTIVITY_LOG($activity: ActivityLogTypes!, $doctorId: ID) {
    createActivityLogHistory(activity: $activity, doctorId: $doctorId)
  }
`
