import BreadCrumb from '@medical/components/Breadcrumb'
import React from 'react'
import Router from '@medical/routes/router'
import { Link } from 'react-router-dom'


const ClinicsManageHome = ({ i18n, listState, setCurrentState }) => {
  return (
    <React.Fragment>
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('staff.menuBar.clinics')}
          <div className='breadcrumb'>
            <Link to={Router.home}>{i18n.t('main.home')}</Link>
            <Link to={Router.staffClinics}>
              <i className='pi pi-chevron-right' />
              {i18n.t('staff.menuBar.clinics')}
            </Link>
            <a onClick={() => setCurrentState(listState.state0)}>
              <i className='pi pi-chevron-right' />
              マネジメント
            </a>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='box' style={{ fontSize: '20px', fontWeight: 'bold' }}>
          <a onClick={() => setCurrentState(listState.state1)}>
            <p>新規拠点作成</p>
          </a>
          <a onClick={() => setCurrentState(listState.state2)}>
            <p>診療科編集</p>
          </a>
          <a onClick={() => setCurrentState(listState.state3)}>
            <p>交通費計算</p>
          </a>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ClinicsManageHome
