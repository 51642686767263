import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import gql from 'graphql-tag'

export const WORK_OUTSIDE_CONNECTION = gql`
  query WorkOutsidesConnection(
    $where: WorkOutsideWhereInput
    $first: Int
    $skip: Int
    $orderBy: WorkOutsideOrderByInput
  ) {
    workOutsidesConnection(
      where: $where
      first: $first
      skip: $skip
      orderBy: $orderBy
    ) {
      edges {
        node {
          id
          createdAt
          updatedAt
          deletedAt
          startTime
          endTime
          clinicalDepartment {
            id
            name
            clinic {
              id
              name
            }
          }
          createdBy {
            id
            lastname
            firstname
          }
          isStartTimeBreakTime
          splitDateTime3
          splitDateTime2
          splitDateTime1
          isSplitDateTime1BreakTime
          isSplitDateTime3BreakTime
          isSplitDateTime2BreakTime
          address
          workContent
          status
        }
      }
      aggregate(where: $where) {
        count
      }
    }
    ...DEPARTMENTS_FRAGMENT
  }
  ${DEPARTMENTS_FRAGMENT}
`

