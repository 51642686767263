import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import { WORK_SCHEDULE_FRAGMENTS } from '@medical/pages/doctor/Schedule/Schedule.graphql'
import gql from 'graphql-tag'

export const WORK_SCHEDULE_CREATE = gql`
  mutation WORK_SCHEDULE_CREATE(
    $availableShiftId: ID!
    $doctorComment: String
  ) {
    doctorCreateWorkSchedule(
      availableShiftId: $availableShiftId
      comment: $doctorComment
    ) {
      ...WORK_SCHEDULE_FRAGMENTS
    }
  }
  ${WORK_SCHEDULE_FRAGMENTS}
`

export const SEND_REQUESTED_SCHEDULE_MAIL = gql`
  mutation SEND_REQUESTED_SCHEDULE_MAIL($requestedAvailableShiftIds: [ID!]!) {
    doctorSendRequestedWorkSchedule(
      requestedAvailableShiftIds: $requestedAvailableShiftIds
    )
  }
`

export const DEPARTMENTS_LIST = gql`
  query DEPARTMENTS_LIST {
    ...DEPARTMENTS_FRAGMENT
  }
  ${DEPARTMENTS_FRAGMENT}
`
