import ClinicNameRender from '@medical/components/ClinicNameRender'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import { STAFF_STATUS } from '@medical/constant'
import { splitLogicReverse } from '@medical/libs'
import ShiftFormSplits from '@medical/pages/staff/components/ShiftFormSplits'
import { Checkbox, Input, TimePicker } from 'antd'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import React, { useState } from 'react'
import * as Yup from 'yup'

const WorkScheduleOverTimeDetailScene = ({
  i18n,
  onSubmit,
  dataOvertime,
  departmentNames,
  workSchedule,
  isMoneyPermitted,
  cancel,
  accept,
  withdrawalOfApproval,
  acceptedShift,
  data,
}) => {
  const disabled = dataOvertime.status !== 'WAITING_FOR_APPROVAL'
  const [confirmationMessage, setConfirmationMessage] = useState()

  const { TextArea } = Input
  const { clinicalDepartment, startTime, endTime } = workSchedule

  const dataSplits = splitLogicReverse({
    availableShift: data,
  })
  // if (dataOvertime.endTime && dataOvertime.status === 'WAITING_FOR_APPROVAL') {
  //   dataSplits.push({
  //     date: moment(dataOvertime.endTimeSplit).toDate(),
  //     hourlyWage: 0,
  //     isBreakTime: false,
  //   })
  // }

  const validationSchema = Yup.object().shape({
    splits: Yup.array().of(
      Yup.object().shape({
        date: Yup.date().required(i18n.t('validation.date.required')),
        hourlyWage: isMoneyPermitted
          ? Yup.number(i18n.t('validation.hourlyWage.number')).required(
              i18n.t('validation.hourlyWage.required')
            )
          : undefined,
      })
    ),
  })
  const {
    splitDateTime1,
    splitDateTime2,
    splitDateTime3,
    isStartTimeBreakTime,
    isSplitDateTime1BreakTime,
    isSplitDateTime2BreakTime,
    isSplitDateTime3BreakTime,
  } = acceptedShift
  const workingHours = (time1, time2, time3) => {
    if (time1 && time2 && time3) {
      return (
        <>
          {moment(startTime).format('HH:mm')}〜{moment(time1).format('HH:mm')}
          {isSplitDateTime1BreakTime ? ' (休憩時間)' : ''}
          <br />
          {moment(time1).format('HH:mm')}〜{moment(time2).format('HH:mm')}
          {isSplitDateTime2BreakTime ? ' (休憩時間)' : ''}
          <br />
          {moment(time2).format('HH:mm')}〜{moment(time3).format('HH:mm')}
          {isSplitDateTime3BreakTime ? ' (休憩時間)' : ''}
          <br />
          {moment(time3).format('HH:mm')}〜{moment(endTime).format('HH:mm')}
          {isStartTimeBreakTime ? ' (休憩時間)' : ''}
          <br />
        </>
      )
    }
    if (time1 && time2) {
      return (
        <>
          {moment(startTime).format('HH:mm')}〜{moment(time1).format('HH:mm')}
          {isSplitDateTime1BreakTime ? ' (休憩時間)' : ''}
          <br />
          {moment(time1).format('HH:mm')}〜{moment(time2).format('HH:mm')}
          {isSplitDateTime2BreakTime ? ' (休憩時間)' : ''}
          <br />
          {moment(time2).format('HH:mm')}〜{moment(endTime).format('HH:mm')}
          {isStartTimeBreakTime ? ' (休憩時間)' : ''}
          <br />
        </>
      )
    }
    if (time1) {
      return (
        <>
          {moment(startTime).format('HH:mm')}〜{moment(time1).format('HH:mm')}
          {isSplitDateTime1BreakTime ? ' (休憩時間)' : ''}
          <br />
          {moment(time1).format('HH:mm')}〜{moment(endTime).format('HH:mm')}
          {isStartTimeBreakTime ? ' (休憩時間)' : ''}
          <br />
        </>
      )
    }
    return (
      <>
        {moment(startTime).format('HH:mm')}〜{moment(endTime).format('HH:mm')}
        {isStartTimeBreakTime ? ' (休憩時間)' : ''}
      </>
    )
  }
  return (
    <Formik
      enableReinitialize
      initialValues={{
        confirm: false,
        splits: dataSplits,
        doctor: dataOvertime.doctor,
        // startTime: dataOvertime.startTimeSplit
        //   ? moment(dataOvertime.startTimeSplit).toDate()
        //   : moment(workSchedule.acceptedShift.startTime).toDate(),
        startTime: data.startTime,
        date: moment(data.startTime).toDate(),
        endTime: data.endTime,
        scheduleDate: moment(workSchedule.startTime).format('LL (dd)'),
        functionName: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={formikProps => {
        const { setFieldValue, validateForm, setTouched } = formikProps
        return (
          <div className='container move-expenses-container'>
            <div className='row' style={{ justifyContent: 'center' }}>
              <div
                className='move-expenses-header__title'
                style={{ fontSize: '18px', fontWeight: '100' }}
              >
                {i18n.t('staff.overTime.title')}
              </div>
            </div>
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='overtime_title'>{i18n.t('main.clinicName')}</div>
              <div className='width-67'>
                {ClinicNameRender({ clinicalDepartment })}
              </div>
            </div>
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='overtime_title'>{i18n.t('main.departments')}</div>
              <div className='width-67 '>
                {departmentNames[clinicalDepartment.name]}
              </div>
            </div>
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='overtime_title'>{i18n.t('main.date')}</div>
              <div className='width-67 '>{moment(startTime).format('LL')}</div>
            </div>
            {dataOvertime.status === STAFF_STATUS.WAITING_FOR_APPROVAL && (
              <div className='row mt-15 move-inline move-expenses-item-container'>
                <div className='overtime_title'>
                  {i18n.t('staff.overTime.registeredCurrent')}
                </div>
                {workingHours(splitDateTime1, splitDateTime2, splitDateTime3)}
              </div>
            )}

            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='overtime_title'>
                {i18n.t('doctor.overTime.timeWork')}
              </div>
              {workSchedule.workScheduleActualTime && (
                <div className='width-67 '>
                  {` ${moment(
                    workSchedule.workScheduleActualTime.startTime
                  ).format('HH:mm')}
                  〜
                  ${moment(workSchedule.workScheduleActualTime.endTime).format(
                    'HH:mm'
                  )}`}
                </div>
              )}
            </div>
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='overtime_title'>
                {i18n.t('main.movingExpensesOther.type')}
              </div>
              <div className=' checkbox'>
                <Checkbox checked={dataOvertime.earlyDeparture}>
                  {i18n.t('doctor.overTime.earlyArrival')}
                </Checkbox>
                <Checkbox checked={dataOvertime.lateArrival}>
                  {i18n.t('doctor.overTime.overTime')}
                </Checkbox>
              </div>
            </div>
            {/* <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='overtime_title'>
                {i18n.t('staff.overTime.time')}
              </div>
              <div>
                {dataOvertime.startTime && (
                  <div className='tr'>
                    <div className=''>{i18n.t('main.startTime')}</div>
                    <div className='mobile-cart'>
                      <TimePicker
                        format='HH:mm'
                        disabled
                        value={moment(dataOvertime.startTime, 'HH:mm')}
                      />
                    </div>
                  </div>
                )}
                {dataOvertime.endTime && (
                  <div className='tr'>
                    <div className='mt-15'>{i18n.t('main.endTime')}</div>
                    <div className='mobile-cart mt-15'>
                      <TimePicker
                        format='HH:mm'
                        disabled
                        value={moment(dataOvertime.endTime, 'HH:mm')}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div> */}
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='overtime_title'>
                {i18n.t('staff.overTime.reason')}
              </div>
              <div className='width-67 '>
                {dataOvertime.reasonEarlyDeparture && (
                  <div className='tr'>
                    <div className=''>
                      {i18n.t('doctor.overTime.reasonEarlyArrival')}
                    </div>
                    <div className='td'>
                      <TextArea
                        autoSize
                        disabled
                        value={dataOvertime.reasonEarlyDeparture}
                      />
                    </div>
                  </div>
                )}
                {dataOvertime.reasonLateArrival && (
                  <div className='tr'>
                    <div className=''>
                      {i18n.t('doctor.overTime.reasonOverTime')}
                    </div>
                    <div className='td'>
                      <TextArea
                        autoSize
                        disabled
                        value={dataOvertime.reasonLateArrival}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            {data.comment && (
              <div className='row mt-15 move-inline move-expenses-item-container'>
                <div className='overtime_title'>
                  {i18n.t('staff.overTime.comment')}
                </div>
                <div className='width-67'>
                  <div className='td' style={{ padding: '15px 15px 15px 0' }}>
                    <TextArea autoSize disabled value={data.comment} />
                  </div>
                </div>
              </div>
            )}
            <ShiftFormSplits
              {...formikProps}
              i18n={i18n}
              classDisabled
              isMoneyPermitted={isMoneyPermitted}
              disabled={disabled}
            />
            {!dataOvertime.deletedAt && (
              <div className='modal-bottom' style={{ marginBottom: '5px' }}>
                <Button
                  type='submit'
                  className='outline-red'
                  label={
                    dataOvertime.status === STAFF_STATUS.WAITING_FOR_APPROVAL
                      ? i18n.t('staff.movingExpensesOtherDetail.btnReject')
                      : i18n.t(
                          'staff.acceptedWorkSchedulesList.approvalWithdrawal'
                        )
                  }
                  onClick={() => {
                    validateForm().then(response => {
                      if (!Object.keys(response).length) {
                        setFieldValue('confirm', true)
                        if (
                          dataOvertime.status ===
                          STAFF_STATUS.WAITING_FOR_APPROVAL
                        ) {
                          setFieldValue('functionName', cancel)
                        } else {
                          setFieldValue('functionName', withdrawalOfApproval)
                        }
                      } else {
                        setTouched(response)
                      }
                    })
                    setConfirmationMessage(
                      dataOvertime.status === STAFF_STATUS.WAITING_FOR_APPROVAL
                        ? i18n.t(
                            'staff.movingExpensesOtherDetail.messageReject'
                          )
                        : i18n.t(
                            'staff.acceptedWorkSchedulesList.messageWithdrawal'
                          )
                    )
                  }}
                />
                {dataOvertime.status !== STAFF_STATUS.ACCEPTED && (
                  <Button
                    className='reflect-button btn'
                    label={i18n.t('staff.movingExpensesOtherDetail.btnApprove')}
                    type='submit'
                    onClick={() => {
                      validateForm().then(response => {
                        if (!Object.keys(response).length) {
                          setFieldValue('confirm', true)
                          setFieldValue('functionName', accept)
                        } else {
                          setTouched(response)
                        }
                      })
                      setConfirmationMessage(
                        i18n.t('staff.movingExpensesOtherDetail.messageAccepct')
                      )
                    }}
                  />
                )}
              </div>
            )}

            <div className='confirmation-moving'>
              <ConfirmationModal
                {...formikProps}
                confirmationMessage={confirmationMessage}
              />
            </div>
          </div>
        )
      }}
    />
  )
}
export default WorkScheduleOverTimeDetailScene
