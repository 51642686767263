import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import {
  ENUM_ACCEPTED_WS,
  ENUM_AVAILABLE_SHIFT,
  ENUM_CANCELED_WS,
  ENUM_FIXED_WS,
  ENUM_NOT_ACCEPTED_WS,
  ENUM_UNCONFIRMED_SHIFT,
  HOLIDAY_URL,
  ENUM_MASS_RECRUITMENT,
} from '@medical/constant'
import { useAxios } from '@medical/hooks'
import {
  clinicalDepartmentNames,
  combineNames,
  dailyToTimeline,
  dateValidation,
  sortAndFilterByClinicOrder,
  thisMonthHolidays,
} from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import Router from '@medical/routes/router'
import moment from 'moment'
import queryString from 'query-string'
import { pathOr } from 'ramda'
import React, { useEffect, useMemo } from 'react'
import { Redirect } from 'react-router-dom'

import {
  CLINICS_DAILY_REPORT,
  REGION_QUERY,
  WORK_SCHEDULES_CONNECTION,
} from './DailyAvailableShift.graphql'
import DailyAvailableShiftScene from './DailyAvailableShiftScene'
import { CLINICS_DAILY_OPEN_TIME } from './DailyOpenTime.graphql'

const DailyAvailableShift = ({
  history,
  match: {
    path,
    url,
    params: {
      year = moment().year(),
      month = moment().month() + 1,
      day = moment().day(),
    },
  },
  location: { search },
}) => {
  const [{ i18n }] = useCustom()
  const date = moment()
    .startOf('day')
    .year(year)
    .month(month - 1)
    .date(day || 1)
  const newDate = useMemo(
    () =>
      moment(date)
        .format()
        .split('+09:00')[0],
    [date]
  )

  const {
    loading: wsLoading,
    error: wsError,
    data: wsData,
    refetch,
  } = useQuery(WORK_SCHEDULES_CONNECTION, {
    variables: {
      where: {
        accepted: false,
        deletedStatus: null,
        Doctor: {
          deletedAt: null,
        },
      },
    },
  })
  const { loading, error, data } = useQuery(CLINICS_DAILY_REPORT, {
    variables: {
      date,
      where: {
        Clinic: {
          deletedAt: null,
        },
      },
    },
    fetchPolicy: 'network-only',
  })

  const {
    loading: listRegionLoading,
    error: listRegionError,
    data: listRegionData,
  } = useQuery(REGION_QUERY, {
    variables: {
      deletedAt: null,
      orderBy: 'position_ASC'
    },
  })

  const {
    loading: holidayLoading,
    data: holidaysData,
    error: holidaysError,
  } = useAxios({
    url: HOLIDAY_URL,
    params: {
      timeMin: moment(date)
        .startOf('month')
        .toISOString(),
      timeMax: moment(date)
        .endOf('month')
        .toISOString(),
    },
  })

  const holidays = thisMonthHolidays({
    date,
    holidaysData: pathOr([], ['items'], holidaysData),
  })

  let isHoliday = false
  for (const element of holidays) {
    if (element.date === moment(date).format('YYYY-MM-DD')) isHoliday = true
  }

  const {
    loading: openTimeLoading,
    error: openTimeError,
    data: openTimeData,
  } = useQuery(CLINICS_DAILY_OPEN_TIME, {
    variables: {
      date: newDate,
      isHoliday,
    },
    fetchPolicy: 'network-only',
  })
  useEffect(() => {
    refetch()
  })

  const currentDate = moment(`${year} ${month} ${day}`, 'YYYY MM DD')
  if (
    (dateValidation({ year, month, day }) &&
      currentDate.isBefore(moment().subtract(1, 'years'), 'month')) ||
    [
      Router.staffDailyAvailableShiftsWithoutDay,
      Router.staffDailyAvailableShiftsWithoutMonthDay,
      Router.staffDailyAvailableShiftsWithoutYearMonthDay,
    ].includes(path)
  )
    return (
      <Redirect
        to={Router.get(Router.staffDailyAvailableShifts, {
          year: moment().year(),
          month: moment().month() + 1,
          day: moment().date(),
        })}
      />
    )
  if (
    loading ||
    holidayLoading ||
    openTimeLoading ||
    wsLoading ||
    listRegionLoading
  )
    return <ProgressSpinner />
  if (error || holidaysError || openTimeError || wsError || listRegionError)
    return (
      <ErrorComponent
        error={
          error || holidaysError || openTimeError || wsError || listRegionError
        }
      />
    )
  const {
    clinicalDepartmentIds = [],
    statuses = [
      ENUM_AVAILABLE_SHIFT,
      ENUM_ACCEPTED_WS,
      ENUM_NOT_ACCEPTED_WS,
      ENUM_CANCELED_WS,
      ENUM_FIXED_WS,
      ENUM_UNCONFIRMED_SHIFT,
      ENUM_MASS_RECRUITMENT,
    ],
  } = queryString.parse(search)
  const clinicalDepartmentNamesFilter =
    JSON.parse(localStorage.getItem('monthly_clinicalDepartments')) || []
  const clinicIds = JSON.parse(localStorage.getItem('monthly_clinics')) || []
  const regionIds = JSON.parse(localStorage.getItem('monthly_regions')) || []

  const getClinicalDepartmentId = clinicalDepartmentList => {
    if (clinicalDepartmentList && clinicalDepartmentList.length > 0) {
      const result = clinicalDepartmentList
        .map(i => {
          if (clinicalDepartmentNamesFilter.includes(i.name)) {
            return i.id
          }
        })
        .filter(id => id != null)
      if (result && result.length > 0) return result
      return []
    }
    return []
  }

  const clinicalDepartmentIdList =
    clinicalDepartmentIds && clinicalDepartmentIds.length > 0
      ? clinicalDepartmentIds
      : getClinicalDepartmentId(data?.clinicalDepartments || [])

  const onRegionChange = e => {
    history.push({
      search: queryString.stringify({
        statuses,
      }),
    })
    const regionIds = e.value.map(({ id }) => id)
    localStorage.setItem('monthly_regions', JSON.stringify(regionIds))
  }

  const onClinicChange = e => {
    history.push({
      search: queryString.stringify({
        statuses,
      }),
    })
    const clinicIds = e.value.map(({ id }) => id)
    localStorage.setItem('monthly_clinics', JSON.stringify(clinicIds))
  }

  const onDepartmentChange = names => {
    history.push({
      search: queryString.stringify({
        statuses,
      }),
    })
    const newClinicalDepartmentNames = names.value.map(({ name }) => name)
    localStorage.setItem(
      'monthly_clinicalDepartments',
      JSON.stringify(newClinicalDepartmentNames)
    )
  }

  const onCheckboxChange = (e, valueRadios) => {
    const filteredStatuses = [...statuses]
    if (valueRadios) {
      for (let valueRadio of valueRadios) {
        if (statuses.includes(valueRadio)) {
          filteredStatuses.splice(filteredStatuses.indexOf(valueRadio), 1)
        }
      }
      if (!statuses.includes(e.value)) {
        filteredStatuses.push(e.value)
      }
    } else {
      if (statuses.includes(e.value)) {
        filteredStatuses.splice(filteredStatuses.indexOf(e.value), 1)
      } else {
        filteredStatuses.push(e.value)
      }
    }
    history.push({
      search: queryString.stringify({
        statuses: filteredStatuses,
      }),
    })
  }
  const departmentNames = clinicalDepartmentNames({
    departments: data.departments,
  })

  const listRegions = listRegionData?.regions.map(item => ({
    id: item.id,
    region: item.name,
  }))

  data.clinicalDepartments.forEach(department => {
    const matchingTimeSheet = openTimeData.clinicTimeSheet.find(
      timeSheet => department.id === timeSheet.clinicalDepartmentId
    )

    if (matchingTimeSheet) {
      const {
        startTimeMorning,
        endTimeMorning,
        isAvailableMorning,
        startTimeAfternoon,
        endTimeAfternoon,
        isAvailableAfternoon,
      } = matchingTimeSheet

      department.startTimeMorning = startTimeMorning
      department.endTimeMorning = endTimeMorning
      department.isAvailableMorning = isAvailableMorning

      department.startTimeAfternoon = startTimeAfternoon
      department.endTimeAfternoon = endTimeAfternoon
      department.isAvailableAfternoon = isAvailableAfternoon
    }
  })

  const sortedClinicalDepartments = sortAndFilterByClinicOrder({
    clinicalDepartments: data.clinicalDepartments,
    isFilter: true,
    isDisplayCancelShift: true,
  })

  const mappedDataClinic = sortedClinicalDepartments.map(item => ({
    id: item.clinic.id,
    clinicName: item.clinic.name,
  }))

  const uniqueClinicName = [
    ...new Map(mappedDataClinic.map(item => [item[`id`], item])).values(),
  ]

  const { groups, uniqueItems } = dailyToTimeline({
    data: sortedClinicalDepartments,
    i18n,
    clinicalDepartmentIds: clinicalDepartmentIdList,
    statuses,
    departmentNames,
    regionIds,
    clinicIds,
  })

  return (
    <DailyAvailableShiftScene
      i18n={i18n}
      date={date}
      groups={groups}
      items={uniqueItems}
      statuses={statuses}
      search={search}
      onCheckboxChange={onCheckboxChange}
      url={url}
      holidays={holidays}
      history={history}
      isNewWS={wsData?.workSchedulesConnection?.edges?.length}
      listRegions={listRegions}
      regionIds={regionIds}
      onRegionChange={onRegionChange}
      onClinicChange={onClinicChange}
      onDepartmentChange={onDepartmentChange}
      uniqueClinicName={uniqueClinicName}
      clinicIds={clinicIds}
      departmentNames={data?.departments.map(item => {
        return {
          departmentName: item.description,
          name: item.value,
        }
      })}
      clinicalDepartmentNamesFilter={clinicalDepartmentNamesFilter}
    />
  )
}

export default DailyAvailableShift
