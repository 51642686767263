import { useApolloClient, useSubscription } from '@apollo/react-hooks'
import { INQUIRY_SENDER } from '@medical/constant'
import { useCustom } from '@medical/provider/context'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'

import { CONVERSATIONS_LIST, STAFF_MSG_SUBSCRIPTION } from './Inquiries.graphql'
import InquiriesScene from './InquiriesScene'

const Inquiries = ({ location: { search } }) => {
  const { doctorId, doctorName, conversationId } = queryString.parse(search)
  const [{ i18n, popup }, { setNewMsg }] = useCustom()
  const client = useApolloClient()
  const [statusSearch, setStatusSearch] = useState('')
  const [dataConversation, setDataConversation] = useState([])
  const [first] = useState(10)
  const [skip, setSkip] = useState(0)
  const [isEndConversation, setIsEndConversation] = useState(0)
  const variables = {
    first,
    skip,
    orderBy: 'receivedLastMessageAt_DESC',
    where: {
      status_in: statusSearch ? [statusSearch] : ['IN_RESPONSE', 'COMPLETED'],
      Doctor: {
        id_not: null,
      }
    },
  }
  const getConversations = async (offset = 0, limit = 10) => {
    try {
      const {
        data: { conversations },
      } = await client.query({
        query: CONVERSATIONS_LIST,
        variables: {
          ...variables,
          first: limit,
          skip: offset,
        },
        fetchPolicy: 'network-only',
      })
      if (conversations.length === 0) {
        setIsEndConversation(1)
      }
      return conversations
    } catch (error) {
      return []
    }
  }

  const getMoreConversation = async () => {
    try {
      if (isEndConversation === 0) {
        const edges = await getConversations(skip + 10)
        setDataConversation([...dataConversation, ...edges])
        setSkip(skip + 10)
      }
    } catch (error) {
      popup.error(error)
    }
  }
  useEffect(() => {
    async function fetchMyAPI() {
      const edges = await getConversations()
      setDataConversation([...edges])
    }
    fetchMyAPI()
  }, [statusSearch])

  useSubscription(STAFF_MSG_SUBSCRIPTION, {
    variables,
    onSubscriptionData: () => {
      async function getMsg() {
        const edges = await getConversations()
        setDataConversation(edges)
        setSkip(0)
        setIsEndConversation(0)
      }
      getMsg()
    },
  })

  return (
    <InquiriesScene
      i18n={i18n}
      setNewMsg={setNewMsg}
      popup={popup}
      conversations={
        statusSearch
          ? dataConversation.filter(item => item.status === statusSearch)
          : dataConversation
      }
      inquirySender={INQUIRY_SENDER.STAFF}
      doctorId={doctorId || ''}
      doctorNameFromParams={doctorName || ''}
      conversationIdFromParams={conversationId || ''}
      statusSearch={statusSearch}
      setStatusSearch={e => {
        setStatusSearch(e)
        setIsEndConversation(0)
        setSkip(0)
      }}
      getMoreConversation={getMoreConversation}
    />
  )
}

export default Inquiries
