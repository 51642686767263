import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import { CLINICAL_DEPARTMENTS_QUERY } from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import gql from 'graphql-tag'

export const AVAILABLE_SHIFTS_CONNECTION = gql`
  query AVAILABLE_SHIFT_CONNECTION(
    $where: AvailableShiftWhereInput
    $first: Int
    $skip: Int
    $orderBy: AvailableShiftOrderByInput
  ) {
    availableShiftsConnection(
      where: $where
      first: $first
      skip: $skip
      orderBy: $orderBy
    ) {
      edges {
        node {
          id
          createdAt
          startTime
          endTime
          hourlyWage
          splitHourlyWage1
          splitHourlyWage2
          splitHourlyWage3
          splitDateTime1
          splitDateTime2
          splitDateTime3
          dailySalary
          isPublished
          isSpecial
          comment
          recruitmentCategory(where: { deletedAt: null }) {
            id
            category
            status
            description
            deletedAt
          }
          scheduledAvailableShiftGroup {
            id
            recruitmentCategory(where: { deletedAt: null }) {
              id
              category
              status
              description
              deletedAt
            }
          }
          recruitmentShiftsType
          clinicalDepartment {
            id
            name
            departmentCode
            clinic {
              id
              name
              order
            }
          }
        }
      }
      aggregate(where: $where) {
        count
      }
    }
    ...DEPARTMENTS_FRAGMENT
    ...CLINICAL_DEPARTMENTS_QUERY
  }
  ${CLINICAL_DEPARTMENTS_QUERY}
  ${DEPARTMENTS_FRAGMENT}
`
export const AVAILABLE_SHIFTS_UNCONFIRMED = gql`
  query ClinicalDepartment($date: DateTime!) {
    clinicalDepartments {
      id
      departmentCode
      name
      extraName
      clinic {
        id
        name
      }
      monthlyAvailableShiftChouboshuu(date: $date) {
        id
        createdAt
        startTime
        endTime
        hourlyWage
        splitHourlyWage1
        splitHourlyWage2
        splitHourlyWage3
        splitDateTime1
        splitDateTime2
        splitDateTime3
        dailySalary
        isPublished
        isSpecial
        comment
        recruitmentShiftsType
        clinicalDepartment {
          id
          name
          departmentCode
          clinic {
            id
            name
            order
          }
        }
      }
    }
  }
`
export const MASS_RECRUITMENT_UNCONFIRM = gql`
  query ClinicalDepartment(
    $date: DateTime!
    $isUnConfirmedShift: Boolean
    $first: Int
    $skip: Int
  ) {
    clinicalDepartments(first: $first, skip: $skip) {
      id
      departmentCode
      name
      extraName
      clinic {
        id
        name
      }
      monthlyAvailableShiftChouboshuu(
        date: $date
        isUnConfirmedShift: $isUnConfirmedShift
      ) {
        id
        createdAt
        startTime
        endTime
        hourlyWage
        splitHourlyWage1
        splitHourlyWage2
        splitHourlyWage3
        splitDateTime1
        splitDateTime2
        splitDateTime3
        dailySalary
        isPublished
        isSpecial
        isDoubleRecruitment
        comment
        recruitmentShiftsType
        clinicalDepartment {
          id
          name
          departmentCode
          clinic {
            id
            name
            order
          }
        }
      }
    }
  }
`

export const WORKSCHEDULES_CONNECTION_DOWNLOAD_CSV = gql`
  query ClinicalDepartment(
    $date: DateTime!
    $first: Int
    $skip: Int
  ) {
    clinicalDepartments(first: $first, skip: $skip) {
      id
      departmentCode
      name
      extraName
      clinic {
        id
        name
        order
      }
      monthlyWorkSchedules(
        date: $date
      ) {
        id
        accepted
        startTime
        endTime
      }
    }
  }
`