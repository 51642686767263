import { useMutation } from '@apollo/react-hooks'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import ConfirmationModalResendForm from '@medical/components/ConfirmationModalResendForm'
import InputTextarea from '@medical/components/InputTextarea'
import MyNumberShow from '@medical/components/MyNumberShow'
import { DOWNLOAD_FILE } from '@medical/pages/staff/Doctors/DoctorDetail/DoctorDetail.graphql'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const DetailDoctorIdentityScene = ({
  doctor,
  doctorIdentity,
  doctorIdentityRelations,
  onSubmit,
  isProcessPermitted,
  isDeletePermitted,
}) => {
  const { i18n } = useTranslation()
  const [frontSideIdentityKey, setFrontSideIdentityKey] = useState(null)
  const [backSideIdentityKey, setBackSideIdentityKey] = useState(null)
  const [frontSideLicenseKey, setFrontSideDriverLicenseKey] = useState(null)
  const [backSideLicenseKey, setBackSideDriverLicenseKey] = useState(null)
  const [downloadFile] = useMutation(DOWNLOAD_FILE)
  const downloadFileSubmit = async (key, callback) => {
    if (!key || key.length === 0) return
    try {
      const {
        data: { downloadFileS3: fileURL },
      } = await downloadFile({
        variables: {
          key,
        },
      })
      callback(fileURL)
    } catch (error) {
      callback(null)
    }
  }

  useEffect(() => {
    downloadFileSubmit(doctorIdentity.frontSideIdentityKey, fileURL =>
      setFrontSideIdentityKey(fileURL)
    )
    downloadFileSubmit(doctorIdentity.backSideIdentityKey, fileURL =>
      setBackSideIdentityKey(fileURL)
    )
    downloadFileSubmit(doctorIdentity.frontSideDriverLicenseKey, fileURL =>
      setFrontSideDriverLicenseKey(fileURL)
    )
    downloadFileSubmit(doctorIdentity.backSideDriverLicenseKey, fileURL =>
      setBackSideDriverLicenseKey(fileURL)
    )
  }, [doctorIdentity])

  const renderMyNumber = number => {
    return number
      .toString()
      .replace(/(\d\d\d\d)(\d\d\d\d)(\d\d\d\d)/, '$1 $2 $3')
  }

  const base64Decode = input => {
    return Buffer.from(input, 'base64').toString('ascii')
  }

  const formatDoctorIdentityRelation = array => {
    if (!array || array.length === 0) return []

    const result = array.map(element => {
      const elementReplace = {}
      elementReplace.id = element.id
      elementReplace.name = element.name
      elementReplace.relation = element.relation
      elementReplace.identityNumber = base64Decode(element.identityNumber)
      elementReplace.birthday = moment(element.birthday).toDate()
      return elementReplace
    })
    return result
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        functionName: '',
        confirm: false,
        rejecting: false,
        accepting: false,
        deleting: false,
        rejectedReason: '',
        doctorIdentityId: doctorIdentity.id,
        fullName: doctor.fullName,
        email: doctor.email,
        birthday: moment(doctor.birthday).format('YYYY年MM月DD日'),
        identityNumber: doctorIdentity.identityNumber,
        frontSideIdentityKey: frontSideIdentityKey,
        backSideIdentityKey: backSideIdentityKey,
        frontSideLicenseKey: frontSideLicenseKey,
        backSideLicenseKey: backSideLicenseKey,
        frontSideIdentityImageKey: doctorIdentity.frontSideIdentityKey,
        backSideIdentityImageKey: doctorIdentity.backSideIdentityKey,
        frontSideLicenseImageKey: doctorIdentity.frontSideDriverLicenseKey,
        backSideLicenseImageKey: doctorIdentity.backSideDriverLicenseKey,
        relation: formatDoctorIdentityRelation(doctorIdentityRelations),
      }}
      onSubmit={onSubmit}
      render={formikProps => {
        const {
          initialValues,
          errors,
          isSubmitting,
          validateForm,
          setTouched,
          setFieldValue,
          setFieldError,
          handleSubmit,
          values,
        } = formikProps
        let confirmationMessage
        switch (values.functionName) {
          case 'accept':
            confirmationMessage = i18n.t(
              'staff.detailDoctorIdentity.confirmMessage'
            )
            break
          case 'resendRequest':
            confirmationMessage = i18n.t(
              'staff.detailDoctorIdentity.resendMessage'
            )
            break
          case 'delete':
            confirmationMessage = `この医師(${values.fullName})のマイナンバー情報を完全に削除しますか?
            マイナンバー情報とマイナンバーカード画像、身分証明書画像、扶養家族情報が全て完全に削除されます。
            削除後はデータを復元することができません。`
            break
          default:
            break
        }
        const validateAndConfirm = () =>
          validateForm().then(response => {
            if (!Object.keys(response).length) {
              setFieldValue('confirm', true)
            } else {
              setTouched(response)
            }
          })
        return (
          <React.Fragment>
            <div className='tr'>
              <div className='th'>{i18n.t('doctor.identity.fullName')}</div>
              <div className='td'>{values.fullName}</div>
            </div>
            <div className='tr'>
              <div className='th'>{i18n.t('doctor.identity.mailAddress')}</div>
              <div className='td'>{values.email}</div>
            </div>
            <div className='tr'>
              <div className='th'>{i18n.t('doctor.identity.birthday')}</div>
              <div className='td'>{values.birthday}</div>
            </div>
            <div className='tr'>
              <div className='th'>{i18n.t('doctor.identity.myNumber')}</div>
              <div className='td'>
                <MyNumberShow
                  value={renderMyNumber(base64Decode(values.identityNumber))}
                  disabled
                  classDisabled
                  inDetailScene
                />
              </div>
            </div>
            {/* Start Drop Image */}
            <div className='tr'>
              <div className='th'>
                {i18n.t('staff.detailDoctorIdentity.myNumberImage')}
              </div>
            </div>
            <div className='tr'>
              <div className='td' style={{ width: '50%' }}>
                {i18n.t('doctor.identity.front')}
              </div>
              <div className='td' style={{ width: '50%' }}>
                {i18n.t('doctor.identity.back')}
              </div>
            </div>
            <div className='tr'>
              <div className='td' style={{ width: '50%' }}>
                <div className='dropzone'>
                  {values.frontSideIdentityKey &&
                    values.frontSideIdentityKey.length > 0 && (
                      <img
                        alt={i18n.t('doctor.uploadFile.dropFileHere')}
                        src={values.frontSideIdentityKey}
                      />
                    )}
                </div>
              </div>
              <div className='td' style={{ width: '50%' }}>
                <div className='dropzone'>
                  {values.backSideIdentityKey &&
                    values.backSideIdentityKey.length > 0 && (
                      <img
                        alt={i18n.t('doctor.uploadFile.dropFileHere')}
                        src={values.backSideIdentityKey}
                      />
                    )}
                </div>
              </div>
            </div>
            {/* End Drop Image */}
            {/* Start Drop Image */}
            <div className='tr'>
              <div className='th'>
                {i18n.t('staff.detailDoctorIdentity.identityCard')}
              </div>
            </div>
            <div className='tr'>
              <div className='td' style={{ width: '50%' }}>
                {i18n.t('doctor.identity.front')}
              </div>
              <div className='td' style={{ width: '50%' }}>
                {i18n.t('doctor.identity.back')}
              </div>
            </div>
            <div className='tr'>
              <div className='td' style={{ width: '50%' }}>
                <div className='dropzone'>
                  {values.frontSideLicenseKey &&
                    values.frontSideLicenseKey.length > 0 && (
                      <img
                        alt={i18n.t('doctor.uploadFile.dropFileHere')}
                        src={values.frontSideLicenseKey}
                      />
                    )}
                </div>
              </div>
              <div className='td' style={{ width: '50%' }}>
                <div className='dropzone'>
                  {values.backSideLicenseKey &&
                    values.backSideLicenseKey.length > 0 && (
                      <img
                        alt={i18n.t('doctor.uploadFile.dropFileHere')}
                        src={values.backSideLicenseKey}
                      />
                    )}
                </div>
              </div>
            </div>
            {/* End Drop Image */}

            <div className='tr'>
              <div className='th'>
                {i18n.t('doctor.identity.familyIdentity')}
              </div>
            </div>

            <div className='container'>
              <table className='table-comment-container'>
                <thead
                  style={{
                    textAlign: 'left',
                  }}
                >
                  <tr>
                    <th
                      style={{
                        width: '18%',
                      }}
                    >
                      <span>{i18n.t('doctor.identity.personName')}</span>
                    </th>
                    <th
                      style={{
                        width: '2%',
                      }}
                    />
                    <th
                      style={{
                        width: '18%',
                      }}
                    >
                      <span>{i18n.t('doctor.identity.relation')}</span>
                    </th>
                    <th
                      style={{
                        width: '30%',
                      }}
                    >
                      <span style={{ padding: '0px 15px' }}>
                        {i18n.t('doctor.identity.birthday')}
                      </span>
                    </th>
                    <th
                      style={{
                        width: '30%',
                      }}
                    >
                      <span>{i18n.t('doctor.identity.myNumber')}</span>
                    </th>
                    <th
                      style={{
                        width: '2%',
                      }}
                    />

                    <th
                      style={{
                        width: '5%',
                      }}
                    />
                  </tr>
                </thead>
                <tbody>
                  {values.relation.map((doctorIdentityRelation, index) => (
                    <>
                      <tr
                        key={doctorIdentityRelation.id}
                        style={{ textAlign: 'left' }}
                      >
                        <td>{doctorIdentityRelation.name}</td>
                        <td />
                        <td>{doctorIdentityRelation.relation}</td>
                        <td>
                          {moment(doctorIdentityRelation.birthday).format(
                            'YYYY年MM月DD日'
                          )}
                        </td>
                        <td>
                          <MyNumberShow
                            value={renderMyNumber(
                              doctorIdentityRelation.identityNumber
                            )}
                            disabled
                            classDisabled
                            inDetailScene
                          />
                        </td>
                      </tr>
                      <div style={{ marginBottom: '20px' }} />
                    </>
                  ))}
                </tbody>
              </table>
            </div>
            <div className='modal-bottom'>
              {isDeletePermitted ? (
                <Button
                  className=''
                  onClick={() => {
                    setFieldValue('functionName', 'delete')
                    setFieldValue('deleting', true)
                  }}
                  disabled={isSubmitting}
                  type='submit'
                  label={i18n.t('staff.detailDoctorIdentity.deleteButton')}
                />
              ) : null}
              {isProcessPermitted ? (
                <>
                  <Button
                    className='cancel-button'
                    onClick={() => {
                      setFieldValue('functionName', 'resendRequest')
                      setFieldValue('rejecting', true)
                    }}
                    disabled={isSubmitting}
                    type='submit'
                    label={i18n.t('staff.detailDoctorIdentity.resendRequest')}
                  />
                  <Button
                    className='reflect-button'
                    onClick={() => {
                      setFieldValue('functionName', 'accept')
                      setFieldValue('accepting', true)
                    }}
                    disabled={isSubmitting}
                    type='submit'
                    label={i18n.t('staff.detailDoctorIdentity.confirm')}
                  />
                </>
              ) : null}
            </div>
            <ConfirmationModal
              {...formikProps}
              confirmationMessage={confirmationMessage}
            />
            <ConfirmationModal
              {...formikProps}
              handleSubmit={handleSubmit}
              handleNoClick={() => {
                setFieldValue('accepting', false)
              }}
              confirmationMessage={confirmationMessage}
              visibleKey='accepting'
            ></ConfirmationModal>
            <ConfirmationModal
              {...formikProps}
              handleSubmit={handleSubmit}
              handleNoClick={() => {
                setFieldValue('deleting', false)
              }}
              confirmationMessage={confirmationMessage}
              visibleKey='deleting'
            ></ConfirmationModal>
            <div className='resendRequest-form'>
              <ConfirmationModalResendForm
                {...formikProps}
                handleSubmit={() => {
                  if (
                    values.functionName === 'resendRequest' &&
                    !values.rejectedReason
                  ) {
                    setFieldError(
                      'rejectedReason',
                      i18n.t('staff.detailDoctorIdentity.reasonRequired')
                    )
                  } else {
                    validateAndConfirm()
                  }
                }}
                handleNoClick={() => {
                  setFieldValue('rejecting', false)
                  setFieldValue('rejectedReason', '')
                }}
                // confirmationMessage={confirmationMessage}
                visibleKey='rejecting'
              >
                {values.functionName === 'resendRequest' && (
                  <div>
                    <div className='th'>{i18n.t('main.resendReason')}</div>
                    <InputTextarea
                      {...formikProps}
                      name='rejectedReason'
                      myNumberDetail
                    />
                    <div className='alert'>{errors.rejectedReason}</div>
                  </div>
                )}
              </ConfirmationModalResendForm>
            </div>
          </React.Fragment>
        )
      }}
    />
  )
}

export default DetailDoctorIdentityScene
