import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, NotFound, ProgressSpinner } from '@medical/components'
import { STAFF_CREATE_REPLY_PERMISSION } from '@medical/constant/permissions'
import { checkStaffPermission } from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import React from 'react'

import { INQUIRY_QUERY, STAFF_CREATE_REPLY } from './SingleInquiry.graphql'
import SingleInquiryScene from './SingleInquiryScene'

const SingleInquiry = ({
  match: {
    params: { inquiryId },
  },
}) => {
  const [{ i18n, popup }] = useCustom()
  const { loading, error, data } = useQuery(INQUIRY_QUERY, {
    variables: {
      inquiryId,
    },
  })
  const isReplyPermitted = checkStaffPermission({
    functionName: STAFF_CREATE_REPLY_PERMISSION,
  })
  const mutationOptions = {
    refetchQueries: [
      {
        query: INQUIRY_QUERY,
        variables: {
          inquiryId,
        },
      },
    ],
    awaitRefetchQueries: true,
  }
  const [staffCreateReply] = useMutation(STAFF_CREATE_REPLY, mutationOptions)

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const { inquiry = {} } = data
  if (!inquiry) {
    return <NotFound />
  }

  const onSubmit = async ({ reply }, { setSubmitting, resetForm }) => {
    try {
      await staffCreateReply({
        variables: {
          inquiryId,
          reply,
        },
      })
      setSubmitting(false)
      resetForm()
      popup.success(i18n.t('staff.createReply.submissionSuccess'))
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }

  return (
    <SingleInquiryScene
      inquiry={inquiry}
      i18n={i18n}
      onSubmit={onSubmit}
      isReplyPermitted={isReplyPermitted}
    />
  )
}

export default SingleInquiry
