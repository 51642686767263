import './style.css'

import { FilePdfOutlined } from '@ant-design/icons'
import { useApolloClient, useMutation } from '@apollo/react-hooks'
import { ProgressSpinner } from '@medical/components'
import ChatboxImg from '@medical/components/ChatboxImg'
import ChatboxText from '@medical/components/ChatboxText'
import { INQUIRY_SENDER, MAX_FILE_SIZE, SCROLL_TYPE } from '@medical/constant'
import { checkIsPdf } from '@medical/constant/utilities'
import { DOCTORS_QUERY } from '@medical/pages/staff/Inquiries/Inquiries.graphql'
import { Popover, Switch } from 'antd'
import axios from 'axios'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import React, { useEffect, useRef, useState } from 'react'
import Dropzone from 'react-dropzone'

import { GET_POST_PRE_SIGNED_URL_CHAT } from './Inquiry.graphql'

const textAreaLength = text =>
  text.replace(/\n/g, '                            ').length

const InquiryChatForm = ({
  i18n,
  inquiryMessages,
  onSubmit,
  searchText,
  setSearchText,
  searchData,
  handleMarkAsRead,
  getMoreMessage,
  inquirySender,
  getMessagesByIndex,
  index,
  setIndex,
  doctorName,
  isReplyPermitted,
  doctorId,
  setConversationId,
  lastestIndex,
  isRecruitment,
  title,
  type,
  clinicalDepartment,
  conversationId,
  conversationStatus,
  updateConversationStatus,
}) => {
  const messagesEndRef = useRef(null)
  const [popOverVisible, setPopOverVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isLoadingNew, setIsLoadingNew] = useState(false)
  const client = useApolloClient()
  const [getPostPreSignedUrl] = useMutation(GET_POST_PRE_SIGNED_URL_CHAT)

  const renderType = typeDoctor => {
    if (typeDoctor === 'REGULAR_SHIFT_DOCTOR') return '定期非常勤'
    if (typeDoctor === 'PERMANENT_DOCTOR') return '常勤'
  }
  let disableInput = false
  if (inquirySender === INQUIRY_SENDER.STAFF) {
    if (!doctorName || !isReplyPermitted) {
      disableInput = true
    }
  }
  let msgRecruitment = `【常勤/定期非常勤募集に関する問い合わせ】
  ${title}\n`

  if (clinicalDepartment) {
    msgRecruitment += `${clinicalDepartment || ''}\n`
  }

  msgRecruitment += `  応募区分: ${renderType(type)}
  お問い合わせ内容（以下に文章を入力してください）：`
  // const trackScrolling = e => {
  //   const scrollHeight = e.target.scrollHeight + e.target.scrollTop
  //   if (
  //     scrollHeight === e.target.clientHeight ||
  //     scrollHeight === e.target.clientHeight + 1
  //   ) {
  //     setIsLoading(true)
  //     setTimeout(async () => {
  //       try {
  //         await getMoreMessage(SCROLL_TYPE.SCROLL_UP)
  //       } finally {
  //         setIsLoading(false)
  //       }
  //     }, 500)
  //   } else if (e.target.scrollTop === 0 && index > 0) {
  //     setIsLoading(true)
  //     setTimeout(async () => {
  //       try {
  //         await getMoreMessage(SCROLL_TYPE.SCROLL_DOWN)
  //       } finally {
  //         setIsLoading(false)
  //       }
  //     }, 500)
  //   }
  // }

  const checkOldestMessage = messages => {
    const lastIndex = messages[messages.length - 1].node.index
    return lastIndex === 1 || lastIndex === -1
  }

  const checkLastestMessage = messages => lastestIndex > messages[0].node.index

  const scrollToBottom = () => {
    if (messagesEndRef.current)
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    scrollToBottom()
  }, [])
  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        initialValues={{
          message: isRecruitment ? msgRecruitment : '',
          file: null,
          searchText: '',
          imageKey: '',
        }}
        onSubmit={onSubmit}
        render={formikProps => {
          const {
            values,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          } = formikProps
          return (
            <div
              className='chat-box'
              style={
                inquirySender === INQUIRY_SENDER.STAFF
                  ? {}
                  : { width: '80%', margin: 'auto' }
              }
            >
              <div className='header'>
                <div className='title'>
                  {doctorName && inquirySender === INQUIRY_SENDER.STAFF
                    ? doctorName
                    : i18n.t('doctor.inquiry.chatBoxTitle')}
                  {inquirySender === INQUIRY_SENDER.STAFF && conversationId && (
                    <span
                      className='toggle-published'
                      style={{ paddingLeft: '5px' }}
                    >
                      <Switch
                        checkedChildren='対応中'
                        unCheckedChildren='対応済み'
                        checked={conversationStatus === 'IN_RESPONSE'}
                        onChange={e => {
                          updateConversationStatus()
                        }}
                      />
                    </span>
                  )}
                </div>
                <div className='row'>
                  <InputText
                    className='inquiry-input'
                    placeholder={i18n.t('doctor.inquiry.searchPlaceholder')}
                    onChange={e => {
                      setSearchText(e.target.value)
                      setFieldValue('searchText', e.target.target)
                      setPopOverVisible(false)
                    }}
                    values={values}
                    value={
                      searchText && searchText.length ? values.searchText : ''
                    }
                    disabled={
                      (!doctorName && inquirySender === INQUIRY_SENDER.STAFF) || !conversationId
                    }
                  />
                  <Popover
                    placement='bottomRight'
                    content={
                      searchData &&
                      searchData.conversationContents &&
                      searchData.conversationContents.length > 0 ? (
                        <div>
                          {searchData.conversationContents.map(value => (
                            <div
                              className='list-doctor-item'
                              onClick={async () => {
                                await getMessagesByIndex(value.index)
                                setPopOverVisible(!popOverVisible)
                                setIndex(value.index)
                                const element = document.getElementById(
                                  value.index
                                )
                                if (element) {
                                  element.scrollIntoView({ behavior: 'smooth' })
                                }
                              }}
                            >
                              {value.message}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div>{i18n.t('staff.inquiries.noMessage')}</div>
                      )
                    }
                    trigger='click'
                    overlayClassName='drop-doctor'
                    visible={popOverVisible}
                  >
                    <Button
                      icon='pi pi-search'
                      disabled={
                        (!doctorName && inquirySender === INQUIRY_SENDER.STAFF) || !conversationId
                      }
                      onClick={async () => {
                        try {
                          const {
                            data: { doctor },
                          } = await client.query({
                            query: DOCTORS_QUERY,
                            variables: {
                              doctorId,
                            },
                            fetchPolicy: 'network-only',
                          })
                          if (doctor && doctor.conversation)
                            setConversationId(doctor.conversation.id)
                        } catch (e) {}
                        setPopOverVisible(!popOverVisible)
                      }}
                    />
                  </Popover>
                </div>
              </div>
              <div ref={messagesEndRef} />
              <div
                id='scrollingDiv'
                className={`${
                  values.message && textAreaLength(values.message) > 500
                    ? 'content content-small'
                    : 'content'
                }`}
                // onScroll={trackScrolling}
              >
                {isLoadingNew && <ProgressSpinner />}
                {!isLoadingNew &&
                  index > 0 &&
                  inquiryMessages.length > 0 &&
                  checkLastestMessage(inquiryMessages) && (
                    <Button
                      className='load-message-button'
                      onClick={() => {
                        setIsLoadingNew(true)
                        setTimeout(async () => {
                          try {
                            await getMoreMessage(SCROLL_TYPE.SCROLL_DOWN)
                          } finally {
                            setIsLoadingNew(false)
                          }
                        }, 500)
                      }}
                      label='メッセージを読み込む'
                    />
                  )}

                {inquiryMessages && inquiryMessages.length > 0 ? (
                  <>
                    {inquiryMessages.map(({ node }, i) => {
                      if (
                        inquiryMessages[i + 1] &&
                        moment(inquiryMessages[i].node.createdAt).format(
                          'YYYY/MM/DD'
                        ) >
                          moment(inquiryMessages[i + 1].node.createdAt).format(
                            'YYYY/MM/DD'
                          )
                      )
                        if (
                          !node.isCreatedByStaff ===
                          (inquirySender === INQUIRY_SENDER.DOCTOR)
                        ) {
                          return (
                            <>
                              {node.imageKey ? (
                                <div id={node.index}>
                                  <ChatboxImg
                                    value={node.imageKey}
                                    time={node.createdAt}
                                    right
                                    imageURL={node.imageURL || null}
                                  />
                                </div>
                              ) : (
                                <div id={node.index}>
                                  <ChatboxText
                                    value={node.message}
                                    time={node.createdAt}
                                    searchText={searchText}
                                    right
                                  />
                                </div>
                              )}
                              <div style={{ textAlign: 'center' }}>
                                {moment(
                                  inquiryMessages[i].node.createdAt
                                ).format('YYYY年MM月DD日')}
                              </div>
                            </>
                          )
                        } else
                          return (
                            <>
                              {node.imageKey ? (
                                <div id={node.index}>
                                  <ChatboxImg
                                    value={node.imageKey}
                                    time={node.createdAt}
                                    imageURL={node.imageURL || null}
                                  />
                                </div>
                              ) : (
                                <div id={node.index}>
                                  <ChatboxText
                                    value={node.message}
                                    time={node.createdAt}
                                    searchText={searchText}
                                  />
                                </div>
                              )}
                              <div style={{ textAlign: 'center' }}>
                                {moment(
                                  inquiryMessages[i].node.createdAt
                                ).format('YYYY年MM月DD日')}
                              </div>
                            </>
                          )
                      if (
                        !node.isCreatedByStaff ===
                        (inquirySender === INQUIRY_SENDER.DOCTOR)
                      ) {
                        return (
                          <>
                            {node.imageKey ? (
                              <div id={node.index}>
                                <ChatboxImg
                                  value={node.imageKey}
                                  time={node.createdAt}
                                  imageURL={node.imageURL || null}
                                  right
                                />
                              </div>
                            ) : (
                              <div id={node.index}>
                                <ChatboxText
                                  value={node.message}
                                  time={node.createdAt}
                                  searchText={searchText}
                                  right
                                />
                              </div>
                            )}
                          </>
                        )
                      }
                      return (
                        <>
                          {node.imageKey ? (
                            <div id={node.index}>
                              <ChatboxImg
                                value={node.imageKey}
                                time={node.createdAt}
                                imageURL={node.imageURL || null}
                              />
                            </div>
                          ) : (
                            <div id={node.index}>
                              <ChatboxText
                                value={node.message}
                                time={node.createdAt}
                                searchText={searchText}
                              />
                            </div>
                          )}
                        </>
                      )
                    })}
                    <div style={{ textAlign: 'center' }}>
                      {moment(
                        inquiryMessages[inquiryMessages.length - 1].node
                          .createdAt
                      ).format('YYYY年MM月DD日')}
                    </div>
                  </>
                ) : (
                  <div
                    className='empty-message'
                    style={{ textAlign: 'center' }}
                  >
                    {i18n.t('doctor.inquiry.emptyMessage')}
                  </div>
                )}
                {!isLoading &&
                  inquiryMessages.length > 0 &&
                  !checkOldestMessage(inquiryMessages) && (
                    <Button
                      className='load-message-button'
                      onClick={() => {
                        setIsLoading(true)
                        setTimeout(async () => {
                          try {
                            await getMoreMessage(SCROLL_TYPE.SCROLL_UP)
                          } finally {
                            setIsLoading(false)
                          }
                        }, 500)
                      }}
                      label='メッセージを読み込む'
                    />
                  )}
                {isLoading && <ProgressSpinner />}
              </div>
              <div className='input-field'>
                <form onSubmit={handleSubmit}>
                  <div className='input'>
                    <div
                      className={`${
                        values.message && textAreaLength(values.message) > 500
                          ? 'input-message message-double-height'
                          : 'input-message'
                      }`}
                    >
                      <InputTextarea
                        className='input-message-placeholder'
                        name='message'
                        value={values.message}
                        autoResize
                        onFocus={handleMarkAsRead}
                        disabled={disableInput}
                        onChange={e => {
                          setFieldValue('message', e.target.value)
                        }}
                        placeholder={
                          inquirySender === INQUIRY_SENDER.DOCTOR
                            ? 'こちらのフォームにメッセージを入力してください。カーソルをこちらのフォームに合わせると、メニューに表示されている未読マークが消えます。'
                            : 'こちらのフォームにメッセージを入力してください。カーソルをこちらのフォームに合わせると、メニューと医師名に表示されている未読マークが消えます。'
                        }
                      />
                    </div>
                    <div className='button-message'>
                      <div className='scc-fragment' />
                      <div
                        className={
                          values.file ? 'input-img' : 'input-img-small'
                        }
                      >
                        {loading ? (
                          <ProgressSpinner />
                        ) : (
                          <>
                            <Dropzone
                              disabled={disableInput}
                              onDrop={async files => {
                                if (files.length > 0) {
                                  setLoading(true)
                                  setFieldValue('file', files[0])
                                  const {
                                    data: postPreSignedUrl,
                                  } = await getPostPreSignedUrl({
                                    variables: {
                                      fileName: files[0].name,
                                      mimetype: files[0].type,
                                    },
                                  })
                                  if (postPreSignedUrl.createPresignedPostUrl) {
                                    try {
                                      const dataResponse = JSON.parse(
                                        postPreSignedUrl.createPresignedPostUrl
                                      )

                                      const {
                                        url,
                                        fields,
                                      } = dataResponse.fulfillmentValue
                                      const formData = new FormData()
                                      Object.keys(fields).forEach(key => {
                                        formData.append(key, fields[key])
                                      })
                                      formData.append('file', files[0])
                                      await axios({
                                        method: 'POST',
                                        url,
                                        data: formData,
                                        headers: {
                                          'Content-Type': 'multipart/form-data',
                                        },
                                      })

                                      setFieldValue('imageKey', fields.key)
                                    } catch (e) {
                                      return false
                                    }
                                    setLoading(false)
                                  }
                                }
                              }}
                              accept={[
                                'image/jpeg',
                                'image/png',
                                'application/pdf',
                              ]}
                              maxSize={MAX_FILE_SIZE}
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div
                                  {...getRootProps({
                                    className: values.file
                                      ? 'dropzone btn-w-140'
                                      : 'dropzone dropzone-small btn-w-140',
                                  })}
                                >
                                  <input {...getInputProps()} />
                                  {values.file ? (
                                    <>
                                      <button
                                        type='button'
                                        className='close-img-btn'
                                        onClick={e => {
                                          e.preventDefault()
                                          e.stopPropagation()
                                          setFieldValue('file', '')
                                        }}
                                      >
                                        X
                                      </button>
                                      {checkIsPdf(values.file.name) ? (
                                        <div style={{ textAlign: 'center' }}>
                                          <FilePdfOutlined
                                            style={{
                                              fontSize: '40px',
                                              textAlign: 'center',
                                            }}
                                          />
                                          <p>{values.file.name}</p>
                                        </div>
                                      ) : (
                                        <img
                                          alt={i18n.t(
                                            'doctor.uploadFile.dropFileHere'
                                          )}
                                          src={
                                            values.file
                                              ? URL.createObjectURL(values.file)
                                              : null
                                          }
                                          style={{
                                            maxHeight: '60px',
                                            maxWidth: '110px',
                                          }}
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <i className='pi pi-file' />
                                  )}
                                </div>
                              )}
                            </Dropzone>
                          </>
                        )}
                      </div>

                      <div
                        className={
                          values.file
                            ? 'submit-button submit-button-small'
                            : 'submit-button'
                        }
                      >
                        {isSubmitting ? (
                          <ProgressSpinner />
                        ) : (
                          <Button
                            type='submit'
                            className='reflect-button btn-w-150'
                            disabled={!values.file && !values.message}
                            onClick={() => {
                              handleSubmit()
                              // setIsRecruitment(false)
                            }}
                            label={i18n.t('doctor.inquiry.sendButton')}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )
        }}
      />
    </React.Fragment>
  )
}

export default InquiryChatForm
