import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import {
  STAFF_PAGE_SCHEDULE_CANCEL,
  STAFF_UPDATE_SCHEDULE_CANCEL,
} from '@medical/constant/permissions'
import { checkStaffPermission, onPageChange } from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import Router from '@medical/routes/router'
import { Button } from 'primereact/button'
import queryString from 'query-string'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import ScheduleCancelScene from './ScheduleCancelScene'
import { GET_LIST_SCHEDULE_CANCEL } from './ScheduleCancelScene.graphql'

const WorkScheduleCancel = ({ history, location: { search } }) => {
  const [{ i18n }] = useCustom()
  const isUpdateScheduleCancel = checkStaffPermission({
    functionName: STAFF_UPDATE_SCHEDULE_CANCEL,
  })
  const isPageScheduleCancel = checkStaffPermission({
    functionName: STAFF_PAGE_SCHEDULE_CANCEL,
  })
  const {
    page = 1,
    rowsPerPage = 10,
    fullname,
    statusValue,
  } = queryString.parse(search)
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  let variables = {
    where: {
      deletedAt: null,
      status_in: ['WAITING_FOR_APPROVAL', 'REJECTED', 'ACCEPTED'],
      WorkSchedule: {
        Doctor: {
          deletedAt: null,
        }
      }
    },
    first: first < 0 ? 10 : first,
    skip: skip < 0 ? 0 : skip,
    orderBy: 'updatedAt_DESC',
  }
  if (fullname) {
    variables = {
      where: {
        deletedAt: null,
        status_in: ['WAITING_FOR_APPROVAL', 'REJECTED', 'ACCEPTED'],
        WorkSchedule: {
          Doctor: {
            unSigned_contains: fullname
              .replace(/ /g, '')
              .replace(/　/g, '')
              .toLowerCase(),
            deletedAt: null,
          },
        },
      },
      first: first < 0 ? 1 : first,
      skip: skip < 0 ? 0 : skip,
      orderBy: 'updatedAt_DESC',
    }
  }
  if (statusValue) {
    variables = {
      where: {
        deletedAt: null,
        status: statusValue,
        WorkSchedule: {
          Doctor: {
            deletedAt: null,
          }
        }
      },
      first: first < 0 ? 1 : first,
      skip: skip < 0 ? 0 : skip,
      orderBy: 'updatedAt_DESC',
    }
  }
  if (fullname && statusValue) {
    variables = {
      where: {
        deletedAt: null,
        status: statusValue.value,
        WorkSchedule: {
          Doctor: {
            unSigned_contains: fullname.replace('').replace('　'),
            deletedAt: null,
          },
        },
      },
      first: first < 0 ? 1 : first,
      skip: skip < 0 ? 0 : skip,
      orderBy: 'updatedAt_DESC',
    }
  }
  const { loading, error, data, refetch } = useQuery(GET_LIST_SCHEDULE_CANCEL, {
    variables,
  })
  useEffect(() => {
    refetch()
  })
  if (!isPageScheduleCancel) {
    return (
      <div className='static-container'>
        <div className='static-title'>エラー</div>
        <div className='static-content'>{i18n.t('main.noPermissionToSee')}</div>
        <Link to={Router.staffHome}>
          <Button
            label={i18n.t('main.pageNotFoundButton')}
            className='static-button'
          />
        </Link>
      </div>
    )
  }
  if (loading) return <ProgressSpinner fullHeight />
  if (error) return <ErrorComponent error={error} />
  const {
    workScheduleCancellationLettersConnection: {
      aggregate: { count },
    },
  } = data
  return (
    <ScheduleCancelScene
      isUpdateScheduleCancel={isUpdateScheduleCancel}
      data={data}
      i18n={i18n}
      page={parseInt(page, 10)}
      rowsPerPage={parseInt(rowsPerPage, 10)}
      count={count}
      onPageChange={onPageChange(history, search)}
      fullname={fullname}
      statusValue={statusValue}
    />
  )
}

export default WorkScheduleCancel
