import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { STAFF_GROUPS } from '@medical/pages/staff/StaffGroups/StaffGroupsList/StaffGroupsList.graphql'
import StaffForm from '@medical/pages/staff/Staffs/components/StaffForm'
import { useCustom } from '@medical/provider/context'
import queryString from 'query-string'
import React from 'react'

import { STAFFS_CONNECTION } from '../StaffsList/StaffsList.graphql'
import { STAFF_CREATE_STAFF } from './StaffCreate.graphql'

const StaffCreate = ({ location: { search }, closeModal, parentPath }) => {
  const [{ i18n, popup }] = useCustom()
  const {
    page = 1,
    rowsPerPage = 20,
    fullname,
    orderBy = 'createdAt_DESC',
  } = queryString.parse(search)
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  const variables = {
    first: first < 0 ? 20 : first,
    skip: skip < 0 ? 0 : skip,
    orderBy,
    where: {
      OR: [
        {
          firstname_contains: fullname,
        },
        {
          lastname_contains: fullname,
        },
        {
          firstnameKana_contains: fullname,
        },
        {
          lastnameKana_contains: fullname,
        },
      ],
    },
  }
  const [createStaff] = useMutation(STAFF_CREATE_STAFF, {
    refetchQueries: [
      {
        query: STAFFS_CONNECTION,
        variables,
      },
    ],
    awaitRefetchQueries: true,
  })
  const { loading, error, data } = useQuery(STAFF_GROUPS)

  const createStaffSubmit = async (
    {
      lastname,
      lastnameKana,
      firstname,
      firstnameKana,
      email,
      position,
      clinicId,
      staffGroup: { id: staffGroupId },
    },
    { setSubmitting, setFieldValue }
  ) => {
    try {
      await createStaff({
        variables: {
          lastname,
          lastnameKana,
          firstname,
          firstnameKana,
          email,
          position,
          clinicId,
          staffGroupId,
        },
      })
      popup.success(i18n.t('staff.doctorDetail.submissionSuccess'))
      setSubmitting(false)
      closeModal()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
      setFieldValue('confirm', false)
    }
  }

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  return (
    <StaffForm
      createIndicator
      submitLabel={i18n.t('staff.createStaff.submit')}
      confirmationMessage={i18n.t('staff.createStaff.submitConfirmation')}
      staffGroups={data.staffGroups}
      onSubmit={createStaffSubmit}
      search={search}
      parentPath={parentPath}
      cancelButton
    />
  )
}

export default StaffCreate
