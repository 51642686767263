import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import React, { useEffect, useState } from 'react'

import {
  EARLY_HOUR_WAGE,
  UPDATE_EARLY_HOUR_WAGE,
  REGION_QUERY,
} from './EarlySpecialHourlyWageSetting.graphql'
import EarlySpecialHourlyWageSettingShiftScene from './EarlySpecialHourlyWageSettingScene'
import moment from 'moment'
import { useCustom } from '@medical/provider/context'
import downloadCsv from 'download-csv'
import { fieldEarlyHourWage } from '@medical/services/csvService'
import {
  checkStaffPermission,
  sortAndFilterByClinicOrder,
  waitFor,
} from '@medical/libs'
import { STAFF_EDIT_HOURLY_WAGE_RESERVATION } from '@medical/constant/permissions'
import queryString from 'query-string'

const reservation = {
  '1': 'ONE_MONTH_AHEAD',
  '2': 'TWO_MONTHS_AHEAD',
  '3': 'THREE_MONTHS_AHEAD',
}

const reservationName = {
  ONE_MONTH_AHEAD: '１ヶ月先',
  TWO_MONTHS_AHEAD: '２ヶ月先',
  THREE_MONTHS_AHEAD: '３ヶ月先',
}

const compareObjects = (obj1, obj2) => {
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)
  if (keys1.length !== keys2.length) {
    return false
  }
  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false
    }
  }
  return true
}

const currentMonth = moment().month() + 1

const EarlySpecialHourlyWageSetting = ({ history, location: { search } }) => {
  const isPermittedEdit = checkStaffPermission({
    functionName: STAFF_EDIT_HOURLY_WAGE_RESERVATION,
  })
  // let {
  //   clinicIds = [],
  //   clinicalDepartmentNames = [],
  //   regionIds = [],
  // } = queryString.parse(search)
  const [{ popup }] = useCustom()
  const [monthAhead, setMonthAhead] = useState(1)
  const [clinicIds, setStateClinicIds] = useState(
    JSON.parse(localStorage.getItem('early_hourly_wage_clinics')) || []
  )
  const [clinicalDepartmentNames, setStateClinicalDepartmentNames] = useState(
    JSON.parse(localStorage.getItem('early_hourly_wage_clinicalDepartments')) ||
      []
  )
  const [regionIds, setStateRegionIds] = useState(
    JSON.parse(localStorage.getItem('early_hourly_wage_regions')) || []
  )

  const [isFirstFetch, setIsFirstFetch] = useState(false)
  const { data, refetch, error, loading } = useQuery(EARLY_HOUR_WAGE, {
    variables: {
      where: {
        reservation: reservation[`${monthAhead}`],
      },
    },
    fetchPolicy: 'network-only',
  })

  const { refetch: fetchDownload } = useQuery(EARLY_HOUR_WAGE, {
    fetchPolicy: 'network-only',
    skip: true,
  })

  const {
    loading: listRegionLoading,
    error: listRegionError,
    data: listRegionData,
    refetch: fetchRegion,
  } = useQuery(REGION_QUERY, {
    variables: {
      deletedAt: null,
      orderBy: 'position_ASC'
    },
  })

  const [updateHourlyWage] = useMutation(UPDATE_EARLY_HOUR_WAGE, {
    refetchQueries: [
      {
        query: EARLY_HOUR_WAGE,
        variables: {
          where: {
            reservation: reservation[`${monthAhead}`],
          },
        },
        awaitRefetchQueries: true,
      },
    ],
  })

  async function handleUpdatetHourlyWage(values, { setSubmitting, resetForm }) {
    try {
      const updateValues = []
      values.map(item => {
        const tempItem = data.hourlyWageChangeReservations.find(
          temp => temp.id === item.id
        )
        if (!compareObjects(item, tempItem)) {
          updateValues.push({
            id: item.id,
            isValid: item.isValid,
            isEarlySpecial: item.isEarlySpecial,
            weekdayMorning: item.weekdayMorning,
            weekdayAfternoon: item.weekdayAfternoon,
            weekdayNight: item.weekdayNight,
            saturdayMorning: item.saturdayMorning,
            saturdayAfternoon: item.saturdayAfternoon,
            saturdayNight: item.saturdayNight,
            holidayMorning: item.holidayMorning,
            holidayAfternoon: item.holidayAfternoon,
            holidayNight: item.holidayNight,
            reservation: item.reservation,
            clinic: item.clinic.id,
            clinicalDepartment: item.clinicalDepartment.id,
          })
        }
      })

      await updateHourlyWage({
        variables: {
          hourlyWageChangeReservations: updateValues,
        },
      })
      refetch()
      resetForm({
        data: values,
      })
      await waitFor(1000)
      popup.success('設定できました。')
      setSubmitting(false)
    } catch (error) {
      popup.error(error)
      refetch()
      setSubmitting(false)
    }
  }

  const handleDownload = async () => {
    const res = await fetchDownload()
    const fields = fieldEarlyHourWage()

    const data = res.data.hourlyWageChangeReservations.map(item => {
      const {
        isEarlySpecial,
        isValid,
        reservation,
        saturdayAfternoon,
        saturdayMorning,
        saturdayNight,
        updatedAt,
        weekdayAfternoon,
        weekdayMorning,
        weekdayNight,
        holidayNight,
        holidayMorning,
        holidayAfternoon,
        clinicalDepartment,
        clinic,
        createdBy,
      } = item
      const isEarlySpecialText = isEarlySpecial ? '済' : '未'
      const isValidText = isValid ? '済' : '未'

      return {
        reservation: reservationName[reservation],
        isValid: isValidText,
        isEarlySpecial: isEarlySpecialText,
        clinic: clinic.name,
        clinicalDepartment:
          departmentNames.filter(i => i.name === clinicalDepartment.name)[0]
            .departmentName || '',
        weekdayMorning,
        weekdayAfternoon,
        weekdayNight,
        saturdayMorning,
        saturdayAfternoon,
        saturdayNight,
        holidayMorning,
        holidayAfternoon,
        holidayNight,
        updatedAt: moment.utc(updatedAt).format('YYYY-MM-DD'),
        createdBy: `${createdBy?.lastname || ''}${createdBy?.firstname || ''}`,
      }
    })
    downloadCsv(data, fields, '早特時給CSV')
  }

  const getClinicalDepartmentId = clinicalDepartmentList => {
    if (clinicalDepartmentList && clinicalDepartmentList.length > 0) {
      const result = clinicalDepartmentList
        .map(i => {
          if (clinicalDepartmentNames.includes(i.name)) {
            return i.id
          }
        })
        .filter(id => id != null)
      if (result && result.length > 0) return result
      return undefined
    }
    return undefined
  }

  useEffect(() => {
    refetch()
  }, [monthAhead])

  useEffect(() => {
    if (!data) return
    refetch({
      where: {
        reservation: reservation[`${monthAhead}`],
        clinic_in: clinicIds.length > 0 ? clinicIds : undefined,
        clinicalDepartment_in: getClinicalDepartmentId(
          data.clinicalDepartments
        ),
        region_in: regionIds.length > 0 ? regionIds : undefined
      },
    })
  }, [clinicIds, clinicalDepartmentNames, regionIds])

  useEffect(() => {
    if (!data) return
    if (!isFirstFetch) {
      refetch({
        where: {
          reservation: reservation[`${monthAhead}`],
          clinic_in: clinicIds.length > 0 ? clinicIds : undefined,
          clinicalDepartment_in: getClinicalDepartmentId(
            data.clinicalDepartments
          ),
          region_in: regionIds.length > 0 ? regionIds : undefined
        },
      }).finally(() => setIsFirstFetch(true))
    }
  }, [data])

  if (
    loading ||
    (clinicalDepartmentNames && !isFirstFetch) ||
    listRegionLoading
  )
    return <ProgressSpinner />
  if (error || listRegionError)
    return <ErrorComponent error={error || listRegionError} />

  const departments = data?.departments || []

  const clinicalDepartmentSort = sortAndFilterByClinicOrder({
    clinicalDepartments: data?.clinicalDepartments || [],
  })

  const mappedDataClinic = clinicalDepartmentSort.map(item => ({
    id: item.clinic.id,
    clinicName: item.clinic.name,
  }))

  const uniqueClinicName = [
    ...new Map(mappedDataClinic.map(item => [item[`id`], item])).values(),
  ]

  const departmentNames = []
  const listRegions = listRegionData?.regions.map(item => ({
    id: item.id,
    region: item.name,
  }))

  if (departments.length) {
    departments.reduce((acc, department) => {
      departmentNames.push({
        departmentName: department?.description,
        name: department?.value,
      })
    }, {})
  }

  const setClinicIds = ids => {
    const newClinicIds = ids.value.map(({ id }) => id)
    localStorage.setItem(
      'early_hourly_wage_clinics',
      JSON.stringify(newClinicIds)
    )
    setStateClinicIds(newClinicIds)
  }

  const setRegionIds = ids => {
    const newRegionIds = ids.value.map(({ id }) => id)
    localStorage.setItem(
      'early_hourly_wage_regions',
      JSON.stringify(newRegionIds)
    )
    setStateRegionIds(newRegionIds)
  }

  const setClinicalDepartmentNames = names => {
    const newClinicalDepartmentNames = names.value.map(({ name }) => name)
    localStorage.setItem(
      'early_hourly_wage_clinicalDepartments',
      JSON.stringify(newClinicalDepartmentNames)
    )
    setStateClinicalDepartmentNames(newClinicalDepartmentNames)
  }

  return (
    <EarlySpecialHourlyWageSettingShiftScene
      data={data.hourlyWageChangeReservations}
      monthAhead={monthAhead}
      setMonthAhead={setMonthAhead}
      currentMonth={currentMonth}
      handleUpdatetHourlyWage={handleUpdatetHourlyWage}
      handleDownload={handleDownload}
      loading={loading}
      isPermittedEdit={isPermittedEdit}
      departmentNames={departmentNames}
      uniqueClinicName={uniqueClinicName}
      setClinicIds={setClinicIds}
      clinicIds={clinicIds}
      clinicalDepartmentNames={clinicalDepartmentNames}
      setClinicalDepartmentNames={setClinicalDepartmentNames}
      listRegions={listRegions}
      regionIds={regionIds}
      setRegionIds={setRegionIds}
    />
  )
}

export default EarlySpecialHourlyWageSetting
