import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { DOCTORS_FIELDS } from '@medical/constant/permissions'
import {
  checkStaffPermission,
  clinicalDepartmentNames,
  dateValidation,
  findNameOrEmail,
  onPageChange,
} from '@medical/libs'
// import Auth from '@medical/middleware/auth'
import { useCustom } from '@medical/provider/context'
import Router from '@medical/routes/router'
import moment from 'moment'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'

import { DOCTORS_CONNECTION } from '../DoctorsList/DoctorsList.graphql'
import NewDoctorsListScene from './NewDoctorsListScene'

const NewDoctorsList = ({
  history,
  location: { search },
  match: {
    path,
    params: { year = moment().year(), month = moment().month() + 1 },
  },
}) => {
  const [{ i18n, popup }] = useCustom()
  const [order, setOrderBy] = useState('createdAt_ASC')
  const startDate = moment()
    .year(year)
    .month(month - 1)
    .startOf('month')
  const endDate = startDate.clone().add(1, 'month')
  const compare = (a, b) =>
    moment(a.node.createdAt) > moment(b.node.createdAt) ? 1 : -1
  const {
    page = 1,
    rowsPerPage = 20,
    orderBy = 'createdAt_ASC',
    fullname,
    email,
    status,
  } = queryString.parse(search)
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  const variables = {
    first: first < 0 ? 20 : first,
    skip: skip < 0 ? 0 : skip,
    orderBy,
    where: {
      AND: findNameOrEmail({ fullname, email }),
      registrationStatus: status,
      createdAt_gte: startDate,
      createdAt_lt: endDate,
    },
  }
  const isDoctorsFieldsPermitted = checkStaffPermission({
    functionName: DOCTORS_FIELDS,
  })

  const { loading, error, data, refetch } = useQuery(DOCTORS_CONNECTION, {
    variables,
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!loading && (data && data.doctorsConnection)) {
      if (order === 'createdAt_ASC' || order === 'createdAt_DESC') {
        data.doctorsConnection.edges.sort((a, b) =>
          order === 'createdAt_ASC' ? compare(a, b) : compare(b, a)
        )
      }
    }
  }, [loading, data, order, refetch])

  if (
    dateValidation({ year, month }) ||
    [
      Router.staffNewDoctorsListWithoutYearMonth,
      Router.staffNewDoctorsListWithoutMonth,
    ].includes(path)
  )
    return (
      <Redirect
        to={Router.get(Router.staffNewDoctorsList, {
          year: moment().year(),
          month: moment().month() + 1,
        })}
      />
    )

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const departmentNames = clinicalDepartmentNames({
    departments: data.departments,
  })

  const onSortHandle = e => {
    if (e.sortField === 'createdAt') {
      setOrderBy(order !== 'createdAt_ASC' ? 'createdAt_ASC' : 'createdAt_DESC')
    }
  }

  const {
    doctorsConnection: {
      edges,
      aggregate: { count },
    },
  } = data

  return (
    <NewDoctorsListScene
      i18n={i18n}
      edges={edges}
      count={count}
      variables={variables}
      page={parseInt(page, 10)}
      rowsPerPage={parseInt(rowsPerPage, 10)}
      fullname={fullname}
      email={email}
      status={status}
      onPageChange={onPageChange(history, search)}
      isDoctorsFieldsPermitted={isDoctorsFieldsPermitted}
      departmentNames={departmentNames}
      currentDate={startDate}
      onSortHandle={onSortHandle}
    />
  )
}

export default NewDoctorsList
