import ClinicNameRender from '@medical/components/ClinicNameRender'
import numeralCurrency from '@medical/libs/numeralCurrency'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'

import styles from './Style'

const DoctorCartSp = ({ workSchedule, departmentNames }) => {
  const { i18n } = useTranslation()

  return (
    <div>
      <div style={styles.mobileRequestItem}>
        {i18n.t('main.clinicName')} :
        <span style={styles.mobileRequestItemContent}>
          {ClinicNameRender({
            clinicalDepartment: workSchedule.clinicalDepartment,
          })}
        </span>
      </div>

      <div style={styles.mobileRequestItem}>
        {i18n.t('main.departmentName')} :
        <span style={styles.mobileRequestItemContent}>
          {departmentNames[workSchedule.clinicalDepartment.name]}
        </span>
      </div>
      <div style={styles.mobileRequestItem}>
        {i18n.t('doctor.cart.date')} :
        <span style={styles.mobileRequestItemContent}>
          {moment(workSchedule.startTime).format('LL (dd)')}
        </span>
      </div>
      <div style={styles.mobileRequestItem}>
        {i18n.t('main.startTime')} :
        <span style={styles.mobileRequestItemContent}>
          {moment(workSchedule.startTime).format('HH:mm')}
        </span>
      </div>
      <div style={styles.mobileRequestItem}>
        {i18n.t('main.endTime')} :
        <span style={styles.mobileRequestItemContent}>
          {moment(workSchedule.endTime).format('HH:mm')}
        </span>
      </div>
      <div style={styles.mobileRequestItem}>
        {i18n.t('main.dailySalary')} :
        <span style={styles.mobileRequestItemContent}>
          {numeralCurrency(workSchedule.dailySalary)}
        </span>
      </div>
      <div style={styles.mobileRequestItem}>コメント :</div>
    </div>
  )
}

export default DoctorCartSp
