import BreadCrumb from '@medical/components/Breadcrumb'
import { dateValidation } from '@medical/libs'
import moment from 'moment'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Router from '@medical/routes/router'
import { Paginator } from 'primereact/paginator'
import { InputText } from 'primereact/inputtext'
import './MonthlyDoctorSalaryConfirmation.scss'
import queryString from 'query-string'
import {
  calDoctorMonthlyTransportationExpenses,
  calTotalMonthlyAmountWorkSchedule,
} from '@medical/libs/doctorMonthlySalaryConfirmation'
import { useEffect } from 'react'
import { DOWNLOAD_CSV_MONTHLY_DOCTOR_SALARY_CONFIRMATION } from '@medical/constant'
const MonthlyDoctorSalaryConfirmationScene = ({
  i18n,
  currentDate,
  filterData,
  filter,
  data,
  totalRecord,
  pageSize,
  socket,
  progress,
  setProgress,
  handleDownloadCSV,
}) => {
  const [doctorName, setDoctorName] = useState(filterData.doctorName)
  const [mailAddress, setMailAddress] = useState(filterData.mailAddress)
  const [page, setPage] = useState(filterData.page)
  const date = moment(currentDate)
  const prevMonth = moment(date).subtract(1, 'month')
  const nextMonth = moment(date).add(1, 'month')
  const isPreviousDisabled =
    date.isBefore(
      moment()
        .subtract(6, 'months')
        .endOf('month')
        .endOf('day')
    ) ||
    dateValidation({
      year: prevMonth.year(),
      month: prevMonth.month() + 1,
    })
  const isNextDisabled = dateValidation(
    {
      year: nextMonth.year(),
      month: nextMonth.month() + 1,
    },
    true
  )

  const amountTemplate = amount => (amount > 0 ? '有' : '無')
  const hasProgress = Object.keys(progress).includes(
    DOWNLOAD_CSV_MONTHLY_DOCTOR_SALARY_CONFIRMATION
  )
  useEffect(() => {
    if (socket && hasProgress) {
      socket.on(DOWNLOAD_CSV_MONTHLY_DOCTOR_SALARY_CONFIRMATION, data => {
        if (data?.percent) {
          setProgress({
            progress: DOWNLOAD_CSV_MONTHLY_DOCTOR_SALARY_CONFIRMATION,
            label: `${i18n.t(
              'staff.menuBar.monthlyDoctorSalaryConfirmation'
            )}中`,
            percent: Number(data.percent),
            url: undefined,
          })
        }
      })
    }
  }, [socket, hasProgress])

  return (
    <>
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('staff.menuBar.monthlyDoctorSalaryConfirmation')}
          <BreadCrumb
            items={[
              {
                title: i18n.t('staff.menuBar.monthlyDoctorSalaryConfirmation'),
              },
            ]}
          />
        </div>
        <div className='staff-buttons'>
          <Button
            label={i18n.t(
              'staff.monthlyDoctorSalaryConfirmation.csvDownloadButton'
            )}
            icon='pi pi-download'
            className='p-button-normal'
            onClick={handleDownloadCSV}
          />
        </div>
      </div>
      <div className='container'>
        <div className='box monthly-doctor-salary-confirm'>
          <div className='header'>
            <Link
              to={{
                pathname: Router.get(
                  Router.staffMonthlyDoctorSalaryConfirmation,
                  {
                    year: prevMonth.year(),
                    month: prevMonth.month() + 1,
                  }
                ),
                search: queryString.stringify({ ...filterData }),
              }}
              onClick={isPreviousDisabled ? e => e.preventDefault() : undefined}
            >
              <Button icon='pi pi-chevron-left' disabled={isPreviousDisabled} />
            </Link>
            <div className='header_title'>
              {moment(currentDate).format('YYYY年M月')}
            </div>
            <Link
              to={{
                pathname: Router.get(
                  Router.staffMonthlyDoctorSalaryConfirmation,
                  {
                    year: nextMonth.year(),
                    month: nextMonth.month() + 1,
                  }
                ),
                search: queryString.stringify({ ...filterData }),
              }}
              onClick={isNextDisabled ? e => e.preventDefault() : undefined}
            >
              <Button icon='pi pi-chevron-right' disabled={isNextDisabled} />
            </Link>
          </div>
          <div className='filter'>
            <div className='filter_input'>
              <InputText
                value={doctorName}
                onChange={e => setDoctorName(e.target.value)}
                placeholder={i18n.t(
                  'staff.monthlyDoctorSalaryConfirmation.doctorName'
                )}
              />
              <InputText
                value={mailAddress}
                onChange={e => setMailAddress(e.target.value)}
                placeholder={i18n.t(
                  'staff.monthlyDoctorSalaryConfirmation.mailAddress'
                )}
              />
              <Button
                onClick={() => filter(doctorName, mailAddress)}
                label={i18n.t('main.search')}
                icon='pi pi-search'
              />
              {(doctorName || mailAddress) && (
                <Button
                  icon='pi pi-times'
                  className='p-button-secondary'
                  style={{ marginLeft: '20px' }}
                  onClick={() => {
                    setDoctorName('')
                    setMailAddress('')
                    filter('', '', page)
                  }}
                />
              )}
            </div>

            <p class='asterisk-label-head'>
              {i18n.t('staff.monthlyDoctorSalaryConfirmation.asterisk')}
            </p>
          </div>
          {!date.isSameOrBefore(
            moment()
              .subtract(7, 'months')
              .endOf('month')
              .endOf('day')
          ) && (
            <>
              <div className='table-wrapper'>
                <DataTable
                  className='dataTable'
                  value={data}
                  resizableColumns
                  columnResizeMode='fit'
                  showGridlines
                  responsiveLayout='scroll'
                >
                  <Column
                    field='fullName'
                    header={i18n.t(
                      'staff.monthlyDoctorSalaryConfirmation.doctorName'
                    )}
                    className='dataTable_cell'
                    headerClassName='dataTable_header'
                    style={{ width: '25%' }}
                  ></Column>
                  <Column
                    field='email'
                    header={i18n.t(
                      'staff.monthlyDoctorSalaryConfirmation.mailAddress'
                    )}
                    className='dataTable_cell'
                    headerClassName='dataTable_header'
                    style={{ width: '30%' }}
                  ></Column>
                  <Column
                    field='totalAmountWorkSchedule'
                    header={i18n.t(
                      'staff.monthlyDoctorSalaryConfirmation.jobProfile'
                    )}
                    className='dataTable_cell'
                    headerClassName='dataTable_header'
                    body={rowData =>
                      amountTemplate(calTotalMonthlyAmountWorkSchedule(rowData))
                    }
                    style={{ width: '15%', textAlign: 'center' }}
                  ></Column>
                  <Column
                    field='totalAmountTransport'
                    header={i18n.t(
                      'staff.monthlyDoctorSalaryConfirmation.transportationExpenses'
                    )}
                    className='dataTable_cell'
                    headerClassName='dataTable_header'
                    body={rowData =>
                      amountTemplate(
                        calDoctorMonthlyTransportationExpenses(rowData)
                      )
                    }
                    style={{ width: '15%', textAlign: 'center' }}
                  ></Column>
                  <Column
                    field='totalAmountIncentive'
                    header={i18n.t(
                      'staff.monthlyDoctorSalaryConfirmation.incentivePayment'
                    )}
                    className='dataTable_cell'
                    headerClassName='dataTable_header'
                    body={rowData =>
                      amountTemplate(rowData.doctorMonthlyIncentive)
                    }
                    style={{ width: '15%', textAlign: 'center' }}
                  ></Column>
                </DataTable>
              </div>
              <Paginator
                rows={pageSize}
                totalRecords={totalRecord}
                first={(page - 1) * pageSize}
                onPageChange={e => {
                  setPage(e.page + 1)
                  filter(doctorName, mailAddress, e.page + 1)
                }}
                leftContent={
                  <div className='paginator-total'>
                    {i18n.t('main.paginationTotal')}: {totalRecord}
                  </div>
                }
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default MonthlyDoctorSalaryConfirmationScene
