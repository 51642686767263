import ClinicNameRender from '@medical/components/ClinicNameRender'
import { calculateSalaryNumber, calculaterSubsidy } from '@medical/libs'
import numeralCurrency from '@medical/libs/numeralCurrency'
import Router from '@medical/routes/router'
import moment from 'moment'
import { Button } from 'primereact/button'
import React from 'react'
import { Link } from 'react-router-dom'

const AcceptedWorkScheduleDetailScene = ({
  i18n,
  workSchedule,
  departmentNames,
  url,
}) => {
  const {
    clinicalDepartment,
    startTime,
    endTime,
    doctor,
    acceptedShift,
  } = workSchedule
  const currentTime = moment().valueOf()
  const {
    splitDateTime1,
    splitDateTime2,
    splitDateTime3,
    isStartTimeBreakTime,
    isSplitDateTime1BreakTime,
    isSplitDateTime2BreakTime,
    isSplitDateTime3BreakTime,
  } = acceptedShift
  const fullName = `${doctor.lastname} ${doctor.firstname}`
  const fullNameKana = `${doctor.lastnameKana} ${doctor.firstnameKana}`
  const workingHours = (time1, time2, time3) => {
    if (time1 && time2 && time3) {
      return (
        <>
          {moment(startTime).format('HH:mm')}〜{moment(time1).format('HH:mm')}
          {isSplitDateTime1BreakTime ? (
            <span style={{ color: '#f03a6c' }}>　※休憩時間</span>
          ) : (
            ''
          )}
          <br />
          {moment(time1).format('HH:mm')}〜{moment(time2).format('HH:mm')}
          {isSplitDateTime2BreakTime ? (
            <span style={{ color: '#f03a6c' }}>　※休憩時間</span>
          ) : (
            ''
          )}
          <br />
          {moment(time2).format('HH:mm')}〜{moment(time3).format('HH:mm')}
          {isSplitDateTime3BreakTime ? (
            <span style={{ color: '#f03a6c' }}>　※休憩時間</span>
          ) : (
            ''
          )}
          <br />
          {moment(time3).format('HH:mm')}〜{moment(endTime).format('HH:mm')}
          {isStartTimeBreakTime ? (
            <span style={{ color: '#f03a6c' }}>　※休憩時間</span>
          ) : (
            ''
          )}
          <br />
        </>
      )
    }
    if (time1 && time2) {
      return (
        <>
          {moment(startTime).format('HH:mm')}〜{moment(time1).format('HH:mm')}
          {isSplitDateTime1BreakTime ? (
            <span style={{ color: '#f03a6c' }}>　※休憩時間</span>
          ) : (
            ''
          )}
          <br />
          {moment(time1).format('HH:mm')}〜{moment(time2).format('HH:mm')}
          {isSplitDateTime2BreakTime ? (
            <span style={{ color: '#f03a6c' }}>　※休憩時間</span>
          ) : (
            ''
          )}
          <br />
          {moment(time2).format('HH:mm')}〜{moment(endTime).format('HH:mm')}
          {isStartTimeBreakTime ? (
            <span style={{ color: '#f03a6c' }}>　※休憩時間</span>
          ) : (
            ''
          )}
          <br />
        </>
      )
    }
    if (time1) {
      return (
        <>
          {moment(startTime).format('HH:mm')}〜{moment(time1).format('HH:mm')}
          {isSplitDateTime1BreakTime ? (
            <span style={{ color: '#f03a6c' }}>　※休憩時間</span>
          ) : (
            ''
          )}
          <br />
          {moment(time1).format('HH:mm')}〜{moment(endTime).format('HH:mm')}
          {isStartTimeBreakTime ? (
            <span style={{ color: '#f03a6c' }}>　※休憩時間</span>
          ) : (
            ''
          )}
          <br />
        </>
      )
    }
    return (
      <>
        {moment(startTime).format('HH:mm')}〜{moment(endTime).format('HH:mm')}
        {isStartTimeBreakTime ? (
          <span style={{ color: '#f03a6c' }}>　※休憩時間</span>
        ) : (
          ''
        )}
      </>
    )
  }
  return (
    <React.Fragment>
      <div className='modal-title' style={{ fontWeight: 'bold' }}>
        確定シフト詳細
      </div>
      <div className='tr'>
        <div className='th '>{i18n.t('main.fullname')}</div>
        <div className='td td-cancel'>{fullName}</div>
      </div>
      <div className='tr'>
        <div className='th '>{i18n.t('main.fullnameKana')}</div>
        <div className='td td-cancel'>{fullNameKana}</div>
      </div>
      <div className='tr'>
        <div className='th '>{i18n.t('main.clinicName')}</div>
        <div className='td td-cancel'>
          {ClinicNameRender({ clinicalDepartment })}
        </div>
      </div>
      <div className='tr'>
        <div className='th '>{i18n.t('main.departments')}</div>
        <div className='td td-cancel'>
          {departmentNames[clinicalDepartment.name]}
        </div>
      </div>
      <div className='tr'>
        <div className='th '>{i18n.t('main.date')}</div>
        <div className='td td-cancel'>
          {moment(startTime).format('LL (dd)')}
        </div>
      </div>
      <div className='tr'>
        <div className='th '>{i18n.t('main.scheduleTime')}</div>
        <div className='td td-cancel'>
          {workingHours(splitDateTime1, splitDateTime2, splitDateTime3)}
        </div>
      </div>
      <div className='tr'>
        <div className='th '>{i18n.t('main.dailySalarys')}</div>
        <div className='td td-cancel'>
          {numeralCurrency(
            calculateSalaryNumber({
              availableShift: acceptedShift,
              startTime: acceptedShift.startTime,
              endTime: acceptedShift.endTime,
              hourlyWageRange: workSchedule.adjustHourlyWageRange,
              doctorSubsidy: calculaterSubsidy(
                workSchedule.acceptedShift.doctorSubsidy
              ),
            })
          )}
        </div>
      </div>
      <div className='tr'>
        <div className='th '>{i18n.t('main.workDetail')}</div>
        <div className='td td-cancel'>{workSchedule.acceptedShift.comment}</div>
      </div>
      {workSchedule.acceptedShift.doctorSubsidy?.length > 0 && (
        <div className='tr'>
          <div className='th '>
            <span className='asterisk-label-head font-size-15'>
              {i18n.t('main.doctorSubsidy')}
            </span>
          </div>
          <div className='td td-cancel'>
            {workSchedule.acceptedShift &&
            workSchedule.acceptedShift.doctorSubsidy
              ? workSchedule.acceptedShift.doctorSubsidy.map(item => (
                  <div>
                    ({i18n.t('main.doctorSubsidyTitle')}: {item.title} -{' '}
                    {i18n.t('main.doctorSubsidyMoney')}: {item.money}円)
                  </div>
                ))
              : null}
          </div>
        </div>
      )}

      <div className='modal-bottom'>
        {(workSchedule.workScheduleCancellationLetter === null ||
          workSchedule.workScheduleCancellationLetter?.status === 'REJECTED') &&
        currentTime < moment(workSchedule.startTime).valueOf() ? (
          <td>
            <Link
              to={{
                pathname: `${url}${Router.doctorAcceptedWorkScheduleCancel}`,
              }}
            >
              <Button
                label={i18n.t('staff.acceptedWorkSchedulesList.btnCancel')}
              />
            </Link>
          </td>
        ) : (
          <td>
            <Button
              label={i18n.t('staff.acceptedWorkSchedulesList.btnCancel')}
              className='p-button-secondary'
            />
          </td>
        )}
      </div>
      <div className='text-red'>
        <span className='asterisk-label-head '>
          キャンセル申請の際に、代替日程を申告していただければキャンセルカウント（ペナルティポイント）は付与致しません。但し、2週間を過ぎてからのキャンセルの場合は代替日程の申告はできません。
        </span>
        <br />
        <span className='asterisk-label-head '>
          やむを得ない事由のキャンセル以外、キャンセルカウント数に応じて募集シフト時給を減額させていただく場合もございます。
        </span>
      </div>
    </React.Fragment>
  )
}
export default AcceptedWorkScheduleDetailScene
