import queryString from 'query-string'
import React from 'react'
import { Link, withRouter } from 'react-router-dom'

const ModalLink = ({ to, state, match: { url }, location: { search }, children }) => (
  <Link
    to={{
      pathname: `${url}${to}`,
      search: queryString.stringify({
        ...queryString.parse(search),
        // Here clearing Modal with Tab  Navigator's query parameters
        activeIndex: undefined,
        editable: undefined,
        uploadFileCategory: undefined,
      }),
      state
    }}
  >
    {children}
  </Link>
)

export default withRouter(ModalLink)
