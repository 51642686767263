import ClinicalDepartment from '@medical/components/AutoComplete'
import { MAX_HOURLY_WAGE } from '@medical/constant'
import {
  calculaterSubsidy,
  calculateSalary,
  getAvailableTime,
  splitLogic,
} from '@medical/libs'
import { Checkbox } from 'antd'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Spinner } from 'primereact/spinner'
import { pathOr } from 'ramda'
import React, { useEffect, useState } from 'react'
import { useApolloClient } from '@apollo/react-hooks'
import AutoComplete from './ShiftFormSplitsAutoComplete'
import styles from './Style'
import { STAFF_CHECK_DOCTOR_DEFAULT_HOURLY_WAGE } from '../CreateWorkSchedule/CreateWorkSchedule.graphql'
import { validateStartTimeEndTime } from '@medical/constant/utilities'

const ShiftGroupFormSplits = ({
  i18n,
  disabled,
  isMoneyPermitted,
  isCreateAvailableShift,
  getStartTimeValue,
  getEndTimeValue,
  doctorHourlyWageRange,
  handleClickAddHourlyWage,
  handleDeleteHourlyWage,
  isCreateWorkSchedule,
  clinicalDepartments,
  formikProps,
  isDetailAvailableShiftGroup,
  hourlyWageData,
  informTime,
  doctorSelecting,
  isHoliday,
  popup,
}) => {
  const client = useApolloClient()
  const { handleChange, touched, errors, setFieldValue, values } = formikProps
  const [count, setCount] = useState(0)
  const hourlyWageRange = doctorHourlyWageRange || 0
  useEffect(() => {
    if (values.splits && Object.keys(values.splits).length > 0) {
      Object.keys(values.splits).forEach(key => {
        let startTime = values.splits[key][0].startTime
        let endTime = values.splits[key][values.splits[key].length - 1].date
        const isHideWarningText = validateStartTimeEndTime(startTime, endTime)
        setFieldValue(
          `splitsWarning[${key}].isHideWarningText`,
          isHideWarningText
        )
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.splits, Object.keys(values.splits || {}).length, values.count])
  useEffect(() => {
    if (
      isCreateAvailableShift &&
      hourlyWageData &&
      hourlyWageData.length > 0 &&
      values.splits &&
      Object.keys(values.splits).length > 0
    ) {
      Object.keys(values.splits).forEach(key => {
        Array.from(values.splits[key]).forEach((data, index) => {
          const startTime =
            index > 0 ? values.splits[key][index - 1].date : data.startTime
          let hourlyWage = 0
          const newHourlyWageData = []
          hourlyWageData.map(item => {
            if (item[`${data.type}`]) {
              newHourlyWageData.push(item)
            }
          })
          const resultNewHourlyWageData = []
          newHourlyWageData.map(item => {
            const format = 'HH:mm:ss'
            const startTimeSplit = moment(
              moment(startTime).format('HH:mm'),
              format
            )
            const startTimeHourly = moment(
              moment.utc(item.startTime).format('HH:mm'),
              format
            )
            const endTimeHourly = moment(
              moment.utc(item.endTime).format('HH:mm'),
              format
            )
            if (
              startTimeSplit.isSameOrAfter(startTimeHourly) &&
              startTimeSplit.isSameOrBefore(endTimeHourly)
            ) {
              resultNewHourlyWageData.push(item)
            }
          })
          resultNewHourlyWageData.forEach(item => {
            hourlyWage = item.hourlyWageDefault
          })
          setFieldValue(`splits[${key}][${index}].hourlyWage`, hourlyWage)
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hourlyWageData, Object.keys(values.splits || {}).length, count])

  useEffect(() => {
    if (isCreateWorkSchedule && doctorSelecting && values.splits) {
      Object.keys(values.splits).forEach(key => {
        Array.from(values.splits[key]).forEach(async (data, index) => {
          const startTime =
            index > 0 ? values.splits[key][index - 1].date : data.startTime
          if (data.hourlyWage === 0 && data.clinicalDepartmentId) {
            try {
              const {
                data: { doctorDefaultHourlyWage },
              } = await client.query({
                query: STAFF_CHECK_DOCTOR_DEFAULT_HOURLY_WAGE,
                variables: {
                  doctorId: doctorSelecting ? doctorSelecting.id : '',
                  clinicalDepartmentId:
                    data && data.clinicalDepartmentId
                      ? data.clinicalDepartmentId
                      : '',
                  date:
                    informTime && startTime
                      ? `${informTime.split('T')[0]}T${moment(startTime).format(
                          'HH:mm:ss'
                        )}`
                      : informTime || '',
                  isHoliday,
                },
              })
              setFieldValue(
                `splits[${key}][${index}].hourlyWage`,
                doctorDefaultHourlyWage
              )
            } catch (e) {
              popup.error(e)
            }
          }
        })
      })
    }
  }, [informTime, doctorSelecting, values.splits])

  return (
    <React.Fragment>
      <div style={styles.row}>
        <div className='shiftForm-container'>
          <React.Fragment>
            {values.repeatSetting &&
              values.startEndDate[0] &&
              values.splits &&
              Object.keys(values.splits)
                .sort((a, b) => a.split('_')[0] - b.split('_')[0])
                .map(key => (
                  <div key={key}>
                    <div className='tr'>
                      <div className='th' style={{ padding: '15px 0px' }}>
                        {i18n.t(`staff.createShift.set${key}`)}
                      </div>
                    </div>
                    {!isCreateAvailableShift && !isDetailAvailableShiftGroup && (
                      <div className='tr'>
                        <div className='th' style={{ padding: '15px 0px' }}>
                          {i18n.t('staff.createShift.clinic')}
                        </div>
                        <div className='td' style={{ padding: '15px 0px' }}>
                          <ClinicalDepartment
                            {...formikProps}
                            values={values}
                            name='clinicalDepartment'
                            field='clinicName'
                            allSuggestions={clinicalDepartments}
                            suggestionName='clinicalDepartments'
                            width='20vw'
                            maxWidth='351px'
                            placeholder={
                              clinicalDepartments.length > 0
                                ? 'クリニックを選択'
                                : '対象データなし'
                            }
                            day={key}
                            disabled={
                              values.splits[key][0]?.isDisabled || false
                            }
                            setCurrentClinicData={val => {
                              setFieldValue(
                                `splits[${key}][0].clinicalDepartmentId`,
                                val && val.id ? val.id : ''
                              )
                            }}
                          />
                          <div className='alert'>
                            {touched.splits &&
                              key &&
                              touched.splits[key] &&
                              touched.splits[key][0]?.clinicalDepartmentId &&
                              errors.splits &&
                              key &&
                              errors.splits[key] &&
                              errors.splits[key][0]?.clinicalDepartmentId}
                          </div>
                        </div>
                      </div>
                    )}

                    <table
                      style={{
                        width: '100%',
                        marginBottom: '10px',
                      }}
                    >
                      <thead>
                        <tr>
                          <th style={styles.th} width='32%'>
                            {i18n.t('staff.createShift.startTime')}
                          </th>
                          <th style={styles.th} width='32%'>
                            {i18n.t('staff.createShift.endTime')}
                          </th>
                          {isMoneyPermitted && (
                            <th style={styles.th} width='36%'>
                              <span style={styles.input}>
                                {i18n.t('staff.createShift.hourlyWage')}
                              </span>
                            </th>
                          )}
                          <th style={{ paddingLeft: '10px' }}>休憩時間</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.from(values.splits[key]).map(
                          (split, indexChild) => {
                            const prevDate = indexChild
                              ? moment(values.splits[key][indexChild - 1].date)
                              : moment(values.splits[key][0].startTime)
                            const minDate = prevDate.format()

                            // const nextDate = moment(values.endTime)
                            // const maxDate = nextDate.format()
                            return (
                              <>
                                <tr key={indexChild}>
                                  <td>
                                    {indexChild ? (
                                      <>
                                        <AutoComplete
                                          value={prevDate.toDate()}
                                          disabled
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <AutoComplete
                                          value={
                                            values.splits[key][0].startTime
                                          }
                                          onChange={e => {
                                            setCount(count + 1)
                                            setFieldValue(
                                              `splits[${key}][${indexChild}].startTime`,
                                              e.value
                                            )

                                            const startTime = moment(e.value)
                                            if (getStartTimeValue) {
                                              getStartTimeValue(
                                                e.value,
                                                indexChild
                                              )
                                            }
                                            Array.from(
                                              values.splits[`${key}`]
                                            ).forEach((s, i) => {
                                              if (
                                                startTime.isSameOrAfter(
                                                  moment(s.date)
                                                )
                                              ) {
                                                const date = getAvailableTime({
                                                  currentTime: e.value,
                                                })
                                                setFieldValue(
                                                  `splits[${key}].${i}.date`,
                                                  date
                                                )
                                              }
                                            })
                                          }}
                                          disabled={disabled}
                                        />
                                      </>
                                    )}
                                  </td>
                                  <td>
                                    <>
                                      <AutoComplete
                                        value={split.date}
                                        onChange={e => {
                                          setFieldValue(
                                            `splits[${key}][${indexChild}].date`,
                                            e.value,
                                            false
                                          )
                                          setCount(count + 1)
                                          const startTime = moment(e.value)
                                          if (getEndTimeValue) {
                                            getEndTimeValue(e.value, indexChild)
                                          }
                                          for (
                                            let i = indexChild + 1;
                                            i < values.splits[key].length;
                                            i += 1
                                          ) {
                                            if (
                                              startTime.isSameOrAfter(
                                                moment(
                                                  values.splits[key][i].date
                                                )
                                              )
                                            ) {
                                              const date = getAvailableTime({
                                                currentTime: e.value,
                                              })
                                              setFieldValue(
                                                `splits[${key}][${i}].date`,
                                                date
                                              )
                                            }
                                          }
                                        }}
                                        minDate={minDate}
                                        disabled={disabled}
                                      />
                                    </>
                                  </td>
                                  {isMoneyPermitted && (
                                    <td
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                      }}
                                    >
                                      <Spinner
                                        onChange={e => {
                                          handleChange(e)
                                        }}
                                        value={
                                          values.splits[key][indexChild]
                                            .hourlyWage
                                        }
                                        name={`splits[${key}][${indexChild}].hourlyWage`}
                                        onBlur={() => {
                                          // eslint-disable-next-line no-restricted-globals
                                          if (
                                            isNaN(
                                              values.splits[key][indexChild]
                                                .hourlyWage
                                            )
                                          ) {
                                            setFieldValue(
                                              `splits.${key}.${indexChild}.hourlyWage`,
                                              0
                                            )
                                          }
                                        }}
                                        style={styles.input}
                                        min={0}
                                        max={MAX_HOURLY_WAGE}
                                        step={500}
                                        formatInput
                                        className='split-autocomplete'
                                        disabled={
                                          disabled ||
                                          values.splits[key][indexChild]
                                            .isBreakTime
                                        }
                                      />
                                      {!!indexChild && (
                                        <Button
                                          onClick={() => {
                                            setFieldValue(
                                              `values.splits[${key}]`,
                                              values.splits[key].splice(
                                                indexChild,
                                                1
                                              )
                                            )
                                            if (handleDeleteHourlyWage) {
                                              handleDeleteHourlyWage(
                                                indexChild,
                                                1
                                              )
                                            }
                                            setFieldValue(
                                              `count`,
                                              values.count + 1
                                            )
                                          }}
                                          icon='pi pi-times'
                                          className='p-button-danger'
                                          style={{
                                            display: 'flex',
                                            marginLeft: '10px',
                                          }}
                                          disabled={disabled}
                                        />
                                      )}
                                      <div className='alert'>
                                        {pathOr(
                                          null,
                                          [
                                            'splits',
                                            key,
                                            indexChild,
                                            'hourlyWage',
                                          ],
                                          touched
                                        ) &&
                                          pathOr(
                                            null,
                                            [
                                              'splits',
                                              key,
                                              indexChild,
                                              'hourlyWage',
                                            ],
                                            errors
                                          )}
                                      </div>
                                    </td>
                                  )}
                                  <td style={{ paddingLeft: '10px' }}>
                                    <Checkbox
                                      valuePropName='checked'
                                      checked={
                                        values.splits[key][indexChild]
                                          .isBreakTime
                                      }
                                      name={`splits[${key}][${indexChild}].isBreakTime`}
                                      onChange={e => {
                                        setFieldValue(
                                          `splits[${key}].${indexChild}.isBreakTime`,
                                          e.target.checked
                                        )
                                        if (e.target.checked)
                                          setFieldValue(
                                            `splits[${key}].${indexChild}.hourlyWage`,
                                            0
                                          )
                                      }}
                                      disabled={disabled}
                                    />
                                  </td>
                                </tr>
                              </>
                            )
                          }
                        )}
                      </tbody>
                    </table>
                    <div
                      // colSpan={4}
                      style={{
                        color: 'red',
                      }}
                    >
                      {values.splitsWarning?.[key]?.isHideWarningText
                        ? '＊午前午後夜間の時間帯を跨ぐシフトは、募集シフト自動時給変更機能でシフト開始時間の時給へ上書きされます'
                        : null}
                    </div>
                    <div style={{ textAlign: 'right' }}>
                      {!disabled && values.splits[`${key}`].length < 4 && (
                        <Button
                          onClick={() => {
                            const newDate = getAvailableTime({
                              currentTime:
                                values.splits[key][
                                  values.splits[key].length - 1
                                ].date,
                            })
                            setCount(count + 1)
                            if (
                              (isCreateAvailableShift ||
                                isCreateWorkSchedule) &&
                              handleClickAddHourlyWage
                            ) {
                              handleClickAddHourlyWage(
                                values.splits[`${key}`][
                                  values.splits[`${key}`].length - 1
                                ].date,
                                values.splits[`${key}`].length,
                                values.splits[`${key}`]
                              )
                            }
                            const newVlues = values.splits[`${key}`].concat([
                              {
                                date: newDate,
                                hourlyWage: 0,
                                clinicalDepartmentId:
                                  values.splits[key][0].clinicalDepartmentId ||
                                  undefined,
                                isBreakTime: false,
                                type: values.splits[key][0].type,
                              },
                            ])
                            setFieldValue(`splits[${key}]`, newVlues)
                          }}
                          icon='pi pi-plus'
                          label={i18n.t('main.add')}
                        />
                      )}
                    </div>
                  </div>
                ))}
          </React.Fragment>
        </div>
      </div>
      {isMoneyPermitted && (
        <div className='tr'>
          <div className='th'>{i18n.t('main.dailySalary')}</div>
          <div className='td'>
            {values.splits !== undefined &&
              Object.keys(values.splits).map(key =>
                calculateSalary({
                  availableShift: splitLogic({
                    submitDate: moment()
                      .startOf('day')
                      .hour(9),
                    splits: values.splits[key],
                  }),
                  i18n,
                  dayOfWeek: key,
                  startTime: values.splits[key][0].startTime,
                  endTime: moment(
                    values.splits[key][values.splits[key].length - 1].date
                  )
                    .hour(
                      moment(
                        values.splits[key][values.splits[key].length - 1].date
                      ).hour()
                    )
                    .minute(
                      moment(
                        values.splits[key][values.splits[key].length - 1].date
                      ).minute()
                    ),
                  hourlyWageRange,
                  doctorSubsidy: calculaterSubsidy(values.doctorSubsidy),
                })
              )}
          </div>
        </div>
      )}
      {(isMoneyPermitted && isCreateAvailableShift) ||
      isDetailAvailableShiftGroup ? (
        <div className='tr'>
          <div className='th'>早特表示</div>
          <div className='td'>
            <Checkbox
              checked={values.isEarlySpecial}
              onChange={e => {
                setFieldValue('isEarlySpecial', e.target.checked)
              }}
            />
          </div>
        </div>
      ) : null}
      {(isMoneyPermitted && isCreateAvailableShift) ||
      isDetailAvailableShiftGroup ? (
        <div className='tr'>
          <div
            style={{
              color: 'red',
            }}
            className='th'
          >
            二診目登録
          </div>
          <div className='td checkbox-color-border'>
            <Checkbox
              checked={values.isDoubleRecruitment}
              onChange={e => {
                setFieldValue('isDoubleRecruitment', e.target.checked)
              }}
            />
          </div>
        </div>
      ) : null}
    </React.Fragment>
  )
}

export default ShiftGroupFormSplits
