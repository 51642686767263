import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import DocumentForm from '@medical/pages/staff/Documents/components/DocumentForm'
import { useCustom } from '@medical/provider/context'
import React from 'react'

import { SINGLE_DOCUMENT, UPDATE_DOCUMENT } from './DocumentUpdate.graphql'

const DocumentUpdate = ({
  match: {
    params: { documentId },
  },
}) => {
  const [{ i18n, popup }] = useCustom()
  const [updateDocument] = useMutation(UPDATE_DOCUMENT)
  const { loading, error, data } = useQuery(SINGLE_DOCUMENT, {
    variables: {
      documentId,
    },
  })

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const onSubmit = async (
    { title, content },
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      await updateDocument({
        variables: {
          documentId,
          title,
          content,
        },
      })
      popup.success(i18n.t('staff.updateDocument.submissionSuccess'))
      setSubmitting(false)
      resetForm()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
      setFieldValue('confirm', false)
    }
  }

  const { document } = data

  return (
    <DocumentForm
      document={document || {}}
      onSubmit={onSubmit}
      submitLabel={i18n.t('staff.updateDocument.submit')}
      confirmationMessage={i18n.t('staff.updateDocument.submitConfirmation')}
    />
  )
}

export default DocumentUpdate
