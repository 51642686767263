import '../style.css'

import { useCustom } from '@medical/provider/context'
import React from 'react'

const SignupSuccess = () => {
  const [{ i18n }] = useCustom()
  return (
    <div>
      <div className='signup-title'>
        {i18n.t('doctor.signupSuccess.successTitle')}
      </div>
      <div className='notice-title'>
        {i18n.t('doctor.signupSuccess.noticeTitle')}
      </div>
      <div className='signup-successTitle'>
        {i18n.t('doctor.signupSuccess.successContent1')}
      </div>
      <div className='signup-successTitle'>
        {i18n.t('doctor.signupSuccess.successContent2')}
      </div>
      <div className='signup-successTitle'>
        {i18n.t('doctor.signupSuccess.successContent3')}
      </div>
      <div className='signup-successTitle last-content'>
        {i18n.t('doctor.signupSuccess.successContent4')}
      </div>
    </div>
  )
}

export default SignupSuccess
