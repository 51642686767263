import ConfirmationModal from '@medical/components/ConfirmationModal'
import { ErrorMessage, Formik } from 'formik'
import { Button } from 'primereact/button'
import Editor from '@medical/components/Editor'
import React from 'react'
import * as Yup from 'yup'

export const GuidanceMessage = ({ i18n, onSubmit, clinic }) => {
  return (
    <>
      <Formik
        initialValues={{
          messageIntroduce: clinic.messageIntroduce,
          isConfirmDisplay: false,
        }}
        validationSchema={Yup.object({
          messageIntroduce: Yup.string()
            .nullable()
            .required('案内メッセージを入力してください'),
        })}
        onSubmit={onSubmit}
        render={formikProps => {
          const { setFieldValue, values } = formikProps
          return (
            <>
              <p>
                <span style={{ color: '#f03a6c' }}>※</span>
                {i18n.t('staff.editClinic.guidanceQuote')}
              </p>
              <Editor
                value={values.messageIntroduce}
                onTextChange={e =>
                  setFieldValue('messageIntroduce', e.htmlValue)
                }
              />
              <ErrorMessage
                name='messageIntroduce'
                component='div'
                className='signup-alert'
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 10,
                }}
              >
                <Button
                  className='reflect-button'
                  a
                  label={i18n.t('staff.editClinic.btnUpdate')}
                  onClick={() => setFieldValue('isConfirmDisplay', true)}
                />
              </div>
              <ConfirmationModal
                {...formikProps}
                confirmationMessage={i18n.t(
                  'staff.editClinic.baseMessageConfirm'
                )}
                visibleKey={'isConfirmDisplay'}
                handleNoClick={() => setFieldValue('isConfirmDisplay', false)}
              />
            </>
          )
        }}
      />
    </>
  )
}
