import { Button } from 'primereact/button'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

const NotFound = ({ history }) => {
  const { i18n } = useTranslation()

  return (
    <div className='static-container'>
      <div className='static-title'>404</div>
      <div className='static-content'>{i18n.t('main.pageNotFound')}</div>
      <Button
        label={i18n.t('main.pageNotFoundButton')}
        className='static-button'
        onClick={() => history.push('/')}
      />
    </div>
  )
}

export default withRouter(NotFound)
