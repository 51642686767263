import gql from 'graphql-tag'

export const ACTIVITY_LOGS_UPDATE_BY_STAFF = gql`
  query ActivityLogsConnection(
    $skip: Int
    $first: Int
    $where: ActivityLogWhereInput
    $orderBy: ActivityLogOrderByInput
  ) {
    activityLogsConnection(
      skip: $skip
      first: $first
      where: $where
      orderBy: $orderBy
    ) {
      aggregate(where: $where) {
        count
      }
      edges {
        node {
          id
          doctor {
            id
            lastname
            firstname
          }
          activity
          details
          createdAt
          staff {
            id
            lastname
            firstname
            createdAt
          }
        }
      }
    }
    staffs {
      id
      firstname
      lastname
      firstnameKana
      lastnameKana
      email
    }
  }
`
