import React from 'react'
export const clinicDepartmentListToString = (
  value,
  getClinic,
  maxLetterLength
) => {
  if (maxLetterLength) {
    return value.map(e => {
      const clinic = getClinic(e).clinicName
      const [clinicName, clinicDepartment] = clinic.split('_')
      return (
        <>
          {clinicDepartment.length > maxLetterLength
            ? clinicName.concat(
                '_',
                clinicDepartment.substr(0, maxLetterLength).concat('...')
              )
            : clinic}
          <br />
        </>
      )
    })
  }
  return value.map(e => (
    <>
      {getClinic(e).clinicName}
      <br />
    </>
  ))
}
