import ClinicNameRender from '@medical/components/ClinicNameRender'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import { splitLogicReverse } from '@medical/libs'
import ShiftFormWorkOutsideSplits from '@medical/pages/staff/components/ShiftFormWorkOutsideSplits'
import { Checkbox, Input } from 'antd'
import { ErrorMessage, Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import React from 'react'
import { isMobile } from 'react-device-detect'
import * as Yup from 'yup'

const AcceptedWorkScheduleOverTimeScene = ({
  i18n,
  workSchedule,
  departmentNames,
  onSubmit,
  closeModal,
  dataOvertime,
  workScheduleActualTime,
  date,
}) => {
  const { clinicalDepartment, startTime, endTime } = workSchedule

  const { TextArea } = Input
  const validationSchema = Yup.object()
    .shape({
      reasonOverTime: Yup.string().when('isOverTime', {
        is: true,
        then: Yup.string()
          .trim()
          .max(500, i18n.t('validation.title.max', { max: 500 }))
          .required('残業勤務理由を入力してください'),
      }),
      // startTime: Yup.string().when('isEarlyArrival', {
      //   is: true,
      //   then: Yup.string().test(
      //     'len',
      //     `${i18n.t('main.startTime')}${i18n.t('validation.required')}`,
      //     val => val !== undefined
      //   ),
      // }),
      reasonEarlyArrival: Yup.string().when('isEarlyArrival', {
        is: true,
        then: Yup.string()
          .trim()
          .max(500, i18n.t('validation.title.max', { max: 500 }))
          .required('早出勤務理由を入力してください'),
      }),
      // comment: Yup.string().when('isComment', {
      //   is: true,
      //   then: Yup.string()
      //     .trim()
      //     .max(500, i18n.t('validation.title.max', { max: 500 }))
      //     .required('その他事務局へのコメントを入力してください'),
      // }),
    })
    .test(
      'checkSelect',
      '早出または残業の理由を記載してから申請してください',
      value => {
        if (!value.isOverTime && !value.isEarlyArrival) {
          return false
        }
        return true
      }
    )
  return (
    <Formik
      enableReinitialize
      initialValues={{
        confirm: false,
        startTime: dataOvertime?.startTime || startTime,
        splits: dataOvertime
          ? splitLogicReverse({ availableShift: dataOvertime })
          : splitLogicReverse({ availableShift: workSchedule.acceptedShift }),
        isOverTime: dataOvertime ? dataOvertime.lateArrival : false,
        isEarlyArrival: dataOvertime ? dataOvertime.earlyDeparture : false,
        isShowOverTime: false,
        isShowArrival: false,
        // startTime: dataOvertime ? dataOvertime.startTime : '',
        endTime: dataOvertime ? dataOvertime.endTime : endTime,
        reasonEarlyArrival: dataOvertime
          ? dataOvertime.reasonEarlyDeparture
          : undefined,
        reasonOverTime: dataOvertime
          ? dataOvertime.reasonLateArrival
          : undefined,
        isDisableOverTime: dataOvertime ? dataOvertime.lateArrival : true,
        isDisableEarlyArrival: dataOvertime
          ? dataOvertime.earlyDeparture
          : true,
        comment: dataOvertime ? dataOvertime.comment : undefined,
        isComment: dataOvertime ? dataOvertime.comment : false,
        isDisableComment: dataOvertime ? dataOvertime.hasComment : true,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={formikProps => {
        const {
          setFieldValue,
          values,
          validateForm,
          setTouched,
          handleChange,
        } = formikProps
        if (isMobile) {
          return (
            <div className='' style={{ border: 'none' }}>
              <div className='schedule-calendar'>
                <div className='calendar-container'>
                  <div className='row' style={{ justifyContent: 'center' }}>
                    <div
                      className='move-expenses-header__title'
                      style={{ fontSize: '18px' }}
                    >
                      {i18n.t('doctor.overTime.title')}
                    </div>
                  </div>
                  <div className='tr'>
                    <div className='th'>{i18n.t('main.clinicName')}</div>
                    <div className='td'>
                      {ClinicNameRender({ clinicalDepartment })}
                    </div>
                  </div>
                  <div className='tr'>
                    <div className='th'> {i18n.t('main.departments')}</div>
                    <div className='td'>
                      {departmentNames[clinicalDepartment.name]}
                    </div>
                  </div>
                  <div className='tr'>
                    <div className='th'> {i18n.t('main.date')}</div>
                    <div className='td'>{moment(startTime).format('LL')}</div>
                  </div>
                  <div className='tr'>
                    <div className='th'>
                      {i18n.t('doctor.overTime.timeWork')}
                    </div>
                    {workScheduleActualTime && (
                      <div className='td'>
                        {` ${moment(workScheduleActualTime.startTime).format(
                          'HH:mm'
                        )}〜  ${moment(workScheduleActualTime.endTime).format(
                          'HH:mm'
                        )}`}
                      </div>
                    )}
                  </div>
                  <div className='row mt-15 move-inline move-expenses-item-container'>
                    <ShiftFormWorkOutsideSplits
                      {...formikProps}
                      i18n={i18n}
                      isDoctor
                      isMoneyPermitted
                      disabled={dataOvertime}
                    />
                  </div>

                  {values.isDisableEarlyArrival && (
                    <>
                      <div className=' mt-15 move-expenses-item-container'>
                        <div className='over-time-item__title checkbox'>
                          <Checkbox
                            checked={values.isEarlyArrival}
                            disabled={dataOvertime}
                            onChange={() => {
                              setFieldValue(
                                'isEarlyArrival',
                                !values.isEarlyArrival
                              )
                              setFieldValue(
                                'checked',
                                values.isEarlyArrival || values.isOverTime
                              )
                              setFieldValue('reasonEarlyArrival', [])
                            }}
                          >
                            {i18n.t('doctor.overTime.earlyArrival')}
                          </Checkbox>
                        </div>
                      </div>
                      {values.isEarlyArrival && (
                        <>
                          <div className='' style={{ paddingLeft: '8px' }}>
                            <Checkbox.Group
                              onChange={checkedValues => {
                                setFieldValue(
                                  'reasonEarlyArrival',
                                  checkedValues
                                )
                              }}
                              disabled={dataOvertime}
                              value={values.reasonEarlyArrival}
                              name={'reasonEarlyArrival'}
                              options={[
                                { label: 'カルテ修正', value: 'カルテ修正' },
                                {
                                  label: 'スタッフからの早出依頼',
                                  value: 'スタッフからの早出依頼',
                                },
                                { label: 'その他', value: 'その他' },
                              ]}
                            />
                            <ErrorMessage
                              name='reasonEarlyArrival'
                              component='div'
                              className='color-red'
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {values.isDisableOverTime && (
                    <div className=' mt-15  move-expenses-item-container'>
                      <div className='over-time-item__title checkbox'>
                        <Checkbox
                          checked={values.isOverTime}
                          disabled={dataOvertime}
                          onChange={() => {
                            setFieldValue('isOverTime', !values.isOverTime)
                            setFieldValue(
                              'checked',
                              values.isEarlyArrival || values.isOverTime
                            )
                            setFieldValue('reasonOverTime', [])
                          }}
                        >
                          {i18n.t('doctor.overTime.overTime')}
                        </Checkbox>
                      </div>
                    </div>
                  )}
                  {values.isOverTime && (
                    <>
                      <div className='' style={{ paddingLeft: '8px' }}>
                        <Checkbox.Group
                          name={'reasonOverTime'}
                          onChange={checkedValues => {
                            setFieldValue('reasonOverTime', checkedValues)
                          }}
                          disabled={dataOvertime}
                          value={values.reasonOverTime}
                          options={[
                            {
                              label: '患者さま対応',
                              value: '患者さま対応',
                            },
                            {
                              label: 'カルテ修正',
                              value: 'カルテ修正',
                            },
                            { label: '疑義照会', value: '疑義照会' },
                            { label: '緊急搬送', value: '緊急搬送' },
                            { label: 'その他', value: 'その他' },
                          ]}
                        />
                        <ErrorMessage
                          name='reasonOverTime'
                          component='div'
                          className='color-red'
                        />
                      </div>
                    </>
                  )}
                  <div className='alert' style={{ textAlign: 'center' }}>
                    <ErrorMessage
                      name='undefined'
                      component='div'
                      className='color-red'
                    />
                  </div>
                  {!dataOvertime && (
                    <div
                      className='modal-bottom'
                      style={{ marginBottom: '5px' }}
                    >
                      <Button
                        onClick={closeModal}
                        type='submit'
                        className='outline-red'
                        label={i18n.t('main.deletedReasonCancel')}
                      />
                      <Button
                        className='reflect-button'
                        label={i18n.t('main.applyDoctor')}
                        disabled={!values.isEarlyArrival && !values.isOverTime}
                        type='submit'
                        onClick={() => {
                          validateForm().then(response => {
                            if (!Object.keys(response).length) {
                              setFieldValue('confirm', true)
                            } else {
                              setTouched(response)
                            }
                          })
                        }}
                      />
                    </div>
                  )}

                  <div className='confirmation-moving'>
                    <ConfirmationModal
                      {...formikProps}
                      confirmationMessage={i18n.t('doctor.overTime.submit')}
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        }
        return (
          <div className='container move-expenses-container'>
            <div className='row' style={{ justifyContent: 'center' }}>
              <div
                className='move-expenses-header__title'
                style={{ fontSize: '18px' }}
              >
                {i18n.t('doctor.overTime.title')}
              </div>
            </div>
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='over-time-item__title'>
                {i18n.t('main.clinicName')}
              </div>
              <div className='width-67'>
                {ClinicNameRender({ clinicalDepartment })}
              </div>
            </div>
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='over-time-item__title'>
                {i18n.t('main.departments')}
              </div>
              <div className='width-67'>
                {departmentNames[clinicalDepartment.name]}
              </div>
            </div>
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='over-time-item__title'>{i18n.t('main.date')}</div>
              <div className='width-67'>{moment(startTime).format('LL')}</div>
            </div>
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='over-time-item__title'>
                {i18n.t('doctor.overTime.timeWork')}
              </div>
              {workScheduleActualTime && (
                <div className='width-67 '>
                  {` ${moment(workScheduleActualTime.startTime).format(
                    'HH:mm'
                  )}〜
                  ${moment(workScheduleActualTime.endTime).format('HH:mm')}`}
                </div>
              )}
            </div>
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <ShiftFormWorkOutsideSplits
                {...formikProps}
                i18n={i18n}
                isDoctor
                isMoneyPermitted
                isAcceptedWorkScheduleOverTime
                disabled={dataOvertime}
              />
            </div>
            {values.isDisableEarlyArrival && (
              <>
                <div className=' mt-15 move-expenses-item-container'>
                  <div className='over-time-item__title checkbox'>
                    <Checkbox
                      checked={values.isEarlyArrival}
                      disabled={dataOvertime}
                      onChange={() => {
                        setFieldValue('isEarlyArrival', !values.isEarlyArrival)
                        setFieldValue(
                          'checked',
                          values.isEarlyArrival || values.isOverTime
                        )
                        setFieldValue('reasonEarlyArrival', [])
                      }}
                    >
                      {i18n.t('doctor.overTime.earlyArrival')}
                    </Checkbox>
                  </div>
                </div>
                {values.isEarlyArrival && (
                  <>
                    <div className='' style={{ paddingLeft: '8px' }}>
                      <Checkbox.Group
                        onChange={checkedValues => {
                          setFieldValue('reasonEarlyArrival', checkedValues)
                        }}
                        disabled={dataOvertime}
                        value={values.reasonEarlyArrival}
                        name={'reasonEarlyArrival'}
                        options={[
                          { label: 'カルテ修正', value: 'カルテ修正' },
                          {
                            label: 'スタッフからの早出依頼',
                            value: 'スタッフからの早出依頼',
                          },
                          { label: 'その他', value: 'その他' },
                        ]}
                      />
                      <ErrorMessage
                        name='reasonEarlyArrival'
                        component='div'
                        className='color-red'
                      />
                    </div>
                  </>
                )}
              </>
            )}
            {values.isDisableOverTime && (
              <div className=' mt-15  move-expenses-item-container'>
                <div className='over-time-item__title checkbox'>
                  <Checkbox
                    checked={values.isOverTime}
                    disabled={dataOvertime}
                    onChange={() => {
                      setFieldValue('isOverTime', !values.isOverTime)
                      setFieldValue(
                        'checked',
                        values.isEarlyArrival || values.isOverTime
                      )
                      setFieldValue('reasonOverTime', [])
                    }}
                  >
                    {i18n.t('doctor.overTime.overTime')}
                  </Checkbox>
                </div>
              </div>
            )}
            {values.isOverTime && (
              <>
                <div className='' style={{ paddingLeft: '8px' }}>
                  <Checkbox.Group
                    name={'reasonOverTime'}
                    onChange={checkedValues => {
                      setFieldValue('reasonOverTime', checkedValues)
                    }}
                    disabled={dataOvertime}
                    value={values.reasonOverTime}
                    options={[
                      {
                        label: '患者さま対応',
                        value: '患者さま対応',
                      },
                      {
                        label: 'カルテ修正',
                        value: 'カルテ修正',
                      },
                      { label: '疑義照会', value: '疑義照会' },
                      { label: '緊急搬送', value: '緊急搬送' },
                      { label: 'その他', value: 'その他' },
                    ]}
                  />
                  <ErrorMessage
                    name='reasonOverTime'
                    component='div'
                    className='color-red'
                  />
                </div>
              </>
            )}
            <div className='alert'>
              <ErrorMessage
                name='undefined'
                component='div'
                className='color-red'
              />
            </div>
            {!dataOvertime && (
              <div className='modal-bottom' style={{ marginBottom: '5px' }}>
                <Button
                  onClick={closeModal}
                  type='submit'
                  className='outline-red'
                  label={i18n.t('main.deletedReasonCancel')}
                />
                <Button
                  className='reflect-button'
                  label={i18n.t('main.applyDoctor')}
                  // disabled={!values.isEarlyArrival && !values.isOverTime}
                  type='submit'
                  onClick={() => {
                    validateForm().then(response => {
                      if (!Object.keys(response).length) {
                        setFieldValue('confirm', true)
                      } else {
                        setTouched(response)
                      }
                    })
                  }}
                />
              </div>
            )}
            <div className='confirmation-moving'>
              <ConfirmationModal
                {...formikProps}
                confirmationMessage={i18n.t('doctor.overTime.submit')}
              />
            </div>
          </div>
        )
      }}
    />
  )
}
export default AcceptedWorkScheduleOverTimeScene
