import Router from '@medical/routes/router'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const BreadCrumb = ({ items, link }) => {
  const { i18n } = useTranslation()

  if (items.length < 1) return null

  return (
    <div className='breadcrumb'>
      <Link to={link || Router.home}>{i18n.t('main.home')}</Link>
      {items.map((item, k) =>
        item.to ? (
          <Link to={item.to} key={k}>
            <i className='pi pi-chevron-right' />
            {item.title}
          </Link>
        ) : (
          <React.Fragment key={k}>
            <i className='pi pi-chevron-right' />
            {item.title}
          </React.Fragment>
        )
      )}
    </div>
  )
}

export default BreadCrumb
