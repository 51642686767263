import './WaitingRecruitment.scss'

import BreadCrumb from '@medical/components/Breadcrumb'
import ModalLink from '@medical/components/ModalLink'
import { clinicDepartmentListToString } from '@medical/libs/clinicDepartmentListToString'
import { useCustom } from '@medical/provider/context'
import Router from '@medical/routes/router'
import { DatePicker } from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { MultiSelect } from 'primereact/multiselect'
import { Paginator } from 'primereact/paginator'
import React, { useState } from 'react'

import { ConfirmStatusTemplate } from './WaitingRecruitmentTemplate'

const { RangePicker } = DatePicker

export const WaitingRecruitmentScene = ({
  clinicalDepartments,
  waitingRecruitmentShiftList,
  clinicalDepartmentIds,
  doctorName,
  setClinicalDepartmentIds,
  setDoctorName,
  page,
  setPage,
  pageSize,
  count,
  setDateRange,
  dateRangeStart,
  dateRangeEnd,
  clearFilter,
  setConfirmationStatus,
  confirmStatusSelected,
}) => {
  const [{ i18n }] = useCustom()
  const currentDate = moment(new Date())
  const [nameFilter, setNameFilter] = useState(doctorName)

  const getClinic = e => clinicalDepartments.find(cd => cd.id === e.clinic)

  return (
    <div className='waiting-recruit'>
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('staff.waitingRecruitment.title')}
          <BreadCrumb
            items={[{ title: i18n.t('staff.waitingRecruitment.title') }]}
          />
        </div>
      </div>
      <div className='container is-max'>
        <div className='box'>
          <div className='filter'>
            <InputText
              className='mx-2 filter-doctorName'
              placeholder={i18n.t('staff.waitingRecruitment.doctorName')}
              value={nameFilter}
              onChange={e => setNameFilter(e.target.value)}
              onBlur={e => setDoctorName(nameFilter)}
              onKeyDown={e => {
                if (e.key === 'Enter') setDoctorName(nameFilter)
              }}
            />
            <MultiSelect
              className='mx-2 dropdown-clinic'
              options={clinicalDepartments}
              optionLabel='clinicName'
              value={clinicalDepartments.filter(({ id }) =>
                clinicalDepartmentIds.includes(id)
              )}
              onChange={e => setClinicalDepartmentIds(e)}
              placeholder={i18n.t('staff.waitingRecruitment.clinicSelect')}
              fixedPlaceholder
              filter
            />
            <Dropdown
              value={confirmStatusSelected}
              name='confirmation-status'
              optionLabel='description'
              valueLabel='value'
              options={ConfirmStatusTemplate}
              placeholder={i18n.t('staff.waitingRecruitment.status')}
              onChange={e => setConfirmationStatus(e.target.value)}
            />
            <RangePicker
              size='medium'
              className='mx-2 date-picker'
              locale={locale}
              value={
                !dateRangeStart || !dateRangeEnd
                  ? ''
                  : [moment(dateRangeStart), moment(dateRangeEnd)]
              }
              onChange={e => setDateRange(e)}
              allowClear={false}
            />
            <Button
              onClick={e => {
                setNameFilter('')
                clearFilter()
              }}
              icon='pi pi-times'
              className='mx-2'
            />
          </div>
          <div>
            <table className='table'>
              <thead>
                <tr>
                  <th width='12%'>
                    {i18n.t('staff.waitingRecruitment.doctorName')}
                  </th>
                  <th width='12%'>
                    {i18n.t('staff.waitingRecruitment.registerDate')}
                  </th>
                  <th width='18%'>
                    {i18n.t('staff.waitingRecruitment.clinicDepart')}
                  </th>
                  <th width='19%'>
                    {i18n.t('staff.waitingRecruitment.workableDate')}
                  </th>
                  <th width='10%'>
                    {i18n.t('staff.waitingRecruitment.workingHour')}
                  </th>
                  <th width='10%'>{i18n.t('staff.waitingRecruitment.note')}</th>
                  <th width='6%'>
                    {i18n.t('staff.waitingRecruitment.isRecruitment')}
                  </th>
                  <th width='5%'>
                    {i18n.t('staff.waitingRecruitment.status')}
                  </th>
                  <th width='5%'>
                    {i18n.t('staff.waitingRecruitment.detail')}
                  </th>
                  <th width='10%'>
                    {i18n.t('staff.waitingRecruitment.create')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {waitingRecruitmentShiftList
                  .sort((a, b) => moment(b.updatedAt) - moment(a.updatedAt))
                  .map(e => (
                    <tr style={{ textAlign: 'center' }} key={e.id}>
                      <td>{e.doctor.fullName}</td>
                      <td>
                        {moment(e.createdAt).format('YYYY年MM月DD日(ddd)')}
                      </td>
                      <td style={{ textAlign: 'left' }}>
                        {/* {e.recruitmentShifts
                          .reduce(
                            (res, cur) =>
                              res.concat(`${getClinic(cur).clinicName}、`),
                            ''
                          )
                          .slice(0, -1)} */}
                        {clinicDepartmentListToString(
                          e.recruitmentShifts,
                          ({ clinic }) =>
                            clinicalDepartments.find(cd => cd.id === clinic),
                          8
                        )}
                      </td>
                      <td>
                        {moment(e.startDate).format('YYYY年MM月DD日')}
                        &nbsp;-&nbsp;
                        {moment(e.endDate).format('YYYY年MM月DD日')}
                      </td>
                      <td>
                        {moment(e.startTime).format('HH:mm')}&nbsp;-&nbsp;
                        {moment(e.endTime).format('HH:mm')}
                      </td>
                      <td className='oneLine'>{e.description}</td>
                      <td>
                        {e.active
                          ? i18n.t('main.yesLabel')
                          : i18n.t('main.noLabel')}
                      </td>
                      <td>
                        {i18n.t(
                          'staff.waitingRecruitment.confirmationStatus.' +
                            `${e.confirmationStatus}`
                        )}
                      </td>
                      <td>
                        <ModalLink
                          to={Router.get(Router.staffWaitingRecruitmentDetail, {
                            id: e.id,
                          })}
                          state={{
                            waitingAvailableShift: {
                              ...e,
                              clinicName: e.recruitmentShifts
                                .reduce(
                                  (res, cur) =>
                                    res.concat(
                                      `${getClinic(cur).clinicName}、`
                                    ),
                                  ''
                                )
                                .slice(0, -1),
                            },
                          }}
                        >
                          <Button icon='pi pi-info' />
                        </ModalLink>
                      </td>
                      <td>
                        <ModalLink
                          to={Router.get(
                            Router.staffCreateAvailableShiftYearMonthDay,
                            {
                              year: currentDate.format('YYYY'),
                              month: currentDate.format('MM'),
                              day: currentDate.format('DD'),
                            }
                          )}
                          state={{
                            clinicalDepartment: clinicalDepartments.find(
                              cd => cd.id === e.clinic
                            ),
                            isWaitingShiftCreate: true,
                            waitingAvailableShift: e,
                            isPublished: true,
                          }}
                        >
                          <Button className='main-color' icon='pi pi-plus' />
                        </ModalLink>
                      </td>
                    </tr>
                  ))}
                {waitingRecruitmentShiftList.length === 0 && (
                  <tr>
                    <td colSpan={9}>
                      {i18n.t('staff.waitingRecruitment.noRecord')}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <Paginator
              rows={pageSize}
              totalRecords={count}
              first={(page - 1) * pageSize}
              onPageChange={e => setPage(e.page + 1)}
              leftContent={
                <div className='paginator-total'>
                  {i18n.t('main.paginationTotal')}: {count}
                </div>
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}
