import gql from 'graphql-tag'

export const HIDE_PORTAL = gql`
  mutation staffDeletePortal(
    $portalId: ID
    $isHide: Boolean
    $isDeleted: Boolean
  ) {
    staffDeletePortal(
      portalId: $portalId
      isHide: $isHide
      isDeleted: $isDeleted
    ) {
      id
      largeTitle
      mediumTitle
      smallTitle
      titleType
      content
    }
  }
`

export const CATEGORY_QUERY = gql`
  query Portals($orderBy: PortalOrderByInput, $where: PortalWhereInput) {
    portals(orderBy: $orderBy, where: $where) {
      id
      largeTitle
      mediumTitle
      titleType
    }
  }
`

export const PORTAL_CONNECTIONS = gql`
  query PortalsConnection(
    $where: PortalWhereInput
    $orderBy: PortalOrderByInput
    $skip: Int
    $first: Int
  ) {
    portalsConnection(
      where: $where
      orderBy: $orderBy
      skip: $skip
      first: $first
    ) {
      edges {
        node {
          id
          isHide
          largeTitle
          mediumTitle
          smallTitle
          titleType
          createdAt
          updatedAt
          updatedBy {
            id
            firstname
            lastname
          }
          createdBy {
            id
            firstname
            lastname
          }
        }
      }
      aggregate(where: $where) {
        count
      }
    }
  }
`
