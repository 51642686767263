import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, NotFound, ProgressSpinner } from '@medical/components'
import { clinicalDepartmentNames } from '@medical/libs'
import AcceptedWorkScheduleCancelScene from '@medical/pages/doctor/AcceptedWorkScheduleCancel/AcceptedWorkScheduleCancelScene'
import { DOCTOR_WORK_SCHEDULE } from '@medical/pages/doctor/SingleSchedule/SingleSchedule.graphql'
import { useCustom } from '@medical/provider/context'
import React from 'react'

import { CREATE_WORK_SCHEDULE_CANCELLATION_LETTER } from './AcceptedWorkScheduleDetail.graphql'
import AcceptedWorkScheduleDetailScene from './AcceptedWorkScheduleDetailScene'

const AcceptedWorkScheduleDetail = ({
  closeModal,
  location: { pathname },
  match: {
    url,
    params: { workScheduleId },
  },
}) => {
  const [{ i18n, popup }] = useCustom()
  const { loading, error, data } = useQuery(DOCTOR_WORK_SCHEDULE, {
    variables: { workScheduleId },
  })
  const [createWorkScheduleCancel] = useMutation(
    CREATE_WORK_SCHEDULE_CANCELLATION_LETTER
  )
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  const { workSchedule = {}, departments } = data
  if (!workSchedule) {
    return <NotFound />
  }
  const departmentNames = clinicalDepartmentNames({
    departments,
  })
  const onSubmit = async (
    values,
    { setSubmitting, setFieldValue, resetForm }
  ) => {
    try {
      await createWorkScheduleCancel({
        variables: {
          workScheduleId: values.workScheduleId,
          reason: values.reason,
          reassignDoctorNote: values.reassignDoctorNote,
          // isTickCountCancel: values.isTickCountCancel,
          reworkNote: values.reworkNote,
        },
      })
      if (values.isCheckTime) {
        popup.success(i18n.t('staff.hourlyPayment.submissionSuccess'))
        closeModal()
      }
      setSubmitting(false)
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
      setFieldValue('confirm', false)
    }
  }
  if (
    pathname.indexOf('cancel') > 0 &&
    (workSchedule.workScheduleCancellationLetter === null ||
      workSchedule.workScheduleCancellationLetter?.status === 'REJECTED')
  ) {
    return (
      <React.Fragment>
        <div className='modal-title' style={{ fontWeight: 'bold' }}>
          シフトキャンセル
        </div>
        <p>
          ご応募いただいた際に、ご同意いただいております通り、原則として、キャンセルに関しては、やむを得ない理由（事前に予想がつかないケース）を除き勤務日の2週間前までとさせていただいております。
          ご勤務日まで2週間以内のキャンセルの場合には、代診の医師が見つかるまで、キャンセルの承認がされません。事務局側でも、代診を探しますので、キャンセルをされる先生方にも、代診医師の手配のご協力をお願いします。
          また、勤務日から2週間以上ある日程のキャンセルについては、キャンセル数のカウントをさせていただきます。キャンセル数が多い場合には、その後の募集時給を個別に下げさせていただく可能性がございます。
          ただし、キャンセルされる際に、代診医師を手配していただいた場合と、他の日程に代替勤務を申請していだき、勤務が確定した場合には、キャンセルカウントの対象外とさせていただきます。
        </p>
        <AcceptedWorkScheduleCancelScene
          workSchedule={workSchedule}
          i18n={i18n}
          closeModal={closeModal}
          departmentNames={departmentNames}
          onSubmit={onSubmit}
        />
      </React.Fragment>
    )
  }
  return (
    <AcceptedWorkScheduleDetailScene
      workSchedule={workSchedule}
      i18n={i18n}
      closeModal={closeModal}
      departmentNames={departmentNames}
      url={url}
    />
  )
}
export default AcceptedWorkScheduleDetail
