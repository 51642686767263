import ClinicNameRender from '@medical/components/ClinicNameRender'
import {
  checkStaffPermission,
  dateValidation,
  sortAndFilterByClinicOrder,
} from '@medical/libs'
import {
  ShiftDateSelectors,
  ShiftHeader,
} from '@medical/pages/staff/components'
import Router from '@medical/routes/router'
import moment from 'moment'
import { Button } from 'primereact/button'
import React, { Suspense, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'

import StatusBar from './components/StatusBar'
import styles from './Style'
import { MultiSelect } from 'primereact/multiselect'
import { ProgressSpinner } from '@medical/components'
import {
  QUERY_ACCEPTED_WORK_SCHEDULES,
  QUERY_AVAILABLE_SHIFTS,
  QUERY_WORK_SCHEDULES,
  STAFF_CREATE_ACCEPTED_WS,
  STAFF_CREATE_AS_GROUP,
  STAFF_CREATE_SHIFT,
  STAFF_PAGE_AVAILABLE_SHIFT_PUBLISHED,
  STAFF_UPLOAD_SHIFT,
} from '@medical/constant/permissions'
import { he } from 'fork/react-date-range/dist/locale'
const AvailableShiftDataTable = React.lazy(() =>
  import('./components/AvailableShiftDataTable')
)
const classNames = {
  0: 'sunday',
  6: 'saturday',
}

const isBefore = val =>
  moment(val).isBefore(moment().startOf('day')) ? { opacity: '0.5' } : {}

const isPast = val =>
  moment(val).isBefore(moment().startOf('day')) ? 'is-past' : ''

const getData = ({
  arr,
  departmentNames,
  filterRegion,
  filterClinic,
  filterClinicalDepartment,
}) => {
  const result = []
  if (arr.length) {
    const newArr = arr
      .filter(({ id }) =>
        filterClinicalDepartment.length === 0
          ? true
          : filterClinicalDepartment.includes(id)
      )
      .filter(({ clinic }) =>
        filterClinic.length === 0 ? true : filterClinic.includes(clinic.id)
      )
      .filter(({ clinic }) =>
        filterRegion.length === 0 ? true : filterRegion.includes(clinic.region)
      )
    const sortedClinicalDepartments = sortAndFilterByClinicOrder({
      clinicalDepartments: newArr,
      isFilter: true,
    })
    const clinicColorMap = {}
    let clinicIndex = 0
    sortedClinicalDepartments.forEach((item, index) => {
      const departmentName = departmentNames[item.name]
      const clinicName = ClinicNameRender({ clinicalDepartment: item })
      const area = item.clinic?.Region?.name
      if (!clinicColorMap[item.clinic.id]) {
        clinicColorMap[item.clinic.id] =
          clinicIndex % 2 === 0 ? '#EAD9FF' : '#ffebeb'
        clinicIndex++
      }
      const backgroundColor = clinicColorMap[item.clinic.id]
      const itemContent = {
        clinic: (
          <div style={{ backgroundColor, height: '30px', lineHeight: '30px' }}>
            <p title={clinicName}>
              {clinicName && clinicName.length > 8
                ? `${clinicName.substring(0, 8)}...`
                : item.clinic.name}
            </p>
          </div>
        ),
        name: (
          <div style={{ backgroundColor, height: '30px', lineHeight: '30px' }}>
            <p title={departmentName}>
              {departmentName && departmentName.length > 10
                ? `${departmentName.substring(0, 10)}...`
                : departmentName}
            </p>
          </div>
        ),
        area: (
          <div style={{ backgroundColor, height: '30px', lineHeight: '30px' }}>
            {area && (
              <p title={area}>
                {area && area.length > 4 ? `${area.substring(0, 4)}...` : area}
              </p>
            )}
          </div>
        ),
      }
      item.monthReports.forEach((report, i) => {
        const {
          date,
          acceptedHours,
          availableHours,
          notRequestedAvailableShiftCount,
        } = report
        itemContent[`m${i}`] = (
          <div
            key={i}
            day={moment(date).day()}
            style={{ height: '30px', lineHeight: '30px' }}
          >
            <div style={isBefore(date)} className={isPast(date)}>
              <StatusBar
                date={date}
                clinicalDepartmentId={item.id}
                acceptedHours={acceptedHours}
                availableHours={availableHours}
                notRequestedAvailableShiftCount={
                  notRequestedAvailableShiftCount
                }
              />
            </div>
          </div>
        )
      })
      result.push(itemContent)
    })
  }
  return result
}

const today = moment()
  .startOf('day')
  .date()

const MonthlyAvailableShiftScene = ({
  i18n,
  clinicalDepartments,
  currentDate,
  holidays,
  departmentNames,
  isQueryRecruitmentUnpublished,
  isNewWS,
  listRegions,
  regionIds,
  setRegionIds,
  uniqueClinicName,
  clinicIds,
  setClinicIds,
  departmentNameList,
  setClinicalDepartmentNames,
  clinicalDepartmentNamesFilter,
  clinicalDepartmentIds,
}) => {
  const isUploadPermitted = checkStaffPermission({
    functionName: STAFF_UPLOAD_SHIFT,
  })

  const isStaffPageAvailableShiftPublished = checkStaffPermission({
    functionName: STAFF_PAGE_AVAILABLE_SHIFT_PUBLISHED,
  })

  const isCreateShiftPermitted = checkStaffPermission({
    functionName: STAFF_CREATE_SHIFT,
  })

  const isCreateSchedulePermitted = checkStaffPermission({
    functionName: STAFF_CREATE_ACCEPTED_WS,
  })

  const isCreateScheduledShiftGroupPermitted = checkStaffPermission({
    functionName: STAFF_CREATE_AS_GROUP,
  })

  const isQueryAcceptedWorkSchedulePermitted = checkStaffPermission({
    functionName: QUERY_ACCEPTED_WORK_SCHEDULES,
  })

  const isQueryWorkSchedulePermitted = checkStaffPermission({
    functionName: QUERY_WORK_SCHEDULES,
  })

  const isQueryAvailableShiftPermitted = checkStaffPermission({
    functionName: QUERY_AVAILABLE_SHIFTS,
  })

  const date = useMemo(() => moment(currentDate), [currentDate])
  const prevMonth = useMemo(() => moment(date).subtract(1, 'month'), [date])
  const nextMonth = useMemo(() => moment(date).add(1, 'month'), [date])
  const isPreviousDisabled = useMemo(
    () =>
      date.isSameOrBefore(moment().subtract(1, 'years'), 'month') &&
      dateValidation({
        year: prevMonth.year(),
        month: prevMonth.month() + 1,
      }),
    [date, prevMonth]
  )

  const isNextDisabled = useMemo(
    () =>
      dateValidation({
        year: nextMonth.year(),
        month: nextMonth.month() + 1,
      }) && date.isSameOrAfter(moment().add(6, 'quarters'), 'month'),
    [date, nextMonth]
  )

  useEffect(() => {
    if (
      date.month() === moment().month() &&
      document.getElementsByClassName('p-datatable-scrollable-body').length > 0
    ) {
      document.getElementsByClassName(
        'p-datatable-scrollable-body'
      )[1].scrollLeft = (today - 1) * 80
      document.getElementsByClassName(
        'p-datatable-scrollable-body'
      )[1].style.height = 'calc(100vh - 204px)'
    }
  }, [date, today])

  const value = useMemo(() => {
    return getData({
      arr: clinicalDepartments,
      departmentNames,
      filterRegion: regionIds,
      filterClinic: clinicIds,
      filterClinicalDepartment: clinicalDepartmentIds,
    })
  }, [
    clinicalDepartments,
    departmentNames,
    regionIds,
    clinicIds,
    clinicalDepartmentIds,
  ])

  const clinicalDepartment = useMemo(
    () => clinicalDepartments[0].monthReports,
    [clinicalDepartments]
  )

  return (
    <div>
      <ShiftHeader
        items={[{ title: moment(date).format('YYYY年MM月') }]}
        isCreateShiftPermitted={isCreateShiftPermitted}
        isUploadPermitted={isUploadPermitted}
        isCreateSchedulePermitted={isCreateSchedulePermitted}
        isCreateScheduledShiftGroupPermitted={
          isCreateScheduledShiftGroupPermitted
        }
      />
      <div className='box pt-3'>
        <div className='staff-download-buttons'>
          <div className='tooltip-grant'>
            <div className='tooltip-parent'>
              <span>{i18n.t('staff.availableShift.tooltipTitle')}</span>
            </div>
            <div className='tooltip-parent'>
              <div className='progress-bar danger' />
              <span>{i18n.t('staff.availableShift.tooltipEmpty')}</span>
              &nbsp;
              <div className='progress-bar-bg' />
              <span>{i18n.t('staff.availableShift.tooltipAvailable')}</span>
            </div>
            <div className='tooltip-parent'>
              <span>{i18n.t('staff.availableShift.tooltipDescription')}</span>
            </div>
          </div>
          <div className='staff-download-button'>
            {isQueryRecruitmentUnpublished &&
            isStaffPageAvailableShiftPublished ? (
              <Link
                to={{
                  pathname: Router.get(Router.staffRecruitmentUnpublished, {
                    year: date.clone().year(),
                    month: date.clone().month() + 1,
                  }),
                }}
              >
                <Button
                  icon='pi pi-list'
                  className='p-button-normal-empty'
                  label={i18n.t('main.recruitmentUnpublished')}
                />
              </Link>
            ) : null}
            {isQueryAvailableShiftPermitted ? (
              <Link
                to={{
                  pathname: Router.get(Router.staffAvailableShiftList, {
                    year: date.clone().year(),
                    month: date.clone().month() + 1,
                  }),
                }}
              >
                <Button
                  icon='pi pi-list'
                  className='p-button-normal-empty'
                  label={i18n.t('main.availableShiftList')}
                />
              </Link>
            ) : null}
            {isQueryWorkSchedulePermitted ? (
              <>
                {isNewWS ? (
                  <>
                    <Link
                      to={{
                        pathname: Router.get(Router.staffWorkSchedulesList, {
                          year: date.clone().year(),
                          month: date.clone().month() + 1,
                        }),
                      }}
                    >
                      <button className='p-button p-component p-button-normal-available p-button-text-icon-left'>
                        <span className='pi pi-list p-c p-button-icon-left'></span>
                        <span
                          className='p-button-text p-c'
                          style={{ display: 'inline-block' }}
                        >
                          {i18n.t('main.workScheduleList')}
                        </span>
                        <span className='new-ws'>new</span>
                      </button>
                    </Link>
                  </>
                ) : (
                  <Link
                    to={{
                      pathname: Router.get(Router.staffWorkSchedulesList, {
                        year: date.clone().year(),
                        month: date.clone().month() + 1,
                      }),
                    }}
                  >
                    <Button
                      icon='pi pi-list'
                      className='p-button-normal-available'
                      label={i18n.t('main.workScheduleList')}
                    />
                  </Link>
                )}
              </>
            ) : null}
            {isQueryAcceptedWorkSchedulePermitted ? (
              <Link
                to={{
                  pathname: Router.get(Router.staffAcceptedWorkSchedulesList, {
                    year: date.clone().year(),
                    month: date.clone().month() + 1,
                  }),
                }}
              >
                <Button
                  icon='pi pi-list'
                  className='p-button-normal'
                  label={i18n.t('main.acceptedWorkScheduleList')}
                />
              </Link>
            ) : null}
          </div>
        </div>
        <div style={styles.header}>
          <div style={styles.headers}>{i18n.t('main.monthlyShiftsTitle')}</div>
          <Link
            to={Router.get(Router.staffMonthlyAvailableShifts, {
              year: prevMonth.year(),
              month: prevMonth.month() + 1,
            })}
            onClick={isPreviousDisabled ? e => e.preventDefault() : undefined}
          >
            <Button icon='pi pi-chevron-left' disabled={isPreviousDisabled} />
          </Link>
          <div style={styles.title}>
            {moment(currentDate).format('YYYY年M月')}
          </div>
          <Link
            to={Router.get(Router.staffMonthlyAvailableShifts, {
              year: nextMonth.year(),
              month: nextMonth.month() + 1,
            })}
            onClick={isNextDisabled ? e => e.preventDefault() : undefined}
          >
            <Button icon='pi pi-chevron-right' disabled={isNextDisabled} />
          </Link>
          <div style={styles.dates}>
            <ShiftDateSelectors />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            // alignItems: 'center',
            gap: '10px',
            marginBottom: '10px',
          }}
        >
          <MultiSelect
            className='mx-2 dropdown-clinic'
            options={listRegions}
            optionLabel='region'
            value={listRegions.filter(({ id }) => regionIds.includes(id))}
            onChange={e => {
              setRegionIds(e)
            }}
            placeholder={i18n.t('staff.waitingRecruitment.areaSelect')}
            fixedPlaceholder
            filter
          />
          <MultiSelect
            className='mx-2 dropdown-clinic'
            options={uniqueClinicName}
            optionLabel='clinicName'
            value={uniqueClinicName.filter(({ id }) => clinicIds.includes(id))}
            onChange={e => {
              setClinicIds(e)
            }}
            placeholder={i18n.t('staff.waitingRecruitment.clinicSelect')}
            fixedPlaceholder
            filter
          />
          <MultiSelect
            className='mx-2 dropdown-clinic'
            options={departmentNameList}
            optionLabel='departmentName'
            value={departmentNameList.filter(({ name }) =>
              clinicalDepartmentNamesFilter.includes(name)
            )}
            onChange={e => {
              setClinicalDepartmentNames(e)
            }}
            placeholder={i18n.t('staff.waitingRecruitment.departmentSelect')}
            fixedPlaceholder
            filter
          />
        </div>
        {!date.isSameOrBefore(moment().subtract(13, 'months'), 'month') ? (
          <Suspense fallback={<ProgressSpinner />}>
            <AvailableShiftDataTable
              value={value}
              clinicalDepartments={clinicalDepartment}
              holidays={holidays}
            />
          </Suspense>
        ) : null}
      </div>
    </div>
  )
}

export default React.memo(MonthlyAvailableShiftScene)
