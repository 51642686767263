import { DOCTOR_FRAGMENTS } from '@medical/layout/DoctorLayout/DoctorLayoutRequest.graphql'
import gql from 'graphql-tag'

export const DOCTOR_EMAIL_CONFIRMATION = gql`
  mutation DOCTOR_EMAIL_CONFIRMATION(
    $confirmationNumber: String!
    $newPassword: String!
  ) {
    doctorEmailConfirmation(
      confirmationNumber: $confirmationNumber
      newPassword: $newPassword
    ) {
      ...DOCTOR_FRAGMENTS
    }
  }
  ${DOCTOR_FRAGMENTS}
`

export const DOCTOR_CHECK_CONFIRMATION_NUMBER = gql`
  query DOCTOR_CHECK_CONFIRMATION_NUMBER($confirmationNumber: String!) {
    doctorCheckConfirmationNumber(confirmationNumber: $confirmationNumber)
  }
`
