import { AutoComplete } from 'primereact/autocomplete'
import React, { useState } from 'react'

import addSplit from './addSplit'

const TransportRegisterAutoComplete = ({
  setFieldValue,
  placeholder,
  name,
  width,
  maxWidth,
  suggestionName,
  field,
  values,
  searchFields,
  register,
  index,
  disabled,
  currentTime,
  startTimeAddMonth,
  slipt,
  paddingBottom,
}) => {
  const allSuggestions = values[suggestionName]
  const [suggestions, setSuggestions] = useState(values[suggestionName])
  return (
    <AutoComplete
      disabled={disabled}
      value={
        register ? values.splits[index].clinic : values.splits[index].category
      }
      suggestions={suggestions}
      completeMethod={e => {
        const results = allSuggestions.filter(suggestion => {
          if (!searchFields)
            return suggestion[field]
              .toLowerCase()
              .includes(e.query.toLowerCase())

          return !!searchFields.reduce(
            (acc, singleField) =>
              acc +
              suggestion[singleField]
                .toLowerCase()
                .includes(e.query.toLowerCase())
          )
        })
        setSuggestions(results)
      }}
      onChange={e => {
        if (register && startTimeAddMonth < currentTime) {
          addSplit(setFieldValue, values, slipt, index)
        } else {
          setFieldValue(name, e.value)
        }
      }}
      placeholder={placeholder}
      minLength={1}
      inputStyle={{
        width: width || 'auto',
        maxWidth: maxWidth || 'inherit',
        paddingBottom: paddingBottom || '',
      }}
      field={field}
      onUnselect={e => {
        if (!(startTimeAddMonth < currentTime)) {
          setFieldValue(name, e.value)
        }
      }}
      onSelect={e => {
        if (!(startTimeAddMonth < currentTime)) {
          setFieldValue(name, e.value)
        }
      }}
      dropdown
    />
  )
}
export default TransportRegisterAutoComplete
