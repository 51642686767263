/* eslint-disable no-nested-ternary */
import ConfirmationModal from '@medical/components/ConfirmationModal'
import UploadImage from '@medical/components/Forms/UploadImage'
import { DatePicker, Input, InputNumber } from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import { ErrorMessage, Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import React, { useState } from 'react'
import * as Yup from 'yup'

const MovingExpensesOtherDetailScence = ({
  i18n,
  transportationExpenses,
  rejected,
  accepted,
  onSubmit,
  clinic,
  typeSettingData,
}) => {
  const newTypes = typeSettingData.map(item => ({
    value: item.type && item.type,
    description: item.type,
    maxCost: item.maxCost ? item.maxCost : undefined,
  }))
  const getValidation = value => {
    if (value === '駐車代') return 1
    return 0
  }
  const [validationRq, setValidationRq] = useState(
    getValidation(transportationExpenses.type)
  )
  const [confirmationMessage, setConfirmationMessage] = useState()
  const validationSchema = Yup.object().shape({
    type: Yup.string()
      .nullable()
      .required(
        `${i18n.t(
          'staff.movingExpensesOtherDetail.applicationClassification'
        )}${i18n.t('validation.required')}`
      ),
    url: Yup.string()
      .url(i18n.t('validation.url'))
      .when('type', {
        is: text =>
          (text &&
            !['駐車代', '高速道路代', '宿泊代', 'その他'].includes(text)) ||
          text === null,
        then: Yup.string().required(
          `${i18n.t('staff.movingExpensesOtherDetail.routeURL')} ${i18n.t(
            'validation.thisIsARequiredField'
          )}`
        ),
      }),
    clinic: Yup.object()
      .nullable()
      .required(
        `${i18n.t('transportApplication.clinicName')}${i18n.t(
          'validation.required'
        )}`
      ),
    cost: Yup.string()
      .nullable()
      .required(
        `${i18n.t('transportApplication.cost')}${i18n.t('validation.required')}`
      ),
    departurePlace: Yup.string()
      .trim()
      .when('type', {
        is: text =>
          (text && !['駐車代', '宿泊代'].includes(text)) || text === null,
        then: Yup.string().required(
          `${i18n.t('staff.movingExpensesOtherDetail.section')}${i18n.t(
            'validation.required'
          )}`
        ),
      }),
    arrivalPlace: Yup.string()
      .trim()
      .when('type', {
        is: text =>
          (text && !['駐車代', '宿泊代'].includes(text)) || text === null,
        then: Yup.string().required(
          `${i18n.t('staff.movingExpensesOtherDetail.section')}${i18n.t(
            'validation.required'
          )}`
        ),
      }),
    endTime: Yup.string().required(
      `${i18n.t('staff.movingExpensesOtherDetail.date')}${i18n.t(
        'validation.required'
      )}`
    ),
    startTime: Yup.string().required(
      `${i18n.t('staff.movingExpensesOtherDetail.date')}${i18n.t(
        'validation.required'
      )}`
    ),
    comment: Yup.string()
      .nullable()
      .max(500, i18n.t('validation.title.max')),
  })
  const validationSchemaCarRq = Yup.object().shape({
    type: Yup.string()
      .nullable()
      .required(
        `${i18n.t(
          'staff.movingExpensesOtherDetail.applicationClassification'
        )}${i18n.t('validation.required')}`
      ),
    url: Yup.string()
      .url(i18n.t('validation.url'))
      .when('type', {
        is: text =>
          (text &&
            !['駐車代', '高速道路代', '宿泊代', 'その他'].includes(text)) ||
          text === null,
        then: Yup.string().required(
          `${i18n.t('staff.movingExpensesOtherDetail.routeURL')} ${i18n.t(
            'validation.thisIsARequiredField'
          )}`
        ),
      }),
    clinic: Yup.object()
      .nullable()
      .required(
        `${i18n.t('transportApplication.clinicName')}${i18n.t(
          'validation.required'
        )}`
      ),
    endTime: Yup.string().required(
      `${i18n.t('staff.movingExpensesOtherDetail.date')}${i18n.t(
        'validation.required'
      )}`
    ),
    startTime: Yup.string().required(
      `${i18n.t('staff.movingExpensesOtherDetail.date')}${i18n.t(
        'validation.required'
      )}`
    ),
    comment: Yup.string()
      .nullable()
      .max(500, i18n.t('validation.title.max')),
  })

  const validationSchemaFeeRq = Yup.object().shape({
    type: Yup.string()
      .nullable()
      .required(
        `${i18n.t(
          'staff.movingExpensesOtherDetail.applicationClassification'
        )}${i18n.t('validation.required')}`
      ),
    url: Yup.string()
      .url(i18n.t('validation.url'))
      .when('type', {
        is: text =>
          (text &&
            !['駐車代', '高速道路代', '宿泊代', 'その他'].includes(text)) ||
          text === null,
        then: Yup.string().required(
          `${i18n.t('staff.movingExpensesOtherDetail.routeURL')} ${i18n.t(
            'validation.thisIsARequiredField'
          )}`
        ),
      }),
    clinic: Yup.object()
      .nullable()
      .required(
        `${i18n.t('transportApplication.clinicName')}${i18n.t(
          'validation.required'
        )}`
      ),
    cost: Yup.string()
      .nullable()
      .required(
        `${i18n.t('transportApplication.cost')}${i18n.t('validation.required')}`
      )
      .when('checkMaxCost', {
        is: check => check == true,
        then: Yup.string().oneOf([false], '交通費は上限金額を超えています。'),
      }),
    endTime: Yup.string().required(
      `${i18n.t('staff.movingExpensesOtherDetail.date')}${i18n.t(
        'validation.required'
      )}`
    ),
    startTime: Yup.string().required(
      `${i18n.t('staff.movingExpensesOtherDetail.date')}${i18n.t(
        'validation.required'
      )}`
    ),
    comment: Yup.string()
      .nullable()
      .max(500, i18n.t('validation.title.max')),
  })
  return (
    <Formik
      enableReinitialize
      initialValues={{
        confirm: false,
        clinic: transportationExpenses.clinic
          ? transportationExpenses.clinic
          : '',
        functionName: '',
        type: transportationExpenses.type || '',
        url: transportationExpenses.url || '',
        startTime: transportationExpenses.startTime || '',
        endTime: transportationExpenses.endTime || '',
        departurePlace: transportationExpenses.departurePlace || '',
        arrivalPlace: transportationExpenses.arrivalPlace || '',
        cost: transportationExpenses.cost || '',
        doctorId: transportationExpenses.doctor.id || '',
        billImage: transportationExpenses.billImage || null,
        comment: transportationExpenses.comment || undefined,
        file: transportationExpenses.billImage,
        checkMaxCost: false,
      }}
      validationSchema={
        validationRq === 0
          ? validationSchema
          : validationRq === 1
          ? validationSchemaFeeRq
          : validationSchemaCarRq
      }
      onSubmit={onSubmit}
      render={formikProps => {
        const {
          setFieldValue,
          handleChange,
          values,
          validateForm,
          setTouched,
        } = formikProps
        return (
          <div className='container move-expenses-container'>
            <div className='row'>
              <div className='move-expenses-header__title'>
                {i18n.t('staff.movingExpensesOtherDetail.title')}
              </div>
            </div>
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='move-expenses-item__title'>
                {i18n.t('staff.movingExpensesOtherDetail.doctorName')}
              </div>
              <div>
                <div>{`${transportationExpenses.doctor.lastname} ${transportationExpenses.doctor.firstname}`}</div>
              </div>
            </div>
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='move-expenses-item__title'>
                {i18n.t('staff.movingExpensesOtherDetail.clinicName')}
              </div>
              <div className='width-67'>
                <Dropdown
                  {...formikProps}
                  value={values.clinic}
                  name='clinic'
                  placeholder={
                    values.clinic.name ? values.clinic.name : 'クリニックを選択'
                  }
                  optionLabel='name'
                  valueLabel='id'
                  options={clinic || []}
                  disabled
                />
                <ErrorMessage
                  name='clinic'
                  component='div'
                  className='signup-alert'
                />
              </div>
            </div>
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='move-expenses-item__title'>
                {i18n.t(
                  'staff.movingExpensesOtherDetail.applicationClassification'
                )}
              </div>
              <div className='width-67'>
                <Dropdown
                  {...formikProps}
                  value={newTypes.find(item => item.value === values.type)}
                  name='type'
                  placeholder='申請区分を選択'
                  optionLabel='description'
                  valueLabel='value'
                  options={newTypes}
                  onChange={e => {
                    setValidationRq(getValidation(e.value.value))
                    setFieldValue(`type`, e.value.value)
                  }}
                />
                <ErrorMessage
                  name='type'
                  component='div'
                  className='color-red'
                />
              </div>
            </div>
            <div className='row mt-15 move-inline move-expenses-item-container '>
              <div className='move-expenses-item__title'>
                {i18n.t('staff.movingExpensesOtherDetail.routeURL')}
              </div>
              <div className='width-67 color-url'>
                <Input
                  {...formikProps}
                  value={values.type === '駐車代' ? '' : values.url}
                  size='large'
                  name='url'
                  disabled={!!(values.type === '駐車代')}
                  onChange={handleChange}
                  placeholder='https://www.navitime.co.jp/'
                />
                <ErrorMessage
                  name='url'
                  component='div'
                  className='color-red'
                />
                {values.url ? (
                  <span>
                    <a href={`${values.url}`} target='_blank' rel='noreferrer'>
                      URLリンクを開く
                    </a>
                  </span>
                ) : (
                  undefined
                )}
              </div>
            </div>
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='move-expenses-item__title'>
                {i18n.t('staff.movingExpensesOtherDetail.date')}
              </div>
              <div className='width-67'>
                <DatePicker
                  placeholder={i18n.t('staff.movingExpensesOtherDetail.date')}
                  format='YYYY年MM月DD日'
                  locale={locale}
                  size='large'
                  style={{ width: '100%' }}
                  allowClear={false}
                  value={
                    values.startTime
                      ? moment(new Date(values.startTime), 'YYYY年MM月DD日')
                      : null
                  }
                  onChange={value => {
                    setFieldValue(
                      'startTime',
                      moment
                        .utc(value)
                        .startOf('day')
                        .toISOString()
                    )
                    setFieldValue(
                      'endTime',
                      moment
                        .utc(value)
                        .set('hour', 14)
                        .set('minute', 59)
                        .set('second', 59)
                        .toISOString()
                    )
                  }}
                />
                <ErrorMessage
                  name={'endTime' && 'startTime'}
                  component='div'
                  className='color-red'
                />
              </div>
            </div>
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='move-expenses-item__title'>
                {i18n.t('staff.movingExpensesOtherDetail.section')}
              </div>
              <div className='width-67'>
                <Input.Group compact>
                  <Input
                    style={{ width: '45%', textAlign: 'center' }}
                    placeholder='東京駅'
                    size='large'
                    name='departurePlace'
                    value={
                      ['駐車代', '宿泊代'].includes(values.type)
                        ? ''
                        : values.departurePlace
                    }
                    onChange={handleChange}
                    disabled={!!['駐車代', '宿泊代'].includes(values.type)}
                  />
                  <Input
                    className='site-input-split'
                    size='large'
                    style={{
                      textAlign: 'center',
                      width: '10%',
                      borderLeft: 0,
                      borderRight: 0,
                      pointerEvents: 'none',
                    }}
                    placeholder='~'
                    disabled
                  />
                  <Input
                    className='site-input-right'
                    size='large'
                    style={{
                      width: '45%',
                      textAlign: 'center',
                    }}
                    placeholder='葛西駅'
                    name='arrivalPlace'
                    value={
                      ['駐車代', '宿泊代'].includes(values.type)
                        ? ''
                        : values.arrivalPlace
                    }
                    onChange={handleChange}
                    disabled={!!['駐車代', '宿泊代'].includes(values.type)}
                  />
                </Input.Group>

                <ErrorMessage
                  name={'departurePlace' && 'arrivalPlace'}
                  component='div'
                  className='color-red'
                />
              </div>
            </div>
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='move-expenses-item__title'>
                {i18n.t('staff.movingExpensesOtherDetail.finance')}
              </div>
              <div className='width-67'>
                <div className='move-expenses-item__input'>
                  <InputNumber
                    placeholder='￥ 0'
                    formatter={value =>
                      `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    type='tel'
                    allowClear
                    size='large'
                    min={0}
                    name='cost'
                    style={{ width: '100%' }}
                    value={values.cost}
                    onChange={e => {
                      const maxCost = typeSettingData.find(
                        item => item.type === values.type
                      )
                        ? typeSettingData.find(
                            item => item.type === values.type
                          ).maxCost
                        : 0
                      setFieldValue('cost', e)
                      if (e > maxCost) {
                        setFieldValue('checkMaxCost', true)
                      } else {
                        setFieldValue('checkMaxCost', false)
                      }
                    }}
                    step={5}
                  />
                </div>
                <ErrorMessage
                  name='cost'
                  component='div'
                  className='color-red'
                />
              </div>
            </div>
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='move-expenses-item__title'>
                {i18n.t('staff.movingExpensesOtherDetail.receipt')}
              </div>
              <div>
                <div className='move-expenses-item__input'>
                  <UploadImage
                    {...formikProps}
                    imgKey={transportationExpenses.billImage}
                  />
                </div>
              </div>
            </div>
            <div className='row mt-15 move-inline move-expenses-item-container'>
              <div className='move-expenses-item__title'>
                {i18n.t('staff.movingExpensesOtherDetail.comment')}
              </div>
              <div className='width-67'>
                <Input.TextArea
                  placeholder={i18n.t(
                    'staff.movingExpensesOtherDetail.comment'
                  )}
                  size='large'
                  name='comment'
                  value={values.comment}
                  autoSize={{
                    minRows: 2,
                    maxRows: 3,
                  }}
                  onChange={handleChange}
                />
                <ErrorMessage
                  name='comment'
                  component='div'
                  className='color-red'
                />
              </div>
            </div>

            <div className='row move-center mt-30'>
              <div className='button-container'>
                <Button
                  label={i18n.t('staff.movingExpensesOtherDetail.btnReject')}
                  className='btn outline-red'
                  style={{ marginRight: '10px' }}
                  onClick={() => {
                    validateForm().then(response => {
                      if (!Object.keys(response).length) {
                        setFieldValue('confirm', true)
                        setFieldValue('functionName', rejected)
                      } else {
                        setTouched(response)
                      }
                    })
                    setConfirmationMessage(
                      i18n.t('staff.movingExpensesOtherDetail.messageReject')
                    )
                  }}
                />
                <Button
                  label={i18n.t('staff.movingExpensesOtherDetail.btnApprove')}
                  className='reflect-button btn'
                  onClick={() => {
                    validateForm().then(response => {
                      if (!Object.keys(response).length) {
                        setFieldValue('confirm', true)
                        setFieldValue('functionName', accepted)
                      } else {
                        setTouched(response)
                      }
                    })
                    setConfirmationMessage(
                      i18n.t('staff.movingExpensesOtherDetail.messageAccepct')
                    )
                  }}
                />
              </div>
            </div>
            <ConfirmationModal
              {...formikProps}
              confirmationMessage={confirmationMessage}
            />
          </div>
        )
      }}
    />
  )
}

export default MovingExpensesOtherDetailScence
