import { ErrorMessage } from 'formik'
import { Dropdown } from 'primereact/dropdown'
import { pathOr } from 'ramda'
import React from 'react'

const CustomDropdown = ({
  placeholder,
  style,
  values,
  errors,
  touched,
  name,
  options = [],
  setFieldValue,
  optionLabel = 'description',
  valueLabel = 'value',
  disabled,
  classDisabled,
  type,
  parentName = '',
  childName = '',
  index,
  staffComment,
  recruitmentCategory,
  recruitmentStatus,
  changed,
}) => {
  const isTouched = pathOr(false, [name], touched)
  const error = pathOr('', [name], errors)
  return (
    <div>
      <Dropdown
        style={style}
        placeholder={placeholder}
        name={name}
        value={options.find(option => {
          let value = type ? values[name][valueLabel] : values[name]
          if (parentName && childName) {
            value = values[parentName][index][childName]
          } else if (staffComment) {
            value = values.splitComments[index].type
          } else if (recruitmentCategory) {
            value = values.splitRecruitmentCategories[index].category
          } else if (recruitmentStatus) {
            value = values.splitRecruitmentCategories[index].status
          }
          return option[valueLabel] === value
        })}
        options={options}
        optionLabel={optionLabel}
        onChange={e => {
          const value = type ? e.value : e.value[valueLabel]
          setFieldValue(name, value)
        }}
        disabled={disabled}
        className={classDisabled && disabled ? 'is-disabled' : ''}
      />
      {recruitmentCategory && (
        <ErrorMessage
          name={`splitRecruitmentCategories[${index}].category`}
          component='div'
          className='alert'
        />
      )}
      {recruitmentStatus && (
        <ErrorMessage
          name={`splitRecruitmentCategories[${index}].status`}
          component='div'
          className='alert'
        />
      )}
      {!recruitmentCategory && !recruitmentStatus && (
        <div className='alert'>{isTouched && error}</div>
      )}
    </div>
  )
}

export default CustomDropdown
