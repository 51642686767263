import { DOCUMENT_FRAGMENT } from '@medical/pages/staff/Documents/DocumentsList/DocumentsList.graphql'
import gql from 'graphql-tag'

export const UPDATE_DOCUMENT = gql`
  mutation UPDATE_DOCUMENT($documentId: ID!, $title: String, $content: String) {
    staffUpdateDocument(
      documentId: $documentId
      title: $title
      content: $content
    ) {
      ...DOCUMENT_FRAGMENT
    }
  }
  ${DOCUMENT_FRAGMENT}
`

export const SINGLE_DOCUMENT = gql`
  query SINGLE_DOCUMENT($documentId: ID) {
    document(where: { id: $documentId }) {
      ...DOCUMENT_FRAGMENT
    }
  }
  ${DOCUMENT_FRAGMENT}
`
