import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import CustomDropdown from '@medical/components/DropdownForSignup'
import TextInputComment from '@medical/components/TextInputForCommentPage'
import { ErrorMessage, FieldArray } from 'formik'
import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'

import { GET_ENUM_COMMENT } from '../DetailWorkSchedule/WorkScheduleComment.graphql'

const ShiftFormStaffComment = ({ i18n, formikProps, isShowAdd }) => {
  const { setFieldValue, handleChange, values, errors, touched } = formikProps
  const [commentOption, setCommentOption] = useState([])
  const { loading, error, data } = useQuery(GET_ENUM_COMMENT, {
    variables: {
      name: 'commentTypes',
    },
  })
  useEffect(() => {
    if (data && data.enumValues) {
      setCommentOption(data.enumValues)
    }
  }, [data])

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  return (
    <div className={`${!isShowAdd ? 'staffpage-comment-container' : ''}`}>
      {!isShowAdd && (
        <div className='staffpage-title'>
          {i18n.t('main.staffComment.titleStaffComment')}
        </div>
      )}

      <FieldArray
        name='splitComments'
        render={({ remove }) => (
          <div className='staff-comment-container'>
            {values.splitComments.map((comment, index) => (
              <div className='input-comment-container' key={index}>
                {/* <Dropdown /> */}
                <div className='move-inline' style={{ alignItems: 'start' }}>
                  <CustomDropdown
                    {...formikProps}
                    name={`splitComments[${index}].type`}
                    options={commentOption}
                    placeholder={i18n.t('main.staffComment.selectType')}
                    staffComment
                    index={index}
                  />
                  <TextInputComment
                    {...formikProps}
                    name={`splitComments[${index}].text`}
                    onChange={handleChange}
                    placeholder={i18n.t('main.staffComment.inputText')}
                    inputWidth='100%'
                    staffComment
                    index={index}
                  />
                  <Button
                    style={{ alignItems: 'start', margin: '0px auto' }}
                    icon='pi pi-trash'
                    onClick={() => {
                      if (values.splitComments.length === 1) {
                        setFieldValue(`splitComments[${index}].type`, '')
                        setFieldValue(`splitComments[${index}].text`, '')
                      } else {
                        remove(index)
                      }
                    }}
                  />
                </div>
                <div className='alert'>
                  {touched?.['splitComments']?.[index] &&
                    errors?.['splitComments']?.[index]?.type}
                </div>
              </div>
            ))}
            <Button
              icon='pi pi-plus'
              label={i18n.t('main.add')}
              onClick={() => {
                setFieldValue('splitComments', [
                  ...values.splitComments,
                  {
                    type: '',
                    text: '',
                  },
                ])
              }}
            />
          </div>
        )}
      />
    </div>
  )
}
export default ShiftFormStaffComment
