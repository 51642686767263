import gql from 'graphql-tag'

export const STAFF_CREATE_INCENTIVE = gql`
  mutation STAFF_CREATE_INCENTIVE(
    $title: String!
    $type: IncentiveType!
    $applyWith: IncentiveApplyType!
    $workPatternApply: [String!]
    $listDoctorId: [ID!]
    $startTime: DateTime!
    $endTime: DateTime!
    $amount: Int!
  ) {
    staffCreateIncentive(
      title: $title
      type: $type
      applyWith: $applyWith
      workPatternApply: $workPatternApply
      listDoctorId: $listDoctorId
      startTime: $startTime
      endTime: $endTime
      amount: $amount
    )
  }
`
export const STAFF_UPDATE_INCENTIVE = gql`
  mutation STAFF_UPDATE_INCENTIVE(
    $incentiveId: ID!
    $title: String
    $applyWith: IncentiveApplyType!
    $workPatternApply: [String!]
    $listDoctorId: [ID!]
    $startTime: DateTime
    $endTime: DateTime
    $amount: Int
  ) {
    staffUpdateIncentive(
      incentiveId: $incentiveId
      title: $title
      applyWith: $applyWith
      workPatternApply: $workPatternApply
      listDoctorId: $listDoctorId
      startTime: $startTime
      endTime: $endTime
      amount: $amount
    )
  }
`

export const STAFF_DELETE_INCENTIVE = gql`
  mutation STAFF_DELETE_INCENTIVE($incentiveId: ID!) {
    staffDeleteIncentive(incentiveId: $incentiveId)
  }
`
export const GET_INCENTIVE_BY_ID = gql`
  query Incentive($where: IncentiveWhereUniqueInput!) {
    incentive(where: $where) {
      id
      createdAt
      updatedAt
      deletedAt
      type
      title
      amount
      startTime
      endTime
      applyWith
      startTime
      endTime
      amount
      workPatternApply
    }
  }
`
export const CREATE_ACTIVITY = gql`
  mutation CREATE_ACTIVITY_LOG($activity: ActivityLogTypes!, $doctorId: ID) {
    createActivityLogHistory(activity: $activity, doctorId: $doctorId)
  }
`
