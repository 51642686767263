import React, { useState } from 'react'
import '../AvailableShiftStyle.css'
import 'fork/react-date-range/dist/styles.css'
import 'fork/react-date-range/dist/theme/default.css'
import { Calendar } from 'fork/react-date-range'
import moment from 'moment'
import * as locales from 'fork/react-date-range/dist/locale'
import { format } from 'date-fns'
import { isHolidays, thisRangeHolidays } from '@medical/libs'
import { pathOr } from 'ramda'
var _eachDayOfInterval = _interopRequireDefault(
  require('date-fns/eachDayOfInterval')
)

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj }
}

function addMonths(numOfMonths, date = new Date()) {
  date.setMonth(date.getMonth() + numOfMonths)

  return date
}

const MultipleDatePicker = ({
  field,
  setFieldValue,
  minDate,
  maxDate,
  months,
  ranges,
  handleChangeRange,
  locale,
  weeks = [],
  handleChecked = () => {},
  holidaysData,
}) => {
  const [count, setCount] = useState(Object.keys(ranges).length)
  const holidays = thisRangeHolidays({
    startDate: minDate,
    endDate: maxDate,
    holidaysData: pathOr([], ['items'], holidaysData),
  })
  const customDayContent = day => {
    const isSelectedDateHoliday = isHolidays({ holidays, date: new Date(day) })
    return (
      <span className={isSelectedDateHoliday ? 'custom-date' : ''}>
        {format(day, 'd')}
      </span>
    )
  }

  // if (holidayLoading) return <ProgressSpinner />
  // if (holidaysError) return <ErrorComponent error={holidaysError} />

  function getMonthDateRange(year, month) {
    year += 1900
    let startDate = moment([year, month])
    let endDate = moment(startDate).endOf('month')
    return { start: startDate.toDate(), end: endDate.toDate() }
  }
  const handleChangeDays = item => {
    let data = ranges,
      dayChecked = `${item.getDay()}_${item.getMonth()}_${item.getYear()}`,
      today = new Date(),
      dateOfMonth = getMonthDateRange(item.getYear(), item.getMonth()),
      endOfMonth = dateOfMonth.end,
      endOfCalendar = addMonths(12),
      start =
        endOfMonth.getFullYear() > today.getFullYear()
          ? dateOfMonth.start
          : endOfMonth.getMonth() > today.getMonth()
          ? dateOfMonth.start
          : today.getDate() >= dateOfMonth.start.getDate()
          ? today
          : dateOfMonth.start,
      end =
        endOfMonth.getFullYear() === endOfCalendar.getFullYear() &&
        endOfMonth.getMonth() === endOfCalendar.getMonth()
          ? endOfCalendar
          : endOfMonth,
      daysOfMonth = (0, _eachDayOfInterval.default)({
        start: start,
        end: end,
      })

    let day = item.getDay()
    let daysChecked = daysOfMonth.filter(d => d.getDay() === day)
    let countItem = 0,
      isCheck = false

    daysChecked.forEach(d => {
      for (let key of Object.keys(data)) {
        if (
          moment(d).format('YYYY-MM-DD') ===
          moment(data[key].startDate).format('YYYY-MM-DD')
        ) {
          countItem++
          break
        }
      }
    })

    for (let key of Object.keys(data)) {
      if (
        moment(item).format('YYYY-MM-DD') ===
        moment(data[key].startDate).format('YYYY-MM-DD')
      ) {
        isCheck = true
      }
    }
    if (isCheck === false && countItem + 1 === daysChecked.length) {
      handleChecked([...weeks, dayChecked])
      setFieldValue('weeks', [...weeks, dayChecked])
    }

    for (let key of Object.keys(data)) {
      if (
        moment(item).format('YYYY-MM-DD') ===
        moment(data[key].startDate).format('YYYY-MM-DD')
      ) {
        if (countItem === daysChecked.length)
          handleChecked(weeks.filter(w => w !== dayChecked))

        delete data[key]
        handleChangeRange({
          ...data,
        })
        setFieldValue(field, { ...data })
        setFieldValue(
          'weeks',
          weeks.filter(w => w !== dayChecked)
        )
        return data
      }
    }

    const newValue = {
      [count]: {
        startDate: item,
        endDate: item,
        key: `${count}`,
        color: '#f03a6c',
      },
    }
    setCount(count + 1)
    handleChangeRange({
      ...newValue,
      ...data,
    })
    setFieldValue(field, { ...newValue, ...data })
  }

  const handleChangeWeeks = items => {
    let data = ranges
    let newCount = count
    let newValue = {}
    if (!items?.length) return
    let dayChecked = `${items[0].getDay()}_${items[0].getMonth()}_${items[0].getYear()}`
    if (Object.keys(data).length === 0) {
      for (let i = 0; i < items.length; i++) {
        newValue = {
          ...newValue,
          [newCount]: {
            startDate: items[i],
            endDate: items[i],
            key: `${newCount}`,
            color: '#f03a6c',
          },
        }
        newCount++
      }
    } else {
      let countItem = 0
      const keys = []
      for (let i = 0; i < items.length; i++) {
        for (let key of Object.keys(data)) {
          if (
            moment(items[i]).format('YYYY-MM-DD') ===
            moment(data[key].startDate).format('YYYY-MM-DD')
          ) {
            countItem++
            keys.push(key)
            break
          }
        }
      }
      if (countItem === Object.keys(items).length) {
        for (let key = 0; key < keys.length; key++) {
          delete data[keys[key]]
        }
        handleChangeRange({
          ...data,
        })
        setFieldValue(field, { ...data })
        handleChecked(weeks.filter(w => w !== dayChecked))
        setFieldValue(
          'weeks',
          weeks.filter(w => w !== dayChecked)
        )
        return data
      } else {
        const dataFormat = []
        for (let key of Object.keys(data)) {
          dataFormat.push(moment(data[key].startDate).format('YYYY-MM-DD'))
        }
        const itemOthers = items.filter(
          i => !dataFormat.includes(moment(i).format('YYYY-MM-DD'))
        )
        for (let i = 0; i < itemOthers.length; i++) {
          newValue = {
            ...newValue,
            [newCount]: {
              startDate: itemOthers[i],
              endDate: itemOthers[i],
              key: `${newCount}`,
              color: '#f03a6c',
            },
          }
          newCount++
        }
      }
    }

    const currentChange = {
      ...newValue,
      ...data,
    }
    handleChecked([...weeks, dayChecked])
    setFieldValue('weeks', [...weeks, dayChecked])
    setCount(newCount)
    handleChangeRange(currentChange)
    setFieldValue(field, { ...newValue, ...data })
  }

  return (
    <Calendar
      color='#fff'
      locale={locales[locale]}
      showDateDisplay={false}
      ranges={Object.values(ranges).reverse()}
      displayMode='dateRange'
      showPreview={false}
      dayContentRenderer={customDayContent}
      onChange={handleChangeDays}
      direction='horizontal'
      months={months}
      minDate={minDate}
      maxDate={maxDate}
      dragSelectionEnabled={false}
      monthDisplayFormat='YoMMM'
      weeks={weeks}
      onChecked={handleChangeWeeks}
    />
  )
}

export default MultipleDatePicker
