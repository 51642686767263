import gql from 'graphql-tag'
import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'

export const WORK_OUTSIDE_CONNECTION = gql`
  query WorkOutsidesConnection(
    $where: WorkOutsideWhereInput
    $first: Int
    $skip: Int
    $orderBy: WorkOutsideOrderByInput
  ) {
    workOutsidesConnection(
      where: $where
      first: $first
      skip: $skip
      orderBy: $orderBy
    ) {
      edges {
        node {
          id
          createdAt
          updatedAt
          deletedAt
          startTime
          endTime
          clinicalDepartment {
            id
            name
            extraName
            clinic {
              id
              name
              nameKana
            }
          }
          createdBy {
            id
            lastname
            firstname
          }
          isStartTimeBreakTime
          splitDateTime3
          splitDateTime2
          splitDateTime1
          isSplitDateTime1BreakTime
          isSplitDateTime3BreakTime
          isSplitDateTime2BreakTime
          address
          workContent
          status
        }
      }
      aggregate(where: $where) {
        count
      }
    }
    ...DEPARTMENTS_FRAGMENT
  }
  ${DEPARTMENTS_FRAGMENT}
`

export const QUERY_WORK_OUTSIDE = gql`
  query WorkOutside($where: WorkOutsideWhereUniqueInput!) {
    workOutside(where: $where) {
      id
      createdAt
      updatedAt
      deletedAt
      startTime
      endTime
      clinicalDepartment {
        id
        name
        extraName
        clinic {
          id
          name
          nameKana
        }
      }
      createdBy {
        id
        lastname
        firstname
      }
      splitDateTime1
      splitDateTime2
      splitDateTime3
      isStartTimeBreakTime
      isSplitDateTime1BreakTime
      isSplitDateTime2BreakTime
      isSplitDateTime3BreakTime
      hourlyWage
      splitHourlyWage1
      splitHourlyWage2
      splitHourlyWage3
      address
      workContent
      status
    }
    ...DEPARTMENTS_FRAGMENT
  }
  ${DEPARTMENTS_FRAGMENT}
`

export const ACCEPTED_OR_REJECTED = gql`
  mutation ACCEPTED_OR_REJECT_WORK_OUTSIDE(
    $workOutsideId: ID!
    $startTime: DateTime!
    $endTime: DateTime!
    $hourlyWage: Int!
    $splitDateTime1: DateTime
    $splitDateTime2: DateTime
    $splitDateTime3: DateTime
    $splitHourlyWage1: Int
    $splitHourlyWage2: Int
    $splitHourlyWage3: Int
    $isStartTimeBreakTime: Boolean
    $isSplitDateTime1BreakTime: Boolean
    $isSplitDateTime2BreakTime: Boolean
    $isSplitDateTime3BreakTime: Boolean
    $action: WorkOutsideStatus!
  ) {
    staffAcceptOrRejectWorkOutside(
      workOutsideId: $workOutsideId
      startTime: $startTime
      endTime: $endTime
      hourlyWage: $hourlyWage
      splitDateTime1: $splitDateTime1
      splitDateTime2: $splitDateTime2
      splitDateTime3: $splitDateTime3
      splitHourlyWage1: $splitHourlyWage1
      splitHourlyWage2: $splitHourlyWage2
      splitHourlyWage3: $splitHourlyWage3
      isStartTimeBreakTime: $isStartTimeBreakTime
      isSplitDateTime1BreakTime: $isSplitDateTime1BreakTime
      isSplitDateTime2BreakTime: $isSplitDateTime2BreakTime
      isSplitDateTime3BreakTime: $isSplitDateTime3BreakTime
      action: $action
    )
  }
`
