import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, NotFound, ProgressSpinner } from '@medical/components'
import EmailConfirmationForm from '@medical/components/Forms/EmailConfirmationForm/EmailConfirmationForm'
import Auth from '@medical/middleware/auth'
import { useCustom } from '@medical/provider/context'
import HomeRedirect from '@medical/routes/HomeRedirect'
import Router from '@medical/routes/router'
import queryString from 'query-string'
import React from 'react'

import {
  DOCTOR_CHECK_CONFIRMATION_NUMBER,
  DOCTOR_EMAIL_CONFIRMATION,
} from './DoctorEmailConfirmation.graphql'

const DoctorEmailConfirmation = ({ history, location: { search } }) => {
  const { confirmationNumber } = queryString.parse(search)
  const [{ i18n, popup }] = useCustom()
  const [emailConfirm] = useMutation(DOCTOR_EMAIL_CONFIRMATION)
  const { loading, error, data } = useQuery(DOCTOR_CHECK_CONFIRMATION_NUMBER, {
    variables: {
      confirmationNumber,
    },
  })

  if (Auth.getToken()) {
    return <HomeRedirect />
  }
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  if (!data.doctorCheckConfirmationNumber) return <NotFound />

  const emailConfirmSubmit = async (
    { newPassword },
    { setSubmitting, resetForm }
  ) => {
    try {
      const {
        data: {
          doctorEmailConfirmation: { email },
        },
      } = await emailConfirm({
        variables: {
          confirmationNumber,
          newPassword,
        },
      })
      setSubmitting(false)
      resetForm()
      popup.success(i18n.t('main.changePassword.submissionSuccess'))
      history.push({
        pathname: Router.doctorLogin,
        state: {
          email,
        },
      })
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }

  return <EmailConfirmationForm onSubmit={emailConfirmSubmit} hasCheckbox />
}

export default DoctorEmailConfirmation
