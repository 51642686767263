import { INITIAL_DEPLOYMENT_DATE } from '@medical/constant'
import moment from 'moment'

const dateValidation = ({ year, month, day = 1 }, isAfter) => {
  const tmp = `${year} ${month} ${day}`
  const momentDate = moment(tmp, 'YYYY MM DD')
  if (isAfter) {
    return (
      !moment(tmp, 'YYYY MM').isValid() ||
      !moment(year, 'YYYY').isValid() ||
      !moment(month, 'MM').isValid() ||
      momentDate.isBefore(moment(INITIAL_DEPLOYMENT_DATE)) ||
      momentDate.isAfter(moment(), 'year') ||
      (momentDate.isAfter(moment(), 'month') &&
        momentDate.isSameOrAfter(moment(), 'year'))
    )
  }
  return (
    !moment(tmp, 'YYYY MM').isValid() ||
    !moment(year, 'YYYY').isValid() ||
    !moment(month, 'MM').isValid() ||
    momentDate.isBefore(moment().subtract(1, 'years'), 'year') ||
    momentDate.isBefore(moment(INITIAL_DEPLOYMENT_DATE)) ||
    momentDate.isAfter(moment().add(6, 'quarters')) ||
    (momentDate.isBefore(moment().subtract(6, 'months'), 'month') &&
      momentDate.isSameOrBefore(moment(), 'year'))
  )
}

export default dateValidation
