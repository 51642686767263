import gql from 'graphql-tag'

export const DOCTORS_MONTHLY_TRANSPORTATION_EXPENSES = gql`
  query Doctors(
    $where: DoctorWhereInput
    $date: DateTime!
    $doctorMonthlyTransportationExpensesDate2: DateTime!
    $transportationExpensesWhere2: TransportationExpenseWhereInput
    $first: Int
    $skip: Int
  ) {
    doctorsConnection(where: $where, first: $first, skip: $skip) {
      aggregate {
        count
      }
      edges {
        node {
          id
          email
          fullName
          transportationExpenses(where: $transportationExpensesWhere2) {
            id
            status
          }
          doctorMonthlyWorkSchedules(date: $date) {
            id
            startTime
            deletedAt
            notApplyCostTransportation
          }
          doctorMonthlyTransportationExpenses(
            date: $doctorMonthlyTransportationExpensesDate2
          ) {
            id
            startTime
            endTime
            deletedAt
            category
            type
            blockedDays
          }
        }
      }
    }
  }
`
