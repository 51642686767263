import gql from 'graphql-tag'

export const UPDATE_CLINIC_DAILY_WORK_TIME = gql`
  mutation UPDATE_CLINIC_OPEN_TIME(
    $clinicId: ID!
    $startTimeMonday: DateTime!
    $endTimeMonday: DateTime!
    $isAvailableOnMonday: Boolean!
    $startTimeTuesday: DateTime!
    $endTimeTuesday: DateTime!
    $isAvailableOnTuesday: Boolean!
    $startTimeWednesday: DateTime!
    $endTimeWednesday: DateTime!
    $isAvailableOnWednesday: Boolean!
    $startTimeThursday: DateTime!
    $endTimeThursday: DateTime!
    $isAvailableOnThursday: Boolean!
    $startTimeFriday: DateTime!
    $endTimeFriday: DateTime!
    $isAvailableOnFriday: Boolean!
    $startTimeSaturday: DateTime!
    $endTimeSaturday: DateTime!
    $isAvailableOnSaturday: Boolean!
    $startTimeSunday: DateTime!
    $endTimeSunday: DateTime!
    $isAvailableOnSunday: Boolean!
    $startTimeHoliday: DateTime!
    $endTimeHoliday: DateTime!
    $isAvailableOnHoliday: Boolean!
    $noteOpenTime: String
  ) {
    staffUpdateClinicDailyWorkTime(
      clinicId: $clinicId
      startTimeMonday: $startTimeMonday
      endTimeMonday: $endTimeMonday
      isAvailableOnMonday: $isAvailableOnMonday
      startTimeTuesday: $startTimeTuesday
      endTimeTuesday: $endTimeTuesday
      isAvailableOnTuesday: $isAvailableOnTuesday
      startTimeWednesday: $startTimeWednesday
      endTimeWednesday: $endTimeWednesday
      isAvailableOnWednesday: $isAvailableOnWednesday
      startTimeThursday: $startTimeThursday
      endTimeThursday: $endTimeThursday
      isAvailableOnThursday: $isAvailableOnThursday
      startTimeFriday: $startTimeFriday
      endTimeFriday: $endTimeFriday
      isAvailableOnFriday: $isAvailableOnFriday
      startTimeSaturday: $startTimeSaturday
      endTimeSaturday: $endTimeSaturday
      isAvailableOnSaturday: $isAvailableOnSaturday
      startTimeSunday: $startTimeSunday
      endTimeSunday: $endTimeSunday
      isAvailableOnSunday: $isAvailableOnSunday
      startTimeHoliday: $startTimeHoliday
      endTimeHoliday: $endTimeHoliday
      isAvailableOnHoliday: $isAvailableOnHoliday
      noteOpenTime: $noteOpenTime
    ) {
      id
    }
  }
`
export const UPDATE_CLINIC_DEPARTMENT_DAILY_WORK_TIME = gql`
  mutation StaffUpdateClinicDailyWorkTime(
    $clinicalDepartments: [ClinicalDepartmentUpdateInput!]!
  ) {
    staffUpdateClinicDailyWorkTime(clinicalDepartments: $clinicalDepartments)
  }
`
