import gql from 'graphql-tag'

export const GET_LIST_MOVING_EXPENSES_REGISTRATION = gql`
  query Query($where: TransportationExpenseWhereInput) {
    transportationExpenses(where: $where) {
      id
      startTime
      endTime
      url
      type
      departurePlace
      arrivalPlace
      cost
      status
      createdAt
      returnPath
      clinic {
        id
        name
      }
      doctor {
        id
        lastname
        firstname
      }
    }
  }
`
export const GET_CLINIC = gql`
  query WorkSchedules {
    clinics {
      id
      name
      nameKana
      isPublished
    }
  }
`
export const UPDATE_MOVING_EXPENSES_REGISTRATION_QL = gql`
  mutation staffUpdateTransportationExpense(
    $transportationId: ID!
    $doctorId: ID!
    $clinicId: ID
    $data: TransportationExpenseUpdateWithoutDoctorDataInput!
  ) {
    staffUpdateTransportationExpense(
      transportationId: $transportationId
      doctorId: $doctorId
      clinicId: $clinicId
      data: $data
    ) {
      id
      createdAt
      startTime
      endTime
      url
      type
      departurePlace
      arrivalPlace
      cost
      status
      returnPath
    }
  }
`

export const GET_LIST_MOVING_EXPENSES_REGISTRATION_GQL = gql`
  query Query(
    $skip: Int
    $first: Int
    $orderBy: TransportationExpenseOrderByInput
    $where: TransportationExpenseWhereInput
  ) {
    transportationExpensesConnection(
      skip: $skip
      first: $first
      orderBy: $orderBy
      where: $where
    ) {
      aggregate(where: $where) {
        count
      }
      edges {
        node {
          id
          startTime
          endTime
          url
          type
          departurePlace
          arrivalPlace
          cost
          status
          createdAt
          returnPath
          isHistory
          dataDivision
          clinic {
            id
            name
          }
          doctor {
            id
            lastname
            firstname
          }
        }
      }
    }
  }
`
export const STAFF_CREATE_TRANSPORTATION = gql`
  mutation staffCreateTransportationWithStaffSite(
    $doctorId: ID!
    $clinicId: ID!
    $data: TransportationExpenseCreateWithoutDoctorInput!
  ) {
    staffCreateTransportationWithStaffSite(
      doctorId: $doctorId
      clinicId: $clinicId
      data: $data
    ) {
      id
      startTime
      url
      departurePlace
      arrivalPlace
      cost
      returnPath
    }
  }
`
