import gql from 'graphql-tag'

export const UPDATE_CLINIC_BASE_MESSAGE = gql`
  mutation UPDATE_CLINIC_BASE_MESSAGE(
    $clinicId: String!
    $messageIntroduce: String!
  ) {
    staffUpdateMessageIntroduceClinic(
      clinicId: $clinicId
      messageIntroduce: $messageIntroduce
    )
  }
`

export const NO_DOCTOR_WORKED_IN_CLINIC = gql`
  query NO_DOCTOR_WORKED_IN_CLINIC(
    $clinicId: ID!
    $doctorId: ID!
  ) {
    countDoctorWorkedInClinic(
      clinicId: $clinicId,
      doctorId: $doctorId
    )
  }
`
