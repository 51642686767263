import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { useCustom } from '@medical/provider/context'
import React from 'react'

import {
  DOCTOR_QUERY,
  STAFF_PROCESS_DOCTOR_IDENTITY_REQUEST,
  STAFF_DELETE_DOCTOR_IDENTITY,
  DELETE_FILE,
} from './DetailDoctorIdentity.graphql'
import { CREATE_ACTIVITY } from '../MyNumberManage/MyNumberManage.graphql'
import {
  STAFF_PROCESS_DOCTOR_IDENTITY,
  STAFF_DELETE_DOCTOR_IDENTITY_PERMISSION,
} from '@medical/constant/permissions'
import { checkStaffPermission } from '@medical/libs'
import DetailDoctorIdentityScene from './DetailDoctorIdentityScene'

const DoctorCreateIdentity = ({
  closeModal,
  match: {
    params: { doctorId },
  },
}) => {
  const [staffProcess] = useMutation(STAFF_PROCESS_DOCTOR_IDENTITY_REQUEST)
  const [staffDeleteMyNumber] = useMutation(STAFF_DELETE_DOCTOR_IDENTITY)
  const [staffDeleteMyNumberImage] = useMutation(DELETE_FILE)
  const [staffCreateActivity] = useMutation(CREATE_ACTIVITY)
  const [{ i18n, popup }] = useCustom()
  const isProcessPermitted = checkStaffPermission({
    functionName: STAFF_PROCESS_DOCTOR_IDENTITY,
  })

  const isDeletePermitted = checkStaffPermission({
    functionName: STAFF_DELETE_DOCTOR_IDENTITY_PERMISSION,
  })
  const { loading, error, data } = useQuery(DOCTOR_QUERY, {
    variables: {
      doctorId,
    },
    fetchPolicy: 'network-only',
  })

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const onSubmit = async (value, { setSubmitting, resetForm }) => {
    try {
      const successMessage = value.accepting
        ? i18n.t('staff.detailDoctorIdentity.confirmSuccess')
        : value.deleting
        ? i18n.t('staff.detailDoctorIdentity.deleteSuccess')
        : i18n.t('staff.detailDoctorIdentity.resendSuccess')
      if (value.deleting) {
        staffDeleteMyNumberImage({
          variables: {
            key: value.frontSideIdentityImageKey,
          },
        })
        staffDeleteMyNumberImage({
          variables: {
            key: value.backSideIdentityImageKey,
          },
        })
        staffDeleteMyNumberImage({
          variables: {
            key: value.frontSideLicenseImageKey,
          },
        })
        staffDeleteMyNumberImage({
          variables: {
            key: value.backSideLicenseImageKey,
          },
        })
        await staffDeleteMyNumber({
          variables: {
            doctorIdentityId: value.doctorIdentityId,
          },
        })
        staffCreateActivity({
          variables: {
            activity: 'STAFF_DELETE_IDENTITY_NUMBER',
            doctorId,
          },
        })
      } else {
        await staffProcess({
          variables: {
            doctorIdentityId: value.doctorIdentityId,
            isApprove: value.accepting,
            mailContent: value.rejectedReason,
          },
        })
        staffCreateActivity({
          variables: {
            activity: 'STAFF_PROCESS_IDENTITY_NUMBER',
            doctorId,
          },
        })
      }
      popup.success(successMessage)
      setSubmitting(false)
      resetForm()
      closeModal()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }
  return (
    <React.Fragment>
      <div className='modal-title' style={{ fontWeight: 'bold' }}>
        {i18n.t('staff.detailDoctorIdentity.title')}
      </div>
      <DetailDoctorIdentityScene
        doctor={data.doctor}
        doctorIdentity={data.doctor.doctorIdentity}
        doctorIdentityRelations={
          data.doctor.doctorIdentity.doctorIdentityRelation
        }
        onSubmit={onSubmit}
        isProcessPermitted={isProcessPermitted}
        isDeletePermitted={isDeletePermitted}
      />
    </React.Fragment>
  )
}

export default DoctorCreateIdentity
