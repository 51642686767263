import Axios from 'axios'
import { useEffect, useState } from 'react'

export const useAxios = ({ url, args, method = 'get', params, dependencies }) => {
  const [{ loading, error, data }, setState] = useState({
    loading: true,
    error: null,
    data: {},
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios({
          method,
          url,
          data: args,
          params,
        })
        setState({
          loading: false,
          data: response.data,
        })
      } catch (error) {
        setState({
          loading: false,
          error: 'ERROR',
        })
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependencies])

  return { loading, error, data }
}
