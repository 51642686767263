import ConfirmationModal from '@medical/components/ConfirmationModal'
import InputTextarea from '@medical/components/InputTextarea/index.js'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import React from 'react'
// import SanitizedHTML from 'react-sanitized-html'
import * as Yup from 'yup'

import styles from './Style'

const SingleInquiryScene = ({ i18n, inquiry, onSubmit, isReplyPermitted }) => {
  const validationSchema = Yup.object().shape({
    reply: Yup.string()
      .nullable()
      .required(i18n.t('validation.reply.required'))
      .max(1000, i18n.t('validation.reply.max', { max: 1000 })),
  })

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          reply: inquiry.reply,
          confirm: false,
          replying: false,
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        render={formikProps => {
          const {
            initialValues,
            validateForm,
            setFieldValue,
            setTouched,
          } = formikProps
          const validateAndConfirm = () =>
            validateForm().then(response => {
              if (!Object.keys(response).length) {
                setFieldValue('confirm', true)
              } else {
                setTouched(response)
              }
            })
          return (
            <div style={styles.container}>
              <div style={styles.title}>{inquiry.article}</div>
              <div>
                {i18n.t('main.from')} :{' '}
                {`${inquiry.doctor.lastname} ${inquiry.doctor.firstname}`}
              </div>
              <div style={styles.date}>
                {i18n.t('main.createdAt')} :{' '}
                {moment(inquiry.createdAt).fromNow()}
              </div>
              {/* <SanitizedHTML html={inquiry.content} /> */}
              {/* eslint-disable-next-line react/no-danger */}
              {/* <div dangerouslySetInnerHTML={{ __html: inquiry.content }} /> */}
              <div>{i18n.t('doctor.inquiry.content')} :</div>
              <div style={styles.content}>
                <pre>{inquiry.content}</pre>
              </div>

              {inquiry.reply ? (
                <>
                  <div style={styles.reply}>
                    {i18n.t('main.staffFrom')} :{' '}
                    {`${inquiry.repliedBy.lastname} ${inquiry.repliedBy.firstname}`}
                  </div>
                  <div style={styles.date}>
                    {i18n.t('main.updatedAt')} :{' '}
                    {moment(inquiry.updatedAt).fromNow()}
                  </div>

                  <div>{i18n.t('main.reply')} :</div>
                  <div style={styles.content}>
                    <pre>{inquiry.reply}</pre>
                  </div>
                </>
              ) : null}
              {isReplyPermitted ? (
                <div className='modal-bottom'>
                  <Button
                    onClick={() => {
                      setFieldValue('replying', true)
                    }}
                    label={i18n.t('staff.createReply.submit')}
                    disabled={inquiry.reply !== null}
                  />
                </div>
              ) : null}

              <ConfirmationModal
                {...formikProps}
                confirmationMessage={i18n.t(
                  'staff.createReply.submitConfirmation'
                )}
              />
              <ConfirmationModal
                {...formikProps}
                confirmationMessage={i18n.t(
                  'staff.createReply.submitConfirmation'
                )}
                handleSubmit={validateAndConfirm}
                handleNoClick={() => {
                  setFieldValue('replying', false)
                  setFieldValue('reply', initialValues.reply)
                }}
                visibleKey='replying'
              >
                <InputTextarea
                  {...formikProps}
                  name='reply'
                  label={i18n.t('main.reply')}
                />
              </ConfirmationModal>
            </div>
          )
        }}
      />
    </React.Fragment>
  )
}

export default SingleInquiryScene
