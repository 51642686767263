import './style.css'

import { useMutation } from '@apollo/react-hooks'
import { ReactComponent as Clinic } from '@medical/assets/image/clinic.svg'
import { ReactComponent as Location } from '@medical/assets/image/location-svg-2.svg'
import { ReactComponent as Phone } from '@medical/assets/image/mobile_outline.svg'
import ModalLink from '@medical/components/ModalLink'
import { DOWNLOAD_FILE } from '@medical/pages/staff/Doctors/DoctorDetail/DoctorDetail.graphql'
import Router from '@medical/routes/router'
import { Breadcrumb } from 'antd'
import { Formik } from 'formik'
import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const RecruitmentPostDetailScene = ({
  i18n,
  onSubmit,
  recruitmentPost,
  departmentNames,
}) => {
  let url
  const [linkUrl, setUrl] = useState(null)
  const [linkUrlClinic, setUrlClinic] = useState(null)
  if (process?.env.REACT_APP_ENV === 'development') {
    url = 'http://localhost:3000/doctor/inquiry?'
  } else if (process?.env.REACT_APP_ENV === 'staging') {
    url = 'https://staging.capsdoctor365.jp/doctor/inquiry?'
  } else if (process?.env.REACT_APP_ENV === 'production') {
    url = 'https://doctor.capsdoctor365.jp/doctor/inquiry?'
  }

  const [downloadFile] = useMutation(DOWNLOAD_FILE)
  const downloadFileSubmit = async (key, callback) => {
    if (!key || key.length === 0) return
    try {
      const {
        data: { downloadFileS3: fileURL },
      } = await downloadFile({
        variables: {
          key,
        },
      })
      callback(fileURL)
    } catch (error) {
      callback(null)
    }
  }

  useEffect(() => {
    if (recruitmentPost) {
      if (recruitmentPost.imageKey) {
        downloadFileSubmit(recruitmentPost.imageKey, fileURL => {
          if (fileURL != null) {
            setUrl(fileURL)
          }
        })
      }
      if (!recruitmentPost.imageKey) {
        downloadFileSubmit(process.env.REACT_APP_IMAGE_DEFAULT, fileURL => {
          if (fileURL != null) {
            setUrl(fileURL)
          }
        })
      }
      if (recruitmentPost.clinicalDepartment?.clinic.imageKey) {
        downloadFileSubmit(
          recruitmentPost.clinicalDepartment.clinic.imageKey,
          fileURL => {
            if (fileURL != null) {
              setUrlClinic(fileURL)
            }
          }
        )
      }
      if (
        recruitmentPost.clinicalDepartment &&
        !recruitmentPost.clinicalDepartment.clinic.imageKey
      ) {
        downloadFileSubmit(process.env.REACT_APP_IMAGE_DEFAULT, fileURL => {
          if (fileURL != null) {
            setUrlClinic(fileURL)
          }
        })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recruitmentPost])

  url += `title=${recruitmentPost.title}`
  url += `&type=${recruitmentPost.recruitmentDoctorType}`

  if (recruitmentPost.clinicalDepartment) {
    url += `&clinicalDepartment=${
      recruitmentPost.clinicalDepartment.clinic.name
    }_${departmentNames[recruitmentPost.clinicalDepartment.name]}`
  }
  return (
    <React.Fragment>
      <div className='is-pc'>
        <div className='container' style={{ maxWidth: '60%' }}>
          <div className='staff-header' style={{ marginTop: '40px' }}>
            <div className='staff-title staff-title-detail-recuitment'>
              {i18n.t('doctor.menuBar.recruitmentFullTime')}

              <Breadcrumb separator={<i className='pi pi-chevron-right'></i>}>
                <Breadcrumb.Item className='breadcrumb-item' href={Router.home}>
                  {i18n.t('main.home')}
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  className='breadcrumb-item'
                  href={Router.doctorRecruitments}
                >
                  {i18n.t('doctor.menuBar.recruitment')}
                </Breadcrumb.Item>
                <Breadcrumb.Item className='breadcrumb-item breadcrumb-title'>
                  {recruitmentPost.title}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <Formik
            initialValues={{
              confirm: false,
              announceId: '',
              linkUrl: linkUrl || null,
              linkUrlClinic: linkUrlClinic || null,
            }}
            onSubmit={onSubmit}
            render={formikProps => {
              const { values } = formikProps
              return (
                <>
                  <div className='recruitment-detail-img'>
                    <img
                      className='recruitment_image'
                      height='100%'
                      width='100%'
                      style={{
                        aspectRatio: '2/1',
                        objectFit: 'contain',
                        margin: 'auto',
                      }}
                      src={linkUrl}
                    />
                  </div>
                  <div className='recruitment_wrap'>
                    <div className='recruitment_type'>
                      <span className='recruitment_type-link-detail'>
                        {recruitmentPost.recruitmentDoctorType ===
                        'PERMANENT_DOCTOR'
                          ? '常勤'
                          : '定期非常勤'}
                      </span>
                    </div>
                    <div className='recruitment_title'>
                      {recruitmentPost.title}
                    </div>

                    {/* <

                    {/* <p
                      className='recruitment_content'
                      // eslint-disable-next-line react/no-danger
                    >
                      {recruitmentPost.content.replace(/<[^>]+>/g, '')}
                    </p> */}
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: recruitmentPost.content,
                      }}
                    />
                  </div>
                  <div
                    className='recruitment_post-clinic'
                    style={{ display: 'flex' }}
                  >
                    <div
                      className='recruitment_post-clinic-img'
                      style={{ margin: 'auto' }}
                    >
                      <img
                        src={linkUrlClinic}
                        style={{
                          aspectRatio: '2/1',
                          objectFit: 'contain',
                          margin: 'auto',
                          maxHeight: '140px',
                          maxWidth: '260px',
                          border: '1px solid #f3e9e9',
                        }}
                      />
                    </div>

                    {recruitmentPost.clinicalDepartment && (
                      <div
                        className='recruitment_post-clinic-infor'
                        style={{ marginLeft: '20px', width: '100%' }}
                      >
                        <div
                          className='recruitment_subtitle'
                          style={{
                            display: 'flex',
                            padding: '10px 0px',
                            borderTop: '1px solid #f3e9e9',
                          }}
                        >
                          <i
                            style={{
                              marginRight: '9px',
                              marginBottom: '-3px',
                              marginLeft: '22px',
                            }}
                            className='bg-red mt-0'
                          >
                            <Clinic />
                          </i>

                          {`${recruitmentPost.clinicalDepartment.clinic.name}_${
                            departmentNames[
                              recruitmentPost.clinicalDepartment.name
                            ]
                          }`}
                        </div>
                        <div
                          className='recruitment_subtitle'
                          style={{
                            display: 'flex',
                            padding: '10px 0px ',
                            borderTop: '1px solid #f3e9e9',
                          }}
                        >
                          <i
                            style={{
                              marginRight: '5px',
                              marginBottom: '-3px',
                              marginLeft: '20px',
                            }}
                            className='bg-red mt-0'
                          >
                            <Location />
                          </i>

                          {`${recruitmentPost.clinicalDepartment.clinic.address}`}
                        </div>
                        <div
                          className='recruitment_subtitle'
                          style={{
                            display: 'flex',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            borderTop: '1px solid #f3e9e9',
                            borderBottom: '1px solid #f3e9e9',
                          }}
                        >
                          <i
                            style={{
                              marginRight: '5px',
                              marginBottom: '-3px',
                              marginLeft: '20px',
                            }}
                            className='bg-red mt-0'
                          >
                            <Phone />
                          </i>

                          {recruitmentPost.clinicalDepartment.clinic
                            .inquiryPhoneNumber !== null
                            ? `${recruitmentPost.clinicalDepartment.clinic.inquiryPhoneNumber}`
                            : ''}
                        </div>
                      </div>
                    )}
                  </div>
                  {recruitmentPost.clinicalDepartment && (
                    <div className='recruitment_more'>
                      ※応募が完了したあと、内容を事務局側で確認させていただき、改めてご連絡します。事務局確認後は応募内容の変更
                      はできかねます。
                    </div>
                  )}
                  <div className='recruitment_button'>
                    <a href={url} target='_blank'>
                      <Button
                        type='button'
                        style={{
                          marginRight: '30px',
                          padding: '2px 6px',
                          borderRadius: '6px',
                        }}
                        className='btn outline-red btn'
                        label='お問い合わせ'
                      />
                    </a>
                    {recruitmentPost.clinicalDepartment && (
                      <ModalLink
                        to={Router.get(Router.doctorRecruitmentDetailApply)}
                      >
                        <Button
                          style={{
                            padding: '2px 6px',
                            borderRadius: '6px',
                            marginRight: '40px',
                          }}
                          type='button'
                          className='reflect-button btn'
                          label='応募する'
                        />
                      </ModalLink>
                    )}
                  </div>
                </>
              )
            }}
          />
        </div>
      </div>
      <div className='is-sp'>
        <div className='container' style={{ minWidth: '100%' }}>
          <div className='staff-header' style={{ marginTop: '40px' }}>
            <div className='staff-title' style={{ marginRight: '20px' }}>
              {i18n.t('doctor.menuBar.recruitmentFullTime')}
              <div
                className='breadcrumb'
                style={{
                  display: 'block',
                  lineHeight: '16px',
                  marginBottom: '30px',
                }}
              >
                <Link to={Router.home}>{i18n.t('main.home')}</Link>
                <Link to={Router.doctorRecruitments}>
                  <i className='pi pi-chevron-right' />
                  {i18n.t('doctor.menuBar.recruitment')}
                </Link>
                <React.Fragment>
                  <i className='pi pi-chevron-right' />
                  {recruitmentPost.title}
                </React.Fragment>
              </div>
            </div>
          </div>
          <Formik
            initialValues={{
              confirm: false,
              announceId: '',
              linkUrl: linkUrl || null,
            }}
            onSubmit={onSubmit}
            render={formikProps => {
              const { values } = formikProps
              return (
                <>
                  <img
                    className='recruitment_image'
                    src={linkUrl}
                    style={{ height: 'auto' }}
                  />
                  <div
                    className='recruitment_wrap'
                    style={{ padding: '0 20px' }}
                  >
                    <div className='recruitment_type'>
                      <span className='recruitment_type-link-detail'>
                        {recruitmentPost.recruitmentDoctorType ===
                        'PERMANENT_DOCTOR'
                          ? '常勤'
                          : '定期非常勤'}
                      </span>
                    </div>
                    <div className='recruitment_title'>
                      {recruitmentPost.title}
                    </div>

                    <p
                      className='recruitment_content'
                      // eslint-disable-next-line react/no-danger
                    >
                      {recruitmentPost.content.replace(/<[^>]+>/g, '')}
                    </p>

                    <div
                      className='recruitment_post-clinic'
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <div
                        className='recruitment_post-clinic-img'
                        style={{ marginBottom: '20px' }}
                      >
                        <img
                          src={linkUrlClinic}
                          style={{
                            aspectRatio: '2/1',
                            objectFit: 'contain',
                            margin: 'auto',
                            width: '100%',
                            border: '1px solid #f3e9e9',
                          }}
                        />
                      </div>

                      {recruitmentPost.clinicalDepartment && (
                        <div
                          className='recruitment_post-clinic-infor'
                          style={{ width: '100%' }}
                        >
                          <div
                            className='recruitment_subtitle'
                            style={{
                              display: 'flex',
                              padding: '10px 0px',
                              borderTop: '1px solid #f3e9e9',
                            }}
                          >
                            <i
                              style={{
                                marginRight: '5px',
                                marginBottom: '-3px',
                                marginLeft: '20px',
                              }}
                              className='bg-red mt-0'
                            >
                              <Clinic />
                            </i>

                            {`${
                              recruitmentPost.clinicalDepartment.clinic.name
                            }_${
                              departmentNames[
                                recruitmentPost.clinicalDepartment.name
                              ]
                            }`}
                          </div>
                          <div
                            className='recruitment_subtitle'
                            style={{
                              display: 'flex',
                              padding: '10px 0px ',
                              borderTop: '1px solid #f3e9e9',
                            }}
                          >
                            <i
                              style={{
                                marginRight: '5px',
                                marginBottom: '-3px',
                                marginLeft: '20px',
                              }}
                              className='bg-red mt-0'
                            >
                              <Location />
                            </i>

                            {`${recruitmentPost.clinicalDepartment.clinic.address}`}
                          </div>
                          <div
                            className='recruitment_subtitle'
                            style={{
                              display: 'flex',
                              paddingTop: '10px',
                              paddingBottom: '10px',
                              borderTop: '1px solid #f3e9e9',
                              borderBottom: '1px solid #f3e9e9',
                            }}
                          >
                            <i
                              style={{
                                marginRight: '5px',
                                marginBottom: '-3px',
                                marginLeft: '20px',
                              }}
                              className='bg-red mt-0'
                            >
                              <Phone />
                            </i>

                            {`${recruitmentPost.clinicalDepartment.clinic.phoneNumber}`}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {recruitmentPost.clinicalDepartment && (
                    <div
                      className='recruitment_more'
                      style={{ padding: '0 20px', marginTop: '40px' }}
                    >
                      ※応募が完了したあと、内容を事務局側で確認させていただき、改めてご連絡します。事務局確認後は応募内容の変更
                      はできかねます。
                    </div>
                  )}
                  <div className='recruitment_button'>
                    <a href={url} target='_blank'>
                      <Button
                        type='button'
                        style={{
                          fontSize: '12px',
                          marginRight: '30px',
                          padding: '2px 6px',
                          borderRadius: '6px',
                        }}
                        className='btn outline-red btn'
                        label='お問い合わせ'
                      />
                    </a>
                    {recruitmentPost.clinicalDepartment && (
                      <ModalLink
                        to={Router.get(Router.doctorRecruitmentDetailApply)}
                      >
                        <Button
                          style={{
                            fontSize: '12px',
                            padding: '2px 6px',
                            borderRadius: '6px',
                            marginRight: '40px',
                          }}
                          type='button'
                          className='reflect-button btn'
                          label='応募する'
                        />
                      </ModalLink>
                    )}
                  </div>
                </>
              )
            }}
          />
        </div>
      </div>
    </React.Fragment>
  )
}
export default RecruitmentPostDetailScene
