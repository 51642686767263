import { useApolloClient, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import i18n from '@medical/libs/I18n/i18n'
import { DOCTORS_CONNECTION } from '@medical/pages/staff/Doctors/DoctorsList/DoctorsList.graphql.js'
import { useCustom } from '@medical/provider/context'
import { Checkbox, Col, Form, Popconfirm, Popover, Row } from 'antd'
import downloadCsv from 'download-csv'
import { Formik } from 'formik'
import moment from 'moment'
import { Button as PrimeButton } from 'primereact/button'
import React, { useState } from 'react'

const checkKey = (item, key) => {
  const { zipCode = '', address1 = '', address2 = '', stateOrRegion = '' } =
    item.homeAddress || {}
  const {
    bankName = '',
    branchName = '',
    accountType = '',
    accountNumber = '',
    accountHolderName = '',
  } = item.bankAccount || {}
  const { departments } = item

  switch (key) {
    case 'birthday':
      return item[key] && moment(item[key]).format('YYYY-MM-DD')
    case 'gender':
      return item[key] ? i18n.t(`main.${item[key]}`) : undefined
    case 'workPattern':
      return item[key] ? i18n.t(`main.${item[key]}`) : undefined
    case 'joinBackground':
      return item[key] ? i18n.t(`main.${item[key]}`) : undefined
    case 'zipCode':
      return zipCode
    case 'address1':
      return address1
    case 'address2':
      return address2
    case 'stateOrRegion':
      return stateOrRegion
    case 'bankName':
      return bankName
    case 'branchName':
      return branchName
    case 'accountType':
      return accountType
    case 'accountNumber':
      return accountNumber
    case 'accountHolderName':
      return accountHolderName
    case 'blocked':
      return item.blocked ? 1 : undefined
    case 'nearestStations':
      return [item.nearestStations]
        .reduce((acc, stationName) => `${acc}${stationName}、`, '')
        .replace(/,/g, '')
    case 'departments':
      return item[key]
        ? departments
            .map((val, index, arr) => (arr[index] = i18n.t(`main.${val}`)))
            .reduce((acc, curr) => `${acc}${curr} `, '')
        : undefined
    default:
      return item[key]
  }
}

const downloadable = async ({ data, columns }) =>
  data.map(item => {
    let newObject = {}
    Object.keys(columns).forEach(key => {
      if (columns[key]) {
        newObject = {
          ...newObject,
          ...{
            [key]: checkKey(item, key),
          },
        }
      }
    })
    return newObject
  })

const advancedDownload = async ({ data, tableColumns, checked }) => {
  const downloadData = await downloadable({ data, columns: checked })
  downloadCsv(
    downloadData,
    tableColumns,
    `doctors_${moment().format('YYYYMMDDHHmm')}.csv`
  )
}

const ExportDoctorCSV = ({ count, variables }) => {
  const [loading] = useState(false)
  const [advanced, setAdvanced] = useState(false)
  const [{ popup }] = useCustom()
  const client = useApolloClient()
  const { error } = useQuery(DOCTORS_CONNECTION, {
    fetchPolicy: 'network-only',
  })
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const tableColumns = {
    lastname: i18n.t('updateProfile.lastname'),
    firstname: i18n.t('updateProfile.firstname'),
    lastnameKana: i18n.t('updateProfile.lastnameKana'),
    firstnameKana: i18n.t('updateProfile.firstnameKana'),
    email: i18n.t('updateProfile.email'),
    phoneNumber: i18n.t('updateProfile.phoneNumber'),
    gender: i18n.t('updateProfile.gender'),
    birthday: i18n.t('updateProfile.birthday'),
    medicalLicenseNumber: i18n.t('updateProfile.medicalLicenseNumber'),
    departments: i18n.t('updateProfile.departments'),
    zipCode: i18n.t('updateProfile.homeAddress.zipCode'),
    stateOrRegion: i18n.t('updateProfile.homeAddress.stateOrRegion'),
    address1: i18n.t('updateProfile.homeAddress.address1'),
    address2: i18n.t('updateProfile.homeAddress.address2'),
    placeOfWork: i18n.t('updateProfile.placeOfWork'),
    nearestStations: i18n.t('updateProfile.nearestStations'),
    workPattern: i18n.t('updateProfile.workPattern'),
    joinBackground: i18n.t('updateProfile.joinBackground'),
    joinBackgroundOther: '入職経緯（備考）',
    bankName: i18n.t('doctor.bankAccount.bankName'),
    branchName: i18n.t('doctor.bankAccount.branchName'),
    accountType: i18n.t('doctor.bankAccount.accountType'),
    accountNumber: i18n.t('doctor.bankAccount.accountNumber'),
    accountHolderName: i18n.t('doctor.bankAccount.accountHolderName'),
    blocked: '募集制限フラグ',
    medicalRecord: i18n.t('staff.createDoctor.medicalRecord'),
    orca: i18n.t('staff.createDoctor.orca'),
  }

  return (
    <Formik
      initialValues={{
        tableColumns,
        checked: null,
      }}
      onSubmit={() => {}}
      render={formikProps => {
        const checkLength = formikProps.values.checked
        return (
          <Popover
            placement='bottom'
            content={
              <div style={{ width: '700px' }}>
                <Form.Item label='医師のデータ数：'>{count}</Form.Item>
                <Row gutter={8}>
                  <Col>
                    <Popconfirm
                      title='選択した項目をダウンロードしますか?'
                      onConfirm={async () => {
                        setAdvanced(true)
                        const data = []
                        const numberOfExecutions =
                          count > 1000 ? Math.round(count / 1000) : 1
                        for (let i = 0; i < numberOfExecutions; i += 1) {
                          try {
                            const {
                              data: {
                                doctorsConnection: { edges },
                              },
                            } = await client.query({
                              query: DOCTORS_CONNECTION,
                              variables: {
                                ...variables,
                                first: undefined,
                                skip: undefined,
                              },
                            })
                            edges.forEach(item => {
                              data.push(item.node)
                            })
                          } catch (error) {
                            popup.error(i18n.t('main.downloadFileError'))
                          }
                        }
                        advancedDownload({
                          data,
                          tableColumns,
                          checked: formikProps.values.checked,
                        })
                        setAdvanced(false)
                      }}
                      disabled={!checkLength}
                      okText={i18n.t('main.yes')}
                      cancelText={i18n.t('main.no')}
                    >
                      <PrimeButton
                        loading={advanced}
                        disabled={!checkLength || advanced}
                        icon='pi pi-download'
                        className='p-button-normal'
                        label='選択した項目をダウンロードする'
                      />
                    </Popconfirm>
                  </Col>
                </Row>
                <Row gutter={16} style={{ marginTop: '25px' }}>
                  {Object.keys(tableColumns).map(function(key) {
                    return (
                      <Col key={key} className='gutter-row' span={6}>
                        <Checkbox
                          name={`${key}`}
                          onChange={e => {
                            formikProps.setFieldValue(
                              `checked[${key}]`,
                              e.target.checked
                            )
                          }}
                        >
                          {tableColumns[key]}
                        </Checkbox>
                      </Col>
                    )
                  })}
                </Row>
              </div>
            }
            title='医師情報CSVダウンロード　※項目を選択した順にデータが表示されます'
            trigger='click'
          >
            <PrimeButton
              icon='pi pi-download'
              className='p-button-normal'
              label='医師情報CSVダウンロード'
            />
          </Popover>
        )
      }}
    />
  )
}

export default ExportDoctorCSV
