import logo from '@medical/assets/image/logo.png'
import PasswordInput from '@medical/components/PasswordInput'
import { PrivacyLink, TermsLink } from '@medical/components/StaticLink'
import {
  PASSWORD_NON_SPACE,
  PASSWORD_PATTERN,
} from '@medical/libs/validation/password'
import { ErrorMessage, Formik } from 'formik'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

const EmailConfirmationForm = ({ onSubmit, hasCheckbox, isResetPassword }) => {
  const { i18n } = useTranslation()
  const min = i18n.t('validation.password.min')
  const max = i18n.t('validation.password.max')
  const pattern = i18n.t('validation.password.pattern')
  const patternNoSpace = i18n.t('validation.password.patternNoSpace')
  const required = i18n.t('validation.required')
  const password = Yup.string()
    .max(50, max)
    .min(6, min)
    .matches(PASSWORD_PATTERN, pattern)
    .matches(PASSWORD_NON_SPACE, patternNoSpace)
  const validationSchema = Yup.object({
    newPassword: password.required(
      `${i18n.t('main.emailConfirmation.newPassword')} ${required}`
    ),
    newPasswordConfirm: password
      .oneOf(
        [Yup.ref('newPassword'), null],
        i18n.t('validation.password.confirmNotMatch')
      )
      .required(
        `${i18n.t('main.emailConfirmation.newPasswordConfirm')} ${required}`
      ),
    checked: hasCheckbox
      ? Yup.bool()
          .oneOf([true], `チェックしてください`)
          .required()
      : undefined,
  })
  return (
    <Formik
      initialValues={{
        newPassword: '',
        newPasswordConfirm: '',
        checked: false,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={formikProps => {
        const { handleSubmit, isSubmitting, values, handleChange } = formikProps
        return (
          <div className='login-bg'>
            <div className='login-box is-medium'>
              <div className='login-title'>
                <img src={logo} alt='' style={{ width: '60%' }} />
              </div>
              {isResetPassword ? (
                <h3>パスワード再設定</h3>
              ) : (
                <h3>パスワード設定</h3>
              )}
              <PasswordInput
                {...formikProps}
                feedback={false}
                name='newPassword'
                label={i18n.t('main.emailConfirmation.newPassword')}
              />
              <PasswordInput
                {...formikProps}
                feedback={false}
                name='newPasswordConfirm'
                label={i18n.t('main.emailConfirmation.newPasswordConfirm')}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    handleSubmit()
                  }
                }}
              />
              {hasCheckbox && (
                <div
                  style={{
                    padding: '20px 5px 0',
                    marginBottom: '50px',
                    position: 'relative',
                  }}
                >
                  <Checkbox
                    onChange={e => {
                      if (e.originalEvent.key === 'Enter') {
                        handleSubmit()
                      } else {
                        handleChange(e)
                      }
                    }}
                    checked={values.checked}
                    name='checked'
                    style={{ margin: '10px' }}
                  />
                  <TermsLink />と
                  <PrivacyLink />
                  に同意します。
                  <ErrorMessage
                    name='checked'
                    component='div'
                    className='login-alert'
                  />
                </div>
              )}
              <div className='modal-bottom'>
                <Button
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  type='submit'
                  label={i18n.t('main.emailConfirmation.submit')}
                  className='login-button'
                />
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

export default EmailConfirmationForm
