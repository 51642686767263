import BreadCrumb from '@medical/components/Breadcrumb'
import {
  calcWorkScheduleTotalHourPerDay,
  convertTime,
  calculaterSubsidy,
} from '@medical/libs'
import numeralCurrency from '@medical/libs/numeralCurrency'
import Router from '@medical/routes/router'
import { Modal } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import DoctorWorkReportHeader from './DoctorWorkReportHeader'

const DoctorWorkReportScene = ({
  i18n,
  doctorId,
  doctor,
  date,
  workSchedules,
  totalHours,
  totalDays,
  totalSalary,
  totalTransportationExpense,
  totalIncentive,
  normalIncentiveTypes = [],
  otherIncentive = {},
}) => {
  const [visible, setvisible] = useState(false)
  const showModal = () => {
    setvisible(true)
  }
  const hideModal = () => {
    setvisible(false)
  }
  const { lastname, firstname } = doctor
  return (
    <React.Fragment>
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('staff.menuBar.doctorWorkRecords')}
          <BreadCrumb
            items={[
              {
                to: Router.get(Router.staffDoctorWorkRecords, {
                  id: doctorId,
                }),
                title: i18n.t('staff.menuBar.doctorWorkRecords'),
              },
              {
                title: lastname + firstname,
              },
            ]}
          />
        </div>
      </div>
      <div className='container'>
        <div className='box'>
          <div className='schedule-calendar'>
            <div className='calendar-container'>
              <div style={{ paddingTop: '50px' }}>
                <div className='overflow-container'>
                  <table className='table' style={{ marginBottom: '10px' }}>
                    <thead>
                      <tr>
                        <th width='30%'>{i18n.t('main.clinicName')}</th>
                        <th width='10%'>{i18n.t('main.departmentName')}</th>
                        <th width='20%'>{i18n.t('main.date')}</th>
                        <th width='10%'>{i18n.t('main.startTime')}</th>
                        <th width='10%'>{i18n.t('main.endTime')}</th>
                        <th width='10%'>{i18n.t('main.shiftHour')}</th>
                        <th width='10%'>{i18n.t('main.dailySalary')}</th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: 'center' }}>
                      {workSchedules.length > 0 ? (
                        workSchedules.map(workSchedule => {
                          return (
                            <tr key={workSchedule.id}>
                              <td>{`${workSchedule.clinic}`}</td>
                              <td>{workSchedule.clinicalDepartment}</td>
                              <td>
                                {moment(workSchedule.startTime).format(
                                  'LL (dd)'
                                )}
                              </td>
                              <td>
                                {moment(workSchedule.startTime).format('HH:mm')}
                              </td>
                              <td>
                                {moment(workSchedule.endTime).format('HH:mm')}
                              </td>
                              <td>
                                {calcWorkScheduleTotalHourPerDay(workSchedule)}
                              </td>
                              <td>
                                {numeralCurrency(workSchedule.dailySalary)}
                              </td>
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan={7}>{i18n.t('main.noWorkSchedule')}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <table>
                  <tbody>
                    <tr>
                      <th style={{ textAlign: 'left' }}>
                        {`${i18n.t(
                          'staff.doctorWorkReport.availableShift'
                        )}${i18n.t('main.time')}${i18n.t('main.total')}`}
                      </th>
                      <td>{convertTime(totalHours)}</td>
                    </tr>
                    <tr>
                      <th style={{ textAlign: 'left' }}>
                        {`${i18n.t(
                          'staff.doctorWorkReport.availableShift'
                        )}${i18n.t('main.Days')}${i18n.t('main.total')}`}
                      </th>
                      <td>{`${totalDays}日`}</td>
                    </tr>
                    <tr>
                      <th style={{ textAlign: 'left' }}>
                        {`${i18n.t(
                          'staff.doctorWorkReport.availableShift'
                        )}${i18n.t('main.dailySalary')}${i18n.t('main.total')}`}
                      </th>
                      <td>{numeralCurrency(totalSalary)}</td>
                    </tr>
                    <tr>
                      <th style={{ textAlign: 'left' }}>
                        {`${i18n.t('main.transportationExpense')}`}
                      </th>
                      <td>{numeralCurrency(totalTransportationExpense)}</td>
                    </tr>
                    <tr>
                      <th style={{ textAlign: 'left' }}>
                        {`${i18n.t('main.totalIncentive')}`}
                      </th>
                      <td>{numeralCurrency(totalIncentive)}</td>
                    </tr>
                    {normalIncentiveTypes.length > 0 &&
                      normalIncentiveTypes.map(
                        (it, index) =>
                          Object.keys(it).length > 0 && (
                            <tr key={index}>
                              <th
                                style={{
                                  textAlign: 'left',
                                }}
                              >
                                <span
                                  style={{
                                    marginLeft: '15px',
                                  }}
                                >
                                  {it.type}:
                                </span>
                              </th>
                              <td>{numeralCurrency(it.value)}</td>
                            </tr>
                          )
                      )}
                    {otherIncentive.NUMBER_INCENTIVE && (
                      <tr>
                        <th
                          style={{
                            textAlign: 'left',
                          }}
                        >
                          <span
                            style={{
                              marginLeft: '15px',
                            }}
                          >
                            {`${i18n.t('main.IncentiveType.NUMBER_INCENTIVE')}`}
                            :
                          </span>
                        </th>
                        <td>
                          {numeralCurrency(otherIncentive.NUMBER_INCENTIVE)}
                        </td>
                      </tr>
                    )}
                    {otherIncentive.BEYOND_EIGHT_INCENTIVE && (
                      <tr>
                        <th
                          style={{
                            textAlign: 'left',
                          }}
                        >
                          <span
                            style={{
                              marginLeft: '15px',
                            }}
                          >
                            {`${i18n.t(
                              'main.IncentiveType.BEYOND_EIGHT_INCENTIVE'
                            )}`}
                            :
                          </span>
                        </th>
                        <td>
                          {numeralCurrency(
                            otherIncentive.BEYOND_EIGHT_INCENTIVE
                          )}
                        </td>
                      </tr>
                    )}
                    {otherIncentive.TIME_INCENTIVE && (
                      <tr>
                        <th
                          style={{
                            textAlign: 'left',
                          }}
                        >
                          <span
                            style={{
                              marginLeft: '15px',
                            }}
                          >
                            {`${i18n.t('main.IncentiveType.TIME_INCENTIVE')}`}:
                          </span>
                        </th>
                        <td>
                          {numeralCurrency(otherIncentive.TIME_INCENTIVE)}
                        </td>
                      </tr>
                    )}
                    {otherIncentive.SATISFACTION_INCENTIVE && (
                      <tr>
                        <th
                          style={{
                            textAlign: 'left',
                          }}
                        >
                          <span
                            style={{
                              marginLeft: '15px',
                            }}
                          >
                            {`${i18n.t(
                              'main.IncentiveType.SATISFACTION_INCENTIVE'
                            )}`}
                            :
                          </span>
                        </th>
                        <td>
                          {numeralCurrency(
                            otherIncentive.SATISFACTION_INCENTIVE
                          )}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <th
                        style={{
                          textAlign: 'left',
                        }}
                      >
                        <Link style={{ color: '#40a9ff' }} onClick={showModal}>
                          ※ {`${i18n.t('main.incentives.detailIncentive')}`}
                        </Link>
                        <Modal
                          title='各インセンティブの詳細について'
                          className='modal-antd'
                          closable={false}
                          visible={visible}
                          okButtonProps={{
                            style: {
                              display: 'none',
                            },
                          }}
                          onCancel={hideModal}
                          cancelText={i18n.t('main.close')}
                        >
                          <div>
                            <p>
                              当法人と直接雇用契約を締結して勤務した場合、勤務成績等に応じたインセンティブを支給しています。
                            </p>
                            <p>
                              単価は事業の環境・収益性・経営状況等を勘案し、定期的に内容の見直しを行っています。
                            </p>
                            <p>◆「ナンバーインセンティブ」：　診療件数x単価</p>
                            <p>
                              ◆「ビヨンド8インセンティブ」：　1時間当りの平均診療件数が8人を超えた場合の超過件数x単価
                            </p>
                            <p>
                              ◆「タイムインセンティブ」：　1件当りの平均診療時間が5~7分の場合の診療件数x単価（※本インセンティブは、予防接種・健康診断・検査を除きます。診療時間は電子カルテの起動・終了時間により集計いたします。）
                            </p>
                            <p>
                              ◆「サティスファクションインセンティブ」：　患者アンケート結果が要件（「非常に満足」
                              90%以上かつ「満足しなかった」
                              0%）を満たした場合、診療件数x単価
                            </p>
                            <p>
                              以下のいずれかに該当した場合は、その情状に応じてインセンティブ額を減額またはは不支給とさせていただきます。
                            </p>
                            <p>
                              ①当法人の診療方針、就業規則から著しく逸脱している場合
                            </p>
                            <p>
                              ②スタッフとのコミュニケーションに著しい支障がある場合
                            </p>
                            <p>③患者アンケートの結果が著しく悪い場合</p>
                            <p>④患者から多大なクレームが寄せられた場合</p>
                            <p>
                              ⑤その他当法人の名誉、信用、社会的評価を著しく毀損した場合
                            </p>
                          </div>
                        </Modal>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
              <DoctorWorkReportHeader doctorId={doctorId} date={date} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default DoctorWorkReportScene
