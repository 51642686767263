import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { TRANSPORTATION_TYPE_SETTINGS_ACTION } from '@medical/constant'
import { useCustom } from '@medical/provider/context'
import React, { useEffect } from 'react'

import {
  DELETE_TRANSPORTATION_TYPE_SETTINGS,
  TRANSPORTATION_TYPE_SETTINGS,
  TRANSPORTATION_TYPE_SETTINGS_CONNECTION,
  TRANSPORTATION_TYPE_SETTINGS_SINGLE,
} from '../MovingExpenseSetting.graphql'
import { MovingExpenseDetailSettingScence } from './MovingExpenseDetailSettingScence'

export const MovingExpenseDetailSetting = ({
  closeModal,
  match: {
    params: { id },
  },
}) => {
  const [{ i18n, popup }] = useCustom()
  const { loading, error, data } = useQuery(
    TRANSPORTATION_TYPE_SETTINGS_SINGLE,
    {
      variables: {
        where: {
          id,
        },
      },
    }
  )

  const {
    error: errorTypeSetting,
    loading: loadingTypeSetting,
    data: dataTypeSetting,
    refetch,
  } = useQuery(TRANSPORTATION_TYPE_SETTINGS, {
    variables: {
      where: {
        isExpiredSetting: false,
      },
    },
  })
  const [deleteMovingExpenseSetting] = useMutation(
    DELETE_TRANSPORTATION_TYPE_SETTINGS
  )
  const onSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    const { maxCost, type, isApply, action } = values
    try {
      if (action === TRANSPORTATION_TYPE_SETTINGS_ACTION.DELETE) {
        await deleteMovingExpenseSetting({
          variables: {
            staffUpsertTransportTypeSettingId: id,
            action,
          },
          refetchQueries: [
            {
              query: TRANSPORTATION_TYPE_SETTINGS_CONNECTION,
              variables: {},
            },
          ],
        })
      }
      if (action === TRANSPORTATION_TYPE_SETTINGS_ACTION.UPDATE) {
        await deleteMovingExpenseSetting({
          variables: {
            staffUpsertTransportTypeSettingId: id,
            action,
            type,
            maxCost: +maxCost,
            isApply,
          },
          refetchQueries: [
            {
              query: TRANSPORTATION_TYPE_SETTINGS_CONNECTION,
              variables: {},
            },
          ],
        })
      }
      popup.success(i18n.t('staff.scheduledShiftGroup.submissionSuccess'))
      setSubmitting(false)
      resetForm()
      closeModal()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
      setFieldValue('confirm', false)
    }
  }
  useEffect(() => {
    refetch()
  }, [])
  if (loading || loadingTypeSetting) return <ProgressSpinner />
  if (error || errorTypeSetting)
    return <ErrorComponent error={error || errorTypeSetting} />
  return (
    <MovingExpenseDetailSettingScence
      i18n={i18n}
      data={data}
      onSubmit={onSubmit}
      typeSettingData={dataTypeSetting.transportationTypeSettings}
    />
  )
}
