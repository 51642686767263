import './style.css'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import {
  clinicalDepartmentNames,
  combineNames,
  sortAndFilterByClinicOrder,
} from '@medical/libs'
import { DEPARTMENTS_LIST } from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import { useCustom } from '@medical/provider/context'
import React, { useEffect, useState } from 'react'

import {
  DELETE__DEFAULT_HOURLY_WAGE_SCHEDULE,
  GET_HOURLY_WAGE_SCHEDULE_BY_ID,
  UPDATE_DEFAULT_HOURLY_WAGE_SCHEDULE,
} from '../ClinicsHourlyWageList/ClinicsHourlyWage.graphql'
import HourlyWageFormScene from './HourlyWageFormScene'
import moment from 'moment'

const HourlyWageUpdate = ({
  match: {
    params: { recruitmentShiftId },
  },
  parentPath,
  closeModal,
}) => {
  const [{ i18n, popup }] = useCustom()
  // const [isUpdateAction, setIsUpdatelAction] = useState(true)
  const { loading, error, data, refetch } = useQuery(DEPARTMENTS_LIST)
  const {
    loading: shiftLoading,
    error: shiftError,
    data: shiftData,
  } = useQuery(GET_HOURLY_WAGE_SCHEDULE_BY_ID, {
    variables: {
      where: {
        id: recruitmentShiftId,
      },
    },
  })
  const [updateDefaultHourlyWageSchedule] = useMutation(
    UPDATE_DEFAULT_HOURLY_WAGE_SCHEDULE
  )
  const [deleteDefaultHourlyWageSchedule] = useMutation(
    DELETE__DEFAULT_HOURLY_WAGE_SCHEDULE
  )
  if (loading || shiftLoading) return <ProgressSpinner />
  if (error || shiftError) return <ErrorComponent error={error} />
  const defaultHourlyWageSchedule =
    shiftData && shiftData.defaultHourlyWageSchedule
      ? shiftData.defaultHourlyWageSchedule
      : null
  const { clinicalDepartments, departments } = data
  const sortedClinicalDepartments = sortAndFilterByClinicOrder({
    clinicalDepartments,
    isFilter: true,
  })
  const departmentNames = clinicalDepartmentNames({ departments })
  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    let {
      defaultHourlyWageScheduleId,
      clinicalDepartmentId,
      date,
      time,
      isMonday,
      isTuesday,
      isWednesday,
      isThursday,
      isFriday,
      isSaturday,
      isSunday,
      isDayoff,
      hourlyWage,
      type,
    } = values
    try {
      if (!date.includes(null) && !time.includes(null)) {
        let startDate, endDate, startTime, endTime
        startDate = !date[0].includes('00:00:00.000Z')
          ? date[0] + 'T' + '00:00:00.000Z'
          : date[0]
        endDate =
          date[1].includes('23:59:59.000Z') || date[1].includes('23:59:59.999Z')
            ? date[1]
            : date[1] + 'T' + '23:59:59.999Z'
        startTime = moment(time[0]).toISOString()
        endTime = moment(time[1]).toISOString()
        if (Array.isArray(type)) {
          type = type.length > 0 ? type[0] : null
        }

        await updateDefaultHourlyWageSchedule({
          variables: {
            defaultHourlyWageScheduleId: defaultHourlyWageScheduleId,
            clinicalDepartmentId: clinicalDepartmentId,
            startDate: startDate,
            endDate: endDate,
            startTime: startTime,
            endTime: endTime,
            hourlyWageDefault: parseInt(hourlyWage),
            isAvailableOnMonday: isMonday,
            isAvailableOnTuesday: isTuesday,
            isAvailableOnWednesday: isWednesday,
            isAvailableOnThursday: isThursday,
            isAvailableOnFriday: isFriday,
            isAvailableOnSaturday: isSaturday,
            isAvailableOnSunday: isSunday,
            isAvailableOnHoliday: isDayoff,
            classify: type,
          },
        })
        popup.success(i18n.t('staff.createShift.submissionSuccess'))
      } else {
        await deleteDefaultHourlyWageSchedule({
          variables: {
            defaultHourlyWageScheduleId: defaultHourlyWageScheduleId,
          },
        })
        popup.success(i18n.t('staff.createShift.submissionSuccess'))
      }
      setSubmitting(false)
      closeModal()
      resetForm()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }

  const onDeleteSchedule = async data => {
    try {
      await deleteDefaultHourlyWageSchedule({
        variables: {
          defaultHourlyWageScheduleId: data,
        },
      })
      popup.success(i18n.t('staff.createShift.submissionSuccess'))
      closeModal()
    } catch (error) {
      popup.error(error)
    }
  }
  return (
    <HourlyWageFormScene
      onSubmit={onSubmit}
      clinicalDepartments={combineNames({
        clinicalDepartments: sortedClinicalDepartments,
        departmentNames,
      })}
      confirmationMessage={i18n.t('staff.createHourlyWage.updateHourlyWage')}
      i18n={i18n}
      isUpdateAction
      defaultHourlyWageSchedule={defaultHourlyWageSchedule}
      onDeleteSchedule={onDeleteSchedule}
    />
  )
}

export default HourlyWageUpdate
