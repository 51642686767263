import React, { useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useCustom } from '@medical/provider/context'
import {
  STAFF_CREATE_GASOLINE_PRICE,
  STAFF_GET_GASOLINE_PRICE,
} from './GasolinePrice.graphql'
import { GasolinePriceScene } from './GasolinePriceScene'
import { checkStaffPermission } from '@medical/libs'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { STAFF_SET_MAX_PRICE_GASOLINE } from '@medical/constant/permissions'
export const GasolinePrice = ({ closeModal }) => {
  const [{ i18n, popup }] = useCustom()
  const variables = {
    orderBy: 'createdAt_DESC',
    where: {
      deletedAt: null,
    },
  }
  const { loading, error, data, refetch } = useQuery(STAFF_GET_GASOLINE_PRICE, {
    variables,
    fetchPolicy: 'network-only',
  })

  const [createGasolinePrice] = useMutation(STAFF_CREATE_GASOLINE_PRICE)
  useEffect(() => {
    refetch()
  })

  const onSubmit = async (
    { price, maxPrice, startTime },
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      await createGasolinePrice({
        variables: {
          maxPrice: parseInt(maxPrice),
          price: parseInt(price),
          startTime,
        },
      })
      popup.success('成功しました')
      setSubmitting(false)
      resetForm()
      closeModal()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
      setFieldValue('confirm', false)
    }
  }
  const isSetMaxPriceGasolinePermitted = checkStaffPermission({
    functionName: STAFF_SET_MAX_PRICE_GASOLINE,
  })
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  return (
    <GasolinePriceScene
      i18n={i18n}
      data={data.gasolinePrices}
      isSetMaxPriceGasolinePermitted={isSetMaxPriceGasolinePermitted}
      onSubmit={onSubmit}
    />
  )
}
