import middleware from '@medical/middleware'
import React from 'react'
import { Redirect } from 'react-router-dom'

import Router from './router'

const HomeRedirect = () => {
  if (middleware.isStaff()) return <Redirect to={Router.staffHome} />
  if (middleware.isDoctor()) {
    return <Redirect to={Router.doctorHome} />
  }
  return <Redirect to={Router.doctorLogin} />
}

export default HomeRedirect
