import { dateValidation } from '@medical/libs'
import Router from '@medical/routes/router'
import moment from 'moment'
import { Button } from 'primereact/button'
import React from 'react'
import { Link } from 'react-router-dom'

const MovingExpensesDetailHeader = ({ date, doctorId }) => {
  const prevMonth = moment(date).subtract(1, 'month')
  const nextMonth = moment(date).add(1, 'month')
  const isPrevDisabled = dateValidation({
    year: prevMonth.year(),
    month: prevMonth.month() + 1,
  })
  const isNextDisabled = nextMonth.isSameOrAfter(moment().endOf('month'))
  return (
    <div className='calendar-control'>
      <Link
        to={Router.get(Router.staffDoctorsMovingExpensesDetail, {
          doctorId,
          year: prevMonth.year(),
          month: prevMonth.month() + 1,
        })}
        onClick={isPrevDisabled ? e => e.preventDefault() : undefined}
      >
        <Button
          icon='pi pi-chevron-left'
          style={{ fontSize: '14px' }}
          disabled={isPrevDisabled}
        />
      </Link>
      <div className='calendar-title'>{date.format('YYYY年M月')}</div>
      <Link
        to={Router.get(Router.staffDoctorsMovingExpensesDetail, {
          doctorId,
          year: nextMonth.year(),
          month: nextMonth.month() + 1,
        })}
        onClick={isNextDisabled ? e => e.preventDefault() : undefined}
      >
        <Button
          icon='pi pi-chevron-right'
          style={{ fontSize: '14px' }}
          disabled={isNextDisabled}
        />
      </Link>
    </div>
  )
}
export default MovingExpensesDetailHeader
