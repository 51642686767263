import BreadCrumb from '@medical/components/Breadcrumb'
import Router from '@medical/routes/router'
import moment from 'moment'
import { Button, Button as PrimeButton } from 'primereact/button'
import React from 'react'
import { Link } from 'react-router-dom'

import styles from '../Style'

const PatientSatisfactionByYearScene = ({
  i18n,
  doctorId,
  date,
  history,
  yearlyDoctorIncentiveReports,
  doctorName,
  download,
}) => {
  const currentDate = moment(date)
  const prevYear = moment(currentDate).subtract(1, 'year')
  const nextYear = moment(currentDate).add(1, 'year')
  const isNextDisabled = nextYear.year() > moment().year() + 1
  const isPrevDisabled = prevYear.year() < moment().year() - 1
  const numberRound = number => Math.round(number * 100) / 100

  return (
    <React.Fragment>
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('staff.menuBar.patientSatisfactionResults')}
          <BreadCrumb
            items={[
              { title: i18n.t('staff.menuBar.patientSatisfactionResults') },
            ]}
          />
        </div>
        <div className='staff-buttons'>
          <PrimeButton
            icon='pi pi-download'
            className='p-button-normal'
            onClick={download}
            label='CSV ダウンロード'
          />
        </div>
      </div>
      <div className='container is-medium box'>
        <div style={{ marginBlock: '10px' }}>
          {i18n.t('patientSatisfactionByYearMonth.doctorName')} {doctorName}
        </div>
        <div style={styles.header}>
          <Link
            to={Router.get(Router.staffPatientSatisfactionResultsByYear, {
              id: doctorId,
              year: prevYear.year(),
            })}
            onClick={isPrevDisabled ? e => e.preventDefault() : undefined}
          >
            <Button icon='pi pi-chevron-left' disabled={isPrevDisabled} />
          </Link>
          <div style={styles.title}>{moment(currentDate).format('YYYY年')}</div>
          <Link
            to={Router.get(Router.staffPatientSatisfactionResultsByYear, {
              id: doctorId,
              year: nextYear.year(),
            })}
            onClick={isNextDisabled ? e => e.preventDefault() : undefined}
          >
            <Button icon='pi pi-chevron-right' disabled={isNextDisabled} />
          </Link>
        </div>
        <div>
          <table className='table table-custom'>
            <thead>
              <tr>
                <th width='3%'>
                  {i18n.t('patientSatisfactionByYearMonth.month')}
                </th>
                <th width='5%'>
                  {i18n.t('patientSatisfactionByYearMonth.clinic')}
                </th>
                <th width='5%'>
                  {i18n.t('patientSatisfactionByYearMonth.department')}
                </th>
                <th width='5%'>
                  {i18n.t('patientSatisfactionByYearMonth.case1')}
                </th>
                <th width='5%'>
                  {i18n.t('patientSatisfactionByYearMonth.case2')}
                </th>
                <th width='5%'>
                  {i18n.t('patientSatisfactionByYearMonth.case3')}
                </th>
                <th width='5%'>
                  {i18n.t('patientSatisfactionByYearMonth.case4')}
                </th>
                <th width='5%'>
                  {i18n.t('patientSatisfactionByYearMonth.case5')}
                </th>
                <th width='5%'>
                  {i18n.t('patientSatisfactionByYearMonth.case6')}
                </th>
                <th width='5%'>
                  {i18n.t('patientSatisfactionByYearMonth.case7')}
                </th>
                <th width='5%'>
                  {i18n.t('patientSatisfactionByYearMonth.case8')}
                </th>
                <th width='5%'>
                  {i18n.t('patientSatisfactionByYearMonth.case9')}
                </th>
                <th width='5%'>
                  {i18n.t('patientSatisfactionByYearMonth.case10')}
                </th>
                <th width='5%'>
                  {i18n.t('patientSatisfactionByYearMonth.case11')}
                </th>
                <th width='5%'>
                  {i18n.t('patientSatisfactionByYearMonth.case12')}
                </th>
                <th width='5%'>
                  {i18n.t('patientSatisfactionByYearMonth.case13')}
                </th>
                <th width='5%'>
                  {i18n.t('patientSatisfactionByYearMonth.case14')}
                </th>
              </tr>
            </thead>
            <tbody>
              {yearlyDoctorIncentiveReports.length > 0 ? (
                yearlyDoctorIncentiveReports.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <Link
                        to={Router.get(Router.staffPatientSatisfaction, {
                          id: doctorId,
                          year: moment(item.date).year(),
                          month: moment(item.date).month() + 1,
                          date: '',
                        })}
                      >
                        <div>{moment(item.date).month() + 1}月</div>
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={Router.get(Router.staffPatientSatisfaction, {
                          id: doctorId,
                          year: moment(item.date).year(),
                          month: moment(item.date).month() + 1,
                          date: '',
                        })}
                      >
                        <div>{item.doctorClinic}</div>
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={Router.get(Router.staffPatientSatisfaction, {
                          id: doctorId,
                          year: moment(item.date).year(),
                          month: moment(item.date).month() + 1,
                          date: '',
                        })}
                      >
                        <div>{item.doctorDepartment}</div>
                      </Link>
                    </td>
                    <td>{numberRound(item.field1)}</td>
                    <td>{numberRound(item.field2)}</td>
                    <td>{numberRound(item.field3)}</td>
                    <td>{numberRound(item.field4)}</td>
                    <td>{numberRound(item.field5)}％</td>
                    <td>{numberRound(item.field6)}％</td>
                    <td>{numberRound(item.field7)}</td>
                    <td>{numberRound(item.field8)}</td>
                    <td>{numberRound(item.field9)}</td>
                    <td>{numberRound(item.field10)}</td>
                    <td>{numberRound(item.field11)}</td>
                    <td>{numberRound(item.field12)}</td>
                    <td>{numberRound(item.field13)}</td>
                    <td>{numberRound(item.field14)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={17}>ご指定の年はデータがありません</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PatientSatisfactionByYearScene
