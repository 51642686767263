import { InputText } from 'primereact/inputtext'
import { pathOr } from 'ramda'
import React from 'react'
import { Link } from 'react-router-dom'
import Router from '@medical/routes/router'
import { Button } from 'primereact/button'

const TextInput = ({
  values,
  handleChange,
  handleBlur,
  name,
  errors,
  touched,
  disabled,
  width,
  label,
  inputWidth,
  classDisabled,
  required,
  breakLine,
  onlyStaff,
  doctorId,
}) => {
  const doctorLink = doctorId => {
    return (
      <Link
        to={{
          pathname: `${Router.get(Router.staffDoctors, {})}${Router.get(
            Router.staffDoctorDetail,
            {
              doctorId: doctorId,
            }
          )}`,
        }}
        style={{ color: '#f84e51', fontWeight: 'bold' }}
        rel='noopener noreferrer'
        target='_blank'
      >
        <Button icon='pi pi-external-link' style={{ border: 'none' }} />
      </Link>
    )
  }
  const keys = name.split('.')
  const value = pathOr('', keys, values)
  const isTouched = pathOr(false, keys, touched)
  const error = pathOr('', keys, errors)
  return (
    <div className='tr'>
      {label && (
        <div
          className={`th ${required ? 'required' : ''} ${
            onlyStaff ? 'onlyStaff' : ''
          }`}
          style={{ width }}
        >
          {label}
        </div>
      )}
      <div className='td'>
        <InputText
          name={name}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          style={inputWidth ? { width: inputWidth } : { width: '100%' }}
          disabled={disabled}
          className={classDisabled && disabled ? 'is-disabled' : ''}
          autoCapitalize='none'
        />
        {doctorId ? doctorLink(doctorId) : null}
        <div className='alert'>{isTouched && error}</div>
      </div>
    </div>
  )
}

export default TextInput
