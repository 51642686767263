import BreadCrumb from '@medical/components/Breadcrumb'
import ModalLink from '@medical/components/ModalLink'
import Router from '@medical/routes/router'
import { Formik } from 'formik'
import moment from 'moment'
import { AutoComplete } from 'primereact/autocomplete'
import { Button } from 'primereact/button'
import { Paginator } from 'primereact/paginator'
import queryString from 'query-string'
import React from 'react'
import { Link } from 'react-router-dom'

const ActivityLogsUpdateByStaffSence = ({
  i18n,
  edges,
  rowsPerPage,
  page,
  count,
  onPageChange,
  staffs,
  staffId,
  onSearch,
}) => {
  const detailActivity = node => {
    let activity
    switch (node.activity) {
      case 'STAFF_HARD_DELETE_DOCTOR':
        activity = (
          <p>
            {i18n.t(`staff.activityLogsUpdateByStaff.${node.activity}`)}
            {`  (医師名: ${node.details})`}
          </p>
        )
        break
      case 'DELETE_RECRUITMENT_SHIFTS':
        activity = (
          <p>
            {i18n.t(`staff.activityLogsUpdateByStaff.${node.activity}`)}
            {`  (シフト日時: ${node.details})`}
          </p>
        )
        break
      case 'DELETE_RECRUITMENT_SHIFTS_GROUP':
        activity = (
          <p>
            {i18n.t(`staff.activityLogsUpdateByStaff.${node.activity}`)}
            {`  (シフトタイトル: ${node.details})`}
          </p>
        )
        break
      case 'CANCEL_FIXED_SHIFTS':
        activity = (
          <p>
            {i18n.t(`staff.activityLogsUpdateByStaff.${node.activity}`)}
            {`  (${node.details} 医師名: ${node.doctor.lastname} ${node.doctor.firstname})`}
          </p>
        )
        break
      case 'DELETE_FIXED_SHIFTS_GROUP':
        activity = (
          <p>
            {i18n.t(`staff.activityLogsUpdateByStaff.${node.activity}`)}
            {`  (シフトタイトル: ${node.details} 医師名: ${node.doctor.lastname} ${node.doctor.firstname})`}
          </p>
        )
        break
      case 'STAFF_UPLOAD_CSV_DOCTOR_PROFILE_UPDATE':
        activity = (
          <p>
            {i18n.t(`staff.activityLogsUpdateByStaff.${node.activity}`)}
            {`  (医師名: ${node.details} )`}
          </p>
        )
        break
      default:
        if (node.doctor) {
          activity = (
            <p>
              {i18n.t(`staff.activityLogsUpdateByStaff.${node.activity}`)}
              {`  (医師名: ${node.doctor.lastname} ${node.doctor.firstname})`}
            </p>
          )
        } else
          activity = (
            <p>{i18n.t(`staff.activityLogsUpdateByStaff.${node.activity}`)}</p>
          )
    }
    return activity
  }
  return (
    <React.Fragment>
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('staff.activityLogsUpdateByStaff.title')}
          <BreadCrumb
            items={[{ title: i18n.t('staff.activityLogsUpdateByStaff.title') }]}
          />
        </div>
      </div>
      <div className='container'>
        <div className='box'>
          <Formik
            initialValues={{
              staff: staffs.find(staff => staff.id === staffId),
              staffs,
            }}
            render={formikProps => {
              const { values, setFieldValue } = formikProps
              return (
                <div className='search-parent'>
                  <AutoComplete
                    {...formikProps}
                    value={values.staff}
                    suggestions={values.staffs}
                    completeMethod={e => {
                      const results = staffs.filter(
                        staff =>
                          staff.fullName
                            .toLowerCase()
                            .includes(e.query.toLowerCase()) ||
                          staff.fullNameKana
                            .toLowerCase()
                            .includes(e.query.toLowerCase()) ||
                          staff.email
                            .toLowerCase()
                            .includes(e.query.toLowerCase())
                      )
                      setFieldValue('staffs', results)
                    }}
                    minLength={1}
                    placeholder='スタッフをお選びください'
                    field='fullNameEmail'
                    onUnselect={() => onSearch({ staff: null })}
                    onSelect={e => onSearch({ staff: e.value })}
                    onChange={e => setFieldValue('staff', e.value)}
                    dropdown
                    style={{ minWidth: '300px' }}
                  />
                  {values.staff ? (
                    <Link
                      to={{
                        pathname: Router.staffActivityLogsListUpdateByStaff,
                        search: queryString.stringify({
                          rowsPerPage,
                        }),
                      }}
                    >
                      <Button
                        icon='pi pi-times'
                        className='p-button-secondary'
                        style={{ marginLeft: '20px' }}
                      />
                    </Link>
                  ) : null}
                </div>
              )
            }}
          />
          <div>
            <table className='table' style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th width='30%'>
                    {i18n.t('staff.activityLogsUpdateByStaff.staff')}
                  </th>
                  <th width='40%'>
                    {i18n.t('staff.activityLogsUpdateByStaff.activity')}
                  </th>
                  <th width='30%'>
                    {i18n.t('staff.activityLogsUpdateByStaff.createdAt')}
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {edges.map(({ node }) => (
                  <tr key={node.id}>
                    <td>{node.staff ? `${node.staff.lastname} ${node.staff.firstname}` : ''}</td>
                    <td>{detailActivity(node)}</td>
                    <td className='center'>
                      {moment(node.createdAt).format('LLL (dd)')}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <ModalLink
                        to={Router.get(
                          Router.staffActivityLogsListUpdateByStaffDetail,
                          {
                            activityLogId: node.id,
                          }
                        )}
                      >
                        <Button icon='pi pi-info' />
                      </ModalLink>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Paginator
            rows={rowsPerPage}
            totalRecords={count}
            first={(page - 1) * rowsPerPage}
            onPageChange={onPageChange}
            leftContent={
              <div className='paginator-total'>
                {i18n.t('main.paginationTotal')}: {count}
              </div>
            }
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default ActivityLogsUpdateByStaffSence
