import { useApolloClient, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { useCustom } from '@medical/provider/context'
import Router from '@medical/routes/router'
import downloadCsv from 'download-csv'
import moment from 'moment'
import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'

import { DOCTOR_INCENTIVE_REPORT } from './PatientSatisfactionByMonth.graphql'
import PatientSatisfactionByMonthScene from './PatientSatisfactionByMonthScene'
import { DOCTORS_CONNECTION } from '../../Inquiries/Inquiries.graphql'
const PatientSatisfactionByMonth = ({
  history,
  match: {
    path,
    params: { id, year = moment().year(), month = moment().month(), date },
  },
}) => {
  const [{ i18n, popup }] = useCustom()
  const currentDate = moment()
    .date(date || moment().date())
    .year(year)
    .month(month - 1)
    .toISOString()

  let findByDate = {
    date_lte: moment(currentDate)
      .endOf('month')
      .toISOString(),
    date_gte: moment(currentDate)
      .startOf('month')
      .toISOString(),
  }

  if (date) {
    findByDate = {
      date: moment(currentDate).toISOString(),
    }
  }

  const variables = {
    where: {
      Doctor: {
        id,
        deletedAt: null,
      },
      ...findByDate,
    },
    orderBy: 'date_ASC',
  }

  const { loading, error, data, refetch } = useQuery(DOCTOR_INCENTIVE_REPORT, {
    variables: {
      ...variables,
    },
  })
  const {
    loading: doctorLoading,
    error: doctorError,
    data: doctorConnection,
  } = useQuery(DOCTORS_CONNECTION, {
    variables: {
      where: {
        id: id,
      },
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    refetch()
  })

  const client = useApolloClient()
  if (
    [
      Router.staffPatientSatisfactionWithoutMonthDate,
      Router.staffPatientSatisfactionWithoutYearMonthDate,
    ].includes(path)
  )
    return (
      <Redirect
        to={Router.get(Router.staffPatientSatisfaction, {
          id,
          year: moment().year(),
          month: moment().month() + 1,
          date: '',
        })}
      />
    )

  if (loading || doctorLoading) return <ProgressSpinner />
  if (error || doctorError) return <ErrorComponent error={error} />
  let doctorName = doctorConnection.doctorsConnection.edges[0].node.fullName
  const download = async () => {
    try {
      const {
        data: { doctorIncentiveReports },
      } = await client.query({
        query: DOCTOR_INCENTIVE_REPORT,
        variables: {
          ...variables,
        },
      })
      const dataByMonth = doctorIncentiveReports.map(
        ({
          date: time,
          doctorClinic,
          doctorDepartment,
          field1,
          field2,
          field3,
          field4,
          field5,
          field6,
          field7,
          field8,
          field9,
          field10,
          field11,
          field12,
          field13,
          field14,
        }) => ({
          date: moment(time).format('YYYY/MM/DD'),
          doctorClinic,
          doctorDepartment,
          field1,
          field2,
          field3,
          field4,
          field5,
          field6,
          field7,
          field8,
          field9,
          field10,
          field11,
          field12,
          field13,
          field14,
        })
      )
      await downloadCsv(
        dataByMonth,
        {
          date: i18n.t('patientSatisfactionByYearMonth.workingDay'),
          doctorClinic: i18n.t('patientSatisfactionByYearMonth.clinic'),
          doctorDepartment: i18n.t('patientSatisfactionByYearMonth.department'),
          field1: i18n.t('patientSatisfactionByYearMonth.case1'),
          field2: i18n.t('patientSatisfactionByYearMonth.case2'),
          field3: i18n.t('patientSatisfactionByYearMonth.case3'),
          field4: i18n.t('patientSatisfactionByYearMonth.case4'),
          field5: i18n.t('patientSatisfactionByYearMonth.case5'),
          field6: i18n.t('patientSatisfactionByYearMonth.case6'),
          field7: i18n.t('patientSatisfactionByYearMonth.case7'),
          field8: i18n.t('patientSatisfactionByYearMonth.case8'),
          field9: i18n.t('patientSatisfactionByYearMonth.case9'),
          field10: i18n.t('patientSatisfactionByYearMonth.case10'),
          field11: i18n.t('patientSatisfactionByYearMonth.case11'),
          field12: i18n.t('patientSatisfactionByYearMonth.case12'),
          field13: i18n.t('patientSatisfactionByYearMonth.case13'),
          field14: i18n.t('patientSatisfactionByYearMonth.case14'),
        },
        `${i18n.t('staff.menuBar.patientSatisfactionResults')}${moment().format(
          'YYYYMMDDHHmm'
        )}.csv`
      )
    } catch (error) {
      popup.error(i18n.t('main.downloadFileError'))
    }
  }
  return (
    <PatientSatisfactionByMonthScene
      i18n={i18n}
      doctorId={id}
      date={currentDate}
      history={history}
      doctorIncentiveReports={data.doctorIncentiveReports}
      doctorName={doctorName}
      download={download}
      datePresent={date}
    />
  )
}

export default PatientSatisfactionByMonth
