import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { HOLIDAY_URL, TYPE_CREATE } from '@medical/constant'
import {
  SHOW_MONEY_FIELDS,
  STAFF_ADD_COMMENT,
  STAFF_DELETE_ALL_COMMENT,
  STAFF_DELETE_AS_GROUP,
  STAFF_DELETE_COMMENT,
  STAFF_UPDATE_AS_GROUP,
  STAFF_UPDATE_CONFIRMED_SHIFTS_STATUS,
} from '@medical/constant/permissions'
import { useAxios } from '@medical/hooks'
import {
  checkStaffPermission,
  clinicalDepartmentNames,
  combineNames,
  findNameOrEmail,
  splitLogic,
  thisRangeHolidays,
  sortAndFilterByClinicOrder,
} from '@medical/libs'
import { CREATE_ACTIVITY } from '@medical/pages/doctor/AvailableShift/AvailableShift.graphql'
import { SCHEDULE_AVAILABLESHIFT_CONNECTION } from '@medical/pages/staff/AvailableShiftGroupList/AvailableShiftGroupList.graphql'
import {
  convertBooleanToDay,
  convertDayInMonthToMatrix,
} from '@medical/pages/staff/RecruitmentScheduleShift/CreateRecruitmentScheduleShift/function'
import { useCustom } from '@medical/provider/context'
import Router from '@medical/routes/router'
import queryString from 'query-string'
import { pathOr } from 'ramda'
import React, { useEffect } from 'react'
import { activityLogHistories } from '@medical/libs/activityLogHistory'
import {
  ACTIVITIES_HISTORY,
  DELETE_AS_GROUP_BY_ID,
  STAFF_GET_AS_GROUP_BY_ID,
  STAFF_UPDATE_AS_GROUP_BY_ID,
} from './DetailScheduleShiftGroup.graphql'
import DetailScheduleShiftGroupScene from './DetailScheduleShiftGroupScene'
import { LinkMail } from './templateEmail'
import moment from 'moment'

const DetailScheduleShiftGroup = ({
  history,
  match: {
    params: { scheduledShiftGroupId },
  },
  location: { search },
  closeModal,
}) => {
  const activities = [
    {
      value: 'UPDATED_CONFIRMED_SHIFTS_PUBLISHED',
      description: '掲載',
    },
    {
      value: 'UPDATED_CONFIRMED_SHIFTS_NOT_PUBLISHED',
      description: '未掲載',
    },
  ]
  const updateActivities = [
    {
      value: 'CREATE_FIXED_SHIFTS',
      description: '個別確定シフト作成',
    },
    {
      value: 'UPDATE_FIXED_SHIFTS',
      description: '個別確定シフト更新',
    },
    {
      value: 'CREATE_FIXED_SHIFTS_GROUP',
      description: '定期確定シフト作成',
    },
    {
      value: 'UPDATE_FIXED_SHIFTS_GROUP',
      description: '定期確定シフト更新',
    },
  ]
  const [{ i18n, popup }] = useCustom()
  const isMoneyPermitted = checkStaffPermission({
    functionName: SHOW_MONEY_FIELDS,
  })
  const isUpdatePermitted = checkStaffPermission({
    functionName: STAFF_UPDATE_AS_GROUP,
  })
  const isDeletePermitted = checkStaffPermission({
    functionName: STAFF_DELETE_AS_GROUP,
  })
  const isAddCommentPermitted = checkStaffPermission({
    functionName: STAFF_ADD_COMMENT,
  })
  const isDeleteCommentPermitted = checkStaffPermission({
    functionName: STAFF_DELETE_COMMENT,
  })
  const isChangeConfirmedShiftsStatusPermitted = checkStaffPermission({
    functionName: STAFF_UPDATE_CONFIRMED_SHIFTS_STATUS,
  })
  const isDeleteAllComment = checkStaffPermission({
    functionName: STAFF_DELETE_ALL_COMMENT,
  })
  const {
    page = 1,
    rowsPerPage = 10,
    orderBy = 'startDate_ASC',
    fullname,
    email,
    name_contains = '',
  } = queryString.parse(search)
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  const reloadVariables = {
    first: first < 0 ? 10 : first,
    skip: skip < 0 ? 0 : skip,
    orderBy,
    where: {
      name_contains: name_contains.trim(),
      AvailableShift_some: {
        WorkSchedule: {
          Doctor: {
            AND: findNameOrEmail({ fullname, email }),
            deletedAt: null,
          },
        },
      },
    },
  }
  const [staffCreateActivity] = useMutation(CREATE_ACTIVITY)
  const [updateAvailableShiftGroup] = useMutation(STAFF_UPDATE_AS_GROUP_BY_ID, {
    refetchQueries: [
      {
        query: SCHEDULE_AVAILABLESHIFT_CONNECTION,
        variables: reloadVariables,
        fetchPolicy: 'network-only',
      },
    ],
    awaitRefetchQueries: true,
  })
  const [deleteAvailableShiftGroup] = useMutation(DELETE_AS_GROUP_BY_ID, {
    refetchQueries: [
      {
        query: SCHEDULE_AVAILABLESHIFT_CONNECTION,
        variables: reloadVariables,
        fetchPolicy: 'network-only',
      },
    ],
    awaitRefetchQueries: true,
  })
  const variables = {
    where: {
      id: scheduledShiftGroupId,
    },
    availableShiftsWhere2: {
      OR: [
        {
          deletedAt: null,
        },
        {
          WorkSchedule: {
            deletedStatus: 'WORKSCHEDULE_CANCEL',
            deletedAt_not: null,
          },
        },
      ],
    },
  }
  const { loading, error, data, refetch } = useQuery(STAFF_GET_AS_GROUP_BY_ID, {
    variables,
    fetchPolicy: 'network-only',
  })
  const { loading: acLoading, error: acError, data: acData } = useQuery(
    ACTIVITIES_HISTORY,
    {
      variables: {
        orderBy: 'createdAt_ASC',
        where: {
          scheduledAvailableShiftGroup: scheduledShiftGroupId,
        },
      },
      fetchPolicy: 'network-only',
    }
  )

  const {
    loading: holidaysLoading,
    data: holidaysData,
    error: holidaysError,
  } = useAxios({
    url: HOLIDAY_URL,
    params: {
      timeMin: moment()
        .startOf('month')
        .toISOString(),
      timeMax: moment()
        .add(18, 'month')
        .endOf('month')
        .toISOString(),
    },
  })

  useEffect(() => {
    refetch()
  })
  if (holidaysLoading || acLoading || loading) return <ProgressSpinner />

  if (error || holidaysError || acError)
    return <ErrorComponent error={error || holidaysError || acError} />

  if (data && data.scheduledAvailableShiftGroup) {
    const { scheduledAvailableShiftGroup } = data
    const matrixDate = JSON.parse(scheduledAvailableShiftGroup.dateMatrix)
    scheduledAvailableShiftGroup.listWeekDay =
      matrixDate && matrixDate[0] ? convertBooleanToDay(matrixDate[0]) : []
    scheduledAvailableShiftGroup.listWeekDay1 =
      matrixDate && matrixDate[1] ? convertBooleanToDay(matrixDate[1]) : []
    scheduledAvailableShiftGroup.listWeekDay2 =
      matrixDate && matrixDate[2] ? convertBooleanToDay(matrixDate[2]) : []
    scheduledAvailableShiftGroup.listWeekDay3 =
      matrixDate && matrixDate[3] ? convertBooleanToDay(matrixDate[3]) : []
    scheduledAvailableShiftGroup.listWeekDay4 =
      matrixDate && matrixDate[4] ? convertBooleanToDay(matrixDate[4]) : []
  }
  const {
    updateActivityHistories,
    postActivityHistories,
  } = activityLogHistories(
    acData.activityHistories,
    activities,
    updateActivities
  )
  const onSubmit = async (
    { functionName, splits, ...values },
    { setSubmitting, resetForm }
  ) => {
    const {
      date: submitDate,
      repeatSetting,
      listWeekDay,
      listWeekDay1,
      listWeekDay2,
      listWeekDay3,
      listWeekDay4,
      comment,
      updateType,
      doctorSubsidy,
    } = values
    const action = updateType.split('_')

    for (let i = 0; i < doctorSubsidy.length; i += 1) {
      delete doctorSubsidy[i].__typename
    }
    let successMessage
    if (action.includes('UPDATE')) {
      successMessage = i18n.t('staff.availableShift.updateSubmissionSuccess')
    } else {
      successMessage = i18n.t('staff.availableShift.removeSubmissionSuccess')
    }
    const splitComments = []
    values.splitComments.map(value => {
      if (value.text !== '' && value.type !== '') {
        splitComments.push({
          text: value.text,
          type: value.type,
          staff: {},
        })
      }

      return splitComments
    })
    try {
      let dataTime
      Object.keys(splits).forEach(key => {
        dataTime = {
          ...dataTime,
          ...splitLogic({
            submitDate: splits[key][0].startTime,
            splits: splits[key],
            key,
          }),
        }
      })
      const holidays = thisRangeHolidays({
        holidaysData: pathOr([], ['items'], holidaysData),
        startDate: values.startEndDate[0],
        endDate: values.startEndDate[1],
      })
      const isHolidays = []
      holidays.forEach(item => {
        isHolidays.push(item.date)
      })
      const dateMatrix = JSON.stringify(
        convertDayInMonthToMatrix(
          listWeekDay,
          listWeekDay1,
          listWeekDay2,
          listWeekDay3,
          listWeekDay4
        )
      )
      const mail = values.isSendMail
        ? `${values.mailContent.trim()}
      
${LinkMail()}`
        : null

      if (action.includes('UPDATE')) {
        await updateAvailableShiftGroup({
          variables: {
            scheduledAvailableShiftGroupId: scheduledShiftGroupId,
            doctorId:
              data.scheduledAvailableShiftGroup.availableShifts[0].workSchedule
                .doctor.id,
            isHolidays,
            repeatSetting,
            dateMatrix,
            comment,
            dataTime,
            updateType,
            staffComments: splitComments,
            mailContent: mail,
            doctorSubsidy: values.doctorSubsidy,
            isHolidayRepeat: values.isHolidayRepeat,
            data: {
              name: values.name,
              startDate: values.startEndDate[0].split('Z')[0],
              endDate: values.startEndDate[1].split('Z')[0],
              isAvailableOnMonday:
                repeatSetting === TYPE_CREATE.DAILY ? true : values.isMonday,
              isAvailableOnTuesday:
                repeatSetting === TYPE_CREATE.DAILY ? true : values.isTuesday,
              isAvailableOnWednesday:
                repeatSetting === TYPE_CREATE.DAILY ? true : values.isWednesday,
              isAvailableOnThursday:
                repeatSetting === TYPE_CREATE.DAILY ? true : values.isThursday,
              isAvailableOnFriday:
                repeatSetting === TYPE_CREATE.DAILY ? true : values.isFriday,
              isAvailableOnSaturday:
                repeatSetting === TYPE_CREATE.DAILY ? true : values.isSaturday,
              isAvailableOnSunday:
                repeatSetting === TYPE_CREATE.DAILY ? true : values.isSunday,
              isAvailableOnHoliday:
                repeatSetting === TYPE_CREATE.DAILY ? true : values.isDayoff,
              isAvailableOnWeekdayHoliday: values.isDayOnWeekday,
            },
            availableShiftEditedList: values.availableShiftEditedList,
            isPublished: values.isPublished,
          },
        })
      }
      if (action.includes('DELETE')) {
        history.push({
          pathname: Router.staffAvailableShiftGroupList,
        })
        await deleteAvailableShiftGroup({
          variables: {
            scheduledAvailableShiftGroupId: scheduledShiftGroupId,
            doctorEmail: values.doctorEmail,
            mailContent: mail,
          },
        })
        staffCreateActivity({
          variables: {
            activity: 'DELETE_FIXED_SHIFTS_GROUP',
            details: values.name,
            doctorId:
              data.scheduledAvailableShiftGroup.availableShifts[0].workSchedule
                .doctor.id,
          },
        })
      }
      popup.success(successMessage)
      setSubmitting(false)
      resetForm()
      closeModal()
      // refetch()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }
  const { clinicalDepartments, departments } = data
  const departmentNames = clinicalDepartmentNames({
    departments: departments,
  })
  const sortedClinicalDepartments = sortAndFilterByClinicOrder({
    clinicalDepartments,
  })
  const {
    doctor,
  } = data.scheduledAvailableShiftGroup.availableShifts[0].workSchedule
  return (
    <DetailScheduleShiftGroupScene
      i18n={i18n}
      onSubmit={onSubmit}
      isUpdatePermitted={isUpdatePermitted}
      isDeletePermitted={isDeletePermitted}
      scheduledAvailableShiftGroup={data.scheduledAvailableShiftGroup}
      // availableShift={availableShift}
      availableShiftList={data.scheduledAvailableShiftGroup.availableShifts}
      // activitiesHistory={acData.activityHistories}
      activitiesHistory={postActivityHistories}
      postActivitiesHistory={updateActivityHistories}
      staffComments={data.scheduledAvailableShiftGroup.staffComments}
      // departmentNames={departmentNames}
      clinicalDepartments={combineNames({
        clinicalDepartments: sortedClinicalDepartments,
        departmentNames,
      })}
      doctor={doctor}
      isAddCommentPermitted={isAddCommentPermitted}
      isDeleteCommentPermitted={isDeleteCommentPermitted}
      staffMe={data.staffMe}
      scheduledShiftGroupId={scheduledShiftGroupId}
      isCreateWorkSchedule
      isMoneyPermitted={isMoneyPermitted}
      holidaysData={holidaysData}
      popup={popup}
      isChangeConfirmedShiftsStatusPermitted={
        isChangeConfirmedShiftsStatusPermitted
      }
      isDeleteAllComment={isDeleteAllComment}
    />
  )
}

export default DetailScheduleShiftGroup
