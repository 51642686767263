import './style.css'

import Router from '@medical/routes/router'
import { Col, Row } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

const CheckShiftWorkLayout = () => (
  <>
    <p className='check-shift-title'>シフトを確認する</p>

    <Row justify='space-between'>
      <Col
        span={24}
        lg={10}
        xs={10}
        md={10}
        offset={2}
        className='check-shift-item-col'
        style={{ margin: 'auto' }}
      >
        <Link
          style={{
            display: 'flex',

            flexDirection: 'column',

            justifyContent: 'center',
          }}
          to={Router.doctorAcceptedWorkSchedulesListWithoutYearMonth}
          className='check-shift-item'
        >
          <p className='check-shift-title-item'>次回出勤予定日を確認する</p>
          <h2 className='check-shift-content-item'>確定シフト一覧</h2>
        </Link>
      </Col>

      <Col
        span={24}
        lg={10}
        xs={10}
        md={10}
        offset={2}
        className='check-shift-item-col'
        style={{ margin: 'auto' }}
      >
        <Link
          style={{
            display: 'flex',

            flexDirection: 'column',

            justifyContent: 'center',
          }}
          to={Router.doctorRequestedWorkSchedulesListWithoutYearMonth}
          className='check-shift-item'
        >
          <p className='check-shift-title-item'>承認待ちのシフトを確認する</p>
          <h2 className='check-shift-content-item'>応募中シフト一覧</h2>
        </Link>
      </Col>

      {/* <Col
        span={24}
        lg={10}
        xs={12}
        md={12}
        offset={2}
        style={{ height: '180px' }}
        className='check-shift-item-col'
      >
        <Link
          style={{
            display: 'flex',

            flexDirection: 'column',

            justifyContent: 'center',

          }}
          to={Router.doctorTransportReportsWithoutYearMonth}
          className='check-shift-item'
        >
          <p className='check-shift-title-item c'>
            勤務前に交通費経路登録お済みですか？
          </p>

          <h2 className='check-shift-content-item'>通勤経路新規・変更登録</h2>
        </Link>
      </Col>

      <Col
        span={24}
        lg={10}
        xs={12}
        md={12}
        offset={2}
        style={{ height: '180px' }}
        className='check-shift-item-col'
      >
        <Link
          style={{
            display: 'flex',

            flexDirection: 'column',

            justifyContent: 'center',

          }}
          to={Router.doctorWaitingRecruitment}
          className='check-shift-item'
        >
          <p className='check-shift-title-item'>
            自分の働ける時間をリクエスト！ 募集が出たら通知を受け取れる
          </p>

          <h2 className='check-shift-content-item check-shift-content-item-4' >募集待ち登録</h2>
        </Link>
      </Col> */}
    </Row>

    <Row justify='space-between' style={{ marginTop: '20px' }}>
      <Col
        span={24}
        lg={10}
        xs={10}
        md={10}
        offset={2}
        className='check-shift-item-col'
        style={{ margin: 'auto' }}
      >
        <Link
          style={{
            display: 'flex',

            flexDirection: 'column',

            justifyContent: 'center',
          }}
          to={Router.doctorTransportRegister}
          className='check-shift-item'
        >
          <p className='check-shift-title-item c'>
            ご勤務前に必ずご確認ください！
          </p>

          <h2 className='check-shift-content-item check-shift-content-item-3'>
            通勤経路新規・変更登録
          </h2>
        </Link>
      </Col>

      <Col
        span={24}
        lg={10}
        xs={10}
        md={10}
        offset={2}
        className='check-shift-item-col'
        style={{ margin: 'auto' }}
      >
        <Link
          style={{
            display: 'flex',

            flexDirection: 'column',

            justifyContent: 'center',
          }}
          to={Router.doctorWaitingRecruitment}
          className='check-shift-item'
        >
          <p className='check-shift-title-item check-shift-title-item-tow-line'>
            自分の働ける時間をリクエスト！
          </p>

          <h2 className='check-shift-content-item check-shift-content-item-4'>
            募集待ち登録
          </h2>
        </Link>
      </Col>
    </Row>
  </>
)

export default CheckShiftWorkLayout
