import React, { useContext } from 'react'
import { Progress, Card } from 'antd'
import { SocketContext } from '@medical/provider/socket'

export const ProgressBar = ({
  i18n,
  progress,
  removeProgress,
}) => {
  const socket = useContext(SocketContext)
  return (
    <div
      className='list-progress'
      style={{
        display: Object.values(progress).length > 0 ? 'unset' : 'none',
      }}
    >
      {Object.values(progress).length > 0 &&
        Object.values(progress).map(
          item =>
            (item.percent >= 0 || item.msg) && (
              <div key={item.progress}>
                <Card
                  title={`${i18n.t('main.confirmation')}: ${item.label}`}
                  extra={
                    <span
                      onClick={() => {
                        removeProgress(item.progress)
                        socket.off(item.progress)
                      }}
                      style={{ cursor: 'pointer' }}
                      className='pi pi-times'
                    ></span>
                  }
                >
                  {!item.msg ? (
                    <Progress percent={parseInt(item.percent)} />
                  ) : (
                    item.msg
                  )}
                </Card>
              </div>
            )
        )}
    </div>
  )
}
