import BreadCrumb from '@medical/components/Breadcrumb'
import Router from '@medical/routes/router'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Paginator } from 'primereact/paginator'
import queryString from 'query-string'
import React from 'react'
import { Link } from 'react-router-dom'

const PatientSatisfactionResultsScene = ({
  i18n,
  edges,
  fullname,
  email,
  rowsPerPage,
  onPageChange,
  count,
  page,
}) => (
  <React.Fragment>
    <div className='staff-header'>
      <div className='staff-title'>
        {i18n.t('staff.menuBar.patientSatisfactionResults')}
        <BreadCrumb
          items={[
            { title: i18n.t('staff.menuBar.patientSatisfactionResults') },
          ]}
        />
      </div>
    </div>
    <div className='container is-medium box'>
      <Formik
        initialValues={{
          fullname,
          email,
        }}
        render={formikProps => {
          const { values, handleBlur, handleChange } = formikProps
          return (
            <div className='search-parent'>
              <InputText
                name='fullname'
                value={values.fullname}
                placeholder={i18n.t('main.fullname')}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{ width: '200px', marginRight: '20px' }}
              />
              <InputText
                name='email'
                value={values.email}
                placeholder={i18n.t('main.email')}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{ width: '200px', marginRight: '20px' }}
              />
              <Link
                to={{
                  pathname: Router.staffPatientSatisfactionResults,
                  search: queryString.stringify({
                    rowsPerPage,
                    fullname: values.fullname,
                    email: values.email,
                  }),
                }}
              >
                <Button label={i18n.t('main.search')} icon='pi pi-search' />
              </Link>
              {fullname || email ? (
                <Link
                  to={{
                    pathname: Router.staffPatientSatisfactionResults,
                    search: queryString.stringify({
                      rowsPerPage,
                    }),
                  }}
                >
                  <Button
                    icon='pi pi-times'
                    className='p-button-secondary'
                    style={{ marginLeft: '10px' }}
                  />
                </Link>
              ) : null}
            </div>
          )
        }}
      />

      <Formik
        render={formikProps => (
          <div>
            <table className='table'>
              <thead>
                <tr>
                  <th width='12%'>
                    {i18n.t('patientSatisfactionResults.fullname')}
                  </th>
                  <th width='15%'>
                    {i18n.t('patientSatisfactionResults.email')}
                  </th>
                  <th width='15%'>
                    {i18n.t('patientSatisfactionResults.lastUpdated')}
                  </th>
                  <th width='7%'>
                    {i18n.t('patientSatisfactionResults.detail')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {edges.map(({ node }) => (
                  <tr key={node.id}>
                    <td>{`${node.lastname} ${node.firstname}`}</td>
                    <td>
                      <a href={`mailto:${node.email}`}>{node.email}</a>
                    </td>
                    <td>
                      {moment(node.updatedAt).format('YYYY年MM月DD日 HH:mm')}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <Link
                        to={Router.get(Router.staffPatientSatisfaction, {
                          id: node.id,
                          year: moment().year(),
                          month: moment().month() + 1,
                          date: '',
                        })}
                      >
                        <Button icon='pi pi-info' />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Paginator
              rows={rowsPerPage}
              totalRecords={count}
              first={(page - 1) * rowsPerPage}
              onPageChange={onPageChange}
              leftContent={
                <div className='paginator-total'>
                  {i18n.t('main.paginationTotal')}: {count}
                </div>
              }
            />
          </div>
        )}
      />
    </div>
  </React.Fragment>
)

export default PatientSatisfactionResultsScene
