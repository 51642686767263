import jaLocale from '@fullcalendar/core/locales/ja'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import { dateValidation } from '@medical/libs'
import Router from '@medical/routes/router'
import moment from 'moment'
import { Button } from 'primereact/button'
import { FullCalendar } from 'primereact/fullcalendar'
import React from 'react'
import { Link } from 'react-router-dom'

const ScheduleScene = ({ date, events, eventClick, calendar }) => {
  const prevMonth = moment(date).subtract(1, 'month')
  const nextMonth = moment(date).add(1, 'month')
  const isPreviousDisabled = dateValidation({
    year: prevMonth.year(),
    month: prevMonth.month() + 1,
    day: prevMonth.date(),
  })

  const isNextDisabled = dateValidation({
    year: nextMonth.year(),
    month: nextMonth.month(),
    day: nextMonth.date(),
  })
  return (
    <div className='container'>
      <div className='box'>
        <div className='schedule-calendar'>
          <div className='calendar-container'>
            <FullCalendar
              ref={calendar}
              events={events}
              locale={jaLocale}
              options={{
                locales: [jaLocale],
                locale: process.env.REACT_APP_LOCALE,
                plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
                defaultView: 'dayGridMonth',
                defaultDate: date.toDate(),
                header: {
                  left: null,
                  center: 'title',
                  right: null,
                },
                editable: true,
                eventClick,
              }}
            />
            <div className='calendar-control'>
              <Link
                to={Router.get(Router.doctorSchedules, {
                  year: prevMonth.year(),
                  month: prevMonth.month() + 1,
                })}
                onClick={
                  isPreviousDisabled ? e => e.preventDefault() : undefined
                }
              >
                <Button
                  icon='pi pi-chevron-left'
                  style={{ fontSize: '14px' }}
                  disabled={isPreviousDisabled}
                />
              </Link>
              <Link
                to={Router.get(Router.doctorSchedules, {
                  year: nextMonth.year(),
                  month: nextMonth.month() + 1,
                })}
                onClick={isNextDisabled ? e => e.preventDefault() : undefined}
              >
                <Button
                  icon='pi pi-chevron-right'
                  style={{ fontSize: '14px' }}
                  disabled={isNextDisabled}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScheduleScene
