import gql from 'graphql-tag'
export const GET_INCENTIVE_SETTING = gql`
  query IncentivesConnection(
    $orderBy: IncentiveOrderByInput
    $skip: Int
    $first: Int
    $where: IncentiveWhereInput
  ) {
    incentivesConnection(
      orderBy: $orderBy
      skip: $skip
      first: $first
      where: $where
    ) {
      aggregate(where: $where) {
        count
      }
      edges {
        node {
          id
          type
          title
          applyWith
          startTime
          endTime
          amount
          createdAt
        }
      }
    }
  }
`

export const GET_DOCTORLIST = gql`
  query Incentive($where: IncentiveWhereUniqueInput!, $incentiveDetailsWhere2: IncentiveDetailWhereInput) {
    incentive(where: $where) {
      id
      incentiveDetails(where: $incentiveDetailsWhere2) {
        id
        doctor {
          fullName
          email
          id
          workPattern
        }
      }
    }
  }
`

export const DELETE_INCENTIVE_SETTING = gql`
  mutation STAFF_DELETE_INCENTIVE($incentiveId: ID!) {
    staffDeleteIncentive(incentiveId: $incentiveId)
  }
`
