import './style.css'

import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { onChangePageAnt } from '@medical/libs/onPageChange'
import { useCustom } from '@medical/provider/context'
import queryString from 'query-string'
import React from 'react'

import { DOCTOR_ANNOUNCES } from './News.graphql'
import NewsScene from './NewsScene'

const News = ({ history, location: { search } }) => {
  const {
    page = 1,
    rowsPerPage = 10,
    orderBy = 'createdAt_DESC',
  } = queryString.parse(search)
  const [{ i18n }] = useCustom()
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  const variables = {
    where: {
      isPublished: true,
    },
    first: first < 0 ? 5 : first,
    skip: skip < 0 ? 0 : skip,
    orderBy,
  }
  const { loading, error, data } = useQuery(DOCTOR_ANNOUNCES, {
    variables,
  })

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const {
    announcesConnection: {
      edges,
      aggregate: { count },
    },
  } = data

  return (
    <NewsScene
      i18n={i18n}
      edges={edges}
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      history={history}
      onPageChange={onChangePageAnt(history, search)}
    />
  )
}

export default News
