import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { HOLIDAY_URL } from '@medical/constant'
import { useAxios } from '@medical/hooks'
import {
  clinicalDepartmentNames,
  dateValidation,
  thisMonthHolidays,
  sortAndFilterByClinicOrder,
} from '@medical/libs'
import { CREATE_ACTIVITY } from '@medical/pages/doctor/AvailableShift/AvailableShift.graphql'
import { useCustom } from '@medical/provider/context'
import Router from '@medical/routes/router'
import moment from 'moment'
import { pathOr } from 'ramda'
import React, { useEffect, useMemo, useState } from 'react'
import { Redirect } from 'react-router-dom'

import {
  CLINICS_MONTHLY_REPORT,
  REGION_QUERY,
  WORK_SCHEDULES_CONNECTION,
} from './MonthlyAvailableShift.graphql'
import MonthlyAvailableShiftScene from './MonthlyAvailableShiftScene'

const MonthlyAvailableShift = ({
  match: {
    path,
    params: { year = moment().year(), month = moment().month() },
  },
}) => {
  const currentDate = useMemo(
    () =>
      moment()
        .year(year)
        .month(month - 1)
        .startOf('month')
        .toISOString(),
    [year, month]
  )
  const [{ i18n }] = useCustom()
  const [clinicIds, setStateClinicIds] = useState(
    JSON.parse(localStorage.getItem('monthly_clinics')) || []
  )
  const [
    clinicalDepartmentNamesFilter,
    setStateClinicalDepartmentNames,
  ] = useState(
    JSON.parse(localStorage.getItem('monthly_clinicalDepartments')) || []
  )
  const [regionIds, setStateRegionIds] = useState(
    JSON.parse(localStorage.getItem('monthly_regions')) || []
  )

  const [staffCreateActivity] = useMutation(CREATE_ACTIVITY)

  useEffect(() => {
    staffCreateActivity({
      variables: {
        activity: 'STAFF_DISPLAYED_SHIFT_LIST',
      },
    })
  }, [staffCreateActivity])

  const {
    loading: wsLoading,
    error: wsError,
    data: wsData,
    refetch,
  } = useQuery(WORK_SCHEDULES_CONNECTION, {
    variables: {
      where: {
        accepted: false,
        deletedStatus: null,
      },
    },
  })
  const { loading, error, data } = useQuery(CLINICS_MONTHLY_REPORT, {
    variables: {
      date: currentDate,
      where: {
        Clinic: {
          deletedAt: null,
        },
        isDisplay: true,
      },
    },
    fetchPolicy: 'network-only',
  })

  const {
    loading: holidayLoading,
    data: holidaysData,
    error: holidaysError,
  } = useAxios({
    url: HOLIDAY_URL,
    params: {
      timeMin: moment(currentDate)
        .startOf('month')
        .toISOString(),
      timeMax: moment(currentDate)
        .endOf('month')
        .toISOString(),
    },
    dependencies: currentDate,
  })

  const {
    loading: listRegionLoading,
    error: listRegionError,
    data: listRegionData,
  } = useQuery(REGION_QUERY, {
    variables: {
      deletedAt: null,
      orderBy: 'position_ASC'
    },
  })

  const holidays = useMemo(
    () =>
      thisMonthHolidays({
        date: currentDate,
        holidaysData: pathOr([], ['items'], holidaysData),
      }),
    [holidaysData, currentDate]
  )

  const isNewWS = useMemo(
    () => wsData?.workSchedulesConnection?.edges?.length,
    [wsData]
  )

  const departmentNames = useMemo(() => {
    if (data) {
      return clinicalDepartmentNames({
        departments: data?.departments,
      })
    }
    return undefined
  }, [data])

  useEffect(() => {
    refetch()
  }, [])

  const momentDate = moment(`${year} ${month} 01`, 'YYYY MM DD')
  if (
    (dateValidation({ year, month }) &&
      momentDate.isBefore(moment().subtract(1, 'years'), 'month')) ||
    [
      Router.staffMonthlyAvailableShiftsWithoutMonth,
      Router.staffMonthlyAvailableShiftsWithoutYearMonth,
    ].includes(path)
  )
    return (
      <Redirect
        to={Router.get(Router.staffMonthlyAvailableShifts, {
          year: moment().year(),
          month: moment().month() + 1,
        })}
      />
    )

  if (loading || holidayLoading || wsLoading || listRegionLoading)
    return <ProgressSpinner />
  if (error || holidaysError || wsError || listRegionError)
    return (
      <ErrorComponent
        error={error || holidaysError || wsError || listRegionError}
      />
    )

  const listRegions = listRegionData?.regions.map(item => ({
    id: item.id,
    region: item.name,
  }))

  const setRegionIds = ids => {
    const newRegionIds = ids.value.map(({ id }) => id)
    localStorage.setItem('monthly_regions', JSON.stringify(newRegionIds))
    setStateRegionIds(newRegionIds)
  }

  const setClinicIds = ids => {
    const newClinicIds = ids.value.map(({ id }) => id)
    localStorage.setItem(
      'monthly_clinics',
      JSON.stringify(newClinicIds)
    )
    setStateClinicIds(newClinicIds)
  }

  const getClinicalDepartmentId = clinicalDepartmentList => {
    if (clinicalDepartmentList && clinicalDepartmentList.length > 0) {
      const result = clinicalDepartmentList
        .map(i => {
          if (clinicalDepartmentNamesFilter.includes(i.name)) {
            return i.id
          }
        })
        .filter(id => id != null)
      if (result && result.length > 0) return result
      return []
    }
    return []
  }

  const setClinicalDepartmentNames = names => {
    const newClinicalDepartmentNames = names.value.map(({ name }) => name)
    localStorage.setItem(
      'monthly_clinicalDepartments',
      JSON.stringify(newClinicalDepartmentNames)
    )
    setStateClinicalDepartmentNames(newClinicalDepartmentNames)
  }

  const clinicalDepartmentIds = getClinicalDepartmentId(
    data?.clinicalDepartments || []
  )

  const clinicalDepartmentSort = sortAndFilterByClinicOrder({
    clinicalDepartments: data?.clinicalDepartments || [],
  })

  const mappedDataClinic = clinicalDepartmentSort.map(item => ({
    id: item.clinic.id,
    clinicName: item.clinic.name,
  }))

  const uniqueClinicName = [
    ...new Map(mappedDataClinic.map(item => [item[`id`], item])).values(),
  ]

  return (
    <MonthlyAvailableShiftScene
      i18n={i18n}
      currentDate={currentDate}
      clinicalDepartments={data.clinicalDepartments}
      holidays={holidays}
      departmentNames={departmentNames}
      isQueryRecruitmentUnpublished
      isNewWS={isNewWS}
      listRegions={listRegions}
      regionIds={regionIds}
      setRegionIds={setRegionIds}
      clinicIds={clinicIds}
      setClinicIds={setClinicIds}
      uniqueClinicName={uniqueClinicName}
      departmentNameList={data?.departments.map(item => {
        return {
          departmentName: item.description,
          name: item.value,
        }
      })}
      setClinicalDepartmentNames={setClinicalDepartmentNames}
      clinicalDepartmentNamesFilter={clinicalDepartmentNamesFilter}
      clinicalDepartmentIds={clinicalDepartmentIds}
    />
  )
}

export default MonthlyAvailableShift
