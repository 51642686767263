import React, { useEffect } from 'react'
import RecruitmentDoctorDetailScene from './RecruitmentDoctorDetailScene'
import { useCustom } from '@medical/provider/context'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { useMutation, useQuery } from '@apollo/react-hooks'
import {
  DOCTOR_RECRUITMENT_DOCTOR,
  STAFF_UPDATE_RECRUITMENT_DOCTOR,
} from './RecruitmentDoctorDetail.grapql'
import { checkStaffPermission, clinicalDepartmentNames } from '@medical/libs'
import { STAFF_UPDATE_RECRUITMENT_DOCTOR_PERMISSIONS } from '@medical/constant/permissions'

const RecruitmentDoctorDetail = ({
  closeModal,
  match: {
    params: { id },
  },
}) => {
  const [{ i18n, popup }] = useCustom()
  const isUpdatePermitted = checkStaffPermission({
    functionName: STAFF_UPDATE_RECRUITMENT_DOCTOR_PERMISSIONS,
  })
  const [staffUpdateRecruitmentDoctor] = useMutation(
    STAFF_UPDATE_RECRUITMENT_DOCTOR
  )
  const { loading, error, data, refetch } = useQuery(
    DOCTOR_RECRUITMENT_DOCTOR,
    {
      variables: {
        where: { id },
      },
    }
  )
  useEffect(() => {
    refetch()
  })
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  const { recruitmentDoctors, departments } = data
  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      let status = ''
      if (values.notAction) status = 'NOT_ACTION'
      else if (values.inAction) status = 'IN_ACTION'
      else status = 'COMPLETED'
      await staffUpdateRecruitmentDoctor({
        variables: {
          recruitmentDoctorId: id,
          status: status,
          staffMemo: values.staffMemo.trim(),
        },
      })
      popup.success(i18n.t('staff.updateClinic.submissionSuccess'))
      setSubmitting(false)
      resetForm()
      closeModal()
    } catch (err) {
      popup.error(err)
      setSubmitting(false)
    }
  }
  const departmentNames = clinicalDepartmentNames({
    departments,
  })
  return (
    <RecruitmentDoctorDetailScene
      i18n={i18n}
      recruitmentPost={recruitmentDoctors[0].recruitmentPostId}
      recruitmentDoctorData={recruitmentDoctors[0]}
      doctor={recruitmentDoctors[0].doctor}
      onSubmit={onSubmit}
      isUpdatePermitted={isUpdatePermitted}
      departmentNames={departmentNames}
    />
  )
}
export default RecruitmentDoctorDetail
