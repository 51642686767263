import gql from 'graphql-tag'

export const CREATE_WORK_SCHEDULE_CANCELLATION_LETTER = gql`
  mutation CREATE_WORK_SCHEDULE_CANCELLATION_LETTER(
    $isTickCountCancel: Boolean
    $reason: String!
    $reassignDoctorNote: String
    $reworkNote: String
    $comment: String
    $workScheduleId: ID!
  ) {
    staffCreateWorkScheduleCancellationLetter(
      isTickCountCancel: $isTickCountCancel
      reason: $reason
      reassignDoctorNote: $reassignDoctorNote
      reworkNote: $reworkNote
      comment: $comment
      workScheduleId: $workScheduleId
    ) {
      id
    }
  }
`
