import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import gql from 'graphql-tag'

export const DOCTOR_RECRUITMENT_POST = gql`
  query RecruitmentPost($where: RecruitmentPostWhereUniqueInput!) {
    recruitmentPost(where: $where) {
      id
      title
      content
      isPublished
      recruitmentDoctorType
      imageKey
      clinicalDepartment {
        id
        name
        extraName
        departmentCode
        isDisplay
        clinic {
          id
          name
          order
          imageKey
          address
          phoneNumber
          inquiryPhoneNumber
          isPublished
          imageKey
        }
      }
    }
    ...DEPARTMENTS_FRAGMENT
  }
  ${DEPARTMENTS_FRAGMENT}
`
