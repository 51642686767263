import i18n from '@medical/libs/I18n/i18n'

const types = [
  {
    value: 'TAXI',
    description: `${i18n.t('transportApplication.taxi')}`,
  },
  {
    value: 'BASE_TRANSFER',
    description: `${i18n.t('transportApplication.moveBases')}`,
  },
  {
    value: 'PARKING_FREE',
    description: `${i18n.t('transportApplication.parkingFree')}`,
  },
  {
    value: 'HIGHWAY',
    description: `${i18n.t('transportApplication.highway')}`,
  },
  {
    value: 'ACCOMMONDATION',
    description: `${i18n.t('transportApplication.accommodation')}`,
  },
  {
    value: 'OTHER',
    description: `${i18n.t('transportApplication.other')}`,
  }
]
export default types
