export const STAFF_PAGE_SCHEDULE_CANCEL =
  'staffPageWorkScheduleCancellationLetter'
export const STAFF_UPDATE_DOCTOR = 'staffUpdateDoctor'
export const STAFF_CREATE_DOCTOR = 'staffCreateDoctor'
export const STAFF_CREATE_STAFF = 'staffCreateStaff'
export const STAFF_UPDATE_STAFF = 'staffUpdateStaff'
export const STAFF_DELETE_STAFF = 'staffDeleteStaff'
export const STAFF_CREATE_SHIFT = 'createShift'
export const STAFF_ACCEPTED_WS = 'staffAcceptWorkSchedule'
export const STAFF_UPDATE_AS = 'staffUpdateAvailableShift'
export const STAFF_CREATE_WS = 'staffCreateWorkSchedule'
export const STAFF_DELETE_AS = 'staffDeleteAvailableShift'
export const STAFF_CREATE_ACCEPTED_WS = 'staffCreateAcceptedWorkSchedule'
export const STAFF_CREATE_AS_GROUP = 'staffCreateScheduledAvailableShiftGroup'
export const STAFF_UPDATE_AS_GROUP = 'staffUpdateScheduledAvailableShiftGroup'
export const STAFF_UPDATE_SCHEDULE_CANCEL =
  'staffUpdateWorkScheduleCancellationLetter'
export const STAFF_DELETE_AS_GROUP = 'staffDeleteScheduledAvailableShiftGroup'
export const STAFF_UPDATE_CLINIC = 'staffUpdateClinic'
export const STAFF_UPLOAD_SHIFT = 'staffUploadShift'
export const STAFF_REJECT_WS = 'staffRejectWorkSchedule'
export const STAFF_CANCEL_ACCEPTED_WS = 'staffCancelAcceptedWorkSchedule'
export const STAFF_CREATE_ANNOUNCE = 'staffCreateAnnounce'
export const STAFF_UPDATE_ANNOUNCE = 'staffUpdateAnnounce'
export const STAFF_DELETE_ANNOUNCE = 'staffDeleteAnnounce'
export const STAFF_CREATE_GROUP = 'staffCreateStaffGroup'
export const STAFF_UPDATE_GROUP = 'staffUpdateStaffGroup'
export const STAFF_DELETE_GROUP = 'staffDeleteStaffGroup'
export const STAFF_RESTORE_WS = 'staffRestoreWorkSchedule'
export const STAFF_UPDATE_WS = 'staffUpdateWorkSchedule'
export const STAFF_UPDATE_CONFIRMED_SHIFTS_STATUS =
  'staffUpdateConfirmedShiftsStatus'
export const STAFF_CHECK_SHIFT = 'staffCheckUploadShifts'
export const STAFF_ADD_COMMENT = 'staffAddComment'
export const STAFF_UPDATE_WORK_SCHEDULE_TYPE = 'staffUpdateWorkScheduleType'
export const STAFF_DELETE_COMMENT = 'staffDeleteComment'
export const STAFF_BLOCK_DOCTOR = 'staffBlockDoctor'
export const STAFF_PROCESS_DOCTOR_IDENTITY = 'staffProcessDoctorIdentity'
export const STAFF_UPDATE_DOCTOR_IDENTITY_NUMBER = 'staffUpdateDoctorIdentity'
export const STAFF_DELETE_DOCTOR_IDENTITY_PERMISSION =
  'staffDeleteDoctorIdentity'
export const STAFF_RESTORE_BLOCKED_DOCTOR = 'staffRestoreBlockedDoctor'
export const STAFF_CREATE_REPLY_PERMISSION = 'createConversationContent'
export const DOCTORS_FIELDS = 'showDoctorDownloadField'
export const WORK_SCHEDULE_DOWNLOAD_FIELDS = 'showWorkScheduleDownloadField'
export const SHOW_MONEY_FIELDS = 'showMoneyFields'
export const QUERY_WORK_SCHEDULES = 'staffPageWorkSchedulesList'
export const QUERY_ACCEPTED_WORK_SCHEDULES =
  'staffPageAcceptedWorkSchedulesList'
export const QUERY_AVAILABLE_SHIFTS = 'staffPageAvailableShiftsList'
export const UPDATE_MOVING_EXPENSES_REGISTRATION =
  'staffEditTransportationExpense'
export const STAFF_PAGE_TRANSPORTATION_EXPEMSES =
  'staffPageTransportationExpense'
export const STAFF_CREATE_HOURLY_WAGE_SCHEDULES =
  'staffCreateDefaultHourlyWageSchedule'
export const STAFF_UPDATE_HOURLY_WAGE_SCHEDULES =
  'staffUpdateDefaultHourlyWageSchedule'
export const STAFF_DELETE_HOURLY_WAGE_SCHEDULES =
  'staffDeleteDefaultHourlyWageSchedule'
export const STAFF_PAGE_ACTIVITY_LOG_LIST = 'staffPageActivityLogsList'
export const STAFF_UPDATE_RECRUITMENT_SHIFTS_STATUS =
  'staffUpdateRecruitmentShiftsStatus'
export const STAFF_UPDATE_SCHEDULE_AVAILABLE_SHIFTS =
  'staffUpdateScheduledAvailableShiftGroupRecruitment'
export const STAFF_DELETE_SCHEDULE_AVAILABLE_SHIFTS =
  'staffDeleteScheduledAvailableShiftGroupRecruitment'
export const STAFF_CREATE_SCHEDULE_AVAILABLE_SHIFTS =
  'staffCreateScheduledAvailableShiftGroupRecruitment'
export const STAFF_VIEW_PAGE_SCHEDULE_AVAILABLE_SHIFTS =
  'staffPageScheduledAvailableShiftGroupRecruitment'
export const STAFF_EDIT_CAREER_HISTORY_DOCTOR_PROFILE =
  'staffEditCareeHistoryDoctorProfile'
export const STAFF_EDIT_OBTAIN_DOCTOR_LICENSE_YEAR_DOCTOR_PROFILE =
  'staffEditObtainDoctorLicenseYearDoctorProfile'
export const STAFF_EDIT_ADDRESS_DOCTOR_PROFILE = 'staffEditAddressDoctorProfile'
export const STAFF_EDIT_SKILL_INFORMATION_DOCTOR_PROFILE =
  'staffEditSkillInformationDoctorProfile'
export const STAFF_EDIT_MEDICAL_RECORD_DOCTOR_PROFILE =
  'staffEditMedicalRecordDDoctorProfile'
export const STAFF_EDIT_PROFESSIONAL_DESCRIPTION_DOCTOR_PROFILE =
  'staffEditProfessionalDescriptionDoctorProfile'
export const STAFF_EDIT_JOIN_BACKGROUND_DOCTOR_PROFILE =
  'staffEditJoinBackgroundDoctorProfile'
export const STAFF_EDIT_INTRODUCED_BY_DOCTOR_PROFILE =
  'staffEditIntroducedByDoctorProfile'
export const STAFF_EDIT_STAFFMEMO_DOCTOR_PROFILE =
  'staffEditStaffMemoDoctorProfile'
export const STAFF_PAGE_STAFFMEMO_DOCTOR_PROFILE =
  'staffPageStaffMemoDoctorProfile'
export const STAFF_EDIT_ADJUST_HOURLYWAGE_RANGE_DOCTOR_PROFILE =
  'staffEditAdjustHourlyWageRangeDoctorProfile'
export const STAFF_EDIT_GRADUATIONUNIVERSITY_DOCTOR_PROFILE =
  'staffEditGraduationUniversityDoctorProfile'
export const STAFF_EDIT_AVAILABLE_SHIFT_THE_PAST =
  'staffEditAvailableShiftThePast'
export const STAFF_UPLOAD_SHIFT_CSV = 'staffUploadIncentiveReportCsv'
export const STAFF_CHECK_SHIFT_CSV = 'staffCheckUploadIncentiveReportCsv'
export const STAFF_CREATE_INCENTIVE_PERMISSIONS = 'staffCreateIncentive'
export const STAFF_UPDATE_INCENTIVE_PERMISSIONS = 'staffUpdateIncentive'
export const STAFF_DELETE_INCENTIVE_PERMISSIONS = 'staffDeleteIncentive'
export const STAFF_UPDATE_INCENTIVE_DETAIL_PERMISSIONS =
  'staffUpdateIncentiveDetail'
export const STAFF_DELETE_INCENTIVE_DETAIL_PERMISSIONS =
  'staffDeleteIncentiveDetail'
export const STAFF_PAGE_INCENTIVE_SETTING = 'staffPageIncentiveSetting'
export const STAFF_DOCTOR_SKILL_CREATE = 'staffCreateSkill'
export const STAFF_DOCTOR_SKILL_UPDATE = 'staffUpdateSkill'
export const STAFF_DOCTOR_SKILL_DELETE = 'staffDeleteSkill'
export const STAFF_PAGE_AVAILABLE_SHIFT_PUBLISHED =
  'staffPageAvailableShiftPublished'
export const STAFF_CREATE_DOCTOR_DELETE_REQUEST_PERMISSION =
  'staffCreateDoctorDeleteRequest'
export const STAFF_CONFIRM_DOCTOR_DELETE_REQUEST_PERMISSION =
  'staffHardDeleteDoctor'
export const STAFF_PAGE_DOCTOR_DELETE_REQUEST =
  'staffPageDoctorsDeleteRequestList'
export const STAFF_UPLOAD_DOCTOR_CSV = 'staffUploadDoctorCsv'
export const STAFF_UPLOAD_DOCTOR_CSV_UPDATE = 'staffUploadDoctorCsvUpdate'
export const STAFF_CHECK_DOCTOR_CSV = 'staffCheckUploadDoctorCsv'
export const STAFF_PROCESS_WORK_OUTSIDE = 'staffAcceptOrRejectWorkOutside'
export const STAFF_UPDATE_OVERTIME = 'staffAcceptWorkScheduleOverTime'
export const STAFF_PAGE_OVERTIME = 'staffPageWorkScheduleOverTime'
export const STAFF_CREATE_PORTAL = 'staffCreatePortal'
export const STAFF_UPDATE_PORTAL = 'staffUpdatePortal'
export const STAFF_DELETE_PORTAL = 'staffDeletePortal'
export const STAFF_CREATE_QUESTION = 'staffCreateQuestion'
export const STAFF_UPDATE_QUESTION = 'staffUpdateQuestion'
export const STAFF_DELETE_QUESTION = 'staffDeleteQuestion'
export const STAFF_CREATE_CATEGORY = 'staffCreateCategory'
export const STAFF_UPDATE_CATEGORY = 'staffUpdateCategory'
export const STAFF_DELETE_CATEGORY = 'staffDeleteCategory'
export const STAFF_VIEW_HOURLY_WAGE_PAGE = 'staffPageDoctorDefaultHourlyWage'
export const STAFF_VIEW_HOURLY_WAGE_PAGE_RECRUITMENT =
  'defaultHourlyWageSchedules'
export const STAFF_CREATE_DEFAULT_HOULRY_WAGE =
  'staffCreateDoctorDefaultHourlyWage'
export const STAFF_UPDATE_DEFAULT_HOULRY_WAGE = 'staffUpdateDefaultHourlyWage'
export const STAFF_DELETE_DEFAULT_HOULRY_WAGE = 'staffDeleteDefaultHourlyWage'
export const STAFF_PAGE_RECRUITMENT_POST = 'staffPageRecruitmentPosts'
export const STAFF_PAGE_RECRUITMENT_DOCTOR = 'staffPageRecruitmentDoctor'
export const STAFF_CREATE_RECRUITMENT_POST = 'staffCreateRecruitmentPost'
export const STAFF_UPDATE_RECRUITMENT_POST = 'staffUpdateRecruitmentPost'
export const STAFF_DELETE_RECRUITMENT_POST = 'staffDeleteRecruitmentPost'
export const STAFF_UPDATE_RECRUITMENT_DOCTOR_PERMISSIONS =
  'staffUpdateRecruitmentDoctor'
export const STAFF_PAGE_DOCTORS = 'staffPageDoctors'
export const STAFF_PAGE_INCENTIVE_REPORT = 'staffPageDoctorIncentiveReport'
export const STAFF_PAGE_MY_NUMBER = 'staffPageDoctorMyNumbers'
export const STAFF_PAGE_SALARY_CONFIRM = 'staffPageMonthlySalaryConfirmation'
export const EDIT_DOCTOR_LICENSE_NUMBER = 'staffEditDoctorMedicalLicenseNumber'
export const VIEW_DOCTOR_LICENSE_NUMBER = 'staffViewDoctorMedicalLicenseNumber'
export const VIEW_MEDICAL_RECORD_DOCTOR_PROFILE =
  'staffViewMedicalRecordDoctorProfile'
export const STAFF_CLINIC_TIME_SHEET = 'clinicTimeSheet'
export const STAFF_UPSERT_DOCTOR_FILE = 'staffUpsertDoctorFile'
export const STAFF_CHECK_HOURLYWAGE_CSV = 'staffCheckUploadCSVDefaultHourlyWage'
export const STAFF_HOURLYWAGE_CSV = 'staffUploadDefaultHourlyWageCSV'
export const STAFF_BLOCK_DOCTOR_MAIL = 'staffBlockDoctorMail'
export const STAFF_SET_MAX_PRICE_GASOLINE = 'staffSetMaxPriceGasoline'
export const STAFF_VIEW_HOURLY_WAGE_RESERVATION = 'staffPageHourlyWageReservation'
export const STAFF_EDIT_HOURLY_WAGE_RESERVATION = 'staffEditHourlyWageReservation'
export const STAFF_DELETE_ALL_COMMENT='staffDeleteAllComment'
export const STAFF_CREATE_REGION = 'staffCreateRegion'
export const STAFF_UPDATE_REGION = 'staffUpdateRegion'
export const STAFF_DELETE_REGION = 'staffDeleteRegion'
export const STAFF_VIEW_PAGE_DOCTOR_CONSULTATION_TIME = 'staffViewPageDoctorConsultationTime'
export const STAFF_VIEW_COUNT_PAGE_WORK_SCHEDULE_DETAIL='staffViewCountPageWorkScheduleDetail'
export const STAFF_VIEW_PAGE_DOCTOR_OUTPUT_INSURANCE = 'staffViewPageDoctorOutputInsurance'