import './MovingExpensesManagementStyle.css'

import { useApolloClient } from '@apollo/react-hooks'
import { ConfirmationModal } from '@medical/components'
import BreadCrumb from '@medical/components/Breadcrumb'
import numeralCurrency from '@medical/libs/numeralCurrency'
import Router from '@medical/routes/router'
import { DatePicker, InputNumber } from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import { ErrorMessage, Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import React from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'

import { GET_GASOLINE_PRICE } from './ListMovingExpensesManagement.graphql'

const MovingExpensesByCarDetailScene = ({
  i18n,
  data,
  onSubmit,
  dataResponse,
  address,
  popup,
}) => {
  // const disabledDate = current => {
  //   const dateNow = moment().get('date')
  //   let minDay = moment().startOf('month')
  //   const maxDay = moment().add(3, 'month')
  //   if (dateNow <= 10) {
  //     minDay = moment()
  //       .subtract(1, 'month')
  //       .startOf('month')
  //     return !minDay.isSameOrBefore(current) || !maxDay.isSameOrAfter(current)
  //   }
  //   return !maxDay.isSameOrAfter(current) || !minDay.isSameOrBefore(current)
  // }
  const client = useApolloClient()
  const validationSchema = Yup.object().shape({
    splits: Yup.array().of(
      Yup.object().shape({
        distance: Yup.number()
          .typeError(i18n.t('validation.hourlyWage.number'))
          .required(
            `${i18n.t('validation.oneWayDistance')}${i18n.t(
              'validation.date.required'
            )}`
          ),
        applicableDate: Yup.string().required(
          `適用開始日${i18n.t('validation.required')}`
        ),
      })
    ),
  })
  const getFee = item => {
    const fee = item.distance * item.gasolinePrice
    return fee + (item.highwayFare || 0)
  }
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{
        confirm: false,
        splits: dataResponse || [],
      }}
      onSubmit={onSubmit}
      render={formikProps => {
        const { values, setFieldValue, validateForm, setTouched } = formikProps
        return (
          <>
            <div className='staff-header'>
              <div className='staff-title'>
                {i18n.t('main.movingExpenses.routeDetailsTitle')}
                <BreadCrumb
                  items={[
                    {
                      to: Router.get(
                        Router.staffManageDoctorTransportByPersonalCar
                      ),
                      title: '医師別マイカー通勤管理',
                    },
                    { title: i18n.t('main.movingExpenses.routeDetailsTitle') },
                  ]}
                />
              </div>
              <div className='staff-buttons' />
            </div>
            <div className='container is-max'>
              <div className='box'>
                <div
                  className='search-parent'
                  style={{ justifyContent: 'end', width: '100%' }}
                >
                  <Link
                    to={Router.get(
                      Router.staffManageDoctorTransportByPersonalCar
                    )}
                  >
                    <Button
                      className='cancel-button'
                      label='戻る'
                      style={{ marginRight: '20px' }}
                    />
                  </Link>
                  <Button
                    className='btn-main-color'
                    label={i18n.t('staff.movingExpensesDetail.updateDistance')}
                    onClick={() => {
                      validateForm().then(response => {
                        if (!Object.keys(response).length) {
                          setFieldValue('confirm', true)
                        } else {
                          setTouched(response)
                        }
                      })
                    }}
                  />
                </div>
                <div className='transport-detail'>
                  <div className='tr'>
                    <div className='th' style={{ width: '10%' }}>
                      {i18n.t('staff.movingExpensesDetail.doctorName')}
                    </div>
                    <div className='td'>{data.fullName}</div>
                  </div>
                  <div className='tr'>
                    <div className='th' style={{ width: '10%' }}>
                      {i18n.t('main.movingExpenses.address')}
                    </div>
                    <div className='td'>{address}</div>
                  </div>
                  <table className='table-moving-expenses'>
                    <thead>
                      <tr>
                        <th width='15%'>
                          {i18n.t('main.movingExpensesRegistration.clinic')}
                        </th>
                        <th width='15%'>
                          {i18n.t('main.movingExpenses.distance')}
                        </th>
                        <th width='10%'>
                          {i18n.t('main.movingExpenses.gasolinePrice')}
                        </th>
                        <th width='12%'>
                          {i18n.t('main.movingExpenses.highwayToll')}
                        </th>
                        <th width='10%'>
                          {i18n.t('main.movingExpenses.totalOnway')}
                        </th>
                        <th width='10%'>
                          {i18n.t('main.movingExpenses.totalRoundTrip')}
                        </th>
                        <th width='10%'>
                          {i18n.t('main.movingExpenses.maximumAmountOver')}
                        </th>
                        <th width='18%'>
                          {i18n.t('main.movingExpenses.effectiveDate')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {values.splits.length > 0 ? (
                        values.splits.map((item, index) => {
                          const maxPrice = values.splits[index]?.maxPrice
                          const total =
                            (values.splits[index].highwayFare || 0) +
                            values.splits[index].distance *
                              values.splits[index].gasolinePrice
                          item.price = Math.floor(total)
                          return (
                            <tr key={index}>
                              <td>{item.clinicName}</td>
                              <td>
                                <InputNumber
                                  name={`splits[${index}].distance`}
                                  value={item.distance}
                                  onChange={e => {
                                    setFieldValue(
                                      `splits[${index}].distance`,
                                      e
                                    )
                                    setFieldValue(
                                      `splits[${index}].price`,
                                      e * item.gasolinePrice
                                    )
                                  }}
                                />
                                <span style={{ paddingLeft: '3px' }}>Km</span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                {numeralCurrency(
                                  values.splits[index].distance *
                                    values.splits[index].gasolinePrice
                                )}
                              </td>
                              <td>
                                <InputNumber
                                  placeholder='￥ 1000'
                                  formatter={value =>
                                    `￥ ${value}`.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ','
                                    )
                                  }
                                  allowClear
                                  step={10}
                                  min={0}
                                  type='tel'
                                  name={`splits[${index}].highwayFare`}
                                  value={values.splits[index].highwayFare}
                                  onChange={e => {
                                    setFieldValue(
                                      `splits[${index}].highwayFare`,
                                      e
                                    )
                                  }}
                                />
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                {numeralCurrency(item.price)}
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                {maxPrice && item.price * 2 > maxPrice
                                  ? numeralCurrency(maxPrice)
                                  : numeralCurrency(item.price * 2)}
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                {maxPrice && item.price * 2 > maxPrice && (
                                  <span className='ml-3 signup-alert'>
                                    {numeralCurrency(item.price * 2)}
                                  </span>
                                )}
                              </td>
                              <td>
                                <DatePicker
                                  name={`splits[${index}].applicableDate`}
                                  className='date-picker'
                                  format='YYYY年MM月DD日'
                                  value={moment(
                                    new Date(
                                      values.splits[index].applicableDate
                                    ),
                                    'YYYY年MM月DD日'
                                  )}
                                  locale={locale}
                                  // disabledDate={disabledDate}
                                  allowClear={false}
                                  onChange={async event => {
                                    setFieldValue(
                                      `splits[${index}].applicableDate`,
                                      moment(event)
                                        .startOf('day')
                                        .toISOString()
                                    )
                                    try {
                                      let price = 0
                                      let maxPrice
                                      const {
                                        data: gasolinePrices,
                                      } = await client.query({
                                        query: GET_GASOLINE_PRICE,
                                        variables: {
                                          where: {
                                            startTime_lte: moment(
                                              event
                                            ).startOf('day'),
                                          },
                                          first: 1,
                                          skip: 0,
                                          orderBy: 'createdAt_DESC',
                                        },
                                        fetchPolicy: 'network-only',
                                      })
                                      if (
                                        gasolinePrices.gasolinePrices.length > 0
                                      ) {
                                        price = Math.round(
                                          gasolinePrices.gasolinePrices[0]
                                            .price / 10
                                        )
                                        maxPrice =
                                          gasolinePrices.gasolinePrices[0]
                                            ?.maxPrice
                                      }
                                      setFieldValue(
                                        `splits[${index}].gasolinePrice`,
                                        price
                                      )
                                      setFieldValue(
                                        `splits[${index}].maxPrice`,
                                        maxPrice
                                      )
                                    } catch (error) {
                                      popup.error()
                                    }
                                  }}
                                />
                                <ErrorMessage
                                  name={`splits[${index}].applicableDate`}
                                  component='div'
                                  className='signup-alert'
                                />
                              </td>
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan={9} className='center'>
                            データがありません。
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <ConfirmationModal
                    {...formikProps}
                    confirmationMessage='距離情報を更新しますか？'
                  />
                </div>
              </div>
            </div>
          </>
        )
      }}
    />
  )
}

export default MovingExpensesByCarDetailScene
