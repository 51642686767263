const { SET_PROGRESS, REMOVE_PROGRESS } = require('./progress.action')

const initialState = {}
const progressBarReducer = (state = { progress: initialState }, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_PROGRESS:
      if (payload?.url) {
        window.open(payload.url, '_blank')
      }
      return {
        progress: {
          ...state.progress,
          [payload.progress]: {
            progress: payload.progress,
            label: payload.label,
            percent: payload.percent,
            url: payload.url,
            msg: payload.msg,
          },
        },
      }
    case REMOVE_PROGRESS:
      const { [payload]: remove, ...data } = state.progress
      return {
        progress: { ...data },
      }
    default:
      return state
  }
}

export default progressBarReducer
