import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, NotFound, ProgressSpinner } from '@medical/components'
import { SHOW_MONEY_FIELDS } from '@medical/constant/permissions'
import {
  checkStaffPermission,
  clinicalDepartmentNames,
  splitLogic,
} from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import moment from 'moment'
import React, { useEffect } from 'react'

import {
  STAFF_ACCEPT_WORKSCHEDULE_OVERTIME,
  WORKSCHEDULE_OVERTIME_DETAIL,
} from './WorkScheduleOverTimeDetail.grapql'
import WorkScheduleOverTimeDetailScene from './WorkScheduleOverTimeDetailScene'

const accept = 'ACCEPTED'
const cancel = 'REJECTED'
const withdrawalOfApproval = 'CANCEL_ACCEPTED'
const WorkScheduleOverTimeDetail = ({
  closeModal,
  match: {
    params: { workOverTimeId },
  },
}) => {
  const [{ i18n, popup }] = useCustom()
  const { loading, error, data, refetch } = useQuery(
    WORKSCHEDULE_OVERTIME_DETAIL,
    {
      variables: {
        where: {
          id: workOverTimeId,
        },
      },
    }
  )
  const isMoneyPermitted = checkStaffPermission({
    functionName: SHOW_MONEY_FIELDS,
  })
  const [updateWorkScheduleOverTime] = useMutation(
    STAFF_ACCEPT_WORKSCHEDULE_OVERTIME
  )
  useEffect(() => {
    refetch()
  })
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  const { workScheduleOvertimes = {}, departments } = data

  if (!workScheduleOvertimes[0]) {
    return <NotFound />
  }
  const onSubmit = async (
    { functionName, startTime, splits },
    { setSubmitting, setFieldValue }
  ) => {
    let successMessage
    switch (functionName) {
      case accept:
        successMessage = i18n.t(
          'staff.acceptedWorkSchedulesList.acceptedSuccess'
        )
        break
      case cancel:
        successMessage = i18n.t(
          'staff.acceptedWorkSchedulesList.rejectedSuccess'
        )
        break
      default:
        successMessage = i18n.t(
          'staff.acceptedWorkSchedulesList.withdrawalOfApprovalSuccess'
        )
        break
    }
    try {
      const {
        endTime,
        hourlyWage,
        splitDateTime1,
        splitDateTime2,
        splitDateTime3,
        splitHourlyWage1,
        splitHourlyWage2,
        splitHourlyWage3,
        isStartTimeBreakTime,
        isSplitDateTime1BreakTime,
        isSplitDateTime2BreakTime,
        isSplitDateTime3BreakTime,
      } = splitLogic({ submitDate: startTime, splits })
      await updateWorkScheduleOverTime({
        variables: {
          workOverTimeId,
          workScheduleId: workScheduleOvertimes[0].workSchedule.id,
          startTime,
          endTime,
          hourlyWage,
          splitDateTime1,
          splitDateTime2,
          splitDateTime3,
          splitHourlyWage1,
          splitHourlyWage2,
          splitHourlyWage3,
          isStartTimeBreakTime,
          isSplitDateTime1BreakTime,
          isSplitDateTime2BreakTime,
          isSplitDateTime3BreakTime,
          data: {
            endTime: workScheduleOvertimes[0].endTime
              ? moment(workScheduleOvertimes[0].endTime)
              : undefined,
            startTime: workScheduleOvertimes[0].startTime
              ? moment(workScheduleOvertimes[0].startTime)
              : undefined,
            earlyDeparture: workScheduleOvertimes[0].earlyDeparture,
            lateArrival: workScheduleOvertimes[0].lateArrival,
            reasonEarlyDeparture: workScheduleOvertimes[0].reasonEarlyDeparture
              ? workScheduleOvertimes[0].reasonEarlyDeparture
              : undefined,
            reasonLateArrival: workScheduleOvertimes[0].reasonLateArrival
              ? workScheduleOvertimes[0].reasonLateArrival
              : undefined,
            status: functionName,
          },
        },
      })
      popup.success(successMessage)
      setSubmitting(false)
      closeModal()
    } catch (error) {
      popup.error(error)
      setFieldValue('confirm', false)
      setSubmitting(false)
    }
  }
  const dataOverTime = {
    earlyDeparture: workScheduleOvertimes[0].earlyDeparture,
    endTime: workScheduleOvertimes[0].endTime
      ? moment(workScheduleOvertimes[0].endTime).format('HH:mm')
      : null,
    startTime: workScheduleOvertimes[0].startTime
      ? moment(workScheduleOvertimes[0].startTime).format('HH:mm')
      : null,
    lateArrival: workScheduleOvertimes[0].lateArrival,
    reasonEarlyDeparture: workScheduleOvertimes[0].reasonEarlyDeparture,
    reasonLateArrival: workScheduleOvertimes[0].reasonLateArrival,
    status: workScheduleOvertimes[0].status,
    startTimeSplit: workScheduleOvertimes[0].startTime || null,
    endTimeSplit: workScheduleOvertimes[0].endTime || null,
    deletedAt: workScheduleOvertimes[0].deletedAt,
    workScheduleHistory: JSON.parse(
      workScheduleOvertimes[0].workScheduleHistory
    ),
  }
  const departmentNames = clinicalDepartmentNames({
    departments,
  })
  return (
    <WorkScheduleOverTimeDetailScene
      i18n={i18n}
      dataOvertime={dataOverTime}
      onSubmit={onSubmit}
      departmentNames={departmentNames}
      workSchedule={workScheduleOvertimes[0].workSchedule}
      isMoneyPermitted={isMoneyPermitted}
      accept={accept}
      cancel={cancel}
      acceptedShift={workScheduleOvertimes[0].workSchedule.acceptedShift}
      withdrawalOfApproval={withdrawalOfApproval}
      data={workScheduleOvertimes[0]}
    />
  )
}
export default WorkScheduleOverTimeDetail
