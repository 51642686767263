import * as AWS from 'aws-sdk'
import uuid from 'uuid'
import winston, { createLogger, transports } from 'winston'
import WinstonCloudWatch from 'winston-cloudwatch'
import { checkEnvironment } from './checkEnvironment'

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  region: 'ap-northeast-1',
})
const logStreamName = () => {
  const date = new Date().toISOString().split('T')[0]
  return `${date}-${uuid.v1()}-${process.env.REACT_APP_ENV}`
}
const cloudWatchLogger = () => {
  const config = new WinstonCloudWatch({
    cloudWatchLogs: new AWS.CloudWatchLogs(),
    logGroupName: 'caps-front',
    logStreamName: logStreamName(),
    jsonMessage: true,
  })

  const transport = checkEnvironment() ? [config] : [new transports.Console()]
  
  const logger = createLogger({
    transports: transport,
  })
  return logger
}
export default cloudWatchLogger
