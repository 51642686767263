import './style.css'

import { useMutation } from '@apollo/react-hooks'
import { checkIsPdf } from '@medical/constant/utilities'
import { DOWNLOAD_FILE } from '@medical/pages/staff/Doctors/DoctorDetail/DoctorDetail.graphql'
import { useCustom } from '@medical/provider/context'
import axios from 'axios'
import moment from 'moment'
import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'

const ChatboxImg = ({ value, time, right, imageURL }) => {
  const [img, setImg] = useState(null)
  const [{ popup }] = useCustom()
  const [downloadFile] = useMutation(DOWNLOAD_FILE)
  const downloadFileSubmit = async (key, callback) => {
    if (!key || key.length === 0) return
    try {
      const {
        data: { downloadFileS3: fileURL },
      } = await downloadFile({
        variables: {
          key,
        },
      })
      callback(fileURL)
    } catch (error) {
      callback(null)
    }
  }
  const pdfName = url => {
    let copyUrl = ''
    if (url) {
      url.split('_').map((values, i) => {
        if (i === 1) copyUrl += values
        if (i > 1) copyUrl += `_${values}`
      })
    }
    return copyUrl
  }
  const downloadImage = async fileName => {
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/download-file`,
        null,
        {
          params: {
            fileName,
          },
          responseType: 'blob',
        }
      )
      const blobURL = URL.createObjectURL(data.data)
      const a = document.createElement('a')
      a.href = blobURL
      a.style = 'display: none'
      a.download = `${value}`
      a.target = '__blank'
      document.body.appendChild(a)
      a.click()
    } catch (err) {
      popup.error(err)
    }
  }

  const downloadTempImage = url => {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const blobURL = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = blobURL
        a.style = 'display: none'
        a.download = `${value}`
        a.target = '__blank'
        document.body.appendChild(a)
        a.click()
      })
      .catch(e => {
        popup.error(e)
      })
  }

  useEffect(() => {
    if (imageURL) {
      setImg(URL.createObjectURL(imageURL))
    } else {
      downloadFileSubmit(value, fileURL => setImg(fileURL))
    }
  }, [value, imageURL])

  if (!imageURL)
    return (
      <div
        className='chatbox-text row'
        style={right ? { justifyContent: 'end' } : null}
      >
        {right ? (
          <>
            {img && checkIsPdf(img) ? (
              <>
                <div className='time right-time-inquiry'>
                  {moment(time).format('a HH:mm')}
                </div>
                <div className='text'>
                  <a target='_blank' href={img}>
                    {pdfName(value)}
                  </a>
                </div>
              </>
            ) : (
              <>
                <div className='time right-time-inquiry'>
                  {moment(time).format('a HH:mm')}
                </div>
                <Button
                  label=' '
                  className='download-button-chat download-button-chat-right'
                  onClick={() => {
                    downloadImage(value)
                  }}
                >
                  <i className='pi pi-cloud-download' />
                </Button>
                <div
                  className='dropzone'
                  style={{
                    maxHeight: '270px',
                    maxWidth: '480px',
                  }}
                >
                  <img
                    alt='a'
                    className='chat-image-content'
                    src={img}
                    style={{
                      maxHeight: 'calc(270px - 10px)',
                      maxWidth: '480px',
                    }}
                  />
                </div>
              </>
            )}
          </>
        ) : (
          <>
            {img && checkIsPdf(img) ? (
              <>
                <div className='text'>
                  <a target='_blank' href={img}>
                    {pdfName(value)}
                  </a>
                </div>
                <div className='time left-time-inquiry'>
                  {moment(time).format('a HH:mm')}
                </div>
              </>
            ) : (
              <>
                <div
                  className='dropzone'
                  style={{
                    maxHeight: '270px',
                    maxWidth: '480px',
                  }}
                >
                  <img
                    alt='a'
                    className='chat-image-content'
                    src={img}
                    style={{
                      maxHeight: 'calc(270px - 10px)',
                      maxWidth: '480px',
                    }}
                  />
                </div>
                <Button
                  label=' '
                  className='download-button-chat'
                  onClick={() => {
                    downloadImage(value)
                  }}
                >
                  <i className='pi pi-cloud-download' />
                </Button>
                <div className='time left-time-inquiry'>
                  {moment(time).format('a HH:mm')}
                </div>
              </>
            )}
          </>
        )}
      </div>
    )

  return (
    <div
      className='chatbox-text row'
      style={right ? { justifyContent: 'end' } : null}
    >
      {right ? (
        <>
          {img && imageURL && checkIsPdf(imageURL.name) ? (
            <>
              <div className='time'>{moment(time).format('a HH:mm')}</div>
              <div className='text'>
                <a target='_blank' href={img}>
                  {imageURL.name}
                </a>
              </div>
            </>
          ) : (
            <>
              <div className='time'>{moment(time).format('a HH:mm')}</div>
              <Button
                label=' '
                className='download-button-chat download-button-chat-right'
                onClick={() => {
                  downloadTempImage(img)
                }}
              >
                <i className='pi pi-cloud-download' />
              </Button>
              <div
                className='dropzone'
                style={{
                  maxHeight: '270px',
                  maxWidth: '480px',
                }}
              >
                <img
                  alt='a'
                  className='chat-image-content'
                  src={img}
                  style={{
                    maxHeight: 'calc(270px - 10px)',
                    maxWidth: '480px',
                  }}
                />
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {img && imageURL && checkIsPdf(imageURL.name) ? (
            <>
              <div className='text'>
                <a target='_blank' href={img}>
                  {imageURL.name}
                </a>
              </div>
              <div className='time'>{moment(time).format('a HH:mm')}</div>
            </>
          ) : (
            <>
              <div
                className='dropzone'
                style={{
                  maxHeight: '270px',
                  maxWidth: '480px',
                }}
              >
                <img
                  alt='a'
                  className='chat-image-content'
                  src={img}
                  style={{
                    maxHeight: 'calc(270px - 10px)',
                    maxWidth: '480px',
                  }}
                />
              </div>
              <Button
                className='download-button-chat'
                label=' '
                onClick={() => {
                  downloadTempImage(img)
                }}
              >
                <i className='pi pi-cloud-download' />
              </Button>
              <div className='time'>{moment(time).format('a HH:mm')}</div>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default ChatboxImg
