import { Spinner as PrimeSpinner } from 'primereact/spinner'
import { pathOr } from 'ramda'
import React from 'react'

const Spinner = ({
  label,
  values,
  handleChange,
  handleBlur,
  name,
  errors,
  touched,
  disabled,
  width,
  inputWidth,
  classDisabled,
  min,
  max,
  step,
}) => {
  const keys = name.split('.')
  const value = pathOr('', keys, values)
  const isTouched = pathOr(false, keys, touched)
  const error = pathOr('', keys, errors)
  return (
    <div className='tr'>
      <div className='th' style={{ width }}>
        {label}
      </div>
      <div className='td'>
        <PrimeSpinner
          name={name}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          min={min}
          max={max}
          step={step}
          style={inputWidth ? { width: inputWidth } : { width: '100%' }}
          disabled={disabled}
          className={classDisabled && disabled ? 'is-disabled' : ''}
        />
        <div className='alert'>{isTouched && error}</div>
      </div>
    </div>
  )
}

export default Spinner
