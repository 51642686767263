import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, NotFound, ProgressSpinner } from '@medical/components'
import { clinicalDepartmentNames } from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import React, { useEffect } from 'react'

import {
  AVAILABLE_SHIFT_COMMENT,
  DOCTOR_WORK_SCHEDULE,
} from './SingleSchedule.graphql'
import SingleScheduleScene from './SingleScheduleScene'

const SingleSchedule = ({
  match: {
    params: { workScheduleId },
  },
}) => {
  const [{ i18n }] = useCustom()
  const { loading, error, data, refetch } = useQuery(DOCTOR_WORK_SCHEDULE, {
    variables: { workScheduleId },
  })

  const {
    data: availableShiftComment,
    loading: loadingAvailableShiftComment,
    error: errorAvailableShiftComment,
  } = useQuery(AVAILABLE_SHIFT_COMMENT, {
    variables: {
      availableShiftsWhere: {
        WorkSchedule: {
          id: workScheduleId,
        },
      },
    },
  })

  useEffect(() => {
    refetch()
  })
  if (loading || loadingAvailableShiftComment) return <ProgressSpinner />
  if (error || errorAvailableShiftComment)
    return <ErrorComponent error={error} />

  const { workSchedule = {}, departments } = data

  if (!workSchedule) {
    return <NotFound />
  }
  const departmentNames = clinicalDepartmentNames({
    departments,
  })

  return (
    <SingleScheduleScene
      workSchedule={data.workSchedule}
      availableShifts={availableShiftComment.availableShifts}
      i18n={i18n}
      departmentNames={departmentNames}
    />
  )
}

export default SingleSchedule
