import { Column } from '@ant-design/charts'
import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import React from 'react'

import { STAFF_WORKTENDENCY } from './WorkTendencyForm.grapql'

const WorkTendencyForm = ({ i18n, doctor }) => {
  const {
    countedCancelWorkTendency,
    countedCancelLetterDueToClinicTendency,
  } = doctor
  const doctorId = doctor.id
  const { loading, error, data: workTendency } = useQuery(STAFF_WORKTENDENCY, {
    variables: { doctorId },
    fetchPolicy: 'network-only',
  })
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  const { time, dayWork, clinic } = workTendency.doctorGraph

  const { clinicalDepartments } = workTendency
  const clinicals = []
  clinicalDepartments
    .filter(it => it.clinic.isPublished === true)
    .map(item => {
      clinicals.push(item.clinic)
      return clinicals
    })
  const dataTest = [...new Set(clinicals)]
  const dataTime = JSON.parse(time)
  const dayWorks = JSON.parse(dayWork)
  const dataclinics = JSON.parse(clinic)
  const dataChartClinic = dataTest.map(element => ({
    type: element.name,
    value:
      dataclinics[`${element.name}`] !== 'undefined'
        ? dataclinics[`${element.name}`]
        : 0,
  }))
  const data = [
    {
      type: '9:00',
      sales: dataTime[9],
    },
    {
      type: '10:00',
      sales: dataTime[10],
    },
    {
      type: '11:00',
      sales: dataTime[11],
    },
    {
      type: '12:00',
      sales: dataTime[12],
    },
    {
      type: '13:00',
      sales: dataTime[13],
    },
    {
      type: '14:00',
      sales: dataTime[14],
    },
    {
      type: '15:00',
      sales: dataTime[15],
    },
    {
      type: '16:00',
      sales: dataTime[16],
    },
    {
      type: '17:00',
      sales: dataTime[17],
    },
    {
      type: '18:00',
      sales: dataTime[18],
    },
    {
      type: '19:00',
      sales: dataTime[19],
    },
    {
      type: '20:00',
      sales: dataTime[20],
    },
    {
      type: '21:00',
      sales: dataTime[21],
    },
  ]

  const dataWeeks = []
  for (const key in dayWorks) {
    dataWeeks.push({
      type: `${i18n.t('staff.workTendency.' + `${key}` + '')}`,
      sales: dayWorks[key],
    })
  }

  const config = {
    data,
    color: '#f03a6c',
    xField: 'type',
    yField: 'sales',
    style: {
      width: '100%',
      padding: '20px',
      border: '1px solid #c8c8c8',
    },
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: false,
        autoRotate: false,
      },
    },
    meta: {
      // type: { alias: '类别' },
      sales: { alias: '合計', min: 0, max: 100, tickCount: 6 },
    },
  }
  const configWeek = {
    data: dataWeeks,
    color: '#f03a6c',
    xField: 'type',
    yField: 'sales',
    style: {
      width: '100%',
      padding: '20px',
      border: '1px solid #c8c8c8',
    },
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: false,
        autoRotate: false,
      },
    },
    meta: {
      // type: { alias: '类别' },
      sales: { alias: '合計', min: 0, max: 100, tickCount: 6 },
    },
  }
  const configClinic = {
    data: dataChartClinic,
    color: '#f03a6c',
    xField: 'type',
    yField: 'value',
    style: {
      width: '100%',
      padding: '10px',
      border: '1px solid #c8c8c8',
    },
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: false,
        autoRotate: true,
        autoEllipsis: true,
        formatter: (x: string) =>
          x.length > 15 ? `${x.substring(0, 15)}...` : x,
      },
    },
    meta: {
      // type: { alias: '类别' },
      value: { alias: '合計', min: 0, max: 100, tickCount: 6 },
    },
  }
  return (
    <>
      <div className='asterisk-label-head' style={{ padding: '10px 0px' }}>
        勤務傾向は直近6ヶ月の勤務実績を元に集計しています
      </div>
      <div
        style={{
          paddingBottom: '20px',
          fontWeight: 'bold',
        }}
      >
        勤務時間帯
      </div>
      <Column {...config} />
      <div
        style={{
          paddingBottom: '20px',
          paddingTop: '50px',
          fontWeight: 'bold',
        }}
      >
        勤務曜日
      </div>
      <Column {...configWeek} />
      <div
        style={{
          paddingBottom: '20px',
          paddingTop: '50px',
          fontWeight: 'bold',
        }}
      >
        勤務拠点
      </div>
      <Column {...configClinic} />
      <div className='tr '>
        <div className='th' style={{ marginBottom: 'auto', marginTop: '15px' }}>
          {i18n.t('staff.workTendency.countCancel')}
        </div>
        <div className='td workTendency'>
          <div className='tr'>
            <div className='th'>{i18n.t('staff.workTendency.totalCancel')}</div>
            <div className='td'>{countedCancelWorkTendency.totalCount}</div>
            <div className='th'>{i18n.t('staff.workTendency.latestYear')}</div>
            <div className='td'>{countedCancelWorkTendency.oneYear}</div>
            <div className='th'>
              {i18n.t('staff.workTendency.latest6Month')}
            </div>
            <div className='td'>{countedCancelWorkTendency.sixMonth}</div>
            <div className='th'>
              {i18n.t('staff.workTendency.latest3Month')}
            </div>
            <div className='td'>{countedCancelWorkTendency.threeMonth}</div>
            <div className='th'>
              {i18n.t('staff.workTendency.latest1Month')}
            </div>
            <div className='td'>{countedCancelWorkTendency.oneMonth}</div>
          </div>
        </div>
        <div className='th' style={{ marginBottom: 'auto', marginTop: '15px' }}>
          {i18n.t('staff.workTendency.corporateCancel')}
        </div>
        <div className='td workTendency'>
          <div className='tr'>
            <div className='th'>{i18n.t('staff.workTendency.totalCancel')}</div>
            <div className='td'>
              {countedCancelLetterDueToClinicTendency.totalCount}
            </div>
            <div className='th'>{i18n.t('staff.workTendency.latestYear')}</div>
            <div className='td'>
              {countedCancelLetterDueToClinicTendency.oneYear}
            </div>
            <div className='th'>
              {i18n.t('staff.workTendency.latest6Month')}
            </div>
            <div className='td'>
              {countedCancelLetterDueToClinicTendency.sixMonth}
            </div>
            <div className='th'>
              {i18n.t('staff.workTendency.latest3Month')}
            </div>
            <div className='td'>
              {countedCancelLetterDueToClinicTendency.threeMonth}
            </div>
            <div className='th'>
              {i18n.t('staff.workTendency.latest1Month')}
            </div>
            <div className='td'>
              {countedCancelLetterDueToClinicTendency.oneMonth}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WorkTendencyForm
