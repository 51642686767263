import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const AcceptedWorkScheduleCancelConfirmation = ({
  values,
  isSubmitting,
  handleSubmit,
  handleNoClick,
  setFieldValue,
  confirmationMessage,
  children,
  closeModal,
  isCheckTime,
  visibleKey = 'confirm',
  confirmationMessageBefor,
}) => {
  const { i18n } = useTranslation()
  const noClick = () => setFieldValue(visibleKey, false)
  const [displayCancel, setDisplayCancel] = useState(false)

  const dialogFuncMap = {
    displayCancel: setDisplayCancel,
  }
  // eslint-disable-next-line no-shadow
  const cancelClick = name => {
    dialogFuncMap[`${name}`](true)
  }
  const onHide = () => {
    closeModal()
  }
  const CheckTimeCancel = () => {
    if (isCheckTime) {
      return (
        <>
          <Button
            type='submit'
            label={i18n.t('main.yes')}
            icon={`pi pi-${isSubmitting ? 'spinner pi-spin' : 'check'}`}
            disabled={isSubmitting}
            onClick={handleSubmit}
          />
          <Button
            label={i18n.t('main.no')}
            icon='pi pi-times'
            onClick={() => cancelClick('displayCancel')}
            className='p-button-secondary'
            disabled={isSubmitting}
          />
          <div style={{ textAlign: 'center' }}>
            <Dialog
              className='cancel-before '
              disabled={isSubmitting}
              position='center'
              onHide={() => onHide('displayCancel')}
              header=''
              visible={displayCancel}
              style={{ width: '50vw' }}
            >
              <span>
                ドクターサポ―ト事務局
                <a href='mailto:doctor-support@caps365.jp'>
                  doctor-support@caps365.jp
                </a>
                まで、ご連絡ください。その後、再度こちらのサイトから申請いただきます。
              </span>
            </Dialog>
          </div>
        </>
      )
    }
    return (
      <>
        <Button
          type='submit'
          label={i18n.t('main.yes')}
          icon={`pi pi-${isSubmitting ? 'spinner pi-spin' : 'check'}`}
          disabled={isSubmitting}
          onClick={() => handleSubmit() || cancelClick('displayCancel')}
        />
        <Dialog
          disabled={isSubmitting}
          position='center'
          onHide={() => onHide('displayCancel')}
          header='ご確認ください'
          visible={displayCancel}
          closable={false}
          style={{ width: '50vw', textAlign: 'left' }}
          footer={
            <Button
              label='確認しました'
              onClick={() => onHide('displayCancel') || noClick}
              className='p-button-secondary'
              disabled={isSubmitting}
            />
          }
        >
          {confirmationMessageBefor}
        </Dialog>
        <Button
          label={i18n.t('main.no')}
          icon='pi pi-times'
          onClick={handleNoClick || noClick}
          className='p-button-secondary'
          disabled={isSubmitting}
        />
      </>
    )
  }
  return (
    <Dialog
      header={i18n.t('main.confirmation')}
      visible={values[visibleKey]}
      className='p-dialog'
      footer={
        <div>
          <CheckTimeCancel />
        </div>
      }
      closable={!isSubmitting}
      onHide={handleNoClick || noClick}
    >
      {children}
      {confirmationMessage}
    </Dialog>
  )
}

export default AcceptedWorkScheduleCancelConfirmation
