import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import gql from 'graphql-tag'

export const WORKSCHEDULE_OVERTIMES_CONNECTION = gql`
  query WorkScheduleOvertimesConnection(
    $skip: Int
    $first: Int
    $where: WorkScheduleOvertimeWhereInput
    $orderBy: WorkScheduleOvertimeOrderByInput
  ) {
    workScheduleOvertimesConnection(
      skip: $skip
      first: $first
      where: $where
      orderBy: $orderBy
    ) {
      aggregate(where: $where) {
        count
      }
      edges {
        node {
          id
          startTime
          endTime
          deletedAt
          lateArrival
          status
          earlyDeparture
          workSchedule {
            id
            startTime
            doctor {
              id
              email
              lastname
              firstname
            }
            clinicalDepartment {
              id
              name
              extraName
              isDisplay
              clinic {
                id
                order
                name
                nameKana
              }
            }
          }
        }
      }
    }
    ...DEPARTMENTS_FRAGMENT
  }
  ${DEPARTMENTS_FRAGMENT}
`
