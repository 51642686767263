import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import ClinicNameRender from '@medical/components/ClinicNameRender'
import {
  checkStaffPermission,
  clinicalDepartmentNames,
  onPageChange,
} from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import moment from 'moment'
import React, { useEffect } from 'react'
import queryString from 'query-string'
import { WORK_OUTSIDE_CONNECTION } from './WorkOutsideClinic.graphql'
import WorkOutsideClinicScene from './WorkOutsideClinicScene'
import { STAFF_PROCESS_WORK_OUTSIDE } from '@medical/constant/permissions'

const WorkOutsideClinic = ({ history, location: { search } }) => {
  const [{ i18n }] = useCustom()
  const isProcessPermitted = checkStaffPermission({
    functionName: STAFF_PROCESS_WORK_OUTSIDE,
  })
  const {
    page = 1,
    rowsPerPage = 20,
    fullname,
    date,
    orderBy = 'createdAt_DESC',
  } = queryString.parse(search)
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  let variables = {
    first: first < 0 ? 20 : first,
    skip: skip < 0 ? 0 : skip,
    orderBy,
    where: {
      AND: [
        {
          Doctor: {
            deletedAt: null,
            unSigned_contains: fullname
              ? fullname
                  .replace(/ /g, '')
                  .replace(/　/g, '')
                  .toLowerCase()
              : '',
          },
        },
      ],
    },
  }
  if (date)
    variables = {
      first: first < 0 ? 20 : first,
      skip: skip < 0 ? 0 : skip,
      orderBy,
      where: {
        AND: [
          {
            Doctor: {
              deletedAt: null,
              unSigned_contains: fullname
                ? fullname
                    .replace(/ /g, '')
                    .replace(/　/g, '')
                    .toLowerCase()
                : '',
            },
            startTime_gte: moment(date).startOf('days'),
            endTime_lte: moment(date).endOf('days'),
          },
        ],
      },
    }
  const { loading, error, data, refetch } = useQuery(WORK_OUTSIDE_CONNECTION, {
    variables,
    fetchPolicy: 'network-only',
  })
  useEffect(() => {
    refetch()
  }, [])
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  const {
    workOutsidesConnection: {
      edges,
      aggregate: { count },
    },
    departments,
  } = data
  const departmentNames = clinicalDepartmentNames({
    departments,
  })
  const resultWorkOutsidesList = []
  edges.map(({ node }) => {
    resultWorkOutsidesList.push({
      id: node.id,
      createdAt: node.createdAt,
      doctorName: `${node.createdBy.lastname} ${node.createdBy.firstname}`,
      startTime: node.startTime,
      endTime: node.endTime,
      clinicName: ClinicNameRender({
        clinicalDepartment: node.clinicalDepartment,
      }),
      clinicalDepartmentName: departmentNames[node.clinicalDepartment.name],
      address: node.address,
      status: node.status,
    })
  })
  const statusConvertText = status => {
    switch (status) {
      case 'WAITING_APPROVAL': {
        return <span style={{ color: '#369EFD' }}>承認待ち</span>
      }
      case 'APPROVAL': {
        return <span style={{ color: '#647380' }}>承認済み</span>
      }
      case 'REJECTED': {
        return <span style={{ color: '#FF518F' }}>却下</span>
      }
      default:
        return null
    }
  }
  return (
    <WorkOutsideClinicScene
      i18n={i18n}
      fullname={fullname}
      rowsPerPage={rowsPerPage}
      page={page}
      count={count}
      resultWorkOutsidesList={resultWorkOutsidesList}
      onPageChange={onPageChange(history, search)}
      statusConvertText={statusConvertText}
      date={date}
      isProcessPermitted={isProcessPermitted}
    />
  )
}

export default WorkOutsideClinic
