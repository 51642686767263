import { COLORS } from '@medical/constant'
import React from 'react'

const itemRenderer = ({ item, itemContext, getItemProps, getResizeProps }) => {
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()
  const backgroundColor = itemContext.selected ? COLORS.success : item.bgColor
  const borderColor = itemContext.selected ? COLORS.success : item.borderColor
  const color = itemContext.selected ? '#fff' : item.color
  const itemProps = getItemProps({
    style: {
      backgroundColor,
      color,
      borderColor,
      borderStyle: 'solid',
      borderWidth: 1,
      borderRadius: 10,
      borderLeftWidth: 1,
      borderRightWidth: 1,
      boxShadow: '0 2px 3px #aaa',
      position: 'relative',
      overflow: 'hidden',
    },
  })
  const widthTimeLine = parseFloat(itemProps.style.width)

  return (
    <div {...itemProps}>
      {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

      <div
        style={{
          position: 'relative',
          height: itemContext.dimensions.height,
          overflow: 'hidden',
          padding: '0 5px',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          textAlign: 'left',
          fontWeight: 'bold',
          zIndex: 10,
        }}
      >
        {item.title}
      </div>

      {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}

      {item?.breakTime &&
        item.breakTime?.map(breakItem => {
          const elements = []
          if (breakItem.type === 'breaking') {
            elements.push(
              <div
                key={`${item.id}-${breakItem.start}`}
                style={{
                  position: 'absolute',
                  left: breakItem.start * widthTimeLine,
                  top: 0,
                  width: breakItem.width * widthTimeLine,
                  backgroundColor: itemContext.selected
                    ? COLORS.success
                    : '#007ad9',
                  height: itemContext.dimensions.height - 2,
                }}
              />
            )
          }

          return elements
        })}
    </div>
  )
}
export default itemRenderer
