import DoctorProfileForm from '@medical/components/Forms/DoctorProfileForm'
import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import React, { useEffect } from 'react'
import { DOCTOR_SKILLS } from './UpdateProfile.graphql'

const UpdateProfileScene = ({
  i18n,
  doctor = {},
  onSubmit,
  genders,
  departments,
  closeModal,
  insuranceDoctorTypes
}) => {
  return (
    <React.Fragment>
      <div className='modal-title'>保険診療・給与支払必須必要情報更新</div>
      <DoctorProfileForm
        i18n={i18n}
        doctor={doctor}
        doctorAddress={doctor.homeAddress || {}}
        genders={genders}
        insuranceDoctorTypes={insuranceDoctorTypes}
        departments={departments}
        onSubmit={onSubmit}
        setDisabled={closeModal}
        confirmationMessage={i18n.t('updateProfile.submitConfirmation')}
        buttonLabel={i18n.t('doctor.myPage.updateProfile')}
        updateProfile
        dataSkill={doctor.skillSet}
        doctorUpdate={true}
        isHiddenDoctorNo
        isUpdate
      />
    </React.Fragment>
  )
}

export default UpdateProfileScene
