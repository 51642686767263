import numeralCurrency from '@medical/libs/numeralCurrency'
import { Checkbox, DatePicker, Input, InputNumber } from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import { ErrorMessage } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import addSplit from './addSplit'
import AutoComplete from './TransportRegisterAutoComplete'
import TransportRegisterModal from './TransportRegisterModal'

const TransportRegisterSceneMobile = ({
  slipt,
  startTimeAddMonth,
  currentTime,
  isPast,
  index,
  formikProps,
  clinicals,
  disabledDate,
  format,
  arrayHelpers,
  remove,
  dataTransport,
}) => {
  const { i18n } = useTranslation()
  const { setFieldValue, validateForm, values } = formikProps

  return (
    <div
      className={
        slipt.isPast || isPast
          ? ' mobile-report background-grey'
          : 'mobile-report'
      }
      style={{ marginBottom: '20px' }}
    >
      <div key={index} className='tr'>
        <div className='th' style={{ width: '50%' }}>
          {i18n.t('main.clinicName')}
        </div>
        <div className='td'>
          <AutoComplete
            {...formikProps}
            register
            startTimeAddMonth={startTimeAddMonth}
            currentTime={currentTime}
            slipt={slipt}
            disabled={slipt.isPast || isPast}
            index={index}
            name={`splits[${index}].clinic`}
            field='name'
            allSuggestions={clinicals}
            suggestionName='clinicals'
            width='100%'
            paddingBottom='0px'
            placeholder={
              clinicals.length > 0 ? 'クリニックを選択' : '対象データなし'
            }
          />
          <ErrorMessage
            name={`splits[${index}].clinic`}
            component='div'
            className='signup-alert'
          />
          <div className='end-time-transport'>
            {slipt.acceptedBy
              ? moment.utc(slipt.createdAt).format('YYYY年MM月DD日 事務局入力')
              : null}
          </div>
        </div>
      </div>
      <div className='tr'>
        <div className='th' style={{ width: '50%' }}>
          {i18n.t('transportApplication.routeURL')}
          <Link
            to='#'
            style={{ color: '#40a9ff' }}
            onClick={() => {
              validateForm().then(() => {
                setFieldValue('showModalUrl', true)
                setFieldValue(
                  `splits[${index}].show`,
                  values.splits[index].show
                )
              })
            }}
          >
            ※
          </Link>
          <TransportRegisterModal {...formikProps} name='showModalUrl' />
        </div>
        <div className='td'>
          <Input
            className='color-url'
            placeholder='https://www.navitime.co.jp/'
            allowClear
            disabled={slipt.isPast || isPast}
            name={`splits[${index}].url`}
            value={values.splits[index].url}
            onChange={e => {
              if (slipt.id && startTimeAddMonth < currentTime) {
                addSplit(setFieldValue, values, slipt, index)
              } else {
                setFieldValue(`splits[${index}].url`, e.target.value)
              }
            }}
          />
          <ErrorMessage
            name={`splits[${index}].url`}
            component='div'
            className='signup-alert'
          />
        </div>
      </div>
      {slipt.isCheckBox && (
        <div className='tr'>
          <div className='th' style={{ width: '50%' }}>
            {`${i18n.t('staff.movingExpensesDetail.routeURL')}${i18n.t(
              'staff.movingExpensesDetail.past'
            )}`}
          </div>
          <div className={slipt.isPast || isPast ? 'td background-grey' : 'td'}>
            <Input
              className='color-url'
              placeholder='https://www.navitime.co.jp/'
              allowClear
              disabled={slipt.isPast || isPast}
              name={`splits[${index}].roundTrip.urlRoundTrip`}
              value={values.splits[index].roundTrip.urlRoundTrip}
              onChange={e => {
                if (slipt.id && startTimeAddMonth < currentTime) {
                  addSplit(setFieldValue, values, slipt, index)
                } else {
                  setFieldValue(
                    `splits[${index}].roundTrip.urlRoundTrip`,
                    e.target.value
                  )
                }
              }}
            />
            <ErrorMessage
              name={`splits[${index}].roundTrip.urlRoundTrip`}
              component='div'
              className='signup-alert'
            />
          </div>
        </div>
      )}

      <div className='tr'>
        <div className='th' style={{ width: '50%' }}>
          {i18n.t('transportApplication.departurePlace')}
          <Link
            to='#'
            style={{ color: '#40a9ff' }}
            onClick={() => {
              validateForm().then(() => {
                setFieldValue('showModalDeparturePlace', true)
                setFieldValue(
                  `splits[${index}].show`,
                  values.splits[index].show
                )
              })
            }}
          >
            ※
          </Link>
          <TransportRegisterModal
            {...formikProps}
            name='showModalDeparturePlace'
          />
        </div>
        <div className='td'>
          <Input
            placeholder='例：東京駅'
            allowClear
            disabled={slipt.isPast || isPast}
            name={`splits[${index}].departurePlace`}
            value={values.splits[index].departurePlace}
            onChange={e => {
              if (slipt.id && startTimeAddMonth < currentTime) {
                addSplit(setFieldValue, values, slipt, index)
              } else {
                setFieldValue(`splits[${index}].departurePlace`, e.target.value)
              }
            }}
          />
          <ErrorMessage
            name={`splits[${index}].departurePlace`}
            component='div'
            className='signup-alert'
          />
        </div>
      </div>
      {slipt.show && (
        <>
          {slipt.isCheckBox && (
            <div className='tr'>
              <div className='th' style={{ width: '50%' }}>
                {`${i18n.t(
                  'staff.movingExpensesDetail.pointOfDeparture'
                )}${i18n.t('staff.movingExpensesDetail.past')}`}
              </div>
              <div
                className={slipt.isPast || isPast ? 'td background-grey' : 'td'}
              >
                <Input
                  placeholder='例：東京駅'
                  allowClear
                  disabled={slipt.isPast || isPast}
                  name={`splits[${index}].roundTrip.departurePlaceRoundTrip`}
                  value={values.splits[index].roundTrip.departurePlaceRoundTrip}
                  onChange={e => {
                    if (slipt.id && startTimeAddMonth < currentTime) {
                      addSplit(setFieldValue, values, slipt, index)
                    } else {
                      setFieldValue(
                        `splits[${index}].roundTrip.departurePlaceRoundTrip`,
                        e.target.value
                      )
                    }
                  }}
                />
                <ErrorMessage
                  name={`splits[${index}].roundTrip.departurePlaceRoundTrip`}
                  component='div'
                  className='signup-alert'
                />
              </div>
            </div>
          )}
          <div className='tr'>
            <div className='th' style={{ width: '50%' }}>
              {i18n.t('transportApplication.arrivalPlace')}
              <Link
                to='#'
                style={{ color: '#40a9ff' }}
                onClick={() => {
                  validateForm().then(() => {
                    setFieldValue('showModalArrivalPlace', true)
                    setFieldValue(
                      `splits[${index}].show`,
                      values.splits[index].show
                    )
                  })
                }}
              >
                ※
              </Link>
              <TransportRegisterModal
                {...formikProps}
                name='showModalArrivalPlace'
              />
            </div>
            <div className='td'>
              <Input
                placeholder='例：新宿駅バス停'
                allowClear
                disabled={slipt.isPast || isPast}
                name={`splits[${index}].arrivalPlace`}
                value={values.splits[index].arrivalPlace}
                onChange={e => {
                  if (slipt.id && startTimeAddMonth < currentTime) {
                    addSplit(setFieldValue, values, slipt, index)
                  } else {
                    setFieldValue(
                      `splits[${index}].arrivalPlace`,
                      e.target.value
                    )
                  }
                }}
              />
              <ErrorMessage
                name={`splits[${index}].arrivalPlace`}
                component='div'
                className='signup-alert'
              />
            </div>
          </div>
          {slipt.isCheckBox && (
            <div className='tr'>
              <div className='th' style={{ width: '50%' }}>
                {`${i18n.t('staff.movingExpensesDetail.destination')}${i18n.t(
                  'staff.movingExpensesDetail.past'
                )}`}
              </div>
              <div
                className={slipt.isPast || isPast ? 'td background-grey' : 'td'}
              >
                <Input
                  placeholder='例：新宿駅バス停'
                  allowClear
                  disabled={slipt.isPast || isPast}
                  name={`splits[${index}].roundTrip.arrivalPlaceRoundTrip`}
                  value={values.splits[index].roundTrip.arrivalPlaceRoundTrip}
                  onChange={e => {
                    if (slipt.id && startTimeAddMonth < currentTime) {
                      addSplit(setFieldValue, values, slipt, index)
                    } else {
                      setFieldValue(
                        `splits[${index}].roundTrip.arrivalPlaceRoundTrip`,
                        e.target.value
                      )
                    }
                  }}
                />
                <ErrorMessage
                  name={`splits[${index}].roundTrip.arrivalPlaceRoundTrip`}
                  component='div'
                  className='signup-alert'
                />
              </div>
            </div>
          )}

          <div className='tr'>
            <div className='th' style={{ width: '50%' }}>
              {i18n.t('transportApplication.fareOneway')}
              <Link
                to='#'
                style={{ color: '#40a9ff' }}
                onClick={() => {
                  validateForm().then(() => {
                    setFieldValue('showModalCost', true)
                    setFieldValue(
                      `splits[${index}].show`,
                      values.splits[index].show
                    )
                  })
                }}
              >
                ※
              </Link>
              <TransportRegisterModal {...formikProps} name='showModalCost' />
            </div>
            <div className='td' style={{ width: '50%' }}>
              <InputNumber
                disabled={slipt.isPast || isPast}
                placeholder='￥ 300'
                formatter={value =>
                  `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                type='tel'
                allowClear
                min={0}
                step={5}
                value={values.splits[index].cost}
                name={`splits[${index}].cost`}
                onChange={value => {
                  if (slipt.id && startTimeAddMonth < currentTime) {
                    addSplit(setFieldValue, values, slipt, index)
                  } else {
                    setFieldValue(`splits[${index}].cost`, value)
                  }
                }}
              />

              <ErrorMessage
                name={`splits[${index}].cost`}
                component='div'
                className='signup-alert'
              />
            </div>
          </div>
          {slipt.isCheckBox && (
            <div className='tr'>
              <div className='th' style={{ width: '50%' }}>
                {`${i18n.t('staff.movingExpensesDetail.oneWayFare')}${i18n.t(
                  'staff.movingExpensesDetail.past'
                )}`}
              </div>
              <div
                className={slipt.isPast || isPast ? 'td background-grey' : 'td'}
                style={{ width: '50%' }}
              >
                <InputNumber
                  disabled={slipt.isPast || isPast}
                  placeholder='￥ 300'
                  formatter={value =>
                    `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  type='tel'
                  allowClear
                  min={1}
                  step={5}
                  value={values.splits[index].roundTrip.costRoundTrip}
                  name={`splits[${index}].roundTrip.costRoundTrip`}
                  onChange={value => {
                    if (slipt.id && startTimeAddMonth < currentTime) {
                      addSplit(setFieldValue, values, slipt, index)
                    } else {
                      setFieldValue(
                        `splits[${index}].roundTrip.costRoundTrip`,
                        value
                      )
                    }
                  }}
                />

                <ErrorMessage
                  name={`splits[${index}].roundTrip.costRoundTrip`}
                  component='div'
                  className='signup-alert'
                />
              </div>
            </div>
          )}
          <div className='tr'>
            <div className='th' style={{ width: '50%' }}>
              {i18n.t('transportApplication.roundTripRegister')}
            </div>
            <div className='td' style={{ width: '50%' }}>
              <Checkbox
                disabled={slipt.isPast || isPast}
                checked={slipt.isCheckBox}
                onChange={() => {
                  if (slipt.id && startTimeAddMonth < currentTime) {
                    addSplit(setFieldValue, values, slipt, index)
                  } else {
                    setFieldValue(
                      `splits[${index}].isCheckBox`,
                      !values.splits[index].isCheckBox
                    )
                    if (!slipt.isCheckBox) {
                      setFieldValue(`splits[${index}].roundTrip`, {
                        urlRoundTrip: '',
                        departurePlaceRoundTrip: '',
                        arrivalPlaceRoundTrip: '',
                        costRoundTrip: '',
                      })
                    } else {
                      setFieldValue(`splits[${index}].roundTrip`, null)
                    }
                  }
                }}
              />
            </div>
          </div>
          <div className='tr'>
            <div className='th' style={{ width: '50%' }}>
              {i18n.t('transportApplication.totalCost')}
            </div>
            <div className='td' style={{ width: '50%' }}>
              {numeralCurrency(
                values.splits[index].cost +
                  (values.splits[index].roundTrip &&
                  values.splits[index].roundTrip.costRoundTrip > 0
                    ? parseInt(values.splits[index].roundTrip.costRoundTrip)
                    : values.splits[index].cost)
              )}
            </div>
          </div>
          <div className='tr'>
            <div className='th' style={{ width: '50%' }}>
              {i18n.t('transportApplication.startDate')}
            </div>
            <div className='td tranport-sb' style={{ width: '50%' }}>
              <DatePicker
                disabled={slipt.isPast || isPast}
                format='YYYY年MM月DD日'
                locale={locale}
                // disabledDate={disabledDate}
                allowClear={false}
                value={
                  values.splits[index].startTime
                    ? moment.utc(
                        new Date(values.splits[index].startTime),
                        'YYYY年MM月DD日'
                      )
                    : moment.utc(new Date(), 'YYYY年MM月DD日')
                }
                onChange={value => {
                  if (slipt.id && startTimeAddMonth < currentTime) {
                    setFieldValue('splits', [
                      ...values.splits,
                      {
                        clinic: slipt.clinic,
                        url: slipt.url,
                        departurePlace: slipt.departurePlace,
                        arrivalPlace: slipt.arrivalPlace,
                        cost: slipt.cost,
                        isCheckBox: slipt.isCheckBox,
                        roundTrip: slipt.roundTrip
                          ? {
                              urlRoundTrip: slipt.roundTrip.urlRoundTrip,
                              departurePlaceRoundTrip:
                                slipt.roundTrip.departurePlaceRoundTrip,
                              arrivalPlaceRoundTrip:
                                slipt.roundTrip.arrivalPlaceRoundTrip,
                              costRoundTrip: slipt.roundTrip.costRoundTrip,
                            }
                          : null,
                        startTime: moment
                          .utc(value)
                          .startOf('day')
                          .toISOString(),
                      },
                    ])
                    setFieldValue(
                      `splits[${index}].endTime`,
                      moment
                        .utc(value)
                        .startOf('month')
                        .subtract(1, 'days')
                    )
                    setFieldValue(`splits[${index}].isHistory`, moment())
                    setFieldValue(`splits[${index}].isPast`, true)
                  } else {
                    setFieldValue(
                      `splits[${index}].startTime`,
                      moment
                        .utc(value)
                        .startOf('day')
                        .toISOString()
                    )
                  }
                }}
              />
              <div className='end-time-transport'>
                {slipt.endTime
                  ? moment.utc(slipt.endTime).format('YYYY年MM月DD日まで')
                  : format}
              </div>
            </div>
          </div>
          {(dataTransport.length > 0 || values.splits.length > 1) && (
            <div
              className='td'
              style={{ textAlign: 'center', marginBottom: '20px' }}
            >
              <Button
                icon='pi pi-trash'
                onClick={() => {
                  if (startTimeAddMonth < currentTime) {
                    setFieldValue(`splits[${index}].isHidden`, true)
                    setFieldValue(`splits[${index}].isHistory`, moment.utc())

                    setFieldValue('functionName', 'hidden')
                    setFieldValue('confirm', true)
                  } else if (slipt.id) {
                    if (slipt.isPast || isPast) {
                      setFieldValue('functionName', remove)
                      values.transportationsId.push(slipt.id)
                      setFieldValue('confirm', true)
                    } else {
                      arrayHelpers.remove(index)

                      values.transportationsId.push(slipt.id)
                    }
                  } else {
                    arrayHelpers.remove(index)
                  }
                }}
              />
            </div>
          )}
        </>
      )}

      <div style={{ textAlign: 'center' }}>
        <Button
          style={{
            borderRadius: '25px',
            marginBottom: '5px',
          }}
          icon={
            values.splits[index].show
              ? 'pi pi-chevron-up'
              : 'pi pi-chevron-down'
          }
          onClick={() => {
            setFieldValue(`splits[${index}].show`, !values.splits[index].show)
          }}
        />
      </div>
    </div>
  )
}
export default TransportRegisterSceneMobile
