import '@medical/pages/staff/Incentive/IncentiveSetting/component/styles.css'

import { useApolloClient } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import CustomCheckBox from '@medical/components/CheckBox'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import Chips from '@medical/components/CustomChips/index'
import TextInput from '@medical/components/TextInput'
import { INCENTIVE_TYPE, objectTargetConstant } from '@medical/constant/index'
import { convertDoctorsConnection } from '@medical/libs'
import { DOCTOR_CONNECTION } from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import { getListIdAndWorkPartten } from '@medical/pages/staff/Incentive/IncentiveSetting/component/commonFunction'
import {
  Checkbox,
  DatePicker,
  Input,
  InputNumber,
  Space,
  TimePicker,
} from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import { ErrorMessage, Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { ListBox } from 'primereact/listbox'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'

const { RangePicker } = DatePicker
const IncentiveSettingForm = ({
  onSubmit,
  confirmationMessage,
  isUpdateAction,
  i18n,
  clinicalDepartments,
  doctors,
  node,
  doctorsSelected,
  isCreateIncentive,
  isUpdatePermitted,
  isDeletePermitted,
}) => {
  const [listDoctor, setListDoctor] = useState([])
  const [statusObjectTarget, setStatusObjectTarget] = useState({
    value: '',
  })
  const inputDoctorNameFilter = React.createRef()
  const [confirmMessage, setConfirmMessage] = useState()
  const client = useApolloClient()
  useEffect(() => {
    setConfirmMessage(confirmationMessage)
  }, [])
  useEffect(() => {
    if (node) {
      setStatusObjectTarget({
        value: node.applyWith,
      })
      // eslint-disable-next-line no-nested-ternary
      const startTimeValidated = moment(node.startTime, 'YYYY-MM').isBefore(
        moment().format('YYYY-MM')
      )
        ? moment(moment().format('YYYY-MM-DD HH:mm:ss')).isSameOrAfter(
            moment()
              .startOf('month')
              .add(15, 'day')
              .format('YYYY-MM-DD HH:mm:ss')
          )
          ? moment()
              .startOf('month')
              .format('YYYY-MM-DD')
          : `${
              moment()
                .startOf('month')
                .subtract(1, 'month')
                .format()
                .split('+')[0]
            }.000Z`
        : moment(node.startTime)
            .startOf('month')
            .format('YYYY-MM-DD')
      const workPatternApplyValidate = !Array.isArray(node.workPatternApply)
        ? JSON.parse(node.workPatternApply)
        : node.workPatternApply
      node['startTimeValidated'] = startTimeValidated
      node['workPatternApply'] = workPatternApplyValidate
    }
    if (
      doctorsSelected &&
      doctorsSelected.incentive &&
      doctorsSelected.incentive.incentiveDetails
    ) {
      // add list doctor selected into node
      const { incentiveDetails } = doctorsSelected.incentive
      node['listDoctor'] =
        node.applyWith === objectTargetConstant.SET_FOR_EACH_DOCTOR
          ? getListIdAndWorkPartten(incentiveDetails).listDoctors
          : []
      node['listWorkPartten'] =
        node.applyWith === objectTargetConstant.SET_FOR_EACH_WORKPARTTEN
          ? getListIdAndWorkPartten(incentiveDetails).workPartten
          : []
    }
  }, [node])
  useEffect(() => {
    if (doctors) {
      const tempList = []
      for (let i = 0; i < doctors.length; i += 1) {
        if (
          doctors[i].registrationStatus &&
          !doctors[i].blocked &&
          (doctors[i].registrationStatus === 'CREATED_BY_STAFF' ||
            doctors[i].registrationStatus === 'ACCEPTED')
        ) {
          tempList.push(doctors[i])
        }
      }
      setListDoctor(tempList)
    }
  }, [doctors])
  const validationSchema = Yup.object().shape({
    incentiveType: Yup.object().required(
      i18n.t('main.incentiveSetting.listMessageValidation.incentive')
    ),
    title: Yup.string().required(
      i18n.t('main.incentiveSetting.listMessageValidation.title')
    ),
    workPartten:
      statusObjectTarget.value === objectTargetConstant.SET_FOR_EACH_WORKPARTTEN
        ? Yup.mixed().test(
            'validateWorkPartten',
            i18n.t('main.incentiveSetting.listMessageValidation.objectTarget'),
            val => {
              if (val && val.length > 0) return true
              return false
            }
          )
        : Yup.array().nullable(),
    objectTarget: Yup.object().required(
      i18n.t('main.incentiveSetting.listMessageValidation.objectTarget')
    ),
    doctor:
      statusObjectTarget.value === objectTargetConstant.SET_FOR_EACH_DOCTOR
        ? Yup.mixed().test(
            'validateSelectDoctor',
            i18n.t('main.incentiveSetting.listMessageValidation.objectTarget'),
            val => {
              if (val && val.length > 0) return true
              return false
            }
          )
        : Yup.array().nullable(),
    textSearchDoctorByName: Yup.string().nullable(),
    rangeDate: Yup.mixed().test(
      'validateRangeDate',
      i18n.t('main.incentiveSetting.listMessageValidation.rangeDate'),
      val => {
        if (Array.isArray(val) && val.length > 0) return true
        return false
      }
    ),
    cost: Yup.number()
      .nullable()
      .required(i18n.t('main.incentiveSetting.listMessageValidation.cost')),
  })
  const incentiveType = [
    {
      name: i18n.t('main.incentiveSetting.normalIncentive'),
      value: INCENTIVE_TYPE.NORMAL_INCENTIVE,
    },
    {
      name: i18n.t('main.incentiveSetting.numberIncentive'),
      value: INCENTIVE_TYPE.NUMBER_INCENTIVE,
    },
    {
      name: i18n.t('main.incentiveSetting.beyound8Incentive'),
      value: INCENTIVE_TYPE.BEYOND_EIGHT_INCENTIVE,
    },
    {
      name: i18n.t('main.incentiveSetting.timeIncentive'),
      value: INCENTIVE_TYPE.TIME_INCENTIVE,
    },
    {
      name: i18n.t('main.incentiveSetting.satisfactionIncentive'),
      value: INCENTIVE_TYPE.SATISFACTION_INCENTIVE,
    },
  ]
  const listObjectTarget = [
    {
      name: i18n.t('main.incentiveSetting.listOptionObjectTarget.new'),
      value: objectTargetConstant.NEW,
    },
    {
      name: i18n.t(
        'main.incentiveSetting.listOptionObjectTarget.setForEachDoctor'
      ),
      value: objectTargetConstant.SET_FOR_EACH_DOCTOR,
    },
    {
      name: i18n.t(
        'main.incentiveSetting.listOptionObjectTarget.setForAllDoctor'
      ),
      value: objectTargetConstant.SET_FOR_ALL_DOCTOR,
    },
    {
      name: i18n.t(
        'main.incentiveSetting.listOptionObjectTarget.setForEachWorkPartten'
      ),
      value: objectTargetConstant.SET_FOR_EACH_WORKPARTTEN,
    },
  ]
  const listWorkPartten = [
    {
      value: 'ALL_WORKPATTTEN',
      description: i18n.t('main.ALL_WORK_PATTERN'),
    },
    {
      value: 'PERMANENT_DOCTOR',
      description: i18n.t('main.PERMANENT_DOCTOR'),
    },
    {
      value: 'REGULAR_SHIFT_DOCTOR',
      description: i18n.t('main.REGULAR_SHIFT_DOCTOR'),
    },
    {
      value: 'IRREGULAR_SHIFT_DOCTOR',
      description: i18n.t('main.IRREGULAR_SHIFT_DOCTOR'),
    },
    {
      value: 'TEMPORARY_DOCTOR',
      description: i18n.t('main.TEMPORARY_DOCTOR'),
    },
  ]
  const listDescription = [
    {
      value: INCENTIVE_TYPE.NORMAL_INCENTIVE,
      description: `通常インセンティブは、設定した時点で対象の医師へのインセンティブ付与が確定します。
      タイトルで入力した内容が勤務実績画面に表示されますので、詳細をご記入ください。`,
    },
    {
      value: INCENTIVE_TYPE.NUMBER_INCENTIVE,
      description: '使用するデータ: 診療件数',
    },
    {
      value: INCENTIVE_TYPE.BEYOND_EIGHT_INCENTIVE,
      description: '使用するデータ:1時間あたり8件超の件数',
    },
    {
      value: INCENTIVE_TYPE.TIME_INCENTIVE,
      description:
        '使用するデータ:(1件当りの平均診療時間が5~7分の場合) 診療件数',
    },
    {
      value: INCENTIVE_TYPE.SATISFACTION_INCENTIVE,
      description:
        '使用するデータ:(患者アンケート結果が「非常に満足 90%以上かつ「満足しなかった」 0%の要件を満たした場合)診療件数',
    },
  ]
  const getDescription = (listData, option) => {
    if (listData && option) {
      const itemFind = listData.find(item => item.value === option.value)
      if (itemFind && itemFind.description) {
        return itemFind.description
      }
    }
    return ''
  }
  return (
    <Formik
      enableReinitialize
      initialValues={{
        confirm: false,
        title: node ? node.title : '',
        incentiveType: node
          ? incentiveType.find(item => item.value === node.type)
          : '',
        workPartten: node && node.workPatternApply ? node.workPatternApply : [],
        objectTarget: node
          ? listObjectTarget.find(item => item.value === node.applyWith)
          : '',
        doctor: node && node.listDoctor ? node.listDoctor : [],
        doctors,
        textSearchDoctorByName: '',
        workParttenSearch: '',
        rangeDate:
          node && node.startTime && node.startTimeValidated && node.endTime
            ? [node.startTimeValidated, node.endTime]
            : [],
        cost: node ? node.amount : '',
        selectedDoctor:
          node && node.listDoctor
            ? node.listDoctor.map(item => item.fullNameEmail)
            : [],
        listDoctorSelected: [],
        action: '',
        description: '',
        listDoctors: listDoctor,
        isShowListBox: false,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={formikProps => {
        const {
          setFieldValue,
          handleChange,
          values,
          validateForm,
          setTouched,
          errors,
        } = formikProps
        return (
          <div className=' container'>
            <div className='row incentive-container'>
              <div className='hourly-title col-md-12'>
                {isCreateIncentive
                  ? i18n.t('main.incentiveSetting.titleOfFormCreate')
                  : i18n.t('main.incentiveSetting.titleOfFormDetail')}
              </div>
            </div>
            <div className='tr'>
              <div className='th'>
                {i18n.t('main.incentiveSetting.incentiveType')}
              </div>
              <div className='td'>
                <Dropdown
                  name='incentiveType'
                  options={incentiveType}
                  optionLabel='name'
                  value={values.incentiveType}
                  disabled={!isCreateIncentive}
                  placeholder={i18n.t(
                    'main.incentiveSetting.listPlaceHolder.incentiveType'
                  )}
                  onChange={e => {
                    setFieldValue('incentiveType', e.target.value)
                    const textDescription = listDescription.find(
                      item => item.value === e.target.value.value
                    )
                    setFieldValue(
                      'description',
                      textDescription ? textDescription.description : ''
                    )
                  }}
                />
                <ErrorMessage
                  name='incentiveType'
                  component='div'
                  className='color-red'
                />
              </div>
            </div>

            {!values.incentiveType ? null : (
              <div className='tr'>
                <div className='td description-container'>
                  {getDescription(listDescription, values.incentiveType)}
                </div>
              </div>
            )}
            <div className='tr'>
              <div className='th'>
                {i18n.t('main.incentiveSetting.position')}
              </div>
              <div className='td'>
                <InputText
                  name='title'
                  value={values.title}
                  onChange={e => {
                    setFieldValue('title', e.target.value)
                  }}
                  style={{ width: '50%' }}
                />
                <ErrorMessage
                  name='title'
                  component='div'
                  className='color-red'
                />
              </div>
            </div>
            <div className='tr'>
              <div className='th'>
                {i18n.t('main.incentiveSetting.objectTarget')}
              </div>
              <div className='td'>
                <Dropdown
                  name='objectTarget'
                  placeholder={i18n.t(
                    'main.incentiveSetting.listPlaceHolder.objectTarget'
                  )}
                  options={
                    values.incentiveType.value !==
                    INCENTIVE_TYPE.NORMAL_INCENTIVE
                      ? listObjectTarget.filter(
                          item => item.value !== 'NEW_DOCTOR'
                        )
                      : listObjectTarget
                  }
                  optionLabel='name'
                  value={values.objectTarget}
                  onChange={e => {
                    setFieldValue('objectTarget', e.target.value)
                    setStatusObjectTarget(e.target.value)
                  }}
                />
                <ErrorMessage
                  name='objectTarget'
                  component='div'
                  className='color-red'
                />
              </div>
            </div>
            {values.objectTarget.value ===
            objectTargetConstant.SET_FOR_EACH_DOCTOR ? (
              <>
                <div className='tr'>
                  <div className='td group-select-type'>
                    <Dropdown
                      name='workParttenSearch'
                      options={listWorkPartten}
                      className='drop-down-select-workpartten-search'
                      optionLabel='description'
                      value={values.workParttenSearch}
                      placeholder={i18n.t(
                        'main.incentiveSetting.listPlaceHolder.workPattern'
                      )}
                      onChange={e => {
                        setFieldValue('workParttenSearch', e.target.value)
                      }}
                    />
                    <InputText
                      style={{ width: '48%' }}
                      name='textSearchDoctorByName'
                      placeholder={i18n.t(
                        'main.incentiveSetting.listPlaceHolder.nameSearch'
                      )}
                      className='input-text-search'
                      ref={inputDoctorNameFilter}
                      onChange={e => {
                        setFieldValue('textSearchDoctorByName', e.target.value)
                      }}
                    />
                    <Button
                      label={i18n.t('main.incentiveSetting.search')}
                      icon='pi pi-search'
                      onClick={async () => {
                        // if (inputDoctorNameFilter.current.element.value) {
                        // }
                        try {
                          const { data: dataConnection } = await client.query({
                            query: DOCTOR_CONNECTION,
                            variables: {
                              where: {
                                AND: [
                                  {
                                    OR: [
                                      {
                                        unSigned_contains: values.textSearchDoctorByName
                                          ? values.textSearchDoctorByName
                                              .replace(/ /g, '')
                                              .replace(/　/g, '')
                                              .toLowerCase()
                                          : '',
                                      },
                                      {
                                        email_contains:
                                          values.textSearchDoctorByName,
                                      },
                                    ],
                                  },
                                ],
                                registrationStatus_in: [
                                  'CREATED_BY_STAFF',
                                  'ACCEPTED',
                                ],
                                blocked: false || null,
                              },
                              first: 10,
                              skip: 0,
                              orderBy: 'unSigned_ASC',
                            },
                            fetchPolicy: 'network-only',
                          })
                          setFieldValue(
                            'doctors',
                            convertDoctorsConnection(
                              dataConnection.doctorsConnection.edges
                            )
                          )
                        } catch (err) {}
                        setFieldValue('isShowListBox', true)
                        if (
                          values.workParttenSearch &&
                          !values.textSearchDoctorByName
                        ) {
                          if (
                            values.workParttenSearch.value === 'ALL_WORKPATTTEN'
                          ) {
                            setFieldValue('listDoctors', listDoctor)
                          } else {
                            setFieldValue(
                              'listDoctors',
                              listDoctor.filter(
                                item =>
                                  item.workPattern ===
                                  values.workParttenSearch.value
                              )
                            )
                          }
                        } else if (
                          !values.workParttenSearch &&
                          values.textSearchDoctorByName
                        ) {
                          const tempListFind = listDoctor.filter(item =>
                            item.fullName
                              .toLowerCase()
                              .includes(
                                values.textSearchDoctorByName.toLowerCase()
                              )
                          )
                          setFieldValue('listDoctors', tempListFind)
                        } else if (
                          values.workParttenSearch &&
                          values.textSearchDoctorByName
                        ) {
                          if (
                            values.workParttenSearch.value === 'ALL_WORKPATTTEN'
                          ) {
                            setFieldValue(
                              'listDoctors',
                              listDoctor.filter(item =>
                                item.fullName
                                  .toLowerCase()
                                  .includes(
                                    values.textSearchDoctorByName.toLowerCase()
                                  )
                              )
                            )
                          } else {
                            setFieldValue(
                              'listDoctors',
                              listDoctor.filter(
                                item =>
                                  item.fullName
                                    .toLowerCase()
                                    .includes(
                                      values.textSearchDoctorByName.toLowerCase()
                                    ) &&
                                  item.workPattern ===
                                    values.workParttenSearch.value
                              )
                            )
                          }
                        }
                      }}
                    />
                  </div>
                </div>
                <div className='tr'>
                  {values.isShowListBox || values.doctor ? (
                    <div className='td'>
                      <ListBox
                        optionValue='id'
                        dataKey='id'
                        name='listDoctors'
                        optionLabel='fullNameEmail'
                        className='list-doctor'
                        value={values.doctor}
                        listStyle={{ maxHeight: '250px' }}
                        // options={values.listDoctors}
                        options={values.doctors}
                        onChange={async e => {
                          await setFieldValue('doctor', e.value)

                          let listNameDoctorSelected =
                            values.doctor && !isCreateIncentive
                              ? values.doctor
                              : []
                          const listDoctorSelecting = []
                          for (let i = 0; i < e.value.length; i++) {
                            const tempObj = {
                              id: e.value[i].id,
                              fullName: e.value[i].fullName,
                              email: e.value[i].email,
                              fullNameEmail: e.value[i].fullNameEmail,
                            }
                            listDoctorSelecting.push(tempObj)
                          }
                          const listNameDoctorSelectedFilter = new Map()
                          for (let i = 0; i < listDoctorSelecting.length; i++) {
                            listNameDoctorSelectedFilter.set(
                              listDoctorSelecting[i].id,
                              {
                                id: listDoctorSelecting[i].id,
                                fullName: listDoctorSelecting[i].fullName,
                                email: listDoctorSelecting[i].email,
                                fullNameEmail: e.value[i].fullNameEmail,
                              }
                            )
                          }
                          listNameDoctorSelected = Array.from(
                            listNameDoctorSelectedFilter,
                            ([key, value]) => ({
                              id: value.id,
                              fullName: value.fullName,
                              email: value.email,
                              fullNameEmail: values.fullNameEmail,
                            })
                          )
                          if (listNameDoctorSelected) {
                            const tempList = []
                            const tempListDoctorSelected =
                              values.listDoctorSelected
                            for (
                              let i = 0;
                              i < listNameDoctorSelected.length;
                              i += 1
                            ) {
                              tempList.push(
                                listNameDoctorSelected[i].fullNameEmail
                              )
                            }
                            setFieldValue('selectedDoctor', tempList)
                          }
                        }}
                        multiple
                      />

                      <Chips
                        {...formikProps}
                        name='selectedDoctor'
                        componentClassName='chip-doctor-selected'
                        referenceVariable='doctor'
                        // disabled={true}
                      />
                    </div>
                  ) : null}
                </div>
              </>
            ) : null}
            <div className='tr'>
              <div className='td'>
                <ErrorMessage
                  name='doctor'
                  component='div'
                  className='color-red'
                />
              </div>
            </div>
            {values.objectTarget.value ===
            objectTargetConstant.SET_FOR_EACH_WORKPARTTEN ? (
              <CustomCheckBox
                {...formikProps}
                name='workPartten'
                options={listWorkPartten.filter(
                  item => item.value !== 'ALL_WORKPATTTEN'
                )}
              />
            ) : null}
            {values.incentiveType.value === INCENTIVE_TYPE.NORMAL_INCENTIVE ||
            !values.incentiveType ? (
              <div className='tr'>
                <div className='th'>
                  {i18n.t('main.incentiveSetting.titleDateIncentiveType1')}
                </div>
                <div className='td'>
                  <DatePicker
                    format='YYYY/MM'
                    disabledDate={current =>
                      (current && current > moment().add(1, 'y')) ||
                      moment(moment().format('YYYY-MM-DD HH:mm:ss')).isBefore(
                        moment()
                          .startOf('month')
                          .add(15, 'day')
                          .format('YYYY-MM-DD HH:mm:ss')
                      )
                        ? moment(current, 'YYYY-MM-DD HH:mm:ss').isBefore(
                            moment()
                              .startOf('month')
                              .subtract(1, 'month')
                              .format('YYYY-MM-DD HH:mm:ss')
                          ) || current > moment().add(1, 'y')
                        : moment(current, 'YYYY-MM-DD HH:mm:ss').isBefore(
                            moment().format('YYYY-MM-DD HH:mm:ss')
                          )
                    }
                    allowClear={false}
                    value={
                      values.rangeDate[0]
                        ? moment.utc(new Date(values.rangeDate[0]), 'YYYY-MM')
                        : null
                    }
                    picker='month'
                    name='rangeDate'
                    placeholder={false}
                    locale={locale}
                    onChange={e => {
                      setFieldValue('rangeDate', [
                        moment(e).format('YYYY-MM'),
                        moment(e).format('YYYY-MM'),
                      ])
                    }}
                  />
                  <ErrorMessage
                    name='rangeDate'
                    component='div'
                    className='color-red'
                  />
                </div>
              </div>
            ) : (
              <div className='tr'>
                <div className='th'>
                  {i18n.t('main.incentiveSetting.titleDateIncentiveOther')}
                </div>
                <div className='td'>
                  <DatePicker.RangePicker
                    value={[
                      values.rangeDate[0]
                        ? moment.utc(new Date(values.rangeDate[0]), 'YYYY-MM')
                        : null,
                      values.rangeDate[1]
                        ? moment.utc(new Date(values.rangeDate[1]), 'YYYY-MM')
                        : null,
                    ]}
                    format='YYYY/MM'
                    picker='month'
                    name='rangeDate'
                    allowClear={false}
                    locale={locale}
                    placeholder={['開始月', '終了月']}
                    disabledDate={current =>
                      (current && current > moment().add(1, 'y')) ||
                      moment(moment().format('YYYY-MM-DD HH:mm:ss')).isBefore(
                        moment()
                          .startOf('month')
                          .add(15, 'day')
                          .format('YYYY-MM-DD HH:mm:ss')
                      )
                        ? moment(current, 'YYYY-MM-DD HH:mm:ss').isBefore(
                            moment()
                              .startOf('month')
                              .subtract(1, 'month')
                              .format('YYYY-MM-DD HH:mm:ss')
                          ) || current > moment().add(1, 'y')
                        : moment(current, 'YYYY-MM-DD HH:mm:ss').isBefore(
                            moment().format('YYYY-MM-DD HH:mm:ss')
                          )
                    }
                    onChange={e => {
                      if (!isCreateIncentive && node) {
                        setFieldValue('rangeDate', [
                          moment(node.startTimeValidated).format(),
                          moment(e[1]).format(),
                        ])
                      } else {
                        setFieldValue('rangeDate', [
                          moment(e[0]).format(),
                          moment(e[1]).format(),
                        ])
                      }
                    }}
                  />
                  <ErrorMessage
                    name='rangeDate'
                    component='div'
                    className='color-red'
                  />
                </div>
                {/* // eslint-disable-next-line no-nested-ternary */}
                {node &&
                node.endTime &&
                moment(node.startTime, 'YYYY-MM').isBefore(
                  moment().format('YYYY-MM')
                ) &&
                node.type !== INCENTIVE_TYPE.NORMAL_INCENTIVE &&
                !moment(node.startTime, 'YYYY-MM').isSame(
                  moment(
                    moment()
                      .subtract(1, 'month')
                      .format('YYYY-MM')
                  )
                ) ? (
                  <div className='td'>
                    {`
                      ${i18n.t('main.incentiveSetting.warningTimeInPast')}: 
                      ${moment(node.startTime).format('YYYY-MM')}年 ~
                      ${moment(node.endTime).format('YYYY-MM')}月
                    `}
                  </div>
                ) : moment(
                    moment().format('YYYY-MM-DD HH:mm:ss')
                  ).isSameOrAfter(
                    moment()
                      .startOf('month')
                      .add(15, 'month')
                      .format('YYYY-MM-DD HH:mm:ss')
                  ) ? (
                  <div className='td'>
                    {`
                      ${i18n.t('main.incentiveSetting.warningTimeInPast')}: 
                      ${moment(node.startTime).format('YYYY-MM')} ~
                      ${moment(node.endTime).format('YYYY-MM')}
                    `}
                  </div>
                ) : (
                  ''
                )}
              </div>
            )}
            <div className='tr'>
              <div className='th'>
                {values.incentiveType.value ===
                  INCENTIVE_TYPE.NORMAL_INCENTIVE || !values.incentiveType
                  ? i18n.t('main.incentiveSetting.titleCostIncentveType1')
                  : i18n.t('main.incentiveSetting.titleCostIncentiveOther')}
              </div>
              <div className='td '>
                <InputNumber
                  placeholder='￥ 0'
                  formatter={value =>
                    `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  type='tel'
                  allowClear
                  size='middle'
                  name='cost'
                  style={{ width: '50%', borderColor: '#978787' }}
                  value={values.cost}
                  onChange={e => {
                    setFieldValue('cost', e)
                  }}
                  inputWidth='50%'
                  step={100}
                  min={0}
                />
                <ErrorMessage
                  name='cost'
                  component='div'
                  className='color-red'
                />
              </div>
            </div>
            <div className='tr'>
              {node &&
              node.endTime &&
              moment(node.startTime, 'YYYY-MM').isBefore(
                moment().format('YYYY-MM')
              ) &&
              node.type !== INCENTIVE_TYPE.NORMAL_INCENTIVE &&
              !moment(node.startTime, 'YYYY-MM').isSame(
                moment(
                  moment()
                    .subtract(1, 'month')
                    .format('YYYY-MM')
                )
              ) ? (
                <div className='td'>
                  {`この設定は ${moment(node.startTimeValidated).month() +
                    1}月計算分から変更になります`}
                </div>
              ) : moment(moment().format('YYYY-MM-DD HH:mm:ss')).isSameOrAfter(
                  moment()
                    .startOf('month')
                    .add(15, 'month')
                    .format('YYYY-MM-DD HH:mm:ss')
                ) ? (
                <div className='td'>
                  {`この設定は ${moment(node.startTimeValidated).month() +
                    1}月計算分から変更になります`}
                </div>
              ) : (
                ''
              )}
            </div>
            <div className='modal-bottom'>
              {!isCreateIncentive && isDeletePermitted ? (
                <Button
                  label={i18n.t('main.incentiveSetting.cancel')}
                  className='color-red'
                  onClick={() => {
                    setFieldValue('action', 'delete')
                    setConfirmMessage(
                      i18n.t('main.incentiveSetting.actionMessage.delete')
                    )
                    setFieldValue('confirm', true)
                  }}
                />
              ) : null}
              {isCreateIncentive || isUpdatePermitted ? (
                <Button
                  label={
                    isCreateIncentive
                      ? i18n.t('main.incentiveSetting.button.create')
                      : i18n.t('main.incentiveSetting.button.update')
                  }
                  className='reflect-button'
                  onClick={() => {
                    if (isCreateIncentive) {
                      setConfirmMessage(
                        i18n.t('main.incentiveSetting.actionMessage.create')
                      )
                    } else {
                      setConfirmMessage(
                        i18n.t('main.incentiveSetting.actionMessage.update')
                      )
                    }
                    validateForm().then(response => {
                      if (!Object.keys(response).length) {
                        setFieldValue('confirm', true)
                      } else {
                        setTouched(response)
                      }
                    })
                  }}
                />
              ) : null}
            </div>
            <ConfirmationModal
              {...formikProps}
              confirmationMessage={confirmMessage}
            />
          </div>
        )
      }}
    />
  )
}

export default IncentiveSettingForm
