import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import {
  clinicalDepartmentNames,
  combineNames,
  sortAndFilterByClinicOrder,
} from '@medical/libs'
import moment from 'moment'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'

import { WAITING_RECRUITMENT_QUERY } from './WaitingRecruitment.graphql'
import { WaitingRecruitmentScene } from './WaitingRecruitmentScene'

export const WaitingRecruitment = ({ history, location: { search } }) => {
  const [confirmStatusSelected, setConfirmStatusSelected] = useState('');
  let {
    clinicalDepartmentIds = [],
    doctorName = '',
    dateRangeStart,
    dateRangeEnd,
    page = 1,
    confirmationStatus = ''
  } = queryString.parse(search)
  const pageSize = 10

  const getVariablesRefetch = () => {
    const variables = {
      skip: (page - 1) * pageSize,
      first: pageSize,
      where: {
        RecruitmentShift_some: {
          clinic_in: clinicalDepartmentIds,
        },
        OR: [
          {
            startDate_gte: moment(dateRangeStart).startOf('day').toISOString(),
            startDate_lte: moment(dateRangeEnd).endOf('day').toISOString(),
          },
          {
            startDate_lte: moment(dateRangeStart).endOf('day').toISOString(),
            endDate_gte: moment(dateRangeStart).startOf('day').toISOString(),
          },
          {
            endDate_gte: moment(dateRangeStart).startOf('day').toISOString(),
            endDate_lte: moment(dateRangeEnd).endOf('day').toISOString(),
          },
        ],
        Doctor: {
          deletedAt: null,
          unSigned_contains: doctorName
            ? doctorName
                .replace(/ /g, '')
                .replace(/　/g, '')
                .toLowerCase()
            : '',
        },
        confirmationStatus: confirmationStatus
      },
      orderBy: 'createdDate_DESC',
    }
    if(!confirmationStatus) {
      delete variables.where.confirmationStatus
    }
    if (!dateRangeStart || !dateRangeEnd) {
      delete variables.where.OR
      variables.where.endDate_gte = moment(new Date()).startOf('day')
    }
    if (clinicalDepartmentIds.length === 0)
      delete variables.where.RecruitmentShift_some
    if (!Object.keys(variables.where).length) delete variables.where
    return variables
  }
  const { data, error, loading, refetch } = useQuery(
    WAITING_RECRUITMENT_QUERY,
    {
      variables: getVariablesRefetch(),
    }
  )

  const getWaitingList = () => {
    const query = {
      clinicalDepartmentIds,
      doctorName,
      dateRangeStart,
      dateRangeEnd,
      page,
      confirmationStatus
    }

    if (!dateRangeEnd) delete query.dateRangeEnd
    if (!dateRangeStart) delete query.dateRangeStart
    history.push({
      search: queryString.stringify({
        ...query,
      }),
    })

    refetch(getVariablesRefetch())
  }

  const clearFilter = () => {
    clinicalDepartmentIds = []
    doctorName = ''
    dateRangeEnd = null
    dateRangeStart = null
    confirmationStatus = ''
    getWaitingList()
  }

  const setClinicalDepartmentIds = ids => {
    clinicalDepartmentIds = ids.value.map(({ id }) => id)
    getWaitingList()
  }

  const setDoctorName = keySearch => {
    doctorName = keySearch
    getWaitingList()
  }

  const setPage = value => {
    page = value
    getWaitingList()
  }

  const setDateRange = dateRange => {
    if (!dateRange) {
      dateRangeStart = null
      dateRangeEnd = null
    } else {
      const [start, end] = dateRange
      dateRangeStart = start
      dateRangeEnd = end
    }
    getWaitingList()
  }

  const setConfirmationStatus = status => {
    confirmationStatus = status.value
    setConfirmStatusSelected(status)
    getWaitingList()
  }

  useEffect(() => {
    refetch()
  })
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  return (
    <WaitingRecruitmentScene
      waitingRecruitmentShiftList={data.waitingRecruitmentShiftsConnection.edges.map(
        e => ({ ...e.node })
      )}
      clinicalDepartments={combineNames({
        clinicalDepartments: sortAndFilterByClinicOrder({
          clinicalDepartments: data.clinicalDepartments,
        }),
        departmentNames: clinicalDepartmentNames({
          departments: data.departments,
        }),
      })}
      clinicalDepartmentIds={clinicalDepartmentIds}
      doctorName={doctorName}
      setClinicalDepartmentIds={setClinicalDepartmentIds}
      setDoctorName={setDoctorName}
      page={page}
      setPage={setPage}
      pageSize={pageSize}
      count={data.waitingRecruitmentShiftsConnection.aggregate.count}
      setDateRange={setDateRange}
      dateRangeStart={dateRangeStart}
      dateRangeEnd={dateRangeEnd}
      clearFilter={clearFilter}
      setConfirmationStatus={setConfirmationStatus}
      confirmStatusSelected={confirmStatusSelected}
    />
  )
}
