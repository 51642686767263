import ConfirmationModal from '@medical/components/ConfirmationModal'
import Editor from '@medical/components/Editor'
import { Switch, Input } from 'antd'
import { Formik } from 'formik'
import { Button } from 'primereact/button'
import { Select, DatePicker } from 'antd'
import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useCustom } from '@medical/provider/context'
import { useMutation } from '@apollo/react-hooks'
import { useApolloClient } from '@apollo/react-hooks'
import * as Yup from 'yup'
import Dropzone from 'react-dropzone'
import { MAX_FILE_SIZE } from '@medical/constant'
import { DOWNLOAD_FILE } from '../../Doctors/DoctorDetail/DoctorDetail.graphql'
import {
  AVAILABLESHIFTS,
  RECRUITMENT_POSTS,
} from '../AnnouncesList/AnnouncesList.graphql'
import { Checkbox } from 'primereact/checkbox'
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  convertToPixelCrop,
} from 'react-image-crop'
import { imageCrop } from '@medical/libs/imageCrop'
import { Modal } from 'antd'
import './style.css'
import { GET_POST_PRE_SIGNED_URL } from '@medical/components/Forms/UploadImage/UploadImage.graphql'
import uploadImage from '@medical/libs/uploadImage'

const AnnounceForm = ({
  announce: {
    title = '',
    content = '',
    isSentEmailToAllDoctors = false,
    isPublished = true,
    imageKey = '',
    isAddAvailableShift = false,
    isAddRecruitmentPost = false,
    recruitmentPostList = [],
    availableShiftList = [],
    isNotSendDoctorBlock = false,
    isNotSendDoctorWorkScheduleBlock = false,
  },
  onSubmit,
  confirmationMessage,
  submitLabel,
  isUpdate = false,
  clinicalDepartments,
  departmentNames,
}) => {
  const [availableShifts, setAvailableShifts] = useState([])
  const [allAvailableShifts, setAllAvailableShifts] = useState(
    availableShiftList
  )
  const [allRecruitmentPosts, setAllRecruitmentPosts] = useState(
    recruitmentPostList
  )
  const [recruitmentPosts, setRecruitmentPosts] = useState([])
  const [isGetAvailableShifts, setIsGetAvailableShifts] = useState(false)
  const [isGetRecruitmentPosts, setIsGetRecruitmentPosts] = useState(false)
  const [{ popup }] = useCustom()
  const { i18n } = useTranslation()
  const client = useApolloClient()
  let disableInput = false
  const [crop, setCrop] = useState()
  const [img, setImg] = useState(null)
  const [imgSrc, setImgSrc] = useState()
  const [completedCrop, setCompletedCrop] = useState()
  const [cropImgVisible, setCropImgVisible] = useState(false)
  const imgRef = useRef()
  const canvasRef = useRef()
  const [downloadFile] = useMutation(DOWNLOAD_FILE)
  const [getPostPreSignedUrl] = useMutation(GET_POST_PRE_SIGNED_URL)

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .trim()
      .min(2, i18n.t('validation.title.min', { min: 2 }))
      .max(100, i18n.t('validation.title.max', { max: 100 }))
      .required(i18n.t('validation.title.required')),
    content: Yup.string()
      .trim()
      .max(5000, i18n.t('validation.content.max', { max: 5000 }))
      .nullable()
      .required(i18n.t('validation.content.required'))
      .test('only-text', i18n.t('validation.content.required'), value => {
        const div = document.createElement('div')
        div.innerHTML = value
        return div.textContent.trim()
      }),
    isPublished: Yup.boolean(),
    isSentEmailToAllDoctors: Yup.boolean(),
    isAddAvailableShift: Yup.boolean(),
    isAddRecruitmentPost: Yup.boolean(),
    availableShiftsIds: Yup.array().when('isAddAvailableShift', {
      is: true,
      then: Yup.array().min(1, '選択してください。'),
    }),
    recruitmentPostIds: Yup.array().when('isAddRecruitmentPost', {
      is: true,
      then: Yup.array().min(1, '選択してください。'),
    }),
  })

  const downloadFileSubmit = async (key, callback) => {
    if (!key || key.length === 0) return
    try {
      const {
        data: { downloadFileS3: fileURL },
      } = await downloadFile({
        variables: {
          key,
        },
      })
      callback(fileURL)
    } catch (error) {
      callback(null)
    }
  }
  useEffect(() => {
    downloadFileSubmit(imageKey, fileURL => setImg(fileURL))
  }, [imageKey])

  const onGetDataAvailable = async (id, startTime_gte, endTime_lte) => {
    try {
      const {
        data: { availableShifts },
      } = await client.query({
        query: AVAILABLESHIFTS,
        variables: {
          where: {
            deletedAt: null,
            workSchedule: null,
            isPublished: true,
            ClinicalDepartment: {
              isDisplay: true,
              id,
            },
            startTime_gte,
            endTime_lte,
          },
        },
        fetchPolicy: 'network-only',
      })
      const listAvailableShifts = availableShifts.map(a => ({
        ...a,
        clinicName: `${a.clinicalDepartment.clinic.name}_${
          departmentNames[a.clinicalDepartment.name]
        }`,
      }))
      const listRes = []
      listAvailableShifts.forEach(available => {
        if (!allAvailableShifts.some(a => a?.id === available.id)) {
          listRes.push(available)
        }
      })
      setAvailableShifts(availableShifts)
      setAllAvailableShifts([...allAvailableShifts, ...listRes])
      setIsGetAvailableShifts(true)
    } catch (error) {
      popup.error(i18n.t('main.downloadFileError'))
    }
  }
  const onGetDataRecuitmentPosts = async id => {
    try {
      const {
        data: { recruitmentPosts },
      } = await client.query({
        query: RECRUITMENT_POSTS,
        variables: {
          where: {
            ClinicalDepartment: {
              id: id,
            },
            isPublished: true,
          },
        },
        fetchPolicy: 'network-only',
      })
      const listRecruitmentPosts = recruitmentPosts.map(p => ({
        ...p,
        clinicName: `${p.clinicalDepartment.clinic.name}_${
          departmentNames[p.clinicalDepartment.name]
        }`,
      }))
      const listRes = []
      listRecruitmentPosts.forEach(recruitmentPost => {
        if (!allRecruitmentPosts.some(p => p?.id === recruitmentPost.id)) {
          listRes.push(recruitmentPost)
        }
      })
      setRecruitmentPosts(recruitmentPosts)
      setAllRecruitmentPosts([...allRecruitmentPosts, ...listRes])
      setIsGetRecruitmentPosts(true)
    } catch (error) {
      popup.error(i18n.t('main.downloadFileError'))
    }
  }

  const centerAspectCrop = (mediaWidth, mediaHeight) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        2 / 1,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    )
  }

  const onImageLoad = event => {
    const { width, height } = event.currentTarget
    setCrop(centerAspectCrop(width, height))
    setCompletedCrop(
      convertToPixelCrop(centerAspectCrop(width, height), width, height)
    )
  }

  const onSelectFile = event => {
    if (event.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        setImgSrc(reader.result.toString() || undefined)
        setCropImgVisible(true)
      })
      reader.readAsDataURL(event[0])
    }
  }
  return (
    <div className='container'>
      <div className='box'>
        <Formik
          initialValues={{
            confirm: false,
            title,
            content,
            isPublished,
            isSentEmailToAllDoctors,
            file: null,
            clinicId: null,
            date: null,
            clinicId2: null,
            recuitmentPostId: '',
            isAddAvailableShift,
            isUpdate: isUpdate,
            availableShiftsIds: availableShiftList.map(as => as.id),
            isAddRecruitmentPost,
            recruitmentPostIds: recruitmentPostList.map(p => p.id),
            isNotSendDoctorBlock,
            isNotSendDoctorWorkScheduleBlock,
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          render={formikProps => {
            const {
              values,
              touched,
              errors,
              setTouched,
              validateForm,
              handleChange,
              setFieldValue,
            } = formikProps
            return (
              <div>
                <div className='tr'>
                  <div className='th' style={{ width: '20%' }}>
                    {i18n.t('staff.announces.titleLabel')}
                  </div>
                  <div className='td'>
                    <Input
                      value={values.title}
                      name='title'
                      onChange={handleChange}
                      style={{ width: '100%' }}
                    />
                    <div className='alert'>
                      {!!touched.title && errors.title}
                    </div>
                  </div>
                </div>
                <div className='tr'>
                  <div className='th' style={{ width: '20%' }}>
                    {i18n.t('staff.announces.contentLabel')}
                  </div>
                  <div className='td'>
                    <Editor
                      value={values.content}
                      onTextChange={e => setFieldValue('content', e.htmlValue)}
                    />
                    <div className='alert'>
                      {!!touched.content && errors.content}
                    </div>
                  </div>
                </div>
                <div className='tr'>
                  <div className='th' style={{ width: '20%' }}>
                    {i18n.t('staff.announces.isPublished')}
                  </div>
                  <div className='td'>
                    <div className='checkbox-item toggle-published'>
                      <Switch
                        checkedChildren={i18n.t('main.isPublished')}
                        unCheckedChildren={i18n.t('main.isNotPublished')}
                        name='isPublished'
                        checked={values.isPublished}
                        onChange={val => {
                          handleChange(val)
                          setFieldValue('isPublished', val)
                        }}
                      />
                      <span style={{ marginLeft: '1rem' }}>
                        {i18n.t('staff.announces.publishDescription')}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='tr'>
                  <div className='th' style={{ width: '20%' }}>
                    {i18n.t('staff.announces.isSentEmailToAllDoctors')}
                  </div>
                  <div className='td'>
                    <div className='checkbox-item toggle-published'>
                      <Switch
                        checkedChildren={i18n.t('main.isSent')}
                        unCheckedChildren={i18n.t('main.isNotSent')}
                        name='isSentEmailToAllDoctors'
                        checked={values.isSentEmailToAllDoctors}
                        onChange={val => {
                          handleChange(val)
                          setFieldValue('isSentEmailToAllDoctors', val)
                          if (!isUpdate) {
                            setFieldValue('isNotSendDoctorBlock', true)
                            setFieldValue(
                              'isNotSendDoctorWorkScheduleBlock',
                              true
                            )
                          }
                        }}
                      />
                      <span style={{ marginLeft: '1rem' }}>
                        {i18n.t('staff.announces.sendEmailDescription')}
                      </span>
                    </div>
                    {values.isSentEmailToAllDoctors && (
                      <div style={{ display: 'inline-grid', marginLeft: '8%' }}>
                        <div>
                          <Checkbox
                            style={{ margin: '12px 0px' }}
                            checked={values.isNotSendDoctorBlock}
                            defaultChecked={true}
                            onChange={e => {
                              setFieldValue('isNotSendDoctorBlock', e.checked)
                            }}
                          />
                          <label className='ml-2 font-12'>
                            メールアドレス無効化の医師に送信しない
                          </label>
                        </div>
                        <div>
                          <Checkbox
                            checked={values.isNotSendDoctorWorkScheduleBlock}
                            defaultChecked={true}
                            onChange={e => {
                              setFieldValue(
                                'isNotSendDoctorWorkScheduleBlock',
                                e.checked
                              )
                            }}
                          />
                          <label className='ml-2 font-12'>
                            シフト募集を制限をしている医師に送信しない
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='tr'>
                  <div className='th' style={{ width: '20%' }}>
                    お知らせ用画像
                  </div>
                  <div className='td'>
                    <Dropzone
                      disabled={disableInput}
                      onDrop={onSelectFile}
                      accept={['image/jpeg', 'image/png']}
                      maxSize={MAX_FILE_SIZE}
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          style={{ position: 'relative' }}
                          {...getRootProps({
                            className: values.file
                              ? 'dropzone'
                              : 'dropzone dropzone-small',
                          })}
                        >
                          <input {...getInputProps()} />
                          {canvasRef && imgSrc && (
                            <>
                              <canvas
                                ref={canvasRef}
                                id='myCanvas'
                                style={{
                                  maxHeight: 'calc(30vh - 20px)',
                                  display: cropImgVisible ? 'none' : 'unset',
                                }}
                              />
                              {values.file && (
                                <button
                                  type='button'
                                  className='close-img-btn'
                                  style={{
                                    position: 'absolute ',
                                    top: '20px',
                                    right: '20px',
                                  }}
                                  onClick={event => {
                                    event.preventDefault()
                                    event.stopPropagation()
                                    setFieldValue('file', '')
                                    setImgSrc()
                                    canvasRef.current
                                      .getContext('2d')
                                      .clearRect(
                                        0,
                                        0,
                                        canvasRef.current.width,
                                        canvasRef.current.height
                                      )
                                    canvasRef.current.width = 0
                                    canvasRef.current.height = 0
                                  }}
                                >
                                  X
                                </button>
                              )}
                            </>
                          )}
                          {!isUpdate
                            ? !values.file && <span>アップロード</span>
                            : !values.file &&
                              img && (
                                <img
                                  alt={i18n.t('doctor.uploadFile.dropFileHere')}
                                  src={img}
                                  style={{
                                    maxWidth: 'unset',
                                    display:
                                      !cropImgVisible && canvasRef && imgSrc
                                        ? 'none'
                                        : 'unset',
                                  }}
                                />
                              )}
                        </div>
                      )}
                    </Dropzone>
                    {imgSrc && (
                      <Modal
                        title='画像の表示範囲を選択する'
                        centered
                        visible={cropImgVisible}
                        footer={null}
                        onCancel={() => {
                          setImgSrc()
                          setCropImgVisible(false)
                        }}
                      >
                        <>
                          <ReactCrop
                            aspect={2 / 1}
                            crop={crop}
                            onChange={(_, percentCrop) => setCrop(percentCrop)}
                            onComplete={c => setCompletedCrop(c)}
                          >
                            <img
                              ref={imgRef}
                              alt='プレビュー画像'
                              src={imgSrc}
                              onLoad={onImageLoad}
                            />
                          </ReactCrop>
                          <div
                            style={{
                              margin: '1rem 0',
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Button
                              onClick={() => {
                                if (imgSrc) {
                                  const ctx = canvasRef.current.getContext('2d')
                                  if (ctx) {
                                    const file = imageCrop({
                                      ctx,
                                      imgRef,
                                      canvasRef,
                                      completedCrop,
                                      scale: 1,
                                    })
                                    file.current.toBlob(async blob => {
                                      const newFile = new File(
                                        [blob],
                                        `upload_img_${moment().valueOf()}`,
                                        {
                                          type: 'image/jpeg',
                                        }
                                      )
                                      const {
                                        data: postPreSignedUrl,
                                      } = await getPostPreSignedUrl({
                                        variables: {
                                          fileName: newFile.name,
                                          mimetype: newFile.type,
                                        },
                                      })
                                      const key = await uploadImage(
                                        i18n,
                                        popup,
                                        postPreSignedUrl,
                                        newFile
                                      )
                                      setFieldValue('file', key)
                                    }, 'image/jpeg')
                                  }
                                }
                                setCropImgVisible(false)
                              }}
                              label='この画像を登録する'
                              className='reflect-button'
                              style={{ margin: '0 1rem 0' }}
                            />
                            <Button
                              onClick={() => {
                                setImgSrc()
                                setCropImgVisible(false)
                              }}
                              label={i18n.t('doctor.identity.cancelButton')}
                            />
                          </div>
                        </>
                      </Modal>
                    )}
                    <div className='alert'>{touched.file && errors.file}</div>
                  </div>
                </div>
                <div className='tr'>
                  <div className='th' style={{ width: '20%' }}>
                    対象募集シフト
                  </div>
                  <div className='td'>
                    <div className='checkbox-item toggle-published'>
                      <Switch
                        checkedChildren='登録'
                        unCheckedChildren='未登録'
                        name='isAddAvailableShift'
                        checked={values.isAddAvailableShift}
                        onChange={val => {
                          handleChange(val)
                          setFieldValue('isAddAvailableShift', val)
                        }}
                      />
                    </div>
                  </div>
                </div>
                {values.isAddAvailableShift && (
                  <>
                    <div className='tr'>
                      <div className='th' style={{ width: '20%' }}></div>
                      <div className='td'>
                        <Select
                          style={{ width: '100% ' }}
                          placeholder='クリニックを選択'
                          value={values.clinicId}
                          onChange={e => {
                            setFieldValue('clinicId', e)
                            if (e === '' || values.date === null) return
                            const id = e
                            const startTime_gte = values.date
                              .startOf('day')
                              .toDate()
                            const endTime_lte = values.date
                              .endOf('day')
                              .toDate()
                            onGetDataAvailable(id, startTime_gte, endTime_lte)
                          }}
                        >
                          {clinicalDepartments.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.clinicName}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                    <div className='tr'>
                      <div className='th' style={{ width: '20%' }}></div>
                      <div className='td'>
                        <DatePicker
                          style={{ width: '100% ' }}
                          placeholder='※カレンダーで日付を選択'
                          value={values.date}
                          onChange={date => {
                            setFieldValue('date', date)
                            if (values.clinicId === '' || date === null) return
                            const id = values.clinicId
                            const startTime_gte = date.startOf('day').toDate()
                            const endTime_lte = date.endOf('day').toDate()
                            onGetDataAvailable(id, startTime_gte, endTime_lte)
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className='tr'
                      style={{
                        display:
                          availableShifts.length === 0 && isGetAvailableShifts
                            ? 'flex'
                            : 'none',
                      }}
                    >
                      <div className='th' style={{ width: '20%' }}></div>
                      <div className='td'>対象とする募集シフトがありません</div>
                    </div>
                    <div
                      className='tr'
                      style={{
                        display: availableShifts.length === 0 ? 'none' : null,
                      }}
                    >
                      <div className='th' style={{ width: '20%' }}></div>
                      <div className='td'>
                        <ul
                          className={
                            availableShifts.length > 3
                              ? 'list-itemm scroll-yy'
                              : 'list-itemm '
                          }
                        >
                          {availableShifts.length > 0 &&
                            availableShifts.map(a => {
                              return (
                                <li key={a.id} className='checkbox-container '>
                                  <Checkbox
                                    value={a.id}
                                    checked={values.availableShiftsIds.some(
                                      id => id === a.id
                                    )}
                                    onChange={e => {
                                      values.availableShiftsIds.some(
                                        id => id === e.value
                                      )
                                        ? setFieldValue(
                                            'availableShiftsIds',
                                            values.availableShiftsIds.filter(
                                              id => id !== e.value
                                            )
                                          )
                                        : setFieldValue('availableShiftsIds', [
                                            ...values.availableShiftsIds,
                                            e.value,
                                          ])
                                    }}
                                  />
                                  <label
                                    htmlFor={`${a.id}`}
                                    className='checkbox-label'
                                  >
                                    {moment(a.startTime).format(
                                      'MM [月] DD [日]   LT [~] '
                                    )}
                                    {moment(a.endTime).format('LT')}
                                  </label>
                                </li>
                              )
                            })}
                        </ul>
                      </div>
                    </div>
                    <div className='tr'>
                      <div className='th' style={{ width: '20%' }}></div>
                      <div
                        className='td'
                        style={{
                          display:
                            values.availableShiftsIds.length > 0
                              ? 'flex'
                              : null,
                        }}
                      >
                        <span
                          style={{
                            marginRight: '10px',
                            fontWeight: 700,
                            width: '20%',
                          }}
                        >
                          対象とする募集シフト:{' '}
                        </span>
                        <ul
                          className={
                            values.availableShiftsIds.length > 3
                              ? 'list-itemm scroll-yy'
                              : 'list-itemm '
                          }
                          style={{
                            minWidth: '350px',
                            maxWidth: '80%',
                            padding: '15px',
                            border: '1px solid #d9d9d9',
                            display:
                              values.availableShiftsIds.length === 0
                                ? 'none'
                                : null,
                          }}
                        >
                          {values.availableShiftsIds.map(id =>
                            allAvailableShifts.map(as => {
                              if (id === as.id) {
                                return (
                                  <li
                                    className='checkbox-container '
                                    key={as.id}
                                  >
                                    {`${as.clinicName} ${moment(
                                      as.startTime
                                    ).format('MM [月] DD [日]   LT [~] ')} 
                                      ${moment(as.endTime).format('LT')}`}
                                  </li>
                                )
                              }
                              return null
                            })
                          )}
                        </ul>
                        <div className='alert'>
                          {touched.availableShiftsIds &&
                            errors.availableShiftsIds}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className='tr'>
                  <div className='th' style={{ width: '20%' }}>
                    対象募集要項
                  </div>
                  <div className='td'>
                    <div className='checkbox-item toggle-published'>
                      <Switch
                        checkedChildren='登録'
                        unCheckedChildren='未登録'
                        name='isAddRecruitmentPost'
                        checked={values.isAddRecruitmentPost}
                        onChange={val => {
                          handleChange(val)
                          setFieldValue('isAddRecruitmentPost', val)
                        }}
                      />
                    </div>
                  </div>
                </div>
                {values.isAddRecruitmentPost && (
                  <>
                    <div
                      className='tr'
                      style={{
                        display:
                          values.recruitmentPostIds.length > 0 ? 'flex' : null,
                      }}
                    >
                      <div className='th' style={{ width: '20%' }}></div>
                      <div className='td'>
                        <Select
                          style={{ width: '100% ' }}
                          value={values.clinicId2}
                          placeholder='クリニックを選択'
                          onChange={e => {
                            setFieldValue('clinicId2', e)
                            onGetDataRecuitmentPosts(e)
                          }}
                        >
                          {clinicalDepartments.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.clinicName}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                    <div
                      className='tr'
                      style={{
                        display:
                          recruitmentPosts.length === 0 && isGetRecruitmentPosts
                            ? 'flex'
                            : 'none',
                      }}
                    >
                      <div className='th' style={{ width: '20%' }}></div>
                      <div className='td'>対象とする募集要項がありません</div>
                    </div>
                    <div
                      className='tr'
                      style={{
                        display: recruitmentPosts.length === 0 ? 'none' : null,
                      }}
                    >
                      <div className='th' style={{ width: '20%' }}></div>
                      <div className='td'>
                        <ul
                          className={
                            recruitmentPosts.length > 3
                              ? 'list-itemm scroll-yy'
                              : 'list-itemm '
                          }
                        >
                          {recruitmentPosts.length > 0 &&
                            recruitmentPosts.map(p => {
                              return (
                                <li key={p.id} className='checkbox-container '>
                                  <Checkbox
                                    value={p.id}
                                    checked={values.recruitmentPostIds.some(
                                      id => id === p.id
                                    )}
                                    onChange={e => {
                                      values.recruitmentPostIds.some(
                                        id => id === e.value
                                      )
                                        ? setFieldValue(
                                            'recruitmentPostIds',
                                            values.recruitmentPostIds.filter(
                                              id => id !== e.value
                                            )
                                          )
                                        : setFieldValue('recruitmentPostIds', [
                                            ...values.recruitmentPostIds,
                                            e.value,
                                          ])
                                    }}
                                  />
                                  <label
                                    htmlFor={`${p.id}`}
                                    className='checkbox-label'
                                  >
                                    {p.title}
                                  </label>
                                </li>
                              )
                            })}
                        </ul>
                      </div>
                    </div>
                    <div className='tr'>
                      <div className='th' style={{ width: '20%' }}></div>
                      <div
                        className='td'
                        style={{
                          display:
                            values.recruitmentPostIds.length > 0
                              ? 'flex'
                              : null,
                        }}
                      >
                        <span
                          style={{
                            marginRight: '10px',
                            fontWeight: 700,
                            width: '20%',
                          }}
                        >
                          対象とする募集要項:{' '}
                        </span>
                        <ul
                          className={
                            values.recruitmentPostIds.length > 3
                              ? 'list-itemm scroll-yy'
                              : 'list-itemm '
                          }
                          style={{
                            minWidth: '350px',
                            maxWidth: '80%',
                            padding: '15px',
                            border: '1px solid #d9d9d9',
                            display:
                              values.recruitmentPostIds.length === 0
                                ? 'none'
                                : null,
                          }}
                        >
                          {values.recruitmentPostIds.map(id =>
                            allRecruitmentPosts.map(p => {
                              if (id === p.id) {
                                return (
                                  <li
                                    className='checkbox-container '
                                    key={p.id}
                                  >
                                    {`${p.clinicName} ${p.title}`}
                                  </li>
                                )
                              }
                              return null
                            })
                          )}
                        </ul>
                        <div className='alert'>
                          {touched.recruitmentPostIds &&
                            errors.recruitmentPostIds}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className='modal-bottom'>
                  <Button
                    label={submitLabel}
                    onClick={() => {
                      validateForm().then(response => {
                        if (!Object.keys(response).length) {
                          setFieldValue('confirm', true)
                        } else {
                          setTouched(response)
                        }
                      })
                    }}
                    className='p-button-success'
                  />
                </div>

                <ConfirmationModal
                  {...formikProps}
                  confirmationMessage={confirmationMessage}
                />
              </div>
            )
          }}
        />
      </div>
    </div>
  )
}

export default AnnounceForm
