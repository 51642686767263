import { useQuery, useMutation } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import {
  STAFF_ADD_COMMENT,
  STAFF_DELETE_ALL_COMMENT,
  STAFF_DELETE_COMMENT,
} from '@medical/constant/permissions'
import { checkStaffPermission } from '@medical/libs'
import React from 'react'

import {
  DETAIL_WAITING_RECRUITMENT_COMMENT,
  UPDATE_WAITING_RECRUITMENT_SHIFT_STATUS
} from './DetailWaitingRecruitment.graphql'
import { DetailWaitingRecruitmentScene } from './DetailWaitingRecruitmentScene'
import { useState } from 'react'
import { useCustom } from '@medical/provider/context'

export const DetailWaitingRecruitment = ({
  closeModal,
  location: { state },
  match: {
    params: { id },
  },
}) => {
  const [{ popup, i18n }] = useCustom()
  const { loading, error, data } = useQuery(
    DETAIL_WAITING_RECRUITMENT_COMMENT,
    {
      variables: {
        where: {
          WaitingRecruitmentShift: {
            id,
          },
        },
      },
      fetchPolicy: 'network-only',
    }
  )

  const [updateStatus] = useMutation(UPDATE_WAITING_RECRUITMENT_SHIFT_STATUS)

  const onSubmit = async ( values, { setSubmitting }) => {
    try {
      setSubmitting(false)
      await updateStatus({
        variables: {
          id: id,
          confirmationStatus: values.confirmationStatus.value,
        },
      })
      closeModal()
      popup.success(i18n.t('staff.hourlyPayment.submissionSuccess'))
    } catch (error) {
      popup.error(error)
    }
  }

  const isAddPermitted = checkStaffPermission({
    functionName: STAFF_ADD_COMMENT,
  })
  const isDeletePermitted = checkStaffPermission({
    functionName: STAFF_DELETE_COMMENT,
  })
  const isDeleteAllComment = checkStaffPermission({
    functionName: STAFF_DELETE_ALL_COMMENT,
  })
  if (loading) return <ProgressSpinner />

  if (error) return <ErrorComponent error={error} />
  return (
    <DetailWaitingRecruitmentScene
      workScheduleComment={data.workScheduleComments}
      detailWaitingRecruitment={state.waitingAvailableShift}
      isAddPermitted={isAddPermitted}
      isDeletePermitted={isDeletePermitted}
      staffMeId={data.staffMe.id}
      onSubmit={onSubmit}
      isDeleteAllComment={isDeleteAllComment}
    />
  )
}
