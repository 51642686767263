import React, {Suspense, useMemo} from 'react';
import BreadCrumb from "../../../components/Breadcrumb";
import {Button} from "primereact/button";
import {Link} from "react-router-dom";
import Router from "../../../routes/router";
import moment from "moment/moment";
import {dateValidation} from '@medical/libs'
import styles from './Style'
import {Formik} from "formik";
import AutoComplete from "../../../components/AutoComplete";
import {ProgressSpinner} from "../../../components";

const DataTable = React.lazy(() => import('./components/ExpectationDoubleRecruitmentRecordDataTable'))

const ExpectationDoubleRecruitmentRecordScene = (
  {
    onSubmit,
    handleSynchronized,
    isUploaded,
    convertClinic,
    clinics,
    clinicalDepartments,
    holidays,
    history,
    year,
    month,
    generateArrayWithObjectValues,
    convertClinicalDepartment,
    getExpectationDoubleRecruitmentRecord
  }) => {

  const currentDate = useMemo(
    () =>
      moment()
        .year(year)
        .month(month - 1)
        .startOf('month')
        .toISOString(),
    [year, month]
  )
  const date = useMemo(() => moment(currentDate), [currentDate])
  const prevMonth = useMemo(() => moment(date).subtract(1, 'month'), [date])
  const nextMonth = useMemo(() => moment(date).add(1, 'month'), [date])
  const isPreviousDisabled = useMemo(
    () =>
      date.isSameOrBefore(moment().subtract(1, 'years'), 'month') &&
      dateValidation({
        year: prevMonth.year(),
        month: prevMonth.month() + 1,
      }),
    [date, prevMonth]
  )

  const isNextDisabled = useMemo(
    () =>
      dateValidation({
        year: nextMonth.year(),
        month: nextMonth.month() + 1,
      }) && date.isSameOrAfter(moment().add(6, 'quarters'), 'month'),
    [date, nextMonth]
  )

  return (
    <>
      <div className={'staff-header'}>
        <div className={'staff-title'}
             style={{
               margin: '0',
               marginTop: '20px'
             }}>
          二診目希望登録
          <BreadCrumb
            items={[
              {
                title: '二診目希望登録',
              },
            ]}
          />
        </div>
        <div className='staff-buttons'
             style={{
               margin: '0',
               marginTop: '20px'
             }}>
          <Button
            icon='pi pi-plus'
            className='p-button-normal'
            label={'パターン登録'}
            onClick={() => history.push({
              pathname: Router.get(Router.staffExpectationDoubleRecruitmentRecordPattern,
                {
                  year: date.year(),
                  month: date.format('MM'),
                }
              )
            })}
          />
        </div>
      </div>
      <div className='container is-max' style={{
        position: 'relative'
      }}>
        {
          isUploaded && <div style={styles.loading}>
            <ProgressSpinner/>
          </div>
        }
        <Formik
          onSubmit={onSubmit}
          initialValues={{
            clinics: convertClinic(clinics),
            clinic_id: '',
            clinicalDepartments: [],
            clinicalDepartmentsOldData: [],
            clinical_department_id: '',
            isSelectClinic: false,
            isSelectClinicalDepartment: false,
            data: generateArrayWithObjectValues(holidays, null, year, month),
            valueClinicalDepartments: true
          }}
          render={formikProps => {
            const {
              values,
              errors,
              setFieldValue,
              handleSubmit,
              touched,
              resetForm,
            } = formikProps

            return (
              <div className={'box pt-3'}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '2px',
                }}>
                  <p style={{
                    fontSize: '12px',
                  }}>時間帯ごとの必要な医師人数の希望を記録する機能です</p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                  <div
                    onClick={isPreviousDisabled ? e => e.preventDefault() : () => {
                      resetForm()
                      setFieldValue('data', generateArrayWithObjectValues(holidays, null, prevMonth.year(), prevMonth.format('MM')))
                      history.push({
                        pathname: Router.get(Router.staffExpectationDoubleRecruitmentRecord,
                          {
                            year: prevMonth.year(),
                            month: prevMonth.format('MM'),
                          }
                        )
                      })
                    }}
                  >
                    <Button icon='pi pi-chevron-left' disabled={isPreviousDisabled}/>
                  </div>
                  <div style={styles.title}>
                    {moment(currentDate).format('YYYY年MM月')}
                  </div>
                  <div
                    onClick={isNextDisabled ? e => e.preventDefault() : () => {
                      resetForm()
                      setFieldValue('data', generateArrayWithObjectValues(holidays, null, nextMonth.year(), nextMonth.format('MM')))
                      history.push({
                        pathname: Router.get(Router.staffExpectationDoubleRecruitmentRecord,
                          {
                            year: nextMonth.year(),
                            month: nextMonth.format('MM'),
                          }
                        )
                      })
                    }}
                  >
                    <Button icon='pi pi-chevron-right' disabled={isNextDisabled}/>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    margin: '20px 0 10px 0',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                    }}>
                    <AutoComplete
                      {...formikProps}
                      disabled={isUploaded}
                      name={'clinic'}
                      field='name'
                      searchFields={['name']}
                      allSuggestions={values.clinics}
                      suggestionName='clinics'
                      width='60vw'
                      maxWidth='200px'
                      placeholder='クリニックを選択'
                      setCurrentClinicData={
                        async val => {
                          setFieldValue('clinics', convertClinic(clinics))
                          setFieldValue('data', generateArrayWithObjectValues(holidays, null, year, month))
                          setFieldValue('clinical_department_id', '')
                          setFieldValue('clinicalDepartments', [])
                          setFieldValue('clinicalDepartmentsOldData', [])
                          setFieldValue('valueClinicalDepartments', true)
                          setFieldValue('isSelectClinicalDepartment', false)
                          if (val.id) {
                            setFieldValue('clinic_id', val.id)
                            setFieldValue('isSelectClinic', true)
                            const {data} = await clinicalDepartments({
                              variables: {
                                id: val.id
                              }
                            })
                            setFieldValue('clinicalDepartments', convertClinicalDepartment(data.clinicalDepartments))
                            setFieldValue('clinicalDepartmentsOldData', convertClinicalDepartment(data.clinicalDepartments))
                          } else {
                            setFieldValue('isSelectClinic', false)
                          }
                        }
                      }
                    />
                    <AutoComplete
                      {...formikProps}
                      value={values.valueClinicalDepartments}
                      disabled={isUploaded || !values.isSelectClinic}
                      name={'clinicDepartment'}
                      field='label'
                      searchFields={['label']}
                      allSuggestions={values.clinicalDepartments}
                      suggestionName='clinicalDepartments'
                      width='60vw'
                      maxWidth='300px'
                      placeholder='診獠科選択'
                      setCurrentClinicData={
                        async val => {
                          setFieldValue('clinicalDepartments', convertClinicalDepartment(values.clinicalDepartmentsOldData))
                          setFieldValue('valueClinicalDepartments', false)
                          if (val.id) {
                            setFieldValue('clinical_department_id', val.id)
                            setFieldValue('isSelectClinicalDepartment', true)
                            const {data} = await getExpectationDoubleRecruitmentRecord({
                              variables: {
                                clinic: values.clinic_id,
                                clinicalDepartment: val.id,
                                month: +month,
                                year: +year,
                              }
                            })
                            if (data?.getExpectationDoubleRecruitmentRecord && data?.getExpectationDoubleRecruitmentRecord.length > 0) {
                              const convertData = data?.getExpectationDoubleRecruitmentRecord.map(item => {
                                return {
                                  date: moment.utc(item.shiftDate).format('YYYY-MM-DD'),
                                  morning: item.morningShiftDoctors,
                                  afternoon: item.afternoonShiftDoctors,
                                  night: item.nightShiftDoctors,
                                }
                              })
                              setFieldValue('data', convertData)
                            } else {
                              setFieldValue('data', generateArrayWithObjectValues(holidays, null, year, month))
                            }
                          } else {
                            setFieldValue('isSelectClinicalDepartment', false)
                          }
                        }
                      }
                    />
                  </div>
                  <div>
                    <Button
                      type={'button'}
                      onClick={async () => {
                        await handleSynchronized(values.clinic_id, values.clinical_department_id, setFieldValue, values.data)
                      }}
                      disabled={isUploaded || !values.isSelectClinic || !values.isSelectClinicalDepartment}
                      className='p-button-secondary'
                      label={'登録パターンを反映する'}
                    />
                  </div>
                </div>
                <Suspense fallback={<ProgressSpinner/>}>
                  <DataTable
                    data={values.data}
                    setFieldValue={setFieldValue}/>
                </Suspense>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                    marginTop: '20px'
                  }}>
                  <Button
                    onClick={() => {
                      handleSubmit();
                    }}
                    className='reflect-button'
                    disabled={isUploaded || !values.isSelectClinic || !values.isSelectClinicalDepartment}
                    type='submit'
                    label={'確定する'}/>
                </div>
              </div>
            )
          }}
        />
      </div>
    </>
  );
};

export default ExpectationDoubleRecruitmentRecordScene;