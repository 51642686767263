import gql from 'graphql-tag'

export const DETAIL_WAITING_RECRUITMENT_COMMENT = gql`
  query DETAIL_WAITING_RECRUITMENT_COMMENT(
    $where: WorkScheduleCommentWhereInput
  ) {
    staffMe {
      id
    }
    workScheduleComments(where: $where) {
      id
      text
      type
      staff {
        id
        lastname
        firstname
      }
    }
  }
`

export const UPDATE_WAITING_RECRUITMENT_SHIFT_STATUS = gql`
  mutation UPDATE_WAITING_RECRUITMENT_SHIFT_STATUS(
    $id: ID!
    $confirmationStatus: String!
  ) {
    staffRecruitmentShiftUpdate(id: $id, confirmationStatus: $confirmationStatus)
  }
`