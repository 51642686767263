import BreadCrumb from '@medical/components/Breadcrumb'
import Router from '@medical/routes/router'
import { Formik } from 'formik'
import { InputText } from 'primereact/inputtext'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Paginator } from 'primereact/paginator'
import React from 'react'
import { Link } from 'react-router-dom'
import { MultiSelect } from 'primereact/multiselect'
import queryString from 'query-string'

const RecruitmentPostListScene = ({
  i18n,
  edges,
  rowsPerPage,
  page,
  history,
  count,
  onPageChange,
  onSubmit,
  isCreatePermitted,
  title,
  clinicalDepartments,
  clinicalDepartmentIds = [],
}) => {
  const onSearchClinicDepartments = e => {
    history.push({
      pathname: Router.staffRecruitmentPosts,
      search: queryString.stringify({
        rowsPerPage,
        title,
        clinicalDepartmentIds: e.value.map(({ id }) => id).join('＿'),
      }),
    })
  }
  const getClinicDepartmentName = clinicalDepartmentId => {
    return clinicalDepartments.find(({ id }) => id === clinicalDepartmentId)
      .clinicName
  }
  return (
    <React.Fragment>
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('staff.menuBar.recruitmentPost')}
          <BreadCrumb
            items={[
              {
                title: i18n.t('staff.menuBar.recruitmentDoctor'),
                to: Router.staffRecruitmentDoctorList,
              },
              { title: i18n.t('staff.menuBar.recruitmentPost') },
            ]}
          />
        </div>
        {isCreatePermitted ? (
          <div className='staff-buttons'>
            <Link to={Router.staffRecruitmentPostCreate}>
              <Button
                label='募集要項新規作成'
                icon='pi pi-plus'
                className='p-button-normal'
              />
            </Link>
          </div>
        ) : null}
      </div>
      <div className='container'>
        <div className='box'>
          <Formik
            enableReinitialize
            initialValues={{
              title,
            }}
            render={formikProps => {
              const { values, handleChange, handleBlur } = formikProps
              return (
                <div className='search-parent'>
                  <MultiSelect
                    value={clinicalDepartments.filter(({ id }) =>
                      clinicalDepartmentIds.includes(id)
                    )}
                    fixedPlaceholder
                    options={clinicalDepartments}
                    optionLabel='clinicName'
                    onChange={e => {
                      onSearchClinicDepartments(e)
                    }}
                    filter
                    placeholder={i18n.t('main.chooseClinic')}
                  />
                  <InputText
                    name='title'
                    value={values.title}
                    placeholder='検索'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ width: '200px', marginLeft: '20px' }}
                  />
                  <Link
                    to={{
                      pathname: Router.staffRecruitmentPosts,
                      search: queryString.stringify({
                        rowsPerPage,
                        title: values.title,
                        clinicalDepartmentIds: clinicalDepartmentIds.join('＿'),
                      }),
                    }}
                    style={{ marginLeft: '20px' }}
                  >
                    <Button label={i18n.t('main.search')} icon='pi pi-search' />
                  </Link>
                  {title || clinicalDepartmentIds.length > 0 ? (
                    <Link
                      to={{
                        pathname: Router.staffRecruitmentPosts,
                        search: queryString.stringify({
                          rowsPerPage,
                        }),
                      }}
                    >
                      <Button
                        icon='pi pi-times'
                        className='p-button-secondary'
                        style={{ marginLeft: '20px' }}
                      />
                    </Link>
                  ) : null}
                </div>
              )
            }}
          />

          <Formik
            initialValues={{ confirm: false, announceId: '' }}
            onSubmit={onSubmit}
            render={formikProps => (
              <div>
                <table className='table' style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th width='14%'>クリニック名</th>
                      <th width='30%'>
                        {i18n.t('staff.announces.titleLabel')}
                      </th>
                      <th width='13%'>募集要項作成日</th>
                      <th width='13%'>募集要項更新日</th>
                      <th width='8%'>編集</th>
                      <th width='8%'>複製</th>
                      <th width='14%'>掲載ステータス</th>
                    </tr>
                  </thead>
                  <tbody>
                    {edges.map(({ node }) => (
                      <tr key={node.id}>
                        <td>
                          {node.clinicalDepartment?.id ? getClinicDepartmentName(node.clinicalDepartment.id) : 'なし'}
                        </td>
                        <td>{node.title}</td>
                        <td className='center'>
                          {moment(node.createdAt).format('LL (dd)')}
                        </td>
                        <td className='center'>
                          {moment(node.updatedAt).format('LL (dd)')}
                        </td>
                        <td className='center'>
                          <Link
                            to={Router.get(Router.staffRecruitmentPostUpdate, {
                              recruitment_postId: node.id,
                            })}
                          >
                            <Button icon='pi pi-info' />
                          </Link>
                        </td>
                        <td className='center'>
                          <Link
                            to={{
                              pathname: Router.staffRecruitmentPostCreate,
                              state: {
                                recruitment: node,
                              },
                            }}
                          >
                            <Button icon='pi pi-pencil' />
                          </Link>
                        </td>
                        <td className='center'>
                          {node.isPublished ? (
                            <span style={{ color: '#369EFD' }}>掲載中</span>
                          ) : (
                            <span style={{ color: '#647380' }}>未掲載</span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          />
          <Paginator
            rows={rowsPerPage}
            totalRecords={count}
            first={(page - 1) * rowsPerPage}
            onPageChange={onPageChange}
            leftContent={
              <div className='paginator-total'>
                {i18n.t('main.paginationTotal')}: {count}
              </div>
            }
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default RecruitmentPostListScene
