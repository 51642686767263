import { CART_TOKEN_KEY } from '@medical/constant'
import moment from 'moment'

import { ADD_ITEM, REMOVE_ITEM, SET_CART } from './cart.action'

const cartReducer = (
  state = {
    items: JSON.parse(localStorage.getItem(CART_TOKEN_KEY)) || [],
  },
  action
) => {
  const { type, payload } = action
  switch (type) {
    case SET_CART:
      let cartItems = payload
      if (cartItems.lenght > 0) {
        cartItems = payload.filter(item => moment.utc(item.startTime).isBefore(moment.utc()))
      }
      return {
        ...state,
        items: cartItems || [],
      }
    case ADD_ITEM:
      if (moment.utc(payload.startTime).isBefore(moment.utc())) {
        return {
          ...state,
          items: [...state.items],
        }
      }
      return {
        ...state,
        items: [...state.items, payload],
      }
    case REMOVE_ITEM:
      return {
        ...state,
        items: payload || [],
      }
    default:
      return state
  }
}

export default cartReducer
