import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import CustomDropdown from '@medical/components/DropdownForSignup'
import TextInput from '@medical/components/TextInputForCommentPage'
import { useCustom } from '@medical/provider/context'
import { Formik } from 'formik'
import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'

import { AVAILABLE_SHIFT_DETAIL_GENERATOR } from '../DetailAvailableShift/DetailAvailableShift.graphql'
import { STAFF_GET_AS_GROUP_BY_ID } from '../DetailScheduleShiftGroup/DetailScheduleShiftGroup.graphql'
import { DETAIL_WAITING_RECRUITMENT_COMMENT } from '../DetailWaitingRecruitment/DetailWaitingRecruitment.graphql'
import { SCHEDULE_AVAILABLESHIFT_CONNECTION } from '../RecruitmentScheduleShift/RecruitmentScheduleShiftList/RecruitmentScheduleShiftList.graphql'
import { DETAIL_WORK_SCHEDULE } from './DetailWorkSchedule.graphql'
import {
  ADD_COMMENT,
  DELETE_COMMENT,
  GET_ENUM_COMMENT,
} from './WorkScheduleComment.graphql'
import moment from 'moment'

export const WorkScheduleComment = ({
  workScheduleId,
  staffId,
  comments,
  isMoneyPermitted,
  isAddPermitted,
  isDeletePermitted,
  isTabPanel,
  isShowAdd,
  scheduledShiftGroupId,
  isRecruitmentShift,
  availableShiftId,
  availableShiftComment,
  scheduledAvailableShiftGroupId,
  staffCommentAvailableShiftGroup,
  dataEnumComment,
  availableShiftGroupComment,
  isWaitingRecruitment = false,
  waitingRecruitmentShiftId,
  isShowType = true,
  isDeleteAllComment = false,
}) => {
  let dataComments = comments

  if (availableShiftComment) {
    dataComments = [...comments, ...availableShiftComment]
  }
  if (availableShiftGroupComment) {
    dataComments = [...dataComments, ...availableShiftGroupComment]
  }

  if (staffCommentAvailableShiftGroup) {
    dataComments = [...comments, ...staffCommentAvailableShiftGroup]
  }
  const [{ i18n, popup }] = useCustom()
  const [addComment] = useMutation(ADD_COMMENT)

  const [deleteComment] = useMutation(DELETE_COMMENT)
  const [commentOption, setCommentOption] = useState([])
  const { loading, error, data } = useQuery(GET_ENUM_COMMENT, {
    variables: {
      name: 'commentTypes',
    },
    skip: dataEnumComment !== undefined || isWaitingRecruitment,
  })

  useEffect(() => {
    if (data && data.enumValues) {
      setCommentOption(data.enumValues)
    }
    if (dataEnumComment) {
      setCommentOption(dataEnumComment.enumValues)
    }
  }, [data, dataEnumComment])
  const converTypeComment = type => {
    let result = {}
    if (commentOption) {
      result = commentOption.find(item => item.value === type)
    }
    return result ? result.description : ''
  }
  if (loading && dataEnumComment === undefined) return <ProgressSpinner />
  if (error && dataEnumComment === undefined)
    return <ErrorComponent error={error} />

  const onAddComment = async (
    { text, commentType },
    { resetForm, setSubmitting }
  ) => {
    try {
      await addComment({
        variables: {
          workScheduleId,
          availableShiftId,
          waitingRecruitmentShiftId,
          scheduledAvailableShiftGroupId:
            scheduledShiftGroupId || scheduledAvailableShiftGroupId,
          text,
          type: commentType,
        },
        update: (store, { data: { staffAddComment } }) => {
          if (availableShiftId) {
            const availableShiftData = store.readQuery({
              query: AVAILABLE_SHIFT_DETAIL_GENERATOR(isMoneyPermitted),
              variables: {
                availableShiftId,
                detailWhere: {
                  AvailableShift: {
                    id: availableShiftId,
                  },
                  deletedAt: null,
                },
              },
            })
            availableShiftData.availableShift.staffComments.push(
              staffAddComment
            )
            store.writeQuery({
              query: AVAILABLE_SHIFT_DETAIL_GENERATOR(isMoneyPermitted),
              data: availableShiftData,
              variables: {
                availableShiftId,
                detailWhere: {
                  AvailableShift: {
                    id: availableShiftId,
                  },
                  deletedAt: null,
                },
              },
            })
          } else if (waitingRecruitmentShiftId) {
            const variables = {
              where: {
                WaitingRecruitmentShift: {
                  id: waitingRecruitmentShiftId,
                },
              },
            }
            const waitingRecruitmentShiftData = store.readQuery({
              query: DETAIL_WAITING_RECRUITMENT_COMMENT,
              variables,
            })
            waitingRecruitmentShiftData.workScheduleComments.push(
              staffAddComment
            )
            store.writeQuery({
              query: DETAIL_WAITING_RECRUITMENT_COMMENT,
              data: waitingRecruitmentShiftData,
              variables,
            })
          } else if (scheduledAvailableShiftGroupId) {
            const variables = {
              where: {
                isRecruitmentShift: true,
                name_contains: '',
                AvailableShift_some: {
                  workSchedule: null,
                },
                id: scheduledAvailableShiftGroupId,
              },
              availableIsEditedIndividually: true,
              availableWorkSchedule: null,
              availableDeletedAt: null,
            }
            const scheduledAvailableShiftGroupData = store.readQuery({
              query: SCHEDULE_AVAILABLESHIFT_CONNECTION,
              variables,
            })
            scheduledAvailableShiftGroupData.scheduledAvailableShiftGroupsConnection.edges[0].node.staffComments.push(
              staffAddComment
            )
            store.writeQuery({
              query: SCHEDULE_AVAILABLESHIFT_CONNECTION,
              data: scheduledAvailableShiftGroupData,
              variables,
            })
          } else if (scheduledShiftGroupId) {
            const variables = {
              where: {
                id: scheduledShiftGroupId,
              },
              availableShiftsWhere2: {
                OR: [
                  {
                    deletedAt: null,
                  },
                  {
                    WorkSchedule: {
                      deletedStatus: 'WORKSCHEDULE_CANCEL',
                      deletedAt_not: null,
                    },
                  },
                ],
              },
            }
            const scheduledShiftGroupData = store.readQuery({
              query: STAFF_GET_AS_GROUP_BY_ID,
              variables,
            })
            scheduledShiftGroupData.scheduledAvailableShiftGroup.staffComments.push(
              staffAddComment
            )
            store.writeQuery({
              query: STAFF_GET_AS_GROUP_BY_ID,
              data: scheduledShiftGroupData,
              variables,
            })
          } else {
            const workScheduleData = store.readQuery({
              query: DETAIL_WORK_SCHEDULE(isMoneyPermitted),
              variables: {
                workScheduleId,
              },
            })
            workScheduleData.workSchedule.staffComments.push(staffAddComment)
            store.writeQuery({
              query: DETAIL_WORK_SCHEDULE(isMoneyPermitted),
              data: workScheduleData,
              variables: {
                workScheduleId,
              },
              availableShiftsWhere2: {
                OR: [
                  {
                    deletedAt: null,
                  },
                  {
                    WorkSchedule: {
                      deletedStatus: 'WORKSCHEDULE_CANCEL',
                      deletedAt_not: null,
                    },
                  },
                ],
              },
            })
          }
        },
      })
      resetForm()
      popup.success(i18n.t('main.staffComment.createCommentSuccess'))
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }

  const onDeleteSubmit = async (
    { workScheduleCommentId },
    { resetForm, setSubmitting }
  ) => {
    try {
      await deleteComment({
        variables: {
          workScheduleCommentId,
        },
        update: store => {
          if (scheduledShiftGroupId) {
            const availableShiftGroupData = store.readQuery({
              query: STAFF_GET_AS_GROUP_BY_ID,
              variables: {
                where: {
                  id: scheduledShiftGroupId,
                },
                availableShiftsWhere2: {
                  OR: [
                    {
                      deletedAt: null,
                    },
                    {
                      WorkSchedule: {
                        deletedStatus: 'WORKSCHEDULE_CANCEL',
                        deletedAt_not: null,
                      },
                    },
                  ],
                },
              },
            })
            store.writeQuery({
              query: STAFF_GET_AS_GROUP_BY_ID,
              data: {
                ...availableShiftGroupData,
                scheduledAvailableShiftGroup: {
                  ...availableShiftGroupData.scheduledAvailableShiftGroup,
                  staffComments: availableShiftGroupData.scheduledAvailableShiftGroup.staffComments.filter(
                    comment => comment.id !== workScheduleCommentId
                  ),
                },
              },
              variables: {
                where: {
                  id: scheduledShiftGroupId,
                },
                availableShiftsWhere2: {
                  OR: [
                    {
                      deletedAt: null,
                    },
                    {
                      workSchedule: {
                        deletedStatus: 'WORKSCHEDULE_CANCEL',
                        deletedAt_not: null,
                      },
                    },
                  ],
                },
              },
            })
          } else if (scheduledAvailableShiftGroupId) {
            const variables = {
              where: {
                isRecruitmentShift: true,
                name_contains: '',
                AvailableShift_some: {
                  workSchedule: null,
                },
                id: scheduledAvailableShiftGroupId,
              },
              availableIsEditedIndividually: true,
              availableWorkSchedule: null,
              availableDeletedAt: null,
            }
            const scheduledAvailableShiftGroupData = store.readQuery({
              query: SCHEDULE_AVAILABLESHIFT_CONNECTION,
              variables,
            })
            scheduledAvailableShiftGroupData.scheduledAvailableShiftGroupsConnection.edges[0].node.staffComments = scheduledAvailableShiftGroupData.scheduledAvailableShiftGroupsConnection.edges[0].node.staffComments.filter(
              comment => comment.id !== workScheduleCommentId
            )
            store.writeQuery({
              query: SCHEDULE_AVAILABLESHIFT_CONNECTION,
              data: scheduledAvailableShiftGroupData,
              variables,
            })
          } else if (availableShiftId) {
            const availableShiftData = store.readQuery({
              query: AVAILABLE_SHIFT_DETAIL_GENERATOR(isMoneyPermitted),
              variables: {
                availableShiftId,
                detailWhere: {
                  AvailableShift: {
                    id: availableShiftId,
                  },
                  deletedAt: null,
                },
              },
            })
            store.writeQuery({
              query: AVAILABLE_SHIFT_DETAIL_GENERATOR(isMoneyPermitted),
              data: {
                ...availableShiftData,
                availableShift: {
                  ...availableShiftData.availableShift,
                  staffComments: availableShiftData.availableShift.staffComments.filter(
                    comment => comment.id !== workScheduleCommentId
                  ),
                  scheduledAvailableShiftGroup: {
                    ...availableShiftData.availableShift
                      .scheduledAvailableShiftGroup,
                    staffComments: availableShiftData.availableShift.scheduledAvailableShiftGroup.staffComments.filter(
                      comment => comment.id !== workScheduleCommentId
                    ),
                  },
                },
              },
              variables: {
                availableShiftId,
                detailWhere: {
                  AvailableShift: {
                    id: availableShiftId,
                  },
                  deletedAt: null,
                },
              },
            })
          } else if (waitingRecruitmentShiftId) {
            const variables = {
              where: {
                WaitingRecruitmentShift: {
                  id: waitingRecruitmentShiftId,
                },
              },
            }
            const waitingRecruitmentShiftData = store.readQuery({
              query: DETAIL_WAITING_RECRUITMENT_COMMENT,
              variables,
            })
            store.writeQuery({
              query: DETAIL_WAITING_RECRUITMENT_COMMENT,
              data: {
                ...waitingRecruitmentShiftData,
                workScheduleComments: waitingRecruitmentShiftData.workScheduleComments.filter(
                  comment => comment.id !== workScheduleCommentId
                ),
              },
              variables,
            })
          } else if (!isRecruitmentShift) {
            const workScheduleData = store.readQuery({
              query: DETAIL_WORK_SCHEDULE(isMoneyPermitted),
              variables: {
                workScheduleId,
              },
            })
            store.writeQuery({
              query: DETAIL_WORK_SCHEDULE(isMoneyPermitted),
              data: {
                ...workScheduleData,
                workSchedule: {
                  ...workScheduleData.workSchedule,
                  staffComments: workScheduleData.workSchedule.staffComments.filter(
                    comment => comment.id !== workScheduleCommentId
                  ),
                  acceptedShift: {
                    ...workScheduleData.workSchedule.acceptedShift,
                    scheduledAvailableShiftGroup: {
                      ...workScheduleData.workSchedule.acceptedShift
                        .scheduledAvailableShiftGroup,
                      staffComments: workScheduleData.workSchedule.acceptedShift.scheduledAvailableShiftGroup.staffComments.filter(
                        comment => comment.id !== workScheduleCommentId
                      ),
                    },
                  },
                },
              },
              variables: {
                workScheduleId,
              },
            })
          }
        },
      })

      resetForm()
      popup.success(i18n.t('main.staffComment.deleteCommentSuccess'))
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }
  const validationSchema = Yup.object().shape({
    text: Yup.string(i18n.t('validation.comment.required'))
      .trim()
      .required(i18n.t('validation.comment.required'))
      .max(500, i18n.t('validation.title.max', { max: 500 })),
    commentType: Yup.string(i18n.t('validation.comment.required')).required(
      i18n.t('validation.dropdownCommentType.required')
    ),
  })

  return (
    <div className='staff-comment-container'>
      {dataComments.length === 0 ? (
        <div style={{ padding: ' 20px 0' }}>
          {i18n.t('main.staffComment.emptyComment')}
        </div>
      ) : (
        <Formik
          initialValues={{
            workScheduleCommentId: null,
            confirm: false,
          }}
          onSubmit={onDeleteSubmit}
        >
          {formikProps => {
            const { setFieldValue } = formikProps
            return (
              <React.Fragment>
                <table className='table-comment-container'>
                  <thead
                    style={{
                      textAlign: 'left',
                    }}
                  >
                    <th
                      style={{
                        width: '15%',
                      }}
                    >
                      {/* {i18n.t('main.staffName')} */}
                    </th>
                    {isShowType && (
                      <th
                        style={{
                          width: '10%',
                        }}
                      />
                    )}
                    {/* add space */}
                    <th
                      style={{
                        width: '2%',
                      }}
                    />
                    <th
                      style={{
                        width: '65%',
                      }}
                    >
                      {/* {i18n.t('main.comment')} */}
                    </th>
                    {/* add space */}
                    <th
                      style={{
                        width: '2%',
                      }}
                    />
                    {isDeletePermitted ? (
                      <th
                        style={{
                          width: '5%',
                        }}
                      />
                    ) : null}
                  </thead>
                  <tbody>
                    {dataComments.map(staffComment => (
                      <>
                        <tr
                          key={staffComment.id}
                          className='list-staff-comment-container'
                        >
                          <td className='staff-comment-name'>
                            {`${staffComment.staff.lastname} ${staffComment.staff.firstname}`}
                          </td>
                          {!isWaitingRecruitment && (
                            <td className='staff-comment-type'>
                              <p>{converTypeComment(staffComment.type)}</p>
                            </td>
                          )}

                          {/* add space between column */}
                          <td />
                          <td
                            className='staff-comment-content'
                            style={{
                              maxWidth: 300,
                              whiteSpace: 'wrap',
                              wordWrap: 'break-word',
                              backgroundColor:
                                staffComment?.scheduledAvailableShiftGroup ||
                                isWaitingRecruitment
                                  ? '#eee'
                                  : `rgb(${'240, 248, 255'})`,
                            }}
                          >
                            {staffComment.text}
                          </td>
                          {/* add space between column */}
                          <td />
                          {isDeletePermitted ? (
                            <td>
                              {(staffComment.staff.id === staffId ||
                                staffComment.id === staffId ||
                                isDeleteAllComment) && (
                                <Button
                                  icon='pi pi-trash'
                                  onClick={() => {
                                    setFieldValue(
                                      'workScheduleCommentId',
                                      staffComment.id
                                    )
                                  }}
                                />
                              )}
                            </td>
                          ) : null}
                        </tr>
                        <tr rowSpan={1} style={{ marginBottom: '20px' }}>
                          <td
                            colSpan={isWaitingRecruitment ? 3 : 4}
                            style={{
                              textAlign: 'end',
                              fontSize: '12px',
                            }}
                          >
                            {`${
                              staffComment?.scheduledAvailableShiftGroup &&
                              !isWaitingRecruitment
                                ? '定期シフトコメント：'
                                : !staffComment?.scheduledAvailableShiftGroup &&
                                  !isWaitingRecruitment
                                ? '個別シフトコメント：'
                                : ''
                            }${moment(staffComment.createdAt).format(
                              'YYYY-MM-DD HH:mm:ss'
                            )}`}
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
                <ConfirmationModal
                  {...formikProps}
                  confirmationMessage={i18n.t(
                    'main.staffComment.deleteConfirmation'
                  )}
                  visibleKey='workScheduleCommentId'
                />
              </React.Fragment>
            )
          }}
        </Formik>
      )}
      {!isShowAdd && (
        <Formik
          enableReinitialize
          initialValues={{
            confirm: false,
            text: '',
            commentType: !isWaitingRecruitment ? '' : 'OTHER',
          }}
          validationSchema={validationSchema}
          onSubmit={onAddComment}
        >
          {formikProps => {
            const {
              setFieldValue,
              handleChange,
              validateForm,
              setTouched,
            } = formikProps
            return (
              <div className='input-comment-container'>
                {/* <Dropdown /> */}
                <div className='move-inline' style={{ alignItems: 'start' }}>
                  {!isWaitingRecruitment ? (
                    <CustomDropdown
                      {...formikProps}
                      name='commentType'
                      options={commentOption}
                      placeholder={i18n.t('main.staffComment.selectType')}
                    />
                  ) : null}
                  {!isWaitingRecruitment ? (
                    <TextInput
                      {...formikProps}
                      name='text'
                      onChange={handleChange}
                      placeholder={i18n.t('main.staffComment.inputText')}
                      inputWidth='100%'
                    />
                  ) : (
                    <TextInput
                      {...formikProps}
                      name='text'
                      onChange={handleChange}
                      placeholder={i18n.t('main.staffComment.inputText')}
                      inputWidth='120%'
                    />
                  )}
                </div>
                {isAddPermitted ? (
                  isTabPanel ? (
                    <div className='button-submit-comment-container'>
                      <Button
                        // icon='pi pi-plus'
                        className='reflect-button'
                        label={i18n.t('main.staffComment.addComment')}
                        onClick={() => {
                          validateForm().then(response => {
                            if (!Object.keys(response).length) {
                              setFieldValue('confirm', true)
                            } else {
                              setTouched(response)
                            }
                          })
                        }}
                      />
                    </div>
                  ) : (
                    <Button
                      icon='pi pi-plus'
                      label={i18n.t('main.staffComment.addComment')}
                      onClick={() => {
                        validateForm().then(response => {
                          if (!Object.keys(response).length) {
                            setFieldValue('confirm', true)
                          } else {
                            setTouched(response)
                          }
                        })
                      }}
                    />
                  )
                ) : null}
                <ConfirmationModal
                  {...formikProps}
                  confirmationMessage={i18n.t(
                    'main.staffComment.addConfirmation'
                  )}
                />
              </div>
            )
          }}
        </Formik>
      )}
    </div>
  )
}
