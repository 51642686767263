export const calculaterSubsidy = data => {
  let sum = 0
  if (data) {
    for (let i = 0; i < data.length; i += 1) {
      sum += data[i].money
    }
    return sum
  }
  return 0
}
