import { useCustom } from '@medical/provider/context'
import React from 'react'
import YouTube from 'react-youtube'
import { isMobile } from 'react-device-detect'

const NewsLayout = () => {
  const [{ i18n }] = useCustom()
  return (
    <>
      <p className='news-title'>当医療法人について</p>
      <div className='content-box-news'>
        <div className='is-pc'>
          <div className='sub-title-news'>
            {i18n.t('doctor.news.videoNotice')}
          </div>
        </div>
        <div className='is-sp'>
          <div className='sub-title-news-sp'>
            {i18n.t('doctor.news.videoNotice')}
          </div>
        </div>
        <div style={{ textAlign: 'center', width: '100%' }}>
          <YouTube videoId='whmj03yRhzs' opts={ isMobile ? { width: '100%' } : {}}/>
        </div>
      </div>
    </>
  )
}

export default NewsLayout
