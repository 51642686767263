import './JobSearchBoxStyle.css'

import Calendar from '@medical/components/Calendar'
import { PERMANENT_DOCTOR, REGULAR_SHIFT_DOCTOR } from '@medical/constant'
import { useCustom } from '@medical/provider/context'
import Router from '@medical/routes/router'
import { Tabs } from 'antd'
import { MultiSelect } from 'fork/primereact/components/multiselect/MultiSelect'
import moment from 'moment'
import { AutoComplete } from 'primereact/autocomplete'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import queryString from 'query-string'
import React, { useState } from 'react'
import { withRouter } from 'react-router'

const JobSearchBox = ({ clinicListJobSearch, history, clinicList }) => {
  const [{ i18n }] = useCustom()
  const { TabPane } = Tabs
  const [filteredClinic, setFilteredClinic] = useState(null)
  const [currentValue, setCurrentValue] = useState({})
  const searchValue = {}
  const workPatterns = [
    { value: PERMANENT_DOCTOR, description: '常勤' },

    { value: REGULAR_SHIFT_DOCTOR, description: '定期非常勤' },
  ]
  return (
    <div className='job-search-box'>
      <Tabs
        defaultActiveKey='0'
        type='card'
        size='small'
        onChange={e => {
          setCurrentValue({})
          setFilteredClinic(null)
        }}
      >
        <TabPane tab={i18n.t('doctor.menuBar.availableShifts')} key='0'>
          <div className='input-search-box'>
            <p className='title' htmlFor='clinicInput'>
              {i18n.t('doctor.menuBar.searchByClinic')}
            </p>
            <AutoComplete
              name='clinics'
              field='name'
              value={currentValue.clinics}
              suggestions={filteredClinic}
              completeMethod={event => {
                const results = clinicList.filter(clinic =>
                  clinic.name.toLowerCase().includes(event.query.toLowerCase())
                )
                setFilteredClinic(results)
              }}
              onUnselect={() => {
                setCurrentValue({
                  ...currentValue,
                  clinics: null,
                })
              }}
              onSelect={event => {
                setCurrentValue({
                  ...currentValue,
                  clinics: event.value,
                })
              }}
              onChange={event => {
                setCurrentValue({
                  ...currentValue,
                  clinics: event.value,
                })
              }}
              dropdown
              placeholder={i18n.t('doctor.menuBar.inputClinic')}
            />
          </div>
          <div className='input-search-box'>
            <p className='title' htmlFor='dateInput'>
              {i18n.t('doctor.menuBar.searchByDate')}
            </p>
            <Calendar
              name='dateRange'
              value={currentValue.dateRange}
              placeholder={i18n.t('doctor.menuBar.inputDate')}
              handleChange={event =>
                setCurrentValue({
                  ...currentValue,
                  dateRange: event.target.value,
                })
              }
              minDate={new Date()}
              maxDate={
                new Date(
                  moment()
                    .add(12, 'months')
                    .calendar()
                )
              }
            />
            <div className='btn-search'>
              <Button
                type='submit'
                label={i18n.t('doctor.menuBar.btnFindJob')}
                onClick={() => {
                  if (currentValue.dateRange) {
                    searchValue.dateRange = moment(
                      currentValue.dateRange
                    ).format('YYYYMMDD')
                  }
                  if (
                    currentValue.clinics &&
                    currentValue.clinics.id !== 'CHILDREN_VACCINE_MEDICINE' &&
                    currentValue.clinics.id !== 'INTERNAL_VACCINE_MEDICINE'
                  ) {
                    searchValue.clinics = currentValue.clinics.id
                  }
                  if (
                    currentValue.clinics &&
                    currentValue.clinics.id === 'CHILDREN_VACCINE_MEDICINE'
                  ) {
                    searchValue.departments = 'CHILDREN_VACCINE_MEDICINE'
                  }
                  if (
                    currentValue.clinics &&
                    currentValue.clinics.id === 'INTERNAL_VACCINE_MEDICINE'
                  ) {
                    searchValue.departments = 'INTERNAL_VACCINE_MEDICINE'
                  }
                  history.push({
                    pathname: Router.doctorFindAvailableShift,
                    search: queryString.stringify(searchValue),
                  })
                }}
              />
            </div>
          </div>
        </TabPane>
        <TabPane tab={i18n.t('doctor.menuBar.findJob')} key='1'>
          <div
            className='input-search-box'
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <p className='title' htmlFor='clinicInput'>
              {i18n.t('doctor.menuBar.searchByClinic')}
            </p>

            <MultiSelect
              display='chip'
              value={
                currentValue &&
                currentValue.clinics &&
                currentValue.clinics.length > 0
                  ? currentValue.clinics
                  : []
              }
              options={clinicListJobSearch}
              onChange={event => {
                setCurrentValue({
                  ...currentValue,
                  clinics: event.target.value,
                })
              }}
              optionLabel='clinicName'
              placeholder={i18n.t('doctor.menuBar.inputClinic')}
            />
          </div>
          <div className='input-search-box'>
            <p className='title' htmlFor='dateInput'>
              {i18n.t('doctor.menuBar.searchByJobType')}
            </p>
            <Dropdown
              name='workPattern'
              options={workPatterns}
              className='drop-down-select-workpartten-search input-search-box'
              optionLabel='description'
              value={currentValue.workPattern}
              placeholder={i18n.t('doctor.menuBar.jobTypeInput')}
              onChange={event => {
                setCurrentValue({
                  ...currentValue,
                  workPattern: event.target.value,
                })
              }}
            />
            <div className='btn-search'>
              <Button
                type='submit'
                label={i18n.t('doctor.menuBar.btnFindShift')}
                onClick={() => {
                  if (currentValue.workPattern) {
                    history.push({
                      pathname: Router.doctorRecruitments,
                      search: queryString.stringify({
                        workPattern: currentValue.workPattern.value,
                      }),
                    })
                  }
                  if (currentValue.clinics) {
                    history.push({
                      pathname: Router.doctorRecruitments,
                      search: queryString.stringify({
                        clinicalDepartmentIds: currentValue.clinics
                          ?.map(item => item.id)
                          .join('＿'),
                      }),
                    })
                  }
                  if (currentValue.workPattern && currentValue.clinics) {
                    history.push({
                      pathname: Router.doctorRecruitments,
                      search: queryString.stringify({
                        workPattern: currentValue.workPattern.value,
                        clinicalDepartmentIds: currentValue.clinics
                          ?.map(item => item.id)
                          .join('＿'),
                      }),
                    })
                  }
                  if (
                    !('workPattern' in currentValue) &&
                    !('clinics' in currentValue)
                  ) {
                    history.push({
                      pathname: Router.doctorRecruitments,
                    })
                  }
                }}
              />
            </div>
          </div>
        </TabPane>
      </Tabs>
    </div>
  )
}

export default withRouter(JobSearchBox)
