import logo from '@medical/assets/image/logo.png'
import { Card } from 'primereact/card'
import { Panel } from 'primereact/panel'
import React from 'react'

const Privacy = () => {
  const header = (
    <div className='layout-logo-privacy'>
      <img src={logo} alt='' style={{ width: '100%' }} />
    </div>
  )
  const footer = (
    <div>
      <div className='privacy-footer'>
        <div className='privacy-layout-company-footer'>
          <p>医療法人社団ナイズ</p>
          <p>東京都港区六本木七丁目18番18号</p>
          <p>住友不動産六本木通ビル</p>
        </div>
        <div className='layout-logo-privacy-footer'>
          <img
            alt=''
            src='https://static.wixstatic.com/media/374359_b55c0588b0164b129ec2cbfb5ebb54da~mv2.jpg'
            style={{ width: '100%' }}
          />
          <div className='layout-logo-tips-privacy-footer'>
            <p>健康経営支援事業において認証取得</p>
          </div>
        </div>
      </div>
      <div
        style={{ textAlign: 'center', paddingBottom: '5px', fontSize: '11px' }}
      >
        © 2018 MNYS 医療法人社団ナイズ　All rights reserved.
      </div>
    </div>
  )
  return (
    <Card
      title='プライバシーポリシー'
      className='ui-card-shadow'
      header={header}
      footer={footer}
    >
      <Panel header='第1条　総則'>
        <ol>
          <li className='privacy-li'>
            本プライバシーポリシーは、お客様が医療法人社団ナイズ（以下「当社」といいます。）の運営・提供するサービス（以下「サービス」といいます。）をご利用されたことに伴い、当社が取得したお客様の個人情報の取扱い方針を定めるものです。
          </li>
          <li className='privacy-li'>
            当社は、サービスをご利用いただくお客様をはじめとする、当社に個人情報を提供される全ての方々の個人情報の重要性を認識するとともに、従業員に対して個人情報保護に関する法令及びその他の規範を遵守させる教育を行い、自主的なルール及び体制を確立し、個人情報の保護に細心の注意を払っております。
          </li>
          <li className='privacy-li'>
            当社がサービスごとに定める利用規約等のほか、当社が運営する全てのサービスにおいて、本プライバシーポリシーが適用されます。
          </li>
        </ol>
      </Panel>
      <Panel header='第2条　個人情報の定義'>
        <p className='privacy-p'>
          本プライバシーポリシーにおいて「個人情報」とは、生存する個人に関する情報であり、その情報に含まれる氏名、生年月日、Eメールアドレスのほか、身長や体重、体脂肪、血液情報、遺伝子情報、健康診断結果及び当社が測定する身体に関する情報、運動や食事の内容及び履歴等の日常生活における活動に関する情報、その他の記述、画像若しくは音声又は個人別に付与された番号、記号その他の符号によりその個人を識別できるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含みます。）をいいます。
        </p>
      </Panel>
      <Panel header='第3条　個人情報の収集'>
        <p className='privacy-p'>
          当社では、当社の提供するサービスを利用頂くにあたり、上記の個人情報を収集させて頂く場合があります。これらの個人情報は、下記の利用目的にしたがって、適法かつ公正な手段により収集させていただきます。
        </p>
      </Panel>
      <Panel header='第4条　個人情報の利用目的'>
        <p className='privacy-p'>
          当社は、お客様の個人情報を以下の目的のために利用させていただきます。
        </p>
        <ol>
          <li className='privacy-li'>
            健康診断の受診状況や結果の管理並びにこれらに基づいた情報提供及びコンサルテーションのため
          </li>
          <li className='privacy-li'>
            セミナーや通信教育の案内及び教材、書籍の送付並びにインターネットを用いたこれらの実施のため
          </li>
          <li className='privacy-li'>
            当社が提供するサービス及び商品の販売のため
          </li>
          <li className='privacy-li'>
            当社又は当社と提携する第三者及び当社と共同でキャンペーンやセミナーを開催する第三者が提供するサービス及び商品の案内のため
          </li>
          <li className='privacy-li'>
            当社又は当社と提携する第三者がニュース記事及びブログ等による情報を配信するため
          </li>
          <li className='privacy-li'>
            当社が提供するサービス及び商品の改善並びに今後提供する予定のサービス及び商品の調査、開発及び改善の資料とするため
          </li>
          <li className='privacy-li'>広告の配信及び表示を行うため</li>
          <li className='privacy-li'>
            当社が提供するサービス及び商品に関するお客様からのお問い合わせに回答するため
          </li>
          <li className='privacy-li'>
            当社が提供するサービス及び商品に関連して、個人を特定できない形に加工したうえで、健康や運動、食事等についての統計、レポートを作成し、学術目的や商業目的での発表を行うため
          </li>
          <li className='privacy-li'>
            お客様に対して、当社が提供するサービス及び商品の利用料金を請求するため
          </li>
          <li className='privacy-li'>
            当社が別途定める規約類（以下「規約類」といいます。）に違反する行為に対応するため
          </li>
          <li className='privacy-li'>
            本プライバシーポリシーや規約類の変更などをお客様に対して通知するため
          </li>
          <li className='privacy-li'>
            その他前各号に掲げる利用目的に準じ、又は関連する目的のため
          </li>
        </ol>
      </Panel>
      <Panel header='第5条　個人情報の委託について'>
        <p className='privacy-p'>
          当社は、前条に定める利用目的の達成に必要な範囲で、個人情報の取扱いを外部に委託する場合があります。その場合には、個人情報の保護に十分な措置を講じている者を選定し、委託先に対して必要かつ適切な監督を行います。
        </p>
      </Panel>
      <Panel header='第6条　個人情報の第三者への提供について'>
        <ol>
          <li className='privacy-li'>
            当社は、第2項各号に該当する場合を除き、お客様の同意なく個人情報を第三者に提供する開示をすることはございません。
          </li>
          <li className='privacy-li'>
            当社は以下各号に定める場合には、個人情報を第三者に提供できるものとします。
          </li>
          <ol>
            <li className='privacy-li'>法令に基づく場合</li>
            <li className='privacy-li'>
              人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難である場合
            </li>
            <li className='privacy-li'>
              国及び地方公共団体並びにその委託を受けた第三者の業務遂行に協力するため
            </li>
            <li className='privacy-li'>
              お客様が当社に支払いする料金の決済を行うに当たり、金融機関及び回収代行業者その他決済又はその代行を行う会社に開示する場合
            </li>
            <li className='privacy-li'>
              合併、会社分割及び事業譲渡その他の事由による承継の際、事業を承継する者に対して開示する場合
            </li>
            <li className='privacy-li'>
              当社の業務遂行に必要な限度で個人情報の取扱いを委託する場合
            </li>
            <li className='privacy-li'>
              個人情報保護法の定めに基づき共同利用する場合
            </li>
            <li className='privacy-li'>
              前各号に定めるほか、社会通念上当社が必要と判断した場合であって、本人の同意を得ることが困難である場合
            </li>
          </ol>
        </ol>
      </Panel>
      <Panel header='第7条　個人情報の共同利用について'>
        <ul>
          <li className='privacy-li'>共同利用の目的</li>
          <ul>
            <li className='privacy-li'>第4条に定める利用目的と同じ</li>
          </ul>
        </ul>
        <ul>
          <li className='privacy-li'>共同利用者の範囲</li>
          <ul>
            <li className='privacy-li'>医療法人社団ナイズ</li>
            <li className='privacy-li'>
              当社と適切な機密保持契約等を締結した情報システム開発における当社の協力会社
            </li>
          </ul>
        </ul>
        <ul>
          <li className='privacy-li'>
            共同利用する場合における個人情報の管理責任者及びその連絡先
          </li>
          <ul>
            <li className='privacy-li'>
              第10条第2項に定めるお問い合わせ窓口と同じ
            </li>
          </ul>
        </ul>
      </Panel>
      <Panel header='第8条　cookieの使用について'>
        <ol>
          <li className='privacy-li'>
            当社は、お客様が当社のサービス及び商品に関するサイトをご利用の際に、cookieと呼ばれる情報をお客様がご利用中のコンピューター及びデバイスに送る場合があり、その情報はハードディスク又はメモリーに保存されます。
          </li>
          <li className='privacy-li'>
            cookieはアクセス情報の統計処理など、当社がお客様に対してより良いサービス及び商品を提供する目的で使用するものであり、お客様のプライバシー又は利用環境を侵すことはありません。また、当社はいかなる場合においてもアクセスした方を個別に識別できる状態で情報を公開することはありません。
          </li>
        </ol>
      </Panel>
      <Panel header='第9条　プライバシーポリシーの改定について'>
        <ol>
          <li className='privacy-li'>
            当本プライバシーポリシーは改定される場合があります。
          </li>
          <li className='privacy-li'>
            当社が本プライバシーポリシーを改定する場合、当社ウェブサイトにて告知を行うものとします。
          </li>
        </ol>
      </Panel>
      <Panel header='第10条　個人情報の取扱いに関する相談及び苦情'>
        <ol>
          <li className='privacy-li'>
            当社の個人情報の取扱いに関する相談及び苦情については、受け付け次第、適切、かつ、迅速に対応いたします。また、その体制及び手順については随時、整備及び改善を行い、常に適切に対応出来る体制を維持していきます。
          </li>
          <li className='privacy-li'>
            当社の個人情報の取扱いに関する相談及び苦情並びにお問い合わせは、下記の窓口にてお受けいたします。
          </li>
        </ol>
      </Panel>
      <div className='privacy-layout-company'>
        <p>〒106-0032</p>
        <p>東京都港区六本木七丁目18番18号　住友不動産六本木通ビル</p>
        <p>医療法人社団ナイズ</p>
        <p>制定年月日：2015年4月1日</p>
        <p>改定年月日：2019年2月7日</p>
      </div>

      <h2 className='privacy-h2'>情報セキュリティポリシー</h2>
      <Panel header='基本理念'>
        <p className='privacy-p'>
          医療法人社団ナイズ（以下、当社）は、ヘルスケアサービスを通じて、健康的で豊かな生活を世界中に、そして永続的に実現できる社会を目指しています。
          その中で当社の事業の中で取り扱う、お客様の貴重な情報をはじめとする情報資産は、当社の経営基盤として極めて重要なものです。
          当社は、漏洩、き損、滅失等のリスクからこれら情報資産を保護することの重要性を認識し、役員、従業員以下、すべての従業者を挙げて本基本方針を遵守し、情報資産の機密性、完全性、可用性といった情報セキュリティを維持するための活動を実践いたします。
        </p>
      </Panel>
      <Panel header='基本方針'>
        <ol>
          <li className='privacy-li'>
            情報資産を保護するために、情報セキュリティポリシーを策定し、これに従って業務を行うとともに、情報セキュリティに関連する法令、規制その他の規範、及び、お客様との契約事項を遵守いたします。
          </li>
          <li className='privacy-li'>
            情報資産に対して存在する漏洩、き損、滅失等のリスクを分析、評価するための基準を明確にし、体系的なリスクアセスメント方法を確立するとともに、定期的にリスクアセスメントを実施いたします。また、その結果に基づき、必要かつ適切なセキュリティ対策を実施いたします。
          </li>
          <li className='privacy-li'>
            担当役員を中心とした情報セキュリティ体制を確立するとともに、情報セキュリティに関する権限および責任を明確にいたします。また、すべての従業者が、情報セキュリティの重要性を認識し、情報資産の適切な取り扱いを確実にするために、定期的に教育、訓練および啓発を行います。
          </li>
          <li className='privacy-li'>
            情報セキュリティポリシーの遵守状況及び情報資産の取扱いについて、定期的に点検及び監査を行い、発見された不備や改善項目については、速やかに是正処置を講じます。
          </li>
          <li className='privacy-li'>
            情報セキュリティ上のイベントやインシデントの発生に対する適切な処置を講じるとともに、万一それらが発生した場合に際して、あらかじめ、被害を最小限に留めるための対応手順を確立し、有事の際には、速やかに対応するとともに、適切な是正処置を講じます。また、特に、業務中断に関わるようなインシデントについては、その管理の枠組みを確立し、定期的に対応、復旧試験及び見直しを行うことにより、当社の事業継続を確実にいたします。
          </li>
          <li className='privacy-li'>
            基本理念を実現するための目標を定めた情報セキュリティマネジメントシステムを確立し、これを実行するとともに、継続的に見直し、改善を行います。
          </li>
        </ol>
      </Panel>
      <div className='privacy-layout-company'>
        <p>制定年月日：2015年4月1日</p>
        <p>改定年月日：2018年10月1日</p>
        <p>医療法人社団ナイズ</p>
      </div>
    </Card>
  )
}

export default Privacy
