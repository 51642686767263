import gql from 'graphql-tag'

export const DETAIL_SKILL = gql`
  query SkillsConnection($skip: Int, $first: Int, $where: SkillWhereInput) {
    skillsConnection(skip: $skip, first: $first, where: $where) {
      aggregate(where: $where) {
        count
      }
      edges {
        node {
          id
          name
          createdAt
          updatedAt
          createdBy {
            id
            lastname
            firstname
            lastnameKana
            firstnameKana
            email
          }
          updatedBy {
            id
            lastname
            firstname
            lastnameKana
            firstnameKana
            email
          }
          isShow
        }
      }
    }
  }
`
export const STAFF_ME = gql`
  query STAFF_ME {
    staffMe {
      id
    }
  }
`

export const DELETE_SKILL = gql`
  mutation STAFF_DELETE_SKILL($skillId: ID!) {
    staffDeleteSkill(skillId: $skillId)
  }
`

export const UPDATE_SKILL = gql`
  mutation STAFF_UPDATE_SKILL(
    $skillId: ID!
    $name: String
    $updatedBy: ID
    $isShow: Boolean
  ) {
    staffUpdateSkill(
      skillId: $skillId
      name: $name
      updatedBy: $updatedBy
      isShow: $isShow
    )
  }
`
