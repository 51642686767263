import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, NotFound, ProgressSpinner } from '@medical/components'
import StaffResendConfirmationEmailForm from '@medical/components/Forms/StaffResendConfirmationForm'
import { useCustom } from '@medical/provider/context'
import { TabPanel, TabView } from 'primereact/tabview'
import queryString from 'query-string'
import React from 'react'

import {
  STAFF_QUERY,
  STAFF_RESEND_CONFIRMATION_EMAIL,
} from './StaffDetail.graphql'
import StaffDetailScene from './StaffDetailScene'

const StaffDetail = ({
  match: {
    params: { staffId },
  },
  location: { pathname, search },
  parentPath,
  history,
}) => {
  const [{ i18n, popup }] = useCustom()
  const { loading, error, data } = useQuery(STAFF_QUERY, {
    variables: {
      staffId,
    },
  })
  const [resendConfirmationEmail] = useMutation(STAFF_RESEND_CONFIRMATION_EMAIL)
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const { staff = {} } = data
  if (!staff) {
    return <NotFound />
  }

  const { activeIndex = 0, ...restSearch } = queryString.parse(search)

  const onTabChange = (e, isEditable) => {
    history.push({
      pathname,
      search: queryString.stringify({
        ...restSearch,
        activeIndex: e.index,
        editable: isEditable,
      }),
    })
  }

  const onSubmit = async ({ email }, { resetForm, setSubmitting }) => {
    try {
      await resendConfirmationEmail({
        variables: {
          email,
        },
      })
      popup.success(i18n.t('resendConfirmationEmail.submissionSuccess'))
      resetForm()
      setSubmitting(false)
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }

  return (
    <TabView activeIndex={parseInt(activeIndex, 10)} onTabChange={onTabChange}>
      <TabPanel header={i18n.t('staff.myPage.profile')}>
        <div className='modal-wrapper'>
          <StaffDetailScene
            i18n={i18n}
            parentPath={parentPath}
            search={search}
            staff={staff}
            staffGroups={data.staffGroups}
          />
        </div>
      </TabPanel>
      <TabPanel header={i18n.t('resendConfirmationEmail.title')}>
        <div className='modal-wrapper'>
          <div>{i18n.t('resendConfirmationEmail.description1')}</div>
          <div>{i18n.t('resendConfirmationEmail.description2')}</div>
          <StaffResendConfirmationEmailForm
            i18n={i18n}
            staff={staff}
            onSubmit={onSubmit}
          />
        </div>
      </TabPanel>
    </TabView>
  )
}

export default StaffDetail
