import gql from 'graphql-tag'

export const UPSERT_CATEGORY = gql`
  mutation staffUpsertPortal(
    $portalId: ID
    $largeTitle: String
    $mediumTitle: String
    $smallTitle: String
    $titleType: titlePortalType!
    $content: String
  ) {
    staffUpsertPortal(
      portalId: $portalId
      largeTitle: $largeTitle
      mediumTitle: $mediumTitle
      smallTitle: $smallTitle
      titleType: $titleType
      content: $content
    ) {
      id
      largeTitle
      mediumTitle
      smallTitle
      titleType
      content
    }
  }
`

export const DELETE_CATEGORY = gql`
  mutation staffDeletePortal(
    $portalId: ID
    $isHide: Boolean
    $isDeleted: Boolean
    $deleteTitle: Boolean
  ) {
    staffDeletePortal(
      portalId: $portalId
      isHide: $isHide
      isDeleted: $isDeleted
      deleteTitle: $deleteTitle
    ) {
      id
      largeTitle
      mediumTitle
      smallTitle
      titleType
      content
    }
  }
`

export const CATEGORY_QUERY = gql`
  query Portals($orderBy: PortalOrderByInput, $where: PortalWhereInput) {
    portals(orderBy: $orderBy, where: $where) {
      id
      largeTitle
      mediumTitle
      titleType
    }
  }
`
