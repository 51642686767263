/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-duplicate-props */
import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { checkStaffPermission, onPageChange } from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import moment from 'moment'
import queryString from 'query-string'
import React, { useEffect } from 'react'
import {
  STAFF_CREATE_INCENTIVE_PERMISSIONS,
  STAFF_UPDATE_INCENTIVE_PERMISSIONS,
  STAFF_DELETE_INCENTIVE_PERMISSIONS,
} from '@medical/constant/permissions'
import {
  DELETE_INCENTIVE_SETTING,
  GET_INCENTIVE_SETTING,
} from './IncentiveSettingList.graphql'
import IncentiveSettingListScene from './IncentiveSettingListScene'

const IncentiveSettingList = ({ history, location: { search } }) => {
  const [{ i18n, popup }] = useCustom()
  const IncentiveType = [
    'NORMAL_INCENTIVE',
    'NUMBER_INCENTIVE',
    'BEYOND_EIGHT_INCENTIVE',
    'TIME_INCENTIVE',
    'SATISFACTION_INCENTIVE',
  ]
  const IncentiveApplyType = [
    'NEW_DOCTOR',
    'SPECIFY_DOCTORS',
    'ALL_DOCTORS',
    'WORK_PATTERN_APPLIED',
  ]
  let {
    page = 1,
    rowsPerPage = 20,
    orderBy = 'endTime_DESC',
    type,
    title,
    apply,
    old,
    date,
  } = queryString.parse(search)
  const isCreatePermitted = checkStaffPermission({
    functionName: STAFF_CREATE_INCENTIVE_PERMISSIONS,
  })
  const isUpdatePermitted = checkStaffPermission({
    functionName: STAFF_UPDATE_INCENTIVE_PERMISSIONS,
  })
  const isDeletePermitted = checkStaffPermission({
    functionName: STAFF_DELETE_INCENTIVE_PERMISSIONS,
  })

  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  const endTimeDefault = 15
  const getEndTime = () => {
    if (old == null && date == null) {
      return moment()
        .subtract(2, 'month')
        .endOf('month')
        .toISOString()
    }
    if (old === 'false' && date == null) {
      return moment()
        .subtract(2, 'month')
        .endOf('month')
        .toISOString()
    }
    if (old === 'true' && date == null) {
      return undefined
    }

    if (
      moment().isAfter(moment(date)) &&
      moment()
        .subtract(1, 'month')
        .month() === moment(date).month()
    ) {
      if (moment().date() > endTimeDefault) {
        if (old == null || old === 'true') {
          old = 'true'
          return moment(date)
            .startOf('month')
            .toISOString()
        }
        if (old === 'false') {
          return moment(date)
            .add(1, 'month')
            .startOf('month')
            .toISOString()
        }
      }
      if (moment().date() <= endTimeDefault) {
        if (old == null || old === 'true') {
          old = 'true'
        }
        return moment(date)
          .startOf('month')
          .toISOString()
      }
    }
    if (
      (old == null || old === 'true') &&
      date != null &&
      moment().isAfter(moment(date))
    ) {
      old = 'true'
      return moment(date)
        .startOf('month')
        .toISOString()
    }
    if (old === 'false' && date != null && moment().isAfter(moment(date))) {
      return moment()
        .startOf('month')
        .toISOString()
    }
    if (date != null && moment().isBefore(moment(date))) {
      return moment(date)
        .startOf('month')
        .toISOString()
    }
  }
  const variables = {
    first: first < 0 ? 10 : first,
    skip: skip < 0 ? 0 : skip,
    orderBy,
    where: {
      AND: {
        // type_in: [
        //   'NORMAL_INCENTIVE',
        //   'NUMBER_INCENTIVE',
        //   'BEYOND_EIGHT_INCENTIVE',
        //   'TIME_INCENTIVE',
        //   'SATISFACTION_INCENTIVE',
        // ],
        deletedAt: null,
        type,
        title_contains: title,
        applyWith: apply,
        startTime_lte: date
          ? moment(date)
              .endOf('month')
              .toISOString()
          : undefined,
        endTime_gte: getEndTime(),
      },
    },
  }

  const [deleteIncentive] = useMutation(DELETE_INCENTIVE_SETTING)
  const { loading, error, data, refetch } = useQuery(GET_INCENTIVE_SETTING, {
    variables,
    fetchPolicy: 'network-only',
  })
  useEffect(() => {
    refetch()
  })
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const onSubmit = async ({ incentiveId }, { setSubmitting, resetForm }) => {
    try {
      await deleteIncentive({
        variables: {
          incentiveId,
        },
      })
      popup.success('成功しました')
      setSubmitting(false)
      resetForm()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }
  if (data && data.incentivesConnection) {
    data.incentivesConnection.edges.map(as => {
      if (IncentiveType.includes(as.node.type))
        as.node.type = i18n.t(`main.IncentiveType.${as.node.type}`)
      if (IncentiveApplyType.includes(as.node.applyWith))
        as.node.applyWith = i18n.t(
          `main.IncentiveApplyType.${as.node.applyWith}`
        )
    })
  }

  return (
    <IncentiveSettingListScene
      i18n={i18n}
      IncentiveType={IncentiveType}
      IncentiveApplyType={IncentiveApplyType}
      list={data.incentivesConnection.edges}
      history={history}
      onPageChange={onPageChange(history, search)}
      count={data.incentivesConnection.aggregate.count}
      rowsPerPage={parseInt(rowsPerPage, 10)}
      page={parseInt(page, 10)}
      incentiveS={type}
      titleS={title}
      applyS={apply}
      onSubmit={onSubmit}
      isCheckbox={old}
      date={date}
      isCreatePermitted={isCreatePermitted}
      isUpdatePermitted={isUpdatePermitted || isDeletePermitted}
    />
  )
}

export default IncentiveSettingList
