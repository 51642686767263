import { useQuery, useApolloClient } from '@apollo/react-hooks'
import { ErrorComponent, NotFound, ProgressSpinner } from '@medical/components'
import { GET_LIST_AVAILABLE } from '@medical/pages/doctor/AvailableShift/AvailableShift.graphql'
import { CLINICS_CONNECTION } from '@medical/pages/staff/Clinics/ClinicsList/ClinicsList.graphql'
import { useCustom } from '@medical/provider/context'
import moment from 'moment'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import FindAvailableShiftSence from './FindAvailableShiftsence'
import { DOCTOR_WORK_SCHEDULES } from './FindAvailableShiftsence.graphql'
import { parseJwt } from '@medical/constant/utilities'
import { checkOverlapTime } from '@medical/libs/checkOverlapTime'
import { REGION_QUERY } from '@medical/pages/staff/DailyAvailableShift/DailyAvailableShift.graphql'

const FindAvailableShift = ({
  history,
  location: { search },
  addItemToCart,
  removeItemFromCart,
  cartItems,
  popup,
}) => {
  const [{ isDoctorBlocked }] = useCustom()
  const pageSize = 10
  let {
    page = 1,
    weeks,
    dateRange,
    clinics = [],
    startTime = null,
    endTime = null,
    morningShift = false,
    eveningShift = false,
    nightShift = false,
    isSpecialHourlyWage = false,
    hourlyWage = null,
    departments = [],
  } = queryString.parse(search)
  const [pageUrl, setPage] = useState(page)
  morningShift = JSON.parse(morningShift)
  eveningShift = JSON.parse(eveningShift)
  nightShift = JSON.parse(nightShift)
  isSpecialHourlyWage = JSON.parse(isSpecialHourlyWage)
  const {
    loading: loadingClinics,
    data: dataClinics,
    error: errorClinics,
  } = useQuery(CLINICS_CONNECTION, {
    variables: {
      where: {
        isSearchDisplay: true,
      },
    },
    fetchPolicy: 'network-only',
  })
  const { doctorId } = parseJwt(localStorage.getItem('medical_doctor_token'))
  const {
    loading: loadingWorkSchedules,
    error: errorWorkSchedules,
    data: dataWorkSchedules,
  } = useQuery(DOCTOR_WORK_SCHEDULES, {
    variables: {
      where: {
        id: doctorId,
      },
      workSchedulesWhere2: {
        accepted: true,
        deletedAt: null,
        AvailableShift: {
          isPublished: true,
        },
        startTime_gte: moment()
          .startOf('day')
          .toISOString(),
      },
    },
    fetchPolicy: 'network-only',
  })

  const { loading:listRegionLoading, error:listRegionError, data:listRegionData } = useQuery(REGION_QUERY, {
    variables: {
      deletedAt: null,
    },
  })

  const modifyDateString = (value, toDateArray) => {
    const newArray = value.split(',')
    if (toDateArray) {
      return newArray.map(item => moment(item).format('YYYY-MM-DD'))
    }
    return newArray.map(item => ({
      startDate: moment(item, 'YYYY-MM-DD').toDate(),
      endDate: moment(item, 'YYYY-MM-DD').toDate(),
      color: '#f03a6c',
    }))
  }

  const { loading, data: dataList, error, refetch } = useQuery(
    GET_LIST_AVAILABLE,
    {
      variables: {
        dateRange: dateRange ? modifyDateString(dateRange, true) : null,
        clinics,
        startTime,
        endTime,
        morningShift,
        eveningShift,
        nightShift,
        isSpecialHourlyWage,
        hourlyWage,
        first: `${pageSize}`,
        skip: hourlyWage ? `${page - 1}` : `${(page - 1) * pageSize}`,
        departments,
      },
    }
  )
  useEffect(() => {
    refetch()
  }, [])
  if (loading || loadingClinics || loadingWorkSchedules)
    return <ProgressSpinner />
  if (error || errorClinics || errorWorkSchedules)
    return <ErrorComponent error={error || errorClinics} />
  if (isDoctorBlocked) {
    return <NotFound />
  }
  const { workSchedules } = dataWorkSchedules.doctor
  const {
    doctorFindAvailableShift: { data, count },
  } = dataList
  const {
    clinicsConnection: { edges },
  } = dataClinics

  let idClinic = data.map(value => value.id)
  const myId = new Set(idClinic)
  idClinic = [...myId]

  const onSubmit = async (
    {
      weeks,
      dateRange,
      clinics,
      startTime,
      endTime,
      morningShift,
      eveningShift,
      nightShift,
      isSpecialHourlyWage,
      hourlyWage,
      departments,
    },
    { setSubmitting }
  ) => {
    try {
      dateRange = Object.values(dateRange).map(item =>
        moment(item.startDate).format('YYYYMMDD')
      )
      const dateValue = dateRange.join(',')
      const weekValue = weeks.join(',')
      const clinicsValue = clinics.join('&clinics=')
      const findAvailableShift = {
        startTime:
          startTime && startTime.isValid()
            ? moment(startTime, 'HH:mm').format('HH:mm')
            : null,
        endTime:
          endTime && endTime.isValid()
            ? moment(endTime, 'HH:mm').format('HH:mm')
            : null,
        morningShift,
        eveningShift,
        nightShift,
        isSpecialHourlyWage,
        hourlyWage: hourlyWage?.value,
        departments,
        page: pageUrl,
      }
      history.push({
        search: `dateRange=${dateValue}&weeks=${weekValue}&clinics=${clinicsValue}&${queryString.stringify(
          {
            ...findAvailableShift,
          }
        )}`,
      })
      setSubmitting(false)
    } catch (error) {
      setSubmitting(false)
    }
  }

  const PaginationProps = {
    pageSize,
    pageUrl,
    setPage,
    count: dataList.doctorFindAvailableShift.count,
  }
  const checkWorkScheduleExits = item => {
    let isExits = false
    for (let index = 0; index < workSchedules.length; index++) {
      if (
        checkOverlapTime(
          workSchedules[index].startTime,
          workSchedules[index].endTime,
          item.startTime,
          item.endTime
        )
      ) {
        isExits = true
        break
      }
    }

    return isExits
  }
  return (
    <div>
      <FindAvailableShiftSence
        history={history}
        search={search}
        tableData={data}
        idClinic={idClinic}
        dateRange={dateRange ? { ...modifyDateString(dateRange, false) } : {}}
        weeks={weeks ? weeks.split(',') : []}
        clinics={Array.isArray(clinics) ? clinics : [clinics]}
        departments={Array.isArray(departments) ? departments : [departments]}
        startTime={startTime}
        endTime={endTime}
        morningShift={morningShift}
        eveningShift={eveningShift}
        nightShift={nightShift}
        isSpecialHourlyWage={isSpecialHourlyWage}
        hourlyWage={hourlyWage}
        count={count}
        clinicList={edges}
        listRegionData={listRegionData}
        PaginationProps={PaginationProps}
        onSubmit={onSubmit}
        cartItems={cartItems}
        checkWorkScheduleExits={checkWorkScheduleExits}
        addItemToCart={item => {
          if (checkWorkScheduleExits(item)) {
            popup.error('同日時で確定シフトがあるためカートに追加できません')
          } else if (moment(item.startTime).isAfter(moment())) {
            popup.success('募集シフトをカートに追加しました')
            addItemToCart(item)
          } else
            popup.error('このシフトの開始時間が過ぎましたので応募できません')
        }}
        removeItemFromCart={item => {
          popup.warn('募集シフトをカートから削除しました')
          removeItemFromCart(item)
        }}
        page={page}
      />
    </div>
  )
}
export default FindAvailableShift
