import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import gql from 'graphql-tag'

export const CLINIC_FRAGMENT = gql`
  fragment CLINIC_FRAGMENT on Clinic {
    id
    name
    nameKana
    address
    phoneNumber
    inquiryPhoneNumber
    email
    isPublished
    clinicalDepartments {
      id
      name
      isDisplay
      startTimeMondayMorning
      endTimeMondayMorning
      isAvailableOnMondayMorning
      startTimeMondayAfternoon
      endTimeMondayAfternoon
      isAvailableOnMondayAfternoon
      startTimeTuesdayMorning
      endTimeTuesdayMorning
      isAvailableOnTuesdayMorning
      startTimeTuesdayAfternoon
      endTimeTuesdayAfternoon
      isAvailableOnTuesdayAfternoon
      startTimeWednesdayMorning
      endTimeWednesdayMorning
      isAvailableOnWednesdayMorning
      startTimeWednesdayAfternoon
      endTimeWednesdayAfternoon
      isAvailableOnWednesdayAfternoon
      startTimeThursdayMorning
      endTimeThursdayMorning
      isAvailableOnThursdayMorning
      startTimeThursdayAfternoon
      endTimeThursdayAfternoon
      isAvailableOnThursdayAfternoon
      startTimeFridayMorning
      endTimeFridayMorning
      isAvailableOnFridayMorning
      startTimeFridayAfternoon
      endTimeFridayAfternoon
      isAvailableOnFridayAfternoon
      startTimeSaturdayMorning
      endTimeSaturdayMorning
      isAvailableOnSaturdayMorning
      startTimeSaturdayAfternoon
      endTimeSaturdayAfternoon
      isAvailableOnSaturdayAfternoon
      startTimeSundayMorning
      endTimeSundayMorning
      isAvailableOnSundayMorning
      startTimeSundayAfternoon
      endTimeSundayAfternoon
      isAvailableOnSundayAfternoon
      startTimeHolidayMorning
      endTimeHolidayMorning
      isAvailableOnHolidayMorning
      endTimeHolidayAfternoon
      startTimeHolidayAfternoon
      isAvailableOnHolidayAfternoon
    }
    startTimeMonday
    endTimeMonday
    isAvailableOnMonday
    startTimeTuesday
    endTimeTuesday
    isAvailableOnTuesday
    startTimeWednesday
    endTimeWednesday
    isAvailableOnWednesday
    startTimeThursday
    endTimeThursday
    isAvailableOnThursday
    startTimeFriday
    endTimeFriday
    isAvailableOnFriday
    startTimeSaturday
    endTimeSaturday
    isAvailableOnSaturday
    startTimeSunday
    endTimeSunday
    isAvailableOnSunday
    startTimeHoliday
    endTimeHoliday
    isAvailableOnHoliday
    numberPatient
    maleAgeFrom
    maleAgeTo
    femaleAgeFrom
    femaleAgeTo
    kindOfDisease
    vaccine
    referHospital
    noteOpenTime
    messageIntroduce
    region
  }
`

export const CLINICS_CONNECTION = gql`
  query CLINICS_CONNECTION($where: ClinicWhereInput, $skip: Int, $first: Int) {
    clinicsConnection(where: $where, skip: $skip, first: $first) {
      edges {
        node {
          ...CLINIC_FRAGMENT
        }
      }
      aggregate {
        count
      }
    }
    ...DEPARTMENTS_FRAGMENT
  }
  ${DEPARTMENTS_FRAGMENT}
  ${CLINIC_FRAGMENT}
`
