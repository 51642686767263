import './style.css'
import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import {
  STAFF_DOCTOR_SKILL_CREATE,
  STAFF_DOCTOR_SKILL_UPDATE,
  STAFF_DOCTOR_SKILL_DELETE,
} from '@medical/constant/permissions'
import { checkStaffPermission } from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import queryString from 'query-string'
import React, { useEffect } from 'react'
import { SKILL_CONNECTIONS } from './DoctorSkill.graphql'
import DoctorSkillScene from './DoctorSkillScene'

const DoctorSkill = ({ history, location: { search } }) => {
  const {
    page = 1,
    rowsPerPage = 20,
    orderBy = 'createdAt_DESC',
  } = queryString.parse(search)
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  let variables = {
    first: first < 0 ? 20 : first,
    skip: skip < 0 ? 0 : skip,
    orderBy: orderBy,
  }

  const [{ i18n, popup }] = useCustom()
  const isCreatePermitted = checkStaffPermission({
    functionName: STAFF_DOCTOR_SKILL_CREATE,
  })

  const isUpdatePermitted = checkStaffPermission({
    functionName: STAFF_DOCTOR_SKILL_UPDATE,
  })

  const isDeletePermitted = checkStaffPermission({
    functionName: STAFF_DOCTOR_SKILL_DELETE,
  })

  const { loading, error, data, refetch } = useQuery(SKILL_CONNECTIONS, {
    variables,
    fetchPolicy: 'network-only',
  })
  useEffect(() => {
    refetch()
  })
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  const {
    skillsConnection: {
      edges,
      aggregate: { count },
    },
  } = data

  return (
    <DoctorSkillScene
      i18n={i18n}
      edges={edges}
      isCreatePermitted={isCreatePermitted}
      isUpdatePermitted={isUpdatePermitted}
      isDeletePermitted={isDeletePermitted}
    />
  )
}

export default DoctorSkill
