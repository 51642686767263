import React, {useEffect, useState} from 'react'
import StaffDoctorOutputInsuranceScene from "./StaffDoctorOutputInsuranceScene";
import {checkStaffPermission} from "../../../libs";
import {useCustom} from "../../../provider/context";
import {ErrorComponent, ProgressSpinner} from '@medical/components'
import {useMutation, useQuery} from '@apollo/react-hooks'
import Auth from "../../../middleware/auth";
import {
  CHECK_DOCTOR_ID,
  CLINICS_CONNECTION, DOWNLOAD_LIST_FILE,
  GET_DOCTOR_FILE
} from "./StaffDoctorOutputInsurance.graphql";
import {STAFF_CHECK_SHIFT, STAFF_UPLOAD_SHIFT} from "../../../constant/permissions";
import jsPDF from "jspdf";
import {STAFF_VIEW_PAGE_DOCTOR_OUTPUT_INSURANCE} from '@medical/constant/permissions'
import styles from "../ExpectationDoubleRecruitmentRecord/Style";
import {compressImage, fetchDownloadableImage} from "../../../libs/fileToBase64";

const StaffDoctorOutputInsurance = () => {
  const [{i18n, popup}] = useCustom()
  const {loading, error, data} = useQuery(CLINICS_CONNECTION, {
    fetchPolicy: 'network-only',
  })
  const [checkDoctorIdMutation] = useMutation(CHECK_DOCTOR_ID)
  const [getDoctorFile] = useMutation(GET_DOCTOR_FILE)
  const [downloadListFile] = useMutation(DOWNLOAD_LIST_FILE)
  const token = Auth.getToken()
  const isUploadPermitted = checkStaffPermission({
    functionName: STAFF_UPLOAD_SHIFT,
  })
  const isUploadCheckPermitted = checkStaffPermission({
    functionName: STAFF_CHECK_SHIFT,
  })
  const isViewStaffDoctorOutputInsurancePermitted = checkStaffPermission({
    functionName: STAFF_VIEW_PAGE_DOCTOR_OUTPUT_INSURANCE,
  })
  const [state, setState] = useState({
    isUploaded: false,
  })

  useEffect(() => {
    if (state.isUploaded) {
      document.getElementsByClassName('staff-layout-body')[0].style.overflowY = 'hidden';
    } else {
      document.getElementsByClassName('staff-layout-body')[0].style.overflowY = 'scroll';
    }
  }, [state.isUploaded]);

  if (loading) return <ProgressSpinner/>
  if (error) return <ErrorComponent error={error}/>

  const {
    clinicsConnection: {
      edges,
    },
  } = data

  const convertClinic = (clinics) => (clinics.map(clinic => {
    return {
      ...clinic.node,
    }
  }))
  const downloadSample = () => {
    window.location.href = `${
      process.env.REACT_APP_ENDPOINT
    }/files/保険医登録票CSVサンプル.csv?token=${token}`
  }

  const checkCsvData = async ({clinic_id}, {data}, {setFieldValue}) => {
    setState({
      isUploaded: true,
    })
    try {
      if (!isUploadCheckPermitted) {
        popup.error(i18n.t('main.noPermission'))
      } else {
        const messageAddedCsvData = await Promise.all(
          data.map(async (item) => {
            if (item.clinic_id !== clinic_id) {
              setFieldValue('validate', false)
              return {
                ...item,
                errorMessage: 'クリニックが一致しません。',
                checked: true,
              }
            }
            const {data: {checkDoctorId}} = await checkDoctorIdMutation({
              variables: {
                doctorId: item.doctor_id,
              }
            })
            if (checkDoctorId.errorMessage) {
              setFieldValue('validate', false)
              return {
                ...item,
                errorMessage: checkDoctorId.errorMessage,
                checked: checkDoctorId.checked,
              }
            }
            setFieldValue('validate', true)
            return {
              ...item,
              checked: true,
            }
          }))
        setFieldValue('data', messageAddedCsvData)
        setState({
          isUploaded: false,
        })
        setTimeout(() => {
          popup.success(i18n.t('staff.uploadCheckCsv.submissionSuccess'))
        }, 2000)
      }
    } catch (error) {
      console.log(error)
      popup.error(error)
      setState({
        isUploaded: false,
      })
    }
  }

  const onSubmit = async ({clinic_name, data}, {resetForm}) => {
    setState({
      isUploaded: true,
    })
    try {
      if (!isUploadPermitted) {
        popup.error(i18n.t('main.noPermission'))
      } else {
        popup.info(i18n.t('staff.uploadCsv.uploading'))
        const listDoctorId = data.map(data => data.doctor_id)
        const {data: {doctorFile}} = await getDoctorFile({
          variables: {
            doctorIds: listDoctorId,
          }
        })

        const {data: {downloadListFileS3}} = doctorFile.length > 0 ? await downloadListFile({
          variables: {
            keys: doctorFile,
          },
        }) : null;
        const mergeData = listDoctorId.map((item, index) => ({
          doctorId: item,
          imageUrl: downloadListFileS3[index],
        }))
        await handleCreatePDF(clinic_name, mergeData)
        await resetForm()
      }
      setState({
        isUploaded: false,
      })
      popup.clear()
    } catch (error) {
      console.log(error)
      popup.error(error)
      setState({
        isUploaded: false,
      })
    }
  }

  const handleCreatePDF = async (clinic_name, listImage) => {
    const doc = new jsPDF({
      format: "a4",
      unit: "mm",
    });
    doc.addFont("/NotoSansJP-Regular.ttf", "NotoSansJP-Regular", "regular");
    doc.setFont("NotoSansJP-Regular", "regular");
    const newClinicName = 'キャップスクリニック' + clinic_name;

    await loadImages(doc, listImage);

    const totalPageNumber = doc.internal.getNumberOfPages();
    for (let i = 1; i <= totalPageNumber; ++i) {
      doc.setPage(i);
      const footerText = `${newClinicName} - No.${i}`
      const footerFontSize = 10;
      doc.setFontSize(footerFontSize);
      const textWidth = doc.getTextWidth(footerText);
      const pageWidth = doc.internal.pageSize.width;
      const margin = 10;
      const xPosition = pageWidth - textWidth - margin;
      const yPosition = doc.internal.pageSize.height - 10;
      doc.text(footerText, xPosition, yPosition);
    }
    const baseFilePath = `${Date.now()}_${newClinicName}.pdf`;
    // doc.output('blob');
    doc.save(baseFilePath);
  }


  const loadImages = async (doc, listImage) => {
    let imageYPosition = 20;
    const batchSize = 30;
    for (let i = 0; i < listImage.length; i += batchSize) {
      const batch = listImage.slice(i, i + batchSize);
      for (let j = 0; j < batch.length; j++) {
        await new Promise((resolve, reject) => {
          const imgFile = new Image();
          imgFile.src = batch[j].imageUrl;
          imgFile.crossOrigin = "Anonymous";
          imgFile.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = imgFile.width;
            canvas.height = imgFile.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(imgFile, 0, 0);
            const imageDataURL = canvas.toDataURL("image/png");
            doc.addImage(imageDataURL, "PNG", 10, imageYPosition, 60, 60);
            imageYPosition += 70;
            if ((j + 1) % 3 === 0 && (i+j) !== listImage.length - 1) {
              doc.addPage();
              imageYPosition = 20;
            }
            resolve();
          };
          imgFile.onerror = reject;
        });
      }
    }
  }

  return (
    <>
      {
        state.isUploaded && <div style={styles.loading}>
          <ProgressSpinner/>
        </div>
      }
      <StaffDoctorOutputInsuranceScene
        isUploaded={state.isUploaded}
        popup={popup}
        onSubmit={onSubmit}
        i18n={i18n}
        convertClinic={convertClinic}
        edges={edges}
        downloadSample={downloadSample}
        isUploadPermitted={isUploadPermitted}
        isUploadCheckPermitted={isUploadCheckPermitted}
        checkCsvData={checkCsvData}
        isViewStaffDoctorOutputInsurancePermitted={isViewStaffDoctorOutputInsurancePermitted}
      />
    </>
  );
};


export default StaffDoctorOutputInsurance