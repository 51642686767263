import { dateValidation, waitFor } from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import Router from '@medical/routes/router'
import moment from 'moment'
import React, { useContext, useMemo } from 'react'
import { Redirect } from 'react-router-dom'
import MonthlyDoctorSalaryConfirmationScene from './MonthlyDoctorSalaryConfirmationScene'
import queryString from 'query-string'
import { useQuery,useMutation } from '@apollo/react-hooks'
import {
  TOTAL_AMOUNT_DOCTOR_SALARY_IDS_LIST,
  TOTAL_AMOUNT_DOCTOR_SALARY_LIST,
  CREATE_ACTIVITY
} from './MonthlyDoctorSalaryConfirmation.grapql'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import _ from 'lodash'
import { connect } from 'react-redux'
import { SocketContext } from '@medical/provider/socket'
import {
  removeProgress,
  setProgressBar,
} from '@medical/provider/store/reducers/progress/progress.action'
import { getDepartmentNameJP } from '@medical/constant/utilities'
import { fieldMonthlyDoctorSalary } from '@medical/services/csvService'
import downloadCsv from 'download-csv'
import { DOWNLOAD_CSV_MONTHLY_DOCTOR_SALARY_CONFIRMATION } from '@medical/constant'

const ROWS_PER_PAGE_DOCTOR_TOTAL_AMOUNT = 15

const CATEGORY_TRANSPORT = {
  TRANFFIC_FEE: 'TRANFFIC_FEE',
  RECURRING_TRAVEL_FEE: 'RECURRING_TRAVEL_FEE',
  CLINIC_TRANFFIC_FEE: 'CLINIC_TRANFFIC_FEE',
}

const calTotalMonthlyAmountWorkSchedule = ({ doctorMonthlyWorkSchedules }) =>
  doctorMonthlyWorkSchedules.reduce(
    (prev, { acceptedShift }) => prev + acceptedShift.dailySalary,
    0
  )

const calcTotalTransportationExpense = (
  transportationExpenses,
  sortedWorkSchedules
) => {
  const totalCostTransportationExpense = transportationExpenses
    .filter(item => !item.deletedAt)
    .reduce((acc, expense) => acc + expense.cost, 0)

  const totalCostTransportationByWorkSchedules = sortedWorkSchedules
    .filter(
      item =>
        !item.notApplyCostTransportation ||
        item.notApplyCostTransportation === null
    )
    .reduce(
      (acc, ws) =>
        acc +
        ws.costWs +
        (ws.costTransportationByDoctorWorkSchedule
          ? parseInt(ws.costTransportationByDoctorWorkSchedule)
          : ws.costWs),
      0
    )
  return totalCostTransportationExpense + totalCostTransportationByWorkSchedules
}

const calDoctorMonthlyTransportationExpenses = ({
  doctorMonthlyTransportationExpenses,
  doctorMonthlyWorkSchedules,
}) => {
  // copy logic doctorWorkReport file
  const lstTransportationWithDate = []
  for (const transportation of doctorMonthlyTransportationExpenses) {
    if (transportation.category === CATEGORY_TRANSPORT.TRANFFIC_FEE) {
      lstTransportationWithDate.push({ ...transportation })
    } else {
      const startTime = moment.utc(transportation.startTime).clone()
      const endTime = moment.utc(transportation.endTime)
      while (startTime.isSameOrBefore(endTime)) {
        const timeCurrent = moment.utc(startTime).format('YYYY-MM-DD')
        const datesCheck = transportation.blockedDays
          ? transportation.blockedDays.split(',')
          : []

        if (!datesCheck.includes(timeCurrent.slice(0, 10))) {
          lstTransportationWithDate.push({
            ...transportation,
            startTime: `${timeCurrent}T00:00:00.000Z`,
            endTime: `${timeCurrent}T00:00:00.000Z`,
          })
        }
        startTime.add(1, 'days')
      }
    }
  }
  const transportationExpensesCost = lstTransportationWithDate.map(item => ({
    cost: item.cost,
    deletedAt: item.deletedAt,
  }))

  return calcTotalTransportationExpense(
    transportationExpensesCost,
    doctorMonthlyWorkSchedules.map(item => ({
      ...item,
      costWs: item.isTypeTransportation
        ? item.costTransportationByMyCar
        : item.costTransportationByDoctorWorkSchedule.length > 0
        ? item.costTransportationByDoctorWorkSchedule[0].cost
        : 0,
      costTransportationByDoctorWorkSchedule: item.isTypeTransportation
        ? item.costTransportationByMyCar
        : item.costTransportationByDoctorWorkSchedule.length > 0
        ? item.costTransportationByDoctorWorkSchedule[0].returnPath[2]
        : 0,
    }))
  )
}

const MonthlyDoctorSalaryConfirmation = ({
  match: {
    path,
    params: { year = moment().year(), month = moment().month() },
  },
  location: { search },
  history,
  progress,
  setProgress,
  removeProgress,
}) => {
  const socket = useContext(SocketContext)
  const [staffCreateActivity] = useMutation(CREATE_ACTIVITY)
  const { doctorName = '', mailAddress = '', page = 1 } = queryString.parse(
    search
  )
  const [{ i18n }] = useCustom()
  const pageSize = 20

  const startDateOfCurrentMonth = useMemo(
    () =>
      moment()
        .year(year)
        .month(month - 1)
        .startOf('month')
        .toISOString(),
    [year, month]
  )

  const endDateOfCurrentMonth = useMemo(
    () =>
      moment()
        .year(year)
        .month(month - 1)
        .endOf('month')
        .toISOString(),
    [year, month]
  )

  const { data, loading, error } = useQuery(
    TOTAL_AMOUNT_DOCTOR_SALARY_IDS_LIST,
    {
      variables: {
        date: startDateOfCurrentMonth,
        skip: pageSize * (page - 1),
        first: pageSize,
        where: {
          startTime_gte: startDateOfCurrentMonth,
          endTime_lte: endDateOfCurrentMonth,
          Doctor: {
            unSigned_contains: doctorName
              ? doctorName
                  .replace(/ /g, '')
                  .replace(/　/g, '')
                  .toLowerCase()
              : '',
            email_contains: mailAddress.trim().toLowerCase(),
            deletedAt: null,
            registrationStatus_not_in: ['PENDING', 'REJECTED', 'NEW'],
          },
        },
      },
      fetchPolicy: 'no-cache',
    }
  )

  const { refetch: fetchSalaryList } = useQuery(
    TOTAL_AMOUNT_DOCTOR_SALARY_LIST,
    {
      skip: true,
    }
  )

  if (
    dateValidation({ year, month }, true) ||
    [
      Router.staffMonthlyDoctorSalaryConfirmationWithoutYearMonth,
      Router.staffMonthlyDoctorSalaryConfirmationWithoutMonth,
    ].includes(path)
  ) {
    return (
      <Redirect
        to={Router.get(Router.staffMonthlyDoctorSalaryConfirmation, {
          year: moment().year(),
          month: moment().month() + 1,
        })}
      />
    )
  }

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const {
    getAmountDoctorSalaryList: {
      edges,
      aggregate: { count },
    },
  } = data

  const filter = (doctorName, mailAddress, page = 1) => {
    history.push({
      search: queryString.stringify({
        doctorName,
        mailAddress,
        page,
      }),
    })
  }

  const updateProcessDownloadCsv = percent => {
    setProgress({
      progress: DOWNLOAD_CSV_MONTHLY_DOCTOR_SALARY_CONFIRMATION,
      label: `${i18n.t('staff.menuBar.monthlyDoctorSalaryConfirmation')}中`,
      percent: percent,
      url: undefined,
    })
  }

  const handleDownloadCSV = async () => {
    updateProcessDownloadCsv(0)
    try {
      const data = []
      for (
        let i = 0;
        i < Math.ceil(count / ROWS_PER_PAGE_DOCTOR_TOTAL_AMOUNT);
        i++
      ) {
        const res = await fetchSalaryList({
          where: {
            OR: [
              {
                deletedAt: null,
                registrationStatus_not_in: ['NEW', 'REJECTED', 'PENDING'],
                WorkSchedule_some: {
                  startTime_gte: startDateOfCurrentMonth,
                  startTime_lte: endDateOfCurrentMonth,
                  accepted: true,
                },
              },
              {
                IncentiveDetail_some: {
                  applyTime_lte: moment().toISOString(),
                  applyTime_gte: moment(startDateOfCurrentMonth).startOf(
                    'month'
                  ),
                  deletedAt: null,
                  incentiveTotalAmount_gt: 0,
                },
              },
              {
                TransportationExpense_some: {
                  endTime_gte: moment(startDateOfCurrentMonth).startOf('month'),
                  endTime_lt: moment().toISOString(),
                  OR: [
                    {
                      category: 'RECURRING_TRAVEL_FEE',
                      status: 'ACCEPTED',
                    },
                    {
                      category: 'TRANFFIC_FEE',
                      ClinicalDepartment: {
                        id_not: null,
                      },
                    },
                  ],
                },
              },
            ],
          },
          date: startDateOfCurrentMonth,
          skip: i * ROWS_PER_PAGE_DOCTOR_TOTAL_AMOUNT,
          first: ROWS_PER_PAGE_DOCTOR_TOTAL_AMOUNT,
        })

        updateProcessDownloadCsv(
          Math.ceil((i / (count / ROWS_PER_PAGE_DOCTOR_TOTAL_AMOUNT)) * 100)
        )

        data.push(...res.data.doctorsConnection.edges)
      }

      const totalAmountDoctorSalary = data.map(
        ({
          node: {
            doctorMonthlyIncentive,
            doctorMonthlyTransportationExpenses,
            doctorMonthlyWorkSchedules,
            email,
            fullName,
          },
        }) => {
          const totalWorkScheduleSalary = calTotalMonthlyAmountWorkSchedule({
            doctorMonthlyTransportationExpenses,
            doctorMonthlyWorkSchedules,
          })

          const totalTransportationExpenses = calDoctorMonthlyTransportationExpenses(
            {
              doctorMonthlyTransportationExpenses,
              doctorMonthlyWorkSchedules,
            }
          )

          const totalMonthyDoctorSalary =
            parseInt(totalWorkScheduleSalary.toFixed(0)) +
            totalTransportationExpenses +
            doctorMonthlyIncentive

          return {
            fullName,
            email,
            departments:
              doctorMonthlyWorkSchedules[0] &&
              doctorMonthlyWorkSchedules[0].clinicalDepartment &&
              doctorMonthlyWorkSchedules[0].clinicalDepartment.name
                ? getDepartmentNameJP(
                    doctorMonthlyWorkSchedules[0].clinicalDepartment.name
                  )
                : '',
            totalWorkScheduleSalary: parseInt(
              totalWorkScheduleSalary.toFixed(0)
            ),
            totalTransportationExpenses,
            totalMonthyIncentive: doctorMonthlyIncentive,
            totalMonthyDoctorSalary,
          }
        }
      )

      updateProcessDownloadCsv(100)
      await waitFor(2000)

      const fields = fieldMonthlyDoctorSalary()
      const filename = [
        '月間医師給与確認_',
        `${year}年${month}月`,
        '.CSV',
      ].join('')

      downloadCsv(totalAmountDoctorSalary, fields, filename)
      staffCreateActivity({
        variables: {
          activity: 'DOWNLOAD_MONTHLY_SALARY_CONFIRMATION_CSV',
        },
      })
    } catch (err) {
      setProgress({
        progress: DOWNLOAD_CSV_MONTHLY_DOCTOR_SALARY_CONFIRMATION,
        label: `${i18n.t('staff.menuBar.monthlyDoctorSalaryConfirmation')}中`,
        msg: i18n.t('main.msgDownloadCSVStatusError'),
      })
    }
    removeProgress(DOWNLOAD_CSV_MONTHLY_DOCTOR_SALARY_CONFIRMATION)
  }

  return (
    <MonthlyDoctorSalaryConfirmationScene
      i18n={i18n}
      socket={socket}
      progress={progress}
      setProgress={progress => setProgress(progress)}
      removeProgress={progress => removeProgress(progress)}
      month={month}
      currentDate={startDateOfCurrentMonth}
      filterData={{ mailAddress, doctorName, page }}
      filter={filter}
      data={edges.map(e => ({ ...e.node }))}
      totalRecord={count}
      pageSize={pageSize}
      year={year}
      handleDownloadCSV={handleDownloadCSV}
    />
  )
}
const mapStateToProps = state => ({
  progress: state.progressBarStore.progress,
})

const mapDispatchToProps = dispatch => ({
  setProgress: progress => dispatch(setProgressBar(progress)),
  removeProgress: progress => dispatch(removeProgress(progress)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MonthlyDoctorSalaryConfirmation)
