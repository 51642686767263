import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import StaffGroupForm from '@medical/pages/staff/StaffGroups/StaffGroupForm/StaffGroupForm'
import { STAFF_GROUPS } from '@medical/pages/staff/StaffGroups/StaffGroupsList/StaffGroupsList.graphql'
import { useCustom } from '@medical/provider/context'
import React from 'react'

import {
  CREATE_STAFF_GROUP,
  STAFF_GROUP_CREATE_QUERY,
} from './StaffGroupCreate.graphql'

const StaffGroupCreate = () => {
  const [{ i18n, popup }] = useCustom()
  const [createStaffGroup] = useMutation(CREATE_STAFF_GROUP, {
    refetchQueries: [
      {
        query: STAFF_GROUPS,
      },
    ],
    awaitRefetchQueries: true,
  })
  const { loading, error, data } = useQuery(STAFF_GROUP_CREATE_QUERY)

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const onSubmit = async (
    { groupName, permissions },
    { setSubmitting, resetForm }
  ) => {
    try {
      await createStaffGroup({
        variables: {
          name: groupName,
          permissions: permissions.map(({ api: { name }, action }) => ({
            name,
            action,
          })),
        },
      })
      popup.success(i18n.t('staff.staffGroup.createSuccess'))
      setSubmitting(false)
      resetForm()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }

  const { apis, permittedActions, apiGroupTypes } = data

  const staffGroup = {
    name: '',
    permissions: apis.map(({ id, name, description, type, apiGroup }) => ({
      id,
      api: { name, description, type, apiGroup },
      action: 'NONE',
    })),
  }

  return (
    <StaffGroupForm
      i18n={i18n}
      staffGroup={staffGroup}
      onSubmit={onSubmit}
      permittedActions={permittedActions}
      submitLabel={i18n.t('staff.staffGroup.create')}
      confirmationMessage={i18n.t('staff.staffGroup.createConfirmation')}
      apiGroupTypes={apiGroupTypes}
    />
  )
}

export default StaffGroupCreate
