import { Checkbox, InputNumber } from 'antd'
import React from 'react'

export const departmentNames = {
  INTERNAL_MEDICINE: '内科',
  CHILDREN_MEDICINE: '小児科',
  CHILDREN_VACCINE_MEDICINE: '小児科ワクチン専任(対象：小児～成人)',
  INTERNAL_VACCINE_MEDICINE: '内科ワクチン専任(対象：小児～成人)',
}

const TableRowData = ({
  item,
  backgroundColor,
  index,
  setFieldValue,
  isError,
  isPermittedEdit,
  backgroundColorClinic,
}) => {
  const disabled = !isPermittedEdit
  return (
    <tr
      key={item.id}
      style={{ backgroundColor, border: isError ? '2px solid red' : 'none' }}
    >
      <>
        <td className='padding-0'>
          <Checkbox
            checked={item.isValid}
            onChange={e => {
              setFieldValue(`data[${index}].isValid`, e.target.checked)
            }}
            disabled={disabled}
            style={{ padding: '8px' }}
          />
        </td>
        <td className='padding-0'>
          <Checkbox
            checked={item.isEarlySpecial}
            onChange={e => {
              setFieldValue(`data[${index}].isEarlySpecial`, e.target.checked)
            }}
            disabled={disabled}
            style={{ padding: '16px' }}
          />
        </td>
        <td
          className='area-text'
          style={{ fontSize: '12px', padding: '0 8px' }}
        >
          <p
            style={{
              backgroundColor: backgroundColorClinic,
              margin: 0,
              padding: '8px',
              whiteSpace: 'nowrap',
            }}
            title={item.clinic.Region?.name}
          >
            {item.clinic.Region?.name && item.clinic.Region?.name.length > 4
              ? `${item.clinic.Region?.name.substring(0, 4)}...`
              : item.clinic.Region?.name}
          </p>
        </td>
        <td style={{ fontSize: '12px', padding: '0 8px' }}>
          <p
            style={{
              backgroundColor: backgroundColorClinic,
              margin: 0,
              padding: '8px',
              whiteSpace: 'nowrap',
            }}
            title={item.clinic.name}
          >
            {item.clinic.name && item.clinic.name.length > 10
              ? `${item.clinic.name.substring(0, 10)}...`
              : item.clinic.name}
          </p>
        </td>
        <td style={{ fontSize: '12px', padding: '0 8px' }}>
          <p
            style={{
              backgroundColor: backgroundColorClinic,
              margin: 0,
              padding: '8px',
              whiteSpace: 'nowrap',
            }}
            title={departmentNames[item.clinicalDepartment.name]}
          >
            {item.clinicalDepartment.name &&
            departmentNames[item.clinicalDepartment.name].length > 10
              ? `${departmentNames[item.clinicalDepartment.name].substring(
                  0,
                  10
                )}...`
              : departmentNames[item.clinicalDepartment.name]}
          </p>
        </td>
        <td className='padding-0'>
          <InputNumber
            onChange={e => {
              setFieldValue(`data[${index}].weekdayMorning`, e)
            }}
            value={item.weekdayMorning}
            disabled={disabled}
            min={1}
            step={500}
            type='number'
          />
        </td>
        <td className='padding-0'>
          <InputNumber
            onChange={e => {
              setFieldValue(`data[${index}].weekdayAfternoon`, e)
            }}
            value={item.weekdayAfternoon}
            disabled={disabled}
            min={1}
            step={500}
            type='number'
          />
        </td>
        <td className='padding-0'>
          <InputNumber
            onChange={e => {
              setFieldValue(`data[${index}].weekdayNight`, e)
            }}
            value={item.weekdayNight}
            disabled={disabled}
            min={1}
            step={500}
            type='number'
          />
        </td>
        <td className='padding-0'>
          <InputNumber
            onChange={e => {
              setFieldValue(`data[${index}].saturdayMorning`, e)
            }}
            value={item.saturdayMorning}
            disabled={disabled}
            min={1}
            step={500}
            type='number'
          />
        </td>
        <td className='padding-0'>
          <InputNumber
            onChange={e => {
              setFieldValue(`data[${index}].saturdayAfternoon`, e)
            }}
            value={item.saturdayAfternoon}
            disabled={disabled}
            min={1}
            step={500}
            type='number'
          />
        </td>
        <td className='padding-0'>
          <InputNumber
            onChange={e => {
              setFieldValue(`data[${index}].saturdayNight`, e)
            }}
            value={item.saturdayNight}
            disabled={disabled}
            min={1}
            step={500}
            type='number'
          />
        </td>
        <td className='padding-0'>
          <InputNumber
            onChange={e => {
              setFieldValue(`data[${index}].holidayMorning`, e)
            }}
            value={item.holidayMorning}
            disabled={disabled}
            min={1}
            step={500}
            type='number'
          />
        </td>
        <td className='padding-0'>
          <InputNumber
            onChange={e => {
              setFieldValue(`data[${index}].holidayAfternoon`, e)
            }}
            value={item.holidayAfternoon}
            disabled={disabled}
            min={1}
            step={500}
            type='number'
          />
        </td>
        <td className='padding-0'>
          <InputNumber
            onChange={e => {
              setFieldValue(`data[${index}].holidayNight`, e)
            }}
            value={item.holidayNight}
            disabled={disabled}
            step={500}
            min={1}
            type='number'
          />
        </td>
      </>
    </tr>
  )
}

export default React.memo(TableRowData)
