/* eslint-disable no-use-before-define */
/* eslint-disable no-nested-ternary */
import { DialogCsv } from '@medical/components'
import BreadCrumb from '@medical/components/Breadcrumb'
import ModalLink from '@medical/components/ModalLink'
import { dateValidation, waitFor } from '@medical/libs'
import styles from '@medical/pages/staff/MonthlyAvailableShift/Style'
import Router from '@medical/routes/router'
import { DatePicker } from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import Axios from 'axios'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { MultiSelect } from 'primereact/multiselect'
import { Paginator } from 'primereact/paginator'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import {
  DOWNLOAD_CSV_ACCEPTED_WORK_SCHEDULE_LIST,
  DOWNLOAD_CSV_ISSHIN_WORK_SCHEDULE_LIST,
} from '@medical/constant'
const AcceptedWorkSchedulesListScene = ({
  i18n,
  workScheduleLists,
  workPatterSearch,
  history,
  clinicalDepartmentIds = [],
  clinicalDepartments,
  currentDate,
  workPatterns,
  isUpdatePermitted,
  download,
  handleDownloadWorkScheduleByTime,
  downloadUniqueTimeWork,
  page,
  onPageChange,
  count,
  rowsPerPage,
  fullname,
  daySearch,
  isWorkScheduleDownloadPermitted,
  isOneVisit,
  socket,
  progress,
  setProgress,
  removeProgress,
}) => {
  const [displayDialog, setDisplayDialog] = useState(false)
  const [{ loading, msg }, setState] = useState({
    loading: true,
    msg: null,
  })
  const now = moment()
  const getWorkPatterSearch = () => {
    let workPatter
    workPatterns.map(as => {
      if (as.value === workPatterSearch) {
        workPatter = as
      }
    })
    return workPatter
  }
  const optionsIsOneVisit = [
    { value: 1, description: '一診体制のみ' },
    { value: 0, description: '全て' },
  ]
  const getOneVisitSearch = () => {
    let result
    optionsIsOneVisit.map(as => {
      if (as.value === +isOneVisit) {
        result = as
      }
    })
    return result
  }
  const [dateSelected, setDateSelected] = useState(daySearch || '')
  const [nameFilter, setNameFilter] = useState(fullname || '')
  const [workPatternFilter, setWorkPatternFilter] = useState(
    getWorkPatterSearch() || ''
  )
  const [isOneVisitFilter, setIsOneVisitFilter] = useState(
    getOneVisitSearch() || ''
  )
  const date = moment(currentDate)
  const prevMonth = moment(date).subtract(1, 'month')
  const nextMonth = moment(date).add(1, 'month')
  const isPreviousDisabled =
    date.isSameOrBefore(moment().subtract(1, 'years'), 'month') &&
    date.isBefore(
      moment()
        .subtract(6, 'months')
        .endOf('month')
    )

  const isNextDisabled =
    dateValidation({
      year: nextMonth.year(),
      month: nextMonth.month() + 1,
    }) && date.isSameOrAfter(moment().add(6, 'quarters'), 'month')

  const onSearchClinicDepartments = e => {
    history.push({
      pathname: Router.get(Router.staffAcceptedWorkSchedulesList, {
        year: moment(currentDate).year(),
        month: moment(currentDate).month() + 1,
      }),
      search: queryString.stringify({
        rowsPerPage,
        fullname: nameFilter,
        day: dateSelected ? moment(dateSelected).format('YYYYMMDD') : undefined,
        workPatter: workPatterSearch,
        clinicalDepartmentIds: e.value.map(({ id }) => id).join('＿'),
        isOneVisit: isOneVisitFilter
          ? isOneVisitFilter.value
          : isOneVisit || '0',
      }),
    })
  }
  const removeSearch = () => {
    history.push({
      pathname: Router.get(Router.staffAcceptedWorkSchedulesList, {
        year: moment(currentDate).year(),
        month: moment(currentDate).month() + 1,
      }),
    })
  }
  const disabledDate = current => {
    const minDay = moment(date).startOf('month')
    const maxDay = moment(date).endOf('month')
    return !maxDay.isSameOrAfter(current) || !minDay.isSameOrBefore(current)
  }
  const onSearch = () => {
    history.push({
      pathname: Router.get(Router.staffAcceptedWorkSchedulesList, {
        year: moment(currentDate).year(),
        month: moment(currentDate).month() + 1,
      }),
      search: queryString.stringify({
        rowsPerPage,
        fullname: nameFilter,
        day: dateSelected ? moment(dateSelected).format('YYYYMMDD') : undefined,
        workPatter:
          workPatternFilter && workPatternFilter.value
            ? workPatternFilter.value
            : undefined,
        clinicalDepartmentIds: clinicalDepartmentIds.join('＿'),
        isOneVisit: isOneVisitFilter
          ? isOneVisitFilter.value
          : isOneVisit || '0',
      }),
    })
  }
  const checkShowActualTime = wsStartTime => {
    if (moment().isAfter(moment(wsStartTime).endOf('day'))) return true
    if (
      moment().format('YYYY-MM-DD') ===
        moment(wsStartTime).format('YYYY-MM-DD') &&
      moment().isAfter(
        moment()
          .startOf('day')
          .add(21, 'hours')
          .format('YYYY-MM-DD HH:mm:ss')
      )
    )
      return true
    return false
  }
  const hasProgress = Object.keys(progress).includes(
    DOWNLOAD_CSV_ACCEPTED_WORK_SCHEDULE_LIST
  )
  const hasProgress2 = Object.keys(progress).includes(
    DOWNLOAD_CSV_ISSHIN_WORK_SCHEDULE_LIST
  )
  useEffect(() => {
    if (socket && hasProgress) {
      socket.on(DOWNLOAD_CSV_ACCEPTED_WORK_SCHEDULE_LIST, data => {
        if (data?.percent) {
          setProgress({
            progress: DOWNLOAD_CSV_ACCEPTED_WORK_SCHEDULE_LIST,
            label: '確定シフトCSVダウンロード中',
            percent: Number(data?.percent),
          })
        }
      })
    }
  }, [socket, hasProgress])

  useEffect(() => {
    if (socket && hasProgress2) {
      socket.on(DOWNLOAD_CSV_ISSHIN_WORK_SCHEDULE_LIST, data => {
        if (data?.percent) {
          setProgress({
            progress: DOWNLOAD_CSV_ISSHIN_WORK_SCHEDULE_LIST,
            label: '一診体制のみ確定シフトcsvダウンロード中',
            percent: Number(data?.percent),
          })
        }
      })
    }
  }, [socket, hasProgress2])

  return (
    <React.Fragment>
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('main.acceptedWorkScheduleList')}
          <BreadCrumb
            items={[
              {
                to: Router.get(Router.staffMonthlyAvailableShifts, {
                  year: date.year(),
                  month: date.month() + 1,
                }),
                title: moment(date).format('YYYY年MM月'),
              },
              { title: i18n.t('main.acceptedWorkScheduleList') },
            ]}
          />
        </div>
        <div className='staff-buttons'>
          {isWorkScheduleDownloadPermitted ? (
            <>
              <Button
                icon='pi pi-download'
                className='p-button-gray'
                label='時間帯別確定シフトCSVダウンロード'
                onClick={handleDownloadWorkScheduleByTime}
              />
              <Button
                icon='pi pi-download'
                className='p-button-normal-empty'
                label='確定シフトCSVダウンロード'
                onClick={async () => {
                  setProgress({
                    progress: DOWNLOAD_CSV_ACCEPTED_WORK_SCHEDULE_LIST,
                    label: '確定シフトCSVダウンロード中',
                    percent: 0,
                  })
                  try {
                    const { status, data: urlS3 } = await Axios({
                      method: 'GET',
                      url: download,
                    })
                    await waitFor(3000)
                    if (status === 204) {
                      setProgress({
                        progress: DOWNLOAD_CSV_ACCEPTED_WORK_SCHEDULE_LIST,
                        label: '確定シフトCSVダウンロード中',
                        msg: i18n.t('main.msgDownloadCSVStatusError'),
                      })
                      await waitFor(3000)
                    } else {
                      setProgress({
                        progress: DOWNLOAD_CSV_ACCEPTED_WORK_SCHEDULE_LIST,
                        percent: 100,
                        url: urlS3,
                      })
                    }
                  } catch (err) {
                    setProgress({
                      progress: DOWNLOAD_CSV_ACCEPTED_WORK_SCHEDULE_LIST,
                      label: '確定シフトCSVダウンロード中',
                      msg: i18n.t('main.msgDownloadCSVStatusError'),
                    })
                    await waitFor(3000)
                  }
                  removeProgress(DOWNLOAD_CSV_ACCEPTED_WORK_SCHEDULE_LIST)
                }}
              />
              <Button
                icon='pi pi-download'
                className='p-button-normal-empty'
                label='一診体制のみ確定シフトcsvダウンロード'
                onClick={async () => {
                  setProgress({
                    progress: DOWNLOAD_CSV_ISSHIN_WORK_SCHEDULE_LIST,
                    label: '一診体制のみ確定シフトcsvダウンロード中',
                    percent: 0,
                  })
                  try {
                    const { status, data: urlS3 } = await Axios({
                      method: 'GET',
                      url: downloadUniqueTimeWork,
                    })
                    await waitFor(3000)
                    if (status === 204) {
                      setProgress({
                        progress: DOWNLOAD_CSV_ISSHIN_WORK_SCHEDULE_LIST,
                        label: '一診体制のみ確定シフトcsvダウンロード中',
                        msg: i18n.t('main.msgDownloadCSVStatusError'),
                      })
                      await waitFor(3000)
                    } else {
                      setProgress({
                        progress: DOWNLOAD_CSV_ISSHIN_WORK_SCHEDULE_LIST,
                        percent: 100,
                        url: urlS3,
                      })
                    }
                  } catch (err) {
                    setProgress({
                      progress: DOWNLOAD_CSV_ISSHIN_WORK_SCHEDULE_LIST,
                      label: '一診体制のみ確定シフトcsvダウンロード中',
                      msg: i18n.t('main.msgDownloadCSVStatusError'),
                    })
                    await waitFor(3000)
                  }
                  removeProgress(DOWNLOAD_CSV_ISSHIN_WORK_SCHEDULE_LIST)
                }}
              />
              <div style={{ marginTop: '12px' }}>
                <span style={{ color: '#f03a6c' }}>
                ※時間帯別確定シフトCSVは仮設置です。重いので時間をおいてダウンロードしてください<br/>
                </span>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <div className='container is-max'>
        <div className='box'>
          <div style={styles.header}>
            <Link
              to={{
                pathname: Router.get(Router.staffAcceptedWorkSchedulesList, {
                  year: prevMonth.year(),
                  month: prevMonth.month() + 1,
                }),
                search: queryString.stringify({
                  rowsPerPage,
                  fullname: nameFilter,
                  workPatter: workPatterSearch,
                  clinicalDepartmentIds: clinicalDepartmentIds.join('＿'),
                  isOneVisit: isOneVisitFilter
                    ? isOneVisitFilter.value
                    : isOneVisit || '0',
                }),
              }}
              onClick={isPreviousDisabled ? e => e.preventDefault() : undefined}
            >
              <Button icon='pi pi-chevron-left' disabled={isPreviousDisabled} />
            </Link>
            <div style={styles.title}>
              {moment(currentDate).format('YYYY年M月')}
            </div>
            <Link
              to={{
                pathname: Router.get(Router.staffAcceptedWorkSchedulesList, {
                  year: nextMonth.year(),
                  month: nextMonth.month() + 1,
                }),
                search: queryString.stringify({
                  rowsPerPage,
                  fullname: nameFilter,
                  workPatter: workPatterSearch,
                  clinicalDepartmentIds: clinicalDepartmentIds.join('＿'),
                  isOneVisit: isOneVisitFilter
                    ? isOneVisitFilter.value
                    : isOneVisit || '0',
                }),
              }}
              onClick={isNextDisabled ? e => e.preventDefault() : undefined}
            >
              <Button icon='pi pi-chevron-right' disabled={isNextDisabled} />
            </Link>
          </div>
          <div className='search-parent'>
            <MultiSelect
              value={clinicalDepartments.filter(({ id }) =>
                clinicalDepartmentIds.includes(id)
              )}
              fixedPlaceholder
              options={clinicalDepartments}
              optionLabel='clinicName'
              onChange={e => {
                onSearchClinicDepartments(e)
              }}
              filter
              placeholder={i18n.t('main.chooseClinic')}
            />
            <Dropdown
              value={workPatternFilter}
              options={workPatterns}
              optionLabel='description'
              valueLabel='value'
              placeholder='医師勤務種別選択'
              onChange={e => {
                setWorkPatternFilter(e.value)
              }}
              style={{ marginLeft: '20px' }}
            />
            <InputText
              className='black-input'
              value={nameFilter}
              name='fullname'
              placeholder={i18n.t('main.fullname')}
              onChange={e => {
                setNameFilter(e.target.value)
              }}
              style={{
                width: '170px',
                marginLeft: '20px',
                marginRight: '20px',
              }}
            />
            <DatePicker
              className='black-input-datePicker'
              defaultPickerValue={date}
              locale={locale}
              defaultDate={date}
              format='YYYY年MM月DD日'
              style={{ border: '1px solid gray' }}
              defaultValue={dateSelected ? moment(dateSelected) : null}
              onChange={value => {
                setDateSelected(value ? moment(value).toString() : '')
              }}
              disabledDate={disabledDate}
            />
            <Dropdown
              value={isOneVisitFilter}
              options={optionsIsOneVisit}
              optionLabel='description'
              valueLabel='value'
              placeholder='体制選択'
              onChange={e => {
                setIsOneVisitFilter(e.value)
              }}
              style={{ marginLeft: '20px' }}
            />
            <Button
              label={i18n.t('main.movingExpenses.search')}
              icon='pi pi-search'
              style={{ marginLeft: '20px' }}
              onClick={onSearch}
            />
            {fullname ||
            daySearch ||
            workPatterSearch ||
            isOneVisit ||
            clinicalDepartmentIds.length > 0 ? (
              <Button
                icon='pi pi-times'
                onClick={() => {
                  removeSearch()
                  setIsOneVisitFilter('')
                }}
                style={{
                  marginLeft: '20px',
                  marginRight: '1px',
                  height: '35px',
                }}
              />
            ) : null}
          </div>
          <Formik
            initialValues={{
              createdAt: false,
            }}
            render={formikProps => {
              const { setFieldValue, values } = formikProps
              return (
                <div className='overflow-container'>
                  <table className='table-workSchedule'>
                    <thead>
                      <tr>
                        <th width='18%'>
                          {i18n.t('staff.acceptedWorkSchedulesList.shiftDate')}
                        </th>
                        <th width='10%'>元のシフト時間</th>
                        <th width='16%'>{i18n.t('main.fullname')}</th>
                        <th width='14%'>
                          {i18n.t(
                            'staff.acceptedWorkSchedulesList.shiftUpdateDate'
                          )}
                        </th>
                        <th width='14%'>{i18n.t('main.clinicName')}</th>
                        <th width='14%'>{i18n.t('main.departmentName')}</th>
                        <th width='6%'>
                          {i18n.t(
                            'staff.acceptedWorkSchedulesList.isFixedShiftLabel'
                          )}
                        </th>
                        <th width='12%'>
                          {i18n.t(
                            'staff.acceptedWorkSchedulesList.doctorWorkPatternLabel'
                          )}
                        </th>
                        {isOneVisit === '1' && (
                          <th width='12%'>
                            {i18n.t(
                              'staff.acceptedWorkSchedulesList.consultationShiftTimeLabel'
                            )}
                          </th>
                        )}
                        {isUpdatePermitted ? <th width='6%' /> : null}
                      </tr>
                    </thead>
                    <tbody>
                      {workScheduleLists.length > 0 ? (
                        workScheduleLists.map(ws => (
                          <tr key={ws.id}>
                            <td>
                              {`${moment(ws.startTime).format(
                                'YYYY年MM月DD日(ddd)'
                              )} ${moment(ws.startTime).format(
                                'HH:mm'
                              )}～${moment(ws.endTime).format('HH:mm')}`}
                            </td>
                            <td>
                              {ws.actualTime &&
                              checkShowActualTime(ws.startTime)
                                ? ws.actualTime
                                : ''}
                            </td>
                            <td>{ws.fullName}</td>
                            <td>
                              {moment(ws.updatedAt).format(
                                'YYYY年MM月DD日(ddd) HH:mm'
                              )}
                            </td>
                            <td>{ws.clinicName}</td>
                            <td>{ws.clinicalDepartmentName}</td>
                            <td>
                              {ws.scheduledAvailableShiftGroup
                                ? i18n.t(
                                    'staff.acceptedWorkSchedulesList.isFixedShiftLabel'
                                  )
                                : null}
                            </td>
                            <td>
                              {ws.workPatternName ? ws.workPatternName : null}
                            </td>
                            {isOneVisit === '1' && (
                              <td>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  {ws.isshinTime &&
                                    ws.isshinTime.split(', ')[0]}
                                  <br />
                                  {ws.isshinTime.split(', ').length > 1 && (
                                    <div>...</div>
                                  )}
                                </div>
                              </td>
                            )}
                            {isUpdatePermitted ? (
                              <td style={{ textAlign: 'center' }}>
                                <ModalLink
                                  to={Router.get(
                                    Router.staffDetailWorkSchedule,
                                    {
                                      workScheduleId: ws.id,
                                    }
                                  )}
                                  state={{
                                    acceptedShiftId: ws.acceptedShiftId,
                                    isshinTime: ws?.isshinTime || '',
                                  }}
                                >
                                  <Button icon='pi pi-pencil' />
                                </ModalLink>
                              </td>
                            ) : null}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={7}>{i18n.t('main.noWorkSchedule')}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <Paginator
                    rows={rowsPerPage}
                    totalRecords={count}
                    first={(page - 1) * rowsPerPage}
                    onPageChange={onPageChange}
                    leftContent={
                      <div className='paginator-total'>
                        {i18n.t('main.paginationTotal')}: {count}
                      </div>
                    }
                  />
                </div>
              )
            }}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default AcceptedWorkSchedulesListScene
