import moment from 'moment'
import { Button } from 'primereact/button'
import React from 'react'
import { isMobile } from 'react-device-detect'

export const AvailableShiftHeader = ({
  selectedDate,
  setSelectedDate,
  isSelectedDateHoliday,
}) => {
  if (isMobile) return null

  return (
    <div className='content-title'>
      {isSelectedDateHoliday ? (
        <h2>
          {selectedDate
            ? `${moment(selectedDate).format('M月D日 (dd)')}(祝)`
            : ''}
        </h2>
      ) : (
        <h2>
          {selectedDate ? moment(selectedDate).format('M月D日 (dd)') : ''}
        </h2>
      )}
      <Button
        icon='pi pi-times'
        className='p-button-secondary'
        onClick={() => {
          setSelectedDate(null)
        }}
      />
    </div>
  )
}
