import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { InputTextarea } from 'primereact/inputtextarea'
import React, { useEffect, useRef } from 'react'
import { isMobile } from 'react-device-detect'

export default function DialogViewShift({
  open,
  onClose,
  addItemToCart,
  item,
  isAddToCart,
}) {
  const textareaRef = useRef(null)

  useEffect(() => {
    const textarea = textareaRef.current?.element
    if (textarea) {
      if (isMobile) {
        textarea.style.height = 'auto'
      } else {
        // textarea.style.height = 'auto'
        textarea.style.height = `${textarea.scrollHeight}px`
      }
    }
  }, [item?.comment])

  const footerContent = (
    <div>
      <Button
        label='確認してカートに入れる'
        icon='pi pi-check'
        onClick={() => {
          addItemToCart(item)
          onClose()
        }}
        autoFocus
        className='reflect-button'
        style={{
          marginBottom: isMobile && 4,
        }}
      />
      <Button
        label='やめる'
        icon='pi pi-times'
        onClick={onClose}
        className='p-button-text'
      />
    </div>
  )

  return (
    <Dialog
      header={isAddToCart ? '確認' : '募集シフト'}
      visible={open}
      onHide={onClose}
      breakpoints={{ '960px': '75vw' }}
      style={{
        width: isMobile ? '80vw' : '50vw',
        textAlign: 'center',
      }}
      footer={isAddToCart ? footerContent : undefined}
    >
      <table className='single-available-shift modal-table'>
        <tbody>
          <tr>
            <th style={{ width: '20%' }}>業務内容（備考）</th>
            <td
              style={{
                maxHeight: '60vh',
                overflow: 'auto',
                marginLeft: isMobile ? 0 : '10%',
              }}
            >
              <InputTextarea
                ref={textareaRef}
                value={item?.comment || ''}
                disabled
                autoResize
                style={{
                  minHeight: '128px',
                  maxHeight: '70vh',
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </Dialog>
  )
}
