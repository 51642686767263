import { cloudWatchLogger } from '@medical/libs'

const popupGenerator = (growl, i18n) => ({
  success: detail =>
    growl.current.show({
      severity: 'success',
      summary: i18n.t('main.SUCCESS'),
      detail,
    }),
  error: detail => {
    try {
      // logger.info({
      //   level: 'INFO',
      //   detail,
      // })
      if (typeof detail === 'string') {
        growl.current.show({
          severity: 'error',
          summary: i18n.t('main.ERROR'),
          detail,
        })
      } else {
        if (detail && detail.networkError) {
          growl.current.show({
            severity: 'error',
            summary: i18n.t('main.ERROR'),
            detail: i18n.t('main.NetworkError'),
          })
          return
        }
        if (detail && detail.graphQLErrors && detail.graphQLErrors.length > 0) {
          growl.current.show({
            severity: 'error',
            summary: i18n.t('main.ERROR'),
            detail: detail.graphQLErrors[0].message,
          })
          return
        }
        growl.current.show({
          severity: 'error',
          summary: i18n.t('main.ERROR'),
          detail: i18n.t('main.ERROR'),
        })
      }
    } catch (error) {
      cloudWatchLogger().error({
        level: 'ERROR',
        urlError: window.location.href,
        detail: error,
        eM: 'Network Error',
      })
    }
  },
  info: detail =>
    growl.current.show({
      sticky: true,
      severity: 'info',
      summary: i18n.t('main.INFO'),
      detail,
    }),
  warn: detail =>
    growl.current.show({
      severity: 'warn',
      summary: i18n.t('main.WARNING'),
      detail,
    }),
  clear: () => growl.current.clear(),
})

export default popupGenerator
