import {
  SET_CLICK_DATETIME_IN_AVAILABLE_SHIFT_FIRST_TIME,
  UPDATE_CANCELLATION_LETTER,
  UPDATE_RECRUITMENT_SHIFT,
} from './staff.action'

const staffReducer = (
  state = {
    isChangeDateFirstTime: false,
    isUpdateCancellationLetter: false,
    isUpdateRecruitmentShift: false,
  },
  action
) => {
  const { type, payload } = action
  switch (type) {
    case SET_CLICK_DATETIME_IN_AVAILABLE_SHIFT_FIRST_TIME:
      return {
        ...state,
        isChangeDateFirstTime: payload,
      }
    case UPDATE_CANCELLATION_LETTER:
      return {
        ...state,
        isUpdateCancellationLetter: payload,
      }
    case UPDATE_RECRUITMENT_SHIFT:
      return {
        ...state,
        isUpdateRecruitmentShift: payload,
      }

    default:
      return state
  }
}
export default staffReducer
