import { calcWorkScheduleTotalHourPerDay } from '@medical/libs'
import numeralCurrency from '@medical/libs/numeralCurrency'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'

import styles from './Style'

const RequestedWorkSchedulesListSp = ({ workSchedule }) => {
  const { i18n } = useTranslation()
  return (
    <div style={styles.mobileRequest}>
      <div style={styles.mobileRequestItem}>
        {i18n.t('main.clinicName')} :
        <span style={styles.mobileRequestItemContent}>
          {workSchedule.clinic}
        </span>
      </div>
      <div style={styles.mobileRequestItem}>
        {i18n.t('main.departmentName')} :
        <span style={styles.mobileRequestItemContent}>
          {workSchedule.clinicalDepartment}
        </span>
      </div>
      <div style={styles.mobileRequestItem}>
        {i18n.t('main.startTime')} :
        <span style={styles.mobileRequestItemContent}>
          {moment(workSchedule.startTime).format('HH:mm')}
        </span>
      </div>
      <div style={styles.mobileRequestItem}>
        {i18n.t('main.endTime')} :
        <span style={styles.mobileRequestItemContent}>
          {moment(workSchedule.endTime).format('HH:mm')}
        </span>
      </div>
      <div style={styles.mobileRequestItem}>
        {i18n.t('main.shiftHour')} :
        <span style={styles.mobileRequestItemContent}>
          {calcWorkScheduleTotalHourPerDay(workSchedule)}
        </span>
      </div>
      <div style={styles.mobileRequestItem}>
        {i18n.t('main.dailySalary')} :
        <span style={styles.mobileRequestItemContent}>
          {numeralCurrency(workSchedule.dailySalary)}
        </span>
      </div>
    </div>
  )
}

export default RequestedWorkSchedulesListSp
