import { useMutation } from '@apollo/react-hooks'
import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import {
  clinicalDepartmentNames,
  combineNames,
  sortAndFilterByClinicOrder,
} from '@medical/libs'
import AnnounceForm from '@medical/pages/staff/Announces/components/AnnounceForm'
import { DOCTORS_DEPARTMENTS } from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import { useCustom } from '@medical/provider/context'
import Router from '@medical/routes/router'
import React from 'react'

import { CREATE_ANNOUNCE } from './AnnounceCreate.graphql'

const AnnounceCreate = ({ history }) => {
  const [{ i18n, popup }] = useCustom()
  const [createAnnounce] = useMutation(CREATE_ANNOUNCE)

  const onSubmit = async (
    {
      title,
      content,
      isPublished,
      isSentEmailToAllDoctors,
      file,
      isAddAvailableShift,
      availableShiftsIds,
      isAddRecruitmentPost,
      recruitmentPostIds,
      isNotSendDoctorBlock,
      isNotSendDoctorWorkScheduleBlock,
    },
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    const variables = {
      title,
      content,
      isPublished,
      isSentEmailToAllDoctors,
      image: file,
      isAddAvailableShift,
      availableShifts: availableShiftsIds,
      isAddRecruitmentPost,
      recruitmentPosts: recruitmentPostIds,
      isNotSendDoctorBlock,
      isNotSendDoctorWorkScheduleBlock,
    }
    try {
      await createAnnounce({
        variables,
      })
      popup.success(i18n.t('staff.createAnnounce.submissionSuccess'))
      setSubmitting(false)
      resetForm()
      history.push({
        pathname: Router.staffAnnounces,
      })
    } catch (error) {
      popup.error(error)
      setFieldValue('confirm', false)
      setSubmitting(false)
    }
  }
  const {
    loading: clinicalDepartmentLoading,
    error: clinicalDepartmentErr,
    data: clinicalDepartmentData,
  } = useQuery(DOCTORS_DEPARTMENTS)
  if (clinicalDepartmentLoading) return <ProgressSpinner />
  if (clinicalDepartmentErr)
    return <ErrorComponent error={clinicalDepartmentErr} />

  const { clinicalDepartments, departments } = clinicalDepartmentData
  const departmentNames = clinicalDepartmentNames({ departments })
  const sortedClinicalDepartments = sortAndFilterByClinicOrder({
    clinicalDepartments,
    isFilter: true,
  })
  return (
    <AnnounceForm
      announce={{}}
      onSubmit={onSubmit}
      submitLabel={i18n.t('staff.createAnnounce.submit')}
      confirmationMessage={i18n.t('staff.createAnnounce.submitConfirmation')}
      isUpdate={false}
      clinicalDepartments={combineNames({
        clinicalDepartments: sortedClinicalDepartments,
        departmentNames,
      })}
      departmentNames={departmentNames}
    />
  )
}

export default AnnounceCreate
