import ClinicNameRender from '@medical/components/ClinicNameRender'
import { Input, Typography } from 'antd'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import React from 'react'
import * as Yup from 'yup'

import AcceptedWorkScheduleCancelConfirmation from './AcceptedWorkScheduleCancelConfirmation'

const AcceptedWorkScheduleCancelScene = ({
  i18n,
  workSchedule,
  departmentNames,
  onSubmit,
  closeModal,
}) => {
  const { Text } = Typography
  const { TextArea } = Input
  const { clinicalDepartment, startTime, endTime, doctor } = workSchedule
  const startTimeAddTwoWeeks = moment(startTime)
    .subtract(14, 'days')
    .valueOf()
  const currentTime = moment().valueOf()
  const isCheckTime = startTimeAddTwoWeeks <= currentTime
  const validationSchema = Yup.object({
    reason: Yup.string()
      .required(`${i18n.t('main.reason')}${i18n.t('validation.required')}`)
      .max(300, i18n.t('validation.reply.max', { max: 300 })),
    reassignDoctorNote:
      isCheckTime &&
      Yup.string().required(
        `${i18n.t('main.reassignDoctorNote')}${i18n.t('validation.required')}`
      ),
  })
  const confirmationMessageAfter =
    '勤務から2週間以内のキャンセルは、ドクターサポート事務局に事前連絡が必要です。応募の際にご同意いただいておりますように、2週間前のキャンセルは基本的にお断りさせていただいております。事前にドクターサポ―ト事務局にご連絡はしていただいておりますでしょうか。'
  const confirmationMessageBefor = `${moment(startTime).format('LL')}${
    departmentNames[clinicalDepartment.name]
  }  ${ClinicNameRender({ clinicalDepartment })} ${moment(startTime).format(
    'HH:mm'
  )} ~
  ${moment(endTime).format(
    'HH:mm'
  )}のキャンセル申請が送信されました。キャンセル理由を含む、詳細をドクターサポート事務局で確認させていだき、承認されると、キャンセルとなります。キャンセルが承認されるまで、今しばらくお待ちください。`
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={{
        confirm: false,
        reason: '',
        reworkNote: '',
        isTickCountCancel: null,
        reassignDoctorNote: '',
        workScheduleId: workSchedule.id,
        isCheckTime,
      }}
      onSubmit={onSubmit}
      render={formikProps => {
        const {
          touched,
          errors,
          setTouched,
          setFieldValue,
          validateForm,
          handleChange,
        } = formikProps
        return (
          <>
            <div className='tr'>
              <div className='th '>{i18n.t('main.clinicName')}</div>
              <div className='td td-cancel'>
                {ClinicNameRender({ clinicalDepartment })}
              </div>
            </div>
            <div className='tr'>
              <div className='th '>{i18n.t('main.departments')}</div>
              <div className='td td-cancel'>
                {departmentNames[clinicalDepartment.name]}
              </div>
            </div>
            <div className='tr'>
              <div className='th '>{i18n.t('main.scheduledWorkDate')}</div>
              <div className='td td-cancel'>
                <td>
                  {moment(startTime).format('LL (dd)')}
                  {isCheckTime ? (
                    <Text style={{ color: '#f03a6c', marginLeft: '20px' }}>
                      *2週間以内のため、キャンセル期限を過ぎています
                    </Text>
                  ) : (
                    ''
                  )}
                </td>
              </div>
            </div>
            <div className='tr'>
              <div className='th '>{i18n.t('main.time')}</div>
              <div className='td td-cancel'>
                {moment(startTime).format('HH:mm')} ~
                {moment(endTime).format('HH:mm')}
              </div>
            </div>
            <div className='tr'>
              <div className='th '>直近6ヶ月間のキャンセル申請回数</div>
              <div className='td td-cancel'>{doctor.totalCancelLetter}</div>
            </div>
            <div className='tr'>
              <div className='th required'>{i18n.t('main.reason')}</div>
              <div className='td'>
                <TextArea
                  onChange={handleChange}
                  rows='5'
                  name='reason'
                  placeholder='詳しく記入してください'
                  cols='20'
                  allowClear
                />
                <div className='alert'>{touched.reason && errors.reason}</div>
              </div>
            </div>
            <div className='tr'>
              <div className={`th ${isCheckTime ? 'required' : ''}`}>
                {i18n.t('main.reassignDoctorNote')}
              </div>
              <div className='td'>
                <Input
                  allowClear
                  onChange={handleChange}
                  name='reassignDoctorNote'
                  placeholder='例：鈴木愛子先生が代わりに入ってくださいます'
                />
                <div className='alert'>
                  {touched.reassignDoctorNote && errors.reassignDoctorNote}
                </div>
              </div>
            </div>
            {!isCheckTime ? (
              <div className='tr'>
                <div className='th'>{i18n.t('main.reworkNote')}</div>
                <div className='td'>
                  <Input
                    name='reworkNote'
                    onChange={handleChange}
                    allowClear
                    placeholder='例：1月4日10:00～17:00まで勤務可能です'
                  />
                </div>
              </div>
            ) : null}
            <Text style={{ color: '#f03a6c' }}>
              ※キャンセル申請が承認されるまでは、キャンセル確定ではありません。
              <br />
              ※2週間以内のキャンセルにつきましては、やむを得ない理由を除き、代診者を見つけてください。
            </Text>
            <div className='modal-bottom'>
              <Button
                label={i18n.t('main.applyDoctor')}
                onClick={() => {
                  validateForm().then(response => {
                    if (!Object.keys(response).length) {
                      setFieldValue('confirm', true)
                    } else {
                      setTouched(response)
                    }
                  })
                }}
                className='reflect-button btn'
              />
            </div>
            <AcceptedWorkScheduleCancelConfirmation
              {...formikProps}
              closeModal={closeModal}
              isCheckTime={isCheckTime}
              confirmationMessageBefor={confirmationMessageBefor}
              confirmationMessage={
                isCheckTime
                  ? confirmationMessageAfter
                  : 'シフトキャンセルを申請しますか？'
              }
            />
          </>
        )
      }}
    />
  )
}

export default AcceptedWorkScheduleCancelScene
