import gql from 'graphql-tag'

export const CHECK_UPLOAD_DOCTORCSV = gql`
  mutation StaffCheckUploadDoctorCsvMutation(
    $doctorCsvRecord: [DoctorCsvRecord!]!
  ) {
    staffCheckUploadDoctorCsv(DoctorCsvRecord: $doctorCsvRecord) {
      email
      lastname
      firstname
      lastnameKana
      firstnameKana
      phoneNumber
      birthDay
      gender
      medicalLicenseNumber
      placeOfWork
      workPattern
      medicalRecord
      orca
      questionnaire
      joinBackground
      joinBackgroundOther
      departments
      errorMessage
    }
  }
`
export const UPLOAD_DOCTOR_CSV = gql`
  mutation StaffUploadDoctorCsv($doctorCsvRecord: [DoctorCsvRecord!]!) {
    staffUploadDoctorCsv(DoctorCsvRecord: $doctorCsvRecord)
  }
`
