import './style.css'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import {
  clinicalDepartmentNames,
  combineNames,
  sortAndFilterByClinicOrder,
} from '@medical/libs'
import { DEPARTMENTS_LIST } from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import { useCustom } from '@medical/provider/context'
import React, { useEffect, useState } from 'react'

import { CREATE_DEFAULT_HOURLY_WAGE_SCHEDULE } from '../ClinicsHourlyWageList/ClinicsHourlyWage.graphql'
import HourlyWageFormScene from './HourlyWageFormScene'

const HourlyWageDetail = ({
  match: {
    params: { departmentId },
  },
  parentPath,
  closeModal,
}) => {
  const [{ i18n, popup }] = useCustom()
  const { loading, error, data } = useQuery(DEPARTMENTS_LIST)
  const [createRecuitmentShift] = useMutation(
    CREATE_DEFAULT_HOURLY_WAGE_SCHEDULE
  )
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  const { clinicalDepartments, departments } = data
  const sortedClinicalDepartments = sortAndFilterByClinicOrder({
    clinicalDepartments,
    isFilter: true,
  })
  const departmentNames = clinicalDepartmentNames({ departments })
  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    let {
      // eslint-disable-next-line no-shadow
      clinicalDepartments,
      date,
      time,
      isAllday,
      isMonday,
      isTuesday,
      isWednesday,
      isThursday,
      isFriday,
      isSaturday,
      isSunday,
      isDayoff,
      hourlyWage,
      type,
    } = values

    try {
      let startDate, endDate, startTime, endTime
      startDate = date[0] + 'T' + '00:00:00.000Z'
      endDate = date[1] + 'T' + '23:59:59.000Z'
      startTime = '1000-10-04T' + time[0].split('T')[1].split('+')[0] + '.000Z'
      endTime = '1000-10-04T' + time[1].split('T')[1].split('+')[0] + '.000Z'
      if (Array.isArray(type)) {
        type = type.length > 0 ? type[0] : null
      }
      await createRecuitmentShift({
        variables: {
          clinicalDepartmentId: clinicalDepartments.value,
          startDate,
          endDate,
          startTime,
          endTime,
          hourlyWageDefault: parseInt(hourlyWage),
          isAvailableOnMonday: isMonday,
          isAvailableOnTuesday: isTuesday,
          isAvailableOnWednesday: isWednesday,
          isAvailableOnThursday: isThursday,
          isAvailableOnFriday: isFriday,
          isAvailableOnSaturday: isSaturday,
          isAvailableOnSunday: isSunday,
          isAvailableOnHoliday: isDayoff,
          classify: type,
        },
      })
      popup.success(i18n.t('staff.createShift.submissionSuccess'))
      closeModal()
      setSubmitting(false)
      resetForm()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }

  return (
    <HourlyWageFormScene
      onSubmit={onSubmit}
      clinicalDepartments={combineNames({
        clinicalDepartments: sortedClinicalDepartments,
        departmentNames,
      })}
      confirmationMessage={i18n.t('staff.createHourlyWage.createHourlyWage')}
      i18n={i18n}
    />
  )
}

export default HourlyWageDetail
