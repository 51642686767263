import gql from 'graphql-tag'

export const CLINIC_FRAGMENT = gql`
    fragment CLINIC_FRAGMENT on Clinic {
        id
        name
        nameKana
        address
        phoneNumber
        email
        isPublished
    }
`

export const CHECK_DOCTOR_ID = gql`
    mutation CheckDoctorId($doctorId: ID!) {
        checkDoctorId(doctorId: $doctorId) {
            errorMessage
            checked
        }
    }`

export const GET_DOCTOR_FILE = gql`
    mutation DoctorFile($doctorIds: [ID!]) {
        doctorFile(doctorIds: $doctorIds)
    }`

export const CLINICS_CONNECTION = gql`
    query CLINICS_CONNECTION($where: ClinicWhereInput, $skip: Int, $first: Int) {
        clinicsConnection(where: $where, skip: $skip, first: $first) {
            edges {
                node {
                    ...CLINIC_FRAGMENT
                }
            }
        }
    }
    ${CLINIC_FRAGMENT}
`

export const DOWNLOAD_LIST_FILE = gql`
    mutation DownloadListFileS3($keys: [String!]) {
        downloadListFileS3(keys: $keys)
    }
`