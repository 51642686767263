import { ApolloProvider } from '@apollo/react-hooks'
import { DOCTOR_EMAIL, STAFF_EMAIL } from '@medical/constant'
import { cloudWatchLogger } from '@medical/libs'
import { NETWORK_ERROR } from '@medical/libs/logEvents'
import middleware from '@medical/middleware'
import Auth from '@medical/middleware/auth'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { ApolloLink, split } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { WebSocketLink } from 'apollo-link-ws'
import { createUploadLink } from 'apollo-upload-client'
import { getMainDefinition } from 'apollo-utilities'
import moment from 'moment'
import React, { useState } from 'react'
import { Provider as ReduxProvider } from 'react-redux'

import { CustomProvider } from './context'
import store from './store'

const Provider = ({ children }) => {
  const [storeToken, setToken] = useState(Auth.getToken())
  const httpLink = createUploadLink({ uri: process.env.REACT_APP_ENDPOINT })
  let email
  if (middleware.isDoctor()) {
    email = localStorage.getItem(DOCTOR_EMAIL)
  } else if (middleware.isStaff()) {
    email = localStorage.getItem(STAFF_EMAIL)
  }
  let listError = []
  const filterError = (error, operationName, timeSet = 0, limit = 1) => {
    const now = moment()
    if (!listError[`${error.message}`]) {
      listError[`${error.message}`] = []
    }
    listError[`${error.message}`].push(now)
    listError[error.message] = listError[error.message].filter(function(item) {
      return new Date(now).getTime() - new Date(item).getTime() <= timeSet
    })
    if (listError[error.message].length >= limit) {
      cloudWatchLogger().error({
        level: 'ERROR',
        detail: operationName,
        email,
        urlError: window.location.href,
        eM:
          operationName !== NETWORK_ERROR ? error.debug.message : error.message,
      })
      listError[error.message] = []
    }
  }
  const errorLink = onError(
    ({ operation: { operationName }, graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        if (
          graphQLErrors[0].debug.message === 'USER_AUTH_WRONG_AUTHORIZATION'
        ) {
          filterError(graphQLErrors[0], operationName, 3 * 60 * 1000, 30)
        } else if (
          graphQLErrors[0].debug.message === 'USER_AUTH_SSO_OLD_LOGIN'
        ) {
          filterError(graphQLErrors[0], operationName, 3 * 60 * 1000, 30)
        } else if (
          graphQLErrors[0].debug.message === 'USER_LOGIN_INVALID_PASSWORD'
        ) {
          filterError(graphQLErrors[0], operationName, 3 * 60 * 1000, 30)
        } else if (graphQLErrors[0].debug.message === 'NO_PAST_MONTH_SHIFT') {
          filterError(graphQLErrors[0], operationName, 3 * 60 * 1000, 30)
        } else if (
          graphQLErrors[0].debug.message === 'USER_LOGIN_NOT_CONFIRMED'
        ) {
          filterError(graphQLErrors[0], operationName, 3 * 60 * 1000, 30)
        } else if (graphQLErrors[0].debug.message === 'USER_LOGIN_NO_EMAIL') {
          filterError(graphQLErrors[0], operationName, 1 * 60 * 1000, 3)
        } else {
          filterError(graphQLErrors[0], operationName)
        }
      }
      if (networkError) {
        filterError(networkError, NETWORK_ERROR, 3 * 60 * 1000, 30)
        // cloudWatchLogger().error({
        //   level: 'ERROR',
        //   detail: NETWORK_ERROR,
        //   email,
        //   urlError: window.location.href,
        //   eM: networkError.message,
        // })
      }
    }
  )
  const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_WS_ENDPOINT,
    options: {
      reconnect: true,
      connectionParams: async () => ({
        Authorization: `Bearer ${storeToken}`,
      }),
    },
  })
  const authLink = setContext(({ operationName, variables }) => {
    // get the authentication token from local storage if it exists
    const token = Auth.getToken()
    // if (
    //   ![
    //     'WorkScheduleCancellationLettersConnection',
    //     'checkNewMeggage',
    //   ].includes(operationName)
    // ) {
    //   cloudWatchLogger().info({
    //     level: 'INFO',
    //     detail: operationName,
    //     v: variables.password ? variables.email : variables,
    //     token,
    //   })
    // }

    return {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  })
  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink,
    httpLink
  )
  const cache = new InMemoryCache()
  const client = new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, link]),
    cache,
  })

  return (
    <ReduxProvider store={store}>
      <CustomProvider setToken={setToken}>
        <ApolloProvider client={client}>{children}</ApolloProvider>
      </CustomProvider>
    </ReduxProvider>
  )
}

export default Provider
