import { useMutation } from '@apollo/react-hooks'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Router from '@medical/routes/router'
import './style.css'
import { DOWNLOAD_FILE } from '@medical/pages/staff/Doctors/DoctorDetail/DoctorDetail.graphql'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Col, Row } from 'antd'
import { ReactComponent as Clinic } from '@medical/assets/image/clinic.svg'
import numeralCurrency from '@medical/libs/numeralCurrency'
import { InputTextarea } from 'primereact/inputtextarea'
import { Breadcrumb } from 'antd'
import DialogViewShift from '../../DoctorHome/components/DialogViewShift'

const NewDetalScene = ({
  i18n,
  announce = [],
  departmentNames,
  addItemToCart,
  removeItemFromCart,
  cartItems,
}) => {
  const [linkUrl, setUrl] = useState(null)
  const [listData, setListData] = useState([])
  const [isAlert, setIsAlert] = useState(false)
  const [comment, setComment] = useState('')
  const [selectedShift, setSelectedShift] = useState()
  const [isAddToCart, setIsAddToCart] = useState(false)

  const [downloadFile] = useMutation(DOWNLOAD_FILE)
  // download image detail
  const downloadFileSubmit = async (key, callback) => {
    if (!key || key.length === 0) return
    try {
      const {
        data: { downloadFileS3: fileURL },
      } = await downloadFile({
        variables: {
          key,
        },
      })
      callback(fileURL)
    } catch (error) {
      callback(null)
    }
  }

  // download list image
  const downloadListFileSubmit = async key => {
    if (!key || key.length === 0) return null
    try {
      const {
        data: { downloadFileS3: fileURL },
      } = await downloadFile({
        variables: {
          key,
        },
      })
      return fileURL
    } catch (error) {
      return null
    }
  }
  const renderStatus = status => {
    switch (status) {
      case 'NOT_APPLY':
        return <span style={{ color: '#ff518f' }}>未応募</span>

      case 'APPLIED':
        return <span style={{ color: '#369efd' }}>応募中</span>

      case 'COMPLETED':
        return <span style={{ color: '#647380' }}>対応終了</span>
      default:
        return ''
    }
  }

  const handleCloseViewShift = () => {
    setSelectedShift(undefined)
  }

  const handleClickAdd = value => {
    if (value.comment) {
      setIsAddToCart(true)
      setSelectedShift(value)
    } else {
      addItemToCart(value)
    }
  }

  const handleClickView = value => {
    setIsAddToCart(false)
    setSelectedShift(value)
  }

  useEffect(() => {
    if (announce) {
      if (announce?.imageKey) {
        downloadFileSubmit(announce?.imageKey, fileURL => {
          if (fileURL != null) {
            setUrl(fileURL)
          }
        })
      }
      if (!announce?.imageKey) {
        downloadFileSubmit(process.env.REACT_APP_IMAGE_DEFAULT, fileURL => {
          if (fileURL != null) {
            setUrl(fileURL)
          }
        })
      }

      if (announce.recruitmentPostList.length > 0) {
        const fetchData = async () => {
          const promises = []
          for (const item of announce?.recruitmentPostList) {
            if (item.imageKey) {
              promises.push(downloadListFileSubmit(item.imageKey))
            }
            if (!item.imageKey) {
              promises.push(
                downloadListFileSubmit(process.env.REACT_APP_IMAGE_DEFAULT)
              )
            }
          }
          const results = await Promise.all(promises)
          const temp = announce?.recruitmentPostList.map((el, index) => ({
            ...el,
            img: results[index],
          }))
          setListData(temp)
        }

        fetchData()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [announce])

  return (
    <div className='container-news-detail'>
      <div className='staff-header' style={{ marginTop: '20px' }}>
        <div
          className='staff-title staff-title-detail-new'
          style={{ marginTop: '10px' }}
        >
          {i18n.t('doctor.menuBar.newsDetail')}
          {/* <div className='breadcrumb'>
            <Link to={Router.home}>{i18n.t('main.home')}</Link>
            <Link to={Router.doctorNews}>
              <i className='pi pi-chevron-right' />
              お知らせ一覧
            </Link>
            <React.Fragment>
              <i className='pi pi-chevron-right' />
              {announce?.title}
            </React.Fragment>
          </div> */}
          <Breadcrumb
            style={{ marginTop: '10px' }}
            separator={<i className='pi pi-chevron-right' />}
          >
            <Breadcrumb.Item className='breadcrumb-item' href={Router.home}>
              {i18n.t('main.home')}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className='breadcrumb-item'
              href={Router.doctorNews}
            >
              お知らせ一覧
            </Breadcrumb.Item>
            <Breadcrumb.Item className='breadcrumb-item breadcrumb-title'>
              {announce?.title}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <div className='news_wrap_detail'>
        <div className='recruitment-detail-img'>
          <img
            height='100%'
            width='100%'
            style={{ aspectRatio: '2/1', objectFit: 'contain', margin: 'auto' }}
            src={linkUrl}
            alt=''
          />
        </div>
        <div className='news-detail-content'>
          <div className='recruitment_title'>{announce?.title}</div>

          <div
            className='news_content'
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: announce?.content,
            }}
          />
          <div className='table_recruitment'>
            {announce?.availableShiftList.length > 0 &&
            announce?.isAddAvailableShift ? (
              <>
                <h2 className='table_recruitment-title'>対象募集シフト</h2>
                <div className='table_recruitment_content'>
                  <Formik
                    initialValues={{ confirm: false, announceId: '' }}
                    render={formikProps => (
                      <div>
                        <div className='is-pc'>
                          <table className='table' style={{ width: '100%' }}>
                            <thead>
                              <tr>
                                <th width='20%'>{i18n.t('main.clinicName')}</th>
                                <th width='15%'>
                                  {i18n.t('main.departmentName')}
                                </th>
                                <th width='22%'>{i18n.t('main.date')}</th>
                                <th width='12%'>{i18n.t('main.startTime')}</th>
                                <th width='12%'>{i18n.t('main.endTime')}</th>
                                <th width='12%'>
                                  {i18n.t('main.dailySalary')}
                                </th>
                                <th width='5%'>
                                  {i18n.t('main.descriptionDetail')}
                                </th>
                                <th width='5%'>{i18n.t('main.apply')}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* {sortedAvailableShifts.length > 0 ? (
                          sortedAvailableShifts.filter
                        )} */}
                              {announce?.availableShiftList.map(item => (
                                <tr>
                                  <td align='center'>
                                    {item.clinicalDepartment.clinic.name}
                                  </td>
                                  <td align='center'>{item.clinicName}</td>
                                  <td align='center'>
                                    {moment(item.endTime).format('LL (dd)')}
                                  </td>
                                  <td align='center'>
                                    {moment(item.startTime).format('LT')}
                                  </td>
                                  <td align='center'>
                                    {moment(item.endTime).format('LT')}
                                  </td>
                                  <td align='center'>
                                    {numeralCurrency(item.dailySalary)}
                                  </td>
                                  <td align='center'>
                                    <Button
                                      icon='pi pi-info'
                                      className='p-button-secondary'
                                      disabled={!item.comment}
                                      onClick={() => {
                                        setIsAlert(true)
                                        setComment(item.comment)
                                      }}
                                    />

                                    {isAlert ? (
                                      <div className='react-router-modal__container'>
                                        <div className='react-router-modal__wrapper'>
                                          <div className='react-router-modal__backdrop react-router-modal__backdrop--in' />
                                          <div
                                            className='react-router-modal__modal react-router-modal__modal--in'
                                            role='dialog'
                                          >
                                            <div className='modal-container'>
                                              <div className='modal-title'>
                                                募集シフト
                                                <Button
                                                  onClick={() => {
                                                    setIsAlert(false)
                                                  }}
                                                  icon='pi pi-times'
                                                  className='p-button-plain'
                                                  style={{
                                                    marginRight: '20px',
                                                    float: 'right',
                                                  }}
                                                />
                                              </div>
                                              <div
                                                className='comment-wrap'
                                                style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                }}
                                              >
                                                <p
                                                  className='comment-title'
                                                  style={{
                                                    fontSize: '15px',
                                                    width: '30%',
                                                    marginRight: '20px',
                                                  }}
                                                >
                                                  業務内容（備考）
                                                </p>
                                                <InputTextarea
                                                  style={{ marginTop: '60px' }}
                                                  value={comment}
                                                  disabled
                                                  autoResize
                                                  rows={6}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </td>
                                  <td align='center'>
                                    <div className='table-action'>
                                      {cartItems.some(
                                        element => element.id === item.id
                                      ) ? (
                                        <Button
                                          onClick={() =>
                                            removeItemFromCart(item.id)
                                          }
                                          icon='pi pi-times'
                                          className='p-button-danger'
                                        />
                                      ) : (
                                        <Button
                                          onClick={() => handleClickAdd(item)}
                                          icon='pi pi-plus'
                                          className='p-button-success'
                                        />
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div className='is-sp'>
                          {announce?.availableShiftList.map(item => (
                            <Row
                              className='table_recruitment_sp'
                              style={{ marginBottom: '5px' }}
                              gutter={{
                                xs: 8,
                                sm: 16,
                              }}
                            >
                              <Col className='gutter-row' span={18}>
                                <Row
                                  style={{ justifyContent: 'space-between' }}
                                >
                                  <span className='infor-table'>
                                    {moment(item.endTime).format(
                                      'YYYY[/]M[/]D'
                                    )}
                                  </span>
                                  <span className='infor-table'>
                                    {item.clinicalDepartment.clinic.name}
                                  </span>
                                  <span className='infor-table'>
                                    {item.clinicName}
                                  </span>
                                </Row>
                                <Row
                                  style={{ justifyContent: 'space-between' }}
                                >
                                  <span className='infor-table'>
                                    {moment(item.startTime).format('LT')}
                                    {' ~ '}
                                    {moment(item.endTime).format('LT')}
                                  </span>
                                  <span className='infor-table'>
                                    {numeralCurrency(item.dailySalary)}
                                  </span>
                                </Row>
                              </Col>
                              <Col
                                className='gutter-row'
                                span={6}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'end',
                                  columnGap: 8
                                }}
                              >
                                <Button
                                  disabled={!item?.comment}
                                  onClick={() => handleClickView(item)}
                                  icon='pi pi-info'
                                  className='p-button-secondary'
                                />
                                {cartItems.some(
                                      element => element.id === item.id
                                    ) ? (
                                      <Button
                                        onClick={() =>
                                          removeItemFromCart(item.id)
                                        }
                                        icon='pi pi-times'
                                        className='p-button-danger'
                                      />
                                    ) : (
                                      <Button
                                        onClick={() => {
                                          handleClickAdd(item)
                                        }}
                                        icon='pi pi-plus'
                                        className='p-button-success'
                                      />
                                    )}
                              </Col>
                            </Row>
                          ))}
                        </div>
                      </div>
                    )}
                  />
                </div>
              </>
            ) : (
              <div />
            )}
          </div>
        </div>

        <div className='recruitment_post'>
          {announce?.recruitmentPostList?.length > 0 &&
          announce?.isAddRecruitmentPost ? (
            <>
              <h2>対象募集要項</h2>
              <div className='recruitment_post'>
                {listData.map(item => (
                  <Row style={{ marginBottom: '20px' }}>
                    <Col
                      className='gutter-row news-image-detail'
                      xs={{
                        span: 24,
                        offset: 0,
                      }}
                      md={{
                        span: 8,
                        offset: 0,
                      }}
                      lg={{
                        span: 8,
                        offset: 0,
                      }}
                    >
                      <Link
                        to={Router.get(Router.doctorRecruitmentDetail, {
                          recruitmentId: item.id,
                        })}
                        style={{ maxHeight: '100%' }}
                      >
                        <img src={item.img} alt='' />
                      </Link>
                    </Col>
                    <Col
                      className='gutter-row '
                      xs={{
                        span: 24,
                        offset: 0,
                      }}
                      md={{
                        span: 16,
                        offset: 0,
                      }}
                      lg={{
                        span: 16,
                        offset: 0,
                      }}
                      style={{ marginTop: '-20px' }}
                    >
                      <div className='recruitment-item-content'>
                        <div
                          style={{
                            display: 'flex',
                            marginTop: '20px',
                            alignItems: 'center',
                          }}
                        >
                          <span className='recruitment_type-link'>
                            {item.recruitmentDoctorType === 'PERMANENT_DOCTOR'
                              ? '常勤'
                              : '定期非常勤'}
                          </span>
                          <span
                            style={{ marginLeft: '10px' }}
                            className='is-sp'
                          >
                            {renderStatus(item?.status)}
                          </span>
                        </div>

                        <Link
                          to={Router.get(Router.doctorRecruitmentDetail, {
                            recruitmentId: item.id,
                          })}
                        >
                          <h2
                            className='recruitment-title'
                            style={{ fontWeight: 'bold', margin: '0px' }}
                          >
                            {item.title}
                          </h2>
                        </Link>
                        <p className='recruitment-content-post'>
                          {item.content.replace(/<[^>]+>/g, '')}
                        </p>
                        <div
                          className='recruitment_subtitle'
                          style={{
                            display: 'flex',
                            color: 'black',
                            padding: '0px',
                          }}
                        >
                          <i
                            style={{
                              marginRight: '5px',
                              marginBottom: '-3px',
                            }}
                            className='bg-red mt-0'
                          >
                            <Clinic />
                          </i>
                          {`${item.clinicalDepartment.clinic.name}_${
                            departmentNames[item.clinicalDepartment.name]
                          }`}
                        </div>
                      </div>
                    </Col>
                  </Row>
                ))}
              </div>
            </>
          ) : (
            <div />
          )}
        </div>
      </div>

      {!!selectedShift && (
        <DialogViewShift
          open={!!selectedShift}
          onClose={handleCloseViewShift}
          item={selectedShift}
          addItemToCart={addItemToCart}
          isAddToCart={isAddToCart}
        />
      )}
    </div>
  )
}

export default NewDetalScene
