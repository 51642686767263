import { Modal } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const TransportRegisterModal = ({ values, setFieldValue, name }) => {
  const { i18n } = useTranslation()
  const noClick = () => setFieldValue(`${name}`, false)
  let title = ''
  let msg = ''
  switch (name) {
    case 'showNote':
      title = '通勤経路登録について'
      msg = (
        <>
          各拠点において、初回の勤務時には、こちらをご入力下さい。
          <br />
          交通費は、この申請に基づき、各拠点の勤務日数に基づき、月末締め、翌月25日にお支払いさせていただきます。
          <br />
          交通費の1日の上限は、5,000円となります。 <br />
          <br />
          【公共交通機関を利用する場合】 <br />
          Yahoo!の路線情報
          <Link
            to='#'
            onClick={() => {
              window.open('https://transit.yahoo.co.jp/', '_blank')
            }}
          >
            (https://transit.yahoo.co.jp/)
          </Link>
          を使用し、出発地の最寄り駅、最寄りバス停を、到着地にクリニックの最寄り駅、バス停を入力し、通勤に使う経路を検索してください。検索結果後、ルート表示された見出しの部分に「ルート共有」のボタンがあります。それをクリックすると下に、QRコードとURLが表示されますので、そのURLをコピーし、こちらの経路URLに貼り付けてください。ドクターサイト内の出発地、到着地にも同様にご入力いただき、IC料金を片道料金の欄にご入力下さい。
          <br />
          <br />
          また、以下の区間は、駅からクリニックまで、タクシーの利用を認めております。
          <br />
          ・北葛西：船堀駅・西葛西駅・葛西駅からキャップスクリニック北葛西の間
          <br />
          ・越谷レイクタウン：新越谷駅から越谷レイクタウン駐車場P3出口の間
          <br />
          川口：タクシー手配にはタクシーアプリ『GO』をご活用ください。（片道上限3,000円まで）
          <br />
          埼玉高速鉄道/鳩ヶ谷駅または新井宿駅 
          <br />
          JR京浜東北線/蕨駅、西川口駅
          <br />
          JR武蔵野線/東浦和駅から
          <br />
          より当院(イオンモール川口)（領収書をご提出ください）
          <br />
          <br />
          この区間におけるタクシー代は、「交通費精算」にて都度ご精算ください。また、この区間のタクシー代は、交通費合計額には含みません。
          <br />
          <br />
          【定常的にマイカーを利用する場合】
          <br />
          基本的には、公共交通機関による通勤をお願いしておりますが、マイカー通勤をご希望の際には、マイカー通勤申請規定
          <Link
            to='#'
            onClick={() => {
              window.open(
                'https://drive.google.com/file/d/1xKk9EFIqdjDpAosAfdZ9qVWVanazma8E/view?usp=sharing',
                '_blank'
              )
            }}
          >
            (https://drive.google.com/file/d/1xKk9EFIqdjDpAosAfdZ9qVWVanazma8E/view?usp=sharing)
          </Link>
          をお読みいただき、マイカー通勤の許可の基準を満たしていること確認して下さい。その後、道路交通法その他自動車交通関係法令並びにマイカー通勤に関する規程を遵守し、常に安全運転に努めることにご同意頂ける場合には、規定への同意欄ににチェックを入れて下さい。こちらにご同意いただけない場合には、マイカー通勤の許可はできませんので、ご注意下さい。
          <br />
          マイカー通勤の場合には、Googleマップ
          <Link
            to='#'
            onClick={() => {
              window.open('https://www.google.com/maps/', '_blank')
            }}
          >
            (https://www.google.com/maps/)
          </Link>
          のルート検索機能を利用し、出発地と到着地を入力して経路検索をして下さい。その経路に間違いない場合には、検索結果の分数の横に、3つの丸が2つのバーでつながっているアイコンをクリックし、ポップアップ画面の「リンクをコピー」して、そのコピーしたURLをドクターサイト内の経路URLの欄に貼り付けて下さい。ドクターサイト内の出発地、到着地に関してヘア、高速道路を使用しない場合には、出発地、到着地は、「なし」、片道料金は「0」と入力して下さい。高速道路など有料道路を使用する際は、有料道路に乗るインターチェンジ名（例　芝浦IC）と降りるインターチェンジ名（例　柏IC）を入力してください。また、片道料金については、こちらのサイト
          <Link
            to='#'
            onClick={() => {
              window.open('https://search.shutoko.jp/index.html', '_blank')
            }}
          >
            (https://search.shutoko.jp/index.html)
          </Link>
          で検索し、ETC料金を入力して下さい。
          <br />
          ※通勤経路はガソリン代の計算や労災の届出時等に使用します。※法人の判断で経路を見直すことがあります。
          <br /> <br />
          こちらで申請いただく定常的な通勤経路ではない、イレギュラーな交通費の精算は、「交通費精算」にて、ご精算をお願いいたします。
        </>
      )
      break
    case 'showModalUrl':
      title = '経路URLについて'
      msg = (
        <>
          ※以下のサイトで片道の最短/最安経路を検索し、検索結果が表示されたURLをご提出ください。
          ご自宅（または勤務先など）～勤務拠点まで利用する全ての交通機関を指定し、経路検索を行ってください
          <br />
          <Link
            to='#'
            onClick={() => {
              window.open('https://transit.yahoo.co.jp/', '_blank')
            }}
          >
            (https://transit.yahoo.co.jp/)
          </Link>
        </>
      )
      break
    case 'showMyCarRegulations':
      title = 'マイカー規定について'
      msg = (
        <>
          ※定常的にマイカーを利用しない場合、チェックは不要です。
          <br />
          <br />
          【定常的にマイカーを利用する場合】
          <br />
          基本的には、公共交通機関による通勤をお願いしておりますが、マイカー通勤をご希望の際には、マイカー通勤申請規定
          <Link
            to='#'
            onClick={() => {
              window.open(
                'https://drive.google.com/file/d/1xKk9EFIqdjDpAosAfdZ9qVWVanazma8E/view?usp=sharing',
                '_blank'
              )
            }}
          >
            (https://drive.google.com/file/d/1xKk9EFIqdjDpAosAfdZ9qVWVanazma8E/view?usp=sharing)
          </Link>
          をお読みいただき、マイカー通勤の許可の基準を満たしていること確認して下さい。その後、道路交通法その他自動車交通関係法令並びにマイカー通勤に関する規程を遵守し、常に安全運転に努めることにご同意頂ける場合には、規定への同意欄ににチェックを入れて下さい。こちらにご同意いただけない場合には、マイカー通勤の許可はできませんので、ご注意下さい。
          マイカー通勤の場合には、Googleマップ
          <Link
            to='#'
            onClick={() => {
              window.open('https://www.google.com/maps/', '_blank')
            }}
          >
            (https://www.google.com/maps/)
          </Link>
          のルート検索機能を利用し、出発地と到着地を入力して経路検索をして下さい。その経路に間違いない場合には、検索結果の分数の横に、3つの丸が2つのバーでつながっているアイコンをクリックし、ポップアップ画面の「リンクをコピー」して、そのコピーしたURLをドクターサイト内の経路URLの欄に貼り付けて下さい。ドクターサイト内の出発地、到着地に関してヘア、高速道路を使用しない場合には、出発地、到着地は、「なし」、片道料金は「0」と入力して下さい。高速道路など有料道路を使用する際は、有料道路に乗るインターチェンジ名（例　芝浦IC）と降りるインターチェンジ名（例　柏IC）を入力してください。また、片道料金については、こちらのサイト
          <Link
            to='#'
            onClick={() => {
              window.open('https://search.shutoko.jp/index.html', '_blank')
            }}
          >
            (https://search.shutoko.jp/index.html)
          </Link>
          で検索し、ETC料金を入力して下さい。
          <br />
          ※通勤経路はガソリン代の計算や労災の届出時等に使用します。※法人の判断で経路を見直すことがあります。
          <br />
          <br />
          こちらで申請いただく定常的な通勤経路ではない、イレギュラーな交通費の精算は、「交通費精算」にて、ご精算をお願いいたします。
        </>
      )
      break
    case 'showModalCost':
      title = '片道料金について'
      msg = (
        <>
          時間帯によってはご自宅からではなくご勤務先等からクリニックへ出勤しご自宅へ帰宅されるなど、様々なご事情があると思いますので、往路と復路を別に登録することができるようになっております。
          往路と復路の料金が異なる場合は、「復路登録」にチェックを入れていただき、復路の経路と料金をご登録ください。
        </>
      )
      break
    case 'showModalDeparturePlace':
      title = '出発地について'
      msg = (
        <>
          行き(往路)はどちらからクリニックに向かいますか?ご自宅から向かわれる場合は「自宅」と記載し、ご勤務先から向かわれる場合は「勤務先」と記載してください。
          それ以外の場合は出発地点（駅名等）をご記載ください。
        </>
      )
      break
    case 'showModalArrivalPlace':
      title = '到着地について'
      msg = (
        <>
          ご勤務終了後(復路)はどちらへ戻られますか?ご自宅へ戻られる場合は「自宅」と記載し、ご勤務先へ戻られる場合は「勤務先」と記載してください。
          それ以外の場合は戻られる場所（駅名等）をご記載ください。
        </>
      )
      break

    default:
      break
  }
  return (
    <Modal
      title={title}
      className='modal-antd'
      closable={false}
      visible={values[name]}
      okButtonProps={{
        style: {
          display: 'none',
        },
      }}
      onCancel={noClick}
      cancelText={i18n.t('main.close')}
    >
      {msg}
    </Modal>
  )
}
export default TransportRegisterModal
