import gql from 'graphql-tag'

export const CREATE_QUESTION = gql`
  mutation StaffCreateQuestion(
    $categoryId: ID!
    $title: String
    $content: String
    $categoryCreatedAt: String
  ) {
    staffCreateQuestion(
      categoryId: $categoryId
      title: $title
      content: $content
      categoryCreatedAt: $categoryCreatedAt
    )
  }
`
