import Calendar from '@medical/components/Calendar'
import { setClickDateTimeFirstTime } from '@medical/provider/store/actions'
import Router from '@medical/routes/router'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

const ShiftDateSelectors = ({
  history,
  match: {
    params: { year = moment().year(), month = moment().month() + 1, day },
  },
  setClickDateTimeFirstTime,
  search,
}) => {
  const currentDate = moment()
    .year(year)
    .month(month - 1)
    .date(day || 1)

  const onDayChange = e => {
    const chosenMonth = moment(e.value)
    history.push({
      pathname: Router.get(Router.staffDailyAvailableShifts, {
        year: chosenMonth.year(),
        month: chosenMonth.clone().month() + 1,
        day: chosenMonth.date(),
      }),
      search,
    })
    setClickDateTimeFirstTime(true)
  }

  return (
    <React.Fragment>
      <Calendar
        value={currentDate.toDate()}
        onChange={onDayChange}
        showIcon
        readOnlyInput
        monthNavigator
        minDate={moment()
          .startOf('month')
          .subtract(12, 'months')
          .toDate()}
      />
    </React.Fragment>
  )
}

const mapDispatchToProps = dispatch => ({
  setClickDateTimeFirstTime: flag => {
    dispatch(setClickDateTimeFirstTime(flag))
  },
})

export default connect(
  null,
  mapDispatchToProps
)(withRouter(ShiftDateSelectors))
