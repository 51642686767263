import './styles.css'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { UPDATE_MOVING_EXPENSES_REGISTRATION } from '@medical/constant/permissions'
import { checkStaffPermission } from '@medical/libs'
import MovingExpensesRegistrationDetailScence from '@medical/pages/staff/MovingExpenses/RegistrationMovingExpenses/MovingExpensesRegistrationDetailScence'
import { useCustom } from '@medical/provider/context'
import Router from '@medical/routes/router'
import { Button } from 'primereact/button'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import {
  GET_CLINIC,
  GET_LIST_MOVING_EXPENSES_REGISTRATION,
  UPDATE_MOVING_EXPENSES_REGISTRATION_QL,
} from './ListRegistration.graphql'

const MovingExpensesRegistrationDetail = ({
  closeModal,
  match: {
    params: { transportationId },
  },
}) => {
  const [{ i18n, popup }] = useCustom()
  const {
    loading: clinicLoading,
    error: clinicError,
    data: dataClinic,
  } = useQuery(GET_CLINIC)
  const { loading, error, data, refetch } = useQuery(
    GET_LIST_MOVING_EXPENSES_REGISTRATION,
    {
      variables: {
        where: {
          id: transportationId,
        },
      },
    }
  )
  const [updateMovingExpenses] = useMutation(
    UPDATE_MOVING_EXPENSES_REGISTRATION_QL
  )
  useEffect(() => {
    refetch()
  })
  if (loading || clinicLoading) return <ProgressSpinner />
  if (error || clinicError) return <ErrorComponent error={clinicError} />
  const isUpdateMovingExpenses = checkStaffPermission({
    functionName: UPDATE_MOVING_EXPENSES_REGISTRATION,
  })
  if (!isUpdateMovingExpenses) {
    return (
      <div className='static-container'>
        <div className='static-title'>エラー</div>
        <div className='static-content'>{i18n.t('main.noPermissionToSee')}</div>
        <Link to={Router.staffHome}>
          <Button
            label={i18n.t('main.pageNotFoundButton')}
            className='static-button'
          />
        </Link>
      </div>
    )
  }
  const onSubmit = async (
    {
      url,
      doctorId,
      clinic,
      departurePlace,
      arrivalPlace,
      cost,
      startTime,
      isPast,
      urlRoundTrip,
      departurePlaceRoundTrip,
      arrivalPlaceRoundTrip,
      costRoundTrip,
    },
    { setSubmitting, setFieldValue }
  ) => {
    try {
      await updateMovingExpenses({
        variables: {
          transportationId,
          doctorId,
          clinicId: clinic.id,
          data: {
            url,
            departurePlace,
            arrivalPlace,
            cost,
            startTime,
            returnPath: isPast
              ? {
                  set: [
                    departurePlaceRoundTrip,
                    arrivalPlaceRoundTrip,
                    `${costRoundTrip}`,
                    urlRoundTrip,
                  ],
                }
              : null,
          },
        },
      })
      popup.success(i18n.t('staff.movingExpensesDetail.submissionSuccess'))
      setSubmitting(true)
      closeModal()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
      setFieldValue('confirm', false)
    }
  }
  return (
    <MovingExpensesRegistrationDetailScence
      i18n={i18n}
      clinics={dataClinic}
      data={data.transportationExpenses[0] || {}}
      onSubmit={onSubmit}
    />
  )
}

export default MovingExpensesRegistrationDetail
