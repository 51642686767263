/* eslint-disable camelcase */
import { GEOCODE_URL } from '@medical/constant'
import axios from 'axios'

const administrativeAreaTypes = [
  'administrative_area_level_1',
  'administrative_area_level_2',
  'administrative_area_level_3',
  'administrative_area_level_4',
  'administrative_area_level_5',
  'administrative_area_level_6',
  'administrative_area_level_7',
]

const localityAreaTypes = ['locality', 'sublocality']

export const getDetailAddressFromZipCode = async ({
  zipCode,
  language = 'ja',
}) => {
  const { data } = await axios({
    method: 'get',
    url: `${GEOCODE_URL}&language=${language}&region=jp&address=${zipCode}`,
  })

  const { results, status, error_message } = data

  if (status === 'OK') {
    const preferAddressComponent = results[0].address_components
    const administrativeArea = preferAddressComponent
      .filter(ac => administrativeAreaTypes.some(ae => ac.types.includes(ae)))
      .reverse()
      .reduce((res, cur) => res.concat(`${cur.long_name}`), '')
    const localityArea = preferAddressComponent
      .filter(ac => localityAreaTypes.some(ae => ac.types.includes(ae)))
      .reverse()
      .reduce((res, cur) => res.concat(`${cur.long_name}`), '')
    return {
      status,
      administrativeArea,
      localityArea,
    }
  }

  return {
    status,
    error_message,
  }
}
