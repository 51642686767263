import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { useCustom } from '@medical/provider/context'
import React, { useEffect } from 'react'
import { checkStaffPermission } from '@medical/libs'
import { STAFF_UPDATE_INCENTIVE_DETAIL_PERMISSIONS } from '@medical/constant/permissions'
import {
  GET_INCENTIVE_BY_ID,
  UPDATE_INCENTIVE_DETAIL,
  CREATE_ACTIVITY
} from './DetailOfIncentives.graphql'
import DetailOfIncentivesScene from './DetailOfIncentivesScene'

const DetailOfIncentives = ({
  match: {
    params: { id },
  },
  closeModal,
}) => {
  const [{ i18n, popup }] = useCustom()
  const variables = {
    where: {
      id,
    },
  }
  const [updateIncentiveDetail] = useMutation(UPDATE_INCENTIVE_DETAIL)
  const [staffCreateActivity] = useMutation(CREATE_ACTIVITY)
  const { loading, error, data, refetch } = useQuery(GET_INCENTIVE_BY_ID, {
    variables,
    fetchPolicy: 'network-only',
  })
  const isUpdatePermitted = checkStaffPermission({
    functionName: STAFF_UPDATE_INCENTIVE_DETAIL_PERMISSIONS,
  })
  useEffect(() => {
    refetch()
  })
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  const onSubmit = async (
    { incentiveDetailId, incentiveTotalAmount, doctorId },
    { setSubmitting, resetForm }
  ) => {
    try {
      await updateIncentiveDetail({
        variables: {
          incentiveDetailId,
          incentiveTotalAmount,
        },
      })
      staffCreateActivity({
        variables: {
          activity: 'STAFF_RENEWAL_OF_INCENTIVE_GRANT',
          doctorId: doctorId,
        },
      })
      popup.success('成功しました')
      closeModal()
      setSubmitting(false)
      resetForm()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }

  return (
    <DetailOfIncentivesScene
      i18n={i18n}
      data={data.incentiveDetailsConnection.edges}
      onSubmit={onSubmit}
      isUpdatePermitted={isUpdatePermitted}
    />
  )
}

export default DetailOfIncentives
