import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { objectTargetConstant } from '@medical/constant'
import {
  SHOW_MONEY_FIELDS,
  STAFF_ADD_COMMENT,
  STAFF_UPDATE_RECRUITMENT_SHIFTS_STATUS,
} from '@medical/constant/permissions'
import {
  checkStaffPermission,
  clinicalDepartmentNames,
  combineNames,
  convertDoctorsConnection,
  sortAndFilterByClinicOrder,
  splitLogic,
} from '@medical/libs'
import { ShiftForm } from '@medical/pages/staff/components'
import { CLINICS_DAILY_REPORT } from '@medical/pages/staff/DailyAvailableShift/DailyAvailableShift.graphql'
import { CLINICS_MONTHLY_REPORT } from '@medical/pages/staff/MonthlyAvailableShift/MonthlyAvailableShift.graphql'
import { useCustom } from '@medical/provider/context'
import moment from 'moment'
import React, { useState } from 'react'
import { connect } from 'react-redux'

import {
  DEPARTMENTS_LIST,
  DOCTOR_CONNECTION,
  STAFF_CREATE_AVAILABLE_SHIFT,
} from './CreateAvailableShift.graphql'

const CreateAvailableShift = ({
  closeModal,
  match: {
    params: {
      year = moment().year(),
      month = moment().month(),
      day = moment().day(),
    },
  },
  location: { state, stateUnpublished },
  isChangeDateFirstTime,
}) => {
  const isCreateAvailableShift = useState(true)
  const date = moment()
    .startOf('day')
    .year(year)
    .month(month - 1)
    .date(day || 1)
    .hour(9)
  const isMoneyPermitted = checkStaffPermission({
    functionName: SHOW_MONEY_FIELDS,
  })
  const isChangeRecruitmentShiftsStatusPermitted = checkStaffPermission({
    functionName: STAFF_UPDATE_RECRUITMENT_SHIFTS_STATUS,
  })
  const isAddCommentPermitted = checkStaffPermission({
    functionName: STAFF_ADD_COMMENT,
  })

  const [{ i18n, popup }] = useCustom()
  const { loading, error, data } = useQuery(DEPARTMENTS_LIST)
  const {
    loading: doctorLoading,
    error: doctorError,
    data: doctorData,
  } = useQuery(DOCTOR_CONNECTION, {
    variables: {
      where: {
        deletedAt: null,
        registrationStatus_in: ['CREATED_BY_STAFF', 'ACCEPTED'],
        blocked: false,
      },
      first: 10,
      skip: 0,
      orderBy: 'unSigned_ASC',
    },
  })
  const [createShift] = useMutation(STAFF_CREATE_AVAILABLE_SHIFT, {
    refetchQueries: day
      ? [
          {
            query: CLINICS_DAILY_REPORT,
            variables: { date: moment(date).startOf('day') },
          },
        ]
      : [
          {
            query: CLINICS_MONTHLY_REPORT,
            variables: {
              date: moment(date).startOf('day'),
            },
          },
        ],
    awaitRefetchQueries: true,
  })

  if (loading || doctorLoading) return <ProgressSpinner />
  if (error || doctorError)
    return <ErrorComponent error={error || doctorError} />

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    const {
      clinicalDepartment,
      date: submitDate,
      startTime,
      numberOfRooms,
      splits,
      unpermittedDoctors,
      comment,
      isPosted,
      isSpecial,
      isSetIndividually,
      objectTarget,
      workPattern,
      specificDoctors,
      isEarlySpecial,
      isDoubleRecruitment
    } = values
    const unpermittedDoctorIds = unpermittedDoctors.map(doctor => doctor.id)
    const splitComments = []
    const time = moment(values.dateSettingHideShift)
      .set('hour', moment(values.timeSettingHideShift).format('HH'))
      .set('minute', 0)
    values.splitComments.map(value => {
      if (value.text !== '' && value.type !== '') {
        splitComments.push({
          text: value.text,
          type: value.type,
          staff: {},
        })
      }

      return splitComments
    })
    const splitRecruitmentCategories = []
    values.splitRecruitmentCategories.map(value => {
      if (value.category !== '' && value.status !== '') {
        splitRecruitmentCategories.push({
          category: value.category,
          status: value.status,
          description: value.description || '',
          staff: {},
        })
      }

      return splitRecruitmentCategories
    })
    const {
      endTime,
      hourlyWage,
      splitDateTime1,
      splitDateTime2,
      splitDateTime3,
      splitHourlyWage1,
      splitHourlyWage2,
      splitHourlyWage3,
      isStartTimeBreakTime,
      isSplitDateTime1BreakTime,
      isSplitDateTime2BreakTime,
      isSplitDateTime3BreakTime,
    } = splitLogic({ submitDate, splits })
    try {
      await createShift({
        variables: {
          clinicalDepartmentId: clinicalDepartment.id,
          startTime: moment(submitDate)
            .hour(moment(startTime).hour())
            .minute(moment(startTime).minute()),
          numberOfRooms: parseInt(numberOfRooms),
          unpermittedDoctorIds,
          endTime,
          hourlyWage,
          splitDateTime1,
          splitDateTime2,
          splitDateTime3,
          splitHourlyWage1,
          splitHourlyWage2,
          splitHourlyWage3,
          comment,
          isPublished: isPosted,
          isStartTimeBreakTime,
          isSplitDateTime1BreakTime,
          isSplitDateTime2BreakTime,
          isSplitDateTime3BreakTime,
          staffComments: splitComments,
          recruitmentCategory: splitRecruitmentCategories,
          isSpecial,
          isEarlySpecial,
          isDoubleRecruitment,
          isSetIndividually,
          applyWith:
            isSetIndividually && objectTarget ? objectTarget : undefined,
          workPatternApply:
            isSetIndividually &&
            objectTarget === objectTargetConstant.SET_FOR_EACH_WORKPARTTEN
              ? workPattern
              : undefined,
          listDoctorId:
            isSetIndividually &&
            objectTarget === objectTargetConstant.SET_FOR_EACH_DOCTOR
              ? specificDoctors.map(e => e.id)
              : undefined,
          timeSettingHideShift: moment(time).utc(),
        },
      })
      popup.success(i18n.t('staff.createShift.submissionSuccess'))
      setSubmitting(false)
      resetForm()
      closeModal()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }
  const { clinicalDepartments, departments } = data
  const sortedClinicalDepartments = sortAndFilterByClinicOrder({
    clinicalDepartments,
    isFilter: true,
  })
  const departmentNames = clinicalDepartmentNames({ departments })
  return (
    <ShiftForm
      state={state}
      i18n={i18n}
      unPublished={state && state.unPublished ? state.unPublished : null}
      availableShift={state || {}}
      title={i18n.t('main.createAvailableShift')}
      clinicalDepartments={combineNames({
        clinicalDepartments: sortedClinicalDepartments,
        departmentNames,
      })}
      doctorsList={convertDoctorsConnection(doctorData.doctorsConnection.edges)}
      doctors={[]}
      onSubmit={onSubmit}
      date={
        stateUnpublished && stateUnpublished.startTime
          ? moment(stateUnpublished.startTime)
          : date
      }
      submitLabel={i18n.t('staff.createShift.submit')}
      confirmationMessage={i18n.t('staff.createShift.submitConfirmation')}
      confirmationMessage2={i18n.t('staff.createShift.submitConfirmation2')}
      isMoneyPermitted={isMoneyPermitted}
      isCreateAvailableShift={isCreateAvailableShift}
      stateUnpublished={stateUnpublished || {}}
      isChangeDateFirstTime={isChangeDateFirstTime}
      isChangeRecruitmentShiftsStatusPermitted={
        isChangeRecruitmentShiftsStatusPermitted
      }
      popup={popup}
      isAddCommentPermitted={isAddCommentPermitted}
      waitingAvailableShift={
        state && state.waitingAvailableShift
          ? state.waitingAvailableShift
          : null
      }
    />
  )
}

const mapStateToProps = state => ({
  isChangeDateFirstTime: state.staffStore.isChangeDateFirstTime,
})

export default connect(mapStateToProps, null)(CreateAvailableShift)
