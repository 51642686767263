import gql from 'graphql-tag'

import { WORK_SCHEDULE_COMMENT_FRAGMENTS } from '../../DetailWorkSchedule/DetailWorkSchedule.graphql'

export const SCHEDULE_AVAILABLESHIFT_CONNECTION = gql`
  query SCHEDULE_AVAILABLESHIFT_CONNECTION(
    $orderBy: ScheduledAvailableShiftGroupOrderByInput
    $where: ScheduledAvailableShiftGroupWhereInput
    $skip: Int
    $first: Int
    $availableIsEditedIndividually: Boolean
    $availableWorkSchedule: String
    $availableDeletedAt: DateTime
  ) {
    staffMe {
      id
    }
    scheduledAvailableShiftGroupsConnection(
      orderBy: $orderBy
      where: $where
      skip: $skip
      first: $first
    ) {
      edges {
        node {
          id
          createdAt
          updatedAt
          name
          startDate
          endDate
          generatedAt
          dataTime
          isAvailableOnMonday
          isAvailableOnTuesday
          isAvailableOnWednesday
          isAvailableOnThursday
          isAvailableOnFriday
          isAvailableOnSaturday
          isAvailableOnSunday
          isAvailableOnHoliday
          isEdited
          isPublished
          isRecruitmentShift
          dateMatrix
          repeatSetting
          comment
          numberOfRooms
          blockedDays
          isHolidayRepeat
          isEarlySpecial
          isDoubleRecruitment
          staffComments {
            ...WORK_SCHEDULE_COMMENT_FRAGMENTS
          }
          recruitmentCategory(where: { deletedAt: null }) {
            id
            status
            category
            description
            deletedAt
            staff {
              id
              lastname
              firstname
            }
          }
          availableShifts(
            where: {
              isEditedIndividually: $availableIsEditedIndividually
              workSchedule: $availableWorkSchedule
              deletedAt: $availableDeletedAt
            }
          ) {
            id
            hourlyWage
            splitDateTime1
            startTime
            endTime
            deletedAt
            splitDateTime2
            splitDateTime3
            splitHourlyWage1
            splitHourlyWage2
            splitHourlyWage3
            isStartTimeBreakTime
            isSplitDateTime1BreakTime
            isSplitDateTime2BreakTime
            isSplitDateTime3BreakTime
            isPublished
            isEditedIndividually
            isEarlySpecial
            isDoubleRecruitment
            clinicalDepartment {
              id
              clinic {
                id
                name
              }
            }
            createdBy {
              id
              lastname
              firstname
            }
            updatedBy {
              id
              lastname
              firstname
            }
            isSetIndividually
            applyWith
            workPatternApply
            workSchedule {
              id
            }
            availableShiftDetails {
              doctor {
                id
                blocked
                fullName
                email
                deletedAt
              }
            }
          }
        }
      }
      aggregate(where: $where) {
        count
      }
    }
  }
  ${WORK_SCHEDULE_COMMENT_FRAGMENTS}
`

export const FIRST_AVAILABLE_SHIFT_IN_SCHEDULE = gql`
  query AvailableShifts(
    $where: AvailableShiftWhereInput
    $first: Int
    $skip: Int
    ) {
    availableShifts(where: $where, first: $first, skip: $skip) {
      id
      hourlyWage
      splitDateTime1
      startTime
      endTime
      deletedAt
      splitDateTime2
      splitDateTime3
      splitHourlyWage1
      splitHourlyWage2
      splitHourlyWage3
      isStartTimeBreakTime
      isSplitDateTime1BreakTime
      isSplitDateTime2BreakTime
      isSplitDateTime3BreakTime
      isPublished
      isEditedIndividually
      clinicalDepartment {
        id
        clinic {
          id
          name
        }
      }
      createdBy {
        id
        lastname
        firstname
      }
      updatedBy {
        id
        lastname
        firstname
      }
      isSetIndividually
      applyWith
      workPatternApply
      workSchedule {
        id
      }
      availableShiftDetails {
        doctor {
          id
          blocked
          fullName
          email
          deletedAt
        }
      }
    }
  }
`

export const SCHEDULE_AVAILABLESHIFT_GROUPS_CONNECTION = gql`
  query SCHEDULE_AVAILABLESHIFT_CONNECTION(
    $orderBy: ScheduledAvailableShiftGroupOrderByInput
    $where: ScheduledAvailableShiftGroupWhereInput
    $skip: Int
    $first: Int
    $availableShiftsWhere2: AvailableShiftWhereInput
    $availableShiftsFirst2: Int
    $availableShiftsSkip2: Int
  ) {
    scheduledAvailableShiftGroupsConnection(
      orderBy: $orderBy
      where: $where
      skip: $skip
      first: $first
    ) {
      edges {
        node {
          id
          createdAt
          updatedAt
          name
          startDate
          endDate
          generatedAt
          dataTime
          isAvailableOnMonday
          isAvailableOnTuesday
          isAvailableOnWednesday
          isAvailableOnThursday
          isAvailableOnFriday
          isAvailableOnSaturday
          isAvailableOnSunday
          isAvailableOnHoliday
          isEdited
          isPublished
          isRecruitmentShift
          dateMatrix
          repeatSetting
          numberOfRooms
          isHolidayRepeat
          availableShifts(
            first: $availableShiftsFirst2
            skip: $availableShiftsSkip2
            where: $availableShiftsWhere2
          ) {
            id
          }
          updatedBy {
            id
            lastname
            firstname
          }
        }
      }
      aggregate(where: $where) {
        count
      }
    }
  }
`
