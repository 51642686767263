import React from 'react'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { Button } from 'primereact/button'
import TextInput from '@medical/components/TextInput'
import { Message } from 'primereact/message'
import { DOCTOR_CHANGE_EMAIL } from '@medical/pages/doctor/UpdateProfile/UpdateProfile.graphql'
import { useMutation } from '@apollo/react-hooks'
import ProgressSpinner from '@medical/components/ProgressSpinner'

export const ChangeEmailForm = ({ currentEmail, doctorId, i18n }) => {
  const [doctorChangedEmail, { data, loading, error }] = useMutation(
    DOCTOR_CHANGE_EMAIL
  )

  const SignupSchema = Yup.object().shape({
    email: Yup.string(),
    newEmail: Yup.string()
      .email(i18n.t('validation.newEmail.invalid'))
      .required(i18n.t('validation.newEmail.required')),
    id: Yup.string().required('Required'),
  })

  if (loading) return <ProgressSpinner />

  return (
    <Formik
      enableReinitialize
      initialValues={{
        email: currentEmail,
        newEmail: '',
        id: doctorId,
      }}
      validationSchema={SignupSchema}
      onSubmit={async values => {
        try {
          await doctorChangedEmail({
            variables: {
              ...values,
            },
          })
        } catch (e) {}
      }}
      render={formikProps => (
        <>
          {error && (
            <Message severity='error' text={error.graphQLErrors[0].message} />
          )}
          {data && (
            <Message
              severity='success'
              text={i18n.t('doctor.myPage.sendSuccess')}
            />
          )}
          <Form>
            <div className='change-email-form'>
              <TextInput
                {...formikProps}
                name='email'
                disabled
                classDisabled
                label={i18n.t('doctor.myPage.currentEmail')}
              />
            </div>
            <div className='change-email-form'>
              <TextInput
                {...formikProps}
                name='newEmail'
                label={i18n.t('doctor.myPage.newEmail')}
              />
            </div>
            <div className='tr'>
              <Button
                className='mx-auto w-100'
                type='submit'
                label={i18n.t('doctor.myPage.send')}
              />
            </div>
          </Form>
        </>
      )}
    />
  )
}
