import './DoctorPortal.scss'

import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, NotFound, ProgressSpinner } from '@medical/components'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import uuid from 'uuid'

import { PORTAL_QUERY } from './DoctorPortal.graphql'
import DoctorPortalMobile from './DoctorPortalMobile'
import DoctorPortalScene from './DoctorPortalScene'

const DoctorPortal = () => {
  const [largeTypeList, setLargeTypeList] = useState([])
  const [largeTypeSelected, setLargeTypeSelected] = useState(null)
  const [nodeList, setNodeList] = useState([])
  const [nodeSelected, setNodeSelected] = useState({ label: '', data: '' })
  const [nodesSearched, setNodesSearched] = useState('')

  const mapToTree = data => {
    const treeMap = []
    data.forEach(e => {
      const nodeLv1 = treeMap.find(el => el.label === e.largeTitle)
      if (nodeLv1) {
        const nodeLv2 = nodeLv1.children.find(el => el.label === e.mediumTitle)
        if (nodeLv2) {
          nodeLv2.children.push({
            key: uuid.v1(),
            label: e.smallTitle,
            data: e.content,
          })
        } else {
          nodeLv1.children.push({
            key: uuid.v1(),
            label: e.mediumTitle,
            data: e.mediumTitle,
            children: [
              {
                key: uuid.v1(),
                label: e.smallTitle,
                data: e.content,
              },
            ],
          })
        }
      } else {
        treeMap.push({
          key: uuid.v1(),
          label: e.largeTitle,
          data: e.largeTitle,
          children: [
            {
              key: uuid.v1(),
              label: e.mediumTitle,
              data: e.mediumTitle,
              children: [
                {
                  key: uuid.v1(),
                  label: e.smallTitle,
                  data: e.content,
                },
              ],
            },
          ],
        })
      }
    })
    treeMap.forEach(e => {
      e.children.sort((a, b) => `${a.label}`.localeCompare(b.label))
    })
    treeMap.forEach(e => {
      e.children.forEach(elv2 => {
        elv2.children.sort((a, b) => `${a.label}`.localeCompare(b.label))
      })
    })
    return treeMap
  }

  const { loading, error, data } = useQuery(PORTAL_QUERY, {
    variables: {
      where: {
        titleType: 'SMALL_TITLE',
        isHide: false,
      },
      orderBy: 'largeTitle_ASC',
    },
    onCompleted: data => {
      const dataConverted = data.portalsConnection.edges.map(e => ({
        ...e.node,
      }))
      const largeTypes = [...new Set(dataConverted.map(e => e.largeTitle))]
      setLargeTypeList(largeTypes)
      if (largeTypes.length > 0) {
        const nodes = mapToTree(dataConverted)
        setNodeList(nodes)
        setNodeSelected(nodes[0].children[0].children[0])
      }
    },
  })
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const selectLargeType = typeSelected => {
    setLargeTypeSelected(typeSelected)
    const dataConverted = data.portalsConnection.edges.map(e => ({
      ...e.node,
    }))
    if (!typeSelected) {
      setNodeList(mapToTree(dataConverted))
      return
    }
    setNodeList(
      mapToTree(dataConverted.filter(e => e.largeTitle === typeSelected))
    )
  }

  const searchContent = text => {
    const dataConverted = data.portalsConnection.edges.map(e => ({
      ...e.node,
    }))
    // Remove tag html from content to search
    const nodes = dataConverted.filter(e => {
      const div = document.createElement('div')
      div.innerHTML = e.content
      const textContent = div.textContent || div.innerText || ''
      if (!largeTypeSelected)
        return textContent.toLowerCase().includes(text.toLowerCase())
      return (
        textContent.toLowerCase().includes(text.toLowerCase()) &&
        e.largeTitle == largeTypeSelected
      )
    })
    setNodesSearched(nodes)
  }
  if (isMobile)
    return (
      <DoctorPortalMobile
        menuNodes={nodeList}
        nodeSelected={nodeSelected}
        selectNode={setNodeSelected}
        largeTypeList={largeTypeList}
        largeTypeSelected={largeTypeSelected}
        selectLargeType={selectLargeType}
        searchContent={searchContent}
        nodesSearched={nodesSearched}
      />
    )

  return (
    <DoctorPortalScene
      menuNodes={nodeList}
      nodeSelected={nodeSelected}
      selectNode={setNodeSelected}
      largeTypeList={largeTypeList}
      largeTypeSelected={largeTypeSelected}
      selectLargeType={selectLargeType}
      searchContent={searchContent}
      nodesSearched={nodesSearched}
    />
  )
}

export default DoctorPortal
