import gql from 'graphql-tag'

export const INQUIRY_QUERY = gql`
  query INQUIRY_QUERY($inquiryId: ID) {
    inquiry(where: { id: $inquiryId }) {
      id
      createdAt
      article
      content
      doctor {
        id
        firstname
        lastname
      }
      updatedAt
      reply
      repliedBy {
        firstname
        lastname
      }
    }
  }
`
export const STAFF_CREATE_REPLY = gql`
  mutation STAFF_CREATE_REPLY($inquiryId: ID!, $reply: String!) {
    staffCreateInquiryReply(inquiryId: $inquiryId, reply: $reply) {
      id
    }
  }
`
