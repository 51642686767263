import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import { WORK_SCHEDULE_FRAGMENTS } from '@medical/pages/doctor/Schedule/Schedule.graphql'
import gql from 'graphql-tag'

export const AVAILABLE_SHIFT_FRAGMENTS = gql`
  fragment AVAILABLE_SHIFT_FRAGMENTS on AvailableShift {
    id
    name
    startTime
    endTime
    hourlyWage
    splitDateTime1
    splitDateTime2
    splitDateTime3
    splitHourlyWage1
    splitHourlyWage2
    splitHourlyWage3
    comment
    isPublished
    isSpecial
    recruitmentShiftsType
    isStartTimeBreakTime
    isSplitDateTime1BreakTime
    isSplitDateTime2BreakTime
    isSplitDateTime3BreakTime
    clinicalDepartment {
      id
      name
      extraName
      isDisplay
      clinic {
        id
        order
        name
        nameKana
        messageIntroduce
      }
    }
  }
`
export const AVAILABLE_SHIFT_FRAGMENTS_NO_MONEY = gql`
  fragment AVAILABLE_SHIFT_FRAGMENTS_NO_MONEY on AvailableShift {
    id
    startTime
    endTime
    splitDateTime1
    splitDateTime2
    splitDateTime3
    comment
    isStartTimeBreakTime
    isSplitDateTime1BreakTime
    isSplitDateTime2BreakTime
    isSplitDateTime3BreakTime
    clinicalDepartment {
      id
      name
      extraName
      isDisplay
      clinic {
        id
        order
        name
        nameKana
      }
    }
  }
`
export const AVAILABLE_SHIFT_FRAGMENS_FAST = gql`
  fragment AVAILABLE_SHIFT_FRAGMENS_FAST on AvailableShift {
    id
    startTime
    endTime
    createdAt
    updatedAt
    hourlyWage
    splitDateTime1
    splitDateTime2
    splitDateTime3
    splitHourlyWage1
    splitHourlyWage2
    splitHourlyWage3
    dailySalary
    comment
    isSpecial
    updatedHourlyWageAt
    isStartTimeBreakTime
    isSplitDateTime1BreakTime
    isSplitDateTime2BreakTime
    isSplitDateTime3BreakTime
    clinicalDepartment: clinicalDepartmentFast {
      id
      name
      extraName
      isDisplay
      clinic: clinicFast {
        id
        name
        nameKana
      }
    }
  }
`

export const DOCTOR_AVAILABLE_SHIFTS_GENERATOR = isDoctorBlocked => {
  if (!isDoctorBlocked) {
    return gql`
      query DOCTOR_AVAILABLE_SHIFTS(
        $date: DateTime!
      ) {
        doctorMe {
          id
          isAvailableOnMonday
          isAvailableOnTuesday
          isAvailableOnWednesday
          isAvailableOnThursday
          isAvailableOnFriday
          isAvailableOnSaturday
          isAvailableOnSunday
          isAvailableOnHoliday
          registrationStatus
          lastLoggedIn
          monthlyAvailableShifts(date: $date) {
            date
            availableShifts {
              id
              startTime
              endTime
              createdAt
              updatedAt
              isSpecial
              updatedHourlyWageAt
            }
            workSchedules {
              id
              accepted
              startTime
              endTime
              acceptedShift {
                id
                dailySalary
              }
              clinicalDepartment {
                id
                name
                extraName
                isDisplay
                clinic {
                  id
                  order
                  name
                  nameKana
                }
              }
            }
          }
        }
        ...DEPARTMENTS_FRAGMENT
      }
      ${DEPARTMENTS_FRAGMENT}
    `
  }
  return gql`
    query DOCTOR_AVAILABLE_SHIFTS(
      $date: DateTime!
    ) {
      doctorMe {
        id
        isAvailableOnMonday
        isAvailableOnTuesday
        isAvailableOnWednesday
        isAvailableOnThursday
        isAvailableOnFriday
        isAvailableOnSaturday
        isAvailableOnSunday
        isAvailableOnHoliday
        registrationStatus
        lastLoggedIn
        monthlyAvailableShifts(date: $date) {
          date
          workSchedules {
            id
            accepted
            startTime
            endTime
            acceptedShift {
              id
              dailySalary
            }
            clinicalDepartment {
              id
              name
              extraName
              isDisplay
              clinic {
                id
                order
                name
                nameKana
              }
            }
          }
        }
      }
      ...DEPARTMENTS_FRAGMENT
    }

    ${DEPARTMENTS_FRAGMENT}
  `
}

export const CREATE_ACTIVITY = gql`
  mutation CREATE_ACTIVITY_LOG(
    $activity: ActivityLogTypes!
    $doctorId: ID
    $details: String
  ) {
    createActivityLogHistory(
      activity: $activity
      doctorId: $doctorId
      details: $details
    )
  }
`

export const GET_LIST_AVAILABLE = gql`
  query DoctorFindAvailableShift(
    $dateRange: [DateTime!]
    $morningShift: Boolean
    $eveningShift: Boolean
    $nightShift: Boolean
    $isHome: Boolean
    $clinics: [ID!]
    $departments: [String!]
    $isSpecialHourlyWage: Boolean
    $hourlyWage: String
    $startTime: DateTime
    $endTime: DateTime
    $first: String
    $skip: String
  ) {
    doctorFindAvailableShift(
      dateRange: $dateRange
      morningShift: $morningShift
      eveningShift: $eveningShift
      nightShift: $nightShift
      isHome: $isHome
      clinics: $clinics
      departments: $departments
      isSpecialHourlyWage: $isSpecialHourlyWage
      hourlyWage: $hourlyWage
      startTime: $startTime
      endTime: $endTime
      first: $first
      skip: $skip
    ) {
      data {
        id
        salary
        dailySalary
        startTime
        endTime
        isSpecial
        isEarlySpecial
        clinicalDepartment {
          id
          name
          clinic {
            id
            name
          }
        }
        comment
        deletedAt
        createdAt
        updatedAt
      }
      count
    }
  }
`
export const AVAILABLE_SHIFTS = gql`
  query AvailableShifts($where: AvailableShiftWhereInput) {
    availableShifts(where: $where) {
      id
      startTime
      endTime
      createdAt
      updatedAt
      hourlyWage
      splitDateTime1
      splitDateTime2
      splitDateTime3
      splitHourlyWage1
      splitHourlyWage2
      splitHourlyWage3
      dailySalary
      comment
      isSpecial
      updatedHourlyWageAt
      isStartTimeBreakTime
      isSplitDateTime1BreakTime
      isSplitDateTime2BreakTime
      isSplitDateTime3BreakTime
      clinicalDepartment {
        id
        name
        extraName
        isDisplay
        clinic {
          id
          name
          nameKana
        }
      }
    }
  }
`

export const CLINICS_CONNECTION = gql`
  query CLINICS_CONNECTION($where: ClinicWhereInput, $skip: Int, $first: Int) {
    clinicsConnection(where: $where, skip: $skip, first: $first) {
      edges {
        node {
          id
          name
          nameKana
          isSearchDisplay
          region
          clinicalDepartments {
            id
            name
            isDisplay
          }
        }
      }
      aggregate {
        count
      }
    }
  }
`
