import ClinicNameRender from '@medical/components/ClinicNameRender'
import ProgressSpinner from '@medical/components/ProgressSpinner'
import StatusRender from '@medical/components/StatusRender'
import numeralCurrency from '@medical/libs/numeralCurrency'
import moment from 'moment'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React from 'react'
import { isMobile } from 'react-device-detect'

import { ActionItem } from './ActionItem'
import { WorkScheduleItem } from './WorkScheduleItem'

const WorkScheduleTable = ({
  loading,
  i18n,
  workSchedules,
  selectedDate,
  toggleWorkSchedule,
  setToggleWorkSchedule,
  isSelectedDateHoliday,
  departmentNames,
}) => {
  workSchedules.sort((a, b) => b.accepted - a.accepted)
  if (isMobile) {
    if (!workSchedules.length) {
      return null
    }
    return (
      <div>
        <div
          className={`mobile-accordion mobile-header ${
            toggleWorkSchedule ? 'active' : ''
          }`}
          onClick={() => setToggleWorkSchedule(!toggleWorkSchedule)}
        >
          {isSelectedDateHoliday ? (
            <div>
              {selectedDate
                ? `${moment(selectedDate).format('M月D日 (dd)')}(祝)`
                : null}
              <h2>{i18n.t('doctor.availableShifts.workSchedules')}</h2>
            </div>
          ) : (
            <div>
              {selectedDate ? moment(selectedDate).format('M月D日 (dd)') : null}
              <h2>{i18n.t('doctor.availableShifts.workSchedules')}</h2>
            </div>
          )}
          <div className='accordian-button'>
            <i
              className={`pi pi-chevron-${toggleWorkSchedule ? 'up' : 'down'}`}
            />
          </div>
        </div>
        <div
          className='mobile-accordian-body'
          style={
            toggleWorkSchedule ? { display: 'block' } : { display: 'none' }
          }
        >
          {workSchedules.map(workSchedule => (
            <WorkScheduleItem
              workSchedule={workSchedule}
              key={workSchedule.id}
              departmentNames={departmentNames}
            />
          ))}
        </div>
      </div>
    )
  }

  const header = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <div>
        <h2>{i18n.t('doctor.availableShifts.workSchedules')}</h2>
      </div>
    </div>
  )

  const convertToArray = arr => {
    const result = []
    arr.forEach(item => {
      result.push({
        clinic: ClinicNameRender({
          clinicalDepartment: item.clinicalDepartment,
        }),
        clinicKana: item.clinicalDepartment.clinic.nameKana,
        name: departmentNames[item.clinicalDepartment.name],
        startTime: moment(item.startTime).format('HH:mm'),
        endTime: moment(item.endTime).format('HH:mm'),
        accepted: (
          <div className='flex-parent is-center'>
            <StatusRender val={item.accepted} />
          </div>
        ),
        dailySalary: numeralCurrency(item.acceptedShift.dailySalary),
        action: <ActionItem item={item} />,
      })
    })
    return result
  }

  return (
    <React.Fragment>
      {loading ? (
        <ProgressSpinner />
      ) : (
        <DataTable
          value={convertToArray(workSchedules)}
          header={header}
          scrollable
          scrollHeight='35vh'
          emptyMessage={i18n.t('staff.workSchedule.noWorkSchedule')}
        >
          <Column
            field='clinic'
            header={i18n.t('main.clinicName')}
            style={{ width: '20%' }}
            sortable
            sortField='clinicKana'
          />
          <Column
            field='name'
            header={i18n.t('main.departmentName-short')}
            style={{ width: '12%' }}
            sortable
          />
          <Column
            field='startTime'
            header={i18n.t('main.startTime')}
            style={{ width: '14%' }}
            sortable
          />
          <Column
            field='endTime'
            header={i18n.t('main.endTime')}
            style={{ width: '14%' }}
            sortable
          />
          <Column
            field='dailySalary'
            header={i18n.t('main.dailySalary')}
            style={{ width: '16%' }}
            sortable
          />
          <Column
            field='accepted'
            header={i18n.t('main.status')}
            style={{ width: '14%', fontSize: 7 }}
          />
          <Column
            field='action'
            header={i18n.t('main.action')}
            style={{ width: '10%' }}
          />
        </DataTable>
      )}
    </React.Fragment>
  )
}

export default WorkScheduleTable
