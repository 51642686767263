import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import {
  clinicalDepartmentNames,
  combineNames,
  sortAndFilterByClinicOrder,
} from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import React from 'react'
import { DEPARTMENTS_LIST } from '../../CreateAvailableShift/CreateAvailableShift.graphql'
import {
  CREATE_DEFAULT_HOURLY_WAGE_SCHEDULE,
  GET_HOURLY_WAGE_SCHEDULE_BY_ID,
} from '../ClinicsHourlyWageList/ClinicsHourlyWage.graphql'
import HourlyWageFormScene from './HourlyWageFormScene'
import moment from 'moment'

const HourlyWageDuplicate = ({
  match: {
    params: { recruitmentShiftId },
  },
  closeModal,
}) => {
  const [{ i18n, popup }] = useCustom()
  const { loading, error, data } = useQuery(DEPARTMENTS_LIST)
  const {
    loading: shiftLoading,
    error: shiftError,
    data: shiftData,
  } = useQuery(GET_HOURLY_WAGE_SCHEDULE_BY_ID, {
    variables: {
      where: {
        id: recruitmentShiftId,
      },
    },
  })
  const [createRecuitmentShift] = useMutation(
    CREATE_DEFAULT_HOURLY_WAGE_SCHEDULE
  )
  if (loading || shiftLoading) return <ProgressSpinner />
  if (error || shiftError) return <ErrorComponent error={error} />

  const defaultHourlyWageSchedule =
    shiftData && shiftData.defaultHourlyWageSchedule
      ? shiftData.defaultHourlyWageSchedule
      : null

  const { clinicalDepartments, departments } = data
  const sortedClinicalDepartments = sortAndFilterByClinicOrder({
    clinicalDepartments,
    isFilter: true,
  })
  const departmentNames = clinicalDepartmentNames({ departments })

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    let {
      // eslint-disable-next-line no-shadow
      clinicalDepartmentId,
      clinicalDepartments,
      date,
      time,
      isMonday,
      isTuesday,
      isWednesday,
      isThursday,
      isFriday,
      isSaturday,
      isSunday,
      isDayoff,
      hourlyWage,
      type,
    } = values
    try {
      let startDate, endDate, startTime, endTime
      startDate = !date[0].includes('00:00:00.000Z')
        ? date[0] + 'T' + '00:00:00.000Z'
        : date[0]
      endDate =
        date[1].includes('23:59:59.000Z') || date[1].includes('23:59:59.999Z')
          ? date[1]
          : date[1] + 'T' + '23:59:59.999Z'
      startTime = moment(time[0]).toISOString()
      endTime = moment(time[1]).toISOString()

      if (Array.isArray(type)) {
        type = type.length > 0 ? type[0] : null
      }

      await createRecuitmentShift({
        variables: {
          clinicalDepartmentId: clinicalDepartmentId,
          startDate: startDate,
          endDate: endDate,
          startTime: startTime,
          endTime: endTime,
          hourlyWageDefault: parseInt(hourlyWage),
          isAvailableOnMonday: isMonday,
          isAvailableOnTuesday: isTuesday,
          isAvailableOnWednesday: isWednesday,
          isAvailableOnThursday: isThursday,
          isAvailableOnFriday: isFriday,
          isAvailableOnSaturday: isSaturday,
          isAvailableOnSunday: isSunday,
          isAvailableOnHoliday: isDayoff,
          classify: type,
        },
      })
      popup.success(i18n.t('staff.createShift.submissionSuccess'))
      closeModal()
      setSubmitting(false)
      resetForm()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }

  return (
    <HourlyWageFormScene
      onSubmit={onSubmit}
      clinicalDepartments={combineNames({
        clinicalDepartments: sortedClinicalDepartments,
        departmentNames,
      })}
      confirmationMessage='作成しますか?'
      i18n={i18n}
      isDuplicateAction
      defaultHourlyWageSchedule={defaultHourlyWageSchedule}
    />
  )
}
export default HourlyWageDuplicate
