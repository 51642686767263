import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { useCustom } from '@medical/provider/context'
import React from 'react'
import {
  DOCTOR_EMAIL_NOTIFICATION_SETTINGS_QUERY,
  DOCTOR_UPDATE_EMAIL_NOTIFICATION_SETTINGS,
} from './EmailNotificationsSetting.graphql'
import EmailNotificationsSettingScene from './EmailNotificationsSettingScene'

const EmailNotificationsSetting = () => {
  const [{ i18n, popup }] = useCustom()

  const { data, loading, error } = useQuery(
    DOCTOR_EMAIL_NOTIFICATION_SETTINGS_QUERY
  )

  const [updateEmailNotificationSettings] = useMutation(
    DOCTOR_UPDATE_EMAIL_NOTIFICATION_SETTINGS,
    {
      refetchQueries: [
        {
          query: DOCTOR_EMAIL_NOTIFICATION_SETTINGS_QUERY,
          awaitRefetchQueries: true,
        },
      ],
    }
  )

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const onSubmit = async (
    { isReceivedEmailNotification },
    { setSubmitting, resetForm }
  ) => {
    try {
      await updateEmailNotificationSettings({
        variables: {
          isReceivedEmailNotification,
        },
      })
      popup.success(i18n.t('doctor.emailNotificationsSetting.update.success'))
      setSubmitting(false)
      resetForm()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }

  const { doctorEmailNotificationSettings } = data

  return (
    <EmailNotificationsSettingScene
      settingOptions={doctorEmailNotificationSettings}
      onSubmit={onSubmit}
      i18n={i18n}
    />
  )
}

export default EmailNotificationsSetting
