import { splitLogic } from '@medical/libs'
import moment from 'moment'

const renderAvailableDay = key => {
  let availableDay = ''
  switch (key) {
    case 'mon':
      availableDay = '月'
      break
    case 'tue':
      availableDay = '火'
      break
    case 'wed':
      availableDay = '水'
      break
    case 'thu':
      availableDay = '木'
      break
    case 'fri':
      availableDay = '金'
      break
    case 'sat':
      availableDay = '土'
      break
    case 'sun':
      availableDay = '日'
      break
    case 'mon_hol':
      availableDay = '月(祝日)'
      break
    case 'tue_hol':
      availableDay = '火(祝日)'
      break
    case 'wed_hol':
      availableDay = '水(祝日)'
      break
    case 'thu_hol':
      availableDay = '木(祝日)'
      break
    case 'fri_hol':
      availableDay = '金(祝日)'
      break
    case 'sat_hol':
      availableDay = '土(祝日)'
      break
    case 'sun_hol':
      availableDay = '日(祝日)'
      break
    case 'hol':
      availableDay = '祝'
      break
    default:
      break
  }
  // if (
  //   !isAvailableOnMonday ||
  //   !isAvailableOnTuesday ||
  //   !isAvailableOnWednesday ||
  //   !isAvailableOnThursday ||
  //   !isAvailableOnFriday
  // ) {
  //   if (isAvailableOnMonday) availableDay += '月'
  //   if (isAvailableOnTuesday) availableDay += '火'
  //   if (isAvailableOnWednesday) availableDay += '水'
  //   if (isAvailableOnThursday) availableDay += '木'
  //   if (isAvailableOnFriday) availableDay += '金'
  //   if (isAvailableOnSaturday) availableDay += '土'
  //   if (isAvailableOnSunday) availableDay += '日'
  //   if (isAvailableOnHoliday) availableDay += '祝'
  // } else {
  //   availableDay = '平日'
  //   if (isAvailableOnSaturday) availableDay += '土'
  //   if (isAvailableOnSunday) availableDay += '日'
  //   if (isAvailableOnHoliday) availableDay += '祝'
  // }
  return availableDay
}

const headerMail = (doctor, isDetail) => {
  const fullName = doctor.fullName ? doctor.fullName : ''
  const text = isDetail
    ? `定期確定シフトの内容を変更しましたのでお知らせします。`
    : `定期確定シフトを作成しましたのでお知らせします。

  以下の日程で定期確定シフトを作成しておりますのでご確認ください。`
  return `${fullName}先生

お世話になっております。
CAPSクリニック ドクターサポート事務局です。
${text}
`
}

const contentMail = (clinicalDepartments, submitDate, splits, startEndDate) => {
  let dataTime = {}
  if (Object.keys(splits).length > 0) {
    Object.keys(splits).forEach(key => {
      dataTime = {
        ...dataTime,
        ...splitLogic({ submitDate, splits: splits[key], key }),
      }
    })
  }

  const startDate = startEndDate[0]
    ? moment.utc(startEndDate[0]).format('YYYY年MM月DD日')
    : ''
  const endDate = startEndDate[1]
    ? moment.utc(startEndDate[1]).format('YYYY年MM月DD日')
    : ''
  let startEndDateString = ''
  if (startEndDate[0] && startEndDate[1])
    startEndDateString = `${startDate}〜${endDate}`
  let msg = ' '
  if (Object.keys(dataTime).length > 0) {
    msg += Object.keys(dataTime).map(key => {
      const {
        startTime,
        endTime,
        splitDateTime1,
        splitDateTime2,
        splitDateTime3,
        isStartTimeBreakTime,
        isSplitDateTime1BreakTime,
        isSplitDateTime2BreakTime,
        isSplitDateTime3BreakTime,
        clinicalDepartmentId,
      } = dataTime[key]
      const clinicalDepartment = clinicalDepartmentId
        ? clinicalDepartments.find(({ id }) => id === clinicalDepartmentId)
        : ''
      const clinicalDepartmentName = clinicalDepartment.clinicName || ''
      if (splitDateTime1 && splitDateTime2 && splitDateTime3) {
        let isStartTimeBreakTimeContent = ''
        let isSplitDateTime1BreakTimeContent = ''
        let isSplitDateTime2BreakTimeContent = ''
        let isSplitDateTime3BreakTimeContent = ''
        if (isStartTimeBreakTime) isStartTimeBreakTimeContent = '(休憩)'
        if (isSplitDateTime1BreakTime)
          isSplitDateTime1BreakTimeContent = '(休憩)'
        if (isSplitDateTime2BreakTime)
          isSplitDateTime2BreakTimeContent = '(休憩)'
        if (isSplitDateTime3BreakTime)
          isSplitDateTime3BreakTimeContent = '(休憩)'
        return `
      曜日：${renderAvailableDay(key)} 
            時間：${moment(startTime).format('HH:mm')}〜${moment(
          splitDateTime1
        ).format('HH:mm')}${isSplitDateTime1BreakTimeContent}, ${moment(
          splitDateTime1
        ).format('HH:mm')}〜${moment(splitDateTime2).format(
          'HH:mm'
        )}${isSplitDateTime2BreakTimeContent}, ${moment(splitDateTime2).format(
          'HH:mm'
        )}〜${moment(splitDateTime3).format(
          'HH:mm'
        )}${isSplitDateTime3BreakTimeContent}, ${moment(splitDateTime3).format(
          'HH:mm'
        )}〜${moment(endTime).format('HH:mm')}${isStartTimeBreakTimeContent}
            クリニック名：「${clinicalDepartmentName}」
  
`
      }
      if (splitDateTime2 && splitDateTime1) {
        let isStartTimeBreakTimeContent = ''
        let isSplitDateTime1BreakTimeContent = ''
        let isSplitDateTime2BreakTimeContent = ''
        if (isStartTimeBreakTime) isStartTimeBreakTimeContent = '(休憩)'
        if (isSplitDateTime1BreakTime)
          isSplitDateTime1BreakTimeContent = '(休憩)'
        if (isSplitDateTime2BreakTime)
          isSplitDateTime2BreakTimeContent = '(休憩)'
        return `
      曜日：${renderAvailableDay(key)}  
            時間：${moment(startTime).format('HH:mm')}〜${moment(
          splitDateTime1
        ).format('HH:mm')}${isSplitDateTime1BreakTimeContent}, ${moment(
          splitDateTime1
        ).format('HH:mm')}〜${moment(splitDateTime2).format(
          'HH:mm'
        )}${isSplitDateTime2BreakTimeContent}, ${moment(splitDateTime2).format(
          'HH:mm'
        )}〜${moment(endTime).format('HH:mm')}${isStartTimeBreakTimeContent}
            クリニック名：「${clinicalDepartmentName}」
 
  `
      }
      if (splitDateTime1) {
        let isStartTimeBreakTimeContent = ''
        let isSplitDateTime1BreakTimeContent = ''
        if (isStartTimeBreakTime) isStartTimeBreakTimeContent = '(休憩)'
        if (isSplitDateTime1BreakTime)
          isSplitDateTime1BreakTimeContent = '(休憩)'
        return `
      曜日： ${renderAvailableDay(key)} 
            時間：${moment(startTime).format('HH:mm')}〜${moment(
          splitDateTime1
        ).format('HH:mm')}${isSplitDateTime1BreakTimeContent}, ${moment(
          splitDateTime1
        ).format('HH:mm')}〜${moment(endTime).format(
          'HH:mm'
        )}${isStartTimeBreakTimeContent}
            クリニック名：「${clinicalDepartmentName}」

  `
      }
      if (!splitDateTime1 && !splitDateTime2 && !splitDateTime3) {
        let isStartTimeBreakTimeContent = ''
        if (isStartTimeBreakTime) isStartTimeBreakTimeContent = '(休憩)'
        return `
      曜日： ${renderAvailableDay(key)} 
            時間：${moment(startTime).format('HH:mm')}〜${moment(
          endTime
        ).format('HH:mm')}${isStartTimeBreakTimeContent}
            クリニック名：「${clinicalDepartmentName}」
      `
      }
    })
  }

  return `
期間：${startEndDateString}
${msg}
ご勤務予定については、変更となる可能性もございます。`
}

export const LinkMail = () => {
  let url
  if (process.env.REACT_APP_ENV === 'development')
    url = 'http://localhost:3000/doctor/login'
  else if (process.env.REACT_APP_ENV === 'staging')
    url = 'https://staging.capsdoctor365.jp/doctor/login'
  else url = 'https://doctor.capsdoctor365.jp/doctor/login'
  return `詳細はCAPSドクター365 (<a href=${url}>${url}</a>) よりご確認ください。`
}

export const getEmailTemplate = (
  isDetail,
  doctor,
  clinicalDepartments,
  submitDate,
  splits,
  startEndDate,
  isAvailableOnMonday,
  isAvailableOnTuesday,
  isAvailableOnWednesday,
  isAvailableOnThursday,
  isAvailableOnFriday,
  isAvailableOnSaturday,
  isAvailableOnSunday,
  isAvailableOnHoliday
) => `${headerMail(doctor, isDetail)}
  
${contentMail(
  clinicalDepartments,
  submitDate,
  splits,
  startEndDate,
  isAvailableOnMonday,
  isAvailableOnTuesday,
  isAvailableOnWednesday,
  isAvailableOnThursday,
  isAvailableOnFriday,
  isAvailableOnSaturday,
  isAvailableOnSunday,
  isAvailableOnHoliday
)}`
