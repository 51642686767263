import Router from '@medical/routes/router'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export const TermsLink = () => {
  const i18n = useTranslation()

  return (
    <React.Fragment>
      <Link to={Router.doctorTerms} rel='noopener noreferrer' target='_blank'>
        {i18n.t('doctor.terms')}
      </Link>
      {/* <a
        href="http://shonika-style.jp/page/kiyaku"
        target="blank"
        tabIndex="-1"
      >
        {i18n.t('doctor.terms')}
      </a> */}
    </React.Fragment>
  )
}

export const PrivacyLink = () => {
  const i18n = useTranslation()

  return (
    <React.Fragment>
      <Link to={Router.doctorPrivacy} rel='noopener noreferrer' target='_blank'>
        {i18n.t('doctor.privacy')}
      </Link>
      {/* <a
        href="https://www.caps365.jp/privacy-policy"
        target="blank"
        tabIndex="-1"
      >
        {i18n.t('doctor.privacy')}
      </a> */}
    </React.Fragment>
  )
}
