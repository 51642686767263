import { useCustom } from '@medical/provider/context'
import React, { useState } from 'react'
import HourlyWageCsvSence from './HourlyWageCsvSence'
import Auth from '@medical/middleware/auth'
import {
  STAFF_CHECK_HOURLYWAGE_CSV,
  STAFF_HOURLYWAGE_CSV,
} from '@medical/constant/permissions'
import { checkStaffPermission, clinicalDepartmentNames } from '@medical/libs'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent } from '@medical/components/ErrorComponent'
import { ProgressSpinner } from '@medical/components'
import downloadCsv from 'download-csv'
import { DEPARTMENTS_LIST } from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import ClinicNameRender from '@medical/components/ClinicNameRender'
import { CHECK_UPLOAD_SHIFTS, UPLOAD_SHIFTS } from './HourlyWageCsv.graphql'
import convertHourlyWageCsvRecord from '@medical/libs/convertHourlyWageCsvRecord'

const HourlyWageCsv = () => {
  const [{ i18n, popup }] = useCustom()
  const { loading, error, data } = useQuery(DEPARTMENTS_LIST)
  const [uploadShifts] = useMutation(UPLOAD_SHIFTS)
  const [checkUploadShifts] = useMutation(CHECK_UPLOAD_SHIFTS)

  const [state, setState] = useState({
    isUploaded: false,
  })
  const isUploadPermitted = checkStaffPermission({
    functionName: STAFF_HOURLYWAGE_CSV,
  })
  const isUploadCheckPermitted = checkStaffPermission({
    functionName: STAFF_CHECK_HOURLYWAGE_CSV,
  })

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const downloadSample = () => {
    window.location.href = `${
      process.env.REACT_APP_ENDPOINT
    }/files/募集シフト時給設定管理サンプルcsvイメージ.csv?token=${Auth.getToken()}`
  }

  const { clinicalDepartments, departments } = data
  const departmentNames = clinicalDepartmentNames({ departments })
  const downloadClinicalDepartments = () => {
    const sortedDepartments = clinicalDepartments
      .filter(department => department.isDisplay)
      .sort((a, b) => {
        const codeA = parseInt(a.departmentCode, 10)
        const codeB = parseInt(b.departmentCode, 10)
        return codeA - codeB
      })
    const sortedByDepartmentCode = sortedDepartments.map(department => ({
      clinicName: `${ClinicNameRender({ clinicalDepartment: department })}`,
      departmentName: departmentNames[department.name],
      departmentCode: department.departmentCode,
    }))
    downloadCsv(
      sortedByDepartmentCode,
      {
        departmentName: i18n.t('main.departmentName'),
        clinicName: i18n.t('main.clinicName'),
        departmentCode: i18n.t('main.departmentCode'),
      },
      '診療科コード一覧.csv'
    )
  }

  const onSubmit = async ({ data: UserCsvData }, { resetForm }) => {
    await setState({
      isUploaded: true,
    })
    try {
      if (!isUploadCheckPermitted) {
        popup.error('権限がありません')
      } else {
        popup.info(i18n.t('staff.uploadCsv.uploading'))
        const CsvRecords = convertHourlyWageCsvRecord(UserCsvData)
        await uploadShifts({
          variables: {
            HourlyWageCsvRecords: CsvRecords,
          },
        })
        popup.clear()
        popup.success(i18n.t('staff.uploadCsv.submissionSuccess'))
        resetForm()
      }
    } catch (error) {
      popup.clear()
      popup.error(error)
    }
    await setState({
      isUploaded: false,
    })
  }
  const checkCsvData = async ({ data: UserCsvData }, { setFieldValue }) => {
    try {
      if (!isUploadCheckPermitted) {
        popup.error(i18n.t('main.noPermission'))
      } else {
        popup.info(i18n.t('staff.uploadCheckCsv.uploading'))
        const CsvRecords = convertHourlyWageCsvRecord(UserCsvData)
        const {
          data: { staffCheckDefaultHourlyWageCsv },
        } = await checkUploadShifts({
          variables: {
            HourlyWageCsvRecords: CsvRecords,
          },
        })
        const messageAddedCsvData = UserCsvData.map((item, index) => ({
          ...item,
          errorMessage: staffCheckDefaultHourlyWageCsv[index].errorMessage,
          checked: true,
        }))
        setFieldValue('data', messageAddedCsvData)
        popup.clear()
        popup.success(i18n.t('staff.uploadCheckCsv.submissionSuccess'))
      }
    } catch (error) {
      popup.clear()
      popup.error(error)
    }
  }
  return (
    <>
      <HourlyWageCsvSence
        i18n={i18n}
        downloadSample={downloadSample}
        onSubmit={onSubmit}
        popup={popup}
        checkCsvData={checkCsvData}
        isUploaded={state.isUploaded}
        downloadClinicalDepartments={downloadClinicalDepartments}
        isUploadCheckPermitted={isUploadCheckPermitted}
        isUploadPermitted={isUploadPermitted}
      />
    </>
  )
}
export default HourlyWageCsv
