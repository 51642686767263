import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { useCustom } from '@medical/provider/context'
import React from 'react'

import {
  DETAIL_SKILL,
  DELETE_SKILL,
  UPDATE_SKILL,
  STAFF_ME,
} from './DetailDoctorSkill.graphql'
import {
  STAFF_DOCTOR_SKILL_UPDATE,
  STAFF_DOCTOR_SKILL_DELETE,
} from '@medical/constant/permissions'
import { checkStaffPermission } from '@medical/libs'
import DetailDoctorSkillScene from './DetailDoctorSkillScene'

const DoctorSkill = ({
  closeModal,
  match: {
    params: { skillId },
  },
}) => {
  const [{ i18n, popup }] = useCustom()
  const [staffDeleteSkill] = useMutation(DELETE_SKILL)
  const [staffUpdateSkill] = useMutation(UPDATE_SKILL)
  const isUpdatePermitted = checkStaffPermission({
    functionName: STAFF_DOCTOR_SKILL_UPDATE,
  })

  const isDeletePermitted = checkStaffPermission({
    functionName: STAFF_DOCTOR_SKILL_DELETE,
  })
  const { loading, error, data } = useQuery(DETAIL_SKILL, {
    variables: {
      where: {
        id: skillId,
      },
    },
  })
  const {
    loading: staffMeLoading,
    error: staffMeError,
    data: staffMeData,
  } = useQuery(STAFF_ME)

  if (loading || staffMeLoading) return <ProgressSpinner />
  if (error || staffMeError) return <ErrorComponent error={error} />
  const skillDetail = data.skillsConnection.edges[0].node
  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      let successMessage = '更新しました。'
      if (values.functionName === 'delete') successMessage = '削除しました。'
      if (values.functionName === 'delete') {
        await staffDeleteSkill({
          variables: {
            skillId: values.skillId,
          },
        })
      } else if (values.functionName === 'update') {
        await staffUpdateSkill({
          variables: {
            skillId: values.skillId,
            name: values.skillName,
            isShow: values.skillDisplay,
            updatedBy: staffMeData.staffMe.id,
          },
        })
      }
      popup.success(successMessage)
      setSubmitting(false)
      resetForm()
      closeModal()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }
  return (
    <React.Fragment>
      <DetailDoctorSkillScene
        onSubmit={onSubmit}
        skillDetail={skillDetail}
        isUpdatePermitted={isUpdatePermitted}
        isDeletePermitted={isDeletePermitted}
      />
    </React.Fragment>
  )
}

export default DoctorSkill
