import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, NotFound, ProgressSpinner } from '@medical/components'
import {
  STAFF_PROCESS_DOCTOR_IDENTITY,
  STAFF_UPDATE_DOCTOR_IDENTITY_NUMBER,
} from '@medical/constant/permissions'
import { checkStaffPermission } from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import React, { useEffect } from 'react'
import {
  DOCTOR_QUERY,
  STAFF_UPDATE_DOCTOR_IDENTITY,
} from './ManangerDoctorIdentiry.graphql'
import ManagerDoctorIdentityScence from './ManangerDoctorIdentitySence'

const ManagerDoctorIdentity = ({
  closeModal,
  match: {
    params: { doctorId },
  },
}) => {
  {
    const [{ i18n, popup }] = useCustom()
    const isProcessPermitted = checkStaffPermission({
      functionName: STAFF_PROCESS_DOCTOR_IDENTITY,
    })
    const isUpdateDoctorIdentity = checkStaffPermission({
      functionName: STAFF_UPDATE_DOCTOR_IDENTITY_NUMBER,
    })
    const [staffUpdateDoctorIdentity] = useMutation(
      STAFF_UPDATE_DOCTOR_IDENTITY
    )
    const { loading, error, data, refetch } = useQuery(DOCTOR_QUERY, {
      variables: {
        doctorId,
      },
      fetchPolicy: 'network-only',
    })
    useEffect(() => {
      refetch()
    })
    if (loading) return <ProgressSpinner />
    if (error) return <ErrorComponent error={error} />
    if (!isUpdateDoctorIdentity) {
      return <NotFound />
    }
    const formatDoctorIdentityRelation = doctorIdentityRelation => {
      doctorIdentityRelation.forEach(v => {
        delete v.updatedAt
        delete v.__typename
        if (!v.deletedAt || v.deletedAt.length === 0) delete v.deletedAt
      })
      return doctorIdentityRelation
    }
    const onSubmit = async (values, { setSubmitting, resetForm }) => {
      try {
        const {
          identityNumber,
          doctorIdentityRelation,
          frontSideIdentityImg,
          backSideIdentityImg,
          frontSideLicenseImg,
          backSideLicenseImg,
        } = values
        const successMessage = "マイナンバーを更新しました。"
        await staffUpdateDoctorIdentity({
          variables: {
            doctorId: doctorId,
            identityNumber: identityNumber,
            frontSideIdentityFile: frontSideIdentityImg,
            backSideIdentityFile: backSideIdentityImg,
            frontSideLicenseFile: frontSideLicenseImg,
            backSideLicenseFile: backSideLicenseImg,
            doctorIdentityRelations: formatDoctorIdentityRelation([
              ...doctorIdentityRelation,
            ]),
          },
        })
        popup.success(successMessage)
        setSubmitting(false)
        resetForm()
        closeModal()
      } catch (error) {
        popup.error(error)
        setSubmitting(false)
      }
    }
    return (
      <React.Fragment>
        <div className='modal-title' style={{ fontWeight: 'bold' }}>
          マイナンバー編集
        </div>
        <ManagerDoctorIdentityScence
          doctor={data.doctor}
          doctorIdentity={data.doctor.doctorIdentity}
          doctorIdentityRelations={
            data.doctor.doctorIdentity
              ? data.doctor.doctorIdentity.doctorIdentityRelation
              : null
          }
          onSubmit={onSubmit}
          isProcessPermitted={isProcessPermitted}
        />
      </React.Fragment>
    )
  }
}

export default ManagerDoctorIdentity
