import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import {
  STAFF_CREATE_ANNOUNCE,
  STAFF_DELETE_ANNOUNCE,
  STAFF_UPDATE_ANNOUNCE,
} from '@medical/constant/permissions'
import { checkStaffPermission, onPageChange } from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import queryString from 'query-string'
import React from 'react'

import { ANNOUNCES_CONNECTION, DELETE_ANNOUNCE } from './AnnouncesList.graphql'
import AnnouncesListScene from './AnnouncesListScene'

const AnnouncesList = ({ history, location: { search } }) => {
  const [{ i18n, popup }] = useCustom()
  const {
    page = 1,
    rowsPerPage = 10,
    orderBy = 'createdAt_DESC',
  } = queryString.parse(search)
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  const variables = {
    first: first < 0 ? 10 : first,
    skip: skip < 0 ? 0 : skip,
    orderBy,
  }

  const isCreatePermitted = checkStaffPermission({
    functionName: STAFF_CREATE_ANNOUNCE,
  })
  const isDeletePermitted = checkStaffPermission({
    functionName: STAFF_DELETE_ANNOUNCE,
  })
  const isUpdatePermitted = checkStaffPermission({
    functionName: STAFF_UPDATE_ANNOUNCE,
  })
  const { loading, error, data } = useQuery(ANNOUNCES_CONNECTION, {
    variables,
    fetchPolicy: 'network-only',
  })
  const [deleteAnnounce] = useMutation(DELETE_ANNOUNCE)

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const {
    announcesConnection: {
      edges,
      aggregate: { count },
    },
  } = data

  const onSubmit = async ({ announceId }, { setSubmitting, resetForm }) => {
    try {
      await deleteAnnounce({
        variables: {
          announceId,
        },
        update: store => {
          const announcesData = store.readQuery({
            query: ANNOUNCES_CONNECTION,
            variables,
          })
          announcesData.announcesConnection.edges = announcesData.announcesConnection.edges.filter(
            edge => edge.node.id !== announceId
          )
          announcesData.announcesConnection.aggregate.count -= 1
          store.writeQuery({
            query: ANNOUNCES_CONNECTION,
            data: announcesData,
            variables,
          })
        },
      })
      popup.success(i18n.t('staff.deleteAnnounce.submissionSuccess'))
      setSubmitting(false)
      resetForm()
    } catch (error) {
      popup.error(i18n.t('staff.deleteAnnounce.submissionError'))
      setSubmitting(false)
    }
  }

  return (
    <AnnouncesListScene
      i18n={i18n}
      edges={edges}
      rowsPerPage={rowsPerPage}
      page={page}
      count={count}
      onPageChange={onPageChange(history, search)}
      onSubmit={onSubmit}
      isCreatePermitted={isCreatePermitted}
      isDeletePermitted={isDeletePermitted}
      isUpdatePermitted={isUpdatePermitted}
    />
  )
}

export default AnnouncesList
