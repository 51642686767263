import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import ClinicNameRender from '@medical/components/ClinicNameRender'
import { CATEGORY_TRANSPORT } from '@medical/constant'
import { DOCTOR_ME } from '@medical/layout/DoctorLayout/DoctorLayoutRequest.graphql'
import {
  calcTotalTransportationExpense,
  calculateSalaryNumber,
  calcWorkScheduleTotalDays,
  calcWorkScheduleTotalHours,
  calcWorkScheduleTotalSalary,
  clinicalDepartmentNames,
  dateValidation,
  calculaterSubsidy,
} from '@medical/libs'
import { GET_INCENTIVE } from '@medical/pages/staff/Incentive/ListOfIncentives/ListOfIncentives.graphql'
import Router from '@medical/routes/router'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'

import {
  DOCTOR_MONTHLY_INCENTIVE_AMOUNT,
  WORK_REPORTS,
} from './WorkReports.graphql'
import WorkReportsScene from './WorkReportsScene'

const WorkReports = ({
  match: {
    path,
    params: { year = moment().year(), month = moment().month() + 1 },
  },
}) => {
  const { i18n } = useTranslation()
  const date = moment()
    .year(year)
    .month(month - 1)
    .startOf('month')
  const { loading, error, data } = useQuery(WORK_REPORTS, {
    variables: {
      date,
    },
  })
  const {
    loading: loadingMonthlyIncentive,
    error: errorMonthlyIncentive,
    data: dataTotalIncentive,
  } = useQuery(DOCTOR_MONTHLY_INCENTIVE_AMOUNT, {
    variables: {
      date: moment(date.format('YYYY-MM-DD')),
    },
  })
  const {
    data: {
      doctorMe: { id: doctorMeId },
    },
  } = useQuery(DOCTOR_ME)

  const incentiveTypes = [
    'NORMAL_INCENTIVE',
    'NUMBER_INCENTIVE',
    'BEYOND_EIGHT_INCENTIVE',
    'TIME_INCENTIVE',
    'SATISFACTION_INCENTIVE',
  ]
  const incentiveTypeSearch = () => {
    if (
      moment()
        .subtract(2, 'month')
        .isSameOrAfter(date)
    ) {
      return incentiveTypes
    }
    if (
      moment()
        .subtract(1, 'month')
        .year() === date.year() &&
      moment()
        .subtract(1, 'month')
        .month() === date.month()
    ) {
      if (moment().date() >= 15) {
        return incentiveTypes
      }
      return ['NORMAL_INCENTIVE']
    }
    return ['NORMAL_INCENTIVE']
  }

  const variables = {
    where: {
      incentiveTotalAmount_gte: 1,
      applyTime: moment()
        .utc()
        .year(year)
        .month(month - 1)
        .startOf('month'),
      Doctor: {
        id: doctorMeId,
        deletedAt: null,
      },
      Incentive: {
        type_in: incentiveTypeSearch(),
      },
    },
  }

  const {
    loading: incentiveLoading,
    error: incentiveError,
    data: dataIncentives,
  } = useQuery(GET_INCENTIVE, {
    variables,
    fetchPolicy: 'network-only',
  })
  if (
    dateValidation({ year, month }, true) ||
    [
      Router.doctorWorkReportsWithoutYearMonth,
      Router.doctorWorkReportsWithoutMonth,
    ].includes(path)
  )
    return (
      <Redirect
        to={Router.get(Router.doctorWorkReports, {
          year: moment().year(),
          month: moment().month() + 1,
        })}
      />
    )

  if (loading || incentiveLoading || loadingMonthlyIncentive)
    return <ProgressSpinner />
  if (error || incentiveError || errorMonthlyIncentive)
    return (
      <ErrorComponent
        error={error || incentiveError || errorMonthlyIncentive}
      />
    )
  const { doctorMonthlyWorkSchedules: workSchedules = [] } = data.doctorMe || {}

  const { doctorMonthlyTransportationExpenses: transportationExpenses = [] } =
    data.doctorMe || {}

  const departmentNames = clinicalDepartmentNames({
    departments: data.departments,
  })
  const sortedWorkSchedules = workSchedules
    .sort((a, b) => moment(a.startTime) - moment(b.startTime))
    .map(ws => ({
      id: ws.id,
      deletedAt: ws.deletedAt,
      clinic: ClinicNameRender({
        clinicalDepartment: ws.clinicalDepartment,
      }),
      clinicKana: ws.clinicalDepartment.clinic.nameKana,
      clinicalDepartment: departmentNames[ws.clinicalDepartment.name],
      startTime: ws.startTime,
      endTime: ws.endTime,
      dailySalary: calculateSalaryNumber({
        availableShift: ws.acceptedShift,
        startTime: ws.startTime,
        endTime: ws.endTime,
        hourlyWageRange: ws.adjustHourlyWageRange,
        doctorSubsidy: calculaterSubsidy(ws.acceptedShift.doctorSubsidy),
      }),
      costWs: ws.isTypeTransportation
        ? ws.costTransportationByMyCar
        : ws.costTransportationByDoctorWorkSchedule.length > 0
        ? ws.costTransportationByDoctorWorkSchedule[0].cost
        : 0,
      costTransportationByDoctorWorkSchedule: ws.isTypeTransportation
        ? ws.costTransportationByMyCar
        : ws.costTransportationByDoctorWorkSchedule.length > 0
        ? ws.costTransportationByDoctorWorkSchedule[0].returnPath[2]
        : 0,
      notApplyCostTransportation: ws.notApplyCostTransportation,
    }))
  const totalHours = calcWorkScheduleTotalHours(sortedWorkSchedules)

  const totalSalary = calcWorkScheduleTotalSalary(sortedWorkSchedules)

  const totalDays = calcWorkScheduleTotalDays(sortedWorkSchedules)
  const lstTransportationWithDate = []
  for (const transportation of transportationExpenses) {
    if (transportation.category === CATEGORY_TRANSPORT.TRANFFIC_FEE) {
      lstTransportationWithDate.push({ ...transportation })
    } else {
      const startTime = moment.utc(transportation.startTime).clone()
      const endTime = moment.utc(transportation.endTime)
      while (startTime.isSameOrBefore(endTime)) {
        const timeCurrent = moment.utc(startTime).format('YYYY-MM-DD')
        const datesCheck = transportation.blockedDays
          ? transportation.blockedDays.split(',')
          : []

        if (!datesCheck.includes(timeCurrent.slice(0, 10))) {
          lstTransportationWithDate.push({
            ...transportation,
            startTime: `${timeCurrent}T00:00:00.000Z`,
            endTime: `${timeCurrent}T00:00:00.000Z`,
          })
        }
        startTime.add(1, 'days')
      }
    }
  }
  const transportationExpensesCost = lstTransportationWithDate.map(item => ({
    cost: item.cost,
    deletedAt: item.deletedAt,
  }))
  const totalTransportationExpense = calcTotalTransportationExpense(
    transportationExpensesCost,
    sortedWorkSchedules
  )
  const getNormalIncentiveTypes = () => {
    const result = []
    if (dataIncentives.incentiveDetailsConnection) {
      dataIncentives.incentiveDetailsConnection.edges.forEach(({ node }) => {
        if (node.incentiveTotalAmount && node.incentiveTotalAmount > 0) {
          if (node.incentive.type === 'NORMAL_INCENTIVE') {
            result.push({
              type: node.incentive.title,
              value: node.incentiveTotalAmount,
            })
          }
        }
      })
    }
    return result
  }

  const getOtherIncentiveTypes = () => {
    if (dataIncentives.incentiveDetailsConnection) {
      const otherIncentiveTypes = []
      dataIncentives.incentiveDetailsConnection.edges.forEach(({ node }) => {
        if (node.incentiveTotalAmount && node.incentiveTotalAmount > 0) {
          if (node.incentive.type !== 'NORMAL_INCENTIVE') {
            otherIncentiveTypes.push({
              type: node.incentive.type,
              value: node.incentiveTotalAmount,
            })
          }
        }
        return otherIncentiveTypes
      })
      return otherIncentiveTypes.reduce((finalObj, cur) => {
        if (cur.type in finalObj) {
          // eslint-disable-next-line operator-assignment
          finalObj[cur.type] = finalObj[cur.type] + cur.value
        } else {
          finalObj[cur.type] = cur.value
        }
        return finalObj
      }, {})
    }
  }

  return (
    <WorkReportsScene
      i18n={i18n}
      date={date}
      workSchedules={sortedWorkSchedules}
      totalHours={totalHours}
      totalDays={totalDays}
      totalSalary={totalSalary}
      totalTransportationExpense={totalTransportationExpense}
      totalIncentive={dataTotalIncentive.doctorMonthlyIncentive}
      normalIncentiveTypes={getNormalIncentiveTypes()}
      otherIncentive={getOtherIncentiveTypes()}
    />
  )
}

export default WorkReports
