import CustomCheckBox from '@medical/components/CheckBox'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import InputTextarea from '@medical/components/InputTextarea'
import TextInput from '@medical/components/TextInput'
import { Formik } from 'formik'
import { Button } from 'primereact/button'
import React, { useState } from 'react'
import * as Yup from 'yup'

import { getEmailTemplate } from './templateEmail'

const digDeeper = (errors, message = []) => {
  Object.keys(errors).forEach(key => {
    if (typeof errors[key] === 'string') {
      message.push(<div className='alert'>{errors[key]}</div>)
    } else {
      digDeeper(errors[key], message)
    }
  })
}

const DoctorStatusForm = ({
  i18n,
  doctor,
  onSubmit,
  doctorStatus,
  confirmationMessage,
}) => {
  const validationSchema = Yup.object().shape({
    doctorStatus: Yup.string().required(
      i18n.t('doctor.uploadStatus.status') + i18n.t('validation.required')
    ),
    mailContent: Yup.string().when('isSendEmail', {
      is: false,
      then: Yup.string().required(i18n.t('validation.mainContent')),
    }),
  })
  const [optionSelected, setOptionSelected] = useState(
    doctor.registrationStatus
  )
  const [contentEmail, setContentEmail] = useState({
    content: '',
  })
  const getCurrentValue = val => {
    setOptionSelected(val)
    setContentEmail({
      content: `${getEmailTemplate(val, doctor)}`,
    })
  }
  return (
    <Formik
      enableReinitialize
      initialValues={{
        confirm: false,
        email: doctor.email,
        homeAddress: doctor.homeAddress,
        isSendEmail: null,
        doctorStatus: [optionSelected],
        mailContent: contentEmail.content,
        isCreateTransportationCost: false,
        isUpdateAddress: false,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={formikProps => {
        const {
          errors,
          setTouched,
          setFieldValue,
          validateForm,
          values,
        } = formikProps
        const accepted = values.doctorStatus[0] === 'ACCEPTED'
        const errorMessage = []
        digDeeper(errors, errorMessage)
        return (
          <React.Fragment>
            <CustomCheckBox
              {...formikProps}
              name='doctorStatus'
              options={doctorStatus}
              label={i18n.t('doctor.uploadStatus.status')}
              required
              getCurrentValue={getCurrentValue}
            />
            <TextInput
              {...formikProps}
              label={i18n.t('doctor.uploadStatus.mailAddress')}
              disabled
              name='email'
            />
            <InputTextarea
              {...formikProps}
              name='mailContent'
              disabled={false}
              label={i18n.t('doctor.uploadStatus.mailContent')}
            />
            <div className='modal-bottom'>
              <Button
                type='submit'
                label={i18n.t('doctor.uploadStatus.btnChangeStatus')}
                onClick={async () => {
                  await setFieldValue('isSendEmail', true)
                  validateForm().then(response => {
                    if (!Object.keys(response).length) {
                      setFieldValue('confirm', 'OnlyAccept')
                    } else {
                      setTouched(response)
                    }
                  })
                }}
              />
              <Button
                type='submit'
                label={i18n.t('doctor.uploadStatus.btnChangeStatusAndSendmail')}
                onClick={async () => {
                  await setFieldValue('isSendEmail', false)
                  validateForm().then(response => {
                    if (!Object.keys(response).length) {
                      setFieldValue('confirm', 'Accept_SendMail')
                    } else {
                      setTouched(response)
                    }
                  })
                }}
              />
            </div>
            {(values.mailContent.length > 0 || values.isSendEmail === true) && (
              <ConfirmationModal
                {...formikProps}
                statusAccepted={accepted && !values.isSendEmail}
                confirmationMessage={
                  values.isSendEmail
                    ? confirmationMessage
                    : i18n.t(
                        'staff.doctorDetail.confirmAcceptDoctocandSendMali'
                      )
                }
              />
            )}
          </React.Fragment>
        )
      }}
    />
  )
}

export default DoctorStatusForm
