import React from 'react'
import '../AvailableShiftStyle.css'
import 'fork/react-date-range/dist/styles.css'
import 'fork/react-date-range/dist/theme/default.css'
import { Checkbox, TimePicker } from 'antd'
import { FieldArray } from 'formik'
import moment from 'moment'

const SpecifyTime = ({
  i18n,
  setFieldValue,
  values,
  chosenType,
  handleChosenType,
}) => {
  return (
    <>
      <div className='content-row'>
        <FieldArray
          name='splitSpecifyTime'
          render={() => (
            <React.Fragment>
              <div className='content-title'>
                <input
                  name='isSpecifyTime'
                  id='specifyTime'
                  type='radio'
                  value='specifyTime'
                  onChange={e => {
                    setFieldValue('morningShift', undefined)
                    setFieldValue('eveningShift', undefined)
                    setFieldValue('nightShift', undefined)
                    handleChosenType('specifyTime')
                  }}
                  checked={chosenType === 'specifyTime'}
                />
                <label
                  className='specify-time-radio-label'
                  htmlFor='specifyTime'
                >
                  {i18n.t('doctor.availableShifts.specifyTime')}
                </label>
              </div>
              <div className='specify-time'>
                <label
                  className='specify-start-label'
                  htmlFor='specifyStartTime'
                >
                  {i18n.t('doctor.availableShifts.specifyStartTime')}
                </label>
                <TimePicker
                  name='startTime'
                  showNow={false}
                  hideDisabledOptions
                  popupClassName='timepicker'
                  disabledHours={() => [0, 1, 2, 3, 4, 5, 6]}
                  value={
                    values.startTime && values.startTime.isValid()
                      ? moment(values.startTime, 'HH:mm')
                      : null
                  }
                  format='HH:mm'
                  placeholder=''
                  onSelect={value => {
                    value
                      ? setFieldValue('startTime', value)
                      : setFieldValue('startTime', null)
                  }}
                  onChange={value => setFieldValue('startTime', value)}
                  defaultOpenValue={moment('00:00', 'HH:mm')}
                  disabled={chosenType === 'specifyShift' || !chosenType}
                />
              </div>
              <div className='specify-time'>
                <label htmlFor='specifyEndTime'>
                  {i18n.t('doctor.availableShifts.specifyEndTime')}
                </label>
                <TimePicker
                  name='endTime'
                  showNow={false}
                  hideDisabledOptions
                  popupClassName='timepicker'
                  disabledHours={() => [0, 1, 2, 3, 4, 5, 6]}
                  value={
                    values.endTime && values.endTime.isValid()
                      ? moment(values.endTime, 'HH:mm')
                      : null
                  }
                  format='HH:mm'
                  placeholder=''
                  onSelect={value => {
                    value
                      ? setFieldValue('endTime', value)
                      : setFieldValue('endTime', null)
                  }}
                  onChange={value => setFieldValue('endTime', value)}
                  defaultOpenValue={
                    values.startTime && values.startTime.isValid()
                      ? moment(values.startTime, 'HH:mm')
                      : null
                  }
                  disabled={chosenType === 'specifyShift' || !chosenType}
                />
              </div>
            </React.Fragment>
          )}
        />
      </div>
      <div className='content-row'>
        <div className='content-title'>
          <input
            name='isSpecifyTime'
            id='specifyShift'
            type='radio'
            value='specifyShift'
            onChange={() => {
              setFieldValue('startTime', null)
              setFieldValue('endTime', null)
              handleChosenType('specifyShift')
            }}
            checked={chosenType === 'specifyShift'}
          />
          <label htmlFor='specifyShift'>
            {i18n.t('doctor.availableShifts.specifyWorkSchedule')}
          </label>
        </div>
        <div className='content-item'>
          <Checkbox
            inputId='morningShift'
            name='morningShift'
            value={values.morningShift}
            onChange={() => {
              setFieldValue('morningShift', !values.morningShift)
            }}
            checked={values.morningShift}
            disabled={chosenType === 'specifyTime' || !chosenType}
          />
          <label htmlFor='morningShift'>
            {i18n.t('doctor.availableShifts.morningShift')}
          </label>
        </div>
        <div className='content-item'>
          <Checkbox
            inputId='eveningShift'
            name='eveningShift'
            value={values.eveningShift}
            onChange={() => {
              setFieldValue('eveningShift', !values.eveningShift)
            }}
            checked={values.eveningShift}
            disabled={chosenType === 'specifyTime' || !chosenType}
          />
          <label htmlFor='eveningShift'>
            {i18n.t('doctor.availableShifts.eveningShift')}
          </label>
        </div>
        <div className='content-item'>
          <Checkbox
            inputId='nightShift'
            name='nightShift'
            value={values.nightShift}
            onChange={() => {
              setFieldValue('nightShift', !values.nightShift)
            }}
            checked={values.nightShift}
            disabled={chosenType === 'specifyTime' || !chosenType}
          />
          <label htmlFor='nightShift'>
            {i18n.t('doctor.availableShifts.nightShift')}
          </label>
        </div>
      </div>
    </>
  )
}

export default SpecifyTime
