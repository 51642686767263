import { DOCTOR_FRAGMENTS } from '@medical/layout/DoctorLayout/DoctorLayoutRequest.graphql'
import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import gql from 'graphql-tag'

import { WORK_SCHEDULE_FRAGMENTS } from '../Schedule/Schedule.graphql'

export const WORK_REPORTS = gql`
  query WORK_REPORTS($date: DateTime!) {
    doctorMe {
      ...DOCTOR_FRAGMENTS
      doctorMonthlyTransportationExpenses(date: $date) {
        createdAt
        id
        cost
        deletedAt
        endTime
        startTime
        type
        category
        blockedDays
        clinicalDepartment {
          id
        }
      }
      doctorMonthlyWorkSchedules(date: $date) {
        ...WORK_SCHEDULE_FRAGMENTS
        deletedAt
        isTypeTransportation
        costTransportationByMyCar
        notApplyCostTransportation
        costTransportationByDoctorWorkSchedule {
          id
          endTime
          startTime
          returnPath
          cost
        }
        acceptedShift {
          id
          hourlyWage
          splitDateTime1
          splitDateTime2
          splitDateTime3
          splitHourlyWage1
          splitHourlyWage2
          splitHourlyWage3
          isStartTimeBreakTime
          isSplitDateTime1BreakTime
          isSplitDateTime2BreakTime
          isSplitDateTime3BreakTime
          doctorSubsidy {
            id
            title
            money
          }
        }
      }
    }
    ...DEPARTMENTS_FRAGMENT
  }
  ${DOCTOR_FRAGMENTS}
  ${WORK_SCHEDULE_FRAGMENTS}
  ${DEPARTMENTS_FRAGMENT}
`

export const DOCTOR_MONTHLY_INCENTIVE_AMOUNT = gql`
  query DoctorMonthlyIncentiveAmount($doctorId: ID, $date: DateTime!) {
    doctorMonthlyIncentive(doctorId: $doctorId, date: $date)
  }
`
