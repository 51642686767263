import './style.css'

import { DownOutlined } from '@ant-design/icons'
import BreadCrumb from '@medical/components/Breadcrumb'
import { HOURLY_WAGE_LIST } from '@medical/constant'
import { useCustom } from '@medical/provider/context'
import {
  addItem,
  removeItem,
} from '@medical/provider/store/reducers/cart/cart.action'
import Router from '@medical/routes/router'
import { Button, Dropdown } from 'antd'
import { Formik } from 'formik'
import moment from 'moment'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { connect } from 'react-redux'
import ClinicList from '../AvailableShift/AvailableShiftComponents/ClinicList'
import HourlyWageList from '../AvailableShift/AvailableShiftComponents/HourlyWageList'
import MultipleDatePicker from '../AvailableShift/AvailableShiftComponents/MultipleDatePicker'
import SpecifyTime from '../AvailableShift/AvailableShiftComponents/SpecifyTime'
import WorkContent from '../AvailableShift/AvailableShiftComponents/WorkContent'
import FindAvailableShiftMobile from './FindAvailableShiftMobile'
import TableResult from './TableResult'

const FindAvailableShiftSence = ({
  tableData,
  idClinic,
  dateRange,
  weeks,
  clinics,
  departments,
  startTime,
  endTime,
  morningShift,
  eveningShift,
  nightShift,
  isSpecialHourlyWage,
  hourlyWage,
  clinicList,
  count,
  onSubmit,
  PaginationProps,
  addItemToCart,
  removeItemFromCart,
  cartItems,
  history,
  search,
  page,
  checkWorkScheduleExits,
  listRegionData
}) => {
  const [calendarVisible, setCalendarVisible] = useState(false)
  const [clinicVisible, setClinicVisible] = useState(false)
  const [timeVisible, setTimeVisible] = useState(false)
  const [otherVisible, setOtherVisible] = useState(false)
  const [workContentVisible, setWorkContentVisible] = useState(false)
  const [chosenType, setchosenType] = useState(
    startTime != null || endTime != null
      ? 'specifyTime'
      : morningShift === true || eveningShift === true || nightShift === true
      ? 'specifyShift'
      : undefined
  )
  const timeCount = morningShift + eveningShift + nightShift
  const countTime = !!endTime + !!startTime + timeCount

  const [{ i18n, popup }] = useCustom()

  const [newDateRange, setNewDateRange] = useState(dateRange)
  const [newWeeks, setNewWeeks] = useState(weeks)
  const handleChangeRange = value => {
    setNewDateRange(value)
  }
  const handleChecked = weeks => {
    setNewWeeks(weeks)
  }
  const clearDate = () => {
    const dateArray = Object.getOwnPropertyNames(newDateRange)
    dateArray.forEach(key => {
      delete newDateRange[key]
    })
  }

  const calendarVisibleChange = flag => {
    setCalendarVisible(flag)
  }
  const clinicVisibleChange = flag => {
    setClinicVisible(flag)
  }
  const timeVisibleChange = flag => {
    setTimeVisible(flag)
  }
  const otherVisibleChange = flag => {
    setOtherVisible(flag)
  }
  const workContentVisibleChange = flag => {
    setWorkContentVisible(flag)
  }
  const specialClinicId = clinicList
    .filter(item => item.node.name.includes('職域新型コロナワクチン接種'))
    .map(({ node }) => node.id)
  let countClinics = clinics.length
  let countDepartments = departments.length
  if (clinics.length >= 1 && clinics[0] === '') {
    countClinics -= 1
  }
  if (
    specialClinicId.length > 1 &&
    clinics.filter(item => specialClinicId.includes(item)).length > 0
  ) {
    countClinics -= 1
  }

  if (
    departments.indexOf('CHILDREN_VACCINE_MEDICINE') !== -1 &&
    departments.indexOf('INTERNAL_VACCINE_MEDICINE') !== -1 &&
    departments.length > 0
  ) {
    countDepartments -= 1
  }

  const handleChosenType = type => {
    setchosenType(type)
  }
  if (isMobile)
    return (
      <>
        <FindAvailableShiftMobile
          page={page}
          history={history}
          countClinics={countClinics}
          search={search}
          tableData={tableData}
          idClinic={idClinic}
          dateRange={dateRange}
          weeks={weeks}
          clinics={Array.isArray(clinics) ? clinics : [clinics]}
          listRegionData={listRegionData}
          departments={departments}
          startTime={moment(startTime, 'HH:mm')}
          endTime={moment(endTime, 'HH:mm')}
          morningShift={morningShift}
          eveningShift={eveningShift}
          nightShift={nightShift}
          isSpecialHourlyWage={isSpecialHourlyWage}
          hourlyWage={hourlyWage}
          count={count}
          clinicList={clinicList}
          PaginationProps={PaginationProps}
          onSubmit={onSubmit}
          cartItems={cartItems}
          chosenType={chosenType}
          handleChosenType={handleChosenType}
          addItemToCart={item => {
            if (checkWorkScheduleExits(item)) {
              popup.error('同日時で確定シフトがあるためカートに追加できません')
            } else if (moment(item.startTime).isAfter(moment())) {
              popup.success('募集シフトをカートに追加しました')
              addItemToCart(item)
            } else
              popup.error('このシフトの開始時間が過ぎましたので応募できません')
          }}
          removeItemFromCart={item => {
            popup.warn('募集シフトをカートから削除しました')
            removeItemFromCart(item)
          }}
        />
      </>
    )

  return (
    <div className='doctor-find-shift'>
      <div className='find-shift-title'>
        <div className='staff-title'>
          {i18n.t('doctor.menuBar.availableShifts')}
          <BreadCrumb
            items={[
              {
                to: Router.get(Router.doctorAvailableShiftsWithoutYearMonth),
                title: 'シフトを探す',
              },
              { title: i18n.t('main.availableShiftList') },
            ]}
          />
        </div>
        <Formik
          initialValues={{
            dateRange,
            weeks,
            clinics,
            startTime: moment(startTime, 'HH:mm'),
            endTime: moment(endTime, 'HH:mm'),
            morningShift,
            eveningShift,
            nightShift,
            isSpecialHourlyWage,
            hourlyWage: HOURLY_WAGE_LIST.find(
              item => item.value === hourlyWage
            ),
            hourlyWageList: HOURLY_WAGE_LIST,
            departments,
          }}
          onSubmit={onSubmit}
          render={formikProps => {
            const { setFieldValue, handleSubmit } = formikProps

            return (
              <div className='search-item-wrapper'>
                {/* 期間 */}
                <Dropdown
                  visible={calendarVisible}
                  onVisibleChange={calendarVisibleChange}
                  trigger={['click']}
                  overlay={
                    <div className='dropdown-search-feature'>
                      <MultipleDatePicker
                        {...formikProps}
                        field='dateRange'
                        locale='ja'
                        ranges={newDateRange}
                        handleChangeRange={handleChangeRange}
                        handleChecked={handleChecked}
                        weeks={newWeeks}
                        months={1}
                        minDate={new Date()}
                        maxDate={
                          new Date(
                            moment()
                              .add(12, 'months')
                              .calendar()
                          )
                        }
                      />
                      <div className='space-dropdown' />
                      <div className='submit-search-wapper'>
                        <button
                          type='submit'
                          className='clear-search-btn'
                          onClick={async () => {
                            await setCalendarVisible(false)
                            await setFieldValue('dateRange', {})
                            await clearDate()
                            await handleSubmit()
                          }}
                        >
                          クリア
                        </button>
                        <button
                          className='submit-search-btn'
                          type='submit'
                          onClick={() => {
                            setCalendarVisible(false)
                            handleSubmit()
                          }}
                        >
                          適用する
                        </button>
                      </div>
                    </div>
                  }
                >
                  <Button className='search-item-btn calendar-btn' type='link'>
                    期間
                    {Object.keys(newDateRange).length > 0 ? (
                      <span className='p-search-selected'>
                        {Object.keys(newDateRange).length}
                      </span>
                    ) : null}
                    <DownOutlined style={{ color: 'var(--primary)' }} />
                  </Button>
                </Dropdown>
                {/* クリニック */}
                <Dropdown
                  trigger={['click']}
                  visible={clinicVisible}
                  onVisibleChange={clinicVisibleChange}
                  overlay={
                    <div className='dropdown-search-feature'>
                      <ClinicList {...formikProps} clinics={clinicList} listRegionData={listRegionData} />
                      <div className='space-dropdown' />
                      <div className='submit-search-wapper'>
                        <button
                          className='clear-search-btn'
                          onClick={async () => {
                            await setClinicVisible(false)
                            await setFieldValue('clinics', [])
                            await handleSubmit()
                          }}
                        >
                          クリア
                        </button>
                        <button
                          onClick={() => {
                            setClinicVisible(false)
                            handleSubmit()
                          }}
                          className='submit-search-btn'
                        >
                          適用する
                        </button>
                      </div>
                    </div>
                  }
                >
                  <Button className='search-item-btn clinic-btn' type='link'>
                    クリニック
                    {countClinics > 0 ? (
                      <span className='p-search-selected'>{countClinics}</span>
                    ) : null}
                    <DownOutlined style={{ color: 'var(--primary)' }} />
                  </Button>
                </Dropdown>
                {/* 勤務内容を指定 */}
                <Dropdown
                  trigger={['click']}
                  visible={workContentVisible}
                  onVisibleChange={workContentVisibleChange}
                  overlay={
                    <div className='dropdown-search-feature other-open'>
                      <WorkContent {...formikProps} i18n={i18n} />
                      <div className='space-dropdown' />
                      <div className='submit-search-wapper'>
                        <button
                          className='clear-search-btn'
                          onClick={async () => {
                            setWorkContentVisible(false)
                            await setFieldValue('departments', [])
                            await handleSubmit()
                          }}
                        >
                          クリア
                        </button>
                        <button
                          type='submit'
                          className='submit-search-btn'
                          onClick={() => {
                            setWorkContentVisible(false)
                            handleSubmit()
                          }}
                        >
                          適用する
                        </button>
                      </div>
                    </div>
                  }
                >
                  <Button className='search-item-btn other-btn' type='link'>
                    勤務内容を指定
                    {countDepartments > 0 ? (
                      <span className='p-search-selected'>
                        {countDepartments}
                      </span>
                    ) : null}
                    <DownOutlined style={{ color: 'var(--primary)' }} />
                  </Button>
                </Dropdown>
                {/* 勤務時間 */}
                <Dropdown
                  trigger={['click']}
                  visible={timeVisible}
                  onVisibleChange={timeVisibleChange}
                  overlay={
                    <div className='dropdown-search-feature time-open'>
                      <SpecifyTime
                        {...formikProps}
                        i18n={i18n}
                        chosenType={chosenType}
                        handleChosenType={handleChosenType}
                      />
                      <div className='space-dropdown' />
                      <div className='submit-search-wapper'>
                        <button
                          className='clear-search-btn'
                          onClick={async () => {
                            await setTimeVisible(false)
                            await setFieldValue('morningShift', false)
                            await setFieldValue('eveningShift', false)
                            await setFieldValue('nightShift', false)
                            await setFieldValue('startTime', null)
                            await setFieldValue('endTime', null)
                            await setchosenType(undefined)
                            await handleSubmit()
                          }}
                        >
                          クリア
                        </button>
                        <button
                          type='submit'
                          className='submit-search-btn'
                          onClick={() => {
                            setTimeVisible(false)
                            handleSubmit()
                          }}
                        >
                          適用する
                        </button>
                      </div>
                    </div>
                  }
                >
                  <Button className='search-item-btn time-btn' type='link'>
                    勤務時間
                    {timeCount || countTime > 0 ? (
                      <span className='p-search-selected'>{countTime}</span>
                    ) : null}
                    <DownOutlined style={{ color: 'var(--primary)' }} />
                  </Button>
                </Dropdown>
                {/* 条件 */}
                <Dropdown
                  trigger={['click']}
                  visible={otherVisible}
                  onVisibleChange={otherVisibleChange}
                  overlay={
                    <div className='dropdown-search-feature other-open'>
                      <HourlyWageList
                        {...formikProps}
                        i18n={i18n}
                        hourlyWageList={HOURLY_WAGE_LIST}
                        placeholder={i18n.t(
                          'doctor.availableShifts.undefinedHourlyWage'
                        )}
                      />
                      <div className='space-dropdown' />
                      <div className='submit-search-wapper'>
                        <button
                          className='clear-search-btn'
                          onClick={async () => {
                            setOtherVisible(false)
                            await setFieldValue('hourlyWage', null)
                            await setFieldValue('isSpecialHourlyWage', null)
                            await handleSubmit()
                          }}
                        >
                          クリア
                        </button>
                        <button
                          type='submit'
                          className='submit-search-btn'
                          onClick={() => {
                            setOtherVisible(false)
                            handleSubmit()
                          }}
                        >
                          適用する
                        </button>
                      </div>
                    </div>
                  }
                >
                  <Button className='search-item-btn other-btn' type='link'>
                    条件
                    <DownOutlined style={{ color: 'var(--primary)' }} />
                  </Button>
                </Dropdown>
              </div>
            )
          }}
        />
        <div className='search-result-table' />
      </div>
      <div className='note-wrapper'>
        <div className='note-item'>
          <span style={{ padding: '0px 17px' }}>New</span>
          前回ログインから新たに募集開始したシフト
        </div>
        <div className='note-item'>
          <span style={{ backgroundColor: 'var(--yellow)' }}>Update</span>
          前回ログインから新たに情報更新したシフト
        </div>
      </div>
      <TableResult
        hourlyWage={hourlyWage}
        history={history}
        search={search}
        i18n={i18n}
        tableData={tableData}
        idClinic={idClinic}
        PaginationProps={PaginationProps}
        cartItems={cartItems}
        addItemToCart={item => {
          if (checkWorkScheduleExits(item)) {
            popup.error('同日時で確定シフトがあるためカートに追加できません')
          } else if (moment(item.startTime).isAfter(moment())) {
            popup.success('募集シフトをカートに追加しました')
            addItemToCart(item)
          } else
            popup.error('このシフトの開始時間が過ぎましたので応募できません')
        }}
        removeItemFromCart={item => {
          popup.warn('募集シフトをカートから削除しました')
          removeItemFromCart(item)
        }}
        page={page}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  cartItems: state.cartStore.items,
})

const mapDispatchToProps = dispatch => ({
  addItemToCart: item => dispatch(addItem(item)),
  removeItemFromCart: availableShiftId =>
    dispatch(removeItem(availableShiftId)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FindAvailableShiftSence)
