import gql from 'graphql-tag'

export const DOCTOR_WORK_SCHEDULES = gql`
  query DOCTOR_WORK_SCHEDULES(
    $where: DoctorWhereUniqueInput!
    $workSchedulesWhere2: WorkScheduleWhereInput
  ) {
    doctor(where: $where) {
      workSchedules(where: $workSchedulesWhere2) {
        id
        startTime
        endTime
      }
    }
  }
`
