import gql from 'graphql-tag'

export const GET_PROFILE_MYNUMBER = gql`
  query DOCTOR_ME {
    doctorMe {
      id
      email
      fullName
      birthday
      doctorIdentity {
        id
        identityNumber
        frontSideIdentityKey
        backSideIdentityKey
        frontSideDriverLicenseKey
        backSideDriverLicenseKey
        doctorIdentityRelation {
          id
          name
          birthday
          relation
          identityNumber
          updatedAt
          deletedAt
        }
      }
    }
  }
`

export const CREATE_UPDATE_DOCTOR_IDENTITY = gql`
  mutation DOCTOR_UPSERT_IDENTITY_NUMBER(
    $isCreateAction: Boolean!
    $identityNumber: String!
    $frontSideIdentityKey: String
    $backSideIdentityKey: String
    $frontSideLicenseKey: String
    $backSideLicenseKey: String
    $doctorIdentityRelations: [DoctorIdentityRelationForUpsert!]
  ) {
    doctorUpsertIdentity(
      isCreateAction: $isCreateAction
      identityNumber: $identityNumber
      frontSideIdentityKey: $frontSideIdentityKey
      backSideIdentityKey: $backSideIdentityKey
      frontSideLicenseKey: $frontSideLicenseKey
      backSideLicenseKey: $backSideLicenseKey
      doctorIdentityRelations: $doctorIdentityRelations
    ) {
      id
    }
  }
`
