import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { useCustom } from '@medical/provider/context'
import React from 'react'

import {
  DOCTOR_CHANGE_EMAIL,
  DOCTOR_UPDATE_PROFILE,
  DOCTOR_UPDATE_PROFILE_QUERY,
} from './UpdateProfile.graphql'
import UpdateProfileScene from './UpdateProfileScene'

const UpdateProfile = ({ closeModal }) => {
  const [{ i18n, popup }] = useCustom()
  const { loading, error, data } = useQuery(DOCTOR_UPDATE_PROFILE_QUERY)
  const [doctorUpdateProfile] = useMutation(DOCTOR_UPDATE_PROFILE)

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const onSubmit = async (values, { setSubmitting, setFieldValue }) => {
    let {
      firstname,
      lastname,
      firstnameKana,
      lastnameKana,
      gender,
      birthday,
      medicalLicenseNumber,
      departments,
      phoneNumberFirst,
      phoneNumberSecond,
      phoneNumberThird,
      homeAddress: {
        stateOrRegion,
        address1,
        address2,
        zipCodeFirst,
        zipCodeSecond,
      },
      nearestStations,
      placeOfWork,
      graduationUniversity,
      graduationUniversityYear,
      obtainDoctorLicenseYear,
      professionalDescription,
      isJoinedSublingualImmunotherapyELearning,
      isJoinedOnlineDiagnosticTraining,
      isIntroduce,
      introducedBy,
      careerHistories,
      skillSet,
      province,
      isCreateTransportationCost,
      insuranceDoctorNumber,
      insuranceDoctorSymbol,
      insuranceDoctorType,
    } = values
    try {
      const isUpdateAddress =
        data.doctorMe.homeAddress.address1 !== address1 ||
        data.doctorMe.homeAddress.address2 !== address2 ||
        data.doctorMe.homeAddress.stateOrRegion !== stateOrRegion
      for (let i = 0; i < careerHistories.length; i += 1) {
        delete careerHistories[i].__typename
      }
      if (Array.isArray(isJoinedOnlineDiagnosticTraining)) {
        isJoinedOnlineDiagnosticTraining = isJoinedOnlineDiagnosticTraining[0]
      }
      if (Array.isArray(isJoinedSublingualImmunotherapyELearning)) {
        isJoinedSublingualImmunotherapyELearning =
          isJoinedSublingualImmunotherapyELearning[0]
      }
      isJoinedOnlineDiagnosticTraining =
        isJoinedOnlineDiagnosticTraining === 'true'
      isJoinedSublingualImmunotherapyELearning =
        isJoinedSublingualImmunotherapyELearning === 'true'
      if (Array.isArray(isIntroduce)) {
        isIntroduce = isIntroduce[0]
      }
      if (isIntroduce === '0') {
        introducedBy = ''
      }
      await doctorUpdateProfile({
        variables: {
          firstname,
          lastname,
          firstnameKana,
          lastnameKana,
          gender,
          birthday,
          medicalLicenseNumber,
          departments,
          phoneNumber: `${phoneNumberFirst}${phoneNumberSecond}${phoneNumberThird}`,
          stateOrRegion,
          address1,
          address2,
          zipCode: `${zipCodeFirst}${zipCodeSecond}`,
          nearestStations,
          placeOfWork,
          graduationUniversity,
          graduationUniversityYear,
          obtainDoctorLicenseYear,
          professionalDescription,
          isJoinedSublingualImmunotherapyELearning,
          isJoinedOnlineDiagnosticTraining,
          introducedBy,
          careerHistories,
          skillSet,
          province: province,
          isUpdateAddress,
          isCreateTransportationCost,
          insuranceDoctorNumber,
          insuranceDoctorSymbol,
          insuranceDoctorType,
        },
      })
      popup.success(i18n.t('updateProfile.submissionSuccess'))
      setSubmitting(false)
      closeModal()
    } catch (error) {
      popup.error(error)
      setFieldValue('confirm', false)
      setSubmitting(false)
    }
  }

  return (
    <UpdateProfileScene
      i18n={i18n}
      closeModal={closeModal}
      genders={data.genders}
      insuranceDoctorTypes={data.insuranceDoctorTypes}
      departments={data.departments}
      doctor={data.doctorMe}
      onSubmit={onSubmit}
      isHiddenDoctorNo
    />
  )
}

export default UpdateProfile
