import React from 'react'

const StatusRender = ({ val }) =>
  val ? (
    <span className='status-label is-success'>確定</span>
  ) : (
    <span className='status-label is-warning'>応募</span>
  )

export default StatusRender
