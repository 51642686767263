import Router from '@medical/routes/router'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'

export const renderDetails = ({ i18n, doctorId, activity, details = '' }) => {
  const parsedDetail = JSON.parse(details)

  const jumpToDoctor = (
    <Link
      to={`${Router.staffDoctors}${Router.get(Router.staffDoctorDetail, {
        doctorId,
      })}`}
    >
      {i18n.t('staff.activityLogs.jumpToDoctor')}
    </Link>
  )

  switch (activity) {
    case 'UPDATED_PROFILE':
      return (
        <div>
          {Object.keys(parsedDetail).map(key => (
            <div style={{ paddingBottom: '10px', fontSize: '14px' }}>
              {i18n.t(`updateProfile.${key}`)}: {parsedDetail[key].oldValue}{' '}
              <i className='pi pi-arrow-right' style={{ margin: '0 10px' }} />{' '}
              {parsedDetail[key].newValue}
            </div>
          ))}
          {jumpToDoctor}
        </div>
      )
    case 'UPDATED_BANK':
      return (
        <div>
          {Object.keys(parsedDetail).map(key => (
            <div style={{ paddingBottom: '10px', fontSize: '14px' }}>
              {i18n.t(`doctor.bankAccount.${key}`)}:{' '}
              {parsedDetail[key].oldValue}{' '}
              <i className='pi pi-arrow-right' style={{ margin: '0 10px' }} />{' '}
              {parsedDetail[key].newValue}
            </div>
          ))}
          <Link
            to={`${Router.staffDoctors}${Router.get(Router.staffDoctorDetail, {
              doctorId,
            })}`}
          >
            {i18n.t('staff.activityLogs.jumpToDoctor')}
          </Link>
        </div>
      )
    case 'CREATED_WORK_SCHEDULE': {
      const startTime = moment(parsedDetail.startTime)
      return (
        <div>
          <Link
            to={`${Router.get(Router.staffDailyAvailableShifts, {
              year: startTime.year(),
              month: startTime.month() + 1,
              day: startTime.date(),
            })}${Router.get(Router.staffDetailWorkSchedule, {
              workScheduleId: parsedDetail.id,
            })}`}
          >
            {i18n.t('staff.activityLogs.jumpToWorkSchedule')}
          </Link>
        </div>
      )
    }
    case 'UPDATED_FILE':
      return <div>{jumpToDoctor}</div>
    case 'CREATED_INQUIRY':
      return (
        <div style={{ paddingBottom: '10px', fontSize: '14px' }}>
          <Link
            to={`${Router.staffInquiries}${Router.get(
              Router.staffInquiryDetail,
              {
                inquiryId: parsedDetail,
              }
            )}`}
          >
            {i18n.t('staff.activityLogs.jumpToInquiry')}
          </Link>
        </div>
      )
    default:
      return null
  }
}
