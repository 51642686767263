/* eslint-disable no-nested-ternary */
import { ReactComponent as Delete } from '@medical/assets/image/delete.svg'
import { ReactComponent as Revival } from '@medical/assets/image/revival.svg'
import BreadCrumb from '@medical/components/Breadcrumb'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import ModalLink from '@medical/components/ModalLink'
import { ACTION_TYPE } from '@medical/constant'
import types from '@medical/constant/typeTransports'
import numeralCurrency from '@medical/libs/numeralCurrency'
import MovingExpensesDetailHeader from '@medical/pages/staff/MovingExpenses/ListMovingExpenses/MovingExpensesDetailHeader'
import Router from '@medical/routes/router'
import { Select } from 'antd'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import React from 'react'

const MovingExpensesDetailScene = ({
  i18n,
  date,
  movingExpenses,
  doctorId,
  onSubmit,
  fullName,
  totalDays,
  totalCost,
  listDuplicates,
  numberIncentive,
  isBlockedMyCar,
}) => {
  const { Option } = Select
  const getType = val => {
    let rs
    types.map(as => {
      if (as.value == val) {
        rs = as.description
      }
    })
    return rs
  }
  const haveUpdate = movingExpenses.some(item => !item.startTime)
  return (
    <div>
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('main.movingExpenses.title')}
          <BreadCrumb
            items={[{ title: i18n.t('main.movingExpenses.title') }]}
          />
        </div>
      </div>
      <div className='box'>
        <Formik
          enableReinitialize
          initialValues={{
            confirm: false,
            doctorId: '',
            functionName: '',
            isMyCar: '',
            transportationsId: [],
            workScheduleId: '',
          }}
          onSubmit={onSubmit}
          render={formikProps => {
            const { values, setFieldValue } = formikProps
            let confirmationMessage
            switch (values.functionName) {
              case ACTION_TYPE.DELETE:
                confirmationMessage = i18n.t(
                  'main.movingExpenses.deleteConfirmation'
                )
                break

              case ACTION_TYPE.RESTORE_DELETE:
                confirmationMessage = i18n.t(
                  'main.movingExpenses.restoreDeletedConfirmation'
                )
                break
              case 'UPDATE_TYPE':
                confirmationMessage = i18n.t('main.movingExpenses.updateType')
                break
              default:
                break
            }
            const isWorkschedule = (
              startTime,
              isTransport,
              deletedAt,
              id,
              notApplyCostTransportation
            ) => {
              if (isTransport) {
                return (
                  <Button
                    icon='a'
                    className='payment-confirmation'
                    style={{ border: 'none', display: 'inline-flex' }}
                    onClick={() => {
                      setFieldValue('transportationsId', [id])
                      setFieldValue('confirm', true)
                      const action =
                        !deletedAt || deletedAt.length === 0
                          ? ACTION_TYPE.DELETE
                          : ACTION_TYPE.RESTORE_DELETE
                      setFieldValue('functionName', action)
                      setFieldValue('startTime', startTime)
                    }}
                  >
                    {!deletedAt || deletedAt.length === 0 ? (
                      <Revival className='icon-btn-moving' />
                    ) : (
                      <Delete className='icon-btn-moving' />
                    )}
                  </Button>
                )
              }
              return (
                <Button
                  className='payment-confirmation'
                  icon='a'
                  onClick={() => {
                    setFieldValue('workScheduleId', id)
                    setFieldValue('confirm', true)
                    const action = !notApplyCostTransportation
                      ? ACTION_TYPE.DELETE
                      : ACTION_TYPE.RESTORE_DELETE
                    setFieldValue('functionName', action)
                    setFieldValue('startTime', startTime)
                  }}
                >
                  {notApplyCostTransportation === null ||
                  !notApplyCostTransportation ? (
                    <Revival className='icon-btn-moving' />
                  ) : (
                    <Delete className='icon-btn-moving' />
                  )}
                </Button>
              )
            }
            return (
              <div className='schedule-calendar'>
                <div className='tr'>
                  <div className='th'>
                    {`${i18n.t('staff.movingExpensesDetail.doctorName')}:`}
                    <span style={{ marginLeft: '10px' }}>{fullName}</span>
                  </div>
                </div>

                <div className='calendar-container'>
                  <div style={{ paddingTop: '50px' }}>
                    <div className='overflow-container'>
                      <table className='table' style={{ marginBottom: '10px' }}>
                        <thead>
                          <tr>
                            <th width='16%'>
                              {i18n.t('staff.movingExpensesList.workingDay')}
                            </th>
                            <th width='16%'>
                              {i18n.t('staff.movingExpensesList.applyingDay')}
                            </th>
                            <th width='10%'>
                              {i18n.t('staff.movingExpensesList.clinicName')}
                            </th>
                            <th width='16%'>
                              {i18n.t(
                                'staff.movingExpensesList.departmentName'
                              )}
                            </th>
                            <th width='10%'>
                              {i18n.t(
                                'staff.movingExpensesList.typeApplication'
                              )}
                            </th>
                            <th width='8%'>
                              {i18n.t(
                                'staff.movingExpensesList.ticketPriceOneWay'
                              )}
                            </th>
                            <th width='8%'>
                              {i18n.t(
                                'staff.movingExpensesList.ticketPriceRoundTrip'
                              )}
                            </th>
                            <th width='8%'>
                              {i18n.t('staff.movingExpensesList.registerPrice')}
                            </th>
                            {haveUpdate ? <th /> : null}
                            <th width='8%'>
                              {i18n.t(
                                'staff.movingExpensesList.confirmPayment'
                              )}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {movingExpenses && movingExpenses.length > 0 ? (
                            movingExpenses.map((movingExpense, index) => (
                              <tr
                                key={index}
                                className={
                                  movingExpense.deletedAt ||
                                  movingExpense.notApplyCostTransportation
                                    ? 'background-grey'
                                    : listDuplicates.includes(index)
                                    ? 'background-pink'
                                    : null
                                }
                              >
                                <td className='center'>
                                  <span>
                                    {!movingExpense.isTransport ||
                                    (movingExpense.type &&
                                      !movingExpense.isStaffCreate)
                                      ? moment(movingExpense.startTime).format(
                                          'LL (dd)'
                                        )
                                      : !movingExpense.startTime
                                      ? moment(movingExpense.endTime).format(
                                          'LL (dd)'
                                        )
                                      : null}
                                  </span>
                                  <p>
                                    {!movingExpense.isTransport
                                      ? null
                                      : !movingExpense.startTime
                                      ? '(事務局入力)'
                                      : null}
                                  </p>
                                </td>
                                <td className='center'>
                                  <span>
                                    {movingExpense.isTransport &&
                                    !movingExpense.departmentID
                                      ? moment(movingExpense.createdAt).format(
                                          'LL (dd)'
                                        )
                                      : !movingExpense.startTime
                                      ? moment(movingExpense.createdAt)
                                          .utc()
                                          .format('LL (dd)')
                                      : movingExpense.category ==
                                        'RECURRING_TRAVEL_FEE'
                                      ? moment(movingExpense.createdAt)
                                          .utc()
                                          .format('LL (dd)')
                                      : null}
                                  </span>
                                  <p>
                                    {!movingExpense.isTransport
                                      ? null
                                      : !movingExpense.startTime
                                      ? '(事務局入力)'
                                      : null}
                                  </p>
                                </td>
                                <td className='center'>
                                  {movingExpense.category
                                    ? movingExpense.clinicDate
                                    : movingExpense.clinic}
                                  {movingExpense.costTransportationByDoctorWorkSchedule >
                                    0 &&
                                    !movingExpense.isTypeTransportation && (
                                      <>
                                        <div className='border-top' />
                                        {movingExpense.clinic}
                                      </>
                                    )}
                                </td>

                                <td className='center'>
                                  <span>
                                    {movingExpense.clinicalDepartment}
                                  </span>
                                  {movingExpense.costTransportationByDoctorWorkSchedule >
                                    0 && !movingExpense.isTypeTransportation ? (
                                    <>
                                      <div className='border-top' />
                                      {movingExpense.clinicalDepartment}
                                    </>
                                  ) : null}
                                </td>
                                <td className='center'>
                                  <span>
                                    {movingExpense.isTransport ? (
                                      movingExpense.type
                                    ) : movingExpense.category ==
                                      'RECURRING_TRAVEL_FEE' ? (
                                      getType(movingExpense.type)
                                    ) : movingExpense.isShowSelectType ? (
                                      <Select
                                        disabled={
                                          movingExpense.deletedAt ||
                                          movingExpense.notApplyCostTransportation
                                        }
                                        value={
                                          movingExpense.isTypeTransportation ===
                                          true
                                            ? '1'
                                            : '0'
                                        }
                                        style={{ width: 120 }}
                                        onChange={async e => {
                                          setFieldValue(
                                            'workScheduleId',
                                            movingExpense.id
                                          )
                                          setFieldValue('isMyCar', e !== '0')
                                          setFieldValue('confirm', true)
                                          setFieldValue(
                                            'functionName',
                                            'UPDATE_TYPE'
                                          )
                                        }}
                                      >
                                        <Option value='0'>公共交通機関</Option>
                                        <Option
                                          disabled={!isBlockedMyCar}
                                          value='1'
                                        >
                                          マイカー利用
                                        </Option>
                                      </Select>
                                    ) : null}
                                  </span>
                                </td>
                                <td className='center'>
                                  <span>
                                    {movingExpense &&
                                    movingExpense.category ==
                                      'RECURRING_TRAVEL_FEE'
                                      ? null
                                      : !movingExpense.isTransport
                                      ? numeralCurrency(movingExpense.costWs)
                                      : null}
                                    {movingExpense.departmentID
                                      ? movingExpense.isStaffCreate
                                        ? null
                                        : numeralCurrency(movingExpense.cost)
                                      : null}
                                    {movingExpense.costTransportationByDoctorWorkSchedule >
                                      0 &&
                                    !movingExpense.isTypeTransportation ? (
                                      <>
                                        <div className='border-top' />
                                        {numeralCurrency(
                                          movingExpense.costTransportationByDoctorWorkSchedule
                                        )}
                                      </>
                                    ) : null}
                                  </span>
                                </td>
                                <td className='center'>
                                  <span>
                                    {movingExpense &&
                                    movingExpense.category ==
                                      'RECURRING_TRAVEL_FEE'
                                      ? null
                                      : !movingExpense.isTransport
                                      ? numeralCurrency(
                                          parseInt(
                                            movingExpense.costTransportationByDoctorWorkSchedule >
                                              0 &&
                                              !movingExpense.isTypeTransportation
                                              ? movingExpense.costTransportationByDoctorWorkSchedule
                                              : movingExpense.costWs
                                          ) + movingExpense.costWs
                                        )
                                      : null}

                                    {movingExpense.departmentID
                                      ? numeralCurrency(movingExpense.cost)
                                      : null}
                                  </span>
                                </td>
                                <td className='center'>
                                  <span>
                                    {movingExpense.isTransport &&
                                    !movingExpense.departmentID
                                      ? numeralCurrency(movingExpense.cost)
                                      : null}
                                  </span>
                                </td>
                                {!movingExpense.startTime ? (
                                  <td className='center'>
                                    <ModalLink
                                      to={Router.get(
                                        Router.staffDoctorsMovingExpensesUpdate,
                                        {
                                          transportationId: movingExpense.id,
                                        }
                                      )}
                                    >
                                      <Button icon='pi pi-pencil' />
                                    </ModalLink>
                                  </td>
                                ) : haveUpdate ? (
                                  <td />
                                ) : null}

                                <td className='center'>
                                  {isWorkschedule(
                                    movingExpense.startTime,
                                    movingExpense.isTransport,
                                    movingExpense.deletedAt,
                                    movingExpense.id,
                                    movingExpense.notApplyCostTransportation
                                  )}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={9} className='center'>
                                {i18n.t('main.movingExpenses.noData')}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <table style={{ width: '100%' }}>
                      <tbody>
                        <tr>
                          <th style={{ textAlign: 'left', width: '7%' }}>
                            {`${i18n.t(
                              'staff.movingExpensesList.totalDayWorking'
                            )}`}
                          </th>
                          <td style={{ paddingTop: '13px' }}>
                            <span style={{ float: 'left' }}>{totalDays}日</span>
                            <div className='modal-bottom-right'>
                              <ModalLink
                                to={Router.get(
                                  Router.staffDoctorsMovingExpensesCreate
                                )}
                              >
                                <Button
                                  label={`${i18n.t('main.add')}する`}
                                  className='reflect-button'
                                />
                              </ModalLink>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th style={{ textAlign: 'left' }}>
                            {`${i18n.t(
                              'staff.movingExpensesList.numberOfApplication'
                            )}`}
                          </th>
                          <td>
                            {numberIncentive}
                            {i18n.t('main.movingExpenses.movingExpenseUnit')}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ textAlign: 'left' }}>
                            {`${i18n.t(
                              'staff.movingExpensesList.totalExpenses'
                            )}`}
                          </th>
                          <td>{numeralCurrency(totalCost)}</td>
                        </tr>
                      </tbody>
                    </table>
                    <ConfirmationModal
                      {...formikProps}
                      confirmationMessage={confirmationMessage}
                    />
                  </div>
                  <MovingExpensesDetailHeader date={date} doctorId={doctorId} />
                </div>
              </div>
            )
          }}
        />
      </div>
    </div>
  )
}
export default MovingExpensesDetailScene
