import BreadCrumb from '@medical/components/Breadcrumb'
import ModalLink from '@medical/components/ModalLink'
import Router from '@medical/routes/router'
import { DatePicker } from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { Paginator } from 'primereact/paginator'
import queryString from 'query-string'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Auth from '@medical/middleware/auth'
import Axios from 'axios'
import { waitFor } from '@medical/libs'
import { DOWNLOAD_CSV_LIST_MOVING_EXPENSES } from '@medical/constant'
import { useEffect } from 'react'

const ListMovingExpensesOtherScene = ({
  isUpdateMovingExpenses,
  arrStatus,
  page,
  onPageChange,
  rowsPerPage,
  count,
  i18n,
  list,
  fullname,
  dates,
  status,
  startAt,
  endAt,
  startTime,
  startTimeGte,
  startTimeLte,
  socket,
  progress,
  setProgress,
  removeProgress,
}) => {
  const getStatusFillter = name => {
    if (name) {
      return {
        value: name,
        description: i18n.t(`main.movingExpensesOther.${name}`),
      }
    }
  }
  const [date, setDate] = useState(dates || ['', ''])
  const [startTimeFilter, setStartTime] = useState(startTime || ['', ''])
  const [statusFilter, setStatusFillter] = useState(
    getStatusFillter(status) || ''
  )
  const [nameFilter, setNameFilter] = useState(fullname || '')

  const GET_OPTIONS = {
    WAITING_FOR_APPROVAL: '',
    REJECTED: '',
    ACCEPTED: '',
  }
  const getOptions = () => {
    const result = []
    Object.keys(GET_OPTIONS).forEach(key => {
      result.push({
        value: key,
        description: i18n.t(`main.movingExpensesOther.${key}`),
      })
    })
    return result
  }

  const getTypeTd = statusValue => {
    if (statusValue === 'WAITING_FOR_APPROVAL') {
      return 'table-moving-blue'
    }
    if (statusValue === 'REJECTED') {
      return 'table-moving-red'
    }
    return ''
  }

  const getParams = (
    fname,
    sTime,
    eTime,
    statusParam,
    startTimeGte,
    startTimeLte
  ) => {
    const params = {}
    if (fname) params.fullname = fname
    if (sTime) params.startAt = sTime
    if (eTime) params.endAt = eTime
    if (statusParam) params.status = statusParam.value
    if (startTimeGte) params.startTimeGte = startTimeGte
    if (startTimeLte) params.startTimeLte = startTimeLte
    return params
  }
  const hasProgress = Object.keys(progress).includes(
    DOWNLOAD_CSV_LIST_MOVING_EXPENSES
  )
  useEffect(() => {
    if (socket && hasProgress) {
      socket.on(DOWNLOAD_CSV_LIST_MOVING_EXPENSES, data => {
        if (data?.percent) {
          setProgress({
            progress: DOWNLOAD_CSV_LIST_MOVING_EXPENSES,
            label: `${i18n.t('main.movingExpensesOther.title')}中`,
            percent: Number(data.percent),
          })
        }
      })
    }
  }, [socket, hasProgress])

  return (
    <div
      style={{
        padding: '10px',
        height: '100%',
      }}
    >
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('main.movingExpensesOther.title')}
          <BreadCrumb
            items={[{ title: i18n.t('main.movingExpensesOther.title') }]}
          />
        </div>
      </div>

      <div
        className='box'
        style={{
          backgroundColor: '#ffffff',
          marginLeft: '20px',
          marginRight: '20px',
          padding: '20px',
          borderRadius: '10px',
        }}
      >
        <div className='search-parent' style={{ display: 'inline' }}>
          <InputText
            value={nameFilter}
            name='fullname'
            placeholder={i18n.t('main.fullname')}
            onChange={e => {
              setNameFilter(e.target.value)
            }}
            style={{
              width: '170px',
              margin: '0 10px 10px 0',
              display: 'inline-block',
            }}
          />
          <Dropdown
            value={statusFilter}
            name='statusValue'
            optionLabel='description'
            valueLabel='value'
            options={getOptions()}
            placeholder={i18n.t('main.movingExpensesOther.status')}
            onChange={e => {
              setStatusFillter(e.value)
            }}
            style={{
              width: '50px',
              margin: '0 10px 3px 0',
              display: 'inline-block',
            }}
          />
          <div
            className='search-time'
            style={{ display: 'inline-block', margin: '0 10px 0 -10px' }}
          >
            <DatePicker.RangePicker
              value={[
                date[0] ? moment(new Date(date[0]), 'YYYY年MM月DD日') : null,
                date[1] ? moment(new Date(date[1]), 'YYYY年MM月DD日') : null,
              ]}
              placeholder={['申請開始日', '申請終了日']}
              format='YYYY年MM月DD日'
              locale={locale}
              allowClear={false}
              onChange={value => {
                setDate([
                  moment(value[0])
                    .startOf('day')
                    .toISOString(),
                  moment(value[1])
                    .endOf('day')
                    .toISOString(),
                ])
              }}
            />
          </div>
          <div
            className='search-time'
            style={{ display: 'inline-block', margin: '0 10px 10px -10px' }}
          >
            <DatePicker.RangePicker
              value={[
                startTimeFilter[0]
                  ? moment(new Date(startTimeFilter[0]), 'YYYY年MM月DD日')
                  : null,
                startTimeFilter[1]
                  ? moment(new Date(startTimeFilter[1]), 'YYYY年MM月DD日')
                  : null,
              ]}
              placeholder={['利用開始日', '利用終了日']}
              format='YYYY年MM月DD日'
              locale={locale}
              allowClear={false}
              onChange={value => {
                setStartTime([
                  moment(value[0])
                    .startOf('day')
                    .toISOString(),
                  moment(value[1])
                    .endOf('day')
                    .toISOString(),
                ])
              }}
            />
          </div>
          <Link
            to={{
              pathname: Router.staffDoctorsMovingExpensesOther,
              search: queryString.stringify({
                rowsPerPage,
                ...getParams(
                  nameFilter,
                  date[0],
                  date[1],
                  statusFilter,
                  startTimeFilter[0],
                  startTimeFilter[1]
                ),
              }),
            }}
          >
            <Button
              label={i18n.t('main.movingExpensesOther.search')}
              icon='pi pi-search'
              style={{ display: 'inline-block', margin: '0 0 10px' }}
            />
          </Link>

          {fullname ||
          status ||
          startAt ||
          endAt ||
          startTimeGte ||
          startTimeLte ? (
            <Link
              to={{
                pathname: Router.staffDoctorsMovingExpensesOther,
                search: queryString.stringify({
                  rowsPerPage,
                }),
              }}
            >
              <Button
                icon='pi pi-times'
                className='p-button-secondary'
                style={{ marginLeft: '20px' }}
              />
            </Link>
          ) : null}
        </div>
        <div style={{ position: 'absolute', top: '1rem', right: '3rem' }}>
          <Button
            icon='pi pi-download'
            className='p-button-normal'
            label='CSVダウンロード'
            onClick={async () => {
              setProgress({
                progress: DOWNLOAD_CSV_LIST_MOVING_EXPENSES,
                label: `${i18n.t('main.movingExpensesOther.title')}中`,
                percent: 0,
                url: undefined,
              })
              const token = Auth.getToken()
              try {
                const url = `${process.env.REACT_APP_ENDPOINT}/downloadcsvMovingExpenseOther?token=${token}`
                const { status, data: urlS3 } = await Axios({
                  method: 'GET',
                  url,
                })
                await waitFor(3000)
                if (status === 204) {
                  setProgress({
                    progress: DOWNLOAD_CSV_LIST_MOVING_EXPENSES,
                    label: `${i18n.t('main.movingExpensesOther.title')}中`,
                    msg: i18n.t('main.msgDownloadCSVStatusError'),
                  })
                  await waitFor(3000)
                } else {
                  setProgress({
                    progress: DOWNLOAD_CSV_LIST_MOVING_EXPENSES,
                    percent: 100,
                    url: urlS3,
                  })
                }
              } catch (err) {
                setProgress({
                  progress: DOWNLOAD_CSV_LIST_MOVING_EXPENSES,
                  label: `${i18n.t('main.movingExpensesOther.title')}中`,
                  msg: i18n.t('main.msgDownloadCSVStatusError'),
                })
                await waitFor(3000)
              }
              removeProgress(DOWNLOAD_CSV_LIST_MOVING_EXPENSES)
            }}
            style={{ marginLeft: '100px' }}
          />
        </div>
        <div>
          <div>
            <table className='table-moving-expenses'>
              <thead>
                <tr>
                  <th width='24%'>
                    {i18n.t('main.movingExpensesOther.fullname')}
                  </th>
                  <th width='16%'>{i18n.t('main.movingExpensesOther.type')}</th>
                  <th width='15%'>{i18n.t('main.movingExpensesOther.date')}</th>
                  <th width='15%'>
                    {i18n.t('staff.movingExpensesOtherDetail.usePeriod')}
                  </th>
                  <th width='15%'>{i18n.t('main.movingExpensesOther.cost')}</th>
                  <th width='12%'>
                    {i18n.t('main.movingExpensesOther.status')}
                  </th>
                  {isUpdateMovingExpenses ? (
                    <th width='15%'>
                      {i18n.t('main.movingExpensesOther.detail')}
                    </th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {list && list.length > 0
                  ? list.map(as => (
                      <tr>
                        <td>{`${as.node.doctor.lastname} ${as.node.doctor.firstname}`}</td>
                        <td>{as.node.type}</td>
                        <td>{moment(as.node.createdAt).format('LL (dd)')}</td>
                        <td>{moment(as.node.startTime).format('LL (dd)')}</td>
                        <td>
                          {`￥${as.node.cost ? as.node.cost : 0}`.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ','
                          )}
                        </td>
                        <td className={getTypeTd(as.node.status)}>
                          {arrStatus.includes(as.node.status)
                            ? i18n.t(
                                `main.movingExpensesOther.${as.node.status}`
                              )
                            : null}
                        </td>
                        {isUpdateMovingExpenses ? (
                          <td>
                            <ModalLink
                              to={Router.get(
                                Router.staffDoctorsMovingExpensesOtherDetail,
                                { doctorId: as.node.id }
                              )}
                              className={
                                Router.staffDoctorsMovingExpensesOtherDetail
                              }
                            >
                              <Button icon='pi pi-pencil' />
                            </ModalLink>
                          </td>
                        ) : null}
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
            <Paginator
              rows={rowsPerPage}
              totalRecords={count}
              first={(page - 1) * rowsPerPage}
              onPageChange={onPageChange}
              leftContent={
                <div className='paginator-total'>
                  {i18n.t('main.paginationTotal')}: {count}
                </div>
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListMovingExpensesOtherScene
