import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import {
  STAFF_CREATE_QUESTION,
  STAFF_DELETE_QUESTION,
  STAFF_UPDATE_QUESTION,
} from '@medical/constant/permissions'
import { checkStaffPermission, onPageChange } from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import Router from '@medical/routes/router'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'

import {
  ISHIDE_QUESTION,
  QUESTION_CATEGORY,
  QUESTION_CONNECTION,
} from './QuestionsList.graphql'
import QuestionsListScene from './QuestionsListScene'

const QuestionsList = ({ history, location: { search } }) => {
  const [{ i18n, popup }] = useCustom()
  const {
    page = 1,
    rowsPerPage = 20,
    orderBy = 'updatedAt_DESC',
    category,
    content,
  } = queryString.parse(search)
  const [refetchData, setRefetchData] = useState('')
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  const variables = {
    first: first < 0 ? 10 : first,
    skip: skip < 0 ? 0 : skip,
    orderBy,
    where: {
      AND: [
        {
          QuestionCategory_Question_categoryToQuestionCategory: {
            id: category,
            deletedAt: null,
          },

          OR: [
            {
              title_contains: content,
            },
            {
              content_contains: content,
            },
          ],
        },
      ],
    },
  }
  const isCreatePermitted = checkStaffPermission({
    functionName: STAFF_CREATE_QUESTION,
  })
  const isDeletePermitted = checkStaffPermission({
    functionName: STAFF_DELETE_QUESTION,
  })
  const isUpdatePermitted = checkStaffPermission({
    functionName: STAFF_UPDATE_QUESTION,
  })
  const [isHideQuestion] = useMutation(ISHIDE_QUESTION)
  const { loading, error, data, refetch } = useQuery(QUESTION_CONNECTION, {
    variables,
    fetchPolicy: 'network-only',
  })
  const {
    loading: loadingCategorys,
    error: errorCategory,
    data: categorys,
  } = useQuery(QUESTION_CATEGORY, {
    orderBy: 'updatedAt_ASC',
    where: {
      deletedAt: null,
    },
    fetchPolicy: 'network-only',
  })
  useEffect(() => {
    refetch()
  }, [refetch, refetchData])
  if (loading || loadingCategorys) return <ProgressSpinner />
  if (error || errorCategory) return <ErrorComponent error={error} />
  const {
    questionsConnection: {
      edges,
      aggregate: { count },
    },
  } = data
  const getCategorys = id => {
    for (const category of categorys.questionCategories) {
      if (category.id === id) {
        return category.content
      }
    }
    return {}
  }
  const listCategory = categorys.questionCategories
    ? categorys.questionCategories.map(item => ({
        value: item.id,
        description: item.content,
        createdAt: item.createdAt,
      }))
    : []
  const categorySearch = () => {
    for (const item of listCategory) {
      if (item.value === category) {
        return item
      }
    }
  }
  const onSubmit = async (
    { isHide, questionId },
    { setSubmitting, resetForm }
  ) => {
    try {
      await isHideQuestion({
        variables: {
          where: {
            id: questionId,
          },
          data: {
            isHide: !isHide,
          },
        },
      })
      setRefetchData(!refetchData)
      popup.success(i18n.t('成功しました'))
      setSubmitting(false)
      resetForm()
      history.push(Router.staffQuestions)
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }

  return (
    <QuestionsListScene
      listCategory={listCategory}
      i18n={i18n}
      categorySearch={categorySearch}
      contentSearch={content}
      getCategorys={getCategorys}
      categorys={categorys}
      edges={edges}
      rowsPerPage={rowsPerPage}
      page={page}
      count={count}
      onPageChange={onPageChange(history, search)}
      onSubmit={onSubmit}
      isCreatePermitted={isCreatePermitted}
      isDeletePermitted={isDeletePermitted}
      isUpdatePermitted={isUpdatePermitted}
    />
  )
}

export default QuestionsList
