import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { convertDoctors, convertDoctorsConnection } from '@medical/libs'
import { DOCTORS_DEPARTMENTS } from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import { DOCTOR_CONNECTION } from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import MovingExpensesRegistrationDetailScence from '@medical/pages/staff/MovingExpenses/RegistrationMovingExpenses/MovingExpensesRegistrationDetailScence'
import { useCustom } from '@medical/provider/context'
import React from 'react'

import {
  GET_CLINIC,
  STAFF_CREATE_TRANSPORTATION,
} from './ListRegistration.graphql'

const MovingExpensesRegistrationCreate = ({ closeModal }) => {
  const [{ i18n, popup }] = useCustom()
  const { loading, error, data } = useQuery(DOCTORS_DEPARTMENTS)
  const {
    loading: dataDoctorConnectionLoading,
    error: dataDoctorConnectionError,
    data: dataDoctorConnection,
  } = useQuery(DOCTOR_CONNECTION, {
    variables: {
      where: {
        registrationStatus_in: ['CREATED_BY_STAFF', 'ACCEPTED'],
        blocked: false,
      },
      first: 10,
      skip: 0,
      orderBy: 'createdAt_ASC',
    },
  })
  const {
    loading: clinicLoading,
    error: clinicError,
    data: dataClinic,
  } = useQuery(GET_CLINIC)
  const [creeatMovingExpenses] = useMutation(STAFF_CREATE_TRANSPORTATION)

  if (loading || clinicLoading || dataDoctorConnectionLoading)
    return <ProgressSpinner />
  if (error || clinicError) return <ErrorComponent error={error} />
  if (dataDoctorConnectionError)
    return <ErrorComponent error={dataDoctorConnectionError} />
  const { doctors } = data
  const onSubmit = async (
    {
      url,
      doctorId,
      clinic,
      departurePlace,
      arrivalPlace,
      cost,
      startTime,
      isPast,
      urlRoundTrip,
      departurePlaceRoundTrip,
      arrivalPlaceRoundTrip,
      costRoundTrip,
    },
    { setSubmitting, setFieldValue }
  ) => {
    try {
      await creeatMovingExpenses({
        variables: {
          doctorId,
          clinicId: clinic.id,
          data: {
            url,
            departurePlace,
            arrivalPlace,
            cost,
            startTime,
            returnPath: isPast
              ? {
                  set: [
                    departurePlaceRoundTrip,
                    arrivalPlaceRoundTrip,
                    `${costRoundTrip}`,
                    urlRoundTrip,
                  ],
                }
              : null,
          },
        },
      })
      popup.success(i18n.t('staff.movingExpensesDetail.submissionSuccess'))
      setSubmitting(true)
      closeModal()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
      setFieldValue('confirm', false)
    }
  }
  const { edges } = dataDoctorConnection.doctorsConnection
  return (
    <MovingExpensesRegistrationDetailScence
      i18n={i18n}
      // doctors={convertDoctors(doctors)}
      doctors={convertDoctorsConnection(edges)}
      isCreate
      clinics={dataClinic}
      onSubmit={onSubmit}
    />
  )
}
export default MovingExpensesRegistrationCreate
