import gql from 'graphql-tag'

import { ACTIVITY_LOG_FRAGMENT } from '../ActivityList/ActivityList.graphql'

export const ACTIVITY_LOG_DETAIL = gql`
  query ACTIVITY_LOG_DETAIL($activityLogId: ID!) {
    activityLog(where: { id: $activityLogId }) {
      ...ACTIVITY_LOG_FRAGMENT
      details
    }
  }
  ${ACTIVITY_LOG_FRAGMENT}
`
