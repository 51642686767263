import BreadCrumb from '@medical/components/Breadcrumb'
import ModalLink from '@medical/components/ModalLink'
import { STAFF_STATUS } from '@medical/constant'
import Router from '@medical/routes/router'
import { DatePicker } from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { Paginator } from 'primereact/paginator'
import queryString from 'query-string'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const WorkScheduleOverTimeListScene = ({
  i18n,
  fullname,
  email,
  page,
  rowsPerPage,
  onPageChange,
  count,
  dataResponse,
  isUpdateOverTime,
  startDate,
  status,
}) => {
  const arrStatus = [
    'WAITING_FOR_APPROVAL',
    'REJECTED',
    'ACCEPTED',
    'CANCEL_ACCEPTED',
  ]

  const statusOption = [
    { value: 'WAITING_FOR_APPROVAL', description: '承認待ち' },
    { value: 'ACCEPTED', description: '承認済み' },
    { value: 'REJECTED', description: '却下' },
    { value: 'CANCEL_ACCEPTED', description: '承認の取り消し' },
  ]
  const [nameFilter, setNameFilter] = useState(fullname || '')
  const [mailFilter, setMailFilter] = useState(email || '')
  const [dateFilter, setDateFilter] = useState(startDate || '')
  const [statusFilter, setStatusFilter] = useState(
    status ? statusOption.filter(item => item.value === status)[0] : ''
  )
  const disabledDate = current => {
    const min = moment().subtract(1, 'days')
    const max = moment().add(6, 'quarters')

    return !(min.isSameOrAfter(current) && max.isAfter(current))
  }
  const getTypeTd = statusValue => {
    if (statusValue === STAFF_STATUS.WAITING_FOR_APPROVAL) {
      return 'table-moving-blue'
    }
    if ([STAFF_STATUS.REJECTED, 'CANCEL_ACCEPTED'].includes(statusValue)) {
      return 'table-moving-red'
    }
    return ''
  }

  return (
    <div
      style={{
        padding: '10px',
        height: '100%',
      }}
    >
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('staff.menuBar.overTime')}
          <BreadCrumb items={[{ title: i18n.t('staff.menuBar.overTime') }]} />
        </div>
      </div>
      <div
        className='box'
        style={{
          backgroundColor: '#ffffff',
          marginLeft: '20px',
          marginRight: '20px',
          padding: '20px',
          borderRadius: '10px',
        }}
      >
        <div className='search-parent'>
          <InputText
            value={nameFilter}
            name='fullname'
            placeholder={i18n.t('main.fullname')}
            onChange={e => {
              setNameFilter(e.target.value)
            }}
            style={{ width: '170px', marginRight: '20px' }}
          />
          <InputText
            value={mailFilter}
            name='email'
            placeholder={i18n.t('main.movingExpenses.mail')}
            onChange={e => {
              setMailFilter(e.target.value)
            }}
            style={{ width: '170px', marginRight: '20px' }}
          />
          <DatePicker
            className='dateFilter'
            placeholder='勤務日を選択'
            value={dateFilter ? moment(dateFilter) : null}
            format='YYYY年MM月DD日'
            locale={locale}
            disabledDate={disabledDate}
            allowClear={false}
            showToday={false}
            onChange={e => {
              setDateFilter(moment(e).format())
            }}
          />
          <Dropdown
            value={statusFilter}
            name='statusValue'
            optionLabel='description'
            valueLabel='value'
            options={statusOption}
            placeholder={i18n.t('main.workScheduleCancel.status')}
            onChange={e => {
              setStatusFilter(e.value)
            }}
            style={{ width: '50px', marginRight: '20px' }}
          />
          <Link
            to={{
              pathname: Router.staffOverTime,
              search: queryString.stringify({
                rowsPerPage,
                fullname: nameFilter,
                email: mailFilter,
                startDate: dateFilter,
                status: statusFilter.value,
              }),
            }}
          >
            <Button
              label={i18n.t('main.movingExpenses.search')}
              icon='pi pi-search'
            />
          </Link>
          {fullname || email || dateFilter || status ? (
            <Link
              to={{
                pathname: Router.staffOverTime,
                search: queryString.stringify({
                  rowsPerPage,
                }),
              }}
              style={{ marginLeft: '20px' }}
            >
              <Button
                icon='pi pi-times'
                className='p-button-secondary'
                style={{ marginLeft: '20px' }}
              />
            </Link>
          ) : null}
        </div>
        <div>
          <div>
            <table className='table-moving-expenses'>
              <thead>
                <tr>
                  <th width='15%'>{i18n.t('staff.activityLogs.doctor')}</th>
                  <th width='18%'>{i18n.t('main.movingExpenses.mail')}</th>
                  <th width='15%'>{i18n.t('main.clinicName')}</th>
                  <th width='12%'>
                    {i18n.t('main.movingExpenses.department')}
                  </th>
                  <th width='15%'>{i18n.t('main.date')}</th>
                  <th width='10%'>{i18n.t('main.movingExpensesOther.type')}</th>
                  <th width='14%'>
                    {i18n.t('main.movingExpensesOther.status')}
                  </th>
                  {isUpdateOverTime ? (
                    <th width='5%'>{i18n.t('main.movingExpenses.detail')}</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {dataResponse && dataResponse.length > 0
                  ? dataResponse.map(as => {
                      let text
                      if (as.isOverTime && as.isEarlyArrival) {
                        text = '早出/残業'
                      } else if (as.isEarlyArrival) {
                        text = '早出'
                      } else {
                        text = '残業'
                      }
                      return (
                        <tr>
                          <td>{`${as.lastname} ${as.fristName}`}</td>
                          <td>
                            <a href={`mailto:${as.email}`}>{as.email}</a>
                          </td>
                          <td>{as.clinicName}</td>
                          <td>{as.departmentName}</td>
                          <td>{as.date}</td>
                          <td>{text}</td>
                          <td className={getTypeTd(as.status)}>
                            {arrStatus.includes(as.status)
                              ? i18n.t(`main.movingExpensesOther.${as.status}`)
                              : null}
                          </td>
                          {isUpdateOverTime ? (
                            <td>
                              <ModalLink
                                to={Router.get(Router.staffOverTimeDetail, {
                                  workOverTimeId: as.workOverTimeId,
                                })}
                                className={Router.staffOverTimeDetail}
                              >
                                <Button icon='pi pi-pencil' />
                              </ModalLink>
                            </td>
                          ) : null}
                        </tr>
                      )
                    })
                  : null}
              </tbody>
            </table>
            <Paginator
              rows={rowsPerPage}
              totalRecords={count}
              first={(page - 1) * rowsPerPage}
              onPageChange={onPageChange}
              leftContent={
                <div className='paginator-total'>
                  {i18n.t('main.paginationTotal')}: {count}
                </div>
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default WorkScheduleOverTimeListScene
