import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import ClinicNameRender from '@medical/components/ClinicNameRender'
import { STAFF_UPDATE_OVERTIME } from '@medical/constant/permissions'
import {
  checkStaffPermission,
  clinicalDepartmentNames,
  onPageChange,
} from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import moment from 'moment'
import queryString from 'query-string'
import React, { useEffect } from 'react'

import { WORKSCHEDULE_OVERTIMES_CONNECTION } from './WorkScheduleOverTimeList.grapql'
import WorkScheduleOverTimeListScene from './WorkScheduleOverTimeListScene'

const WorkScheduleOverTimeList = ({ history, location: { search } }) => {
  const {
    page = 1,
    rowsPerPage = 20,
    fullname,
    email,
    startDate,
    status,
  } = queryString.parse(search)
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  const [{ i18n }] = useCustom()
  const { loading, error, data, refetch } = useQuery(
    WORKSCHEDULE_OVERTIMES_CONNECTION,
    {
      variables: {
        first: first < 0 ? 20 : first,
        skip: skip < 0 ? 0 : skip,
        orderBy: 'createdAt_DESC',
        where: {
          status,
          WorkSchedule: {
            startTime_gte: startDate
              ? moment(startDate)
                  .startOf('day')
                  .toISOString()
              : undefined,
            startTime_lte: startDate
              ? moment(startDate)
                  .endOf('day')
                  .toISOString()
              : undefined,
            Doctor: {
              email_contains: email,
              OR: [
                {
                  unSigned_contains: fullname
                    ? fullname
                        .replace(/ /g, '')
                        .replace(/　/g, '')
                        .toLowerCase()
                    : '',
                },
              ],
              deletedAt: null,
            },
          },
        },
      },
      fetchPolicy: 'network-only',
    }
  )
  useEffect(() => {
    refetch()
  })
  const isUpdateOverTime = checkStaffPermission({
    functionName: STAFF_UPDATE_OVERTIME,
  })
  if (loading) return <ProgressSpinner fullHeight />
  if (error) return <ErrorComponent error={error} />
  const { departments } = data
  const departmentNames = clinicalDepartmentNames({ departments })
  const dataResponse = data.workScheduleOvertimesConnection.edges.map(item => ({
    workScheduleId: item.node.workSchedule.id,
    workOverTimeId: item.node.id,
    fristName: item.node.workSchedule.doctor.firstname,
    lastname: item.node.workSchedule.doctor.lastname,
    email: item.node.workSchedule.doctor.email,
    clinicName: ClinicNameRender(item.node.workSchedule),
    departmentName:
      departmentNames[item.node.workSchedule.clinicalDepartment.name],
    status: item.node.status,
    date: moment(item.node.workSchedule.startTime).format('LL '),
    isOverTime: item.node.lateArrival,
    isEarlyArrival: item.node.earlyDeparture,
  }))
  return (
    <WorkScheduleOverTimeListScene
      i18n={i18n}
      count={data.workScheduleOvertimesConnection.aggregate.count}
      rowsPerPage={parseInt(rowsPerPage, 10)}
      onPageChange={onPageChange(history, search)}
      dataResponse={dataResponse || {}}
      fullname={fullname}
      email={email}
      isUpdateOverTime={isUpdateOverTime}
      page={parseInt(page, 10)}
      startDate={startDate}
      status={status}
    />
  )
}
export default WorkScheduleOverTimeList
