/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
import BreadCrumb from '@medical/components/Breadcrumb'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import Router from '@medical/routes/router'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { Paginator } from 'primereact/paginator'
import queryString from 'query-string'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const QuestionsListScene = ({
  i18n,
  listCategory,
  contentSearch,
  categorySearch = {},
  getCategorys,
  edges = [],
  rowsPerPage,
  page,
  count,
  onPageChange,
  onSubmit,
  isCreatePermitted,
  isDeletePermitted,
  isUpdatePermitted,
}) => {
  const [category, setCategory] = useState(categorySearch || {})
  const [content, setContent] = useState(contentSearch)
  return (
    <React.Fragment>
      <div className='tien check'>
        <div className='staff-header'>
          <div className='staff-title'>
            {i18n.t('staff.questions.title')}
            <BreadCrumb items={[{ title: i18n.t('staff.menuBar.question') }]} />
          </div>
        </div>
        <div className='box'>
          <div>
            <Formik
              initialValues={{ confirm: false, announceId: '', confirmation: '' }}
              onSubmit={onSubmit}
              render={formikProps => {
                const { values } = formikProps
                return (
                  <div>
                    <div className='search-parent'>
                      <Dropdown
                        value={category}
                        name='category'
                        options={listCategory}
                        optionLabel='description'
                        valueLabel='value'
                        placeholder={i18n.t('staff.questions.category')}
                        onChange={e => {
                          setCategory(e.value)
                        }}
                      />
                      <InputText
                        value={content}
                        placeholder='検索'
                        onChange={e => {
                          setContent(e.target.value)
                        }}
                        style={{ width: '250px', marginLeft: '20px' }}
                      />
                      <Link
                        to={{
                          pathname: Router.staffQuestions,
                          search: queryString.stringify({
                            rowsPerPage,
                            category:
                              category && category.value
                                ? category.value
                                : undefined,
                            content,
                          }),
                        }}
                        style={{ marginLeft: '20px' }}
                      >
                        <Button
                          label={i18n.t('main.search')}
                          icon='pi pi-search'
                        />
                      </Link>
                      {category || content ? (
                        <Link
                          to={{
                            pathname: Router.staffQuestions,
                          }}
                        >
                          <Button
                            icon='pi pi-times'
                            className='p-button-secondary'
                            style={{ marginLeft: '20px' }}
                          />
                        </Link>
                      ) : null}
                      {isCreatePermitted ? (
                        <div
                          className='staff-buttons'
                          style={{ marginLeft: 'auto', marginRight: '0px' }}
                        >
                          <Link to={Router.staffQuestionCreate}>
                            <Button
                              label={i18n.t('staff.questions.createQuestion')}
                              icon='pi pi-plus'
                              className='p-button-normal'
                            />
                          </Link>
                        </div>
                      ) : null}
                    </div>
                    <table className='table' style={{ width: '100%' }}>
                      <thead>
                        <tr>
                          <th width='15%'>
                            {i18n.t('staff.questions.category')}
                          </th>
                          <th width='20%'>
                            {i18n.t('staff.questions.titleLabel')}
                          </th>
                          <th width='10%'>
                            {i18n.t('staff.questions.updatedAt')}
                          </th>
                          {isUpdatePermitted || isDeletePermitted ? (
                            <th width='8%'>{i18n.t('main.action')}</th>
                          ) : null}
                        </tr>
                      </thead>
                      <tbody>
                        {edges.map(({ node }) => (
                          <tr
                            key={node.id}
                            className={node.isHide ? 'background-grey' : null}
                          >
                            <td>{getCategorys(node.category.id)}</td>
                            <td>{node.title}</td>
                            <td className='center'>
                              {moment(node.updatedAt).format('LL (dd)')}
                            </td>
                            {isUpdatePermitted || isDeletePermitted ? (
                              <td>
                                <div className='action'>
                                  {isUpdatePermitted ? (
                                    <Link
                                      to={Router.get(Router.staffQuestionUpdate, {
                                        questionId: node.id,
                                      })}
                                    >
                                      <Button icon='pi pi-pencil' />
                                    </Link>
                                  ) : null}
                                  {isDeletePermitted ? (
                                    <Button
                                      onClick={() => {
                                        if (!node.isHide) {
                                          formikProps.setFieldValue(
                                            'confirmation',
                                            i18n.t(
                                              'staff.questions.submitHideQuestionConfirmation'
                                            )
                                          )
                                        } else {
                                          formikProps.setFieldValue(
                                            'confirmation',
                                            i18n.t(
                                              'staff.questions.submitSeenQuestionConfirmation'
                                            )
                                          )
                                        }

                                        formikProps.setFieldValue(
                                          'questionId',
                                          node.id
                                        )
                                        formikProps.setFieldValue(
                                          'isHide',
                                          node.isHide
                                        )
                                        formikProps.setFieldValue('confirm', true)
                                      }}
                                      icon={
                                        !node.isHide
                                          ? `pi pi-eye`
                                          : `pi pi-eye-slash`
                                      }
                                    />
                                  ) : null}
                                </div>
                              </td>
                            ) : null}
                          </tr>
                        ))}
                        <td style={{ textAlign: 'center' }} colSpan='5'>
                          {edges.length === 0 && (category || content)
                            ? 'ご質問はありません。'
                            : null}
                        </td>
                      </tbody>
                    </table>
                    <ConfirmationModal
                      {...formikProps}
                      confirmationMessage={values.confirmation}
                    />
                  </div>
                )
              }}
            />
            <Paginator
              rows={rowsPerPage}
              totalRecords={count}
              first={(page - 1) * rowsPerPage}
              onPageChange={onPageChange}
              leftContent={
                <div className='paginator-total'>
                  {i18n.t('main.paginationTotal')}: {count}
                </div>
              }
            />
          </div>
        </div>

      </div>
    </React.Fragment>
  )
}
export default QuestionsListScene
