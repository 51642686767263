import moment from 'moment'
import { Checkbox } from 'primereact/checkbox'
import { InputText } from 'primereact/inputtext'
import { TabPanel, TabView } from 'primereact/tabview'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getClinicalDepartmentJP } from '@medical/libs'

const DoctorClinicDetailScene = ({ clinic = {}, departments, disabled }) => {
  const { i18n } = useTranslation()
  const clinicalDepartments = clinic.clinicalDepartments.map(it => it.name)
  const departmentRs = departments.map(it => {
    if (clinicalDepartments.includes(it.value)) {
      return {
        value: it.value,
        description: it.description,
        checked: true,
      }
    }
    return {
      value: it.value,
      description: it.description,
      checked: false,
    }
  })
  const getTimeOpen = (startTime, endTime, isAvailable) =>
    isAvailable
      ? `${moment.utc(startTime).format('HH:mm')} ~ ${moment
          .utc(endTime)
          .format('HH:mm')}`
      : '定休日'
  const numberOfPatient = clinic.numberPatient
    ? clinic.numberPatient.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : null
  return (
    <div>
      <TabView>
        <TabPanel header={i18n.t('staff.editClinic.clinicDetail')}>
          <div>
            <div className='tr'>
              <div className='th'>{i18n.t('main.clinicName')}</div>
              <div className='td'>
                <InputText
                  value={clinic.name}
                  name='clinicName'
                  disabled={disabled}
                />
              </div>
            </div>
            <div className='tr'>
              <div className='th'>{i18n.t('main.clinicNameKana')}</div>
              <div className='td'>
                <InputText
                  value={clinic.nameKana}
                  name='clinicNameKana'
                  disabled={disabled}
                />
              </div>
            </div>
            <div className='tr'>
              <div className='th'>{i18n.t('main.address')}</div>
              <div className='td'>
                <InputText
                  value={clinic.address}
                  name='address'
                  disabled={disabled}
                />
              </div>
            </div>
            <div className='tr'>
              <div className='th'>{i18n.t('main.email')}</div>
              <div className='td'>
                <InputText
                  value={clinic.email}
                  name='email'
                  disabled={disabled}
                />
              </div>
            </div>
            <div className='tr'>
              <div className='th'>{i18n.t('main.phoneNumber')}</div>
              <div className='td'>
                <InputText
                  value={clinic.phoneNumber}
                  name='phoneNumber'
                  disabled={disabled}
                />
              </div>
            </div>
            <div className='tr'>
              <div className='th'>{i18n.t('main.departmentName')}</div>
              <div className='td'>
                {departmentRs.map((it, key) => (
                  <div key={key} style={{ marginBottom: '10px' }}>
                    <Checkbox
                      name='departmentName'
                      label={i18n.t('main.departmentName')}
                      checked={it.checked}
                      disabled
                    />
                    <span> {it.description}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className='tr'>
              <div className='th'>
                {i18n.t('staff.treatmentResult.openTime')}
              </div>
              <div className='td '>
                {clinic &&
                clinic.clinicalDepartments &&
                clinic.clinicalDepartments.length > 0
                  ? clinic.clinicalDepartments.map(item => (
                      <>
                        <div className='label-department'>
                          {getClinicalDepartmentJP(item.name)}
                        </div>
                        <div>
                          <span className='label-day'>
                            {i18n.t('staff.editClinic.monday')}
                          </span>
                          <span className='time-container'>
                            {i18n.t('staff.editClinic.morning')}:
                            {getTimeOpen(
                              item.startTimeMondayMorning,
                              item.endTimeMondayMorning,
                              item.isAvailableOnMondayMorning
                            )}{' '}
                          </span>

                          <span className='time-container'>
                            {i18n.t('staff.editClinic.afternoon')}:
                            {getTimeOpen(
                              item.startTimeMondayAfternoon,
                              item.endTimeMondayAfternoon,
                              item.isAvailableOnMondayAfternoon
                            )}{' '}
                          </span>
                        </div>
                        <div>
                          <span className='label-day'>
                            {i18n.t('staff.editClinic.tuesday')}{' '}
                          </span>

                          <span className='time-container'>
                            {i18n.t('staff.editClinic.morning')}:
                            {getTimeOpen(
                              item.startTimeTuesdayMorning,
                              item.endTimeTuesdayMorning,
                              item.isAvailableOnTuesdayMorning
                            )}
                          </span>
                          <span className='time-container'>
                            {i18n.t('staff.editClinic.afternoon')}:
                            {getTimeOpen(
                              item.startTimeTuesdayAfternoon,
                              item.endTimeTuesdayAfternoon,
                              item.isAvailableOnTuesdayAfternoon
                            )}{' '}
                          </span>
                        </div>
                        <div>
                          <span className='label-day'>
                            {' '}
                            {i18n.t('staff.editClinic.wednesday')}{' '}
                          </span>

                          <span className='time-container'>
                            {i18n.t('staff.editClinic.morning')}:
                            {getTimeOpen(
                              item.startTimeWednesdayMorning,
                              item.endTimeWednesdayMorning,
                              item.isAvailableOnWednesdayMorning
                            )}{' '}
                          </span>
                          <span className='time-container'>
                            {i18n.t('staff.editClinic.afternoon')}:
                            {getTimeOpen(
                              item.startTimeWednesdayAfternoon,
                              item.endTimeWednesdayAfternoon,
                              item.isAvailableOnWednesdayAfternoon
                            )}
                          </span>
                        </div>
                        <div>
                          <span className='label-day'>
                            {' '}
                            {i18n.t('staff.editClinic.thursday')}{' '}
                          </span>
                          <span className='time-container'>
                            {i18n.t('staff.editClinic.morning')}:
                            {getTimeOpen(
                              item.startTimeThursdayMorning,
                              item.endTimeThursdayMorning,
                              item.isAvailableOnThursdayMorning
                            )}{' '}
                          </span>

                          <span className='time-container'>
                            {i18n.t('staff.editClinic.afternoon')}:
                            {getTimeOpen(
                              item.startTimeThursdayAfternoon,
                              item.endTimeThursdayAfternoon,
                              item.isAvailableOnThursdayAfternoon
                            )}{' '}
                          </span>
                        </div>

                        <div>
                          <span className='label-day'>
                            {i18n.t('staff.editClinic.friday')}{' '}
                          </span>
                          <span className='time-container'>
                            {i18n.t('staff.editClinic.morning')}:
                            {getTimeOpen(
                              item.startTimeFridayMorning,
                              item.endTimeFridayMorning,
                              item.isAvailableOnFridayMorning
                            )}{' '}
                          </span>
                          <span className='time-container'>
                            {i18n.t('staff.editClinic.afternoon')}:
                            {getTimeOpen(
                              item.startTimeFridayAfternoon,
                              item.endTimeFridayAfternoon,
                              item.isAvailableOnFridayAfternoon
                            )}{' '}
                          </span>
                        </div>

                        <div>
                          <span className='label-day'>
                            {i18n.t('staff.editClinic.saturday')}{' '}
                          </span>
                          <span className='time-container'>
                            {i18n.t('staff.editClinic.morning')}:
                            {getTimeOpen(
                              item.startTimeSaturdayMorning,
                              item.endTimeSaturdayMorning,
                              item.isAvailableOnSaturdayMorning
                            )}{' '}
                          </span>
                          <span className='time-container'>
                            {i18n.t('staff.editClinic.afternoon')}:
                            {getTimeOpen(
                              item.startTimeSaturdayAfternoon,
                              item.endTimeSaturdayAfternoon,
                              item.isAvailableOnSaturdayAfternoon
                            )}{' '}
                          </span>
                        </div>

                        <div>
                          <span className='label-day'>
                            {i18n.t('staff.editClinic.sunday')}{' '}
                          </span>
                          <span className='time-container'>
                            {i18n.t('staff.editClinic.morning')}:
                            {getTimeOpen(
                              item.startTimeSundayMorning,
                              item.endTimeSundayMorning,
                              item.isAvailableOnSundayMorning
                            )}{' '}
                          </span>
                          <span className='time-container'>
                            {i18n.t('staff.editClinic.afternoon')}:
                            {getTimeOpen(
                              item.startTimeSundayAfternoon,
                              item.endTimeSundayAfternoon,
                              item.isAvailableOnSundayAfternoon
                            )}{' '}
                          </span>
                        </div>
                        <div>
                          <span className='label-day'>
                            {i18n.t('staff.editClinic.holiday')}{' '}
                          </span>
                          <span className='time-container'>
                            {i18n.t('staff.editClinic.morning')}:
                            {getTimeOpen(
                              item.startTimeHolidayMorning,
                              item.endTimeHolidayMorning,
                              item.isAvailableOnHolidayMorning
                            )}{' '}
                          </span>
                          <span className='time-container'>
                            {i18n.t('staff.editClinic.afternoon')}:
                            {getTimeOpen(
                              item.startTimeHolidayAfternoon,
                              item.endTimeHolidayAfternoon,
                              item.isAvailableOnHolidayAfternoon
                            )}{' '}
                          </span>
                        </div>
                      </>
                    ))
                  : null}
              </div>
            </div>
            <div className='tr'>
              <div className='th'>
                {i18n.t('staff.treatmentResult.noteOpenTime')}
              </div>
              <div className='td'>{clinic.noteOpenTime}</div>
            </div>

            <div className='tr'>
              <div className='th'>
                {i18n.t('staff.treatmentResult.numberOfPatients')}
              </div>
              <div className='td'>{numberOfPatient}</div>
            </div>
            <div className='tr'>
              <div className='th'>
                {i18n.t('staff.treatmentResult.ageGroup')}
              </div>
              <div className='td'>
                <p>
                  {(clinic.maleAgeFrom || clinic.maleAgeFrom === 0) &&
                  clinic.maleAgeTo
                    ? `男性 ${clinic.maleAgeFrom} ~ ${clinic.maleAgeTo} 代`
                    : ''}
                </p>
                <p>
                  {(clinic.femaleAgeFrom || clinic.femaleAgeFrom === 0) &&
                  clinic.femaleAgeTo
                    ? `女性 ${clinic.femaleAgeFrom} ~ ${clinic.femaleAgeTo} 代`
                    : ''}
                </p>
              </div>
            </div>
            <div className='tr'>
              <div className='th'>{i18n.t('staff.treatmentResult.type')}</div>
              <div className='td'>{clinic.kindOfDisease}</div>
            </div>
            <div className='tr'>
              <div className='th'>
                {i18n.t('staff.treatmentResult.vaccine')}
              </div>
              <div className='td'>{clinic.vaccine}</div>
            </div>
            <div className='tr'>
              <div className='th'>
                {i18n.t('staff.treatmentResult.referClinic')}
              </div>
              <div className='td'>{clinic.referHospital}</div>
            </div>
          </div>
        </TabPanel>
      </TabView>
    </div>
  )
}

export default DoctorClinicDetailScene
