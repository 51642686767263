import Moment from 'moment'
import { extendMoment } from 'moment-range'

const moment = extendMoment(Moment)

export const thisMonthHolidays = ({ holidaysData, date }) => {
  const holiday = []
  const startTime = moment(date)
    .startOf('month')
    .startOf('day')
  const endTime = moment(date)
    .endOf('month')
    .endOf('day')
  const itemRange = moment.range(startTime, endTime)

  for (let j = 0; j < holidaysData.length; j += 1) {
    const startEvent = moment(holidaysData[j].start.date)
    if (itemRange.contains(startEvent)) {
      holiday.push({
        date: holidaysData[j].start.date,
        summary: holidaysData[j].summary,
      })
    }
  }
  return holiday
}
