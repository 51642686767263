import { useApolloClient, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import Auth from '@medical/middleware/auth'
import Router from '@medical/routes/router'
import moment from 'moment'
import { connect } from 'react-redux'
import React, { useEffect } from 'react'
import {
  removeProgress,
  setProgressBar,
} from '@medical/provider/store/reducers/progress/progress.action'
import {
  CONVERSATIONS,
  DOCTOR_STATUS,
  DOCTOR_STATUS_SUBSCRIPTION,
  GET_NEW_SCHEDULE_CANCEL,
  RECRUITMENT_MANAGEMENT,
  RECRUITMENT_MANAGEMENT_SUBSCRIPTION,
  STAFF_ME,
  STAFF_MSG_SUBSCRIPTION,
  STAFF_SUBSCRIPTION,
  WAITING_RECRUITMENT_SHIFTS,
  WAITING_RECRUITMENT_SHIFTS_SUBSCRIPTION,
  WORK_OUTSIDE_SUBSCRIPTION,
  WORK_OUTSIDES,
  WORK_OVERTIME_SUBSCRIPTION,
  WORK_SCHEDULE_CANCEL_SUBSCRIPTION,
  WS_OVERTIME,
} from './StaffLayout.graphql'
import StaffLayoutScene from './StaffLayoutScene'
import { STAFF_EMAIL } from '@medical/constant'

const year = moment().year()
const month = moment().month() + 1

const reduceWorkOutsideData = workOutsideData => {
  return workOutsideData.map(item => item.status)
}

const StaffLayout = ({
  history,
  children,
  path,
  progress,
  setProgress,
  removeProgress,
}) => {
  const client = useApolloClient()
  const endDateGte = moment().startOf('day')

  const { loading, error, data, subscribeToMore } = useQuery(STAFF_ME)
  const {
    loading: conversationLoading,
    error: conversationError,
    data: conversationData,
    subscribeToMore: subscribeToMoreConversation,
  } = useQuery(CONVERSATIONS, {
    variables: {
      where: {
        isReadByStaff: false,
      },
    },
  })
  const {
    loading: loadingWorkOutside,
    error: errorWorkOutside,
    data: dataWorkOutside,
    subscribeToMore: subscribeToMoreWorkOutside,
  } = useQuery(WORK_OUTSIDES, {
    variables: {
      where: {
        status: 'WAITING_APPROVAL',
      },
    },
  })
  const {
    loading: loadingNewScheduleCancel,
    error: errorNewScheduleCancel,
    data: dataNewScheduleCancel,
    subscribeToMore: subscribeToMoreScheduleCancel,
  } = useQuery(GET_NEW_SCHEDULE_CANCEL, {
    variables: {
      where: {
        deletedAt: null,
        status: 'WAITING_FOR_APPROVAL',
      },
    },
  })
  const {
    loading: loadingWaiting,
    error: errorWaiting,
    data: dataWaiting,
    subscribeToMore: subscribeToMoreWaiting,
  } = useQuery(WAITING_RECRUITMENT_SHIFTS, {
    variables: {
      where: {
        endDate_gte: endDateGte,
        confirmationStatus: 'UNCONFIRM',
      },
    },
  })
  const {
    loading: recruitmentManagerLoadingWaiting,
    error: recruitmentManagerErrorWaiting,
    data: recruitmentManagerment,
    subscribeToMore: subscribeToMoreRecruitment,
  } = useQuery(RECRUITMENT_MANAGEMENT, {
    variables: {
      where: {
        status: 'NOT_ACTION',
      },
    },
  })
  const {
    loading: wotLoading,
    error: wotError,
    data: wotData,
    subscribeToMore: subscribeToMoreWot,
  } = useQuery(WS_OVERTIME, {
    variables: {
      where: {
        status: 'WAITING_FOR_APPROVAL',
        deletedAt: null,
      },
    },
  })
  const {
    loading: doctorsLoading,
    error: doctorsError,
    data: doctorsData,
    subscribeToMore: subscribeToMoreDoctors,
  } = useQuery(DOCTOR_STATUS, {
    variables: {
      where: {
        registrationStatus: 'NEW',
        deletedAt: null,
      },
    },
  })
  useEffect(() => {
    subscribeToMore({
      document: STAFF_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        const email = localStorage.getItem(STAFF_EMAIL)
        if (subscriptionData.data.staffLoggedIn.email === email) {
          Auth.signout({ history, client, path: Router.staffLogin })
        }
        return prev
      },
    })
    subscribeToMoreConversation({
      document: STAFF_MSG_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        return {
          ...prev,
          conversations: [
            subscriptionData.data.staffConversationsSubscription,
            ...prev.conversations,
          ],
        }
      },
    })
    subscribeToMoreWorkOutside({
      document: WORK_OUTSIDE_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => ({
        ...prev,
        workOutsides: [
          subscriptionData.data.workOutsideSubscription,
          ...prev.workOutsides,
        ],
      }),
    })
    subscribeToMoreScheduleCancel({
      document: WORK_SCHEDULE_CANCEL_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => ({
        ...prev,
        workScheduleCancellationLetters: [
          subscriptionData.data.workScheduleCancelLetterSubscription,
          ...prev.workScheduleCancellationLetters,
        ],
      }),
    })
    subscribeToMoreWaiting({
      document: WAITING_RECRUITMENT_SHIFTS_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => ({
        ...prev,
        waitingRecruitmentShifts: [
          subscriptionData.data.waitingRecruitmentShiftSubscription,
          ...prev.waitingRecruitmentShifts,
        ],
      }),
    })
    subscribeToMoreRecruitment({
      document: RECRUITMENT_MANAGEMENT_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => ({
        ...prev,
        recruitmentManager: [
          subscriptionData.data.recruitmentManagementSubscription,
          ...prev.recruitmentDoctors,
        ],
      }),
    })
    subscribeToMoreWot({
      document: WORK_OVERTIME_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => ({
        ...prev,
        workScheduleOvertimes: [
          subscriptionData.data.workOverTimeSubscription,
          ...prev.workScheduleOvertimes,
        ],
      }),
    })
    subscribeToMoreDoctors({
      document: DOCTOR_STATUS_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => ({
        ...prev,
        doctors: [
          subscriptionData.data.doctorStatusSubscription,
          ...prev.doctors,
        ],
      }),
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (error) {
      if (error.graphQLErrors.length > 0 && error.graphQLErrors[0].message)
        Auth.signout({
          history,
          client,
          path: Router.staffLogin,
        })
    }
  }, [client, error, history])

  if (
    loading ||
    conversationLoading ||
    loadingWorkOutside ||
    loadingNewScheduleCancel ||
    loadingWaiting ||
    recruitmentManagerLoadingWaiting ||
    wotLoading ||
    doctorsLoading
  )
    return <ProgressSpinner fullHeight />
  if (
    error ||
    conversationError ||
    errorWorkOutside ||
    errorNewScheduleCancel ||
    errorWaiting ||
    recruitmentManagerErrorWaiting ||
    wotError ||
    doctorsError
  )
    return (
      <ErrorComponent
        error={
          error ||
          conversationError ||
          errorWorkOutside ||
          errorNewScheduleCancel ||
          errorWaiting ||
          recruitmentManagerErrorWaiting ||
          wotError ||
          doctorsError
        }
      />
    )

  const onSignout = () =>
    Auth.signout({
      history,
      client,
      pathname: Router.staffLogin,
    })

  const { staffMe: staff } = data

  return (
    <StaffLayoutScene
      path={path}
      onSignout={onSignout}
      year={year}
      month={month}
      staff={staff || {}}
      newMsg={conversationData?.conversations?.length > 0}
      dataWorkOutside={reduceWorkOutsideData(dataWorkOutside.workOutsides)}
      dataNewScheduleCancel={dataNewScheduleCancel}
      newWaitingRecruitmentShift={
        dataWaiting?.waitingRecruitmentShifts?.length > 0
      }
      recruitmentManager={
        recruitmentManagerment?.recruitmentDoctors?.length > 0
      }
      wotData={reduceWorkOutsideData(wotData.workScheduleOvertimes)}
      doctorsData={doctorsData?.doctors?.map(doctor => doctor.registrationStatus)}
      progress={progress}
      setProgress={progress => setProgress(progress)}
      removeProgress={progress => removeProgress(progress)}
    >
      {children}
    </StaffLayoutScene>
  )
}
const mapStateToProps = state => ({
  progress: state.progressBarStore.progress,
})

const mapDispatchToProps = dispatch => ({
  setProgress: progress => dispatch(setProgressBar(progress)),
  removeProgress: progress => dispatch(removeProgress(progress)),
})

export default connect(mapStateToProps, mapDispatchToProps)(StaffLayout)
