import gql from 'graphql-tag'

export const CONVERSATIONS_LIST = gql`
  query CONVERSATIONS(
    $where: ConversationWhereInput
    $orderBy: ConversationOrderByInput
    $first: Int
    $skip: Int
  ) {
    conversations(
      where: $where
      orderBy: $orderBy
      first: $first
      skip: $skip
    ) {
      id
      deletedAt
      createdAt
      updatedAt
      receivedLastMessageAt
      isReadByStaff
      isReadByDoctor
      status
      doctor {
        id
        firstname
        lastname
        email
      }
    }
  }
`

export const DOCTORS_CONNECTION = gql`
  query DOCTORS_CONNECTION(
    $where: DoctorWhereInput
    $first: Int
    $skip: Int
    $orderBy: DoctorOrderByInput
  ) {
    doctorsConnection(
      where: $where
      first: $first
      skip: $skip
      orderBy: $orderBy
    ) {
      edges {
        node {
          id
          firstname
          lastname
          email
          fullName
          conversation {
            id
            status
          }
        }
      }
      aggregate(where: $where) {
        count
      }
    }
  }
`

export const DOCTORS_QUERY = gql`
  query DOCTOR_QUERY($doctorId: ID) {
    doctor(where: { id: $doctorId }) {
      id
      conversation {
        id
        status
      }
    }
  }
`

export const STAFF_MSG_SUBSCRIPTION = gql`
  subscription STAFF_MSG_SUBSCRIPTION {
    staffConversationsSubscription {
      id
      deletedAt
      createdAt
      updatedAt
      isReadByStaff
      receivedLastMessageAt
      isReadByDoctor
      doctor {
        id
        firstname
        lastname
        email
      }
    }
  }
`

export const UPDATE_CONVERSATION_STATUS = gql`
  mutation StaffUpsertConversationStatus(
    $conversationId: ID!
    $status: ConversationStatus!
  ) {
    staffUpsertConversationStatus(
      conversationId: $conversationId
      status: $status
    ) {
      id
      status
    }
  }
`
