import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import {
  STAFF_CREATE_STAFF,
  STAFF_DELETE_STAFF,
  STAFF_UPDATE_STAFF,
} from '@medical/constant/permissions'
import { checkStaffPermission, onPageChange } from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import queryString from 'query-string'
import React, { useContext } from 'react'
import { connect } from 'react-redux'
import { SocketContext } from '@medical/provider/socket'
import {
  removeProgress,
  setProgressBar,
} from '@medical/provider/store/reducers/progress/progress.action'
import { DELETE_STAFF, STAFFS_CONNECTION } from './StaffsList.graphql'
import StaffsListScene from './StaffsListScene'

const StaffsList = ({
  history,
  location: { search },
  progress,
  setProgress,
  removeProgress,
}) => {
  const [{ i18n, popup }] = useCustom()
  const socket = useContext(SocketContext)
  const {
    page = 1,
    rowsPerPage = 20,
    fullname,
    orderBy = 'createdAt_DESC',
  } = queryString.parse(search)
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  const variables = {
    first: first < 0 ? 20 : first,
    skip: skip < 0 ? 0 : skip,
    orderBy,
    where: fullname
      ? {
          OR: [
            {
              firstname_contains: fullname,
            },
            {
              lastname_contains: fullname,
            },
            {
              firstnameKana_contains: fullname,
            },
            {
              lastnameKana_contains: fullname,
            },
          ],
        }
      : undefined,
  }
  const isUpdatePermitted = checkStaffPermission({
    functionName: STAFF_UPDATE_STAFF,
  })
  const isCreatePermitted = checkStaffPermission({
    functionName: STAFF_CREATE_STAFF,
  })
  const isDeletePermitted = checkStaffPermission({
    functionName: STAFF_DELETE_STAFF,
  })
  const { loading, error, data } = useQuery(STAFFS_CONNECTION, {
    variables,
    fetchPolicy: 'network-only',
  })

  const [deleteStaff] = useMutation(DELETE_STAFF)

  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const onSubmit = async ({ staffId }, { setSubmitting, resetForm }) => {
    try {
      await deleteStaff({
        variables: {
          staffId,
        },
        update: store => {
          const staffsData = store.readQuery({
            query: STAFFS_CONNECTION,
            variables,
          })
          staffsData.staffsConnection.edges = staffsData.staffsConnection.edges.filter(
            edge => edge.node.id !== staffId
          )
          staffsData.staffsConnection.aggregate.count -= 1
          store.writeQuery({
            query: STAFFS_CONNECTION,
            data: staffsData,
            variables,
          })
        },
      })
      popup.success(i18n.t('staff.deleteStaff.submissionSuccess'))
      setSubmitting(false)
      resetForm()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
      resetForm()
    }
  }

  const {
    staffsConnection: {
      edges,
      aggregate: { count },
    },
  } = data

  return (
    <StaffsListScene
      i18n={i18n}
      socket={socket}
      progress={progress}
      setProgress={progress => setProgress(progress)}
      removeProgress={progress => removeProgress(progress)}
      edges={edges}
      page={parseInt(page, 10)}
      rowsPerPage={parseInt(rowsPerPage, 10)}
      fullname={fullname}
      count={count}
      onPageChange={onPageChange(history, search)}
      onSubmit={onSubmit}
      isCreatePermitted={isCreatePermitted}
      isDeletePermitted={isDeletePermitted}
      isUpdatePermitted={isUpdatePermitted}
    />
  )
}

const mapStateToProps = state => ({
  progress: state.progressBarStore.progress,
})

const mapDispatchToProps = dispatch => ({
  setProgress: progress => dispatch(setProgressBar(progress)),
  removeProgress: progress => dispatch(removeProgress(progress)),
})
export default connect(mapStateToProps, mapDispatchToProps)(StaffsList)
