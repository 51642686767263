import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, NotFound, ProgressSpinner } from '@medical/components'
import EmailConfirmationForm from '@medical/components/Forms/EmailConfirmationForm/EmailConfirmationForm'
import Auth from '@medical/middleware/auth'
import { useCustom } from '@medical/provider/context'
import HomeRedirect from '@medical/routes/HomeRedirect'
import Router from '@medical/routes/router'
import queryString from 'query-string'
import React from 'react'

import {
  DOCTOR_CHANGE_RESET_PASSWORD,
  DOCTOR_CHECK_RESET_PASSWORD_NUMBER,
} from './DoctorResetPassword.graphql'

const DoctorResetPassword = ({ history, location: { search } }) => {
  const { resetPasswordNumber } = queryString.parse(search)
  const [{ i18n, popup }] = useCustom()
  const [changeResetPassword] = useMutation(DOCTOR_CHANGE_RESET_PASSWORD)
  const { loading, error, data } = useQuery(
    DOCTOR_CHECK_RESET_PASSWORD_NUMBER,
    {
      variables: {
        resetPasswordNumber,
      },
    }
  )

  if (Auth.getToken()) return <HomeRedirect />
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent />
  if (!data.doctorCheckPasswordNumber) return <NotFound />

  const onSubmit = async ({ newPassword }, { setSubmitting, resetForm }) => {
    try {
      await changeResetPassword({
        variables: {
          resetPasswordNumber,
          newPassword,
        },
      })
      setSubmitting(false)
      resetForm()
      popup.success(i18n.t('main.changePassword.submissionSuccess'))
      history.push({
        pathname: Router.doctorLogin,
      })
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }

  return <EmailConfirmationForm onSubmit={onSubmit} isResetPassword />
}

export default DoctorResetPassword
