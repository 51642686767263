import Router from '@medical/routes/router'
import moment from 'moment'
import { Button } from 'primereact/button'
import React from 'react'
import { Link } from 'react-router-dom'

const AcceptedWorkSchedulesListHeader = ({
  date,
}) => {
  const isNextDisabled = date.isAfter(
    moment()
      .add(11, 'months')
      .endOf('month')
      .endOf('day')
  )
  const isPreviousDisabled = date.isBefore(
    moment()
      .subtract(1, 'months')
      .endOf('month')
      .endOf('day')
  )
  return (
    <div className='calendar-control'>
      <Link
        to={Router.get(Router.doctorAcceptedWorkSchedulesList, {
          year: date
            .clone()
            .subtract(1, 'month')
            .year(),
          month:
            date
              .clone()
              .subtract(1, 'month')
              .month() + 1,
        })}
        onClick={isPreviousDisabled ? e => e.preventDefault() : undefined}
      >
        <Button
          icon='pi pi-chevron-left'
          style={{ fontSize: '14px' }}
          disabled={isPreviousDisabled}
        />
      </Link>
      <div className='calendar-title'>{date.format('YYYY年M月')}</div>
      <Link
        to={Router.get(Router.doctorAcceptedWorkSchedulesList, {
          year: date
            .clone()
            .add(1, 'month')
            .year(),
          month:
            date
              .clone()
              .add(1, 'month')
              .month() + 1,
        })}
        onClick={isNextDisabled ? e => e.preventDefault() : undefined}
      >
        <Button
          icon='pi pi-chevron-right'
          style={{ fontSize: '14px' }}
          disabled={isNextDisabled}
        />
      </Link>
    </div>
  )
}
export default AcceptedWorkSchedulesListHeader
