import { DOCTOR_TOKEN_KEY, STAFF_TOKEN_KEY } from '@medical/constant'

const getUserType = () => {
  const doctorToken = localStorage.getItem(DOCTOR_TOKEN_KEY)
  const staffToken = localStorage.getItem(STAFF_TOKEN_KEY)
  if (doctorToken) {
    return 'DOCTOR'
  }
  if (staffToken) {
    return 'STAFF'
  }
  return 'ANONYMOUS'
}

const getToken = () => {
  const doctorToken = localStorage.getItem(DOCTOR_TOKEN_KEY)
  const staffToken = localStorage.getItem(STAFF_TOKEN_KEY)
  if (doctorToken) {
    return doctorToken
  }
  if (staffToken) {
    return staffToken
  }
  return null
}

const signout = ({ history, client, pathname }) => {
  client.resetStore()
  localStorage.clear()
  history.push({
    pathname,
  })
}

const Auth = {
  getUserType,
  getToken,
  signout,
}

export default Auth
