import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { useCustom } from '@medical/provider/context'
import React from 'react'
import { parseJwt } from '../DoctorWaitingRecruitment/DoctorWaitingRecruitment'
import {
  CLINICAL_DEPARTMENTS_SETUP_QUERY,
  DOCTOR_CREATE_WAITING_SHIFT,
  DOCTOR_DELETE_WAITING_RECRUITMENT_SHIFT,
  DOCTOR_UPDATE_WAITING_SHIFT,
  WAITING_RECRUITMENT_QUERY,
} from './CreateWaitingRecruitment.graphql'
import { CreateWaitingRecruitmentScene } from './CreateWaitingRecruitmentScene'
import queryString from 'query-string'
import moment from 'moment'
import {
  clinicalDepartmentNames,
  combineNames,
  sortAndFilterByClinicOrder,
} from '@medical/libs'

export const CreateWaitingRecruitment = ({
  match: {
    params: { id },
  },
  location: { state, search },
  closeModal,
}) => {
  let { clinicalDepartmentIds = [], dateFilter, page = 1 } = queryString.parse(
    search
  )

  const doctorId = parseJwt(localStorage.getItem('medical_doctor_token'))
    .doctorId

  const getWhereRefetch = () => {
    const where = {
      Doctor: {
        id: doctorId,
      },
      RecruitmentShift_some: {
        clinic_in: clinicalDepartmentIds,
      },
      startDate_lte: dateFilter
        ? new Date(
            moment(dateFilter)
              .endOf('day')
              .format('YYYY-MM-DD')
          )
        : new Date(
            moment(new Date())
              .endOf('day')
              .format('YYYY-MM-DD')
          ),
      endDate_gte: dateFilter
        ? new Date(
            moment(dateFilter)
              .startOf('day')
              .format('YYYY-MM-DD')
          )
        : new Date(
            moment(new Date())
              .startOf('day')
              .format('YYYY-MM-DD')
          ),
    }
    if (!dateFilter) {
      delete where.startDate_lte
      where.startDate_gte = new Date(
        moment(new Date())
          .endOf('day')
          .format('YYYY-MM-DD')
      )
    }
    if (clinicalDepartmentIds.length === 0) delete where.RecruitmentShift_some
    return where
  }

  const [{ i18n, popup }] = useCustom()
  const mutationOptions = {
    refetchQueries: [
      {
        query: WAITING_RECRUITMENT_QUERY,
        variables: {
          where: getWhereRefetch(),
          skip: (page - 1) * 10,
          first: 10,
        },
        awaitRefetchQueries: true,
      },
    ],
  }

  const { loading, error, data } = useQuery(CLINICAL_DEPARTMENTS_SETUP_QUERY)
  const [
    deleteRecruitmentShift,
    { loading: deleteLoading, error: deleteError, data: deleteData },
  ] = useMutation(DOCTOR_DELETE_WAITING_RECRUITMENT_SHIFT, mutationOptions)

  const [
    createWaitingRecruitment,
    { data: createData, error: createError, loading: createLoading },
  ] = useMutation(DOCTOR_CREATE_WAITING_SHIFT, mutationOptions)

  const [
    updateWaitingRecruitment,
    { data: updateData, error: updateError, loading: updateLoading },
  ] = useMutation(DOCTOR_UPDATE_WAITING_SHIFT, mutationOptions)

  if (loading || createLoading || updateLoading || deleteLoading)
    return <ProgressSpinner />
  if (error || createError || updateError || deleteError)
    return (
      <ErrorComponent
        error={error || createError || updateError || deleteError}
      />
    )

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    const {
      clinics,
      startDate,
      endDate,
      startTime,
      endTime,
      description,
    } = values
    try {
      if (!id) {
        await createWaitingRecruitment({
          variables: {
            clinics,
            startDate: moment.utc(startDate).startOf('day'),
            endDate: moment.utc(endDate).endOf('day'),
            startTime: moment.utc(startTime),
            endTime: moment.utc(endTime),
            description,
            doctorId,
          },
        })
        popup.success(i18n.t('doctor.waitingRecruitment.createShift.success'))
      } else {
        await updateWaitingRecruitment({
          variables: {
            doctorRecruitmentShiftUpdateId: id,
            clinics,
            startDate: moment.utc(startDate).startOf('day'),
            endDate: moment.utc(endDate).endOf('day'),
            startTime: moment.utc(startTime),
            endTime: moment.utc(endTime),
            description,
          },
        })
        popup.success(i18n.t('doctor.waitingRecruitment.updateShift.success'))
      }
      resetForm()
      setSubmitting(false)
      closeModal()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }

  const handleDeleteWaitingShift = async () => {
    try {
      await deleteRecruitmentShift({
        variables: {
          id,
        },
      })
      popup.success(i18n.t('doctor.waitingRecruitment.deleteShift.success'))
      closeModal()
    } catch (error) {
      popup.error(error)
    }
  }

  return (
    <CreateWaitingRecruitmentScene
      i18n={i18n}
      onSubmit={onSubmit}
      isCreateForm={!id}
      clinicalDepartments={combineNames({
        clinicalDepartments: sortAndFilterByClinicOrder({
          clinicalDepartments: data.clinicalDepartments,
          isFilter: true,
        }),
        departmentNames: clinicalDepartmentNames({
          departments: data.departments,
        }),
      })}
      deleteRecruitmentShift={handleDeleteWaitingShift}
      availableWaitingShift={state ? state.availableWaitingShift : null}
    />
  )
}
