import gql from 'graphql-tag'

export const QUERY_PORTAL = gql`
  query Portals($where: PortalWhereInput) {
    portals(where: $where) {
      id
      largeTitle
      mediumTitle
      smallTitle
      titleType
      content
    }
  }
`

export const DELETE_PORTAL = gql`
  mutation staffDeletePortal(
    $portalId: ID
    $isHide: Boolean
    $isDeleted: Boolean
  ) {
    staffDeletePortal(
      portalId: $portalId
      isHide: $isHide
      isDeleted: $isDeleted
    ) {
      id
      largeTitle
      mediumTitle
      smallTitle
      titleType
      content
    }
  }
`