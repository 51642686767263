import { ConfirmationModal } from '@medical/components'
import { TRANSPORTATION_TYPE_SETTINGS_ACTION } from '@medical/constant'
import { InputNumber, Switch } from 'antd'
import { ErrorMessage, Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import React from 'react'
import * as Yup from 'yup'

export const MovingExpenseDetailSettingScence = ({
  i18n,
  data,
  onSubmit,
  typeSettingData,
}) => {
  const newTypes = typeSettingData.map(item => ({
    value: item.type && item.type,
    description: item.type,
    maxCost: item.maxCost ? item.maxCost : undefined,
  }))
  const initialValues = {
    type: data.transportationTypeSetting.type || '',
    action: '',
    maxCost: data.transportationTypeSetting.maxCost
      ? data.transportationTypeSetting.maxCost
      : '',
    isApply: !!data.transportationTypeSetting.isApply,
    confirm: false,
  }
  const validationSchema = Yup.object().shape({
    maxCost: Yup.number()
      .nullable()
      .min(1, '上限金額を入力してください。')
      .required('上限金額を入力してください。'),
  })
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        render={formikProps => {
          const {
            values,
            setTouched,
            validateForm,
            setFieldValue,
          } = formikProps
          return (
            <div className='container move-expenses-container'>
              <div
                className='move-expenses-header__title'
                style={{ textAlign: 'center' }}
              >
                <span>その他交通費設定の編集</span>
              </div>
              <div className='tr'>
                <div className='th' style={{ width: '30%' }}>
                  {i18n.t(`main.movingExpensesSetting.preparationDay`)}
                </div>
                <div className='td' style={{ width: '70%' }}>
                  {moment(data.transportationTypeSetting.createAt).format(
                    'LL (dd)'
                  )}
                </div>
              </div>
              <div className='tr'>
                <div className='th' style={{ width: '30%' }}>
                  {i18n.t(`main.movingExpensesSetting.lastUpdateDate`)}
                </div>
                <div className='td' style={{ width: '70%' }}>
                  {moment(data.transportationTypeSetting.updateAt).format(
                    'LL (dd)'
                  )}
                </div>
              </div>
              <div className='tr'>
                <div className='th' style={{ width: '30%' }}>
                  {i18n.t(`main.movingExpensesSetting.author`)}
                </div>
                <div className='td' style={{ width: '70%' }}>
                  {data.transportationTypeSetting.createdBy.lastname}
                  {' '}
                  {data.transportationTypeSetting.createdBy.firstname}
                </div>
              </div>
              <div className='tr'>
                <div className='th' style={{ width: '30%' }}>
                  {i18n.t(`main.movingExpensesSetting.lastUpdateby`)}
                </div>
                <div className='td' style={{ width: '70%' }}>
                  {data.transportationTypeSetting.updatedBy.lastname}
                  {' '}
                  {data.transportationTypeSetting.updatedBy.firstname}
                </div>
              </div>
              <div className='tr'>
                <div className='th' style={{ width: '30%' }}>
                  {i18n.t(`main.movingExpensesSetting.maxCost`)}
                </div>
                <div className='td'>
                  <InputNumber
                    {...formikProps}
                    value={values.maxCost}
                    name='maxCost'
                    step={10}
                    min={0}
                    onChange={e => {
                      setFieldValue('maxCost', e)
                    }}
                    style={{ width: '70%' }}
                  />
                  <span style={{ marginLeft: 5 }}>円</span>
                  <ErrorMessage
                    name='maxCost'
                    component='div'
                    className='signup-alert'
                  />
                </div>
              </div>
              <div className='tr'>
                <div className='th' style={{ width: '30%' }}>
                  {i18n.t(`main.movingExpensesSetting.movingExpensesType`)}
                </div>
                <div className='td'>
                  <Dropdown
                    {...formikProps}
                    value={newTypes.find(item => item.value === values.type)}
                    name='type'
                    placeholder='申請区分入力'
                    optionLabel='description'
                    valueLabel='value'
                    options={newTypes}
                    disabled
                  />
                </div>
              </div>
              <div className='tr'>
                <div
                  className='th'
                  style={{ width: '30%', whiteSpace: 'normal' }}
                >
                  {i18n.t(`main.movingExpensesSetting.settingIsApply`)}
                </div>
                <div className='td' style={{ padding: 0 }}>
                  <div
                    className='td toggle-published'
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      width: '80%',
                    }}
                  >
                    <Switch
                      {...formikProps}
                      checkedChildren='表示'
                      unCheckedChildren='非表示'
                      name='isApply'
                      checked={values.isApply}
                      onChange={e => {
                        setFieldValue('isApply', e)
                      }}
                    />
                  </div>
                </div>
              </div>
              <div style={{ textAlign: 'center' }}>
                <Button
                  style={{ marginRight: '5px' }}
                  label='削除する'
                  type='submit'
                  onClick={() => {
                    setFieldValue('confirm', true)
                    setFieldValue(
                      'action',
                      TRANSPORTATION_TYPE_SETTINGS_ACTION.DELETE
                    )
                  }}
                />
                <Button
                  style={{ marginLeft: '5px' }}
                  label='更新する'
                  type='submit'
                  className='reflect-button'
                  onClick={() => {
                    validateForm().then(response => {
                      if (!Object.keys(response).length) {
                        setFieldValue(
                          'action',
                          TRANSPORTATION_TYPE_SETTINGS_ACTION.UPDATE
                        )
                        setFieldValue('confirm', true)
                      } else {
                        setTouched(response)
                      }
                    })
                  }}
                />
              </div>
              <ConfirmationModal
                {...formikProps}
                confirmationMessage={
                  values.action === 'update'
                    ? i18n.t('main.movingExpensesSetting.confirmUpdate')
                    : i18n.t('main.movingExpenses.deleteConfirmation')
                }
              />
            </div>
          )
        }}
      />
    </>
  )
}
