import { useMutation } from '@apollo/react-hooks'
import {
  STAFF_CHECK_DOCTOR_CSV,
  STAFF_UPLOAD_DOCTOR_CSV,
} from '@medical/constant/permissions'
import { checkStaffPermission, convertDoctorCsvRecord } from '@medical/libs'
import Auth from '@medical/middleware/auth'
import { useCustom } from '@medical/provider/context'
import React, { useState } from 'react'

import { CHECK_UPLOAD_DOCTORCSV, UPLOAD_DOCTOR_CSV } from './DoctorCsv.graphql'
import DoctorCsvScene from './DoctorCsvScene'

const DoctorCsv = () => {
  const [{ i18n, popup }] = useCustom()
  const [uploadDoctorCsv] = useMutation(UPLOAD_DOCTOR_CSV)
  const [checkUploadDoctorCsv] = useMutation(CHECK_UPLOAD_DOCTORCSV)
  const [state, setState] = useState({
    isUploaded: false,
  })
  const isUploadCheckPermitted = checkStaffPermission({
    functionName: STAFF_CHECK_DOCTOR_CSV,
  })
  const isUploadPermitted = checkStaffPermission({
    functionName: STAFF_UPLOAD_DOCTOR_CSV,
  })

  const onSubmit = async ({ data: UserCsvData }, { resetForm }) => {
    await setState({
      isUploaded: true,
    })
    try {
      if (!isUploadPermitted) {
        popup.error(i18n.t('main.noPermission'))
      } else {
        popup.info(i18n.t('staff.uploadCsv.uploading'))
        const doctorCsvRecord = convertDoctorCsvRecord(UserCsvData)
        await uploadDoctorCsv({
          variables: {
            doctorCsvRecord,
          },
        })
        popup.clear()
        popup.success(i18n.t('staff.uploadCsv.submissionSuccess'))
        resetForm()
      }
    } catch (error) {
      popup.clear()
      popup.error(error)
    }
    await setState({
      isUploaded: false,
    })
  }

  const checkCsvData = async ({ data: UserCsvData }, { setFieldValue }) => {
    try {
      if (!isUploadPermitted) {
        popup.error(i18n.t('main.noPermission'))
      } else {
        const doctorCsvRecord = convertDoctorCsvRecord(UserCsvData)
        const {
          data: { staffCheckUploadDoctorCsv },
        } = await checkUploadDoctorCsv({
          variables: {
            doctorCsvRecord,
          },
        })
        const messageAddedCsvData = UserCsvData.map((item, index) => ({
          ...item,
          errorMessage: staffCheckUploadDoctorCsv[index].errorMessage,
          checked: true,
        }))
        setFieldValue('data', messageAddedCsvData)
        popup.clear()
        popup.success(i18n.t('staff.uploadCheckCsv.submissionSuccess'))
      }
    } catch (error) {
      popup.clear()
      popup.error(error)
    }
  }

  const downloadSample = () => {
    window.location.href = `${
      process.env.REACT_APP_ENDPOINT
    }/files/医師情報サンプルcsvイメージ.csv?token=${Auth.getToken()}`
  }

  return (
    <DoctorCsvScene
      popup={popup}
      i18n={i18n}
      onSubmit={onSubmit}
      downloadSample={downloadSample}
      isUploaded={state.isUploaded}
      checkCsvData={checkCsvData}
      isUploadPermitted={isUploadPermitted}
      isUploadCheckPermitted={isUploadCheckPermitted}
    />
  )
}

export default DoctorCsv
