import Router from '@medical/routes/router'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import React from 'react'
import { useTranslation } from 'react-i18next'

const DoctorCartConfirmation = ({
  values,
  isSubmitting,
  handleSubmit,
  handleNoClick,
  setFieldValue,
  confirmationMessage,
  children,
  removeItemFromCart,
  visibleKey = 'confirm',
}) => {
  const { i18n } = useTranslation()

  const redirect = () => {
    const now = moment()
    const fileUrl = Router.get(Router.doctorAvailableShifts, {
      year: now.year(),
      month: now.month() + 1,
    })
    window.location.href = fileUrl
  }
  const noClick = () => {
    setFieldValue(visibleKey, false)
  }

  return (
    <Dialog
      header={i18n.t('main.confirmation')}
      visible={values[visibleKey]}
      className='p-dialog'
      footer={
        <div>
          <Button
            type='submit'
            label='同意する'
            icon={`pi pi-${isSubmitting ? 'spinner pi-spin' : 'check'}`}
            disabled={isSubmitting}
            onClick={handleSubmit}
          />
          <Button
            label='同意しない'
            icon='pi pi-times'
            onClick={() =>
              values.items.forEach(item => {
                setFieldValue('items', [])
                removeItemFromCart(item.id)
              }) ||
              noClick() ||
              redirect()
            }
            className='p-button-secondary'
            disabled={isSubmitting}
          />
        </div>
      }
      closable={!isSubmitting}
      onHide={handleNoClick || noClick}
    >
      {children}
      {confirmationMessage}
    </Dialog>
  )
}

export default DoctorCartConfirmation
