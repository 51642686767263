import React, { useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { IMAGE_ERROR } from '@medical/constant'
import { Button } from 'primereact/button'
import { ProgressSpinner } from '@medical/components'

export const PreviewImgDialog = ({
  i18n,
  visible,
  dataImage,
  handleClose,
  footerLabel,
  handleDownloadImg,
  loadingVisible = true,
}) => {
  const [loading, setLoading] = useState()
  const [img, setImg] = useState()
  useEffect(() => {
    if (dataImage) {
      setImg(dataImage)
      loadingVisible && setLoading(true)
    }
  }, [dataImage])
  const onLoadImg = () => {
    setLoading(false)
  }
  return (
    <div className='preview-img-dialog'>
      <Dialog
        closeOnEscape
        onShow={() => loadingVisible && setLoading(true)}
        style={{ width: 'calc(300px + 2rem)', textAlign: 'left' }}
        header={i18n.t('main.confirmation')}
        className='p-dialog'
        visible={visible && img}
        onHide={handleClose}
        footer={
          handleDownloadImg && footerLabel ? (
            <div style={{ textAlign: 'center' }}>
              <Button
                type='submit'
                label={footerLabel}
                onClick={handleDownloadImg}
                icon='pi pi-cloud-download'
                className='p-button-secondary'
              />
            </div>
          ) : null
        }
      >
        {loading && <ProgressSpinner />}
        <img
          onLoad={onLoadImg}
          onError={() => {
            setImg(IMAGE_ERROR)
            setLoading(false)
          }}
          width={300}
          height={300}
          src={img || IMAGE_ERROR}
          style={{ display: loading ? 'none' : 'unset' }}
        />
      </Dialog>
    </div>
  )
}
