import './style.css'

import BreadCrumb from '@medical/components/Breadcrumb'
import ModalLink from '@medical/components/ModalLink'
import Router from '@medical/routes/router'
import { Formik } from 'formik'
import { Button } from 'primereact/button'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'

const DoctorSkillScene = ({
  i18n,
  edges,
  isCreatePermitted,
  isUpdatePermitted,
  isDeletePermitted,
}) => {
  return (
    <React.Fragment>
      <div className='staff-header'>
        <div className='staff-title'>
          医師スキル情報設定
          <BreadCrumb
            items={[
              {
                title: '医師一覧',
              },
              {
                title: '医師スキル情報設定',
              },
            ]}
          />
        </div>
        <div className='staff-buttons'>
          {/* <Button
            icon='pi pi-download'
            className='p-button-normal'
            label='CSVダウンロード'
            onClick={download}
          />
          <p className='csv-note'>※マイナンバー情報が含まれます</p> */}
        </div>
      </div>
      <div className='container is-max'>
        <div className='box'>
          <div className='search-parent' style={{ justifyContent: 'end' }}>
            <Link to={Router.get(Router.staffDoctors)}>
              <Button
                className='cancel-button'
                label='戻る'
                style={{ marginRight: '20px' }}
              />
            </Link>
            {isCreatePermitted ? (
              <ModalLink to={Router.get(Router.staffCreateDoctorSkill)}>
                <Button
                  icon='pi pi-plus'
                  // className='p-button-normal'
                  label='追加'
                />
              </ModalLink>
            ) : null}
          </div>
          <div style={{ fontSize: '12px', marginBottom: '20px' }}>
            ※医師スキル編集時の注意：スキル情報を追加したら、必ず「登録する」ボタンを押してください。
            スキル名を編集しても、すでに医師プロフィール上に登録してある可OR不可の情報は編集ができませんのでご注意ください。
          </div>
          <Formik
            initialValues={{
              confirm: false,
            }}
            render={formikProps => {
              return (
                <div className='overflow-container'>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th width='12%'>作成日</th>
                        <th width='12%'>最後更新日</th>
                        <th width='20%'>スキル名</th>
                        <th width='13%'>作成者</th>
                        <th width='13%'>最後更新者</th>
                        {isUpdatePermitted || isDeletePermitted ? (
                          <th width='10%'></th>
                        ) : null}
                      </tr>
                    </thead>
                    <tbody>
                      {edges.map(({ node }) => (
                        <tr
                          key={node.id}
                          style={
                            node.isShow
                              ? { textAlign: 'center' }
                              : { textAlign: 'center', backgroundColor:'#dadee0' }
                          }
                        >
                          <td>
                            {moment(node.createdAt).format('YYYY年MM月DD日')}
                          </td>
                          <td>
                            {moment(node.updatedAt).format('YYYY年MM月DD日')}
                          </td>
                          <td>{node.name}</td>
                          <td>
                            {`${node.createdBy.lastname} ${node.createdBy.firstname}`}
                          </td>
                          <td>
                            {`${node.updatedBy.lastname} ${node.updatedBy.firstname}`}
                          </td>
                          {isUpdatePermitted || isDeletePermitted ? (
                            <td>
                              <ModalLink
                                to={Router.get(Router.staffDetailDoctorSkill, {
                                  skillId: node.id,
                                })}
                              >
                                <Button icon='pi pi-info' />
                              </ModalLink>
                            </td>
                          ) : null}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )
            }}
          />
        </div>
      </div>
    </React.Fragment>
  )
}
export default DoctorSkillScene
