import { DOCTOR_FRAGMENTS } from '@medical/layout/DoctorLayout/DoctorLayoutRequest.graphql'
import gql from 'graphql-tag'

export const BANK_ACCOUNT_FRAGMENTS = gql`
  fragment BANK_ACCOUNT_FRAGMENTS on BankAccount {
    id
    bankName
    bankCode
    branchName
    branchCode
    accountType
    accountNumber
    accountHolderName
  }
`

export const DOCTOR_BANK_ACCOUNT = gql`
  query DOCTOR_BANK_ACCOUNT {
    doctorMe {
      bankAccount {
        ...BANK_ACCOUNT_FRAGMENTS
      }
      ...DOCTOR_FRAGMENTS
    }
  }
  ${DOCTOR_FRAGMENTS}
  ${BANK_ACCOUNT_FRAGMENTS}
`

export const DOCTOR_UPSERT_BANK_ACCOUNT = gql`
  mutation DOCTOR_BANK_ACCOUNT(
    $bankName: String!
    $bankCode: String
    $branchName: String!
    $branchCode: String
    $accountType: String!
    $accountNumber: String!
    $accountHolderName: String!
  ) {
    doctorUpsertBankAccount(
      bankName: $bankName
      bankCode: $bankCode
      branchName: $branchName
      branchCode: $branchCode
      accountType: $accountType
      accountNumber: $accountNumber
      accountHolderName: $accountHolderName
    ) {
      bankAccount {
        ...BANK_ACCOUNT_FRAGMENTS
      }
      ...DOCTOR_FRAGMENTS
    }
  }
  ${DOCTOR_FRAGMENTS}
  ${BANK_ACCOUNT_FRAGMENTS}
`
