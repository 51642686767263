import { WORK_SCHEDULE_FRAGMENTS } from '@medical/pages/doctor/Schedule/Schedule.graphql'
import gql from 'graphql-tag'

export const STAFF_CREATE_WORK_SCHEDULE = gql`
  mutation STAFF_CREATE_WORK_SCHEDULE(
    $clinicalDepartmentId: ID!
    $doctorId: ID!
    $startTime: DateTime!
    $endTime: DateTime!
    $hourlyWage: Int!
    $splitDateTime1: DateTime
    $splitDateTime2: DateTime
    $splitDateTime3: DateTime
    $splitHourlyWage1: Int
    $splitHourlyWage2: Int
    $splitHourlyWage3: Int
    $isStartTimeBreakTime: Boolean
    $isSplitDateTime1BreakTime: Boolean
    $isSplitDateTime2BreakTime: Boolean
    $isSplitDateTime3BreakTime: Boolean
    $comment: String
    $staffComments: [WorkScheduleCommentCreateInput!]
    $doctorSubsidy: [DoctorSubsidyForCreateInput!]
    $isPublished: Boolean
    $isDoubleRecruitment: Boolean
  ) {
    staffCreateAcceptedWorkSchedule(
      clinicalDepartmentId: $clinicalDepartmentId
      doctorId: $doctorId
      startTime: $startTime
      endTime: $endTime
      hourlyWage: $hourlyWage
      splitDateTime1: $splitDateTime1
      splitDateTime2: $splitDateTime2
      splitDateTime3: $splitDateTime3
      splitHourlyWage1: $splitHourlyWage1
      splitHourlyWage2: $splitHourlyWage2
      splitHourlyWage3: $splitHourlyWage3
      isStartTimeBreakTime: $isStartTimeBreakTime
      isSplitDateTime1BreakTime: $isSplitDateTime1BreakTime
      isSplitDateTime2BreakTime: $isSplitDateTime2BreakTime
      isSplitDateTime3BreakTime: $isSplitDateTime3BreakTime
      staffComments: $staffComments
      comment: $comment
      doctorSubsidy: $doctorSubsidy
      isPublished: $isPublished
      isDoubleRecruitment: $isDoubleRecruitment
    ) {
      ...WORK_SCHEDULE_FRAGMENTS
    }
  }
  ${WORK_SCHEDULE_FRAGMENTS}
`

export const STAFF_CHECK_AVAILABLE_SHIFT_CREATE_IN_CLINIC_WORK_TIME = gql`
  mutation STAFF_CHECK_AVAILABLE_SHIFT_CREATE_IN_CLINIC_WORK_TIME(
    $clinicalDepartmentId: ID!
    $date: DateTime!
    $startTime: DateTime!
    $endTime: DateTime!
    $isHoliday: Boolean!
  ) {
    staffCheckAvailableShiftCreateInClinicWorkTime(
      clinicalDepartmentId: $clinicalDepartmentId
      date: $date
      startTime: $startTime
      endTime: $endTime
      isHoliday: $isHoliday
    )
  }
`
export const STAFF_CHECK_DOCTOR_DEFAULT_HOURLY_WAGE = gql`
  query Query(
    $doctorId: ID
    $clinicalDepartmentId: ID
    $date: DateTime!
    $isHoliday: Boolean!
  ) {
    doctorDefaultHourlyWage(
      doctorId: $doctorId
      clinicalDepartmentId: $clinicalDepartmentId
      date: $date
      isHoliday: $isHoliday
    )
  }
`
