import { CLINIC_FRAGMENT } from '@medical/pages/staff/Clinics/ClinicsList/ClinicsList.graphql'
import gql from 'graphql-tag'

export const UPDATE_CLINIC_TREATMENT_RESULT = gql`
  mutation UPDATE_CLINIC_TREATMENT_RESULT(
    $clinicId: ID!
    $numberPatient: Int
    $maleAgeFrom: Int
    $maleAgeTo: Int
    $femaleAgeFrom: Int
    $femaleAgeTo: Int
    $kindOfDisease: String
    $vaccine: String
    $referHospital: String
  ) {
    staffUpdateClinicTreatmentResult(
      clinicId: $clinicId
      numberPatient: $numberPatient
      maleAgeFrom: $maleAgeFrom
      maleAgeTo: $maleAgeTo
      femaleAgeFrom: $femaleAgeFrom
      femaleAgeTo: $femaleAgeTo
      kindOfDisease: $kindOfDisease
      vaccine: $vaccine
      referHospital: $referHospital
    ) {
      ...CLINIC_FRAGMENT
    }
  }
  ${CLINIC_FRAGMENT}
`
