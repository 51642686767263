import CustomCheckBox from '@medical/components/CheckBox'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import CustomDropdown from '@medical/components/Dropdown'
import TextInput from '@medical/components/TextInput'
import { phoneRegExp } from '@medical/constant'
import Router from '@medical/routes/router'
import { Descriptions } from 'antd'
import { Formik } from 'formik'
import { Button } from 'primereact/button'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'

const ClinicForm = ({
  update,
  clinic = {},
  departments,
  submitLabel,
  confirmationMessage,
  onSubmit,
  disabled,
  parentPath,
  region,
  isClinicUpdate,
}) => {
  const optionsRegion = region?.map(({ id, name }) => ({
    value: id,
    description: name,
  }))
  const { i18n } = useTranslation()
  const validationSchema = Yup.object().shape({
    clinicName: Yup.string()
      .nullable()
      .required(
        `${i18n.t('main.clinicName')} ${i18n.t('validation.required')}`
      ),
    clinicNameKana: Yup.string()
      .nullable()
      .required(
        `${i18n.t('main.clinicNameKana')} ${i18n.t('validation.required')}`
      ),
    address: Yup.string()
      .nullable()
      .required(`${i18n.t('main.address')} ${i18n.t('validation.required')}`),
    emailAddress: Yup.string()
      .email(i18n.t('validation.email.email'))
      .nullable()
      .required(`${i18n.t('main.email')} ${i18n.t('validation.required')}`),
    phoneNumber: Yup.string()
      .matches(phoneRegExp, i18n.t('main.phoneNumberNotValid'))
      .nullable()
      .required(
        `${i18n.t('main.phoneNumber')} ${i18n.t('validation.required')}`
      ),
    inquiryPhoneNumber: Yup.string()
      .matches(phoneRegExp, i18n.t('main.phoneNumberNotValid'))
      .nullable()
      .required(
        `${i18n.t('main.phoneNumber')} ${i18n.t('validation.required')}`
      ),
    departmentDisplays: Yup.array()
      .of(Yup.string())
      .min(1, i18n.t('validation.departmentDisplays.required')),
  })

  const { clinicalDepartments = [] } = clinic
  return (
    <div>
      <Formik
        initialValues={{
          confirm: false,
          clinicName: clinic.name,
          clinicNameKana: clinic.nameKana,
          address: clinic.address,
          emailAddress: clinic.email,
          phoneNumber: clinic.phoneNumber,
          inquiryPhoneNumber: clinic.inquiryPhoneNumber,
          departmentName: clinicalDepartments.map(({ name }) => name),
          departmentDisplays: clinicalDepartments
            .filter(it => it.isDisplay === true)
            .map(({ name }) => name),

          region: clinic.region,
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        render={formikProps => {
          const {
            validateForm,
            setTouched,
            setFieldValue,
            values,
          } = formikProps
          const props = { ...formikProps, i18nKey: 'main' }
          return (
            <div>
              <TextInput
                {...props}
                name='clinicName'
                label={i18n.t('main.clinicName')}
                disabled={disabled}
              />
              <TextInput
                {...props}
                name='clinicNameKana'
                label={i18n.t('main.clinicNameKana')}
                disabled={disabled}
              />
              <div>
                <CustomDropdown
                  {...props}
                  name='region'
                  options={optionsRegion}
                  disabled={disabled}
                  label='エリア'
                  style={{ width: '30%' }}
                  isClinic={true}
                  isClinicUpdate={isClinicUpdate}
                />
              </div>
              <TextInput
                {...props}
                name='address'
                label={i18n.t('main.address')}
                disabled={disabled}
              />
              <TextInput
                {...props}
                name='emailAddress'
                label={i18n.t('main.email')}
                disabled={disabled}
              />
              <TextInput
                {...props}
                id='phoneNumber'
                name='phoneNumber'
                label={i18n.t('main.phoneNumber')}
                disabled={disabled}
              />
              <TextInput
                {...props}
                value={values.inquiryPhoneNumber}
                id='inquiryPhoneNumber'
                name='inquiryPhoneNumber'
                label='問い合わせ用電話番号'
                disabled={disabled}
              />
              <CustomCheckBox
                {...props}
                name='departmentName'
                label={i18n.t('main.departmentName')}
                options={departments}
                disabled={!!update}
              />
              <CustomCheckBox
                {...props}
                name='departmentDisplays'
                label={i18n.t('main.departmentDisplay')}
                options={departments.filter(it =>
                  values.departmentName.includes(it.value)
                )}
                disabled={disabled}
              />
              {!disabled && (
                <div className='modal-bottom'>
                  <Link
                    to={
                      parentPath.pathname +
                      Router.get(Router.staffClinicDetail, {
                        clinicId: clinic.id,
                      })
                    }
                  >
                    <Button
                      label={i18n.t('main.cancel')}
                      className='p-button-secondary'
                    />
                  </Link>
                  <Button
                    label={submitLabel}
                    onClick={() => {
                      validateForm().then(response => {
                        if (!Object.keys(response).length) {
                          setFieldValue('confirm', true)
                        } else {
                          setTouched(response)
                        }
                      })
                    }}
                  />
                </div>
              )}
              <ConfirmationModal
                {...formikProps}
                confirmationMessage={confirmationMessage}
                isStaffUpdateAddressClinic={clinic.address !== values.address}
              />
            </div>
          )
        }}
      />
    </div>
  )
}

export default ClinicForm
