import { useApolloClient } from '@apollo/react-hooks'
import { STAFF_ME } from '@medical/layout/StaffLayout/StaffLayout.graphql'

export const checkIsGoldWare = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const client = useApolloClient()
  const { staffMe } = client.readQuery({
    query: STAFF_ME,
  })
  const { isGoldWare } = staffMe
  return isGoldWare
}
