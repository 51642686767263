import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, NotFound, ProgressSpinner } from '@medical/components'
import { clinicalDepartmentNames } from '@medical/libs'
import { useCustom } from '@medical/provider/context'
import React, { useEffect } from 'react'

import { DOCTOR_AVAILABLE_SHIFT } from './SingleAvailableShift.graphql'
import SingleAvailableShiftScene from './SingleAvailableShiftScene'

const SingleAvailableShift = ({
  match: {
    params: { availableShiftId },
  },
}) => {
  const [{ i18n }] = useCustom()
  const { loading, error, data, refetch } = useQuery(DOCTOR_AVAILABLE_SHIFT, {
    variables: {
      availableShiftId,
    },
  })
  useEffect(() => {
    refetch()
  })
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const { availableShift = {}, departments } = data
  if (!availableShift) {
    return <NotFound />
  }
  const departmentNames = clinicalDepartmentNames({
    departments,
  })
  return (
    <SingleAvailableShiftScene
      i18n={i18n}
      availableShift={availableShift}
      departmentNames={departmentNames}
    />
  )
}

export default SingleAvailableShift
