import { STAFF_FRAGMENTS } from '@medical/layout/StaffLayout/StaffLayout.graphql'
import gql from 'graphql-tag'

export const STAFF_EMAIL_CONFIRMATION = gql`
  mutation STAFF_EMAIL_CONFIRMATION(
    $confirmationNumber: String!
    $newPassword: String!
  ) {
    staffEmailConfirmation(
      confirmationNumber: $confirmationNumber
      newPassword: $newPassword
    ) {
      ...STAFF_FRAGMENTS
    }
  }
  ${STAFF_FRAGMENTS}
`

export const STAFF_CHECK_CONFIRMATION_NUMBER = gql`
  query STAFF_CHECK_CONFIRMATION_NUMBER($confirmationNumber: String!) {
    staffCheckConfirmationNumber(confirmationNumber: $confirmationNumber)
  }
`
