import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { STAFF_CREATE_INCENTIVE_PERMISSIONS } from '@medical/constant/permissions'
import {
  checkStaffPermission,
  clinicalDepartmentNames,
  combineNames,
  convertDoctors,
  convertDoctorsConnection,
  sortAndFilterByClinicOrder,
} from '@medical/libs'
import {
  DEPARTMENTS_LIST,
  // DOCTOR_CONNECTION_DEPARTMENT,
  DOCTOR_CONNECTION,
  DOCTORS_DEPARTMENTS,
} from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import { STAFF_CREATE_INCENTIVE } from '@medical/pages/staff/Incentive/IncentiveSetting/component/incentiveSetting.graphql'
import IncentiveSettingForm from '@medical/pages/staff/Incentive/IncentiveSetting/component/IncentiveSettingForm'
import { useCustom } from '@medical/provider/context'
import moment from 'moment'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'

const CreateIncentiveSetting = ({
  match: {
    params: { departmentId },
  },
  parentPath,
  location: { search },
  closeModal,
}) => {
  const [fullName, setFullName] = useState('')
  const [{ i18n, popup }] = useCustom()
  const { loading, error, data } = useQuery(DEPARTMENTS_LIST)
  // const {
  //   loading: doctorLoading,
  //   error: doctorError,
  //   data: doctorData,
  // } = useQuery(DOCTORS_DEPARTMENTS)
  const {
    loading: dataDoctorConnectionLoading,
    error: dataDoctorConnectionError,
    data: dataDoctorConnection,
  } = useQuery(DOCTOR_CONNECTION, {
    variables: {
      where: {
        deletedAt: null,
      },
      first: 10,
      skip: 0,
      orderBy: 'unSigned_ASC',
    },
  })
  const isCreatePermitted = checkStaffPermission({
    functionName: STAFF_CREATE_INCENTIVE_PERMISSIONS,
  })
  const [staffCreateIncentive] = useMutation(STAFF_CREATE_INCENTIVE)
  if (loading || dataDoctorConnectionLoading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  if (dataDoctorConnectionError)
    return <ErrorComponent data={dataDoctorConnectionError} />
  const { clinicalDepartments, departments } = data
  const sortedClinicalDepartments = sortAndFilterByClinicOrder({
    clinicalDepartments,
    isFilter: true,
  })
  const departmentNames = clinicalDepartmentNames({ departments })
  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    let {
      title,
      incentiveType,
      workPartten,
      objectTarget,
      doctor,
      selectedDoctor,
      rangeDate,
      cost,
    } = values
    try {
      if (!isCreatePermitted) {
        popup.error(i18n.t('main.noPermission'))
        setSubmitting(false)
      } else {
        const listDoctorId = []
        if (doctor && Array.isArray(doctor)) {
          for (let i = 0; i < doctor.length; i += 1) {
            listDoctorId.push(doctor[i].id)
          }
        }
        const startTimeFormat = moment(rangeDate[0])
          .startOf('month')
          .format()
          .split('+')[0]
        const endTimeFormat =
          rangeDate.length > 1
            ? moment(rangeDate[1])
                .startOf('month')
                .format()
                .split('+')[0]
            : moment(rangeDate[0])
                .startOf('month')
                .format()
                .split('+')[0]
        await staffCreateIncentive({
          variables: {
            title,
            type: incentiveType.value,
            applyWith: objectTarget.value,
            workPatternApply: workPartten,
            listDoctorId,
            startTime: startTimeFormat,
            endTime: endTimeFormat,
            amount: parseInt(cost),
          },
        })
        popup.success(i18n.t('staff.createShift.submissionSuccess'))
        closeModal()
        setSubmitting(false)
        resetForm()
      }
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }
  const { edges } = dataDoctorConnection.doctorsConnection
  return (
    <IncentiveSettingForm
      onSubmit={onSubmit}
      clinicalDepartments={combineNames({
        clinicalDepartments: sortedClinicalDepartments,
        departmentNames,
      })}
      // doctors={convertDoctors(doctors)}
      doctors={convertDoctorsConnection(edges)}
      isCreateIncentive
      confirmationMessage={i18n.t('main.incentiveSetting.actionMessage.create')}
      i18n={i18n}
    />
  )
}

export default CreateIncentiveSetting
