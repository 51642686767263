import { useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { STAFF_CREATE_RECRUITMENT_POST } from '@medical/constant/permissions'
import {
  checkStaffPermission,
  clinicalDepartmentNames,
  combineNames,
  onPageChange,
  sortAndFilterByClinicOrder,
} from '@medical/libs'
import { DOCTORS_DEPARTMENTS } from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import { useCustom } from '@medical/provider/context'
import queryString from 'query-string'
import React from 'react'

import { RECRUITMENT_POSTS_CONNECTION } from './RecruitmentPost.graphql'
import RecruitmentPostsListScene from './RecruitmentPostsListScene'

const RecruitmentPostsList = ({ history, location: { search } }) => {
  const [{ i18n }] = useCustom()
  const {
    page = 1,
    rowsPerPage = 10,
    orderBy = 'createdAt_DESC',
    title,
    clinicalDepartmentIds,
  } = queryString.parse(search)
  const first = parseInt(rowsPerPage, 10)
  const skip = (parseInt(page, 10) - 1) * parseInt(rowsPerPage, 10)
  const clinicalDepartmentIdsSearch =
    clinicalDepartmentIds && clinicalDepartmentIds.length > 0
      ? clinicalDepartmentIds.split('＿')
      : undefined

  const clinicalDepartmentCondition = [
    {
      title_contains: title || undefined,
      ClinicalDepartment: clinicalDepartmentIdsSearch?.includes('other')
        ? {
            id_in: clinicalDepartmentIdsSearch.filter(item => item !== 'other'),
          }
        : clinicalDepartmentIdsSearch && {
            id_in: clinicalDepartmentIdsSearch,
          },
    },
  ]

  if (clinicalDepartmentIdsSearch?.includes('other')) {
    clinicalDepartmentCondition.push({
      title_contains: title || undefined,
      ClinicalDepartment: null,
    })
  }

  let variables = {
    first: first < 0 ? 10 : first,
    skip: skip < 0 ? 0 : skip,
    orderBy,
    where: {
      OR: clinicalDepartmentCondition,
    },
  }
  if (title)
    variables = {
      where: {
        title_contains: title,
        ClinicalDepartment: {
          id_in: clinicalDepartmentIdsSearch,
        },
      },
      first: first < 0 ? 10 : first,
      skip: skip < 0 ? 0 : skip,
      orderBy,
    }
  const isCreatePermitted = checkStaffPermission({
    functionName: STAFF_CREATE_RECRUITMENT_POST,
  })
  const { loading, error, data } = useQuery(RECRUITMENT_POSTS_CONNECTION, {
    variables,
    fetchPolicy: 'network-only',
  })
  const {
    loading: clinicalDepartmentLoading,
    error: clinicalDepartmentErr,
    data: clinicalDepartmentData,
  } = useQuery(DOCTORS_DEPARTMENTS)
  if (loading || clinicalDepartmentLoading) return <ProgressSpinner />
  if (error || clinicalDepartmentErr)
    return <ErrorComponent error={error || clinicalDepartmentErr} />

  const {
    recruitmentPostsConnection: {
      edges,
      aggregate: { count },
    },
  } = data
  const { clinicalDepartments, departments } = clinicalDepartmentData
  const departmentNames = clinicalDepartmentNames({ departments })
  const sortedClinicalDepartments = sortAndFilterByClinicOrder({
    clinicalDepartments,
    // isFilter: true,
  })
  return (
    <RecruitmentPostsListScene
      i18n={i18n}
      edges={edges}
      rowsPerPage={rowsPerPage}
      page={page}
      count={count}
      title={title}
      onPageChange={onPageChange(history, search)}
      isCreatePermitted={isCreatePermitted}
      history={history}
      clinicalDepartments={[
        {
          clinicName: 'なし',
          id: 'other',
        },
        ...combineNames({
          clinicalDepartments: sortedClinicalDepartments,
          departmentNames,
        }),
      ]}
      clinicalDepartmentIds={clinicalDepartmentIdsSearch}
    />
  )
}

export default RecruitmentPostsList
