export default {
  date: {
    margin: '５px',
    // fontWeight: 'bold',
  },
  content: {
    marginBottom: '20px',
    fontWeight: 'bold',
  },
  reply: {
    borderTop: '3px dotted #000',
    paddingTop: '10px',
  },
  container: {
    width: '100%',
  },
  title: {
    fontSize: '20px',
    textAlign: 'center',
    marginBottom: '10px',
  },
}
