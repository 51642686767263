import ConfirmationModal from '@medical/components/ConfirmationModal'
import ModalLink from '@medical/components/ModalLink'
import { PreviewImgDialog } from '@medical/components/PreviewImgDialog'
import Router from '@medical/routes/router'
import { Formik } from 'formik'
import { Button } from 'primereact/button'
import React, { useState } from 'react'

const styles = {
  title: {
    borderBottom: '1px solid #eee',
    paddingBottom: '10px',
    fontSize: '20px',
    marginTop: '20px',
    color: '#666',
  },
  description: {
    fontSize: '15px',
    marginTop: '20px',
    color: '#db7093',
    paddingBottom: '10px',
    fontWeight: 600
  },
  requireProfile: {
    color: '#db7093',
    fontSize: '15px',
  },
  requireFile: {
    color: '#db7093',
    fontSize: '15px',
  },
  th: {
    whiteSpace: 'initial',
  },
}

const MyPageScene = ({
  i18n,
  fileCategories,
  files,
  downloadFileSubmit,
  doctor,
  otherFileAll = [],
  imgPath,
  openPreviewImg,
  handleOpenPreview,
  onSubmit,
}) => {
  const [newOther, setNewOther] = useState(false)
  const createNewOther = () => {
    setNewOther(true)
  }
  return (
    <div className='container is-medium'>
      <div className='box'>
        <div style={styles.title}>保険診療・給与支払必須必要情報</div>
        <div style={styles.description}>
          ※必須項目は、保険診療・給与支払いに必要な情報です。必ずご入力ください。
        </div>
        <table className='modal-table' style={{ width: '90%' }}>
          <tbody>
            <tr>
              <th style={styles.th} width='25%'>
                {i18n.t('doctor.myPage.profile')}
              </th>
              <td width='25%'>
                <ModalLink to={Router.doctorUpdateProfile}>
                  <Button
                    label={i18n.t('doctor.myPage.updateProfile')}
                    icon='pi pi-pencil'
                    className='p-button-secondary'
                  />
                </ModalLink>
              </td>
              <td style={styles.requireProfile}>※必須項目</td>
            </tr>
            <tr>
              <th style={styles.th}>{i18n.t('doctor.myPage.password')}</th>
              <td>
                <ModalLink to={Router.doctorChangePassword}>
                  <Button
                    label={i18n.t('doctor.myPage.changePassword')}
                    icon='pi pi-lock'
                    className='p-button-secondary'
                  />
                </ModalLink>
              </td>
            </tr>
            <tr>
              <th style={styles.th}>{i18n.t('doctor.myPage.bankAccount')}</th>
              <td>
                <ModalLink to={Router.doctorBankAccount}>
                  <Button
                    label={
                      doctor.bankAccount
                        ? i18n.t('doctor.myPage.changeBankAccount')
                        : '新規登録'
                    }
                    icon='pi pi-money-bill'
                    className='p-button-secondary'
                  />
                </ModalLink>
              </td>
              <td style={styles.requireProfile}>※必須項目</td>
            </tr>
          </tbody>
        </table>
        <div style={styles.title}>{i18n.t('doctor.myPage.files')}</div>
        <div style={styles.description}>
          <p style={{margin: 0}}>※必須項目は、保険診療・給与支払いに必要な情報です。必ずご提出ください。</p>
          <p style={{margin: 0}}>※画像アップロード時に画像の状態を確認してください。登録された画像が下記状態の場合、再提出をお願いする可能性がございます。</p>
          <p style={{margin: 0}}>・画像が上下逆さまたは画像が横向き</p>
          <p style={{margin: 0}}>・ピントがあっていない、不鮮明</p>
          <p style={{margin: 0}}>・文字が読み取れない</p>
          <p style={{margin: 0}}>※png・ jpg・jpeg以外の画像はアップロードしないようにお願いいたします。（iPhoneの場合は　設定＞カメラ＞フォーマット　で「互換性優先」を選べばjpeg画像の撮影ができます。）</p>
        </div>
        <Formik
          initialValues={{
            confirm: false,
            key: '',
          }}
          onSubmit={onSubmit}
          render={formikProps => {
            const { setFieldValue } = formikProps
            return (
            <React.Fragment>
              <table className='modal-table' style={{ width: '90%' }}>
                <tbody>
                  {fileCategories.map(({ value, description }) => {
                    const doctorFile = files.find(
                      ({ fileCategory }) => value === fileCategory
                    )
                    if (value !== 'MY_NUMBER_CARD' && value !== 'OTHER')
                      return (
                        <tr key={value}>
                          <th style={styles.th} width='25%'>
                            {description}
                          </th>
                          <td width='25%'>
                            <ModalLink
                              to={Router.get(Router.doctorUploadFile, {
                                fileCategory: value,
                              })}
                            >
                              <Button
                                label={i18n.t('doctor.uploadFile.submit')}
                                disabled={!!doctorFile}
                                icon='pi pi-cloud-upload'
                                className='p-button-secondary sp-button'
                              />
                            </ModalLink>
                          </td>
                          {doctorFile ? (
                            <td width='25%'>
                              <PreviewImgDialog
                                i18n={i18n}
                                visible={openPreviewImg}
                                dataImage={imgPath}
                                handleClose={() => handleOpenPreview(false)}
                                footerLabel={i18n.t('main.downloadFile')}
                                handleDownloadImg={() => {
                                  setFieldValue('confirm', true)
                                }}
                              />
                              <Button
                                label={i18n.t('main.downloadFile')}
                                icon='pi pi-cloud-download'
                                className='p-button-secondary'
                                onClick={async () => {
                                  setFieldValue('key', doctorFile.key)
                                  await Promise.resolve()
                                  downloadFileSubmit(doctorFile.key)
                                }}
                              />
                            </td>
                          ) : (
                            <td width='25%'>
                              <p>提出中のファイルはありません</p>
                            </td>
                          )}
                          {value === 'DOCTOR_LICENSE' ? (
                            <td style={styles.requireFile}>※必須項目</td>
                          ) : (
                            undefined
                          )}
                          {value === 'INSURANCE_DOCTOR_REGISTRATION' ? (
                            <td style={styles.requireFile}>※必須項目</td>
                          ) : (
                            undefined
                          )}
                        </tr>
                      )
                  })}
                  {otherFileAll && otherFileAll.length === 0 ? (
                    <tr key='OTHER'>
                      <th style={styles.th} width='25%'>
                        その他
                      </th>
                      <td width='25%'>
                        <ModalLink
                          to={Router.get(Router.doctorUploadFile, {
                            fileCategory: 'OTHER',
                          })}
                        >
                          <Button
                            label={i18n.t('doctor.uploadFile.submit')}
                            icon='pi pi-cloud-upload'
                            className='p-button-secondary sp-button'
                          />
                        </ModalLink>
                      </td>
                      <td>
                        <p>提出中のファイルはありません</p>
                      </td>
                    </tr>
                  ) : null}
                  {otherFileAll && otherFileAll.length > 0
                    ? otherFileAll.map((otherFile, index) => (
                        <tr key='OTHER'>
                          <th style={styles.th} width='25%'>
                            {index === 0 ? 'その他' : null}
                          </th>
                          <td width='25%'>
                            <ModalLink
                              to={Router.get(Router.doctorUploadFileOther, {
                                fileCategory: 'OTHER',
                                fileId: otherFile.id,
                              })}
                            >
                              <Button
                                label={i18n.t('doctor.uploadFile.submit')}
                                icon='pi pi-cloud-upload'
                                className='p-button-secondary sp-button'
                              />
                            </ModalLink>
                          </td>
                          <td>
                            <Button
                              type='submit'
                              label={i18n.t('main.downloadFile')}
                              onClick={async () => {
                                formikProps.setFieldValue('key', otherFile.key)
                                await Promise.resolve()
                                downloadFileSubmit(otherFile.key)
                              }}
                              icon='pi pi-cloud-download'
                              className='p-button-secondary'
                            />
                          </td>
                        </tr>
                      ))
                    : null}
                  {newOther ? (
                    <tr key='OTHER'>
                      <th style={styles.th} width='25%' />
                      <td width='25%'>
                        <ModalLink
                          to={Router.get(Router.doctorUploadFile, {
                            fileCategory: 'OTHER',
                          })}
                        >
                          <Button
                            label={i18n.t('doctor.uploadFile.submit')}
                            icon='pi pi-cloud-upload'
                            className='p-button-secondary sp-button'
                          />
                        </ModalLink>
                      </td>
                      <td>
                        <p>提出中のファイルはありません</p>
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
              {otherFileAll && otherFileAll.length > 0 ? (
                <Button
                  style={{
                    color: '#FF0E6B',
                    marginLeft: '25%',
                    borderColor: '#FF0E6B',
                  }}
                  onClick={createNewOther}
                  label='＋追加で画像を提出する'
                />
              ) : null}
              <ConfirmationModal
                {...formikProps}
                confirmationMessage={i18n.t('main.downloadConfirmation')}
              />
            </React.Fragment>
          )}}
        />
        <div style={styles.title}>{i18n.t('doctor.myPage.myNumberArea')}</div>
        <table className='modal-table' style={{ width: '90%' }}>
          <tbody>
            <tr>
              <th style={styles.th} width='25%'>
                {i18n.t('doctor.myPage.myNumber')}
              </th>
              <td width='25%'>
                <ModalLink to={Router.DoctorCreateIdentity}>
                  <Button
                    label={
                      doctor.doctorIdentity && doctor.doctorIdentity.id
                        ? i18n.t('doctor.myPage.updateButton')
                        : i18n.t('doctor.myPage.createButton')
                    }
                    className='p-button-secondary'
                  />
                </ModalLink>
              </td>
              <td>
                <p>
                  {doctor.doctorIdentity && doctor.doctorIdentity.id
                    ? i18n.t('doctor.myPage.createdLabel')
                    : i18n.t('doctor.myPage.notCreatedYet')}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <div />
      </div>
    </div>
  )
}
export default MyPageScene
