import gql from 'graphql-tag'

export const SKILL_CONNECTIONS = gql`
  query SkillsConnection($skip: Int, $first: Int, $where: SkillWhereInput) {
    skillsConnection(skip: $skip, first: $first, where: $where) {
      aggregate(where: $where) {
        count
      }
      edges {
        node {
          id
          name
          isShow
        }
      }
    }
  }
`

