import { ConfirmationModal } from '@medical/components'
import BreadCrumb from '@medical/components/Breadcrumb'
import ModalLink from '@medical/components/ModalLink'
import Router from '@medical/routes/router'
import { Switch, Tabs } from 'antd'
import { Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import React, { useState } from 'react'

export const MovingExpenseSettingScence = ({
  i18n,
  data,
  onSubmit,
  dataExpiredDate,
}) => {
  const { TabPane } = Tabs
  const [isTab, setIsTab] = useState('1')
  const onChange = key => {
    setIsTab(key)
  }
  const options = () => {
    const result = []
    for (let i = 1; i <= 30; i += 1) {
      result.push({ value: i, description: `${i}` })
    }
    result.push({ value: 31, description: '月末' })
    return result
  }
  return (
    <>
      <div className='container is-medium'>
        <div className='staff-header' style={{ alignItems: 'center' }}>
          <div className='staff-title'>
            {i18n.t('staff.menuBar.settingTransportationExpense')}
            <BreadCrumb items={[{ title: '設定' }]} />
          </div>
          {isTab === '1' ? (
            <div className='staff-buttons'>
              <ModalLink
                to={Router.get(Router.staffDoctorsMovingExpensesAddSetting)}
              >
                <Button
                  icon='pi pi-plus'
                  className='p-button-normal'
                  label='申請区分追加'
                  style={{ padding: '2px 4px' }}
                />
              </ModalLink>
            </div>
          ) : null}
        </div>

        <div className='box'>
          <Tabs className='tab-transport' type='card' onChange={onChange}>
            <TabPane tab='その他交通費設定' key='1'>
              <div>
                <div>
                  <table className='table-moving-expenses'>
                    <thead>
                      <tr>
                        <th width='18%'>
                          {i18n.t(`main.movingExpenses.applicationDistinction`)}
                        </th>
                        <th width='18%'>
                          {i18n.t(`main.movingExpenses.ceilingAmount`)}
                        </th>
                        <th width='18%'>医師サイト</th>
                        <th width='18%'>
                          {i18n.t(`main.movingExpenses.lastUpdate`)}
                        </th>
                        <th width='18%'>最終更新者</th>
                        <th width='10%' />
                      </tr>
                    </thead>
                    {data &&
                      data.map((item, index) => {
                        return (
                          <tr>
                            <td>{item.node.type}</td>
                            <td>
                              {item.node.maxCost
                                ? `${item.node.maxCost}円`
                                : '上限なし'}
                            </td>
                            <td>{item.node.isApply ? '表示' : '非表示'}</td>
                            <td>
                              {moment(item.node.updateAt).format('LL (dd)')}
                            </td>
                            <td>{`${item.node.updatedBy.lastname} ${item.node.updatedBy.firstname}`}</td>

                            <td>
                              <ModalLink
                                to={Router.get(
                                  Router.staffDoctorsMovingExpensesDetailSetting,
                                  {
                                    id: item.node.id,
                                  }
                                )}
                              >
                                <Button icon='pi pi-info' />
                              </ModalLink>
                            </td>
                          </tr>
                        )
                      })}
                  </table>
                </div>
              </div>
            </TabPane>
            <TabPane
              tab={i18n.t(`main.movingExpenses.setDateTransportationFee`)}
              key='2'
            >
              <Formik
                enableReinitialize
                initialValues={{
                  expiredDate:
                    dataExpiredDate &&
                    dataExpiredDate.transportationTypeSettings.length > 0
                      ? dataExpiredDate.transportationTypeSettings[0]
                          .expiredDate
                      : null,
                  isApply:
                    dataExpiredDate &&
                    dataExpiredDate.transportationTypeSettings.length > 0
                      ? dataExpiredDate.transportationTypeSettings[0].isApply
                      : false,
                  confirm: false,
                }}
                onSubmit={onSubmit}
                render={formikProps => {
                  const {
                    values,
                    setTouched,
                    validateForm,
                    setFieldValue,
                  } = formikProps
                  return (
                    <>
                      <div className='tr'>
                        <div className='th' style={{ maxWidth: 'fit-content' }}>
                          {i18n.t(
                            `main.movingExpenses.setDateTransportationFee`
                          )}
                        </div>
                        <div className='td'>
                          <div
                            className='td toggle-published'
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              maxWidth: 'fit-content',
                              alignItems: 'center',
                            }}
                          >
                            <Switch
                              style={{ marginRight: '15px' }}
                              {...formikProps}
                              checkedChildren='設定'
                              unCheckedChildren='解除'
                              checked={values.isApply}
                              onChange={e => {
                                setFieldValue('isApply', e)
                              }}
                            />
                            {values.isApply ? (
                              <div
                                style={{
                                  display: 'flex',
                                  flexWrap: 'nowrap',
                                  alignItems: 'center',
                                  marginRight: '15px',
                                }}
                              >
                                <span
                                  style={{
                                    wordBreak: 'keep-all',
                                    marginRight: '5px',
                                  }}
                                >
                                  {i18n.t('main.movingExpensesSetting.monthly')}
                                </span>
                                <Dropdown
                                  {...formikProps}
                                  options={options()}
                                  optionLabel='description'
                                  value={options().find(
                                    item => item.value === values.expiredDate
                                  )}
                                  onChange={e =>
                                    setFieldValue('expiredDate', e.value.value)
                                  }
                                />
                                {/* <InputNumber
                                  {...formikProps}
                                  style={{ margin: '0px 6px' }}
                                  value={values.expiredDate}
                                  max={28}
                                  min={1}
                                  onChange={e =>
                                    setFieldValue('expiredDate', e)
                                  }
                                /> */}
                                <span
                                  style={{
                                    wordBreak: 'keep-all',
                                    marginLeft: '5px',
                                  }}
                                >
                                  日
                                </span>
                              </div>
                            ) : null}

                            <Button
                              label='登録'
                              className='reflect-button btn'
                              type='submit'
                              style={{
                                wordBreak: 'keep-all',
                                width: '50px',
                                height: '30px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-around',
                              }}
                              onClick={() => {
                                validateForm().then(response => {
                                  if (!Object.keys(response).length) {
                                    setFieldValue('confirm', true)
                                  } else {
                                    setTouched(response)
                                  }
                                })
                              }}
                            />
                          </div>
                          <ConfirmationModal
                            {...formikProps}
                            confirmationMessage={i18n.t(
                              'main.movingExpensesSetting.confirmUpdate'
                            )}
                          />
                        </div>
                      </div>
                    </>
                  )
                }}
              />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </>
  )
}
