import gql from 'graphql-tag'

export const DOCTOR_RECRUITMENT_DOCTOR = gql`
  query RecruitmentDoctors($where: RecruitmentDoctorWhereInput) {
    recruitmentDoctors(where: $where) {
      id
      status
      desireStartMoment
      endTimeFriday
      endTimeHoliday
      endTimeMonday
      endTimeSaturday
      endTimeSunday
      endTimeThursday
      endTimeWednesday
      endTimeTuesday
      startTimeFriday
      startTimeHoliday
      startTimeMonday
      startTimeSaturday
      startTimeSunday
      startTimeThursday
      startTimeTuesday
      startTimeWednesday
      remark
    }
  }
`

export const DOCTOR_APPLY_RECRUITMENT_DOCTOR = gql`
  mutation DOCTOR_APPLY_RECRUITMENT_POST(
    $recruitmentPostId: ID!
    $desireStartMoment: String!
    $startTimeMonday: DateTime
    $endTimeMonday: DateTime
    $startTimeTuesday: DateTime
    $endTimeTuesday: DateTime
    $startTimeWednesday: DateTime
    $endTimeWednesday: DateTime
    $startTimeThursday: DateTime
    $endTimeThursday: DateTime
    $startTimeFriday: DateTime
    $endTimeFriday: DateTime
    $startTimeSaturday: DateTime
    $endTimeSaturday: DateTime
    $startTimeSunday: DateTime
    $endTimeSunday: DateTime
    $startTimeHoliday: DateTime
    $endTimeHoliday: DateTime
    $remark: String
  ) {
    createRecruitmentDoctor(
      recruitmentPostId: $recruitmentPostId
      desireStartMoment: $desireStartMoment
      startTimeMonday: $startTimeMonday
      endTimeMonday: $endTimeMonday
      startTimeTuesday: $startTimeTuesday
      endTimeTuesday: $endTimeTuesday
      startTimeWednesday: $startTimeWednesday
      endTimeWednesday: $endTimeWednesday
      startTimeThursday: $startTimeThursday
      endTimeThursday: $endTimeThursday
      startTimeFriday: $startTimeFriday
      endTimeFriday: $endTimeFriday
      startTimeSaturday: $startTimeSaturday
      endTimeSaturday: $endTimeSaturday
      startTimeSunday: $startTimeSunday
      endTimeSunday: $endTimeSunday
      startTimeHoliday: $startTimeHoliday
      endTimeHoliday: $endTimeHoliday
      remark: $remark
    )
  }
`

export const DOCTOR_UPDATE_RECRUITMENT_DOCTOR = gql`
  mutation DOCTOR_UPDATE_RECRUITMENT_DOCTOR(
    $recruitmentDoctorId: ID!
    $desireStartMoment: String!
    $startTimeMonday: DateTime
    $endTimeMonday: DateTime
    $startTimeTuesday: DateTime
    $endTimeTuesday: DateTime
    $startTimeWednesday: DateTime
    $endTimeWednesday: DateTime
    $startTimeThursday: DateTime
    $endTimeThursday: DateTime
    $startTimeFriday: DateTime
    $endTimeFriday: DateTime
    $startTimeSaturday: DateTime
    $endTimeSaturday: DateTime
    $startTimeSunday: DateTime
    $endTimeSunday: DateTime
    $startTimeHoliday: DateTime
    $endTimeHoliday: DateTime
    $remark: String
  ) {
    doctorUpdateRecruitmentDoctor(
      recruitmentDoctorId: $recruitmentDoctorId
      desireStartMoment: $desireStartMoment
      startTimeMonday: $startTimeMonday
      endTimeMonday: $endTimeMonday
      startTimeTuesday: $startTimeTuesday
      endTimeTuesday: $endTimeTuesday
      startTimeWednesday: $startTimeWednesday
      endTimeWednesday: $endTimeWednesday
      startTimeThursday: $startTimeThursday
      endTimeThursday: $endTimeThursday
      startTimeFriday: $startTimeFriday
      endTimeFriday: $endTimeFriday
      startTimeSaturday: $startTimeSaturday
      endTimeSaturday: $endTimeSaturday
      startTimeSunday: $startTimeSunday
      endTimeSunday: $endTimeSunday
      startTimeHoliday: $startTimeHoliday
      endTimeHoliday: $endTimeHoliday
      remark: $remark
    )
  }
`
export const DOCTOR_DELETE_RECRUITMENT_DOCTOR = gql`
  mutation DOCTOR_DELETE_RECRUITMENT_DOCTOR($recruitmentDoctorId: ID!) {
    doctorDeleteRecruitmentDoctor(recruitmentDoctorId: $recruitmentDoctorId)
  }
`
