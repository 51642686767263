import { useMutation } from '@apollo/react-hooks'
import LoginComponent from '@medical/components/Forms/LoginComponent'
import { CHECK_DOCTOR_CERT, DOCTOR_EMAIL, DOCTOR_TOKEN_KEY } from '@medical/constant'
import { waitFor } from '@medical/libs'
import Auth from '@medical/middleware/auth'
import { CREATE_ACTIVITY } from '@medical/pages/doctor/AvailableShift/AvailableShift.graphql'
import { useCustom } from '@medical/provider/context'
import HomeRedirect from '@medical/routes/HomeRedirect'
import Router from '@medical/routes/router'
import { Button } from 'primereact/button'
import React from 'react'
import { Link } from 'react-router-dom'

import { DOCTOR_LOGIN } from './DoctorLogin.graphql'

const LoginContainer = ({ history, location: { state } }) => {
  const [{ i18n, popup }, { setToken }] = useCustom()
  const [doctorLogin] = useMutation(DOCTOR_LOGIN)
  const [doctorCreateActivity] = useMutation(CREATE_ACTIVITY)
  const { email: stateEmail } = state || {}

  if (Auth.getToken()) {
    return <HomeRedirect />
  }

  const login = async ({ email, password }, { setSubmitting }) => {
    try {
      const loginResponse = await doctorLogin({
        variables: {
          email,
          password,
        },
      })
      const { token } = loginResponse.data.doctorLogin
      await waitFor(1000)
      setToken(token)
      localStorage.setItem(DOCTOR_TOKEN_KEY, token)
      localStorage.setItem(DOCTOR_EMAIL, email)
      localStorage.setItem(CHECK_DOCTOR_CERT, false)
      popup.success(i18n.t('doctor.login.success'))
      await doctorCreateActivity({
        variables: {
          activity: 'LOGGED_DOCTOR',
        },
      })
      const first = true
      history.push({
        pathname: Router.home,
        search: '?query=abc',
        state: { first },
        isFirst: true,
      })
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }
  return (
    <LoginComponent
      email={stateEmail}
      title={i18n.t('doctor.login.title')}
      loginLabel={i18n.t('doctor.login.doctorLoginLabel')}
      emailLabel={i18n.t('doctor.login.emailLabel')}
      passwordLabel={i18n.t('doctor.login.passwordLabel')}
      submitLabel={i18n.t('doctor.login.submit')}
      login={login}
      bottom={
        <div className='login-bottom-content'>
          <div className='sign-up-link'>
            <Button
              type='button'
              className='login-button p-button-primary'
              label={i18n.t('doctor.login.signUp')}
              onClick={() => {
                history.push('./signup')
              }}
            />
          </div>
          <div className='login-link'>
            <Link to={Router.doctorForgotPassword}>
              {i18n.t('doctor.login.forgotPassword')}
            </Link>
          </div>
        </div>
      }
      top={<div className='login-title-label'>aaaaa</div>}
    />
  )
}

export default LoginContainer
