import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import CustomDropdown from '@medical/components/DropdownForSignup'
import { useCustom } from '@medical/provider/context'
import { Formik } from 'formik'
import { Button } from 'primereact/button'
import React, { useEffect } from 'react'

import {
  GET_ALL_TYPE,
  GET_TYPE_BY_ID,
  UPDATE_TYPE_BY_ID,
} from './WorkScheduleType.graphql'

const FAKE_OPTIONS = {
  DEFAULT: '勤務種別未定',
  FULL_TIME_SHIFT: '常勤医師のシフト勤務',
  FULL_TIME_SHIFT_CHANGE: '常勤医師のシフト変更勤務',
  FULL_TIME_SHIFT_EXTRA: '常勤医師の追加勤務',
  PART_TIME_SHIFT_REGULAR: '定期非常勤医師の定期シフト勤務',
  PART_TIME_SHIFT_CHANGE: '定期非常勤医師の定期シフト変更勤務',
  PART_TIME_SHIFT_SPOT_WORK: '定期非常勤医師のスポット勤務',
  NON_REGULAR_PART_TIME_SHIFT_SPOT_WORK: '非定期非常勤医師のスポット勤務',
}

export const WorkScheduleType = ({ workScheduleId, type, isTabPanel }) => {
  const [{ i18n, popup }] = useCustom()
  const [updateWorkType] = useMutation(UPDATE_TYPE_BY_ID)
  const onUpdateWorkType = async (
    { workScheduleId, workScheduleType },
    { resetForm, setSubmitting, setFieldValue }
  ) => {
    try {
      await updateWorkType({
        variables: {
          where: {
            id: workScheduleId,
          },
          data: {
            type: workScheduleType,
          },
        },
      })
      setSubmitting(false)
      popup.success(i18n.t('main.workTypes.updateSuccess'))
      resetForm()
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }

  const getOptions = () => {
    const result = []
    Object.keys(FAKE_OPTIONS).forEach(key => {
      result.push({
        value: key,
        description: i18n.t(`main.workTypes.${key}`),
      })
    })

    return result
  }

  return (
    <Formik
      initialValues={{
        confirm: false,
        workScheduleType: type,
        workScheduleId,
      }}
      onSubmit={onUpdateWorkType}
      render={formikProps => {
        const { handleSubmit, setFieldValue, setSubmitting } = formikProps
        return (
          <>
            <div
              style={{
                padding: '15px 20px 10px 0px',
                fontWeight: 'bold',
              }}
            >
              {i18n.t('main.workTypes.title')}
            </div>
            <div
              style={
                isTabPanel
                  ? {
                      height: '250px',
                    }
                  : {
                      height: '50px',
                    }
              }
            >
              <CustomDropdown
                {...formikProps}
                style={{
                  minWidth: '300px',
                }}
                name='workScheduleType'
                options={getOptions()}
                placeholder={i18n.t('main.workTypes.placeholder')}
                disabled={!isTabPanel}
              />
            </div>
            {isTabPanel ? (
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                <Button
                  onClick={() => {
                    setFieldValue('confirm', true)
                    handleSubmit()
                  }}
                  label={i18n.t('main.workTypes.submit')}
                  className='reflect-button'
                />
              </div>
            ) : null}

            <ConfirmationModal
              {...formikProps}
              confirmationMessage={i18n.t('main.workTypes.updateConfirmation')}
            />
          </>
        )
      }}
    />
  )
}
