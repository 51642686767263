import BreadCrumb from '@medical/components/Breadcrumb'
import {
  DOWNLOAD_CSV_DOCTOR_DAILY_TRANSPORT_EXPENSES,
  DOWNLOAD_CSV_DOCTOR_MONTHLY_TRANSPORT_EXPENSES,
} from '@medical/constant'
import { waitFor } from '@medical/libs'
import Router from '@medical/routes/router'
import { DatePicker } from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import Axios from 'axios'
import { InputText } from 'fork/primereact/components/inputtext/InputText'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { Paginator } from 'primereact/paginator'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import DoctorsMonthlyTransportationExpensesHeader from './DoctorsMonthlyTransportationExpensesHeader'

const DoctorsMonthlyTransporationExpensesScene = ({
  i18n,
  data,
  page,
  month,
  year,
  count,
  rowsPerPage,
  onPageChange,
  fullname,
  email,
  isWaitingForApproval,
  isDuplicateRouteFee,
  isDuplicate,
  download,
  urlSpecificDay,
  socket,
  progress,
  setProgress,
  removeProgress,
}) => {
  const disabledDate = current => {
    const maxDay = moment().endOf('month')
    const minDay = moment().subtract(1, 'year')
    return minDay.isSameOrAfter(current) || maxDay.isBefore(current)
  }
  const patterns = [
    { status: '有', value: '有' },
    { status: '無', value: '無' },
  ]
  const [mailFilter, setMailFilter] = useState(email || '')
  const [dateCsvDownload, setDateCsvDownload] = useState([])
  const [nameFilter, setNameFilter] = useState(fullname || '')
  const [isWaitingForApprovalFilter, setIsWaitingForApprovalFilter] = useState(
    isWaitingForApproval
      ? patterns.find(item => item.value === isWaitingForApproval)
      : undefined
  )
  const [isDuplicateFilter, setIsDuplicateFilter] = useState(
    isDuplicate ? patterns.find(item => item.value === isDuplicate) : undefined
  )
  const [isDuplicateRouteFeeFilter, setIsDuplicateRouteFilter] = useState(
    isDuplicateRouteFee
      ? patterns.find(item => item.value === isDuplicateRouteFee)
      : undefined
  )
  const hasProgress =
    Object.keys(progress).includes(
      DOWNLOAD_CSV_DOCTOR_MONTHLY_TRANSPORT_EXPENSES
    ) ||
    Object.keys(progress).includes(DOWNLOAD_CSV_DOCTOR_DAILY_TRANSPORT_EXPENSES)
  useEffect(() => {
    if (socket && hasProgress) {
      socket.on(DOWNLOAD_CSV_DOCTOR_MONTHLY_TRANSPORT_EXPENSES, data => {
        if (data?.percent) {
          setProgress({
            progress: DOWNLOAD_CSV_DOCTOR_MONTHLY_TRANSPORT_EXPENSES,
            label: '月間交通費csvダウンロード中',
            percent: Number(data.percent),
            url: undefined,
          })
        }
      })
      socket.on(DOWNLOAD_CSV_DOCTOR_DAILY_TRANSPORT_EXPENSES, data => {
        if (data?.percent) {
          setProgress({
            progress: DOWNLOAD_CSV_DOCTOR_DAILY_TRANSPORT_EXPENSES,
            label: '日別交通費csvダウンロード中',
            percent: Number(data.percent),
            url: undefined,
          })
        }
      })
    }
  }, [socket, hasProgress])
  return (
    <div
      style={{
        padding: '10px',
        height: '100%',
      }}
    >
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('staff.menuBar.monthlyTransportationExpenses')}
          <BreadCrumb
            items={[
              { title: i18n.t('staff.menuBar.monthlyTransportationExpenses') },
            ]}
          />
        </div>
        <div className='downloadDaily'>
          <div>
            <DatePicker.RangePicker
              size='large'
              className='date-picker'
              disabledDate={disabledDate}
              placeholder={['開始日', '終了日']}
              format='YYYY年MM月DD日'
              locale={locale}
              allowClear={true}
              onChange={value => {
                if (value && value.length > 0) {
                  setDateCsvDownload([
                    moment(value[0])
                      .startOf('day')
                      .toISOString(),
                    moment(value[1])
                      .endOf('day')
                      .toISOString(),
                  ])
                } else {
                  setDateCsvDownload([])
                }
              }}
            />
          </div>
          <div>
            <Button
              icon='pi pi-download'
              className='p-button-normal'
              label='日別交通費csvダウンロード'
              disabled={!(dateCsvDownload.length > 0)}
              onClick={async () => {
                setProgress({
                  progress: DOWNLOAD_CSV_DOCTOR_DAILY_TRANSPORT_EXPENSES,
                  label: '日別交通費csvダウンロード中',
                  percent: 0,
                  url: undefined,
                })
                try {
                  const { status, data: urlS3 } = await Axios({
                    method: 'GET',
                    url: urlSpecificDay(dateCsvDownload),
                  })
                  await waitFor(3000)
                  if (status === 204) {
                    setProgress({
                      progress: DOWNLOAD_CSV_DOCTOR_DAILY_TRANSPORT_EXPENSES,
                      label: '日別交通費csvダウンロード中',
                      msg: i18n.t('main.msgDownloadCSVStatusError'),
                    })
                    await waitFor(3000)
                  } else {
                    setProgress({
                      progress: DOWNLOAD_CSV_DOCTOR_DAILY_TRANSPORT_EXPENSES,
                      percent: 100,
                      url: urlS3,
                    })
                  }
                } catch (err) {
                  setProgress({
                    progress: DOWNLOAD_CSV_DOCTOR_DAILY_TRANSPORT_EXPENSES,
                    label: '日別交通費csvダウンロード中',
                    msg: i18n.t('main.msgDownloadCSVStatusError'),
                  })
                  await waitFor(3000)
                }
                removeProgress(DOWNLOAD_CSV_DOCTOR_DAILY_TRANSPORT_EXPENSES)
              }}
              style={{ marginLeft: '10px' }}
            />
          </div>
        </div>
      </div>
      <div
        className='box'
        style={{
          backgroundColor: '#ffffff',
          marginLeft: '20px',
          marginRight: '20px',
          padding: '20px',
          borderRadius: '10px',
        }}
      >
        <div>
          <DoctorsMonthlyTransportationExpensesHeader
            date={moment([year, month - 1])}
          />
          <div
            className='search-parent'
            style={{
              width: '100%',
              flexDirection: 'column',
              alignItems: 'stretch',
            }}
          >
            <div className='search-up' style={{ display: 'flex' }}>
              <InputText
                value={nameFilter}
                name='fullname'
                placeholder={i18n.t('main.fullname')}
                onChange={e => {
                  setNameFilter(e.target.value)
                }}
                style={{ width: '176px', marginRight: '20px' }}
              />
              <InputText
                value={mailFilter}
                name='fullname'
                placeholder={i18n.t('main.movingExpenses.mail')}
                onChange={e => {
                  setMailFilter(e.target.value)
                }}
                style={{ width: '176px', marginRight: '20px' }}
              />
              <div style={{ width: '170px', marginRight: '10px' }}>
                <Dropdown
                  name='transportationExpensesWaitingForApproval'
                  options={patterns}
                  className='drop-down-select-workpartten-search'
                  optionLabel='status'
                  valueLabel='value'
                  value={isWaitingForApprovalFilter}
                  placeholder={i18n.t(
                    'main.movingExpenses.transportationExpensesWaitingForApproval'
                  )}
                  onChange={event => {
                    setIsWaitingForApprovalFilter(event.target.value)
                  }}
                />
              </div>
            </div>
            <span style={{ margin: '0.5rem 0' }} />
            <div className='search-down' style={{ display: 'flex' }}>
              <div style={{ width: '170px', marginRight: '10px' }}>
                <Dropdown
                  name='duplicateTransportationExpenses'
                  options={patterns}
                  className='drop-down-select-workpartten-search'
                  optionLabel='status'
                  valueLabel='value'
                  value={isDuplicateFilter}
                  placeholder={'重複(交通費精算)'}
                  onChange={event => {
                    setIsDuplicateFilter(event.target.value)
                  }}
                />
              </div>
              <span style={{ margin: '0 0.5rem' }} />
              <div style={{ width: '170px', marginRight: '27px' }}>
                <Dropdown
                  name='routeTransportationFee'
                  options={patterns}
                  className='drop-down-select-workpartten-search'
                  optionLabel='status'
                  valueLabel='value'
                  value={isDuplicateRouteFeeFilter}
                  placeholder={'重複(経路交通費)'}
                  onChange={event => {
                    setIsDuplicateRouteFilter(event.target.value)
                  }}
                />
              </div>
              <Link
                to={{
                  pathname: Router.get(
                    Router.staffDoctorsMonthlyTransportationExpenses,
                    {
                      year,
                      month,
                    }
                  ),
                  search: queryString.stringify({
                    rowsPerPage,
                    fullname: nameFilter,
                    email: mailFilter,
                    isWaitingForApproval: isWaitingForApprovalFilter
                      ? isWaitingForApprovalFilter.value
                      : undefined,
                    isDuplicate: isDuplicateFilter
                      ? isDuplicateFilter.value
                      : undefined,
                    isDuplicateRouteFee: isDuplicateRouteFeeFilter
                      ? isDuplicateRouteFeeFilter.value
                      : undefined,
                  }),
                }}
              >
                <Button icon='pi pi-search' label={i18n.t('main.search')} />
              </Link>
              <Link
                to={{
                  pathname: Router.get(
                    Router.staffDoctorsMonthlyTransportationExpenses,
                    {
                      year,
                      month,
                    }
                  ),
                  search: queryString.stringify({
                    rowsPerPage,
                  }),
                }}
              >
                <Button
                  icon='pi pi-times'
                  className='p-button-secondary'
                  style={{ marginLeft: '20px' }}
                  onClick={() => {
                    setNameFilter('')
                    setMailFilter('')
                    setIsDuplicateFilter(undefined)
                    setIsWaitingForApprovalFilter(undefined)
                    setIsDuplicateRouteFilter(undefined)
                  }}
                />
              </Link>
            </div>
            <div style={{ position: 'absolute', top: '1rem', right: '3rem' }}>
              <Button
                icon='pi pi-download'
                className='p-button-normal'
                label='月間交通費csvダウンロード'
                onClick={async () => {
                  setProgress({
                    progress: DOWNLOAD_CSV_DOCTOR_MONTHLY_TRANSPORT_EXPENSES,
                    label: '月間交通費csvダウンロード中',
                    percent: 0,
                    url: undefined,
                  })
                  try {
                    const { status, data: urlS3 } = await Axios({
                      method: 'GET',
                      url: download,
                    })
                    await waitFor(3000)
                    if (status === 204) {
                      setProgress({
                        progress: DOWNLOAD_CSV_DOCTOR_MONTHLY_TRANSPORT_EXPENSES,
                        label: '月間交通費csvダウンロード中',
                        msg: i18n.t('main.msgDownloadCSVStatusError'),
                      })
                      await waitFor(3000)
                    } else {
                      setProgress({
                        progress: DOWNLOAD_CSV_DOCTOR_MONTHLY_TRANSPORT_EXPENSES,
                        percent: 100,
                        url: urlS3,
                      })
                    }
                  } catch (err) {
                    setProgress({
                      progress: DOWNLOAD_CSV_DOCTOR_MONTHLY_TRANSPORT_EXPENSES,
                      label: '月間交通費csvダウンロード中',
                      msg: i18n.t('main.msgDownloadCSVStatusError'),
                    })
                    await waitFor(3000)
                  }
                  removeProgress(DOWNLOAD_CSV_DOCTOR_MONTHLY_TRANSPORT_EXPENSES)
                }}
                style={{ marginLeft: '100px' }}
              />
            </div>
          </div>
          <table className='table-moving-expenses'>
            <thead>
              <tr>
                <th width='18%'>{i18n.t('main.movingExpenses.fullname')}</th>
                <th width='18%'>{i18n.t('main.movingExpenses.mail')}</th>
                <th width='18%'>
                  {i18n.t('main.movingExpenses.numberOfMonthlyFixedShifts')}
                </th>
                <th width='12%'>
                  {i18n.t(
                    'main.movingExpenses.transportationExpensesWaitingForApproval'
                  )}
                </th>
                <th width='12%'>重複(交通費精算)</th>
                <th width='12%'>重複(経路交通費)</th>
                <th width='30%'>{i18n.t('main.movingExpenses.detail')}</th>
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0
                ? data.map((as, index) => (
                    <tr key={index}>
                      <td>{as.fullName}</td>
                      <td>
                        <a href={`mailto:${as.email}`}>{as.email}</a>
                      </td>
                      <td>{as.numberOfMonthlyFixedShifts}</td>
                      <td>{as.transportationExpensesWaitingForApproval}</td>
                      <td>{as.duplicateTransporation}</td>
                      <td>{as.routeTransportationFee}</td>
                      <td>
                        <Link
                          to={Router.get(
                            Router.staffDoctorsMovingExpensesDetail,
                            {
                              doctorId: as.doctorId,
                              year,
                              month,
                            }
                          )}
                          className={Router.staffDoctorsMovingExpensesDetail}
                        >
                          <Button icon='pi pi-pencil' />
                        </Link>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
          <Paginator
            rows={rowsPerPage}
            totalRecords={count}
            first={(page - 1) * rowsPerPage}
            onPageChange={onPageChange}
            leftContent={
              <div className='paginator-total'>
                {i18n.t('main.paginationTotal')}: {count}
              </div>
            }
          />
        </div>
      </div>
    </div>
  )
}

export default DoctorsMonthlyTransporationExpensesScene
