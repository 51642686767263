import React, {useEffect, useMemo, useState} from 'react';
import {useCustom} from "../../../provider/context";
import ExpectationDoubleRecruitmentRecordScene from "./ExpectationDoubleRecruitmentRecordScene";
import moment from "moment/moment";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {ErrorComponent, ProgressSpinner} from "../../../components";
import {
  CLINICAL_DEPARTMENTS,
  CLINICS_CONNECTION, GET_EXPECTATION_DOUBLE_RECRUITMENT_RECORD,
  GET_EXPECTATION_DOUBLE_RECRUITMENT_RECORD_PATTERN, UPSERT_EXPECTATION_DOUBLE_RECRUITMENT_RECORD
} from "./ExpectationDoubleRecruitmentRecord.graphql";
import axios from "axios";

const ExpectationDoubleRecruitmentRecord = (
  {
    history,
    match: {
      path,
      params: {year = moment().year(), month = moment().month()},
    },
  }) => {
  const [{popup}] = useCustom()
  const [state, setState] = useState({
    isUploaded: false,
  })

  const [holidays, setHolidays] = useState([])
  const {
    loading: clinicsLoading,
    error: clinicsError,
    data: clinics,
    refetch: clinicRefetch
  } = useQuery(CLINICS_CONNECTION, {
    fetchPolicy: 'network-only',
  })

  const [clinicalDepartments] = useMutation(CLINICAL_DEPARTMENTS)
  const [mutationGetExpectationDoubleRecruitmentRecordPattern] = useMutation(GET_EXPECTATION_DOUBLE_RECRUITMENT_RECORD_PATTERN)
  const [upsertExpectationDoubleRecruitmentRecord] = useMutation(UPSERT_EXPECTATION_DOUBLE_RECRUITMENT_RECORD)
  const [getExpectationDoubleRecruitmentRecord] = useMutation(GET_EXPECTATION_DOUBLE_RECRUITMENT_RECORD)

  useEffect(() => {
    const fetch = async () => {
      return await axios.get(`https://holidays-jp.shogo82148.com/${year}/${month}`)
    }
    fetch().then(response => setHolidays(response.data.holidays))
    // clinicRefetch()
    // clinicalDepartmentsRefetch()
  }, [year, month])

  if (clinicsLoading) return <ProgressSpinner/>
  if (clinicsError) return <ErrorComponent
    error={clinicsError}/>

  const {
    clinicsConnection: {
      edges,
    },
  } = clinics

  const convertClinic = (clinics) => (clinics.map(clinic => {
    return {
      ...clinic.node,
    }
  }))

  const clinicalDepartmentNameRender = (name) => {
    let clinicDepartmentName = ''
    switch (name) {
      case 'INTERNAL_MEDICINE': {
        clinicDepartmentName = '内科'
        break
      }
      case 'CHILDREN_MEDICINE': {
        clinicDepartmentName = '小児科'
        break
      }
      case 'CHILDREN_VACCINE_MEDICINE': {
        clinicDepartmentName = '小児科ワクチン'
        break
      }
      case 'INTERNAL_VACCINE_MEDICINE': {
        clinicDepartmentName = '内科ワクチン'
        break
      }
      default: {
        break
      }
    }
    return clinicDepartmentName
  }

  const convertClinicalDepartment = (clinicalDepartments) => (clinicalDepartments.map(clinicalDepartment => {
    return {
      label: clinicalDepartmentNameRender(clinicalDepartment.name),
      ...clinicalDepartment
    }
  }))

  const generateDatesInMonth = (year, month) => {
    const dates = [];
    const start = moment(`${year}-${month}-01`);
    const end = start.clone().endOf('month');

    while (start.isSameOrBefore(end)) {
      dates.push(start.format('YYYY-MM-DD'));
      start.add(1, 'day');
    }
    return dates;
  };

  const generateArrayWithObjectValues = (holidays, obj, year, month) => {
    const datesInMonth = generateDatesInMonth(year, month);
    return datesInMonth.map(date => {
      const isHoliday = holidays.some(holiday => holiday.date === date);
      const dayOfWeek = moment(date).locale('en').format('dddd').toLowerCase();
      const result = {
        date,
        morning: obj && obj[`${dayOfWeek}MorningShiftDoctors`] !== null ? obj[`${dayOfWeek}MorningShiftDoctors`] : null,
        afternoon: obj && obj[`${dayOfWeek}AfternoonShiftDoctors`] !== null ? obj[`${dayOfWeek}AfternoonShiftDoctors`] : null,
        night: obj && obj[`${dayOfWeek}NightShiftDoctors`] !== null ? obj[`${dayOfWeek}NightShiftDoctors`] : null,
      };
      if (isHoliday) {
        result.morning = obj && obj.holidayMorningShiftDoctors !== null ? obj.holidayMorningShiftDoctors : result.morning;
        result.afternoon = obj && obj.holidayAfternoonShiftDoctors !== null ? obj.holidayAfternoonShiftDoctors : result.afternoon;
        result.night = obj && obj.holidayNightShiftDoctors !== null ? obj.holidayNightShiftDoctors : result.night;
      }
      return result;
    });
  };

  const handleSynchronized = async (clinic_id, clinical_department_id, setFieldValue, data) => {
    setState({
      isUploaded: true,
    })
    try {
      const {
        data: {
          getExpectationDoubleRecruitmentRecordPattern
        }
      } = await mutationGetExpectationDoubleRecruitmentRecordPattern({
        variables: {
          clinic: clinic_id,
          clinicalDepartment: clinical_department_id,
          targetMonth: +month,
          targetYear: +year,
        }
      })
      if (getExpectationDoubleRecruitmentRecordPattern) {
        const dataSynchronized = generateArrayWithObjectValues(holidays, getExpectationDoubleRecruitmentRecordPattern, year, month)
        const result = data.map((defaultItem, index) => {
          const syncItem = dataSynchronized[index];

          return {
            date: defaultItem.date,
            morning: (syncItem.morning !== null) ? syncItem.morning : defaultItem.morning,
            afternoon: (syncItem.afternoon !== null) ? syncItem.afternoon : defaultItem.afternoon,
            night: (syncItem.night !== null) ? syncItem.night : defaultItem.night
          };
        });
        setFieldValue('data', result)
      }
    } catch (error) {
      console.error(error)
      popup.clear()
      popup.error(error)
    }
    setState({
      isUploaded: false,
    })
  }

  const onSubmit = async ({clinic_id, clinical_department_id, data}, {resetForm}) => {
    setState({
      isUploaded: true,
    })
    try {
      const ExpectationDoubleRecruitmentRecord = data.map(item => (
        {
          shiftDate: moment.utc(item.date),
          morningShiftDoctors: item.morning,
          afternoonShiftDoctors: item.afternoon,
          nightShiftDoctors: item.night,
        }
      ))
      const result = await upsertExpectationDoubleRecruitmentRecord({
        variables: {
          clinic: clinic_id,
          clinicalDepartment: clinical_department_id,
          data: ExpectationDoubleRecruitmentRecord
        }
      })
      if (result.data.upsertExpectationDoubleRecruitmentRecord){
        popup.success('成功　保存しました。')
      } else {
        popup.error('エラー　エラーが発生しました。再度ご確認ください。')
      }
    } catch (error) {
      console.error(error)
      popup.error('エラー　エラーが発生しました。再度ご確認ください。')
      popup.clear()
    }
    setState({
      isUploaded: false,
    })
  }
  return (
    <ExpectationDoubleRecruitmentRecordScene
      handleSynchronized={handleSynchronized}
      onSubmit={onSubmit}
      popup={popup}
      isUploaded={state.isUploaded}
      convertClinic={convertClinic}
      convertClinicalDepartment={convertClinicalDepartment}
      clinics={edges}
      holidays={holidays}
      history={history}
      year={year}
      month={month}
      generateArrayWithObjectValues={generateArrayWithObjectValues}
      clinicalDepartments={clinicalDepartments}
      getExpectationDoubleRecruitmentRecord={getExpectationDoubleRecruitmentRecord}
    />
  );
};

export default ExpectationDoubleRecruitmentRecord;