import React from 'react'

const TransportReportsByCarDetailScene = ({
  i18n,
  data,
  dataResponse,
  homeAddress,
}) => {
  return (
    <div className='transport-detail'>
      <div className='modal-title'>
        {i18n.t('staff.movingExpensesDetail.titleMycar')}
      </div>
      <div className='td' style={{ padding: '15px' }}>
        {i18n.t('transportApplication.carTransportPeriodicModalDescription')}
        <div style={{ color: '#f03a6c' }} className='asterisk-label-head '>
          距離データが正しく反映されていない場合もございますので勤務するクリニックへの
          片道距離は必ずご確認をお願いします。
        </div>
      </div>
      <div className='tr'>
        <div className='th'>
          {i18n.t('staff.movingExpensesDetail.doctorName')}
        </div>
        <div className='td'>{data.fullName}</div>
      </div>
      <div className='tr'>
        <div className='th'>{i18n.t('main.movingExpenses.address')}</div>
        <div className='td'>{homeAddress}</div>
      </div>
      <div className='car-transport-periodic-detail-table'>
        <div className='tr'>
          <div className='th' style={{ width: '35%' }}>
            {i18n.t('main.movingExpensesRegistration.clinic')}
          </div>
          <div className='th'>
            {i18n.t('staff.movingExpensesDetail.distanceClinic')}
          </div>
          <div className='th'>
            {i18n.t('main.movingExpenses.effectiveDate')}
          </div>
        </div>
        {dataResponse.length > 0 &&
          dataResponse.map(item => (
            <div key={item.clinic} className='tr'>
              <div className='td' style={{ width: '35%' }}>
                {item.clinicName}
              </div>
              <div className='td'>
                {item.distance}
                <span>Km</span>
              </div>
              <div className='td'>{item.applicableDate}</div>
            </div>
          ))}
      </div>
    </div>
  )
}
export default TransportReportsByCarDetailScene
