import gql from 'graphql-tag'

export const ACTIVITY_LOG_DETAIL_BY_STAFF = gql`
  query ACTIVITY_LOG_DETAIL($activityLogId: ID!) {
    activityLog(where: { id: $activityLogId }) {
      id
      createdAt
      staff {
        id
        lastname
        firstname
      }
      doctor {
        id
        lastname
        firstname
      }
      activity
      details
    }
  }
`
