import './style.css'

import { useMutation } from '@apollo/react-hooks'
import CustomCalendarPicker from '@medical/components/CalendarPicker'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import TextInput from '@medical/components/TextInput'
import { validateIdentityNumber } from '@medical/constant/utilities'
import { ja } from '@medical/libs'
import { DOWNLOAD_FILE } from '@medical/pages/staff/Doctors/DoctorDetail/DoctorDetail.graphql'
import { Checkbox } from 'antd'
import { ErrorMessage, FieldArray, Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { InputText } from 'primereact/inputtext'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import UploadIdentity from './UploadIdentity'

const base64Decode = input => {
  return Buffer.from(input, 'base64').toString('ascii')
}

const DoctorIdentityForm = ({
  doctor,
  identity,
  closeModal,
  onSubmit,
  confirmationMessage,
  disabled,
}) => {
  const { i18n } = useTranslation()
  const minDate = moment().subtract(100, 'year')
  const maxDate = moment()
  const yearRange = `${minDate.format('YYYY')}:${maxDate.format('YYYY')}`
  const validationSchema = Yup.object({
    identityNumber: Yup.string()
      .required(i18n.t('validation.identity.required'))
      .test(
        'checkDigitNumber',
        i18n.t('validation.identity.checkDigit'),
        value => validateIdentityNumber(value)
      ),
    isAcceptLicense: Yup.boolean()
      .required(
        '個人番号利用目的についてご確認いただき、こちらにチェックをつけていただく必要がございます。'
      )
      .oneOf(
        [true],
        '個人番号利用目的についてご確認いただき、こちらにチェックをつけていただく必要がございます。'
      ),
    frontSideIdentityKey: Yup.mixed().required(
      i18n.t('validation.identity.myNumberRequired')
    ),
    backSideIdentityKey: Yup.mixed().required(
      i18n.t('validation.identity.myNumberRequired')
    ),
    doctorIdentityRelation: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(i18n.t('validation.identity.nameRequired')),
        relation: Yup.string().required(
          i18n.t('validation.identity.relationRequired')
        ),
        birthday: Yup.string().required(
          i18n.t('validation.identity.birthdayRequired')
        ),
        identityNumber: Yup.string()
          .required(i18n.t('validation.identity.identityNumberRequired'))
          .test(
            'checkDigitNumberRelation',
            i18n.t('validation.identity.checkDigit'),
            value => validateIdentityNumber(value)
          ),
      })
    ),
  })

  const [frontSideIdentityKey, setFrontSideIdentityKey] = useState(null)
  const [backSideIdentityKey, setBackSideIdentityKey] = useState(null)
  const [frontSideLicenseKey, setFrontSideDriverLicenseKey] = useState(null)
  const [backSideLicenseKey, setBackSideDriverLicenseKey] = useState(null)

  const [downloadFile] = useMutation(DOWNLOAD_FILE)
  const downloadFileSubmit = async (key, callback) => {
    if (!key || key.length === 0) return
    try {
      const {
        data: { downloadFileS3: fileURL },
      } = await downloadFile({
        variables: {
          key,
        },
      })
      callback(fileURL)
    } catch (error) {
      callback(null)
    }
  }

  useEffect(() => {
    if (identity) {
      downloadFileSubmit(identity.frontSideIdentityKey, fileURL =>
        setFrontSideIdentityKey(fileURL)
      )
      downloadFileSubmit(identity.backSideIdentityKey, fileURL =>
        setBackSideIdentityKey(fileURL)
      )
      downloadFileSubmit(identity.frontSideDriverLicenseKey, fileURL =>
        setFrontSideDriverLicenseKey(fileURL)
      )
      downloadFileSubmit(identity.backSideDriverLicenseKey, fileURL =>
        setBackSideDriverLicenseKey(fileURL)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identity])

  const formatDoctorIdentityRelation = array => {
    if (!array || array.length === 0) return []

    const result = array.map(element => {
      const elementReplace = {}
      elementReplace.id = element.id
      elementReplace.name = element.name
      elementReplace.relation = element.relation
      elementReplace.identityNumber = base64Decode(element.identityNumber)
      elementReplace.birthday = moment(element.birthday).toDate()
      return elementReplace
    })
    return result
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        isAcceptLicense: !!identity,
        fullName: doctor ? doctor.fullName : '',
        email: doctor ? doctor.email : '',
        birthday: doctor ? moment(doctor.birthday).toDate() : '',
        identityNumber:
          identity && identity.identityNumber
            ? base64Decode(identity.identityNumber)
            : '',
        frontSideIdentityKey: identity?.frontSideIdentityKey,
        backSideIdentityKey: identity?.backSideIdentityKey,
        frontSideLicenseKey: identity?.frontSideDriverLicenseKey,
        backSideLicenseKey: identity?.backSideDriverLicenseKey,
        deletedDoctorIdentityRelation: [],
        doctorIdentityRelation:
          identity && identity.doctorIdentityRelation
            ? formatDoctorIdentityRelation(identity.doctorIdentityRelation)
            : [],
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={formikProps => {
        const {
          handleChange,
          isSubmitting,
          handleBlur,
          setFieldValue,
          validateForm,
          setTouched,
          values,
        } = formikProps
        return (
          <React.Fragment>
            <TextInput
              {...formikProps}
              name='fullName'
              disabled
              classDisabled
              label={i18n.t('doctor.identity.fullName')}
              required
            />
            <TextInput
              {...formikProps}
              name='email'
              disabled
              classDisabled
              label={i18n.t('doctor.identity.mailAddress')}
              required
            />
            <CustomCalendarPicker
              {...formikProps}
              name='birthday'
              disabled
              readOnlyInput
              yearNavigator
              yearRange={yearRange}
              monthNavigator
              classDisabled
              label={i18n.t('doctor.identity.birthday')}
              required
              minDate={minDate.startOf('year').toDate()}
              maxDate={maxDate.endOf('year').toDate()}
              viewDate={
                values.birthday
                  ? moment(values.birthday).toDate()
                  : minDate.startOf('year').toDate()
              }
            />
            <div className='tr'>
              <div className='th required'>
                {i18n.t('doctor.identity.myNumber')}
              </div>
              <div className='td'>
                <input
                  className='p-inputtext p-component'
                  type='text'
                  name='identityNumber'
                  placeholder={i18n.t('doctor.identity.myNumberPlaceholder')}
                  value={values.identityNumber}
                  pattern='[0-9-]*'
                  onChange={e => {
                    e.target.value = e.target.value.replace(/[^0-9-]/g, '')
                    handleChange(e)
                  }}
                  onBlur={handleBlur}
                  disabled={disabled}
                />
                <ErrorMessage
                  name='identityNumber'
                  component='div'
                  className='signup-alert'
                />
              </div>
            </div>
            {/* Start Drop Image */}
            <div className='tr'>
              <div className='th required'>
                {i18n.t('doctor.identity.myNumberImage')}
              </div>
            </div>
            <div className='tr'>
              <div className='td' style={{ width: '50%' }}>
                {i18n.t('doctor.identity.front')}
              </div>
              <div className='td' style={{ width: '50%' }}>
                {i18n.t('doctor.identity.back')}
              </div>
            </div>
            <div className='tr'>
              <div className='td' style={{ width: '50%' }}>
                <UploadIdentity
                  {...formikProps}
                  i18n={i18n}
                  imgKey={frontSideIdentityKey}
                  fieldValue='frontSideIdentityKey'
                  downloadFileSubmit={downloadFileSubmit}
                  onCompleteUpload={fields => {
                    setFieldValue('frontSideIdentityKey', fields.key)
                  }}
                />
                <ErrorMessage
                  name='frontSideIdentityKey'
                  component='div'
                  className='signup-alert'
                />
              </div>
              <div className='td' style={{ width: '50%' }}>
                <UploadIdentity
                  {...formikProps}
                  i18n={i18n}
                  imgKey={backSideIdentityKey}
                  fieldValue='backSideIdentityKey'
                  downloadFileSubmit={downloadFileSubmit}
                  onCompleteUpload={fields => {
                    setFieldValue('backSideIdentityKey', fields.key)
                  }}
                />
                <ErrorMessage
                  name='backSideIdentityKey'
                  component='div'
                  className='signup-alert'
                />
              </div>
            </div>
            {/* End Drop Image */}
            {/* Start Drop Image */}
            <div className='tr'>
              <div className='th'>{i18n.t('doctor.identity.identityCard')}</div>
            </div>
            <div className='tr'>
              <div className='td' style={{ width: '50%' }}>
                {i18n.t('doctor.identity.front')}
              </div>
              <div className='td' style={{ width: '50%' }}>
                {i18n.t('doctor.identity.back')}
              </div>
            </div>
            <div className='tr'>
              <div className='td' style={{ width: '50%' }}>
              <UploadIdentity
                  {...formikProps}
                  i18n={i18n}
                  imgKey={frontSideLicenseKey}
                  fieldValue='frontSideLicenseKey'
                  downloadFileSubmit={downloadFileSubmit}
                  onCompleteUpload={(fields) => {
                    setFieldValue('frontSideLicenseKey', fields.key)
                  }}
                />
                <ErrorMessage
                  name='frontSideLicenseKey'
                  component='div'
                  className='signup-alert'
                />
              </div>
              <div className='td' style={{ width: '50%' }}>
              <UploadIdentity
                  {...formikProps}
                  i18n={i18n}
                  imgKey={backSideLicenseKey}
                  fieldValue='backSideLicenseKey'
                  downloadFileSubmit={downloadFileSubmit}
                  onCompleteUpload={(fields) => {
                    setFieldValue('backSideLicenseKey', fields.key)
                  }}
                />
                <ErrorMessage
                  name='backSideLicenseKey'
                  component='div'
                  className='signup-alert'
                />
              </div>
            </div>
            {/* End Drop Image */}

            <div className='tr'>
              <div className='th'>
                {i18n.t('doctor.identity.familyIdentity')}
              </div>
            </div>

            <div className='container'>
              <FieldArray
                name='doctorIdentityRelation'
                render={arrayHelpers => (
                  <div>
                    <table className='table-comment-container'>
                      <thead
                        style={{
                          textAlign: 'left',
                        }}
                      >
                        <th
                          style={{
                            width: '15%',
                          }}
                        >
                          <span>{i18n.t('doctor.identity.personName')}</span>
                        </th>
                        <th
                          style={{
                            width: '2%',
                          }}
                        />
                        <th
                          style={{
                            width: '15%',
                          }}
                        >
                          <span>{i18n.t('doctor.identity.relation')}</span>
                        </th>
                        <th
                          style={{
                            width: '2%',
                          }}
                        />
                        <th>
                          <span>{i18n.t('doctor.identity.birthday')}</span>
                        </th>
                        <th
                          style={{
                            width: '2%',
                          }}
                        />
                        <th
                          style={{
                            width: '25%',
                          }}
                        >
                          <span>{i18n.t('doctor.identity.myNumber')}</span>
                        </th>
                        <th
                          style={{
                            width: '2%',
                          }}
                        />

                        <th
                          style={{
                            width: '5%',
                          }}
                        />
                      </thead>
                      <tbody>
                        {values.doctorIdentityRelation.map(
                          (doctorIdentityRelation, index) => (
                            <>
                              <tr key={doctorIdentityRelation.id}>
                                <td className>
                                  <InputText
                                    value={
                                      values.doctorIdentityRelation[index].name
                                    }
                                    name={`doctorIdentityRelation[${index}].name`}
                                    disabled={disabled}
                                    classDisabled
                                    onChange={e => {
                                      setFieldValue(
                                        `doctorIdentityRelation[${index}].name`,
                                        e.target.value
                                      )
                                    }}
                                  />
                                  <ErrorMessage
                                    name={`doctorIdentityRelation[${index}].name`}
                                    component='div'
                                    className='signup-alert'
                                  />
                                </td>
                                <td />
                                <td className>
                                  <InputText
                                    value={
                                      values.doctorIdentityRelation[index]
                                        .relation
                                    }
                                    name={`doctorIdentityRelation[${index}].relation`}
                                    disabled={disabled}
                                    classDisabled
                                    onChange={e => {
                                      setFieldValue(
                                        `doctorIdentityRelation[${index}].relation`,
                                        e.target.value
                                      )
                                    }}
                                  />
                                  <ErrorMessage
                                    name={`doctorIdentityRelation[${index}].relation`}
                                    component='div'
                                    className='signup-alert'
                                  />
                                </td>
                                <td />
                                <td className='calendar-identity'>
                                  <Calendar
                                    value={
                                      values.doctorIdentityRelation[index]
                                        .birthday ||
                                      minDate.startOf('year').toDate()
                                    }
                                    dateFormat={ja.dateFormat}
                                    locale={ja}
                                    onChange={e =>
                                      setFieldValue(
                                        `doctorIdentityRelation[${index}].birthday`,
                                        e.value
                                      )
                                    }
                                    disabled={disabled}
                                    showIcon={!disabled}
                                    yearNavigator
                                    yearRange={yearRange}
                                    monthNavigator
                                    required
                                    readOnlyInput
                                    minDate={minDate.startOf('year').toDate()}
                                    maxDate={new Date()}
                                  />
                                  <ErrorMessage
                                    name={`doctorIdentityRelation[${index}].birthday`}
                                    component='div'
                                    className='signup-alert'
                                  />
                                </td>
                                <td />
                                <td>
                                  <InputText
                                    value={
                                      values.doctorIdentityRelation[index]
                                        .identityNumber
                                    }
                                    name={`doctorIdentityRelation[${index}].identityNumber`}
                                    disabled={disabled}
                                    classDisabled
                                    pattern='[0-9-]*'
                                    onChange={e => {
                                      e.target.value = e.target.value.replace(
                                        /[^0-9-]/g,
                                        ''
                                      )
                                      setFieldValue(
                                        `doctorIdentityRelation[${index}].identityNumber`,
                                        e.target.value
                                      )
                                    }}
                                  />
                                  <ErrorMessage
                                    name={`doctorIdentityRelation[${index}].identityNumber`}
                                    component='div'
                                    className='signup-alert'
                                  />
                                </td>
                                <td />
                                <td>
                                  <Button
                                    icon='pi pi-trash'
                                    onClick={() => {
                                      if (
                                        !doctor &&
                                        (!doctorIdentityRelation.deletedAt ||
                                          doctorIdentityRelation.deletedAt
                                            .length === 0)
                                      ) {
                                        const cloneDoctorIdentityRelation = {
                                          ...doctorIdentityRelation,
                                        }
                                        cloneDoctorIdentityRelation.deletedAt = moment
                                          .utc(new Date())
                                          .format()
                                        setFieldValue(
                                          `deletedDoctorIdentityRelation`,
                                          [
                                            ...values.deletedDoctorIdentityRelation,
                                            cloneDoctorIdentityRelation,
                                          ]
                                        )
                                      }
                                      arrayHelpers.remove(index)
                                    }}
                                  />
                                </td>
                              </tr>
                              <div style={{ marginBottom: '20px' }} />
                            </>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              />
              <div className='row add-career-button'>
                <Button
                  type='button'
                  disabled={disabled}
                  onClick={() => {
                    setFieldValue('doctorIdentityRelation', [
                      ...values.doctorIdentityRelation,
                      {
                        name: '',
                        relation: '',
                        birthday: moment('1970/01/01').toDate(),
                        identityNumber: '',
                        deletedAt: '',
                      },
                    ])
                  }}
                  label={i18n.t('doctor.signup.addCareerButton')}
                />
              </div>
            </div>
            <div style={{ margin: '40px 0' }}>
              <h3 style={{ textAlign: 'center', fontWeight: 'bold' }}>
                個人番号利用目的について
              </h3>
              <p>
                ※ご登録前に必ずご確認ください。
                <br />
                <br />
                当医療法人は、「行政手続きにおける特定の個人を識別するための番号の利用等に関する法律」及びその他関連法令の規定に基づき、個人番号(行政手続における特定の個人を識別するための番号の利用等に関する法律に定める個人番号をいいます。)を以下の目的で利用いたします。
                <br />
                【1】税務関係
                <br />
                <span style={{ marginLeft: '20px' }}>
                  1. 源泉徴収票及び給与支払報告書作成事務
                </span>
                <br />
                <span style={{ marginLeft: '20px' }}>
                  2. 税務署への支払調書作成および報告
                </span>
                <br />
                【2】その他
                <br />
                <span style={{ marginLeft: '20px' }}>
                  番号法及び関連法(省令やガイドライン含む)の改正等により、使用目的が拡大された場合にはその内容を含む
                </span>
                <br /> <br />
                ご登録いただいた個人番号については、上記の利用目的のみに使用し、個人番号等は厳正に管理致します。
              </p>
            </div>
            <div className='row'>
              <Checkbox
                {...formikProps}
                name='isAcceptLicense'
                checked={values.isAcceptLicense}
                onClick={e => {
                  setFieldValue('isAcceptLicense', e.target.checked)
                }}
              />
              <p style={{ marginLeft: '10px', color: '#db7093' }}>
                個人番号利用目的について内容を確認し、理解致しました。
              </p>
            </div>
            <div className='row'>
              <ErrorMessage
                name='isAcceptLicense'
                component='div'
                className='signup-alert'
              />
            </div>
            <div className='modal-bottom'>
              <Button
                className='cancel-button'
                onClick={closeModal}
                disabled={isSubmitting}
                type='submit'
                label={i18n.t('doctor.identity.cancelButton')}
              />
              <Button
                className='reflect-button'
                onClick={() => {
                  validateForm().then(response => {
                    if (!Object.keys(response).length) {
                      setFieldValue('confirm', true)
                    } else {
                      setTouched(response)
                    }
                  })
                }}
                disabled={isSubmitting}
                type='submit'
                label={
                  !identity
                    ? i18n.t('doctor.identity.createButton')
                    : i18n.t('doctor.identity.updateButton')
                }
              />
            </div>
            <ConfirmationModal
              {...formikProps}
              confirmationMessage={confirmationMessage}
            />
          </React.Fragment>
        )
      }}
    />
  )
}

export default DoctorIdentityForm
