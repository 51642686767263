import { useQuery, useSubscription } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import BreadCrumb from '@medical/components/Breadcrumb'
import { INQUIRY_SENDER } from '@medical/constant'
import { useCustom } from '@medical/provider/context'
import queryString from 'query-string'
import React from 'react'

import { DOCTOR_ME_CHAT, DOCTOR_MSG_SUBSCRIPTION } from './Inquiry.graphql'
import InquiryScene from './InquiryScene'

const Inquiry = ({ location: { search } }) => {
  const { title, type, clinicalDepartment } = queryString.parse(search)
  const [{ i18n }, { setNewMsg }] = useCustom()
  const {
    loading: doctorDataLoading,
    error: doctorDataError,
    data: doctorData,
    refetch: doctorDataRefetch,
  } = useQuery(DOCTOR_ME_CHAT, { fetchPolicy: 'cache-network-only' })
  useSubscription(DOCTOR_MSG_SUBSCRIPTION, {
    variables: {
      doctorId: doctorData && doctorData.doctorMe ? doctorData.doctorMe.id : '',
    },
    onSubscriptionData: () => {
      doctorDataRefetch()
    },
  })
  if (doctorDataLoading) return <ProgressSpinner />
  if (doctorDataError) return <ErrorComponent error={doctorDataError} />

  const { id: doctorId, conversation } = doctorData.doctorMe
  return (
    <React.Fragment>
      <div
        className='container is-max container-sp-inquiry'
        style={{ width: '70%', margin: '0px auto' }}
      >
        <div className='staff-title'>
          {i18n.t('doctor.menuBar.inquiry')}
          <BreadCrumb items={[{ title: i18n.t('doctor.menuBar.inquiry') }]} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className='box' style={{ width: '100%' }}>
            <InquiryScene
              setNewMsg={setNewMsg}
              doctorId={doctorId}
              i18n={i18n}
              conversationId={conversation ? conversation.id : null}
              inquirySender={INQUIRY_SENDER.DOCTOR}
              isReplyPermitted
              isRecruitment={title || type || clinicalDepartment}
              title={title}
              type={type}
              clinicalDepartment={clinicalDepartment}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Inquiry
