export const convertDoctorsConnection = doctors =>
  doctors.map(doctor => {
    const item = doctor.node
    return {
      ...item,
      fullNameEmail: item.blocked
        ? `【募集制限中】${item.fullName} (${item.email})`
        : `${item.fullName} (${item.email})`,
    }
  })
