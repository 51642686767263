import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import { CLINICAL_DEPARTMENTS_QUERY } from '@medical/pages/staff/CreateAvailableShift/CreateAvailableShift.graphql'
import gql from 'graphql-tag'

export const DOCTOR_APPLY_AVAILABLE_SHIFTS = gql`
  query DOCTOR_APPLY_AVAILABLE_SHIFTS(
    $startDate: DateTime
    $endDate: DateTime
    $clinicalDepartmentId: [ID!]
    $skip: Int
    $first: Int
  ) {
    doctorGetCanApplyAvailableShifts(
      startDate: $startDate
      endDate: $endDate
      clinicalDepartmentId: $clinicalDepartmentId
      skip: $skip
      first: $first
    ) {
      id
      startTime
      endTime
      createdAt
      updatedAt
      splitDateTime1
      splitDateTime2
      splitDateTime3
      hourlyWage
      splitHourlyWage1
      splitHourlyWage2
      splitHourlyWage3
      dailySalary
      comment
      updatedHourlyWageAt
      isPublished
      isSpecial
      recruitmentShiftsType
      count
      clinicalDepartment {
        id
        name
        extraName
        isDisplay
        clinic {
          id
          order
          name
          nameKana
        }
      }
      availableShiftDetails {
        id
        deletedAt
        doctor {
          id
        }
      }
    }
    ...DEPARTMENTS_FRAGMENT
    ...CLINICAL_DEPARTMENTS_QUERY
  }
  ${CLINICAL_DEPARTMENTS_QUERY}
  ${DEPARTMENTS_FRAGMENT}
`
