import { CATEGORY_TRANSPORT } from '@medical/constant'
import moment from 'moment'
import { calcTotalTransportationExpense } from './calcWorkScheduleTotal'

export const calDoctorMonthlyTransportationExpenses = ({
  doctorMonthlyTransportationExpenses,
  doctorMonthlyWorkSchedules,
}) => {
  // copy logic doctorWorkReport file
  const lstTransportationWithDate = []
  for (const transportation of doctorMonthlyTransportationExpenses) {
    if (transportation.category === CATEGORY_TRANSPORT.TRANFFIC_FEE) {
      lstTransportationWithDate.push({ ...transportation })
    } else {
      const startTime = moment.utc(transportation.startTime).clone()
      const endTime = moment.utc(transportation.endTime)
      while (startTime.isSameOrBefore(endTime)) {
        const timeCurrent = moment.utc(startTime).format('YYYY-MM-DD')
        const datesCheck = transportation.blockedDays
          ? transportation.blockedDays.split(',')
          : []

        if (!datesCheck.includes(timeCurrent.slice(0, 10))) {
          lstTransportationWithDate.push({
            ...transportation,
            startTime: `${timeCurrent}T00:00:00.000Z`,
            endTime: `${timeCurrent}T00:00:00.000Z`,
          })
        }
        startTime.add(1, 'days')
      }
    }
  }
  const transportationExpensesCost = lstTransportationWithDate.map(item => ({
    cost: item.cost,
    deletedAt: item.deletedAt,
  }))

  return calcTotalTransportationExpense(
    transportationExpensesCost,
    doctorMonthlyWorkSchedules.map(item => ({
      ...item,
      costWs:
        item.costTransportationByDoctorWorkSchedule.length > 0
          ? item.costTransportationByDoctorWorkSchedule[0].cost
          : 0,
      costTransportationByDoctorWorkSchedule:
        item.costTransportationByDoctorWorkSchedule.length > 0
          ? item.costTransportationByDoctorWorkSchedule[0].returnPath[2]
          : 0,
    }))
  )
}

export const calTotalMonthlyAmountWorkSchedule = ({
  doctorMonthlyWorkSchedules,
}) =>
  doctorMonthlyWorkSchedules.reduce(
    (prev, { acceptedShift }) => prev + acceptedShift.dailySalary,
    0
  )
