import ConfirmationModal from '@medical/components/ConfirmationModal'
import { Checkbox } from 'antd'
import { ErrorMessage, Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import React from 'react'
import * as Yup from 'yup'

const DetailDoctorSkillScene = ({
  skillDetail,
  onSubmit,
  isUpdatePermitted,
  isDeletePermitted,
}) => {
  const validationSchema = Yup.object().shape({
    skillName: Yup.string()
      .required('スキル名は必要です。')
      .test('len', '100文字以下で設定してください', val => val.length <= 100),
  })
  return (
    <Formik
      enableReinitialize
      initialValues={{
        functionName: '',
        confirm: false,
        deleting: false,
        updating: false,
        skillId: skillDetail.id,
        skillName: skillDetail.name,
        skillDisplay: skillDetail.isShow,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={formikProps => {
        const {
          isSubmitting,
          validateForm,
          setTouched,
          setFieldValue,
          values,
        } = formikProps
        let confirmationMessage
        switch (values.functionName) {
          case 'update':
            confirmationMessage = '更新しますか？'
            break
          case 'delete':
            confirmationMessage = '削除しますか？'
            break
          default:
            break
        }
        return (
          <div className='doctor-skill'>
            <div className='modal-title' style={{ fontWeight: 'bold' }}>
              医師スキル更新
            </div>
            <div className='tr'>
              <div className='th'>作成日</div>
              <div className='td'>
                {moment(skillDetail.createdAt).format('YYYY年MM月DD日')}
              </div>
            </div>
            <div className='tr'>
              <div className='th'>最後更新日</div>
              <div className='td'>
                {moment(skillDetail.updatedAt).format('YYYY年MM月DD日')}
              </div>
            </div>
            <div className='tr'>
              <div className='th'>作成者</div>
              <div className='td'>{`${skillDetail.createdBy.lastname} ${skillDetail.createdBy.firstname}`}</div>
            </div>
            <div className='tr'>
              <div className='th'>最後更新者</div>
              <div className='td'>{`${skillDetail.updatedBy.lastname} ${skillDetail.updatedBy.firstname}`}</div>
            </div>
            <div className='tr'>
              <div className='th'>スキル名</div>
              <div className='td'>
                <InputText
                  {...formikProps}
                  value={values.skillName}
                  name='skillName'
                  onChange={e => {
                    setFieldValue('skillName', e.target.value)
                  }}
                />
              </div>
            </div>
            <div className='tr'>
              <div className='th' />
              <div className='td'>
                <div className='alert'>
                  <ErrorMessage name='skillName' />
                </div>
              </div>
            </div>
            <div className='tr checkbox'>
              <div className='th '>医師スキルをプロフィール上に表示</div>
              <div className='td'>
                <Checkbox
                  {...formikProps}
                  checked={values.skillDisplay}
                  name='skillDisplay'
                  onChange={e => {
                    setFieldValue('skillDisplay', e.target.checked)
                  }}
                />
              </div>
            </div>
            <div className='modal-bottom'>
              {isDeletePermitted ? (
                <Button
                  className='cancel'
                  onClick={() => {
                    setFieldValue('functionName', 'delete')
                    setFieldValue('deleting', true)
                    setFieldValue('confirm', true)
                  }}
                  disabled={isSubmitting}
                  type='submit'
                  label='削除する'
                />
              ) : null}
              {isUpdatePermitted ? (
                <Button
                  className='reflect-button'
                  onClick={() => {
                    validateForm().then(response => {
                      if (!Object.keys(response).length) {
                        setFieldValue('functionName', 'update')
                        setFieldValue('updating', true)
                        setFieldValue('confirm', true)
                      } else {
                        setTouched(response)
                      }
                    })
                  }}
                  disabled={isSubmitting || values.skillName.length === 0}
                  type='submit'
                  label='更新する'
                />
              ) : null}
            </div>
            <ConfirmationModal
              {...formikProps}
              confirmationMessage={confirmationMessage}
            />
          </div>
        )
      }}
    />
  )
}

export default DetailDoctorSkillScene
