import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { CREATE_ACTIVITY } from '@medical/pages/doctor/AvailableShift/AvailableShift.graphql'
import { DOWNLOAD_FILE } from '@medical/pages/staff/Doctors/DoctorDetail/DoctorDetail.graphql'
import { useCustom } from '@medical/provider/context'
import React, { useEffect, useState } from 'react'
import { checkIsPdf } from '@medical/constant/utilities'
import { DOCTOR_FILES } from './DoctorMyPage.graphql'
import DoctorMyPageScene from './DoctorMyPageScene'

const DoctorMyPage = () => {
  const [openPreviewImg, setOpenPreviewImg] = useState(false)
  const [imgPath, setImgPath] = useState()
  const [{ i18n, popup }] = useCustom()
  const { loading, error, data, refetch } = useQuery(DOCTOR_FILES, {
    variables: {
      where: {
        deletedAt: null,
      },
    },
  })
  const [downloadFile] = useMutation(DOWNLOAD_FILE)
  const [doctorCreateActivity] = useMutation(CREATE_ACTIVITY)
  useEffect(() => {
    doctorCreateActivity({
      variables: {
        activity:
          'DOCTOR_DISPLAYED_INFORMATION_FOR_INSURANCE_MEDICAL_TREATMENT_AND_SALARY_PAYMENT',
      },
    })
  }, [])
  useEffect(() => {
    refetch()
  })
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />

  const downloadFileSubmit = async key => {
    try {
      const {
        data: { downloadFileS3: fileURL },
      } = await downloadFile({
        variables: {
          key,
        },
      })
      if (checkIsPdf(fileURL.toString())) {
        setImgPath()
        window.open(fileURL.toString(), '_blank')
      } else {
        setImgPath(fileURL)
        setOpenPreviewImg(true)
      }
    } catch (error) {
      popup.error(i18n.t('main.downloadFileError'))
    }
  }
  const otherFileAll = data.doctorMe.files.filter(
    item => item.fileCategory === 'OTHER'
  )
  const handleOpenPreview = value => {
    setOpenPreviewImg(value)
  }
  const onSubmit = async ({ key }, { setSubmitting, resetForm }) => {
    try {
      window.location.href = imgPath
      setSubmitting(false)
      resetForm()
    } catch (error) {
      popup.error(i18n.t('main.downloadFileError'))
      setSubmitting(false)
    }
  }
  return (
    <DoctorMyPageScene
      fileCategories={data.fileCategories}
      i18n={i18n}
      files={data.doctorMe.files}
      doctor={data.doctorMe}
      downloadFileSubmit={downloadFileSubmit}
      otherFileAll={otherFileAll}
      imgPath={imgPath}
      openPreviewImg={openPreviewImg}
      handleOpenPreview={handleOpenPreview}
      onSubmit={onSubmit}
    />
  )
}

export default DoctorMyPage
