export const findNameOrEmail = ({ fullname, email }) => [
  {
    OR: [
      {
        unSigned_contains: fullname
          ? fullname
              .replace(/ /g, '')
              .replace(/　/g, '')
              .toLowerCase()
          : '',
      },
    ],
  },
  {
    email_contains: email ? email.trim() : '',
  },
]
