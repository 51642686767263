import ClinicNameRender from '@medical/components/ClinicNameRender'
import numeralCurrency from '@medical/libs/numeralCurrency'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const AvailableShiftItem = ({
  availableShift,
  action,
  departmentNames,
}) => {
  const { i18n } = useTranslation()

  return (
    <div className='mobile-work-schedule-item'>
      <div style={{ marginBottom: '10px' }}>
        {i18n.t('main.clinicName')} :
        <span className='clinic-name'>
          {ClinicNameRender({
            clinicalDepartment: availableShift.clinicalDepartment,
          })}
        </span>
      </div>
      <div>
        <div>
          {i18n.t('main.departmentName')} :{' '}
          {departmentNames[availableShift.clinicalDepartment.name]}
        </div>
        <div>
          {i18n.t('main.startTime')} :{' '}
          {moment(availableShift.startTime).format('HH:mm')}
        </div>
        <div>
          {i18n.t('main.endTime')} :{' '}
          {moment(availableShift.endTime).format('HH:mm')}
        </div>
        <div>
          {i18n.t('main.dailySalary')} :{' '}
          {numeralCurrency(availableShift.dailySalary)}
        </div>
      </div>
      <span className='action'>{action(availableShift)}</span>
    </div>
  )
}
