import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import gql from 'graphql-tag'

import { WORK_SCHEDULE_FRAGMENTS } from '../Schedule/Schedule.graphql'

export const DOCTOR_WORK_SCHEDULES = gql`
  query WorkSchedules(
    $where: WorkScheduleWhereInput
    $orderBy: WorkScheduleOrderByInput
  ) {
    workSchedules(where: $where, orderBy: $orderBy) {
      id
      startTime
      endTime
      accepted
      deletedStatus
      adjustHourlyWageRange
      acceptedAt
      clinicalDepartment {
        id
        name
        extraName
        isDisplay
        clinic {
          id
          order
          name
          nameKana
        }
      }
      acceptedShift {
        id
        dailySalary
      }
    }
    ...DEPARTMENTS_FRAGMENT
  }
  ${DEPARTMENTS_FRAGMENT}
`
export const DOCTOR_CANCEL_REQUESTED_WORK_SCHEDULE = gql`
  mutation DOCTOR_CANCEL_REQUESTED_WORK_SCHEDULE($workScheduleId: ID!) {
    doctorCancelRequestedWorkSchedule(workScheduleId: $workScheduleId) {
      id
    }
  }
`
