/* eslint-disable no-nested-ternary */
import ConfirmationModal from '@medical/components/ConfirmationModal'
import {  Input } from 'antd'
import {  Formik } from 'formik'
import { Button, Button as PrimeButton } from 'primereact/button'
import React, { useState } from 'react'
import * as Yup from 'yup'

const CategoryForm = ({
  onSubmit,
  i18n,
  listCategory = [],
  isCreatePermitted,
  isDeletePermitted,
  isUpdatePermitted,
}) => {
  const [editCategory, setEditCategory] = useState([])
  const [validate, setValidate] = useState(false)
  const validationSchema = Yup.object().shape({
    createContent: Yup.string()
      .nullable()
      .required(
        `${i18n.t('staff.questions.category')}${i18n.t('validation.required')}`
      ),
  })
  return (
    <Formik
      enableReinitialize
      initialValues={{
        listCategory,
        createContent: '',
        confirmationMessage: '',
      }}
      onSubmit={onSubmit}
      render={formikProps => {
        const { setFieldValue, values } = formikProps
        return (
          <div>
            <div className='tr'>
              <div className='staff-title' style={{ marginBottom: '20px' }}>
                {i18n.t('staff.questions.categoryTitle')}
              </div>
            </div>
            <div>
              {values.listCategory && values.listCategory.length > 0
                ? values.listCategory.map((as, index) => (
                    <div className='tr'>
                      <div className='td' style={{ width: '70%' }}>
                        <Input
                          className='category-form-td input-category'
                          value={values.listCategory[index].content}
                          name={`listCategory[${index}].content`}
                          disabled={values.listCategory[index].edit}
                          onChange={e => {
                            setFieldValue(
                              `listCategory[${index}].content`,
                              e.target.value
                            )
                          }}
                        />
                        <div className='signup-alert category-form-td'>
                          {values.listCategory[index].content
                            ? null
                            : 'カテゴリーは必須項目です'}
                        </div>
                      </div>
                      <div className='td' style={{ width: '30%' }}>
                        {isUpdatePermitted &&
                        values.listCategory[index].edit ? (
                          <Button
                            style={{ marginLeft: '10px' }}
                            icon='pi pi-pencil'
                            onClick={() => {
                              setEditCategory(!editCategory)
                              values.listCategory[index].edit = false
                            }}
                          />
                        ) : isUpdatePermitted ? (
                          <Button
                            style={{ marginLeft: '10px' }}
                            icon='pi pi-save'
                            onClick={() => {
                              if (values.listCategory[index].content.trim()) {
                                formikProps.setFieldValue(
                                  'confirmationMessage',
                                  i18n.t(
                                    'staff.questions.submitUpdateCategoryConfirmation'
                                  )
                                )
                                formikProps.setFieldValue(
                                  'id',
                                  values.listCategory[index].id
                                )
                                formikProps.setFieldValue('isUpdate', true)
                                formikProps.setFieldValue(
                                  'content',
                                  values.listCategory[index].content
                                )
                                formikProps.setFieldValue('confirm', true)
                              }
                            }}
                          />
                        ) : null}

                        {isDeletePermitted ? (
                          <Button
                            style={{ marginLeft: '10px' }}
                            icon='pi pi-trash'
                            onClick={() => {
                              formikProps.setFieldValue(
                                'confirmationMessage',
                                i18n.t(
                                  'staff.questions.submitDeleteCategoryConfirmation'
                                )
                              )
                              formikProps.setFieldValue(
                                'id',
                                values.listCategory[index].id
                              )
                              formikProps.setFieldValue(
                                'content',
                                values.listCategory[index].content
                              )
                              formikProps.setFieldValue('confirm', true)
                            }}
                          />
                        ) : null}
                      </div>
                    </div>
                  ))
                : null}

              {isCreatePermitted ? (
                <div className='tr'>
                  <div className='td' style={{ width: '70%' }}>
                    <Input
                      value={values.createContent}
                      className='category-form-td input-category'
                      placeholder='カテゴリー'
                      onChange={e => {
                        setFieldValue('createContent', e.target.value)
                        if (e.target.value !== '') {
                          setValidate(false)
                        }
                      }}
                    />
                    <div className='signup-alert category-form-td'>
                      {validate ? 'カテゴリーは必須項目です' : null}
                    </div>
                  </div>
                  <div className='td' style={{ width: '30%' }} />
                </div>
              ) : null}
              {isCreatePermitted ? (
                <div style={{ marginTop: '40px', textAlign: 'center' }}>
                  <Button
                    label={i18n.t('staff.questions.crateCategory')}
                    icon='pi pi-plus'
                    type='submit'
                    onClick={() => {
                      if (values.createContent.trim()) {
                        formikProps.setFieldValue(
                          'confirmationMessage',
                          i18n.t(
                            'staff.questions.submitCreateCategoryConfirmation'
                          )
                        )
                        setFieldValue('content', values.createContent)
                        setFieldValue('isCreate', true)
                        setFieldValue('confirm', true)
                      } else {
                        setValidate(true)
                      }
                    }}
                  />
                </div>
              ) : null}
            </div>
            <ConfirmationModal
              {...formikProps}
              confirmationMessage={values.confirmationMessage}
            />
          </div>
        )
      }}
    />
  )
}
export default CategoryForm
