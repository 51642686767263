export default {
  row: {
    display: 'flex',
    justifyContent: 'left',
  },
  head: {
    fontWeight: 'bold',
    padding: '15px 0',
    lineHeight: '20px',
    marginBottom: '4px',
  },
  headParent: {
    display: 'flex',
    fontWeight: 'bold',
    paddingBottom: '10px',
  },
  headItem: {
    width: '50%',
  },
  column: {
    marginBottom: '20px',
    display: 'flex',
  },
  columnHead: {
    padding: '0 40px 0 0',
  },
  th: {
    textAlign: 'left',
  },
  table: {
    width: '100%',
    marginBottom: '20px',
  },
  columnBody: {
    padding: '10px',
  },
  input: {
    display: 'flex',
    flex: 1,
  },
}
