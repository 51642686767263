import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import gql from 'graphql-tag'
import { CLINICAL_DEPARTMENTS_QUERY } from '../CreateAvailableShift/CreateAvailableShift.graphql'

export const WAITING_RECRUITMENT_QUERY = gql`
  query WaitingRecruitmentShiftsConnection(
    $where: WaitingRecruitmentShiftWhereInput
    $skip: Int
    $first: Int
  ) {
    waitingRecruitmentShiftsConnection(where: $where, skip: $skip, first: $first) {
      edges {
        node {
          id
          createdAt
          updatedAt
          deletedAt
          startDate
          endDate
          active
          startTime
          endTime
          description
          confirmationStatus
          doctor {
            id
            fullName
          }
          recruitmentShifts {
            id,
            clinic
          }
        }
      }
      aggregate(where: $where) {
        count
      }
    }
    ...DEPARTMENTS_FRAGMENT
    ...CLINICAL_DEPARTMENTS_QUERY
  }
  ${DEPARTMENTS_FRAGMENT}
  ${CLINICAL_DEPARTMENTS_QUERY}
`
