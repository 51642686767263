import moment from 'moment'
import { setTimeSplitByDay } from '@medical/libs'
export const isHolidayInRangeDate = ({ startDate, endDate, checkWeekDay }) => {
  let values
  let startDate1 = moment(startDate)
  let count = 0
  while (startDate1.isSameOrBefore(moment(endDate)) && count < 7) {
    let dayNo = moment(startDate1).day()
    switch (dayNo) {
      case 1:
        values = { ...values, ...setTimeSplitByDay('1', 'isAvailableOnMonday') }
        break
      case 2:
        values = {
          ...values,
          ...setTimeSplitByDay('2', 'isAvailableOnTuesday'),
        }
        break
      case 3:
        values = {
          ...values,
          ...setTimeSplitByDay('3', 'isAvailableOnWednesday'),
        }
        break
      case 4:
        values = {
          ...values,
          ...setTimeSplitByDay('4', 'isAvailableOnThursday'),
        }
        break
      case 5:
        values = { ...values, ...setTimeSplitByDay('5', 'isAvailableOnFriday') }
        break
      case 6:
        if (!checkWeekDay) {
          values = {
            ...values,
            ...setTimeSplitByDay('6', 'isAvailableOnSaturday'),
          }
        }
        break
      case 0:
        if (!checkWeekDay) {
          values = {
            ...values,
            ...setTimeSplitByDay('7', 'isAvailableOnSunday'),
          }
        }
        break
      default:
        break
    }
    startDate1.add(1, 'days')
    count += 1
  }
  return values
}
