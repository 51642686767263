import CustomCheckBox from '@medical/components/CheckboxHourlyWage/index'
import ConfirmationModal from '@medical/components/ConfirmationModal'
import { Checkbox, DatePicker, Input, Space, TimePicker } from 'antd'
import locale from 'antd/es/date-picker/locale/ja_JP'
import { ErrorMessage, Formik } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import AutoComplete from '@medical/components/AutoComplete'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'

const { RangePicker } = DatePicker
const HourlyWageDetailScene = ({
  onSubmit,
  confirmationMessage,
  isUpdateAction,
  i18n,
  clinicalDepartments,
  defaultHourlyWageSchedule,
  onDeleteSchedule,
  isDuplicateAction,
}) => {
  const [isDeleteAction, setIsDeleteAction] = useState(false)
  const validationSchema = Yup.object()
    .shape({
      clinicalDepartments:
        isUpdateAction || isDuplicateAction
          ? Yup.string()
          : Yup.string().required(
              i18n.t('validation.hourlyWagePage.clinicalDepartments')
            ),
      defaultHourlyWageScheduleId: Yup.string(),
      hourlyWage: !isDeleteAction
        ? Yup.mixed()
            .test(
              'validateHourlyWage',
              i18n.t('validation.hourlyWagePage.required'),
              val => {
                if (isNaN(val)) {
                  return false
                }
                return true
              }
            )
            .required('入力してください。')
        : Yup.number(),
      date: Yup.mixed().test(
        'validateDate',
        i18n.t('validation.hourlyWagePage.date'),
        val => {
          if (!isUpdateAction) {
            const result = !val.includes(null)
            return result
          }
          return true
        }
      ),
      time: Yup.mixed().test(
        'validateTime',
        i18n.t('validation.hourlyWagePage.time'),
        val => {
          if (!isUpdateAction) {
            const result = !val.includes(null)
            return result
          }
          return true
        }
      ),
      isMonday: Yup.boolean(),
      isTuesday: Yup.boolean(),
      isWednesday: Yup.boolean(),
      isThursday: Yup.boolean(),
      isFriday: Yup.boolean(),
      isSaturday: Yup.boolean(),
      isSunday: Yup.boolean(),
      isDayoff: Yup.boolean(),
    })
    .test('checkSelectDay', i18n.t('validation.hourlyWagePage.day'), value => {
      if (
        !value.isMonday &&
        !value.isTuesday &&
        !value.isWednesday &&
        !value.isThursday &&
        !value.isFriday &&
        !value.isSaturday &&
        !value.isSunday &&
        !value.isDayoff &&
        !isDeleteAction
      ) {
        return false
      }
      return true
    })
  const [isSelectdAll, setIsSelectedAll] = useState(false)
  const [allDepartments, setAllDepartments] = useState([])
  const [currentValue, setCurrentValue] = useState({
    defaultHourlyWageScheduleId: '',
    date: [null, null],
    time: [null, null],
    isAllday: false,
    isMonday: false,
    isTuesday: false,
    isWednesday: false,
    isThursday: false,
    isFriday: false,
    isSaturday: false,
    isSunday: false,
    isDayoff: false,
    hourlyWage: '',
    type: [],
  })
  useEffect(() => {
    if (isUpdateAction || isDuplicateAction) {
      if (defaultHourlyWageSchedule) {
        let currentdefaultHourlyWageSchedule = defaultHourlyWageSchedule
        let tempList = {
          defaultHourlyWageScheduleId: currentdefaultHourlyWageSchedule.id,
          clinicalDepartments:
            currentdefaultHourlyWageSchedule.clinicalDepartment.clinic.name,
          clinicalDepartmentId:
            currentdefaultHourlyWageSchedule.clinicalDepartment.id,
          date: [
            currentdefaultHourlyWageSchedule.startDate,
            currentdefaultHourlyWageSchedule.endDate,
          ],
          time: [
            currentdefaultHourlyWageSchedule.startTime,
            currentdefaultHourlyWageSchedule.endTime,
          ],
          isAllday:
            currentdefaultHourlyWageSchedule.isAvailableOnMonday &&
            currentdefaultHourlyWageSchedule.isAvailableOnTuesday &&
            currentdefaultHourlyWageSchedule.isAvailableOnWednesday &&
            currentdefaultHourlyWageSchedule.isAvailableOnThursday &&
            currentdefaultHourlyWageSchedule.isAvailableOnFriday
              ? true
              : false,
          isMonday: currentdefaultHourlyWageSchedule.isAvailableOnMonday,
          isTuesday: currentdefaultHourlyWageSchedule.isAvailableOnTuesday,
          isWednesday: currentdefaultHourlyWageSchedule.isAvailableOnWednesday,
          isThursday: currentdefaultHourlyWageSchedule.isAvailableOnThursday,
          isFriday: currentdefaultHourlyWageSchedule.isAvailableOnFriday,
          isSaturday: currentdefaultHourlyWageSchedule.isAvailableOnSaturday,
          isSunday: currentdefaultHourlyWageSchedule.isAvailableOnSunday,
          isDayoff: currentdefaultHourlyWageSchedule.isAvailableOnHoliday,
          hourlyWage: currentdefaultHourlyWageSchedule.hourlyWageDefault,
          type: [currentdefaultHourlyWageSchedule.classify],
        }
        setCurrentValue(tempList)
        setIsSelectedAll(true)
      }
    }
    let allClinic = []
    for (let i = 0; i < clinicalDepartments.length; i++) {
      let tempItem = {
        label: clinicalDepartments[i].clinicName,
        value: clinicalDepartments[i].id,
      }
      allClinic.push(tempItem)
    }
    setAllDepartments(allClinic)
  }, [])

  const option = [
    {
      description: i18n.t('staff.createHourlyWage.new'),
      value: 'NEW',
    },
    {
      description: i18n.t('staff.createHourlyWage.existing'),
      value: 'EXIST',
    },
  ]
  const formatTime = 'HH:mm'

  return (
    <Formik
      enableReinitialize
      initialValues={{
        confirm: false,
        defaultHourlyWageScheduleId:
          isUpdateAction || isDuplicateAction
            ? currentValue.defaultHourlyWageScheduleId
            : '',
        clinicalDepartmentId: currentValue.clinicalDepartmentId,
        clinicalDepartments: currentValue.clinicalDepartments,
        clinicalDepartment: allDepartments,
        date: currentValue.date,
        time: currentValue.time,
        isAllday: currentValue.isAllday,
        isMonday: currentValue.isMonday,
        isTuesday: currentValue.isTuesday,
        isWednesday: currentValue.isWednesday,
        isThursday: currentValue.isThursday,
        isFriday: currentValue.isFriday,
        isSaturday: currentValue.isSaturday,
        isSunday: currentValue.isSunday,
        isDayoff: currentValue.isDayoff,
        hourlyWage: currentValue.hourlyWage,
        type: currentValue.type,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={formikProps => {
        const {
          setFieldValue,
          handleChange,
          values,
          validateForm,
          setTouched,
          errors,
        } = formikProps
        return (
          <div className='hourly-container container'>
            <div className='row'>
              <div className='hourly-title col-md-12'>
                {i18n.t('staff.createHourlyWage.title')}
              </div>
            </div>
            <div className='row mt-15 move-inline'>
              <div className='hourly-item_title'>
                {i18n.t('staff.createHourlyWage.clinic')}
              </div>
              <div>
                <AutoComplete
                  {...formikProps}
                  name='clinicalDepartments'
                  field='label'
                  allSuggestions={allDepartments}
                  suggestionName='clinicalDepartment'
                  maxWidth='386px'
                  placeholder={
                    clinicalDepartments.length > 0
                      ? 'クリニックを選択'
                      : '対象データなし'
                  }
                  setCurrentClinicData={val => {
                    setIsSelectedAll(true)
                    setCurrentValue({
                      ...currentValue,
                      clinicalDepartments: val,
                    })
                  }}
                  disabled={isUpdateAction || isDuplicateAction}
                />
                <ErrorMessage
                  name='clinicalDepartments'
                  component='div'
                  className='color-red'
                />
              </div>
            </div>
            {isSelectdAll ? (
              <div className='hourly-page-detail'>
                <div className='hourly-wage-detail'>
                  <div className='row '>
                    <div className='col-md-10 border-black'>
                      <div className='row mt-10'>
                        <div className='col-md-10 move-inline'>
                          <div className='hour-detail-title '>
                            {i18n.t('staff.createHourlyWage.date')}
                          </div>
                          <div>
                            <Space direction='vertical' size={12}>
                              <RangePicker
                                {...formikProps}
                                className='hourly-picker'
                                value={[
                                  values.date[0]
                                    ? moment.utc(
                                        new Date(values.date[0]),
                                        'YYYY-MM-DD'
                                      )
                                    : null,
                                  values.date[1]
                                    ? moment.utc(
                                        new Date(values.date[1]),
                                        'YYYY-MM-DD'
                                      )
                                    : null,
                                ]}
                                name='date'
                                allowClear={false}
                                placeholder={['開始日', '終了日']}
                                locale={locale}
                                onChange={e => {
                                  setCurrentValue({
                                    ...currentValue,
                                    date: [
                                      moment(e[0]).format('YYYY-MM-DD'),
                                      moment(e[1]).format('YYYY-MM-DD'),
                                    ],
                                  })
                                }}
                              />
                            </Space>
                            <ErrorMessage
                              name='date'
                              component='div'
                              className='color-red'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row mt-10'>
                        <div className='col-md-4 move-inline'>
                          <div className='hour-detail-title '>
                            {i18n.t('staff.createHourlyWage.time')}
                          </div>
                          <div>
                            <Space direction='vertical' size={12}>
                              <TimePicker.RangePicker
                                {...formikProps}
                                className='hourly-picker'
                                value={[
                                  // eslint-disable-next-line no-nested-ternary
                                  values.time[0] != null
                                    ? isUpdateAction || isDuplicateAction
                                      ? moment.utc(
                                          new Date(values.time[0]),
                                          'YYYY-MM-DD'
                                        )
                                      : moment(
                                          new Date(values.time[0]),
                                          'YYYY-MM-DD'
                                        )
                                    : null,
                                  // eslint-disable-next-line no-nested-ternary
                                  values.time[1] != null
                                    ? isUpdateAction || isDuplicateAction
                                      ? moment.utc(
                                          new Date(values.time[1]),
                                          'YYYY-MM-DD'
                                        )
                                      : moment(
                                          new Date(values.time[1]),
                                          'YYYY-MM-DD'
                                        )
                                    : null,
                                ]}
                                name='time'
                                allowClear={false}
                                disabledHours={() => [0, 1, 2, 3, 4, 5, 6]}
                                hideDisabledOptions
                                placeholder={['開始時間', '終了時間']}
                                format={formatTime}
                                onChange={(e, timeString) => {
                                  setCurrentValue({
                                    ...currentValue,
                                    time: [e[0].format(), e[1].format()],
                                  })
                                }}
                              />
                            </Space>
                            <ErrorMessage
                              name='time'
                              component='div'
                              className='color-red'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row mt-10'>
                        <div>
                          <div className='col-md-10 move-inline'>
                            <div className='hour-detail-title '>
                              {i18n.t('staff.createHourlyWage.dayOfWeek')}
                            </div>
                            <div className='checkbox-container'>
                              <div className='checkbox-item'>
                                <Checkbox
                                  name='isAllday'
                                  checked={values.isAllday}
                                  onChange={e => {
                                    if (e.target.checked) {
                                      setCurrentValue({
                                        ...currentValue,
                                        isAllday: true,
                                        isMonday: true,
                                        isTuesday: true,
                                        isWednesday: true,
                                        isThursday: true,
                                        isFriday: true,
                                      })
                                    } else {
                                      setCurrentValue({
                                        ...currentValue,
                                        isAllday: false,
                                        isMonday: false,
                                        isTuesday: false,
                                        isWednesday: false,
                                        isThursday: false,
                                        isFriday: false,
                                      })
                                    }
                                  }}
                                />
                                <div className='checkbox-title'>
                                  {i18n.t('staff.createHourlyWage.allDay')}
                                </div>
                              </div>
                              <div className='checkbox-item'>
                                <Checkbox
                                  name='isMonday'
                                  checked={values.isMonday}
                                  onChange={e => {
                                    if (!e.target.checked) {
                                      setCurrentValue({
                                        ...currentValue,
                                        isMonday: false,
                                        isAllday: false,
                                      })
                                    } else if (
                                      values.isTuesday &&
                                      values.isWednesday &&
                                      values.isThursday &&
                                      values.isFriday
                                    ) {
                                      setCurrentValue({
                                        ...currentValue,
                                        isMonday: true,
                                        isAllday: true,
                                      })
                                    } else {
                                      setCurrentValue({
                                        ...currentValue,
                                        isMonday: e.target.checked,
                                      })
                                    }
                                  }}
                                />
                                <div className='checkbox-title'>
                                  {i18n.t('staff.createHourlyWage.monDay')}
                                </div>
                              </div>
                              <div className='checkbox-item'>
                                <Checkbox
                                  name='isTuesday'
                                  checked={values.isTuesday}
                                  onChange={e => {
                                    if (!e.target.checked) {
                                      setCurrentValue({
                                        ...currentValue,
                                        isTuesday: false,
                                        isAllday: false,
                                      })
                                    } else {
                                      if (
                                        values.isMonday &&
                                        values.isWednesday &&
                                        values.isThursday &&
                                        values.isFriday
                                      ) {
                                        setCurrentValue({
                                          ...currentValue,
                                          isTuesday: true,
                                          isAllday: true,
                                        })
                                      } else {
                                        setCurrentValue({
                                          ...currentValue,
                                          isTuesday: e.target.checked,
                                        })
                                      }
                                    }
                                  }}
                                />
                                <div className='checkbox-title'>
                                  {i18n.t('staff.createHourlyWage.tuesDay')}
                                </div>
                              </div>
                              <div className='checkbox-item'>
                                <Checkbox
                                  name='isWednesday'
                                  checked={values.isWednesday}
                                  onChange={e => {
                                    if (!e.target.checked) {
                                      setCurrentValue({
                                        ...currentValue,
                                        isWednesday: false,
                                        isAllday: false,
                                      })
                                    } else {
                                      if (
                                        values.isMonday &&
                                        values.isTuesday &&
                                        values.isThursday &&
                                        values.isFriday
                                      ) {
                                        setCurrentValue({
                                          ...currentValue,
                                          isWednesday: true,
                                          isAllday: true,
                                        })
                                      } else {
                                        setCurrentValue({
                                          ...currentValue,
                                          isWednesday: e.target.checked,
                                        })
                                      }
                                    }
                                  }}
                                />
                                <div className='checkbox-title'>
                                  {i18n.t('staff.createHourlyWage.wednesDay')}
                                </div>
                              </div>
                              <div className='checkbox-item'>
                                <Checkbox
                                  name='isThursday'
                                  checked={values.isThursday}
                                  onChange={e => {
                                    if (!e.target.checked) {
                                      setCurrentValue({
                                        ...currentValue,
                                        isThursday: false,
                                        isAllday: false,
                                      })
                                    } else {
                                      if (
                                        values.isMonday &&
                                        values.isTuesday &&
                                        values.isWednesday &&
                                        values.isFriday
                                      ) {
                                        setCurrentValue({
                                          ...currentValue,
                                          isThursday: true,
                                          isAllday: true,
                                        })
                                      } else {
                                        setCurrentValue({
                                          ...currentValue,
                                          isThursday: e.target.checked,
                                        })
                                      }
                                    }
                                  }}
                                />
                                <div className='checkbox-title'>
                                  {i18n.t('staff.createHourlyWage.thursDay')}
                                </div>
                              </div>
                              <div className='checkbox-item'>
                                <Checkbox
                                  name='isFriday'
                                  checked={values.isFriday}
                                  onChange={e => {
                                    if (!e.target.checked) {
                                      setCurrentValue({
                                        ...currentValue,
                                        isFriday: false,
                                        isAllday: false,
                                      })
                                    } else {
                                      if (
                                        values.isMonday &&
                                        values.isTuesday &&
                                        values.isWednesday &&
                                        values.isThursday
                                      ) {
                                        setCurrentValue({
                                          ...currentValue,
                                          isFriday: true,
                                          isAllday: true,
                                        })
                                      } else {
                                        setCurrentValue({
                                          ...currentValue,
                                          isFriday: e.target.checked,
                                        })
                                      }
                                    }
                                  }}
                                />
                                <div className='checkbox-title'>
                                  {i18n.t('staff.createHourlyWage.friDay')}
                                </div>
                              </div>
                              <div className='checkbox-item'>
                                <Checkbox
                                  name='isSaturday'
                                  checked={values.isSaturday}
                                  onChange={e => {
                                    setCurrentValue({
                                      ...currentValue,
                                      isSaturday: e.target.checked,
                                    })
                                  }}
                                />
                                <div className='checkbox-title'>
                                  {i18n.t('staff.createHourlyWage.saturDay')}
                                </div>
                              </div>
                              <div className='checkbox-item'>
                                <Checkbox
                                  name='isSunday'
                                  checked={values.isSunday}
                                  onChange={e => {
                                    setCurrentValue({
                                      ...currentValue,
                                      isSunday: e.target.checked,
                                    })
                                  }}
                                />
                                <div className='checkbox-title'>
                                  {i18n.t('staff.createHourlyWage.sunDay')}
                                </div>
                              </div>
                              <div className='checkbox-item'>
                                <Checkbox
                                  name='isDayoff'
                                  checked={values.isDayoff}
                                  onChange={e => {
                                    setCurrentValue({
                                      ...currentValue,
                                      isDayoff: e.target.checked,
                                    })
                                  }}
                                />
                                <div className='checkbox-title'>
                                  {i18n.t('staff.createHourlyWage.dayOff')}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='ml-70'>
                            <ErrorMessage
                              name='undefined'
                              component='div'
                              className='color-red'
                            />
                          </div>
                        </div>
                      </div>

                      <div className='row mt-10'>
                        <div className='col-md-12 move-inline'>
                          <div className='hour-detail-title'>
                            {i18n.t('staff.createHourlyWage.hourlyWage')}
                          </div>
                          <div>
                            <Input
                              name='hourlyWage'
                              value={values.hourlyWage}
                              placeholder='例：10000'
                              onChange={e => {
                                setCurrentValue({
                                  ...currentValue,
                                  hourlyWage: e.target.value,
                                })
                              }}
                            />
                            <ErrorMessage
                              name='hourlyWage'
                              component='div'
                              className='color-red'
                            />
                          </div>
                          <span className='ml-10'>
                            {i18n.t('staff.createHourlyWage.yen')}
                          </span>
                        </div>
                      </div>
                      <div className='row mt-10'>
                        <div className='hour-detail-title '>
                          {i18n.t('staff.createHourlyWage.type')}
                        </div>
                        <div className='col-md-12 move-inline'>
                          <CustomCheckBox
                            {...formikProps}
                            options={option}
                            name='type'
                            inLine
                            onceCheck
                            getCurrentValue={e => {
                              let tempList = [e]
                              // tempList.push(e.target.value.value)
                              setCurrentValue({
                                ...currentValue,
                                type: tempList,
                              })
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='button-clear'>
                  {!isDuplicateAction ? (
                    <Button
                      icon='pi pi-trash'
                      onClick={() => {
                        if (isUpdateAction) {
                          setIsDeleteAction(true)
                          setFieldValue('date', [null, null])
                          setFieldValue('confirm', true)
                        }
                      }}
                    />
                  ) : null}
                </div>
              </div>
            ) : null}

            <div className='row move-center mt-10'>
              {isDuplicateAction ? (
                <Button
                  label={i18n.t('staff.scheduledShiftGroup.createSubmit')}
                  className='reflect-button'
                  type='submit'
                  onClick={() => {
                    validateForm().then(response => {
                      if (!Object.keys(response).length) {
                        setFieldValue('confirm', true)
                      } else {
                        setTouched(response)
                      }
                    })
                  }}
                />
              ) : (
                <Button
                  label={
                    isUpdateAction
                      ? i18n.t('staff.createHourlyWage.buttonUpdate')
                      : i18n.t('staff.createHourlyWage.buttonAdd')
                  }
                  className='reflect-button'
                  type='submit'
                  onClick={() => {
                    validateForm().then(response => {
                      if (!Object.keys(response).length) {
                        setFieldValue('confirm', true)
                      } else {
                        setTouched(response)
                      }
                    })
                  }}
                />
              )}
            </div>
            {isDeleteAction ? (
              <ConfirmationModal
                isDeleteAction
                {...formikProps}
                confirmationMessage={'この設定を削除しますか？'}
              />
            ) : (
              <ConfirmationModal
                {...formikProps}
                confirmationMessage={confirmationMessage}
              />
            )}
          </div>
        )
      }}
    />
  )
}

export default HourlyWageDetailScene
