/* eslint-disable no-nested-ternary */
import BreadCrumb from '@medical/components/Breadcrumb'
import Calendar from '@medical/components/Calendar'
import { dateValidation } from '@medical/libs'
import Router from '@medical/routes/router'
import moment from 'moment'
import { Button, Button as PrimeButton } from 'primereact/button'
import React from 'react'
import { Link } from 'react-router-dom'

import styles from '../Style'

const PatientSatisfactionByMonthScene = ({
  i18n,
  doctorId,
  date,
  history,
  doctorIncentiveReports,
  doctorName,
  download,
  datePresent,
}) => {
  const currentDate = moment(date)
  const prevMonth = moment(currentDate).subtract(1, 'month')
  const nextMonth = moment(currentDate).add(1, 'month')
  const isPreviousDisabled =
    currentDate.isBefore(
      moment()
        .subtract(6, 'months')
        .endOf('month')
        .endOf('day')
    ) ||
    dateValidation({
      year: prevMonth.year(),
      month: prevMonth.month() + 1,
    })

  const isNextDisabled = dateValidation({
    year: nextMonth.year(),
    month: nextMonth.month() + 1,
  })

  const onDayChange = e => {
    const chosenMonth = moment(e.value)
    history.push({
      pathname: Router.get(Router.staffPatientSatisfaction, {
        id: doctorId,
        year: chosenMonth.year(),
        month: chosenMonth.clone().month() + 1,
        date: chosenMonth.date(),
      }),
    })
  }
  return (
    <React.Fragment>
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('staff.menuBar.patientSatisfactionResults')}
          <BreadCrumb
            items={[
              { title: i18n.t('staff.menuBar.patientSatisfactionResults') },
            ]}
          />
        </div>
        <div className='staff-buttons'>
          <PrimeButton
            icon='pi pi-download'
            className='p-button-normal'
            onClick={download}
            label='CSV ダウンロード'
          />
        </div>
      </div>
      <div className='container is-medium box'>
        <div className='breadcrumb' style={{ fontSize: '16px' }}>
          <Link
            to={Router.get(Router.staffPatientSatisfactionResultsByYear, {
              id: doctorId,
              year: currentDate.year(),
            })}
          >
            {currentDate.year()}年
          </Link>
          <i className='pi pi-chevron-right' />
          {moment(currentDate).format('YYYY年M月')}
        </div>

        <div style={{ marginBlock: '10px' }}>
          {i18n.t('patientSatisfactionByYearMonth.doctorName')} {doctorName}
        </div>
        <div style={styles.header}>
          <Link
            to={Router.get(Router.staffPatientSatisfaction, {
              id: doctorId,
              year: prevMonth.year(),
              month: prevMonth.month() + 1,
              date: '',
            })}
            onClick={isPreviousDisabled ? e => e.preventDefault() : undefined}
          >
            <Button icon='pi pi-chevron-left' disabled={isPreviousDisabled} />
          </Link>
          <div style={styles.title}>
            {moment(currentDate).format('YYYY年M月')}
          </div>
          <Link
            to={Router.get(Router.staffPatientSatisfaction, {
              id: doctorId,
              year: nextMonth.year(),
              month: nextMonth.month() + 1,
              date: '',
            })}
            onClick={isNextDisabled ? e => e.preventDefault() : undefined}
          >
            <Button icon='pi pi-chevron-right' disabled={isNextDisabled} />
          </Link>
          <div style={styles.dates}>
            <Calendar
              value={currentDate.toDate()}
              onChange={onDayChange}
              showIcon
              readOnlyInput
              monthNavigator
              minDate={moment()
                .startOf('month')
                .subtract(6, 'months')
                .toDate()}
            />
          </div>
        </div>
        <div>
          <table className='table table-custom'>
            <thead>
              <tr>
                <th width='3%'>
                  {i18n.t('patientSatisfactionByYearMonth.workingDay')}
                </th>
                <th width='5%'>
                  {i18n.t('patientSatisfactionByYearMonth.clinic')}
                </th>
                <th width='5%'>
                  {i18n.t('patientSatisfactionByYearMonth.department')}
                </th>
                <th width='5%'>
                  {i18n.t('patientSatisfactionByYearMonth.case1')}
                </th>
                <th width='5%'>
                  {i18n.t('patientSatisfactionByYearMonth.case2')}
                </th>
                <th width='5%'>
                  {i18n.t('patientSatisfactionByYearMonth.case3')}
                </th>
                <th width='5%'>
                  {i18n.t('patientSatisfactionByYearMonth.case4')}
                </th>
                <th width='5%'>
                  {i18n.t('patientSatisfactionByYearMonth.case5')}
                </th>
                <th width='5%'>
                  {i18n.t('patientSatisfactionByYearMonth.case6')}
                </th>
                <th width='5%'>
                  {i18n.t('patientSatisfactionByYearMonth.case7')}
                </th>
                <th width='5%'>
                  {i18n.t('patientSatisfactionByYearMonth.case8')}
                </th>
                <th width='5%'>
                  {i18n.t('patientSatisfactionByYearMonth.case9')}
                </th>
                <th width='5%'>
                  {i18n.t('patientSatisfactionByYearMonth.case10')}
                </th>
                <th width='5%'>
                  {i18n.t('patientSatisfactionByYearMonth.case11')}
                </th>
                <th width='5%'>
                  {i18n.t('patientSatisfactionByYearMonth.case12')}
                </th>
                <th width='5%'>
                  {i18n.t('patientSatisfactionByYearMonth.case13')}
                </th>
                <th width='5%'>
                  {i18n.t('patientSatisfactionByYearMonth.case14')}
                </th>
              </tr>
            </thead>
            <tbody>
              {doctorIncentiveReports.length > 0 ? (
                doctorIncentiveReports.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <div>{moment(item.date).format('MM/DD')}</div>
                    </td>
                    <td>
                      <div>{item.doctorClinic}</div>
                    </td>
                    <td>
                      <div>{item.doctorDepartment}</div>
                    </td>
                    <td>{item.field1}</td>
                    <td>{item.field2}</td>
                    <td>{item.field3}</td>
                    <td>{item.field4}</td>
                    <td>{item.field5}％</td>
                    <td>{item.field6}％</td>
                    <td>{item.field7}</td>
                    <td>{item.field8}</td>
                    <td>{item.field9}</td>
                    <td>{item.field10}</td>
                    <td>{item.field11}</td>
                    <td>{item.field12}</td>
                    <td>{item.field13}</td>
                    <td>{item.field14}</td>
                  </tr>
                ))
              ) : datePresent ? (
                <tr>
                  <td colSpan={17}>ご指定の日はデータがありません</td>
                </tr>
              ) : (
                <tr>
                  <td colSpan={17}>ご指定の月はデータがありません</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PatientSatisfactionByMonthScene
