import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { STAFF_CREATE_QUESTION } from '@medical/constant/permissions'
import { checkStaffPermission } from '@medical/libs'
import { QUESTION_CATEGORY } from '@medical/libs/general.graphql'
import { useCustom } from '@medical/provider/context'
import Router from '@medical/routes/router'
import React from 'react'

import QuestionsForm from '../components/QuestionsForm'
import { CREATE_QUESTION } from './QuestionsCreate.graphql'
import { CREATE_ACTIVITY } from '@medical/pages/doctor/AvailableShift/AvailableShift.graphql'

const QuestionCreate = ({ history }) => {
  const [{ i18n, popup }] = useCustom()
  const [createQuestion] = useMutation(CREATE_QUESTION)
  const [staffCreateActivity] = useMutation(CREATE_ACTIVITY)
  const { error, loading: loadingCategorys, data: categorys } = useQuery(
    QUESTION_CATEGORY,
    {
      variables: {
        where: {
          deletedAt: null,
        },
        orderBy: 'createdAt_ASC',
      },
      fetchPolicy: 'network-only',
    }
  )
  const isCreatePermitted = checkStaffPermission({
    functionName: STAFF_CREATE_QUESTION,
  })
  if (loadingCategorys) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  const listCategory =
    categorys && categorys.questionCategories
      ? categorys.questionCategories.map(item => ({
          value: item.id,
          description: item.content,
          createdAt: item.createdAt,
        }))
      : []
  const onSubmit = async (
    { category, title, content },
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      await createQuestion({
        variables: {
          categoryId: category.value,
          title: title ? title.trim() : '',
          content: content ? content.trim() : '',
          categoryCreatedAt: category.createdAt,
        },
      })
      staffCreateActivity({
        variables: {
          activity: 'STAFF_CREATE_QUESTION',
        },
      })
      popup.success('成功しました')
      setSubmitting(false)
      resetForm()
      history.push({
        pathname: Router.staffQuestions,
      })
    } catch (error) {
      popup.error(error)
      setFieldValue('confirm', false)
      setSubmitting(false)
    }
  }

  return (
    <QuestionsForm
      listCategory={listCategory}
      announce={{}}
      onSubmit={onSubmit}
      isCreatePermitted={isCreatePermitted}
      submitLabel={i18n.t('staff.questions.createLabel')}
      confirmationMessage={i18n.t(
        'staff.questions.submitCreateQuestionConfirmation'
      )}
    />
  )
}

export default QuestionCreate
