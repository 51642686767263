import { DOCTOR_FRAGMENTS } from '@medical/layout/DoctorLayout/DoctorLayoutRequest.graphql'
import {
  FILE_CATEGORIES_FRAGMENT,
  FILE_FRAGMENTS,
} from '@medical/libs/general.graphql'
import gql from 'graphql-tag'

import { BANK_ACCOUNT_FRAGMENTS } from '../BankAccount/BankAccount.graphql'

export const DOCTOR_FILES = gql`
  query DOCTOR_ME($where: FileWhereInput) {
    doctorMe {
      ...DOCTOR_FRAGMENTS
      files(where: $where) {
        ...FILE_FRAGMENTS
      }
      bankAccount {
        ...BANK_ACCOUNT_FRAGMENTS
      }
      doctorIdentity {
        id
        identityNumber
        frontSideIdentityKey
        backSideIdentityKey
        frontSideDriverLicenseKey
        backSideDriverLicenseKey
        doctorIdentityRelation {
          id
          name
          birthday
          relation
          identityNumber
          updatedAt
          deletedAt
        }
      }
    }
    ...FILE_CATEGORIES_FRAGMENT
  }
  ${DOCTOR_FRAGMENTS}
  ${FILE_FRAGMENTS}
  ${FILE_CATEGORIES_FRAGMENT}
  ${BANK_ACCOUNT_FRAGMENTS}
`

export const UPDATE_PROFILE_DOCTOR = gql`
  mutation UPDATE_PROFILE_DOCTOR($data: String) {
    updateDoctorProfile(data: $data) {
      id
    }
  }
`
