import gql from 'graphql-tag'

export const UPDATE_QUESTION = gql`
  mutation UpdateQuestion(
    $data: QuestionUpdateInput!
    $where: QuestionWhereUniqueInput!
  ) {
    updateQuestion(data: $data, where: $where) {
      id
    }
  }
`
export const DELETE_QUESTION = gql`
  mutation DeleteQuestion($where: QuestionWhereUniqueInput!) {
    deleteQuestion(where: $where) {
      id
    }
  }
`

export const GET_QUESTION = gql`
  query QUESTION_CONNECTION($where: QuestionWhereInput) {
    questionsConnection(where: $where) {
      edges {
        node {
          id
          category {
            id
            content
          }
          title
          content
          createdAt
        }
      }
    }
  }
`
