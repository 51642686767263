export default {
  spContainer: {
    position: 'relative',
    border: '1px solid #b2b6b8',
    borderCollapse: 'collapse',
  },
  removeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: '5px',
  },
  mobileRequestTitle: {
    fontSize: '14px',
    fontWeight: 'bold',
    backgroundColor: '#f4f4f4',
    padding: '10px 5px',
  },
  mobileRequest: {
    paddingLeft: '5px',
  },

  mobileRequestItem: {
    fontSize: '13px',
    padding: '2px 0',
  },

  mobileRequestItemContent: {
    paddingLeft: '10px',
    fontWeight: 'bold',
  },
}
